<template>
    <div class="charts">

        <div class="artwork-debug" v-if="debug">
            <div>
                <p>Color</p>
                <select name="color" @change="debugColorChange($event)" class="form-control" v-model="key">
                    <option value="red">Red</option>
                    <option value="green">Green</option>
                    <option value="yellow">Yellow</option>
                    <option value="blue">Blue</option>
                </select>
            </div>
            <div>
                <p>Animal</p>
                <select name="animal" @change="debugAnimalChange($event)" class="form-control" v-model="key">
                    <option value="dolphin">Dolphin</option>
                    <option value="panther">Panther</option>
                    <option value="peacock">Peacock</option>
                    <option value="owl">Owl</option>
                </select>
            </div>
            <div>
                <p>Nature</p>
                <select name="nature" @change="debugNatureChange($event)" class="form-control" v-model="key">
                    <option value="leaf">Leaf</option>
                    <option value="petal">Petal</option>
                    <option value="roots">Roots</option>
                    <option value="tree">Tree</option>
                </select>
            </div>
            <div>
                <p>Blending Mode</p>
                <select name="blendmode" @change="debugBlendmodeChange($event)" class="form-control" v-model="key">
                    <option value="color">color</option>
                    <option value="color-burn">color-burn</option>
                    <option value="color-dodge">color-dodge</option>
                    <option value="darken">darken</option>
                    <option value="difference">difference</option>
                    <option value="exclusion">exclusion</option>
                    <option value="hard-light">hard-light</option>
                    <option value="hue">hue</option>
                    <option value="lighten">lighten</option>
                    <option value="luminosity">luminosity</option>
                    <option value="multiply">multiply</option>
                    <option value="normal">normal</option>
                    <option value="overlay">overlay</option>
                    <option value="saturation">saturation</option>
                    <option value="screen">screen</option>
                    <option value="soft-light">soft-light</option>
                </select>
            </div>
        </div>

        <div class="artwrap">
            <div class="artwork" :data-color="backgroundColor" :class="'grad-' + backgroundColor">

                <div id="cap" style="position:relative;">
                    <img class="artwork__logo" alt="logo" src="~@/images/logo-bars.png"/>

                    <svg id="artwork" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 844.78 844.78" width="600" height="600">
                        
                        <linearGradient id="grad-1" gradientUnits="objectBoundingBox" 
                            x1="0%" y1="0%" x2="100%" y2="0%" gradientTransform="rotate(0)">
                            <stop offset="0%" stop-color="#0091AD" stop-opacity="1"/>
                            <stop offset="100%" stop-color="#6EFAFB" stop-opacity="1"/>
                        </linearGradient>
                        <linearGradient id="grad-2" gradientUnits="objectBoundingBox" 
                            x1="0%" y1="0%" x2="100%" y2="0%" gradientTransform="rotate(65)">
                            <stop offset="30%" stop-color="#FFF4E4" stop-opacity="1"/>
                            <stop offset="90%" stop-color="#F7E8A4" stop-opacity="1"/>
                        </linearGradient>
                        <linearGradient id="grad-3" gradientUnits="objectBoundingBox" 
                            x1="0%" y1="0%" x2="100%" y2="0%" gradientTransform="rotate(25)">
                            <stop offset="0%" stop-color="#A0E8AF" stop-opacity="1"/>
                            <stop offset="100%" stop-color="#86BAA1 " stop-opacity="1"/>
                        </linearGradient>
                        <linearGradient id="grad-4" gradientUnits="objectBoundingBox" 
                            x1="0%" y1="0%" x2="100%" y2="0%" gradientTransform="rotate(115)">
                            <stop offset="0%" stop-color="#7F95D1" stop-opacity="1"/>
                            <stop offset="100%" stop-color="#FFC0BE" stop-opacity="1"/>
                        </linearGradient>
                        <linearGradient id="grad-5" gradientUnits="objectBoundingBox" 
                            x1="0%" y1="0%" x2="100%" y2="0%" gradientTransform="rotate(45)">
                            <stop offset="00%" stop-color="#E8AE68" stop-opacity="1"/>
                            <stop offset="100%" stop-color="#A57F60" stop-opacity="1"/>
                        </linearGradient>

                        <g id="Layer_2" data-name="Layer 2">
                            <g id="Layer_1-2" data-name="Layer 1">

                                <g v-if="debugShape === false">                                
                                    <Dolphin v-if="animal === 'dolphin'"/>                                
                                    <Panther v-if="animal === 'panther'"/>
                                    <Peacock v-if="animal === 'peacock'"/>
                                    <Owl v-if="animal === 'owl'"/>
                                </g>

                                <g class="generatedlines" :transform="graphrotate">

                                    <!-- <path :d="getLayer(0)" :fill="pattern[0]" class="shape layer1"></path>
                                    <path :d="getLayer(1)" :fill="pattern[1]" class="shape layer2"></path>
                                    <path :d="getLayer(2)" :fill="pattern[2]" class="shape layer3"></path>
                                    <path :d="getLayer(3)" :fill="pattern[3]" class="shape layer4"></path>
                                    <path :d="getLayer(4)" :fill="pattern[4]" class="shape layer5"></path> -->
                                    
                                    <path :style="blendMode" :d="getLayer(0)" fill="url(#grad-1)" class="shape layer1"></path>
                                    <path :style="blendMode" :d="getLayer(1)" fill="url(#grad-2)" class="shape layer2"></path>
                                    <path :style="blendMode" :d="getLayer(2)" fill="url(#grad-3)" class="shape layer3"></path>
                                    <path :style="blendMode" :d="getLayer(3)" fill="url(#grad-4)" class="shape layer4"></path>
                                    <path :style="blendMode" :d="getLayer(4)" fill="url(#grad-5)" class="shape layer5"></path>

                                    <path :style="blendMode" :d="getLayer(0)" fill="url(#grad-1)" class="shape layer1"></path>
                                    <path :style="blendMode" :d="getLayer(1)" fill="url(#grad-2)" class="shape layer2"></path>
                                    <path :style="blendMode" :d="getLayer(2)" fill="url(#grad-3)" class="shape layer3"></path>
                                    <path :style="blendMode" :d="getLayer(3)" fill="url(#grad-4)" class="shape layer4"></path>
                                    <path :style="blendMode" :d="getLayer(4)" fill="url(#grad-5)" class="shape layer5"></path>

                                    <g class="radar-9" v-for="c in colText()" :key="c[0]">
                                        <text :id="c[5]" :data-id="c[6]" class="radar-16 radar-caption" :x="c[0]" :y="c[1]" text-anchor="middle" alignment-baseline="central" dominant-baseline="central" :transform="c[4]">{{c[2]}}<tspan class="radar-18" :x="c[0]" dy="1.2em">{{c[3]}}</tspan></text>
                                    </g>
                                </g>

                                <g v-if="debugShape === false">
                                    <Leaf v-if="nature === 'leaf'" transform="translate(260 190)"/>
                                    <Petal v-if="nature === 'petal'" transform="translate(210 210)"/>
                                    <Roots v-if="nature === 'roots'" transform="translate(230 250)"/>
                                    <Tree v-if="nature === 'tree'" transform="translate(260 280)"/>
                                </g>
                            </g>
                        </g>
                    </svg>

                </div>

                <div class="art-type">
                    <div class="container">
                        <div class="row">
                            <div class="col-12">
                                <h1>The Art of the Difference</h1>
                                <hr>
                                <h2>       
                                    {{learner}},                          
                                    {{cat[0]}}, 
                                    {{cat[1]}}, 
                                    {{cat[2]}}, 
                                    {{cat[3]}}, 
                                    {{cat[4]}}, 
                                    {{cat[5]}}.
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>

                

            </div>

        </div>

        <div class="container">
            
            <div class="row">                        
                <div class="col-12 text-center">
                    <Socials/>
                    <div class="eMenu">
                        <img src="~@/images/home/engage.png" alt="" class="eMenu__item" v-on:click="engage">
                        <img src="~@/images/home/e-split.png" alt="" class="eMenu__split">
                        <img src="~@/images/home/experience.png" alt="" class="eMenu__item" v-on:click="experience">
                        <img src="~@/images/home/e-split.png" alt="" class="eMenu__split">
                        <img src="~@/images/home/empower.png" alt="" class="eMenu__item" v-on:click="empower">
                    </div>
                </div>
            </div>

        </div>

        

    </div>
</template>

<script>

import router from '../router/';
import Dolphin from '../components/animals/dolphin.vue';
import Owl from '../components/animals/owl.vue';
import Peacock from '../components/animals/peacock.vue';
import Panther from '../components/animals/panther.vue';
import Leaf from '../components/nature/leaf.vue';
import Petal from '../components/nature/petal.vue';
import Roots from '../components/nature/roots.vue';
import Tree from '../components/nature/tree.vue';
import key from '../fixtures/key';
import Socials from '../components/socials.vue';
import {animalsTable, coloursTable, natureTable, typeTable, categoriesTable } from '../tables';
import roundPathCorners from '../helpers/rounder';


export default {
    name: 'results',
    props: {
        msg: String
    },
    components: {
        Owl,
        Peacock,
        Panther,
        Dolphin,
        Leaf,
        Petal,
        Roots,
        Tree,
        Socials
    },
    data: function () {
        var c = 7;

        return {
            debug: false,
            debugShape: false,
            rawData: JSON.parse(localStorage.getItem(key)),
            categories: c,
            canvasSize: 900,
            maxRadius: 340,
            arn: 0,
            arnrotate: "",
            wiaopacity: 1,
            goalopacity: 1,
            currentcat: 1,
            tour: 1,
            animal: null,
            colour: '#ffffff',
            nature: null,
            layers: [0,0,0,0,0],
            production: false,
            pattern: ['#5398b7','#565b7b','#eb7e83','#d02037','#edb81e'],
            pal: ['#8c4d93', '#a23b3c', '#e47a25', '#f1c05a', '#9acf40', '#128282', '#1b61a6'],
            learner: '',
            cat: ['','','','','',''],
            mixBlendMode: 'soft-light'
        };
    },
    computed: {
        numAxes: function () {
            return 7;
        },
        originX: function () {
            //the origin is the centre of the graph
            return (this.canvasSize / 2) - 20;
        },
        originY: function () {
            return (this.canvasSize / 2) - 20;
        },
        barwidth: function () {
            return 1;
        },
        backgroundColor: function() {
            // return "#ffcc00";
            return this.colour;
        },
        blendMode: function() {
            return "mix-blend-mode: " + this.mixBlendMode;
        },
        graphrotate: function () {
            return "rotate(-130 " + this.originX + " " + this.originY + ")";
        },
        antigraphrotate: function () {
            return "rotate(130 " + this.originX + " " + this.originY + ")";
        },
        allrotate: function () {
            return "rotate(" + this.arn + " " + this.originX + " " + this.originY + ")";
        },
        Axes: function () {
            var myaxes = [];
            for (var i = 0; i < this.numAxes; i++) {
                var ax = [];
                var ax2 = [];
                //the max radius needs to be adjusted for stroke width
                var adjustedMR = ((this.maxRadius / 10) / 2) + this.maxRadius;
                var fromAngle = i * 360 / this.numAxes;
                var toAngle = (i + 1) * 360 / this.numAxes;
                var fromCoordX = this.originX + (adjustedMR * Math.cos(fromAngle * Math.PI / 180));
                var fromCoordY = this.originX + (adjustedMR * Math.sin(fromAngle * Math.PI / 180));
                var toCoordX = this.originX + (adjustedMR * Math.cos(toAngle * Math.PI / 180));
                var toCoordY = this.originY + (adjustedMR * Math.sin(toAngle * Math.PI / 180));
                ax.push(fromCoordX);
                ax.push(fromCoordY);
                ax.push(toCoordX);
                ax.push(toCoordY);
                ax2.push(this.originX);
                ax2.push(this.originY);
                ax2.push(toCoordX);
                ax2.push(toCoordY);
                myaxes.push(ax);
                myaxes.push(ax2);
                // var d = 'M' + this.originX + ',' + this.originX + ' L' + fromCoordX + ',' + fromCoordY + ' A' + this.maxRadius + ',' + this.maxRadius + ' 0 0,1 ' + toCoordX + ',' + toCoordY + 'z';
            }
            return myaxes;
        },
        // viewboxW: function () {
        //     if ('-ms-scroll-limit' in document.documentElement.style && '-ms-ime-align' in document.documentElement.style) {
        //         return 900;
        //     } else {
        //         setTimeout(function () {
        //             var w = 900;
        //             var wW = window.innerWidth;
        //             if (wW < 900) {
        //                 w = wW;
        //             } else if ($('.graph_holder').innerWidth() < 900) {
        //                 w = $('.graph_holder').innerWidth();
        //             }
        //             console.log('wW: ' + wW);
        //             console.log('w: ' + w);
        //             return w;
        //         }, 500);
        //     }
        // }
    },
    methods: {
        engage: function() {
            document.querySelector("html").scrollTop = 0
            router.push('/engage-intro');
        },
        experience: function() {
            document.querySelector("html").scrollTop = 0
            router.push('/experience-intro');
        },
        empower: function() {
            document.querySelector("html").scrollTop = 0
            router.push('/empower-intro');
        },
        debugColorChange(event) {
            let value = event.target.value;
            this.colour = value;
        },
        debugAnimalChange(event) {
            let value = event.target.value;
            this.animal = value;

        },
        debugNatureChange(event) {
            let value = event.target.value
            this.nature = value;
        },
        debugBlendmodeChange(event) {
            let value = event.target.value
            this.mixBlendMode = value;
        },
        returnToMenu: function() {
            router.push('/menu');
        },
        getring: function (ringno) {
            var mypoints = "";
            var firstpoints = "";
            var myradius = (this.maxRadius / 10) * ringno;

            for (var i = 0; i < this.numAxes; i++) {
                var fromAngle = i * 360 / this.numAxes;
                var toAngle = (i + 1) * 360 / this.numAxes;
                var fromCoordX = this.originX + (myradius * Math.cos(fromAngle * Math.PI / 180));
                var fromCoordY = this.originX + (myradius * Math.sin(fromAngle * Math.PI / 180));
                var toCoordX = this.originX + (myradius * Math.cos(toAngle * Math.PI / 180));
                var toCoordY = this.originY + (myradius * Math.sin(toAngle * Math.PI / 180));

                if (i === 0) {
                    firstpoints = fromCoordX + " " + fromCoordY;
                    mypoints = mypoints + fromCoordX + " " + fromCoordY + " " + toCoordX + " " + toCoordY;
                } else {
                    mypoints = mypoints + " " + toCoordX + " " + toCoordY;
                }
                if (i === this.numAxes - 1) {
                    mypoints = mypoints + " " + firstpoints;
                }

            }
            return mypoints;
        },
        getMedian(values) {
            values.sort(function(a,b){
                return a-b;
            });
            var half = Math.floor(values.length / 2);
  
            if (values.length % 2) {
                return values[half];
            }
            else {
                return (values[half - 1] + values[half]) / 2.0;
            }
        },
        getLayer: function (layerNo) {
            var mypoints = "";
            // var firstpoints = "";
            let fakeStart = "";


            for (var i = 0; i < this.layers[layerNo].length; i++) {

                var score = this.layers[layerNo][i];
                var adjustedMR = (this.maxRadius / 10) / 2;
                var myradius = ((this.maxRadius / 10) * score) + adjustedMR;

                var fromAngle = i * 360 / this.numAxes;
                var toAngle = (i + 1) * 360 / this.numAxes;
                var fromCoordX = this.originX + (myradius * Math.cos(fromAngle * Math.PI / 180));
                var fromCoordY = this.originX + (myradius * Math.sin(fromAngle * Math.PI / 180));
                var toCoordX = this.originX + (myradius * Math.cos(toAngle * Math.PI / 180));
                var toCoordY = this.originY + (myradius * Math.sin(toAngle * Math.PI / 180));

                
                if (i === 0) {
                    // firstpoints = fromCoordX + "," + fromCoordY;
                    fakeStart = this.getMedian([fromCoordX, toCoordX]) + "," + this.getMedian([fromCoordY, toCoordY])
                    mypoints = fakeStart + " L " + toCoordX + "," + toCoordY;
                } else {
                    mypoints = mypoints + " L " + toCoordX + "," + toCoordY;
                }
                
                if (i === this.numAxes - 1) {
                    mypoints = mypoints + " L " + fakeStart;
                }

            }

            let path = "M " + mypoints + " z";
            let roundedPath = roundPathCorners(path, 30, false);

            return roundedPath;
        },
        getgoal: function () {
            var mypoints = "";
            var firstpoints = "";

            for (var i = 0; i < this.categories.length; i++) {

                var score = this.categories[i].goal;
                var adjustedMR = (this.maxRadius / 10) / 2;
                var myradius = ((this.maxRadius / 10) * score) + adjustedMR;

                var fromAngle = i * 360 / this.numAxes;
                var toAngle = (i + 1) * 360 / this.numAxes;
                var fromCoordX = this.originX + (myradius * Math.cos(fromAngle * Math.PI / 180));
                var fromCoordY = this.originX + (myradius * Math.sin(fromAngle * Math.PI / 180));
                var toCoordX = this.originX + (myradius * Math.cos(toAngle * Math.PI / 180));
                var toCoordY = this.originY + (myradius * Math.sin(toAngle * Math.PI / 180));

                if (i === 0) {
                    firstpoints = fromCoordX + " " + fromCoordY;
                    mypoints = mypoints + fromCoordX + " " + fromCoordY + " " + toCoordX + " " + toCoordY;
                } else {
                    mypoints = mypoints + " " + toCoordX + " " + toCoordY;
                }
                if (i === this.numAxes - 1) {
                    mypoints = mypoints + " " + firstpoints;
                }

            }
            return mypoints;
        },
        colText: function () {
            var myCaptions = [1, 2, 3, 4,5,6,7];

            for (var i = 0; i < this.categories.length; i++) {
                var caption = [];
                var line1 = "Some";
                var line2 = "Text";
                var adjustedMR = (this.maxRadius / 10);
                var myradius = this.maxRadius + adjustedMR + adjustedMR;

                var fromAngle = i * 360 / this.numAxes;
                var toAngle = (i + 1) * 360 / this.numAxes;
                // var fromCoordX = this.originX + (myradius * Math.cos(fromAngle * Math.PI / 180));
                // var fromCoordY = this.originX + (myradius * Math.sin(fromAngle * Math.PI / 180));
                var toCoordX = this.originX + (myradius * Math.cos(toAngle * Math.PI / 180));
                var toCoordY = this.originY + (myradius * Math.sin(toAngle * Math.PI / 180));

                caption.push(toCoordX);
                caption.push(toCoordY);
                caption.push(line1);
                caption.push(line2);
                fromAngle = fromAngle + 130;
                //console.log("rotate(" + fromAngle + " " + toCoordX + " " + toCoordY + ")");
                caption.push("rotate(" + fromAngle + " " + toCoordX + " " + toCoordY + ")");

                myCaptions.push(caption);
            }
            return myCaptions;
        },
        createRandomData: function() {


            let data = new Array(5);
            for (var i = 0; i < data.length; i++) {
                data[i] = new Array(7);
                for (var n = 0; n < data[i].length; n++) {
                    data[i][n] = Math.floor(Math.random() * 10);
                }
            }
            this.layers = data;


        },
        resetPointScores: function() {

            // we need to run this to stop scores stacking from previous submissions
            for(let i = 0; i < animalsTable.length; i++) {
                animalsTable[i].points = 0;
            }
            for(let i = 0; i < natureTable.length; i++) {
                natureTable[i].points = 0;
            }
            for(let i = 0; i < coloursTable.length; i++) {
                coloursTable[i].points = 0;
            }
            for(let i = 0; i < typeTable.length; i++) {
                typeTable[i].points = 0;
            }
            for(let i = 0; i < categoriesTable.length; i++) {
                categoriesTable[i].points = 0;
            }
        },
        processData() { 

            function* chunks(arr, n) {
                for (let i = 0; i < arr.length; i += n) {
                    yield arr.slice(i, i + n);
                }
            }


            let chunkPre = ([...chunks(this.rawData, 7)]),
                chunked = JSON.parse(JSON.stringify(chunkPre));

            let data = new Array(5);
            for (var i = 0; i < chunked.length; i++) {
                data[i] = new Array(chunked[i].length);
                for (var n = 0; n < chunked[i].length; n++) {
                    // data[i][n] = 0;
                    for (var a = 0; a < chunked[i][n].answers.length; a++) {
                        if (chunked[i][n].answers[a].selected === true) {
                            data[i][n] = a;

                            //This is the selected answer, start calculating points for animals/colours
                            var ans = chunked[i][n].answers[a];

                            animalsTable[parseInt(ans.animal) - 1].points++;
                            natureTable[parseInt(ans.flower) - 1].points++;
                            coloursTable[parseInt(ans.color) - 1].points++;
                            typeTable[parseInt(ans.type) - 1].points++;
                            categoriesTable[parseInt(ans.category) - 1].points++;
                            
                        }
                    }
                }
            }


            let animalsArr = animalsTable.map(function(i){
                return i.points;
            });

            this.animal = animalsTable[animalsArr.indexOf(Math.max(...animalsArr))].name;

            // $("#" + animalsTable[animalsArr.indexOf(Math.max(...animalsArr))].name).show();
            
            let natureArr = natureTable.map(function(i){
                return i.points;
            });
            this.nature = natureTable[natureArr.indexOf(Math.max(...natureArr))].name;

            // $("#" + natureTable[natureArr.indexOf(Math.max(...natureArr))].name).show();
            
            let coloursArr = coloursTable.map(function(i){
                return i.points;
            });
            
            this.colour = coloursTable[coloursArr.indexOf(Math.max(...coloursArr))].name

            let typeArr = typeTable.map(function(i){
                return i.points;
            });

            let catTable1 = [], 
                catTable2 = [], 
                catTable3 = [], 
                catTable4 = [], 
                catTable6 = [], 
                catTable7 = [];

            for (let i = 0; i < categoriesTable.length; i++) {
                const cat = categoriesTable[i];

                if (cat.group !== undefined) {

                    switch (cat.group) {
                        case 1: 
                            catTable1.push(cat)
                            break;
                        case 2: 
                            catTable2.push(cat)
                            break;
                        case 3: 
                            catTable3.push(cat)
                            break;
                        case 4: 
                            catTable4.push(cat)
                            break;
                        case 6: 
                            catTable6.push(cat)
                            break;
                        case 7: 
                            catTable7.push(cat)
                            break;
                    }
                }                
            }

            let catArr1 = catTable1.map(function(i){
                return i.points;
            });
            let catArr2 = catTable2.map(function(i){
                return i.points;
            });
            let catArr3 = catTable3.map(function(i){
                return i.points;
            });
            let catArr4 = catTable4.map(function(i){
                return i.points;
            });
            let catArr6 = catTable6.map(function(i){
                return i.points;
            });
            let catArr7 = catTable7.map(function(i){
                return i.points;
            });
            this.cat[0] = catTable1[catArr1.indexOf(Math.max(...catArr1))].name
            this.cat[1] = catTable2[catArr2.indexOf(Math.max(...catArr2))].name
            this.cat[2] = catTable3[catArr3.indexOf(Math.max(...catArr3))].name
            this.cat[3] = catTable4[catArr4.indexOf(Math.max(...catArr4))].name
            this.cat[4] = catTable6[catArr6.indexOf(Math.max(...catArr6))].name
            this.cat[5] = catTable7[catArr7.indexOf(Math.max(...catArr7))].name

            this.learner = typeTable[typeArr.indexOf(Math.max(...typeArr))].name

            console.groupCollapsed("Your results");
            console.log(data)
            console.log("You are " + this.learner)
            console.log("You are " + this.cat[0])
            console.log("You are " + this.cat[1])
            console.log("You are " + this.cat[2])
            console.log("You are " + this.cat[3])
            console.log("You are " + this.cat[4])
            console.log("You are " + this.cat[5])
            console.log("You are " + animalsTable[animalsArr.indexOf(Math.max(...animalsArr))].name)
            console.log("You are " + natureTable[natureArr.indexOf(Math.max(...natureArr))].name)
            console.log("You are " + coloursTable[coloursArr.indexOf(Math.max(...coloursArr))].name)
            console.groupEnd();

            this.layers = data;

            this.resetPointScores();
            
        },
        goToQuestions() {
            router.push('/quiz');
        },

    },
    mounted: function () {
        
        //this.createRandomData();
        // this.processData();

        try {
            this.processData();
        } catch (e) {
            router.push('/quiz');
            console.log("Insufficient data for artwork.");
        }
        
    }
};
</script>

<style lang="scss" scoped>
    .artwork-debug {
        position: fixed;
        top: 100px;
        left: 20px;
        & p {
            margin-bottom: 5px;
        }
        & > div {
            margin-bottom: 10px;
        }
    }
    @media (min-width: 992px) {
        .container {
            max-width: 960px;
        }
    }
    .grad-red {
        background-image: linear-gradient(150deg, #953e3d 40%, #7f5290 100%)
    }
    .grad-green {
        background-image: linear-gradient(150deg, #447b80 10%, #a5ca59 80%)
    }
    .grad-yellow {
        background-image: linear-gradient(150deg, #cc6633 10%, #dcc16f 70%)
    }
    .grad-blue {
        background-image: linear-gradient(150deg, #339966 0%, #426a9c 60%)
    }

    .art-type {         
        color: #fff;
        font-weight: 400;
        width: 600px;
        padding: 40px;
        margin-top: -60px;

        @media (max-width: 992px) {
            width: 100%;
        }

        & h1, & h2 {            
            font-family: "baskerville-display-pt", serif;
        }
        & h1 {            
            font-size: 24px;
            font-style: italic;
        }
        & h2 {            
            font-size: 16px;
            font-style: normal;
            max-width: 50%;
            @media (max-width: 992px) {
                max-width: 100%;
            }
        } 
        & p {            
            font-size: 12px;
            font-family: "Roboto", sans-serif;
            font-style: normal;
        }
        & hr {
            max-width: 50%;
            height: 2px;
            opacity: 1;
            background-color: white;
            @media (max-width: 992px) {
                max-width: 100%;
            }
        }
    }
    .artwork {        
        justify-content: center;
        display: inline-flex;
        flex-direction: column;
        align-items: flex-start;
        position: relative;
        margin-top: 60px;
        box-shadow: 5px 5px 10px 0 rgba(0,0,0,0.4);
        

        &__logo {
            bottom: 30px;
            left: 30px;
            position: absolute;
            display: none;
        }
        & > svg {                
            @media (max-width: 768px) {
                width: 100vw;
                height: 100vw;
            }
        }
    }
    .artwrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 60px;
        @media (max-width: 992px) {
            width: 100%;
        }
        & svg#artwork {
            @media (max-width: 576px) {
                width: 90vw;
                height: 90vw;
            }
        }
    }
    .charts {
        display: flex;
        flex-direction: column;
        align-items: center;
        min-height: 100vh;
        padding-bottom: 80px;
        @media (max-width: 576px) {
            padding: 0 20px 0;
        }
    }
    .cls-1 {
        fill: #a4ac84;
    }

    .cls-2 {
        font-size: 48px;
    }

    .cls-13, .cls-19, .cls-2, .cls-6 {
        fill: #fff;
    }

    .cls-13, .cls-2, .cls-6 {
        font-family: Helvetica;
    }

    .cls-3 {
        letter-spacing: -0.01em;
    }

    .cls-6 {
        font-size: 24px;
    }

    .cls-13 {
        font-size: 12px;
    }



    .cls-19 {
        font-size: 10px;
        font-family: Helvetica-Bold, Helvetica Bold;
        font-weight: 700;
    }

    .cls-26 {
        fill: none;
        stroke: #fff;
        stroke-miterlimit: 10;
        stroke-width: 6px;
    }

    .shape {
        mix-blend-mode: hard-light;
        opacity:1;
        // opacity: .5
    }
    .heading {        
        font-size: 48px;
        font-family: 'baskerville-display-pt', serif; 
        font-weight: 400;
        font-style: italic;
        fill: #fff;
    }
    .subheading {        
        font-size: 24px;
        font-family: 'baskerville-display-pt', serif;
        font-weight: 400;
        font-style: normal;
        fill: #fff;
    }
    .bodycopy {
        font-size: 12px;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-style: normal;
        fill: #fff;
    }

    .label {
        float: left;
        text-align: right;
        font-family: 'Helvetica';
        color: white;
        font-weight: bold;
        padding: 15px;
    }

    .slider {
        float: right;
    }

    .slider_container {
        clear: both;
        width: 100%;
    }
    .animal-outline {
        fill: rgb(0, 0, 0, .2);
        transform-origin: center;
        // display: none;
        mix-blend-mode: overlay;
    }
    .nature-outline {
        transform-origin: center;
        // display: none;
        fill: none;
        stroke: rgb(0, 0, 0, .4);
        // mix-blend-mode: overlay;
    }
    #dolphin {
        transform: translateY(80px) scale(0.95);
    }
    #panther {
        transform: translate(50px, 90px) scale(1);
    }
    #owl {
        transform: translate(100px, 110px) scale(1);
    }
    #peacock {
        transform: translate(40px, 40px) scale(0.9);
    }
    .menu-btn {
        margin-top: 40px;
        cursor: pointer;
        &:hover {
            transform: scale(0.97);
        }
    }
    .eMenu {
        margin-bottom: 80px;
        @media (max-width: 768px) {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        &__item {
            cursor: pointer;
            margin: 0px 5px;
            transition: all .02s ease-out;
            cursor: pointer;
            height: 20px;
            &:hover {
                transform: scale(0.97);
            }
            @media (max-width: 768px) {
                margin-bottom: 40px
            }
        
        }
        &__split {
            margin: 0px 5px;
            height: 30px;
            @media (max-width: 768px) {
                display: none;
            }
        }
    }
</style>

