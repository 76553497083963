<template>
    <div class="research-wrap" :style="{'background-color': background}">
        
        <!-- <section class="wipe" :data-bg="colors.yellow" :data-accent="colors.red">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col" data-anim="fader">
                        <h1 class="clip-red">
                            <span :style="{'color': colors.yellow}">
                                We are known for making visually striking work that is vibrant and engaging.
                            </span>
                        </h1>
                        <p :style="{'color': colors.red}">
                            This can lead to the misconception that aesthetics are our main focus.
                        </p>
                        <p :style="{'color': colors.red}">
                            Despite our love of all things beautiful, we are firm believers in doing our research in order to create effective learning with real substance.
                        </p>
                        <p :style="{'color': colors.red}">
                            Here are some of our recent findings...
                        </p>
                    </div>
                </div>
            </div>
        </section> -->

        <section class="wipe" data-scroll-time="0.1" :data-bg="colors.green" :data-accent="colors.yellow">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col" data-anim="fader">
                        <h1 class="clip-yellow">
                            <span :style="{'color': colors.green}">
                                "Learning is important and worth doing well"
                            </span>
                        </h1>
                        <p :style="{'color': colors.yellow}" data-anim="typer">Digital learning, in particular, is proven to be effective, and well-matched with how employees prefer to learn.</p>
                    </div>
                </div>
            </div>
        </section>

        <section class="wipe" data-scroll-time="6" :data-bg="colors.green" :data-accent="colors.yellow" :data-animation="0">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col" data-anim="fader">
                        <!-- <p :style="{'color': colors.yellow}">
                            Digital learning contributes:
                        </p> -->
                        <div class="lottie" id="arrows"></div>
                    </div>
                </div>
            </div>
        </section>

        <section class="wipe" :data-bg="colors.pink" :data-accent="colors.purple">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col" data-anim="fader">
                        <h1 class="clip-purple">
                            <span :style="{'color': colors.pink}">
                                For HR professionals themselves it's also the most popular form of learning
                            </span>
                        </h1>
                    </div>
                </div>
            </div>
        </section>

        <section class="wipe" data-scroll-time="6" :data-bg="colors.pink" :data-accent="colors.purple" :data-animation="1">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col text-center" data-anim="fader">
                        <p :style="{'color': colors.purple}">
                            How are people professionals developing themselves?
                        </p>
                        <div class="lottie" id="people"></div>
                    </div>
                </div>
            </div>
        </section>

        <section class="wipe" :data-bg="colors.teal" :data-accent="colors.purple">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col" data-anim="fader">
                        <h1 class="clip-purple">
                            <span :style="{'color': colors.teal}">
                                But not all e-learning is equal!
                            </span>
                        </h1>
                        <p :style="{'color': colors.purple}">When asked, learners say the two biggest challenges to learning online are:</p>
                        <p :style="{'color': colors.purple}">"Lack of time" & "Uninspiring content".</p>
                    </div>
                </div>
            </div>
        </section>

        <section class="wipe" data-scroll-time="4" :data-bg="colors.teal" :data-accent="colors.purple" :data-animation="2">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col text-center" data-anim="fader">
                        <p :style="{'color': colors.purple}">What are your biggest challenges to learning online?</p>
                        <div class="lottie" id="discs"></div>
                    </div>
                </div>
            </div>
        </section>

        <!-- <section class="wipe" :data-bg="colors.yellow" :data-accent="colors.red">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col" data-anim="fader">
                        <h1 class="clip-red">
                            <span :style="{'color': colors.yellow}">
                                L&D professionals list those same barriers in their "top 5" concerns:
                            </span>
                        </h1>
                    </div>
                </div>
            </div>
        </section> -->

        <section class="wipe" data-scroll-time="2" :data-bg="colors.yellow" :data-accent="colors.red" :data-animation="3">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col" data-anim="fader">
                        <h1 class="clip-red">
                            <span :style="{'color': colors.yellow}">
                                L&D professionals list those same barriers in their "top 5" concerns:
                            </span>
                        </h1>
                    </div>
                </div>
                <div class="row align-items-center">
                    <div class="col text-center" data-anim="fader">
                        <p :style="{'color': colors.red}">What is L&D extremely concerned about?</p>
                        <div class="lottie" id="bargraphs"></div>
                    </div>
                </div>
            </div>
        </section>
        
        <section class="wipe" :data-bg="colors.green" :data-accent="colors.yellow">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col" data-anim="fader">
                        <h1 class="clip-yellow">
                            <span :style="{'color': colors.green}">
                                Research suggests that 'motivation' is the factor that has the biggest impact on employee learning.
                            </span>
                        </h1>
                        <p :style="{'color': colors.yellow}">So make sure you use compelling 'headlines' and effective targeting to motivate learners to engage with your content.</p>
                        <!-- <p :style="{'color': colors.yellow}">Here are the key reasons that learners themselves give as their top motivations for learning online:</p> -->
                    </div>
                </div>
            </div>
        </section>
        
        <section class="wipe" data-scroll-time="4" :data-bg="colors.green" :data-accent="colors.yellow" :data-animation="4">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col text-center" data-anim="fader">
                        <p :style="{'color': colors.yellow}">Here are the key reasons that learners themselves give as their top motivations for learning online:</p>
                        <div class="lottie" id="workers"></div>
                    </div>
                </div>
            </div>
        </section>

        <section class="wipe" :data-bg="colors.pink" :data-accent="colors.purple">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col" data-anim="fader">
                        <h1 class="clip-purple">
                            <span :style="{'color': colors.pink}">
                                Then, having captured learners' attention, what are the 'top tips' for making your content engaging?
                            </span>
                        </h1>
                        <p :style="{'color': colors.purple}">It's clear that taking a 'gamified' approach has a significant positive effect</p>
                    </div>
                </div>
            </div>
        </section>


        <section class="wipe" data-scroll-time="4" :data-bg="colors.pink" :data-accent="colors.purple" :data-animation="5">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col" data-anim="fader">
                        <div class="lottie" id="bubbles"></div>
                    </div>
                </div>
            </div>
        </section>

        <section class="wipe" :data-bg="colors.pink" :data-accent="colors.purple">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col text-center" data-anim="fader">
                        <p :style="{'color': colors.purple}">... and whatever your definition of 'gamified', the key is that learners are 'active' and not 'passive'.</p>
                        <p :style="{'color': colors.purple}">Having regular opportunities to demonstrate their understanding and, ideally to APPLY the knowledge makes the experience not just more motivating, but more effective.</p>
                    </div>
                </div>
            </div>
        </section>

        <section class="wipe" data-scroll-time="6" :data-bg="colors.pink" :data-accent="colors.purple" :data-animation="6">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col text-center" data-anim="fader">
                        <p :style="{'color': colors.purple}">Engaging with content helps people retain and apply information.</p>
                        <p :style="{'color': colors.purple}">Score on a test out of 100 after people had:</p>
                        <div class="lottie" id="watch"></div>
                    </div>
                </div>
            </div>
        </section>

        <section class="wipe" :data-bg="colors.teal" :data-accent="colors.purple">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col" data-anim="fader">
                        <h1 class="clip-purple">
                            <span :style="{'color': colors.teal}">
                                And make sure the experience tells a story
                            </span>
                        </h1>
                        <p :style="{'color': colors.purple}">It's the way we humans have learned and passed on information for thousands of years, so it's a highly effective way to put content in a format that our brains will easily absorb and retain.</p>
                    </div>
                </div>
            </div>
        </section>

        <section class="wipe" data-scroll-time="2" :data-bg="colors.teal" :data-accent="colors.purple" :data-animation="7">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col text-center" data-anim="fader">
                        <p :style="{'color': colors.purple}">In his book 'Actual Minds, Possible Worlds', psychologist Jerome Bruner estimates that facts are approximately 22 times more likely to be remembered if they are part of a story.</p>
                        <div class="lottie" id="books"></div>
                    </div>
                </div>
            </div>
        </section>

        <section class="wipe" data-scroll-time="2" :data-bg="colors.teal" :data-accent="colors.purple" :data-animation="8">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col text-center" data-anim="fader">
                        <p :style="{'color': colors.purple}">Organisational psychologist Peg Neuhasuer found... that learning derived from a well-told story is remembered more accurately, and for far longer than the learning derived from facts or figures.</p>
                        <div class="lottie" id="peg"></div>
                    </div>
                </div>
            </div>
        </section>

        <section class="wipe" :data-bg="colors.yellow" :data-accent="colors.red">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col" data-anim="fader">
                        <h1 class="clip-red">
                            <span :style="{'color': colors.yellow}">
                                And it turns out there's more science behind our ability to retain knowledge!
                            </span>
                        </h1>
                    </div>
                </div>
            </div>
        </section>

        <section class="wipe" data-scroll-time="2" :data-bg="colors.yellow" :data-accent="colors.red" :data-animation="9">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col" data-anim="fader">
                        <p :style="{'color': colors.red}">The first image: Ebbinghaus' 'Forgetting Curve', shows a learner's rentention of learning in the time following its delivery.</p>
                        <div class="lottie" id="forgetting"></div>
                    </div>
                </div>
            </div>
        </section>

        <section class="wipe" data-scroll-time="2" :data-bg="colors.yellow" :data-accent="colors.red" :data-animation="10">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col" data-anim="fader">
                        <p :style="{'color': colors.red}">The second image shows a percentage of retention when the learning is repeated in smaller, more managable chunks.</p>
                        <div class="lottie" id="retention"></div>
                    </div>
                </div>
            </div>
        </section>

        <section class="wipe" data-scroll-time="0.5" :data-bg="colors.yellow" :data-accent="colors.red">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col" data-anim="fader">
                        <p :style="{'color': colors.red}">This reinforces the need for micro-learning and a 'little and often' approach to e-learning. Providing learners with a large chunk of information on a single occasion is likely to be easily forgotten and therefore, ineffective.</p>
                    </div>
                </div>
            </div>
        </section>

        
        <svg :class="{ 'hidden' : notFirstSlide}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.87 71.2" class="scroll-prompt">
            <g>
                <path :style="{'fill': progressColor}" class="scroll-prompt__arrow" d="M84.11,53.88,67.75,70.25a3.29,3.29,0,0,1-4.63,0L46.76,53.88a3.27,3.27,0,0,1,4.63-4.62L62.16,60V28.66a3.28,3.28,0,0,1,6.55,0V60L79.49,49.26a3.27,3.27,0,0,1,4.62,4.62Z"/>
                <!-- <rect :style="{'fill': progressColor}" width="130.87" height="38.89" rx="19.45"/>
                <text :style="{'fill': background}" style="font-size:32px;font-family:FuturaPT-Demi, Futura PT;font-weight:300;" transform="translate(11.61 30.02)">SCROLL</text> -->
            </g>
        </svg>

        <div class="scroll-progress" :style="{'width': percent + '%', 'background-color': progressColor}"></div>
    </div>
    
</template>

<script>

import $ from 'jquery';
import lottie from 'lottie-web';

// import JSON animation data
import * as animArrowsData from '../animations/arrows.json';
import * as animPeopleData from '../animations/people.json';
import * as animDiscsData from '../animations/discs.json';
import * as animBarGraphsData from '../animations/bargraphs.json';
import * as animWorkersData from '../animations/workers.json';
import * as animBubblesData from '../animations/bubbles.json';
import * as animWatchData from '../animations/watch.json';
import * as animBooksData from '../animations/books.json';
import * as animPegData from '../animations/peg.json';
import * as animForgettingData from '../animations/forgettingcurve.json';
import * as animRetentionData from '../animations/retentiongraph.json';

export default {
    name: 'Research',

    data: function() {
        return {
            animations: [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
            ],
            currentSlide: 0,
            background: '#dfb756',
            colors: {
                yellow: '#dfb756',
                red: '#e2431e',
                green: '#7f9453',
                pink: '#efadc9',
                purple: '#695391',
                teal: '#76aab7'
            },
            notFirstSlide: false,
            percent: 0,
            progressColor: '#e2431e',
            textTypeoutArray: null
        }
    },
    methods: {
        
        onScroll: function() {
            this.fadeInScroll(.9);
            this.pinning();
        },
        fadeInScroll: function(anchor) {

            // This controls the rows fading and floating up as they scroll into view

            let scrollPos = $("html").scrollTop();
            let wH = window.innerHeight;
            let trigger = (wH * anchor) + scrollPos;
            $("[data-anim='fader']").each(function(){
                let pos = $(this).offset().top;
                if (pos < trigger) {
                    // Fade In
                    $(this).css({
                        "opacity": 1,
                        "transform": "translate(0px, 0px)"
                    });
                } else {
                    // Fade out
                    $(this).css({
                        "opacity": 0,
                        "transform": "translate(0px, 120px)"
                    });
                }
            });           

        },
        pinning: function() {        
            let scrollPos = $("html").scrollTop();
            let slideCount = $(".wipe").length;
            let wH = window.innerHeight;

            let height = 0;

            if (slideCount === 0) return false;


            // calculate size of body from scroll lengths of each wipe slide
            $(".wipe").each(function(){
                $(this).attr("data-start-val", (height / 100) * wH);
                let scrollTime = $(this).attr("data-scroll-time");

                if (scrollTime === undefined) {
                    scrollTime = 1;
                } else {
                    scrollTime = Number(scrollTime);
                }
                
                height += scrollTime * 100;
            });

            // set height
            $(".research-wrap").css("height", height + 100 + "vh");

            let roundedScroll = null;

            // user the current scroll position to find the number of the slide that should currently be visible
            for (let i = 1; i < (slideCount + 1); i++) {
                let offset = $(".wipe:nth-child("+i+")").attr("data-start-val");
                
                if (offset <= scrollPos) {
                    roundedScroll = i;
                }
            }

            // calculate percentage
            let percent = null;

            let currentSlideHeight = wH;
            let scrollTime = $(".wipe:nth-child("+roundedScroll+")").attr("data-scroll-time");
            let scrollOffset = $(".wipe:nth-child("+roundedScroll+")").attr("data-start-val");
            scrollPos = scrollPos - scrollOffset;

            if (scrollTime !== undefined) {
                currentSlideHeight = scrollTime * wH;
            }

            let start = 0;
            let end = start + currentSlideHeight;
            percent = ((scrollPos / end)  * 100);

            // set new slide variables

            let newPin = $(".wipe:nth-child("+roundedScroll+")"),
                newAnimation = newPin.data("animation"); 
                
            this.animation = newAnimation;
            this.checkAnimation(percent);

            if (roundedScroll === this.currentSlide) return;
            this.setPin(roundedScroll);
        },
        checkAnimation:function(percent) {

            // Use the percentage scrolled through the current slide to calculate the frame the animation should render

            this.percent = percent;

            if (this.textTypeoutArray !== null) {
                // this.typeoutText(percent);
            }

            if (this.animations[this.animation] === undefined) return false;

            let frameCount = this.animations[this.animation].getDuration(true);
            let frameToPlay = frameCount * (percent / 100);

            if (frameToPlay === frameCount) frameToPlay = frameCount - 1;

            this.animations[this.animation].goToAndStop(frameToPlay, true);
            
        },
        setPin: function(n){
            const comp = this;

            // let count = $(".wipe").length;

            this.notFirstSlide = n !== 1;

            // pin
            let newPin = $(".wipe:nth-child("+n+")"),
                newColor = newPin.data("bg"),
                newProgressColor = newPin.data("accent");

            comp.progressColor = newProgressColor;
                
            if (n < this.currentSlide) {    
                // hide all slides
                $(".wipe").not(newPin).removeClass("animateIn animateOut");
                $(newPin).addClass("animateOut");                

                setTimeout(function(){
                    
                    // set background color
                    comp.background = newColor;
                    document.querySelector('meta[name="theme-color"]').setAttribute('content',  newColor);

                    // show new slide
                    $(newPin).addClass("animateIn");
                    $(newPin).removeClass("animateOut");
                    
                    comp.currentSlide = n;

                    // comp.prepTypers(n);

                    $(".wipe").not($(".wipe:nth-child("+n+")")).removeClass("animateIn animateOut");
                    
                }, 500)
            } else {                    
                // hide all slides
                $(".wipe").not(newPin).addClass("animateOut");
                $(newPin).removeClass("animateOut");
                
                
                setTimeout(function(){
                    
                    // set background color
                    comp.background = newColor;
                    document.querySelector('meta[name="theme-color"]').setAttribute('content',  newColor);

                    // reset state
                    $(".wipe").not(newPin).removeClass("animateIn");

                    // show new slide
                    $(newPin).addClass("animateIn");
                    
                    comp.currentSlide = n;

                    // comp.prepTypers(n);

                    $(".wipe").not($(".wipe:nth-child("+n+")")).removeClass("animateIn animateOut");
                    
                }, 500)
            }

        },
        prepTypers: function(n){
            const comp = this;

            const currentSlide = $(".wipe:nth-child("+n+")")

            if (currentSlide.find("[data-anim='typer']").length === 0) {
                this.textTypeoutArray = null;
                return false;
            }
            
            currentSlide.find("[data-anim='typer']").each(function(){
                let $t = $(this),
                    text = $t.text();

                
                if ($(this).attr("data-text") === undefined) {
                    $t.attr("data-text", text);
                }

                comp.textTypeoutArray = $t.attr("data-text").split("");
                
                $t.text("");

                // comp.typeoutText();
            });
            
        },
        typeoutText: function() {
            let arr = this.textTypeoutArray,
                length = arr.length,
                charactersToDisplay = Math.round(length * ((this.percent * 2)/100));

            let textToShow = arr.slice(0,charactersToDisplay).join('');

            const currentSlide = $(".wipe:nth-child("+this.currentSlide+")");
            currentSlide.find("[data-anim='typer']").text(textToShow);


        }
    },
    mounted: function() {

        // this.prepTypers(1);

        //arrows animation
        const animArrows = document.getElementById("arrows");
        
        this.animations[0] = lottie.loadAnimation({
            container: animArrows, // the dom element that will contain the animation
            renderer: 'svg',
            loop: false,
            autoplay: false,
            animationData: animArrowsData.default // the path to the animation json
        });

        //laptop animation
        const animPeople = document.getElementById("people");
        
        this.animations[1] = lottie.loadAnimation({
            container: animPeople, // the dom element that will contain the animation
            renderer: 'svg',
            loop: false,
            autoplay: false,
            animationData: animPeopleData.default // the path to the animation json
        });

        //discs animation
        const animDiscs = document.getElementById("discs");
        
        this.animations[2] = lottie.loadAnimation({
            container: animDiscs, // the dom element that will contain the animation
            renderer: 'svg',
            loop: false,
            autoplay: false,
            animationData: animDiscsData.default // the path to the animation json
        });

        //bargraphs animation
        const animBarGraphs = document.getElementById("bargraphs");
        
        this.animations[3] = lottie.loadAnimation({
            container: animBarGraphs, // the dom element that will contain the animation
            renderer: 'svg',
            loop: false,
            autoplay: false,
            animationData: animBarGraphsData.default // the path to the animation json
        });

        //workers animation
        const animWorkers = document.getElementById("workers");
        
        this.animations[4] = lottie.loadAnimation({
            container: animWorkers, // the dom element that will contain the animation
            renderer: 'svg',
            loop: false,
            autoplay: false,
            animationData: animWorkersData.default // the path to the animation json
        });

        //bubbles animation
        const animBubbles = document.getElementById("bubbles");
        
        this.animations[5] = lottie.loadAnimation({
            container: animBubbles, // the dom element that will contain the animation
            renderer: 'svg',
            loop: false,
            autoplay: false,
            animationData: animBubblesData.default // the path to the animation json
        });

        //books animation
        const animBooks = document.getElementById("books");
        
        this.animations[7] = lottie.loadAnimation({
            container: animBooks, // the dom element that will contain the animation
            renderer: 'svg',
            loop: false,
            autoplay: false,
            animationData: animBooksData.default // the path to the animation json
        });

        //watch animation
        const animWatch = document.getElementById("watch");
        
        this.animations[6] = lottie.loadAnimation({
            container: animWatch, // the dom element that will contain the animation
            renderer: 'svg',
            loop: false,
            autoplay: false,
            animationData: animWatchData.default // the path to the animation json
        });

        //peg animation
        const animPeg = document.getElementById("peg");
        
        this.animations[8] = lottie.loadAnimation({
            container: animPeg, // the dom element that will contain the animation
            renderer: 'svg',
            loop: false,
            autoplay: false,
            animationData: animPegData.default // the path to the animation json
        });

        //forgetting animation
        const animForgetting = document.getElementById("forgetting");
        
        this.animations[9] = lottie.loadAnimation({
            container: animForgetting, // the dom element that will contain the animation
            renderer: 'svg',
            loop: false,
            autoplay: false,
            animationData: animForgettingData.default // the path to the animation json
        });

        //retention animation
        const animRetention = document.getElementById("retention");
        
        this.animations[10] = lottie.loadAnimation({
            container: animRetention, // the dom element that will contain the animation
            renderer: 'svg',
            loop: false,
            autoplay: false,
            animationData: animRetentionData.default // the path to the animation json
        });


        // add parallaxing 
        document.addEventListener('scroll', this.onScroll);
        let comp = this;
        setTimeout(function(){
            comp.pinning();
            comp.fadeInScroll(1.1);
        }, 500);

        

    }
}
</script>

<style lang="scss" scoped>

    /* 
        animations
    */
    [data-anim='fader'] {        
        opacity: 0; 
        transform: translate(0px, 120px);
        // transition: all .5s ease-out;
        transition: all 1s cubic-bezier(0, 0, 0, 1);
    }

    .research-wrap {
        height: 300vh;
        transition: all 1s cubic-bezier(0, 0, 0, 1);
    }

    .wipe {
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        width:100%;
        left: 0;
        position: fixed;
        opacity: 0;
        transform: translate(0px, 120px);
        transition: all .5s cubic-bezier(0, 0, 0, 1);

        &.animateIn {
            opacity: 1;
            transform: translate(0px, 0px);
            z-index: 10;
        }
        &.animateOut {
            opacity: 0;
            transform: translate(0px, -120px);
        }
    }
    h1 {        
        font-family: futura-pt;
        font-weight: 700;
        line-height: 1.4;
        font-size: 32px;
        margin-bottom: 30px;
        &.clip-red {
            & span {                
                background-color: #e2431e;
                box-shadow: -10px 0px 0 5px #e2431e,
                    10px 0px 0 5px #e2431e,
                    0 0 0 5px #e2431e;
                box-decoration-break: clone;
            }
        }
        &.clip-purple {
            & span {                
                background-color: #695391;
                box-shadow: -10px 0px 0 5px #695391,
                    10px 0px 0 5px #695391,
                    0 0 0 5px #695391;
                box-decoration-break: clone;
            }
        }
        &.clip-yellow {
            & span {                
                background-color: #dfb756;
                box-shadow: -10px 0px 0 5px #dfb756,
                    10px 0px 0 5px #dfb756,
                    0 0 0 5px #dfb756;
                box-decoration-break: clone;
            }
        }
    }
    p {
        font-family: futura-pt;
        font-weight: 600;
        line-height: 1.4;
        font-size: 24px;
        margin-bottom: 20px;
    }

    .scroll-progress {        
        position: fixed;
        height: 10px;
        bottom: 0;
        transition: all 1s cubic-bezier(0, 0, 0, 1);
    }
    .scroll-prompt {        
        position: fixed;
        width: 70px;
        bottom: 30px;
        left: 50%;
        transform: translateX(-50%);
        transition: all 1s cubic-bezier(0, 0, 0, 1);
    overflow: visible;
        &.hidden {
            opacity: 0;
        }
        &__arrow {
            animation: bounce 1s infinite alternate-reverse ease-in-out;
        }
    }
    @keyframes bounce {
        0% {transform: translateY(0px);}
        100% {transform: translateY(10px);}
    }
    .lottie {
        position: relative;
        transform: scale(1.3);
        
        @media (max-width: 991px) {
            transform: scale(1.4);
        }

        &#workers {
            @media (max-width: 991px) {
                width: 140%;
                left: 50%;
                transform: translateX(-50%) scale(1);
                margin: 0vw 0;
            }
        }
    }
</style>