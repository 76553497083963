<template>
    <div class="contactFooter">
        <div class="container">
            <h3>Empowered Clients</h3>
            <h4>There is a reason that some of the biggest names in the world choose to work with us... call us to find out more.</h4>
            <div class="clients">
                <img v-for="(item, index) in clientList" :key="index" :src="require('@/images/footer/'+ item+'.svg')" alt="" class="clients__icon">
            </div>
            <div class="hr"></div>
            <div class="weblinks">
                <a href="https://purplemedia.co.uk" target="_blank">
                    <svg class="weblinks__logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 112.88 35.89">
                        <g style="fill: #fff59b">
                            <path d="M53.19,25a3.32,3.32,0,0,1-2.32-5.64l18-18.11a3.29,3.29,0,0,1,4.67,4.63l-18,18.1A3.22,3.22,0,0,1,53.19,25Z"/>
                            <path d="M72.47,24.73a3.27,3.27,0,0,1-2.32-5.6l17.9-17.9a3.28,3.28,0,1,1,4.64,4.64h0L74.8,23.77A3.25,3.25,0,0,1,72.47,24.73Z"/>
                            <path d="M91.7,24.73a3.27,3.27,0,0,1-2.33-5.6l17.9-17.9a3.28,3.28,0,0,1,4.65,4.64h0L94.05,23.77A3.3,3.3,0,0,1,91.7,24.73Z"/>
                            <path d="M33.89,25a3.29,3.29,0,0,1-2.32-5.65L49.74,1.23a3.3,3.3,0,0,1,4.65,0,3.35,3.35,0,0,1,1,2.31,3.26,3.26,0,0,1-1,2.32L36.22,24.05A3.25,3.25,0,0,1,33.89,25Z"/>
                            <path d="M3.61,35.89a3.56,3.56,0,0,1-2.56-6.08L29.87,1a3.58,3.58,0,0,1,5.08,5L6.13,34.85A3.57,3.57,0,0,1,3.61,35.89Z"/>
                        </g>
                    </svg>
                </a>
                

                <div class="weblinks__links">
                    <a href="https://purplemedia.co.uk/#hello" target="_blank" class="weblinks__link">Hello</a>
                    <a href="https://purplemedia.co.uk/#work" target="_blank" class="weblinks__link">Our Work</a>
                    <a href="https://purplemedia.co.uk/#about" target="_blank" class="weblinks__link">Our Manifesto</a>
                    <a href="https://purplemedia.co.uk/#contact" target="_blank" class="weblinks__link">Contact</a>
                </div>
            </div>
            <h5>We don't stand still. We stand out.</h5>
            <p>+44(0)1604 622491</p>
            <a href="mailto:hello@purplemedia.co.uk">hello@purplemedia.co.uk</a>
        </div>
    </div>
</template>

<script>
import clients from '../fixtures/footer';
export default {
    name: 'ContactFooter',
    data() {
        return {
            clientList: clients
        }
    }
}
</script>

<style lang="scss" scoped>
    $bg: #9e3d3d;
    $color: #fff59b;
    .contactFooter {
        padding: 120px 0;
        background: $bg;
        color: $color;
        text-align: center;
        & h3 {
            text-transform: uppercase;
            font-family: futura-pt;
            font-weight: 700;
            font-size: 2rem;
            margin-bottom: 40px;
        }
        & h4 {
            font-weight: 200;
            font-size: 2rem;
            margin-bottom: 40px;
            font-style: italic;
            font-family: Georgia;
        }
        & h5 {
            font-weight: 200;
            font-size: 1.5rem;
            margin-bottom: 40px;
            font-style: italic;
            font-family: Georgia;
        }
        & .hr {
            background: $color;
            position: relative;
            width: calc(100% + 160px);
            max-width: calc(100vw - 20px);
            left: 50%;
            transform: translateX(-50%);
            height: 2px;
            margin: 40px 0 80px;
        }
    }
    .clients {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        &__icon {
            margin: 40px 20px;
            width: 100px;  
            max-height: 40px;      
            @media (max-width: 992px) {
                margin: 40px 10px;
                width: 80px;
            }
            @media (max-width: 768px) {
                margin: 20px 30px;
                width: 80px;
            }
            @media (max-width: 576px) {
                margin: 20px 40px;
                width: 60px;
            }
            @media (max-width: 450px) {
                margin: 20px 30px;
                width: 60px;
            }
        }
    }

    .weblinks {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 600px;
        margin: 40px auto 80px;
        &__logo {
            width: 100px;
            cursor: pointer;
        }
        &__links {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        &__link {
            text-transform: uppercase;
            font-family: futura-pt;
            margin: 0 10px;
            font-weight: 500;
            cursor: pointer;
            color: inherit;
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    a {        
        cursor: pointer;
        color: inherit;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
</style>