import { createWebHashHistory , createRouter } from "vue-router";
import Home from "@/views/home.vue";
import Menu from "@/views/menu.vue";
import Quiz from "@/views/quiz.vue";
import Results from "@/views/results.vue";
import Video from "@/views/videoFull.vue";
import Experience from "@/views/experience.vue";
import Empower from "@/views/empower.vue";
import Research from "@/views/research.vue";

const hrefPath = window.location.href.replace(window.location.origin, '');

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/menu",
        name: "Menu",
        component: Menu,
    },
    {
        path: "/quiz",
        name: "Quiz",
        component: Quiz,
    },
    {
        path: "/results",
        name: "Results",
        component: Results,
    },
    {
        path: "/engage-intro",
        name: "Engage Intro",
        component: Video,
        props: {
            color: "#e0cdad",
            video: "https://player.vimeo.com/external/586368161.hd.mp4?s=407d4cea705113692565ce668f23b6d3add6227f&profile_id=175",
            redirect: '/quiz'
        }
    },
    {
        path: "/experience-intro",
        name: "Experience Intro",
        component: Video,
        props: {
            color: "#e0cdad",
            video: "https://player.vimeo.com/external/586368322.hd.mp4?s=fee4a8fec2e2bc7c4835758ffa9db7d71c7fccf8&profile_id=175",
            redirect: '/experience'
        }
    },
    {
        path: "/empower-intro",
        name: "Empower Intro",
        component: Video,
        props: {
            color: "#e0cdad",
            video: "https://player.vimeo.com/external/586368410.hd.mp4?s=67ba3f7a638ebe63a53d21b1f670e57ec0850c6d&profile_id=175",
            redirect: '/empower'
        }
    },
    {
        path: "/experience",
        name: "Experience",
        component: Experience,
        props: {
            color: "#7f9453",
            video: "https://player.vimeo.com/external/586317157.hd.mp4?s=34a1c956b2cf062e7c3c0b0d806ede623a9f5f41&profile_id=175",
            redirect: '/menu'
        }
    },
    {
        path: "/empower",
        name: "Empower",
        component: Empower,
    },
    {
        path: "/research",
        name: "Research",
        component: Research,
    },
    { path: '/:pathMatch(.*)*', name: 'NotFound', component: Home },
];


const router = createRouter({
    history: createWebHashHistory (),
    base: hrefPath,
    routes,
});
router.afterEach(() => {    
    document.querySelector("html").scrollTop = 0
})

export default router;