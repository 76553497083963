<template>
    <!-- <div id="nav" style="
        position: absolute;
        top: 0px;
        z-index: 1000;
        display: flex;
        width: 100%;
        justify-content: center;"
        >
        <router-link to="/">Home</router-link> |
        <router-link to="/quiz">Quiz</router-link> |
        <router-link to="/results">Results</router-link>
    </div> -->
    <Header />
    <router-view />
</template>

<script>
    // import Home from './pages/home.vue';
    // // import Results from './pages/results.vue';
    import Header from './components/header.vue';

    export default {
        name: 'app',
        components: {
            Header
        },
        data: function() {
            return {
                hrefPath: window.location.href.replace(window.location.origin, '')
            }
        },
        mounted: function(){
            document.addEventListener('keydown', KeyPress);

            function KeyPress(e) {
                var evtobj = window.event? event : e
                if (evtobj.keyCode == 81 && evtobj.ctrlKey) {
                    if (document.designMode === 'off') {
                        document.designMode = "on";
                        alert("Edit mode enabled")
                    } else {
                        document.designMode = "off";
                        alert("Edit mode disabled")
                    }
                }
            }
        }
    };
</script>

<style>

    html, :root {
        scroll-behavior: auto;
    }
    

</style>