/* eslint-disable */

const animalsTable = [
	{
		id: 0,
		name: "dolphin",
		points: 0
	},
	{
		id: 1,
		name: "panther",
		points: 0
	},
	{
		id: 2,
		name: "peacock",
		points: 0
	},
	{
		id: 3,
		name: "owl",
		points: 0
	}
];
const coloursTable = [
	{
		id: 0,
		name: "blue",
		hex: "#1b61a6",
		points: 0
	},
	{
		id: 1,
		name: "yellow",
		hex: "#f1c05a",
		points: 0
	},
	{
		id: 2,
		name: "red",
		hex: "#a23b3c",
		points: 0
	},
	{
		id: 3,
		name: "green",
		hex: "#74963e",
		points: 0
	}
];
const natureTable = [
	{
		id: 0,
		name: "petal",
		points: 0
	},
	{
		id: 1,
		name: "roots",
		points: 0
	},
	{
		id: 2,
		name: "leaf",
		points: 0
	},
	{
		id: 3,
		name: "tree",
		points: 0
	}
];
const typeTable = [
	{
		id: 0,
		name: "Visual",
		points: 0
	},
	{
		id: 1,
		name: "Verbal",
		points: 0
	},
	{
		id: 2,
		name: "Aural",
		points: 0
	},
	{
		id: 3,
		name: "Kinaesthetic",
		points: 0
	}
];
const categoriesTable = [
	{
		id: 0,
		name: "Introvert",
		points: 0,
		group: 1
	},
	{
		id: 1,
		name: "Extrovert",
		points: 0,
		group: 1
	},
	{
		id: 2,
		name: "Energetic",
		points: 0,
		group: 2
	},
	{
		id: 3,
		name: "Calm",
		points: 0,
		group: 2
	},
	{
		id: 4,
		name: "Methodical",
		points: 0,
		group: 3
	},
	{
		id: 5,
		name: "Improvised",
		points: 0,
		group: 3
	},
	{
		id: 6,
		name: "Formal",
		points: 0,
		group: 4
	},
	{
		id: 7,
		name: "Casual",
		points: 0,
		group: 4
	},
	{
		id: 8,
		name: "Read Instructions",
		points: 0
	},
	{
		id: 9,
		name: "Test first then instructions",
		points: 0
	},
	{
		id: 10,
		name: "Test",
		points: 0
	},
	{
		id: 11,
		name: "Test",
		points: 0,
		desc: ''
	},
	{
		id: 12,
		name: "Detail",
		points: 0,
		group: 6
	},
	{
		id: 13,
		name: "Big Picture",
		points: 0,
		group: 6
	},
	{
		id: 14,
		name: "Intuitive",
		points: 0,
		group: 7
	},
	{
		id: 15,
		name: "Personal",
		points: 0,
		group: 7
	},
	{
		id: 16,
		name: "Functional",
		points: 0,
		group: 7
	},
	{
		id: 17,
		name: "Analytical",
		points: 0,
		group: 7
	},
	{
		id: 18,
		name: "Watches only giving necessary info",
		points: 0
	},
	{
		id: 19,
		name: "Observes before taking action",
		points: 0
	},
	{
		id: 20,
		name: "Jumps right in",
		points: 0
	},
	{
		id: 21,
		name: "Jumps right in",
		points: 0
	},
	{
		id: 22,
		name: "When it is about a goal",
		points: 0
	},
	{
		id: 23,
		name: "When linked to a memory",
		points: 0
	},
	{
		id: 24,
		name: "In conversation",
		points: 0
	},
	{
		id: 25,
		name: "Through song lyrics",
		points: 0
	},
	{
		id: 26,
		name: "Turn it into a picture",
		points: 0
	},
	{
		id: 27,
		name: "Act it out",
		points: 0
	},
	{
		id: 28,
		name: "Voice record",
		points: 0
	},
	{
		id: 29,
		name: "Tell someone",
		points: 0
	},
	{
		id: 30,
		name: "Watch then try",
		points: 0
	},
	{
		id: 31,
		name: "Find a live chat",
		points: 0
	},
	{
		id: 32,
		name: "Listen to a podcast",
		points: 0
	},
	{
		id: 33,
		name: "YouTube Tutorial",
		points: 0
	}
];

const questions = [
	{
		qno: 1,
		q: "You find group activities fun",
		answers: [
			{
				a: 1,
				animal: "4",
				color: "1",
				type: "3",
				flower: "2",
				category: "1",
				selected: false,
			},
			{
				a: 2,
				animal: "4",
				color: "1",
				type: "3",
				flower: "2",
				category: "1",
				selected: false,
			},
			{
				a: 3,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "1",
				selected: false,
			},
			{
				a: 4,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "1",
				selected: false,
			},
			{
				a: 5,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "2",
				selected: false,
			},
			{
				a: 6,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "2",
				selected: false,
			},
			{
				a: 7,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "2",
				selected: false,
			},
			{
				a: 8,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "2",
				selected: false,
			},
			{
				a: 9,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "2",
				selected: false,
			},
			{
				a: 10,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "2",
				selected: false,
			},
		],
	},
	{
		qno: 2,
		q:
			"At the end of a busy week, what you need is an exciting social event.",
		answers: [
			{
				a: 1,
				animal: "4",
				color: "1",
				type: "3",
				flower: "2",
				category: "4",
				selected: false,
			},
			{
				a: 2,
				animal: "4",
				color: "1",
				type: "3",
				flower: "2",
				category: "4",
				selected: false,
			},
			{
				a: 3,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "4",
				selected: false,
			},
			{
				a: 4,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "4",
				selected: false,
			},
			{
				a: 5,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "3",
				selected: false,
			},
			{
				a: 6,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "3",
				selected: false,
			},
			{
				a: 7,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "3",
				selected: false,
			},
			{
				a: 8,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "3",
				selected: false,
			},
			{
				a: 9,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "3",
				selected: false,
			},
			{
				a: 10,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "3",
				selected: false,
			},
		],
	},
	{
		qno: 3,
		q: "You prefer to finish one thing before starting another.",
		answers: [
			{
				a: 1,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "6",
				selected: false,
			},
			{
				a: 2,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "6",
				selected: false,
			},
			{
				a: 3,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "6",
				selected: false,
			},
			{
				a: 4,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "6",
				selected: false,
			},
			{
				a: 5,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "6",
				selected: false,
			},
			{
				a: 6,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "6",
				selected: false,
			},
			{
				a: 7,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "5",
				selected: false,
			},
			{
				a: 8,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "5",
				selected: false,
			},
			{
				a: 9,
				animal: "4",
				color: "1",
				type: "3",
				flower: "2",
				category: "5",
				selected: false,
			},
			{
				a: 10,
				animal: "4",
				color: "1",
				type: "3",
				flower: "2",
				category: "5",
				selected: false,
			},
		],
	},
	{
		qno: 4,
		q: "You focus on the present.",
		answers: [
			{
				a: 1,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "7",
				selected: false,
			},
			{
				a: 2,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "7",
				selected: false,
			},
			{
				a: 3,
				animal: "4",
				color: "1",
				type: "3",
				flower: "2",
				category: "7",
				selected: false,
			},
			{
				a: 4,
				animal: "4",
				color: "1",
				type: "3",
				flower: "2",
				category: "7",
				selected: false,
			},
			{
				a: 5,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "8",
				selected: false,
			},
			{
				a: 6,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "8",
				selected: false,
			},
			{
				a: 7,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "8",
				selected: false,
			},
			{
				a: 8,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "8",
				selected: false,
			},
			{
				a: 9,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "8",
				selected: false,
			},
			{
				a: 10,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "8",
				selected: false,
			},
		],
	},
	{
		qno: 5,
		q:
			"The first thing you do when you get a new gadget or device is try it out.",
		answers: [
			{
				a: 1,
				animal: "4",
				color: "1",
				type: "3",
				flower: "2",
				category: "9",
				selected: false,
			},
			{
				a: 2,
				animal: "4",
				color: "1",
				type: "3",
				flower: "2",
				category: "9",
				selected: false,
			},
			{
				a: 3,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "10",
				selected: false,
			},
			{
				a: 4,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "10",
				selected: false,
			},
			{
				a: 5,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "11",
				selected: false,
			},
			{
				a: 6,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "11",
				selected: false,
			},
			{
				a: 7,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "11",
				selected: false,
			},
			{
				a: 8,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "12",
				selected: false,
			},
			{
				a: 9,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "12",
				selected: false,
			},
			{
				a: 10,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "12",
				selected: false,
			},
		],
	},
	{
		qno: 6,
		q: "You prefer to keep your options open.",
		answers: [
			{
				a: 1,
				animal: "4",
				color: "1",
				type: "3",
				flower: "2",
				category: "13",
				selected: false,
			},
			{
				a: 2,
				animal: "4",
				color: "1",
				type: "3",
				flower: "2",
				category: "13",
				selected: false,
			},
			{
				a: 3,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "13",
				selected: false,
			},
			{
				a: 4,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "13",
				selected: false,
			},
			{
				a: 5,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "14",
				selected: false,
			},
			{
				a: 6,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "14",
				selected: false,
			},
			{
				a: 7,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "14",
				selected: false,
			},
			{
				a: 8,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "14",
				selected: false,
			},
			{
				a: 9,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "14",
				selected: false,
			},
			{
				a: 10,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "14",
				selected: false,
			},
		],
	},
	{
		qno: 7,
		q: "You want the facts and numbers.",
		answers: [
			{
				a: 1,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "15",
				selected: false,
			},
			{
				a: 2,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "15",
				selected: false,
			},
			{
				a: 3,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "16",
				selected: false,
			},
			{
				a: 4,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "16",
				selected: false,
			},
			{
				a: 5,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "16",
				selected: false,
			},
			{
				a: 6,
				animal: "4",
				color: "1",
				type: "3",
				flower: "2",
				category: "17",
				selected: false,
			},
			{
				a: 7,
				animal: "4",
				color: "1",
				type: "3",
				flower: "2",
				category: "17",
				selected: false,
			},
			{
				a: 8,
				animal: "4",
				color: "1",
				type: "3",
				flower: "2",
				category: "17",
				selected: false,
			},
			{
				a: 9,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "18",
				selected: false,
			},
			{
				a: 10,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "18",
				selected: false,
			},
		],
	},
	{
		qno: 8,
		q: "Time on your own is important to you.",
		answers: [
			{
				a: 1,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "2",
				selected: false,
			},
			{
				a: 2,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "2",
				selected: false,
			},
			{
				a: 3,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "2",
				selected: false,
			},
			{
				a: 4,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "2",
				selected: false,
			},
			{
				a: 5,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "1",
				selected: false,
			},
			{
				a: 6,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "1",
				selected: false,
			},
			{
				a: 7,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "1",
				selected: false,
			},
			{
				a: 8,
				animal: "4",
				color: "1",
				type: "3",
				flower: "2",
				category: "1",
				selected: false,
			},
			{
				a: 9,
				animal: "4",
				color: "1",
				type: "3",
				flower: "2",
				category: "1",
				selected: false,
			},
			{
				a: 10,
				animal: "4",
				color: "1",
				type: "3",
				flower: "2",
				category: "1",
				selected: false,
			},
		],
	},
	{
		qno: 9,
		q: "You feel more drawn to places with quiet, calm atmospheres.",
		answers: [
			{
				a: 1,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "3",
				selected: false,
			},
			{
				a: 2,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "3",
				selected: false,
			},
			{
				a: 3,
				animal: "2",
				color: "3",
				type: "2",
				flower: "4",
				category: "3",
				selected: false,
			},
			{
				a: 4,
				animal: "2",
				color: "3",
				type: "2",
				flower: "4",
				category: "3",
				selected: false,
			},
			{
				a: 5,
				animal: "2",
				color: "3",
				type: "2",
				flower: "4",
				category: "3",
				selected: false,
			},
			{
				a: 6,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "4",
				selected: false,
			},
			{
				a: 7,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "4",
				selected: false,
			},
			{
				a: 8,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "4",
				selected: false,
			},
			{
				a: 9,
				animal: "4",
				color: "1",
				type: "3",
				flower: "2",
				category: "4",
				selected: false,
			},
			{
				a: 10,
				animal: "4",
				color: "1",
				type: "3",
				flower: "2",
				category: "4",
				selected: false,
			},
		],
	},
	{
		qno: 10,
		q:
			"You usually prefer doing what you feel like rather than planning a daily routine.",
		answers: [
			{
				a: 1,
				animal: "4",
				color: "1",
				type: "3",
				flower: "2",
				category: "5",
				selected: false,
			},
			{
				a: 2,
				animal: "4",
				color: "1",
				type: "3",
				flower: "2",
				category: "5",
				selected: false,
			},
			{
				a: 3,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "5",
				selected: false,
			},
			{
				a: 4,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "5",
				selected: false,
			},
			{
				a: 5,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "5",
				selected: false,
			},
			{
				a: 6,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "6",
				selected: false,
			},
			{
				a: 7,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "6",
				selected: false,
			},
			{
				a: 8,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "6",
				selected: false,
			},
			{
				a: 9,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "6",
				selected: false,
			},
			{
				a: 10,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "6",
				selected: false,
			},
		],
	},
	{
		qno: 11,
		q: "You like to work hard.",
		answers: [
			{
				a: 1,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "8",
				selected: false,
			},
			{
				a: 2,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "8",
				selected: false,
			},
			{
				a: 3,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "8",
				selected: false,
			},
			{
				a: 4,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "8",
				selected: false,
			},
			{
				a: 5,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "8",
				selected: false,
			},
			{
				a: 6,
				animal: "4",
				color: "1",
				type: "3",
				flower: "2",
				category: "7",
				selected: false,
			},
			{
				a: 7,
				animal: "4",
				color: "1",
				type: "3",
				flower: "2",
				category: "7",
				selected: false,
			},
			{
				a: 8,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "7",
				selected: false,
			},
			{
				a: 9,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "7",
				selected: false,
			},
			{
				a: 10,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "7",
				selected: false,
			},
		],
	},
	{
		qno: 12,
		q: "You learn by getting involved and experiencing things.",
		answers: [
			{
				a: 1,
				animal: "4",
				color: "1",
				type: "3",
				flower: "2",
				category: "19",
				selected: false,
			},
			{
				a: 2,
				animal: "4",
				color: "1",
				type: "3",
				flower: "2",
				category: "19",
				selected: false,
			},
			{
				a: 3,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "20",
				selected: false,
			},
			{
				a: 4,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "20",
				selected: false,
			},
			{
				a: 5,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "20",
				selected: false,
			},
			{
				a: 6,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "21",
				selected: false,
			},
			{
				a: 7,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "21",
				selected: false,
			},
			{
				a: 8,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "21",
				selected: false,
			},
			{
				a: 9,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "22",
				selected: false,
			},
			{
				a: 10,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "22",
				selected: false,
			},
		],
	},
	{
		qno: 13,
		q: "When learning something new, you want the details.",
		answers: [
			{
				a: 1,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "14",
				selected: false,
			},
			{
				a: 2,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "14",
				selected: false,
			},
			{
				a: 3,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "14",
				selected: false,
			},
			{
				a: 4,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "14",
				selected: false,
			},
			{
				a: 5,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "14",
				selected: false,
			},
			{
				a: 6,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "13",
				selected: false,
			},
			{
				a: 7,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "13",
				selected: false,
			},
			{
				a: 8,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "13",
				selected: false,
			},
			{
				a: 9,
				animal: "4",
				color: "1",
				type: "3",
				flower: "2",
				category: "13",
				selected: false,
			},
			{
				a: 10,
				animal: "4",
				color: "1",
				type: "3",
				flower: "2",
				category: "13",
				selected: false,
			},
		],
	},
	{
		qno: 14,
		q: "You can tell how someone is feeling just by looking at them.",
		answers: [
			{
				a: 1,
				animal: "4",
				color: "1",
				type: "3",
				flower: "2",
				category: "17",
				selected: false,
			},
			{
				a: 2,
				animal: "4",
				color: "1",
				type: "3",
				flower: "2",
				category: "17",
				selected: false,
			},
			{
				a: 3,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "18",
				selected: false,
			},
			{
				a: 4,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "18",
				selected: false,
			},
			{
				a: 5,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "18",
				selected: false,
			},
			{
				a: 6,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "15",
				selected: false,
			},
			{
				a: 7,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "15",
				selected: false,
			},
			{
				a: 8,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "16",
				selected: false,
			},
			{
				a: 9,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "16",
				selected: false,
			},
			{
				a: 10,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "16",
				selected: false,
			},
		],
	},
	{
		qno: 15,
		q: "You are always the first one to contact your friends.",
		answers: [
			{
				a: 1,
				animal: "4",
				color: "1",
				type: "3",
				flower: "2",
				category: "1",
				selected: false,
			},
			{
				a: 2,
				animal: "4",
				color: "1",
				type: "3",
				flower: "2",
				category: "1",
				selected: false,
			},
			{
				a: 3,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "1",
				selected: false,
			},
			{
				a: 4,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "1",
				selected: false,
			},
			{
				a: 5,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "2",
				selected: false,
			},
			{
				a: 6,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "2",
				selected: false,
			},
			{
				a: 7,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "2",
				selected: false,
			},
			{
				a: 8,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "2",
				selected: false,
			},
			{
				a: 9,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "2",
				selected: false,
			},
			{
				a: 10,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "2",
				selected: false,
			},
		],
	},
	{
		qno: 16,
		q: "You relax by doing physical activities.",
		answers: [
			{
				a: 1,
				animal: "4",
				color: "1",
				type: "3",
				flower: "2",
				category: "4",
				selected: false,
			},
			{
				a: 2,
				animal: "4",
				color: "1",
				type: "3",
				flower: "2",
				category: "4",
				selected: false,
			},
			{
				a: 3,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "4",
				selected: false,
			},
			{
				a: 4,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "4",
				selected: false,
			},
			{
				a: 5,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "4",
				selected: false,
			},
			{
				a: 6,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "3",
				selected: false,
			},
			{
				a: 7,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "3",
				selected: false,
			},
			{
				a: 8,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "3",
				selected: false,
			},
			{
				a: 9,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "3",
				selected: false,
			},
			{
				a: 10,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "3",
				selected: false,
			},
		],
	},
	{
		qno: 17,
		q: "You always have a plan.",
		answers: [
			{
				a: 1,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "6",
				selected: false,
			},
			{
				a: 2,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "6",
				selected: false,
			},
			{
				a: 3,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "6",
				selected: false,
			},
			{
				a: 4,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "6",
				selected: false,
			},
			{
				a: 5,
				animal: "4",
				color: "1",
				type: "3",
				flower: "2",
				category: "5",
				selected: false,
			},
			{
				a: 6,
				animal: "4",
				color: "1",
				type: "3",
				flower: "2",
				category: "5",
				selected: false,
			},
			{
				a: 7,
				animal: "4",
				color: "1",
				type: "3",
				flower: "2",
				category: "5",
				selected: false,
			},
			{
				a: 8,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "5",
				selected: false,
			},
			{
				a: 9,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "5",
				selected: false,
			},
			{
				a: 10,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "5",
				selected: false,
			},
		],
	},
	{
		qno: 18,
		q: "Working as a team, to achieve goals, is important to you.",
		answers: [
			{
				a: 1,
				animal: "4",
				color: "1",
				type: "3",
				flower: "2",
				category: "8",
				selected: false,
			},
			{
				a: 2,
				animal: "4",
				color: "1",
				type: "3",
				flower: "2",
				category: "8",
				selected: false,
			},
			{
				a: 3,
				animal: "4",
				color: "1",
				type: "3",
				flower: "2",
				category: "8",
				selected: false,
			},
			{
				a: 4,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "8",
				selected: false,
			},
			{
				a: 5,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "8",
				selected: false,
			},
			{
				a: 6,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "8",
				selected: false,
			},
			{
				a: 7,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "7",
				selected: false,
			},
			{
				a: 8,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "7",
				selected: false,
			},
			{
				a: 9,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "7",
				selected: false,
			},
			{
				a: 10,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "7",
				selected: false,
			},
		],
	},
	{
		qno: 19,
		q: "You can easily remember things you have heard.",
		answers: [
			{
				a: 1,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "23",
				selected: false,
			},
			{
				a: 2,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "23",
				selected: false,
			},
			{
				a: 3,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "24",
				selected: false,
			},
			{
				a: 4,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "24",
				selected: false,
			},
			{
				a: 5,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "24",
				selected: false,
			},
			{
				a: 6,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "25",
				selected: false,
			},
			{
				a: 7,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "25",
				selected: false,
			},
			{
				a: 8,
				animal: "4",
				color: "1",
				type: "3",
				flower: "2",
				category: "26",
				selected: false,
			},
			{
				a: 9,
				animal: "4",
				color: "1",
				type: "3",
				flower: "2",
				category: "26",
				selected: false,
			},
			{
				a: 10,
				animal: "4",
				color: "1",
				type: "3",
				flower: "2",
				category: "26",
				selected: false,
			},
		],
	},
	{
		qno: 20,
		q: "When talking to you, others should keep it brief.",
		answers: [
			{
				a: 1,
				animal: "4",
				color: "1",
				type: "3",
				flower: "2",
				category: "13",
				selected: false,
			},
			{
				a: 2,
				animal: "4",
				color: "1",
				type: "3",
				flower: "2",
				category: "13",
				selected: false,
			},
			{
				a: 3,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "13",
				selected: false,
			},
			{
				a: 4,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "13",
				selected: false,
			},
			{
				a: 5,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "14",
				selected: false,
			},
			{
				a: 6,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "14",
				selected: false,
			},
			{
				a: 7,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "14",
				selected: false,
			},
			{
				a: 8,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "14",
				selected: false,
			},
			{
				a: 9,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "14",
				selected: false,
			},
			{
				a: 10,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "14",
				selected: false,
			},
		],
	},
	{
		qno: 21,
		q: "You cut right to the chase.",
		answers: [
			{
				a: 1,
				animal: "4",
				color: "1",
				type: "3",
				flower: "2",
				category: "17",
				selected: false,
			},
			{
				a: 2,
				animal: "4",
				color: "1",
				type: "3",
				flower: "2",
				category: "17",
				selected: false,
			},
			{
				a: 3,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "16",
				selected: false,
			},
			{
				a: 4,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "16",
				selected: false,
			},
			{
				a: 5,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "18",
				selected: false,
			},
			{
				a: 6,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "18",
				selected: false,
			},
			{
				a: 7,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "18",
				selected: false,
			},
			{
				a: 8,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "15",
				selected: false,
			},
			{
				a: 9,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "15",
				selected: false,
			},
			{
				a: 10,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "15",
				selected: false,
			},
		],
	},
	{
		qno: 22,
		q: "You prefer to listen rather than talk.",
		answers: [
			{
				a: 1,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "2",
				selected: false,
			},
			{
				a: 2,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "2",
				selected: false,
			},
			{
				a: 3,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "2",
				selected: false,
			},
			{
				a: 4,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "2",
				selected: false,
			},
			{
				a: 5,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "2",
				selected: false,
			},
			{
				a: 6,
				animal: "4",
				color: "1",
				type: "3",
				flower: "2",
				category: "1",
				selected: false,
			},
			{
				a: 7,
				animal: "4",
				color: "1",
				type: "3",
				flower: "2",
				category: "1",
				selected: false,
			},
			{
				a: 8,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "1",
				selected: false,
			},
			{
				a: 9,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "1",
				selected: false,
			},
			{
				a: 10,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "1",
				selected: false,
			},
		],
	},
	{
		qno: 23,
		q: "You relax by resting.",
		answers: [
			{
				a: 1,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "3",
				selected: false,
			},
			{
				a: 2,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "3",
				selected: false,
			},
			{
				a: 3,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "3",
				selected: false,
			},
			{
				a: 4,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "3",
				selected: false,
			},
			{
				a: 5,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "3",
				selected: false,
			},
			{
				a: 6,
				animal: "4",
				color: "1",
				type: "3",
				flower: "2",
				category: "4",
				selected: false,
			},
			{
				a: 7,
				animal: "4",
				color: "1",
				type: "3",
				flower: "2",
				category: "4",
				selected: false,
			},
			{
				a: 8,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "4",
				selected: false,
			},
			{
				a: 9,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "4",
				selected: false,
			},
			{
				a: 10,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "4",
				selected: false,
			},
		],
	},
	{
		qno: 24,
		q: "You often do things last minute.",
		answers: [
			{
				a: 1,
				animal: "4",
				color: "1",
				type: "3",
				flower: "2",
				category: "5",
				selected: false,
			},
			{
				a: 2,
				animal: "4",
				color: "1",
				type: "3",
				flower: "2",
				category: "5",
				selected: false,
			},
			{
				a: 3,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "5",
				selected: false,
			},
			{
				a: 4,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "5",
				selected: false,
			},
			{
				a: 5,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "6",
				selected: false,
			},
			{
				a: 6,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "6",
				selected: false,
			},
			{
				a: 7,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "6",
				selected: false,
			},
			{
				a: 8,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "6",
				selected: false,
			},
			{
				a: 9,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "6",
				selected: false,
			},
			{
				a: 10,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "6",
				selected: false,
			},
		],
	},
	{
		qno: 25,
		q: "Working to deadlines is important to you.",
		answers: [
			{
				a: 1,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "8",
				selected: false,
			},
			{
				a: 2,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "8",
				selected: false,
			},
			{
				a: 3,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "8",
				selected: false,
			},
			{
				a: 4,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "8",
				selected: false,
			},
			{
				a: 5,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "8",
				selected: false,
			},
			{
				a: 6,
				animal: "4",
				color: "1",
				type: "3",
				flower: "2",
				category: "7",
				selected: false,
			},
			{
				a: 7,
				animal: "4",
				color: "1",
				type: "3",
				flower: "2",
				category: "7",
				selected: false,
			},
			{
				a: 8,
				animal: "4",
				color: "1",
				type: "3",
				flower: "2",
				category: "7",
				selected: false,
			},
			{
				a: 9,
				animal: "2",
				color: "2",
				type: "1",
				flower: "4",
				category: "7",
				selected: false,
			},
			{
				a: 10,
				animal: "2",
				color: "2",
				type: "1",
				flower: "4",
				category: "7",
				selected: false,
			},
		],
	},
	{
		qno: 26,
		q: "You can focus better on information by reading it aloud.",
		answers: [
			{
				a: 1,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "27",
				selected: false,
			},
			{
				a: 2,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "27",
				selected: false,
			},
			{
				a: 3,
				animal: "1",
				color: "3",
				type: "4",
				flower: "3",
				category: "28",
				selected: false,
			},
			{
				a: 4,
				animal: "1",
				color: "3",
				type: "4",
				flower: "3",
				category: "28",
				selected: false,
			},
			{
				a: 5,
				animal: "1",
				color: "3",
				type: "4",
				flower: "3",
				category: "28",
				selected: false,
			},
			{
				a: 6,
				animal: "4",
				color: "1",
				type: "3",
				flower: "2",
				category: "29",
				selected: false,
			},
			{
				a: 7,
				animal: "4",
				color: "1",
				type: "3",
				flower: "2",
				category: "29",
				selected: false,
			},
			{
				a: 8,
				animal: "4",
				color: "1",
				type: "3",
				flower: "2",
				category: "29",
				selected: false,
			},
			{
				a: 9,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "30",
				selected: false,
			},
			{
				a: 10,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "30",
				selected: false,
			},
		],
	},
	{
		qno: 27,
		q:
			"You have so many interests that it can be difficult to choose what to do next.",
		answers: [
			{
				a: 1,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "14",
				selected: false,
			},
			{
				a: 2,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "14",
				selected: false,
			},
			{
				a: 3,
				animal: "4",
				color: "1",
				type: "3",
				flower: "2",
				category: "14",
				selected: false,
			},
			{
				a: 4,
				animal: "4",
				color: "1",
				type: "3",
				flower: "2",
				category: "14",
				selected: false,
			},
			{
				a: 5,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "13",
				selected: false,
			},
			{
				a: 6,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "13",
				selected: false,
			},
			{
				a: 7,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "13",
				selected: false,
			},
			{
				a: 8,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "13",
				selected: false,
			},
			{
				a: 9,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "13",
				selected: false,
			},
			{
				a: 10,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "13",
				selected: false,
			},
		],
	},
	{
		qno: 28,
		q: "You prefer doing things in a particular order.",
		answers: [
			{
				a: 1,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "15",
				selected: false,
			},
			{
				a: 2,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "15",
				selected: false,
			},
			{
				a: 3,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "16",
				selected: false,
			},
			{
				a: 4,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "16",
				selected: false,
			},
			{
				a: 5,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "18",
				selected: false,
			},
			{
				a: 6,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "18",
				selected: false,
			},
			{
				a: 7,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "18",
				selected: false,
			},
			{
				a: 8,
				animal: "4",
				color: "1",
				type: "3",
				flower: "2",
				category: "17",
				selected: false,
			},
			{
				a: 9,
				animal: "4",
				color: "1",
				type: "3",
				flower: "2",
				category: "17",
				selected: false,
			},
			{
				a: 10,
				animal: "4",
				color: "1",
				type: "3",
				flower: "2",
				category: "17",
				selected: false,
			},
		],
	},
	{
		qno: 29,
		q:
			"You are happier helping others accomplish things than accomplishing something yourself.",
		answers: [
			{
				a: 1,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "2",
				selected: false,
			},
			{
				a: 2,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "2",
				selected: false,
			},
			{
				a: 3,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "2",
				selected: false,
			},
			{
				a: 4,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "2",
				selected: false,
			},
			{
				a: 5,
				animal: "4",
				color: "1",
				type: "3",
				flower: "2",
				category: "1",
				selected: false,
			},
			{
				a: 6,
				animal: "4",
				color: "1",
				type: "3",
				flower: "2",
				category: "1",
				selected: false,
			},
			{
				a: 7,
				animal: "4",
				color: "1",
				type: "3",
				flower: "2",
				category: "1",
				selected: false,
			},
			{
				a: 8,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "1",
				selected: false,
			},
			{
				a: 9,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "1",
				selected: false,
			},
			{
				a: 10,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "1",
				selected: false,
			},
		],
	},
	{
		qno: 30,
		q:
			"You spend a lot of your free time exploring various random topics that interest you.",
		answers: [
			{
				a: 1,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "3",
				selected: false,
			},
			{
				a: 2,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "3",
				selected: false,
			},
			{
				a: 3,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "3",
				selected: false,
			},
			{
				a: 4,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "3",
				selected: false,
			},
			{
				a: 5,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "3",
				selected: false,
			},
			{
				a: 6,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "4",
				selected: false,
			},
			{
				a: 7,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "4",
				selected: false,
			},
			{
				a: 8,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "4",
				selected: false,
			},
			{
				a: 9,
				animal: "4",
				color: "1",
				type: "3",
				flower: "2",
				category: "4",
				selected: false,
			},
			{
				a: 10,
				animal: "4",
				color: "1",
				type: "3",
				flower: "2",
				category: "4",
				selected: false,
			},
		],
	},
	{
		qno: 31,
		q:
			"When your plans get interrupted, you want to get them back on track as soon as possible.",
		answers: [
			{
				a: 1,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "6",
				selected: false,
			},
			{
				a: 2,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "6",
				selected: false,
			},
			{
				a: 3,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "6",
				selected: false,
			},
			{
				a: 4,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "6",
				selected: false,
			},
			{
				a: 5,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "5",
				selected: false,
			},
			{
				a: 6,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "5",
				selected: false,
			},
			{
				a: 7,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "5",
				selected: false,
			},
			{
				a: 8,
				animal: "4",
				color: "1",
				type: "3",
				flower: "2",
				category: "5",
				selected: false,
			},
			{
				a: 9,
				animal: "4",
				color: "1",
				type: "3",
				flower: "2",
				category: "5",
				selected: false,
			},
			{
				a: 10,
				animal: "4",
				color: "1",
				type: "3",
				flower: "2",
				category: "5",
				selected: false,
			},
		],
	},
	{
		qno: 32,
		q: "You follow your heart rather than your head.",
		answers: [
			{
				a: 1,
				animal: "4",
				color: "1",
				type: "3",
				flower: "2",
				category: "7",
				selected: false,
			},
			{
				a: 2,
				animal: "4",
				color: "1",
				type: "3",
				flower: "2",
				category: "7",
				selected: false,
			},
			{
				a: 3,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "7",
				selected: false,
			},
			{
				a: 4,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "7",
				selected: false,
			},
			{
				a: 5,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "8",
				selected: false,
			},
			{
				a: 6,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "8",
				selected: false,
			},
			{
				a: 7,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "8",
				selected: false,
			},
			{
				a: 8,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "8",
				selected: false,
			},
			{
				a: 9,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "8",
				selected: false,
			},
			{
				a: 10,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "8",
				selected: false,
			},
		],
	},
	{
		qno: 33,
		q:
			"The first thing you do when you want to learn something is watch a video.",
		answers: [
			{
				a: 1,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "31",
				selected: false,
			},
			{
				a: 2,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "31",
				selected: false,
			},
			{
				a: 3,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "32",
				selected: false,
			},
			{
				a: 4,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "32",
				selected: false,
			},
			{
				a: 5,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "32",
				selected: false,
			},
			{
				a: 6,
				animal: "4",
				color: "1",
				type: "3",
				flower: "2",
				category: "33",
				selected: false,
			},
			{
				a: 7,
				animal: "4",
				color: "1",
				type: "3",
				flower: "2",
				category: "33",
				selected: false,
			},
			{
				a: 8,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "34",
				selected: false,
			},
			{
				a: 9,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "34",
				selected: false,
			},
			{
				a: 10,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "34",
				selected: false,
			},
		],
	},
	{
		qno: 34,
		q: "You break things down into smaller chunks.",
		answers: [
			{
				a: 1,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "14",
				selected: false,
			},
			{
				a: 2,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "14",
				selected: false,
			},
			{
				a: 3,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "14",
				selected: false,
			},
			{
				a: 4,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "14",
				selected: false,
			},
			{
				a: 5,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "13",
				selected: false,
			},
			{
				a: 6,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "13",
				selected: false,
			},
			{
				a: 7,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "13",
				selected: false,
			},
			{
				a: 8,
				animal: "4",
				color: "1",
				type: "3",
				flower: "2",
				category: "13",
				selected: false,
			},
			{
				a: 9,
				animal: "4",
				color: "1",
				type: "3",
				flower: "2",
				category: "13",
				selected: false,
			},
			{
				a: 10,
				animal: "4",
				color: "1",
				type: "3",
				flower: "2",
				category: "13",
				selected: false,
			},
		],
	},
	{
		qno: 35,
		q:
			"You spend a lot of time trying to understand views that are not the same as yours.",
		answers: [
			{
				a: 1,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "18",
				selected: false,
			},
			{
				a: 2,
				animal: "2",
				color: "3",
				type: "1",
				flower: "4",
				category: "18",
				selected: false,
			},
			{
				a: 3,
				animal: "4",
				color: "1",
				type: "3",
				flower: "2",
				category: "17",
				selected: false,
			},
			{
				a: 4,
				animal: "4",
				color: "1",
				type: "3",
				flower: "2",
				category: "17",
				selected: false,
			},
			{
				a: 5,
				animal: "4",
				color: "1",
				type: "3",
				flower: "2",
				category: "17",
				selected: false,
			},
			{
				a: 6,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "15",
				selected: false,
			},
			{
				a: 7,
				animal: "3",
				color: "2",
				type: "2",
				flower: "1",
				category: "15",
				selected: false,
			},
			{
				a: 8,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "16",
				selected: false,
			},
			{
				a: 9,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "16",
				selected: false,
			},
			{
				a: 10,
				animal: "1",
				color: "4",
				type: "4",
				flower: "3",
				category: "16",
				selected: false,
			},
		],
	},
];


export {animalsTable, coloursTable, natureTable, typeTable, categoriesTable, questions};