<template>
    <div class="experience">
        <video controls playsinline autoplay id="experienceVideo">
            <source src="https://player.vimeo.com/external/586317157.hd.mp4?s=34a1c956b2cf062e7c3c0b0d806ede623a9f5f41&profile_id=175" type="video/mp4">
        </video>
        <div class="contact-us">
            <h3>Our operators are ready to take your call!</h3>
            <p>(0)1604 622491 | <a href="mailto:hello@purplemedia.co.uk">hello@purplemedia.co.uk</a></p>
            <div class="ads">
                <div class="ads__item">
                    <video muted loop autoplay src="https://player.vimeo.com/external/589788700.hd.mp4?s=aee21c7d6740efeb51f53afba9155d57216910a5&profile_id=174"></video>
                    <img src="~@/images/xp/energy.jpg" alt="" class="ads__item__img">
                </div>
                <div class="ads__item">
                    <video muted loop autoplay src="https://player.vimeo.com/external/595270581.hd.mp4?s=e4a83d9607ad8a38c29eb1cae7b1f22d6a1a612f&profile_id=174"></video>
                    <img src="~@/images/xp/fragrance.jpg" alt="" class="ads__item__img">
                </div>
                <div class="ads__item">
                    <!-- <video muted loop autoplay src="https://player.vimeo.com/external/589788700.sd.mp4?s=1e07147395624f2526c9588716a080cbe4eebdaf&profile_id=165"></video> -->
                    <img style="pointer-events: none; opacity: 1;" src="~@/images/xp/clean.jpg" alt="" class="ads__item__img">
                </div>
            </div>
            <div class="eMenu">
                <img src="~@/images/home/engage.png" alt="" class="eMenu__item" v-on:click="engage">
                <img src="~@/images/home/e-split.png" alt="" class="eMenu__split">
                <img src="~@/images/home/experience.png" alt="" class="eMenu__item" v-on:click="experience">
                <img src="~@/images/home/e-split.png" alt="" class="eMenu__split">
                <img src="~@/images/home/empower.png" alt="" class="eMenu__item" v-on:click="empower">
            </div>
        </div>
    </div>
</template>

<script>
import router from '../router';
import $ from 'jquery';
export default {
    name: 'Experience',
    methods: {        
        engage: function() {
            document.querySelector("html").scrollTop = 0
            router.push('/engage-intro');
        },
        experience: function() {
            document.querySelector("html").scrollTop = 0
            router.push('/experience-intro');
        },
        empower: function() {
            document.querySelector("html").scrollTop = 0
            router.push('/empower-intro');
        },
    },
    mounted: function() {
        const video = document.getElementById("experienceVideo");

        video.onended = function() {
            $(".contact-us").addClass("active");
            // router.push('/menu');

            $(".ads__item").on('click', function(){
                if (window.innerWidth < 992) {
                    $(this).find(".ads__item__img").toggleClass("active");
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>

    $bg: #9e3d3d;
    $color: #fff59b;

    .ads {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin: 20px 0;
        @media (min-width: 992px) {
            flex-direction: row;
            margin: 80px 0;
        }
        &__item {
            position: relative;
            margin: 20px 0;
            height: 80vw;
            width: 80vw;
            box-shadow: 5px 5px 5px 0 rgba(0,0,0,0.3);

            @media (min-width: 992px) {
                margin: 0 20px;
                height: 400px;
                width: 400px;
            }

            & video {
                width: 100%;
                pointer-events: none;                
            }
            &__img {
                position: absolute;
                top:0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 1;
                transition: opacity .25s ease-out;
                &:hover {
                    @media (min-width: 992px) {
                        opacity: 0;
                    }
                }
                &.active {
                    @media (max-width: 991px) {
                        opacity: 0;
                    }
                }
            }
            
        }

    }

    .experience {
        width: 100%;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #7f9453;
        & > video {
            width: 100%;
            height: 100%;
            max-width: calc(100% - 120px);
            max-height: calc(100% - 120px);
            &::-webkit-media-controls-panel {
                background-image: none;
            }
        }
    }
    .contact-us {
        position: fixed;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        background: $bg;
        color: $color;
        pointer-events: none;
        opacity: 0;
        transition: all .5s ease-out;
        padding: 120px 10px;
        text-align: center;
        overflow: auto;
        @media (min-width: 992px) {
            padding: 120px 60px;
        }

        &.active {
            opacity: 1;
            pointer-events: auto;
        }

        & h3 {
            text-transform: uppercase;
            font-family: futura-pt;
            font-weight: 700;
            font-size: 2rem;
            margin-bottom: 20px;
        }
        & p,
        & a {
            font-family: futura-pt;
            font-weight: 400;
            color: inherit;
            text-decoration: none;
            font-size: 1.5rem
        }
    }
    .eMenu {
        @media (max-width: 768px) {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        &__item {
            cursor: pointer;
            margin: 0px 5px;
            transition: all .02s ease-out;
            cursor: pointer;
            height: 20px;
            &:hover {
                transform: scale(0.97);
            }
            @media (max-width: 768px) {
                margin-bottom: 40px
            }
        
        }
        &__split {
            margin: 0px 5px;
            height: 30px;
            @media (max-width: 768px) {
                display: none;
            }
        }
    }

</style>

