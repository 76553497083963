<template>
    <g id="tree" class="nature-outline">
        <path class="cls-1" d="M8.26,215.17a205.09,205.09,0,0,0,63.87,89.9c24.66,19.9,57.37,34.28,87.78,25.35,5.29-1.55,10.36-3.77,15.63-5.38,20.15-6.17,42.05-3.21,62.6-7.88,19-4.33,35.95-15,52-26.08,11.84-8.2,24.16-17.76,28.17-31.6,1.62-5.6,1.73-11.52,2.14-17.34a165.18,165.18,0,0,1,11.07-48.91c3.61-9.17,8.13-18.38,8.07-28.24,0-8.18-3.24-16-6.39-23.5q-5.35-12.81-10.69-25.61C315.43,98.93,308.23,81.77,297,67.27c-14-18-33.44-30.86-53.36-41.9C221.45,13.1,197.61,2.53,172.33,1c-21.88-1.35-43.55,4.12-64.8,9.55-9.2,2.35-18.56,4.77-26.62,9.77C70,27.06,62.31,38,52.29,46.09c-6.78,5.48-14.68,9.66-20.53,16.13-5.46,6-8.76,13.69-12,21.18C13,99.19,6.2,115.13,2.78,132a94.48,94.48,0,0,0,1.15,43.79C7.42,188.86,3.32,201.41,8.26,215.17Z"/>
        <path class="cls-2" d="M146.83,163.37a5.4,5.4,0,0,0-.3,7,10.47,10.47,0,0,0,4.23,3.23c4.08,1.88,7.84,2,11.41,2.06a160.82,160.82,0,0,0,21.89-1.32c3.42-.45,6.92-1.11,8.55-3.82s.59-8.16-3.25-11.77a22,22,0,0,0-5.27-3.41C166.24,146.16,154.9,153.5,146.83,163.37Z"/>
        <path class="cls-2" d="M181.07,158.55c-5.7-5.08-13.72-5.47-16.55-.8a5.65,5.65,0,0,0-.87,3.16c.12,3.32,3.41,7,6.74,8.59a15.07,15.07,0,0,0,9.51.84c3-.57,6.1-1.87,6.4-5.13s-3.19-4.71-6.75-7.86"/>
        <path class="cls-2" d="M24.92,87.22a108,108,0,0,0-10.67,24.17c-7.31,24.29-5.82,50.3-2.82,75.49C12.51,196,13.8,205.16,17,213.74c4,10.62,10.76,19.93,17.54,29,14.8,19.86,30.26,39.64,49.92,54.71s44.19,25.17,68.86,23c13.3-1.17,26-5.8,38.93-9.11,20.16-5.16,41.1-7.16,61-13.37s39.35-17.58,49-36c4.86-9.23,7-19.63,9-29.86q5.06-25.39,10.12-50.8c1.59-8,3.18-16,3.13-24.07-.12-19.71-9.82-38-19.73-55-14.55-25-31.74-50.8-58.4-62-8.55-3.6-17.71-5.5-26.49-8.51-16.26-5.57-31.21-14.92-48-18.73C117,.54,52.45,41.14,24.92,87.22Z"/>
        <path class="cls-1" d="M22.36,105.32c-6.53,17.12-6.39,36-6.17,54.27.19,15,.42,30.34,5.21,44.58,4.29,12.74,12,24,20,34.8,19,25.65,40.51,50.2,67.84,66.69,4.28,2.59,8.77,5,13.65,6.12,6.59,1.53,13.47.64,20.17-.25l21.18-2.81c25.71-3.41,51.71-6.9,75.83-16.44s46.52-25.91,58-49.14c6.85-13.84,9.53-29.35,11.09-44.71,1.5-14.76,2-29.76-.77-44.32-2.12-11-6.09-21.48-10-31.92L286.22,90c-1.86-4.89-3.73-9.83-6.59-14.21-8.45-12.92-23.93-19-38.42-24.32L169.24,25c-6.08-2.23-12.26-4.48-18.72-4.93-5.93-.4-11.86.74-17.66,2a166.78,166.78,0,0,0-28.41,8.51c-17.23,7.2-32.2,19.88-46.9,31.19C42.65,73.31,29.17,87.49,22.36,105.32Z"/>
        <path class="cls-2" d="M96.53,42.68C83.66,47.17,73.74,57.41,64.68,67.59q-8.27,9.27-16.15,18.86c-6.6,8-13.09,16.36-17.13,25.94-3.86,9.15-5.33,19.11-6.62,29-3,23.21-5,47.71,4.22,69.21,6.41,14.93,17.72,27.11,28.53,39.23,8.48,9.52,16.85,19.26,26.89,27.12s22,13.83,34.74,14.52c5.56.31,11.15-.39,16.7.07,6.7.54,13.14,2.76,19.72,4.19,15.24,3.34,31.12,2.5,46.56.24a149.31,149.31,0,0,0,31.51-7.67c38.35-14.55,66.21-53.34,67.75-94.33.35-9.24-.56-18.65-4-27.23-2.44-6.08-6.12-11.65-8-17.93-3.62-12.1-.16-25.19-1.9-37.69-3.18-22.92-23.23-40-44.42-49.34s-44.46-13-65.8-21.9c-8.9-3.73-17.49-8.38-26.86-10.67-9.87-2.41-18.12-1.33-26.54,3.82C115.14,38.33,106.19,39.3,96.53,42.68Z"/>
        <path class="cls-2" d="M104.9,45.27c-8.76,3-17.72,6.21-24.94,12C74.73,61.53,70.64,67,66.63,72.37c-11.51,15.45-23,31.08-31.15,48.55S22.79,158,26,177c1.83,11,6.19,21.48,11.2,31.47,4.76,9.51,10.2,18.77,17.27,26.71,14.92,16.76,36,26.52,56.37,35.83l15.85,7.23c8.11,3.69,16.27,7.41,24.92,9.57,10.78,2.69,22,2.88,33.13,3.05,8.69.14,17.45.27,26-1.44,9.17-1.84,17.81-5.78,25.93-10.43,19.16-11,36.18-26.45,46.21-46.11s12.44-43.71,3.73-64c-2-4.61-4.53-9.08-5.33-14-.7-4.34,0-8.76.15-13.16.52-14.62-4.93-28.77-11.23-42-3.56-7.48-7.56-15-13.81-20.43a56,56,0,0,0-10.68-7,163.1,163.1,0,0,0-34.95-13.6c-19.31-5.06-38.46-7.45-56.08-17.42C136.56,31.14,123.52,38.8,104.9,45.27Z"/>
        <path class="cls-2" d="M85.85,60.68a18.59,18.59,0,0,0-6.23,2.19,18,18,0,0,0-4.15,4.24A274.77,274.77,0,0,0,38,132c-3.14,7.71-5.95,15.73-6.21,24s2,16.4,4.28,24.37c5.14,18.11,10.65,36.9,23.27,50.87,8.72,9.66,20.24,16.29,31.74,22.41,12.71,6.77,25.83,13.18,39.95,16.06,20.94,4.28,43.55.7,63.21,9.08,4.41,1.88,8.65,4.35,13.38,5.12,9.5,1.53,18.54-4.07,26.55-9.43,7.26-4.86,14.58-9.77,20.62-16.08,6.7-7,11.62-15.46,16.17-24,8-15.1,15.29-32.32,11-48.87-1.55-6-4.53-11.43-6.32-17.31-2.37-7.8-2.57-16.06-3.48-24.17a138.64,138.64,0,0,0-13-44.93,57.41,57.41,0,0,0-7.81-12.58C242.28,76,228.11,71.7,214.72,67.85L158,51.54C135,44.94,108.47,55.65,85.85,60.68Z"/>
        <path class="cls-2" d="M100.07,64c-7.49,1.28-15,3.41-21.1,8-7.16,5.43-11.46,13.72-15.54,21.72L49,122.08c-4.3,8.45-8.65,17-10.44,26.35-3.6,18.82,3.64,37.84,10.75,55.64,2.36,5.9,4.78,11.9,8.78,16.84,3.77,4.65,8.75,8.13,13.67,11.54,16.83,11.69,34.15,23.6,54,28.66,13.56,3.46,27.71,3.55,41.7,3.63l38.22.21c7.54,0,15.17.07,22.47-1.79,23.51-6,38-29.91,44.41-53.31,1.63-6,2.9-12.12,2.39-18.27-.57-6.72-3.23-13.05-5.18-19.5-4.61-15.18-5.36-31.25-8.6-46.78s-9.63-31.39-22.44-40.76c-5.51-4-11.91-6.65-18.35-8.94C183.49,62.46,138.94,57.33,100.07,64Z"/>
        <path class="cls-2" d="M107.23,73.1c-9.78,2.07-19.66,5.12-27.37,11.48-6.31,5.2-10.77,12.29-14.58,19.53a167.85,167.85,0,0,0-18.4,61.21,59.25,59.25,0,0,0-.11,13.83c.95,7,3.93,13.59,7.64,19.6C65.56,216.85,83,230.11,101,241.53c12,7.68,24.93,14.88,39.11,16.65,9.74,1.22,19.69-.18,29.39,1.37,4.66.74,9.22,2.16,13.94,2.38,7.6.37,15-2.36,22.43-3.84,14.25-2.83,31-1.9,40.69-12.71,2.85-3.18,4.75-7.09,6.46-11,10.3-23.49,15.36-50.82,6.07-74.72-2-5.08-4.55-9.89-6.47-15-6.34-16.92-5.56-37.17-17.48-50.76-9.16-10.45-23.6-14.22-37.2-17.08C166.66,70.26,138.82,66.41,107.23,73.1Z"/>
        <path class="cls-2" d="M97.41,84.1c-6.2,2-12.51,4.53-17,9.26-5,5.23-7.09,12.5-8.9,19.49Q67.85,127,64.9,141.35c-2.25,11-4.18,22.19-2.61,33.25,3.64,25.46,24.8,44.63,46.32,58.72,7.54,4.93,15.43,9.6,24.13,12,7.51,2,15.37,2.28,23.14,2.51l54.51,1.64c10.77.32,22.79.2,30.6-7.22,5.16-4.89,7.39-12,9.43-18.84,3.55-11.85,7.13-24,6.46-36.34-1-18.36-11.15-34.79-16.42-52.4-4.49-15-6.69-32.93-20-41.22-9.72-6-22.82-7.88-33.66-11.21-7.6-2.34-15.15-5.54-23-6.75C142.33,72.15,117.71,77.44,97.41,84.1Z"/>
        <path class="cls-2" d="M125.54,92.25c-7.8.41-15.65-.58-23.42.17S86.26,96,81.68,102.35c-2.42,3.35-3.66,7.4-4.86,11.37-6.94,23-13.82,48.12-4.81,70.36,7.93,19.6,26.51,32.49,44.38,43.78,6.16,3.89,12.42,7.76,19.33,10,7.93,2.63,16.4,3.08,24.75,3.5l27.88,1.42c16,.82,33.8,1.06,45.9-9.46a38.52,38.52,0,0,0,11.14-18c5.59-18.34.08-38-5.41-56.38L229.54,124.1c-2.13-7.13-4.47-14.6-9.9-19.69-3.34-3.12-7.59-5.08-11.81-6.85C194.08,91.79,178,85.43,163,84.15,150.21,83.05,138.57,91.55,125.54,92.25Z"/>
        <path class="cls-2" d="M144.4,154.68c-3.49,2.5-6.57,6.19-6.66,10.48-.11,5.72,5,10.18,10.26,12.51,11.31,5,24.29,4.05,36.56,2.45,4.17-.54,8.73-1.37,11.38-4.62,3.95-4.83,1.48-12.23-2.42-17.1C181.45,143.33,159.6,143.81,144.4,154.68Z"/>
        <path class="cls-2" d="M139.09,148.13c-4.23,4.19-8.27,9.33-8.28,15.28,0,7.88,7,14,14.11,17.38a62.06,62.06,0,0,0,37.34,5c7.77-1.36,16.29-5.12,18.47-12.7,1.25-4.38.06-9.09-1.75-13.27-4.82-11.17-14.21-19.09-26.39-19.47C159.56,139.89,149.17,138.13,139.09,148.13Z"/>
        <path class="cls-2" d="M138.27,140.62c-9.57,6.24-15.41,19-11.13,29.57,3.4,8.41,11.85,13.45,19.76,17.89,6.49,3.64,13.26,7.37,20.69,7.86,5.51.37,11-1.1,16.31-2.54,6.64-1.81,13.76-3.92,17.94-9.39,5.39-7,4-17.15.61-25.35-2.81-6.87-6.87-13.35-12.6-18.08C177.28,130.22,151.46,132,138.27,140.62Z"/>
        <path class="cls-2" d="M138,133.37c-3.41.48-7,1-9.63,3.33-2.1,1.84-3.2,4.54-4.15,7.16a136.34,136.34,0,0,0-4.29,14.24c-1,3.92-1.71,8.14-.28,11.91s4.69,6.33,7.89,8.67a205.67,205.67,0,0,0,39,22.45c8.55,3.74,18.78,6.81,26.85,2.11,4.91-2.85,7.92-8,10.7-13,2.56-4.57,5.15-9.26,6-14.43,2-11.77-5-23-11.75-32.87-2.36-3.46-4.77-7-8.07-9.56C176.16,122.31,153.71,131.17,138,133.37Z"/>
        <path class="cls-2" d="M126,131.65a16.37,16.37,0,0,0-6.89,3.54c-2.29,2.22-3.3,5.41-4.24,8.46-3.86,12.57-7.14,27.93,1.88,37.51,3.44,3.66,8.18,5.78,12.78,7.8l40.22,17.66c5.05,2.22,10.39,4.49,15.88,3.91,7.73-.82,13.66-7,18.86-12.76s10.44-11.91,10.84-19.49c.21-3.9-.94-7.73-2.13-11.45q-5.37-16.89-11.91-33.37a15.14,15.14,0,0,0-3.29-5.69c-1.86-1.76-4.45-2.47-6.95-3C169.39,120,146.64,125.41,126,131.65Z"/>
        <path class="cls-2" d="M112.58,130c-3.5,4.26-4.69,9.91-5.77,15.31-1.43,7.14-2.87,14.39-2.38,21.65s3.14,14.69,8.67,19.43c3.49,3,7.86,4.73,12.14,6.42l54.24,21.33a25.44,25.44,0,0,0,7.1,2c5.13.41,10-2.34,13.92-5.71,11.45-9.9,17-25.45,17.19-40.59s-4.4-30-10.49-43.82a8.56,8.56,0,0,0-8.38-6.41,158.94,158.94,0,0,0-49.26.42C140.53,121.53,118.72,122.52,112.58,130Z"/>
        <path class="cls-2" d="M100.62,137a158.37,158.37,0,0,0-3.17,29.38c-.08,6,.3,12.34,3.73,17.24,3,4.33,8,6.84,12.77,9.12A733.05,733.05,0,0,0,188,223.22c5.32,1.86,11,3.68,16.5,2.36,10.75-2.6,15.14-15.39,16.43-26.37,2.88-24.37-.51-49-3.94-73.29-.5-3.56-1.18-7.44-3.92-9.76-1.78-1.51-4.17-2.09-6.46-2.55-21.85-4.39-44.31-2.51-66.16.87C123.34,117.14,104.71,116.91,100.62,137Z"/>
        <path class="cls-2" d="M90.22,173.75c1.06,7.36,2.9,14.94,7.7,20.62s12.16,8.87,19.17,11.75l28.42,11.67c17.73,7.28,35.86,14.66,55,15.8,3.88.23,7.9.17,11.44-1.41,9.57-4.29,11.25-16.92,11.57-27.4l2-64.54c.3-10.05,0-21.59-7.75-28-4.74-3.93-11.19-4.92-17.31-5.55a267.16,267.16,0,0,0-86.93,5.37c-12.93,3-16.94,9.93-21,22.62S88.35,160.81,90.22,173.75Z"/>
        <path class="cls-1" d="M80.5,129.36A64.39,64.39,0,0,0,87.74,191c11.87,16.27,30.6,25.83,48.62,34.81,7.58,3.77,15.22,7.57,23.4,9.76,13.69,3.68,28.14,2.67,42.27,1.64,9.15-.66,18.83-1.52,26.25-6.91,11.88-8.63,13.73-25.86,10.75-40.25s-9.62-28-10.83-42.64c-.58-7,.12-14.16-.69-21.18s-3.45-14.28-9.15-18.44c-4-2.9-9-4-13.77-5L174,96.46c-6.38-1.33-12.82-2.66-19.34-2.58s-12.86,1.57-19.17,3C112.29,102.33,90.81,104.75,80.5,129.36Z"/>
    </g>
</template>

<script>
export default {
    name: 'Tree'
}
</script>

<style>

</style>