<template>
    <div class="menu">
        <div class="menu__item" v-on:click="engage">
            <div class="menu__inner"></div>
            <p class="menu__text">Engage</p>
        </div>
        <div class="menu__item" v-on:click="experience">
            <div class="menu__inner"></div>
            <p class="menu__text">Experience</p>
        </div>
        <div class="menu__item" v-on:click="empower">
            <div class="menu__inner"></div>
            <p class="menu__text">Empower</p>
        </div>
    </div>
</template>

<script>
import router from '../router'
export default {
    name: 'Menu',
    methods: {      
        changePage: function(e, route){   
            e.target.className += " active glow";

            setTimeout(function(){
                document.querySelector("html").scrollTop = 0;         
                router.push(route);
            },500)
        },
        engage: function(e) {
            this.changePage(e, '/engage-intro');
        },
        experience: function(e) {
            this.changePage(e, '/experience-intro');
        },
        empower: function(e) {
            this.changePage(e, '/empower-intro');
        },
    }
}
</script>

<style lang="scss" scoped>
    $radius: 20px;
    $bg: #b23634;
    $bgdeep: #932a2a;
    $text: #fade4f;
    .menu {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100vh;
        width: 100vw;
        background: $bg;

        @media (min-width: 992px) {                
            flex-direction: row;
        }
        &__item {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            width: 260px;
            height: 100px;
            border-radius: $radius;
            margin: 20px 0;
            background: linear-gradient(to bottom, white -100%, $bg 50%);
            color: $text;
            cursor: pointer;
            text-transform: uppercase;
            font-family: futura-pt;
            font-weight: 700;
            font-size: 30px;
            z-index: 3;
            box-shadow: 
                10px 10px 40px 0 $bgdeep, 
                -10px -10px 40px 0 rgba(232, 91, 91, 1);

            &:before {
                content:'';
                position:absolute;
                top:1px;
                left:1px;
                background: $bg;
                width:calc(100% - 2px);
                height:calc(100% - 2px);
                z-index:3;
                border-radius: $radius;

            }
            
            &.active,
            &:hover {
                transform: scale(.9);
                background: none;
                box-shadow: none;
                font-size: 33px;
                &:before {
                    border-radius: $radius;
                    top: 0px;
                    left: 0px;
                    width: 100%;
                    height: 100%;
                    background: linear-gradient(to top, white -100%, transparent 50%);
                    z-indeX: -1;
                }
                &:after {   
                    content:'';
                    position:absolute;    
                    border-radius: $radius;             
                    top: 1px;
                    left: 1px;
                    width: calc(100% - 2px);
                    height: calc(100% - 2px);
                    background: $bgdeep;
                }
                & .menu__inner {
                    display: block;
                }
            }
            &.glow {
                color: #ffeb89;
                text-shadow: 0 0 20px $text;
            }
            @media (min-width: 992px) {                
                margin: 0 20px;
            }
        }
        &__inner {
            display:none;
            pointer-events: none;
            position:absolute;
            z-index:3;
            top: 3px;
            left: 3px;
            width: calc(100% - 6px);
            height: calc(100% - 6px);
            border-radius: $radius;
            background: linear-gradient(to bottom, white -100%, $bg 50%);

            &:before {
                content:'';
                position:absolute;
                top:1px;
                left:1px;
                background: $bg;
                width:calc(100% - 2px);
                height:calc(100% - 2px);
                z-index:3;
                border-radius: $radius;

            }
        }
        &__text {
            margin:0;
            pointer-events: none;
            position:relative;
            z-index:5;
        }
    }
</style>