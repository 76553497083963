const empowerMachine = {
    dir: 'https://purplemedia.s3-eu-west-1.amazonaws.com/thescience/audio/',
    colgate: {
        color: 'brown',
        plaques: [
            {
                text: 'The benchmark Net Performer Score is 54%<br><br>After PurpleMedia, Colgate scored...',
                value: '79%'
            },
            {
                text: 'The benchmark percentage of learners who scrap learning is 23%<br><br>After PurpleMedia, the percentage of Colgate\'s learners who scrapped learning was...',
                value: '10%'
            }
        ]
    },
    beanbag: {
        color: 'black',
        stems: [
            {
                label: 'Bass',
                src: 'beanbag/bass',
                x: "19.5%",
                y: "47%"
            },
            {
                label: 'Drums',
                src: 'beanbag/drums',
                x: "34.6%",
                y: "47%"
            },
            {
                label: 'Melody',
                src: 'beanbag/melody-lead',
                x: "49.6%",
                y: "47%"
            },
            {
                label: 'Layers',
                src: 'beanbag/melody-layer',
                x: "65%",
                y: "47%"
            }
        ]
    },
    imperial: {        
        color: 'tan',
        plaques: [
            {
                text: 'The average hits per month on Imperial\'s learning website was 40<br><br>After PurpleMedia it was...',
                value: '3750'
            },
            {
                text: 'The cost of learning per hour was £96<br><br>With PurpleMedia it was...',
                value: '£10'
            },
            {
                text: 'Learner satisfaction was previously 56%<br><br>After PurpleMedia it was...',
                value: '85%'
            }
        ]
    },
    worldofdreams: {
        color: 'blue',
        stems: [
            {
                label: 'Bass',
                src: 'worldofdreams/bass',
                x: "19.5%",
                y: "47%"
            },
            {
                label: 'Drums',
                src: 'worldofdreams/drums',
                x: "34.6%",
                y: "47%"
            },
            {
                label: 'Instruments',
                src: 'worldofdreams/instruments',
                x: "49.6%",
                y: "47%"
            },
            {
                label: 'Melody',
                src: 'worldofdreams/melody',
                x: "65%",
                y: "47%"
            },
        ]
    },
    jackdaniels: {        
        color: 'black',
        plaques: [
            {
                text: 'The Number of people using Jack Daniel\'s LMS was 85<br>After PurpleMedia it was...',
                value: '1900+'
            },
            {
                text: 'The average number of people completing the learning was 47<br>After PurpleMedia it was...',
                value: '1300'
            },
        ]
    },
    dharma: {
        color: 'cream',
        stems: [
            {
                label: 'Bass',
                src: 'dharma/bass',
                x: "25.7%",
                y: "45%"
            },
            {
                label: 'Drums',
                src: 'dharma/drums',
                x: "42.2%",
                y: "45%"
            },
            {
                label: 'Instruments',
                src: 'dharma/instruments',
                x: "59.1%",
                y: "45%"
            },
        ]
    },
    vodafone: {        
        color: 'red',
        plaques: [
            {
                text: 'Vodafone were previously not ranked in Stonewall\'s Top 100 index for Diversity and Inclusion.<br>After PurpleMedia, they were ranked...',
                value: '42nd'
            }
        ]
    }
}
export default empowerMachine;