const clients = [
    'VW',
    'ebay',
    'TSB',
    'CocaCola',
    'RSA',
    'Virgin',
    'IHG',
    'GAP',
    'KIA',
    'Imperial',
    'EE',
    'JD',
    'Universal',
    'Gucci',
    'Mazars',
    'CliffordChance',
    'Barclaycard',
    'Boots',
    'DHL',
    'Hyundai',
    'Vodafone',
    'nbn',
    'RoyalMail',
    'EsteeLauder',
    'ClydeAndCo',
    'Sainsburys',
    'Anchor',
    'Sodexo',
    'NortonRose',
    'ColPal'
];
export default clients;