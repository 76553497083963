<template>
    <div>
        <div class="jumbotron">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-lg-6">
                        <h1 class="title"  :style="{'color': '#565b7c'}">The Art of the Difference</h1>
                        <p>The Art of the Difference is a chance to produce your own unique piece of art by understanding elements of your own personality.</p>
                        <p>Your individuality and inner beauty are revealed by answering a few questions honestly; creatively unearthing the artistic differences that make you, you.</p>
                        <p>Let’s make something amazing...</p>
                        <!-- <p v-if="savedData" v-on:click="clearData"><sup>Clear my data</sup></p> -->
                    </div>
                    <div class="col-12 col-lg-6 text-center">
                        <img src="~@/images/quiz/bob-ross.png" alt="" class="bob-ross">
                    </div>
                </div>
            </div>
        </div>
        
        <Accordian v-for="(item, index) in colors" :key="item" :set="(index + 1)" :color="colors[index]" :backgroundColor="backgroundColors[index]"/>

        <section class="accordian bg-8" :style="{'background-color': submitColor}">
            <div class="accordian__head">
                <div class="container">
                    <div class="row text-center">
                        <div class="col-12">
                            <h2 class="accordian__no" v-on:click="submit">Make Art</h2>

                        </div>
                    </div>
                </div>

            </div>
        </section>
    </div>
</template>

<script>

import Accordian from '../components/accordian.vue';
import {questions} from '../tables';
import key from '../fixtures/key';
import router from '../router';
import $ from 'jquery';

export default {
    name: 'quiz',
    props: {
        msg: String
    },
    components: {
        Accordian
    },
    data: function () {

        return {
            questions: questions,
            colors: [
                '#F6B435',
                '#F77883',
                '#555B79',
                '#E5B946',
                '#E5B946',
                '#E5B946',
                '#802527'
            ],
            backgroundColors: [
                '#5398b7',
                '#565b7b',
                '#eb7e83',
                '#d02037',
                '#8b1a22',
                '#bd4b30',
                '#edb81e'
            ],
            background: '#a7c3ce',
            submitColor: 'rgb(23,139,118)'
        };
    },
    computed: {
        savedData() {
            return JSON.parse(localStorage.getItem(key)) !== null;
        }
    },
    methods: {
        checkAnswered() {
            let accordianCount = $(".accordian").length;
            let accordianPassedCount = $(".accordian.passed").length + 1;
            if (accordianCount === accordianPassedCount) {
                $(".accordian:last-of-type").slideDown();
            } else {
                $(".accordian:last-of-type").slideUp();                    
            }
        },
        submit: function() {
            const self = this;
            $(".question.answered").each(function(){
                let $q = $(this),
                    score = $q.attr("data-value"),
                    index = $q.attr("data-q");
                    
                let cAns = self.questions[index].answers;

                for (var i = 0; i < cAns.length; i++) {
                    cAns[i].selected = false;
                }

                cAns[parseInt(score)].selected = true;
            });

            localStorage.setItem(key, JSON.stringify(this.questions));
            router.push('/results');

        },
        restoreAnswers: function () {
            const q = JSON.parse(localStorage.getItem(key))

            // loop
            for (let i = 0; i < q.length; i++) {
                const qa = q[i].answers;

                for (let a = 0; a < qa.length; a++) {
                    const aa = qa[a];

                    if (aa.selected === true) {
                        $(".question[data-q='"+i+"']").closest(".accordian").show();
                        $(".question[data-q='"+i+"']").show();
                        if (i === 6) {
                            $(".accordian").show();
                        }
                    }
                    
                }
                
            }
        },
        clearData() {
            localStorage.removeItem(key);
            var href = window.location.href;
            window.location.href = href;
        }

    },
    mounted: function() {
        try {
            this.restoreAnswers();
        } catch (e) {
            // failed to restore answers, likely nothing in local storage
        }
    }
}
</script>

<style lang="scss" scoped>
    @media (min-width: 992px) {
        .container {
            max-width: 960px;
        }
    }
    .jumbotron {        
        padding: 100px 0;
        background-image: url('~@/images/quiz/bg.svg');
        background-size: cover;
        background-position: center center;
        color: #114c63;
        background-repeat:no-repeat;
    }
    .title {
        margin-bottom: 20px;
        font-family: futura-pt;
        font-weight: 700;
    }
    .bob-ross {
        max-width: 300px;
        float: right;
        clear: both;
        margin-bottom: -50px;
        @media (max-width: 992px) {
            float: none;
            width: 80%;
        }
    }
</style>