<template>
    <g id="dolphin" class="animal-outline">
        <path class="cls-3" d="M132.54,200.48A43.34,43.34,0,0,0,148,189c4.23-5,6.57-11.79,12.14-15.49,5.26-3.5,12-3.93,18.1-2.82,7.14,1.3,13.65,4.76,20.2,7.68,6.91,3.09,14.95,6.33,22.49,3.54l-.24-.42c-9.24,9.14-14.8,22.37-26.76,28.57a35.08,35.08,0,0,1-20,3.34,46.81,46.81,0,0,1-19.9-7.34,58.71,58.71,0,0,0-10.66-5.77c-3.61-1.34-7.5-2.08-11-.15-.28.15,0,.58.26.43,3.84-2.1,8.11-.85,11.91.7a68.29,68.29,0,0,1,10,5.68,46.53,46.53,0,0,0,18.62,6.86,36.48,36.48,0,0,0,19.08-2.4c6-2.6,10.73-7.16,14.8-12.18,4.69-5.79,8.68-12.13,14-17.39.17-.17,0-.51-.24-.42-7.41,2.75-15.31-.45-22.1-3.48-6.49-2.89-12.92-6.3-19.95-7.67-5.51-1.06-11.51-.91-16.65,1.55a18.63,18.63,0,0,0-6.8,5.66c-2,2.66-3.53,5.64-5.46,8.35a42.79,42.79,0,0,1-17.55,14.22c-.3.13,0,.56.25.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M137.34,199a46.59,46.59,0,0,0,13.45-8c4.14-3.73,6.15-9.35,10.77-12.59,4.77-3.34,11.1-3.66,16.66-2.59,6.11,1.19,11.77,3.9,17.61,5.95a45.59,45.59,0,0,0,9.82,2.4,25.65,25.65,0,0,0,5.4.09c.76-.08,1.53-.19,2.28-.34.3-.06.59-.12.88-.2s.59-.15.87-.24c.42-.27.71-.19.86.23-.23-.22-.59.13-.35.35.74.69,1.71-1.31-.08-1.18a15.45,15.45,0,0,0-1.87.46,19.56,19.56,0,0,1-3,.45,26.07,26.07,0,0,1-5.37-.17c-7.13-.92-13.61-4.18-20.33-6.47-5.75-2-12-3.21-18-1.68a16.91,16.91,0,0,0-7.21,3.77c-2.26,2.05-3.85,4.68-5.59,7.15-4.1,5.83-10.47,9.45-16.93,12.16-.3.12-.17.6.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M218.73,182.94c-1.19,1.16-2.65,2-3.88,3.15a57.61,57.61,0,0,0-5.08,5.73c-3.48,4.14-6.88,8.39-11,12a30.86,30.86,0,0,1-13.58,7.29c-5.11,1.08-10-.17-14.78-1.85s-9.76-3.5-15-2.65c-.31.05-.18.54.14.48,5.12-.83,10,1,14.72,2.65,4.5,1.57,9.06,2.81,13.86,2.07,10.54-1.64,18-10.12,24.51-17.86,2-2.35,3.9-4.76,6-7,1.31-1.37,3-2.31,4.37-3.64.23-.22-.13-.58-.36-.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M140.42,199.7c4.84,1.24,9.71,2.29,14.6,3.29s9.62,2.07,14.46,2.95a45.82,45.82,0,0,0,6.91.78c2.38.07,4.76-.23,7.15-.27a36.94,36.94,0,0,0,9.6-1.64,36.39,36.39,0,0,0,10.73-5.46c.26-.18,0-.61-.26-.43-5.6,3.93-12.25,6.81-19.2,7-2.2.07-4.4.25-6.6.3a39.14,39.14,0,0,1-7.25-.61c-4.68-.78-9.32-1.88-14-2.82-5.37-1.08-10.73-2.22-16-3.58a.25.25,0,0,0-.14.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M138.79,199.4c9.56-2.27,19.29-.55,28.82.94,9.14,1.43,18.35,2.45,27.45.21a47.88,47.88,0,0,0,14.18-6.06c.28-.18,0-.61-.25-.44a48,48,0,0,1-27.23,7.35c-9.37-.28-18.51-2.66-27.84-3.41a48.9,48.9,0,0,0-15.26.93.25.25,0,0,0,.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M140,198.6c9.56-3.3,19.71-4.09,29.76-3.89,5,.09,10,.42,15,.79,5.21.38,10.49,1,15.71.32a21.3,21.3,0,0,0,12.47-5.59c.23-.23-.13-.58-.35-.36-7.33,7.26-18.49,5.81-27.83,5.13-9.73-.72-19.54-1.3-29.27-.27a71.73,71.73,0,0,0-15.66,3.38c-.3.11-.17.59.14.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M144.49,196.77a67.16,67.16,0,0,1,29.8-6.8,125.16,125.16,0,0,1,16.16,1.65c5,.68,10.29,1.38,15.29.5,3.94-.69,7.91-3.58,10.51-6.53.21-.24-.14-.59-.36-.35-2.51,2.85-6.34,5.65-10.14,6.37-5.38,1-11.26.08-16.61-.68a110.46,110.46,0,0,0-15.07-1.46A66.7,66.7,0,0,0,159.84,191a68.05,68.05,0,0,0-15.61,5.39c-.28.14,0,.57.26.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M215.54,182.8a35.32,35.32,0,0,1-13.15,5.3,39.71,39.71,0,0,1-15-.48c-4.59-.92-9.13-2-13.84-2a44.74,44.74,0,0,0-13.15,1.94,48.53,48.53,0,0,0-13.5,6.51c-.26.18,0,.61.25.43a46.56,46.56,0,0,1,26.4-8.38c5,0,9.76,1.27,14.63,2.19a39.3,39.3,0,0,0,13.55.45,35.5,35.5,0,0,0,14.11-5.5c.27-.17,0-.61-.25-.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M149.08,193.64c6.23-6.1,13-12.61,21.88-14.26,9.07-1.68,17.14,3.93,25.68,5.91a33.47,33.47,0,0,0,14.26.42c.32-.07.19-.55-.13-.48a33.21,33.21,0,0,1-14.44-.53c-4.36-1.06-8.43-2.88-12.65-4.36a25,25,0,0,0-12.21-1.55A28.67,28.67,0,0,0,160,183.55a81.32,81.32,0,0,0-11.24,9.73c-.23.23.13.58.36.36Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M134,199.62a43.05,43.05,0,0,1-21.86-2.71c-3.23-1.32-6.21-3.16-9.46-4.43a18.77,18.77,0,0,0-8.86-1.28c-13.6,1.46-21.07,14.4-28,24.55-4.17,6.08-9.2,12.88-16.89,14.4a.25.25,0,0,0,0,.48c13.67,3.19,26.31,12.47,40.92,9.94,12.52-2.16,22.83-11.55,28.48-22.62,1.91-3.75,3.55-7.63,6.06-11,2.4-3.25,5.36-6.49,9.66-6.7.32,0,.32-.51,0-.5-6.41.32-10.59,7-13.24,12.09-1.64,3.17-3.05,6.46-4.94,9.51a44.88,44.88,0,0,1-6.11,7.77,38.69,38.69,0,0,1-16.65,10.22c-6.87,2-13.84,1.24-20.58-.88C64.59,235.94,57.16,232,49,230.15v.48c6-1.19,10.53-5.66,14.12-10.35,4.25-5.56,7.67-11.73,12.14-17.13,4.3-5.19,9.9-9.72,16.63-11.14a18.16,18.16,0,0,1,10.21.8c3.24,1.21,6.2,3.05,9.38,4.38a43.36,43.36,0,0,0,22.6,2.91c.32,0,.18-.52-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M129.05,200.87c-6.21,1-13.15,1.45-19.15-.8-3.24-1.21-6.27-3-9.68-3.74a16.84,16.84,0,0,0-8.68.51c-12.85,4.09-17.85,18-27.39,26.27a27,27,0,0,1-9.45,5.28c-.54.18-1.55.59-1.11,1.33a.6.6,0,0,0,1.11-.08.25.25,0,0,0-.48-.13c.25-.89,2.82-1.35,3.64-1.71a24.4,24.4,0,0,0,3.77-2.11,36.63,36.63,0,0,0,5.9-5.15c4.11-4.38,7.34-9.5,11.29-14s8.84-8.52,14.84-9.73a16.81,16.81,0,0,1,9.38.91c2.69,1,5.22,2.44,8,3.27,5.82,1.74,12.24,1.3,18.16.37.32,0,.18-.53-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M51.34,230.84c1.72.39,3.52.33,5.25.71a81.55,81.55,0,0,1,8.23,2.55c5.48,1.84,11,3.73,16.8,4.36a28.66,28.66,0,0,0,15-1.93c4.13-1.88,7.17-5.14,10-8.59,3.23-4,6.42-8.16,11.3-10.16.29-.12.16-.6-.14-.48-8.19,3.36-11.41,12.4-18.51,17.18-9.77,6.59-22.26,3.14-32.49-.26-3.12-1-6.23-2.13-9.4-3-1.91-.53-3.93-.44-5.86-.87-.31-.07-.45.41-.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M126.57,203.23c-6.65,6.88-13.71,13.43-20.76,19.9a49.86,49.86,0,0,1-5.36,4.37c-1.82,1.26-3.8,2.16-5.71,3.27a32.4,32.4,0,0,1-5.95,3c-1.28.43-2.56.87-3.86,1.25a35.85,35.85,0,0,1-12.21,1c-.32,0-.32.48,0,.5,6.93.41,14.19-.66,20.29-4.17,3.83-2.21,7.68-4.17,11.08-7.06,3.56-3,6.86-6.36,10.28-9.54,4.27-4,8.5-8,12.56-12.19a.25.25,0,0,0-.36-.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M128.15,202c-17.38,5.71-27.7,22.8-44.61,29.43a47.69,47.69,0,0,1-18,3.26c-.32,0-.32.49,0,.5a49,49,0,0,0,28.24-8.73c7.4-5,13.72-11.46,20.94-16.74a49.07,49.07,0,0,1,13.51-7.24c.31-.1.18-.58-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M126.62,201.9c-9.15,2-17.63,6.19-25.56,11.12-4,2.5-7.93,5.2-11.79,8s-7.9,5.94-12.15,8.44c-5,3-10.9,5-16.76,3.61-.32-.07-.45.41-.14.49,9.93,2.3,18.56-4.35,26.1-9.81C94.22,218,102.22,212.34,111,208a70.66,70.66,0,0,1,15.77-5.65c.31-.07.18-.56-.14-.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M121.93,202.71A67.94,67.94,0,0,0,93.69,212c-8.61,5.18-15.34,13-24.21,17.75a15.42,15.42,0,0,1-7.23,1.94,23.08,23.08,0,0,1-7.16-.89.25.25,0,0,0-.13.48,21.1,21.1,0,0,0,11.55.34,23.9,23.9,0,0,0,5.93-3,67.21,67.21,0,0,0,5.84-4.23c4.07-3.24,7.93-6.76,12.23-9.72a66.91,66.91,0,0,1,31.42-11.46c.32,0,.32-.53,0-.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M54.12,229.07a36.19,36.19,0,0,0,25-9.12c3.69-3.22,7-6.86,11-9.65a43.24,43.24,0,0,1,12.53-5.87,48.31,48.31,0,0,1,15.82-1.81c.32,0,.32-.48,0-.5a47.23,47.23,0,0,0-25.83,6c-8.24,4.74-13.7,13-22.37,17.11a35.16,35.16,0,0,1-16.18,3.34.25.25,0,0,0,0,.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M116.51,202.47c-8.76-2-18.3-4.06-26.94-.44-8.1,3.38-11.91,11.71-17.75,17.71a33.37,33.37,0,0,1-12.38,8.39c-.3.11-.17.59.14.48,7.63-2.77,13.22-8.66,18-15,2.68-3.54,5.4-7.23,9.22-9.62a25.1,25.1,0,0,1,12.84-3.45c5.66-.12,11.23,1.15,16.72,2.4.31.07.44-.41.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M305.27,151.58a43.48,43.48,0,0,0,15.46-11.49c4.23-5,6.57-11.79,12.14-15.49,5.26-3.5,12-3.93,18.1-2.82,7.14,1.3,13.64,4.76,20.2,7.68,6.91,3.09,14.95,6.33,22.49,3.54l-.24-.42c-9.24,9.14-14.8,22.37-26.76,28.57a35,35,0,0,1-20,3.34,46.81,46.81,0,0,1-19.9-7.34,57.82,57.82,0,0,0-10.67-5.76c-3.6-1.35-7.49-2.09-11-.16-.28.15,0,.58.25.43,3.85-2.1,8.11-.85,11.92.7a67.44,67.44,0,0,1,10,5.69,46.52,46.52,0,0,0,18.62,6.85A36.48,36.48,0,0,0,365,162.5c6-2.6,10.73-7.16,14.8-12.18,4.68-5.79,8.68-12.13,14-17.39.17-.16,0-.5-.24-.42-7.41,2.75-15.31-.45-22.11-3.48-6.48-2.89-12.91-6.3-19.94-7.66-5.51-1.07-11.52-.92-16.65,1.54a18.72,18.72,0,0,0-6.8,5.66c-2,2.66-3.53,5.64-5.46,8.35A42.79,42.79,0,0,1,305,151.14c-.3.13,0,.56.25.44Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M310.07,150.08a46.59,46.59,0,0,0,13.45-8c4.14-3.73,6.15-9.35,10.77-12.59s11.1-3.66,16.66-2.59c6.11,1.19,11.77,3.9,17.61,5.95a45.65,45.65,0,0,0,9.81,2.4,25.74,25.74,0,0,0,5.41.09,19.59,19.59,0,0,0,2.28-.34c.3-.06.59-.12.88-.2l.87-.24c.42-.27.71-.19.86.23-.23-.22-.59.13-.35.35.74.69,1.71-1.31-.08-1.18a13.83,13.83,0,0,0-1.87.47,22.29,22.29,0,0,1-3,.45,26.79,26.79,0,0,1-5.37-.18c-7.13-.92-13.61-4.17-20.33-6.47-5.75-2-11.95-3.21-18-1.68a16.91,16.91,0,0,0-7.21,3.77c-2.26,2.05-3.85,4.68-5.59,7.15-4.1,5.83-10.47,9.45-16.93,12.16-.3.12-.17.6.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M391.46,134c-1.19,1.16-2.65,2-3.88,3.16a56.4,56.4,0,0,0-5.08,5.72c-3.48,4.14-6.88,8.39-11,12A30.86,30.86,0,0,1,358,162.15c-5.11,1.08-10-.17-14.78-1.85s-9.76-3.5-15-2.65c-.31,0-.18.54.14.49,5.12-.84,10,1,14.72,2.64,4.5,1.57,9.06,2.82,13.86,2.07,10.54-1.64,18-10.11,24.5-17.85,2-2.36,3.91-4.77,6-7,1.32-1.37,3-2.31,4.38-3.64.23-.22-.13-.57-.36-.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M313.15,150.79c4.84,1.24,9.71,2.3,14.6,3.29s9.62,2.07,14.46,2.95a47.16,47.16,0,0,0,6.91.79c2.38.06,4.76-.23,7.15-.28a36.94,36.94,0,0,0,9.6-1.64,36.39,36.39,0,0,0,10.73-5.46c.26-.18,0-.61-.26-.43-5.6,3.93-12.25,6.81-19.2,7-2.2.07-4.4.26-6.6.3a39.32,39.32,0,0,1-7.26-.61c-4.67-.78-9.31-1.88-14-2.82-5.37-1.08-10.73-2.22-16-3.58-.32-.08-.45.41-.14.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M311.52,150.5c9.56-2.28,19.29-.56,28.82.93,9.14,1.43,18.35,2.45,27.45.21A48,48,0,0,0,382,145.58c.27-.17,0-.61-.25-.43a48.1,48.1,0,0,1-27.23,7.34c-9.37-.28-18.51-2.66-27.84-3.41a48.9,48.9,0,0,0-15.26.93.25.25,0,0,0,.13.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M312.68,149.69c9.56-3.3,19.71-4.09,29.76-3.89,5,.09,10,.42,15,.79,5.21.38,10.49,1,15.71.32a21.33,21.33,0,0,0,12.47-5.59c.23-.23-.13-.58-.36-.35-7.32,7.25-18.48,5.81-27.82,5.12-9.73-.72-19.54-1.3-29.27-.27a71.05,71.05,0,0,0-15.66,3.39c-.3.1-.17.58.14.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M317.22,147.86a67.29,67.29,0,0,1,29.8-6.8,125.34,125.34,0,0,1,16.16,1.65c5,.68,10.29,1.38,15.29.5,3.94-.69,7.91-3.58,10.51-6.52.21-.25-.14-.6-.36-.36-2.51,2.86-6.34,5.65-10.14,6.38-5.38,1-11.26.07-16.61-.69a110.46,110.46,0,0,0-15.07-1.46A67.35,67.35,0,0,0,332.57,142,68.05,68.05,0,0,0,317,147.43c-.29.14,0,.57.26.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M388.27,133.89a35.16,35.16,0,0,1-13.15,5.3,39.71,39.71,0,0,1-15.05-.48c-4.59-.92-9.13-2-13.84-2a44.74,44.74,0,0,0-13.15,1.94,48.81,48.81,0,0,0-13.5,6.51c-.26.18,0,.61.25.43a46.56,46.56,0,0,1,26.4-8.38c5,0,9.76,1.27,14.63,2.19a39.3,39.3,0,0,0,13.55.45,35.69,35.69,0,0,0,14.11-5.49c.27-.18,0-.61-.25-.44Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M321.81,144.73c6.23-6.1,13-12.61,21.88-14.26,9.07-1.68,17.14,3.93,25.68,5.91a33.47,33.47,0,0,0,14.26.42c.32-.06.19-.55-.13-.48a33.21,33.21,0,0,1-14.44-.53c-4.37-1.06-8.43-2.88-12.65-4.36a25,25,0,0,0-12.21-1.55,28.67,28.67,0,0,0-11.51,4.76,81.39,81.39,0,0,0-11.24,9.74c-.23.22.13.57.36.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M306.72,150.71A42.94,42.94,0,0,1,284.86,148c-3.23-1.31-6.21-3.16-9.46-4.43a18.64,18.64,0,0,0-8.86-1.27c-13.6,1.46-21.07,14.39-28,24.54-4.17,6.08-9.2,12.88-16.89,14.4a.25.25,0,0,0,0,.48c13.67,3.19,26.31,12.47,40.92,9.95C275.08,189.5,285.39,180.11,291,169c1.91-3.75,3.55-7.63,6.06-11,2.4-3.25,5.36-6.49,9.66-6.7.32,0,.32-.51,0-.5-6.41.32-10.59,7-13.24,12.09-1.65,3.17-3,6.47-4.94,9.51a44.13,44.13,0,0,1-6.11,7.77,38.69,38.69,0,0,1-16.65,10.22c-6.87,2-13.84,1.25-20.58-.87-7.92-2.5-15.35-6.4-23.47-8.29v.48c6-1.18,10.52-5.65,14.11-10.35,4.26-5.56,7.68-11.73,12.15-17.13,4.3-5.19,9.9-9.72,16.63-11.14a18.16,18.16,0,0,1,10.21.8c3.24,1.21,6.2,3,9.38,4.38a43.36,43.36,0,0,0,22.6,2.91c.32,0,.18-.52-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M301.78,152c-6.21,1-13.15,1.45-19.15-.8-3.24-1.21-6.27-3-9.68-3.73a16.73,16.73,0,0,0-8.68.5c-12.85,4.09-17.85,18-27.39,26.27a27,27,0,0,1-9.46,5.28c-.53.18-1.54.59-1.1,1.33a.6.6,0,0,0,1.11-.08.25.25,0,0,0-.48-.13c.25-.89,2.82-1.35,3.64-1.71a23.6,23.6,0,0,0,3.77-2.11,36.63,36.63,0,0,0,5.9-5.15c4.11-4.38,7.34-9.49,11.29-14s8.84-8.51,14.84-9.73a17,17,0,0,1,9.38.91c2.69,1,5.22,2.44,8,3.27,5.82,1.74,12.24,1.3,18.16.38.32,0,.18-.54-.13-.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M224.06,181.93c1.73.39,3.53.33,5.26.71a81.55,81.55,0,0,1,8.23,2.55c5.48,1.84,11,3.73,16.8,4.36a28.66,28.66,0,0,0,15-1.93c4.13-1.87,7.16-5.13,10-8.59,3.24-4,6.43-8.16,11.31-10.16.29-.12.16-.6-.14-.48-8.19,3.36-11.41,12.4-18.51,17.19-9.77,6.58-22.26,3.13-32.49-.27-3.12-1-6.23-2.12-9.4-3-1.91-.52-3.93-.44-5.86-.87-.32-.07-.45.41-.14.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M299.3,154.32c-6.65,6.88-13.71,13.43-20.76,19.9a49.86,49.86,0,0,1-5.36,4.37c-1.82,1.26-3.8,2.17-5.71,3.27a32.4,32.4,0,0,1-6,3c-1.28.43-2.56.87-3.86,1.25a35.85,35.85,0,0,1-12.21,1c-.32,0-.32.48,0,.5,6.93.42,14.19-.65,20.29-4.17,3.83-2.21,7.68-4.17,11.08-7.06,3.56-3,6.86-6.36,10.28-9.54,4.27-4,8.5-8,12.56-12.18.22-.24-.13-.59-.36-.36Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M300.88,153.12c-17.38,5.71-27.7,22.8-44.61,29.43a47.69,47.69,0,0,1-17.95,3.26c-.32,0-.32.49,0,.5a49,49,0,0,0,28.24-8.73c7.4-5,13.71-11.46,20.94-16.74A49.07,49.07,0,0,1,301,153.6c.31-.1.18-.58-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M299.35,153c-9.15,2-17.63,6.18-25.56,11.11-4,2.51-7.93,5.21-11.8,8s-7.89,5.94-12.14,8.45c-5,3-10.9,5-16.76,3.6-.32-.07-.45.41-.14.49,9.93,2.3,18.56-4.35,26.1-9.81,7.9-5.7,15.9-11.37,24.67-15.67a71.16,71.16,0,0,1,15.77-5.65c.31-.07.18-.55-.14-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M294.66,153.8a67.94,67.94,0,0,0-28.24,9.32c-8.61,5.18-15.34,13-24.21,17.75a15.42,15.42,0,0,1-7.23,1.94,22.78,22.78,0,0,1-7.16-.89.25.25,0,0,0-.13.48,21.1,21.1,0,0,0,11.55.34,23.9,23.9,0,0,0,5.93-3,67.21,67.21,0,0,0,5.84-4.23c4.07-3.24,7.93-6.76,12.23-9.72a66.91,66.91,0,0,1,31.42-11.46c.32,0,.32-.53,0-.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M226.85,180.16a36.15,36.15,0,0,0,25-9.12c3.69-3.21,7-6.86,11-9.65a43.5,43.5,0,0,1,12.53-5.87,48,48,0,0,1,15.82-1.8c.32,0,.32-.49,0-.5a47.22,47.22,0,0,0-25.83,6c-8.24,4.74-13.7,13-22.37,17.11a35.16,35.16,0,0,1-16.18,3.34.25.25,0,0,0,0,.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M289.24,153.56c-8.76-2-18.3-4.06-26.94-.44-8.1,3.39-11.91,11.71-17.75,17.71a33.25,33.25,0,0,1-12.38,8.39c-.3.11-.17.59.14.48,7.63-2.77,13.22-8.66,18-15,2.68-3.54,5.39-7.23,9.22-9.62a25.1,25.1,0,0,1,12.84-3.45c5.66-.12,11.23,1.15,16.72,2.4.31.07.44-.41.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M261.58,137.81a43.37,43.37,0,0,0-15.46,11.49c-4.23,5-6.57,11.78-12.14,15.49-5.26,3.5-12,3.92-18.11,2.82-7.13-1.31-13.64-4.76-20.2-7.69-6.91-3.08-14.94-6.33-22.49-3.53l.25.42c9.24-9.15,14.8-22.38,26.75-28.57a35.13,35.13,0,0,1,20-3.35,46.83,46.83,0,0,1,19.9,7.35A58.66,58.66,0,0,0,250.72,138c3.61,1.34,7.5,2.08,11,.16a.25.25,0,0,0-.25-.44c-3.84,2.1-8.11.85-11.91-.7a67.77,67.77,0,0,1-10-5.68,46.52,46.52,0,0,0-18.63-6.86,36.35,36.35,0,0,0-19.07,2.41c-6,2.6-10.73,7.15-14.81,12.18-4.68,5.78-8.68,12.13-14,17.38-.17.17,0,.51.25.42,7.41-2.75,15.31.45,22.1,3.48,6.48,2.9,12.92,6.31,20,7.67,5.51,1.07,11.51.91,16.65-1.55a18.59,18.59,0,0,0,6.79-5.66c2-2.65,3.54-5.64,5.47-8.35a43,43,0,0,1,17.55-14.22c.29-.13,0-.56-.25-.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M256.78,139.3a47.09,47.09,0,0,0-13.46,8c-4.13,3.74-6.14,9.35-10.76,12.59-4.77,3.34-11.11,3.67-16.66,2.59-6.11-1.18-11.77-3.9-17.62-5.94a45.38,45.38,0,0,0-9.81-2.41,25.65,25.65,0,0,0-5.4-.08,21.58,21.58,0,0,0-2.28.33l-.88.2c-.3.07-.59.15-.88.24-.41.27-.7.2-.86-.22.24.21.59-.14.36-.36-.74-.68-1.72,1.32.07,1.18a13.56,13.56,0,0,0,1.88-.46,19.56,19.56,0,0,1,3-.45,26.07,26.07,0,0,1,5.37.18c7.13.91,13.6,4.17,20.33,6.47,5.75,2,11.95,3.2,18,1.67a16.7,16.7,0,0,0,7.21-3.77c2.26-2,3.85-4.68,5.59-7.15,4.1-5.83,10.47-9.45,16.93-12.15.29-.13.16-.61-.13-.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M175.38,155.35c1.2-1.16,2.65-2,3.89-3.15a57.61,57.61,0,0,0,5.08-5.73c3.47-4.14,6.88-8.39,11-11.95a30.86,30.86,0,0,1,13.58-7.29c5.11-1.08,10,.17,14.78,1.86s9.75,3.49,15,2.64c.32,0,.19-.53-.13-.48-5.12.83-10-1-14.72-2.65-4.5-1.57-9.06-2.81-13.86-2.07-10.54,1.64-18,10.12-24.51,17.86-2,2.35-3.9,4.76-6,7-1.32,1.36-3,2.31-4.37,3.63-.23.22.12.58.35.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M253.69,138.59c-4.83-1.23-9.7-2.29-14.59-3.29s-9.63-2.07-14.46-2.94a44.58,44.58,0,0,0-6.91-.79c-2.39-.06-4.76.23-7.15.27a36.86,36.86,0,0,0-9.6,1.64,36.39,36.39,0,0,0-10.73,5.46c-.26.18,0,.62.25.43,5.61-3.93,12.26-6.8,19.2-7,2.21-.06,4.41-.25,6.61-.3a39.15,39.15,0,0,1,7.25.62c4.68.78,9.32,1.88,14,2.81,5.37,1.09,10.73,2.23,16,3.58a.25.25,0,0,0,.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M255.33,138.89c-9.56,2.27-19.29.55-28.83-.94-9.13-1.43-18.34-2.45-27.44-.21a48.08,48.08,0,0,0-14.19,6.07.25.25,0,0,0,.26.43,48.09,48.09,0,0,1,27.23-7.35c9.37.28,18.51,2.66,27.84,3.41a48.9,48.9,0,0,0,15.26-.93c.31-.07.18-.56-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M254.17,139.7c-9.57,3.29-19.71,4.08-29.77,3.89-5-.1-10-.43-15-.79-5.22-.39-10.5-1-15.71-.32a21.23,21.23,0,0,0-12.47,5.58.25.25,0,0,0,.35.36c7.32-7.26,18.49-5.81,27.83-5.12,9.73.71,19.54,1.29,29.26.26a70.47,70.47,0,0,0,15.66-3.38c.31-.11.18-.59-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M249.63,141.53a67.32,67.32,0,0,1-29.8,6.79,125.14,125.14,0,0,1-16.16-1.64c-5-.68-10.29-1.39-15.29-.51-3.94.69-7.91,3.58-10.51,6.53-.21.24.14.59.35.35,2.52-2.85,6.35-5.64,10.15-6.37,5.38-1,11.25-.08,16.61.68a108.9,108.9,0,0,0,15.07,1.46,67.31,67.31,0,0,0,14.22-1.47A68.2,68.2,0,0,0,249.88,142c.29-.14,0-.58-.25-.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M178.58,155.49a35.42,35.42,0,0,1,13.15-5.3,39.71,39.71,0,0,1,15.05.48c4.59.92,9.13,2,13.84,2a44.46,44.46,0,0,0,13.15-1.94,48.53,48.53,0,0,0,13.5-6.51c.26-.18,0-.61-.26-.43a46.4,46.4,0,0,1-26.39,8.38c-5,0-9.77-1.26-14.63-2.19a39.05,39.05,0,0,0-13.55-.44,35.64,35.64,0,0,0-14.11,5.49c-.27.17,0,.61.25.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M245,144.66c-6.23,6.1-13,12.61-21.88,14.26C214.08,160.6,206,155,197.47,153a33.47,33.47,0,0,0-14.26-.43c-.31.07-.18.55.14.49a32.92,32.92,0,0,1,14.44.53c4.36,1.05,8.43,2.88,12.65,4.35a25,25,0,0,0,12.21,1.56,28.89,28.89,0,0,0,11.51-4.77A81.15,81.15,0,0,0,245.39,145a.25.25,0,0,0-.35-.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M260.13,138.67A43,43,0,0,1,282,141.39c3.23,1.31,6.21,3.15,9.46,4.42a18.77,18.77,0,0,0,8.86,1.28c13.6-1.46,21.06-14.4,28-24.55,4.16-6.08,9.2-12.88,16.89-14.4a.25.25,0,0,0,0-.48c-13.67-3.18-26.32-12.47-40.92-9.94-12.53,2.17-22.84,11.55-28.48,22.62-1.91,3.75-3.56,7.63-6.06,11-2.4,3.25-5.36,6.48-9.66,6.69-.32,0-.33.52,0,.5,6.41-.31,10.59-7,13.24-12.08,1.64-3.18,3.05-6.47,4.94-9.51a44.57,44.57,0,0,1,6.11-7.78A38.73,38.73,0,0,1,301,99c6.87-2,13.84-1.24,20.58.88,7.92,2.49,15.35,6.39,23.47,8.28v-.48c-6,1.19-10.53,5.66-14.12,10.35-4.25,5.56-7.67,11.73-12.14,17.13-4.3,5.19-9.9,9.72-16.63,11.14a18.06,18.06,0,0,1-10.21-.8c-3.24-1.21-6.2-3-9.39-4.38A43.42,43.42,0,0,0,260,138.19c-.32.05-.18.53.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M265.07,137.42c6.21-1,13.15-1.45,19.15.8,3.24,1.21,6.27,3,9.68,3.74a16.85,16.85,0,0,0,8.68-.5c12.85-4.09,17.85-18,27.39-26.27a27.07,27.07,0,0,1,9.45-5.29c.54-.18,1.54-.59,1.11-1.32a.6.6,0,0,0-1.11.07.25.25,0,0,0,.48.14c-.25.88-2.82,1.34-3.64,1.7a24.46,24.46,0,0,0-3.77,2.12,35.68,35.68,0,0,0-5.9,5.15c-4.12,4.38-7.34,9.49-11.29,14s-8.84,8.51-14.84,9.72a16.8,16.8,0,0,1-9.38-.91c-2.69-1-5.23-2.44-8-3.26-5.82-1.75-12.24-1.31-18.16-.38-.32.05-.19.53.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M342.78,107.45c-1.72-.39-3.53-.33-5.25-.71a81.55,81.55,0,0,1-8.23-2.55c-5.49-1.83-11-3.72-16.8-4.36a28.63,28.63,0,0,0-15,1.94c-4.13,1.87-7.17,5.13-10,8.58-3.23,4-6.42,8.16-11.3,10.16-.29.12-.16.61.13.49,8.2-3.36,11.42-12.41,18.52-17.19,9.76-6.59,22.26-3.13,32.48.27,3.12,1,6.23,2.12,9.41,3,1.91.52,3.93.43,5.86.86.31.07.45-.41.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M267.54,135.06c6.66-6.88,13.72-13.43,20.77-19.9a51.09,51.09,0,0,1,5.36-4.37c1.81-1.25,3.8-2.16,5.71-3.26a31.52,31.52,0,0,1,5.95-3c1.28-.43,2.56-.87,3.86-1.24a36.1,36.1,0,0,1,12.21-1.06c.32,0,.32-.48,0-.5-6.93-.41-14.19.66-20.29,4.17-3.84,2.22-7.68,4.18-11.09,7.06s-6.85,6.37-10.27,9.55c-4.27,4-8.5,8-12.56,12.18-.22.23.13.58.35.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M266,136.26c17.38-5.7,27.7-22.79,44.61-29.42a47.27,47.27,0,0,1,17.95-3.26.25.25,0,0,0,0-.5,48.94,48.94,0,0,0-28.24,8.72c-7.41,5-13.72,11.47-20.94,16.74a48.8,48.8,0,0,1-13.51,7.24c-.31.1-.18.59.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M267.49,136.39c9.16-2,17.64-6.19,25.57-11.12,4-2.5,7.93-5.2,11.79-8s7.9-5.94,12.14-8.44c5-3,10.91-5,16.77-3.61.31.07.45-.41.13-.48-9.92-2.3-18.55,4.35-26.1,9.8-7.89,5.71-15.89,11.37-24.66,15.67a71.37,71.37,0,0,1-15.77,5.66c-.31.07-.18.55.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M272.19,135.58a67.79,67.79,0,0,0,28.24-9.32c8.6-5.17,15.34-13,24.21-17.74a15.28,15.28,0,0,1,7.23-2,23,23,0,0,1,7.15.89c.31.1.44-.39.14-.48a21.2,21.2,0,0,0-11.55-.34,24.42,24.42,0,0,0-5.93,3,67.21,67.21,0,0,0-5.84,4.23c-4.08,3.24-7.94,6.76-12.23,9.72a66.63,66.63,0,0,1-14,7.35,67.45,67.45,0,0,1-17.44,4.11c-.32,0-.32.54,0,.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M340,109.23a36.14,36.14,0,0,0-25,9.11c-3.69,3.22-7,6.87-11.05,9.65a43.28,43.28,0,0,1-12.53,5.88,48.25,48.25,0,0,1-15.81,1.8c-.32,0-.32.48,0,.5a47.25,47.25,0,0,0,25.83-6c8.23-4.74,13.69-13,22.37-17.11A35.28,35.28,0,0,1,340,109.73c.32,0,.32-.49,0-.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M277.61,135.83c8.76,2,18.3,4,26.94.44,8.1-3.39,11.91-11.71,17.75-17.72a33.41,33.41,0,0,1,12.37-8.39c.3-.11.18-.59-.13-.48-7.63,2.78-13.22,8.67-18,15-2.68,3.53-5.4,7.22-9.22,9.61a25.14,25.14,0,0,1-12.84,3.46c-5.66.11-11.23-1.16-16.72-2.4-.31-.08-.45.41-.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M88.85,186.72a43.37,43.37,0,0,0-15.46,11.49c-4.23,5-6.57,11.78-12.14,15.48-5.26,3.5-12,3.93-18.1,2.82-7.14-1.3-13.65-4.75-20.2-7.68C16,205.74,8,202.5.45,205.3l.25.41c9.24-9.14,14.8-22.37,26.75-28.57a35.21,35.21,0,0,1,20-3.34,46.81,46.81,0,0,1,19.9,7.34A58.1,58.1,0,0,0,78,186.91c3.61,1.34,7.5,2.08,11,.16.28-.16,0-.59-.26-.44-3.84,2.1-8.11.85-11.91-.7a67.77,67.77,0,0,1-10-5.68,46.52,46.52,0,0,0-18.63-6.86,36.35,36.35,0,0,0-19.07,2.41c-6,2.6-10.73,7.15-14.8,12.18-4.69,5.78-8.69,12.12-14,17.38-.16.17,0,.51.25.42,7.41-2.75,15.31.45,22.1,3.48,6.49,2.89,12.92,6.3,20,7.67,5.51,1.07,11.51.91,16.65-1.55a18.59,18.59,0,0,0,6.79-5.66c2-2.66,3.54-5.64,5.47-8.35A43,43,0,0,1,89.1,187.15c.3-.13,0-.56-.25-.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M84.05,188.21a46.78,46.78,0,0,0-13.46,8c-4.13,3.73-6.14,9.35-10.76,12.59s-11.1,3.66-16.66,2.59c-6.11-1.18-11.77-3.9-17.62-5.95a45.36,45.36,0,0,0-9.81-2.4,26.34,26.34,0,0,0-5.4-.09c-.76.08-1.53.19-2.28.34l-.88.2c-.3.07-.59.15-.88.24-.41.27-.7.2-.85-.23.23.22.59-.13.35-.35-.74-.68-1.72,1.31.08,1.18a13.78,13.78,0,0,0,1.87-.46,19.56,19.56,0,0,1,3-.45,26.07,26.07,0,0,1,5.37.17c7.13.92,13.6,4.18,20.33,6.48,5.75,2,11.95,3.2,18,1.67A16.8,16.8,0,0,0,61.66,208c2.26-2,3.85-4.68,5.59-7.15,4.1-5.83,10.47-9.45,16.93-12.16.29-.12.17-.6-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M2.65,204.26c1.2-1.16,2.66-2,3.89-3.15a57.61,57.61,0,0,0,5.08-5.73c3.48-4.14,6.88-8.39,11-11.95a30.86,30.86,0,0,1,13.58-7.29c5.11-1.08,10,.17,14.78,1.85s9.75,3.5,15,2.65c.31,0,.18-.53-.14-.48-5.12.83-10-1-14.72-2.65-4.5-1.57-9.06-2.81-13.86-2.07-10.54,1.64-18,10.12-24.51,17.86-2,2.35-3.9,4.76-6,7-1.32,1.37-3,2.32-4.37,3.64-.23.22.12.58.35.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M81,187.5c-4.84-1.24-9.71-2.29-14.6-3.29s-9.63-2.07-14.46-2.94a44.58,44.58,0,0,0-6.91-.79c-2.39-.07-4.76.23-7.15.27a36.86,36.86,0,0,0-9.6,1.64,36.39,36.39,0,0,0-10.73,5.46c-.26.18,0,.62.25.43,5.61-3.93,12.26-6.81,19.2-7,2.21-.07,4.41-.25,6.61-.3a39.14,39.14,0,0,1,7.25.61c4.68.78,9.32,1.89,14,2.82,5.37,1.08,10.73,2.22,16,3.58.31.08.45-.4.14-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M82.6,187.8c-9.56,2.27-19.29.55-28.82-.94-9.14-1.43-18.35-2.45-27.45-.21a48.08,48.08,0,0,0-14.19,6.07.25.25,0,0,0,.26.43,48.09,48.09,0,0,1,27.23-7.35c9.37.28,18.51,2.66,27.84,3.41a48.9,48.9,0,0,0,15.26-.93c.31-.07.18-.56-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M81.44,188.61c-9.56,3.29-19.71,4.08-29.77,3.89-5-.1-10-.43-15-.8-5.22-.38-10.5-1-15.71-.31A21.23,21.23,0,0,0,8.47,197a.25.25,0,0,0,.35.36c7.32-7.26,18.49-5.81,27.83-5.13,9.73.72,19.54,1.3,29.26.27a71,71,0,0,0,15.66-3.38c.31-.11.18-.59-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M76.9,190.43a67.19,67.19,0,0,1-29.8,6.8,125.14,125.14,0,0,1-16.16-1.64c-5-.69-10.29-1.39-15.29-.51-3.94.69-7.91,3.58-10.51,6.53-.21.24.14.59.36.35,2.51-2.85,6.34-5.64,10.14-6.37,5.38-1,11.25-.08,16.61.68a108.9,108.9,0,0,0,15.07,1.46,66.76,66.76,0,0,0,14.23-1.48,68.26,68.26,0,0,0,15.6-5.38c.29-.15,0-.58-.25-.44Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M5.85,204.4A35.26,35.26,0,0,1,19,199.1a39.71,39.71,0,0,1,15,.48c4.59.92,9.13,2,13.84,2A44.79,44.79,0,0,0,61,199.67a48.53,48.53,0,0,0,13.5-6.51c.26-.18,0-.61-.26-.43a46.4,46.4,0,0,1-26.39,8.38c-5,0-9.76-1.26-14.63-2.19a39.05,39.05,0,0,0-13.55-.44A35.64,35.64,0,0,0,5.6,204c-.27.17,0,.61.25.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M72.31,193.56c-6.23,6.11-13,12.62-21.88,14.27-9.08,1.68-17.14-3.93-25.69-5.91a33.31,33.31,0,0,0-14.26-.43c-.31.07-.18.55.14.49a32.91,32.91,0,0,1,14.44.53c4.36,1.05,8.43,2.87,12.65,4.35a25.11,25.11,0,0,0,12.21,1.56,28.89,28.89,0,0,0,11.51-4.77,81.24,81.24,0,0,0,11.23-9.73.25.25,0,0,0-.35-.36Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M87.4,187.58a43,43,0,0,1,21.86,2.72c3.23,1.31,6.21,3.15,9.46,4.42a18.77,18.77,0,0,0,8.86,1.28c13.6-1.46,21.06-14.4,28-24.55,4.17-6.08,9.2-12.88,16.89-14.4a.25.25,0,0,0,0-.48c-13.67-3.18-26.32-12.47-40.92-9.94-12.53,2.16-22.83,11.55-28.48,22.62-1.91,3.75-3.56,7.63-6.06,11-2.4,3.25-5.36,6.48-9.66,6.69-.32,0-.32.52,0,.5,6.41-.32,10.59-7,13.24-12.09,1.64-3.17,3.05-6.46,4.94-9.51a44.88,44.88,0,0,1,6.11-7.77,38.73,38.73,0,0,1,16.65-10.22c6.87-2,13.84-1.24,20.58.88,7.92,2.49,15.35,6.39,23.47,8.28v-.48c-6,1.19-10.53,5.66-14.12,10.35-4.25,5.56-7.67,11.73-12.14,17.13-4.3,5.19-9.9,9.72-16.63,11.14a18.06,18.06,0,0,1-10.21-.8c-3.24-1.21-6.2-3-9.39-4.38a43.41,43.41,0,0,0-22.59-2.91c-.32,0-.18.53.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M92.34,186.33c6.21-1,13.15-1.45,19.15.8,3.24,1.21,6.27,3,9.68,3.74a17,17,0,0,0,8.68-.5c12.85-4.1,17.85-18,27.39-26.28a27,27,0,0,1,9.45-5.28c.54-.18,1.54-.59,1.11-1.33a.6.6,0,0,0-1.11.08.25.25,0,0,0,.48.13c-.25.89-2.82,1.35-3.64,1.71a23.65,23.65,0,0,0-3.77,2.12,36.09,36.09,0,0,0-5.9,5.14c-4.12,4.39-7.34,9.5-11.29,14s-8.84,8.52-14.84,9.73a16.8,16.8,0,0,1-9.38-.91c-2.69-1-5.22-2.44-8-3.26-5.82-1.75-12.24-1.31-18.16-.38-.32.05-.19.53.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M170.05,156.36c-1.72-.39-3.53-.33-5.25-.71a81.55,81.55,0,0,1-8.23-2.55c-5.49-1.83-11-3.72-16.8-4.36a28.64,28.64,0,0,0-15,1.93c-4.13,1.88-7.17,5.14-10,8.59-3.23,4-6.42,8.16-11.3,10.16-.29.12-.16.61.13.48,8.2-3.36,11.42-12.4,18.52-17.18,9.76-6.59,22.26-3.13,32.48.27,3.12,1,6.23,2.12,9.41,3,1.91.53,3.93.44,5.86.87.31.07.45-.41.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M94.81,184c6.66-6.88,13.72-13.43,20.77-19.9a49.86,49.86,0,0,1,5.36-4.37c1.82-1.26,3.8-2.16,5.71-3.27a32.4,32.4,0,0,1,5.95-3c1.28-.43,2.56-.87,3.86-1.25a35.85,35.85,0,0,1,12.21-1.05c.32,0,.32-.48,0-.5-6.93-.41-14.19.66-20.29,4.17-3.84,2.22-7.68,4.18-11.08,7.06-3.57,3-6.86,6.37-10.28,9.55-4.27,4-8.5,8-12.56,12.18-.22.23.13.58.35.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M93.24,185.17c17.38-5.71,27.7-22.79,44.61-29.43a47.45,47.45,0,0,1,18-3.25.25.25,0,0,0,0-.5,49,49,0,0,0-28.24,8.72c-7.4,5-13.72,11.47-20.94,16.74a48.8,48.8,0,0,1-13.51,7.24c-.31.1-.18.58.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M94.77,185.3c9.15-2.05,17.63-6.19,25.56-11.12,4-2.5,7.93-5.2,11.79-8s7.9-5.94,12.14-8.44c5-3,10.91-5,16.77-3.61.31.07.45-.41.13-.48-9.92-2.3-18.55,4.35-26.1,9.8-7.89,5.71-15.89,11.37-24.66,15.67a70.66,70.66,0,0,1-15.77,5.65c-.31.07-.18.56.14.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M99.46,184.49a67.79,67.79,0,0,0,28.24-9.32c8.61-5.18,15.34-13,24.21-17.74a15.28,15.28,0,0,1,7.23-2,23,23,0,0,1,7.15.89.25.25,0,0,0,.14-.48,21.2,21.2,0,0,0-11.55-.34,24.07,24.07,0,0,0-5.93,3,67.21,67.21,0,0,0-5.84,4.23c-4.08,3.24-7.94,6.76-12.23,9.72A66.91,66.91,0,0,1,99.46,184c-.32,0-.32.53,0,.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M167.27,158.13a36.19,36.19,0,0,0-25,9.12c-3.69,3.22-7,6.86-11.05,9.65a43,43,0,0,1-12.53,5.87,48,48,0,0,1-15.81,1.81c-.32,0-.32.48,0,.5a47.25,47.25,0,0,0,25.83-6c8.23-4.74,13.69-13,22.37-17.11a35.42,35.42,0,0,1,16.18-3.34c.32,0,.32-.49,0-.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M104.88,184.74c8.76,2,18.3,4,26.94.44,8.1-3.39,11.91-11.71,17.75-17.72A33.37,33.37,0,0,1,162,159.07c.3-.11.17-.59-.14-.48-7.63,2.77-13.22,8.67-18,15-2.68,3.54-5.4,7.23-9.22,9.62a25.14,25.14,0,0,1-12.84,3.46c-5.66.11-11.23-1.16-16.72-2.41a.25.25,0,0,0-.13.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M274.31,200a43.37,43.37,0,0,0-15.46,11.49c-4.23,5-6.57,11.78-12.14,15.49-5.26,3.5-12,3.93-18.1,2.82-7.14-1.3-13.64-4.76-20.2-7.69-6.91-3.08-14.95-6.33-22.49-3.53l.24.42c9.24-9.15,14.8-22.38,26.76-28.57a35.09,35.09,0,0,1,20-3.35,47,47,0,0,1,19.9,7.35,58.66,58.66,0,0,0,10.66,5.76c3.61,1.35,7.5,2.09,11,.16a.25.25,0,0,0-.26-.43c-3.84,2.09-8.11.84-11.91-.71a68.29,68.29,0,0,1-10-5.68,46.37,46.37,0,0,0-18.62-6.85,36.28,36.28,0,0,0-19.08,2.4c-6,2.6-10.73,7.15-14.8,12.18-4.69,5.78-8.68,12.13-14,17.38-.17.17,0,.51.24.42,7.41-2.75,15.31.45,22.1,3.48,6.49,2.9,12.92,6.31,19.95,7.67,5.51,1.07,11.52.91,16.65-1.55a18.63,18.63,0,0,0,6.8-5.66c2-2.65,3.53-5.64,5.46-8.35a43,43,0,0,1,17.55-14.22c.3-.13,0-.56-.25-.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M269.51,201.51a46.79,46.79,0,0,0-13.45,8c-4.14,3.74-6.15,9.36-10.77,12.59s-11.1,3.67-16.66,2.59c-6.11-1.18-11.77-3.89-17.61-5.94a45.67,45.67,0,0,0-9.81-2.41,26.45,26.45,0,0,0-5.41-.08,22,22,0,0,0-2.28.33l-.88.2-.87.24c-.42.27-.71.2-.86-.22.23.22.59-.14.35-.36-.74-.68-1.71,1.32.08,1.18a13.78,13.78,0,0,0,1.87-.46,20.83,20.83,0,0,1,3-.45,26.79,26.79,0,0,1,5.37.18c7.13.91,13.61,4.17,20.33,6.47,5.75,2,12,3.21,18,1.68a16.91,16.91,0,0,0,7.21-3.77c2.26-2.05,3.85-4.69,5.59-7.16,4.1-5.83,10.47-9.45,16.93-12.15.3-.12.17-.61-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M188.12,217.56c1.19-1.17,2.65-2,3.88-3.16a57.61,57.61,0,0,0,5.08-5.73c3.48-4.13,6.88-8.39,11-11.95a31,31,0,0,1,13.58-7.29c5.11-1.07,10,.18,14.78,1.86s9.76,3.49,15,2.64c.31-.05.18-.53-.14-.48-5.12.83-10-1-14.72-2.64-4.5-1.58-9.06-2.82-13.86-2.07-10.54,1.63-18,10.11-24.51,17.85-2,2.36-3.9,4.77-6,7-1.31,1.37-3,2.31-4.37,3.63-.23.23.13.58.36.36Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M266.43,200.79c-4.84-1.23-9.71-2.29-14.6-3.28s-9.62-2.08-14.46-2.95a45.84,45.84,0,0,0-6.91-.79c-2.38-.06-4.76.23-7.15.27a36.26,36.26,0,0,0-20.33,7.1c-.26.19,0,.62.26.43,5.6-3.93,12.25-6.8,19.2-7,2.2-.06,4.4-.25,6.6-.29a38.48,38.48,0,0,1,7.26.61c4.67.78,9.31,1.88,14,2.82,5.37,1.08,10.73,2.22,16,3.57a.25.25,0,0,0,.14-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M268.06,201.09c-9.56,2.27-19.29.55-28.82-.94-9.14-1.43-18.35-2.45-27.45-.21A48.17,48.17,0,0,0,197.61,206c-.27.17,0,.6.25.43a48.09,48.09,0,0,1,27.23-7.35c9.37.28,18.51,2.67,27.84,3.41a48.9,48.9,0,0,0,15.26-.93c.31-.07.18-.55-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M266.9,201.9c-9.56,3.29-19.71,4.08-29.76,3.89-5-.1-10-.42-15-.79-5.21-.39-10.49-1-15.71-.32a21.24,21.24,0,0,0-12.47,5.59c-.23.22.13.58.35.35,7.33-7.26,18.49-5.81,27.83-5.12,9.73.71,19.54,1.29,29.27.26A70.59,70.59,0,0,0,267,202.38c.3-.1.17-.59-.14-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M262.36,203.73a67.29,67.29,0,0,1-29.8,6.79,125.32,125.32,0,0,1-16.16-1.64c-5-.68-10.29-1.39-15.29-.51-3.94.69-7.91,3.59-10.51,6.53-.21.24.14.6.36.35,2.51-2.85,6.34-5.64,10.14-6.37,5.38-1,11.26-.08,16.61.68A110.48,110.48,0,0,0,232.78,211a67.49,67.49,0,0,0,29.84-6.87c.28-.14,0-.57-.26-.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M191.31,217.69a35.49,35.49,0,0,1,13.15-5.3,39.71,39.71,0,0,1,15,.49c4.59.91,9.13,2,13.84,2A44.73,44.73,0,0,0,246.5,213a48.81,48.81,0,0,0,13.5-6.51c.26-.18,0-.62-.25-.44a46.41,46.41,0,0,1-26.4,8.38c-5,0-9.76-1.26-14.63-2.19a39.55,39.55,0,0,0-13.55-.44,35.64,35.64,0,0,0-14.11,5.49c-.27.18,0,.61.25.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M257.77,206.86c-6.23,6.1-13,12.61-21.88,14.26-9.07,1.68-17.14-3.93-25.68-5.91a33.31,33.31,0,0,0-14.26-.42c-.32.06-.19.54.13.48a33.06,33.06,0,0,1,14.44.53c4.37,1,8.43,2.88,12.65,4.36a25.1,25.1,0,0,0,12.21,1.55,28.83,28.83,0,0,0,11.51-4.77,80.65,80.65,0,0,0,11.24-9.73c.23-.22-.13-.58-.36-.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M272.86,200.88a42.84,42.84,0,0,1,21.86,2.71c3.23,1.31,6.21,3.15,9.46,4.42a18.52,18.52,0,0,0,8.86,1.28c13.6-1.46,21.07-14.4,28-24.54,4.17-6.09,9.2-12.88,16.89-14.41a.25.25,0,0,0,0-.48c-13.67-3.18-26.31-12.47-40.92-9.94-12.52,2.17-22.83,11.56-28.48,22.62-1.91,3.75-3.55,7.63-6.06,11-2.4,3.25-5.36,6.48-9.66,6.69-.32,0-.32.52,0,.5,6.41-.31,10.59-7,13.24-12.08,1.64-3.18,3.05-6.47,4.94-9.51a44.13,44.13,0,0,1,6.11-7.77,38.71,38.71,0,0,1,16.65-10.23c6.87-2,13.84-1.24,20.58.88,7.92,2.49,15.35,6.39,23.47,8.28v-.48c-6,1.19-10.53,5.66-14.11,10.35-4.26,5.56-7.68,11.73-12.15,17.13-4.3,5.19-9.9,9.72-16.63,11.15a18.25,18.25,0,0,1-10.21-.8c-3.24-1.22-6.2-3.05-9.38-4.39a43.46,43.46,0,0,0-22.6-2.91c-.32.05-.18.53.13.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M277.8,199.62c6.21-1,13.15-1.44,19.15.8,3.24,1.22,6.27,3,9.68,3.74a16.84,16.84,0,0,0,8.68-.5c12.85-4.09,17.85-18,27.39-26.27a27,27,0,0,1,9.46-5.29c.53-.18,1.54-.59,1.1-1.32a.6.6,0,0,0-1.11.07.25.25,0,0,0,.48.14c-.25.88-2.82,1.34-3.64,1.7a24.46,24.46,0,0,0-3.77,2.12,35.68,35.68,0,0,0-5.9,5.15c-4.11,4.38-7.34,9.49-11.29,14s-8.84,8.51-14.84,9.72a16.81,16.81,0,0,1-9.38-.91c-2.69-1-5.22-2.43-8-3.26-5.82-1.75-12.24-1.31-18.16-.38-.32.05-.18.53.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M355.51,169.65c-1.72-.38-3.52-.32-5.25-.7a84.25,84.25,0,0,1-8.23-2.56c-5.48-1.83-11-3.72-16.8-4.36a28.66,28.66,0,0,0-15,1.94c-4.13,1.87-7.16,5.13-10,8.59-3.23,4-6.42,8.16-11.3,10.15-.29.13-.16.61.14.49,8.19-3.36,11.41-12.41,18.51-17.19,9.77-6.58,22.26-3.13,32.49.27,3.12,1,6.23,2.12,9.4,3,1.91.52,3.93.43,5.86.86.32.07.45-.41.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M280.28,197.26c6.65-6.88,13.71-13.43,20.76-19.89A51.18,51.18,0,0,1,306.4,173c1.82-1.25,3.8-2.16,5.71-3.26a32.45,32.45,0,0,1,5.95-3c1.28-.43,2.56-.87,3.86-1.24a36.1,36.1,0,0,1,12.21-1.06c.32,0,.32-.48,0-.5-6.93-.41-14.19.66-20.29,4.18-3.83,2.21-7.68,4.17-11.08,7.05-3.56,3-6.86,6.37-10.28,9.55-4.27,4-8.5,8-12.56,12.18-.22.23.13.59.36.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M278.7,198.47c17.38-5.71,27.7-22.8,44.61-29.43a47.69,47.69,0,0,1,17.95-3.26.25.25,0,0,0,0-.5A49.06,49.06,0,0,0,313,174c-7.4,5-13.71,11.47-20.94,16.75A49,49,0,0,1,278.57,198c-.31.1-.18.59.13.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M280.23,198.59c9.15-2.05,17.63-6.19,25.56-11.11,4-2.51,7.93-5.21,11.79-8,4-2.86,7.9-5.94,12.15-8.44,5-3,10.9-5,16.76-3.61.32.07.45-.41.14-.48-9.93-2.3-18.56,4.35-26.1,9.8-7.9,5.71-15.9,11.38-24.67,15.67a71.19,71.19,0,0,1-15.77,5.66c-.31.07-.18.55.14.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M284.92,197.79a68,68,0,0,0,28.24-9.33c8.61-5.17,15.34-13,24.21-17.74a15.42,15.42,0,0,1,7.23-1.94,22.77,22.77,0,0,1,7.16.88c.3.1.44-.38.13-.48a21.2,21.2,0,0,0-11.55-.34,24.6,24.6,0,0,0-5.93,3,69.39,69.39,0,0,0-5.84,4.23c-4.07,3.25-7.93,6.76-12.23,9.72a66.63,66.63,0,0,1-14,7.35,67.88,67.88,0,0,1-17.44,4.12c-.32,0-.32.53,0,.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M352.73,171.43a36.14,36.14,0,0,0-25,9.11c-3.69,3.22-7,6.87-11,9.66a43.77,43.77,0,0,1-12.53,5.87,48.28,48.28,0,0,1-15.82,1.8c-.32,0-.32.48,0,.5a47.23,47.23,0,0,0,25.83-6c8.24-4.74,13.7-13,22.37-17.1a35.16,35.16,0,0,1,16.18-3.34.25.25,0,0,0,0-.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M290.34,198c8.76,2,18.3,4.06,26.94.44,8.1-3.39,11.91-11.71,17.75-17.72a33.49,33.49,0,0,1,12.38-8.39c.3-.11.17-.59-.14-.48-7.63,2.78-13.22,8.67-18,15-2.68,3.53-5.4,7.22-9.22,9.61A25.11,25.11,0,0,1,307.19,200c-5.66.11-11.23-1.16-16.72-2.4-.31-.07-.44.41-.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M101.58,248.92a43.37,43.37,0,0,0-15.46,11.49c-4.22,5-6.57,11.78-12.14,15.49-5.26,3.5-12,3.92-18.1,2.82-7.13-1.31-13.64-4.76-20.2-7.69-6.91-3.08-14.95-6.33-22.49-3.53l.24.42c9.24-9.15,14.8-22.38,26.76-28.57a35.09,35.09,0,0,1,20-3.35,46.83,46.83,0,0,1,19.9,7.35,58.42,58.42,0,0,0,10.67,5.76c3.6,1.34,7.49,2.08,11,.16a.25.25,0,0,0-.25-.43c-3.85,2.09-8.11.84-11.92-.71a68.29,68.29,0,0,1-10-5.68A46.53,46.53,0,0,0,61,235.59,36.39,36.39,0,0,0,41.87,238c-6,2.6-10.73,7.15-14.8,12.18-4.68,5.78-8.68,12.13-14,17.38-.17.17,0,.51.24.42,7.41-2.75,15.31.45,22.11,3.48,6.48,2.9,12.91,6.31,19.94,7.67,5.51,1.07,11.52.91,16.65-1.55a18.54,18.54,0,0,0,6.8-5.66c2-2.65,3.53-5.64,5.46-8.35a43,43,0,0,1,17.55-14.22c.3-.13,0-.56-.25-.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M96.78,250.41a47,47,0,0,0-13.45,8c-4.14,3.74-6.15,9.35-10.77,12.59s-11.1,3.67-16.66,2.59c-6.11-1.18-11.77-3.9-17.61-5.94a45.67,45.67,0,0,0-9.81-2.41,25.74,25.74,0,0,0-5.41-.08,22,22,0,0,0-2.28.33l-.88.2L19,266c-.42.27-.71.2-.86-.22.23.21.59-.14.35-.36-.74-.68-1.71,1.32.08,1.18a13.43,13.43,0,0,0,1.87-.46,19.56,19.56,0,0,1,3-.45,26.07,26.07,0,0,1,5.37.18c7.13.91,13.61,4.17,20.33,6.47,5.75,2,12,3.2,18,1.67a16.7,16.7,0,0,0,7.21-3.77c2.26-2,3.85-4.68,5.59-7.15,4.1-5.83,10.47-9.45,16.93-12.15.3-.13.17-.61-.13-.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M15.39,266.46c1.19-1.16,2.65-2,3.88-3.15a57.61,57.61,0,0,0,5.08-5.73c3.48-4.14,6.88-8.39,11-11.95a30.86,30.86,0,0,1,13.58-7.29c5.11-1.08,10,.17,14.78,1.86s9.76,3.49,15,2.64c.31-.05.18-.53-.14-.48-5.12.83-10-1-14.72-2.65-4.5-1.57-9.05-2.81-13.86-2.07-10.54,1.64-18,10.12-24.5,17.86-2,2.35-3.91,4.76-6,7-1.32,1.36-3,2.31-4.38,3.63-.23.22.13.58.36.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M93.7,249.7c-4.84-1.23-9.71-2.29-14.6-3.29s-9.62-2.07-14.46-2.94a44.58,44.58,0,0,0-6.91-.79c-2.38-.06-4.76.23-7.15.27a37,37,0,0,0-9.6,1.64,36.21,36.21,0,0,0-10.72,5.46c-.27.18,0,.62.25.43,5.6-3.93,12.25-6.8,19.2-7,2.2-.06,4.4-.25,6.6-.3a39.33,39.33,0,0,1,7.26.62c4.67.78,9.31,1.88,14,2.81,5.37,1.09,10.73,2.23,16,3.58a.25.25,0,0,0,.14-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M95.33,250c-9.56,2.27-19.29.55-28.82-.94-9.14-1.43-18.35-2.45-27.45-.21a48,48,0,0,0-14.18,6.07c-.27.17,0,.6.25.43A48.11,48.11,0,0,1,52.36,248c9.37.28,18.51,2.66,27.84,3.41a48.9,48.9,0,0,0,15.26-.93c.32-.07.18-.56-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M94.17,250.81c-9.56,3.29-19.71,4.08-29.76,3.89-5-.1-10-.43-15-.79-5.21-.39-10.49-1-15.71-.32a21.26,21.26,0,0,0-12.47,5.58c-.23.23.13.58.36.36,7.32-7.26,18.48-5.81,27.82-5.12,9.73.71,19.54,1.29,29.27.26a70.47,70.47,0,0,0,15.66-3.38c.3-.11.17-.59-.14-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M89.63,252.64a67.29,67.29,0,0,1-29.8,6.79,125.32,125.32,0,0,1-16.16-1.64c-5-.68-10.29-1.39-15.29-.51-3.94.69-7.91,3.58-10.51,6.53-.21.24.14.59.36.35,2.51-2.85,6.34-5.64,10.14-6.37,5.38-1,11.26-.08,16.61.68a108.9,108.9,0,0,0,15.07,1.46,67.34,67.34,0,0,0,14.23-1.47,68.53,68.53,0,0,0,15.61-5.39c.29-.14,0-.57-.26-.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M18.58,266.6a35.49,35.49,0,0,1,13.15-5.3,39.71,39.71,0,0,1,15.05.48c4.59.92,9.13,2,13.84,2a44.41,44.41,0,0,0,13.15-1.94,48.53,48.53,0,0,0,13.5-6.51c.26-.18,0-.61-.25-.43a46.41,46.41,0,0,1-26.4,8.38c-5,0-9.76-1.26-14.63-2.19a39.05,39.05,0,0,0-13.55-.44,35.69,35.69,0,0,0-14.11,5.49c-.27.17,0,.61.25.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M85,255.77c-6.23,6.1-13,12.61-21.88,14.26-9.07,1.68-17.14-3.93-25.68-5.91a33.47,33.47,0,0,0-14.26-.43c-.32.07-.18.55.13.49a32.91,32.91,0,0,1,14.44.53c4.37,1.05,8.43,2.88,12.65,4.35a25,25,0,0,0,12.21,1.56,28.83,28.83,0,0,0,11.51-4.77,80.65,80.65,0,0,0,11.24-9.73c.23-.23-.13-.58-.36-.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M100.14,249.78A42.92,42.92,0,0,1,122,252.5c3.23,1.31,6.21,3.15,9.46,4.42a18.77,18.77,0,0,0,8.86,1.28c13.6-1.46,21.07-14.4,28-24.55,4.17-6.08,9.2-12.88,16.89-14.4a.25.25,0,0,0,0-.48c-13.67-3.18-26.31-12.47-40.92-9.94-12.52,2.17-22.83,11.55-28.48,22.62-1.91,3.75-3.55,7.63-6.06,11-2.4,3.25-5.36,6.48-9.66,6.69-.32,0-.32.52,0,.5,6.41-.31,10.59-7,13.24-12.08,1.65-3.18,3-6.47,4.94-9.51a44.57,44.57,0,0,1,6.11-7.78A38.79,38.79,0,0,1,141,210.09c6.87-2,13.84-1.24,20.58.88,7.92,2.49,15.35,6.39,23.47,8.28v-.48c-6,1.19-10.52,5.66-14.11,10.35-4.26,5.56-7.68,11.73-12.15,17.13-4.3,5.19-9.9,9.72-16.63,11.14a18.07,18.07,0,0,1-10.21-.8c-3.24-1.21-6.2-3-9.38-4.38A43.46,43.46,0,0,0,100,249.3c-.32,0-.18.53.14.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M105.07,248.53c6.21-1,13.15-1.45,19.15.8,3.24,1.22,6.27,3,9.68,3.74a16.84,16.84,0,0,0,8.68-.5c12.85-4.09,17.85-18,27.39-26.27a27,27,0,0,1,9.46-5.29c.53-.18,1.54-.59,1.1-1.32a.6.6,0,0,0-1.11.07.25.25,0,0,0,.48.14c-.25.88-2.82,1.34-3.64,1.7a24.93,24.93,0,0,0-3.77,2.12,35.68,35.68,0,0,0-5.9,5.15c-4.11,4.38-7.34,9.49-11.29,14s-8.84,8.51-14.84,9.72a16.81,16.81,0,0,1-9.38-.91c-2.69-1-5.22-2.44-8-3.26-5.81-1.75-12.24-1.31-18.16-.38-.32,0-.18.53.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M182.79,218.56c-1.73-.39-3.53-.33-5.26-.71a82.35,82.35,0,0,1-8.23-2.55c-5.48-1.83-11-3.72-16.8-4.36a28.66,28.66,0,0,0-15,1.94c-4.13,1.87-7.16,5.13-10,8.58-3.24,4-6.43,8.16-11.31,10.16-.29.12-.16.61.14.49,8.19-3.36,11.42-12.41,18.51-17.19,9.77-6.59,22.26-3.13,32.49.27,3.12,1,6.23,2.12,9.4,3,1.91.52,3.93.43,5.86.86.32.07.45-.41.14-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M107.55,246.17c6.65-6.88,13.71-13.43,20.76-19.9a51.09,51.09,0,0,1,5.36-4.37c1.82-1.25,3.8-2.16,5.71-3.26a32.13,32.13,0,0,1,6-3c1.29-.43,2.56-.87,3.86-1.24a36.1,36.1,0,0,1,12.21-1.06c.32,0,.32-.48,0-.5-6.93-.41-14.19.66-20.28,4.17-3.84,2.22-7.69,4.18-11.09,7.06-3.56,3-6.86,6.37-10.28,9.55-4.27,4-8.5,8-12.56,12.18a.25.25,0,0,0,.36.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M106,247.38c17.38-5.71,27.7-22.8,44.61-29.43a47.5,47.5,0,0,1,17.95-3.26.25.25,0,0,0,0-.5,49,49,0,0,0-28.24,8.72c-7.4,5-13.71,11.47-20.94,16.74a48.8,48.8,0,0,1-13.51,7.24c-.31.1-.18.59.13.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M107.5,247.5c9.15-2.05,17.63-6.19,25.56-11.12,4-2.5,7.93-5.2,11.8-8s7.89-5.94,12.14-8.44c5-3,10.9-5,16.76-3.61.32.07.45-.41.14-.48-9.93-2.3-18.56,4.35-26.1,9.8-7.89,5.71-15.9,11.37-24.67,15.67A71.19,71.19,0,0,1,107.36,247c-.31.07-.18.55.14.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M112.19,246.69a67.79,67.79,0,0,0,28.24-9.32c8.61-5.17,15.34-13,24.21-17.74a15.31,15.31,0,0,1,7.23-1.95,23.08,23.08,0,0,1,7.16.89c.31.1.44-.39.13-.48a21.2,21.2,0,0,0-11.55-.34,24.6,24.6,0,0,0-5.93,3,69.39,69.39,0,0,0-5.84,4.23c-4.07,3.24-7.93,6.76-12.23,9.72a66.63,66.63,0,0,1-14,7.35,67.45,67.45,0,0,1-17.44,4.11c-.32,0-.32.54,0,.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M180,220.34a36.15,36.15,0,0,0-25,9.11c-3.69,3.22-7,6.87-11,9.65A43.53,43.53,0,0,1,131.44,245a48.28,48.28,0,0,1-15.82,1.8c-.32,0-.32.48,0,.5a47.23,47.23,0,0,0,25.83-6c8.24-4.74,13.7-13,22.37-17.11A35.3,35.3,0,0,1,180,220.84c.32,0,.32-.49,0-.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M117.61,246.94c8.76,2,18.3,4.05,26.94.44,8.1-3.39,11.92-11.71,17.75-17.72a33.37,33.37,0,0,1,12.38-8.39c.3-.11.17-.59-.14-.48-7.63,2.78-13.22,8.67-18,15-2.68,3.53-5.39,7.22-9.22,9.61a25.11,25.11,0,0,1-12.84,3.46c-5.66.11-11.23-1.16-16.72-2.4-.31-.08-.44.41-.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M145.27,262.69a43.48,43.48,0,0,0,15.46-11.49c4.23-5,6.57-11.79,12.14-15.49,5.26-3.5,12-3.93,18.11-2.82,7.13,1.3,13.64,4.76,20.2,7.68,6.91,3.09,14.94,6.33,22.49,3.54l-.25-.42c-9.24,9.14-14.8,22.37-26.75,28.57a35,35,0,0,1-20,3.34,46.81,46.81,0,0,1-19.9-7.34,58.06,58.06,0,0,0-10.66-5.76c-3.61-1.35-7.5-2.09-11-.16-.29.15,0,.58.25.43,3.84-2.1,8.11-.85,11.91.7a66.94,66.94,0,0,1,10,5.69A46.5,46.5,0,0,0,185.91,276a36.45,36.45,0,0,0,19.07-2.4c6-2.6,10.73-7.16,14.81-12.18,4.68-5.79,8.68-12.13,14-17.39a.26.26,0,0,0-.25-.42c-7.41,2.75-15.31-.45-22.1-3.48-6.48-2.89-12.92-6.3-20-7.66-5.51-1.07-11.51-.92-16.65,1.54a18.69,18.69,0,0,0-6.79,5.66c-2,2.66-3.53,5.64-5.47,8.35A42.79,42.79,0,0,1,145,262.25c-.29.13,0,.57.25.44Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M150.07,261.19a46.64,46.64,0,0,0,13.46-8c4.13-3.73,6.14-9.35,10.76-12.59,4.77-3.34,11.11-3.66,16.66-2.59,6.11,1.19,11.77,3.9,17.62,6a45.36,45.36,0,0,0,9.81,2.4,25.65,25.65,0,0,0,5.4.09,19.28,19.28,0,0,0,2.28-.34c.3-.06.59-.12.88-.2s.59-.15.88-.24c.41-.27.7-.19.86.23-.24-.22-.59.13-.36.35.74.69,1.72-1.31-.07-1.18a13.6,13.6,0,0,0-1.88.47,22.29,22.29,0,0,1-3,.45,26.79,26.79,0,0,1-5.37-.18c-7.13-.92-13.6-4.17-20.33-6.47-5.75-2-11.95-3.21-18-1.68a16.91,16.91,0,0,0-7.21,3.77c-2.26,2-3.85,4.68-5.59,7.15-4.1,5.83-10.47,9.45-16.93,12.16-.29.12-.16.6.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M231.47,245.14c-1.2,1.16-2.65,2-3.89,3.16A56.4,56.4,0,0,0,222.5,254c-3.47,4.14-6.88,8.39-11,12A30.86,30.86,0,0,1,198,273.26c-5.11,1.08-10-.17-14.78-1.85s-9.75-3.5-15-2.65c-.32.05-.19.54.13.49,5.12-.84,10,1,14.72,2.64,4.5,1.57,9.06,2.82,13.86,2.07,10.54-1.64,18-10.11,24.51-17.85,2-2.36,3.91-4.77,6-7,1.32-1.37,3-2.31,4.37-3.64.23-.22-.12-.57-.35-.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M153.16,261.9c4.83,1.24,9.7,2.3,14.59,3.29s9.63,2.07,14.46,2.95a47.16,47.16,0,0,0,6.91.79c2.39.06,4.76-.23,7.15-.28a36.86,36.86,0,0,0,9.6-1.64,36.39,36.39,0,0,0,10.73-5.46c.26-.18,0-.61-.25-.43-5.61,3.93-12.26,6.81-19.2,7-2.21.07-4.41.26-6.61.3a39.14,39.14,0,0,1-7.25-.61c-4.68-.78-9.32-1.88-14-2.82-5.37-1.08-10.73-2.22-16-3.58-.31-.08-.45.41-.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M151.52,261.61c9.56-2.28,19.29-.56,28.83.93,9.13,1.43,18.34,2.45,27.44.21A48.05,48.05,0,0,0,222,256.69c.27-.17,0-.61-.26-.43a48.06,48.06,0,0,1-27.23,7.34c-9.37-.28-18.51-2.66-27.84-3.41a48.9,48.9,0,0,0-15.26.93.25.25,0,0,0,.13.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M152.68,260.8c9.57-3.3,19.71-4.09,29.77-3.89,5,.09,10,.42,15,.79,5.22.38,10.5,1,15.71.32a21.3,21.3,0,0,0,12.47-5.59.25.25,0,0,0-.35-.35c-7.32,7.25-18.49,5.81-27.83,5.12-9.73-.72-19.54-1.3-29.26-.27a71.05,71.05,0,0,0-15.66,3.39c-.31.1-.18.58.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M157.22,259a67.33,67.33,0,0,1,29.8-6.8,125.16,125.16,0,0,1,16.16,1.65c5,.68,10.29,1.38,15.29.5,3.94-.69,7.92-3.58,10.51-6.52.21-.25-.14-.6-.35-.36-2.52,2.86-6.35,5.65-10.14,6.38-5.39,1-11.26.07-16.62-.69a110.46,110.46,0,0,0-15.07-1.46A67.49,67.49,0,0,0,157,258.54c-.29.14,0,.57.25.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M228.27,245a35.09,35.09,0,0,1-13.15,5.3,39.71,39.71,0,0,1-15.05-.48c-4.59-.92-9.13-2-13.84-2a44.79,44.79,0,0,0-13.15,1.94,48.81,48.81,0,0,0-13.5,6.51c-.26.18,0,.61.26.43a46.55,46.55,0,0,1,26.39-8.38c5,0,9.77,1.27,14.63,2.19a39.3,39.3,0,0,0,13.55.45,35.64,35.64,0,0,0,14.11-5.49c.27-.18,0-.61-.25-.44Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M161.81,255.84c6.23-6.1,13-12.61,21.88-14.26,9.08-1.68,17.14,3.93,25.69,5.91a33.47,33.47,0,0,0,14.26.42c.31-.06.18-.55-.14-.48a33.22,33.22,0,0,1-14.44-.53c-4.36-1.06-8.43-2.88-12.65-4.36A25,25,0,0,0,184.2,241a28.73,28.73,0,0,0-11.51,4.76,81.9,81.9,0,0,0-11.23,9.74c-.23.22.12.57.35.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M146.72,261.82a43,43,0,0,1-21.86-2.71c-3.23-1.31-6.21-3.16-9.46-4.43a18.64,18.64,0,0,0-8.86-1.27c-13.6,1.46-21.06,14.39-28,24.54-4.16,6.08-9.2,12.88-16.89,14.4a.25.25,0,0,0,0,.48C75.31,296,88,305.3,102.56,302.78c12.53-2.17,22.84-11.56,28.48-22.63,1.91-3.75,3.56-7.63,6.07-11s5.35-6.49,9.65-6.7c.32,0,.33-.51,0-.5-6.41.32-10.59,7-13.24,12.09-1.64,3.17-3.05,6.47-4.94,9.51a44.13,44.13,0,0,1-6.11,7.77,38.63,38.63,0,0,1-16.65,10.22c-6.87,2-13.84,1.25-20.58-.87-7.92-2.5-15.35-6.4-23.47-8.29v.48c6-1.18,10.53-5.65,14.12-10.35,4.25-5.56,7.67-11.73,12.14-17.13,4.3-5.19,9.9-9.72,16.63-11.14a18.15,18.15,0,0,1,10.21.8c3.24,1.21,6.2,3.05,9.39,4.38a43.32,43.32,0,0,0,22.59,2.91c.32,0,.18-.52-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M141.78,263.07c-6.21,1-13.15,1.45-19.15-.8-3.24-1.21-6.27-3-9.68-3.73a16.74,16.74,0,0,0-8.68.5c-12.84,4.09-17.85,18-27.39,26.27a27,27,0,0,1-9.45,5.28c-.54.18-1.54.59-1.11,1.33a.6.6,0,0,0,1.11-.08.25.25,0,0,0-.48-.13c.25-.89,2.82-1.35,3.64-1.71a23.6,23.6,0,0,0,3.77-2.11,36.15,36.15,0,0,0,5.9-5.15c4.12-4.38,7.34-9.49,11.3-14s8.83-8.51,14.83-9.73a17,17,0,0,1,9.38.91c2.69,1,5.23,2.44,8,3.27,5.82,1.74,12.24,1.3,18.16.38.32-.05.19-.54-.13-.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M64.07,293c1.72.39,3.53.33,5.25.71a81.55,81.55,0,0,1,8.23,2.55c5.49,1.84,11,3.73,16.8,4.36a28.63,28.63,0,0,0,15-1.93c4.13-1.87,7.17-5.13,10-8.59,3.23-4,6.42-8.16,11.3-10.16.29-.12.17-.6-.13-.48-8.2,3.36-11.42,12.4-18.52,17.19-9.76,6.58-22.26,3.13-32.48-.27-3.12-1-6.23-2.12-9.41-3-1.91-.52-3.93-.44-5.86-.87-.31-.07-.45.41-.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M139.31,265.43c-6.66,6.88-13.71,13.43-20.77,19.9a49.86,49.86,0,0,1-5.36,4.37c-1.81,1.26-3.8,2.17-5.71,3.27a32.09,32.09,0,0,1-6,3c-1.28.43-2.56.87-3.86,1.25a35.85,35.85,0,0,1-12.21,1.05c-.32,0-.32.48,0,.5,6.93.42,14.19-.65,20.29-4.17,3.84-2.21,7.68-4.17,11.09-7.06,3.56-3,6.85-6.36,10.27-9.54,4.27-4,8.5-8,12.56-12.18.22-.24-.13-.59-.35-.36Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M140.88,264.23c-17.38,5.71-27.7,22.8-44.61,29.43a47.66,47.66,0,0,1-18,3.26c-.32,0-.32.49,0,.5a49,49,0,0,0,28.24-8.73c7.41-5,13.72-11.46,20.94-16.74A49.07,49.07,0,0,1,141,264.71c.31-.1.18-.58-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M139.36,264.11c-9.16,2-17.64,6.18-25.57,11.11-4,2.51-7.93,5.21-11.79,8-4,2.86-7.9,5.94-12.14,8.45-5,3-10.91,5-16.77,3.6-.31-.07-.45.41-.13.49,9.92,2.3,18.55-4.35,26.1-9.81,7.89-5.7,15.89-11.37,24.66-15.67a71.34,71.34,0,0,1,15.77-5.65c.31-.07.18-.55-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M134.66,264.91a68,68,0,0,0-28.24,9.32c-8.6,5.18-15.34,13-24.21,17.75A15.39,15.39,0,0,1,75,293.92a22.72,22.72,0,0,1-7.15-.89.25.25,0,0,0-.14.48,21.1,21.1,0,0,0,11.55.34,23.73,23.73,0,0,0,5.93-3A67.21,67.21,0,0,0,91,286.59c4.08-3.24,7.94-6.76,12.23-9.72a66.91,66.91,0,0,1,31.42-11.46c.32,0,.32-.53,0-.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M66.85,291.27a36.15,36.15,0,0,0,25-9.12c3.69-3.21,7-6.86,11-9.65a43.26,43.26,0,0,1,12.53-5.87,48,48,0,0,1,15.81-1.8c.32,0,.32-.49,0-.5a47.24,47.24,0,0,0-25.83,6c-8.23,4.75-13.69,13-22.37,17.11a35.16,35.16,0,0,1-16.18,3.34c-.32,0-.32.49,0,.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M129.24,264.67c-8.76-2-18.3-4.06-26.94-.44-8.1,3.39-11.91,11.71-17.75,17.71a33.29,33.29,0,0,1-12.37,8.39c-.3.11-.17.59.13.48,7.63-2.77,13.23-8.66,18-15,2.68-3.54,5.4-7.23,9.22-9.62a25.13,25.13,0,0,1,12.84-3.45c5.66-.12,11.23,1.15,16.72,2.4.31.07.45-.41.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M318,213.78a43.37,43.37,0,0,0,15.46-11.49c4.23-5,6.57-11.78,12.14-15.49,5.26-3.5,12-3.93,18.11-2.82,7.13,1.3,13.64,4.76,20.19,7.69,6.92,3.08,15,6.32,22.5,3.53l-.25-.42c-9.24,9.14-14.8,22.38-26.75,28.57a35.13,35.13,0,0,1-20,3.35,47,47,0,0,1-19.9-7.35,58.06,58.06,0,0,0-10.66-5.76c-3.61-1.35-7.5-2.09-11-.16-.28.15,0,.59.26.43,3.84-2.1,8.11-.85,11.91.7a66.94,66.94,0,0,1,10,5.69,46.5,46.5,0,0,0,18.63,6.85,36.45,36.45,0,0,0,19.07-2.4c6-2.6,10.73-7.15,14.8-12.18,4.69-5.78,8.69-12.13,14-17.39.16-.16,0-.5-.25-.41-7.41,2.74-15.31-.45-22.1-3.49-6.49-2.89-12.92-6.3-20-7.66-5.51-1.07-11.51-.91-16.65,1.54a18.72,18.72,0,0,0-6.79,5.67c-2,2.65-3.54,5.64-5.47,8.35a43,43,0,0,1-17.55,14.22c-.3.13,0,.56.25.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M322.8,212.28a47,47,0,0,0,13.46-8c4.13-3.74,6.14-9.36,10.76-12.59,4.77-3.35,11.1-3.67,16.66-2.59,6.11,1.18,11.77,3.89,17.62,5.94a44.79,44.79,0,0,0,9.81,2.4,25.65,25.65,0,0,0,5.4.09,19.59,19.59,0,0,0,2.28-.34l.88-.19.88-.25c.41-.27.7-.19.86.23-.24-.22-.6.14-.36.35.74.69,1.72-1.31-.07-1.17a12.27,12.27,0,0,0-1.88.46,22.29,22.29,0,0,1-3,.45,26.79,26.79,0,0,1-5.37-.18c-7.13-.92-13.6-4.17-20.33-6.47-5.75-2-11.95-3.21-18-1.68a16.91,16.91,0,0,0-7.21,3.77c-2.26,2-3.85,4.68-5.59,7.16-4.1,5.82-10.47,9.44-16.93,12.15-.29.12-.16.61.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M404.2,196.23c-1.2,1.17-2.66,2-3.89,3.16a56.51,56.51,0,0,0-5.08,5.73c-3.48,4.13-6.88,8.38-11,11.94a30.76,30.76,0,0,1-13.58,7.29c-5.11,1.08-10-.17-14.78-1.85s-9.75-3.5-15-2.65c-.32.06-.19.54.13.49,5.12-.84,10,1,14.72,2.64,4.5,1.57,9.06,2.82,13.86,2.07,10.54-1.63,18-10.11,24.51-17.85,2-2.36,3.9-4.77,6-7,1.32-1.37,3-2.31,4.37-3.63a.25.25,0,0,0-.35-.36Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M325.88,213c4.84,1.23,9.71,2.29,14.6,3.28s9.63,2.07,14.46,2.95a45.84,45.84,0,0,0,6.91.79c2.39.06,4.76-.23,7.15-.28a36.13,36.13,0,0,0,20.33-7.09c.26-.19,0-.62-.25-.44-5.61,3.93-12.26,6.81-19.2,7-2.21.06-4.41.25-6.61.29a39.14,39.14,0,0,1-7.25-.61c-4.68-.78-9.32-1.88-14-2.82-5.37-1.08-10.73-2.22-16-3.58-.31-.08-.45.41-.14.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M324.25,212.7c9.56-2.28,19.29-.55,28.82.94,9.14,1.42,18.35,2.45,27.45.21a48.34,48.34,0,0,0,14.19-6.07c.27-.17,0-.61-.26-.43a48.08,48.08,0,0,1-27.23,7.34c-9.37-.27-18.51-2.66-27.84-3.4a48.9,48.9,0,0,0-15.26.92.25.25,0,0,0,.13.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M325.41,211.89c9.57-3.3,19.71-4.08,29.77-3.89,5,.1,10,.42,15,.79,5.22.38,10.5,1,15.71.32a21.24,21.24,0,0,0,12.47-5.59.25.25,0,0,0-.35-.35c-7.32,7.25-18.49,5.81-27.83,5.12-9.73-.72-19.54-1.3-29.26-.27a71.05,71.05,0,0,0-15.66,3.39c-.31.1-.18.58.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M330,210.06a67.33,67.33,0,0,1,29.8-6.8,125.16,125.16,0,0,1,16.16,1.65c5,.68,10.29,1.38,15.29.51,3.94-.69,7.91-3.59,10.51-6.53.21-.24-.14-.6-.36-.36-2.51,2.86-6.34,5.65-10.14,6.38-5.38,1-11.25.07-16.61-.68a110.48,110.48,0,0,0-15.07-1.47,67.49,67.49,0,0,0-29.83,6.87c-.29.14,0,.57.25.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M401,196.1a35.24,35.24,0,0,1-13.15,5.29,39.71,39.71,0,0,1-15.05-.48c-4.59-.92-9.13-2-13.84-2a44.78,44.78,0,0,0-13.15,1.93,48.81,48.81,0,0,0-13.5,6.51c-.26.18,0,.62.26.43A46.46,46.46,0,0,1,359,199.39c5,0,9.76,1.26,14.63,2.18a39.3,39.3,0,0,0,13.55.45,35.64,35.64,0,0,0,14.11-5.49c.27-.18,0-.61-.25-.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M334.54,206.93c6.23-6.1,13-12.61,21.88-14.26,9.08-1.68,17.14,3.93,25.69,5.91a33.46,33.46,0,0,0,14.26.42c.31-.06.18-.54-.14-.48a33.21,33.21,0,0,1-14.44-.53c-4.36-1.06-8.43-2.88-12.65-4.36a25,25,0,0,0-12.21-1.55,28.75,28.75,0,0,0-11.51,4.77,81.15,81.15,0,0,0-11.23,9.73c-.23.22.12.58.35.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M319.45,212.91a43,43,0,0,1-21.86-2.71c-3.23-1.31-6.21-3.15-9.46-4.43a18.64,18.64,0,0,0-8.86-1.27c-13.6,1.46-21.06,14.39-28,24.54-4.16,6.09-9.2,12.88-16.89,14.4a.26.26,0,0,0,0,.49c13.67,3.18,26.32,12.46,40.92,9.94,12.53-2.17,22.84-11.56,28.48-22.63,1.91-3.74,3.56-7.62,6.06-11,2.4-3.25,5.36-6.48,9.66-6.7.32,0,.32-.51,0-.5-6.41.32-10.59,7-13.24,12.09-1.64,3.17-3.05,6.47-4.94,9.51a44.13,44.13,0,0,1-6.11,7.77,38.63,38.63,0,0,1-16.65,10.22c-6.87,2-13.84,1.25-20.58-.87-7.92-2.49-15.35-6.4-23.47-8.29v.49c6-1.19,10.53-5.66,14.12-10.35,4.25-5.57,7.67-11.74,12.14-17.14,4.3-5.19,9.9-9.72,16.63-11.14a18.15,18.15,0,0,1,10.21.8c3.24,1.22,6.2,3.05,9.39,4.38a43.31,43.31,0,0,0,22.59,2.91c.32,0,.18-.52-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M314.51,214.17c-6.21,1-13.15,1.44-19.15-.8-3.24-1.22-6.27-3-9.68-3.74a16.85,16.85,0,0,0-8.68.5c-12.85,4.09-17.85,18-27.39,26.27a27,27,0,0,1-9.45,5.28c-.54.18-1.54.6-1.11,1.33a.6.6,0,0,0,1.11-.08.25.25,0,0,0-.48-.13c.25-.88,2.82-1.34,3.64-1.71a23.6,23.6,0,0,0,3.77-2.11,35.68,35.68,0,0,0,5.9-5.15c4.12-4.38,7.34-9.49,11.29-14s8.84-8.51,14.84-9.72a16.89,16.89,0,0,1,9.38.9c2.69,1,5.22,2.44,8,3.27,5.82,1.75,12.24,1.3,18.16.38.32-.05.19-.53-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M236.8,244.14c1.72.38,3.53.32,5.25.7a79.12,79.12,0,0,1,8.23,2.56c5.49,1.83,11,3.72,16.8,4.36a28.75,28.75,0,0,0,15-1.94c4.13-1.87,7.17-5.13,10-8.59,3.23-4,6.42-8.16,11.3-10.16.29-.12.16-.6-.13-.48C295,234,291.78,243,284.68,247.78c-9.76,6.58-22.26,3.13-32.48-.27-3.12-1-6.23-2.12-9.41-3-1.91-.52-3.93-.43-5.86-.87-.31-.07-.45.42-.13.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M312,216.52c-6.66,6.88-13.72,13.44-20.77,19.9a49.86,49.86,0,0,1-5.36,4.37c-1.82,1.26-3.8,2.17-5.71,3.27a32.76,32.76,0,0,1-5.95,3c-1.28.42-2.56.87-3.86,1.24a35.85,35.85,0,0,1-12.21,1.05c-.32,0-.32.48,0,.5,6.93.42,14.19-.65,20.29-4.17,3.84-2.21,7.68-4.17,11.09-7.06s6.85-6.36,10.27-9.54c4.27-4,8.5-8,12.56-12.18.22-.23-.13-.59-.35-.36Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M313.61,215.32c-17.38,5.71-27.7,22.8-44.61,29.43A47.66,47.66,0,0,1,251.05,248c-.32,0-.32.49,0,.5a49,49,0,0,0,28.24-8.72c7.41-5,13.72-11.47,20.94-16.75a49.07,49.07,0,0,1,13.51-7.24c.31-.1.18-.58-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M312.09,215.2c-9.16,2-17.64,6.19-25.57,11.11-4,2.51-7.93,5.21-11.79,8-4,2.86-7.9,5.93-12.14,8.44-5,3-10.91,5-16.77,3.61-.31-.08-.45.41-.13.48,9.92,2.3,18.55-4.35,26.1-9.81,7.89-5.7,15.89-11.37,24.66-15.67a71.16,71.16,0,0,1,15.77-5.65c.31-.07.18-.55-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M307.39,216a67.81,67.81,0,0,0-28.24,9.33c-8.61,5.17-15.34,13-24.21,17.74a15.39,15.39,0,0,1-7.23,1.94,22.43,22.43,0,0,1-7.15-.89c-.31-.09-.44.39-.14.49a21.1,21.1,0,0,0,11.55.33,23.73,23.73,0,0,0,5.93-3,67.21,67.21,0,0,0,5.84-4.23c4.08-3.24,7.94-6.76,12.23-9.71a66.17,66.17,0,0,1,14-7.35,67.07,67.07,0,0,1,17.44-4.12c.32,0,.32-.53,0-.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M239.58,242.36a36.19,36.19,0,0,0,25-9.11c3.69-3.22,7-6.87,11.05-9.66a43.26,43.26,0,0,1,12.53-5.87,48,48,0,0,1,15.81-1.8c.32,0,.32-.49,0-.5a47.25,47.25,0,0,0-25.83,6c-8.23,4.74-13.69,13-22.37,17.1a35.16,35.16,0,0,1-16.18,3.34c-.32,0-.32.49,0,.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M302,215.76c-8.76-2-18.3-4.06-26.94-.44-8.1,3.39-11.91,11.71-17.75,17.71a33.29,33.29,0,0,1-12.37,8.39c-.3.11-.18.6.13.49,7.63-2.78,13.22-8.67,18-15,2.68-3.54,5.4-7.22,9.22-9.62a25.13,25.13,0,0,1,12.84-3.45c5.66-.12,11.23,1.16,16.72,2.4.31.07.45-.41.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M162,323.9a43.33,43.33,0,0,0,15.45-11.49c4.23-5,6.58-11.78,12.15-15.48,5.25-3.5,12-3.93,18.1-2.82,7.13,1.3,13.64,4.75,20.2,7.68,6.91,3.09,14.95,6.33,22.49,3.53l-.24-.41c-9.24,9.14-14.8,22.37-26.76,28.57a35.18,35.18,0,0,1-20,3.34,46.76,46.76,0,0,1-19.9-7.34,58.19,58.19,0,0,0-10.67-5.77c-3.6-1.34-7.49-2.08-11-.15-.28.15,0,.58.25.43,3.85-2.1,8.11-.85,11.91.7a68.39,68.39,0,0,1,10,5.68,46.53,46.53,0,0,0,18.62,6.86,36.39,36.39,0,0,0,19.08-2.41c6-2.6,10.73-7.15,14.8-12.18,4.68-5.78,8.68-12.12,14-17.38.17-.17,0-.51-.24-.42-7.42,2.75-15.31-.45-22.11-3.48-6.48-2.89-12.92-6.3-20-7.67-5.51-1.07-11.51-.91-16.64,1.55a18.54,18.54,0,0,0-6.8,5.66c-2,2.66-3.53,5.64-5.46,8.35a42.9,42.9,0,0,1-17.56,14.22c-.29.13,0,.56.26.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M166.8,322.41a46.59,46.59,0,0,0,13.45-8c4.13-3.73,6.15-9.35,10.77-12.59s11.1-3.66,16.65-2.59c6.12,1.18,11.78,3.9,17.62,5.95a45.36,45.36,0,0,0,9.81,2.4,25.66,25.66,0,0,0,5.4.09c.77-.08,1.53-.19,2.29-.34l.88-.2.87-.24c.42-.27.7-.2.86.23-.24-.22-.59.13-.35.35.74.68,1.71-1.31-.08-1.18a13.43,13.43,0,0,0-1.87.46,19.56,19.56,0,0,1-3,.45,26.14,26.14,0,0,1-5.38-.17c-7.12-.92-13.6-4.18-20.33-6.48-5.75-2-11.94-3.2-17.95-1.67a16.84,16.84,0,0,0-7.22,3.77c-2.25,2-3.85,4.68-5.59,7.15-4.1,5.83-10.47,9.45-16.93,12.16-.29.12-.16.6.14.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M248.19,306.36c-1.2,1.16-2.65,2-3.89,3.15a57.61,57.61,0,0,0-5.08,5.73c-3.47,4.14-6.88,8.39-11,11.95a30.86,30.86,0,0,1-13.58,7.29c-5.11,1.08-10-.17-14.78-1.85s-9.76-3.5-15-2.65c-.32,0-.18.53.13.48,5.13-.83,10,1,14.73,2.65,4.5,1.57,9,2.81,13.85,2.07,10.55-1.64,18-10.12,24.51-17.86,2-2.35,3.91-4.76,6-7,1.32-1.37,3-2.32,4.37-3.64.23-.22-.12-.58-.35-.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M169.88,323.12c4.83,1.24,9.71,2.29,14.59,3.29s9.63,2.07,14.47,2.94a44.46,44.46,0,0,0,6.9.79c2.39.07,4.77-.23,7.15-.27a36.86,36.86,0,0,0,9.6-1.64,36.39,36.39,0,0,0,10.73-5.46c.26-.18,0-.62-.25-.43-5.61,3.93-12.26,6.81-19.2,7-2.2.07-4.4.25-6.6.3A39.25,39.25,0,0,1,200,329c-4.68-.78-9.31-1.88-14-2.82-5.37-1.08-10.73-2.22-16-3.58a.25.25,0,0,0-.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M168.25,322.82c9.55-2.27,19.29-.55,28.82.94,9.14,1.43,18.34,2.45,27.45.21a48,48,0,0,0,14.18-6.07c.27-.17,0-.6-.25-.43a48.11,48.11,0,0,1-27.23,7.35c-9.37-.28-18.52-2.66-27.84-3.41a49,49,0,0,0-15.27.93c-.31.07-.18.56.14.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M169.4,322c9.57-3.3,19.71-4.09,29.77-3.9,5,.1,10,.43,15,.8,5.21.38,10.49,1,15.71.31a21.26,21.26,0,0,0,12.47-5.58.26.26,0,0,0-.36-.36c-7.32,7.26-18.48,5.81-27.82,5.13-9.73-.72-19.54-1.3-29.27-.27a71,71,0,0,0-15.66,3.38c-.3.11-.17.59.13.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M173.94,320.19a67.23,67.23,0,0,1,29.81-6.8A123.44,123.44,0,0,1,219.9,315c5,.68,10.3,1.38,15.3.5,3.93-.69,7.91-3.58,10.5-6.53.21-.24-.14-.59-.35-.35-2.51,2.85-6.34,5.64-10.14,6.37-5.39,1-11.26.08-16.62-.68a108.75,108.75,0,0,0-15.07-1.46,66.67,66.67,0,0,0-14.22,1.48,68.34,68.34,0,0,0-15.61,5.38c-.29.15,0,.58.25.44Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M245,306.22a35.26,35.26,0,0,1-13.15,5.3,39.67,39.67,0,0,1-15-.48c-4.6-.92-9.13-2-13.84-2A44.69,44.69,0,0,0,189.81,311a48.53,48.53,0,0,0-13.5,6.51c-.27.18,0,.61.25.43a46.41,46.41,0,0,1,26.4-8.38c5,0,9.76,1.26,14.62,2.19a39.07,39.07,0,0,0,13.55.44,35.68,35.68,0,0,0,14.12-5.49c.26-.17,0-.61-.26-.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M178.53,317.06c6.23-6.1,13-12.62,21.88-14.26,9.08-1.69,17.15,3.92,25.69,5.9a33.32,33.32,0,0,0,14.26.43c.32-.07.18-.55-.13-.48a33.26,33.26,0,0,1-14.45-.53c-4.36-1.06-8.43-2.88-12.65-4.36a25.09,25.09,0,0,0-12.21-1.56,28.85,28.85,0,0,0-11.5,4.77,81.32,81.32,0,0,0-11.24,9.73.25.25,0,0,0,.35.36Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M163.44,323a42.92,42.92,0,0,1-21.85-2.72c-3.23-1.31-6.22-3.15-9.46-4.42a18.77,18.77,0,0,0-8.86-1.28c-13.6,1.46-21.07,14.4-28,24.55-4.16,6.08-9.2,12.88-16.89,14.4a.25.25,0,0,0,0,.48c13.68,3.18,26.32,12.47,40.92,9.94,12.53-2.16,22.84-11.55,28.48-22.62,1.92-3.75,3.56-7.63,6.07-11s5.36-6.49,9.66-6.7c.32,0,.32-.52,0-.5-6.41.32-10.59,7-13.24,12.09-1.65,3.17-3.05,6.46-4.94,9.51a45.37,45.37,0,0,1-6.11,7.77,38.76,38.76,0,0,1-16.66,10.22c-6.86,2-13.83,1.24-20.57-.88-7.93-2.49-15.35-6.39-23.47-8.28v.48c6-1.19,10.52-5.66,14.11-10.35,4.26-5.56,7.67-11.73,12.15-17.13,4.29-5.19,9.89-9.72,16.63-11.14a18.12,18.12,0,0,1,10.2.8c3.24,1.21,6.2,3,9.39,4.38a43.46,43.46,0,0,0,22.6,2.91c.31,0,.18-.53-.14-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M158.51,324.29c-6.21,1-13.16,1.45-19.15-.8-3.24-1.21-6.28-3-9.68-3.74a16.94,16.94,0,0,0-8.68.5c-12.85,4.1-17.86,18-27.39,26.28a27,27,0,0,1-9.46,5.28c-.53.18-1.54.59-1.1,1.33a.6.6,0,0,0,1.11-.08.25.25,0,0,0-.48-.13c.24-.89,2.82-1.35,3.64-1.71a24.78,24.78,0,0,0,3.76-2.11A36.28,36.28,0,0,0,97,344c4.11-4.39,7.34-9.5,11.29-14s8.84-8.52,14.83-9.73a16.84,16.84,0,0,1,9.39.91c2.69,1,5.22,2.44,8,3.27,5.82,1.74,12.25,1.3,18.17.37.32,0,.18-.53-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M80.79,354.26c1.73.39,3.53.33,5.26.71a82.17,82.17,0,0,1,8.22,2.55c5.49,1.83,11,3.72,16.8,4.36A28.63,28.63,0,0,0,126,360c4.13-1.88,7.17-5.14,10-8.59,3.24-4,6.42-8.16,11.3-10.16.3-.12.17-.61-.13-.48-8.2,3.36-11.42,12.4-18.51,17.18-9.77,6.59-22.27,3.13-32.49-.26-3.12-1-6.23-2.13-9.4-3-1.92-.53-3.93-.44-5.87-.87-.31-.07-.44.41-.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M156,326.65c-6.65,6.88-13.71,13.43-20.77,19.9a49.75,49.75,0,0,1-5.35,4.37c-1.82,1.26-3.81,2.16-5.71,3.27a32.49,32.49,0,0,1-6,3c-1.28.43-2.56.87-3.86,1.25a35.81,35.81,0,0,1-12.2,1c-.33,0-.32.48,0,.5,6.92.41,14.19-.66,20.28-4.17,3.84-2.22,7.69-4.18,11.09-7.06,3.56-3,6.86-6.37,10.27-9.55,4.27-4,8.51-8,12.56-12.18a.25.25,0,0,0-.35-.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M157.61,325.45c-17.39,5.71-27.7,22.79-44.61,29.43a47.52,47.52,0,0,1-18,3.25.25.25,0,0,0,0,.5,49,49,0,0,0,28.24-8.72c7.4-5,13.71-11.47,20.93-16.74a48.86,48.86,0,0,1,13.52-7.24c.3-.1.17-.58-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M156.08,325.32c-9.15,2.05-17.63,6.19-25.56,11.12-4,2.5-7.94,5.2-11.8,8s-7.9,5.94-12.14,8.44c-5,3-10.91,5-16.77,3.61-.31-.07-.44.41-.13.48,9.93,2.3,18.56-4.35,26.1-9.8,7.89-5.71,15.9-11.37,24.67-15.67a70.76,70.76,0,0,1,15.76-5.65c.32-.07.18-.56-.13-.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M151.39,326.13a67.84,67.84,0,0,0-28.25,9.32c-8.6,5.18-15.33,13-24.21,17.74a15.25,15.25,0,0,1-7.23,1.95,23,23,0,0,1-7.15-.89.25.25,0,0,0-.13.48,21.1,21.1,0,0,0,11.55.34,24.42,24.42,0,0,0,5.93-3,68.77,68.77,0,0,0,5.84-4.23c4.07-3.24,7.93-6.76,12.23-9.72a66.8,66.8,0,0,1,31.42-11.46c.31,0,.32-.53,0-.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M83.57,352.49a36.16,36.16,0,0,0,25-9.12c3.69-3.22,7-6.86,11-9.65a43.12,43.12,0,0,1,12.53-5.87A48,48,0,0,1,148,326c.32,0,.32-.48,0-.5a47.2,47.2,0,0,0-25.82,6c-8.24,4.74-13.7,13-22.37,17.11A35.46,35.46,0,0,1,83.57,352c-.32,0-.32.49,0,.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M146,325.88c-8.77-2-18.3-4.05-26.95-.44-8.09,3.39-11.91,11.72-17.74,17.72a33.45,33.45,0,0,1-12.38,8.39c-.3.11-.17.59.13.48,7.63-2.77,13.23-8.67,18-15,2.68-3.54,5.39-7.23,9.21-9.62A25.17,25.17,0,0,1,129.12,324c5.66-.11,11.23,1.16,16.71,2.41.32.07.45-.41.14-.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M334.72,275a43.25,43.25,0,0,0,15.46-11.48c4.23-5,6.58-11.79,12.15-15.49,5.25-3.5,12-3.93,18.1-2.82,7.13,1.3,13.64,4.76,20.2,7.68,6.91,3.09,14.94,6.33,22.49,3.54l-.24-.42c-9.24,9.14-14.8,22.37-26.76,28.57a35.11,35.11,0,0,1-20,3.34,46.76,46.76,0,0,1-19.9-7.34,58.8,58.8,0,0,0-10.67-5.77c-3.61-1.34-7.49-2.08-11-.15-.28.15,0,.58.25.43,3.85-2.1,8.11-.85,11.91.7a68.39,68.39,0,0,1,10,5.68,46.53,46.53,0,0,0,18.62,6.86,36.48,36.48,0,0,0,19.08-2.4c6-2.6,10.73-7.16,14.8-12.18,4.68-5.79,8.68-12.13,14-17.39.17-.17,0-.51-.24-.42-7.42,2.75-15.31-.45-22.11-3.48-6.48-2.89-12.92-6.3-19.95-7.67-5.51-1.06-11.51-.91-16.64,1.55a18.64,18.64,0,0,0-6.8,5.66c-2,2.66-3.53,5.64-5.47,8.35a42.72,42.72,0,0,1-17.55,14.22c-.29.13,0,.56.25.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M339.53,273.5a46.73,46.73,0,0,0,13.45-8c4.13-3.73,6.15-9.35,10.77-12.59s11.1-3.66,16.65-2.59c6.12,1.19,11.78,3.9,17.62,6a45.36,45.36,0,0,0,9.81,2.4,25.66,25.66,0,0,0,5.4.09,22.18,22.18,0,0,0,2.29-.34c.29-.06.59-.12.88-.2s.58-.15.87-.24c.42-.27.7-.19.86.23-.24-.22-.59.13-.35.35.74.69,1.71-1.31-.08-1.18a13.46,13.46,0,0,0-1.87.47,22.43,22.43,0,0,1-3.05.45,26.79,26.79,0,0,1-5.37-.18c-7.12-.92-13.6-4.18-20.33-6.47-5.75-2-11.94-3.21-17.95-1.68a17,17,0,0,0-7.22,3.77c-2.25,2-3.85,4.68-5.59,7.15-4.1,5.83-10.47,9.45-16.93,12.16-.29.12-.16.6.14.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M420.92,257.45c-1.2,1.16-2.65,2-3.89,3.15a57.61,57.61,0,0,0-5.08,5.73c-3.47,4.14-6.88,8.39-11,11.95a30.91,30.91,0,0,1-13.58,7.29c-5.11,1.08-10-.17-14.78-1.85s-9.76-3.5-15-2.65c-.32.05-.18.54.13.48,5.13-.83,10,1,14.72,2.65,4.51,1.57,9.06,2.81,13.86,2.07,10.55-1.64,18-10.11,24.51-17.86,2-2.35,3.91-4.76,6-7,1.32-1.37,3-2.31,4.37-3.64.23-.22-.12-.58-.35-.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M342.61,274.21c4.83,1.24,9.71,2.29,14.59,3.29s9.63,2.07,14.47,2.95a45.69,45.69,0,0,0,6.9.78c2.39.07,4.77-.23,7.15-.27a36.86,36.86,0,0,0,9.6-1.64,36.39,36.39,0,0,0,10.73-5.46c.26-.18,0-.61-.25-.43-5.61,3.93-12.26,6.81-19.2,7-2.2.07-4.4.25-6.6.3a39.25,39.25,0,0,1-7.26-.61c-4.68-.78-9.31-1.88-14-2.82-5.37-1.08-10.73-2.22-16-3.58a.25.25,0,0,0-.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M341,273.91c9.55-2.27,19.29-.55,28.82.94,9.14,1.43,18.34,2.45,27.45.21A48,48,0,0,0,411.43,269c.27-.18,0-.61-.25-.44A48,48,0,0,1,384,275.91c-9.38-.28-18.52-2.66-27.84-3.41a49,49,0,0,0-15.27.93.25.25,0,0,0,.14.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M342.13,273.11c9.57-3.3,19.71-4.09,29.77-3.89,5,.09,10,.42,15,.79,5.21.38,10.49,1,15.71.32a21.27,21.27,0,0,0,12.46-5.59.25.25,0,0,0-.35-.35c-7.32,7.25-18.48,5.8-27.82,5.12-9.73-.72-19.54-1.3-29.27-.27A71.61,71.61,0,0,0,342,272.62c-.3.11-.17.59.13.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M346.67,271.28a67.23,67.23,0,0,1,29.81-6.8,125.19,125.19,0,0,1,16.15,1.65c5,.68,10.3,1.38,15.3.5,3.93-.69,7.91-3.58,10.5-6.53.21-.24-.14-.59-.35-.35-2.51,2.85-6.34,5.65-10.14,6.37-5.39,1-11.26.08-16.62-.68A110.31,110.31,0,0,0,376.25,264a67.49,67.49,0,0,0-29.83,6.87c-.29.14,0,.57.25.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M417.72,257.31a35.26,35.26,0,0,1-13.15,5.3,39.67,39.67,0,0,1-15-.48c-4.6-.92-9.13-2-13.84-2A44.69,44.69,0,0,0,362.54,262a48.53,48.53,0,0,0-13.5,6.51c-.27.18,0,.61.25.43a46.56,46.56,0,0,1,26.4-8.38c5,0,9.76,1.27,14.62,2.19a39.31,39.31,0,0,0,13.55.45A35.74,35.74,0,0,0,418,257.75c.26-.18,0-.61-.26-.44Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M351.26,268.15c6.23-6.1,13-12.61,21.88-14.26,9.08-1.68,17.14,3.93,25.69,5.91a33.47,33.47,0,0,0,14.26.42c.31-.07.18-.55-.13-.48a33.26,33.26,0,0,1-14.45-.53c-4.36-1.06-8.43-2.88-12.65-4.36a25,25,0,0,0-12.21-1.55,28.69,28.69,0,0,0-11.5,4.76,81.32,81.32,0,0,0-11.24,9.73.25.25,0,0,0,.35.36Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M336.17,274.13a42.92,42.92,0,0,1-21.85-2.71c-3.23-1.32-6.22-3.16-9.46-4.43a18.66,18.66,0,0,0-8.87-1.27c-13.59,1.45-21.06,14.39-28,24.54-4.16,6.08-9.2,12.88-16.89,14.4a.25.25,0,0,0,0,.48c13.68,3.19,26.32,12.47,40.92,9.94,12.53-2.16,22.84-11.55,28.48-22.62,1.91-3.75,3.56-7.63,6.07-11s5.35-6.49,9.66-6.7c.32,0,.32-.51,0-.5-6.41.32-10.59,7-13.24,12.09-1.65,3.17-3.05,6.47-4.94,9.51a45.37,45.37,0,0,1-6.11,7.77,38.66,38.66,0,0,1-16.66,10.22c-6.86,2-13.83,1.24-20.57-.88-7.93-2.49-15.35-6.39-23.47-8.28v.48c6-1.19,10.52-5.66,14.11-10.35,4.26-5.56,7.67-11.73,12.15-17.13,4.29-5.19,9.89-9.72,16.63-11.14a18.12,18.12,0,0,1,10.2.8c3.24,1.21,6.2,3.05,9.39,4.38a43.33,43.33,0,0,0,22.59,2.91c.32,0,.19-.52-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M331.23,275.38c-6.2,1-13.15,1.45-19.14-.8-3.24-1.21-6.28-3-9.68-3.74a16.84,16.84,0,0,0-8.68.51c-12.85,4.09-17.86,18-27.39,26.27a27,27,0,0,1-9.46,5.28c-.53.18-1.54.59-1.1,1.33a.6.6,0,0,0,1.11-.08.25.25,0,0,0-.48-.13c.24-.89,2.82-1.35,3.63-1.71a23.6,23.6,0,0,0,3.77-2.11,36.28,36.28,0,0,0,5.91-5.15c4.11-4.38,7.34-9.5,11.29-14s8.83-8.52,14.83-9.73a16.85,16.85,0,0,1,9.39.91c2.69,1,5.22,2.44,8,3.27,5.82,1.74,12.25,1.3,18.17.37.31,0,.18-.53-.14-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M253.52,305.35c1.73.39,3.53.33,5.26.71a82.17,82.17,0,0,1,8.22,2.55c5.49,1.84,11,3.73,16.8,4.36a28.63,28.63,0,0,0,15-1.93c4.13-1.88,7.17-5.14,10-8.59,3.23-4,6.42-8.16,11.3-10.16.3-.12.17-.6-.13-.48-8.2,3.36-11.42,12.4-18.51,17.19-9.77,6.58-22.27,3.13-32.49-.27-3.12-1-6.23-2.13-9.4-3-1.92-.53-3.93-.44-5.87-.87-.31-.07-.44.41-.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M328.76,277.74c-6.65,6.88-13.71,13.43-20.77,19.9a49.75,49.75,0,0,1-5.35,4.37c-1.82,1.26-3.81,2.17-5.71,3.27a32.49,32.49,0,0,1-6,3c-1.28.43-2.56.87-3.86,1.25a35.81,35.81,0,0,1-12.2,1.05c-.33,0-.32.48,0,.5,6.92.41,14.18-.66,20.28-4.17,3.84-2.21,7.69-4.17,11.09-7.06,3.56-3,6.86-6.36,10.27-9.54,4.27-4,8.51-8,12.56-12.19.23-.23-.13-.58-.35-.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M330.33,276.54c-17.38,5.71-27.69,22.8-44.6,29.43a47.74,47.74,0,0,1-18,3.26c-.32,0-.32.49,0,.5A49,49,0,0,0,296,301c7.4-5,13.71-11.46,20.93-16.74A49.12,49.12,0,0,1,330.47,277c.3-.1.17-.58-.14-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M328.81,276.42c-9.16,2-17.63,6.18-25.56,11.11-4,2.5-7.94,5.21-11.8,8s-7.9,5.94-12.14,8.44c-5,3-10.91,5-16.77,3.61a.25.25,0,0,0-.13.49c9.92,2.3,18.56-4.35,26.1-9.81,7.89-5.7,15.9-11.37,24.67-15.67a70.76,70.76,0,0,1,15.76-5.65c.32-.07.18-.55-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M324.11,277.22a68,68,0,0,0-28.24,9.32c-8.6,5.18-15.33,13-24.21,17.75a15.36,15.36,0,0,1-7.23,1.94,22.72,22.72,0,0,1-7.15-.89.25.25,0,0,0-.13.48,21.1,21.1,0,0,0,11.55.34,24.07,24.07,0,0,0,5.93-3,67.06,67.06,0,0,0,5.83-4.23c4.08-3.24,7.94-6.76,12.24-9.72a66.82,66.82,0,0,1,31.41-11.46c.32,0,.33-.53,0-.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M256.3,303.58a36.18,36.18,0,0,0,25-9.12c3.69-3.22,7-6.86,11-9.65a43.12,43.12,0,0,1,12.53-5.87,48.25,48.25,0,0,1,15.81-1.81c.32,0,.32-.48,0-.5a47.2,47.2,0,0,0-25.82,6c-8.24,4.74-13.7,13-22.37,17.11a35.21,35.21,0,0,1-16.19,3.34c-.32,0-.32.49,0,.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M318.69,277c-8.76-2-18.29-4.06-26.94-.44-8.09,3.38-11.91,11.71-17.74,17.71a33.45,33.45,0,0,1-12.38,8.39c-.3.11-.17.59.13.48,7.63-2.77,13.23-8.66,18-15,2.69-3.54,5.4-7.23,9.22-9.62a25.17,25.17,0,0,1,12.85-3.45c5.66-.12,11.23,1.15,16.71,2.4.32.07.45-.41.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M291,261.23a43.28,43.28,0,0,0-15.46,11.49c-4.23,5-6.58,11.78-12.15,15.49-5.25,3.49-12,3.92-18.1,2.82-7.13-1.31-13.64-4.76-20.2-7.69-6.91-3.09-14.94-6.33-22.49-3.53l.24.41c9.24-9.14,14.8-22.37,26.76-28.57a35.18,35.18,0,0,1,20-3.34,46.65,46.65,0,0,1,19.9,7.35,58.75,58.75,0,0,0,10.67,5.76c3.6,1.34,7.49,2.08,11,.16a.25.25,0,0,0-.25-.44c-3.85,2.1-8.11.85-11.91-.7a68.39,68.39,0,0,1-10-5.68,46.53,46.53,0,0,0-18.62-6.86,36.39,36.39,0,0,0-19.08,2.41c-6,2.6-10.73,7.15-14.8,12.18-4.68,5.78-8.68,12.13-14,17.38-.17.17,0,.51.24.42,7.42-2.75,15.31.45,22.11,3.48,6.48,2.89,12.92,6.3,20,7.67,5.51,1.07,11.51.91,16.64-1.55a18.54,18.54,0,0,0,6.8-5.66c2-2.66,3.53-5.64,5.46-8.35a43,43,0,0,1,17.56-14.22c.29-.13,0-.56-.25-.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M286.23,262.72a46.59,46.59,0,0,0-13.45,8c-4.13,3.73-6.15,9.35-10.77,12.59s-11.1,3.66-16.65,2.59c-6.12-1.18-11.78-3.9-17.62-5.94a44.81,44.81,0,0,0-9.81-2.41,26.35,26.35,0,0,0-5.4-.09c-.77.08-1.53.19-2.29.34l-.88.2-.87.24c-.42.27-.7.2-.86-.23.24.22.59-.13.35-.35-.74-.68-1.71,1.31.08,1.18a13.43,13.43,0,0,0,1.87-.46,19.56,19.56,0,0,1,3-.45,26.86,26.86,0,0,1,5.38.17c7.12.92,13.6,4.18,20.33,6.48,5.75,2,11.94,3.2,17.95,1.67a16.84,16.84,0,0,0,7.22-3.77c2.25-2,3.85-4.68,5.59-7.15,4.1-5.83,10.47-9.45,16.93-12.16.29-.12.16-.6-.14-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M204.84,278.77c1.2-1.16,2.65-2,3.89-3.15a57.61,57.61,0,0,0,5.08-5.73c3.47-4.14,6.88-8.39,11-11.95a30.86,30.86,0,0,1,13.58-7.29c5.11-1.08,10,.17,14.78,1.85s9.76,3.5,15,2.65c.32-.05.18-.53-.13-.48-5.13.83-10-1-14.73-2.65-4.5-1.57-9-2.81-13.85-2.07-10.55,1.64-18,10.12-24.51,17.86-2,2.35-3.91,4.76-6,7-1.32,1.36-3,2.31-4.37,3.63-.23.22.12.58.35.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M283.15,262c-4.83-1.24-9.71-2.29-14.59-3.29s-9.63-2.07-14.47-2.94a44.46,44.46,0,0,0-6.9-.79c-2.39-.07-4.77.23-7.15.27a36.86,36.86,0,0,0-9.6,1.64,36.39,36.39,0,0,0-10.73,5.46c-.26.18,0,.62.25.43,5.61-3.93,12.26-6.8,19.2-7,2.2-.07,4.4-.25,6.6-.3a39.25,39.25,0,0,1,7.26.61c4.68.78,9.31,1.89,14,2.82,5.37,1.08,10.73,2.22,16,3.58a.25.25,0,0,0,.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M284.78,262.31c-9.55,2.27-19.29.55-28.82-.94-9.14-1.43-18.34-2.45-27.45-.21a48,48,0,0,0-14.18,6.07c-.27.17,0,.6.25.43a48.11,48.11,0,0,1,27.23-7.35c9.37.28,18.52,2.66,27.84,3.41a49,49,0,0,0,15.27-.93c.31-.07.18-.56-.14-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M283.63,263.12c-9.57,3.29-19.71,4.08-29.77,3.89-5-.1-10-.43-15-.8-5.21-.38-10.49-1-15.71-.31a21.26,21.26,0,0,0-12.47,5.58.26.26,0,0,0,.36.36c7.32-7.26,18.48-5.81,27.82-5.13,9.73.72,19.54,1.3,29.27.27a71,71,0,0,0,15.66-3.38c.3-.11.17-.59-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M279.09,264.94a67.23,67.23,0,0,1-29.81,6.8,125.17,125.17,0,0,1-16.15-1.64c-5-.69-10.3-1.39-15.3-.51-3.93.69-7.91,3.58-10.5,6.53-.21.24.14.59.35.35,2.51-2.85,6.34-5.64,10.14-6.37,5.39-1,11.26-.08,16.62.68a108.75,108.75,0,0,0,15.07,1.46,66.67,66.67,0,0,0,14.22-1.48,68.34,68.34,0,0,0,15.61-5.38c.29-.14,0-.58-.25-.44Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M208,278.91a35.26,35.26,0,0,1,13.15-5.3,39.67,39.67,0,0,1,15,.48c4.6.92,9.13,2,13.84,2a44.36,44.36,0,0,0,13.15-1.94,48.53,48.53,0,0,0,13.5-6.51c.27-.18,0-.61-.25-.43a46.41,46.41,0,0,1-26.4,8.38c-5,0-9.76-1.26-14.62-2.19A39.07,39.07,0,0,0,221.9,273a35.68,35.68,0,0,0-14.12,5.49c-.26.17,0,.61.26.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M274.5,268.07c-6.23,6.11-13,12.62-21.88,14.27-9.08,1.68-17.14-3.93-25.69-5.91a33.32,33.32,0,0,0-14.26-.43c-.32.07-.18.55.13.49a33,33,0,0,1,14.45.53c4.36,1.05,8.43,2.87,12.65,4.35a25.09,25.09,0,0,0,12.21,1.56,28.85,28.85,0,0,0,11.5-4.77,81.32,81.32,0,0,0,11.24-9.73.25.25,0,0,0-.35-.36Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M289.59,262.09a42.92,42.92,0,0,1,21.85,2.72c3.23,1.31,6.22,3.15,9.46,4.42a18.79,18.79,0,0,0,8.87,1.28c13.59-1.46,21.06-14.4,28-24.55,4.16-6.08,9.2-12.88,16.89-14.4a.25.25,0,0,0,0-.48c-13.68-3.18-26.32-12.47-40.92-9.94-12.53,2.16-22.84,11.55-28.48,22.62-1.92,3.75-3.56,7.63-6.07,11-2.39,3.25-5.36,6.48-9.66,6.69-.32,0-.32.52,0,.5,6.41-.32,10.59-7,13.24-12.09,1.65-3.17,3.05-6.46,4.94-9.51a45.37,45.37,0,0,1,6.11-7.77,38.76,38.76,0,0,1,16.66-10.22c6.86-2,13.83-1.24,20.57.88,7.93,2.49,15.35,6.39,23.47,8.28v-.48c-6,1.19-10.52,5.66-14.11,10.35-4.26,5.56-7.67,11.73-12.15,17.13-4.29,5.19-9.89,9.72-16.63,11.14a18,18,0,0,1-10.2-.8c-3.24-1.21-6.2-3-9.39-4.38a43.43,43.43,0,0,0-22.59-2.91c-.32,0-.19.53.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M294.52,260.84c6.21-1,13.16-1.45,19.15.8,3.24,1.21,6.28,3,9.68,3.74a16.94,16.94,0,0,0,8.68-.5c12.85-4.1,17.86-18,27.39-26.28a27.16,27.16,0,0,1,9.46-5.28c.53-.18,1.54-.59,1.1-1.33a.6.6,0,0,0-1.11.08.25.25,0,0,0,.48.13c-.24.89-2.82,1.35-3.64,1.71A24,24,0,0,0,362,236a35.74,35.74,0,0,0-5.91,5.14c-4.11,4.39-7.34,9.5-11.29,14s-8.84,8.51-14.83,9.72a16.84,16.84,0,0,1-9.39-.91c-2.69-1-5.22-2.44-8-3.26-5.82-1.75-12.25-1.31-18.17-.38-.32.05-.18.53.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M372.24,230.87c-1.73-.39-3.53-.33-5.26-.71a82.17,82.17,0,0,1-8.22-2.55c-5.49-1.83-11-3.72-16.8-4.36a28.73,28.73,0,0,0-15,1.93c-4.13,1.88-7.17,5.14-10,8.59-3.24,4-6.42,8.16-11.3,10.16-.3.12-.17.61.13.48,8.2-3.36,11.42-12.4,18.51-17.18,9.77-6.59,22.27-3.13,32.49.27,3.12,1,6.23,2.12,9.4,3,1.92.53,3.93.44,5.87.87.31.07.44-.41.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M297,258.48c6.65-6.88,13.71-13.43,20.77-19.9a51,51,0,0,1,5.35-4.37c1.82-1.26,3.81-2.16,5.71-3.27a32.49,32.49,0,0,1,6-3c1.28-.43,2.56-.87,3.86-1.25a35.81,35.81,0,0,1,12.2-1.05c.33,0,.32-.48,0-.5-6.92-.41-14.18.66-20.28,4.17-3.84,2.22-7.69,4.18-11.09,7.06-3.56,3-6.86,6.37-10.27,9.55-4.27,4-8.51,8-12.56,12.18a.25.25,0,0,0,.35.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M295.42,259.68c17.39-5.71,27.7-22.79,44.61-29.43A47.52,47.52,0,0,1,358,227a.25.25,0,0,0,0-.5,49,49,0,0,0-28.24,8.72c-7.4,5-13.71,11.47-20.93,16.74a48.86,48.86,0,0,1-13.52,7.24c-.3.1-.17.58.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M297,259.81c9.16-2.05,17.63-6.19,25.56-11.12,4-2.5,7.94-5.2,11.8-8s7.9-5.94,12.14-8.44c5-3,10.91-5,16.77-3.61.31.07.44-.41.13-.48-9.93-2.3-18.56,4.35-26.1,9.8-7.89,5.71-15.9,11.37-24.67,15.67a70.76,70.76,0,0,1-15.76,5.65c-.32.07-.18.56.13.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M301.64,259a67.84,67.84,0,0,0,28.25-9.32c8.6-5.18,15.33-13,24.21-17.74a15.25,15.25,0,0,1,7.23-1.95,23,23,0,0,1,7.15.89c.31.1.44-.39.13-.48a21.2,21.2,0,0,0-11.55-.34,24.42,24.42,0,0,0-5.93,3,68.77,68.77,0,0,0-5.84,4.23c-4.07,3.24-7.93,6.76-12.23,9.72a66.8,66.8,0,0,1-31.42,11.46c-.31,0-.32.53,0,.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M369.46,232.64a36.16,36.16,0,0,0-25,9.12c-3.69,3.22-7,6.86-11.05,9.65a43.4,43.4,0,0,1-12.53,5.88,48.25,48.25,0,0,1-15.81,1.8c-.32,0-.32.48,0,.5a47.2,47.2,0,0,0,25.82-6c8.24-4.74,13.7-13,22.37-17.11a35.46,35.46,0,0,1,16.19-3.34c.32,0,.32-.48,0-.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M307.06,259.25c8.77,2,18.3,4.05,26.95.44C342.1,256.3,345.92,248,351.75,242a33.45,33.45,0,0,1,12.38-8.39c.3-.11.17-.59-.13-.48-7.63,2.77-13.23,8.67-18,15-2.68,3.54-5.39,7.23-9.21,9.62a25.17,25.17,0,0,1-12.85,3.46c-5.66.11-11.23-1.16-16.71-2.41-.32-.07-.45.42-.14.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M118.31,310.14a43.37,43.37,0,0,0-15.46,11.48c-4.23,5-6.58,11.79-12.14,15.49-5.26,3.5-12,3.93-18.11,2.82-7.13-1.3-13.64-4.76-20.2-7.68-6.91-3.09-14.94-6.33-22.49-3.54l.24.42C39.39,320,45,306.76,56.91,300.56a35.11,35.11,0,0,1,20-3.34,46.76,46.76,0,0,1,19.9,7.34,58.8,58.8,0,0,0,10.67,5.77c3.61,1.34,7.49,2.08,11,.15.28-.15,0-.58-.25-.43-3.85,2.1-8.11.85-11.91-.7a68.39,68.39,0,0,1-10-5.68,46.53,46.53,0,0,0-18.62-6.86,36.48,36.48,0,0,0-19.08,2.4c-6,2.6-10.73,7.16-14.8,12.19-4.68,5.78-8.68,12.12-14,17.38-.17.17,0,.51.24.42,7.42-2.75,15.31.45,22.11,3.48,6.48,2.89,12.92,6.3,19.95,7.67,5.51,1.06,11.51.91,16.64-1.55a18.54,18.54,0,0,0,6.8-5.66c2-2.66,3.53-5.64,5.47-8.35a42.72,42.72,0,0,1,17.55-14.22c.29-.13,0-.56-.25-.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M113.5,311.63a46.73,46.73,0,0,0-13.45,8c-4.13,3.73-6.15,9.35-10.77,12.59s-11.1,3.66-16.65,2.59c-6.12-1.19-11.78-3.9-17.62-5.95a45.36,45.36,0,0,0-9.81-2.4,25.66,25.66,0,0,0-5.4-.09c-.77.08-1.53.19-2.29.34l-.88.2q-.43.1-.87.24c-.42.27-.7.19-.86-.23.24.22.59-.13.35-.35-.74-.68-1.71,1.31.08,1.18a13.43,13.43,0,0,0,1.87-.46,19.68,19.68,0,0,1,3-.45,26.07,26.07,0,0,1,5.37.17c7.12.92,13.6,4.18,20.33,6.47,5.75,2,11.94,3.21,18,1.68a17,17,0,0,0,7.22-3.77c2.25-2,3.85-4.68,5.59-7.15,4.1-5.83,10.47-9.45,16.93-12.16.29-.12.16-.6-.14-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M32.11,327.68c1.2-1.16,2.65-2,3.89-3.15a57.61,57.61,0,0,0,5.08-5.73c3.47-4.14,6.88-8.39,11-11.95a30.91,30.91,0,0,1,13.58-7.29c5.12-1.08,10,.17,14.78,1.85s9.76,3.5,15,2.65c.32-.05.18-.53-.13-.48-5.13.83-10-1-14.72-2.65-4.51-1.57-9.06-2.81-13.86-2.07-10.55,1.64-18,10.12-24.51,17.86-2,2.35-3.91,4.76-6,7-1.32,1.37-3,2.31-4.37,3.64-.23.22.12.58.35.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M110.42,310.92c-4.83-1.24-9.71-2.29-14.59-3.29s-9.63-2.07-14.47-2.95a45.69,45.69,0,0,0-6.9-.78c-2.39-.07-4.77.23-7.15.27a36.86,36.86,0,0,0-9.6,1.64A36.39,36.39,0,0,0,47,311.27c-.26.18,0,.62.25.43,5.61-3.93,12.26-6.81,19.2-7,2.2-.07,4.4-.25,6.6-.3a39.25,39.25,0,0,1,7.26.61c4.68.78,9.31,1.88,14,2.82,5.37,1.08,10.73,2.22,16,3.58a.25.25,0,0,0,.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M112.05,311.22c-9.55,2.27-19.29.55-28.82-.94-9.14-1.43-18.34-2.45-27.45-.21a48,48,0,0,0-14.18,6.06c-.27.18,0,.61.25.44a48,48,0,0,1,27.23-7.35c9.38.28,18.52,2.66,27.84,3.41a49,49,0,0,0,15.27-.93.25.25,0,0,0-.14-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M110.9,312c-9.57,3.3-19.71,4.09-29.77,3.89-5-.09-10-.42-15-.79-5.21-.38-10.49-1-15.71-.31a21.2,21.2,0,0,0-12.46,5.58.25.25,0,0,0,.35.36c7.32-7.26,18.48-5.81,27.82-5.13,9.73.72,19.54,1.3,29.27.27A71.61,71.61,0,0,0,111,312.51c.3-.11.17-.59-.13-.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M106.36,313.85a67.23,67.23,0,0,1-29.81,6.8A123.44,123.44,0,0,1,60.4,319c-5-.68-10.3-1.38-15.3-.5-3.93.69-7.91,3.58-10.5,6.53-.21.24.14.59.35.35,2.51-2.85,6.34-5.65,10.14-6.37,5.39-1,11.26-.08,16.62.68a110.31,110.31,0,0,0,15.07,1.46A66.67,66.67,0,0,0,91,319.67a67.89,67.89,0,0,0,15.61-5.39c.29-.14,0-.57-.25-.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M35.31,327.82a35.26,35.26,0,0,1,13.15-5.3,39.66,39.66,0,0,1,15,.48c4.6.92,9.13,2,13.84,2a44.69,44.69,0,0,0,13.15-1.94,48.53,48.53,0,0,0,13.5-6.51c.27-.18,0-.61-.25-.43a46.41,46.41,0,0,1-26.4,8.38c-5,0-9.76-1.27-14.62-2.19a39.31,39.31,0,0,0-13.55-.45,35.75,35.75,0,0,0-14.12,5.5c-.26.17,0,.61.26.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M101.77,317c-6.23,6.1-13,12.61-21.88,14.26-9.08,1.69-17.14-3.93-25.69-5.9a33.31,33.31,0,0,0-14.26-.43c-.31.07-.18.55.13.48a33.26,33.26,0,0,1,14.45.53c4.36,1.06,8.43,2.88,12.65,4.36a25,25,0,0,0,12.21,1.55,28.69,28.69,0,0,0,11.5-4.76,81.32,81.32,0,0,0,11.24-9.73.25.25,0,0,0-.35-.36Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M116.86,311a42.92,42.92,0,0,1,21.85,2.72c3.23,1.31,6.22,3.15,9.46,4.42a18.79,18.79,0,0,0,8.87,1.28c13.59-1.46,21.06-14.4,28-24.55,4.16-6.08,9.2-12.88,16.89-14.4a.25.25,0,0,0,0-.48c-13.68-3.19-26.32-12.47-40.92-9.94-12.53,2.16-22.84,11.55-28.48,22.62-1.91,3.75-3.56,7.63-6.07,11s-5.35,6.49-9.66,6.7c-.32,0-.32.52,0,.5,6.41-.32,10.59-7,13.24-12.09,1.65-3.17,3-6.46,4.94-9.51a45.37,45.37,0,0,1,6.11-7.77,38.66,38.66,0,0,1,16.66-10.22c6.86-2,13.83-1.24,20.57.88,7.93,2.49,15.35,6.39,23.47,8.28V280c-6,1.19-10.52,5.66-14.11,10.35-4.26,5.56-7.67,11.73-12.15,17.13-4.29,5.19-9.89,9.72-16.62,11.14a18.15,18.15,0,0,1-10.21-.8c-3.24-1.21-6.2-3.05-9.39-4.38a43.33,43.33,0,0,0-22.59-2.91c-.32,0-.19.52.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M121.8,309.75c6.2-1,13.15-1.45,19.14.8,3.24,1.21,6.28,3,9.68,3.74a16.84,16.84,0,0,0,8.68-.51c12.85-4.09,17.86-18,27.39-26.27a27,27,0,0,1,9.46-5.28c.53-.18,1.54-.59,1.1-1.33a.6.6,0,0,0-1.11.08.25.25,0,0,0,.48.13c-.24.89-2.81,1.35-3.63,1.71a24.4,24.4,0,0,0-3.77,2.11,36.28,36.28,0,0,0-5.91,5.15c-4.11,4.38-7.34,9.5-11.29,14s-8.83,8.52-14.83,9.73a16.85,16.85,0,0,1-9.39-.91c-2.69-1-5.22-2.44-8-3.27-5.82-1.74-12.25-1.3-18.17-.37-.31.05-.18.53.14.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M199.51,279.78c-1.73-.39-3.53-.33-5.26-.71a82.17,82.17,0,0,1-8.22-2.55c-5.49-1.84-11-3.73-16.8-4.36a28.63,28.63,0,0,0-15,1.93c-4.13,1.88-7.17,5.14-10,8.59-3.23,4-6.42,8.16-11.3,10.16-.3.12-.17.6.13.48,8.2-3.36,11.42-12.4,18.51-17.18,9.77-6.59,22.27-3.14,32.49.26,3.12,1,6.23,2.13,9.4,3,1.92.53,3.93.44,5.87.87.31.07.44-.41.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M124.27,307.39C130.93,300.51,138,294,145,287.49a49.75,49.75,0,0,1,5.35-4.37c1.82-1.26,3.81-2.16,5.71-3.27a32.49,32.49,0,0,1,6-3c1.28-.43,2.56-.87,3.86-1.25a35.81,35.81,0,0,1,12.2-1.05c.33,0,.32-.48,0-.5-6.92-.41-14.18.66-20.28,4.17-3.84,2.21-7.69,4.17-11.09,7.06-3.56,3-6.86,6.36-10.27,9.54-4.27,4-8.51,8-12.56,12.19-.23.23.13.58.35.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M122.7,308.59c17.38-5.71,27.69-22.8,44.6-29.43a47.74,47.74,0,0,1,18-3.26c.32,0,.32-.49,0-.5A49,49,0,0,0,157,284.13c-7.4,5-13.71,11.46-20.93,16.74a49,49,0,0,1-13.52,7.24c-.3.1-.17.58.14.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M124.22,308.72c9.16-2.05,17.63-6.19,25.56-11.12,4-2.5,7.94-5.2,11.8-8s7.9-5.94,12.14-8.44c5-3,10.91-5,16.77-3.61.31.07.44-.41.13-.48-9.92-2.31-18.56,4.34-26.1,9.8-7.89,5.71-15.9,11.37-24.67,15.67a70.76,70.76,0,0,1-15.76,5.65c-.32.07-.18.56.13.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M128.92,307.91a68,68,0,0,0,28.24-9.32c8.6-5.18,15.33-13,24.21-17.75a15.36,15.36,0,0,1,7.23-1.94,23,23,0,0,1,7.15.89.25.25,0,0,0,.13-.48,21.1,21.1,0,0,0-11.55-.34,24.07,24.07,0,0,0-5.93,3,67.06,67.06,0,0,0-5.83,4.23c-4.08,3.24-7.94,6.76-12.24,9.72a66.82,66.82,0,0,1-31.41,11.46c-.32,0-.33.53,0,.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M196.73,281.55a36.18,36.18,0,0,0-25,9.12c-3.69,3.22-7,6.86-11.05,9.65a43.12,43.12,0,0,1-12.53,5.87A48.25,48.25,0,0,1,132.35,308c-.32,0-.32.48,0,.5a47.2,47.2,0,0,0,25.82-6c8.24-4.74,13.7-13,22.37-17.11a35.21,35.21,0,0,1,16.19-3.34c.32,0,.32-.49,0-.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M134.34,308.15c8.76,2,18.29,4.06,26.94.44,8.09-3.38,11.91-11.71,17.74-17.71a33.45,33.45,0,0,1,12.38-8.39c.3-.11.17-.59-.13-.48-7.63,2.77-13.23,8.66-18,15-2.69,3.54-5.4,7.23-9.22,9.62a25.17,25.17,0,0,1-12.85,3.45c-5.66.12-11.22-1.15-16.71-2.4-.31-.07-.45.41-.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M303.77,323.43a43.28,43.28,0,0,0-15.46,11.49c-4.23,5-6.58,11.78-12.14,15.49-5.26,3.5-12,3.92-18.11,2.82-7.13-1.31-13.64-4.76-20.2-7.69-6.91-3.08-14.94-6.33-22.49-3.53l.25.42c9.24-9.15,14.8-22.38,26.75-28.57a35.13,35.13,0,0,1,20-3.35,46.83,46.83,0,0,1,19.9,7.35,58.66,58.66,0,0,0,10.66,5.76c3.61,1.34,7.5,2.08,11,.16a.25.25,0,0,0-.25-.43c-3.85,2.09-8.11.84-11.91-.71a67.77,67.77,0,0,1-10-5.68,46.41,46.41,0,0,0-18.63-6.85,36.28,36.28,0,0,0-19.08,2.4c-6,2.6-10.73,7.15-14.8,12.18-4.68,5.78-8.68,12.13-14,17.38-.17.17,0,.51.25.42,7.41-2.75,15.31.45,22.1,3.48,6.48,2.9,12.92,6.31,19.95,7.67,5.51,1.07,11.51.91,16.65-1.55a18.59,18.59,0,0,0,6.79-5.66c2-2.65,3.53-5.64,5.47-8.35A43,43,0,0,1,304,323.86c.29-.13,0-.56-.25-.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M299,324.92a47.09,47.09,0,0,0-13.46,8c-4.13,3.74-6.14,9.36-10.76,12.59-4.78,3.34-11.11,3.67-16.66,2.59-6.12-1.18-11.77-3.9-17.62-5.94a45.38,45.38,0,0,0-9.81-2.41,25.65,25.65,0,0,0-5.4-.08A22.15,22.15,0,0,0,223,340l-.87.2c-.3.07-.59.15-.88.24-.42.27-.7.2-.86-.22.24.21.59-.14.36-.36-.74-.68-1.72,1.32.07,1.18a13.92,13.92,0,0,0,1.88-.46,19.38,19.38,0,0,1,3-.45,26.07,26.07,0,0,1,5.37.18c7.13.91,13.6,4.17,20.33,6.47,5.75,2,12,3.2,18,1.67a16.7,16.7,0,0,0,7.21-3.77c2.26-2,3.85-4.68,5.59-7.15,4.1-5.83,10.47-9.45,16.93-12.15.29-.13.16-.61-.13-.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M217.57,341c1.2-1.16,2.65-2,3.89-3.15a57.61,57.61,0,0,0,5.08-5.73c3.47-4.14,6.88-8.39,11-11.95a30.86,30.86,0,0,1,13.58-7.29c5.11-1.08,10,.17,14.77,1.86s9.76,3.49,15,2.64c.32-.05.19-.53-.13-.48-5.12.83-10-1-14.72-2.65-4.5-1.57-9.06-2.81-13.86-2.07-10.54,1.64-18,10.12-24.51,17.86-2,2.35-3.91,4.77-6,7-1.32,1.36-3,2.31-4.37,3.63a.25.25,0,0,0,.35.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M295.88,324.21c-4.83-1.23-9.7-2.29-14.59-3.29s-9.63-2.07-14.46-2.94a46,46,0,0,0-6.91-.79c-2.39-.06-4.77.23-7.15.27a36.26,36.26,0,0,0-20.33,7.1c-.26.18,0,.62.25.43,5.61-3.93,12.26-6.8,19.2-7,2.2-.06,4.41-.25,6.61-.3a39.15,39.15,0,0,1,7.25.62c4.68.78,9.31,1.88,14,2.81,5.37,1.09,10.73,2.23,16,3.58a.25.25,0,0,0,.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M297.51,324.51c-9.55,2.27-19.28.55-28.82-.94-9.14-1.43-18.34-2.45-27.44-.21a48,48,0,0,0-14.19,6.07.25.25,0,0,0,.26.43,48.06,48.06,0,0,1,27.22-7.35c9.38.28,18.52,2.66,27.85,3.41a48.9,48.9,0,0,0,15.26-.93c.31-.07.18-.56-.14-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M296.36,325.32c-9.57,3.29-19.71,4.08-29.77,3.89-5-.1-10-.43-15-.79-5.22-.39-10.5-1-15.71-.32a21.23,21.23,0,0,0-12.47,5.58c-.23.23.12.59.35.36,7.32-7.26,18.49-5.81,27.83-5.12,9.72.71,19.54,1.29,29.26.26a70.47,70.47,0,0,0,15.66-3.38c.3-.11.17-.59-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M291.82,327.15a67.32,67.32,0,0,1-29.8,6.79,125.14,125.14,0,0,1-16.16-1.64c-5-.68-10.3-1.39-15.3-.51-3.93.69-7.91,3.58-10.5,6.53-.21.24.14.59.35.35,2.52-2.85,6.35-5.64,10.14-6.37,5.39-1,11.26-.08,16.62.68a108.9,108.9,0,0,0,15.07,1.46,67.28,67.28,0,0,0,29.83-6.86c.29-.14,0-.57-.25-.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M220.77,341.11a35.42,35.42,0,0,1,13.15-5.3,39.72,39.72,0,0,1,15.05.48c4.59.92,9.13,2,13.84,2A44.35,44.35,0,0,0,276,336.38a48.84,48.84,0,0,0,13.51-6.5c.26-.19,0-.62-.26-.44a46.4,46.4,0,0,1-26.39,8.38c-5,0-9.77-1.26-14.63-2.19a39.3,39.3,0,0,0-13.55-.44,35.64,35.64,0,0,0-14.11,5.49c-.27.18,0,.61.25.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M287.23,330.28c-6.23,6.1-13,12.61-21.88,14.26-9.08,1.68-17.14-3.93-25.69-5.91a33.47,33.47,0,0,0-14.26-.43c-.31.07-.18.55.14.49a32.92,32.92,0,0,1,14.44.53c4.36,1,8.43,2.88,12.65,4.35a25,25,0,0,0,12.21,1.56,28.79,28.79,0,0,0,11.5-4.77,80.65,80.65,0,0,0,11.24-9.73.25.25,0,0,0-.35-.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M302.32,324.29A42.92,42.92,0,0,1,324.17,327c3.23,1.31,6.22,3.15,9.46,4.42a18.55,18.55,0,0,0,8.87,1.28c13.59-1.46,21.06-14.4,28-24.55,4.16-6.08,9.2-12.88,16.89-14.4a.25.25,0,0,0,0-.48c-13.67-3.18-26.32-12.47-40.92-9.94C334,285.51,323.64,294.89,318,306c-1.91,3.75-3.56,7.63-6.07,11-2.39,3.25-5.35,6.48-9.65,6.69-.32,0-.33.52,0,.5,6.41-.31,10.58-7,13.24-12.08,1.64-3.18,3.05-6.47,4.93-9.51a45.11,45.11,0,0,1,6.12-7.78,38.73,38.73,0,0,1,16.65-10.22c6.86-2,13.84-1.24,20.58.88,7.92,2.49,15.35,6.39,23.47,8.28v-.48c-6,1.19-10.53,5.66-14.12,10.35-4.26,5.56-7.67,11.73-12.14,17.13-4.3,5.19-9.9,9.72-16.63,11.15a18.15,18.15,0,0,1-10.21-.81c-3.24-1.21-6.2-3-9.39-4.38a43.42,43.42,0,0,0-22.59-2.91c-.32.05-.18.53.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M307.26,323c6.21-1,13.15-1.45,19.15.8,3.23,1.22,6.27,3,9.68,3.74a16.8,16.8,0,0,0,8.67-.5c12.85-4.09,17.86-18,27.39-26.27a27.2,27.2,0,0,1,9.46-5.29c.53-.18,1.54-.59,1.1-1.32a.6.6,0,0,0-1.11.07.26.26,0,0,0,.49.14c-.25.88-2.82,1.34-3.64,1.7a24.46,24.46,0,0,0-3.77,2.12,35.27,35.27,0,0,0-5.9,5.15c-4.12,4.38-7.34,9.49-11.3,14s-8.83,8.51-14.83,9.72a16.8,16.8,0,0,1-9.38-.91c-2.69-1-5.23-2.44-8-3.26-5.82-1.75-12.24-1.31-18.17-.38-.31.05-.18.53.14.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M385,293.07c-1.73-.39-3.53-.33-5.25-.7a84.25,84.25,0,0,1-8.23-2.56c-5.49-1.83-11-3.72-16.8-4.36a28.63,28.63,0,0,0-15,1.94c-4.13,1.87-7.17,5.13-10,8.58-3.23,4-6.42,8.16-11.3,10.16-.29.12-.17.61.13.49,8.2-3.36,11.42-12.41,18.51-17.19,9.77-6.59,22.27-3.13,32.49.27,3.12,1,6.23,2.12,9.4,3,1.92.52,3.94.43,5.87.86.31.07.45-.41.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M309.73,320.68c6.66-6.88,13.71-13.43,20.77-19.9a53.38,53.38,0,0,1,5.35-4.37c1.82-1.25,3.81-2.16,5.72-3.26a31.52,31.52,0,0,1,5.95-3c1.28-.43,2.56-.87,3.86-1.24a36.1,36.1,0,0,1,12.21-1.06c.32,0,.32-.48,0-.5-6.93-.41-14.19.66-20.29,4.17-3.84,2.22-7.69,4.18-11.09,7.06-3.56,3-6.86,6.37-10.27,9.55-4.27,4-8.51,8-12.56,12.18-.23.23.13.58.35.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M308.16,321.89c17.38-5.71,27.7-22.8,44.6-29.43a47.55,47.55,0,0,1,18-3.26.25.25,0,0,0,0-.5,48.94,48.94,0,0,0-28.24,8.72c-7.41,5-13.72,11.47-20.94,16.74A48.72,48.72,0,0,1,308,321.4c-.3.1-.17.59.14.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M309.68,322c9.16-2.05,17.63-6.19,25.56-11.12,4-2.5,7.94-5.2,11.8-8s7.9-5.94,12.14-8.44c5-3,10.91-5,16.77-3.61.31.07.45-.41.13-.48-9.92-2.3-18.56,4.35-26.1,9.8-7.89,5.71-15.9,11.37-24.66,15.67a71.55,71.55,0,0,1-15.77,5.66c-.31.07-.18.55.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M314.38,321.2a67.84,67.84,0,0,0,28.24-9.32c8.6-5.17,15.34-13,24.21-17.74a15.28,15.28,0,0,1,7.23-1.95,23,23,0,0,1,7.15.89c.31.1.44-.39.14-.48a21.23,21.23,0,0,0-11.56-.34,24.53,24.53,0,0,0-5.92,3,69.39,69.39,0,0,0-5.84,4.23c-4.08,3.24-7.94,6.76-12.23,9.72a66.63,66.63,0,0,1-14,7.35,67.45,67.45,0,0,1-17.44,4.11c-.32,0-.32.54,0,.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M382.19,294.85a36.14,36.14,0,0,0-25,9.11c-3.69,3.22-7,6.87-11.05,9.65a43.4,43.4,0,0,1-12.53,5.88,48.25,48.25,0,0,1-15.81,1.8c-.32,0-.32.48,0,.5a47.2,47.2,0,0,0,25.82-6c8.24-4.74,13.7-13,22.37-17.11a35.35,35.35,0,0,1,16.19-3.33c.32,0,.32-.49,0-.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M319.8,321.45c8.76,2,18.3,4.05,26.94.44,8.09-3.39,11.91-11.71,17.74-17.72a33.53,33.53,0,0,1,12.38-8.39c.3-.11.17-.59-.13-.48-7.63,2.78-13.23,8.67-18,15-2.68,3.53-5.4,7.22-9.22,9.61a25.14,25.14,0,0,1-12.84,3.46c-5.66.11-11.23-1.16-16.72-2.4-.31-.08-.45.41-.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M131,372.34a43.37,43.37,0,0,0-15.46,11.49c-4.23,5-6.57,11.78-12.14,15.49-5.26,3.5-12,3.92-18.11,2.82-7.13-1.31-13.64-4.76-20.2-7.69-6.91-3.08-14.94-6.33-22.49-3.53l.25.41c9.24-9.14,14.8-22.37,26.75-28.57a35.21,35.21,0,0,1,20-3.34,46.7,46.7,0,0,1,19.9,7.35,58.66,58.66,0,0,0,10.66,5.76c3.61,1.34,7.5,2.08,11,.16.29-.16,0-.59-.25-.44-3.84,2.1-8.11.85-11.91-.7a67.77,67.77,0,0,1-10-5.68A46.58,46.58,0,0,0,90.4,359a36.39,36.39,0,0,0-19.08,2.41c-6,2.6-10.72,7.15-14.8,12.18-4.68,5.78-8.68,12.13-14,17.38-.17.17,0,.51.25.42,7.41-2.75,15.31.45,22.1,3.48,6.48,2.89,12.92,6.3,20,7.67,5.51,1.07,11.51.91,16.65-1.55a18.59,18.59,0,0,0,6.79-5.66c2-2.66,3.53-5.64,5.47-8.35a43,43,0,0,1,17.55-14.22c.29-.13,0-.56-.25-.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M126.24,373.83a46.64,46.64,0,0,0-13.46,8c-4.13,3.73-6.14,9.35-10.76,12.59S90.91,398.12,85.36,397c-6.11-1.18-11.77-3.9-17.62-5.94a44.81,44.81,0,0,0-9.81-2.41,26.34,26.34,0,0,0-5.4-.09c-.77.08-1.53.19-2.29.34l-.87.2c-.3.07-.59.15-.88.24-.41.27-.7.2-.86-.23.24.22.59-.13.36-.35-.74-.68-1.72,1.31.07,1.18a13.56,13.56,0,0,0,1.88-.46,19.56,19.56,0,0,1,3-.45,26.79,26.79,0,0,1,5.37.17c7.13.92,13.6,4.18,20.33,6.48,5.75,2,11.95,3.2,18,1.67a16.8,16.8,0,0,0,7.21-3.77c2.26-2,3.85-4.68,5.59-7.15,4.1-5.83,10.47-9.45,16.93-12.16.29-.12.16-.6-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M44.84,389.88c1.2-1.16,2.65-2,3.89-3.15A57.61,57.61,0,0,0,53.81,381c3.47-4.14,6.88-8.39,11-11.95a30.86,30.86,0,0,1,13.58-7.29c5.11-1.08,10,.17,14.78,1.85s9.75,3.5,15,2.65c.32-.05.19-.53-.13-.48-5.12.83-10-1-14.72-2.65-4.5-1.57-9.06-2.81-13.86-2.07-10.54,1.64-18,10.12-24.51,17.86-2,2.35-3.91,4.76-6,7-1.32,1.36-3,2.31-4.37,3.63-.23.22.12.58.35.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M123.15,373.12c-4.83-1.24-9.7-2.29-14.59-3.29s-9.63-2.07-14.46-2.94a44.72,44.72,0,0,0-6.91-.79c-2.39-.07-4.76.23-7.15.27a36.86,36.86,0,0,0-9.6,1.64,36.39,36.39,0,0,0-10.73,5.46c-.26.18,0,.62.25.43,5.61-3.93,12.26-6.8,19.2-7,2.21-.07,4.41-.25,6.61-.3a39.14,39.14,0,0,1,7.25.61c4.68.78,9.32,1.89,14,2.82,5.37,1.08,10.73,2.22,16,3.58a.25.25,0,0,0,.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M124.79,373.42c-9.56,2.27-19.29.55-28.83-.94-9.13-1.43-18.34-2.45-27.44-.21a48.08,48.08,0,0,0-14.19,6.07.25.25,0,0,0,.26.43,48.06,48.06,0,0,1,27.22-7.35c9.38.28,18.52,2.66,27.85,3.41a48.9,48.9,0,0,0,15.26-.93c.31-.07.18-.56-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M123.63,374.23c-9.57,3.29-19.71,4.08-29.77,3.89-5-.1-10-.43-15-.79-5.22-.39-10.5-1-15.71-.32a21.23,21.23,0,0,0-12.47,5.58A.25.25,0,0,0,51,383c7.32-7.26,18.49-5.81,27.83-5.12,9.72.71,19.54,1.29,29.26.26a71,71,0,0,0,15.66-3.38c.31-.11.18-.59-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M119.09,376.05a67.19,67.19,0,0,1-29.8,6.8,125.14,125.14,0,0,1-16.16-1.64c-5-.69-10.3-1.39-15.29-.51-3.94.69-7.92,3.58-10.51,6.53-.21.24.14.59.35.35,2.52-2.85,6.35-5.64,10.14-6.37,5.39-1,11.26-.08,16.62.68a108.9,108.9,0,0,0,15.07,1.46,66.67,66.67,0,0,0,14.22-1.48,68.17,68.17,0,0,0,15.61-5.38c.29-.14,0-.58-.25-.44Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M48,390a35.42,35.42,0,0,1,13.15-5.3,39.72,39.72,0,0,1,15,.48c4.59.92,9.13,2,13.84,2a44.35,44.35,0,0,0,13.14-1.94,48.46,48.46,0,0,0,13.51-6.51c.26-.18,0-.61-.26-.43a46.4,46.4,0,0,1-26.39,8.38c-5,0-9.77-1.26-14.63-2.19a39.05,39.05,0,0,0-13.55-.44,35.64,35.64,0,0,0-14.11,5.49c-.27.17,0,.61.25.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M114.5,379.18c-6.23,6.11-13,12.62-21.88,14.27-9.08,1.68-17.14-3.93-25.69-5.91a33.31,33.31,0,0,0-14.26-.43c-.31.07-.18.55.14.49a32.92,32.92,0,0,1,14.44.53c4.36,1.05,8.43,2.87,12.65,4.35A25.09,25.09,0,0,0,92.11,394a28.89,28.89,0,0,0,11.51-4.77,81.15,81.15,0,0,0,11.23-9.73.25.25,0,0,0-.35-.36Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M129.59,373.2a43,43,0,0,1,21.86,2.72c3.23,1.31,6.21,3.15,9.46,4.42a18.77,18.77,0,0,0,8.86,1.28c13.59-1.46,21.06-14.4,28-24.55,4.16-6.08,9.2-12.88,16.89-14.4a.25.25,0,0,0,0-.48c-13.67-3.18-26.32-12.47-40.92-9.94-12.53,2.16-22.84,11.55-28.48,22.62-1.91,3.75-3.56,7.63-6.07,11-2.39,3.25-5.35,6.48-9.65,6.69-.32,0-.33.52,0,.5,6.41-.32,10.59-7,13.24-12.09,1.64-3.17,3.05-6.46,4.94-9.51a44.88,44.88,0,0,1,6.11-7.77,38.73,38.73,0,0,1,16.65-10.22c6.87-2,13.84-1.24,20.58.88,7.92,2.49,15.35,6.39,23.47,8.28v-.48c-6,1.19-10.53,5.66-14.12,10.35-4.25,5.56-7.67,11.73-12.14,17.13-4.3,5.19-9.9,9.72-16.63,11.14a18.06,18.06,0,0,1-10.21-.8c-3.24-1.21-6.2-3-9.39-4.38a43.42,43.42,0,0,0-22.59-2.91c-.32,0-.18.53.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M134.53,372c6.21-1,13.15-1.45,19.15.8,3.24,1.21,6.27,3,9.68,3.74A17,17,0,0,0,172,376c12.84-4.1,17.85-18,27.39-26.28a27.21,27.21,0,0,1,9.45-5.28c.54-.18,1.54-.59,1.11-1.32a.61.61,0,0,0-1.12.07.25.25,0,0,0,.49.13c-.25.89-2.82,1.35-3.64,1.71a23.65,23.65,0,0,0-3.77,2.12,36.09,36.09,0,0,0-5.9,5.14c-4.12,4.39-7.34,9.5-11.3,14s-8.83,8.51-14.83,9.72a16.8,16.8,0,0,1-9.38-.91c-2.69-1-5.23-2.44-8-3.26-5.82-1.75-12.24-1.31-18.17-.38-.31,0-.18.53.14.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M212.24,342c-1.72-.39-3.53-.33-5.25-.71a81.55,81.55,0,0,1-8.23-2.55c-5.49-1.83-11-3.72-16.8-4.36a28.73,28.73,0,0,0-15,1.93c-4.13,1.88-7.17,5.14-10,8.59-3.23,4-6.42,8.16-11.3,10.16-.29.12-.17.61.13.48,8.2-3.35,11.42-12.4,18.52-17.18,9.76-6.59,22.26-3.13,32.48.27,3.12,1,6.23,2.12,9.4,3,1.92.53,3.94.44,5.87.87.31.07.45-.41.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M137,369.59c6.66-6.88,13.71-13.43,20.77-19.9a52,52,0,0,1,5.35-4.37c1.82-1.26,3.81-2.16,5.72-3.27a32.09,32.09,0,0,1,5.95-3c1.28-.43,2.56-.87,3.86-1.25a36.1,36.1,0,0,1,12.21-1.05c.32,0,.32-.48,0-.5-6.93-.41-14.19.66-20.29,4.17-3.84,2.22-7.69,4.18-11.09,7.06-3.56,3-6.86,6.37-10.27,9.55-4.27,4-8.51,8-12.56,12.18-.23.23.13.58.35.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M135.43,370.79c17.38-5.71,27.7-22.79,44.61-29.43a47.45,47.45,0,0,1,18-3.25.25.25,0,0,0,0-.5,48.94,48.94,0,0,0-28.24,8.72c-7.41,5-13.72,11.47-20.94,16.74a48.8,48.8,0,0,1-13.51,7.24c-.31.1-.18.58.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M137,370.92c9.16-2.05,17.63-6.19,25.56-11.12,4-2.5,7.94-5.2,11.8-8s7.9-5.94,12.14-8.44c5-3,10.91-5,16.77-3.61.31.07.45-.41.13-.48-9.92-2.3-18.55,4.35-26.1,9.8-7.89,5.71-15.9,11.37-24.66,15.67a70.84,70.84,0,0,1-15.77,5.65c-.31.07-.18.56.13.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M141.65,370.11a67.84,67.84,0,0,0,28.24-9.32c8.6-5.18,15.34-13,24.21-17.74a15.28,15.28,0,0,1,7.23-1.95,23,23,0,0,1,7.15.89c.31.1.44-.39.14-.48a21.2,21.2,0,0,0-11.55-.34,24.07,24.07,0,0,0-5.93,3,67.21,67.21,0,0,0-5.84,4.23c-4.08,3.24-7.94,6.76-12.23,9.72a66.91,66.91,0,0,1-31.42,11.46c-.32,0-.32.53,0,.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M209.46,343.75a36.19,36.19,0,0,0-25,9.12c-3.69,3.22-7,6.86-11.05,9.65a43.28,43.28,0,0,1-12.53,5.88,48.25,48.25,0,0,1-15.81,1.8c-.32,0-.32.48,0,.5a47.25,47.25,0,0,0,25.83-6c8.23-4.74,13.69-13,22.36-17.11a35.49,35.49,0,0,1,16.19-3.34c.32,0,.32-.48,0-.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M147.07,370.36c8.76,2,18.3,4.05,26.94.44,8.09-3.39,11.91-11.71,17.75-17.72a33.41,33.41,0,0,1,12.37-8.39c.3-.11.17-.59-.13-.48-7.63,2.77-13.23,8.67-18,15-2.68,3.54-5.4,7.23-9.22,9.62a25.14,25.14,0,0,1-12.84,3.46c-5.66.11-11.23-1.16-16.72-2.41-.31-.07-.45.42-.13.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M174.73,386.1a43.34,43.34,0,0,0,15.46-11.48c4.22-5,6.57-11.79,12.14-15.49,5.26-3.5,12-3.93,18.1-2.82,7.13,1.3,13.64,4.76,20.2,7.68,6.91,3.09,15,6.33,22.49,3.54l-.24-.42c-9.24,9.14-14.8,22.37-26.76,28.57a35,35,0,0,1-20,3.34,46.81,46.81,0,0,1-19.9-7.34,57.82,57.82,0,0,0-10.67-5.76c-3.6-1.35-7.49-2.09-11-.16-.28.15,0,.58.25.43,3.85-2.1,8.11-.85,11.91.7a68.39,68.39,0,0,1,10,5.68,46.53,46.53,0,0,0,18.62,6.86,36.48,36.48,0,0,0,19.08-2.4c6-2.6,10.73-7.16,14.8-12.18,4.68-5.79,8.68-12.13,14-17.39.17-.16,0-.51-.24-.42-7.41,2.75-15.31-.45-22.11-3.48-6.48-2.89-12.91-6.3-19.94-7.67-5.51-1.06-11.52-.91-16.65,1.55a18.64,18.64,0,0,0-6.8,5.66c-2,2.66-3.53,5.64-5.46,8.35a42.79,42.79,0,0,1-17.55,14.22c-.3.13,0,.56.25.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M179.53,384.61a46.59,46.59,0,0,0,13.45-8c4.14-3.73,6.15-9.35,10.77-12.59,4.77-3.34,11.1-3.66,16.66-2.59,6.11,1.19,11.77,3.9,17.61,6a45.5,45.5,0,0,0,9.81,2.4,25.66,25.66,0,0,0,5.4.09,21.78,21.78,0,0,0,2.29-.34c.3-.06.59-.12.88-.2l.87-.24c.42-.27.71-.19.86.23-.23-.22-.59.13-.35.35.74.69,1.71-1.31-.08-1.18a13.46,13.46,0,0,0-1.87.47,22.29,22.29,0,0,1-3,.45,26.79,26.79,0,0,1-5.37-.18c-7.13-.92-13.61-4.18-20.34-6.47-5.74-2-11.94-3.21-18-1.68a16.91,16.91,0,0,0-7.21,3.77c-2.26,2.05-3.85,4.68-5.59,7.15-4.11,5.83-10.47,9.45-16.93,12.16-.3.12-.17.6.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M260.92,368.56c-1.19,1.16-2.65,2-3.88,3.15a57.61,57.61,0,0,0-5.08,5.73c-3.48,4.14-6.88,8.39-11,11.95a30.86,30.86,0,0,1-13.58,7.29c-5.11,1.08-10-.17-14.78-1.85s-9.76-3.5-15-2.65c-.31.05-.18.54.13.48,5.13-.83,10,1,14.73,2.65,4.5,1.57,9.05,2.81,13.85,2.07,10.55-1.64,18-10.11,24.51-17.86,2-2.35,3.91-4.76,6-7,1.32-1.37,3-2.31,4.38-3.64.23-.22-.13-.57-.36-.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M182.61,385.32c4.83,1.24,9.71,2.29,14.6,3.29s9.62,2.07,14.46,2.95a45.82,45.82,0,0,0,6.91.78c2.38.07,4.76-.23,7.15-.27a37,37,0,0,0,9.6-1.64A36.21,36.21,0,0,0,246.05,385c.27-.18,0-.61-.25-.43-5.6,3.93-12.25,6.81-19.2,7-2.2.07-4.4.25-6.6.3a39.32,39.32,0,0,1-7.26-.61c-4.67-.78-9.31-1.88-14-2.82-5.37-1.08-10.73-2.22-16-3.58a.25.25,0,0,0-.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M181,385c9.56-2.27,19.29-.55,28.82.94,9.14,1.43,18.35,2.45,27.45.21a48,48,0,0,0,14.18-6.06c.27-.18,0-.61-.25-.44A48,48,0,0,1,224,387c-9.37-.28-18.51-2.66-27.84-3.41a48.88,48.88,0,0,0-15.26.93.25.25,0,0,0,.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M182.14,384.22c9.56-3.3,19.71-4.09,29.76-3.89,5,.09,10,.42,15,.79,5.21.38,10.49,1,15.71.32a21.33,21.33,0,0,0,12.47-5.59c.23-.23-.13-.58-.36-.35-7.32,7.25-18.48,5.8-27.82,5.12-9.73-.72-19.54-1.3-29.27-.27A71.61,71.61,0,0,0,182,383.73c-.3.11-.17.59.14.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M186.68,382.39a67.16,67.16,0,0,1,29.8-6.8,125.34,125.34,0,0,1,16.16,1.65c5,.68,10.29,1.38,15.29.5,3.94-.69,7.91-3.58,10.51-6.53.21-.24-.14-.59-.36-.35-2.51,2.85-6.34,5.65-10.14,6.37-5.38,1-11.26.08-16.61-.68a110.46,110.46,0,0,0-15.07-1.46A67.35,67.35,0,0,0,202,376.57,68.05,68.05,0,0,0,186.42,382c-.29.14,0,.57.26.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M257.73,368.42a35.32,35.32,0,0,1-13.15,5.3,39.71,39.71,0,0,1-15.05-.48c-4.59-.92-9.13-2-13.84-2a44.74,44.74,0,0,0-13.15,1.94,48.53,48.53,0,0,0-13.5,6.51c-.26.18,0,.61.25.43a46.56,46.56,0,0,1,26.4-8.38c5,0,9.76,1.27,14.63,2.19a39.3,39.3,0,0,0,13.55.45A35.69,35.69,0,0,0,258,368.86c.27-.18,0-.61-.25-.44Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M191.27,379.26c6.22-6.1,13-12.61,21.88-14.26,9.07-1.68,17.14,3.93,25.68,5.91a33.47,33.47,0,0,0,14.26.42c.32-.07.18-.55-.13-.48a33.21,33.21,0,0,1-14.44-.53c-4.37-1.06-8.43-2.88-12.65-4.36a25,25,0,0,0-12.21-1.55,28.67,28.67,0,0,0-11.51,4.76,81.32,81.32,0,0,0-11.24,9.73c-.23.23.13.58.36.36Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M176.17,385.24a42.92,42.92,0,0,1-21.85-2.71c-3.23-1.32-6.21-3.16-9.46-4.43a18.64,18.64,0,0,0-8.86-1.27c-13.6,1.45-21.07,14.39-28,24.54-4.17,6.08-9.21,12.88-16.89,14.4a.25.25,0,0,0,0,.48c13.67,3.19,26.31,12.47,40.92,9.94,12.52-2.16,22.83-11.55,28.48-22.62,1.91-3.75,3.55-7.63,6.06-11s5.36-6.49,9.66-6.7c.32,0,.32-.51,0-.5-6.41.32-10.59,7-13.24,12.09-1.65,3.17-3,6.47-4.94,9.51a44.88,44.88,0,0,1-6.11,7.77,38.69,38.69,0,0,1-16.65,10.22c-6.87,2-13.84,1.24-20.58-.88-7.92-2.49-15.35-6.39-23.47-8.28v.48c6-1.18,10.52-5.66,14.11-10.35,4.26-5.56,7.67-11.73,12.15-17.13,4.3-5.19,9.9-9.72,16.63-11.14a18.16,18.16,0,0,1,10.21.8c3.24,1.21,6.2,3.05,9.38,4.38a43.36,43.36,0,0,0,22.6,2.91c.31,0,.18-.52-.14-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M171.24,386.49c-6.21,1-13.15,1.45-19.15-.8-3.24-1.21-6.27-3-9.68-3.74a16.84,16.84,0,0,0-8.68.51c-12.85,4.09-17.85,18-27.39,26.27A27,27,0,0,1,96.88,414c-.53.18-1.54.59-1.1,1.33a.6.6,0,0,0,1.11-.08.25.25,0,0,0-.48-.13c.25-.89,2.82-1.35,3.64-1.71a24,24,0,0,0,3.77-2.11,36.63,36.63,0,0,0,5.9-5.15c4.11-4.38,7.34-9.5,11.29-14s8.84-8.52,14.84-9.73a17,17,0,0,1,9.38.91c2.69,1,5.22,2.44,8,3.27,5.81,1.74,12.24,1.3,18.16.37.32,0,.18-.53-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M93.52,416.46c1.73.39,3.53.33,5.26.71a82.35,82.35,0,0,1,8.23,2.55c5.48,1.84,11,3.73,16.79,4.36a28.68,28.68,0,0,0,15-1.93c4.12-1.88,7.16-5.14,10-8.59,3.24-4,6.43-8.16,11.31-10.16.29-.12.16-.6-.14-.48-8.19,3.36-11.42,12.4-18.51,17.19-9.77,6.58-22.26,3.13-32.49-.27-3.12-1-6.23-2.13-9.4-3-1.91-.53-3.93-.44-5.86-.87-.32-.07-.45.41-.14.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M168.76,388.85c-6.65,6.88-13.71,13.43-20.76,19.9a49.86,49.86,0,0,1-5.36,4.37c-1.82,1.26-3.8,2.17-5.71,3.27a32.72,32.72,0,0,1-6,3c-1.29.43-2.56.87-3.86,1.25a35.85,35.85,0,0,1-12.21,1.05c-.32,0-.32.48,0,.5,6.93.41,14.19-.66,20.28-4.17,3.84-2.21,7.69-4.17,11.09-7.06,3.56-3,6.86-6.36,10.28-9.54,4.27-4,8.5-8,12.56-12.19a.25.25,0,0,0-.36-.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M170.34,387.65c-17.38,5.71-27.7,22.8-44.61,29.43a47.69,47.69,0,0,1-18,3.26c-.32,0-.32.49,0,.5A49,49,0,0,0,136,412.11c7.4-5,13.71-11.46,20.94-16.74a49.07,49.07,0,0,1,13.51-7.24c.31-.1.17-.58-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M168.81,387.53c-9.15,2-17.63,6.18-25.56,11.11-4,2.5-7.94,5.21-11.8,8s-7.9,5.94-12.14,8.44c-5,3-10.9,5-16.76,3.61-.32-.07-.45.41-.14.49,9.93,2.3,18.56-4.35,26.1-9.81,7.89-5.7,15.9-11.37,24.67-15.67A70.76,70.76,0,0,1,168.94,388c.32-.07.19-.55-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M164.12,388.33a67.94,67.94,0,0,0-28.24,9.32c-8.61,5.18-15.34,13-24.21,17.75a15.43,15.43,0,0,1-7.24,1.94,22.76,22.76,0,0,1-7.15-.89.25.25,0,0,0-.13.48,21.1,21.1,0,0,0,11.55.34,23.9,23.9,0,0,0,5.93-3,67.21,67.21,0,0,0,5.84-4.23c4.07-3.24,7.93-6.76,12.23-9.72a66.91,66.91,0,0,1,31.42-11.46c.32,0,.32-.53,0-.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M96.3,414.69a36.16,36.16,0,0,0,25-9.12c3.69-3.22,7-6.86,11-9.65a43.12,43.12,0,0,1,12.53-5.87,48.28,48.28,0,0,1,15.82-1.81c.32,0,.32-.48,0-.5a47.23,47.23,0,0,0-25.83,6c-8.24,4.74-13.7,13-22.37,17.11a35.21,35.21,0,0,1-16.19,3.34c-.32,0-.32.49,0,.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M158.7,388.09c-8.76-2-18.3-4.06-26.94-.44-8.1,3.38-11.92,11.71-17.75,17.71a33.37,33.37,0,0,1-12.38,8.39c-.3.11-.17.59.13.48,7.64-2.77,13.23-8.66,18-15,2.68-3.54,5.39-7.23,9.22-9.62a25.1,25.1,0,0,1,12.84-3.45c5.66-.12,11.23,1.15,16.71,2.4.32.07.45-.41.14-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M347.46,337.2a43.48,43.48,0,0,0,15.46-11.49c4.22-5,6.57-11.79,12.14-15.49,5.26-3.5,12-3.93,18.1-2.82,7.13,1.3,13.64,4.76,20.2,7.68,6.91,3.09,14.95,6.33,22.49,3.54l-.24-.42c-9.24,9.14-14.8,22.37-26.76,28.57a35,35,0,0,1-20,3.34,46.81,46.81,0,0,1-19.9-7.34A58.14,58.14,0,0,0,358.31,337c-3.6-1.35-7.49-2.09-11-.16-.28.15,0,.58.25.43,3.85-2.1,8.11-.85,11.91.7a67.54,67.54,0,0,1,10,5.69,46.52,46.52,0,0,0,18.62,6.85,36.48,36.48,0,0,0,19.08-2.4c6-2.6,10.73-7.16,14.8-12.18,4.68-5.79,8.68-12.13,14-17.39.17-.16,0-.5-.24-.41-7.41,2.74-15.31-.46-22.11-3.49-6.48-2.89-12.91-6.3-19.95-7.66-5.5-1.07-11.51-.92-16.64,1.54a18.57,18.57,0,0,0-6.8,5.67c-2,2.65-3.53,5.63-5.46,8.34a42.75,42.75,0,0,1-17.56,14.22c-.29.13,0,.57.26.44Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M352.26,335.7a46.79,46.79,0,0,0,13.45-8c4.13-3.74,6.15-9.36,10.77-12.59s11.1-3.67,16.66-2.6c6.11,1.19,11.77,3.9,17.61,5.95a45.5,45.5,0,0,0,9.81,2.4,25.66,25.66,0,0,0,5.4.09,19.44,19.44,0,0,0,2.29-.34c.29-.06.59-.12.88-.2s.58-.15.87-.24c.42-.27.71-.19.86.23-.23-.22-.59.13-.35.35.74.69,1.71-1.31-.08-1.18a13.46,13.46,0,0,0-1.87.47,22.29,22.29,0,0,1-3,.45,26.79,26.79,0,0,1-5.37-.18c-7.13-.92-13.61-4.17-20.34-6.47-5.74-2-11.94-3.21-17.95-1.68a16.91,16.91,0,0,0-7.21,3.77c-2.26,2-3.86,4.68-5.59,7.15-4.11,5.83-10.47,9.45-16.93,12.16-.3.12-.17.61.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M433.65,319.65c-1.19,1.17-2.65,2-3.88,3.16a56.4,56.4,0,0,0-5.08,5.72c-3.48,4.14-6.88,8.39-11,12a30.76,30.76,0,0,1-13.58,7.29c-5.11,1.08-10-.17-14.78-1.85s-9.76-3.5-15-2.65c-.32.06-.18.54.13.49,5.13-.84,10,1,14.73,2.64,4.5,1.57,9,2.82,13.85,2.07,10.55-1.64,18-10.11,24.51-17.85,2-2.36,3.91-4.77,6-7,1.32-1.37,3-2.31,4.38-3.64.23-.22-.13-.57-.36-.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M355.34,336.42c4.83,1.23,9.71,2.29,14.6,3.28s9.62,2.07,14.46,2.95a47.16,47.16,0,0,0,6.91.79c2.38.06,4.76-.23,7.14-.28a37,37,0,0,0,9.61-1.64,36.21,36.21,0,0,0,10.72-5.46c.27-.18,0-.61-.25-.43-5.6,3.93-12.26,6.81-19.2,7-2.2.07-4.4.26-6.6.3a39.32,39.32,0,0,1-7.26-.61c-4.67-.78-9.31-1.88-14-2.82-5.37-1.08-10.73-2.22-16-3.58a.25.25,0,0,0-.13.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M353.71,336.12c9.56-2.28,19.29-.56,28.82.93,9.14,1.43,18.35,2.45,27.45.21a48,48,0,0,0,14.18-6.06c.27-.17,0-.61-.25-.43a48.1,48.1,0,0,1-27.23,7.34c-9.37-.28-18.51-2.66-27.84-3.41a48.86,48.86,0,0,0-15.26.93c-.32.08-.18.56.13.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M354.87,335.31c9.56-3.3,19.71-4.09,29.76-3.89,5,.09,10,.42,15,.79,5.21.38,10.49,1,15.71.32a21.33,21.33,0,0,0,12.47-5.59c.23-.23-.13-.58-.36-.35-7.32,7.25-18.48,5.81-27.82,5.12-9.73-.72-19.54-1.3-29.27-.27a71.05,71.05,0,0,0-15.66,3.39c-.3.1-.17.58.14.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M359.4,333.48a67.37,67.37,0,0,1,29.81-6.8,125.34,125.34,0,0,1,16.16,1.65c5,.68,10.29,1.38,15.29.5,3.94-.69,7.91-3.58,10.5-6.52.22-.24-.14-.6-.35-.36-2.51,2.86-6.34,5.65-10.14,6.38-5.38,1-11.26.07-16.61-.69A110.46,110.46,0,0,0,389,326.18a67.49,67.49,0,0,0-29.84,6.87c-.29.14,0,.57.25.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M430.46,319.51a35.16,35.16,0,0,1-13.15,5.3,39.71,39.71,0,0,1-15.05-.48c-4.6-.92-9.13-2-13.84-2a44.4,44.4,0,0,0-13.15,1.93,48.81,48.81,0,0,0-13.5,6.51c-.26.18,0,.61.25.43a46.47,46.47,0,0,1,26.4-8.37c5,0,9.76,1.26,14.63,2.18a39.3,39.3,0,0,0,13.55.45A35.69,35.69,0,0,0,430.71,320c.27-.18,0-.61-.25-.44Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M364,330.35c6.22-6.1,13-12.61,21.88-14.26C395,314.41,403,320,411.56,322a33.47,33.47,0,0,0,14.26.42c.32-.06.18-.55-.13-.48a33.26,33.26,0,0,1-14.45-.53c-4.36-1.06-8.42-2.88-12.64-4.36a25,25,0,0,0-12.22-1.55,28.69,28.69,0,0,0-11.5,4.76A81.39,81.39,0,0,0,363.64,330c-.23.22.13.57.36.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M348.9,336.33a42.92,42.92,0,0,1-21.85-2.71c-3.23-1.31-6.21-3.16-9.46-4.43a18.64,18.64,0,0,0-8.86-1.27c-13.6,1.46-21.07,14.39-28,24.54-4.17,6.08-9.21,12.88-16.89,14.4a.25.25,0,0,0,0,.48c13.67,3.19,26.31,12.47,40.91,10,12.53-2.17,22.84-11.56,28.49-22.63,1.91-3.75,3.55-7.63,6.06-11s5.36-6.48,9.66-6.7c.32,0,.32-.51,0-.5-6.41.32-10.59,7-13.24,12.09-1.65,3.17-3,6.47-4.94,9.51a44.13,44.13,0,0,1-6.11,7.77A38.69,38.69,0,0,1,308,376c-6.87,2-13.84,1.25-20.58-.87-7.92-2.5-15.35-6.4-23.47-8.29v.48c6-1.18,10.52-5.65,14.11-10.35,4.26-5.56,7.67-11.73,12.15-17.13,4.3-5.19,9.89-9.72,16.63-11.14a18.16,18.16,0,0,1,10.21.8c3.24,1.21,6.2,3.05,9.38,4.38a43.36,43.36,0,0,0,22.6,2.91c.31,0,.18-.52-.14-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M344,337.58c-6.21,1-13.15,1.45-19.15-.8-3.24-1.21-6.27-3-9.68-3.73a16.83,16.83,0,0,0-8.68.5c-12.85,4.09-17.85,18-27.39,26.27a27,27,0,0,1-9.46,5.28c-.53.18-1.54.59-1.1,1.33a.6.6,0,0,0,1.11-.08.25.25,0,0,0-.48-.13c.25-.89,2.82-1.35,3.64-1.71a24,24,0,0,0,3.77-2.11,36.15,36.15,0,0,0,5.9-5.15c4.11-4.38,7.34-9.49,11.29-14s8.84-8.51,14.84-9.73a17,17,0,0,1,9.38.91c2.69,1,5.22,2.44,8,3.27,5.81,1.74,12.24,1.3,18.16.38.32-.05.18-.54-.13-.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M266.25,367.55c1.73.39,3.53.33,5.26.71a79.88,79.88,0,0,1,8.23,2.56c5.48,1.83,11,3.72,16.79,4.35a28.63,28.63,0,0,0,15-1.93c4.13-1.87,7.17-5.13,10-8.59,3.24-4,6.43-8.16,11.31-10.16.29-.12.16-.6-.14-.48-8.2,3.36-11.42,12.4-18.51,17.19-9.77,6.58-22.26,3.13-32.49-.27-3.12-1-6.23-2.12-9.4-3-1.92-.52-3.93-.44-5.86-.87-.32-.07-.45.41-.14.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M341.49,339.94c-6.65,6.88-13.71,13.43-20.77,19.9a48.78,48.78,0,0,1-5.35,4.37c-1.82,1.26-3.8,2.17-5.71,3.27a32.72,32.72,0,0,1-6,3c-1.29.43-2.56.88-3.86,1.25a35.89,35.89,0,0,1-12.21,1.05c-.32,0-.32.48,0,.5,6.93.42,14.19-.65,20.28-4.17,3.84-2.21,7.69-4.17,11.09-7.06,3.56-3,6.86-6.36,10.28-9.54,4.27-4,8.5-8,12.56-12.18.22-.24-.13-.59-.36-.36Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M343.07,338.74c-17.38,5.71-27.7,22.8-44.61,29.43a47.73,47.73,0,0,1-17.95,3.26c-.32,0-.32.49,0,.5a49.06,49.06,0,0,0,28.24-8.72c7.4-5,13.71-11.47,20.93-16.75a49.27,49.27,0,0,1,13.52-7.24c.3-.1.17-.58-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M341.54,338.62c-9.15,2-17.63,6.18-25.56,11.11-4,2.51-7.94,5.21-11.8,8s-7.9,5.94-12.14,8.45c-5,3-10.9,5-16.76,3.6-.32-.07-.45.41-.14.49,9.93,2.3,18.56-4.35,26.1-9.81,7.89-5.7,15.9-11.37,24.67-15.67a71.26,71.26,0,0,1,15.76-5.65c.32-.07.19-.55-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M336.85,339.42a67.89,67.89,0,0,0-28.24,9.32c-8.61,5.18-15.34,13-24.21,17.75a15.43,15.43,0,0,1-7.24,1.94,22.76,22.76,0,0,1-7.15-.89c-.31-.09-.44.39-.13.49a21.1,21.1,0,0,0,11.55.33,24.07,24.07,0,0,0,5.93-3,67.21,67.21,0,0,0,5.84-4.23c4.07-3.24,7.93-6.76,12.23-9.72a66.91,66.91,0,0,1,31.42-11.46c.31,0,.32-.53,0-.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M269,365.78a36.11,36.11,0,0,0,25-9.12c3.69-3.21,7-6.86,11.05-9.65a43.38,43.38,0,0,1,12.53-5.87,48,48,0,0,1,15.81-1.8.25.25,0,0,0,0-.5,47.19,47.19,0,0,0-25.82,6c-8.24,4.75-13.7,13-22.37,17.11A35.21,35.21,0,0,1,269,365.28c-.32,0-.32.49,0,.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M331.43,339.18c-8.76-2-18.3-4.06-26.94-.44-8.1,3.39-11.92,11.71-17.75,17.71a33.33,33.33,0,0,1-12.38,8.39c-.3.11-.17.6.13.48,7.64-2.77,13.23-8.66,18-15,2.68-3.54,5.39-7.23,9.22-9.62a25.1,25.1,0,0,1,12.84-3.45c5.66-.12,11.23,1.15,16.71,2.4.32.07.45-.41.14-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M190.89,447.48A43.37,43.37,0,0,0,206.35,436c4.22-5,6.57-11.78,12.14-15.49,5.26-3.5,12-3.92,18.1-2.82,7.13,1.31,13.64,4.76,20.2,7.69,6.91,3.08,14.95,6.33,22.49,3.53l-.24-.41c-9.24,9.14-14.8,22.37-26.76,28.57a35.16,35.16,0,0,1-20,3.34,46.83,46.83,0,0,1-19.9-7.35,58.75,58.75,0,0,0-10.67-5.76c-3.6-1.34-7.49-2.08-11-.16a.25.25,0,0,0,.25.44c3.85-2.1,8.11-.85,11.91.7a68.39,68.39,0,0,1,10,5.68,46.53,46.53,0,0,0,18.62,6.86,36.39,36.39,0,0,0,19.08-2.41c6-2.6,10.73-7.15,14.8-12.18,4.68-5.78,8.68-12.13,14-17.38.17-.17,0-.51-.24-.42-7.42,2.75-15.31-.45-22.11-3.48-6.48-2.89-12.91-6.31-20-7.67-5.5-1.07-11.51-.91-16.64,1.55a18.54,18.54,0,0,0-6.8,5.66c-2,2.66-3.53,5.64-5.46,8.35a42.91,42.91,0,0,1-17.56,14.22c-.29.13,0,.56.26.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M195.69,446a46.82,46.82,0,0,0,13.45-8c4.13-3.74,6.15-9.35,10.77-12.59,4.77-3.34,11.1-3.67,16.66-2.59,6.11,1.18,11.77,3.9,17.61,5.94a45.53,45.53,0,0,0,9.81,2.41,25.66,25.66,0,0,0,5.4.08,21.77,21.77,0,0,0,2.29-.33l.88-.2.87-.24c.42-.27.71-.2.86.23-.23-.22-.59.13-.35.35.74.68,1.71-1.31-.08-1.18a13.43,13.43,0,0,0-1.87.46,19.56,19.56,0,0,1-3,.45,26.88,26.88,0,0,1-5.38-.17c-7.12-.92-13.6-4.18-20.33-6.48-5.74-2-11.94-3.2-18-1.67a16.7,16.7,0,0,0-7.21,3.77c-2.26,2-3.86,4.68-5.59,7.15-4.11,5.83-10.47,9.45-16.94,12.15-.29.13-.16.61.14.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M277.08,429.94c-1.19,1.16-2.65,2-3.88,3.15a59,59,0,0,0-5.09,5.73c-3.47,4.14-6.88,8.39-11,11.95a30.86,30.86,0,0,1-13.58,7.29c-5.11,1.08-10-.17-14.78-1.86s-9.76-3.49-15-2.64c-.32.05-.18.53.13.48,5.13-.83,10,1,14.73,2.65,4.5,1.57,9.05,2.81,13.85,2.07,10.55-1.64,18-10.12,24.51-17.86,2-2.35,3.91-4.76,6-7,1.32-1.36,3-2.31,4.38-3.63.23-.22-.13-.58-.36-.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M198.77,446.7c4.83,1.24,9.71,2.29,14.6,3.29s9.62,2.07,14.46,2.94a44.58,44.58,0,0,0,6.91.79c2.38.07,4.76-.23,7.14-.27a36.86,36.86,0,0,0,9.6-1.64,36.14,36.14,0,0,0,10.73-5.46c.27-.18,0-.62-.25-.43-5.6,3.93-12.26,6.8-19.2,7-2.2.07-4.4.25-6.6.3a40.21,40.21,0,0,1-7.26-.61c-4.67-.79-9.31-1.89-14-2.82-5.37-1.09-10.73-2.23-16-3.58a.25.25,0,0,0-.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M197.14,446.4c9.56-2.27,19.29-.55,28.82.94,9.14,1.43,18.35,2.45,27.45.21a48,48,0,0,0,14.18-6.07c.27-.17,0-.6-.25-.43a48.11,48.11,0,0,1-27.23,7.35c-9.37-.28-18.51-2.66-27.84-3.41a48.86,48.86,0,0,0-15.26.93c-.32.07-.18.56.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M198.3,445.59c9.56-3.29,19.71-4.08,29.76-3.89,5,.1,10,.43,15,.79,5.21.39,10.49,1,15.71.32a21.26,21.26,0,0,0,12.47-5.58c.23-.23-.13-.58-.36-.36-7.32,7.26-18.48,5.81-27.82,5.12-9.73-.71-19.54-1.29-29.27-.26a71,71,0,0,0-15.66,3.38c-.3.11-.17.59.14.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M202.83,443.77a67.23,67.23,0,0,1,29.81-6.8,125.32,125.32,0,0,1,16.16,1.64c5,.68,10.29,1.39,15.29.51,3.94-.69,7.91-3.58,10.5-6.53.22-.24-.14-.59-.35-.35-2.51,2.85-6.34,5.64-10.14,6.37-5.39,1-11.26.08-16.61-.68a108.9,108.9,0,0,0-15.07-1.46A66.7,66.7,0,0,0,218.19,438a68,68,0,0,0-15.61,5.38c-.29.14,0,.58.25.44Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M273.89,429.8a35.49,35.49,0,0,1-13.15,5.3,39.71,39.71,0,0,1-15.05-.48c-4.6-.92-9.13-2-13.84-2a44.41,44.41,0,0,0-13.15,1.94A48.53,48.53,0,0,0,205.2,441c-.26.18,0,.61.25.43a46.41,46.41,0,0,1,26.4-8.38c5,0,9.76,1.26,14.62,2.19a39.12,39.12,0,0,0,13.56.44,35.69,35.69,0,0,0,14.11-5.49c.27-.17,0-.61-.25-.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M207.43,440.63c6.22-6.1,13-12.61,21.88-14.26,9.07-1.68,17.14,3.93,25.68,5.91a33.32,33.32,0,0,0,14.26.43c.32-.07.18-.55-.13-.49a33,33,0,0,1-14.45-.53c-4.36-1.05-8.43-2.87-12.64-4.35a25.15,25.15,0,0,0-12.22-1.56,28.85,28.85,0,0,0-11.5,4.77,80.65,80.65,0,0,0-11.24,9.73.25.25,0,0,0,.36.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M192.33,446.62a42.92,42.92,0,0,1-21.85-2.72c-3.23-1.31-6.21-3.15-9.46-4.42a18.77,18.77,0,0,0-8.86-1.28c-13.6,1.46-21.07,14.4-28,24.55-4.17,6.08-9.21,12.88-16.89,14.4a.25.25,0,0,0,0,.48c13.67,3.18,26.31,12.47,40.91,9.94C160.7,485.4,171,476,176.66,465c1.91-3.75,3.55-7.63,6.06-11,2.39-3.25,5.36-6.48,9.66-6.69.32,0,.32-.52,0-.5-6.41.31-10.59,7-13.24,12.09-1.65,3.17-3,6.46-4.94,9.51a44.88,44.88,0,0,1-6.11,7.77,38.79,38.79,0,0,1-16.65,10.22c-6.87,2-13.84,1.24-20.58-.88-7.92-2.49-15.35-6.39-23.47-8.28v.48c6-1.19,10.52-5.66,14.11-10.35,4.26-5.56,7.67-11.73,12.15-17.13,4.3-5.19,9.89-9.72,16.63-11.14a18.07,18.07,0,0,1,10.21.8c3.24,1.21,6.2,3,9.38,4.38a43.46,43.46,0,0,0,22.6,2.91c.31,0,.18-.53-.14-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M187.4,447.87c-6.21,1-13.16,1.45-19.15-.8-3.24-1.21-6.27-3-9.68-3.74a16.94,16.94,0,0,0-8.68.5c-12.85,4.1-17.85,18-27.39,26.27a27,27,0,0,1-9.46,5.29c-.53.18-1.54.59-1.1,1.32a.6.6,0,0,0,1.11-.07.25.25,0,0,0-.48-.14c.25-.88,2.82-1.34,3.64-1.7a24.1,24.1,0,0,0,3.77-2.12,35.68,35.68,0,0,0,5.9-5.15c4.11-4.38,7.34-9.49,11.29-14S146,445,152,443.8a16.81,16.81,0,0,1,9.38.91c2.69,1,5.22,2.44,8,3.26,5.81,1.75,12.24,1.31,18.16.38.32-.05.18-.53-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M109.68,477.84c1.73.39,3.53.33,5.26.71a82.35,82.35,0,0,1,8.23,2.55c5.48,1.83,11,3.72,16.79,4.36a28.63,28.63,0,0,0,15-1.94c4.13-1.87,7.17-5.13,10-8.58,3.24-4,6.42-8.16,11.31-10.16.29-.12.16-.61-.14-.49-8.2,3.36-11.42,12.41-18.51,17.19-9.77,6.59-22.26,3.13-32.49-.27-3.12-1-6.23-2.12-9.4-3-1.92-.53-3.93-.44-5.86-.87-.32-.07-.45.41-.14.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M184.92,450.23c-6.65,6.88-13.71,13.43-20.77,19.9a50,50,0,0,1-5.35,4.37c-1.82,1.25-3.8,2.16-5.71,3.26a32.13,32.13,0,0,1-6,3c-1.29.43-2.56.87-3.86,1.25A36.4,36.4,0,0,1,131.07,483c-.32,0-.32.48,0,.5,6.93.41,14.19-.66,20.28-4.17,3.84-2.22,7.69-4.18,11.09-7.06,3.56-3,6.86-6.37,10.28-9.55,4.27-4,8.5-8,12.56-12.18a.25.25,0,0,0-.36-.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M186.5,449c-17.39,5.71-27.7,22.79-44.61,29.43a47.52,47.52,0,0,1-17.95,3.25.25.25,0,0,0,0,.5,49,49,0,0,0,28.24-8.72c7.4-5,13.71-11.47,20.93-16.74a49,49,0,0,1,13.52-7.24c.3-.1.17-.59-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M185,448.9c-9.15,2.05-17.63,6.19-25.56,11.12-4,2.5-7.94,5.2-11.8,8s-7.9,5.94-12.14,8.44c-5,3-10.9,5-16.76,3.61-.32-.07-.45.41-.14.48,9.93,2.3,18.56-4.35,26.1-9.8,7.89-5.71,15.9-11.37,24.67-15.67a71.29,71.29,0,0,1,15.76-5.66c.32-.07.18-.55-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M180.28,449.71A67.75,67.75,0,0,0,152,459c-8.61,5.17-15.34,13-24.22,17.74a15.25,15.25,0,0,1-7.23,2,23.05,23.05,0,0,1-7.15-.89c-.31-.1-.44.39-.13.48a21.2,21.2,0,0,0,11.55.34,24.42,24.42,0,0,0,5.93-3,67.21,67.21,0,0,0,5.84-4.23c4.07-3.24,7.93-6.76,12.23-9.72a66.91,66.91,0,0,1,31.42-11.46c.31,0,.32-.54,0-.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M112.46,476.06a36.1,36.1,0,0,0,25-9.11c3.69-3.22,7-6.86,11.05-9.65A43.4,43.4,0,0,1,161,451.42a48.25,48.25,0,0,1,15.81-1.8c.33,0,.32-.48,0-.5a47.2,47.2,0,0,0-25.82,6c-8.24,4.74-13.7,13-22.37,17.11a35.33,35.33,0,0,1-16.19,3.33c-.32,0-.32.49,0,.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M174.86,449.46c-8.76-2-18.3-4-26.94-.44-8.1,3.39-11.92,11.71-17.75,17.72a33.45,33.45,0,0,1-12.38,8.39c-.3.11-.17.59.13.48,7.63-2.77,13.23-8.67,18-15,2.68-3.54,5.39-7.23,9.22-9.62A25.11,25.11,0,0,1,158,447.54c5.66-.11,11.23,1.16,16.71,2.41.32.07.45-.42.14-.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M363.62,398.57a43.45,43.45,0,0,0,15.46-11.49c4.22-4.95,6.57-11.78,12.14-15.48,5.25-3.5,12-3.93,18.1-2.82,7.13,1.3,13.64,4.75,20.2,7.68,6.91,3.09,15,6.33,22.49,3.53l-.24-.41C442.53,388.72,437,402,425,408.15a35.16,35.16,0,0,1-20,3.34,46.81,46.81,0,0,1-19.9-7.34,58.19,58.19,0,0,0-10.67-5.77c-3.6-1.34-7.49-2.08-11-.15-.28.15,0,.58.25.43,3.85-2.1,8.11-.85,11.91.7a68.39,68.39,0,0,1,10,5.68,46.53,46.53,0,0,0,18.62,6.86,36.39,36.39,0,0,0,19.08-2.41c6-2.6,10.73-7.15,14.8-12.18,4.68-5.78,8.68-12.12,14-17.38.17-.17,0-.51-.24-.42-7.42,2.75-15.31-.45-22.11-3.48-6.48-2.89-12.92-6.3-19.95-7.67-5.51-1.06-11.51-.91-16.64,1.55a18.54,18.54,0,0,0-6.8,5.66c-2,2.66-3.53,5.64-5.46,8.35a42.9,42.9,0,0,1-17.56,14.22c-.29.13,0,.56.26.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M368.42,397.08a46.59,46.59,0,0,0,13.45-8c4.13-3.73,6.15-9.35,10.77-12.59,4.77-3.34,11.1-3.66,16.66-2.59,6.11,1.18,11.77,3.9,17.61,5.95a45.5,45.5,0,0,0,9.81,2.4,25.66,25.66,0,0,0,5.4.09c.77-.08,1.53-.19,2.29-.34l.88-.2q.43-.11.87-.24c.42-.27.71-.19.86.23-.24-.22-.59.13-.35.35.74.68,1.71-1.31-.08-1.18a13.43,13.43,0,0,0-1.87.46,19.56,19.56,0,0,1-3,.45,26.14,26.14,0,0,1-5.38-.17c-7.12-.92-13.6-4.18-20.33-6.48-5.74-2-11.94-3.2-18-1.67a16.9,16.9,0,0,0-7.22,3.77c-2.25,2-3.85,4.68-5.58,7.15-4.11,5.83-10.47,9.45-16.94,12.16-.29.12-.16.6.14.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M449.81,381c-1.2,1.16-2.65,2-3.88,3.15a59,59,0,0,0-5.09,5.73c-3.47,4.14-6.88,8.39-11,11.95a30.86,30.86,0,0,1-13.58,7.29c-5.11,1.08-10-.17-14.78-1.85s-9.76-3.5-15-2.65c-.32.05-.18.53.13.48,5.13-.83,10,1,14.73,2.65,4.5,1.57,9.05,2.81,13.85,2.07,10.55-1.64,18-10.12,24.51-17.86,2-2.35,3.91-4.76,6-7,1.32-1.37,3-2.32,4.37-3.64.24-.22-.12-.58-.35-.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M371.5,397.79c4.83,1.24,9.71,2.29,14.6,3.29s9.62,2.07,14.46,2.94a44.58,44.58,0,0,0,6.91.79c2.38.07,4.76-.23,7.14-.27a36.86,36.86,0,0,0,9.6-1.64,36.26,36.26,0,0,0,10.73-5.46c.26-.18,0-.62-.25-.43-5.6,3.93-12.26,6.81-19.2,7-2.2.07-4.4.25-6.6.3a39.32,39.32,0,0,1-7.26-.61c-4.67-.78-9.31-1.88-14-2.82-5.37-1.08-10.73-2.22-16-3.58a.25.25,0,0,0-.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M369.87,397.49c9.56-2.27,19.29-.55,28.82.94,9.14,1.43,18.35,2.45,27.45.21a48,48,0,0,0,14.18-6.07c.27-.17,0-.6-.25-.43a48.11,48.11,0,0,1-27.23,7.35c-9.37-.28-18.51-2.66-27.84-3.41a48.86,48.86,0,0,0-15.26.93.25.25,0,0,0,.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M371,396.69c9.57-3.3,19.72-4.09,29.77-3.9,5,.1,10,.43,15,.8,5.21.38,10.49,1,15.71.31A21.26,21.26,0,0,0,444,388.32c.23-.23-.13-.58-.36-.36-7.32,7.26-18.48,5.81-27.82,5.13-9.73-.72-19.54-1.3-29.27-.27a71.61,71.61,0,0,0-15.66,3.38c-.3.11-.17.59.13.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M375.56,394.86a67.23,67.23,0,0,1,29.81-6.8,123.27,123.27,0,0,1,16.15,1.65c5,.68,10.3,1.38,15.3.5,3.93-.69,7.91-3.58,10.5-6.53.22-.24-.14-.59-.35-.35-2.51,2.85-6.34,5.64-10.14,6.37-5.39,1-11.26.08-16.61-.68a109,109,0,0,0-15.07-1.46A66.7,66.7,0,0,0,390.92,389a68.51,68.51,0,0,0-15.61,5.38c-.29.15,0,.58.25.44Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M446.61,380.89a35.21,35.21,0,0,1-13.14,5.3,39.71,39.71,0,0,1-15.05-.48c-4.6-.92-9.13-2-13.84-2a44.69,44.69,0,0,0-13.15,1.94,48.53,48.53,0,0,0-13.5,6.51c-.27.18,0,.61.25.43a46.41,46.41,0,0,1,26.4-8.38c5,0,9.76,1.27,14.62,2.19a39.07,39.07,0,0,0,13.55.44,35.68,35.68,0,0,0,14.12-5.49c.26-.17,0-.61-.26-.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M380.15,391.73c6.23-6.1,13-12.62,21.88-14.26,9.08-1.69,17.15,3.92,25.69,5.9a33.32,33.32,0,0,0,14.26.43c.32-.07.18-.55-.13-.48a33.26,33.26,0,0,1-14.45-.53c-4.36-1.06-8.43-2.88-12.64-4.36a25,25,0,0,0-12.22-1.55,28.69,28.69,0,0,0-11.5,4.76,81.32,81.32,0,0,0-11.24,9.73.25.25,0,0,0,.35.36Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M365.06,397.71A42.92,42.92,0,0,1,343.21,395c-3.23-1.31-6.22-3.15-9.46-4.42a18.77,18.77,0,0,0-8.86-1.28c-13.6,1.46-21.07,14.4-28,24.55-4.16,6.08-9.2,12.88-16.88,14.4a.25.25,0,0,0,0,.48c13.67,3.19,26.31,12.47,40.91,9.94,12.53-2.16,22.84-11.55,28.48-22.62,1.92-3.75,3.56-7.63,6.07-11s5.36-6.49,9.66-6.7c.32,0,.32-.52,0-.5-6.41.32-10.59,7-13.24,12.09-1.65,3.17-3.05,6.46-4.94,9.51a45.37,45.37,0,0,1-6.11,7.77,38.79,38.79,0,0,1-16.65,10.22c-6.87,2-13.84,1.24-20.58-.88-7.92-2.49-15.35-6.39-23.47-8.28v.48c6-1.19,10.52-5.66,14.11-10.35,4.26-5.56,7.67-11.73,12.15-17.13,4.29-5.19,9.89-9.72,16.63-11.14a18.16,18.16,0,0,1,10.21.8c3.23,1.21,6.2,3,9.38,4.38a43.46,43.46,0,0,0,22.6,2.91c.31,0,.18-.53-.14-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M360.13,399c-6.21,1-13.16,1.45-19.15-.8-3.24-1.21-6.27-3-9.68-3.74a16.84,16.84,0,0,0-8.68.51c-12.85,4.09-17.85,18-27.39,26.27a27,27,0,0,1-9.46,5.28c-.53.18-1.54.59-1.1,1.33a.6.6,0,0,0,1.11-.08.25.25,0,0,0-.48-.13c.25-.89,2.82-1.35,3.64-1.71a24.88,24.88,0,0,0,3.77-2.11,36.63,36.63,0,0,0,5.9-5.15c4.11-4.38,7.34-9.5,11.29-14s8.84-8.52,14.84-9.73a16.81,16.81,0,0,1,9.38.91c2.69,1,5.22,2.44,8,3.27,5.81,1.74,12.24,1.3,18.16.37.32-.05.18-.53-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M282.41,428.93c1.73.39,3.53.33,5.26.71a82.17,82.17,0,0,1,8.22,2.55c5.49,1.83,11,3.72,16.8,4.36a28.63,28.63,0,0,0,15-1.93c4.13-1.88,7.17-5.14,10-8.59,3.24-4,6.42-8.16,11.3-10.16.3-.12.17-.61-.13-.48-8.2,3.36-11.42,12.4-18.51,17.18-9.77,6.59-22.27,3.14-32.49-.26-3.12-1-6.23-2.13-9.4-3-1.92-.53-3.93-.44-5.86-.87-.32-.07-.45.41-.14.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M357.65,401.32c-6.65,6.88-13.71,13.43-20.77,19.9a49.75,49.75,0,0,1-5.35,4.37c-1.82,1.26-3.8,2.16-5.71,3.27a32.72,32.72,0,0,1-5.95,3c-1.29.43-2.57.87-3.86,1.25a35.89,35.89,0,0,1-12.21,1.05c-.32,0-.32.48,0,.5,6.92.41,14.19-.66,20.28-4.17,3.84-2.22,7.69-4.18,11.09-7.06,3.56-3,6.86-6.37,10.28-9.55,4.26-4,8.5-8,12.56-12.18a.25.25,0,0,0-.36-.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M359.23,400.12c-17.39,5.71-27.7,22.8-44.61,29.43a47.52,47.52,0,0,1-17.95,3.25.25.25,0,0,0,0,.5,49,49,0,0,0,28.24-8.72c7.4-5,13.71-11.47,20.93-16.74a49,49,0,0,1,13.52-7.24c.3-.1.17-.58-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M357.7,400c-9.15,2.05-17.63,6.19-25.56,11.12-4,2.5-7.94,5.2-11.8,8s-7.9,5.94-12.14,8.44c-5,3-10.9,5-16.77,3.61-.31-.07-.44.41-.13.48,9.93,2.3,18.56-4.35,26.1-9.8,7.89-5.71,15.9-11.37,24.67-15.67a70.76,70.76,0,0,1,15.76-5.65c.32-.07.18-.56-.13-.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M353,400.8a67.75,67.75,0,0,0-28.24,9.32c-8.61,5.18-15.34,13-24.22,17.74a15.25,15.25,0,0,1-7.23,1.95,23.05,23.05,0,0,1-7.15-.89.25.25,0,0,0-.13.48,21.1,21.1,0,0,0,11.55.34,24.42,24.42,0,0,0,5.93-3,67.21,67.21,0,0,0,5.84-4.23c4.07-3.24,7.93-6.76,12.23-9.72A66.8,66.8,0,0,1,353,401.3c.31,0,.32-.53,0-.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M285.19,427.16a36.16,36.16,0,0,0,25-9.12c3.69-3.22,7-6.86,11.05-9.65a43.12,43.12,0,0,1,12.53-5.87,48,48,0,0,1,15.81-1.81c.33,0,.32-.48,0-.5a47.2,47.2,0,0,0-25.82,6c-8.24,4.74-13.7,13-22.37,17.11a35.33,35.33,0,0,1-16.19,3.34c-.32,0-.32.49,0,.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M347.59,400.55c-8.77-2-18.3-4.05-26.94-.43-8.1,3.38-11.92,11.71-17.75,17.71a33.45,33.45,0,0,1-12.38,8.39c-.3.11-.17.59.13.48,7.63-2.77,13.23-8.67,18-15,2.68-3.54,5.39-7.23,9.21-9.62a25.17,25.17,0,0,1,12.85-3.46c5.66-.11,11.23,1.16,16.71,2.41.32.07.45-.41.14-.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M319.93,384.81a43.37,43.37,0,0,0-15.46,11.48c-4.23,5-6.58,11.79-12.14,15.49-5.26,3.5-12,3.93-18.11,2.82-7.13-1.3-13.64-4.76-20.2-7.68-6.91-3.09-14.94-6.33-22.49-3.54l.24.42c9.25-9.14,14.81-22.37,26.76-28.57a35.11,35.11,0,0,1,20-3.34,46.85,46.85,0,0,1,19.91,7.34A57.78,57.78,0,0,0,309.07,385c3.61,1.34,7.5,2.08,11,.15.29-.15,0-.58-.25-.43-3.85,2.1-8.11.85-11.91-.7a67.77,67.77,0,0,1-10-5.68,46.63,46.63,0,0,0-18.63-6.86,36.39,36.39,0,0,0-19.08,2.41c-6,2.6-10.73,7.15-14.8,12.18-4.68,5.78-8.68,12.12-14,17.38-.17.17,0,.51.24.42,7.42-2.75,15.32.45,22.11,3.48,6.48,2.89,12.92,6.3,20,7.67,5.51,1.06,11.51.91,16.64-1.55a18.54,18.54,0,0,0,6.8-5.66c2-2.66,3.53-5.64,5.47-8.35a42.72,42.72,0,0,1,17.55-14.22c.29-.13,0-.56-.25-.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M315.13,386.3a46.64,46.64,0,0,0-13.46,8c-4.13,3.73-6.14,9.35-10.76,12.59-4.78,3.34-11.11,3.66-16.66,2.59-6.12-1.18-11.78-3.9-17.62-5.95a45.36,45.36,0,0,0-9.81-2.4,25.66,25.66,0,0,0-5.4-.09c-.77.08-1.53.19-2.29.34l-.88.2q-.44.11-.87.24c-.42.27-.7.19-.86-.23.24.22.59-.13.36-.35-.74-.68-1.72,1.31.07,1.18a13.92,13.92,0,0,0,1.88-.46,19.38,19.38,0,0,1,3-.45,26.07,26.07,0,0,1,5.37.17c7.13.92,13.6,4.18,20.33,6.48,5.75,2,11.95,3.2,17.95,1.67a16.89,16.89,0,0,0,7.22-3.77c2.26-2,3.85-4.68,5.59-7.15,4.1-5.83,10.47-9.45,16.93-12.16.29-.12.16-.6-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M233.73,402.35c1.2-1.16,2.65-2,3.89-3.15a57.61,57.61,0,0,0,5.08-5.73c3.47-4.14,6.88-8.39,11-12a30.88,30.88,0,0,1,13.57-7.29c5.12-1.08,10,.17,14.78,1.85s9.76,3.5,15,2.65c.32-.05.18-.53-.13-.48-5.13.83-10-1-14.72-2.65-4.5-1.57-9.06-2.81-13.86-2.07-10.55,1.64-18,10.12-24.51,17.86-2,2.35-3.91,4.76-6,7-1.32,1.37-3,2.31-4.37,3.64-.23.22.12.58.35.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M312,385.59c-4.83-1.24-9.71-2.29-14.59-3.29s-9.63-2.07-14.47-2.95a45.69,45.69,0,0,0-6.9-.78c-2.39-.07-4.77.23-7.15.27a36.86,36.86,0,0,0-9.6,1.64,36.39,36.39,0,0,0-10.73,5.46c-.26.18,0,.62.25.43,5.61-3.93,12.26-6.81,19.2-7,2.2-.07,4.41-.25,6.61-.3a39.21,39.21,0,0,1,7.25.61c4.68.78,9.31,1.88,14,2.82,5.37,1.08,10.73,2.22,16,3.58a.25.25,0,0,0,.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M313.67,385.89c-9.55,2.27-19.29.55-28.82-.94-9.14-1.43-18.34-2.45-27.45-.21a48,48,0,0,0-14.18,6.06c-.27.18,0,.61.25.44a48,48,0,0,1,27.23-7.35c9.38.28,18.52,2.66,27.84,3.41a49,49,0,0,0,15.27-.93.25.25,0,0,0-.14-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M312.52,386.69c-9.57,3.3-19.71,4.09-29.77,3.89-5-.09-10-.42-15-.79-5.22-.38-10.5-1-15.71-.31a21.23,21.23,0,0,0-12.47,5.58.25.25,0,0,0,.35.36c7.32-7.26,18.49-5.81,27.83-5.13,9.72.72,19.53,1.3,29.26.27a71.61,71.61,0,0,0,15.66-3.38c.3-.11.17-.59-.13-.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M308,388.52a67.2,67.2,0,0,1-29.81,6.8A123.44,123.44,0,0,1,262,393.67c-5-.68-10.3-1.38-15.3-.5-3.93.69-7.91,3.58-10.5,6.53-.21.24.14.59.35.35,2.52-2.85,6.35-5.64,10.14-6.37,5.39-1,11.26-.08,16.62.68a110.31,110.31,0,0,0,15.07,1.46,66.67,66.67,0,0,0,14.22-1.48A67.89,67.89,0,0,0,308.23,389c.29-.14,0-.57-.25-.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M236.93,402.49a35.26,35.26,0,0,1,13.15-5.3,39.72,39.72,0,0,1,15,.48c4.59.92,9.12,2,13.83,2a44.69,44.69,0,0,0,13.15-1.94,48.4,48.4,0,0,0,13.5-6.51c.27-.18,0-.61-.25-.43A46.41,46.41,0,0,1,279,399.2c-5,0-9.76-1.27-14.62-2.19a39.31,39.31,0,0,0-13.55-.45,35.65,35.65,0,0,0-14.11,5.5c-.27.17,0,.61.25.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M303.39,391.65c-6.23,6.1-13,12.61-21.88,14.26-9.08,1.69-17.14-3.93-25.69-5.9a33.31,33.31,0,0,0-14.26-.43c-.31.07-.18.55.13.48a33.26,33.26,0,0,1,14.45.53c4.36,1.06,8.43,2.88,12.65,4.36A25,25,0,0,0,281,406.5a28.63,28.63,0,0,0,11.5-4.76A81.32,81.32,0,0,0,303.74,392a.25.25,0,0,0-.35-.36Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M318.48,385.67a42.92,42.92,0,0,1,21.85,2.72c3.23,1.31,6.22,3.15,9.46,4.42a18.79,18.79,0,0,0,8.87,1.28c13.59-1.46,21.06-14.4,28-24.55,4.16-6.08,9.2-12.88,16.89-14.4a.25.25,0,0,0,0-.48c-13.68-3.19-26.32-12.47-40.92-9.94-12.53,2.16-22.84,11.55-28.48,22.62-1.91,3.75-3.56,7.63-6.07,11s-5.35,6.49-9.66,6.7c-.32,0-.32.52,0,.5,6.41-.32,10.59-7,13.24-12.09,1.65-3.17,3.06-6.46,4.94-9.51a45.86,45.86,0,0,1,6.11-7.77A38.72,38.72,0,0,1,359.38,346c6.86-2,13.83-1.24,20.57.88,7.93,2.49,15.36,6.39,23.47,8.28v-.48c-6,1.19-10.52,5.66-14.11,10.35-4.26,5.56-7.67,11.73-12.14,17.13-4.3,5.19-9.9,9.72-16.63,11.14a18.15,18.15,0,0,1-10.21-.8c-3.24-1.21-6.2-3.05-9.39-4.38a43.33,43.33,0,0,0-22.59-2.91c-.32,0-.19.52.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M323.42,384.42c6.2-1,13.15-1.45,19.14.8,3.24,1.21,6.28,3,9.69,3.74a16.8,16.8,0,0,0,8.67-.51c12.85-4.09,17.86-18,27.39-26.27a27,27,0,0,1,9.46-5.28c.53-.18,1.54-.59,1.1-1.33a.6.6,0,0,0-1.11.08.25.25,0,0,0,.49.13c-.25.89-2.82,1.35-3.64,1.71a24.4,24.4,0,0,0-3.77,2.11,35.79,35.79,0,0,0-5.9,5.15c-4.12,4.38-7.34,9.5-11.3,14s-8.83,8.52-14.83,9.73a16.85,16.85,0,0,1-9.39-.91c-2.69-1-5.22-2.44-8-3.27-5.82-1.74-12.25-1.3-18.17-.37-.31.05-.18.53.14.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M401.13,354.45c-1.73-.39-3.53-.33-5.26-.71a82.17,82.17,0,0,1-8.22-2.55c-5.49-1.84-11-3.73-16.8-4.36a28.63,28.63,0,0,0-15,1.93c-4.13,1.88-7.17,5.14-10,8.59-3.23,4-6.42,8.16-11.3,10.16-.3.12-.17.61.13.48,8.2-3.36,11.42-12.4,18.51-17.18,9.77-6.59,22.27-3.14,32.49.26,3.12,1,6.23,2.13,9.4,3,1.92.53,3.94.44,5.87.87.31.07.44-.41.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M325.89,382.06c6.66-6.88,13.71-13.43,20.77-19.9a49.75,49.75,0,0,1,5.35-4.37c1.82-1.26,3.81-2.16,5.72-3.27a32.09,32.09,0,0,1,5.95-3c1.28-.43,2.56-.87,3.86-1.25a35.85,35.85,0,0,1,12.21-1.05c.32,0,.32-.48,0-.5-6.93-.41-14.19.66-20.29,4.17-3.84,2.21-7.69,4.17-11.09,7.06-3.56,3-6.86,6.36-10.27,9.54-4.27,4-8.51,8-12.56,12.19-.23.23.13.58.35.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M324.32,383.26c17.38-5.71,27.69-22.8,44.6-29.43a47.74,47.74,0,0,1,18-3.26c.32,0,.32-.49,0-.5a49,49,0,0,0-28.24,8.73c-7.41,5-13.72,11.46-20.94,16.74a49,49,0,0,1-13.52,7.24c-.3.1-.17.58.14.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M325.84,383.39c9.16-2.05,17.63-6.19,25.56-11.12,4-2.5,7.94-5.2,11.8-8s7.9-5.94,12.14-8.44c5-3,10.91-5,16.77-3.61.31.07.44-.41.13-.48-9.92-2.31-18.56,4.34-26.1,9.8-7.89,5.71-15.9,11.37-24.66,15.67a71,71,0,0,1-15.77,5.65c-.31.07-.18.56.13.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M330.54,382.58a67.84,67.84,0,0,0,28.24-9.32c8.6-5.18,15.34-13,24.21-17.75a15.36,15.36,0,0,1,7.23-1.94,23,23,0,0,1,7.15.89.25.25,0,0,0,.13-.48,21.1,21.1,0,0,0-11.55-.34,24.42,24.42,0,0,0-5.93,3,67.06,67.06,0,0,0-5.83,4.23c-4.08,3.24-7.94,6.76-12.24,9.72A66.78,66.78,0,0,1,348,378a67.65,67.65,0,0,1-17.44,4.11c-.32,0-.32.53,0,.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M398.35,356.22a36.19,36.19,0,0,0-25,9.12c-3.69,3.22-7,6.86-11.05,9.65a43.12,43.12,0,0,1-12.53,5.87A48.25,48.25,0,0,1,334,382.67c-.32,0-.32.48,0,.5a47.2,47.2,0,0,0,25.82-6c8.24-4.74,13.7-13,22.37-17.11a35.21,35.21,0,0,1,16.19-3.34c.32,0,.32-.49,0-.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M336,382.83c8.76,2,18.29,4.05,26.94.43,8.09-3.38,11.91-11.71,17.74-17.71A33.53,33.53,0,0,1,393,357.16c.3-.11.17-.59-.13-.48-7.63,2.77-13.23,8.66-18,15-2.69,3.54-5.4,7.23-9.22,9.62a25.24,25.24,0,0,1-12.84,3.46c-5.67.11-11.23-1.16-16.72-2.41-.31-.07-.45.41-.13.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M147.2,433.71a43.4,43.4,0,0,0-15.46,11.49c-4.23,5-6.58,11.79-12.14,15.49-5.26,3.5-12,3.93-18.11,2.82-7.13-1.3-13.64-4.76-20.2-7.68-6.91-3.09-14.94-6.33-22.49-3.54l.25.42c9.24-9.14,14.8-22.37,26.75-28.57a35,35,0,0,1,20-3.34,46.81,46.81,0,0,1,19.9,7.34,58.06,58.06,0,0,0,10.66,5.76c3.61,1.35,7.5,2.09,11,.16.29-.15,0-.58-.25-.43-3.85,2.1-8.11.85-11.91-.7a66.94,66.94,0,0,1-10-5.69,46.61,46.61,0,0,0-18.63-6.85,36.48,36.48,0,0,0-19.08,2.4c-6,2.6-10.73,7.16-14.8,12.18-4.68,5.79-8.68,12.13-14,17.39-.17.16,0,.51.24.42,7.42-2.75,15.32.45,22.11,3.48,6.48,2.89,12.92,6.3,19.95,7.66,5.51,1.07,11.51.92,16.65-1.54a18.69,18.69,0,0,0,6.79-5.66c2-2.66,3.53-5.64,5.47-8.35a42.72,42.72,0,0,1,17.55-14.22c.29-.13,0-.56-.25-.44Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M142.4,435.21a46.64,46.64,0,0,0-13.46,8c-4.13,3.73-6.14,9.35-10.76,12.59-4.78,3.34-11.11,3.66-16.66,2.59-6.12-1.19-11.77-3.9-17.62-5.95a45.36,45.36,0,0,0-9.81-2.4,25.65,25.65,0,0,0-5.4-.09,19.76,19.76,0,0,0-2.29.34c-.29.06-.58.12-.88.2l-.87.24c-.42.27-.7.19-.86-.23.24.22.59-.13.36-.35-.74-.69-1.72,1.31.07,1.18a14,14,0,0,0,1.88-.47,22.06,22.06,0,0,1,3-.45,26.79,26.79,0,0,1,5.37.18c7.13.92,13.6,4.17,20.33,6.47,5.75,2,12,3.21,18,1.68A16.91,16.91,0,0,0,120,455c2.26-2.05,3.85-4.68,5.59-7.15,4.1-5.83,10.47-9.45,16.93-12.16.29-.12.16-.6-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M61,451.26c1.2-1.16,2.65-2,3.89-3.16A56.4,56.4,0,0,0,70,442.38c3.47-4.14,6.88-8.39,11-11.95a30.86,30.86,0,0,1,13.58-7.29c5.11-1.08,10,.17,14.77,1.85s9.76,3.5,15,2.65c.32-.05.19-.54-.13-.48-5.13.83-10-1-14.72-2.65-4.5-1.57-9.06-2.81-13.86-2.07-10.54,1.64-18,10.11-24.51,17.86-2,2.35-3.91,4.76-6,7-1.32,1.37-3,2.31-4.37,3.64-.23.22.12.57.35.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M139.31,434.5c-4.83-1.24-9.7-2.3-14.59-3.29s-9.63-2.07-14.46-2.95a47.32,47.32,0,0,0-6.91-.79c-2.39-.06-4.77.24-7.15.28a36.86,36.86,0,0,0-9.6,1.64,36.39,36.39,0,0,0-10.73,5.46c-.26.18,0,.61.25.43,5.61-3.93,12.26-6.81,19.2-7,2.2-.07,4.41-.25,6.61-.3a39.21,39.21,0,0,1,7.25.61c4.68.78,9.31,1.88,14,2.82,5.37,1.08,10.73,2.22,16,3.58a.25.25,0,0,0,.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M140.94,434.8c-9.55,2.27-19.28.55-28.82-.94-9.14-1.43-18.34-2.45-27.44-.21a47.94,47.94,0,0,0-14.19,6.06c-.27.17,0,.61.25.44A48,48,0,0,1,98,432.8c9.38.28,18.52,2.66,27.84,3.41a49,49,0,0,0,15.27-.93.25.25,0,0,0-.14-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M139.79,435.6c-9.57,3.3-19.71,4.09-29.77,3.89-5-.09-10-.42-15-.79-5.22-.38-10.5-1-15.71-.32A21.3,21.3,0,0,0,66.82,444a.25.25,0,0,0,.35.35c7.32-7.25,18.49-5.8,27.83-5.12,9.72.72,19.53,1.3,29.26.27a71.05,71.05,0,0,0,15.66-3.39c.3-.1.17-.58-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M135.25,437.43a67.34,67.34,0,0,1-29.81,6.8,125.19,125.19,0,0,1-16.15-1.65c-5-.68-10.3-1.38-15.3-.5-3.93.69-7.91,3.58-10.5,6.52-.21.25.14.6.35.36,2.52-2.85,6.35-5.65,10.14-6.37,5.39-1,11.26-.08,16.62.68a110.31,110.31,0,0,0,15.07,1.46,67.49,67.49,0,0,0,29.83-6.87c.29-.14,0-.57-.25-.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M64.2,451.4a35.09,35.09,0,0,1,13.15-5.3,39.72,39.72,0,0,1,15.05.48c4.59.92,9.13,2,13.84,2a44.67,44.67,0,0,0,13.14-1.94,48.68,48.68,0,0,0,13.5-6.51c.27-.18,0-.61-.25-.43a46.55,46.55,0,0,1-26.39,8.38c-5,0-9.77-1.27-14.63-2.19a39.31,39.31,0,0,0-13.55-.45A35.64,35.64,0,0,0,64,451c-.27.18,0,.61.25.44Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M130.66,440.56c-6.23,6.1-13,12.61-21.88,14.26-9.08,1.68-17.14-3.93-25.69-5.91a33.47,33.47,0,0,0-14.26-.42c-.31.06-.18.55.14.48a33.22,33.22,0,0,1,14.44.53c4.36,1.06,8.43,2.88,12.65,4.36a25,25,0,0,0,12.21,1.55,28.63,28.63,0,0,0,11.5-4.76A82,82,0,0,0,131,440.92a.25.25,0,0,0-.35-.36Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M145.75,434.58a42.92,42.92,0,0,1,21.85,2.71c3.23,1.31,6.22,3.16,9.46,4.43a18.66,18.66,0,0,0,8.87,1.27c13.59-1.46,21.06-14.39,28-24.54,4.16-6.08,9.2-12.88,16.89-14.4a.25.25,0,0,0,0-.48c-13.68-3.19-26.32-12.47-40.92-9.95-12.53,2.17-22.84,11.56-28.48,22.63-1.91,3.75-3.56,7.63-6.07,11s-5.35,6.49-9.65,6.7a.25.25,0,0,0,0,.5c6.41-.32,10.58-7,13.24-12.09,1.64-3.17,3.05-6.47,4.93-9.51a45.47,45.47,0,0,1,6.11-7.77,38.72,38.72,0,0,1,16.66-10.22c6.86-2,13.83-1.25,20.58.87,7.92,2.5,15.35,6.4,23.47,8.29v-.48c-6,1.18-10.53,5.65-14.12,10.35-4.26,5.56-7.67,11.73-12.14,17.13-4.3,5.19-9.9,9.72-16.63,11.14a18.15,18.15,0,0,1-10.21-.8c-3.24-1.21-6.2-3.05-9.39-4.38a43.32,43.32,0,0,0-22.59-2.91c-.32,0-.18.52.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M150.69,433.33c6.21-1,13.15-1.45,19.14.8,3.24,1.21,6.28,3,9.69,3.73a16.7,16.7,0,0,0,8.67-.5c12.85-4.09,17.86-18,27.39-26.27a27.18,27.18,0,0,1,9.46-5.28c.53-.18,1.54-.59,1.1-1.33a.6.6,0,0,0-1.11.08.25.25,0,0,0,.49.13c-.25.89-2.82,1.35-3.64,1.71a23.6,23.6,0,0,0-3.77,2.11,36.21,36.21,0,0,0-5.9,5.15c-4.12,4.38-7.34,9.5-11.3,14s-8.83,8.51-14.83,9.73a17,17,0,0,1-9.39-.91c-2.68-1-5.22-2.44-8-3.27-5.82-1.74-12.25-1.3-18.17-.38-.31.05-.18.54.14.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M228.4,403.36c-1.73-.39-3.53-.33-5.26-.71a82.17,82.17,0,0,1-8.22-2.55c-5.49-1.84-11-3.73-16.8-4.36a28.63,28.63,0,0,0-15,1.93c-4.13,1.88-7.17,5.13-10,8.59-3.23,4-6.42,8.16-11.3,10.16-.3.12-.17.6.13.48,8.2-3.36,11.42-12.4,18.51-17.19,9.77-6.58,22.27-3.13,32.49.27,3.12,1,6.23,2.12,9.4,3,1.92.52,3.94.44,5.87.87.31.07.44-.41.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M153.16,431c6.66-6.88,13.71-13.43,20.77-19.9a50.75,50.75,0,0,1,5.35-4.37c1.82-1.26,3.81-2.17,5.72-3.27a32.09,32.09,0,0,1,5.95-3c1.28-.43,2.56-.87,3.86-1.25a35.85,35.85,0,0,1,12.21-1c.32,0,.32-.48,0-.5-6.93-.42-14.19.66-20.29,4.17-3.84,2.21-7.69,4.17-11.09,7.06-3.56,3-6.86,6.36-10.27,9.54-4.27,4-8.51,8-12.56,12.18-.23.24.13.59.35.36Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M151.59,432.17c17.38-5.71,27.7-22.8,44.6-29.43a47.74,47.74,0,0,1,18-3.26c.32,0,.32-.49,0-.5a49,49,0,0,0-28.24,8.73c-7.41,5-13.72,11.46-20.94,16.74a49,49,0,0,1-13.52,7.24c-.3.1-.17.58.14.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M153.11,432.29c9.16-2,17.63-6.18,25.56-11.11,4-2.5,7.94-5.21,11.8-8s7.9-5.94,12.14-8.45c5-3,10.91-5,16.77-3.6.31.07.45-.41.13-.49-9.92-2.3-18.56,4.35-26.1,9.81-7.89,5.7-15.9,11.37-24.66,15.67A71.52,71.52,0,0,1,153,431.81c-.31.07-.18.55.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M157.81,431.49a68,68,0,0,0,28.24-9.32c8.6-5.18,15.34-13,24.21-17.75a15.39,15.39,0,0,1,7.23-1.94,22.72,22.72,0,0,1,7.15.89.25.25,0,0,0,.13-.48,21.1,21.1,0,0,0-11.55-.34,23.84,23.84,0,0,0-5.92,3,67.21,67.21,0,0,0-5.84,4.23c-4.08,3.24-7.94,6.76-12.23,9.72A66.91,66.91,0,0,1,157.81,431c-.32,0-.32.53,0,.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M225.62,405.13a36.19,36.19,0,0,0-25,9.12c-3.69,3.21-7,6.86-11,9.65a43.12,43.12,0,0,1-12.53,5.87,48,48,0,0,1-15.81,1.8c-.32,0-.32.49,0,.5a47.1,47.1,0,0,0,25.82-6c8.24-4.74,13.7-13,22.37-17.11a35.21,35.21,0,0,1,16.19-3.34c.32,0,.32-.49,0-.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M163.23,431.73c8.76,2,18.3,4.06,26.94.44,8.09-3.39,11.91-11.71,17.74-17.71a33.41,33.41,0,0,1,12.38-8.39c.3-.11.17-.59-.13-.48-7.63,2.77-13.23,8.66-18,15-2.68,3.54-5.4,7.23-9.22,9.62a25.13,25.13,0,0,1-12.84,3.45c-5.67.12-11.23-1.15-16.72-2.4-.31-.07-.45.41-.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M332.66,447A43.37,43.37,0,0,0,317.2,458.5c-4.23,5-6.57,11.78-12.14,15.49-5.26,3.5-12,3.92-18.11,2.82-7.13-1.31-13.64-4.76-20.2-7.69-6.91-3.08-14.94-6.33-22.49-3.53l.25.41c9.24-9.14,14.8-22.37,26.75-28.57a35.21,35.21,0,0,1,20-3.34,46.7,46.7,0,0,1,19.9,7.35,58.66,58.66,0,0,0,10.66,5.76c3.61,1.34,7.5,2.08,11,.16a.25.25,0,0,0-.25-.44c-3.84,2.1-8.11.85-11.91-.7a67.77,67.77,0,0,1-10-5.68A46.52,46.52,0,0,0,292,433.68,36.35,36.35,0,0,0,273,436.09c-6,2.6-10.73,7.15-14.81,12.18-4.68,5.78-8.68,12.13-14,17.38-.16.17,0,.51.25.42,7.41-2.75,15.31.45,22.1,3.48,6.49,2.89,12.92,6.3,19.95,7.67,5.51,1.07,11.51.91,16.65-1.55a18.59,18.59,0,0,0,6.79-5.66c2-2.66,3.54-5.64,5.47-8.35a43,43,0,0,1,17.55-14.22c.3-.13,0-.56-.25-.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M327.86,448.5a47,47,0,0,0-13.46,8c-4.13,3.73-6.14,9.35-10.76,12.59s-11.11,3.67-16.66,2.59c-6.11-1.18-11.77-3.9-17.62-5.94a45.38,45.38,0,0,0-9.81-2.41,26.34,26.34,0,0,0-5.4-.09c-.77.08-1.53.19-2.28.34l-.88.2c-.3.07-.59.15-.88.24-.41.27-.7.2-.86-.23.24.22.6-.13.36-.35-.74-.68-1.72,1.31.07,1.18a13.56,13.56,0,0,0,1.88-.46,19.56,19.56,0,0,1,3-.45,26.79,26.79,0,0,1,5.37.17c7.13.92,13.6,4.18,20.33,6.48,5.75,2,11.95,3.2,18,1.67a16.8,16.8,0,0,0,7.21-3.77c2.26-2,3.85-4.68,5.59-7.15,4.1-5.83,10.47-9.45,16.93-12.15.29-.13.16-.61-.13-.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M246.46,464.55c1.2-1.16,2.65-2,3.89-3.15a57.61,57.61,0,0,0,5.08-5.73c3.47-4.14,6.88-8.39,11-11.95A30.86,30.86,0,0,1,280,436.43c5.11-1.08,10,.17,14.78,1.85s9.75,3.5,15,2.65c.32-.05.19-.53-.13-.48-5.12.83-10-1-14.72-2.65-4.5-1.57-9.06-2.81-13.86-2.07-10.54,1.64-18,10.12-24.51,17.86-2,2.35-3.9,4.76-6,7-1.32,1.36-3,2.31-4.37,3.63-.23.22.12.58.35.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M324.77,447.79c-4.83-1.24-9.7-2.29-14.59-3.29s-9.63-2.07-14.46-2.94a44.58,44.58,0,0,0-6.91-.79c-2.39-.07-4.76.23-7.15.27a36.86,36.86,0,0,0-9.6,1.64,36.39,36.39,0,0,0-10.73,5.46c-.26.18,0,.62.25.43,5.61-3.93,12.26-6.8,19.2-7,2.21-.07,4.41-.25,6.61-.3a39.14,39.14,0,0,1,7.25.61c4.68.78,9.32,1.89,14,2.82,5.37,1.08,10.73,2.22,16,3.58a.25.25,0,0,0,.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M326.41,448.09c-9.56,2.27-19.29.55-28.82-.94-9.14-1.43-18.35-2.45-27.45-.21A48.08,48.08,0,0,0,256,453a.25.25,0,0,0,.26.43,48.09,48.09,0,0,1,27.23-7.35c9.37.28,18.51,2.66,27.84,3.41a48.9,48.9,0,0,0,15.26-.93c.31-.07.18-.56-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M325.25,448.9c-9.57,3.29-19.71,4.08-29.77,3.89-5-.1-10-.43-15-.79-5.22-.39-10.5-1-15.71-.32a21.23,21.23,0,0,0-12.47,5.58.25.25,0,0,0,.35.36c7.32-7.26,18.49-5.81,27.83-5.12,9.73.71,19.54,1.29,29.26.26a71,71,0,0,0,15.66-3.38c.31-.11.18-.59-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M320.71,450.72a67.19,67.19,0,0,1-29.8,6.8,125.14,125.14,0,0,1-16.16-1.64c-5-.69-10.29-1.39-15.29-.51-3.94.69-7.91,3.58-10.51,6.53-.21.24.14.59.35.35,2.52-2.85,6.35-5.64,10.15-6.37,5.38-1,11.25-.08,16.61.68A108.9,108.9,0,0,0,291.13,458a66.67,66.67,0,0,0,14.22-1.48A67.7,67.7,0,0,0,321,451.16c.29-.14,0-.58-.25-.44Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M249.66,464.69a35.42,35.42,0,0,1,13.15-5.3,39.71,39.71,0,0,1,15.05.48c4.59.92,9.13,2,13.84,2A44.46,44.46,0,0,0,304.85,460a48.53,48.53,0,0,0,13.5-6.51c.26-.18,0-.61-.26-.43a46.4,46.4,0,0,1-26.39,8.38c-5,0-9.77-1.26-14.63-2.19a39.05,39.05,0,0,0-13.55-.44,35.64,35.64,0,0,0-14.11,5.49c-.27.17,0,.61.25.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M316.12,453.85c-6.23,6.11-13,12.62-21.88,14.27-9.08,1.68-17.14-3.93-25.69-5.91a33.31,33.31,0,0,0-14.26-.43c-.31.07-.18.55.14.49a32.91,32.91,0,0,1,14.44.53c4.36,1.05,8.43,2.87,12.65,4.35a25.11,25.11,0,0,0,12.21,1.56,28.89,28.89,0,0,0,11.51-4.77,81.15,81.15,0,0,0,11.23-9.73.25.25,0,0,0-.35-.36Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M331.21,447.87a43,43,0,0,1,21.86,2.72c3.23,1.31,6.21,3.15,9.46,4.42a18.77,18.77,0,0,0,8.86,1.28c13.6-1.46,21.06-14.4,28-24.55,4.16-6.08,9.2-12.88,16.89-14.4a.25.25,0,0,0,0-.48c-13.67-3.18-26.32-12.47-40.92-9.94-12.53,2.16-22.84,11.55-28.48,22.62-1.91,3.75-3.56,7.63-6.06,11-2.4,3.25-5.36,6.48-9.66,6.69-.32,0-.33.52,0,.5,6.41-.32,10.59-7,13.24-12.09,1.64-3.17,3-6.46,4.94-9.51a44.88,44.88,0,0,1,6.11-7.77,38.73,38.73,0,0,1,16.65-10.22c6.87-2,13.84-1.24,20.58.88,7.92,2.49,15.35,6.39,23.47,8.28v-.48c-6,1.19-10.53,5.66-14.12,10.35-4.25,5.56-7.67,11.73-12.14,17.13-4.3,5.19-9.9,9.72-16.63,11.14a18.06,18.06,0,0,1-10.21-.8c-3.24-1.21-6.2-3-9.39-4.38a43.42,43.42,0,0,0-22.59-2.91c-.32,0-.18.53.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M336.15,446.62c6.21-1,13.15-1.45,19.15.8,3.24,1.21,6.27,3,9.68,3.74a17,17,0,0,0,8.68-.5c12.85-4.1,17.85-18,27.39-26.27a27.07,27.07,0,0,1,9.45-5.29c.54-.18,1.54-.59,1.11-1.32a.6.6,0,0,0-1.11.07.25.25,0,0,0,.48.13c-.25.89-2.82,1.35-3.64,1.71a23.65,23.65,0,0,0-3.77,2.12,36.09,36.09,0,0,0-5.9,5.14c-4.12,4.39-7.34,9.5-11.29,14s-8.84,8.51-14.84,9.72a16.8,16.8,0,0,1-9.38-.91c-2.69-1-5.23-2.44-8-3.26-5.82-1.75-12.24-1.31-18.16-.38-.32.05-.19.53.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M413.86,416.65c-1.72-.39-3.53-.33-5.25-.71a81.55,81.55,0,0,1-8.23-2.55c-5.49-1.83-11-3.72-16.8-4.36a28.63,28.63,0,0,0-15,1.94c-4.13,1.87-7.17,5.13-10,8.58-3.23,4-6.42,8.16-11.3,10.16-.29.12-.16.61.13.48,8.2-3.35,11.42-12.4,18.52-17.18,9.76-6.59,22.26-3.13,32.48.27,3.12,1,6.23,2.12,9.41,3,1.91.53,3.93.44,5.86.87.31.07.45-.41.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M338.62,444.26c6.66-6.88,13.72-13.43,20.77-19.9a51.09,51.09,0,0,1,5.36-4.37c1.82-1.25,3.8-2.16,5.71-3.26a31.52,31.52,0,0,1,6-3c1.28-.43,2.56-.87,3.86-1.25a36.1,36.1,0,0,1,12.21-1.05c.32,0,.32-.48,0-.5-6.93-.41-14.19.66-20.29,4.17-3.84,2.22-7.68,4.18-11.09,7.06-3.56,3-6.85,6.37-10.27,9.55-4.27,4-8.5,8-12.56,12.18-.22.23.13.58.35.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M337.05,445.46c17.38-5.71,27.7-22.79,44.61-29.43a47.45,47.45,0,0,1,17.95-3.25.25.25,0,0,0,0-.5A48.94,48.94,0,0,0,371.37,421c-7.41,5-13.72,11.47-20.94,16.74A48.8,48.8,0,0,1,336.92,445c-.31.1-.18.58.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M338.57,445.59c9.16-2,17.64-6.19,25.57-11.12,4-2.5,7.93-5.2,11.79-8s7.9-5.94,12.14-8.44c5-3,10.91-5,16.77-3.61.31.07.45-.41.13-.48-9.92-2.3-18.55,4.35-26.1,9.8-7.89,5.71-15.89,11.37-24.66,15.67a70.66,70.66,0,0,1-15.77,5.65.25.25,0,0,0,.13.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M343.27,444.78a67.79,67.79,0,0,0,28.24-9.32c8.6-5.17,15.34-13,24.21-17.74a15.28,15.28,0,0,1,7.23-2,23,23,0,0,1,7.15.89c.31.1.44-.39.14-.48a21.2,21.2,0,0,0-11.55-.34,24.07,24.07,0,0,0-5.93,3,67.21,67.21,0,0,0-5.84,4.23c-4.08,3.24-7.94,6.76-12.23,9.72a66.91,66.91,0,0,1-31.42,11.46c-.32,0-.32.54,0,.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M411.08,418.43a36.14,36.14,0,0,0-25,9.11c-3.69,3.22-7,6.86-11,9.65a43.28,43.28,0,0,1-12.53,5.88,48.25,48.25,0,0,1-15.81,1.8c-.32,0-.32.48,0,.5a47.25,47.25,0,0,0,25.83-6c8.23-4.74,13.69-13,22.37-17.11a35.28,35.28,0,0,1,16.18-3.33c.32,0,.32-.49,0-.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M348.69,445c8.76,2,18.3,4.05,26.94.44,8.1-3.39,11.91-11.71,17.75-17.72a33.41,33.41,0,0,1,12.37-8.39c.3-.11.18-.59-.13-.48-7.63,2.77-13.22,8.67-18,15-2.68,3.54-5.4,7.23-9.22,9.62A25.14,25.14,0,0,1,365.54,447c-5.66.11-11.23-1.16-16.72-2.41-.31-.07-.45.42-.13.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M159.93,495.92a43.46,43.46,0,0,0-15.46,11.48c-4.23,5-6.57,11.79-12.14,15.49-5.26,3.5-12,3.93-18.1,2.82-7.14-1.3-13.65-4.76-20.2-7.68-6.92-3.09-15-6.33-22.5-3.53l.25.41c9.24-9.14,14.8-22.37,26.75-28.57a35.13,35.13,0,0,1,20-3.34,46.81,46.81,0,0,1,19.9,7.34,58.1,58.1,0,0,0,10.66,5.77c3.61,1.34,7.5,2.08,11,.15.28-.15,0-.58-.26-.43-3.84,2.1-8.11.85-11.91-.7a67.77,67.77,0,0,1-10-5.68,46.52,46.52,0,0,0-18.63-6.86A36.35,36.35,0,0,0,100.22,485c-6,2.6-10.73,7.15-14.8,12.18-4.69,5.78-8.68,12.12-14,17.38-.16.17,0,.51.25.42,7.41-2.75,15.31.45,22.1,3.48,6.49,2.89,12.92,6.3,20,7.67,5.51,1.06,11.51.91,16.65-1.55a18.59,18.59,0,0,0,6.79-5.66c2-2.66,3.54-5.64,5.47-8.35a42.79,42.79,0,0,1,17.55-14.22c.3-.13,0-.56-.25-.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M155.13,497.41a46.78,46.78,0,0,0-13.46,8c-4.13,3.73-6.14,9.35-10.76,12.59s-11.1,3.66-16.66,2.59c-6.11-1.18-11.77-3.9-17.62-6a45.36,45.36,0,0,0-9.81-2.4,25.65,25.65,0,0,0-5.4-.09c-.76.08-1.53.19-2.28.34l-.88.2c-.3.07-.59.15-.88.24-.41.27-.7.19-.85-.23.23.22.59-.13.35-.35-.74-.68-1.72,1.31.08,1.18a13.78,13.78,0,0,0,1.87-.46,19.56,19.56,0,0,1,3-.45,26.07,26.07,0,0,1,5.37.17c7.13.92,13.6,4.18,20.33,6.48,5.75,2,12,3.2,18,1.67a16.91,16.91,0,0,0,7.21-3.77c2.26-2,3.85-4.68,5.59-7.15,4.1-5.83,10.47-9.45,16.93-12.16.29-.12.17-.6-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M73.73,513.46c1.2-1.16,2.66-2,3.89-3.15a57.61,57.61,0,0,0,5.08-5.73c3.48-4.14,6.88-8.39,11-11.95a30.86,30.86,0,0,1,13.58-7.29c5.11-1.08,10,.17,14.78,1.85s9.75,3.5,15,2.65c.31,0,.18-.53-.14-.48-5.12.83-10-1-14.72-2.65-4.5-1.57-9.06-2.81-13.86-2.07-10.54,1.64-18,10.12-24.51,17.86-2,2.35-3.9,4.76-6,7-1.32,1.37-3,2.32-4.37,3.64-.23.22.12.58.35.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M152.05,496.7c-4.84-1.24-9.71-2.29-14.6-3.29s-9.63-2.07-14.46-3a45.82,45.82,0,0,0-6.91-.78c-2.38-.07-4.76.23-7.15.27a36.86,36.86,0,0,0-9.6,1.64,36.39,36.39,0,0,0-10.73,5.46c-.26.18,0,.62.26.43,5.6-3.93,12.25-6.81,19.19-7,2.21-.07,4.41-.25,6.61-.3a39.14,39.14,0,0,1,7.25.61c4.68.78,9.32,1.88,14,2.82,5.37,1.08,10.72,2.22,16,3.58.31.08.45-.4.14-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M153.68,497c-9.56,2.27-19.29.55-28.82-.94-9.14-1.43-18.35-2.45-27.45-.21a48.05,48.05,0,0,0-14.19,6.06c-.27.18,0,.61.26.44A48,48,0,0,1,110.71,495c9.37.28,18.51,2.66,27.84,3.41a48.9,48.9,0,0,0,15.26-.93.25.25,0,0,0-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M152.52,497.8c-9.56,3.3-19.71,4.09-29.77,3.89-5-.09-10-.42-15-.79-5.21-.38-10.5-1-15.71-.31a21.23,21.23,0,0,0-12.47,5.58.25.25,0,0,0,.35.36c7.32-7.26,18.49-5.81,27.83-5.13,9.73.72,19.54,1.3,29.26.27a71.61,71.61,0,0,0,15.66-3.38c.31-.11.18-.59-.13-.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M148,499.63a67.19,67.19,0,0,1-29.8,6.8A123.42,123.42,0,0,1,102,504.78c-5-.68-10.29-1.38-15.29-.5-3.94.69-7.91,3.58-10.51,6.53-.21.24.14.59.36.35,2.51-2.85,6.34-5.64,10.14-6.37,5.38-1,11.25-.08,16.61.68a110.46,110.46,0,0,0,15.07,1.46,66.76,66.76,0,0,0,14.23-1.48,67.81,67.81,0,0,0,15.6-5.39c.29-.14,0-.57-.25-.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M76.93,513.6a35.26,35.26,0,0,1,13.15-5.3,39.71,39.71,0,0,1,15,.48c4.59.92,9.13,2,13.84,2a44.79,44.79,0,0,0,13.15-1.94,48.53,48.53,0,0,0,13.5-6.51c.26-.18,0-.61-.25-.43a46.45,46.45,0,0,1-26.4,8.38c-5,0-9.76-1.27-14.63-2.19a39.3,39.3,0,0,0-13.55-.45,35.65,35.65,0,0,0-14.11,5.5c-.27.17,0,.61.25.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M143.39,502.76c-6.23,6.1-13,12.61-21.88,14.26-9.08,1.69-17.14-3.93-25.69-5.9a33.31,33.31,0,0,0-14.26-.43c-.31.07-.18.55.14.48a33.21,33.21,0,0,1,14.44.53c4.36,1.06,8.43,2.88,12.65,4.36A25,25,0,0,0,121,517.61a28.73,28.73,0,0,0,11.51-4.76,80.66,80.66,0,0,0,11.23-9.73c.24-.23-.12-.58-.35-.36Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M158.48,496.78a43,43,0,0,1,21.86,2.72c3.23,1.31,6.21,3.15,9.46,4.42a18.77,18.77,0,0,0,8.86,1.28c13.6-1.46,21.06-14.4,28-24.55,4.17-6.08,9.2-12.88,16.89-14.4a.25.25,0,0,0,0-.48c-13.67-3.19-26.32-12.47-40.92-9.94-12.53,2.16-22.83,11.55-28.48,22.62-1.91,3.75-3.56,7.63-6.06,11s-5.36,6.49-9.66,6.7c-.32,0-.32.52,0,.5,6.41-.32,10.59-7,13.24-12.09,1.64-3.17,3-6.46,4.94-9.51a44.88,44.88,0,0,1,6.11-7.77,38.63,38.63,0,0,1,16.65-10.22c6.87-2,13.84-1.24,20.58.88,7.92,2.49,15.35,6.39,23.47,8.28v-.48c-6,1.19-10.53,5.66-14.12,10.35-4.25,5.56-7.67,11.73-12.14,17.13-4.3,5.19-9.9,9.72-16.63,11.14a18.15,18.15,0,0,1-10.21-.8c-3.24-1.21-6.2-3-9.39-4.38a43.31,43.31,0,0,0-22.59-2.91c-.32,0-.18.52.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M163.42,495.53c6.21-1,13.15-1.45,19.15.8,3.24,1.21,6.27,3,9.68,3.74a16.85,16.85,0,0,0,8.68-.51c12.85-4.09,17.85-18,27.39-26.27a27,27,0,0,1,9.45-5.28c.54-.18,1.54-.59,1.11-1.33a.6.6,0,0,0-1.11.08.25.25,0,0,0,.48.13c-.25.89-2.82,1.35-3.64,1.71a24.4,24.4,0,0,0-3.77,2.11,36.63,36.63,0,0,0-5.9,5.15c-4.12,4.38-7.34,9.5-11.29,14s-8.84,8.52-14.84,9.73a16.8,16.8,0,0,1-9.38-.91c-2.69-1-5.22-2.44-8-3.27-5.82-1.74-12.24-1.3-18.16-.37-.32.05-.19.53.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M241.13,465.56c-1.72-.39-3.53-.33-5.25-.71a81.55,81.55,0,0,1-8.23-2.55c-5.49-1.84-11-3.73-16.8-4.36a28.64,28.64,0,0,0-15,1.93c-4.13,1.88-7.17,5.14-10,8.59-3.23,4-6.42,8.16-11.3,10.16-.29.12-.16.61.13.48,8.2-3.36,11.42-12.4,18.52-17.18,9.76-6.59,22.26-3.14,32.48.26,3.12,1,6.23,2.13,9.41,3,1.91.53,3.93.44,5.86.87.31.07.45-.41.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M165.89,493.17c6.66-6.88,13.72-13.43,20.77-19.9A49.86,49.86,0,0,1,192,468.9c1.82-1.26,3.8-2.16,5.71-3.27a32.4,32.4,0,0,1,6-3c1.28-.43,2.56-.87,3.86-1.25a35.85,35.85,0,0,1,12.21-1.05c.32,0,.32-.48,0-.5-6.93-.41-14.19.66-20.29,4.17-3.84,2.21-7.68,4.18-11.08,7.06-3.57,3-6.86,6.36-10.28,9.54-4.27,4-8.5,8-12.56,12.19-.22.23.13.58.35.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M164.32,494.37c17.38-5.71,27.7-22.8,44.61-29.43a47.66,47.66,0,0,1,17.95-3.26c.32,0,.32-.49,0-.5a49.07,49.07,0,0,0-28.24,8.73c-7.4,5-13.72,11.46-20.94,16.74a49.07,49.07,0,0,1-13.51,7.24c-.31.1-.18.58.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M165.85,494.5c9.15-2.05,17.63-6.19,25.56-11.12,4-2.5,7.93-5.2,11.79-8,4-2.86,7.9-5.94,12.15-8.44,5-3,10.9-5,16.76-3.61.31.07.45-.41.13-.48-9.92-2.3-18.55,4.34-26.1,9.8-7.89,5.71-15.89,11.37-24.66,15.67A70.66,70.66,0,0,1,165.71,494c-.31.07-.18.56.14.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M170.54,493.69a67.79,67.79,0,0,0,28.24-9.32c8.61-5.18,15.34-13,24.21-17.75a15.39,15.39,0,0,1,7.23-1.94,23,23,0,0,1,7.15.89.25.25,0,0,0,.14-.48,21.1,21.1,0,0,0-11.55-.34,24.07,24.07,0,0,0-5.93,3,67.21,67.21,0,0,0-5.84,4.23c-4.08,3.24-7.94,6.76-12.23,9.72a66.91,66.91,0,0,1-31.42,11.46c-.32,0-.32.53,0,.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M238.35,467.33a36.19,36.19,0,0,0-25,9.12c-3.69,3.22-7,6.86-11.05,9.65A43,43,0,0,1,189.78,492,48.25,48.25,0,0,1,174,493.78c-.32,0-.32.48,0,.5a47.25,47.25,0,0,0,25.83-6c8.23-4.74,13.69-13,22.37-17.11a35.16,35.16,0,0,1,16.18-3.34c.32,0,.32-.49,0-.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M176,493.94c8.76,2,18.3,4.05,26.94.43,8.1-3.38,11.91-11.71,17.75-17.71A33.37,33.37,0,0,1,233,468.27c.3-.11.17-.59-.14-.48-7.63,2.77-13.22,8.66-18,15-2.68,3.54-5.4,7.23-9.22,9.62a25.14,25.14,0,0,1-12.84,3.46c-5.66.11-11.23-1.16-16.72-2.41a.25.25,0,0,0-.13.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M203.62,509.68a43.37,43.37,0,0,0,15.46-11.49c4.23-4.95,6.57-11.78,12.14-15.48,5.26-3.5,12-3.93,18.1-2.82,7.14,1.3,13.65,4.75,20.2,7.68,6.91,3.09,15,6.33,22.49,3.53l-.24-.41c-9.24,9.14-14.8,22.37-26.76,28.57a35.08,35.08,0,0,1-20,3.34,46.81,46.81,0,0,1-19.9-7.34,58.1,58.1,0,0,0-10.66-5.77c-3.61-1.34-7.5-2.08-11-.15-.28.15,0,.58.26.43,3.84-2.1,8.11-.85,11.91.7a68.29,68.29,0,0,1,10,5.68A46.53,46.53,0,0,0,244.25,523a36.39,36.39,0,0,0,19.08-2.41c6-2.6,10.73-7.15,14.8-12.18,4.69-5.78,8.68-12.12,14-17.38.17-.17,0-.51-.24-.42-7.41,2.75-15.31-.45-22.1-3.48-6.49-2.89-12.92-6.3-19.95-7.67-5.51-1.06-11.51-.91-16.65,1.55a18.63,18.63,0,0,0-6.8,5.66c-2,2.66-3.53,5.64-5.46,8.35a42.87,42.87,0,0,1-17.55,14.22c-.3.13,0,.56.25.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M208.42,508.19a46.78,46.78,0,0,0,13.46-8c4.13-3.73,6.14-9.35,10.76-12.59,4.77-3.34,11.1-3.66,16.66-2.59,6.11,1.18,11.77,3.9,17.61,5.95a45.59,45.59,0,0,0,9.82,2.4,25.65,25.65,0,0,0,5.4.09c.76-.08,1.53-.19,2.28-.34l.88-.2c.29-.07.59-.15.87-.24.42-.27.71-.19.86.23-.23-.22-.59.13-.35.35.74.68,1.71-1.31-.08-1.18a13.78,13.78,0,0,0-1.87.46,19.56,19.56,0,0,1-3,.45,26.07,26.07,0,0,1-5.37-.17c-7.13-.92-13.6-4.18-20.33-6.48-5.75-2-11.95-3.2-18-1.67a16.8,16.8,0,0,0-7.21,3.77c-2.26,2-3.85,4.68-5.59,7.15-4.1,5.83-10.47,9.45-16.93,12.16-.3.12-.17.6.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M289.81,492.14c-1.19,1.16-2.65,2-3.88,3.15a57.61,57.61,0,0,0-5.08,5.73c-3.48,4.14-6.88,8.39-11,12a30.86,30.86,0,0,1-13.58,7.29c-5.11,1.08-10-.17-14.78-1.85s-9.76-3.5-15-2.65c-.31,0-.18.53.14.48,5.12-.83,10,1,14.72,2.65,4.5,1.57,9.06,2.81,13.86,2.07,10.54-1.64,18-10.12,24.51-17.86,2-2.35,3.9-4.76,6-7,1.31-1.37,3-2.32,4.37-3.64.23-.22-.12-.58-.36-.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M211.5,508.9c4.84,1.24,9.71,2.29,14.6,3.29s9.62,2.07,14.46,2.94a44.58,44.58,0,0,0,6.91.79c2.38.07,4.76-.23,7.15-.27a36.94,36.94,0,0,0,9.6-1.64A36.39,36.39,0,0,0,275,508.55c.26-.18,0-.62-.26-.43-5.6,3.93-12.25,6.81-19.2,7-2.2.07-4.4.25-6.6.3a39.14,39.14,0,0,1-7.25-.61c-4.68-.78-9.32-1.88-14-2.82-5.37-1.08-10.73-2.22-16-3.58a.25.25,0,0,0-.14.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M209.87,508.6c9.56-2.27,19.29-.55,28.82.94,9.14,1.43,18.35,2.45,27.45.21a47.91,47.91,0,0,0,14.18-6.07c.28-.17,0-.6-.25-.43a48.09,48.09,0,0,1-27.23,7.35c-9.37-.28-18.51-2.66-27.84-3.41a48.9,48.9,0,0,0-15.26.93.25.25,0,0,0,.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M211,507.8c9.56-3.3,19.71-4.09,29.77-3.9,5,.1,10,.43,15,.8,5.21.38,10.49,1,15.71.31A21.23,21.23,0,0,0,284,499.43c.23-.23-.13-.58-.35-.36-7.33,7.26-18.49,5.81-27.83,5.13-9.73-.72-19.54-1.3-29.27-.27a71.73,71.73,0,0,0-15.66,3.38c-.3.11-.17.59.14.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M215.57,506a67.19,67.19,0,0,1,29.8-6.8,123.42,123.42,0,0,1,16.16,1.65c5,.68,10.29,1.38,15.29.5,3.94-.69,7.91-3.58,10.51-6.53.21-.24-.14-.59-.36-.35-2.51,2.85-6.34,5.64-10.14,6.37-5.38,1-11.26.08-16.61-.68a108.9,108.9,0,0,0-15.07-1.46,66.7,66.7,0,0,0-14.23,1.48,68.51,68.51,0,0,0-15.61,5.38c-.28.15,0,.58.26.44Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M286.62,492a35.32,35.32,0,0,1-13.15,5.3,39.71,39.71,0,0,1-15.05-.48c-4.59-.92-9.13-2-13.84-2a44.74,44.74,0,0,0-13.15,1.94,48.53,48.53,0,0,0-13.5,6.51c-.26.18,0,.61.25.43a46.41,46.41,0,0,1,26.4-8.38c5,0,9.76,1.27,14.63,2.19a39.05,39.05,0,0,0,13.55.44,35.64,35.64,0,0,0,14.11-5.49c.27-.17,0-.61-.25-.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M220.16,502.84c6.23-6.1,13-12.61,21.88-14.26,9.07-1.69,17.14,3.92,25.68,5.9a33.32,33.32,0,0,0,14.26.43c.32-.07.19-.55-.13-.48a33.21,33.21,0,0,1-14.44-.53c-4.36-1.06-8.43-2.88-12.65-4.36A25,25,0,0,0,242.55,488,28.67,28.67,0,0,0,231,492.75a81.32,81.32,0,0,0-11.24,9.73c-.23.23.13.58.36.36Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M205.07,508.82a43,43,0,0,1-21.86-2.72c-3.23-1.31-6.21-3.15-9.46-4.42a18.77,18.77,0,0,0-8.86-1.28c-13.6,1.46-21.07,14.4-28,24.55-4.17,6.08-9.2,12.88-16.89,14.4a.25.25,0,0,0,0,.48c13.67,3.19,26.31,12.47,40.92,9.94,12.52-2.16,22.83-11.55,28.48-22.62,1.91-3.75,3.55-7.63,6.06-11s5.36-6.49,9.66-6.7c.32,0,.32-.52,0-.5-6.41.32-10.59,7-13.24,12.09-1.64,3.17-3.05,6.46-4.94,9.51a44.88,44.88,0,0,1-6.11,7.77,38.79,38.79,0,0,1-16.65,10.22c-6.87,2-13.84,1.24-20.58-.88-7.92-2.49-15.35-6.39-23.47-8.28v.48c6-1.19,10.53-5.66,14.12-10.35,4.25-5.56,7.67-11.73,12.14-17.13,4.3-5.19,9.9-9.72,16.63-11.14a18.16,18.16,0,0,1,10.21.8c3.24,1.21,6.2,3,9.38,4.38a43.46,43.46,0,0,0,22.6,2.91c.32,0,.18-.52-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M200.13,510.07c-6.21,1-13.15,1.45-19.15-.8-3.24-1.21-6.27-3-9.68-3.74a16.84,16.84,0,0,0-8.68.51c-12.85,4.09-17.85,18-27.39,26.27a27,27,0,0,1-9.45,5.28c-.54.18-1.55.59-1.11,1.33a.6.6,0,0,0,1.11-.08.25.25,0,0,0-.48-.13c.25-.89,2.82-1.35,3.64-1.71a24.4,24.4,0,0,0,3.77-2.11,36.63,36.63,0,0,0,5.9-5.15c4.11-4.38,7.34-9.5,11.29-14s8.84-8.52,14.84-9.73a16.81,16.81,0,0,1,9.38.91c2.69,1,5.22,2.44,8,3.27,5.82,1.74,12.24,1.3,18.16.37.32-.05.18-.53-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M122.42,540c1.72.39,3.52.33,5.25.71a81.55,81.55,0,0,1,8.23,2.55c5.48,1.83,11,3.72,16.8,4.36a28.66,28.66,0,0,0,15-1.93c4.13-1.88,7.17-5.14,10-8.59,3.23-4,6.42-8.16,11.3-10.16.29-.12.16-.61-.14-.48-8.19,3.36-11.41,12.4-18.51,17.18-9.76,6.59-22.26,3.14-32.49-.26-3.11-1-6.22-2.13-9.4-3-1.91-.53-3.93-.44-5.86-.87-.31-.07-.45.41-.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M197.66,512.43c-6.66,6.88-13.72,13.43-20.77,19.9a49.86,49.86,0,0,1-5.36,4.37c-1.82,1.26-3.8,2.16-5.71,3.27a32.4,32.4,0,0,1-5.95,3c-1.28.43-2.56.87-3.86,1.25a35.85,35.85,0,0,1-12.21,1c-.32,0-.32.48,0,.5,6.93.41,14.19-.66,20.29-4.17,3.83-2.22,7.68-4.18,11.08-7.06,3.56-3,6.86-6.36,10.28-9.55,4.27-4,8.5-8,12.56-12.18.22-.23-.13-.58-.35-.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M199.23,511.23c-17.38,5.71-27.7,22.8-44.61,29.43a47.49,47.49,0,0,1-18,3.25.25.25,0,0,0,0,.5,49,49,0,0,0,28.24-8.72c7.4-5,13.72-11.47,20.94-16.74a48.8,48.8,0,0,1,13.51-7.24c.31-.1.18-.58-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M197.7,511.1c-9.15,2-17.63,6.19-25.56,11.12-4,2.5-7.93,5.2-11.79,8s-7.9,5.94-12.15,8.44c-5,3-10.9,5-16.76,3.61-.32-.07-.45.41-.14.48,9.93,2.3,18.56-4.35,26.1-9.8,7.9-5.71,15.9-11.37,24.67-15.67a70.66,70.66,0,0,1,15.77-5.65c.31-.07.18-.56-.14-.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M193,511.91a67.79,67.79,0,0,0-28.24,9.32c-8.61,5.18-15.34,13-24.21,17.74a15.31,15.31,0,0,1-7.23,1.95,23.08,23.08,0,0,1-7.16-.89.25.25,0,0,0-.13.48,21.1,21.1,0,0,0,11.55.34,24.24,24.24,0,0,0,5.93-3,67.21,67.21,0,0,0,5.84-4.23c4.07-3.24,7.93-6.76,12.23-9.72A66.91,66.91,0,0,1,193,512.41c.32,0,.32-.53,0-.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M125.2,538.27a36.19,36.19,0,0,0,25-9.12c3.69-3.22,7-6.86,11-9.65a43.24,43.24,0,0,1,12.53-5.87,48,48,0,0,1,15.82-1.81c.32,0,.32-.48,0-.5a47.23,47.23,0,0,0-25.83,6c-8.24,4.74-13.7,13-22.37,17.11a35.29,35.29,0,0,1-16.18,3.34.25.25,0,0,0,0,.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M187.59,511.66c-8.76-2-18.3-4.05-26.94-.43-8.1,3.38-11.91,11.71-17.75,17.71a33.37,33.37,0,0,1-12.38,8.39c-.3.11-.17.59.14.48,7.63-2.77,13.22-8.66,18-15,2.68-3.54,5.4-7.23,9.22-9.62a25.11,25.11,0,0,1,12.84-3.46c5.66-.11,11.23,1.16,16.72,2.41a.25.25,0,0,0,.13-.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M376.35,460.78a43.48,43.48,0,0,0,15.46-11.49c4.23-5,6.57-11.79,12.14-15.49,5.26-3.5,12-3.93,18.1-2.82,7.14,1.3,13.64,4.76,20.2,7.68,6.91,3.09,14.95,6.33,22.49,3.54l-.24-.42c-9.24,9.14-14.8,22.37-26.76,28.57a35,35,0,0,1-20,3.34,46.81,46.81,0,0,1-19.9-7.34,58.06,58.06,0,0,0-10.66-5.76c-3.61-1.35-7.5-2.09-11-.16-.28.15,0,.58.25.43,3.85-2.1,8.12-.85,11.92.7a68.29,68.29,0,0,1,10,5.68A46.38,46.38,0,0,0,417,474.1a36.48,36.48,0,0,0,19.08-2.4c6-2.6,10.73-7.16,14.8-12.18,4.69-5.79,8.68-12.13,14-17.39.17-.16,0-.51-.24-.42-7.41,2.75-15.31-.45-22.11-3.48-6.48-2.89-12.91-6.3-19.94-7.67-5.51-1.06-11.52-.91-16.65,1.55a18.72,18.72,0,0,0-6.8,5.66c-2,2.66-3.53,5.64-5.46,8.35a42.79,42.79,0,0,1-17.55,14.22c-.3.13,0,.56.25.44Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M381.15,459.28a46.59,46.59,0,0,0,13.45-8c4.14-3.73,6.15-9.35,10.77-12.59s11.1-3.66,16.66-2.59c6.11,1.19,11.77,3.9,17.61,5.95a45.65,45.65,0,0,0,9.81,2.4,25.76,25.76,0,0,0,5.41.09,22,22,0,0,0,2.28-.34c.3-.06.59-.12.88-.2l.87-.24c.42-.27.71-.19.86.23-.23-.22-.59.13-.35.35.74.69,1.71-1.31-.08-1.18a13.83,13.83,0,0,0-1.87.47,22.29,22.29,0,0,1-3,.45,26.79,26.79,0,0,1-5.37-.18c-7.13-.92-13.61-4.17-20.33-6.47-5.75-2-11.95-3.21-18-1.68a16.91,16.91,0,0,0-7.21,3.77c-2.26,2.05-3.85,4.68-5.59,7.15-4.1,5.83-10.47,9.45-16.93,12.16-.3.12-.17.6.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M462.54,443.23c-1.19,1.16-2.65,2-3.88,3.16a56.4,56.4,0,0,0-5.08,5.72c-3.48,4.14-6.88,8.39-11,11.95A30.86,30.86,0,0,1,429,471.35c-5.11,1.08-10-.17-14.78-1.85s-9.76-3.5-15-2.65c-.31,0-.18.54.14.48,5.12-.83,10,1,14.72,2.65,4.5,1.57,9.06,2.81,13.86,2.07,10.54-1.64,18-10.11,24.5-17.86,2-2.35,3.91-4.76,6-7,1.31-1.37,3-2.31,4.37-3.64.23-.22-.13-.57-.36-.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M384.23,460c4.84,1.24,9.71,2.3,14.6,3.29s9.62,2.07,14.46,3a45.82,45.82,0,0,0,6.91.78c2.38.07,4.76-.23,7.15-.27a36.94,36.94,0,0,0,9.6-1.64,36.39,36.39,0,0,0,10.73-5.46c.26-.18,0-.61-.26-.43-5.6,3.93-12.25,6.81-19.2,7-2.2.07-4.4.25-6.6.3a39.32,39.32,0,0,1-7.26-.61c-4.67-.78-9.31-1.88-14-2.82-5.37-1.08-10.73-2.22-16-3.58a.25.25,0,0,0-.14.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M382.6,459.69c9.56-2.27,19.29-.55,28.82.94,9.14,1.43,18.35,2.45,27.45.21a48,48,0,0,0,14.18-6.06c.27-.18,0-.61-.25-.44a48,48,0,0,1-27.23,7.35c-9.37-.28-18.51-2.66-27.84-3.41a48.9,48.9,0,0,0-15.26.93.25.25,0,0,0,.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M383.76,458.89c9.56-3.3,19.71-4.09,29.76-3.89,5,.09,10,.42,15,.79,5.21.38,10.49,1,15.71.32a21.33,21.33,0,0,0,12.47-5.59c.23-.23-.13-.58-.35-.35-7.33,7.25-18.49,5.8-27.83,5.12-9.73-.72-19.54-1.3-29.27-.27a71.61,71.61,0,0,0-15.66,3.38c-.3.11-.17.59.14.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M388.3,457.06a67.29,67.29,0,0,1,29.8-6.8,125.34,125.34,0,0,1,16.16,1.65c5,.68,10.29,1.38,15.29.5,3.94-.69,7.91-3.58,10.51-6.53.21-.24-.14-.59-.36-.35-2.51,2.85-6.34,5.65-10.14,6.37-5.38,1-11.26.08-16.61-.68a110.46,110.46,0,0,0-15.07-1.46,67.35,67.35,0,0,0-14.23,1.48A68.05,68.05,0,0,0,388,456.63c-.29.14,0,.57.26.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M459.35,443.09a35.32,35.32,0,0,1-13.15,5.3,39.71,39.71,0,0,1-15.05-.48c-4.59-.92-9.13-2-13.84-2a44.74,44.74,0,0,0-13.15,1.94,48.53,48.53,0,0,0-13.5,6.51c-.26.18,0,.61.25.43a46.56,46.56,0,0,1,26.4-8.38c5,0,9.76,1.27,14.63,2.19a39.3,39.3,0,0,0,13.55.45,35.69,35.69,0,0,0,14.11-5.49c.27-.18,0-.61-.25-.44Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M392.89,453.93c6.23-6.1,13-12.61,21.88-14.26,9.07-1.68,17.14,3.93,25.68,5.91a33.47,33.47,0,0,0,14.26.42c.32-.07.19-.55-.13-.48a33.21,33.21,0,0,1-14.44-.53c-4.37-1.06-8.43-2.88-12.65-4.36a25,25,0,0,0-12.21-1.55,28.67,28.67,0,0,0-11.51,4.76,81.32,81.32,0,0,0-11.24,9.73c-.23.23.13.58.36.36Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M377.8,459.91a42.94,42.94,0,0,1-21.86-2.71c-3.23-1.31-6.21-3.16-9.46-4.43a18.64,18.64,0,0,0-8.86-1.27c-13.6,1.45-21.07,14.39-28,24.54-4.17,6.08-9.2,12.88-16.89,14.4a.25.25,0,0,0,0,.48c13.67,3.19,26.31,12.47,40.92,9.94,12.52-2.16,22.83-11.55,28.48-22.62,1.91-3.75,3.55-7.63,6.06-11,2.4-3.25,5.36-6.49,9.66-6.7.32,0,.32-.51,0-.5-6.41.32-10.59,7-13.24,12.09-1.65,3.17-3.05,6.47-4.94,9.51a44.88,44.88,0,0,1-6.11,7.77A38.69,38.69,0,0,1,336.9,499.6c-6.87,2-13.84,1.24-20.58-.88-7.92-2.49-15.35-6.39-23.47-8.28v.48c6-1.18,10.52-5.66,14.11-10.35,4.26-5.56,7.68-11.73,12.15-17.13,4.3-5.19,9.9-9.72,16.63-11.14a18.16,18.16,0,0,1,10.21.8c3.24,1.21,6.2,3,9.38,4.38a43.36,43.36,0,0,0,22.6,2.91c.32,0,.18-.52-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M372.86,461.16c-6.21,1-13.15,1.45-19.15-.8-3.24-1.21-6.27-3-9.68-3.74a16.84,16.84,0,0,0-8.68.51c-12.85,4.09-17.85,18-27.39,26.27a27,27,0,0,1-9.46,5.28c-.53.18-1.54.59-1.1,1.33a.6.6,0,0,0,1.11-.08.25.25,0,0,0-.48-.13c.25-.89,2.82-1.35,3.64-1.71a23.6,23.6,0,0,0,3.77-2.11,36.63,36.63,0,0,0,5.9-5.15c4.11-4.38,7.34-9.5,11.29-14s8.84-8.52,14.84-9.73a17,17,0,0,1,9.38.91c2.69,1,5.22,2.44,8,3.27,5.82,1.74,12.24,1.3,18.16.38.32,0,.18-.54-.13-.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M295.15,491.13c1.72.39,3.52.33,5.25.71a81.55,81.55,0,0,1,8.23,2.55c5.48,1.84,11,3.73,16.8,4.36a28.66,28.66,0,0,0,15-1.93c4.13-1.88,7.16-5.14,10-8.59,3.24-4,6.43-8.16,11.31-10.16.29-.12.16-.6-.14-.48C353.35,481,350.13,490,343,494.78c-9.77,6.58-22.26,3.13-32.49-.27-3.12-1-6.23-2.13-9.4-3-1.91-.52-3.93-.44-5.86-.87-.32-.07-.45.41-.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M370.38,463.52c-6.65,6.88-13.71,13.43-20.76,19.9a49.86,49.86,0,0,1-5.36,4.37c-1.82,1.26-3.8,2.17-5.71,3.27a32.4,32.4,0,0,1-5.95,3c-1.28.43-2.56.87-3.86,1.25a35.85,35.85,0,0,1-12.21,1.05c-.32,0-.32.48,0,.5,6.93.41,14.19-.66,20.29-4.17,3.83-2.21,7.68-4.17,11.08-7.06,3.56-3,6.86-6.36,10.28-9.54,4.27-4,8.5-8,12.56-12.18.22-.24-.13-.59-.36-.36Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M372,462.32c-17.38,5.71-27.7,22.8-44.61,29.43a47.69,47.69,0,0,1-18,3.26c-.32,0-.32.49,0,.5a49,49,0,0,0,28.24-8.73c7.4-5,13.71-11.46,20.94-16.74a49.07,49.07,0,0,1,13.51-7.24c.31-.1.18-.58-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M370.43,462.2c-9.15,2-17.63,6.18-25.56,11.11-4,2.5-7.93,5.21-11.8,8s-7.89,5.94-12.14,8.44c-5,3-10.9,5-16.76,3.61-.32-.07-.45.41-.14.49,9.93,2.3,18.56-4.35,26.1-9.81,7.9-5.7,15.9-11.37,24.67-15.67a70.66,70.66,0,0,1,15.77-5.65c.31-.07.18-.55-.14-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M365.74,463a67.94,67.94,0,0,0-28.24,9.32c-8.61,5.18-15.34,13-24.21,17.75a15.42,15.42,0,0,1-7.23,1.94,22.78,22.78,0,0,1-7.16-.89.25.25,0,0,0-.13.48,21.1,21.1,0,0,0,11.55.34,23.9,23.9,0,0,0,5.93-3,67.21,67.21,0,0,0,5.84-4.23c4.07-3.24,7.93-6.76,12.23-9.72a66.91,66.91,0,0,1,31.42-11.46c.32,0,.32-.53,0-.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M297.93,489.36a36.19,36.19,0,0,0,25-9.12c3.69-3.22,7-6.86,11-9.65a43.24,43.24,0,0,1,12.53-5.87,48.28,48.28,0,0,1,15.82-1.81c.32,0,.32-.48,0-.5a47.23,47.23,0,0,0-25.83,6c-8.24,4.74-13.7,13-22.37,17.11a35.16,35.16,0,0,1-16.18,3.34.25.25,0,0,0,0,.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M360.32,462.76c-8.76-2-18.3-4.06-26.94-.44-8.1,3.38-11.91,11.71-17.75,17.71a33.37,33.37,0,0,1-12.38,8.39c-.3.11-.17.59.14.48,7.63-2.77,13.22-8.66,18-15,2.68-3.54,5.39-7.23,9.22-9.62a25.1,25.1,0,0,1,12.84-3.45c5.66-.12,11.23,1.15,16.72,2.4.31.07.44-.41.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M220.34,570.9a43.28,43.28,0,0,0,15.46-11.49c4.23-5,6.58-11.78,12.14-15.49,5.26-3.5,12-3.93,18.11-2.82,7.13,1.31,13.64,4.76,20.2,7.69,6.91,3.08,14.94,6.33,22.49,3.53l-.25-.42c-9.24,9.15-14.8,22.38-26.75,28.57a35.13,35.13,0,0,1-20,3.35,47,47,0,0,1-19.9-7.35,58.66,58.66,0,0,0-10.66-5.76c-3.61-1.34-7.5-2.09-11-.16a.25.25,0,0,0,.25.43c3.85-2.09,8.11-.84,11.91.71a67.77,67.77,0,0,1,10,5.68A46.47,46.47,0,0,0,261,584.22a36.28,36.28,0,0,0,19.08-2.4c6-2.6,10.73-7.15,14.8-12.18,4.68-5.78,8.68-12.13,14-17.38.17-.17,0-.51-.24-.42-7.42,2.75-15.32-.45-22.11-3.48-6.48-2.9-12.92-6.31-19.95-7.67-5.51-1.07-11.51-.91-16.65,1.55a18.59,18.59,0,0,0-6.79,5.66c-2,2.65-3.53,5.64-5.47,8.35a42.88,42.88,0,0,1-17.55,14.22c-.29.13,0,.56.25.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M225.14,569.41a47.09,47.09,0,0,0,13.46-8c4.13-3.74,6.14-9.36,10.76-12.59,4.78-3.34,11.11-3.67,16.66-2.59,6.12,1.18,11.77,3.89,17.62,5.94a45.38,45.38,0,0,0,9.81,2.41,25.65,25.65,0,0,0,5.4.08,22.15,22.15,0,0,0,2.29-.33l.88-.2.87-.24c.42-.27.7-.2.86.22-.24-.21-.59.14-.36.36.74.68,1.72-1.32-.07-1.18a13.92,13.92,0,0,0-1.88.46,19.38,19.38,0,0,1-3,.45A26.79,26.79,0,0,1,293,554c-7.13-.91-13.6-4.17-20.33-6.47-5.75-2-11.95-3.2-18-1.68a16.91,16.91,0,0,0-7.21,3.77c-2.26,2-3.85,4.69-5.59,7.16-4.1,5.83-10.47,9.45-16.93,12.15-.29.13-.16.61.13.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M306.54,553.36c-1.2,1.16-2.65,2-3.89,3.15a57.61,57.61,0,0,0-5.08,5.73c-3.47,4.14-6.88,8.39-11,12A30.86,30.86,0,0,1,273,581.48c-5.11,1.08-9.95-.17-14.77-1.86s-9.76-3.49-15-2.64c-.32,0-.19.53.13.48,5.13-.83,10,1,14.72,2.65,4.5,1.57,9.06,2.81,13.86,2.07,10.55-1.64,18-10.12,24.51-17.86,2-2.35,3.91-4.77,6-7,1.32-1.36,3-2.31,4.37-3.63a.25.25,0,0,0-.35-.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M228.23,570.12c4.83,1.23,9.7,2.29,14.59,3.29s9.63,2.07,14.47,2.94a45.71,45.71,0,0,0,6.9.79c2.39.06,4.77-.23,7.15-.27a36.26,36.26,0,0,0,20.33-7.1c.26-.18,0-.62-.25-.43-5.61,3.93-12.26,6.8-19.2,7-2.2.06-4.41.25-6.61.3a39.22,39.22,0,0,1-7.25-.62c-4.68-.78-9.31-1.88-14-2.81-5.37-1.09-10.73-2.23-16-3.58a.25.25,0,0,0-.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M226.6,569.82c9.55-2.27,19.28-.55,28.82.94,9.14,1.43,18.34,2.45,27.44.21a48,48,0,0,0,14.19-6.07c.27-.17,0-.6-.25-.43a48.11,48.11,0,0,1-27.23,7.35c-9.38-.28-18.52-2.66-27.84-3.41a49,49,0,0,0-15.27.93c-.31.07-.18.55.14.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M227.75,569c9.57-3.29,19.71-4.08,29.77-3.89,5,.1,10,.43,15,.79,5.22.39,10.5,1,15.71.32a21.18,21.18,0,0,0,12.47-5.59c.23-.22-.12-.58-.35-.35-7.32,7.26-18.49,5.81-27.83,5.12-9.72-.71-19.53-1.29-29.26-.26a70.47,70.47,0,0,0-15.66,3.38c-.3.1-.17.59.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M232.29,567.18a67.33,67.33,0,0,1,29.81-6.79A125.17,125.17,0,0,1,278.25,562c5,.68,10.3,1.39,15.3.51,3.93-.69,7.91-3.58,10.5-6.53.21-.24-.14-.6-.35-.35-2.52,2.85-6.35,5.64-10.14,6.37-5.39,1-11.26.08-16.62-.68a108.75,108.75,0,0,0-15.07-1.46A67.28,67.28,0,0,0,232,566.75c-.29.14,0,.57.25.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M303.34,553.22a35.42,35.42,0,0,1-13.15,5.3,39.72,39.72,0,0,1-15.05-.48c-4.59-.92-9.13-2-13.84-2A44.35,44.35,0,0,0,248.16,558a48.37,48.37,0,0,0-13.5,6.5c-.27.19,0,.62.25.44a46.4,46.4,0,0,1,26.39-8.38c5,0,9.77,1.26,14.63,2.19a39.55,39.55,0,0,0,13.55.44,35.64,35.64,0,0,0,14.11-5.49c.27-.18,0-.61-.25-.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M236.88,564.05c6.23-6.1,13-12.61,21.88-14.26,9.08-1.68,17.14,3.93,25.69,5.91a33.31,33.31,0,0,0,14.26.42c.31-.06.18-.54-.13-.48a33,33,0,0,1-14.45-.53c-4.36-1.05-8.43-2.88-12.65-4.36a25.09,25.09,0,0,0-12.21-1.55,28.79,28.79,0,0,0-11.5,4.77,80.65,80.65,0,0,0-11.24,9.73.25.25,0,0,0,.35.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M221.79,570a42.92,42.92,0,0,1-21.85-2.72c-3.23-1.31-6.22-3.15-9.46-4.42a18.55,18.55,0,0,0-8.87-1.28c-13.59,1.46-21.06,14.4-28,24.55-4.16,6.08-9.2,12.87-16.89,14.4a.25.25,0,0,0,0,.48c13.68,3.18,26.32,12.47,40.92,9.94,12.53-2.17,22.84-11.55,28.48-22.62,1.91-3.75,3.56-7.63,6.07-11,2.39-3.25,5.35-6.48,9.65-6.69.33,0,.33-.52,0-.5-6.41.31-10.58,7-13.24,12.08-1.64,3.18-3.05,6.47-4.93,9.51a45.09,45.09,0,0,1-6.11,7.77,38.74,38.74,0,0,1-16.66,10.23c-6.86,2-13.83,1.24-20.58-.88-7.92-2.49-15.35-6.39-23.47-8.28v.48c6-1.19,10.53-5.66,14.12-10.35,4.26-5.56,7.67-11.73,12.14-17.13,4.3-5.19,9.9-9.72,16.63-11.15a18.15,18.15,0,0,1,10.21.81c3.24,1.21,6.2,3,9.39,4.38a43.42,43.42,0,0,0,22.59,2.91c.32,0,.18-.53-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M216.85,571.29c-6.21,1-13.15,1.45-19.14-.8-3.24-1.22-6.28-3-9.69-3.74a16.8,16.8,0,0,0-8.67.5c-12.85,4.09-17.86,18-27.39,26.27a27,27,0,0,1-9.46,5.29c-.53.18-1.54.59-1.1,1.32a.6.6,0,0,0,1.11-.07.26.26,0,0,0-.49-.14c.25-.88,2.82-1.34,3.64-1.7a24.46,24.46,0,0,0,3.77-2.12,35.27,35.27,0,0,0,5.9-5.15c4.12-4.38,7.34-9.49,11.3-14s8.83-8.51,14.83-9.72a16.85,16.85,0,0,1,9.39.91c2.68,1,5.22,2.44,8,3.26,5.82,1.75,12.25,1.31,18.17.38.31,0,.18-.53-.14-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M139.14,601.26c1.73.39,3.53.33,5.26.7a84.92,84.92,0,0,1,8.22,2.56c5.49,1.83,11,3.72,16.8,4.36a28.63,28.63,0,0,0,15-1.94c4.13-1.87,7.17-5.13,10-8.58,3.23-4,6.42-8.16,11.3-10.16.3-.12.17-.61-.13-.49-8.2,3.36-11.42,12.41-18.51,17.19-9.77,6.58-22.27,3.13-32.49-.27-3.12-1-6.23-2.12-9.4-3-1.92-.52-3.94-.43-5.87-.86-.31-.07-.44.41-.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M214.38,573.65c-6.66,6.88-13.71,13.43-20.77,19.9a53.38,53.38,0,0,1-5.35,4.37c-1.82,1.25-3.81,2.16-5.72,3.26a31.52,31.52,0,0,1-5.95,3c-1.28.43-2.56.87-3.86,1.24a36.1,36.1,0,0,1-12.21,1.06c-.32,0-.32.48,0,.5,6.93.41,14.19-.66,20.29-4.17,3.84-2.22,7.69-4.18,11.09-7.06,3.56-3,6.86-6.37,10.27-9.55,4.27-4,8.51-8,12.56-12.18.23-.23-.13-.58-.35-.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M216,572.44c-17.38,5.71-27.7,22.8-44.6,29.43a47.55,47.55,0,0,1-18,3.26.25.25,0,0,0,0,.5,48.94,48.94,0,0,0,28.24-8.72c7.41-5,13.72-11.47,20.94-16.74a48.72,48.72,0,0,1,13.52-7.24c.3-.1.17-.59-.14-.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M214.43,572.32c-9.16,2-17.63,6.19-25.56,11.12-4,2.5-7.94,5.2-11.8,8s-7.9,5.94-12.14,8.44c-5,3-10.91,5-16.77,3.61-.31-.07-.45.41-.13.48,9.92,2.3,18.56-4.35,26.1-9.8,7.89-5.71,15.9-11.37,24.66-15.67a71.55,71.55,0,0,1,15.77-5.66c.31-.07.18-.55-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M209.73,573.13a67.84,67.84,0,0,0-28.24,9.32c-8.6,5.17-15.34,13-24.21,17.74a15.28,15.28,0,0,1-7.23,1.95,23,23,0,0,1-7.15-.89c-.31-.1-.44.39-.13.48a21.19,21.19,0,0,0,11.55.34,24.53,24.53,0,0,0,5.92-3,69.39,69.39,0,0,0,5.84-4.23c4.08-3.24,7.94-6.76,12.23-9.72a66.63,66.63,0,0,1,14-7.35,67.45,67.45,0,0,1,17.44-4.11c.32,0,.32-.54,0-.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M141.92,599.48a36.14,36.14,0,0,0,25-9.11c3.69-3.22,7-6.87,11.05-9.65a43.4,43.4,0,0,1,12.53-5.88A48.25,48.25,0,0,1,206.3,573c.32,0,.32-.48,0-.5a47.2,47.2,0,0,0-25.82,6c-8.24,4.74-13.7,13-22.37,17.1A35.21,35.21,0,0,1,141.92,599c-.32,0-.32.49,0,.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M204.31,572.88c-8.76-2-18.3-4-26.94-.44-8.09,3.39-11.91,11.71-17.74,17.72a33.65,33.65,0,0,1-12.38,8.39c-.3.11-.17.59.13.48,7.63-2.78,13.23-8.67,18-15,2.68-3.53,5.4-7.22,9.22-9.61A25.14,25.14,0,0,1,187.46,571c5.67-.11,11.23,1.16,16.72,2.4.31.07.45-.41.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M393.07,522a43.28,43.28,0,0,0,15.46-11.49c4.23-5,6.58-11.78,12.14-15.49,5.26-3.5,12-3.92,18.11-2.82,7.13,1.31,13.64,4.76,20.2,7.69,6.91,3.08,14.94,6.33,22.49,3.53l-.25-.41c-9.24,9.14-14.8,22.37-26.75,28.57a35.21,35.21,0,0,1-20,3.34,46.7,46.7,0,0,1-19.9-7.35,58.66,58.66,0,0,0-10.66-5.76c-3.61-1.34-7.5-2.08-11-.16-.29.16,0,.59.25.44,3.85-2.1,8.11-.85,11.91.7a67.77,67.77,0,0,1,10,5.68,46.58,46.58,0,0,0,18.63,6.86,36.39,36.39,0,0,0,19.08-2.41c6-2.6,10.73-7.15,14.8-12.18,4.68-5.78,8.68-12.13,14-17.38.17-.17,0-.51-.25-.42-7.41,2.75-15.31-.45-22.1-3.48-6.48-2.89-12.92-6.3-20-7.67-5.51-1.07-11.51-.91-16.65,1.55a18.59,18.59,0,0,0-6.79,5.66c-2,2.66-3.53,5.64-5.47,8.35a43,43,0,0,1-17.55,14.22c-.29.13,0,.56.25.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M397.87,520.5a46.87,46.87,0,0,0,13.46-8c4.13-3.73,6.14-9.35,10.76-12.59,4.78-3.34,11.11-3.67,16.66-2.59,6.12,1.18,11.77,3.9,17.62,5.94a45.38,45.38,0,0,0,9.81,2.41,26.34,26.34,0,0,0,5.4.09c.77-.08,1.53-.19,2.29-.34l.87-.2c.3-.07.59-.15.88-.24.41-.27.7-.2.86.23-.24-.22-.59.13-.36.35.74.68,1.72-1.31-.07-1.18a13.56,13.56,0,0,0-1.88.46,19.38,19.38,0,0,1-3,.45,26.79,26.79,0,0,1-5.37-.17c-7.13-.92-13.6-4.18-20.33-6.48-5.75-2-11.95-3.2-18-1.67a16.8,16.8,0,0,0-7.21,3.77c-2.26,2-3.85,4.68-5.59,7.15-4.1,5.83-10.47,9.45-16.93,12.15-.29.13-.16.61.13.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M479.27,504.45c-1.2,1.16-2.65,2-3.89,3.15a57.61,57.61,0,0,0-5.08,5.73c-3.47,4.14-6.88,8.39-11,11.95a30.86,30.86,0,0,1-13.58,7.29c-5.11,1.08-9.95-.17-14.77-1.86s-9.76-3.49-15-2.64c-.32,0-.19.53.13.48,5.12-.83,10,1,14.72,2.65,4.5,1.57,9.06,2.81,13.86,2.07,10.54-1.64,18-10.12,24.51-17.86,2-2.35,3.91-4.76,6-7,1.32-1.36,3-2.31,4.37-3.63.23-.22-.12-.58-.35-.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M401,521.21c4.83,1.24,9.7,2.29,14.59,3.29s9.63,2.07,14.46,2.94a44.72,44.72,0,0,0,6.91.79c2.39.07,4.77-.23,7.15-.27a36.86,36.86,0,0,0,9.6-1.64,36.39,36.39,0,0,0,10.73-5.46c.26-.18,0-.62-.25-.43-5.61,3.93-12.26,6.8-19.2,7-2.2.07-4.41.25-6.61.3a39.14,39.14,0,0,1-7.25-.61c-4.68-.78-9.31-1.89-14-2.82-5.37-1.09-10.73-2.22-16-3.58a.25.25,0,0,0-.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M399.32,520.91c9.56-2.27,19.29-.55,28.83.94,9.14,1.43,18.34,2.45,27.44.21A48,48,0,0,0,469.78,516a.25.25,0,0,0-.26-.43,48.06,48.06,0,0,1-27.22,7.35c-9.38-.28-18.52-2.66-27.85-3.41a48.9,48.9,0,0,0-15.26.93c-.31.07-.18.56.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M400.48,520.1c9.57-3.29,19.71-4.08,29.77-3.89,5,.1,10,.43,15,.79,5.22.39,10.5,1,15.71.32a21.23,21.23,0,0,0,12.47-5.58.25.25,0,0,0-.35-.36c-7.32,7.26-18.49,5.81-27.83,5.12-9.72-.71-19.54-1.29-29.26-.26a71,71,0,0,0-15.66,3.38c-.3.11-.17.59.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M405,518.28a67.19,67.19,0,0,1,29.8-6.8A125.14,125.14,0,0,1,451,513.12c5,.68,10.3,1.39,15.29.51,3.94-.69,7.92-3.58,10.51-6.53.21-.24-.14-.59-.35-.35-2.52,2.85-6.35,5.64-10.14,6.37-5.39,1-11.26.08-16.62-.68A108.9,108.9,0,0,0,434.6,511a66.67,66.67,0,0,0-14.22,1.48,67.7,67.7,0,0,0-15.61,5.38c-.29.14,0,.58.25.44Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M476.07,504.31a35.42,35.42,0,0,1-13.15,5.3,39.72,39.72,0,0,1-15.05-.48c-4.59-.92-9.13-2-13.84-2A44.35,44.35,0,0,0,420.89,509a48.46,48.46,0,0,0-13.51,6.51c-.26.18,0,.61.26.43A46.4,46.4,0,0,1,434,507.6c5,0,9.77,1.26,14.63,2.19a39.05,39.05,0,0,0,13.55.44,35.64,35.64,0,0,0,14.11-5.49c.27-.17,0-.61-.25-.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M409.61,515.15c6.23-6.11,13-12.62,21.88-14.27,9.08-1.68,17.14,3.93,25.69,5.91a33.31,33.31,0,0,0,14.26.43c.31-.07.18-.55-.14-.49a32.92,32.92,0,0,1-14.44-.53c-4.36-1.05-8.43-2.87-12.65-4.35A25.09,25.09,0,0,0,432,500.29a28.79,28.79,0,0,0-11.5,4.77,80.65,80.65,0,0,0-11.24,9.73.25.25,0,0,0,.35.36Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M394.52,521.13a43,43,0,0,1-21.86-2.72c-3.22-1.31-6.21-3.15-9.45-4.42a18.79,18.79,0,0,0-8.87-1.28c-13.59,1.46-21.06,14.4-28,24.55-4.16,6.08-9.2,12.88-16.89,14.4a.25.25,0,0,0,0,.48c13.67,3.18,26.32,12.47,40.92,9.94,12.53-2.17,22.84-11.55,28.48-22.62,1.91-3.75,3.56-7.63,6.07-11,2.39-3.25,5.35-6.48,9.65-6.69.32,0,.33-.52,0-.5-6.41.32-10.59,7-13.24,12.09-1.64,3.17-3.05,6.46-4.93,9.51a45.42,45.42,0,0,1-6.12,7.77,38.73,38.73,0,0,1-16.65,10.22c-6.87,2-13.84,1.24-20.58-.88-7.92-2.49-15.35-6.39-23.47-8.28v.48c6-1.19,10.53-5.66,14.12-10.35,4.25-5.56,7.67-11.73,12.14-17.13,4.3-5.19,9.9-9.72,16.63-11.14a18.06,18.06,0,0,1,10.21.8c3.24,1.21,6.2,3,9.39,4.38a43.42,43.42,0,0,0,22.59,2.91c.32,0,.18-.53-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M389.58,522.38c-6.21,1-13.15,1.45-19.15-.8-3.23-1.21-6.27-3-9.68-3.74a16.91,16.91,0,0,0-8.67.5c-12.85,4.1-17.86,18-27.39,26.27a27.2,27.2,0,0,1-9.46,5.29c-.54.18-1.54.59-1.1,1.32a.6.6,0,0,0,1.11-.07.25.25,0,0,0-.49-.13c.25-.89,2.82-1.35,3.64-1.71a23.65,23.65,0,0,0,3.77-2.12,35.27,35.27,0,0,0,5.9-5.15c4.12-4.38,7.34-9.49,11.3-14s8.83-8.51,14.83-9.72a16.8,16.8,0,0,1,9.38.91c2.69,1,5.23,2.44,8,3.26,5.82,1.75,12.24,1.31,18.17.38.31-.05.18-.53-.14-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M311.87,552.35c1.73.39,3.53.33,5.25.71a81.55,81.55,0,0,1,8.23,2.55c5.49,1.83,11,3.72,16.8,4.36a28.63,28.63,0,0,0,15-1.94c4.13-1.87,7.17-5.13,10-8.58,3.23-4,6.42-8.16,11.3-10.16.29-.12.17-.61-.13-.48-8.2,3.35-11.42,12.4-18.51,17.18-9.77,6.59-22.27,3.13-32.49-.27-3.12-1-6.23-2.12-9.4-3-1.92-.53-3.94-.44-5.87-.87-.31-.07-.45.41-.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M387.11,524.74c-6.66,6.88-13.71,13.43-20.77,19.9A52,52,0,0,1,361,549c-1.82,1.25-3.81,2.16-5.72,3.26a31.52,31.52,0,0,1-5.95,3c-1.28.43-2.56.87-3.86,1.25a36.36,36.36,0,0,1-12.21,1c-.32,0-.32.48,0,.5,6.93.41,14.19-.66,20.29-4.17,3.84-2.22,7.69-4.18,11.09-7.06,3.56-3,6.86-6.37,10.27-9.55,4.27-4,8.51-8,12.56-12.18.23-.23-.13-.58-.35-.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M388.68,523.54C371.3,529.25,361,546.33,344.07,553a47.45,47.45,0,0,1-17.95,3.25.25.25,0,0,0,0,.5A48.94,48.94,0,0,0,354.36,548c7.41-5,13.72-11.47,20.94-16.74A48.72,48.72,0,0,1,388.82,524c.3-.1.17-.59-.14-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M387.16,523.41c-9.16,2.05-17.63,6.19-25.56,11.12-4,2.5-7.94,5.2-11.8,8s-7.9,5.94-12.14,8.44c-5,3-10.91,5-16.77,3.61-.31-.07-.45.41-.13.48,9.92,2.3,18.55-4.35,26.1-9.8,7.89-5.71,15.9-11.37,24.66-15.67a71.37,71.37,0,0,1,15.77-5.66c.31-.07.18-.55-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M382.46,524.22a67.84,67.84,0,0,0-28.24,9.32c-8.6,5.17-15.34,13-24.21,17.74a15.28,15.28,0,0,1-7.23,2,23,23,0,0,1-7.15-.89c-.31-.1-.44.39-.14.48a21.2,21.2,0,0,0,11.55.34,24.07,24.07,0,0,0,5.93-3,67.21,67.21,0,0,0,5.84-4.23c4.08-3.24,7.94-6.76,12.23-9.72a66.91,66.91,0,0,1,31.42-11.46c.32,0,.32-.54,0-.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M314.65,550.57a36.14,36.14,0,0,0,25-9.11c3.69-3.22,7-6.86,11.05-9.65a43.28,43.28,0,0,1,12.53-5.88,48.25,48.25,0,0,1,15.81-1.8c.32,0,.32-.48,0-.5a47.2,47.2,0,0,0-25.82,6c-8.24,4.74-13.7,13-22.37,17.11a35.35,35.35,0,0,1-16.19,3.33c-.32,0-.32.49,0,.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M377,524c-8.76-2-18.3-4.05-26.94-.44-8.09,3.39-11.91,11.71-17.75,17.72A33.41,33.41,0,0,1,320,549.64c-.3.11-.17.59.13.48,7.63-2.77,13.23-8.67,18-15,2.68-3.54,5.4-7.23,9.22-9.62a25.14,25.14,0,0,1,12.84-3.46c5.66-.11,11.23,1.16,16.72,2.41.31.07.45-.42.13-.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M349.38,508.22a43.48,43.48,0,0,0-15.46,11.49c-4.22,5-6.57,11.79-12.14,15.49-5.26,3.5-12,3.93-18.1,2.82-7.13-1.3-13.64-4.76-20.2-7.68-6.91-3.09-15-6.33-22.49-3.54l.24.42c9.24-9.14,14.8-22.37,26.76-28.57a35,35,0,0,1,20-3.34,46.81,46.81,0,0,1,19.9,7.34,58.14,58.14,0,0,0,10.67,5.76c3.6,1.35,7.49,2.09,11,.16.28-.15,0-.58-.25-.43-3.85,2.1-8.11.85-11.91-.7a67.54,67.54,0,0,1-10-5.69,46.52,46.52,0,0,0-18.62-6.85,36.48,36.48,0,0,0-19.08,2.4c-6,2.6-10.73,7.16-14.8,12.18-4.68,5.79-8.68,12.13-14,17.39-.17.16,0,.51.24.42,7.41-2.75,15.31.45,22.11,3.48,6.48,2.89,12.91,6.3,19.94,7.67,5.51,1.06,11.52.91,16.65-1.55a18.64,18.64,0,0,0,6.8-5.66c2-2.66,3.53-5.64,5.46-8.35a42.75,42.75,0,0,1,17.56-14.22c.29-.13,0-.56-.26-.44Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M344.58,509.72a46.59,46.59,0,0,0-13.45,8c-4.13,3.73-6.15,9.35-10.77,12.59-4.77,3.34-11.1,3.66-16.66,2.59-6.11-1.19-11.77-3.9-17.61-5.95a45.5,45.5,0,0,0-9.81-2.4,25.66,25.66,0,0,0-5.4-.09,21.78,21.78,0,0,0-2.29.34c-.3.06-.59.12-.88.2l-.87.24c-.42.27-.71.19-.86-.23.23.22.59-.13.35-.35-.74-.69-1.71,1.31.08,1.18a13.46,13.46,0,0,0,1.87-.47,22.29,22.29,0,0,1,3-.45,26.79,26.79,0,0,1,5.37.18c7.13.92,13.61,4.17,20.34,6.47,5.74,2,11.94,3.21,18,1.68a16.91,16.91,0,0,0,7.21-3.77c2.26-2,3.85-4.68,5.59-7.15,4.11-5.83,10.47-9.45,16.93-12.16.3-.12.17-.6-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M263.19,525.77c1.19-1.16,2.65-2,3.88-3.16a56.4,56.4,0,0,0,5.08-5.72c3.48-4.14,6.88-8.39,11-11.95a30.86,30.86,0,0,1,13.58-7.29c5.11-1.08,10,.17,14.78,1.85s9.76,3.5,15,2.65c.32,0,.18-.54-.13-.48-5.13.83-10-1-14.73-2.65-4.5-1.57-9.05-2.81-13.85-2.07-10.55,1.64-18,10.11-24.51,17.86-2,2.35-3.91,4.76-6,7-1.32,1.37-3,2.31-4.38,3.64-.23.22.13.57.36.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M341.5,509c-4.83-1.24-9.71-2.3-14.6-3.29s-9.62-2.07-14.46-3a47.16,47.16,0,0,0-6.91-.79c-2.38-.06-4.76.24-7.14.28a37,37,0,0,0-9.61,1.64,36.21,36.21,0,0,0-10.72,5.46c-.27.18,0,.61.25.43,5.6-3.93,12.26-6.81,19.2-7,2.2-.07,4.4-.25,6.6-.3a39.32,39.32,0,0,1,7.26.61c4.67.78,9.31,1.88,14,2.82,5.37,1.08,10.73,2.22,16,3.58a.25.25,0,0,0,.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M343.13,509.31c-9.56,2.27-19.29.55-28.82-.94-9.14-1.43-18.35-2.45-27.45-.21a48,48,0,0,0-14.18,6.06c-.27.18,0,.61.25.44a48,48,0,0,1,27.23-7.35c9.37.28,18.51,2.66,27.84,3.41a48.86,48.86,0,0,0,15.26-.93.25.25,0,0,0-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M342,510.11c-9.56,3.3-19.71,4.09-29.76,3.89-5-.09-10-.42-15-.79-5.21-.38-10.49-1-15.71-.32A21.33,21.33,0,0,0,269,518.48c-.23.23.13.58.36.35,7.32-7.25,18.48-5.8,27.82-5.12,9.73.72,19.54,1.3,29.27.27a71.05,71.05,0,0,0,15.66-3.39c.3-.1.17-.58-.14-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M337.43,511.94a67.29,67.29,0,0,1-29.8,6.8,125.34,125.34,0,0,1-16.16-1.65c-5-.68-10.29-1.38-15.29-.5-3.94.69-7.91,3.58-10.51,6.53-.21.24.15.59.36.35,2.51-2.85,6.34-5.65,10.14-6.37,5.38-1,11.26-.08,16.61.68a110.46,110.46,0,0,0,15.07,1.46,67.35,67.35,0,0,0,14.23-1.48,68.05,68.05,0,0,0,15.61-5.39c.29-.14,0-.57-.26-.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M266.38,525.91a35.16,35.16,0,0,1,13.15-5.3,39.71,39.71,0,0,1,15.05.48c4.6.92,9.13,2,13.84,2a44.74,44.74,0,0,0,13.15-1.94,48.53,48.53,0,0,0,13.5-6.51c.26-.18,0-.61-.25-.43a46.56,46.56,0,0,1-26.4,8.38c-5,0-9.76-1.27-14.63-2.19a39.3,39.3,0,0,0-13.55-.45,35.69,35.69,0,0,0-14.11,5.49c-.27.18,0,.61.25.44Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M332.84,515.07c-6.22,6.1-13,12.61-21.88,14.26-9.07,1.68-17.14-3.93-25.68-5.91A33.47,33.47,0,0,0,271,523c-.32.07-.18.55.13.48a33.26,33.26,0,0,1,14.45.53c4.36,1.06,8.42,2.88,12.64,4.36a25,25,0,0,0,12.21,1.55A28.67,28.67,0,0,0,322,525.16a81.32,81.32,0,0,0,11.24-9.73c.23-.23-.13-.58-.36-.36Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M347.94,509.09a42.92,42.92,0,0,1,21.85,2.71c3.23,1.31,6.21,3.16,9.46,4.43a18.64,18.64,0,0,0,8.86,1.27c13.6-1.45,21.07-14.39,28-24.54,4.17-6.08,9.21-12.88,16.89-14.4a.25.25,0,0,0,0-.48c-13.67-3.19-26.31-12.47-40.91-9.95-12.53,2.17-22.84,11.56-28.49,22.63-1.91,3.75-3.55,7.63-6.06,11-2.39,3.25-5.36,6.49-9.66,6.7-.32,0-.32.51,0,.5,6.41-.32,10.59-7,13.24-12.09,1.65-3.17,3.05-6.47,4.94-9.51a44.5,44.5,0,0,1,6.11-7.77,38.69,38.69,0,0,1,16.65-10.22c6.87-2,13.84-1.24,20.58.87,7.92,2.5,15.35,6.4,23.47,8.29v-.48c-6,1.18-10.52,5.66-14.11,10.35-4.26,5.56-7.67,11.73-12.15,17.13-4.3,5.19-9.9,9.72-16.63,11.14a18.16,18.16,0,0,1-10.21-.8c-3.24-1.21-6.2-3-9.38-4.38a43.36,43.36,0,0,0-22.6-2.91c-.31,0-.18.52.14.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M352.87,507.84c6.21-1,13.15-1.45,19.15.8,3.24,1.21,6.27,3,9.68,3.73a16.73,16.73,0,0,0,8.68-.5c12.85-4.09,17.85-18,27.39-26.27a27,27,0,0,1,9.46-5.28c.53-.18,1.54-.59,1.1-1.33a.6.6,0,0,0-1.11.08.25.25,0,0,0,.48.13c-.25.89-2.82,1.35-3.64,1.71a24,24,0,0,0-3.77,2.11,36.63,36.63,0,0,0-5.9,5.15c-4.11,4.38-7.34,9.5-11.29,14s-8.84,8.52-14.84,9.73a17,17,0,0,1-9.38-.91c-2.69-1-5.22-2.44-8-3.27-5.81-1.74-12.24-1.3-18.16-.38-.32,0-.18.54.13.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M430.59,477.87c-1.73-.39-3.53-.33-5.26-.71a82.35,82.35,0,0,1-8.23-2.55c-5.48-1.84-11-3.73-16.79-4.36a28.63,28.63,0,0,0-15,1.93c-4.13,1.88-7.17,5.13-10,8.59-3.24,4-6.43,8.16-11.31,10.16-.29.12-.16.6.14.48,8.2-3.36,11.42-12.4,18.51-17.19,9.77-6.58,22.26-3.13,32.49.27,3.12,1,6.23,2.12,9.4,3,1.92.52,3.93.44,5.86.87.32.07.45-.41.14-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M355.35,505.48c6.65-6.88,13.71-13.43,20.77-19.9a48.78,48.78,0,0,1,5.35-4.37c1.82-1.26,3.8-2.17,5.71-3.27a32.72,32.72,0,0,1,5.95-3c1.29-.43,2.56-.87,3.86-1.25a35.85,35.85,0,0,1,12.21-1.05c.32,0,.32-.48,0-.5-6.93-.41-14.19.66-20.28,4.17-3.84,2.21-7.69,4.17-11.09,7.06-3.56,3-6.86,6.36-10.28,9.54-4.27,4-8.5,8-12.56,12.18-.22.24.13.59.36.36Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M353.77,506.68c17.38-5.71,27.7-22.8,44.61-29.43A47.69,47.69,0,0,1,416.33,474c.32,0,.32-.49,0-.5a49,49,0,0,0-28.24,8.73c-7.4,5-13.71,11.46-20.93,16.74a49.27,49.27,0,0,1-13.52,7.24c-.3.1-.17.58.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M355.3,506.8c9.15-2,17.63-6.18,25.56-11.11,4-2.5,7.94-5.21,11.8-8s7.9-5.94,12.14-8.45c5-3,10.9-5,16.76-3.6.32.07.45-.41.14-.49-9.93-2.3-18.56,4.35-26.1,9.81-7.89,5.7-15.9,11.37-24.67,15.67a70.76,70.76,0,0,1-15.76,5.65c-.32.07-.19.55.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M360,506a67.89,67.89,0,0,0,28.24-9.32c8.61-5.18,15.34-13,24.21-17.75a15.43,15.43,0,0,1,7.24-1.94,22.76,22.76,0,0,1,7.15.89.25.25,0,0,0,.13-.48,21.1,21.1,0,0,0-11.55-.34,23.9,23.9,0,0,0-5.93,3,67.21,67.21,0,0,0-5.84,4.23c-4.07,3.24-7.93,6.76-12.23,9.72A66.91,66.91,0,0,1,360,505.5c-.31,0-.32.53,0,.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M427.81,479.64a36.16,36.16,0,0,0-25,9.12c-3.69,3.22-7,6.86-11.05,9.65a43.12,43.12,0,0,1-12.53,5.87,48.25,48.25,0,0,1-15.81,1.81c-.33,0-.33.48,0,.5a47.2,47.2,0,0,0,25.82-6c8.24-4.74,13.7-13,22.37-17.11a35.21,35.21,0,0,1,16.19-3.34c.32,0,.32-.49,0-.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M365.41,506.24c8.76,2,18.3,4.06,26.94.44,8.1-3.39,11.92-11.71,17.75-17.71a33.33,33.33,0,0,1,12.38-8.39c.3-.11.17-.59-.13-.48-7.64,2.77-13.23,8.66-18,15-2.68,3.54-5.39,7.23-9.22,9.62a25.1,25.1,0,0,1-12.84,3.45c-5.66.12-11.23-1.15-16.71-2.4-.32-.07-.45.41-.14.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M176.65,557.13a43.57,43.57,0,0,0-15.46,11.49c-4.22,5-6.57,11.79-12.14,15.49-5.26,3.5-12,3.93-18.1,2.82-7.13-1.3-13.64-4.76-20.2-7.68-6.91-3.09-15-6.33-22.49-3.54l.24.42c9.24-9.14,14.8-22.37,26.76-28.57a35,35,0,0,1,20-3.34,46.81,46.81,0,0,1,19.9,7.34,58.14,58.14,0,0,0,10.67,5.76c3.6,1.35,7.49,2.09,11,.16.28-.15,0-.58-.25-.43-3.85,2.1-8.11.85-11.91-.7a67.54,67.54,0,0,1-10-5.69A46.52,46.52,0,0,0,136,543.81a36.48,36.48,0,0,0-19.08,2.4c-6,2.6-10.73,7.16-14.8,12.18-4.68,5.79-8.68,12.13-14,17.39-.17.16,0,.5.24.41,7.42-2.74,15.31.45,22.11,3.49,6.48,2.89,12.91,6.3,19.95,7.66,5.5,1.07,11.51.91,16.64-1.54a18.57,18.57,0,0,0,6.8-5.67c2-2.65,3.53-5.64,5.46-8.34a42.75,42.75,0,0,1,17.56-14.22c.29-.13,0-.57-.26-.44Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M171.85,558.63a46.79,46.79,0,0,0-13.45,8c-4.13,3.74-6.15,9.36-10.77,12.59s-11.1,3.67-16.66,2.6c-6.11-1.19-11.77-3.9-17.61-6a45.5,45.5,0,0,0-9.81-2.4,25.66,25.66,0,0,0-5.4-.09,19.44,19.44,0,0,0-2.29.34c-.29.06-.59.12-.88.2l-.87.24c-.42.27-.71.19-.86-.23.24.22.59-.13.35-.35-.74-.69-1.71,1.31.08,1.18a13.46,13.46,0,0,0,1.87-.47,22.29,22.29,0,0,1,3-.45A26.89,26.89,0,0,1,104,574c7.12.92,13.6,4.17,20.33,6.47,5.74,2,11.94,3.21,18,1.68a16.91,16.91,0,0,0,7.21-3.77c2.26-2,3.86-4.68,5.59-7.15,4.11-5.83,10.47-9.45,16.94-12.16.29-.12.16-.61-.14-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M90.46,574.68c1.19-1.17,2.65-2,3.88-3.16a56.74,56.74,0,0,0,5.09-5.73c3.47-4.13,6.88-8.38,11-11.94A30.76,30.76,0,0,1,124,546.56c5.11-1.08,10,.17,14.78,1.85s9.76,3.5,15,2.65c.32-.06.18-.54-.13-.49-5.13.84-10-1-14.73-2.64-4.5-1.57-9-2.82-13.85-2.07-10.55,1.63-18,10.11-24.51,17.85-2,2.36-3.91,4.77-6,7-1.32,1.37-3,2.31-4.38,3.63-.23.23.13.58.36.36Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M168.77,557.91c-4.83-1.23-9.71-2.29-14.6-3.28s-9.62-2.07-14.46-3a45.84,45.84,0,0,0-6.91-.79c-2.38-.06-4.76.23-7.14.28a36.26,36.26,0,0,0-20.33,7.1c-.27.18,0,.61.25.43,5.6-3.93,12.26-6.81,19.2-7,2.2-.07,4.4-.26,6.6-.3a39.32,39.32,0,0,1,7.26.61c4.67.78,9.31,1.88,14,2.82,5.37,1.08,10.73,2.22,16,3.58a.25.25,0,0,0,.13-.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M170.4,558.21c-9.56,2.28-19.29.56-28.82-.93-9.14-1.43-18.35-2.45-27.45-.21A48,48,0,0,0,100,563.13c-.27.17,0,.61.25.43a48.1,48.1,0,0,1,27.23-7.34c9.37.28,18.51,2.66,27.84,3.41a48.86,48.86,0,0,0,15.26-.93c.32-.08.18-.56-.13-.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M169.24,559c-9.56,3.3-19.71,4.09-29.76,3.89-5-.09-10-.42-15-.79-5.21-.38-10.49-1-15.71-.32a21.33,21.33,0,0,0-12.47,5.59c-.23.23.13.58.36.35,7.32-7.25,18.48-5.81,27.82-5.12,9.73.72,19.54,1.3,29.27.27a71.05,71.05,0,0,0,15.66-3.39c.3-.1.17-.58-.14-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M164.71,560.85a67.37,67.37,0,0,1-29.81,6.8A125.34,125.34,0,0,1,118.74,566c-5-.68-10.29-1.38-15.29-.51-3.94.7-7.91,3.59-10.5,6.53-.22.24.14.6.35.36,2.51-2.86,6.34-5.65,10.14-6.38,5.39-1,11.26-.07,16.61.69a112.07,112.07,0,0,0,15.07,1.46A67.49,67.49,0,0,0,165,561.28c.29-.14,0-.57-.25-.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M93.65,574.81a35.31,35.31,0,0,1,13.15-5.29,39.71,39.71,0,0,1,15,.48c4.6.92,9.13,2,13.84,2a44.73,44.73,0,0,0,13.15-1.93,48.81,48.81,0,0,0,13.5-6.51c.26-.18,0-.62-.25-.43a46.47,46.47,0,0,1-26.4,8.37c-5,0-9.76-1.26-14.62-2.18a39.36,39.36,0,0,0-13.56-.45,35.69,35.69,0,0,0-14.11,5.49c-.27.18,0,.61.25.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M160.11,564c-6.22,6.1-13,12.61-21.88,14.26-9.07,1.68-17.14-3.93-25.68-5.91a33.47,33.47,0,0,0-14.26-.42c-.32.06-.18.55.13.48a33.26,33.26,0,0,1,14.45.53c4.36,1.06,8.43,2.88,12.64,4.36a25,25,0,0,0,12.22,1.55,28.69,28.69,0,0,0,11.5-4.76,81.39,81.39,0,0,0,11.24-9.74c.23-.22-.12-.57-.36-.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M175.21,558a42.92,42.92,0,0,1,21.85,2.71c3.23,1.31,6.21,3.15,9.46,4.43a18.64,18.64,0,0,0,8.86,1.27c13.6-1.46,21.07-14.39,28-24.54,4.17-6.08,9.21-12.88,16.89-14.4a.26.26,0,0,0,0-.49C246.61,523.8,234,514.52,219.37,517c-12.53,2.17-22.84,11.56-28.49,22.63-1.91,3.75-3.55,7.62-6.06,11-2.39,3.25-5.36,6.48-9.66,6.7-.32,0-.32.51,0,.5,6.41-.32,10.59-7,13.24-12.09,1.65-3.17,3-6.47,4.94-9.51a44.13,44.13,0,0,1,6.11-7.77,38.69,38.69,0,0,1,16.65-10.22c6.87-2,13.84-1.25,20.58.87,7.92,2.49,15.35,6.4,23.47,8.29V527c-6,1.19-10.52,5.66-14.11,10.36-4.26,5.56-7.67,11.73-12.15,17.13-4.3,5.19-9.89,9.72-16.63,11.14a18.16,18.16,0,0,1-10.21-.8c-3.23-1.21-6.2-3-9.38-4.38a43.36,43.36,0,0,0-22.6-2.91c-.31,0-.18.52.14.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M180.14,556.75c6.21-1,13.16-1.45,19.15.8,3.24,1.21,6.27,3,9.68,3.73a16.83,16.83,0,0,0,8.68-.5c12.85-4.09,17.85-18,27.39-26.27a27,27,0,0,1,9.46-5.28c.53-.18,1.54-.6,1.1-1.33a.6.6,0,0,0-1.11.08.25.25,0,0,0,.48.13c-.25.89-2.82,1.34-3.64,1.71a24,24,0,0,0-3.77,2.11,36.15,36.15,0,0,0-5.9,5.15c-4.11,4.38-7.34,9.49-11.29,14s-8.84,8.51-14.84,9.73a17,17,0,0,1-9.38-.91c-2.69-1-5.22-2.44-8-3.27-5.81-1.74-12.24-1.3-18.16-.38-.32,0-.18.53.13.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M257.86,526.77c-1.73-.38-3.53-.32-5.26-.7a79.88,79.88,0,0,1-8.23-2.56c-5.48-1.83-11-3.72-16.79-4.35a28.63,28.63,0,0,0-15,1.93c-4.13,1.87-7.17,5.13-10,8.59-3.24,4-6.42,8.16-11.31,10.16-.29.12-.16.6.14.48,8.2-3.36,11.42-12.41,18.51-17.19,9.77-6.58,22.26-3.13,32.49.27,3.12,1,6.23,2.12,9.4,3,1.92.52,3.93.44,5.86.87.32.07.45-.41.14-.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M182.62,554.39c6.65-6.88,13.71-13.43,20.77-19.9a48.78,48.78,0,0,1,5.35-4.37c1.82-1.26,3.8-2.17,5.71-3.27a33.42,33.42,0,0,1,6-3c1.29-.42,2.56-.87,3.86-1.24a35.89,35.89,0,0,1,12.21-1c.32,0,.32-.48,0-.5-6.93-.42-14.19.65-20.28,4.17-3.84,2.21-7.69,4.17-11.09,7.06-3.56,3-6.86,6.36-10.28,9.54-4.27,4-8.5,8-12.56,12.18-.22.23.13.59.36.36Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M181,555.59c17.39-5.71,27.7-22.8,44.61-29.43a47.73,47.73,0,0,1,17.95-3.26.25.25,0,0,0,0-.5,49.06,49.06,0,0,0-28.24,8.72c-7.4,5-13.71,11.47-20.93,16.75a49.27,49.27,0,0,1-13.52,7.24c-.3.1-.17.58.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M182.57,555.71c9.15-2,17.63-6.18,25.56-11.11,4-2.51,7.94-5.21,11.8-8s7.9-5.94,12.14-8.45c5-3,10.9-5,16.76-3.61a.25.25,0,0,0,.14-.48c-9.93-2.3-18.56,4.35-26.1,9.81-7.89,5.7-15.9,11.37-24.67,15.67a71.26,71.26,0,0,1-15.76,5.65c-.32.07-.18.55.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M187.26,554.91a67.89,67.89,0,0,0,28.24-9.32c8.61-5.18,15.34-13,24.22-17.75A15.36,15.36,0,0,1,247,525.9a22.76,22.76,0,0,1,7.15.89c.31.09.44-.39.13-.49a21.1,21.1,0,0,0-11.55-.33,24.07,24.07,0,0,0-5.93,3,67.21,67.21,0,0,0-5.84,4.23c-4.07,3.24-7.93,6.76-12.23,9.71a65.93,65.93,0,0,1-14,7.35,67.07,67.07,0,0,1-17.44,4.12c-.31,0-.32.53,0,.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M255.08,528.55a36.11,36.11,0,0,0-25,9.12c-3.69,3.21-7,6.86-11.05,9.65a43.38,43.38,0,0,1-12.53,5.87A48,48,0,0,1,190.7,555c-.33,0-.32.49,0,.5a47.19,47.19,0,0,0,25.82-6c8.24-4.75,13.7-13,22.37-17.11a35.21,35.21,0,0,1,16.19-3.34c.32,0,.32-.49,0-.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M192.68,555.15c8.76,2,18.3,4.06,26.94.44,8.1-3.39,11.92-11.71,17.75-17.71a33.33,33.33,0,0,1,12.38-8.39c.3-.11.17-.6-.13-.49-7.63,2.78-13.23,8.67-18,15-2.68,3.54-5.39,7.23-9.22,9.62a25.1,25.1,0,0,1-12.84,3.45c-5.66.12-11.23-1.15-16.71-2.4-.32-.07-.45.41-.14.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M362.12,570.43a43.28,43.28,0,0,0-15.46,11.49c-4.23,5-6.58,11.78-12.15,15.48-5.25,3.5-12,3.93-18.1,2.82-7.13-1.3-13.64-4.75-20.2-7.68-6.91-3.09-14.94-6.33-22.49-3.53l.24.41c9.24-9.14,14.8-22.37,26.76-28.57a35.09,35.09,0,0,1,20-3.34,46.76,46.76,0,0,1,19.9,7.34,58.19,58.19,0,0,0,10.67,5.77c3.6,1.34,7.49,2.08,11,.15.28-.15,0-.58-.25-.43-3.85,2.1-8.11.85-11.91-.7a68.39,68.39,0,0,1-10-5.68,46.53,46.53,0,0,0-18.62-6.86,36.39,36.39,0,0,0-19.08,2.41c-6,2.6-10.73,7.15-14.8,12.18-4.68,5.78-8.68,12.12-14,17.38-.17.17,0,.51.24.42,7.42-2.75,15.31.45,22.11,3.48,6.48,2.89,12.92,6.3,20,7.67,5.51,1.06,11.51.91,16.64-1.55a18.54,18.54,0,0,0,6.8-5.66c2-2.66,3.53-5.64,5.47-8.35a42.8,42.8,0,0,1,17.55-14.22c.29-.13,0-.56-.25-.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M357.31,571.92a46.59,46.59,0,0,0-13.45,8c-4.13,3.73-6.15,9.35-10.77,12.59s-11.1,3.66-16.65,2.59c-6.12-1.18-11.78-3.9-17.62-6a45.36,45.36,0,0,0-9.81-2.4,25.66,25.66,0,0,0-5.4-.09c-.77.08-1.53.19-2.29.34l-.88.2q-.44.1-.87.24c-.42.27-.7.19-.86-.23.24.22.59-.13.35-.35-.74-.68-1.71,1.31.08,1.18a13.43,13.43,0,0,0,1.87-.46,19.51,19.51,0,0,1,3.05-.45,26.07,26.07,0,0,1,5.37.17c7.12.92,13.6,4.18,20.33,6.48,5.75,2,11.94,3.2,17.95,1.67a16.84,16.84,0,0,0,7.22-3.77c2.25-2,3.85-4.68,5.59-7.15,4.1-5.83,10.47-9.45,16.93-12.16.29-.12.16-.6-.14-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M275.92,588c1.2-1.16,2.65-2,3.89-3.15a57.61,57.61,0,0,0,5.08-5.73c3.47-4.14,6.88-8.39,11-12a30.86,30.86,0,0,1,13.58-7.29c5.11-1.08,10,.17,14.78,1.85s9.76,3.5,15,2.65c.32-.05.18-.53-.13-.48-5.13.83-10-1-14.72-2.65-4.51-1.57-9.06-2.81-13.86-2.07-10.55,1.64-18,10.12-24.51,17.86-2,2.35-3.91,4.76-6,7-1.32,1.37-3,2.32-4.37,3.64-.23.22.12.58.35.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M354.23,571.21c-4.83-1.24-9.71-2.29-14.59-3.29S330,565.85,325.17,565a44.46,44.46,0,0,0-6.9-.79c-2.39-.07-4.77.23-7.15.27a36.86,36.86,0,0,0-9.6,1.64,36.39,36.39,0,0,0-10.73,5.46c-.26.18,0,.62.25.43,5.61-3.93,12.26-6.81,19.2-7,2.2-.07,4.4-.25,6.6-.3a39.25,39.25,0,0,1,7.26.61c4.68.78,9.31,1.88,14,2.82,5.37,1.08,10.73,2.22,16,3.58a.25.25,0,0,0,.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M355.86,571.51c-9.55,2.27-19.29.55-28.82-.94-9.14-1.43-18.34-2.45-27.45-.21a48,48,0,0,0-14.18,6.07c-.27.17,0,.6.25.43a48,48,0,0,1,27.23-7.35c9.38.28,18.52,2.66,27.84,3.41A49,49,0,0,0,356,572a.25.25,0,0,0-.14-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M354.71,572.31c-9.57,3.3-19.71,4.09-29.77,3.9-5-.1-10-.43-15-.8-5.21-.38-10.49-1-15.71-.31a21.2,21.2,0,0,0-12.46,5.58.25.25,0,0,0,.35.36c7.32-7.26,18.48-5.81,27.82-5.13,9.73.72,19.54,1.3,29.27.27a71.61,71.61,0,0,0,15.66-3.38c.3-.11.17-.59-.13-.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M350.17,574.14a67.23,67.23,0,0,1-29.81,6.8,123.44,123.44,0,0,1-16.15-1.65c-5-.68-10.3-1.38-15.3-.5-3.93.69-7.91,3.58-10.5,6.53-.21.24.14.59.35.35,2.51-2.85,6.34-5.64,10.14-6.37,5.39-1,11.26-.08,16.62.68a108.75,108.75,0,0,0,15.07,1.46A66.67,66.67,0,0,0,334.81,580a67.89,67.89,0,0,0,15.61-5.39c.29-.14,0-.57-.25-.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M279.12,588.11a35.26,35.26,0,0,1,13.15-5.3,39.67,39.67,0,0,1,15,.48c4.6.92,9.13,2,13.84,2a44.69,44.69,0,0,0,13.15-1.94,48.53,48.53,0,0,0,13.5-6.51c.27-.18,0-.61-.25-.43a46.41,46.41,0,0,1-26.4,8.38c-5,0-9.76-1.27-14.62-2.19a39.06,39.06,0,0,0-13.55-.44,35.74,35.74,0,0,0-14.12,5.49c-.26.17,0,.61.26.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M345.58,577.27c-6.23,6.1-13,12.61-21.88,14.26-9.08,1.69-17.14-3.92-25.69-5.9a33.31,33.31,0,0,0-14.26-.43c-.31.07-.18.55.13.48a33.26,33.26,0,0,1,14.45.53c4.36,1.06,8.43,2.88,12.65,4.36a25,25,0,0,0,12.21,1.55,28.69,28.69,0,0,0,11.5-4.76,81.32,81.32,0,0,0,11.24-9.73.25.25,0,0,0-.35-.36Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M360.67,571.29A42.92,42.92,0,0,1,382.52,574c3.23,1.31,6.22,3.15,9.46,4.42a18.79,18.79,0,0,0,8.87,1.28c13.59-1.46,21.06-14.4,28-24.55,4.16-6.08,9.2-12.88,16.89-14.4a.25.25,0,0,0,0-.48c-13.68-3.19-26.32-12.47-40.92-9.94C392.3,532.5,382,541.89,376.35,553c-1.92,3.75-3.56,7.63-6.07,11s-5.36,6.49-9.66,6.7c-.32,0-.32.52,0,.5,6.41-.32,10.59-7,13.24-12.09,1.65-3.17,3.05-6.46,4.94-9.51a45.37,45.37,0,0,1,6.11-7.77,38.76,38.76,0,0,1,16.66-10.22c6.86-2,13.83-1.24,20.57.88,7.93,2.49,15.35,6.39,23.47,8.28v-.48c-6,1.19-10.52,5.66-14.11,10.35-4.26,5.56-7.67,11.73-12.15,17.13-4.29,5.19-9.89,9.72-16.63,11.14a18.12,18.12,0,0,1-10.2-.8c-3.24-1.21-6.2-3-9.39-4.38a43.43,43.43,0,0,0-22.59-2.91c-.32,0-.19.52.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M365.6,570c6.21-1,13.16-1.45,19.15.8,3.24,1.21,6.28,3,9.68,3.74a16.84,16.84,0,0,0,8.68-.51C416,570,421,556.07,430.5,547.8a27,27,0,0,1,9.46-5.28c.53-.18,1.54-.59,1.1-1.33a.6.6,0,0,0-1.11.08.25.25,0,0,0,.48.13c-.24.89-2.82,1.35-3.63,1.71a24.4,24.4,0,0,0-3.77,2.11,36.28,36.28,0,0,0-5.91,5.15c-4.11,4.38-7.34,9.5-11.29,14S407,572.9,401,574.11a16.84,16.84,0,0,1-9.39-.91c-2.69-1-5.22-2.44-8-3.27-5.82-1.74-12.25-1.3-18.17-.37-.31.05-.18.53.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M443.32,540.07c-1.73-.39-3.53-.33-5.26-.71a82.17,82.17,0,0,1-8.22-2.55c-5.49-1.83-11-3.72-16.8-4.36a28.63,28.63,0,0,0-15,1.93c-4.13,1.88-7.17,5.14-10,8.59-3.24,4-6.42,8.16-11.3,10.16-.3.12-.17.61.13.48,8.2-3.36,11.42-12.4,18.51-17.18,9.77-6.59,22.27-3.14,32.49.26,3.12,1,6.23,2.13,9.4,3,1.92.53,3.93.44,5.87.87.31.07.44-.41.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M368.08,567.68c6.65-6.88,13.71-13.43,20.77-19.9a49.75,49.75,0,0,1,5.35-4.37c1.82-1.26,3.81-2.16,5.71-3.27a32.49,32.49,0,0,1,6-3c1.28-.43,2.56-.87,3.86-1.25a35.81,35.81,0,0,1,12.2-1c.33,0,.32-.48,0-.5-6.92-.41-14.18.66-20.28,4.17-3.84,2.21-7.69,4.18-11.09,7.06-3.56,3-6.86,6.36-10.27,9.55-4.27,4-8.51,8-12.56,12.18a.25.25,0,0,0,.35.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M366.51,568.88c17.38-5.71,27.69-22.8,44.6-29.43a47.54,47.54,0,0,1,18-3.25.25.25,0,0,0,0-.5,49,49,0,0,0-28.25,8.72c-7.4,5-13.71,11.47-20.93,16.74a48.86,48.86,0,0,1-13.52,7.24c-.3.1-.17.58.14.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M368,569c9.16-2,17.63-6.19,25.56-11.12,4-2.5,7.94-5.2,11.8-8s7.9-5.94,12.14-8.44c5-3,10.91-5,16.77-3.61.31.07.44-.41.13-.48-9.92-2.3-18.56,4.35-26.1,9.8-7.89,5.71-15.9,11.37-24.67,15.67a70.76,70.76,0,0,1-15.76,5.65c-.32.07-.18.56.13.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M372.72,568.2A67.84,67.84,0,0,0,401,558.88c8.6-5.18,15.33-13,24.21-17.74a15.25,15.25,0,0,1,7.23-1.95,23,23,0,0,1,7.15.89.25.25,0,0,0,.13-.48,21.1,21.1,0,0,0-11.55-.34,24.42,24.42,0,0,0-5.93,3,67.06,67.06,0,0,0-5.83,4.23c-4.08,3.24-7.94,6.76-12.24,9.72a66.8,66.8,0,0,1-31.42,11.46c-.31,0-.32.53,0,.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M440.54,541.84a36.18,36.18,0,0,0-25,9.12c-3.69,3.22-7,6.86-11.05,9.65A43.12,43.12,0,0,1,392,566.48a48,48,0,0,1-15.81,1.81c-.32,0-.32.48,0,.5a47.2,47.2,0,0,0,25.82-6c8.24-4.74,13.7-13,22.37-17.11a35.21,35.21,0,0,1,16.19-3.34c.32,0,.32-.49,0-.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M378.14,568.45c8.77,2,18.3,4,26.95.43,8.09-3.38,11.91-11.71,17.74-17.71a33.45,33.45,0,0,1,12.38-8.39c.3-.11.17-.59-.13-.48-7.63,2.77-13.23,8.66-18,15-2.68,3.54-5.39,7.23-9.21,9.62A25.17,25.17,0,0,1,395,570.37c-5.66.11-11.23-1.16-16.71-2.41-.32-.07-.45.41-.14.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M189.39,619.33a43.4,43.4,0,0,0-15.46,11.49c-4.23,5-6.58,11.79-12.14,15.49-5.26,3.5-12,3.93-18.11,2.82-7.13-1.3-13.64-4.76-20.2-7.68-6.91-3.09-14.94-6.33-22.49-3.54l.24.42C110.47,629.19,116,616,128,609.76a35,35,0,0,1,20-3.34,46.85,46.85,0,0,1,19.91,7.34,57.73,57.73,0,0,0,10.66,5.76c3.61,1.35,7.49,2.09,11,.16.28-.15,0-.58-.25-.43-3.85,2.1-8.11.85-11.91-.7a66.94,66.94,0,0,1-10-5.69A46.61,46.61,0,0,0,148.75,606a36.48,36.48,0,0,0-19.08,2.4c-6,2.6-10.73,7.16-14.8,12.18-4.68,5.79-8.68,12.13-14,17.39-.17.16,0,.51.24.42,7.42-2.75,15.32.45,22.11,3.48,6.48,2.89,12.92,6.3,20,7.67,5.51,1.06,11.51.91,16.64-1.55a18.64,18.64,0,0,0,6.8-5.66c2-2.66,3.53-5.64,5.47-8.35a42.72,42.72,0,0,1,17.55-14.22c.29-.13,0-.56-.25-.44Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M184.58,620.83a46.73,46.73,0,0,0-13.45,8c-4.13,3.73-6.15,9.35-10.77,12.59-4.77,3.34-11.1,3.66-16.65,2.59-6.12-1.19-11.78-3.9-17.62-5.95a45.36,45.36,0,0,0-9.81-2.4,25.66,25.66,0,0,0-5.4-.09,22.18,22.18,0,0,0-2.29.34c-.29.06-.59.12-.88.2l-.87.24c-.42.27-.7.19-.86-.23.24.22.59-.13.36-.35-.75-.69-1.72,1.31.07,1.18a14,14,0,0,0,1.88-.47,22.06,22.06,0,0,1,3-.45,26.79,26.79,0,0,1,5.37.18c7.12.92,13.6,4.17,20.33,6.47,5.75,2,11.94,3.21,17.95,1.68a16.89,16.89,0,0,0,7.22-3.77c2.25-2,3.85-4.68,5.59-7.15,4.1-5.83,10.47-9.45,16.93-12.16.29-.12.16-.6-.14-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M103.19,636.88c1.2-1.16,2.65-2,3.89-3.16a56.4,56.4,0,0,0,5.08-5.72c3.47-4.14,6.88-8.39,11-12a31,31,0,0,1,13.58-7.29c5.12-1.08,10,.17,14.78,1.85s9.76,3.5,15,2.65c.32,0,.18-.54-.13-.48-5.13.83-10-1-14.72-2.65-4.5-1.57-9.06-2.81-13.86-2.07-10.55,1.64-18,10.11-24.51,17.86-2,2.35-3.91,4.76-6,7-1.32,1.37-3,2.31-4.37,3.64-.23.22.12.57.35.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M181.5,620.12c-4.83-1.24-9.71-2.3-14.59-3.29s-9.63-2.07-14.47-3a47,47,0,0,0-6.9-.79c-2.39-.06-4.77.24-7.15.28a36.86,36.86,0,0,0-9.6,1.64,36.39,36.39,0,0,0-10.73,5.46c-.26.18,0,.61.25.43,5.61-3.93,12.26-6.81,19.2-7,2.2-.07,4.4-.25,6.6-.3a39.25,39.25,0,0,1,7.26.61c4.68.78,9.31,1.88,14,2.82,5.37,1.08,10.73,2.22,16,3.58a.25.25,0,0,0,.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M183.13,620.42c-9.55,2.27-19.29.55-28.82-.94-9.14-1.43-18.34-2.45-27.45-.21a48,48,0,0,0-14.18,6.06c-.27.18,0,.61.25.44a48,48,0,0,1,27.23-7.35c9.38.28,18.52,2.66,27.84,3.41a49,49,0,0,0,15.27-.93.25.25,0,0,0-.14-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M182,621.22c-9.57,3.3-19.71,4.09-29.77,3.89-5-.09-10-.42-15-.79-5.22-.38-10.5-1-15.72-.32A21.27,21.27,0,0,0,109,629.59a.25.25,0,0,0,.35.35c7.32-7.25,18.48-5.8,27.83-5.12,9.72.72,19.53,1.3,29.26.27a71.61,71.61,0,0,0,15.66-3.38c.3-.11.17-.59-.13-.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M177.44,623.05a67.37,67.37,0,0,1-29.81,6.8,125.19,125.19,0,0,1-16.15-1.65c-5-.68-10.3-1.38-15.3-.5-3.93.69-7.91,3.58-10.5,6.53-.21.24.14.59.35.35,2.52-2.85,6.34-5.65,10.14-6.37,5.39-1,11.26-.08,16.62.68a110.31,110.31,0,0,0,15.07,1.46,67.49,67.49,0,0,0,29.83-6.87c.29-.14,0-.57-.25-.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M106.39,637a35.09,35.09,0,0,1,13.15-5.3,39.72,39.72,0,0,1,15,.48c4.59.92,9.12,2,13.83,2a44.69,44.69,0,0,0,13.15-1.94,48.53,48.53,0,0,0,13.5-6.51c.27-.18,0-.61-.25-.43a46.56,46.56,0,0,1-26.4,8.38c-5,0-9.76-1.27-14.62-2.19a39.31,39.31,0,0,0-13.55-.45,35.74,35.74,0,0,0-14.12,5.49c-.26.18,0,.61.26.44Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M172.85,626.18c-6.23,6.1-13,12.61-21.88,14.26-9.08,1.68-17.14-3.93-25.69-5.91a33.47,33.47,0,0,0-14.26-.42c-.31.07-.18.55.13.48a33.26,33.26,0,0,1,14.45.53c4.36,1.06,8.43,2.88,12.65,4.36A25,25,0,0,0,150.46,641a28.69,28.69,0,0,0,11.5-4.76,81.32,81.32,0,0,0,11.24-9.73.25.25,0,0,0-.35-.36Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M187.94,620.2a42.92,42.92,0,0,1,21.85,2.71c3.23,1.31,6.22,3.16,9.46,4.43a18.66,18.66,0,0,0,8.87,1.27c13.59-1.45,21.06-14.39,28-24.54,4.16-6.08,9.2-12.88,16.89-14.4a.25.25,0,0,0,0-.48c-13.68-3.19-26.32-12.47-40.92-10-12.53,2.17-22.84,11.56-28.48,22.63-1.91,3.75-3.56,7.63-6.07,11s-5.35,6.49-9.66,6.7c-.32,0-.32.51,0,.5,6.41-.32,10.59-7,13.24-12.09,1.65-3.17,3.05-6.47,4.94-9.51a45.37,45.37,0,0,1,6.11-7.77,38.66,38.66,0,0,1,16.66-10.22c6.86-2,13.83-1.24,20.57.88,7.93,2.49,15.35,6.39,23.47,8.28v-.48c-6,1.18-10.52,5.66-14.11,10.35-4.26,5.56-7.67,11.73-12.15,17.13-4.29,5.19-9.89,9.72-16.62,11.14a18.15,18.15,0,0,1-10.21-.8c-3.24-1.21-6.2-3-9.39-4.38a43.33,43.33,0,0,0-22.59-2.91c-.32,0-.19.52.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M192.88,619c6.2-1,13.15-1.45,19.14.8,3.24,1.21,6.28,3,9.69,3.73a16.7,16.7,0,0,0,8.67-.5c12.85-4.09,17.86-18,27.39-26.27a27,27,0,0,1,9.46-5.28c.53-.18,1.54-.59,1.1-1.33a.6.6,0,0,0-1.11.08.25.25,0,0,0,.48.13c-.24.89-2.81,1.35-3.63,1.71a23.6,23.6,0,0,0-3.77,2.11,36.28,36.28,0,0,0-5.91,5.15c-4.11,4.38-7.34,9.5-11.29,14s-8.83,8.52-14.83,9.73a17,17,0,0,1-9.39-.91c-2.69-1-5.22-2.44-8-3.27-5.82-1.74-12.25-1.3-18.17-.38-.31,0-.18.54.14.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M270.59,589c-1.73-.39-3.53-.33-5.26-.71a82.17,82.17,0,0,1-8.22-2.55c-5.49-1.84-11-3.73-16.8-4.36a28.63,28.63,0,0,0-15,1.93c-4.13,1.88-7.17,5.13-10,8.59-3.23,4-6.42,8.16-11.3,10.16-.3.12-.17.6.13.48,8.2-3.36,11.42-12.4,18.51-17.19,9.77-6.58,22.27-3.13,32.49.27,3.12,1,6.23,2.13,9.4,3,1.92.52,3.93.44,5.87.87.31.07.44-.41.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M195.35,616.59c6.66-6.88,13.71-13.43,20.77-19.9a49.75,49.75,0,0,1,5.35-4.37c1.82-1.26,3.81-2.17,5.71-3.27a32.49,32.49,0,0,1,6-3c1.28-.43,2.56-.87,3.86-1.25a35.81,35.81,0,0,1,12.2-1.05c.33,0,.33-.48,0-.5-6.92-.41-14.18.66-20.28,4.17-3.84,2.21-7.69,4.17-11.09,7.06-3.56,3-6.86,6.36-10.27,9.54-4.27,4-8.51,8-12.56,12.18-.23.24.13.59.35.36Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M193.78,617.79c17.38-5.71,27.69-22.8,44.6-29.43a47.74,47.74,0,0,1,18-3.26c.32,0,.32-.49,0-.5a49,49,0,0,0-28.25,8.73c-7.4,5-13.71,11.46-20.93,16.74a49,49,0,0,1-13.52,7.24c-.3.1-.17.58.14.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M195.3,617.91c9.16-2,17.63-6.18,25.56-11.11,4-2.5,7.94-5.21,11.8-8s7.9-5.94,12.14-8.44c5-3,10.91-5,16.77-3.61a.25.25,0,0,0,.13-.49c-9.92-2.3-18.56,4.35-26.1,9.81-7.89,5.7-15.9,11.37-24.67,15.67a70.76,70.76,0,0,1-15.76,5.65c-.32.07-.18.55.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M200,617.11a68,68,0,0,0,28.24-9.32c8.6-5.18,15.34-13,24.21-17.75a15.36,15.36,0,0,1,7.23-1.94,22.72,22.72,0,0,1,7.15.89.25.25,0,0,0,.13-.48,21.1,21.1,0,0,0-11.55-.34,24.07,24.07,0,0,0-5.93,3,67.06,67.06,0,0,0-5.83,4.23c-4.08,3.24-7.94,6.76-12.24,9.72A66.82,66.82,0,0,1,200,616.61c-.32,0-.32.53,0,.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M267.81,590.75a36.19,36.19,0,0,0-25,9.12c-3.69,3.22-7,6.86-11,9.65a43.12,43.12,0,0,1-12.53,5.87,48.25,48.25,0,0,1-15.81,1.81c-.32,0-.32.48,0,.5a47.2,47.2,0,0,0,25.82-6c8.24-4.74,13.7-13,22.37-17.11a35.21,35.21,0,0,1,16.19-3.34c.32,0,.32-.49,0-.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M205.42,617.35c8.76,2,18.29,4.06,26.94.44,8.09-3.38,11.91-11.71,17.74-17.71a33.45,33.45,0,0,1,12.38-8.39c.3-.11.17-.59-.13-.48-7.63,2.77-13.23,8.66-18,15-2.69,3.54-5.4,7.23-9.22,9.62a25.17,25.17,0,0,1-12.85,3.45c-5.66.12-11.22-1.15-16.71-2.4-.31-.07-.45.41-.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M233.08,633.1a43.33,43.33,0,0,0,15.45-11.49c4.23-5,6.58-11.78,12.15-15.49,5.25-3.5,12-3.92,18.1-2.82,7.13,1.31,13.64,4.76,20.2,7.69,6.91,3.08,14.95,6.33,22.49,3.53l-.24-.41c-9.24,9.14-14.8,22.37-26.76,28.57a35.18,35.18,0,0,1-20,3.34,46.65,46.65,0,0,1-19.9-7.35,58.75,58.75,0,0,0-10.67-5.76c-3.6-1.34-7.49-2.08-11-.16a.25.25,0,0,0,.25.44c3.85-2.1,8.11-.85,11.91.7a68.39,68.39,0,0,1,10,5.68,46.53,46.53,0,0,0,18.62,6.86A36.39,36.39,0,0,0,292.79,644c6-2.6,10.73-7.15,14.8-12.18,4.68-5.78,8.68-12.13,14-17.38.17-.17,0-.51-.24-.42-7.42,2.75-15.31-.45-22.11-3.48-6.48-2.89-12.92-6.3-20-7.67-5.51-1.07-11.51-.91-16.64,1.55a18.54,18.54,0,0,0-6.8,5.66c-2,2.66-3.53,5.64-5.46,8.35a43,43,0,0,1-17.56,14.22c-.29.13,0,.56.26.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M237.88,631.61a46.82,46.82,0,0,0,13.45-8c4.13-3.73,6.15-9.35,10.77-12.59s11.1-3.67,16.65-2.59c6.12,1.18,11.78,3.9,17.62,5.94a45.38,45.38,0,0,0,9.81,2.41,26.35,26.35,0,0,0,5.4.09c.77-.08,1.53-.19,2.29-.34l.88-.2q.44-.1.87-.24c.42-.27.7-.2.86.23-.24-.22-.59.13-.35.35.74.68,1.71-1.31-.08-1.18a13.43,13.43,0,0,0-1.87.46,19.56,19.56,0,0,1-3,.45,26.86,26.86,0,0,1-5.38-.17c-7.12-.92-13.6-4.18-20.33-6.48-5.75-2-11.94-3.2-17.95-1.67a16.84,16.84,0,0,0-7.22,3.77c-2.25,2-3.85,4.68-5.59,7.15-4.1,5.83-10.47,9.45-16.93,12.15-.29.13-.16.61.14.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M319.27,615.56c-1.2,1.16-2.65,2-3.89,3.15a57.61,57.61,0,0,0-5.08,5.73c-3.47,4.14-6.88,8.39-11,11.95a30.86,30.86,0,0,1-13.58,7.29c-5.11,1.08-10-.17-14.78-1.85s-9.76-3.5-15-2.65c-.32.05-.18.53.13.48,5.13-.83,10,1,14.73,2.65,4.5,1.57,9,2.81,13.85,2.07,10.55-1.64,18-10.12,24.51-17.86,2-2.35,3.91-4.76,6-7,1.32-1.36,3-2.31,4.37-3.63.24-.22-.12-.58-.35-.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M241,632.32c4.83,1.24,9.71,2.29,14.59,3.29s9.63,2.07,14.47,2.94a44.58,44.58,0,0,0,6.91.79c2.38.07,4.76-.23,7.14-.27a36.86,36.86,0,0,0,9.6-1.64A36.39,36.39,0,0,0,304.4,632c.26-.18,0-.62-.25-.43-5.61,3.93-12.26,6.8-19.2,7-2.2.07-4.4.25-6.6.3a39.25,39.25,0,0,1-7.26-.61c-4.68-.78-9.31-1.89-14-2.82-5.37-1.08-10.73-2.22-16-3.58a.25.25,0,0,0-.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M239.33,632c9.55-2.27,19.29-.55,28.82.94,9.14,1.43,18.34,2.45,27.45.21a48,48,0,0,0,14.18-6.07c.27-.17,0-.6-.25-.43A48.11,48.11,0,0,1,282.3,634c-9.37-.28-18.51-2.66-27.84-3.41a48.86,48.86,0,0,0-15.26.93c-.32.07-.19.56.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M240.48,631.21c9.57-3.29,19.71-4.08,29.77-3.89,5,.1,10,.43,15,.79,5.21.39,10.49,1,15.71.32a21.26,21.26,0,0,0,12.47-5.58.26.26,0,0,0-.36-.36c-7.32,7.26-18.48,5.81-27.82,5.12-9.73-.71-19.54-1.29-29.27-.26a71,71,0,0,0-15.66,3.38c-.3.11-.17.59.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M245,629.39a67.23,67.23,0,0,1,29.81-6.8A125.17,125.17,0,0,1,291,624.23c5,.69,10.3,1.39,15.3.51,3.93-.69,7.91-3.58,10.5-6.53.22-.24-.14-.59-.35-.35-2.51,2.85-6.34,5.64-10.14,6.37-5.39,1-11.26.08-16.61-.68a109,109,0,0,0-15.08-1.46,66.67,66.67,0,0,0-14.22,1.48A67.86,67.86,0,0,0,244.77,629c-.29.14,0,.58.25.44Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M316.07,615.42a35.45,35.45,0,0,1-13.14,5.3,39.71,39.71,0,0,1-15.05-.48c-4.6-.92-9.13-2-13.84-2a44.36,44.36,0,0,0-13.15,1.94,48.53,48.53,0,0,0-13.5,6.51c-.27.18,0,.61.25.43a46.41,46.41,0,0,1,26.4-8.38c5,0,9.76,1.26,14.62,2.19a39.07,39.07,0,0,0,13.55.44,35.68,35.68,0,0,0,14.12-5.49c.26-.17,0-.61-.26-.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M249.61,626.26c6.23-6.11,13-12.62,21.88-14.27,9.08-1.68,17.15,3.93,25.69,5.91a33.32,33.32,0,0,0,14.26.43c.32-.07.18-.55-.13-.49a33,33,0,0,1-14.45-.53c-4.36-1-8.43-2.87-12.65-4.35A25.09,25.09,0,0,0,272,611.4a28.85,28.85,0,0,0-11.5,4.77,80.65,80.65,0,0,0-11.24,9.73.25.25,0,0,0,.35.36Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M234.52,632.24a42.92,42.92,0,0,1-21.85-2.72c-3.23-1.31-6.22-3.15-9.46-4.42a18.77,18.77,0,0,0-8.86-1.28c-13.6,1.46-21.07,14.4-28,24.55-4.16,6.08-9.2,12.88-16.89,14.4a.25.25,0,0,0,0,.48c13.68,3.18,26.32,12.47,40.92,9.94,12.53-2.17,22.84-11.55,28.48-22.62,1.92-3.75,3.56-7.63,6.07-11,2.39-3.25,5.36-6.48,9.66-6.69.32,0,.32-.52,0-.5-6.41.32-10.59,7-13.24,12.09-1.65,3.17-3.05,6.46-4.94,9.51a45.37,45.37,0,0,1-6.11,7.77,38.76,38.76,0,0,1-16.66,10.22c-6.86,2-13.83,1.24-20.57-.88-7.93-2.49-15.35-6.39-23.47-8.28v.48c6-1.19,10.52-5.66,14.11-10.35,4.26-5.56,7.67-11.73,12.15-17.13,4.29-5.19,9.89-9.72,16.63-11.14a18,18,0,0,1,10.2.8c3.24,1.21,6.2,3,9.39,4.38a43.46,43.46,0,0,0,22.6,2.91c.31,0,.18-.53-.14-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M229.59,633.49c-6.21,1-13.16,1.45-19.15-.8-3.24-1.21-6.28-3-9.68-3.74a16.94,16.94,0,0,0-8.68.5c-12.85,4.1-17.86,18-27.39,26.27a27,27,0,0,1-9.46,5.29c-.53.18-1.54.59-1.1,1.32a.6.6,0,0,0,1.11-.07.25.25,0,0,0-.48-.13c.24-.89,2.82-1.35,3.64-1.71a24,24,0,0,0,3.76-2.12,35.34,35.34,0,0,0,5.91-5.15c4.11-4.38,7.34-9.49,11.29-14s8.84-8.51,14.83-9.72a16.84,16.84,0,0,1,9.39.91c2.69,1,5.22,2.44,8,3.26,5.82,1.75,12.25,1.31,18.17.38.32-.05.18-.53-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M151.87,663.46c1.73.39,3.53.33,5.26.71a82.17,82.17,0,0,1,8.22,2.55c5.49,1.83,11,3.72,16.8,4.36a28.63,28.63,0,0,0,15-1.94c4.13-1.87,7.17-5.13,10-8.58,3.24-4,6.42-8.16,11.3-10.16.3-.12.17-.61-.13-.48-8.2,3.35-11.42,12.4-18.51,17.18-9.77,6.59-22.27,3.13-32.49-.27-3.12-1-6.23-2.12-9.4-3-1.92-.53-3.93-.44-5.87-.87-.31-.07-.44.41-.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M227.11,635.85c-6.65,6.88-13.71,13.43-20.77,19.9a51,51,0,0,1-5.35,4.37c-1.82,1.25-3.81,2.16-5.71,3.26a31.91,31.91,0,0,1-6,3c-1.28.43-2.56.87-3.86,1.25a36.32,36.32,0,0,1-12.2,1c-.32,0-.32.48,0,.5,6.92.41,14.19-.66,20.28-4.17,3.84-2.22,7.69-4.18,11.09-7.06,3.56-3,6.86-6.37,10.27-9.55,4.27-4,8.51-8,12.56-12.18a.25.25,0,0,0-.35-.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M228.69,634.65c-17.39,5.71-27.7,22.79-44.61,29.43a47.52,47.52,0,0,1-18,3.25.25.25,0,0,0,0,.5,49,49,0,0,0,28.24-8.72c7.4-5,13.71-11.47,20.93-16.74a48.86,48.86,0,0,1,13.52-7.24c.3-.1.17-.58-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M227.16,634.52c-9.15,2.05-17.63,6.19-25.56,11.12-4,2.5-7.94,5.2-11.8,8s-7.9,5.94-12.14,8.44c-5,3-10.9,5-16.77,3.61-.31-.07-.44.41-.13.48,9.93,2.3,18.56-4.35,26.1-9.8,7.89-5.71,15.9-11.37,24.67-15.67A71.29,71.29,0,0,1,227.29,635c.32-.07.18-.55-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M222.47,635.33a67.75,67.75,0,0,0-28.24,9.32c-8.61,5.17-15.34,13-24.22,17.74a15.25,15.25,0,0,1-7.23,2,23,23,0,0,1-7.15-.89c-.31-.1-.44.39-.13.48a21.2,21.2,0,0,0,11.55.34,24.42,24.42,0,0,0,5.93-3,68.77,68.77,0,0,0,5.84-4.23c4.07-3.24,7.93-6.76,12.23-9.72a66.8,66.8,0,0,1,31.42-11.46c.31,0,.32-.54,0-.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M154.65,661.68a36.1,36.1,0,0,0,25-9.11c3.69-3.22,7-6.86,11.05-9.65A43.4,43.4,0,0,1,203.22,637a48.25,48.25,0,0,1,15.81-1.8c.32,0,.32-.48,0-.5a47.2,47.2,0,0,0-25.82,6c-8.24,4.74-13.7,13-22.37,17.11a35.33,35.33,0,0,1-16.19,3.33c-.32,0-.32.49,0,.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M217.05,635.08c-8.77-2-18.3-4.05-27-.44-8.09,3.39-11.91,11.71-17.74,17.72A33.45,33.45,0,0,1,160,660.75c-.3.11-.17.59.13.48,7.63-2.77,13.23-8.67,18-15,2.68-3.54,5.39-7.23,9.21-9.62a25.17,25.17,0,0,1,12.85-3.46c5.66-.11,11.23,1.16,16.71,2.41.32.07.45-.42.14-.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M405.8,584.19a43.28,43.28,0,0,0,15.46-11.49c4.23-5,6.58-11.78,12.15-15.48,5.25-3.5,12-3.93,18.1-2.82,7.13,1.3,13.64,4.76,20.2,7.68,6.91,3.09,14.94,6.33,22.49,3.53l-.24-.41c-9.24,9.14-14.8,22.37-26.76,28.57a35.11,35.11,0,0,1-20,3.34,46.76,46.76,0,0,1-19.9-7.34A58.19,58.19,0,0,0,416.66,584c-3.61-1.34-7.49-2.08-11-.15-.28.15,0,.58.25.43,3.85-2.1,8.11-.85,11.91.7a68.39,68.39,0,0,1,10,5.68,46.53,46.53,0,0,0,18.62,6.86,36.39,36.39,0,0,0,19.08-2.41c6-2.6,10.73-7.15,14.8-12.18,4.68-5.78,8.68-12.12,14-17.38.17-.17,0-.51-.24-.42-7.42,2.75-15.31-.45-22.11-3.48-6.48-2.89-12.92-6.3-19.95-7.67-5.51-1.06-11.51-.91-16.64,1.55a18.54,18.54,0,0,0-6.8,5.66c-2,2.66-3.53,5.64-5.47,8.35a42.72,42.72,0,0,1-17.55,14.22c-.29.13,0,.56.25.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M410.61,582.7a46.73,46.73,0,0,0,13.45-8c4.13-3.73,6.15-9.35,10.77-12.59,4.77-3.34,11.1-3.66,16.65-2.59,6.12,1.18,11.78,3.9,17.62,6a45.36,45.36,0,0,0,9.81,2.4,25.66,25.66,0,0,0,5.4.09c.77-.08,1.53-.19,2.29-.34l.88-.2q.43-.1.87-.24c.42-.27.7-.19.86.23-.24-.22-.59.13-.35.35.74.68,1.71-1.31-.08-1.18a13.43,13.43,0,0,0-1.87.46,19.51,19.51,0,0,1-3.05.45,26.07,26.07,0,0,1-5.37-.17c-7.12-.92-13.6-4.18-20.33-6.48-5.75-2-11.94-3.2-18-1.67a17,17,0,0,0-7.22,3.77c-2.25,2-3.85,4.68-5.59,7.15-4.1,5.83-10.47,9.45-16.93,12.16-.29.12-.16.6.14.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M492,566.65c-1.2,1.16-2.65,2-3.89,3.15a57.61,57.61,0,0,0-5.08,5.73c-3.47,4.14-6.88,8.39-11,12a30.91,30.91,0,0,1-13.58,7.29c-5.11,1.08-10-.17-14.78-1.85s-9.76-3.5-15-2.65c-.32.05-.18.53.13.48,5.13-.83,10,1,14.72,2.65,4.51,1.57,9.06,2.81,13.86,2.07,10.55-1.64,18-10.12,24.51-17.86,2-2.35,3.91-4.76,6-7,1.32-1.37,3-2.32,4.37-3.64.23-.22-.12-.58-.35-.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M413.69,583.41c4.83,1.24,9.71,2.29,14.59,3.29s9.63,2.07,14.47,2.95a45.69,45.69,0,0,0,6.9.78c2.39.07,4.77-.23,7.15-.27a36.86,36.86,0,0,0,9.6-1.64,36.39,36.39,0,0,0,10.73-5.46c.26-.18,0-.62-.25-.43-5.61,3.93-12.26,6.81-19.2,7-2.2.07-4.4.25-6.6.3a39.25,39.25,0,0,1-7.26-.61c-4.68-.78-9.31-1.88-14-2.82-5.37-1.08-10.73-2.22-16-3.58a.25.25,0,0,0-.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M412.06,583.11c9.55-2.27,19.29-.55,28.82.94,9.14,1.43,18.34,2.45,27.45.21a48,48,0,0,0,14.18-6.06c.27-.18,0-.61-.25-.44A48,48,0,0,1,455,585.11c-9.38-.28-18.52-2.66-27.84-3.41a49,49,0,0,0-15.27.93.25.25,0,0,0,.14.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M413.21,582.31c9.57-3.3,19.71-4.09,29.77-3.89,5,.09,10,.42,15,.79,5.21.38,10.49,1,15.71.31a21.2,21.2,0,0,0,12.46-5.58.25.25,0,0,0-.35-.36c-7.32,7.26-18.48,5.81-27.82,5.13-9.73-.72-19.54-1.3-29.27-.27a71.61,71.61,0,0,0-15.66,3.38c-.3.11-.17.59.13.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M417.75,580.48a67.23,67.23,0,0,1,29.81-6.8,123.44,123.44,0,0,1,16.15,1.65c5,.68,10.3,1.38,15.3.5,3.93-.69,7.91-3.58,10.5-6.53.21-.24-.14-.59-.35-.35-2.51,2.85-6.34,5.64-10.14,6.37-5.39,1-11.26.08-16.62-.68a110.31,110.31,0,0,0-15.07-1.46,66.67,66.67,0,0,0-14.22,1.48,67.89,67.89,0,0,0-15.61,5.39c-.29.14,0,.57.25.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M488.8,566.51a35.26,35.26,0,0,1-13.15,5.3,39.67,39.67,0,0,1-15-.48c-4.6-.92-9.13-2-13.84-2a44.69,44.69,0,0,0-13.15,1.94,48.53,48.53,0,0,0-13.5,6.51c-.27.18,0,.61.25.43a46.41,46.41,0,0,1,26.4-8.38c5,0,9.76,1.27,14.62,2.19a39.31,39.31,0,0,0,13.55.45,35.75,35.75,0,0,0,14.12-5.5c.26-.17,0-.61-.26-.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M422.34,577.35c6.23-6.1,13-12.61,21.88-14.26,9.08-1.69,17.14,3.93,25.69,5.9a33.31,33.31,0,0,0,14.26.43c.31-.07.18-.55-.13-.48a33.26,33.26,0,0,1-14.45-.53c-4.36-1.06-8.43-2.88-12.65-4.36a25,25,0,0,0-12.21-1.55,28.69,28.69,0,0,0-11.5,4.76A81.32,81.32,0,0,0,422,577a.25.25,0,0,0,.35.36Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M407.25,583.33a42.92,42.92,0,0,1-21.85-2.72c-3.23-1.31-6.22-3.15-9.46-4.42a18.79,18.79,0,0,0-8.87-1.28c-13.59,1.46-21.06,14.4-28,24.55-4.16,6.08-9.2,12.88-16.89,14.4a.25.25,0,0,0,0,.48c13.68,3.19,26.32,12.47,40.92,9.94,12.53-2.16,22.84-11.55,28.48-22.62,1.92-3.75,3.56-7.63,6.07-11s5.36-6.49,9.66-6.7c.32,0,.32-.52,0-.5-6.41.32-10.59,7-13.24,12.09-1.65,3.17-3.05,6.46-4.94,9.51A45.37,45.37,0,0,1,383,612.8,38.66,38.66,0,0,1,366.35,623c-6.86,2-13.83,1.24-20.57-.88-7.93-2.49-15.35-6.39-23.47-8.28v.48c6-1.19,10.52-5.66,14.11-10.35,4.26-5.56,7.67-11.73,12.15-17.13,4.29-5.19,9.89-9.72,16.63-11.14a18.12,18.12,0,0,1,10.2.8c3.24,1.21,6.2,3.05,9.39,4.38a43.33,43.33,0,0,0,22.59,2.91c.32,0,.19-.52-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M402.32,584.58c-6.21,1-13.16,1.45-19.15-.8-3.24-1.21-6.28-3-9.68-3.74a16.84,16.84,0,0,0-8.68.51c-12.85,4.09-17.86,18-27.39,26.27A27,27,0,0,1,328,612.1c-.53.18-1.54.59-1.1,1.33a.6.6,0,0,0,1.11-.08.25.25,0,0,0-.48-.13c.24-.89,2.82-1.35,3.63-1.71a24.4,24.4,0,0,0,3.77-2.11,36.28,36.28,0,0,0,5.91-5.15c4.11-4.38,7.34-9.5,11.29-14s8.83-8.52,14.83-9.73a16.84,16.84,0,0,1,9.39.91c2.69,1,5.22,2.44,8,3.27,5.82,1.74,12.25,1.3,18.17.37.31,0,.18-.53-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M324.6,614.55c1.73.39,3.53.33,5.26.71a82.17,82.17,0,0,1,8.22,2.55c5.49,1.84,11,3.72,16.8,4.36a28.63,28.63,0,0,0,15-1.93c4.13-1.88,7.17-5.14,10-8.59,3.23-4,6.42-8.16,11.3-10.16.3-.12.17-.61-.13-.48-8.2,3.36-11.42,12.4-18.51,17.18-9.77,6.59-22.27,3.14-32.49-.26-3.12-1-6.23-2.13-9.4-3-1.92-.53-3.93-.44-5.87-.87-.31-.07-.44.41-.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M399.84,586.94c-6.65,6.88-13.71,13.43-20.77,19.9a49.75,49.75,0,0,1-5.35,4.37c-1.82,1.26-3.81,2.16-5.71,3.27a32.49,32.49,0,0,1-6,3c-1.28.43-2.56.87-3.86,1.25a35.81,35.81,0,0,1-12.2,1c-.33,0-.32.48,0,.5,6.92.41,14.18-.66,20.28-4.17,3.84-2.21,7.69-4.18,11.09-7.06,3.56-3,6.86-6.36,10.27-9.55,4.27-4,8.51-8,12.56-12.18.23-.23-.13-.58-.35-.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M401.41,585.74c-17.38,5.71-27.69,22.8-44.6,29.43a47.74,47.74,0,0,1-18,3.26c-.32,0-.32.49,0,.5a49.08,49.08,0,0,0,28.25-8.73c7.4-5,13.71-11.46,20.93-16.74a48.86,48.86,0,0,1,13.52-7.24c.3-.1.17-.58-.14-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M399.89,585.61c-9.16,2-17.63,6.19-25.56,11.12-4,2.5-7.94,5.2-11.8,8s-7.9,5.94-12.14,8.44c-5,3-10.91,5-16.77,3.61-.31-.07-.44.41-.13.48,9.92,2.3,18.56-4.35,26.1-9.8,7.89-5.71,15.9-11.37,24.67-15.67A70.76,70.76,0,0,1,400,586.1c.32-.07.18-.56-.13-.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M395.2,586.42A67.84,67.84,0,0,0,367,595.74c-8.6,5.18-15.33,13-24.21,17.75a15.36,15.36,0,0,1-7.23,1.94,23,23,0,0,1-7.15-.89.25.25,0,0,0-.13.48,21.1,21.1,0,0,0,11.55.34,24.42,24.42,0,0,0,5.93-3,67.06,67.06,0,0,0,5.83-4.23c4.08-3.24,7.94-6.76,12.24-9.72a66.8,66.8,0,0,1,31.42-11.46c.31,0,.32-.53,0-.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M327.38,612.78a36.18,36.18,0,0,0,25-9.12c3.69-3.22,7-6.86,11.05-9.65A43.12,43.12,0,0,1,376,588.14a48.25,48.25,0,0,1,15.81-1.81c.32,0,.32-.48,0-.5a47.2,47.2,0,0,0-25.82,6c-8.24,4.74-13.7,13-22.37,17.11a35.21,35.21,0,0,1-16.19,3.34c-.32,0-.32.49,0,.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M389.77,586.17c-8.76-2-18.29-4-26.94-.43-8.09,3.38-11.91,11.71-17.74,17.71a33.45,33.45,0,0,1-12.38,8.39c-.3.11-.17.59.13.48,7.63-2.77,13.23-8.66,18-15,2.68-3.54,5.39-7.23,9.21-9.62a25.17,25.17,0,0,1,12.85-3.46c5.66-.11,11.23,1.16,16.71,2.41a.25.25,0,0,0,.13-.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M476.13,103.2a43.48,43.48,0,0,0,15.46-11.49c4.23-5,6.57-11.79,12.14-15.49,5.26-3.5,12-3.93,18.1-2.82,7.14,1.3,13.65,4.76,20.2,7.68,6.91,3.09,15,6.33,22.49,3.54l-.24-.42c-9.24,9.14-14.8,22.37-26.76,28.57a35,35,0,0,1-20,3.34,46.81,46.81,0,0,1-19.9-7.34A58.06,58.06,0,0,0,487,103c-3.61-1.35-7.5-2.09-11-.16-.28.15,0,.58.26.43,3.84-2.1,8.11-.85,11.91.7a67.44,67.44,0,0,1,10,5.69,46.5,46.5,0,0,0,18.63,6.85,36.46,36.46,0,0,0,19.07-2.4c6-2.6,10.73-7.16,14.8-12.18,4.69-5.79,8.68-12.13,14-17.39.16-.16,0-.5-.25-.41-7.41,2.74-15.31-.46-22.1-3.49-6.49-2.89-12.92-6.3-19.95-7.66-5.51-1.07-11.51-.92-16.65,1.54a18.62,18.62,0,0,0-6.79,5.67c-2,2.65-3.54,5.63-5.47,8.34a42.79,42.79,0,0,1-17.55,14.22c-.3.13,0,.57.25.44Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M480.93,101.7a47,47,0,0,0,13.46-8c4.13-3.74,6.14-9.36,10.76-12.59,4.77-3.35,11.1-3.67,16.66-2.6,6.11,1.19,11.77,3.9,17.61,6a45.59,45.59,0,0,0,9.82,2.4,25.65,25.65,0,0,0,5.4.09,19.59,19.59,0,0,0,2.28-.34c.3-.06.59-.12.88-.2s.59-.15.87-.24c.42-.27.71-.19.86.23-.23-.22-.59.13-.35.35.74.69,1.71-1.31-.08-1.18a13.83,13.83,0,0,0-1.87.47,22.29,22.29,0,0,1-3,.45,26.79,26.79,0,0,1-5.37-.18c-7.13-.92-13.6-4.17-20.33-6.47-5.75-2-12-3.21-18-1.68a16.91,16.91,0,0,0-7.21,3.77c-2.26,2-3.85,4.68-5.59,7.15-4.1,5.83-10.47,9.45-16.93,12.16-.29.12-.17.61.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M562.32,85.65c-1.19,1.17-2.65,2-3.88,3.16a56.4,56.4,0,0,0-5.08,5.72c-3.48,4.14-6.88,8.39-11,12a30.76,30.76,0,0,1-13.58,7.29c-5.11,1.08-10-.17-14.78-1.85s-9.76-3.5-15-2.65c-.31.06-.18.54.14.49,5.12-.84,10,1,14.72,2.64,4.5,1.57,9.06,2.82,13.86,2.07,10.54-1.64,18-10.11,24.51-17.85,2-2.36,3.9-4.77,6-7,1.32-1.37,3-2.31,4.37-3.64.23-.22-.12-.57-.36-.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M484,102.42c4.84,1.23,9.71,2.29,14.6,3.28s9.62,2.07,14.46,3a47.16,47.16,0,0,0,6.91.79c2.38.06,4.76-.23,7.15-.28a36.86,36.86,0,0,0,9.6-1.64,36.39,36.39,0,0,0,10.73-5.46c.26-.18,0-.61-.26-.43-5.6,3.93-12.25,6.81-19.19,7-2.21.07-4.41.26-6.61.3a39.14,39.14,0,0,1-7.25-.61c-4.68-.78-9.32-1.88-14-2.82-5.37-1.08-10.72-2.22-16-3.58a.26.26,0,0,0-.14.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M482.38,102.12c9.56-2.28,19.29-.56,28.82.93,9.14,1.43,18.35,2.45,27.45.21a47.88,47.88,0,0,0,14.18-6.06c.28-.17,0-.61-.25-.43a48.08,48.08,0,0,1-27.23,7.34c-9.37-.28-18.51-2.66-27.84-3.41a48.9,48.9,0,0,0-15.26.93.25.25,0,0,0,.13.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M483.54,101.31c9.56-3.3,19.71-4.09,29.77-3.89,5,.09,10,.42,15,.79,5.21.38,10.5,1,15.71.32a21.3,21.3,0,0,0,12.47-5.59.25.25,0,0,0-.35-.35c-7.33,7.25-18.49,5.81-27.83,5.12-9.73-.72-19.54-1.3-29.27-.27a71.09,71.09,0,0,0-15.65,3.39c-.31.1-.18.58.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M488.08,99.48a67.33,67.33,0,0,1,29.8-6.8A125.16,125.16,0,0,1,534,94.33c5,.68,10.29,1.38,15.29.5,3.94-.69,7.91-3.58,10.51-6.52.21-.24-.14-.6-.36-.36-2.51,2.86-6.34,5.65-10.14,6.38-5.38,1-11.25.07-16.61-.69a110.46,110.46,0,0,0-15.07-1.46,67.41,67.41,0,0,0-29.83,6.87c-.29.14,0,.57.25.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M559.13,85.51A35.09,35.09,0,0,1,546,90.81a39.71,39.71,0,0,1-15.05-.48c-4.59-.92-9.13-2-13.84-2a44.4,44.4,0,0,0-13.15,1.93,48.81,48.81,0,0,0-13.5,6.51c-.26.18,0,.61.25.43a46.47,46.47,0,0,1,26.4-8.37c5,0,9.76,1.26,14.63,2.18a39.3,39.3,0,0,0,13.55.45A35.64,35.64,0,0,0,559.38,86c.27-.18,0-.61-.25-.44Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M492.67,96.35c6.23-6.1,13-12.61,21.88-14.26,9.07-1.68,17.14,3.93,25.69,5.91a33.42,33.42,0,0,0,14.25.42c.32-.06.19-.55-.13-.48a33.21,33.21,0,0,1-14.44-.53c-4.36-1.06-8.43-2.88-12.65-4.36a25,25,0,0,0-12.21-1.55,28.73,28.73,0,0,0-11.51,4.76A81.39,81.39,0,0,0,492.31,96c-.23.22.13.57.36.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M477.58,102.33a42.94,42.94,0,0,1-21.86-2.71c-3.23-1.31-6.21-3.16-9.46-4.43a18.64,18.64,0,0,0-8.86-1.27c-13.6,1.46-21.06,14.39-28,24.54-4.17,6.08-9.2,12.88-16.89,14.4a.25.25,0,0,0,0,.48c13.67,3.19,26.32,12.47,40.92,9.95,12.52-2.17,22.83-11.56,28.48-22.63,1.91-3.75,3.55-7.62,6.06-11,2.4-3.25,5.36-6.48,9.66-6.7.32,0,.32-.51,0-.5-6.41.32-10.59,7-13.24,12.09-1.64,3.17-3.05,6.47-4.94,9.51a44.13,44.13,0,0,1-6.11,7.77A38.69,38.69,0,0,1,436.68,142c-6.87,2-13.84,1.25-20.58-.87-7.92-2.5-15.35-6.4-23.47-8.29v.48c6-1.18,10.53-5.65,14.12-10.35,4.25-5.56,7.67-11.73,12.14-17.13,4.3-5.19,9.9-9.72,16.63-11.14a18.16,18.16,0,0,1,10.21.8c3.24,1.21,6.2,3,9.39,4.38a43.31,43.31,0,0,0,22.59,2.91c.32,0,.18-.52-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M472.64,103.58c-6.21,1-13.15,1.45-19.15-.8-3.24-1.21-6.27-3-9.68-3.73a16.84,16.84,0,0,0-8.68.5c-12.85,4.09-17.85,18-27.39,26.27a27,27,0,0,1-9.45,5.28c-.54.18-1.54.59-1.11,1.33a.6.6,0,0,0,1.11-.08.25.25,0,0,0-.48-.13c.25-.89,2.82-1.35,3.64-1.71a23.6,23.6,0,0,0,3.77-2.11,36.15,36.15,0,0,0,5.9-5.15c4.12-4.38,7.34-9.49,11.29-14s8.84-8.51,14.84-9.73a17,17,0,0,1,9.38.91c2.69,1,5.22,2.44,8,3.27,5.82,1.74,12.24,1.3,18.16.38.32,0,.19-.54-.13-.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M394.93,133.55c1.72.39,3.52.33,5.25.71a79.12,79.12,0,0,1,8.23,2.56c5.49,1.83,11,3.72,16.8,4.35a28.64,28.64,0,0,0,15-1.93c4.13-1.87,7.17-5.13,10-8.59,3.23-4,6.42-8.16,11.3-10.16.29-.12.16-.6-.13-.48-8.2,3.36-11.42,12.4-18.52,17.19-9.76,6.58-22.26,3.13-32.49-.27-3.11-1-6.22-2.12-9.4-3-1.91-.52-3.93-.44-5.86-.87-.31-.07-.45.41-.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M470.17,105.94c-6.66,6.88-13.72,13.43-20.77,19.9a49.86,49.86,0,0,1-5.36,4.37c-1.82,1.26-3.8,2.17-5.71,3.27a32.4,32.4,0,0,1-5.95,3c-1.28.43-2.56.88-3.86,1.25a35.85,35.85,0,0,1-12.21,1.05c-.32,0-.32.48,0,.5,6.93.42,14.19-.65,20.29-4.17,3.84-2.21,7.68-4.17,11.08-7.06,3.56-3,6.86-6.36,10.28-9.54,4.27-4,8.5-8,12.56-12.18.22-.23-.13-.59-.35-.36Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M471.74,104.74c-17.38,5.71-27.7,22.8-44.61,29.43a47.69,47.69,0,0,1-17.95,3.26c-.32,0-.32.49,0,.5a49.06,49.06,0,0,0,28.24-8.72c7.4-5,13.72-11.47,20.94-16.75a49.07,49.07,0,0,1,13.51-7.24c.31-.1.18-.58-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M470.21,104.62c-9.15,2-17.63,6.18-25.56,11.11-4,2.51-7.93,5.21-11.79,8s-7.9,5.94-12.15,8.45c-5,3-10.9,5-16.76,3.6-.31-.07-.45.41-.13.49,9.92,2.3,18.55-4.35,26.1-9.81,7.89-5.7,15.89-11.37,24.66-15.67a71.16,71.16,0,0,1,15.77-5.65c.31-.07.18-.55-.14-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M465.52,105.42a67.94,67.94,0,0,0-28.24,9.32c-8.61,5.18-15.34,13-24.21,17.75a15.39,15.39,0,0,1-7.23,1.94,22.78,22.78,0,0,1-7.16-.89c-.3-.09-.44.39-.13.49a21.1,21.1,0,0,0,11.55.33,23.9,23.9,0,0,0,5.93-3,67.21,67.21,0,0,0,5.84-4.23c4.07-3.24,7.94-6.76,12.23-9.72a66.91,66.91,0,0,1,31.42-11.46c.32,0,.32-.53,0-.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M397.71,131.78a36.15,36.15,0,0,0,25-9.12c3.69-3.21,7-6.86,11.05-9.65a43.32,43.32,0,0,1,12.52-5.87,48.07,48.07,0,0,1,15.82-1.8c.32,0,.32-.49,0-.5a47.22,47.22,0,0,0-25.83,6c-8.24,4.75-13.7,13-22.37,17.11a35.16,35.16,0,0,1-16.18,3.34c-.33,0-.32.49,0,.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M460.1,105.18c-8.76-2-18.3-4.06-26.94-.44-8.1,3.39-11.91,11.71-17.75,17.71A33.25,33.25,0,0,1,403,130.84c-.3.11-.17.6.14.48,7.63-2.77,13.22-8.66,18-15,2.68-3.54,5.4-7.23,9.22-9.62a25.13,25.13,0,0,1,12.84-3.45c5.66-.12,11.23,1.15,16.72,2.4.31.07.44-.41.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M648.86,54.29A43.37,43.37,0,0,0,664.32,42.8c4.23-5,6.57-11.78,12.14-15.49,5.26-3.5,12-3.93,18.1-2.82,7.14,1.3,13.65,4.76,20.2,7.69,6.91,3.08,15,6.32,22.49,3.53l-.24-.42c-9.24,9.15-14.8,22.38-26.76,28.57a35.09,35.09,0,0,1-20,3.35,47,47,0,0,1-19.9-7.35,58.06,58.06,0,0,0-10.66-5.76c-3.61-1.35-7.5-2.09-11-.16-.28.15,0,.59.26.43,3.84-2.09,8.11-.84,11.91.71a67.39,67.39,0,0,1,10,5.68,46.52,46.52,0,0,0,18.62,6.85,36.48,36.48,0,0,0,19.08-2.4c6-2.6,10.73-7.15,14.8-12.18,4.69-5.78,8.68-12.13,14-17.39.17-.16,0-.5-.24-.41-7.41,2.74-15.31-.45-22.1-3.49-6.49-2.89-12.92-6.3-19.95-7.66-5.51-1.07-11.51-.91-16.65,1.54a18.75,18.75,0,0,0-6.8,5.67c-2,2.65-3.53,5.64-5.46,8.35a43,43,0,0,1-17.55,14.22c-.3.13,0,.56.25.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M653.66,52.79a46.79,46.79,0,0,0,13.45-8c4.14-3.74,6.15-9.36,10.77-12.59s11.1-3.67,16.66-2.59c6.11,1.18,11.77,3.89,17.61,5.94a45,45,0,0,0,9.82,2.4,25.65,25.65,0,0,0,5.4.09,19.59,19.59,0,0,0,2.28-.34c.3,0,.59-.12.88-.19l.87-.25c.42-.27.71-.19.86.23-.23-.22-.59.14-.35.35.74.69,1.71-1.31-.08-1.17a12.44,12.44,0,0,0-1.87.46,20.83,20.83,0,0,1-3,.45,26.79,26.79,0,0,1-5.37-.18c-7.13-.92-13.61-4.17-20.33-6.47-5.75-2-12-3.21-18-1.68A16.91,16.91,0,0,0,676.05,33c-2.26,2-3.85,4.68-5.59,7.16C666.36,46,660,49.6,653.53,52.31c-.3.12-.17.61.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M735.05,36.74c-1.19,1.17-2.65,2-3.88,3.16a57.61,57.61,0,0,0-5.08,5.73c-3.48,4.13-6.88,8.38-11,11.94a30.76,30.76,0,0,1-13.58,7.29c-5.11,1.08-10-.17-14.78-1.85s-9.76-3.5-15-2.64c-.31.05-.18.53.14.48,5.12-.84,10,1,14.72,2.64,4.5,1.57,9.06,2.82,13.86,2.07,10.54-1.63,18-10.11,24.51-17.85,2-2.36,3.9-4.77,6-7,1.31-1.37,3-2.31,4.37-3.63.23-.23-.13-.58-.36-.36Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M656.74,53.51c4.84,1.23,9.71,2.29,14.6,3.28s9.62,2.07,14.46,3a45.84,45.84,0,0,0,6.91.79c2.38.06,4.76-.23,7.15-.28a36.13,36.13,0,0,0,20.33-7.09c.26-.19,0-.62-.26-.44-5.6,3.93-12.25,6.81-19.2,7-2.2.06-4.4.25-6.6.29a39.14,39.14,0,0,1-7.25-.61c-4.68-.78-9.32-1.88-14-2.82-5.37-1.08-10.73-2.22-16-3.58a.26.26,0,0,0-.14.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M655.11,53.21c9.56-2.28,19.29-.55,28.82.94,9.14,1.42,18.35,2.45,27.45.21a48.17,48.17,0,0,0,14.18-6.07c.28-.17,0-.61-.25-.43a48,48,0,0,1-27.23,7.34c-9.37-.27-18.51-2.66-27.84-3.4a48.6,48.6,0,0,0-15.26.93c-.31.07-.18.55.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M656.27,52.4c9.56-3.3,19.71-4.08,29.76-3.89,5,.1,10,.42,15,.79,5.21.38,10.49,1,15.71.32A21.24,21.24,0,0,0,729.24,44c.23-.22-.13-.58-.35-.35-7.33,7.25-18.49,5.81-27.83,5.12-9.73-.72-19.54-1.3-29.27-.27a71.17,71.17,0,0,0-15.66,3.39c-.3.1-.17.58.14.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M660.81,50.57a67.29,67.29,0,0,1,29.8-6.8,125.16,125.16,0,0,1,16.16,1.65c5,.68,10.29,1.38,15.29.51,3.94-.69,7.91-3.59,10.51-6.53.21-.24-.14-.6-.36-.36-2.51,2.86-6.34,5.65-10.14,6.38-5.38,1-11.26.08-16.61-.68a110.48,110.48,0,0,0-15.07-1.47,67.49,67.49,0,0,0-29.84,6.87c-.28.14,0,.57.26.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M731.86,36.61a35.31,35.31,0,0,1-13.15,5.29,39.71,39.71,0,0,1-15.05-.48c-4.59-.92-9.13-2-13.84-2a44.73,44.73,0,0,0-13.15,1.93,48.81,48.81,0,0,0-13.5,6.51c-.26.18,0,.62.25.43a46.47,46.47,0,0,1,26.4-8.37c5,0,9.76,1.26,14.63,2.18a39.3,39.3,0,0,0,13.55.45A35.64,35.64,0,0,0,732.11,37c.27-.18,0-.61-.25-.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M665.4,47.44c6.23-6.1,13-12.61,21.88-14.26,9.07-1.68,17.14,3.93,25.68,5.91a33.47,33.47,0,0,0,14.26.42c.32-.06.19-.54-.13-.48a33.21,33.21,0,0,1-14.44-.53c-4.36-1-8.43-2.88-12.65-4.36a25,25,0,0,0-12.21-1.55,28.69,28.69,0,0,0-11.51,4.77A80.65,80.65,0,0,0,665,47.09c-.23.22.13.58.36.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M650.31,53.42a42.94,42.94,0,0,1-21.86-2.71c-3.23-1.31-6.21-3.15-9.46-4.43A18.64,18.64,0,0,0,610.13,45c-13.6,1.46-21.07,14.4-28,24.54-4.17,6.09-9.2,12.88-16.89,14.4a.26.26,0,0,0,0,.49c13.67,3.18,26.31,12.46,40.92,9.94,12.52-2.17,22.83-11.56,28.48-22.63,1.91-3.74,3.55-7.62,6.06-11,2.4-3.25,5.36-6.48,9.66-6.7.32,0,.32-.51,0-.5-6.41.32-10.59,7-13.24,12.09-1.64,3.17-3.05,6.47-4.94,9.51a44.13,44.13,0,0,1-6.11,7.77,38.69,38.69,0,0,1-16.65,10.22c-6.87,2-13.84,1.25-20.58-.87-7.92-2.49-15.35-6.4-23.47-8.29v.49c6-1.19,10.53-5.66,14.12-10.35,4.25-5.57,7.67-11.74,12.14-17.14,4.3-5.19,9.9-9.71,16.63-11.14a18.16,18.16,0,0,1,10.21.8c3.24,1.22,6.2,3,9.38,4.38a43.36,43.36,0,0,0,22.6,2.91c.32,0,.18-.52-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M645.37,54.68c-6.21,1-13.15,1.44-19.15-.8-3.24-1.22-6.27-3-9.68-3.74a16.84,16.84,0,0,0-8.68.5C595,54.73,590,68.65,580.47,76.91A27.23,27.23,0,0,1,571,82.2c-.54.17-1.55.59-1.11,1.32a.6.6,0,0,0,1.11-.08.25.25,0,0,0-.48-.13c.25-.88,2.82-1.34,3.64-1.71A23.6,23.6,0,0,0,578,79.49a35.68,35.68,0,0,0,5.9-5.15c4.11-4.38,7.34-9.49,11.29-14S604,51.82,610,50.61a16.9,16.9,0,0,1,9.38.9c2.69,1,5.22,2.44,8,3.27,5.82,1.75,12.24,1.3,18.16.38.32,0,.18-.53-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M567.66,84.65c1.72.38,3.52.32,5.25.7a79.12,79.12,0,0,1,8.23,2.56c5.48,1.83,11,3.72,16.8,4.36a28.76,28.76,0,0,0,15-1.94c4.13-1.87,7.17-5.13,10-8.59,3.23-4,6.42-8.16,11.3-10.16.29-.12.16-.6-.14-.48-8.19,3.36-11.41,12.41-18.51,17.19-9.77,6.58-22.26,3.13-32.49-.27-3.12-1-6.23-2.12-9.4-3-1.91-.52-3.93-.43-5.86-.87-.31-.07-.45.42-.13.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M642.89,57c-6.65,6.88-13.71,13.44-20.76,19.9a49.86,49.86,0,0,1-5.36,4.37c-1.82,1.26-3.8,2.17-5.71,3.27a33.09,33.09,0,0,1-5.95,3c-1.28.42-2.56.87-3.86,1.24a35.85,35.85,0,0,1-12.21,1c-.32,0-.32.48,0,.5,6.93.42,14.19-.65,20.29-4.17,3.83-2.21,7.68-4.17,11.08-7,3.56-3,6.86-6.37,10.28-9.55,4.27-4,8.5-8,12.56-12.18.22-.23-.13-.59-.36-.36Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M644.47,55.83c-17.38,5.71-27.7,22.8-44.61,29.43a47.69,47.69,0,0,1-18,3.26.25.25,0,0,0,0,.5,49.06,49.06,0,0,0,28.24-8.72c7.4-5,13.72-11.47,20.94-16.75a49.07,49.07,0,0,1,13.51-7.24c.31-.1.18-.58-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M642.94,55.71c-9.15,2-17.63,6.19-25.56,11.11-4,2.51-7.93,5.21-11.79,8s-7.9,5.93-12.15,8.44c-5,3-10.9,5-16.76,3.61-.32-.08-.45.41-.14.48,9.93,2.3,18.56-4.35,26.1-9.81,7.9-5.7,15.9-11.37,24.67-15.67a71.16,71.16,0,0,1,15.77-5.65c.31-.07.18-.55-.14-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M638.25,56.51A67.81,67.81,0,0,0,610,65.84c-8.61,5.17-15.34,13-24.21,17.74a15.42,15.42,0,0,1-7.23,1.94,22.49,22.49,0,0,1-7.16-.89c-.3-.09-.44.39-.13.49a21.1,21.1,0,0,0,11.55.33,23.9,23.9,0,0,0,5.93-3,65.17,65.17,0,0,0,5.84-4.23c4.07-3.24,7.93-6.76,12.23-9.71A67.06,67.06,0,0,1,638.25,57c.32,0,.32-.53,0-.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M570.44,82.87a36.19,36.19,0,0,0,25-9.11c3.69-3.22,7-6.87,11-9.66A43.77,43.77,0,0,1,619,58.23a48,48,0,0,1,15.82-1.8c.32,0,.32-.49,0-.5a47.23,47.23,0,0,0-25.83,6c-8.24,4.74-13.7,13-22.37,17.1a35.16,35.16,0,0,1-16.18,3.34.25.25,0,0,0,0,.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M632.83,56.27c-8.76-2-18.3-4.06-26.94-.44-8.1,3.39-11.91,11.71-17.75,17.71a33.25,33.25,0,0,1-12.38,8.39c-.3.11-.17.6.14.49,7.63-2.78,13.22-8.67,18-15,2.68-3.54,5.4-7.22,9.22-9.62A25.1,25.1,0,0,1,616,54.35c5.66-.12,11.23,1.16,16.72,2.4.31.07.44-.41.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M605.17,40.52A43.37,43.37,0,0,0,589.71,52c-4.23,5-6.57,11.78-12.14,15.49-5.26,3.5-12,3.93-18.1,2.82-7.14-1.3-13.65-4.76-20.2-7.69-6.92-3.08-14.95-6.33-22.5-3.53l.25.42c9.24-9.15,14.8-22.38,26.75-28.57a35.13,35.13,0,0,1,20-3.35A47,47,0,0,1,583.65,35a58.66,58.66,0,0,0,10.66,5.76c3.61,1.35,7.5,2.09,11,.16a.25.25,0,0,0-.26-.43c-3.84,2.09-8.11.84-11.91-.71a67.77,67.77,0,0,1-10-5.68,46.36,46.36,0,0,0-18.63-6.85,36.25,36.25,0,0,0-19.07,2.4c-6,2.6-10.73,7.15-14.8,12.18-4.69,5.78-8.69,12.13-14,17.38-.16.17,0,.51.25.42,7.41-2.74,15.31.45,22.1,3.48,6.49,2.9,12.92,6.31,20,7.67,5.51,1.07,11.51.91,16.65-1.55a18.59,18.59,0,0,0,6.79-5.66c2-2.65,3.54-5.64,5.47-8.35A43,43,0,0,1,605.42,41c.3-.13,0-.56-.25-.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M600.37,42a47,47,0,0,0-13.46,8c-4.13,3.74-6.14,9.36-10.76,12.59s-11.1,3.67-16.66,2.59c-6.11-1.18-11.77-3.89-17.62-5.94a45.38,45.38,0,0,0-9.81-2.41,26.34,26.34,0,0,0-5.4-.08,22,22,0,0,0-2.28.33l-.88.2-.88.24c-.41.27-.7.2-.85-.22.23.22.59-.14.35-.36-.74-.68-1.72,1.32.08,1.18a13.78,13.78,0,0,0,1.87-.46,20.83,20.83,0,0,1,3-.45,26.79,26.79,0,0,1,5.37.18c7.13.91,13.6,4.17,20.33,6.47,5.75,2,12,3.21,18,1.68A16.91,16.91,0,0,0,578,61.81c2.26-2.05,3.85-4.69,5.59-7.16,4.1-5.83,10.47-9.45,16.93-12.15.29-.12.17-.61-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M519,58.07c1.2-1.17,2.66-2,3.89-3.16a57.61,57.61,0,0,0,5.08-5.73c3.48-4.13,6.88-8.39,11-12a31,31,0,0,1,13.58-7.29c5.11-1.07,10,.18,14.78,1.86s9.75,3.49,15,2.64c.31,0,.18-.53-.14-.48-5.12.83-10-1-14.72-2.64-4.5-1.57-9.06-2.82-13.86-2.07C543,30.88,535.51,39.36,529,47.1c-2,2.36-3.9,4.77-6,7-1.32,1.37-3,2.31-4.37,3.63a.25.25,0,0,0,.35.36Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M597.29,41.3c-4.84-1.23-9.71-2.29-14.6-3.28s-9.63-2.08-14.46-3a45.84,45.84,0,0,0-6.91-.79c-2.39-.06-4.76.23-7.15.27a36.26,36.26,0,0,0-20.33,7.1c-.26.19,0,.62.25.43,5.61-3.93,12.26-6.8,19.2-7,2.21-.06,4.41-.25,6.61-.29a39.14,39.14,0,0,1,7.25.61c4.68.78,9.32,1.88,14,2.82,5.37,1.08,10.73,2.22,16,3.57.31.08.45-.4.14-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M598.92,41.6c-9.56,2.27-19.29.55-28.82-.94-9.14-1.43-18.35-2.45-27.45-.21a48.34,48.34,0,0,0-14.19,6.07.25.25,0,0,0,.26.43A48.09,48.09,0,0,1,556,39.6c9.37.28,18.51,2.67,27.84,3.41a48.9,48.9,0,0,0,15.26-.93c.31-.07.18-.55-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M597.76,42.41C588.2,45.7,578.05,46.49,568,46.3c-5-.1-10-.42-15-.79-5.22-.39-10.5-1-15.71-.32a21.24,21.24,0,0,0-12.47,5.59c-.23.22.12.58.35.35,7.32-7.26,18.49-5.81,27.83-5.12,9.73.71,19.54,1.29,29.26.26a70.47,70.47,0,0,0,15.66-3.38c.31-.1.18-.59-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M593.22,44.24A67.32,67.32,0,0,1,563.42,51a125.14,125.14,0,0,1-16.16-1.64c-5-.68-10.29-1.39-15.29-.51-3.94.69-7.91,3.59-10.51,6.53-.21.24.14.6.36.35,2.51-2.85,6.34-5.64,10.14-6.37,5.38-1,11.25-.08,16.61.68a110.48,110.48,0,0,0,15.07,1.47,68.08,68.08,0,0,0,14.23-1.48,67.81,67.81,0,0,0,15.6-5.39c.29-.14,0-.57-.25-.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M522.17,58.2a35.42,35.42,0,0,1,13.15-5.3,39.71,39.71,0,0,1,15,.49c4.59.91,9.13,2,13.84,2a44.78,44.78,0,0,0,13.15-1.93A48.81,48.81,0,0,0,590.86,47c.26-.18,0-.62-.26-.44a46.4,46.4,0,0,1-26.39,8.38c-5,0-9.76-1.26-14.63-2.19A39.55,39.55,0,0,0,536,52.28a35.64,35.64,0,0,0-14.11,5.49c-.27.18,0,.61.25.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M588.63,47.37c-6.23,6.1-13,12.61-21.88,14.26-9.08,1.68-17.14-3.93-25.69-5.91a33.31,33.31,0,0,0-14.26-.42c-.31.06-.18.54.14.48a33.06,33.06,0,0,1,14.44.53c4.36,1,8.43,2.88,12.65,4.36a25.1,25.1,0,0,0,12.21,1.55,28.89,28.89,0,0,0,11.51-4.77A80.57,80.57,0,0,0,589,47.72c.23-.22-.12-.58-.35-.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M603.72,41.39a42.84,42.84,0,0,1,21.86,2.71c3.23,1.31,6.21,3.15,9.46,4.42a18.52,18.52,0,0,0,8.86,1.28c13.6-1.46,21.06-14.4,28-24.54,4.17-6.09,9.2-12.88,16.89-14.41.24,0,.23-.42,0-.48C675.13,7.19,662.48-2.1,647.88.43,635.35,2.6,625.05,12,619.4,23.05c-1.91,3.75-3.56,7.63-6.06,11-2.4,3.25-5.36,6.48-9.66,6.69-.32,0-.32.52,0,.5,6.41-.31,10.59-7,13.24-12.08,1.64-3.18,3.05-6.47,4.94-9.51A44.13,44.13,0,0,1,628,11.92,38.65,38.65,0,0,1,644.62,1.69c6.87-2,13.84-1.24,20.58.88,7.92,2.49,15.35,6.39,23.47,8.28v-.48c-6,1.19-10.53,5.66-14.12,10.35-4.25,5.57-7.67,11.73-12.14,17.13-4.3,5.19-9.9,9.72-16.63,11.15a18.24,18.24,0,0,1-10.21-.8c-3.24-1.22-6.2-3.05-9.39-4.39a43.51,43.51,0,0,0-22.59-2.91c-.32.05-.18.53.13.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M608.66,40.13c6.21-1,13.15-1.44,19.15.8,3.24,1.22,6.27,3,9.68,3.74a16.85,16.85,0,0,0,8.68-.5C659,40.08,664,26.16,673.56,17.9A27.07,27.07,0,0,1,683,12.61c.54-.18,1.54-.59,1.11-1.32a.6.6,0,0,0-1.11.07.25.25,0,0,0,.48.14c-.25.88-2.82,1.34-3.64,1.71a23.6,23.6,0,0,0-3.77,2.11,35.68,35.68,0,0,0-5.9,5.15c-4.12,4.38-7.34,9.49-11.29,14S650.05,43,644.05,44.2a16.8,16.8,0,0,1-9.38-.91c-2.69-1-5.22-2.43-8-3.26-5.82-1.75-12.24-1.31-18.16-.38-.32.05-.19.53.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M686.37,10.16c-1.72-.38-3.53-.32-5.25-.7a84.25,84.25,0,0,1-8.23-2.56c-5.49-1.83-11-3.72-16.8-4.36a28.65,28.65,0,0,0-15,1.94c-4.13,1.87-7.17,5.13-10,8.59-3.23,4-6.42,8.16-11.3,10.16-.29.12-.16.6.13.48,8.2-3.36,11.42-12.41,18.52-17.19,9.76-6.58,22.26-3.13,32.48.27,3.12,1,6.23,2.12,9.41,3,1.91.52,3.93.43,5.86.86.31.07.45-.41.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M611.13,37.77c6.66-6.88,13.72-13.43,20.77-19.89a49.86,49.86,0,0,1,5.36-4.37c1.82-1.26,3.8-2.17,5.71-3.27a32.45,32.45,0,0,1,5.95-3c1.28-.42,2.56-.87,3.86-1.24A36.1,36.1,0,0,1,665,5c.32,0,.32-.48,0-.5-6.93-.41-14.19.66-20.29,4.18-3.84,2.21-7.68,4.17-11.08,7-3.57,3-6.86,6.37-10.28,9.55-4.27,4-8.5,8-12.56,12.18-.22.23.13.59.35.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M609.56,39c17.38-5.71,27.7-22.8,44.61-29.43a47.66,47.66,0,0,1,18-3.26.25.25,0,0,0,0-.5,49,49,0,0,0-28.24,8.72c-7.4,5-13.72,11.47-20.94,16.75a49,49,0,0,1-13.51,7.23c-.31.1-.18.59.13.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M611.09,39.1c9.15-2.05,17.63-6.19,25.56-11.11,4-2.51,7.93-5.21,11.79-8s7.9-5.94,12.14-8.44c5-3,10.91-5,16.77-3.61.31.07.45-.41.13-.48-9.92-2.3-18.55,4.35-26.1,9.8C643.49,23,635.49,28.67,626.72,33A71.19,71.19,0,0,1,611,38.62c-.31.07-.18.55.14.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M615.78,38.3A68,68,0,0,0,644,29c8.61-5.17,15.34-13,24.21-17.74a15.39,15.39,0,0,1,7.23-1.94,22.68,22.68,0,0,1,7.15.88c.31.1.44-.38.14-.48a21.2,21.2,0,0,0-11.55-.34,24.42,24.42,0,0,0-5.93,3,69.39,69.39,0,0,0-5.84,4.23c-4.08,3.25-7.94,6.76-12.23,9.72a66.63,66.63,0,0,1-14,7.35,67.47,67.47,0,0,1-17.44,4.12c-.32,0-.32.53,0,.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M683.59,11.94a36.14,36.14,0,0,0-25,9.11c-3.69,3.22-7,6.87-11.05,9.66A43.52,43.52,0,0,1,635,36.58a48.25,48.25,0,0,1-15.81,1.8c-.32,0-.32.48,0,.5a47.25,47.25,0,0,0,25.83-6c8.23-4.74,13.69-13,22.37-17.1a35.16,35.16,0,0,1,16.18-3.34c.32,0,.32-.49,0-.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M621.2,38.54c8.76,2,18.3,4.06,26.94.44,8.1-3.39,11.91-11.71,17.75-17.72a33.49,33.49,0,0,1,12.38-8.39c.3-.1.17-.59-.14-.48-7.63,2.78-13.22,8.67-18,15-2.68,3.53-5.4,7.22-9.22,9.61a25,25,0,0,1-12.84,3.46c-5.66.11-11.23-1.16-16.72-2.4-.31-.07-.44.41-.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M432.44,89.43A43.37,43.37,0,0,0,417,100.92c-4.23,5-6.57,11.78-12.14,15.49-5.26,3.5-12,3.92-18.1,2.82-7.14-1.31-13.65-4.76-20.2-7.69-6.92-3.08-15-6.33-22.5-3.53l.25.42c9.24-9.15,14.8-22.38,26.76-28.57a35.09,35.09,0,0,1,20-3.35,46.83,46.83,0,0,1,19.9,7.35,58.66,58.66,0,0,0,10.66,5.76c3.61,1.34,7.5,2.08,11,.16a.25.25,0,0,0-.26-.43c-3.84,2.09-8.11.84-11.91-.71a68.29,68.29,0,0,1-10-5.68,46.36,46.36,0,0,0-18.63-6.85,36.26,36.26,0,0,0-19.07,2.4c-6,2.6-10.73,7.15-14.8,12.18-4.69,5.78-8.68,12.13-14,17.38-.16.17,0,.51.25.42,7.41-2.75,15.31.45,22.1,3.48,6.49,2.9,12.92,6.31,20,7.67,5.51,1.07,11.51.91,16.65-1.55a18.59,18.59,0,0,0,6.79-5.66c2-2.65,3.54-5.64,5.47-8.35a43,43,0,0,1,17.55-14.22c.3-.13,0-.56-.25-.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M427.64,90.92a47.23,47.23,0,0,0-13.46,8c-4.13,3.74-6.14,9.36-10.76,12.59-4.77,3.34-11.1,3.67-16.66,2.59-6.11-1.18-11.77-3.9-17.62-5.94a45.38,45.38,0,0,0-9.81-2.41,25.65,25.65,0,0,0-5.4-.08,22,22,0,0,0-2.28.33l-.88.2c-.3.07-.59.15-.88.24-.41.27-.7.2-.85-.22.23.21.59-.14.35-.36-.74-.68-1.72,1.32.08,1.18a13.78,13.78,0,0,0,1.87-.46,19.56,19.56,0,0,1,3-.45,26.07,26.07,0,0,1,5.37.18c7.13.91,13.6,4.17,20.33,6.47,5.75,2,11.95,3.2,18,1.67a16.7,16.7,0,0,0,7.21-3.77c2.26-2,3.85-4.68,5.59-7.15,4.1-5.83,10.47-9.45,16.93-12.15.29-.13.17-.61-.13-.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M346.25,107c1.19-1.16,2.65-2,3.88-3.15a57.61,57.61,0,0,0,5.08-5.73c3.48-4.14,6.88-8.39,11-12a30.86,30.86,0,0,1,13.58-7.29c5.11-1.08,10,.17,14.78,1.86s9.75,3.49,15,2.64c.31,0,.18-.53-.14-.48-5.12.83-10-1-14.72-2.65-4.5-1.57-9.06-2.81-13.86-2.07-10.54,1.64-18,10.12-24.51,17.86-2,2.35-3.9,4.77-6,7-1.32,1.36-3,2.31-4.37,3.63a.25.25,0,0,0,.36.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M424.56,90.21c-4.84-1.23-9.71-2.29-14.6-3.29S400.33,84.85,395.5,84a45.84,45.84,0,0,0-6.91-.79c-2.38-.06-4.76.23-7.15.27a36.26,36.26,0,0,0-20.33,7.1c-.26.18,0,.62.26.43,5.6-3.93,12.25-6.8,19.19-7,2.21-.06,4.41-.25,6.61-.3a39.15,39.15,0,0,1,7.25.62c4.68.78,9.32,1.88,14,2.81,5.37,1.09,10.72,2.23,16,3.58.31.08.45-.4.14-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M426.19,90.51c-9.56,2.27-19.29.55-28.82-.94-9.14-1.43-18.35-2.45-27.45-.21a47.91,47.91,0,0,0-14.18,6.07.25.25,0,0,0,.25.43,48.09,48.09,0,0,1,27.23-7.35c9.37.28,18.51,2.66,27.84,3.41A48.9,48.9,0,0,0,426.32,91c.31-.07.18-.56-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M425,91.32c-9.56,3.29-19.71,4.08-29.77,3.89-5-.1-10-.43-15-.79-5.21-.39-10.5-1-15.71-.32a21.23,21.23,0,0,0-12.47,5.58c-.23.23.12.59.35.36,7.32-7.26,18.49-5.81,27.83-5.12,9.73.71,19.54,1.29,29.27.26a70.5,70.5,0,0,0,15.65-3.38c.31-.11.18-.59-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M420.49,93.15a67.32,67.32,0,0,1-29.8,6.79,125.14,125.14,0,0,1-16.16-1.64c-5-.68-10.29-1.39-15.29-.51-3.94.69-7.91,3.58-10.51,6.53-.21.24.14.59.36.35,2.51-2.85,6.34-5.64,10.14-6.37,5.38-1,11.25-.08,16.61.68a108.9,108.9,0,0,0,15.07,1.46A67.34,67.34,0,0,0,405.14,99a67.81,67.81,0,0,0,15.6-5.39c.29-.14,0-.57-.25-.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M349.44,107.11a35.42,35.42,0,0,1,13.15-5.3,39.71,39.71,0,0,1,15.05.48c4.59.92,9.13,2,13.84,2a44.46,44.46,0,0,0,13.15-1.94,48.79,48.79,0,0,0,13.5-6.5c.26-.19,0-.62-.25-.44a46.43,46.43,0,0,1-26.4,8.38c-5,0-9.76-1.26-14.63-2.19a39.3,39.3,0,0,0-13.55-.44,35.64,35.64,0,0,0-14.11,5.49c-.27.18,0,.61.25.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M415.9,96.28c-6.23,6.1-13,12.61-21.88,14.26-9.07,1.68-17.14-3.93-25.69-5.91a33.42,33.42,0,0,0-14.25-.43c-.32.07-.19.55.13.49a32.91,32.91,0,0,1,14.44.53c4.36,1,8.43,2.88,12.65,4.35a25,25,0,0,0,12.21,1.56A28.89,28.89,0,0,0,405,106.36a80.65,80.65,0,0,0,11.24-9.73c.23-.23-.13-.58-.36-.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M431,90.29A43,43,0,0,1,452.85,93c3.23,1.31,6.21,3.15,9.46,4.42a18.52,18.52,0,0,0,8.86,1.28c13.6-1.46,21.06-14.4,28-24.55,4.17-6.08,9.2-12.88,16.89-14.4a.25.25,0,0,0,0-.48c-13.67-3.18-26.32-12.47-40.92-9.94C462.63,51.51,452.32,60.89,446.67,72c-1.91,3.75-3.56,7.63-6.06,11-2.4,3.25-5.36,6.48-9.66,6.69-.32,0-.32.52,0,.5,6.41-.31,10.59-7,13.24-12.08,1.64-3.18,3.05-6.47,4.94-9.51a44.57,44.57,0,0,1,6.11-7.78A38.79,38.79,0,0,1,471.89,50.6c6.87-2,13.84-1.24,20.58.88,7.92,2.49,15.35,6.39,23.47,8.28v-.48c-6,1.19-10.53,5.66-14.12,10.35-4.25,5.56-7.67,11.73-12.14,17.13-4.3,5.19-9.9,9.72-16.63,11.15a18.16,18.16,0,0,1-10.21-.81c-3.24-1.21-6.2-3-9.39-4.38a43.41,43.41,0,0,0-22.59-2.91c-.32,0-.18.53.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M435.93,89c6.21-1,13.15-1.45,19.15.8,3.24,1.22,6.27,3,9.68,3.74a16.85,16.85,0,0,0,8.68-.5c12.85-4.09,17.85-18,27.39-26.27a27.07,27.07,0,0,1,9.45-5.29c.54-.18,1.54-.59,1.11-1.32a.6.6,0,0,0-1.11.07.25.25,0,0,0,.48.14c-.25.88-2.82,1.34-3.64,1.7a24.46,24.46,0,0,0-3.77,2.12,35.68,35.68,0,0,0-5.9,5.15c-4.12,4.38-7.34,9.49-11.29,14s-8.84,8.51-14.84,9.72a16.81,16.81,0,0,1-9.38-.91c-2.69-1-5.22-2.44-8-3.26-5.82-1.75-12.24-1.31-18.16-.38-.32,0-.19.53.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M513.64,59.07c-1.72-.39-3.53-.33-5.25-.7a84.25,84.25,0,0,1-8.23-2.56c-5.49-1.83-11-3.72-16.8-4.36a28.65,28.65,0,0,0-15,1.94c-4.13,1.87-7.17,5.13-10,8.58-3.23,4-6.42,8.16-11.3,10.16-.29.12-.16.61.13.49,8.2-3.36,11.42-12.41,18.52-17.19,9.76-6.59,22.26-3.13,32.49.27,3.11,1,6.22,2.12,9.4,3,1.91.52,3.93.43,5.86.86.31.07.45-.41.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M438.4,86.68c6.66-6.88,13.72-13.43,20.77-19.9a52.39,52.39,0,0,1,5.36-4.37c1.82-1.25,3.8-2.16,5.71-3.26a31.82,31.82,0,0,1,5.95-3c1.28-.43,2.56-.87,3.86-1.24a36.1,36.1,0,0,1,12.21-1.06c.32,0,.32-.48,0-.5C485.33,53,478.07,54,472,57.54c-3.84,2.22-7.68,4.18-11.08,7.06-3.57,3-6.86,6.37-10.28,9.55-4.27,4-8.5,8-12.56,12.18-.22.23.13.58.35.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M436.83,87.89c17.38-5.71,27.7-22.8,44.61-29.43a47.46,47.46,0,0,1,17.95-3.26.25.25,0,0,0,0-.5,49,49,0,0,0-28.24,8.72c-7.4,5-13.72,11.47-20.94,16.74A48.8,48.8,0,0,1,436.7,87.4c-.31.1-.18.59.13.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M438.36,88C447.51,86,456,81.82,463.92,76.89c4-2.5,7.93-5.2,11.79-8,4-2.86,7.9-5.94,12.15-8.44,5-3,10.9-5,16.76-3.61.31.07.45-.41.13-.48-9.92-2.3-18.55,4.35-26.1,9.8-7.89,5.71-15.89,11.37-24.66,15.67a71.19,71.19,0,0,1-15.77,5.66c-.31.07-.18.55.14.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M443.05,87.2a67.79,67.79,0,0,0,28.24-9.32c8.61-5.17,15.34-13,24.21-17.74a15.28,15.28,0,0,1,7.23-2,23,23,0,0,1,7.15.89.25.25,0,0,0,.14-.48,21.2,21.2,0,0,0-11.55-.34,24.6,24.6,0,0,0-5.93,3,69.39,69.39,0,0,0-5.84,4.23c-4.07,3.24-7.94,6.76-12.23,9.72a66.63,66.63,0,0,1-14,7.35,67.45,67.45,0,0,1-17.44,4.11c-.32,0-.32.54,0,.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M510.86,60.85a36.14,36.14,0,0,0-25,9.11c-3.69,3.22-7,6.87-11.05,9.65a43.35,43.35,0,0,1-12.52,5.88,48.34,48.34,0,0,1-15.82,1.8c-.32,0-.32.48,0,.5a47.25,47.25,0,0,0,25.83-6c8.24-4.74,13.7-13,22.37-17.11a35.28,35.28,0,0,1,16.18-3.33c.33,0,.32-.49,0-.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M448.47,87.45c8.76,2,18.3,4,26.94.44,8.1-3.39,11.91-11.71,17.75-17.72a33.37,33.37,0,0,1,12.38-8.39c.3-.11.17-.59-.14-.48-7.63,2.78-13.22,8.67-18,15-2.68,3.53-5.4,7.22-9.22,9.61a25.14,25.14,0,0,1-12.84,3.46c-5.66.11-11.23-1.16-16.72-2.4-.31-.08-.44.41-.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M617.9,102.72a43.37,43.37,0,0,0-15.46,11.49c-4.22,5-6.57,11.78-12.14,15.49-5.26,3.5-12,3.93-18.1,2.82-7.13-1.3-13.64-4.76-20.2-7.69-6.91-3.08-15-6.32-22.49-3.53l.24.42c9.24-9.15,14.8-22.38,26.76-28.57a35.09,35.09,0,0,1,20-3.35,47,47,0,0,1,19.9,7.35,57.82,57.82,0,0,0,10.67,5.76c3.6,1.35,7.49,2.09,11,.16.28-.15,0-.59-.25-.43-3.85,2.09-8.11.84-11.92-.71a67.39,67.39,0,0,1-10-5.68,46.52,46.52,0,0,0-18.62-6.85,36.48,36.48,0,0,0-19.08,2.4c-6,2.6-10.73,7.15-14.8,12.18-4.68,5.78-8.68,12.13-14,17.39-.17.16,0,.5.24.41,7.41-2.74,15.31.45,22.11,3.49,6.48,2.89,12.91,6.3,19.94,7.66,5.51,1.07,11.52.91,16.65-1.54a18.67,18.67,0,0,0,6.8-5.67c2-2.65,3.53-5.64,5.46-8.35a43,43,0,0,1,17.55-14.22c.3-.13,0-.56-.25-.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M613.1,104.22a46.79,46.79,0,0,0-13.45,8c-4.14,3.74-6.15,9.36-10.77,12.59s-11.1,3.67-16.66,2.59c-6.11-1.18-11.77-3.89-17.61-5.94a45.06,45.06,0,0,0-9.81-2.4,25.74,25.74,0,0,0-5.41-.09,22,22,0,0,0-2.28.33l-.88.2-.87.24c-.42.28-.71.2-.86-.22.23.22.59-.14.35-.35-.74-.69-1.71,1.31.08,1.17a12.44,12.44,0,0,0,1.87-.46,20.83,20.83,0,0,1,3-.45,26.79,26.79,0,0,1,5.37.18c7.13.91,13.61,4.17,20.33,6.47,5.75,2,12,3.21,18,1.68a16.91,16.91,0,0,0,7.21-3.77c2.26-2.05,3.85-4.69,5.59-7.16,4.1-5.83,10.47-9.44,16.93-12.15.3-.12.17-.61-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M531.71,120.27c1.19-1.17,2.65-2,3.88-3.16a57.61,57.61,0,0,0,5.08-5.73c3.48-4.13,6.88-8.38,11-11.94a30.76,30.76,0,0,1,13.58-7.29c5.11-1.08,10,.17,14.78,1.85s9.76,3.5,15,2.64c.31,0,.18-.53-.14-.48-5.12.84-10-1-14.72-2.64-4.5-1.57-9.06-2.82-13.86-2.07-10.54,1.63-18,10.11-24.5,17.85-2,2.36-3.91,4.77-6,7-1.32,1.37-3,2.31-4.38,3.63-.23.23.13.58.36.36Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M610,103.5c-4.84-1.23-9.71-2.29-14.6-3.28s-9.62-2.07-14.46-3a45.84,45.84,0,0,0-6.91-.79c-2.38-.06-4.76.23-7.15.28a36.13,36.13,0,0,0-20.33,7.09c-.26.19,0,.62.26.44,5.6-3.93,12.25-6.81,19.2-7,2.2-.06,4.4-.25,6.6-.29a39.32,39.32,0,0,1,7.26.61c4.67.78,9.31,1.88,14,2.82,5.37,1.08,10.73,2.22,16,3.58.32.07.45-.41.14-.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M611.65,103.8c-9.56,2.28-19.29.55-28.82-.94-9.14-1.42-18.35-2.45-27.45-.21a48.28,48.28,0,0,0-14.18,6.07c-.27.17,0,.61.25.43a48,48,0,0,1,27.23-7.34c9.37.27,18.51,2.66,27.84,3.4a48.6,48.6,0,0,0,15.26-.93c.32-.07.18-.55-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M610.49,104.61c-9.56,3.3-19.71,4.08-29.76,3.89-5-.1-10-.42-15-.79-5.21-.38-10.49-1-15.71-.32A21.27,21.27,0,0,0,537.52,113c-.23.22.13.58.36.35,7.32-7.25,18.48-5.81,27.82-5.12,9.73.72,19.54,1.3,29.27.27a71.05,71.05,0,0,0,15.66-3.39c.3-.1.17-.59-.14-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M606,106.44a67.29,67.29,0,0,1-29.8,6.8A125.34,125.34,0,0,1,560,111.59c-5-.68-10.29-1.38-15.29-.51-3.94.69-7.91,3.59-10.51,6.53-.21.24.14.6.36.36,2.51-2.86,6.34-5.65,10.14-6.38,5.38-1,11.26-.08,16.61.68a110.48,110.48,0,0,0,15.07,1.47,67.49,67.49,0,0,0,29.84-6.87c.29-.14,0-.57-.26-.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M534.9,120.4a35.31,35.31,0,0,1,13.15-5.29,39.71,39.71,0,0,1,15.05.48c4.59.92,9.13,2,13.84,2a44.73,44.73,0,0,0,13.15-1.93,48.81,48.81,0,0,0,13.5-6.51c.26-.18,0-.62-.25-.43a46.47,46.47,0,0,1-26.4,8.37c-5,0-9.76-1.26-14.63-2.19a39.55,39.55,0,0,0-13.55-.44A35.69,35.69,0,0,0,534.65,120c-.27.18,0,.61.25.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M601.36,109.57c-6.23,6.1-13,12.61-21.88,14.26-9.07,1.68-17.14-3.93-25.68-5.91a33.47,33.47,0,0,0-14.26-.42c-.32.06-.18.54.13.48a33.06,33.06,0,0,1,14.44.53c4.37,1,8.43,2.88,12.65,4.36A25,25,0,0,0,579,124.42a28.69,28.69,0,0,0,11.51-4.77,80.65,80.65,0,0,0,11.24-9.73c.23-.22-.13-.58-.36-.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M616.46,103.59a42.92,42.92,0,0,1,21.85,2.71c3.23,1.31,6.21,3.15,9.46,4.43a18.64,18.64,0,0,0,8.86,1.27c13.6-1.46,21.07-14.4,28-24.54,4.17-6.09,9.2-12.88,16.89-14.4a.26.26,0,0,0,0-.49c-13.67-3.18-26.31-12.46-40.92-9.94-12.52,2.17-22.83,11.56-28.48,22.63-1.91,3.74-3.55,7.62-6.06,11-2.4,3.25-5.36,6.48-9.66,6.7-.32,0-.32.51,0,.5,6.41-.32,10.59-7,13.24-12.09,1.65-3.17,3.05-6.47,4.94-9.51a44.13,44.13,0,0,1,6.11-7.77A38.69,38.69,0,0,1,657.35,63.9c6.87-2,13.84-1.25,20.58.87,7.92,2.49,15.35,6.4,23.47,8.29v-.49c-6,1.19-10.52,5.66-14.11,10.35-4.26,5.57-7.68,11.73-12.15,17.14-4.3,5.19-9.9,9.71-16.63,11.14a18.16,18.16,0,0,1-10.21-.8c-3.24-1.22-6.2-3.05-9.38-4.39a43.45,43.45,0,0,0-22.6-2.9c-.32,0-.18.52.14.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M621.39,102.33c6.21-1,13.15-1.44,19.15.8,3.24,1.22,6.27,3,9.68,3.74a16.84,16.84,0,0,0,8.68-.5c12.85-4.09,17.85-18,27.39-26.27a27.19,27.19,0,0,1,9.46-5.29c.53-.17,1.54-.59,1.1-1.32a.6.6,0,0,0-1.11.08.25.25,0,0,0,.48.13c-.25.88-2.82,1.34-3.64,1.71a24,24,0,0,0-3.77,2.11,35.68,35.68,0,0,0-5.9,5.15c-4.11,4.38-7.34,9.49-11.29,14s-8.84,8.51-14.84,9.72a16.9,16.9,0,0,1-9.38-.9c-2.69-1-5.22-2.44-8-3.27-5.81-1.75-12.24-1.3-18.16-.38-.32.05-.18.53.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M699.11,72.36c-1.73-.38-3.53-.32-5.26-.7a79.88,79.88,0,0,1-8.23-2.56c-5.48-1.83-11-3.72-16.8-4.36a28.66,28.66,0,0,0-15,1.94c-4.13,1.87-7.16,5.13-10,8.59-3.24,4-6.43,8.16-11.31,10.16-.29.12-.16.6.14.48,8.19-3.36,11.42-12.41,18.51-17.19,9.77-6.58,22.26-3.13,32.49.27,3.12,1,6.23,2.12,9.4,3,1.91.52,3.93.43,5.86.87.32.07.45-.42.14-.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M623.87,100c6.65-6.88,13.71-13.44,20.76-19.9A49.86,49.86,0,0,1,650,75.71c1.82-1.26,3.8-2.17,5.71-3.27a33.42,33.42,0,0,1,5.95-3c1.29-.42,2.56-.87,3.86-1.24a35.85,35.85,0,0,1,12.21-1c.32,0,.32-.48,0-.5-6.93-.42-14.19.65-20.28,4.17-3.84,2.21-7.69,4.17-11.09,7-3.56,3-6.86,6.37-10.28,9.55-4.27,4-8.5,8-12.56,12.18-.22.23.13.59.36.36Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M622.29,101.18c17.38-5.71,27.7-22.8,44.61-29.43a47.69,47.69,0,0,1,18-3.26.25.25,0,0,0,0-.5,49.06,49.06,0,0,0-28.24,8.72c-7.4,5-13.71,11.47-20.94,16.75a49.07,49.07,0,0,1-13.51,7.24c-.31.1-.18.58.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M623.82,101.3c9.15-2,17.63-6.19,25.56-11.11,4-2.51,7.93-5.21,11.8-8s7.89-5.93,12.14-8.44c5-3,10.9-5,16.76-3.61.32.08.45-.41.14-.48-9.93-2.3-18.56,4.35-26.1,9.81-7.89,5.7-15.9,11.37-24.67,15.67a71.16,71.16,0,0,1-15.77,5.65c-.31.07-.18.55.14.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M628.51,100.5a67.81,67.81,0,0,0,28.24-9.33c8.61-5.17,15.34-13,24.21-17.74a15.42,15.42,0,0,1,7.23-1.94,22.49,22.49,0,0,1,7.16.89c.31.09.44-.39.13-.49a21.1,21.1,0,0,0-11.55-.33,23.9,23.9,0,0,0-5.93,3,65.17,65.17,0,0,0-5.84,4.23c-4.07,3.24-7.93,6.76-12.23,9.71a66.63,66.63,0,0,1-14,7.35A67.07,67.07,0,0,1,628.51,100c-.32,0-.32.53,0,.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M696.33,74.14a36.19,36.19,0,0,0-25,9.11c-3.69,3.22-7,6.87-11,9.66a43.77,43.77,0,0,1-12.53,5.87,48.28,48.28,0,0,1-15.82,1.8c-.32,0-.32.49,0,.5a47.23,47.23,0,0,0,25.83-6c8.24-4.74,13.7-13,22.37-17.1a35.18,35.18,0,0,1,16.19-3.34c.32,0,.32-.49,0-.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M633.93,100.74c8.76,2,18.3,4.06,26.94.44,8.1-3.39,11.92-11.71,17.75-17.71A33.25,33.25,0,0,1,691,75.08c.3-.11.17-.6-.14-.49-7.63,2.78-13.22,8.67-18,15-2.68,3.54-5.39,7.22-9.22,9.62a25.1,25.1,0,0,1-12.84,3.45c-5.66.12-11.23-1.16-16.72-2.4-.31-.07-.44.41-.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M445.17,151.63a43.37,43.37,0,0,0-15.46,11.49c-4.22,5-6.57,11.78-12.14,15.49-5.26,3.5-12,3.93-18.1,2.82-7.13-1.3-13.64-4.76-20.2-7.69-6.91-3.08-14.95-6.33-22.49-3.53l.24.42c9.24-9.15,14.8-22.38,26.76-28.57a35.09,35.09,0,0,1,20-3.35,47,47,0,0,1,19.9,7.35,58.75,58.75,0,0,0,10.67,5.76c3.6,1.35,7.49,2.09,11,.16a.25.25,0,0,0-.25-.43c-3.85,2.09-8.11.84-11.91-.71a68.39,68.39,0,0,1-10-5.68,46.37,46.37,0,0,0-18.62-6.85,36.38,36.38,0,0,0-19.08,2.4c-6,2.6-10.73,7.15-14.8,12.18-4.68,5.78-8.68,12.13-14,17.38-.17.17,0,.51.24.42,7.41-2.74,15.31.45,22.11,3.48,6.48,2.9,12.91,6.31,19.94,7.67,5.51,1.07,11.52.91,16.65-1.55a18.54,18.54,0,0,0,6.8-5.66c2-2.65,3.53-5.64,5.46-8.35a42.91,42.91,0,0,1,17.56-14.22c.29-.13,0-.56-.26-.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M440.37,153.13a46.79,46.79,0,0,0-13.45,8c-4.14,3.74-6.15,9.36-10.77,12.59s-11.1,3.67-16.66,2.59c-6.11-1.18-11.77-3.89-17.61-5.94a45.53,45.53,0,0,0-9.81-2.41,26.35,26.35,0,0,0-5.4-.08,21.77,21.77,0,0,0-2.29.33l-.88.2-.87.24c-.42.27-.71.2-.86-.22.23.22.59-.14.35-.36-.74-.68-1.71,1.32.08,1.18a13.43,13.43,0,0,0,1.87-.46,20.83,20.83,0,0,1,3-.45,26.79,26.79,0,0,1,5.37.18c7.13.91,13.61,4.17,20.34,6.47,5.74,2,11.94,3.21,17.95,1.68a16.91,16.91,0,0,0,7.21-3.77c2.26-2,3.85-4.69,5.59-7.16,4.11-5.83,10.47-9.45,16.93-12.15.3-.12.17-.61-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M359,169.18c1.19-1.17,2.65-2,3.88-3.16a57.61,57.61,0,0,0,5.08-5.73c3.48-4.13,6.88-8.39,11-11.95a31,31,0,0,1,13.58-7.29c5.11-1.07,10,.18,14.78,1.86s9.76,3.49,15,2.64c.32-.05.18-.53-.13-.48-5.13.84-10-1-14.73-2.64-4.5-1.57-9-2.82-13.85-2.07C383,142,375.51,150.47,369,158.21c-2,2.36-3.91,4.77-6,7-1.32,1.37-3,2.31-4.38,3.63-.23.23.13.58.36.36Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M437.29,152.41c-4.83-1.23-9.71-2.29-14.6-3.28s-9.62-2.08-14.46-2.95a45.84,45.84,0,0,0-6.91-.79c-2.38-.06-4.76.23-7.15.27a37,37,0,0,0-9.6,1.64,36.45,36.45,0,0,0-10.72,5.46c-.27.19,0,.62.25.43,5.6-3.93,12.26-6.8,19.2-7,2.2-.06,4.4-.25,6.6-.29a39.32,39.32,0,0,1,7.26.61c4.67.78,9.31,1.88,14,2.82,5.37,1.08,10.73,2.22,16,3.57a.25.25,0,0,0,.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M438.92,152.71c-9.56,2.27-19.29.55-28.82-.94-9.14-1.43-18.35-2.45-27.45-.21a48.28,48.28,0,0,0-14.18,6.07c-.27.17,0,.6.25.43A48.11,48.11,0,0,1,396,150.71c9.37.28,18.51,2.67,27.84,3.41a48.86,48.86,0,0,0,15.26-.93c.32-.07.18-.55-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M437.76,153.52c-9.56,3.29-19.71,4.08-29.76,3.89-5-.1-10-.42-15-.79-5.21-.39-10.49-1-15.71-.32a21.27,21.27,0,0,0-12.47,5.59c-.23.22.13.58.36.35,7.32-7.26,18.48-5.81,27.82-5.12,9.73.71,19.54,1.29,29.27.26A70.47,70.47,0,0,0,437.9,154c.3-.1.17-.59-.14-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M433.22,155.35a67.29,67.29,0,0,1-29.8,6.79,125.32,125.32,0,0,1-16.16-1.64c-5-.68-10.29-1.39-15.29-.51-3.94.69-7.91,3.59-10.51,6.53-.21.24.15.6.36.35,2.51-2.85,6.34-5.64,10.14-6.37,5.38-1,11.26-.08,16.61.68a110.48,110.48,0,0,0,15.07,1.47,67.49,67.49,0,0,0,29.84-6.87c.29-.14,0-.57-.26-.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M362.17,169.31a35.49,35.49,0,0,1,13.15-5.3,39.71,39.71,0,0,1,15.05.49c4.59.91,9.13,2,13.84,2a44.73,44.73,0,0,0,13.15-1.93,48.81,48.81,0,0,0,13.5-6.51c.26-.18,0-.62-.25-.44a46.41,46.41,0,0,1-26.4,8.38c-5,0-9.76-1.26-14.63-2.19a39.55,39.55,0,0,0-13.55-.44,35.69,35.69,0,0,0-14.11,5.49c-.27.18,0,.61.25.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M428.63,158.48c-6.22,6.1-13,12.61-21.88,14.26-9.07,1.68-17.14-3.93-25.68-5.91a33.31,33.31,0,0,0-14.26-.42c-.32.06-.18.54.13.48a33.11,33.11,0,0,1,14.45.53c4.36,1.05,8.42,2.88,12.64,4.36a25.12,25.12,0,0,0,12.21,1.55,28.83,28.83,0,0,0,11.51-4.77A80.65,80.65,0,0,0,429,158.83c.23-.22-.13-.58-.36-.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M443.73,152.5a42.81,42.81,0,0,1,21.85,2.71c3.23,1.31,6.21,3.15,9.46,4.42a18.52,18.52,0,0,0,8.86,1.28c13.6-1.46,21.07-14.4,28-24.54,4.17-6.09,9.21-12.88,16.89-14.41a.25.25,0,0,0,0-.48c-13.67-3.18-26.31-12.47-40.92-9.94-12.52,2.17-22.83,11.56-28.48,22.62-1.91,3.75-3.55,7.63-6.06,11-2.4,3.25-5.36,6.48-9.66,6.69-.32,0-.32.52,0,.5,6.41-.31,10.59-7,13.24-12.08,1.65-3.18,3.05-6.47,4.94-9.51A44.13,44.13,0,0,1,468,123a38.71,38.71,0,0,1,16.65-10.23c6.87-2,13.84-1.24,20.58.88,7.92,2.49,15.35,6.39,23.47,8.28v-.48c-6,1.19-10.52,5.66-14.11,10.35-4.26,5.57-7.67,11.73-12.15,17.14-4.3,5.18-9.9,9.71-16.63,11.14a18.25,18.25,0,0,1-10.21-.8c-3.24-1.22-6.2-3.05-9.38-4.39a43.56,43.56,0,0,0-22.6-2.91c-.31.05-.18.53.14.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M448.66,151.24c6.21-1,13.15-1.44,19.15.8,3.24,1.22,6.27,3,9.68,3.74a16.83,16.83,0,0,0,8.68-.5c12.85-4.09,17.85-18,27.39-26.27a27,27,0,0,1,9.46-5.29c.53-.18,1.54-.59,1.1-1.32a.6.6,0,0,0-1.11.07.25.25,0,0,0,.48.14c-.25.88-2.82,1.34-3.64,1.71a24,24,0,0,0-3.77,2.11,35.68,35.68,0,0,0-5.9,5.15c-4.11,4.38-7.34,9.49-11.29,14s-8.84,8.51-14.84,9.72a16.81,16.81,0,0,1-9.38-.91c-2.69-1-5.22-2.43-8-3.26-5.81-1.75-12.24-1.31-18.16-.38-.32.05-.18.53.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M526.38,121.27c-1.73-.38-3.53-.32-5.26-.7a85.11,85.11,0,0,1-8.23-2.56c-5.48-1.83-11-3.72-16.79-4.36a28.68,28.68,0,0,0-15,1.94c-4.12,1.87-7.16,5.13-10,8.59-3.24,4-6.43,8.16-11.31,10.16-.29.12-.16.6.14.48,8.19-3.36,11.42-12.41,18.51-17.19,9.77-6.58,22.26-3.13,32.49.27,3.12,1,6.23,2.12,9.4,3,1.92.52,3.93.43,5.86.86.32.07.45-.41.14-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M451.14,148.88c6.65-6.88,13.71-13.43,20.76-19.89a49.86,49.86,0,0,1,5.36-4.37c1.82-1.26,3.8-2.17,5.71-3.27a32.76,32.76,0,0,1,5.95-3c1.29-.42,2.56-.87,3.86-1.24A36.1,36.1,0,0,1,505,116.07c.32,0,.32-.48,0-.5-6.93-.41-14.19.66-20.28,4.18-3.84,2.21-7.69,4.17-11.09,7-3.56,3-6.86,6.37-10.28,9.55-4.27,4-8.5,8-12.56,12.18-.22.23.13.59.36.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M449.56,150.09c17.38-5.71,27.7-22.8,44.61-29.43a47.69,47.69,0,0,1,17.95-3.26.25.25,0,0,0,0-.5,49.06,49.06,0,0,0-28.24,8.72c-7.4,5-13.71,11.47-20.93,16.75a49.24,49.24,0,0,1-13.52,7.23c-.3.1-.17.59.13.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M451.09,150.21c9.15-2.05,17.63-6.19,25.56-11.11,4-2.51,7.94-5.21,11.8-8s7.9-5.94,12.14-8.44c5-3,10.9-5,16.76-3.61.32.07.45-.41.14-.48-9.93-2.3-18.56,4.35-26.1,9.8-7.89,5.71-15.9,11.38-24.67,15.67A71.29,71.29,0,0,1,451,149.73c-.32.07-.19.55.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M455.78,149.41A68,68,0,0,0,484,140.08c8.61-5.17,15.34-13,24.21-17.74a15.43,15.43,0,0,1,7.24-1.94,22.75,22.75,0,0,1,7.15.88c.31.1.44-.38.13-.48a21.2,21.2,0,0,0-11.55-.34,24.6,24.6,0,0,0-5.93,3,69.39,69.39,0,0,0-5.84,4.23c-4.07,3.25-7.93,6.76-12.23,9.72a66.39,66.39,0,0,1-14,7.35,67.47,67.47,0,0,1-17.44,4.12c-.32,0-.32.53,0,.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M523.6,123.05a36.1,36.1,0,0,0-25,9.11c-3.69,3.22-7,6.87-11.05,9.66A43.64,43.64,0,0,1,475,147.69a48.25,48.25,0,0,1-15.81,1.8c-.33,0-.33.48,0,.5A47.2,47.2,0,0,0,485,144c8.24-4.74,13.7-13,22.37-17.1a35.21,35.21,0,0,1,16.19-3.34c.32,0,.32-.49,0-.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M461.2,149.65c8.76,2,18.3,4.06,26.94.44,8.1-3.39,11.92-11.71,17.75-17.72A33.57,33.57,0,0,1,518.27,124c.3-.1.17-.59-.13-.48-7.64,2.78-13.23,8.67-18,15-2.68,3.53-5.39,7.22-9.22,9.61a25,25,0,0,1-12.84,3.46c-5.66.11-11.23-1.16-16.71-2.4-.32-.07-.45.41-.14.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M488.86,165.4a43.37,43.37,0,0,0,15.46-11.49c4.23-5,6.58-11.78,12.14-15.49,5.26-3.5,12-3.93,18.11-2.82,7.13,1.3,13.64,4.76,20.2,7.69,6.91,3.08,14.94,6.32,22.49,3.53l-.25-.42c-9.24,9.15-14.8,22.38-26.75,28.57a35.13,35.13,0,0,1-20,3.35,47,47,0,0,1-19.9-7.35,58.06,58.06,0,0,0-10.66-5.76c-3.61-1.35-7.5-2.09-11-.16-.29.15,0,.59.25.43,3.84-2.09,8.11-.84,11.91.71a66.89,66.89,0,0,1,10,5.68,46.56,46.56,0,0,0,18.63,6.85,36.48,36.48,0,0,0,19.08-2.4c6-2.6,10.73-7.15,14.8-12.18,4.68-5.78,8.68-12.13,14-17.39a.25.25,0,0,0-.25-.41c-7.41,2.74-15.31-.45-22.1-3.49-6.48-2.89-12.92-6.3-19.95-7.66-5.51-1.07-11.51-.91-16.65,1.54a18.72,18.72,0,0,0-6.79,5.67c-2,2.65-3.53,5.64-5.47,8.35A43,43,0,0,1,488.61,165c-.29.13,0,.56.25.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M493.66,163.9a46.84,46.84,0,0,0,13.46-8c4.13-3.74,6.14-9.36,10.76-12.59s11.11-3.67,16.66-2.59c6.12,1.18,11.77,3.89,17.62,5.94A44.79,44.79,0,0,0,562,149a25.65,25.65,0,0,0,5.4.09,19.76,19.76,0,0,0,2.29-.34c.29,0,.58-.12.87-.19l.88-.24c.41-.28.7-.2.86.22-.24-.22-.59.14-.36.35.74.69,1.72-1.31-.07-1.17a12.27,12.27,0,0,0-1.88.46,20.63,20.63,0,0,1-3,.45,26.79,26.79,0,0,1-5.37-.18c-7.13-.92-13.6-4.17-20.33-6.47-5.75-2-12-3.21-18-1.68a16.91,16.91,0,0,0-7.21,3.77c-2.26,2-3.85,4.69-5.59,7.16-4.1,5.83-10.47,9.44-16.93,12.15-.29.12-.16.61.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M575.06,147.85c-1.2,1.17-2.65,2-3.89,3.16a57.61,57.61,0,0,0-5.08,5.73c-3.47,4.13-6.88,8.38-11,11.94A30.76,30.76,0,0,1,541.54,176c-5.11,1.08-10-.17-14.77-1.85s-9.76-3.5-15-2.64c-.32.05-.19.53.13.48,5.12-.84,10,1,14.72,2.64,4.5,1.57,9.06,2.82,13.86,2.07,10.54-1.63,18-10.11,24.51-17.85,2-2.36,3.91-4.77,6-7,1.32-1.37,3-2.31,4.37-3.63a.25.25,0,0,0-.35-.36Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M496.75,164.62c4.83,1.23,9.7,2.29,14.59,3.28S521,170,525.8,170.85a46,46,0,0,0,6.91.79c2.39.06,4.76-.23,7.15-.28a36.13,36.13,0,0,0,20.33-7.09c.26-.19,0-.62-.25-.44-5.61,3.93-12.26,6.81-19.2,7-2.2.06-4.41.25-6.61.29a39.14,39.14,0,0,1-7.25-.61c-4.68-.78-9.31-1.88-14-2.82-5.37-1.08-10.73-2.22-16-3.58-.31-.07-.45.41-.13.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M495.11,164.32c9.56-2.28,19.29-.55,28.83.94,9.14,1.42,18.34,2.45,27.44.21a48.23,48.23,0,0,0,14.19-6.07c.27-.17,0-.61-.26-.43a48,48,0,0,1-27.22,7.34c-9.38-.27-18.52-2.66-27.85-3.4a48.6,48.6,0,0,0-15.26.93c-.31.07-.18.55.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M496.27,163.51c9.57-3.3,19.71-4.08,29.77-3.89,5,.1,10,.42,15,.79,5.22.38,10.5,1,15.71.32a21.24,21.24,0,0,0,12.47-5.59c.23-.22-.12-.58-.35-.35-7.32,7.25-18.49,5.81-27.83,5.12-9.72-.72-19.54-1.3-29.26-.27A71.05,71.05,0,0,0,496.14,163c-.3.1-.17.59.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M500.81,161.68a67.33,67.33,0,0,1,29.8-6.8,125.16,125.16,0,0,1,16.16,1.65c5,.68,10.3,1.38,15.29.51,3.94-.69,7.92-3.59,10.51-6.53.21-.24-.14-.6-.35-.36-2.52,2.86-6.35,5.65-10.14,6.38-5.39,1-11.26.08-16.62-.68a110.48,110.48,0,0,0-15.07-1.47,67.49,67.49,0,0,0-29.83,6.87c-.29.14,0,.57.25.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M571.86,147.72A35.24,35.24,0,0,1,558.71,153a39.72,39.72,0,0,1-15.05-.48c-4.59-.92-9.13-2-13.84-2a44.67,44.67,0,0,0-13.14,1.93A48.74,48.74,0,0,0,503.17,159c-.26.18,0,.62.26.43A46.46,46.46,0,0,1,529.82,151c5,0,9.77,1.26,14.63,2.19a39.55,39.55,0,0,0,13.55.44,35.64,35.64,0,0,0,14.11-5.49c.27-.18,0-.61-.25-.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M505.4,158.55c6.23-6.1,13-12.61,21.88-14.26,9.08-1.68,17.14,3.93,25.69,5.91a33.47,33.47,0,0,0,14.26.42c.31-.06.18-.54-.14-.48a33.07,33.07,0,0,1-14.44-.53c-4.36-1.05-8.43-2.88-12.65-4.36a25,25,0,0,0-12.21-1.55,28.65,28.65,0,0,0-11.5,4.77,80.65,80.65,0,0,0-11.24,9.73c-.23.22.12.58.35.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M490.31,164.53a43,43,0,0,1-21.86-2.71c-3.22-1.31-6.21-3.15-9.45-4.43a18.7,18.7,0,0,0-8.87-1.27c-13.59,1.46-21.06,14.4-28,24.54-4.16,6.09-9.2,12.88-16.89,14.4a.26.26,0,0,0,0,.49c13.67,3.18,26.32,12.46,40.92,9.94,12.53-2.17,22.84-11.56,28.48-22.63,1.91-3.74,3.56-7.62,6.07-11,2.39-3.25,5.35-6.48,9.65-6.7.32,0,.33-.51,0-.5-6.41.32-10.59,7-13.24,12.09-1.64,3.17-3.05,6.47-4.94,9.51a44.13,44.13,0,0,1-6.11,7.77,38.63,38.63,0,0,1-16.65,10.22c-6.87,2-13.84,1.25-20.58-.87-7.92-2.49-15.35-6.4-23.47-8.29v.49c6-1.19,10.53-5.66,14.12-10.35,4.25-5.57,7.67-11.73,12.14-17.14,4.3-5.19,9.9-9.71,16.63-11.14a18.15,18.15,0,0,1,10.21.8c3.24,1.22,6.2,3.05,9.39,4.38A43.32,43.32,0,0,0,490.44,165c.32,0,.18-.52-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M485.37,165.79c-6.21,1-13.15,1.44-19.15-.8-3.24-1.22-6.27-3-9.68-3.74a16.8,16.8,0,0,0-8.67.5c-12.85,4.09-17.86,18-27.4,26.27a27.23,27.23,0,0,1-9.45,5.29c-.54.17-1.54.59-1.11,1.32a.61.61,0,0,0,1.12-.08.25.25,0,0,0-.49-.13c.25-.88,2.82-1.34,3.64-1.71A23.6,23.6,0,0,0,418,190.6a35.68,35.68,0,0,0,5.9-5.15c4.12-4.38,7.34-9.49,11.3-14s8.83-8.51,14.83-9.72a16.89,16.89,0,0,1,9.38.9c2.69,1,5.23,2.44,8,3.27,5.82,1.75,12.24,1.3,18.17.38.31-.05.18-.53-.14-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M407.66,195.76c1.72.38,3.53.32,5.25.7a79.12,79.12,0,0,1,8.23,2.56c5.49,1.83,11,3.72,16.8,4.36a28.74,28.74,0,0,0,15-1.94c4.13-1.87,7.17-5.13,10-8.59,3.23-4,6.42-8.16,11.3-10.16.29-.12.17-.6-.13-.48-8.2,3.36-11.42,12.41-18.51,17.19-9.77,6.58-22.27,3.13-32.49-.27-3.12-1-6.23-2.12-9.4-3-1.92-.52-3.94-.43-5.87-.87-.31-.07-.45.42-.13.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M482.9,168.14c-6.66,6.88-13.71,13.44-20.77,19.9a50.75,50.75,0,0,1-5.35,4.37c-1.82,1.26-3.81,2.17-5.72,3.27a32.76,32.76,0,0,1-5.95,3c-1.28.42-2.56.87-3.86,1.24A35.85,35.85,0,0,1,429,201c-.32,0-.32.48,0,.5,6.93.42,14.19-.65,20.29-4.17,3.84-2.21,7.69-4.17,11.09-7.05,3.56-3,6.86-6.37,10.27-9.55,4.27-4,8.51-8,12.56-12.18.23-.23-.13-.59-.35-.36Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M484.47,166.94c-17.38,5.71-27.7,22.8-44.61,29.43a47.66,47.66,0,0,1-17.95,3.26.25.25,0,0,0,0,.5,49,49,0,0,0,28.24-8.72c7.41-5,13.72-11.47,20.94-16.75a49,49,0,0,1,13.52-7.24c.3-.1.17-.58-.14-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M483,166.82c-9.16,2-17.63,6.19-25.56,11.11-4,2.51-7.94,5.21-11.8,8s-7.9,5.93-12.14,8.44c-5,3-10.91,5-16.77,3.61-.31-.08-.45.41-.13.48,9.92,2.3,18.55-4.35,26.1-9.81,7.89-5.7,15.9-11.37,24.66-15.67a71.34,71.34,0,0,1,15.77-5.65c.31-.07.18-.55-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M478.25,167.62A67.85,67.85,0,0,0,450,177c-8.6,5.17-15.34,13-24.21,17.74a15.39,15.39,0,0,1-7.23,1.94,22.43,22.43,0,0,1-7.15-.89c-.31-.09-.44.39-.14.49a21.1,21.1,0,0,0,11.55.33,23.73,23.73,0,0,0,5.93-3,65.17,65.17,0,0,0,5.84-4.23c4.08-3.24,7.94-6.76,12.23-9.71a67.06,67.06,0,0,1,31.42-11.47c.32,0,.32-.53,0-.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M410.44,194a36.19,36.19,0,0,0,25-9.11c3.69-3.22,7-6.87,11.05-9.66A43.52,43.52,0,0,1,459,169.34a48.25,48.25,0,0,1,15.81-1.8c.32,0,.32-.49,0-.5A47.2,47.2,0,0,0,449,173c-8.24,4.74-13.7,13-22.37,17.1a35.23,35.23,0,0,1-16.19,3.34c-.32,0-.32.49,0,.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M472.83,167.38c-8.76-2-18.3-4.06-26.94-.44-8.09,3.39-11.91,11.71-17.75,17.71A33.29,33.29,0,0,1,415.77,193c-.3.11-.17.6.13.49,7.63-2.78,13.23-8.67,18-15,2.68-3.54,5.4-7.22,9.22-9.62A25.13,25.13,0,0,1,456,165.46c5.66-.12,11.23,1.16,16.72,2.4.31.07.45-.41.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M661.59,116.49A43.37,43.37,0,0,0,677.05,105c4.23-5,6.57-11.78,12.14-15.49,5.26-3.5,12-3.93,18.11-2.82,7.13,1.3,13.64,4.76,20.2,7.69,6.91,3.08,14.94,6.33,22.49,3.53l-.25-.42c-9.24,9.15-14.8,22.38-26.75,28.57a35.13,35.13,0,0,1-20,3.35,47,47,0,0,1-19.9-7.35,58.66,58.66,0,0,0-10.66-5.76c-3.61-1.35-7.5-2.09-11-.16a.25.25,0,0,0,.25.43c3.84-2.09,8.11-.84,11.91.71a67.77,67.77,0,0,1,10,5.68,46.36,46.36,0,0,0,18.63,6.85,36.35,36.35,0,0,0,19.07-2.4c6-2.6,10.73-7.15,14.81-12.18,4.68-5.78,8.68-12.13,14-17.38.17-.17,0-.51-.25-.42-7.41,2.74-15.31-.45-22.1-3.48-6.48-2.9-12.92-6.31-20-7.67-5.51-1.07-11.51-.91-16.65,1.54a18.72,18.72,0,0,0-6.79,5.67c-2,2.65-3.53,5.64-5.47,8.35a43,43,0,0,1-17.55,14.22c-.29.13,0,.56.25.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M666.39,115a46.84,46.84,0,0,0,13.46-8c4.13-3.74,6.14-9.36,10.76-12.59s11.11-3.67,16.66-2.59c6.11,1.18,11.77,3.89,17.62,5.94a45.38,45.38,0,0,0,9.81,2.41,26.34,26.34,0,0,0,5.4.08,21.58,21.58,0,0,0,2.28-.33l.88-.2.88-.24c.41-.27.7-.2.86.22-.24-.22-.59.14-.36.36.74.68,1.72-1.32-.07-1.18a13.56,13.56,0,0,0-1.88.46,20.83,20.83,0,0,1-3,.45,26.79,26.79,0,0,1-5.37-.18c-7.13-.91-13.6-4.17-20.33-6.47-5.75-2-12-3.21-18-1.68a16.91,16.91,0,0,0-7.21,3.77c-2.26,2-3.85,4.69-5.59,7.16-4.1,5.83-10.47,9.45-16.93,12.15-.29.12-.16.61.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M747.79,98.94c-1.2,1.17-2.65,2-3.89,3.16a57.61,57.61,0,0,0-5.08,5.73c-3.47,4.13-6.88,8.39-11,12a30.84,30.84,0,0,1-13.58,7.28c-5.11,1.08-10-.17-14.78-1.85s-9.75-3.49-15-2.64c-.32.05-.19.53.13.48,5.12-.84,10,1,14.72,2.64,4.5,1.57,9.06,2.82,13.86,2.07,10.54-1.63,18-10.11,24.51-17.85,2-2.36,3.91-4.77,6-7,1.32-1.37,3-2.31,4.37-3.63a.25.25,0,0,0-.35-.36Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M669.48,115.71c4.83,1.23,9.7,2.29,14.59,3.28s9.63,2.08,14.46,3a45.84,45.84,0,0,0,6.91.79c2.39.06,4.76-.23,7.15-.27a36.26,36.26,0,0,0,20.33-7.1c.26-.19,0-.62-.25-.43-5.61,3.93-12.26,6.8-19.2,7-2.21.06-4.41.25-6.61.29a39.14,39.14,0,0,1-7.25-.61c-4.68-.78-9.32-1.88-14-2.82-5.37-1.08-10.73-2.22-16-3.57a.25.25,0,0,0-.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M667.84,115.41c9.56-2.27,19.29-.55,28.83.94,9.13,1.43,18.34,2.45,27.44.21a48.34,48.34,0,0,0,14.19-6.07.25.25,0,0,0-.26-.43,48.07,48.07,0,0,1-27.23,7.35c-9.37-.28-18.51-2.67-27.84-3.41a48.9,48.9,0,0,0-15.26.93c-.31.07-.18.55.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M669,114.6c9.57-3.29,19.71-4.08,29.77-3.89,5,.1,10,.42,15,.79,5.22.39,10.5,1,15.71.32A21.24,21.24,0,0,0,742,106.23c.23-.22-.12-.58-.35-.35-7.32,7.26-18.49,5.81-27.83,5.12-9.73-.71-19.54-1.29-29.26-.26a70.47,70.47,0,0,0-15.66,3.38c-.31.1-.18.59.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M673.54,112.77a67.32,67.32,0,0,1,29.8-6.79,125.14,125.14,0,0,1,16.16,1.64c5,.68,10.29,1.39,15.29.51,3.94-.69,7.92-3.59,10.51-6.53.21-.24-.14-.6-.35-.35-2.52,2.85-6.35,5.64-10.14,6.37-5.39,1-11.26.08-16.62-.68a110.48,110.48,0,0,0-15.07-1.47,67.49,67.49,0,0,0-29.83,6.87c-.29.14,0,.57.25.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M744.59,98.81a35.42,35.42,0,0,1-13.15,5.3,39.71,39.71,0,0,1-15.05-.49c-4.59-.91-9.13-2-13.84-2a44.78,44.78,0,0,0-13.15,1.93A48.81,48.81,0,0,0,675.9,110c-.26.18,0,.62.26.43a46.46,46.46,0,0,1,26.39-8.37c5,0,9.77,1.26,14.63,2.19a39.55,39.55,0,0,0,13.55.44,35.64,35.64,0,0,0,14.11-5.49c.27-.18,0-.61-.25-.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M678.13,109.64c6.23-6.1,13-12.61,21.88-14.26,9.08-1.68,17.14,3.93,25.69,5.91a33.31,33.31,0,0,0,14.26.42c.31-.06.18-.54-.14-.48a33.07,33.07,0,0,1-14.44-.53c-4.36-1-8.43-2.88-12.65-4.36a25.09,25.09,0,0,0-12.21-1.55A28.89,28.89,0,0,0,689,99.56a81.15,81.15,0,0,0-11.23,9.73c-.23.22.12.58.35.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M663,115.62a42.85,42.85,0,0,1-21.86-2.71c-3.23-1.31-6.21-3.15-9.46-4.42a18.52,18.52,0,0,0-8.86-1.28c-13.6,1.46-21.06,14.4-28,24.54-4.16,6.09-9.2,12.88-16.89,14.41-.24,0-.23.42,0,.48,13.67,3.18,26.32,12.47,40.92,9.94,12.53-2.17,22.84-11.56,28.48-22.62,1.91-3.75,3.56-7.63,6.07-11,2.39-3.25,5.35-6.48,9.65-6.69.32,0,.33-.52,0-.5-6.41.31-10.59,7-13.24,12.08-1.64,3.18-3.05,6.47-4.94,9.51a44.13,44.13,0,0,1-6.11,7.77,38.65,38.65,0,0,1-16.65,10.23c-6.87,2-13.84,1.24-20.58-.88-7.92-2.49-15.35-6.39-23.47-8.28v.48c6-1.19,10.53-5.66,14.12-10.35,4.25-5.57,7.67-11.73,12.14-17.14,4.3-5.18,9.9-9.71,16.63-11.14a18.24,18.24,0,0,1,10.21.8c3.24,1.22,6.2,3,9.39,4.39a43.52,43.52,0,0,0,22.59,2.91c.32,0,.18-.53-.13-.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M658.1,116.88c-6.21,1-13.15,1.44-19.15-.8-3.24-1.22-6.27-3-9.68-3.74a16.85,16.85,0,0,0-8.68.5c-12.84,4.09-17.85,18-27.39,26.27a27.07,27.07,0,0,1-9.45,5.29c-.54.18-1.54.59-1.11,1.32a.6.6,0,0,0,1.11-.07.25.25,0,0,0-.48-.14c.25-.88,2.82-1.34,3.64-1.71a23.6,23.6,0,0,0,3.77-2.11,35.68,35.68,0,0,0,5.9-5.15c4.12-4.38,7.34-9.49,11.3-14s8.83-8.51,14.83-9.72a16.8,16.8,0,0,1,9.38.91c2.69,1,5.23,2.43,8,3.26,5.82,1.75,12.24,1.31,18.16.38.32,0,.19-.53-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M580.39,146.85c1.72.38,3.53.32,5.25.7a84.25,84.25,0,0,1,8.23,2.56c5.49,1.83,11,3.72,16.8,4.36a28.63,28.63,0,0,0,15-1.94c4.13-1.87,7.17-5.13,10-8.59,3.23-4,6.42-8.16,11.3-10.16.29-.12.17-.6-.13-.48-8.2,3.36-11.42,12.41-18.52,17.19-9.76,6.58-22.26,3.13-32.48-.27-3.12-1-6.23-2.12-9.41-3-1.91-.52-3.93-.43-5.86-.86-.31-.07-.45.41-.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M655.63,119.24c-6.66,6.88-13.71,13.43-20.77,19.89a49.86,49.86,0,0,1-5.36,4.37c-1.81,1.26-3.8,2.17-5.71,3.27a32.13,32.13,0,0,1-5.95,3c-1.28.42-2.56.87-3.86,1.24a36.1,36.1,0,0,1-12.21,1.06c-.32,0-.32.48,0,.5,6.93.41,14.19-.66,20.29-4.18,3.84-2.21,7.68-4.17,11.09-7.05,3.56-3,6.85-6.37,10.27-9.55,4.27-4,8.5-8,12.56-12.18.22-.23-.13-.59-.35-.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M657.2,118c-17.38,5.71-27.7,22.8-44.61,29.43a47.66,47.66,0,0,1-18,3.26.25.25,0,0,0,0,.5,49,49,0,0,0,28.24-8.72c7.41-5,13.72-11.47,20.94-16.75a49,49,0,0,1,13.51-7.23c.31-.1.18-.59-.13-.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M655.68,117.91c-9.16,2-17.64,6.19-25.57,11.11-4,2.51-7.93,5.21-11.79,8-4,2.86-7.9,5.94-12.14,8.44-5,3-10.91,5-16.77,3.61-.31-.07-.45.41-.13.48,9.92,2.3,18.55-4.35,26.1-9.8,7.89-5.71,15.89-11.38,24.66-15.67a71.37,71.37,0,0,1,15.77-5.66c.31-.07.18-.55-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M651,118.71A68,68,0,0,0,622.74,128c-8.6,5.17-15.34,13-24.21,17.74a15.39,15.39,0,0,1-7.23,1.94,22.71,22.71,0,0,1-7.15-.88c-.31-.1-.44.38-.14.48a21.2,21.2,0,0,0,11.55.34,24.42,24.42,0,0,0,5.93-3,69.39,69.39,0,0,0,5.84-4.23c4.08-3.25,7.94-6.76,12.23-9.72A67.06,67.06,0,0,1,651,119.21c.32,0,.32-.53,0-.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M583.17,145.07a36.14,36.14,0,0,0,25-9.11c3.69-3.22,7-6.87,11.05-9.66a43.52,43.52,0,0,1,12.53-5.87,48.25,48.25,0,0,1,15.81-1.8c.32,0,.32-.48,0-.5a47.25,47.25,0,0,0-25.83,6c-8.23,4.74-13.69,13-22.37,17.1a35.16,35.16,0,0,1-16.18,3.34c-.32,0-.32.49,0,.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M645.56,118.47c-8.76-2-18.3-4.06-26.94-.44-8.1,3.39-11.91,11.71-17.75,17.72a33.53,33.53,0,0,1-12.37,8.39c-.3.1-.17.59.13.48,7.63-2.78,13.23-8.67,18-15,2.68-3.53,5.4-7.22,9.22-9.61a25,25,0,0,1,12.84-3.46c5.66-.11,11.23,1.16,16.72,2.4.31.07.45-.41.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M505.59,226.62A43.45,43.45,0,0,0,521,215.13c4.23-5,6.58-11.79,12.15-15.49,5.25-3.5,12-3.93,18.1-2.82,7.13,1.3,13.64,4.76,20.2,7.68,6.91,3.09,15,6.33,22.49,3.54l-.24-.42c-9.24,9.14-14.8,22.37-26.76,28.57a35,35,0,0,1-20,3.34,46.76,46.76,0,0,1-19.9-7.34,58.14,58.14,0,0,0-10.67-5.76c-3.6-1.35-7.49-2.09-11-.16-.28.15,0,.58.25.43,3.85-2.1,8.11-.85,11.91.7a67.54,67.54,0,0,1,10,5.69,46.52,46.52,0,0,0,18.62,6.85,36.48,36.48,0,0,0,19.08-2.4c6-2.6,10.73-7.16,14.8-12.18,4.68-5.79,8.68-12.13,14-17.39.17-.16,0-.51-.24-.42-7.42,2.75-15.31-.45-22.11-3.48-6.48-2.89-12.92-6.3-20-7.67-5.51-1.06-11.51-.91-16.64,1.55a18.64,18.64,0,0,0-6.8,5.66c-2,2.66-3.53,5.64-5.46,8.35a42.82,42.82,0,0,1-17.56,14.22c-.29.13,0,.56.26.44Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M510.39,225.12a46.59,46.59,0,0,0,13.45-8c4.13-3.73,6.15-9.35,10.77-12.59s11.1-3.66,16.65-2.59c6.12,1.19,11.78,3.9,17.62,6a45.36,45.36,0,0,0,9.81,2.4,25.66,25.66,0,0,0,5.4.09,22.18,22.18,0,0,0,2.29-.34c.29-.06.59-.12.88-.2s.58-.15.87-.24c.42-.27.7-.19.86.23-.24-.22-.59.13-.35.35.74.69,1.71-1.31-.08-1.18a13.46,13.46,0,0,0-1.87.47,22.29,22.29,0,0,1-3,.45,26.86,26.86,0,0,1-5.38-.18c-7.12-.92-13.6-4.17-20.33-6.47-5.74-2-11.94-3.21-18-1.68a17,17,0,0,0-7.22,3.77c-2.25,2-3.85,4.68-5.58,7.15-4.11,5.83-10.47,9.45-16.94,12.16-.29.12-.16.6.14.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M591.78,209.07c-1.2,1.16-2.65,2-3.88,3.16a57.74,57.74,0,0,0-5.09,5.72c-3.47,4.14-6.88,8.39-11,12a30.86,30.86,0,0,1-13.58,7.29c-5.11,1.08-10-.17-14.78-1.85s-9.76-3.5-15-2.65c-.32.05-.18.54.13.48,5.13-.83,10,1,14.73,2.65,4.5,1.57,9,2.81,13.85,2.07,10.55-1.64,18-10.11,24.51-17.86,2-2.35,3.91-4.76,6-7,1.32-1.37,3-2.31,4.37-3.64.24-.22-.12-.57-.35-.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M513.47,225.83c4.83,1.24,9.71,2.3,14.59,3.29s9.63,2.07,14.47,2.95a47.16,47.16,0,0,0,6.91.79c2.38.06,4.76-.24,7.14-.28a36.86,36.86,0,0,0,9.6-1.64,36.39,36.39,0,0,0,10.73-5.46c.26-.18,0-.61-.25-.43-5.61,3.93-12.26,6.81-19.2,7-2.2.07-4.4.25-6.6.3a39.25,39.25,0,0,1-7.26-.61c-4.67-.78-9.31-1.88-14-2.82-5.37-1.08-10.73-2.22-16-3.58a.25.25,0,0,0-.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M511.84,225.53c9.56-2.27,19.29-.55,28.82.94,9.14,1.43,18.34,2.45,27.45.21a48,48,0,0,0,14.18-6.06c.27-.18,0-.61-.25-.44a48,48,0,0,1-27.23,7.35c-9.37-.28-18.51-2.66-27.84-3.41a48.86,48.86,0,0,0-15.26.93.25.25,0,0,0,.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M513,224.73c9.57-3.3,19.71-4.09,29.77-3.89,5,.09,10,.42,15,.79,5.21.38,10.49,1,15.71.32A21.33,21.33,0,0,0,586,216.36a.25.25,0,0,0-.36-.35c-7.32,7.25-18.48,5.8-27.82,5.12-9.73-.72-19.54-1.3-29.27-.27a71.61,71.61,0,0,0-15.66,3.38c-.3.11-.17.59.13.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M517.53,222.9a67.37,67.37,0,0,1,29.81-6.8,125.19,125.19,0,0,1,16.15,1.65c5,.68,10.3,1.38,15.3.5,3.93-.69,7.91-3.58,10.5-6.53.22-.24-.14-.59-.35-.35-2.51,2.85-6.34,5.65-10.14,6.37-5.39,1-11.26.08-16.61-.68a110.61,110.61,0,0,0-15.07-1.46,67.35,67.35,0,0,0-14.23,1.48,68.05,68.05,0,0,0-15.61,5.39c-.29.14,0,.57.25.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M588.58,208.93a35,35,0,0,1-13.14,5.3,39.71,39.71,0,0,1-15.05-.48c-4.6-.92-9.13-2-13.84-2a44.69,44.69,0,0,0-13.15,1.94,48.53,48.53,0,0,0-13.5,6.51c-.27.18,0,.61.25.43a46.56,46.56,0,0,1,26.4-8.38c5,0,9.76,1.27,14.62,2.19a39.32,39.32,0,0,0,13.55.45,35.68,35.68,0,0,0,14.12-5.49c.26-.18,0-.61-.26-.44Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M522.12,219.77c6.23-6.1,13-12.61,21.88-14.26,9.08-1.68,17.15,3.93,25.69,5.91a33.47,33.47,0,0,0,14.26.42c.32-.07.18-.55-.13-.48a33.26,33.26,0,0,1-14.45-.53c-4.36-1.06-8.43-2.88-12.64-4.36a25,25,0,0,0-12.22-1.55,28.69,28.69,0,0,0-11.5,4.76,81.32,81.32,0,0,0-11.24,9.73.25.25,0,0,0,.35.36Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M507,225.75A42.92,42.92,0,0,1,485.18,223c-3.23-1.31-6.22-3.16-9.46-4.43a18.64,18.64,0,0,0-8.86-1.27c-13.6,1.45-21.07,14.39-28,24.54-4.16,6.08-9.2,12.88-16.89,14.4a.25.25,0,0,0,0,.48c13.68,3.19,26.32,12.47,40.92,9.95,12.53-2.17,22.84-11.56,28.48-22.63,1.92-3.75,3.56-7.63,6.07-11s5.36-6.49,9.66-6.7c.32,0,.32-.51,0-.5-6.41.32-10.59,7-13.24,12.09-1.65,3.17-3,6.47-4.94,9.51a45,45,0,0,1-6.11,7.77,38.66,38.66,0,0,1-16.66,10.22c-6.86,2-13.83,1.24-20.57-.87-7.92-2.5-15.35-6.4-23.47-8.29v.48c6-1.18,10.52-5.66,14.11-10.35,4.26-5.56,7.67-11.73,12.15-17.13,4.29-5.19,9.89-9.72,16.63-11.14a18.12,18.12,0,0,1,10.2.8c3.24,1.21,6.21,3.05,9.39,4.38a43.36,43.36,0,0,0,22.6,2.91c.31,0,.18-.52-.14-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M502.1,227c-6.21,1-13.16,1.45-19.15-.8-3.24-1.21-6.28-3-9.68-3.73a16.73,16.73,0,0,0-8.68.5c-12.85,4.09-17.85,18-27.39,26.27a27,27,0,0,1-9.46,5.28c-.53.18-1.54.59-1.1,1.33a.6.6,0,0,0,1.11-.08.25.25,0,0,0-.48-.13c.24-.89,2.82-1.35,3.64-1.71a24,24,0,0,0,3.76-2.11,36.28,36.28,0,0,0,5.91-5.15c4.11-4.38,7.34-9.5,11.29-14s8.84-8.52,14.84-9.73a17,17,0,0,1,9.38.91c2.69,1,5.22,2.44,8,3.27,5.81,1.74,12.24,1.3,18.16.38.32-.05.18-.54-.13-.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M424.38,257c1.73.39,3.53.33,5.26.71a82.17,82.17,0,0,1,8.22,2.55c5.49,1.84,11,3.73,16.8,4.36a28.63,28.63,0,0,0,15-1.93c4.13-1.88,7.17-5.13,10-8.59,3.24-4,6.42-8.16,11.3-10.16.3-.12.17-.6-.13-.48-8.2,3.36-11.42,12.4-18.51,17.19-9.77,6.58-22.27,3.13-32.49-.27-3.12-1-6.23-2.13-9.4-3-1.92-.52-3.93-.44-5.86-.87-.32-.07-.45.41-.14.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M499.62,229.36c-6.65,6.88-13.71,13.43-20.77,19.9a49.75,49.75,0,0,1-5.35,4.37c-1.82,1.26-3.81,2.17-5.71,3.27a32.72,32.72,0,0,1-6,3c-1.29.43-2.57.87-3.86,1.25a35.89,35.89,0,0,1-12.21,1.05c-.32,0-.32.48,0,.5,6.92.41,14.19-.66,20.28-4.17,3.84-2.21,7.69-4.17,11.09-7.06,3.56-3,6.86-6.36,10.28-9.54,4.26-4,8.5-8,12.56-12.18.22-.24-.13-.59-.36-.36Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M501.2,228.16c-17.39,5.71-27.7,22.8-44.61,29.43a47.73,47.73,0,0,1-17.95,3.26.25.25,0,0,0,0,.5,49,49,0,0,0,28.24-8.73c7.4-5,13.71-11.46,20.93-16.74a49.12,49.12,0,0,1,13.52-7.24c.3-.1.17-.58-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M499.67,228c-9.15,2-17.63,6.18-25.56,11.11-4,2.5-7.94,5.21-11.8,8s-7.9,5.94-12.14,8.44c-5,3-10.9,5-16.77,3.61a.25.25,0,0,0-.13.49c9.93,2.3,18.56-4.35,26.1-9.81,7.89-5.7,15.9-11.37,24.67-15.67a70.76,70.76,0,0,1,15.76-5.65c.32-.07.18-.55-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M495,228.84a67.89,67.89,0,0,0-28.24,9.32c-8.61,5.18-15.34,13-24.22,17.75a15.36,15.36,0,0,1-7.23,1.94,22.76,22.76,0,0,1-7.15-.89.25.25,0,0,0-.13.48,21.1,21.1,0,0,0,11.55.34,24.07,24.07,0,0,0,5.93-3,67.21,67.21,0,0,0,5.84-4.23c4.07-3.24,7.93-6.76,12.23-9.72A66.8,66.8,0,0,1,495,229.34c.31,0,.32-.53,0-.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M427.16,255.2a36.16,36.16,0,0,0,25-9.12c3.69-3.22,7-6.86,11.05-9.65a43.12,43.12,0,0,1,12.53-5.87,48.25,48.25,0,0,1,15.81-1.81c.32,0,.32-.48,0-.5a47.2,47.2,0,0,0-25.82,6c-8.24,4.74-13.7,13-22.37,17.11a35.21,35.21,0,0,1-16.19,3.34c-.32,0-.32.49,0,.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M489.56,228.6c-8.77-2-18.3-4.06-26.94-.44-8.1,3.38-11.92,11.71-17.75,17.71a33.45,33.45,0,0,1-12.38,8.39c-.3.11-.17.59.13.48,7.63-2.77,13.23-8.66,18-15,2.68-3.54,5.39-7.23,9.21-9.62a25.17,25.17,0,0,1,12.85-3.45c5.66-.12,11.23,1.15,16.71,2.4.32.07.45-.41.14-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M678.32,177.71a43.45,43.45,0,0,0,15.45-11.49c4.23-5,6.58-11.79,12.15-15.49,5.25-3.5,12-3.93,18.1-2.82,7.13,1.3,13.64,4.76,20.2,7.68,6.91,3.09,14.95,6.33,22.49,3.54l-.24-.42c-9.24,9.14-14.8,22.37-26.76,28.57a35,35,0,0,1-20,3.34,46.76,46.76,0,0,1-19.9-7.34,58.14,58.14,0,0,0-10.67-5.76c-3.6-1.35-7.49-2.09-11-.16-.28.15,0,.58.25.43,3.85-2.1,8.11-.85,11.91.7a67.54,67.54,0,0,1,10,5.69A46.52,46.52,0,0,0,719,191a36.48,36.48,0,0,0,19.08-2.4c6-2.6,10.73-7.16,14.8-12.18,4.68-5.79,8.68-12.13,14-17.39.17-.16,0-.5-.24-.41-7.42,2.74-15.31-.46-22.11-3.49-6.48-2.89-12.92-6.3-20-7.66-5.51-1.07-11.51-.91-16.64,1.54a18.57,18.57,0,0,0-6.8,5.67c-2,2.65-3.53,5.64-5.46,8.34a42.82,42.82,0,0,1-17.56,14.22c-.29.13,0,.57.26.44Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M683.12,176.21a46.79,46.79,0,0,0,13.45-8c4.13-3.74,6.15-9.36,10.77-12.59s11.1-3.67,16.65-2.6c6.12,1.19,11.78,3.9,17.62,5.95a45.36,45.36,0,0,0,9.81,2.4,25.66,25.66,0,0,0,5.4.09,19.76,19.76,0,0,0,2.29-.34c.29-.06.59-.12.88-.2s.58-.15.87-.24c.42-.27.7-.19.86.23-.24-.22-.59.13-.35.35.74.69,1.71-1.31-.08-1.18a13.46,13.46,0,0,0-1.87.47,22.29,22.29,0,0,1-3,.45,26.86,26.86,0,0,1-5.38-.18c-7.12-.92-13.6-4.17-20.33-6.47-5.75-2-11.94-3.21-17.95-1.68a17,17,0,0,0-7.22,3.77c-2.25,2.05-3.85,4.68-5.59,7.15-4.1,5.83-10.47,9.45-16.93,12.16-.29.12-.16.61.14.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M764.51,160.16c-1.2,1.17-2.65,2-3.89,3.16a55.45,55.45,0,0,0-5.08,5.73c-3.47,4.13-6.88,8.38-11,11.94A30.76,30.76,0,0,1,731,188.28c-5.11,1.08-10-.17-14.78-1.85s-9.76-3.5-15-2.65c-.32.06-.18.54.13.49,5.13-.84,10,1,14.73,2.64,4.5,1.57,9,2.82,13.85,2.07,10.55-1.64,18-10.11,24.51-17.85,2-2.36,3.91-4.77,6-7,1.32-1.37,3-2.31,4.37-3.63a.25.25,0,0,0-.35-.36Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M686.2,176.93c4.83,1.23,9.71,2.29,14.59,3.28s9.63,2.07,14.47,2.95a45.71,45.71,0,0,0,6.9.79c2.39.06,4.77-.23,7.15-.28a36.26,36.26,0,0,0,20.33-7.1c.26-.18,0-.61-.25-.43-5.61,3.93-12.26,6.81-19.2,7-2.2.07-4.4.26-6.6.3a39.25,39.25,0,0,1-7.26-.61c-4.68-.78-9.31-1.88-14-2.82-5.37-1.08-10.73-2.22-16-3.58a.25.25,0,0,0-.13.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M684.57,176.63c9.55-2.28,19.29-.56,28.82.93,9.14,1.43,18.34,2.45,27.45.21A48,48,0,0,0,755,171.71c.27-.17,0-.61-.25-.43a48.1,48.1,0,0,1-27.23,7.34c-9.37-.28-18.52-2.66-27.84-3.41a49,49,0,0,0-15.27.93c-.31.08-.18.56.14.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M685.72,175.82c9.57-3.3,19.71-4.09,29.77-3.89,5,.09,10,.42,15,.79,5.21.38,10.49,1,15.71.32a21.33,21.33,0,0,0,12.47-5.59.25.25,0,0,0-.36-.35c-7.32,7.25-18.48,5.81-27.82,5.12-9.73-.72-19.54-1.3-29.27-.27a71.05,71.05,0,0,0-15.66,3.39c-.3.1-.17.58.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M690.26,174a67.37,67.37,0,0,1,29.81-6.8,125.19,125.19,0,0,1,16.15,1.65c5,.68,10.3,1.38,15.3.51,3.93-.7,7.91-3.59,10.5-6.53.21-.24-.14-.6-.35-.36-2.51,2.86-6.34,5.65-10.14,6.38-5.39,1-11.26.07-16.62-.69a110.31,110.31,0,0,0-15.07-1.46A67.49,67.49,0,0,0,690,173.56c-.29.14,0,.57.25.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M761.31,160a35.24,35.24,0,0,1-13.15,5.29,39.67,39.67,0,0,1-15-.48c-4.6-.92-9.13-2-13.84-2a44.36,44.36,0,0,0-13.15,1.93,48.81,48.81,0,0,0-13.5,6.51c-.27.18,0,.62.25.43a46.47,46.47,0,0,1,26.4-8.37c5,0,9.76,1.26,14.62,2.18a39.32,39.32,0,0,0,13.55.45,35.68,35.68,0,0,0,14.12-5.49c.26-.18,0-.61-.26-.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M694.85,170.86c6.23-6.1,13-12.61,21.88-14.26,9.08-1.68,17.15,3.93,25.69,5.91a33.47,33.47,0,0,0,14.26.42c.32-.06.18-.55-.13-.48a33.26,33.26,0,0,1-14.45-.53c-4.36-1.06-8.43-2.88-12.65-4.36A25,25,0,0,0,717.24,156a28.69,28.69,0,0,0-11.5,4.76,81.39,81.39,0,0,0-11.24,9.74c-.23.22.12.57.35.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M679.76,176.84a42.92,42.92,0,0,1-21.85-2.71c-3.23-1.31-6.22-3.15-9.46-4.43a18.64,18.64,0,0,0-8.86-1.27c-13.6,1.46-21.07,14.39-28,24.54-4.16,6.08-9.2,12.88-16.89,14.4a.25.25,0,0,0,0,.48c13.68,3.19,26.32,12.47,40.92,10,12.53-2.17,22.84-11.56,28.48-22.63,1.92-3.75,3.56-7.62,6.07-11,2.39-3.25,5.36-6.48,9.66-6.7.32,0,.32-.51,0-.5-6.41.32-10.59,7-13.24,12.09-1.65,3.17-3.05,6.47-4.94,9.51a44.61,44.61,0,0,1-6.11,7.77,38.66,38.66,0,0,1-16.66,10.22c-6.86,2-13.83,1.25-20.57-.87-7.93-2.5-15.35-6.4-23.47-8.29v.48c6-1.18,10.52-5.65,14.11-10.35,4.26-5.56,7.67-11.73,12.15-17.13,4.29-5.19,9.89-9.72,16.63-11.14a18.12,18.12,0,0,1,10.2.8c3.24,1.21,6.2,3.05,9.39,4.38a43.33,43.33,0,0,0,22.59,2.91c.32,0,.19-.52-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M674.83,178.09c-6.21,1-13.16,1.45-19.15-.8-3.24-1.21-6.28-3-9.68-3.73a16.83,16.83,0,0,0-8.68.5c-12.85,4.09-17.86,18-27.39,26.27a27,27,0,0,1-9.46,5.28c-.53.18-1.54.59-1.1,1.33a.6.6,0,0,0,1.11-.08.25.25,0,0,0-.48-.13c.24-.89,2.82-1.35,3.64-1.71a24,24,0,0,0,3.76-2.11,35.8,35.8,0,0,0,5.91-5.15c4.11-4.38,7.34-9.49,11.29-14s8.84-8.51,14.83-9.73a17,17,0,0,1,9.39.91c2.69,1,5.22,2.44,8,3.27,5.82,1.74,12.25,1.3,18.17.38.32-.05.18-.53-.13-.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M597.11,208.07c1.73.38,3.53.32,5.26.7a79.7,79.7,0,0,1,8.22,2.56c5.49,1.83,11,3.72,16.8,4.35a28.63,28.63,0,0,0,15-1.93c4.13-1.87,7.17-5.13,10-8.59,3.24-4,6.42-8.16,11.3-10.16.3-.12.17-.6-.13-.48-8.2,3.36-11.42,12.4-18.51,17.19-9.77,6.58-22.27,3.13-32.49-.27-3.12-1-6.23-2.12-9.4-3-1.92-.52-3.93-.44-5.87-.87a.25.25,0,0,0-.13.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M672.35,180.45c-6.65,6.88-13.71,13.43-20.77,19.9a49.75,49.75,0,0,1-5.35,4.37c-1.82,1.26-3.81,2.17-5.71,3.27a33.18,33.18,0,0,1-6,3c-1.28.42-2.56.87-3.86,1.24a35.81,35.81,0,0,1-12.2,1c-.33,0-.32.48,0,.5,6.92.42,14.19-.65,20.28-4.17,3.84-2.21,7.69-4.17,11.09-7.06,3.56-3,6.86-6.36,10.27-9.54,4.27-4,8.51-8,12.56-12.18.23-.23-.12-.59-.35-.36Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M673.93,179.25c-17.39,5.71-27.7,22.8-44.61,29.43a47.73,47.73,0,0,1-18,3.26.25.25,0,0,0,0,.5,49.06,49.06,0,0,0,28.24-8.72c7.4-5,13.71-11.47,20.93-16.75a49.12,49.12,0,0,1,13.52-7.24c.3-.1.17-.58-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M672.4,179.13c-9.15,2-17.63,6.18-25.56,11.11-4,2.51-7.94,5.21-11.8,8s-7.9,5.94-12.14,8.45c-5,3-10.91,5-16.77,3.61a.25.25,0,0,0-.13.48c9.93,2.3,18.56-4.35,26.1-9.81,7.89-5.7,15.9-11.37,24.67-15.67a71.26,71.26,0,0,1,15.76-5.65c.32-.07.18-.55-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M667.71,179.93a68,68,0,0,0-28.25,9.32c-8.6,5.18-15.33,13-24.21,17.75a15.36,15.36,0,0,1-7.23,1.94,22.72,22.72,0,0,1-7.15-.89c-.31-.09-.44.39-.13.49a21.1,21.1,0,0,0,11.55.33,24.07,24.07,0,0,0,5.93-3,68.77,68.77,0,0,0,5.84-4.23c4.07-3.24,7.93-6.76,12.23-9.71a66.09,66.09,0,0,1,14-7.35,67.23,67.23,0,0,1,17.45-4.12c.31,0,.32-.53,0-.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M599.89,206.29a36.11,36.11,0,0,0,25-9.12c3.69-3.21,7-6.86,11-9.65a43.38,43.38,0,0,1,12.53-5.87,48,48,0,0,1,15.81-1.8c.32,0,.32-.49,0-.5a47.19,47.19,0,0,0-25.82,6c-8.24,4.75-13.7,13-22.37,17.11a35.21,35.21,0,0,1-16.19,3.34c-.32,0-.32.49,0,.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M662.29,179.69c-8.77-2-18.3-4.06-26.95-.44C627.25,182.64,623.43,191,617.6,197a33.33,33.33,0,0,1-12.38,8.39c-.3.11-.17.6.13.48,7.63-2.77,13.23-8.66,18-15,2.68-3.54,5.39-7.23,9.21-9.62a25.17,25.17,0,0,1,12.85-3.45c5.66-.12,11.23,1.15,16.71,2.4.32.07.45-.41.14-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M634.63,163.94a43.28,43.28,0,0,0-15.46,11.49c-4.23,5-6.58,11.78-12.14,15.49-5.26,3.5-12,3.92-18.11,2.82-7.13-1.31-13.64-4.76-20.2-7.69-6.91-3.08-14.94-6.33-22.49-3.53l.24.42c9.24-9.15,14.8-22.38,26.76-28.57a35.11,35.11,0,0,1,20-3.35,46.78,46.78,0,0,1,19.9,7.35,58.75,58.75,0,0,0,10.67,5.76c3.61,1.34,7.49,2.09,11,.16a.25.25,0,0,0-.25-.43c-3.85,2.09-8.11.84-11.91-.71a68.39,68.39,0,0,1-10-5.68A46.37,46.37,0,0,0,594,150.62a36.28,36.28,0,0,0-19.08,2.4c-6,2.6-10.73,7.15-14.8,12.18-4.68,5.78-8.68,12.13-14,17.38-.17.17,0,.51.24.42,7.42-2.75,15.31.45,22.11,3.48,6.48,2.9,12.92,6.31,19.95,7.67,5.51,1.07,11.51.91,16.64-1.55a18.54,18.54,0,0,0,6.8-5.66c2-2.65,3.53-5.64,5.47-8.35a42.88,42.88,0,0,1,17.55-14.22c.29-.13,0-.56-.25-.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M629.82,165.43a47.18,47.18,0,0,0-13.45,8c-4.13,3.74-6.15,9.36-10.77,12.59s-11.1,3.67-16.65,2.59c-6.12-1.18-11.78-3.89-17.62-5.94a45.38,45.38,0,0,0-9.81-2.41,25.66,25.66,0,0,0-5.4-.08,22.15,22.15,0,0,0-2.29.33l-.88.2c-.29.07-.58.16-.87.24-.42.27-.7.2-.86-.22.24.21.59-.14.35-.36-.74-.68-1.71,1.32.08,1.18a13.43,13.43,0,0,0,1.87-.46,19.68,19.68,0,0,1,3.05-.45,26.79,26.79,0,0,1,5.37.18c7.12.91,13.6,4.17,20.33,6.47,5.75,2,11.94,3.2,18,1.68a17,17,0,0,0,7.22-3.77c2.25-2,3.85-4.69,5.59-7.16,4.1-5.83,10.47-9.45,16.93-12.15.29-.13.16-.61-.14-.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M548.43,181.48c1.2-1.16,2.65-2,3.89-3.15a57.61,57.61,0,0,0,5.08-5.73c3.47-4.14,6.88-8.39,11-11.95a30.91,30.91,0,0,1,13.58-7.29c5.12-1.08,10,.17,14.78,1.86s9.76,3.49,15,2.64c.32-.05.18-.53-.13-.48-5.13.83-10-1-14.72-2.65-4.51-1.57-9.06-2.81-13.86-2.07-10.55,1.64-18,10.12-24.51,17.86-2,2.35-3.91,4.77-6,7-1.32,1.36-3,2.31-4.37,3.63a.25.25,0,0,0,.35.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M626.74,164.72c-4.83-1.23-9.71-2.29-14.59-3.29s-9.63-2.07-14.47-2.94a45.71,45.71,0,0,0-6.9-.79c-2.39-.06-4.77.23-7.15.27a36.26,36.26,0,0,0-20.33,7.1c-.26.18,0,.62.25.43,5.61-3.93,12.26-6.8,19.2-7,2.2-.06,4.4-.25,6.6-.3a39.26,39.26,0,0,1,7.26.62c4.68.78,9.31,1.88,14,2.81,5.37,1.09,10.73,2.23,16,3.58a.25.25,0,0,0,.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M628.37,165c-9.55,2.27-19.29.55-28.82-.94-9.14-1.43-18.34-2.45-27.45-.21a48,48,0,0,0-14.18,6.07c-.27.17,0,.6.25.43A48.11,48.11,0,0,1,585.4,163c9.38.28,18.52,2.66,27.84,3.41a49,49,0,0,0,15.27-.93c.31-.07.18-.55-.14-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M627.22,165.83c-9.57,3.29-19.71,4.08-29.77,3.89-5-.1-10-.43-15-.79-5.21-.39-10.49-1-15.71-.32a21.15,21.15,0,0,0-12.46,5.59c-.23.22.12.58.35.35,7.32-7.26,18.48-5.81,27.82-5.12,9.73.71,19.54,1.29,29.27.26a70.47,70.47,0,0,0,15.66-3.38c.3-.1.17-.59-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M622.68,167.66a67.36,67.36,0,0,1-29.81,6.79,125.17,125.17,0,0,1-16.15-1.64c-5-.68-10.3-1.39-15.3-.51-3.93.69-7.91,3.58-10.5,6.53-.21.24.14.6.35.35,2.51-2.85,6.34-5.64,10.14-6.37,5.39-1,11.26-.08,16.62.68A108.75,108.75,0,0,0,593.1,175a67.28,67.28,0,0,0,29.83-6.86c.29-.14,0-.57-.25-.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M551.63,181.62a35.42,35.42,0,0,1,13.15-5.3,39.66,39.66,0,0,1,15,.48c4.6.92,9.13,2,13.84,2a44.36,44.36,0,0,0,13.15-1.94,48.51,48.51,0,0,0,13.5-6.5c.27-.19,0-.62-.25-.44a46.41,46.41,0,0,1-26.4,8.38c-5,0-9.76-1.26-14.62-2.19a39.55,39.55,0,0,0-13.55-.44,35.74,35.74,0,0,0-14.12,5.49c-.26.18,0,.61.26.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M618.09,170.79c-6.23,6.1-13,12.61-21.88,14.26-9.08,1.68-17.14-3.93-25.69-5.91a33.31,33.31,0,0,0-14.26-.42c-.31.06-.18.54.13.48a33,33,0,0,1,14.45.53c4.36,1.05,8.43,2.88,12.65,4.36a25.09,25.09,0,0,0,12.21,1.55,28.85,28.85,0,0,0,11.5-4.77,80.65,80.65,0,0,0,11.24-9.73.25.25,0,0,0-.35-.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M633.18,164.8A42.92,42.92,0,0,1,655,167.52c3.23,1.31,6.22,3.15,9.46,4.42a18.55,18.55,0,0,0,8.87,1.28c13.59-1.46,21.06-14.4,28-24.55,4.16-6.08,9.2-12.88,16.89-14.4a.25.25,0,0,0,0-.48c-13.68-3.18-26.32-12.47-40.92-9.94-12.53,2.17-22.84,11.55-28.48,22.62-1.91,3.75-3.56,7.63-6.07,11-2.39,3.25-5.35,6.48-9.66,6.69-.32,0-.32.52,0,.5,6.41-.31,10.59-7,13.24-12.08,1.65-3.18,3-6.47,4.94-9.51a44.61,44.61,0,0,1,6.11-7.77,38.68,38.68,0,0,1,16.66-10.23c6.86-2,13.83-1.24,20.57.88,7.93,2.49,15.35,6.39,23.47,8.28v-.48c-6,1.19-10.52,5.66-14.11,10.35-4.26,5.56-7.67,11.73-12.15,17.13-4.29,5.19-9.89,9.72-16.62,11.15a18.15,18.15,0,0,1-10.21-.81c-3.24-1.21-6.2-3-9.39-4.38a43.43,43.43,0,0,0-22.59-2.91c-.32.05-.19.53.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M638.12,163.55c6.2-1,13.15-1.45,19.14.8,3.24,1.22,6.28,3,9.68,3.74a16.83,16.83,0,0,0,8.68-.5c12.85-4.09,17.86-18,27.39-26.27a27,27,0,0,1,9.46-5.29c.53-.18,1.54-.59,1.1-1.32a.6.6,0,0,0-1.11.07c-.08.31.4.45.48.14-.24.88-2.81,1.34-3.63,1.7a24.46,24.46,0,0,0-3.77,2.12,35.34,35.34,0,0,0-5.91,5.15c-4.11,4.38-7.34,9.49-11.29,14s-8.83,8.51-14.83,9.72a16.85,16.85,0,0,1-9.39-.91c-2.69-1-5.22-2.44-8-3.26-5.82-1.75-12.25-1.31-18.17-.38-.31.05-.18.53.14.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M715.83,133.58c-1.73-.39-3.53-.33-5.26-.7a84.92,84.92,0,0,1-8.22-2.56c-5.49-1.83-11-3.72-16.8-4.36a28.63,28.63,0,0,0-15,1.94c-4.13,1.87-7.17,5.13-10,8.58-3.23,4-6.42,8.16-11.3,10.16-.3.12-.17.61.13.49,8.2-3.36,11.42-12.41,18.51-17.19,9.77-6.59,22.27-3.13,32.49.27,3.12,1,6.23,2.12,9.4,3,1.92.52,3.93.43,5.87.86.31.07.44-.41.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M640.59,161.19c6.66-6.88,13.71-13.43,20.77-19.9a52.27,52.27,0,0,1,5.35-4.37c1.82-1.25,3.81-2.16,5.71-3.26a31.91,31.91,0,0,1,6-3c1.28-.43,2.56-.87,3.86-1.24a36.07,36.07,0,0,1,12.2-1.06c.33,0,.32-.48,0-.5-6.92-.41-14.18.66-20.28,4.17-3.84,2.22-7.69,4.18-11.09,7.06-3.56,3-6.86,6.37-10.27,9.55-4.27,4-8.51,8-12.56,12.18-.23.23.13.58.35.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M639,162.4c17.38-5.71,27.69-22.8,44.6-29.43a47.55,47.55,0,0,1,18-3.26.25.25,0,0,0,0-.5,49,49,0,0,0-28.25,8.72c-7.4,5-13.71,11.47-20.93,16.74a48.72,48.72,0,0,1-13.52,7.24c-.3.1-.17.59.14.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M640.54,162.52c9.16-2.05,17.63-6.19,25.56-11.12,4-2.5,7.94-5.2,11.8-8S685.8,137.5,690,135c5-3,10.91-5,16.77-3.61.31.07.44-.41.13-.48-9.92-2.3-18.56,4.35-26.1,9.8-7.89,5.71-15.9,11.37-24.67,15.67A71.29,71.29,0,0,1,640.41,162c-.32.07-.18.55.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M645.24,161.71a67.84,67.84,0,0,0,28.24-9.32c8.6-5.17,15.33-13,24.21-17.74a15.25,15.25,0,0,1,7.23-2,23,23,0,0,1,7.15.89c.31.1.44-.39.13-.48a21.19,21.19,0,0,0-11.55-.34,24.78,24.78,0,0,0-5.93,3,69.23,69.23,0,0,0-5.83,4.23c-4.08,3.24-7.94,6.76-12.24,9.72a66.56,66.56,0,0,1-14,7.35,67.65,67.65,0,0,1-17.44,4.11c-.32,0-.33.54,0,.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M713.05,135.36a36.12,36.12,0,0,0-25,9.11c-3.69,3.22-7,6.87-11,9.65A43.4,43.4,0,0,1,664.48,160a48.25,48.25,0,0,1-15.81,1.8c-.32,0-.32.48,0,.5a47.2,47.2,0,0,0,25.82-6c8.24-4.74,13.7-13,22.37-17.11a35.33,35.33,0,0,1,16.19-3.33c.32,0,.32-.49,0-.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M650.66,162c8.76,2,18.29,4,26.94.44,8.09-3.39,11.91-11.71,17.74-17.72a33.57,33.57,0,0,1,12.38-8.39c.3-.11.17-.59-.13-.48-7.63,2.78-13.23,8.67-18,15-2.69,3.53-5.4,7.22-9.22,9.61a25.17,25.17,0,0,1-12.85,3.46c-5.66.11-11.22-1.16-16.71-2.4-.31-.08-.45.41-.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M461.9,212.85a43.28,43.28,0,0,0-15.46,11.49c-4.23,5-6.58,11.78-12.14,15.49-5.26,3.5-12,3.92-18.11,2.82-7.13-1.31-13.64-4.76-20.2-7.69-6.91-3.08-14.94-6.33-22.49-3.53l.24.41c9.25-9.14,14.8-22.37,26.76-28.57a35.19,35.19,0,0,1,20-3.34,46.74,46.74,0,0,1,19.91,7.35A58.33,58.33,0,0,0,451,213c3.61,1.34,7.5,2.08,11,.16a.25.25,0,0,0-.25-.44c-3.85,2.1-8.11.85-11.91-.7a67.77,67.77,0,0,1-10-5.68,46.63,46.63,0,0,0-18.63-6.86,36.39,36.39,0,0,0-19.08,2.41c-6,2.6-10.73,7.15-14.8,12.18-4.68,5.78-8.68,12.13-14,17.38-.17.17,0,.51.24.42,7.42-2.75,15.32.45,22.11,3.48,6.48,2.89,12.92,6.3,19.95,7.67,5.51,1.07,11.51.91,16.64-1.55a18.54,18.54,0,0,0,6.8-5.66c2-2.66,3.53-5.64,5.47-8.35a42.88,42.88,0,0,1,17.55-14.22c.29-.13,0-.56-.25-.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M457.1,214.34a46.87,46.87,0,0,0-13.46,8c-4.13,3.73-6.14,9.35-10.76,12.59-4.78,3.34-11.11,3.67-16.66,2.59-6.12-1.18-11.78-3.9-17.62-5.94a45.38,45.38,0,0,0-9.81-2.41,26.35,26.35,0,0,0-5.4-.09c-.77.08-1.53.19-2.29.34l-.88.2q-.44.1-.87.24c-.42.27-.7.2-.86-.23.24.22.59-.13.36-.35-.74-.68-1.72,1.31.07,1.18a13.92,13.92,0,0,0,1.88-.46,19.38,19.38,0,0,1,3-.45,26.79,26.79,0,0,1,5.37.17c7.13.92,13.6,4.18,20.33,6.48,5.75,2,11.95,3.2,17.95,1.67a16.78,16.78,0,0,0,7.22-3.77c2.26-2,3.85-4.68,5.59-7.15,4.1-5.83,10.47-9.45,16.93-12.15.29-.13.16-.61-.13-.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M375.7,230.39c1.2-1.16,2.65-2,3.89-3.15a57.61,57.61,0,0,0,5.08-5.73c3.47-4.14,6.88-8.39,11-11.95a30.88,30.88,0,0,1,13.57-7.29c5.12-1.08,10,.17,14.78,1.85s9.76,3.5,15,2.65c.32-.05.18-.53-.13-.48-5.13.83-10-1-14.72-2.65-4.5-1.57-9.06-2.81-13.86-2.07-10.55,1.64-18,10.12-24.51,17.86-2,2.35-3.91,4.76-6,7-1.32,1.36-3,2.31-4.37,3.63-.23.22.12.58.35.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M454,213.63c-4.83-1.24-9.71-2.29-14.59-3.29s-9.63-2.07-14.47-2.94a44.46,44.46,0,0,0-6.9-.79c-2.39-.07-4.77.23-7.15.27a36.86,36.86,0,0,0-9.6,1.64A36.39,36.39,0,0,0,390.57,214c-.26.18,0,.62.25.43,5.61-3.93,12.26-6.8,19.2-7,2.2-.07,4.4-.25,6.61-.3a39.21,39.21,0,0,1,7.25.61c4.68.78,9.31,1.89,14,2.82,5.37,1.08,10.73,2.22,16,3.58a.25.25,0,0,0,.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M455.64,213.93c-9.55,2.27-19.29.55-28.82-.94-9.14-1.43-18.34-2.45-27.45-.21a48,48,0,0,0-14.18,6.07c-.27.17,0,.6.25.43a48.11,48.11,0,0,1,27.23-7.35c9.38.28,18.52,2.66,27.84,3.41a49,49,0,0,0,15.27-.93c.31-.07.18-.56-.14-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M454.49,214.74c-9.57,3.29-19.71,4.08-29.77,3.89-5-.1-10-.43-15-.79-5.22-.39-10.5-1-15.71-.32a21.23,21.23,0,0,0-12.47,5.58.25.25,0,0,0,.35.36c7.32-7.26,18.48-5.81,27.83-5.12,9.72.71,19.53,1.29,29.26.26a71,71,0,0,0,15.66-3.38c.3-.11.17-.59-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M450,216.56a67.23,67.23,0,0,1-29.81,6.8A125.17,125.17,0,0,1,404,221.72c-5-.69-10.3-1.39-15.3-.51-3.93.69-7.91,3.58-10.5,6.53-.21.24.14.59.35.35,2.52-2.85,6.35-5.64,10.14-6.37,5.39-1,11.26-.08,16.62.68a108.75,108.75,0,0,0,15.07,1.46,66.67,66.67,0,0,0,14.22-1.48A67.86,67.86,0,0,0,450.2,217c.29-.14,0-.58-.25-.44Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M378.9,230.53a35.42,35.42,0,0,1,13.15-5.3,39.72,39.72,0,0,1,15.05.48c4.59.92,9.12,2,13.83,2a44.36,44.36,0,0,0,13.15-1.94,48.4,48.4,0,0,0,13.5-6.51c.27-.18,0-.61-.25-.43a46.41,46.41,0,0,1-26.4,8.38c-5,0-9.76-1.26-14.62-2.19a39.06,39.06,0,0,0-13.55-.44,35.64,35.64,0,0,0-14.11,5.49c-.27.17,0,.61.25.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M445.36,219.69c-6.23,6.11-13,12.62-21.88,14.27-9.08,1.68-17.14-3.93-25.69-5.91a33.31,33.31,0,0,0-14.26-.43c-.31.07-.18.55.13.49a33,33,0,0,1,14.45.53c4.36,1.05,8.43,2.87,12.65,4.35A25.09,25.09,0,0,0,423,234.55a28.79,28.79,0,0,0,11.5-4.77,80.65,80.65,0,0,0,11.24-9.73.25.25,0,0,0-.35-.36Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M460.45,213.71a42.92,42.92,0,0,1,21.85,2.72c3.23,1.31,6.22,3.15,9.46,4.42a18.79,18.79,0,0,0,8.87,1.28c13.59-1.46,21.06-14.4,28-24.55,4.16-6.08,9.2-12.88,16.89-14.4a.25.25,0,0,0,0-.48c-13.68-3.18-26.32-12.47-40.92-9.94-12.53,2.17-22.84,11.55-28.48,22.62-1.91,3.75-3.56,7.63-6.07,11-2.39,3.25-5.35,6.48-9.66,6.69-.32,0-.32.52,0,.5,6.41-.32,10.59-7,13.24-12.09,1.65-3.17,3.06-6.46,4.94-9.51a45.37,45.37,0,0,1,6.11-7.77A38.82,38.82,0,0,1,501.35,174c6.86-2,13.83-1.24,20.57.88,7.93,2.49,15.36,6.39,23.47,8.28v-.48c-6,1.19-10.52,5.66-14.11,10.35-4.26,5.56-7.67,11.73-12.14,17.13-4.3,5.19-9.9,9.72-16.63,11.14a18.06,18.06,0,0,1-10.21-.8c-3.24-1.21-6.2-3-9.39-4.38a43.43,43.43,0,0,0-22.59-2.91c-.32,0-.19.53.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M465.39,212.46c6.2-1,13.15-1.45,19.14.8,3.24,1.21,6.28,3,9.69,3.74a16.91,16.91,0,0,0,8.67-.5c12.85-4.1,17.86-18,27.39-26.27a27,27,0,0,1,9.46-5.29c.53-.18,1.54-.59,1.1-1.32a.6.6,0,0,0-1.11.07.25.25,0,0,0,.48.13c-.24.89-2.81,1.35-3.63,1.71a23.65,23.65,0,0,0-3.77,2.12,34.88,34.88,0,0,0-5.9,5.15c-4.12,4.38-7.34,9.49-11.3,14s-8.83,8.51-14.83,9.72a16.85,16.85,0,0,1-9.39-.91c-2.69-1-5.22-2.44-8-3.26-5.82-1.75-12.25-1.31-18.17-.38-.31.05-.18.53.14.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M543.1,182.49c-1.73-.39-3.53-.33-5.26-.71a82.17,82.17,0,0,1-8.22-2.55c-5.49-1.83-11-3.72-16.8-4.36a28.63,28.63,0,0,0-15,1.94c-4.13,1.87-7.17,5.13-10,8.58-3.23,4-6.42,8.16-11.3,10.16-.3.12-.17.61.13.48,8.2-3.35,11.42-12.4,18.51-17.18,9.77-6.59,22.27-3.13,32.49.27,3.12,1,6.23,2.12,9.4,3,1.92.53,3.94.44,5.87.87.31.07.44-.41.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M467.86,210.1c6.66-6.88,13.71-13.43,20.77-19.9a51,51,0,0,1,5.35-4.37c1.82-1.25,3.81-2.16,5.72-3.26a31.52,31.52,0,0,1,5.95-3c1.28-.43,2.56-.87,3.86-1.25a36.36,36.36,0,0,1,12.21-1.05c.32,0,.32-.48,0-.5-6.93-.41-14.19.66-20.29,4.17-3.84,2.22-7.69,4.18-11.09,7.06-3.56,3-6.86,6.37-10.27,9.55-4.27,4-8.51,8-12.56,12.18-.23.23.13.58.35.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M466.29,211.3c17.38-5.71,27.69-22.79,44.6-29.43a47.54,47.54,0,0,1,18-3.25.25.25,0,0,0,0-.5,49,49,0,0,0-28.25,8.72c-7.4,5-13.71,11.47-20.93,16.74a48.72,48.72,0,0,1-13.52,7.24c-.3.1-.17.58.14.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M467.81,211.43c9.16-2.05,17.63-6.19,25.56-11.12,4-2.5,7.94-5.2,11.8-8s7.9-5.94,12.14-8.44c5-3,10.91-5,16.77-3.61.31.07.44-.41.13-.48-9.92-2.3-18.56,4.35-26.1,9.8-7.89,5.71-15.9,11.37-24.66,15.67A71.55,71.55,0,0,1,467.68,211c-.31.07-.18.55.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M472.51,210.62a67.84,67.84,0,0,0,28.24-9.32c8.6-5.17,15.34-13,24.21-17.74a15.25,15.25,0,0,1,7.23-1.95,23,23,0,0,1,7.15.89c.31.1.44-.39.13-.48a21.19,21.19,0,0,0-11.55-.34,24.42,24.42,0,0,0-5.93,3,67.06,67.06,0,0,0-5.83,4.23c-4.08,3.24-7.94,6.76-12.24,9.72A66.78,66.78,0,0,1,490,206a67.65,67.65,0,0,1-17.44,4.11c-.32,0-.32.54,0,.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M540.32,184.27a36.14,36.14,0,0,0-25,9.11c-3.69,3.22-7,6.86-11.05,9.65a43.4,43.4,0,0,1-12.53,5.88,48.25,48.25,0,0,1-15.81,1.8c-.32,0-.32.48,0,.5a47.2,47.2,0,0,0,25.82-6c8.24-4.74,13.7-13,22.37-17.11a35.33,35.33,0,0,1,16.19-3.33c.32,0,.32-.49,0-.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M477.93,210.87c8.76,2,18.29,4,26.94.44,8.09-3.39,11.91-11.71,17.74-17.72A33.45,33.45,0,0,1,535,185.2c.3-.11.17-.59-.13-.48-7.63,2.77-13.23,8.67-18,15-2.69,3.54-5.4,7.23-9.22,9.62a25.14,25.14,0,0,1-12.84,3.46c-5.67.11-11.23-1.16-16.72-2.41-.31-.07-.45.42-.13.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M647.36,226.14a43.37,43.37,0,0,0-15.46,11.49c-4.23,5-6.58,11.78-12.14,15.49-5.26,3.5-12,3.93-18.11,2.82-7.13-1.3-13.64-4.76-20.2-7.69-6.91-3.08-14.94-6.33-22.49-3.53l.25.42C568.45,236,574,222.76,586,216.57a35.13,35.13,0,0,1,20-3.35,47,47,0,0,1,19.9,7.35,58.66,58.66,0,0,0,10.66,5.76c3.61,1.35,7.5,2.09,11,.16a.25.25,0,0,0-.25-.43c-3.84,2.09-8.11.84-11.91-.71a66.89,66.89,0,0,1-10-5.68,46.56,46.56,0,0,0-18.63-6.85,36.38,36.38,0,0,0-19.08,2.4c-6,2.6-10.72,7.15-14.8,12.18-4.68,5.78-8.68,12.13-14,17.38-.17.17,0,.51.25.42,7.41-2.74,15.31.45,22.1,3.48,6.48,2.9,12.92,6.31,19.95,7.67,5.51,1.07,11.51.91,16.65-1.54a18.72,18.72,0,0,0,6.79-5.67c2-2.65,3.53-5.64,5.47-8.35a43,43,0,0,1,17.55-14.22c.29-.13,0-.56-.25-.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M642.56,227.64a46.84,46.84,0,0,0-13.46,8C625,239.4,623,245,618.34,248.25s-11.11,3.67-16.66,2.59c-6.11-1.18-11.77-3.89-17.62-5.94a45.38,45.38,0,0,0-9.81-2.41,26.34,26.34,0,0,0-5.4-.08,22.15,22.15,0,0,0-2.29.33l-.87.2-.88.24c-.41.27-.7.2-.86-.22.24.22.59-.14.36-.36-.74-.68-1.72,1.32.07,1.18a13.56,13.56,0,0,0,1.88-.46,20.83,20.83,0,0,1,3-.45,26.79,26.79,0,0,1,5.37.18c7.13.91,13.6,4.17,20.33,6.47,5.75,2,12,3.21,18,1.68a16.91,16.91,0,0,0,7.21-3.77c2.26-2.05,3.85-4.69,5.59-7.16,4.1-5.83,10.47-9.45,16.93-12.15.29-.12.16-.61-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M561.16,243.69c1.2-1.17,2.65-2,3.89-3.16a57.61,57.61,0,0,0,5.08-5.73c3.47-4.13,6.88-8.39,11-12a30.84,30.84,0,0,1,13.58-7.28c5.11-1.08,10,.17,14.78,1.85s9.75,3.49,15,2.64c.32-.05.19-.53-.13-.48-5.12.84-10-1-14.72-2.64-4.5-1.57-9.06-2.82-13.86-2.07-10.54,1.63-18,10.11-24.51,17.85-2,2.36-3.91,4.77-6,7-1.32,1.37-3,2.31-4.37,3.63a.25.25,0,0,0,.35.36Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M639.47,226.92c-4.83-1.23-9.7-2.29-14.59-3.28s-9.63-2.08-14.46-2.95a46,46,0,0,0-6.91-.79c-2.39-.06-4.76.23-7.15.27a36.26,36.26,0,0,0-20.33,7.1c-.26.19,0,.62.25.43,5.61-3.93,12.26-6.8,19.2-7,2.21-.06,4.41-.25,6.61-.29a39.14,39.14,0,0,1,7.25.61c4.68.78,9.32,1.88,14,2.82,5.37,1.08,10.73,2.22,16,3.57a.25.25,0,0,0,.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M641.11,227.22c-9.56,2.27-19.29.55-28.83-.94-9.13-1.43-18.34-2.45-27.44-.21a48.23,48.23,0,0,0-14.19,6.07.25.25,0,0,0,.26.43,48.06,48.06,0,0,1,27.22-7.35c9.38.28,18.52,2.67,27.85,3.41a48.9,48.9,0,0,0,15.26-.93c.31-.07.18-.55-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M640,228c-9.57,3.29-19.71,4.08-29.77,3.89-5-.1-10-.42-15-.79-5.22-.39-10.5-1-15.71-.32A21.24,21.24,0,0,0,567,236.4c-.23.22.12.58.35.35,7.32-7.26,18.49-5.81,27.83-5.12,9.72.71,19.54,1.3,29.26.26a70.47,70.47,0,0,0,15.66-3.38c.31-.1.18-.59-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M635.41,229.86a67.32,67.32,0,0,1-29.8,6.79A125.14,125.14,0,0,1,589.45,235c-5-.68-10.3-1.39-15.29-.51-3.94.69-7.92,3.59-10.51,6.53-.21.24.14.6.35.35,2.52-2.85,6.35-5.64,10.14-6.37,5.39-1,11.26-.08,16.62.68a110.48,110.48,0,0,0,15.07,1.47,67.49,67.49,0,0,0,29.83-6.87c.29-.14,0-.57-.25-.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M564.36,243.82a35.42,35.42,0,0,1,13.15-5.3,39.72,39.72,0,0,1,15,.49c4.59.91,9.13,2,13.84,2a44.67,44.67,0,0,0,13.14-1.93,48.74,48.74,0,0,0,13.51-6.51c.26-.18,0-.62-.26-.43a46.46,46.46,0,0,1-26.39,8.37c-5,0-9.77-1.26-14.63-2.19a39.55,39.55,0,0,0-13.55-.44,35.64,35.64,0,0,0-14.11,5.49c-.27.18,0,.61.25.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M630.82,233c-6.23,6.1-13,12.61-21.88,14.26-9.08,1.68-17.14-3.93-25.69-5.91a33.47,33.47,0,0,0-14.26-.42c-.31.06-.18.54.14.48a33.07,33.07,0,0,1,14.44.53c4.36,1,8.43,2.88,12.65,4.36a25.09,25.09,0,0,0,12.21,1.55,28.89,28.89,0,0,0,11.51-4.77,81.15,81.15,0,0,0,11.23-9.73c.23-.22-.12-.58-.35-.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M645.91,227a42.85,42.85,0,0,1,21.86,2.71c3.23,1.31,6.21,3.15,9.46,4.42a18.52,18.52,0,0,0,8.86,1.28c13.59-1.46,21.06-14.4,28-24.54,4.16-6.09,9.2-12.88,16.89-14.41.24,0,.23-.42,0-.48-13.67-3.18-26.32-12.47-40.92-9.94-12.53,2.17-22.84,11.56-28.48,22.62-1.91,3.75-3.56,7.63-6.07,11-2.39,3.25-5.35,6.48-9.65,6.69-.32,0-.33.52,0,.5,6.41-.31,10.59-7,13.24-12.08,1.64-3.18,3-6.47,4.94-9.51a44.13,44.13,0,0,1,6.11-7.77,38.65,38.65,0,0,1,16.65-10.23c6.87-2,13.84-1.24,20.58.88,7.92,2.49,15.35,6.39,23.47,8.28V196c-6,1.19-10.53,5.66-14.12,10.35-4.25,5.57-7.67,11.73-12.14,17.14-4.3,5.18-9.9,9.71-16.63,11.14a18.15,18.15,0,0,1-10.21-.8c-3.24-1.22-6.2-3-9.39-4.39a43.52,43.52,0,0,0-22.59-2.91c-.32,0-.18.53.13.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M650.85,225.75c6.21-1,13.15-1.44,19.15.8,3.24,1.22,6.27,3,9.68,3.74a16.85,16.85,0,0,0,8.68-.5c12.84-4.09,17.85-18,27.39-26.27a27.07,27.07,0,0,1,9.45-5.29c.54-.18,1.54-.59,1.11-1.32a.61.61,0,0,0-1.12.08.25.25,0,0,0,.49.13c-.25.88-2.82,1.34-3.64,1.71a23.6,23.6,0,0,0-3.77,2.11,35.68,35.68,0,0,0-5.9,5.15c-4.12,4.38-7.34,9.49-11.3,14s-8.83,8.51-14.83,9.72a16.8,16.8,0,0,1-9.38-.91c-2.69-1-5.23-2.43-8-3.26-5.82-1.75-12.24-1.31-18.17-.38-.31,0-.18.53.14.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M728.56,195.78c-1.72-.38-3.53-.32-5.25-.7a84.25,84.25,0,0,1-8.23-2.56c-5.49-1.83-11-3.72-16.8-4.36a28.63,28.63,0,0,0-15,1.94c-4.13,1.87-7.17,5.13-10,8.59-3.23,4-6.42,8.16-11.3,10.16-.29.12-.17.6.13.48,8.2-3.36,11.42-12.41,18.52-17.19,9.76-6.58,22.26-3.13,32.48.27,3.12,1,6.23,2.12,9.4,3,1.92.52,3.94.43,5.87.86.31.07.45-.41.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M653.32,223.39C660,216.51,667,210,674.09,203.5a50.75,50.75,0,0,1,5.35-4.37c1.82-1.26,3.81-2.17,5.72-3.27a32.13,32.13,0,0,1,6-3c1.28-.42,2.56-.87,3.86-1.24a36.1,36.1,0,0,1,12.21-1.06c.32,0,.32-.48,0-.5-6.93-.41-14.19.66-20.29,4.18-3.84,2.21-7.69,4.17-11.09,7.05-3.56,3-6.86,6.37-10.27,9.55-4.27,4-8.51,8-12.56,12.18-.23.23.13.59.35.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M651.75,224.6c17.38-5.71,27.7-22.8,44.61-29.43a47.66,47.66,0,0,1,17.95-3.26.25.25,0,0,0,0-.5,49,49,0,0,0-28.24,8.72c-7.41,5-13.72,11.47-20.94,16.75a49.34,49.34,0,0,1-13.51,7.24c-.31.1-.18.58.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M653.27,224.72c9.16-2.05,17.63-6.19,25.56-11.11,4-2.51,7.94-5.21,11.8-8s7.9-5.94,12.14-8.44c5-3,10.91-5,16.77-3.61.31.07.45-.41.13-.48-9.92-2.3-18.55,4.35-26.1,9.8-7.89,5.71-15.9,11.38-24.66,15.67a71.37,71.37,0,0,1-15.77,5.66c-.31.07-.18.55.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M658,223.92a68,68,0,0,0,28.24-9.33c8.6-5.17,15.34-13,24.21-17.74a15.39,15.39,0,0,1,7.23-1.94,22.71,22.71,0,0,1,7.15.88c.31.1.44-.38.14-.48a21.2,21.2,0,0,0-11.55-.34,24.42,24.42,0,0,0-5.93,3,69.39,69.39,0,0,0-5.84,4.23c-4.08,3.25-7.94,6.76-12.23,9.72A67.06,67.06,0,0,1,658,223.42c-.32,0-.32.53,0,.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M725.78,197.56a36.14,36.14,0,0,0-25,9.11c-3.69,3.22-7,6.87-11,9.66a43.52,43.52,0,0,1-12.53,5.87A48.25,48.25,0,0,1,661.4,224c-.32,0-.32.48,0,.5a47.25,47.25,0,0,0,25.83-6c8.23-4.74,13.69-13,22.36-17.1a35.23,35.23,0,0,1,16.19-3.34c.32,0,.32-.49,0-.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M663.39,224.16c8.76,2,18.3,4.06,26.94.44,8.09-3.39,11.91-11.71,17.75-17.72a33.53,33.53,0,0,1,12.37-8.39c.3-.1.17-.59-.13-.48-7.63,2.78-13.23,8.67-18,15-2.68,3.54-5.4,7.22-9.22,9.61a25,25,0,0,1-12.84,3.46c-5.66.11-11.23-1.16-16.72-2.4-.31-.07-.45.41-.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M474.63,275.05a43.37,43.37,0,0,0-15.46,11.49c-4.23,5-6.57,11.78-12.14,15.49-5.26,3.5-12,3.93-18.11,2.82-7.13-1.31-13.64-4.76-20.2-7.69-6.91-3.08-14.94-6.33-22.49-3.53l.25.42c9.24-9.15,14.8-22.38,26.75-28.57a35.13,35.13,0,0,1,20-3.35,47,47,0,0,1,19.9,7.35,58.66,58.66,0,0,0,10.66,5.76c3.61,1.34,7.5,2.09,11,.16a.25.25,0,0,0-.25-.43c-3.84,2.09-8.11.84-11.91-.71a67.77,67.77,0,0,1-10-5.68A46.36,46.36,0,0,0,434,261.73a36.25,36.25,0,0,0-19.07,2.4c-6,2.6-10.73,7.15-14.81,12.18-4.68,5.78-8.68,12.13-14,17.38-.17.17,0,.51.25.42,7.41-2.75,15.31.45,22.1,3.48,6.48,2.9,12.92,6.31,19.95,7.67,5.51,1.07,11.51.91,16.65-1.55a18.59,18.59,0,0,0,6.79-5.66c2-2.65,3.54-5.64,5.47-8.35a43,43,0,0,1,17.55-14.22c.29-.13,0-.56-.25-.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M469.83,276.54a47.09,47.09,0,0,0-13.46,8c-4.13,3.74-6.14,9.36-10.76,12.59-4.77,3.34-11.11,3.67-16.66,2.59-6.11-1.18-11.77-3.89-17.62-5.94a45.38,45.38,0,0,0-9.81-2.41,25.65,25.65,0,0,0-5.4-.08,21.58,21.58,0,0,0-2.28.33l-.88.2c-.3.07-.59.16-.88.24-.41.27-.7.2-.86-.22.24.21.59-.14.36-.36-.74-.68-1.72,1.32.07,1.18a13.56,13.56,0,0,0,1.88-.46,19.56,19.56,0,0,1,3-.45,26.79,26.79,0,0,1,5.37.18c7.13.91,13.6,4.17,20.33,6.47,5.75,2,12,3.2,18,1.68a16.91,16.91,0,0,0,7.21-3.77c2.26-2,3.85-4.69,5.59-7.16,4.1-5.83,10.47-9.45,16.93-12.15.29-.13.16-.61-.13-.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M388.43,292.59c1.2-1.16,2.65-2,3.89-3.15a57.61,57.61,0,0,0,5.08-5.73c3.47-4.14,6.88-8.39,11-11.95A30.86,30.86,0,0,1,422,264.47c5.11-1.08,10,.17,14.78,1.86s9.75,3.49,15,2.64c.32-.05.19-.53-.13-.48-5.12.83-10-1-14.72-2.65-4.5-1.57-9.06-2.81-13.86-2.07-10.54,1.64-18,10.12-24.51,17.86-2,2.35-3.9,4.77-6,7-1.32,1.36-3,2.31-4.37,3.63a.25.25,0,0,0,.35.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M466.74,275.83c-4.83-1.23-9.7-2.29-14.59-3.29s-9.63-2.07-14.46-2.94a45.84,45.84,0,0,0-6.91-.79c-2.39-.06-4.76.23-7.15.27a36.26,36.26,0,0,0-20.33,7.1c-.26.18,0,.62.25.43,5.61-3.93,12.26-6.8,19.2-7,2.21-.06,4.41-.25,6.61-.3a39.15,39.15,0,0,1,7.25.62c4.68.78,9.32,1.88,14,2.81,5.37,1.09,10.73,2.23,16,3.58a.25.25,0,0,0,.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M468.38,276.13c-9.56,2.27-19.29.55-28.83-.94-9.13-1.43-18.34-2.45-27.44-.21a48.08,48.08,0,0,0-14.19,6.07.25.25,0,0,0,.26.43,48.09,48.09,0,0,1,27.23-7.35c9.37.28,18.51,2.66,27.84,3.41a48.9,48.9,0,0,0,15.26-.93c.31-.07.18-.55-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M467.22,276.94c-9.57,3.29-19.71,4.08-29.77,3.89-5-.1-10-.43-15-.79-5.22-.39-10.5-1-15.71-.32a21.18,21.18,0,0,0-12.47,5.59c-.23.22.12.58.35.35,7.32-7.26,18.49-5.81,27.83-5.12,9.73.71,19.54,1.29,29.26.26a70.47,70.47,0,0,0,15.66-3.38c.31-.1.18-.59-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M462.68,278.77a67.32,67.32,0,0,1-29.8,6.79,125.14,125.14,0,0,1-16.16-1.64c-5-.68-10.29-1.39-15.29-.51-3.94.69-7.91,3.58-10.51,6.53-.21.24.14.6.35.35,2.52-2.85,6.35-5.64,10.15-6.37,5.38-1,11.25-.08,16.61.68a108.9,108.9,0,0,0,15.07,1.46,67.28,67.28,0,0,0,29.83-6.86c.29-.14,0-.57-.25-.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M391.63,292.73a35.42,35.42,0,0,1,13.15-5.3,39.71,39.71,0,0,1,15.05.48c4.59.92,9.13,2,13.84,2A44.46,44.46,0,0,0,446.82,288a48.51,48.51,0,0,0,13.5-6.5c.26-.19,0-.62-.26-.44a46.4,46.4,0,0,1-26.39,8.38c-5,0-9.77-1.26-14.63-2.19a39.55,39.55,0,0,0-13.55-.44,35.64,35.64,0,0,0-14.11,5.49c-.27.18,0,.61.25.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M458.09,281.9c-6.23,6.1-13,12.61-21.88,14.26-9.08,1.68-17.14-3.93-25.69-5.91a33.31,33.31,0,0,0-14.26-.42c-.31.06-.18.54.14.48a32.92,32.92,0,0,1,14.44.53c4.36,1.05,8.43,2.88,12.65,4.36a25.1,25.1,0,0,0,12.21,1.55A28.89,28.89,0,0,0,447.21,292a81.15,81.15,0,0,0,11.23-9.73.25.25,0,0,0-.35-.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M473.18,275.91A43,43,0,0,1,495,278.63c3.23,1.31,6.21,3.15,9.46,4.42a18.52,18.52,0,0,0,8.86,1.28c13.6-1.46,21.06-14.4,28-24.55,4.16-6.08,9.2-12.87,16.89-14.4a.25.25,0,0,0,0-.48c-13.67-3.18-26.32-12.47-40.92-9.94-12.53,2.17-22.84,11.55-28.48,22.62-1.91,3.75-3.56,7.63-6.06,11-2.4,3.25-5.36,6.48-9.66,6.69-.32,0-.33.52,0,.5,6.41-.31,10.59-7,13.24-12.08,1.64-3.18,3.05-6.47,4.94-9.51a44.13,44.13,0,0,1,6.11-7.77,38.65,38.65,0,0,1,16.65-10.23c6.87-2,13.84-1.24,20.58.88,7.92,2.49,15.35,6.39,23.47,8.28v-.48c-6,1.19-10.53,5.66-14.12,10.35-4.25,5.56-7.67,11.73-12.14,17.13-4.3,5.19-9.9,9.72-16.63,11.15a18.15,18.15,0,0,1-10.21-.81c-3.24-1.21-6.2-3-9.39-4.38a43.42,43.42,0,0,0-22.59-2.91c-.32.05-.18.53.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M478.12,274.66c6.21-1,13.15-1.45,19.15.8,3.24,1.22,6.27,3,9.68,3.74a16.85,16.85,0,0,0,8.68-.5c12.85-4.09,17.85-18,27.39-26.27a27.07,27.07,0,0,1,9.45-5.29c.54-.18,1.54-.59,1.11-1.32a.6.6,0,0,0-1.11.07.25.25,0,0,0,.48.14c-.25.88-2.82,1.34-3.64,1.7a24.46,24.46,0,0,0-3.77,2.12,35.68,35.68,0,0,0-5.9,5.15c-4.12,4.38-7.34,9.49-11.29,14s-8.84,8.51-14.84,9.72a16.8,16.8,0,0,1-9.38-.91c-2.69-1-5.23-2.44-8-3.26-5.82-1.75-12.24-1.31-18.16-.38-.32.05-.19.53.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M555.83,244.69c-1.72-.39-3.53-.33-5.25-.7a84.25,84.25,0,0,1-8.23-2.56c-5.49-1.83-11-3.72-16.8-4.36a28.63,28.63,0,0,0-15,1.94c-4.13,1.87-7.17,5.13-10,8.58-3.23,4-6.42,8.16-11.3,10.16-.29.12-.16.61.13.49,8.2-3.36,11.42-12.41,18.52-17.19,9.76-6.58,22.26-3.13,32.48.27,3.12,1,6.23,2.12,9.41,3,1.91.52,3.93.43,5.86.86.31.07.45-.41.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M480.59,272.3c6.66-6.88,13.72-13.43,20.77-19.9a52.39,52.39,0,0,1,5.36-4.37c1.81-1.25,3.8-2.16,5.71-3.26a31.52,31.52,0,0,1,6-3c1.28-.43,2.56-.87,3.86-1.24a36.1,36.1,0,0,1,12.21-1.06c.32,0,.32-.48,0-.5-6.93-.41-14.19.66-20.29,4.17-3.84,2.22-7.68,4.18-11.09,7.06s-6.85,6.37-10.27,9.55c-4.27,4-8.5,8-12.56,12.18-.22.23.13.58.35.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M479,273.51c17.38-5.71,27.7-22.8,44.61-29.43a47.46,47.46,0,0,1,18-3.26.25.25,0,0,0,0-.5A48.94,48.94,0,0,0,513.34,249c-7.41,5-13.72,11.47-20.94,16.74A48.8,48.8,0,0,1,478.89,273c-.31.1-.18.59.13.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M480.54,273.63c9.16-2.05,17.64-6.19,25.57-11.12,4-2.5,7.93-5.2,11.79-8s7.9-5.94,12.14-8.44c5-3,10.91-5,16.77-3.61.31.07.45-.41.13-.48-9.92-2.3-18.55,4.35-26.1,9.8-7.89,5.71-15.89,11.37-24.66,15.67a71.37,71.37,0,0,1-15.77,5.66c-.31.07-.18.55.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M485.24,272.82a67.79,67.79,0,0,0,28.24-9.32c8.6-5.17,15.34-13,24.21-17.74a15.28,15.28,0,0,1,7.23-1.95,23,23,0,0,1,7.15.89c.31.1.44-.39.14-.48a21.2,21.2,0,0,0-11.55-.34,24.42,24.42,0,0,0-5.93,3,69.39,69.39,0,0,0-5.84,4.23c-4.08,3.25-7.94,6.76-12.23,9.72a66.63,66.63,0,0,1-14,7.35,67.45,67.45,0,0,1-17.44,4.11c-.32,0-.32.54,0,.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M553.05,246.47a36.14,36.14,0,0,0-25,9.11c-3.69,3.22-7,6.87-11,9.65a43.28,43.28,0,0,1-12.53,5.88,48.25,48.25,0,0,1-15.81,1.8c-.32,0-.32.48,0,.5a47.25,47.25,0,0,0,25.83-6c8.23-4.74,13.69-13,22.37-17.1A35.16,35.16,0,0,1,553.05,247c.32,0,.32-.49,0-.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M490.66,273.07c8.76,2,18.3,4.05,26.94.44,8.1-3.39,11.91-11.71,17.75-17.72a33.53,33.53,0,0,1,12.37-8.39c.3-.11.18-.59-.13-.48-7.63,2.78-13.22,8.67-18,15-2.68,3.53-5.4,7.22-9.22,9.61A25.14,25.14,0,0,1,507.51,275c-5.66.11-11.23-1.16-16.72-2.4-.31-.07-.45.41-.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M518.32,288.82a43.48,43.48,0,0,0,15.46-11.49c4.23-5,6.57-11.79,12.14-15.49,5.26-3.5,12-3.93,18.1-2.82,7.14,1.3,13.64,4.76,20.2,7.68,6.91,3.09,14.95,6.33,22.49,3.54l-.24-.42c-9.24,9.14-14.8,22.38-26.76,28.57a35.09,35.09,0,0,1-20,3.35,47,47,0,0,1-19.9-7.35,57.82,57.82,0,0,0-10.67-5.76c-3.6-1.35-7.49-2.09-11-.16-.28.15,0,.58.25.43,3.85-2.1,8.11-.85,11.92.7a67.44,67.44,0,0,1,10,5.69A46.52,46.52,0,0,0,559,302.14a36.48,36.48,0,0,0,19.08-2.4c6-2.6,10.73-7.16,14.8-12.18,4.68-5.79,8.68-12.13,14-17.39.17-.16,0-.5-.24-.41-7.41,2.74-15.31-.45-22.11-3.49-6.48-2.89-12.91-6.3-19.94-7.66-5.51-1.07-11.52-.91-16.65,1.54a18.65,18.65,0,0,0-6.8,5.67c-2,2.65-3.53,5.64-5.46,8.34a42.89,42.89,0,0,1-17.55,14.23c-.3.12,0,.56.25.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M523.12,287.32a46.79,46.79,0,0,0,13.45-8c4.14-3.74,6.15-9.36,10.77-12.59s11.1-3.67,16.66-2.6c6.11,1.19,11.77,3.9,17.61,5.95a45.65,45.65,0,0,0,9.81,2.4,25.74,25.74,0,0,0,5.41.09,19.59,19.59,0,0,0,2.28-.34c.3-.06.59-.12.88-.2s.58-.15.87-.24c.42-.27.71-.19.86.23-.23-.22-.59.13-.35.35.74.69,1.71-1.31-.08-1.18a13.83,13.83,0,0,0-1.87.47,22.29,22.29,0,0,1-3,.45,26.79,26.79,0,0,1-5.37-.18c-7.13-.92-13.61-4.17-20.33-6.47-5.75-2-11.95-3.21-18-1.68a16.91,16.91,0,0,0-7.21,3.77c-2.26,2.05-3.85,4.68-5.59,7.15-4.1,5.83-10.47,9.45-16.93,12.16-.3.12-.17.61.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M604.51,271.27c-1.19,1.17-2.65,2-3.88,3.16a55.45,55.45,0,0,0-5.08,5.73c-3.48,4.13-6.88,8.38-11,11.94A30.76,30.76,0,0,1,571,299.39c-5.11,1.08-10-.17-14.78-1.85s-9.76-3.5-15-2.65c-.31.06-.18.54.14.49,5.12-.84,10,1,14.72,2.64,4.5,1.57,9.06,2.82,13.86,2.07,10.54-1.63,18-10.11,24.5-17.85,2-2.36,3.91-4.77,6-7,1.32-1.37,3-2.31,4.38-3.63.23-.23-.13-.58-.36-.36Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M526.2,288c4.84,1.23,9.71,2.29,14.6,3.28s9.62,2.07,14.46,2.95a45.84,45.84,0,0,0,6.91.79c2.38.06,4.76-.23,7.15-.28a36.26,36.26,0,0,0,20.33-7.1c.26-.18,0-.61-.26-.43-5.6,3.93-12.25,6.81-19.2,7-2.2.07-4.4.26-6.6.3a39.32,39.32,0,0,1-7.26-.61c-4.67-.78-9.31-1.88-14-2.82-5.37-1.08-10.73-2.22-16-3.58a.26.26,0,0,0-.14.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M524.57,287.74c9.56-2.28,19.29-.56,28.82.93,9.14,1.43,18.35,2.45,27.45.21A48,48,0,0,0,595,282.82c.27-.17,0-.61-.25-.43a48.1,48.1,0,0,1-27.23,7.34c-9.37-.28-18.51-2.66-27.84-3.41a48.9,48.9,0,0,0-15.26.93.25.25,0,0,0,.13.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M525.73,286.93c9.56-3.3,19.71-4.09,29.76-3.89,5,.09,10,.42,15,.79,5.21.38,10.49,1,15.71.32a21.33,21.33,0,0,0,12.47-5.59c.23-.23-.13-.58-.36-.35-7.32,7.25-18.48,5.81-27.82,5.12-9.73-.72-19.54-1.3-29.27-.27a71.05,71.05,0,0,0-15.66,3.39c-.3.1-.17.58.14.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M530.27,285.1a67.29,67.29,0,0,1,29.8-6.8A125.34,125.34,0,0,1,576.23,280c5,.68,10.29,1.38,15.29.51,3.94-.7,7.91-3.59,10.51-6.53.21-.24-.14-.6-.36-.36-2.51,2.86-6.34,5.65-10.14,6.38-5.38,1-11.26.07-16.61-.69a112.07,112.07,0,0,0-15.07-1.46A67.49,67.49,0,0,0,530,284.67c-.29.14,0,.57.26.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M601.32,271.14a35.31,35.31,0,0,1-13.15,5.29,39.71,39.71,0,0,1-15-.48c-4.59-.92-9.13-2-13.84-2a44.73,44.73,0,0,0-13.15,1.93,48.81,48.81,0,0,0-13.5,6.51c-.26.18,0,.62.25.43a46.47,46.47,0,0,1,26.4-8.37c5,0,9.76,1.26,14.63,2.18a39.3,39.3,0,0,0,13.55.45,35.69,35.69,0,0,0,14.11-5.49c.27-.18,0-.61-.25-.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M534.86,282c6.23-6.1,13-12.61,21.88-14.26,9.07-1.68,17.14,3.93,25.68,5.91a33.47,33.47,0,0,0,14.26.42c.32-.06.19-.55-.13-.48a33.21,33.21,0,0,1-14.44-.53c-4.37-1.06-8.43-2.88-12.65-4.36a25,25,0,0,0-12.21-1.55,28.67,28.67,0,0,0-11.51,4.76,81.39,81.39,0,0,0-11.24,9.74c-.23.22.13.57.36.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M519.77,288a42.94,42.94,0,0,1-21.86-2.71c-3.23-1.31-6.21-3.15-9.46-4.43a18.64,18.64,0,0,0-8.86-1.27c-13.6,1.46-21.07,14.39-28,24.54-4.17,6.08-9.2,12.88-16.89,14.4a.26.26,0,0,0,0,.49c13.67,3.18,26.31,12.46,40.92,9.94,12.52-2.17,22.83-11.56,28.48-22.63,1.91-3.75,3.55-7.62,6.06-11,2.4-3.25,5.36-6.48,9.66-6.7.32,0,.32-.51,0-.5-6.41.32-10.59,7-13.24,12.09-1.65,3.17-3.05,6.47-4.94,9.51a44.13,44.13,0,0,1-6.11,7.77,38.69,38.69,0,0,1-16.65,10.22c-6.87,2-13.84,1.25-20.58-.87-7.92-2.49-15.35-6.4-23.47-8.29V319c6-1.19,10.52-5.66,14.11-10.36,4.26-5.56,7.68-11.73,12.15-17.13,4.3-5.19,9.9-9.72,16.63-11.14a18.16,18.16,0,0,1,10.21.8c3.24,1.21,6.2,3.05,9.38,4.38a43.36,43.36,0,0,0,22.6,2.91c.32,0,.18-.52-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M514.83,289.2c-6.21,1-13.15,1.45-19.15-.8-3.24-1.21-6.27-3-9.68-3.73a16.84,16.84,0,0,0-8.68.5c-12.85,4.09-17.85,18-27.39,26.27a27,27,0,0,1-9.46,5.28c-.53.18-1.54.6-1.1,1.33a.6.6,0,0,0,1.11-.08.25.25,0,0,0-.48-.13c.25-.89,2.82-1.34,3.64-1.71a23.6,23.6,0,0,0,3.77-2.11,36.15,36.15,0,0,0,5.9-5.15c4.11-4.38,7.34-9.49,11.29-14s8.84-8.51,14.84-9.73a17,17,0,0,1,9.38.91c2.69,1,5.22,2.44,8,3.27,5.82,1.74,12.24,1.3,18.16.38.32-.05.18-.53-.13-.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M437.11,319.18c1.73.38,3.53.32,5.26.7a79.12,79.12,0,0,1,8.23,2.56c5.48,1.83,11,3.72,16.8,4.35a28.66,28.66,0,0,0,15-1.93c4.13-1.87,7.16-5.13,10-8.59,3.24-4,6.43-8.16,11.31-10.16.29-.12.16-.6-.14-.48C495.32,309,492.1,318,485,322.82c-9.77,6.58-22.26,3.13-32.49-.27-3.12-1-6.23-2.12-9.4-3-1.91-.52-3.93-.44-5.86-.87-.32-.07-.45.41-.14.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M512.35,291.56c-6.65,6.88-13.71,13.43-20.76,19.9a49.86,49.86,0,0,1-5.36,4.37c-1.82,1.26-3.8,2.17-5.71,3.27a33.09,33.09,0,0,1-5.95,3c-1.28.42-2.56.87-3.86,1.24a35.85,35.85,0,0,1-12.21,1.05c-.32,0-.32.48,0,.5,6.93.42,14.19-.65,20.29-4.17,3.83-2.21,7.68-4.17,11.08-7.06,3.56-3,6.86-6.36,10.28-9.54,4.27-4,8.5-8,12.56-12.18.22-.23-.13-.59-.36-.36Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M513.93,290.36c-17.38,5.71-27.7,22.8-44.61,29.43a47.69,47.69,0,0,1-17.95,3.26c-.32,0-.32.49,0,.5a49.06,49.06,0,0,0,28.24-8.72c7.4-5,13.71-11.47,20.94-16.75a49.07,49.07,0,0,1,13.51-7.24c.31-.1.18-.58-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M512.4,290.24c-9.15,2-17.63,6.18-25.56,11.11-4,2.51-7.93,5.21-11.8,8s-7.89,5.94-12.14,8.45c-5,3-10.9,5-16.76,3.61a.25.25,0,0,0-.14.48c9.93,2.3,18.56-4.35,26.1-9.81,7.9-5.7,15.9-11.37,24.67-15.67a71.16,71.16,0,0,1,15.77-5.65c.31-.07.18-.55-.14-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M507.71,291a67.94,67.94,0,0,0-28.24,9.32c-8.61,5.18-15.34,13-24.21,17.75a15.42,15.42,0,0,1-7.23,1.94,22.78,22.78,0,0,1-7.16-.89c-.3-.09-.44.39-.13.49a21.1,21.1,0,0,0,11.55.33,23.9,23.9,0,0,0,5.93-3,67.21,67.21,0,0,0,5.84-4.23c4.07-3.24,7.93-6.76,12.23-9.71a66.17,66.17,0,0,1,14-7.35,67.07,67.07,0,0,1,17.44-4.12c.32,0,.32-.53,0-.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M439.9,317.4a36.15,36.15,0,0,0,25-9.12c3.69-3.21,7-6.86,11-9.65a43.5,43.5,0,0,1,12.53-5.87,48,48,0,0,1,15.82-1.8c.32,0,.32-.49,0-.5a47.22,47.22,0,0,0-25.83,6c-8.24,4.75-13.7,13-22.37,17.11a35.16,35.16,0,0,1-16.18,3.34.25.25,0,0,0,0,.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M502.29,290.8c-8.76-2-18.3-4.06-26.94-.44-8.1,3.39-11.91,11.71-17.75,17.71a33.25,33.25,0,0,1-12.38,8.39c-.3.11-.17.6.14.49,7.63-2.78,13.22-8.67,18-15,2.68-3.54,5.39-7.23,9.22-9.62a25.1,25.1,0,0,1,12.84-3.45c5.66-.12,11.23,1.15,16.72,2.4.31.07.44-.41.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M691.05,239.91a43.37,43.37,0,0,0,15.46-11.49c4.22-5,6.57-11.78,12.14-15.49,5.26-3.5,12-3.93,18.1-2.82,7.13,1.3,13.64,4.76,20.2,7.69,6.91,3.08,14.95,6.32,22.49,3.53l-.24-.42c-9.24,9.15-14.8,22.38-26.76,28.57a35.09,35.09,0,0,1-20,3.35,47,47,0,0,1-19.9-7.35,57.82,57.82,0,0,0-10.67-5.76c-3.6-1.35-7.49-2.09-11-.16-.28.15,0,.59.25.43,3.85-2.09,8.11-.84,11.91.71a67.49,67.49,0,0,1,10,5.68,46.52,46.52,0,0,0,18.62,6.85,36.48,36.48,0,0,0,19.08-2.4c6-2.6,10.73-7.15,14.8-12.18,4.68-5.78,8.68-12.13,14-17.39.17-.16,0-.5-.24-.41-7.41,2.74-15.31-.45-22.11-3.49-6.48-2.89-12.91-6.3-19.94-7.66-5.51-1.07-11.52-.91-16.65,1.54a18.67,18.67,0,0,0-6.8,5.67c-2,2.65-3.53,5.64-5.46,8.35a43,43,0,0,1-17.55,14.22c-.3.13,0,.56.25.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M695.85,238.41a46.79,46.79,0,0,0,13.45-8c4.14-3.74,6.15-9.36,10.77-12.59s11.1-3.67,16.66-2.59c6.11,1.18,11.77,3.89,17.61,5.94a45.53,45.53,0,0,0,9.81,2.41,26.35,26.35,0,0,0,5.4.08,21.77,21.77,0,0,0,2.29-.33l.88-.2.87-.24c.42-.28.71-.2.86.22-.23-.22-.59.14-.35.35.74.69,1.71-1.31-.08-1.17a12.15,12.15,0,0,0-1.87.46,20.83,20.83,0,0,1-3,.45,26.79,26.79,0,0,1-5.37-.18c-7.13-.91-13.61-4.17-20.34-6.47-5.74-2-11.94-3.21-17.95-1.68a16.91,16.91,0,0,0-7.21,3.77c-2.26,2-3.85,4.69-5.59,7.16-4.11,5.83-10.47,9.44-16.93,12.15-.3.12-.17.61.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M777.24,222.36c-1.19,1.17-2.65,2-3.88,3.16a57.61,57.61,0,0,0-5.08,5.73c-3.48,4.13-6.88,8.38-11,11.94a30.76,30.76,0,0,1-13.58,7.29c-5.11,1.08-10-.17-14.78-1.85s-9.76-3.5-15-2.64c-.31,0-.18.53.13.48,5.13-.84,10,1,14.73,2.64,4.5,1.57,9,2.82,13.85,2.07,10.55-1.63,18-10.11,24.51-17.85,2-2.36,3.91-4.77,6-7,1.32-1.37,3-2.31,4.38-3.63.23-.23-.13-.58-.36-.36Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M698.93,239.13c4.83,1.23,9.71,2.29,14.6,3.28s9.62,2.08,14.46,3a45.84,45.84,0,0,0,6.91.79c2.38.06,4.76-.23,7.15-.28a36.08,36.08,0,0,0,20.32-7.09c.27-.19,0-.62-.25-.44-5.6,3.93-12.25,6.81-19.2,7-2.2.06-4.4.25-6.6.29a39.32,39.32,0,0,1-7.26-.61c-4.67-.78-9.31-1.88-14-2.82-5.37-1.08-10.73-2.22-16-3.58a.25.25,0,0,0-.13.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M697.3,238.83c9.56-2.27,19.29-.55,28.82.94,9.14,1.43,18.35,2.45,27.45.21a48.28,48.28,0,0,0,14.18-6.07c.27-.17,0-.6-.25-.43a48,48,0,0,1-27.23,7.34c-9.37-.27-18.51-2.66-27.84-3.4a48.58,48.58,0,0,0-15.26.93c-.32.07-.18.55.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M698.46,238c9.56-3.3,19.71-4.08,29.76-3.89,5,.1,10,.42,15,.79,5.21.38,10.49,1,15.71.32a21.27,21.27,0,0,0,12.47-5.59c.23-.22-.13-.58-.36-.35-7.32,7.26-18.48,5.81-27.82,5.12-9.73-.72-19.54-1.3-29.27-.26a70.47,70.47,0,0,0-15.66,3.38c-.3.1-.17.59.14.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M703,236.19a67.29,67.29,0,0,1,29.8-6.8A127.14,127.14,0,0,1,749,231c5,.68,10.29,1.38,15.29.51,3.94-.69,7.91-3.59,10.51-6.53.21-.24-.14-.6-.36-.36-2.51,2.86-6.34,5.65-10.14,6.38-5.38,1-11.26.08-16.61-.68a110.48,110.48,0,0,0-15.07-1.47,67.49,67.49,0,0,0-29.84,6.87c-.29.14,0,.57.26.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M774.05,222.23a35.31,35.31,0,0,1-13.15,5.29,39.71,39.71,0,0,1-15-.48c-4.59-.91-9.13-2-13.84-2A44.73,44.73,0,0,0,718.86,227a48.81,48.81,0,0,0-13.5,6.51c-.26.18,0,.62.25.43a46.47,46.47,0,0,1,26.4-8.37c5,0,9.76,1.26,14.63,2.19a39.55,39.55,0,0,0,13.55.44,35.69,35.69,0,0,0,14.11-5.49c.27-.18,0-.61-.25-.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M707.59,233.06c6.22-6.1,13-12.61,21.88-14.26,9.07-1.68,17.14,3.93,25.68,5.91a33.47,33.47,0,0,0,14.26.42c.32-.06.18-.54-.13-.48a33.06,33.06,0,0,1-14.44-.53c-4.37-1.05-8.43-2.88-12.65-4.36A25,25,0,0,0,730,218.21,28.69,28.69,0,0,0,718.47,223a80.65,80.65,0,0,0-11.24,9.73c-.23.22.13.58.36.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M692.49,239a42.81,42.81,0,0,1-21.85-2.71c-3.23-1.31-6.21-3.15-9.46-4.43a18.64,18.64,0,0,0-8.86-1.27c-13.6,1.46-21.07,14.4-28,24.54-4.17,6.09-9.21,12.88-16.89,14.4a.26.26,0,0,0,0,.49c13.67,3.18,26.31,12.46,40.92,9.94,12.52-2.17,22.83-11.56,28.48-22.63,1.91-3.74,3.55-7.62,6.06-11,2.4-3.25,5.36-6.48,9.66-6.7.32,0,.32-.51,0-.5-6.41.32-10.59,7-13.24,12.09-1.65,3.17-3,6.47-4.94,9.51a44.13,44.13,0,0,1-6.11,7.77,38.71,38.71,0,0,1-16.65,10.23c-6.87,2-13.84,1.24-20.58-.88-7.92-2.49-15.35-6.4-23.47-8.29v.49c6-1.19,10.52-5.66,14.11-10.35,4.26-5.57,7.67-11.73,12.15-17.14,4.3-5.19,9.9-9.71,16.63-11.14a18.16,18.16,0,0,1,10.21.8c3.24,1.22,6.2,3.05,9.38,4.39a43.45,43.45,0,0,0,22.6,2.9c.31,0,.18-.52-.14-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M687.56,240.3c-6.21,1-13.15,1.44-19.15-.8-3.24-1.22-6.27-3-9.68-3.74a16.83,16.83,0,0,0-8.68.5c-12.85,4.09-17.85,18-27.39,26.27a27.19,27.19,0,0,1-9.46,5.29c-.53.17-1.54.59-1.1,1.32a.6.6,0,0,0,1.11-.08.25.25,0,0,0-.48-.13c.25-.88,2.82-1.34,3.64-1.71a24,24,0,0,0,3.77-2.11A35.68,35.68,0,0,0,626,260c4.11-4.38,7.34-9.49,11.29-14s8.84-8.51,14.84-9.72a16.9,16.9,0,0,1,9.38.9c2.69,1,5.22,2.44,8,3.27,5.81,1.75,12.24,1.3,18.16.38.32-.05.18-.53-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M609.84,270.27c1.73.38,3.53.32,5.26.7a79.88,79.88,0,0,1,8.23,2.56c5.48,1.83,11,3.72,16.79,4.36a28.68,28.68,0,0,0,15-1.94c4.12-1.87,7.16-5.13,10-8.59,3.24-4,6.43-8.16,11.31-10.16.29-.12.16-.6-.14-.48-8.19,3.36-11.42,12.41-18.51,17.19-9.77,6.58-22.26,3.13-32.49-.27-3.12-1-6.23-2.12-9.4-3-1.91-.52-3.93-.43-5.86-.87-.32-.07-.45.42-.14.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M685.08,242.65c-6.65,6.88-13.71,13.44-20.76,19.9a49.86,49.86,0,0,1-5.36,4.37c-1.82,1.26-3.8,2.17-5.71,3.27a32.76,32.76,0,0,1-6,3c-1.29.42-2.56.87-3.86,1.24a35.85,35.85,0,0,1-12.21,1c-.32,0-.32.48,0,.5,6.93.42,14.19-.65,20.28-4.17,3.84-2.21,7.69-4.17,11.09-7.05,3.56-3,6.86-6.37,10.28-9.55,4.27-4,8.5-8,12.56-12.18.22-.23-.13-.59-.36-.36Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M686.66,241.45c-17.38,5.71-27.7,22.8-44.61,29.43a47.69,47.69,0,0,1-17.95,3.26.25.25,0,0,0,0,.5,49.06,49.06,0,0,0,28.24-8.72c7.4-5,13.71-11.47,20.94-16.75a49.07,49.07,0,0,1,13.51-7.24c.31-.1.17-.58-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M685.13,241.33c-9.15,2-17.63,6.19-25.56,11.11-4,2.51-7.94,5.21-11.8,8s-7.9,5.93-12.14,8.44c-5,3-10.9,5-16.76,3.61-.32-.08-.45.41-.14.48,9.93,2.3,18.56-4.35,26.1-9.8,7.89-5.71,15.9-11.38,24.67-15.68a71.26,71.26,0,0,1,15.76-5.65c.32-.07.19-.55-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M680.44,242.13a67.81,67.81,0,0,0-28.24,9.33c-8.61,5.17-15.34,13-24.21,17.74a15.43,15.43,0,0,1-7.24,1.94,22.47,22.47,0,0,1-7.15-.89c-.31-.09-.44.39-.13.49a21.1,21.1,0,0,0,11.55.33,23.9,23.9,0,0,0,5.93-3,67.1,67.1,0,0,0,5.84-4.22c4.07-3.25,7.93-6.77,12.23-9.72a66.39,66.39,0,0,1,14-7.35,67.07,67.07,0,0,1,17.44-4.12c.32,0,.32-.53,0-.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M612.62,268.49a36.15,36.15,0,0,0,25-9.11c3.69-3.22,7-6.87,11-9.66a43.64,43.64,0,0,1,12.53-5.87,48.28,48.28,0,0,1,15.82-1.8c.32,0,.32-.49,0-.5a47.23,47.23,0,0,0-25.83,6c-8.24,4.74-13.7,13-22.37,17.1A35.21,35.21,0,0,1,612.62,268c-.32,0-.32.49,0,.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M675,241.89c-8.76-2-18.3-4.06-26.94-.44-8.1,3.39-11.92,11.71-17.75,17.71A33.25,33.25,0,0,1,618,267.55c-.3.11-.17.6.13.49,7.64-2.78,13.23-8.67,18-15,2.68-3.54,5.39-7.22,9.22-9.62A25.1,25.1,0,0,1,658.17,240c5.66-.12,11.23,1.16,16.71,2.4.32.07.45-.41.14-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M534.48,350.19a43.46,43.46,0,0,0,15.46-11.48c4.22-5,6.57-11.79,12.14-15.49,5.26-3.5,12-3.93,18.1-2.82,7.13,1.3,13.64,4.76,20.2,7.68,6.91,3.09,15,6.33,22.49,3.53l-.24-.41c-9.24,9.14-14.8,22.37-26.76,28.57a35.08,35.08,0,0,1-20,3.34,46.81,46.81,0,0,1-19.9-7.34A58.19,58.19,0,0,0,545.33,350c-3.6-1.34-7.49-2.08-11-.15-.28.15,0,.58.25.43,3.85-2.1,8.11-.85,11.91.7a68.39,68.39,0,0,1,10,5.68,46.53,46.53,0,0,0,18.62,6.86,36.39,36.39,0,0,0,19.08-2.41c6-2.6,10.73-7.15,14.8-12.18,4.68-5.78,8.68-12.12,14-17.38.17-.17,0-.51-.24-.42-7.41,2.75-15.31-.45-22.11-3.48-6.48-2.89-12.91-6.3-19.94-7.67-5.51-1.06-11.52-.91-16.65,1.55a18.54,18.54,0,0,0-6.8,5.66c-2,2.66-3.53,5.64-5.46,8.35a42.75,42.75,0,0,1-17.56,14.22c-.29.13,0,.56.26.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M539.28,348.7a46.59,46.59,0,0,0,13.45-8c4.14-3.73,6.15-9.35,10.77-12.59s11.1-3.66,16.66-2.59c6.11,1.18,11.77,3.9,17.61,5.95a45.5,45.5,0,0,0,9.81,2.4,25.66,25.66,0,0,0,5.4.09c.77-.08,1.54-.19,2.29-.34l.88-.2q.44-.11.87-.24c.42-.27.71-.19.86.23-.23-.22-.59.13-.35.35.74.68,1.71-1.31-.08-1.18a13.43,13.43,0,0,0-1.87.46,19.56,19.56,0,0,1-3,.45,26.07,26.07,0,0,1-5.37-.17c-7.13-.92-13.61-4.18-20.34-6.48-5.74-2-11.94-3.2-18-1.67a16.91,16.91,0,0,0-7.21,3.77c-2.26,2-3.85,4.68-5.59,7.15-4.11,5.83-10.47,9.45-16.93,12.16-.3.12-.17.6.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M620.67,332.65c-1.19,1.16-2.65,2-3.88,3.15a57.61,57.61,0,0,0-5.08,5.73c-3.48,4.14-6.88,8.39-11,12a30.86,30.86,0,0,1-13.58,7.29c-5.11,1.08-10-.17-14.78-1.85s-9.76-3.5-15-2.65c-.32.05-.18.53.13.48,5.13-.83,10,1,14.73,2.65,4.5,1.57,9,2.81,13.85,2.07,10.55-1.64,18-10.12,24.51-17.86,2-2.35,3.91-4.76,6-7,1.32-1.37,3-2.32,4.38-3.64.23-.22-.13-.58-.36-.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M542.36,349.41c4.83,1.24,9.71,2.29,14.6,3.29s9.62,2.07,14.46,2.95a45.82,45.82,0,0,0,6.91.78c2.38.07,4.76-.23,7.15-.27a37,37,0,0,0,9.6-1.64,36.21,36.21,0,0,0,10.72-5.46c.27-.18,0-.62-.25-.43-5.6,3.93-12.26,6.81-19.2,7-2.2.07-4.4.25-6.6.3a39.32,39.32,0,0,1-7.26-.61c-4.67-.78-9.31-1.88-14-2.82-5.37-1.08-10.73-2.22-16-3.58a.25.25,0,0,0-.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M540.73,349.11c9.56-2.27,19.29-.55,28.82.94,9.14,1.43,18.35,2.45,27.45.21a48,48,0,0,0,14.18-6.06c.27-.18,0-.61-.25-.44a48,48,0,0,1-27.23,7.35c-9.37-.28-18.51-2.66-27.84-3.41a48.86,48.86,0,0,0-15.26.93.25.25,0,0,0,.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M541.89,348.31c9.56-3.3,19.71-4.09,29.76-3.89,5,.09,10,.42,15,.79,5.21.38,10.49,1,15.71.31a21.26,21.26,0,0,0,12.47-5.58c.23-.23-.13-.58-.36-.36-7.32,7.26-18.48,5.81-27.82,5.13-9.73-.72-19.54-1.3-29.27-.27a71.61,71.61,0,0,0-15.66,3.38c-.3.11-.17.59.14.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M546.43,346.48a67.16,67.16,0,0,1,29.8-6.8,123.59,123.59,0,0,1,16.16,1.65c5,.68,10.29,1.38,15.29.5,3.94-.69,7.91-3.58,10.51-6.53.21-.24-.15-.59-.36-.35-2.51,2.85-6.34,5.64-10.14,6.37-5.38,1-11.26.08-16.61-.68A110.46,110.46,0,0,0,576,339.18a66.7,66.7,0,0,0-14.23,1.48,68.05,68.05,0,0,0-15.61,5.39c-.29.14,0,.57.26.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M617.48,332.51a35.32,35.32,0,0,1-13.15,5.3,39.71,39.71,0,0,1-15.05-.48c-4.6-.92-9.13-2-13.84-2a44.74,44.74,0,0,0-13.15,1.94,48.53,48.53,0,0,0-13.5,6.51c-.26.18,0,.61.25.43a46.41,46.41,0,0,1,26.4-8.38c5,0,9.76,1.27,14.63,2.19a39.3,39.3,0,0,0,13.55.45,35.71,35.71,0,0,0,14.11-5.5c.27-.17,0-.61-.25-.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M551,343.35c6.22-6.1,13-12.61,21.88-14.26C582,327.4,590,333,598.58,335a33.32,33.32,0,0,0,14.26.43c.32-.07.18-.55-.13-.48a33.26,33.26,0,0,1-14.45-.53c-4.36-1.06-8.42-2.88-12.64-4.36a25,25,0,0,0-12.21-1.55,28.67,28.67,0,0,0-11.51,4.76A81.32,81.32,0,0,0,550.66,343c-.23.23.13.58.36.36Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M535.92,349.33a42.92,42.92,0,0,1-21.85-2.72c-3.23-1.31-6.21-3.15-9.46-4.42a18.77,18.77,0,0,0-8.86-1.28c-13.6,1.46-21.07,14.4-28,24.55-4.17,6.08-9.21,12.88-16.89,14.4a.25.25,0,0,0,0,.48c13.67,3.19,26.31,12.47,40.91,9.94,12.53-2.16,22.84-11.55,28.49-22.62,1.91-3.75,3.55-7.63,6.06-11s5.36-6.49,9.66-6.7c.32,0,.32-.52,0-.5-6.41.32-10.59,7-13.24,12.09-1.65,3.17-3.05,6.46-4.94,9.51a44.88,44.88,0,0,1-6.11,7.77A38.69,38.69,0,0,1,495,389c-6.87,2-13.84,1.24-20.58-.88-7.92-2.49-15.35-6.39-23.47-8.28v.48c6-1.19,10.52-5.66,14.11-10.35,4.26-5.56,7.67-11.73,12.15-17.13,4.3-5.19,9.9-9.72,16.63-11.14a18.16,18.16,0,0,1,10.21.8c3.24,1.21,6.2,3.05,9.38,4.38a43.36,43.36,0,0,0,22.6,2.91c.31,0,.18-.52-.14-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M531,350.58c-6.21,1-13.15,1.45-19.15-.8-3.24-1.21-6.27-3-9.68-3.74a16.84,16.84,0,0,0-8.68.51c-12.85,4.09-17.85,18-27.39,26.27a27,27,0,0,1-9.46,5.28c-.53.18-1.54.59-1.1,1.33a.6.6,0,0,0,1.11-.08.25.25,0,0,0-.48-.13c.25-.89,2.82-1.35,3.64-1.71a24.88,24.88,0,0,0,3.77-2.11,36.63,36.63,0,0,0,5.9-5.15c4.11-4.38,7.34-9.5,11.29-14s8.84-8.52,14.84-9.73a16.81,16.81,0,0,1,9.38.91c2.69,1,5.22,2.44,8,3.27,5.81,1.74,12.24,1.3,18.16.37.32-.05.18-.53-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M453.27,380.55c1.73.39,3.53.33,5.26.71a82.35,82.35,0,0,1,8.23,2.55c5.48,1.84,11,3.72,16.79,4.36a28.63,28.63,0,0,0,15-1.93c4.13-1.88,7.17-5.14,10-8.59,3.24-4,6.43-8.16,11.31-10.16.29-.12.16-.61-.14-.48-8.2,3.36-11.42,12.4-18.51,17.18-9.77,6.59-22.26,3.14-32.49-.26-3.12-1-6.23-2.13-9.4-3-1.92-.53-3.93-.44-5.86-.87-.32-.07-.45.41-.14.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M528.51,352.94c-6.65,6.88-13.71,13.43-20.76,19.9a49.86,49.86,0,0,1-5.36,4.37c-1.82,1.26-3.8,2.16-5.71,3.27a32.72,32.72,0,0,1-5.95,3c-1.29.43-2.56.87-3.86,1.25a35.85,35.85,0,0,1-12.21,1.05c-.32,0-.32.48,0,.5,6.93.41,14.19-.66,20.28-4.17,3.84-2.21,7.69-4.18,11.09-7.06,3.56-3,6.86-6.36,10.28-9.55,4.27-4,8.5-8,12.56-12.18a.25.25,0,0,0-.36-.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M530.09,351.74c-17.38,5.71-27.7,22.8-44.61,29.43a47.69,47.69,0,0,1-18,3.26c-.32,0-.32.49,0,.5a49.07,49.07,0,0,0,28.24-8.73c7.4-5,13.71-11.46,20.93-16.74a49.27,49.27,0,0,1,13.52-7.24c.3-.1.17-.58-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M528.56,351.61c-9.15,2.05-17.63,6.19-25.56,11.12-4,2.5-7.94,5.2-11.8,8s-7.9,5.94-12.14,8.44c-5,3-10.9,5-16.76,3.61-.32-.07-.45.41-.14.48,9.93,2.3,18.56-4.35,26.1-9.8,7.89-5.71,15.9-11.37,24.67-15.67a70.76,70.76,0,0,1,15.76-5.65c.32-.07.19-.56-.13-.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M523.87,352.42a67.75,67.75,0,0,0-28.24,9.32c-8.61,5.18-15.34,13-24.21,17.75a15.43,15.43,0,0,1-7.24,1.94,23.05,23.05,0,0,1-7.15-.89.25.25,0,0,0-.13.48,21.1,21.1,0,0,0,11.55.34,24.24,24.24,0,0,0,5.93-3,67.21,67.21,0,0,0,5.84-4.23c4.07-3.24,7.93-6.76,12.23-9.72a66.91,66.91,0,0,1,31.42-11.46c.31,0,.32-.53,0-.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M456.05,378.78a36.16,36.16,0,0,0,25-9.12c3.69-3.22,7-6.86,11-9.65a43.12,43.12,0,0,1,12.53-5.87,48.25,48.25,0,0,1,15.81-1.81c.33,0,.33-.48,0-.5a47.2,47.2,0,0,0-25.82,6c-8.24,4.74-13.7,13-22.37,17.11a35.21,35.21,0,0,1-16.19,3.34c-.32,0-.32.49,0,.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M518.45,352.17c-8.76-2-18.3-4.05-26.94-.43-8.1,3.38-11.92,11.71-17.75,17.71a33.45,33.45,0,0,1-12.38,8.39c-.3.11-.17.59.13.48,7.64-2.77,13.23-8.66,18-15,2.68-3.54,5.39-7.23,9.22-9.62a25.11,25.11,0,0,1,12.84-3.46c5.66-.11,11.23,1.16,16.71,2.41.32.07.45-.41.14-.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M707.21,301.29a43.57,43.57,0,0,0,15.46-11.49c4.22-5,6.57-11.79,12.14-15.49,5.26-3.5,12-3.93,18.1-2.82,7.13,1.3,13.64,4.76,20.2,7.68,6.91,3.09,14.95,6.33,22.49,3.54l-.24-.42c-9.24,9.14-14.8,22.37-26.76,28.57a35,35,0,0,1-20,3.34,46.81,46.81,0,0,1-19.9-7.34,58.14,58.14,0,0,0-10.67-5.76c-3.6-1.35-7.49-2.09-11-.16-.28.15,0,.58.25.43,3.85-2.1,8.11-.85,11.91.7a67.54,67.54,0,0,1,10,5.69,46.52,46.52,0,0,0,18.62,6.85,36.48,36.48,0,0,0,19.08-2.4c6-2.6,10.73-7.16,14.8-12.18,4.68-5.79,8.68-12.13,14-17.39.17-.16,0-.51-.24-.42-7.42,2.75-15.31-.45-22.11-3.48-6.48-2.89-12.91-6.3-20-7.67-5.5-1.06-11.51-.91-16.64,1.55a18.64,18.64,0,0,0-6.8,5.66c-2,2.66-3.53,5.64-5.46,8.35A42.75,42.75,0,0,1,707,300.85c-.29.13,0,.56.26.44Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M712,299.79a46.59,46.59,0,0,0,13.45-8c4.13-3.73,6.15-9.35,10.77-12.59s11.1-3.66,16.66-2.59c6.11,1.19,11.77,3.9,17.61,5.95a45.5,45.5,0,0,0,9.81,2.4,25.66,25.66,0,0,0,5.4.09,21.78,21.78,0,0,0,2.29-.34c.29-.06.59-.12.88-.2l.87-.24c.42-.27.71-.19.86.23-.23-.22-.59.13-.35.35.74.69,1.71-1.31-.08-1.18a13.46,13.46,0,0,0-1.87.47,22.29,22.29,0,0,1-3,.45,26.89,26.89,0,0,1-5.38-.18c-7.12-.92-13.6-4.17-20.33-6.47-5.74-2-11.94-3.21-17.95-1.68A16.91,16.91,0,0,0,734.4,280c-2.26,2.05-3.86,4.68-5.59,7.15-4.11,5.83-10.47,9.45-16.94,12.16-.29.12-.16.6.14.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M793.4,283.74c-1.19,1.16-2.65,2-3.88,3.16a57.74,57.74,0,0,0-5.09,5.72c-3.47,4.14-6.88,8.39-11,11.95a30.86,30.86,0,0,1-13.58,7.29c-5.11,1.08-10-.17-14.78-1.85s-9.76-3.5-15-2.65c-.32.05-.18.54.13.48,5.13-.83,10,1,14.73,2.65,4.5,1.57,9,2.81,13.85,2.07,10.55-1.64,18-10.11,24.51-17.86,2-2.35,3.91-4.76,6-7,1.32-1.37,3-2.31,4.38-3.64.23-.22-.13-.57-.36-.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M715.09,300.5c4.83,1.24,9.71,2.3,14.6,3.29s9.62,2.07,14.46,2.95a47.16,47.16,0,0,0,6.91.79c2.38.06,4.76-.24,7.14-.28a36.86,36.86,0,0,0,9.6-1.64,36.14,36.14,0,0,0,10.73-5.46c.27-.18,0-.61-.25-.43-5.6,3.93-12.26,6.81-19.2,7-2.2.07-4.4.25-6.6.3a39.32,39.32,0,0,1-7.26-.61c-4.67-.78-9.31-1.88-14-2.82-5.37-1.08-10.73-2.22-16-3.58a.25.25,0,0,0-.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M713.46,300.2c9.56-2.27,19.29-.55,28.82.94,9.14,1.43,18.35,2.45,27.45.21a48,48,0,0,0,14.18-6.06c.27-.17,0-.61-.25-.44a48,48,0,0,1-27.23,7.35c-9.37-.28-18.51-2.66-27.84-3.41a48.86,48.86,0,0,0-15.26.93.25.25,0,0,0,.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M714.62,299.4c9.56-3.3,19.71-4.09,29.76-3.89,5,.09,10,.42,15,.79,5.21.38,10.49,1,15.71.32A21.33,21.33,0,0,0,787.59,291c.23-.23-.13-.58-.36-.35-7.32,7.25-18.48,5.8-27.82,5.12-9.73-.72-19.54-1.3-29.27-.27a71.05,71.05,0,0,0-15.66,3.39c-.3.1-.17.58.14.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M719.15,297.57a67.37,67.37,0,0,1,29.81-6.8,125.34,125.34,0,0,1,16.16,1.65c5,.68,10.29,1.38,15.29.5,3.94-.69,7.91-3.58,10.5-6.53.22-.24-.14-.59-.35-.35-2.51,2.85-6.34,5.65-10.14,6.37-5.39,1-11.26.08-16.61-.68a110.46,110.46,0,0,0-15.07-1.46,67.35,67.35,0,0,0-14.23,1.48,68.05,68.05,0,0,0-15.61,5.39c-.29.14,0,.57.25.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M790.21,283.6a35.16,35.16,0,0,1-13.15,5.3,39.71,39.71,0,0,1-15-.48c-4.6-.92-9.13-2-13.84-2A44.74,44.74,0,0,0,735,288.33a48.81,48.81,0,0,0-13.5,6.51c-.26.18,0,.61.25.43a46.56,46.56,0,0,1,26.4-8.38c5,0,9.76,1.27,14.62,2.19a39.36,39.36,0,0,0,13.56.45A35.69,35.69,0,0,0,790.46,284c.27-.18,0-.61-.25-.44Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M723.75,294.44c6.22-6.1,13-12.61,21.88-14.26,9.07-1.68,17.14,3.93,25.68,5.91a33.47,33.47,0,0,0,14.26.42c.32-.07.18-.55-.13-.48A33.26,33.26,0,0,1,771,285.5c-4.36-1.06-8.43-2.88-12.64-4.36a25,25,0,0,0-12.22-1.55,28.69,28.69,0,0,0-11.5,4.76,82,82,0,0,0-11.24,9.73c-.23.23.12.58.36.36Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M708.65,300.42a42.92,42.92,0,0,1-21.85-2.71c-3.23-1.31-6.21-3.16-9.46-4.43a18.64,18.64,0,0,0-8.86-1.27c-13.6,1.45-21.07,14.39-28,24.54-4.17,6.08-9.21,12.88-16.89,14.4a.25.25,0,0,0,0,.48c13.67,3.19,26.31,12.47,40.91,9.95,12.53-2.17,22.84-11.56,28.49-22.63,1.91-3.75,3.55-7.63,6.06-11s5.36-6.49,9.66-6.7c.32,0,.32-.51,0-.5-6.41.32-10.59,7-13.24,12.09-1.65,3.17-3.05,6.47-4.94,9.51a44.5,44.5,0,0,1-6.11,7.77,38.69,38.69,0,0,1-16.65,10.22c-6.87,2-13.84,1.24-20.58-.87-7.92-2.5-15.35-6.4-23.47-8.29v.48c6-1.18,10.52-5.66,14.11-10.35,4.26-5.56,7.67-11.73,12.15-17.13,4.3-5.19,9.89-9.72,16.63-11.14a18.16,18.16,0,0,1,10.21.8c3.24,1.21,6.2,3.05,9.38,4.38a43.36,43.36,0,0,0,22.6,2.91c.31,0,.18-.52-.14-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M703.72,301.67c-6.21,1-13.16,1.45-19.15-.8-3.24-1.21-6.27-3-9.68-3.73a16.73,16.73,0,0,0-8.68.5c-12.85,4.09-17.85,18-27.39,26.27a27,27,0,0,1-9.46,5.28c-.53.18-1.54.59-1.1,1.33a.6.6,0,0,0,1.11-.08.25.25,0,0,0-.48-.13c.25-.89,2.82-1.35,3.64-1.71a24,24,0,0,0,3.77-2.11,36.63,36.63,0,0,0,5.9-5.15c4.11-4.38,7.34-9.5,11.29-14s8.84-8.52,14.84-9.73a17,17,0,0,1,9.38.91c2.69,1,5.22,2.44,8,3.27,5.81,1.74,12.24,1.3,18.16.38.32-.05.18-.54-.13-.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M626,331.64c1.73.39,3.53.33,5.26.71a82.35,82.35,0,0,1,8.23,2.55c5.48,1.84,11,3.73,16.79,4.36a28.63,28.63,0,0,0,15-1.93c4.13-1.88,7.17-5.13,10-8.59,3.24-4,6.42-8.16,11.31-10.16.29-.12.16-.6-.14-.48-8.2,3.36-11.42,12.4-18.51,17.19-9.77,6.58-22.26,3.13-32.49-.27-3.12-1-6.23-2.12-9.4-3-1.92-.52-3.93-.44-5.86-.87-.32-.07-.45.41-.14.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M701.24,304c-6.65,6.88-13.71,13.43-20.77,19.9a48.78,48.78,0,0,1-5.35,4.37c-1.82,1.26-3.8,2.17-5.71,3.27a32.72,32.72,0,0,1-5.95,3c-1.29.43-2.56.87-3.86,1.25a35.89,35.89,0,0,1-12.21,1c-.32,0-.32.48,0,.5,6.93.41,14.19-.66,20.28-4.17,3.84-2.21,7.69-4.17,11.09-7.06,3.56-3,6.86-6.36,10.28-9.54,4.27-4,8.5-8,12.56-12.18.22-.24-.13-.59-.36-.36Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M702.82,302.83c-17.39,5.71-27.7,22.8-44.61,29.43a47.73,47.73,0,0,1-18,3.26.25.25,0,0,0,0,.5,49,49,0,0,0,28.24-8.73c7.4-5,13.71-11.46,20.93-16.74A49.27,49.27,0,0,1,703,303.31c.3-.1.17-.58-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M701.29,302.71c-9.15,2-17.63,6.18-25.56,11.11-4,2.5-7.94,5.21-11.8,8s-7.9,5.94-12.14,8.45c-5,3-10.9,5-16.76,3.6-.32-.07-.45.41-.14.49,9.93,2.3,18.56-4.35,26.1-9.81,7.89-5.7,15.9-11.37,24.67-15.67a70.76,70.76,0,0,1,15.76-5.65c.32-.07.18-.55-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M696.6,303.51a67.89,67.89,0,0,0-28.24,9.32c-8.61,5.18-15.34,13-24.22,17.75a15.36,15.36,0,0,1-7.23,1.94,22.76,22.76,0,0,1-7.15-.89.25.25,0,0,0-.13.48,21.1,21.1,0,0,0,11.55.34,24.07,24.07,0,0,0,5.93-3,67.21,67.21,0,0,0,5.84-4.23c4.07-3.24,7.93-6.76,12.23-9.72A66.91,66.91,0,0,1,696.6,304c.31,0,.32-.53,0-.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M628.78,329.87a36.16,36.16,0,0,0,25-9.12c3.69-3.22,7-6.86,11.05-9.65a43.12,43.12,0,0,1,12.53-5.87,48.25,48.25,0,0,1,15.81-1.81c.33,0,.32-.48,0-.5a47.2,47.2,0,0,0-25.82,6c-8.24,4.74-13.7,13-22.37,17.11a35.21,35.21,0,0,1-16.19,3.34c-.32,0-.32.49,0,.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M691.18,303.27c-8.76-2-18.3-4.06-26.94-.44-8.1,3.39-11.92,11.71-17.75,17.71a33.33,33.33,0,0,1-12.38,8.39c-.3.11-.17.59.13.48,7.63-2.77,13.23-8.66,18-15,2.68-3.54,5.39-7.23,9.22-9.62a25.1,25.1,0,0,1,12.84-3.45c5.66-.12,11.23,1.15,16.71,2.4.32.07.45-.41.14-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M663.52,287.52A43.28,43.28,0,0,0,648.06,299c-4.23,5-6.58,11.78-12.14,15.49-5.26,3.5-12,3.92-18.11,2.82-7.13-1.31-13.64-4.76-20.2-7.69-6.91-3.08-14.94-6.33-22.49-3.53l.25.41c9.24-9.14,14.8-22.37,26.75-28.57a35.21,35.21,0,0,1,20-3.34A46.83,46.83,0,0,1,642,282a58.66,58.66,0,0,0,10.66,5.76c3.61,1.34,7.5,2.08,11,.16.29-.16,0-.59-.25-.44-3.85,2.1-8.11.85-11.91-.7a67.77,67.77,0,0,1-10-5.68,46.63,46.63,0,0,0-18.63-6.86,36.39,36.39,0,0,0-19.08,2.41c-6,2.6-10.73,7.15-14.8,12.18-4.68,5.78-8.68,12.13-14,17.38-.17.17,0,.51.24.42,7.42-2.75,15.32.45,22.11,3.48,6.48,2.89,12.92,6.31,19.95,7.67,5.51,1.07,11.51.91,16.65-1.55a18.59,18.59,0,0,0,6.79-5.66c2-2.66,3.53-5.64,5.47-8.35A42.88,42.88,0,0,1,663.77,288c.29-.13,0-.56-.25-.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M658.72,289a46.87,46.87,0,0,0-13.46,8c-4.13,3.73-6.14,9.35-10.76,12.59-4.78,3.34-11.11,3.67-16.66,2.59-6.12-1.18-11.77-3.9-17.62-5.94a45.38,45.38,0,0,0-9.81-2.41,26.34,26.34,0,0,0-5.4-.09c-.77.08-1.53.19-2.29.34l-.88.2q-.44.1-.87.24c-.42.27-.7.2-.86-.23.24.22.59-.13.36-.35-.74-.68-1.72,1.31.07,1.18a13.92,13.92,0,0,0,1.88-.46,19.38,19.38,0,0,1,3-.45,26.79,26.79,0,0,1,5.37.17c7.13.92,13.6,4.18,20.33,6.48,5.75,2,12,3.2,18,1.67a16.8,16.8,0,0,0,7.21-3.77c2.26-2,3.85-4.68,5.59-7.15,4.1-5.83,10.47-9.45,16.93-12.15.29-.13.16-.61-.13-.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M577.32,305.06c1.2-1.16,2.65-2,3.89-3.15a57.61,57.61,0,0,0,5.08-5.73c3.47-4.14,6.88-8.39,11-11.95a30.86,30.86,0,0,1,13.58-7.29c5.11-1.08,9.95.17,14.77,1.86s9.76,3.49,15,2.64c.32-.05.19-.53-.13-.48-5.13.83-10-1-14.72-2.65-4.5-1.57-9.06-2.81-13.86-2.07-10.55,1.64-18,10.12-24.51,17.86-2,2.35-3.91,4.76-6,7-1.32,1.36-3,2.31-4.37,3.63-.23.22.12.58.35.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M655.63,288.3c-4.83-1.24-9.7-2.29-14.59-3.29s-9.63-2.07-14.46-2.94a44.72,44.72,0,0,0-6.91-.79c-2.39-.07-4.77.23-7.15.27a36.86,36.86,0,0,0-9.6,1.64,36.39,36.39,0,0,0-10.73,5.46c-.26.18,0,.62.25.43,5.61-3.93,12.26-6.8,19.2-7,2.2-.07,4.41-.25,6.61-.3a39.21,39.21,0,0,1,7.25.61c4.68.79,9.31,1.89,14,2.82,5.37,1.09,10.73,2.23,16,3.58a.25.25,0,0,0,.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M657.26,288.6c-9.55,2.27-19.28.55-28.82-.94-9.14-1.43-18.34-2.45-27.44-.21a48,48,0,0,0-14.19,6.07c-.27.17,0,.6.25.43a48.11,48.11,0,0,1,27.23-7.35c9.38.28,18.52,2.66,27.84,3.41a49,49,0,0,0,15.27-.93c.31-.07.18-.56-.14-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M656.11,289.41c-9.57,3.29-19.71,4.08-29.77,3.89-5-.1-10-.43-15-.79-5.22-.39-10.5-1-15.71-.32a21.23,21.23,0,0,0-12.47,5.58.25.25,0,0,0,.35.36c7.32-7.26,18.49-5.81,27.83-5.12,9.72.71,19.53,1.29,29.26.26a71,71,0,0,0,15.66-3.38c.3-.11.17-.59-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M651.57,291.23a67.2,67.2,0,0,1-29.81,6.8,125.17,125.17,0,0,1-16.15-1.64c-5-.68-10.3-1.39-15.3-.51-3.93.69-7.91,3.58-10.5,6.53-.21.24.14.59.35.35,2.52-2.85,6.35-5.64,10.14-6.37,5.39-1,11.26-.08,16.62.68A108.75,108.75,0,0,0,622,298.53a66.67,66.67,0,0,0,14.22-1.48,67.86,67.86,0,0,0,15.61-5.38c.29-.14,0-.58-.25-.44Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M580.52,305.2a35.42,35.42,0,0,1,13.15-5.3,39.72,39.72,0,0,1,15.05.48c4.59.92,9.13,2,13.84,2a44.35,44.35,0,0,0,13.14-1.94A48.4,48.4,0,0,0,649.2,294c.27-.18,0-.61-.25-.43a46.4,46.4,0,0,1-26.39,8.38c-5,0-9.77-1.26-14.63-2.19a39.06,39.06,0,0,0-13.55-.44,35.64,35.64,0,0,0-14.11,5.49c-.27.17,0,.61.25.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M647,294.37c-6.23,6.1-13,12.61-21.88,14.26-9.08,1.68-17.14-3.93-25.69-5.91a33.31,33.31,0,0,0-14.26-.43c-.31.07-.18.55.13.49a33,33,0,0,1,14.45.53c4.36,1.05,8.43,2.87,12.65,4.35a25.09,25.09,0,0,0,12.21,1.56,28.79,28.79,0,0,0,11.5-4.77,80.65,80.65,0,0,0,11.24-9.73.25.25,0,0,0-.35-.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M662.07,288.38a42.92,42.92,0,0,1,21.85,2.72c3.23,1.31,6.22,3.15,9.46,4.42a18.79,18.79,0,0,0,8.87,1.28c13.59-1.46,21.06-14.4,28-24.55,4.16-6.08,9.2-12.88,16.89-14.4a.25.25,0,0,0,0-.48c-13.68-3.18-26.32-12.47-40.92-9.94-12.53,2.17-22.84,11.55-28.48,22.62-1.91,3.75-3.56,7.63-6.07,11-2.39,3.25-5.35,6.48-9.65,6.69-.33,0-.33.52,0,.5,6.41-.31,10.58-7,13.24-12.09,1.64-3.17,3.05-6.46,4.93-9.51a45.86,45.86,0,0,1,6.11-7.77A38.82,38.82,0,0,1,703,248.69c6.86-2,13.83-1.24,20.58.88,7.92,2.49,15.35,6.39,23.47,8.28v-.48c-6,1.19-10.53,5.66-14.12,10.35-4.26,5.56-7.67,11.73-12.14,17.13-4.3,5.19-9.9,9.72-16.63,11.14a18.06,18.06,0,0,1-10.21-.8c-3.24-1.21-6.2-3-9.39-4.38a43.42,43.42,0,0,0-22.59-2.91c-.32,0-.18.53.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M667,287.13c6.21-1,13.15-1.45,19.14.8,3.24,1.21,6.28,3,9.69,3.74a16.91,16.91,0,0,0,8.67-.5c12.85-4.1,17.86-18,27.39-26.27a27,27,0,0,1,9.46-5.29c.53-.18,1.54-.59,1.1-1.32a.6.6,0,0,0-1.11.07.26.26,0,0,0,.49.14c-.25.88-2.82,1.34-3.64,1.7a23.65,23.65,0,0,0-3.77,2.12,35.27,35.27,0,0,0-5.9,5.15c-4.12,4.38-7.34,9.49-11.3,14S708.4,290,702.4,291.2a16.85,16.85,0,0,1-9.39-.91c-2.68-1-5.22-2.44-8-3.26-5.82-1.75-12.25-1.31-18.17-.38-.31.05-.18.53.14.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M744.72,257.16c-1.73-.39-3.53-.33-5.26-.71a82.17,82.17,0,0,1-8.22-2.55c-5.49-1.83-11-3.72-16.8-4.36a28.63,28.63,0,0,0-15,1.94c-4.13,1.87-7.17,5.13-10,8.58-3.23,4-6.42,8.16-11.3,10.16-.3.12-.17.61.13.48,8.2-3.35,11.42-12.4,18.51-17.18,9.77-6.59,22.27-3.13,32.49.27,3.12,1,6.23,2.12,9.4,3,1.92.53,3.94.44,5.87.87.31.07.44-.41.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M669.48,284.77c6.66-6.88,13.71-13.43,20.77-19.9a52,52,0,0,1,5.35-4.37c1.82-1.25,3.81-2.16,5.72-3.26a31.52,31.52,0,0,1,5.95-3c1.28-.43,2.56-.87,3.86-1.25a36.36,36.36,0,0,1,12.21-1c.32,0,.32-.48,0-.5-6.93-.41-14.19.66-20.29,4.17-3.84,2.22-7.69,4.18-11.09,7.06-3.56,3-6.86,6.37-10.27,9.55-4.27,4-8.51,8-12.56,12.18-.23.23.13.58.35.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M667.91,286c17.38-5.71,27.7-22.79,44.6-29.43a47.54,47.54,0,0,1,18-3.25.25.25,0,0,0,0-.5,48.94,48.94,0,0,0-28.24,8.72c-7.41,5-13.72,11.47-20.94,16.74a48.72,48.72,0,0,1-13.52,7.24c-.3.1-.17.59.14.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M669.43,286.1c9.16-2.05,17.63-6.19,25.56-11.12,4-2.5,7.94-5.2,11.8-8s7.9-5.94,12.14-8.44c5-3,10.91-5,16.77-3.61.31.07.45-.41.13-.48-9.92-2.3-18.56,4.35-26.1,9.8-7.89,5.71-15.9,11.37-24.66,15.67a71.55,71.55,0,0,1-15.77,5.66c-.31.07-.18.55.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M674.13,285.29A67.84,67.84,0,0,0,702.37,276c8.6-5.17,15.34-13,24.21-17.74a15.28,15.28,0,0,1,7.23-2,23,23,0,0,1,7.15.89c.31.1.44-.39.13-.48a21.19,21.19,0,0,0-11.55-.34,24.18,24.18,0,0,0-5.92,3,67.21,67.21,0,0,0-5.84,4.23c-4.08,3.24-7.94,6.76-12.23,9.72a66.91,66.91,0,0,1-31.42,11.46c-.32,0-.32.54,0,.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M741.94,258.94a36.14,36.14,0,0,0-25,9.11c-3.69,3.22-7,6.86-11.05,9.65a43.4,43.4,0,0,1-12.53,5.88,48.25,48.25,0,0,1-15.81,1.8c-.32,0-.32.48,0,.5a47.2,47.2,0,0,0,25.82-6c8.24-4.74,13.7-13,22.37-17.11a35.33,35.33,0,0,1,16.19-3.33c.32,0,.32-.49,0-.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M679.55,285.54c8.76,2,18.3,4,26.94.44,8.09-3.39,11.91-11.71,17.74-17.72a33.53,33.53,0,0,1,12.38-8.39c.3-.11.17-.59-.13-.48-7.63,2.77-13.23,8.67-18,15-2.68,3.54-5.4,7.23-9.22,9.62a25.14,25.14,0,0,1-12.84,3.46c-5.67.11-11.23-1.16-16.72-2.41-.31-.07-.45.42-.13.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M490.79,336.43a43.28,43.28,0,0,0-15.46,11.49c-4.23,4.95-6.58,11.78-12.14,15.48-5.26,3.5-12,3.93-18.11,2.82-7.13-1.3-13.64-4.75-20.2-7.68-6.91-3.09-14.94-6.33-22.49-3.53l.25.41c9.24-9.14,14.8-22.37,26.75-28.57a35.13,35.13,0,0,1,20-3.34,46.81,46.81,0,0,1,19.9,7.34,58.1,58.1,0,0,0,10.66,5.77c3.61,1.34,7.5,2.08,11,.15.29-.15,0-.58-.25-.43-3.85,2.1-8.11.85-11.91-.7a67.77,67.77,0,0,1-10-5.68,46.58,46.58,0,0,0-18.63-6.86,36.39,36.39,0,0,0-19.08,2.41c-6,2.6-10.73,7.15-14.8,12.18-4.68,5.78-8.68,12.12-14,17.38-.17.17,0,.51.25.42,7.41-2.75,15.31.45,22.1,3.48,6.48,2.89,12.92,6.3,19.95,7.67,5.51,1.06,11.51.91,16.65-1.55a18.59,18.59,0,0,0,6.79-5.66c2-2.66,3.53-5.64,5.47-8.35A42.87,42.87,0,0,1,491,336.86c.29-.13,0-.56-.25-.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M486,337.92a46.64,46.64,0,0,0-13.46,8c-4.13,3.73-6.14,9.35-10.76,12.59-4.78,3.34-11.11,3.66-16.66,2.59-6.12-1.18-11.77-3.9-17.62-5.95a45.36,45.36,0,0,0-9.81-2.4,25.65,25.65,0,0,0-5.4-.09c-.77.08-1.53.19-2.29.34l-.87.2c-.3.07-.59.15-.88.24-.41.27-.7.19-.86-.23.24.22.59-.13.36-.35-.74-.68-1.72,1.31.07,1.18a13.56,13.56,0,0,0,1.88-.46,19.38,19.38,0,0,1,3-.45,26.07,26.07,0,0,1,5.37.17c7.13.92,13.6,4.18,20.33,6.48,5.75,2,11.95,3.2,18,1.67a16.8,16.8,0,0,0,7.21-3.77c2.26-2,3.85-4.68,5.59-7.15,4.1-5.83,10.47-9.45,16.93-12.16.29-.12.16-.6-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M404.59,354c1.2-1.16,2.65-2,3.89-3.15a57.61,57.61,0,0,0,5.08-5.73c3.47-4.14,6.88-8.39,11-11.95a30.86,30.86,0,0,1,13.58-7.29c5.11-1.08,9.95.17,14.77,1.85s9.76,3.5,15,2.65c.32-.05.19-.53-.13-.48-5.12.83-10-1-14.72-2.65-4.5-1.57-9.06-2.81-13.86-2.07-10.54,1.64-18,10.12-24.51,17.86-2,2.35-3.91,4.76-6,7-1.32,1.37-3,2.32-4.37,3.64-.23.22.12.58.35.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M482.9,337.21c-4.83-1.24-9.7-2.29-14.59-3.29s-9.63-2.07-14.46-2.94a44.72,44.72,0,0,0-6.91-.79c-2.39-.07-4.77.23-7.15.27a36.86,36.86,0,0,0-9.6,1.64,36.39,36.39,0,0,0-10.73,5.46c-.26.18,0,.62.25.43,5.61-3.93,12.26-6.81,19.2-7,2.2-.07,4.41-.25,6.61-.3a39.14,39.14,0,0,1,7.25.61c4.68.78,9.31,1.88,14,2.82,5.37,1.08,10.73,2.22,16,3.58a.25.25,0,0,0,.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M484.54,337.51c-9.56,2.27-19.29.55-28.83-.94-9.14-1.43-18.34-2.45-27.44-.21a48,48,0,0,0-14.19,6.07.25.25,0,0,0,.26.43,48,48,0,0,1,27.22-7.35c9.38.28,18.52,2.66,27.85,3.41a48.9,48.9,0,0,0,15.26-.93.25.25,0,0,0-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M483.38,338.31c-9.57,3.3-19.71,4.09-29.77,3.9-5-.1-10-.43-15-.8-5.22-.38-10.5-1-15.71-.31a21.23,21.23,0,0,0-12.47,5.58.25.25,0,0,0,.35.36c7.32-7.26,18.49-5.81,27.83-5.13,9.72.72,19.54,1.3,29.26.27a71.61,71.61,0,0,0,15.66-3.38c.3-.11.17-.59-.13-.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M478.84,340.14a67.19,67.19,0,0,1-29.8,6.8,123.42,123.42,0,0,1-16.16-1.65c-5-.68-10.3-1.38-15.29-.5-3.94.69-7.92,3.58-10.51,6.53-.21.24.14.59.35.35,2.52-2.85,6.35-5.64,10.14-6.37,5.39-1,11.26-.08,16.62.68a108.9,108.9,0,0,0,15.07,1.46A66.67,66.67,0,0,0,463.48,346a67.73,67.73,0,0,0,15.61-5.39c.29-.14,0-.57-.25-.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M407.79,354.11a35.26,35.26,0,0,1,13.15-5.3,39.72,39.72,0,0,1,15.05.48c4.59.92,9.13,2,13.84,2A44.67,44.67,0,0,0,463,349.38a48.46,48.46,0,0,0,13.51-6.51c.26-.18,0-.61-.26-.43a46.4,46.4,0,0,1-26.39,8.38c-5,0-9.77-1.27-14.63-2.19a39.05,39.05,0,0,0-13.55-.44,35.64,35.64,0,0,0-14.11,5.49c-.27.17,0,.61.25.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M474.25,343.27c-6.23,6.1-13,12.61-21.88,14.26-9.08,1.69-17.14-3.92-25.69-5.9a33.31,33.31,0,0,0-14.26-.43c-.31.07-.18.55.14.48a33.22,33.22,0,0,1,14.44.53c4.36,1.06,8.43,2.88,12.65,4.36a25,25,0,0,0,12.21,1.55,28.63,28.63,0,0,0,11.5-4.76,81.32,81.32,0,0,0,11.24-9.73.25.25,0,0,0-.35-.36Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M489.34,337.29A43,43,0,0,1,511.2,340c3.22,1.31,6.21,3.15,9.45,4.42a18.81,18.81,0,0,0,8.87,1.28c13.59-1.46,21.06-14.4,28-24.55,4.16-6.08,9.2-12.88,16.89-14.4a.25.25,0,0,0,0-.48c-13.67-3.19-26.32-12.47-40.92-9.94C521,298.5,510.66,307.89,505,319c-1.91,3.75-3.56,7.63-6.07,11s-5.35,6.49-9.65,6.7c-.32,0-.33.52,0,.5,6.41-.32,10.59-7,13.24-12.09,1.64-3.17,3-6.46,4.93-9.51a45.42,45.42,0,0,1,6.12-7.77,38.73,38.73,0,0,1,16.65-10.22c6.87-2,13.84-1.24,20.58.88,7.92,2.49,15.35,6.39,23.47,8.28v-.48c-6,1.19-10.53,5.66-14.12,10.35-4.25,5.56-7.67,11.73-12.14,17.13-4.3,5.19-9.9,9.72-16.63,11.14a18.15,18.15,0,0,1-10.21-.8c-3.24-1.21-6.2-3-9.39-4.38a43.42,43.42,0,0,0-22.59-2.91c-.32,0-.18.52.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M494.28,336c6.21-1,13.15-1.45,19.15.8,3.23,1.21,6.27,3,9.68,3.74a16.8,16.8,0,0,0,8.67-.51c12.85-4.09,17.86-18,27.39-26.27a27.18,27.18,0,0,1,9.46-5.28c.54-.18,1.54-.59,1.1-1.33a.6.6,0,0,0-1.11.08.25.25,0,0,0,.49.13c-.25.89-2.82,1.35-3.64,1.71a24.4,24.4,0,0,0-3.77,2.11,36.21,36.21,0,0,0-5.9,5.15c-4.12,4.38-7.34,9.5-11.3,14s-8.83,8.52-14.83,9.73a16.8,16.8,0,0,1-9.38-.91c-2.69-1-5.23-2.44-8-3.27-5.82-1.74-12.24-1.3-18.17-.37-.31.05-.18.53.14.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M572,306.07c-1.73-.39-3.53-.33-5.25-.71a81.55,81.55,0,0,1-8.23-2.55c-5.49-1.83-11-3.72-16.8-4.36a28.63,28.63,0,0,0-15,1.93c-4.13,1.88-7.17,5.14-10,8.59-3.23,4-6.42,8.16-11.3,10.16-.29.12-.17.61.13.48,8.2-3.36,11.42-12.4,18.51-17.18,9.77-6.59,22.27-3.14,32.49.26,3.12,1,6.23,2.13,9.4,3,1.92.53,3.94.44,5.87.87.31.07.45-.41.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M496.75,333.68c6.66-6.88,13.71-13.43,20.77-19.9a50.75,50.75,0,0,1,5.35-4.37c1.82-1.26,3.81-2.16,5.72-3.27a32.09,32.09,0,0,1,5.95-3c1.28-.43,2.56-.87,3.86-1.25a35.85,35.85,0,0,1,12.21-1.05c.32,0,.32-.48,0-.5-6.93-.41-14.19.66-20.29,4.17-3.84,2.22-7.69,4.18-11.09,7.06-3.56,3-6.86,6.36-10.27,9.55-4.27,4-8.51,8-12.56,12.18-.23.23.13.58.35.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M495.18,334.88c17.38-5.71,27.7-22.8,44.61-29.43a47.45,47.45,0,0,1,18-3.25.25.25,0,0,0,0-.5,48.94,48.94,0,0,0-28.24,8.72c-7.41,5-13.72,11.47-20.94,16.74A48.72,48.72,0,0,1,495,334.4c-.3.1-.17.58.14.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M496.7,335c9.16-2.05,17.63-6.19,25.56-11.12,4-2.5,7.94-5.2,11.8-8s7.9-5.94,12.14-8.44c5-3,10.91-5,16.77-3.61.31.07.45-.41.13-.48-9.92-2.3-18.55,4.35-26.1,9.8-7.89,5.71-15.9,11.37-24.66,15.67a70.84,70.84,0,0,1-15.77,5.65c-.31.07-.18.56.13.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M501.4,334.2a67.84,67.84,0,0,0,28.24-9.32c8.6-5.18,15.34-13,24.21-17.74a15.28,15.28,0,0,1,7.23-1.95,23,23,0,0,1,7.15.89.25.25,0,0,0,.14-.48,21.1,21.1,0,0,0-11.55-.34,24.07,24.07,0,0,0-5.93,3,67.21,67.21,0,0,0-5.84,4.23c-4.08,3.24-7.94,6.76-12.23,9.72A66.91,66.91,0,0,1,501.4,333.7c-.32,0-.32.53,0,.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M569.21,307.84a36.19,36.19,0,0,0-25,9.12c-3.69,3.22-7,6.86-11.05,9.65a43,43,0,0,1-12.53,5.87,48,48,0,0,1-15.81,1.81c-.32,0-.32.48,0,.5a47.2,47.2,0,0,0,25.82-6c8.24-4.74,13.7-13,22.37-17.11a35.23,35.23,0,0,1,16.19-3.34c.32,0,.32-.49,0-.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M506.82,334.45c8.76,2,18.3,4.05,26.94.43,8.09-3.38,11.91-11.71,17.75-17.71a33.41,33.41,0,0,1,12.37-8.39c.3-.11.17-.59-.13-.48-7.63,2.77-13.23,8.66-18,15-2.68,3.54-5.4,7.23-9.22,9.62a25.14,25.14,0,0,1-12.84,3.46c-5.66.11-11.23-1.16-16.72-2.41-.31-.07-.45.41-.13.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M676.25,349.72a43.37,43.37,0,0,0-15.46,11.49c-4.23,5-6.57,11.78-12.14,15.49-5.26,3.5-12,3.93-18.1,2.82-7.14-1.31-13.65-4.76-20.2-7.69-6.92-3.08-15-6.33-22.5-3.53l.25.42c9.24-9.15,14.8-22.38,26.75-28.57a35.13,35.13,0,0,1,20-3.35,47,47,0,0,1,19.9,7.35,58.66,58.66,0,0,0,10.66,5.76c3.61,1.34,7.5,2.09,11,.16a.25.25,0,0,0-.26-.43c-3.84,2.09-8.11.84-11.91-.71a67.77,67.77,0,0,1-10-5.68,46.36,46.36,0,0,0-18.63-6.85,36.25,36.25,0,0,0-19.07,2.4c-6,2.6-10.73,7.15-14.8,12.18-4.69,5.78-8.68,12.13-14,17.38-.16.17,0,.51.25.42,7.41-2.75,15.31.45,22.1,3.48,6.49,2.9,12.92,6.31,19.95,7.67,5.51,1.07,11.51.91,16.65-1.55a18.59,18.59,0,0,0,6.79-5.66c2-2.65,3.54-5.64,5.47-8.35a43,43,0,0,1,17.55-14.22c.3-.13,0-.56-.25-.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M671.45,351.21a47.23,47.23,0,0,0-13.46,8c-4.13,3.74-6.14,9.36-10.76,12.59s-11.1,3.67-16.66,2.59c-6.11-1.18-11.77-3.89-17.62-5.94a45.38,45.38,0,0,0-9.81-2.41,26.34,26.34,0,0,0-5.4-.08,22,22,0,0,0-2.28.33l-.88.2-.88.24c-.41.27-.7.2-.85-.22.23.21.59-.14.35-.36-.74-.68-1.72,1.32.08,1.18a13.78,13.78,0,0,0,1.87-.46,20.83,20.83,0,0,1,3-.45,26.79,26.79,0,0,1,5.37.18c7.13.91,13.6,4.17,20.33,6.47,5.75,2,12,3.2,18,1.68a16.91,16.91,0,0,0,7.21-3.77c2.26-2.05,3.85-4.69,5.59-7.16,4.1-5.83,10.47-9.45,16.93-12.15.29-.13.17-.61-.13-.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M590.05,367.26c1.2-1.16,2.66-2,3.89-3.15a57.61,57.61,0,0,0,5.08-5.73c3.48-4.14,6.88-8.39,11-11.95a30.86,30.86,0,0,1,13.58-7.29c5.11-1.08,10,.17,14.78,1.86s9.75,3.49,15,2.64c.31-.05.18-.53-.14-.48-5.12.83-10-1-14.72-2.65-4.5-1.57-9.06-2.81-13.86-2.07-10.54,1.64-18,10.12-24.51,17.86-2,2.35-3.9,4.77-6,7-1.32,1.36-3,2.31-4.37,3.63a.25.25,0,0,0,.35.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M668.37,350.5c-4.84-1.23-9.71-2.29-14.6-3.29s-9.63-2.07-14.46-2.94a45.84,45.84,0,0,0-6.91-.79c-2.38-.06-4.76.23-7.15.27a36.26,36.26,0,0,0-20.33,7.1c-.26.19,0,.62.26.43,5.6-3.93,12.25-6.8,19.19-7,2.21-.06,4.41-.25,6.61-.3a39.15,39.15,0,0,1,7.25.62c4.68.78,9.32,1.88,14,2.81,5.38,1.09,10.73,2.23,16,3.58.31.08.45-.4.14-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M670,350.8c-9.56,2.27-19.29.55-28.82-.94-9.14-1.43-18.35-2.45-27.45-.21a48.08,48.08,0,0,0-14.19,6.07.25.25,0,0,0,.26.43A48.09,48.09,0,0,1,627,348.8c9.37.28,18.51,2.66,27.84,3.41a48.9,48.9,0,0,0,15.26-.93c.31-.07.18-.55-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M668.84,351.61c-9.56,3.29-19.71,4.08-29.77,3.89-5-.1-10-.42-15-.79-5.21-.39-10.5-1-15.71-.32A21.18,21.18,0,0,0,595.87,360c-.23.22.12.58.35.35,7.32-7.26,18.49-5.81,27.83-5.12,9.73.71,19.54,1.29,29.26.26A70.47,70.47,0,0,0,669,352.09c.31-.1.18-.59-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M664.3,353.44a67.32,67.32,0,0,1-29.8,6.79,125.14,125.14,0,0,1-16.16-1.64c-5-.68-10.29-1.39-15.29-.51-3.94.69-7.91,3.58-10.51,6.53-.21.24.14.6.36.35,2.51-2.85,6.34-5.64,10.14-6.37,5.38-1,11.25-.08,16.61.68a108.9,108.9,0,0,0,15.07,1.46,67.21,67.21,0,0,0,29.83-6.86c.29-.14,0-.57-.25-.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M593.25,367.4a35.42,35.42,0,0,1,13.15-5.3,39.71,39.71,0,0,1,15.05.48c4.59.92,9.13,2,13.84,2a44.78,44.78,0,0,0,13.15-1.93,48.81,48.81,0,0,0,13.5-6.51c.26-.19,0-.62-.26-.44a46.4,46.4,0,0,1-26.39,8.38c-5,0-9.76-1.26-14.63-2.19a39.55,39.55,0,0,0-13.55-.44A35.64,35.64,0,0,0,593,367c-.27.18,0,.61.25.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M659.71,356.57c-6.23,6.1-13,12.61-21.88,14.26-9.08,1.68-17.14-3.93-25.69-5.91a33.31,33.31,0,0,0-14.26-.42c-.31.06-.18.54.14.48a32.91,32.91,0,0,1,14.44.53c4.36,1.05,8.43,2.88,12.65,4.36a25.1,25.1,0,0,0,12.21,1.55,28.89,28.89,0,0,0,11.51-4.77,80,80,0,0,0,11.23-9.73c.24-.23-.12-.58-.35-.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M674.8,350.59a42.84,42.84,0,0,1,21.86,2.71c3.23,1.31,6.21,3.15,9.46,4.42A18.52,18.52,0,0,0,715,359c13.6-1.46,21.06-14.4,28-24.55,4.17-6.08,9.2-12.87,16.89-14.4.24,0,.23-.42,0-.48-13.67-3.18-26.32-12.47-40.92-9.94-12.53,2.17-22.83,11.55-28.48,22.62-1.91,3.75-3.56,7.63-6.06,11-2.4,3.25-5.36,6.48-9.66,6.69-.32,0-.32.52,0,.5,6.41-.31,10.59-7,13.24-12.08,1.64-3.18,3-6.47,4.94-9.51a44.13,44.13,0,0,1,6.11-7.77,38.65,38.65,0,0,1,16.65-10.23c6.87-2,13.84-1.24,20.58.88,7.92,2.49,15.35,6.39,23.47,8.28v-.48c-6,1.19-10.53,5.66-14.12,10.35-4.25,5.56-7.67,11.73-12.14,17.13-4.3,5.19-9.9,9.72-16.63,11.15a18.16,18.16,0,0,1-10.21-.81c-3.24-1.21-6.2-3-9.39-4.38a43.41,43.41,0,0,0-22.59-2.91c-.32,0-.18.53.13.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M679.74,349.33c6.21-1,13.15-1.45,19.15.8,3.24,1.22,6.27,3,9.68,3.74a16.85,16.85,0,0,0,8.68-.5c12.85-4.09,17.85-18,27.39-26.27a27.07,27.07,0,0,1,9.45-5.29c.54-.18,1.54-.59,1.11-1.32a.6.6,0,0,0-1.11.07.25.25,0,0,0,.48.14c-.25.88-2.82,1.34-3.64,1.7a24.46,24.46,0,0,0-3.77,2.12,35.68,35.68,0,0,0-5.9,5.15c-4.12,4.38-7.34,9.49-11.29,14s-8.84,8.51-14.84,9.72a16.8,16.8,0,0,1-9.38-.91c-2.69-1-5.22-2.44-8-3.26-5.82-1.75-12.24-1.31-18.16-.38-.32,0-.19.53.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M757.45,319.36c-1.72-.38-3.53-.32-5.25-.7A84.25,84.25,0,0,1,744,316.1c-5.49-1.83-11-3.72-16.8-4.36a28.65,28.65,0,0,0-15,1.94c-4.13,1.87-7.17,5.13-10,8.58-3.23,4-6.42,8.16-11.3,10.16-.29.12-.16.61.13.49,8.2-3.36,11.42-12.41,18.52-17.19,9.76-6.58,22.26-3.13,32.48.27,3.12,1,6.23,2.12,9.41,3,1.91.52,3.93.43,5.86.86.31.07.45-.41.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M682.21,347c6.66-6.88,13.72-13.43,20.77-19.9a52.39,52.39,0,0,1,5.36-4.37c1.82-1.25,3.8-2.16,5.71-3.26a31.82,31.82,0,0,1,6-3c1.28-.43,2.56-.87,3.86-1.24a36.1,36.1,0,0,1,12.21-1.06c.32,0,.32-.48,0-.5-6.93-.41-14.19.66-20.29,4.17-3.84,2.22-7.68,4.18-11.08,7.06-3.57,3-6.86,6.37-10.28,9.55-4.27,4-8.5,8-12.56,12.18-.22.23.13.58.35.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M680.64,348.18c17.38-5.71,27.7-22.8,44.61-29.43a47.46,47.46,0,0,1,18-3.26.25.25,0,0,0,0-.5A49,49,0,0,0,715,323.71c-7.4,5-13.72,11.47-20.94,16.74a48.8,48.8,0,0,1-13.51,7.24c-.31.1-.18.59.13.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M682.17,348.3c9.15-2.05,17.63-6.19,25.56-11.12,4-2.5,7.93-5.2,11.79-8s7.9-5.94,12.15-8.44c5-3,10.9-5,16.76-3.61.31.07.45-.41.13-.48-9.92-2.3-18.55,4.35-26.1,9.8-7.89,5.71-15.89,11.37-24.66,15.67A71.19,71.19,0,0,1,682,347.82c-.31.07-.18.55.14.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M686.86,347.5a68,68,0,0,0,28.24-9.33c8.61-5.17,15.34-13,24.21-17.74a15.16,15.16,0,0,1,7.23-1.94,22.68,22.68,0,0,1,7.15.88c.31.1.44-.39.14-.48a21.2,21.2,0,0,0-11.55-.34,24.42,24.42,0,0,0-5.93,3,69.39,69.39,0,0,0-5.84,4.23c-4.08,3.25-7.94,6.76-12.23,9.72a66.63,66.63,0,0,1-14,7.35A67.88,67.88,0,0,1,686.86,347c-.32,0-.32.53,0,.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M754.67,321.14a36.14,36.14,0,0,0-25,9.11c-3.69,3.22-7,6.87-11,9.65a43.28,43.28,0,0,1-12.53,5.88,48.25,48.25,0,0,1-15.81,1.8c-.32,0-.32.48,0,.5a47.25,47.25,0,0,0,25.83-6c8.23-4.74,13.69-13,22.37-17.1a35.16,35.16,0,0,1,16.18-3.34c.32,0,.32-.49,0-.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M692.28,347.74c8.76,2,18.3,4.05,26.94.44,8.1-3.39,11.91-11.71,17.75-17.72a33.49,33.49,0,0,1,12.38-8.39c.3-.11.17-.59-.14-.48-7.63,2.78-13.22,8.67-18,15-2.68,3.53-5.4,7.22-9.22,9.61a25.14,25.14,0,0,1-12.84,3.46c-5.66.11-11.23-1.16-16.72-2.4-.31-.07-.44.41-.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M503.52,398.63a43.37,43.37,0,0,0-15.46,11.49c-4.23,5-6.57,11.78-12.14,15.49-5.26,3.5-12,3.92-18.1,2.82-7.14-1.31-13.65-4.76-20.2-7.69-6.92-3.08-14.95-6.33-22.49-3.53l.24.41c9.24-9.14,14.8-22.37,26.76-28.57a35.16,35.16,0,0,1,20-3.34,46.83,46.83,0,0,1,19.9,7.35,58.66,58.66,0,0,0,10.66,5.76c3.61,1.34,7.5,2.08,11,.16.28-.16,0-.59-.26-.44-3.84,2.1-8.11.85-11.91-.7a68.29,68.29,0,0,1-10-5.68,46.52,46.52,0,0,0-18.63-6.86,36.36,36.36,0,0,0-19.07,2.41c-6,2.6-10.73,7.15-14.8,12.18-4.69,5.78-8.68,12.13-14,17.38-.16.17,0,.51.25.42,7.41-2.75,15.31.45,22.1,3.48,6.49,2.89,12.92,6.31,19.95,7.67,5.51,1.07,11.51.91,16.65-1.55a18.59,18.59,0,0,0,6.79-5.66c2-2.66,3.54-5.64,5.47-8.35a43,43,0,0,1,17.55-14.22c.3-.13,0-.56-.25-.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M498.72,400.12a47,47,0,0,0-13.46,8c-4.13,3.74-6.14,9.35-10.76,12.59s-11.1,3.67-16.66,2.59c-6.11-1.18-11.77-3.9-17.61-5.94a45.61,45.61,0,0,0-9.82-2.41,25.65,25.65,0,0,0-5.4-.08,22,22,0,0,0-2.28.33l-.88.2c-.29.07-.59.15-.87.24-.42.27-.71.2-.86-.23.23.22.59-.13.35-.35-.74-.68-1.72,1.31.08,1.18a13.78,13.78,0,0,0,1.87-.46,19.56,19.56,0,0,1,3-.45,26.79,26.79,0,0,1,5.37.17c7.13.92,13.6,4.18,20.33,6.48,5.75,2,11.95,3.2,18,1.67a16.8,16.8,0,0,0,7.21-3.77c2.26-2,3.85-4.68,5.59-7.15,4.1-5.83,10.47-9.45,16.93-12.15.29-.13.17-.61-.13-.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M417.33,416.17c1.19-1.16,2.65-2,3.88-3.15a57.61,57.61,0,0,0,5.08-5.73c3.48-4.14,6.88-8.39,11-12a30.86,30.86,0,0,1,13.58-7.29c5.11-1.08,10,.17,14.78,1.86s9.76,3.49,15,2.64c.31-.05.18-.53-.14-.48-5.12.83-10-1-14.72-2.65-4.5-1.57-9.06-2.81-13.86-2.07-10.54,1.64-18,10.12-24.51,17.86-2,2.35-3.9,4.76-6,7-1.32,1.36-3,2.31-4.37,3.63-.23.22.12.58.36.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M495.64,399.41c-4.84-1.24-9.71-2.29-14.6-3.29s-9.62-2.07-14.46-2.94a44.58,44.58,0,0,0-6.91-.79c-2.38-.07-4.76.23-7.15.27a36.86,36.86,0,0,0-9.6,1.64,36.39,36.39,0,0,0-10.73,5.46c-.26.18,0,.62.26.43,5.6-3.93,12.25-6.8,19.19-7,2.21-.07,4.41-.25,6.61-.3a39.14,39.14,0,0,1,7.25.61c4.68.79,9.32,1.89,14,2.82,5.37,1.09,10.72,2.23,16,3.58a.25.25,0,0,0,.14-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M497.27,399.71c-9.56,2.27-19.29.55-28.82-.94-9.14-1.43-18.35-2.45-27.45-.21a47.91,47.91,0,0,0-14.18,6.07.25.25,0,0,0,.25.43,48.09,48.09,0,0,1,27.23-7.35c9.37.28,18.51,2.66,27.84,3.41a48.9,48.9,0,0,0,15.26-.93c.31-.07.18-.56-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M496.11,400.52c-9.56,3.29-19.71,4.08-29.77,3.89-5-.1-10-.43-15-.79-5.21-.39-10.5-1-15.71-.32a21.23,21.23,0,0,0-12.47,5.58.25.25,0,0,0,.35.36c7.33-7.26,18.49-5.81,27.83-5.12,9.73.71,19.54,1.29,29.27.26A71.07,71.07,0,0,0,496.24,401c.31-.11.18-.59-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M491.57,402.34a67.19,67.19,0,0,1-29.8,6.8,125.14,125.14,0,0,1-16.16-1.64c-5-.68-10.29-1.39-15.29-.51-3.94.69-7.91,3.58-10.51,6.53-.21.24.14.59.36.35,2.51-2.85,6.34-5.64,10.14-6.37,5.38-1,11.25-.08,16.61.68A108.9,108.9,0,0,0,462,409.64a66.7,66.7,0,0,0,14.23-1.48,67.78,67.78,0,0,0,15.6-5.38c.29-.14,0-.58-.25-.44Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M420.52,416.31a35.42,35.42,0,0,1,13.15-5.3,39.71,39.71,0,0,1,15.05.48c4.59.92,9.13,2,13.84,2a44.41,44.41,0,0,0,13.15-1.94,48.53,48.53,0,0,0,13.5-6.51c.26-.18,0-.61-.25-.43a46.41,46.41,0,0,1-26.4,8.38c-5,0-9.76-1.26-14.63-2.19a39.05,39.05,0,0,0-13.55-.44,35.64,35.64,0,0,0-14.11,5.49c-.27.17,0,.61.25.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M487,405.48c-6.23,6.1-13,12.61-21.88,14.26-9.07,1.68-17.14-3.93-25.69-5.91a33.27,33.27,0,0,0-14.25-.43c-.32.07-.19.55.13.49a32.91,32.91,0,0,1,14.44.53c4.36,1.05,8.43,2.87,12.65,4.35a25.11,25.11,0,0,0,12.21,1.56,28.89,28.89,0,0,0,11.51-4.77,80.65,80.65,0,0,0,11.24-9.73c.23-.23-.13-.58-.36-.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M502.07,399.49a43,43,0,0,1,21.86,2.72c3.23,1.31,6.21,3.15,9.46,4.42a18.77,18.77,0,0,0,8.86,1.28c13.6-1.46,21.06-14.4,28-24.55,4.17-6.08,9.2-12.88,16.89-14.4a.25.25,0,0,0,0-.48c-13.67-3.18-26.32-12.47-40.92-9.94-12.52,2.17-22.83,11.55-28.48,22.62-1.91,3.75-3.55,7.63-6.06,11-2.4,3.25-5.36,6.48-9.66,6.69-.32,0-.32.52,0,.5,6.41-.31,10.59-7,13.24-12.09,1.64-3.17,3.05-6.46,4.94-9.51a44.88,44.88,0,0,1,6.11-7.77A38.79,38.79,0,0,1,543,359.8c6.87-2,13.84-1.24,20.58.88,7.92,2.49,15.35,6.39,23.47,8.28v-.48c-6,1.19-10.53,5.66-14.12,10.35-4.25,5.56-7.67,11.73-12.14,17.13-4.3,5.19-9.9,9.72-16.63,11.14a18.07,18.07,0,0,1-10.21-.8c-3.24-1.21-6.2-3-9.39-4.38A43.41,43.41,0,0,0,501.94,399c-.32,0-.18.53.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M507,398.24c6.21-1,13.15-1.45,19.15.8,3.24,1.21,6.27,3,9.68,3.74a17,17,0,0,0,8.68-.5c12.85-4.1,17.85-18,27.39-26.27a27.07,27.07,0,0,1,9.45-5.29c.54-.18,1.54-.59,1.11-1.32a.6.6,0,0,0-1.11.07.25.25,0,0,0,.48.14c-.25.88-2.82,1.34-3.64,1.7a23.65,23.65,0,0,0-3.77,2.12,35.68,35.68,0,0,0-5.9,5.15c-4.12,4.38-7.34,9.49-11.29,14s-8.84,8.51-14.84,9.72a16.81,16.81,0,0,1-9.38-.91c-2.69-1-5.22-2.44-8-3.26-5.82-1.75-12.24-1.31-18.16-.38-.32.05-.19.53.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M584.72,368.27c-1.72-.39-3.53-.33-5.25-.71a81.55,81.55,0,0,1-8.23-2.55c-5.49-1.83-11-3.72-16.8-4.36a28.65,28.65,0,0,0-15,1.94c-4.13,1.87-7.17,5.13-10,8.58-3.23,4-6.42,8.16-11.3,10.16-.29.12-.16.61.13.49,8.2-3.36,11.42-12.41,18.52-17.19,9.76-6.59,22.26-3.13,32.49.27,3.11,1,6.22,2.12,9.4,3,1.91.53,3.93.44,5.86.87.31.07.45-.41.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M509.48,395.88c6.66-6.88,13.72-13.43,20.77-19.9a51.09,51.09,0,0,1,5.36-4.37c1.82-1.25,3.8-2.16,5.71-3.26a31.82,31.82,0,0,1,5.95-3c1.28-.43,2.56-.87,3.86-1.25a36.36,36.36,0,0,1,12.21-1.05c.32,0,.32-.48,0-.5-6.93-.41-14.19.66-20.29,4.17-3.84,2.22-7.68,4.18-11.08,7.06-3.56,3-6.86,6.37-10.28,9.55-4.27,4-8.5,8-12.56,12.18-.22.23.13.58.35.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M507.91,397.08c17.38-5.71,27.7-22.79,44.61-29.43a47.49,47.49,0,0,1,18-3.25.25.25,0,0,0,0-.5,49,49,0,0,0-28.24,8.72c-7.4,5-13.72,11.47-20.94,16.74a48.8,48.8,0,0,1-13.51,7.24c-.31.1-.18.59.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M509.44,397.21c9.15-2,17.63-6.19,25.56-11.12,4-2.5,7.93-5.2,11.79-8s7.9-5.94,12.15-8.44c5-3,10.9-5,16.76-3.61.31.07.45-.41.13-.48-9.92-2.3-18.55,4.35-26.1,9.8-7.89,5.71-15.89,11.37-24.66,15.67a71.19,71.19,0,0,1-15.77,5.66c-.31.07-.18.55.14.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M514.13,396.4a67.79,67.79,0,0,0,28.24-9.32c8.61-5.17,15.34-13,24.21-17.74a15.28,15.28,0,0,1,7.23-1.95,23.08,23.08,0,0,1,7.16.89c.3.1.44-.39.13-.48a21.2,21.2,0,0,0-11.55-.34,24.24,24.24,0,0,0-5.93,3,67.21,67.21,0,0,0-5.84,4.23c-4.07,3.24-7.94,6.76-12.23,9.72a66.91,66.91,0,0,1-31.42,11.46c-.32,0-.32.54,0,.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M581.94,370.05a36.14,36.14,0,0,0-25,9.11c-3.69,3.22-7,6.86-11.05,9.65a43.35,43.35,0,0,1-12.52,5.88,48.34,48.34,0,0,1-15.82,1.8c-.32,0-.32.48,0,.5a47.23,47.23,0,0,0,25.83-6c8.24-4.74,13.7-13,22.37-17.11a35.28,35.28,0,0,1,16.18-3.33c.33,0,.32-.49,0-.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M519.55,396.65c8.76,2,18.3,4.05,26.94.44,8.1-3.39,11.91-11.71,17.75-17.72A33.37,33.37,0,0,1,576.62,371c.3-.11.17-.59-.14-.48-7.63,2.77-13.22,8.67-18,15-2.68,3.54-5.4,7.23-9.22,9.62a25.14,25.14,0,0,1-12.84,3.46c-5.66.11-11.23-1.16-16.72-2.41-.31-.07-.44.42-.13.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M547.21,412.4a43.48,43.48,0,0,0,15.46-11.49c4.23-5,6.57-11.79,12.14-15.49,5.26-3.5,12-3.93,18.1-2.82,7.14,1.3,13.65,4.76,20.2,7.68,6.92,3.09,14.95,6.33,22.5,3.54l-.25-.42c-9.24,9.14-14.8,22.37-26.76,28.57a35,35,0,0,1-20,3.34,46.81,46.81,0,0,1-19.9-7.34,58.06,58.06,0,0,0-10.66-5.76c-3.61-1.35-7.5-2.09-11-.16-.28.15,0,.58.26.43,3.84-2.1,8.11-.85,11.91.7a67.44,67.44,0,0,1,10,5.69,46.5,46.5,0,0,0,18.63,6.85,36.46,36.46,0,0,0,19.07-2.4c6-2.6,10.73-7.16,14.8-12.18,4.69-5.79,8.68-12.13,14-17.39.16-.16,0-.51-.25-.42-7.41,2.75-15.31-.45-22.1-3.48-6.49-2.89-12.92-6.3-20-7.66-5.51-1.07-11.51-.92-16.65,1.54a18.69,18.69,0,0,0-6.79,5.66c-2,2.66-3.54,5.64-5.47,8.35A42.79,42.79,0,0,1,547,412c-.3.13,0,.56.25.44Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M552,410.9a46.78,46.78,0,0,0,13.46-8c4.13-3.73,6.14-9.35,10.76-12.59s11.1-3.66,16.66-2.59c6.11,1.19,11.77,3.9,17.62,5.95a45.36,45.36,0,0,0,9.81,2.4,25.65,25.65,0,0,0,5.4.09,19.59,19.59,0,0,0,2.28-.34c.3-.06.59-.12.88-.2s.59-.15.88-.24c.41-.27.7-.19.85.23-.23-.22-.59.13-.35.35.74.69,1.72-1.31-.08-1.18a13.83,13.83,0,0,0-1.87.47,22.29,22.29,0,0,1-3,.45,26.79,26.79,0,0,1-5.37-.18c-7.13-.92-13.6-4.17-20.33-6.47-5.75-2-12-3.21-18-1.68a16.91,16.91,0,0,0-7.21,3.77c-2.26,2.05-3.85,4.68-5.59,7.15-4.1,5.83-10.47,9.45-16.93,12.16-.29.12-.17.6.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M633.4,394.85c-1.19,1.16-2.65,2-3.88,3.16a56.4,56.4,0,0,0-5.08,5.72c-3.48,4.14-6.88,8.39-11,11.95A30.86,30.86,0,0,1,599.89,423c-5.11,1.08-10-.17-14.78-1.85s-9.76-3.5-15-2.65c-.31,0-.18.54.14.48,5.12-.83,10,1,14.72,2.65,4.5,1.57,9.06,2.81,13.86,2.07,10.54-1.64,18-10.11,24.51-17.86,2-2.35,3.9-4.76,6-7,1.32-1.37,3-2.31,4.37-3.64.23-.22-.12-.57-.36-.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M555.09,411.61c4.84,1.24,9.71,2.3,14.6,3.29s9.63,2.07,14.46,3a47.16,47.16,0,0,0,6.91.79c2.38.06,4.76-.24,7.15-.28a36.86,36.86,0,0,0,9.6-1.64,36.39,36.39,0,0,0,10.73-5.46c.26-.18,0-.61-.26-.43-5.6,3.93-12.25,6.81-19.19,7-2.21.07-4.41.25-6.61.3a39.14,39.14,0,0,1-7.25-.61c-4.68-.78-9.32-1.88-14-2.82-5.37-1.08-10.72-2.22-16-3.58a.25.25,0,0,0-.14.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M553.46,411.31c9.56-2.27,19.29-.55,28.82.94,9.14,1.43,18.35,2.45,27.45.21a47.88,47.88,0,0,0,14.18-6.06c.28-.17,0-.61-.25-.44a48,48,0,0,1-27.23,7.35c-9.37-.28-18.51-2.66-27.84-3.41a48.9,48.9,0,0,0-15.26.93.25.25,0,0,0,.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M554.62,410.51c9.56-3.3,19.71-4.09,29.77-3.89,5,.09,10,.42,15,.79,5.21.38,10.5,1,15.71.32a21.3,21.3,0,0,0,12.47-5.59.25.25,0,0,0-.35-.35c-7.33,7.25-18.49,5.8-27.83,5.12-9.73-.72-19.54-1.3-29.27-.27A71.09,71.09,0,0,0,554.49,410c-.31.1-.18.58.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M559.16,408.68a67.33,67.33,0,0,1,29.8-6.8,125.16,125.16,0,0,1,16.16,1.65c5,.68,10.29,1.38,15.29.5,3.94-.69,7.91-3.58,10.51-6.53.21-.24-.14-.59-.36-.35-2.51,2.85-6.34,5.65-10.14,6.37-5.38,1-11.25.08-16.61-.68a110.46,110.46,0,0,0-15.07-1.46,67.41,67.41,0,0,0-29.83,6.87c-.29.14,0,.57.25.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M630.21,394.71a35.09,35.09,0,0,1-13.15,5.3,39.71,39.71,0,0,1-15-.48c-4.59-.92-9.13-2-13.84-2A44.74,44.74,0,0,0,575,399.44a48.81,48.81,0,0,0-13.5,6.51c-.26.18,0,.61.25.43a46.58,46.58,0,0,1,26.4-8.38c5,0,9.76,1.27,14.63,2.19a39.3,39.3,0,0,0,13.55.45,35.64,35.64,0,0,0,14.11-5.49c.27-.18,0-.61-.25-.44Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M563.75,405.55c6.23-6.1,13-12.61,21.88-14.26,9.07-1.68,17.14,3.93,25.69,5.91a33.42,33.42,0,0,0,14.25.42c.32-.06.19-.55-.13-.48a33.21,33.21,0,0,1-14.44-.53c-4.36-1.06-8.43-2.88-12.65-4.36a25,25,0,0,0-12.21-1.55,28.73,28.73,0,0,0-11.51,4.76,82,82,0,0,0-11.24,9.73c-.23.23.13.58.36.36Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M548.66,411.53a42.94,42.94,0,0,1-21.86-2.71c-3.23-1.31-6.21-3.16-9.46-4.43a18.64,18.64,0,0,0-8.86-1.27c-13.6,1.46-21.06,14.39-28,24.54-4.17,6.08-9.2,12.88-16.89,14.4a.25.25,0,0,0,0,.48c13.67,3.19,26.32,12.47,40.92,9.95,12.52-2.17,22.83-11.56,28.48-22.63,1.91-3.75,3.55-7.63,6.06-11,2.4-3.25,5.36-6.49,9.66-6.7.32,0,.32-.51,0-.5-6.41.32-10.59,7-13.24,12.09-1.64,3.17-3.05,6.47-4.94,9.51a44.5,44.5,0,0,1-6.11,7.77,38.69,38.69,0,0,1-16.65,10.22c-6.87,2-13.84,1.25-20.58-.87-7.92-2.5-15.35-6.4-23.47-8.29v.48c6-1.18,10.53-5.65,14.12-10.35,4.25-5.56,7.67-11.73,12.14-17.13,4.3-5.19,9.9-9.72,16.63-11.14a18.16,18.16,0,0,1,10.21.8c3.24,1.21,6.2,3,9.39,4.38A43.31,43.31,0,0,0,548.79,412c.32,0,.18-.52-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M543.72,412.78c-6.21,1-13.15,1.45-19.15-.8-3.24-1.21-6.27-3-9.68-3.73a16.74,16.74,0,0,0-8.68.5c-12.85,4.09-17.85,18-27.39,26.27a27,27,0,0,1-9.45,5.28c-.54.18-1.54.59-1.11,1.33a.6.6,0,0,0,1.11-.08.25.25,0,0,0-.48-.13c.25-.89,2.82-1.35,3.64-1.71a23.6,23.6,0,0,0,3.77-2.11,36.63,36.63,0,0,0,5.9-5.15c4.12-4.38,7.34-9.5,11.29-14s8.84-8.52,14.84-9.73a17,17,0,0,1,9.38.91c2.69,1,5.22,2.44,8,3.27,5.82,1.74,12.24,1.3,18.16.38.32,0,.19-.54-.13-.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M466,442.75c1.72.39,3.53.33,5.25.71a81.55,81.55,0,0,1,8.23,2.55c5.49,1.84,11,3.73,16.8,4.36a28.64,28.64,0,0,0,15-1.93c4.13-1.88,7.17-5.13,10-8.59,3.23-4,6.42-8.16,11.3-10.16.29-.12.16-.6-.13-.48-8.2,3.36-11.42,12.4-18.52,17.19-9.76,6.58-22.26,3.13-32.49-.27-3.11-1-6.22-2.12-9.4-3-1.91-.52-3.93-.44-5.86-.87-.31-.07-.45.41-.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M541.25,415.14c-6.66,6.88-13.72,13.43-20.77,19.9a49.86,49.86,0,0,1-5.36,4.37c-1.82,1.26-3.8,2.17-5.71,3.27a32.4,32.4,0,0,1-6,3c-1.28.43-2.56.87-3.86,1.25A35.85,35.85,0,0,1,487.39,448c-.32,0-.32.48,0,.5,6.93.42,14.19-.66,20.29-4.17,3.84-2.21,7.68-4.17,11.08-7.06,3.56-3,6.86-6.36,10.28-9.54,4.27-4,8.5-8,12.56-12.18.22-.24-.13-.59-.35-.36Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M542.82,413.94c-17.38,5.71-27.7,22.8-44.61,29.43a47.69,47.69,0,0,1-17.95,3.26c-.32,0-.32.49,0,.5a49,49,0,0,0,28.24-8.73c7.4-5,13.72-11.46,20.94-16.74A49.07,49.07,0,0,1,543,414.42c.31-.1.18-.58-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M541.29,413.82c-9.15,2-17.63,6.18-25.56,11.11-4,2.5-7.93,5.21-11.79,8s-7.9,5.94-12.15,8.45c-5,3-10.9,5-16.76,3.6-.31-.07-.45.41-.13.49,9.92,2.3,18.55-4.35,26.1-9.81,7.89-5.7,15.89-11.37,24.66-15.67a71.16,71.16,0,0,1,15.77-5.65c.31-.07.18-.55-.14-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M536.6,414.62a67.94,67.94,0,0,0-28.24,9.32c-8.61,5.18-15.34,13-24.21,17.75a15.39,15.39,0,0,1-7.23,1.94,22.78,22.78,0,0,1-7.16-.89.25.25,0,0,0-.13.48,21.1,21.1,0,0,0,11.55.34,23.9,23.9,0,0,0,5.93-3A67.21,67.21,0,0,0,493,436.3c4.07-3.24,7.94-6.76,12.23-9.72a66.91,66.91,0,0,1,31.42-11.46c.32,0,.32-.53,0-.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M468.79,441a36.19,36.19,0,0,0,25-9.12c3.69-3.21,7-6.86,11.05-9.65a43.06,43.06,0,0,1,12.52-5.87,48.07,48.07,0,0,1,15.82-1.8c.32,0,.32-.49,0-.5a47.16,47.16,0,0,0-25.83,6c-8.24,4.74-13.7,13-22.37,17.11a35.16,35.16,0,0,1-16.18,3.34c-.33,0-.32.49,0,.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M531.18,414.38c-8.76-2-18.3-4.06-26.94-.44-8.1,3.39-11.91,11.71-17.75,17.71A33.25,33.25,0,0,1,474.11,440c-.3.11-.17.59.14.48,7.63-2.77,13.22-8.66,18-15,2.68-3.54,5.4-7.23,9.22-9.62a25.13,25.13,0,0,1,12.84-3.45c5.66-.12,11.23,1.15,16.72,2.4.31.07.44-.41.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M719.94,363.49A43.48,43.48,0,0,0,735.4,352c4.23-5,6.57-11.79,12.14-15.49,5.26-3.5,12-3.93,18.1-2.82,7.14,1.3,13.65,4.76,20.2,7.68,6.91,3.09,14.95,6.33,22.49,3.54l-.24-.42c-9.24,9.14-14.8,22.38-26.76,28.57a35.09,35.09,0,0,1-20,3.35,47,47,0,0,1-19.9-7.35,58.06,58.06,0,0,0-10.66-5.76c-3.61-1.35-7.5-2.09-11-.16-.28.15,0,.58.26.43,3.84-2.1,8.11-.85,11.91.7A67.44,67.44,0,0,1,742,370a46.52,46.52,0,0,0,18.62,6.85,36.48,36.48,0,0,0,19.08-2.4c6-2.6,10.73-7.16,14.8-12.18,4.69-5.79,8.68-12.13,14-17.39.17-.16,0-.5-.24-.41-7.41,2.74-15.31-.45-22.1-3.49-6.49-2.89-12.92-6.3-20-7.66-5.51-1.07-11.51-.91-16.65,1.54a18.75,18.75,0,0,0-6.8,5.67c-2,2.65-3.53,5.64-5.46,8.35a43,43,0,0,1-17.55,14.22c-.3.12,0,.56.25.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M724.74,362a47,47,0,0,0,13.46-8c4.13-3.74,6.14-9.36,10.76-12.59s11.1-3.67,16.66-2.6c6.11,1.19,11.77,3.9,17.61,6a45.59,45.59,0,0,0,9.82,2.4,25.65,25.65,0,0,0,5.4.09,19.59,19.59,0,0,0,2.28-.34c.3-.06.59-.12.88-.2s.59-.15.87-.24c.42-.27.71-.19.86.23-.23-.22-.59.13-.35.35.74.69,1.71-1.31-.08-1.17a12.44,12.44,0,0,0-1.87.46,22.29,22.29,0,0,1-3,.45,26.79,26.79,0,0,1-5.37-.18c-7.13-.92-13.6-4.17-20.33-6.47-5.75-2-11.95-3.21-18-1.68a16.91,16.91,0,0,0-7.21,3.77c-2.26,2.05-3.85,4.68-5.59,7.15-4.1,5.83-10.47,9.45-16.93,12.16-.3.12-.17.61.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M806.13,345.94c-1.19,1.17-2.65,2-3.88,3.16a55.45,55.45,0,0,0-5.08,5.73c-3.48,4.13-6.88,8.38-11,11.94a30.76,30.76,0,0,1-13.58,7.29c-5.11,1.08-10-.17-14.78-1.85s-9.76-3.5-15-2.65c-.31.06-.18.54.14.49,5.12-.84,10,1,14.72,2.64,4.5,1.57,9.06,2.82,13.86,2.07,10.54-1.63,18-10.11,24.51-17.85,2-2.36,3.9-4.77,6-7,1.31-1.37,3-2.31,4.37-3.63.23-.23-.12-.58-.36-.36Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M727.82,362.71c4.84,1.23,9.71,2.29,14.6,3.28s9.62,2.07,14.46,2.95a45.84,45.84,0,0,0,6.91.79c2.38.06,4.76-.23,7.15-.28a36.26,36.26,0,0,0,20.33-7.1c.26-.18,0-.61-.26-.43-5.6,3.93-12.25,6.81-19.2,7-2.2.07-4.4.26-6.6.3a39.14,39.14,0,0,1-7.25-.61c-4.68-.78-9.32-1.88-14-2.82-5.37-1.08-10.73-2.22-16-3.58a.26.26,0,0,0-.14.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M726.19,362.41c9.56-2.28,19.29-.56,28.82.93,9.14,1.43,18.35,2.45,27.45.21a47.88,47.88,0,0,0,14.18-6.06c.28-.17,0-.61-.25-.43a48.08,48.08,0,0,1-27.23,7.34c-9.37-.28-18.51-2.66-27.84-3.41a49.2,49.2,0,0,0-15.26.93.25.25,0,0,0,.13.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M727.35,361.6c9.56-3.3,19.71-4.09,29.77-3.89,5,.09,10,.42,15,.79,5.21.38,10.49,1,15.71.32a21.3,21.3,0,0,0,12.47-5.59c.23-.23-.13-.58-.35-.35-7.33,7.25-18.49,5.81-27.83,5.12-9.73-.72-19.54-1.3-29.27-.27a71.17,71.17,0,0,0-15.66,3.39c-.3.1-.17.58.14.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M731.89,359.77a67.33,67.33,0,0,1,29.8-6.8,125.16,125.16,0,0,1,16.16,1.65c5,.68,10.29,1.38,15.29.51,3.94-.7,7.91-3.59,10.51-6.53.21-.24-.14-.6-.36-.36-2.51,2.86-6.34,5.65-10.14,6.38-5.38,1-11.26.07-16.61-.68a110.48,110.48,0,0,0-15.07-1.47,67.49,67.49,0,0,0-29.84,6.87c-.28.14,0,.57.26.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M802.94,345.81a35.31,35.31,0,0,1-13.15,5.29,39.71,39.71,0,0,1-15-.48c-4.59-.92-9.13-2-13.84-2a44.73,44.73,0,0,0-13.15,1.93,48.81,48.81,0,0,0-13.5,6.51c-.26.18,0,.62.25.43a46.47,46.47,0,0,1,26.4-8.37c5,0,9.76,1.26,14.63,2.18a39.3,39.3,0,0,0,13.55.45,35.64,35.64,0,0,0,14.11-5.49c.27-.18,0-.61-.25-.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M736.48,356.64c6.23-6.1,13-12.61,21.88-14.26,9.07-1.68,17.14,3.93,25.68,5.91a33.47,33.47,0,0,0,14.26.42c.32-.06.19-.55-.13-.48a33.21,33.21,0,0,1-14.44-.53c-4.36-1.06-8.43-2.88-12.65-4.36a25,25,0,0,0-12.21-1.55,28.55,28.55,0,0,0-11.51,4.77,80.65,80.65,0,0,0-11.24,9.73c-.23.22.13.58.36.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M721.39,362.62a42.94,42.94,0,0,1-21.86-2.71c-3.23-1.31-6.21-3.15-9.46-4.43a18.64,18.64,0,0,0-8.86-1.27c-13.6,1.46-21.07,14.39-28,24.54-4.17,6.09-9.2,12.88-16.89,14.4a.26.26,0,0,0,0,.49c13.67,3.18,26.31,12.46,40.92,9.94,12.52-2.17,22.83-11.56,28.48-22.63,1.91-3.75,3.55-7.62,6.06-11,2.4-3.25,5.36-6.48,9.66-6.7.32,0,.32-.51,0-.5-6.41.32-10.59,7-13.24,12.09-1.64,3.17-3.05,6.47-4.94,9.51a44.13,44.13,0,0,1-6.11,7.77,38.69,38.69,0,0,1-16.65,10.22c-6.87,2-13.84,1.25-20.58-.87-7.92-2.49-15.35-6.4-23.47-8.29v.49c6-1.19,10.53-5.66,14.12-10.36,4.25-5.56,7.67-11.73,12.14-17.13,4.3-5.19,9.9-9.72,16.63-11.14a18.16,18.16,0,0,1,10.21.8c3.24,1.21,6.2,3.05,9.38,4.38a43.36,43.36,0,0,0,22.6,2.91c.32,0,.18-.52-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M716.45,363.87c-6.21,1-13.15,1.45-19.15-.8-3.24-1.21-6.27-3-9.68-3.73a16.84,16.84,0,0,0-8.68.5c-12.85,4.09-17.85,18-27.39,26.27a27,27,0,0,1-9.45,5.28c-.54.18-1.55.6-1.11,1.33a.6.6,0,0,0,1.11-.08.25.25,0,0,0-.48-.13c.25-.88,2.82-1.34,3.64-1.71a23.6,23.6,0,0,0,3.77-2.11,36.15,36.15,0,0,0,5.9-5.15c4.11-4.38,7.34-9.49,11.29-14s8.84-8.51,14.84-9.73a17,17,0,0,1,9.38.91c2.69,1,5.22,2.44,8,3.27,5.82,1.74,12.24,1.3,18.16.38.32-.05.18-.53-.13-.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M638.74,393.85c1.72.38,3.52.32,5.25.7a79.12,79.12,0,0,1,8.23,2.56c5.48,1.83,11,3.72,16.8,4.35a28.66,28.66,0,0,0,15-1.93c4.13-1.87,7.17-5.13,10-8.59,3.23-4,6.42-8.16,11.3-10.16.29-.12.16-.6-.14-.48-8.19,3.36-11.41,12.41-18.51,17.19-9.76,6.58-22.26,3.13-32.49-.27-3.12-1-6.22-2.12-9.4-3-1.91-.52-3.93-.44-5.86-.87-.31-.07-.45.42-.13.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M714,366.23c-6.66,6.88-13.72,13.44-20.77,19.9a49.86,49.86,0,0,1-5.36,4.37c-1.82,1.26-3.8,2.17-5.71,3.27a33.09,33.09,0,0,1-5.95,3c-1.28.42-2.56.87-3.86,1.24A35.85,35.85,0,0,1,660.12,399c-.32,0-.32.48,0,.5,6.93.42,14.19-.65,20.29-4.17,3.83-2.21,7.68-4.17,11.08-7.06,3.56-3,6.86-6.36,10.28-9.54,4.27-4,8.5-8,12.56-12.18.22-.23-.13-.59-.35-.36Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M715.55,365c-17.38,5.71-27.7,22.8-44.61,29.43a47.69,47.69,0,0,1-18,3.26c-.32,0-.32.49,0,.5a49.06,49.06,0,0,0,28.24-8.72c7.4-5,13.72-11.47,20.94-16.75a49.07,49.07,0,0,1,13.51-7.24c.31-.1.18-.58-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M714,364.91c-9.15,2-17.63,6.19-25.56,11.11-4,2.51-7.93,5.21-11.79,8s-7.9,5.94-12.15,8.45c-5,3-10.9,5-16.76,3.61a.25.25,0,0,0-.14.48c9.93,2.3,18.56-4.35,26.1-9.81,7.9-5.7,15.9-11.37,24.67-15.67a71.16,71.16,0,0,1,15.77-5.65c.31-.07.18-.55-.14-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M709.33,365.71A67.94,67.94,0,0,0,681.09,375c-8.61,5.18-15.34,13-24.21,17.75a15.42,15.42,0,0,1-7.23,1.94,22.78,22.78,0,0,1-7.16-.89c-.3-.09-.44.39-.13.49a21.1,21.1,0,0,0,11.55.33,23.9,23.9,0,0,0,5.93-3,67.21,67.21,0,0,0,5.84-4.23c4.07-3.24,7.93-6.76,12.23-9.71a66.17,66.17,0,0,1,14-7.35,67.07,67.07,0,0,1,17.44-4.12c.32,0,.32-.53,0-.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M641.52,392.07a36.15,36.15,0,0,0,25-9.12c3.69-3.21,7-6.86,11-9.65a43.5,43.5,0,0,1,12.53-5.87,48,48,0,0,1,15.82-1.8c.32,0,.32-.49,0-.5a47.22,47.22,0,0,0-25.83,6c-8.24,4.75-13.7,13-22.37,17.11a35.16,35.16,0,0,1-16.18,3.34.25.25,0,0,0,0,.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M703.91,365.47c-8.76-2-18.3-4.06-26.94-.44-8.1,3.39-11.91,11.71-17.75,17.71a33.25,33.25,0,0,1-12.38,8.39c-.3.11-.17.6.14.49,7.63-2.78,13.22-8.67,18-15,2.68-3.54,5.4-7.23,9.22-9.62a25.1,25.1,0,0,1,12.84-3.45c5.66-.12,11.23,1.15,16.72,2.4.31.07.44-.41.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M563.93,473.61a43.28,43.28,0,0,0,15.46-11.49c4.23-5,6.58-11.78,12.14-15.49,5.26-3.5,12-3.92,18.11-2.82,7.13,1.31,13.64,4.76,20.2,7.69,6.91,3.08,14.94,6.33,22.49,3.53l-.24-.41c-9.25,9.14-14.81,22.37-26.76,28.57a35.19,35.19,0,0,1-20,3.34,46.74,46.74,0,0,1-19.91-7.35,58.33,58.33,0,0,0-10.66-5.76c-3.61-1.34-7.5-2.08-11-.16a.25.25,0,0,0,.25.44c3.85-2.1,8.11-.85,11.91.7a67.77,67.77,0,0,1,10,5.68,46.63,46.63,0,0,0,18.63,6.86,36.39,36.39,0,0,0,19.08-2.41c6-2.6,10.73-7.15,14.8-12.18,4.68-5.78,8.68-12.13,14-17.38.17-.17,0-.51-.24-.42-7.42,2.75-15.32-.45-22.11-3.48-6.48-2.89-12.92-6.3-20-7.67-5.51-1.07-11.51-.91-16.64,1.55a18.54,18.54,0,0,0-6.8,5.66c-2,2.66-3.53,5.64-5.47,8.35a42.88,42.88,0,0,1-17.55,14.22c-.29.13,0,.56.25.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M568.73,472.12a46.64,46.64,0,0,0,13.46-8c4.13-3.73,6.14-9.35,10.76-12.59,4.78-3.34,11.11-3.66,16.66-2.59,6.12,1.18,11.78,3.9,17.62,5.94a44.81,44.81,0,0,0,9.81,2.41,26.35,26.35,0,0,0,5.4.09c.77-.08,1.53-.19,2.29-.34l.88-.2q.43-.1.87-.24c.42-.27.7-.2.86.23-.24-.22-.59.13-.36.35.74.68,1.72-1.31-.07-1.18a13.92,13.92,0,0,0-1.88.46,19.38,19.38,0,0,1-3,.45,26.79,26.79,0,0,1-5.37-.17c-7.13-.92-13.6-4.18-20.33-6.48-5.75-2-11.95-3.2-17.95-1.67a16.78,16.78,0,0,0-7.22,3.77c-2.26,2-3.85,4.68-5.59,7.15-4.1,5.83-10.47,9.45-16.93,12.16-.29.12-.16.6.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M650.13,456.07c-1.2,1.16-2.65,2-3.89,3.15a57.61,57.61,0,0,0-5.08,5.73c-3.47,4.14-6.88,8.39-11,11.95a30.88,30.88,0,0,1-13.57,7.29c-5.12,1.08-10-.17-14.78-1.85s-9.76-3.5-15-2.65c-.32.05-.18.53.13.48,5.13-.83,10,1,14.72,2.65,4.5,1.57,9.06,2.81,13.86,2.07,10.55-1.64,18-10.12,24.51-17.86,2-2.35,3.91-4.76,6-7,1.32-1.36,3-2.31,4.37-3.63.23-.22-.12-.58-.35-.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M571.82,472.83c4.83,1.24,9.71,2.29,14.59,3.29s9.63,2.07,14.47,2.94a44.46,44.46,0,0,0,6.9.79c2.39.07,4.77-.23,7.15-.27a36.86,36.86,0,0,0,9.6-1.64,36.39,36.39,0,0,0,10.73-5.46c.26-.18,0-.62-.25-.43-5.61,3.93-12.26,6.8-19.2,7-2.2.07-4.41.25-6.61.3a39.21,39.21,0,0,1-7.25-.61c-4.68-.78-9.31-1.89-14-2.82-5.37-1.08-10.73-2.22-16-3.58a.25.25,0,0,0-.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M570.19,472.53c9.55-2.27,19.29-.55,28.82.94,9.14,1.43,18.34,2.45,27.45.21a48,48,0,0,0,14.18-6.07c.27-.17,0-.6-.25-.43a48.11,48.11,0,0,1-27.23,7.35c-9.38-.28-18.52-2.66-27.84-3.41a49,49,0,0,0-15.27.93c-.31.07-.18.56.14.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M571.34,471.72c9.57-3.29,19.71-4.08,29.77-3.89,5,.1,10,.43,15,.8,5.22.38,10.5,1,15.71.31a21.23,21.23,0,0,0,12.47-5.58A.25.25,0,0,0,644,463c-7.32,7.26-18.49,5.81-27.83,5.13-9.72-.72-19.53-1.3-29.26-.27a71,71,0,0,0-15.66,3.38c-.3.11-.17.59.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M575.88,469.9a67.23,67.23,0,0,1,29.81-6.8,125.17,125.17,0,0,1,16.15,1.64c5,.69,10.3,1.39,15.3.51,3.93-.69,7.91-3.58,10.5-6.53.21-.24-.14-.59-.35-.35-2.52,2.85-6.35,5.64-10.14,6.37-5.39,1-11.26.08-16.62-.68a108.75,108.75,0,0,0-15.07-1.46,66.67,66.67,0,0,0-14.22,1.48,68.34,68.34,0,0,0-15.61,5.38c-.29.14,0,.58.25.44Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M646.93,455.93a35.42,35.42,0,0,1-13.15,5.3,39.72,39.72,0,0,1-15-.48c-4.59-.92-9.12-2-13.83-2a44.36,44.36,0,0,0-13.15,1.94,48.4,48.4,0,0,0-13.5,6.51c-.27.18,0,.61.25.43a46.41,46.41,0,0,1,26.4-8.38c5,0,9.76,1.26,14.62,2.19a39.06,39.06,0,0,0,13.55.44,35.64,35.64,0,0,0,14.11-5.49c.27-.17,0-.61-.25-.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M580.47,466.77c6.23-6.11,13-12.62,21.88-14.27,9.08-1.68,17.14,3.93,25.69,5.91a33.31,33.31,0,0,0,14.26.43c.31-.07.18-.55-.13-.49a33,33,0,0,1-14.45-.53c-4.36-1.05-8.43-2.87-12.65-4.35a25.09,25.09,0,0,0-12.21-1.56,28.79,28.79,0,0,0-11.5,4.77,81.32,81.32,0,0,0-11.24,9.73.25.25,0,0,0,.35.36Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M565.38,472.75A42.92,42.92,0,0,1,543.53,470c-3.23-1.31-6.22-3.15-9.46-4.42a18.79,18.79,0,0,0-8.87-1.28c-13.59,1.46-21.06,14.4-28,24.55C493,495,488,501.76,480.3,503.28a.25.25,0,0,0,0,.48c13.68,3.18,26.32,12.47,40.92,9.94,12.53-2.16,22.84-11.55,28.48-22.62,1.91-3.75,3.56-7.63,6.07-11,2.39-3.25,5.35-6.48,9.66-6.69.32,0,.32-.52,0-.5-6.41.32-10.59,7-13.24,12.09-1.65,3.17-3.06,6.46-4.94,9.51a45.86,45.86,0,0,1-6.11,7.77,38.82,38.82,0,0,1-16.66,10.22c-6.86,2-13.83,1.24-20.57-.88-7.93-2.49-15.36-6.39-23.47-8.28v.48c6-1.19,10.52-5.66,14.11-10.35,4.26-5.56,7.67-11.73,12.14-17.13,4.3-5.19,9.9-9.72,16.63-11.14a18.06,18.06,0,0,1,10.21.8c3.24,1.21,6.2,3,9.39,4.38a43.43,43.43,0,0,0,22.59,2.91c.32,0,.19-.53-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M560.44,474c-6.2,1-13.15,1.45-19.14-.8-3.24-1.21-6.28-3-9.69-3.74a16.91,16.91,0,0,0-8.67.5c-12.85,4.1-17.86,18-27.39,26.28a27.16,27.16,0,0,1-9.46,5.28c-.53.18-1.54.59-1.1,1.33a.6.6,0,0,0,1.11-.08.25.25,0,0,0-.49-.13c.25-.89,2.82-1.35,3.64-1.71a23.65,23.65,0,0,0,3.77-2.12,35.27,35.27,0,0,0,5.9-5.14c4.12-4.39,7.34-9.5,11.3-14s8.83-8.51,14.83-9.72a16.85,16.85,0,0,1,9.39.91c2.69,1,5.22,2.44,8,3.26,5.82,1.75,12.25,1.31,18.17.38.31-.05.18-.53-.14-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M482.73,504c1.73.39,3.53.33,5.26.71a82.17,82.17,0,0,1,8.22,2.55c5.49,1.83,11,3.72,16.8,4.36a28.73,28.73,0,0,0,15-1.93c4.13-1.88,7.17-5.14,10-8.59,3.23-4,6.42-8.16,11.3-10.16.3-.12.17-.61-.13-.48-8.2,3.35-11.42,12.4-18.51,17.18-9.77,6.59-22.27,3.13-32.49-.27-3.12-1-6.23-2.12-9.4-3-1.92-.53-3.94-.44-5.87-.87-.31-.07-.44.41-.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M558,476.36c-6.66,6.88-13.71,13.43-20.77,19.9a51,51,0,0,1-5.35,4.37c-1.82,1.26-3.81,2.16-5.72,3.27a32.09,32.09,0,0,1-6,3c-1.28.43-2.56.87-3.86,1.25a35.85,35.85,0,0,1-12.21,1.05c-.32,0-.32.48,0,.5,6.93.41,14.19-.66,20.29-4.17,3.84-2.22,7.69-4.18,11.09-7.06,3.56-3,6.86-6.37,10.27-9.55,4.27-4,8.51-8,12.56-12.18.23-.23-.13-.58-.35-.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M559.54,475.16c-17.38,5.71-27.69,22.79-44.6,29.43a47.54,47.54,0,0,1-18,3.25.25.25,0,0,0,0,.5,48.94,48.94,0,0,0,28.24-8.72c7.41-5,13.72-11.47,20.94-16.74a48.72,48.72,0,0,1,13.52-7.24c.3-.1.17-.58-.14-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M558,475c-9.16,2.05-17.63,6.19-25.56,11.12-4,2.5-7.94,5.2-11.8,8s-7.9,5.94-12.14,8.44c-5,3-10.91,5-16.77,3.61-.31-.07-.44.41-.13.48,9.92,2.3,18.56-4.35,26.1-9.8,7.89-5.71,15.9-11.37,24.66-15.67a71,71,0,0,1,15.77-5.65c.31-.07.18-.56-.13-.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M553.32,475.84a67.84,67.84,0,0,0-28.24,9.32c-8.6,5.18-15.34,13-24.21,17.74a15.25,15.25,0,0,1-7.23,2,23,23,0,0,1-7.15-.89c-.31-.1-.44.39-.13.48a21.19,21.19,0,0,0,11.55.34,24.42,24.42,0,0,0,5.93-3,67.06,67.06,0,0,0,5.83-4.23c4.08-3.24,7.94-6.76,12.24-9.72a66.78,66.78,0,0,1,14-7.35,67.65,67.65,0,0,1,17.44-4.11c.32,0,.32-.53,0-.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M485.51,502.2a36.19,36.19,0,0,0,25-9.12c3.69-3.22,7-6.86,11-9.65a43.4,43.4,0,0,1,12.53-5.88,48.25,48.25,0,0,1,15.81-1.8c.32,0,.32-.48,0-.5a47.2,47.2,0,0,0-25.82,6c-8.24,4.74-13.7,13-22.37,17.11a35.46,35.46,0,0,1-16.19,3.34c-.32,0-.32.48,0,.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M547.9,475.59c-8.76-2-18.29-4-26.94-.44-8.09,3.39-11.91,11.71-17.74,17.72a33.53,33.53,0,0,1-12.38,8.39c-.3.11-.17.59.13.48,7.63-2.77,13.23-8.67,18-15,2.69-3.54,5.4-7.23,9.22-9.62a25.14,25.14,0,0,1,12.84-3.46c5.67-.11,11.23,1.16,16.72,2.41.31.07.45-.42.13-.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M736.66,424.7a43.37,43.37,0,0,0,15.46-11.48c4.23-5,6.58-11.79,12.14-15.49,5.26-3.5,12-3.93,18.11-2.82,7.13,1.3,13.64,4.76,20.2,7.68,6.91,3.09,14.94,6.33,22.49,3.54l-.25-.42c-9.24,9.14-14.8,22.37-26.75,28.57a35.13,35.13,0,0,1-20,3.34,46.86,46.86,0,0,1-19.9-7.34,58.1,58.1,0,0,0-10.66-5.77c-3.61-1.34-7.5-2.08-11-.15-.29.15,0,.58.25.43,3.85-2.1,8.11-.85,11.91.7a67.77,67.77,0,0,1,10,5.68A46.63,46.63,0,0,0,777.3,438a36.48,36.48,0,0,0,19.08-2.4c6-2.61,10.73-7.16,14.8-12.19,4.68-5.78,8.68-12.12,14-17.38.17-.17,0-.51-.24-.42-7.42,2.75-15.32-.45-22.11-3.48-6.48-2.89-12.92-6.3-20-7.67-5.51-1.06-11.51-.91-16.65,1.55a18.59,18.59,0,0,0-6.79,5.66c-2,2.66-3.53,5.64-5.47,8.35a42.72,42.72,0,0,1-17.55,14.22c-.29.13,0,.56.25.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M741.46,423.21a46.64,46.64,0,0,0,13.46-8c4.13-3.73,6.14-9.35,10.76-12.59,4.78-3.34,11.11-3.66,16.66-2.59,6.12,1.18,11.77,3.9,17.62,5.95a45.36,45.36,0,0,0,9.81,2.4,25.65,25.65,0,0,0,5.4.09c.77-.08,1.53-.19,2.29-.34l.88-.2.87-.24c.42-.27.7-.19.86.23-.24-.22-.59.13-.36.35.74.68,1.72-1.31-.07-1.18a13.92,13.92,0,0,0-1.88.46,19.38,19.38,0,0,1-3,.45,26.07,26.07,0,0,1-5.37-.17c-7.13-.92-13.6-4.18-20.33-6.48-5.75-2-11.95-3.2-18-1.67a16.91,16.91,0,0,0-7.21,3.77c-2.26,2-3.85,4.68-5.59,7.15-4.1,5.83-10.47,9.45-16.93,12.16-.29.12-.16.6.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M822.86,407.16c-1.2,1.16-2.65,2-3.89,3.15a57.61,57.61,0,0,0-5.08,5.73c-3.47,4.14-6.88,8.39-11,11.95a30.86,30.86,0,0,1-13.58,7.29c-5.11,1.08-10-.17-14.77-1.85s-9.76-3.5-15-2.65c-.32.05-.19.53.13.48,5.13-.83,10,1,14.72,2.65,4.5,1.57,9.06,2.81,13.86,2.07,10.55-1.64,18-10.12,24.51-17.86,2-2.35,3.91-4.76,6-7,1.32-1.37,3-2.31,4.37-3.64.23-.22-.12-.58-.35-.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M744.55,423.92c4.83,1.24,9.7,2.29,14.59,3.29s9.63,2.07,14.47,3a45.69,45.69,0,0,0,6.9.78c2.39.07,4.77-.23,7.15-.27a36.86,36.86,0,0,0,9.6-1.64A36.39,36.39,0,0,0,808,423.57c.26-.18,0-.62-.25-.43-5.61,3.93-12.26,6.81-19.2,7-2.2.07-4.41.25-6.61.3a39.21,39.21,0,0,1-7.25-.61c-4.68-.78-9.31-1.88-14-2.82-5.37-1.08-10.73-2.22-16-3.58a.25.25,0,0,0-.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M742.92,423.62c9.55-2.27,19.28-.55,28.82.94,9.14,1.43,18.34,2.45,27.44.21a47.94,47.94,0,0,0,14.19-6.06c.27-.18,0-.61-.25-.44a48,48,0,0,1-27.23,7.35c-9.38-.28-18.52-2.66-27.84-3.41a49,49,0,0,0-15.27.93.25.25,0,0,0,.14.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M744.07,422.82c9.57-3.3,19.71-4.09,29.77-3.89,5,.09,10,.42,15,.79,5.22.38,10.5,1,15.71.31A21.23,21.23,0,0,0,817,414.45a.25.25,0,0,0-.35-.36c-7.32,7.26-18.49,5.81-27.83,5.13-9.72-.72-19.53-1.3-29.26-.27a71.61,71.61,0,0,0-15.66,3.38c-.3.11-.17.59.13.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M748.61,421a67.2,67.2,0,0,1,29.81-6.8,123.44,123.44,0,0,1,16.15,1.65c5,.68,10.3,1.38,15.3.5,3.93-.69,7.91-3.58,10.5-6.53.21-.24-.14-.59-.35-.35-2.52,2.85-6.35,5.65-10.14,6.37-5.39,1-11.26.08-16.62-.68a110.31,110.31,0,0,0-15.07-1.46A66.67,66.67,0,0,0,764,415.17a67.89,67.89,0,0,0-15.61,5.39c-.29.14,0,.57.25.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M819.66,407a35.26,35.26,0,0,1-13.15,5.3,39.72,39.72,0,0,1-15-.48c-4.59-.92-9.13-2-13.84-2a44.67,44.67,0,0,0-13.14,1.94,48.4,48.4,0,0,0-13.5,6.51c-.27.18,0,.61.25.43a46.4,46.4,0,0,1,26.39-8.38c5,0,9.77,1.27,14.63,2.19a39.31,39.31,0,0,0,13.55.45,35.65,35.65,0,0,0,14.11-5.5c.27-.17,0-.61-.25-.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M753.2,417.86c6.23-6.1,13-12.61,21.88-14.26,9.08-1.69,17.14,3.93,25.69,5.9a33.31,33.31,0,0,0,14.26.43c.31-.07.18-.55-.13-.48a33.26,33.26,0,0,1-14.45-.53c-4.36-1.06-8.43-2.88-12.65-4.36A25,25,0,0,0,775.59,403a28.63,28.63,0,0,0-11.5,4.76,81.32,81.32,0,0,0-11.24,9.73.25.25,0,0,0,.35.36Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M738.11,423.84a42.92,42.92,0,0,1-21.85-2.72C713,419.81,710,418,706.8,416.7a18.79,18.79,0,0,0-8.87-1.28c-13.59,1.46-21.06,14.4-28,24.55-4.16,6.08-9.2,12.88-16.89,14.4a.25.25,0,0,0,0,.48c13.68,3.19,26.32,12.47,40.92,9.94,12.53-2.16,22.84-11.55,28.48-22.62,1.91-3.75,3.56-7.63,6.07-11,2.39-3.25,5.35-6.49,9.65-6.7.33,0,.33-.52,0-.5-6.41.32-10.58,7-13.24,12.09-1.64,3.17-3,6.46-4.93,9.51a45.86,45.86,0,0,1-6.11,7.77,38.72,38.72,0,0,1-16.66,10.22c-6.86,2-13.83,1.24-20.58-.88-7.92-2.49-15.35-6.39-23.47-8.28v.48c6-1.19,10.53-5.66,14.12-10.35,4.26-5.56,7.67-11.73,12.14-17.13,4.3-5.19,9.9-9.72,16.63-11.14a18.15,18.15,0,0,1,10.21.8c3.24,1.21,6.2,3.05,9.39,4.38a43.32,43.32,0,0,0,22.59,2.91c.32,0,.18-.52-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M733.17,425.09c-6.21,1-13.15,1.45-19.14-.8-3.24-1.21-6.28-3-9.69-3.74a16.8,16.8,0,0,0-8.67.51c-12.85,4.09-17.86,18-27.39,26.27a27,27,0,0,1-9.46,5.28c-.53.18-1.54.59-1.1,1.33a.6.6,0,0,0,1.11-.08.25.25,0,0,0-.49-.13c.25-.89,2.82-1.35,3.64-1.71a24.4,24.4,0,0,0,3.77-2.11,36.21,36.21,0,0,0,5.9-5.15c4.12-4.38,7.34-9.5,11.3-14s8.83-8.52,14.83-9.73a16.85,16.85,0,0,1,9.39.91c2.68,1,5.22,2.44,8,3.27,5.82,1.74,12.25,1.3,18.17.37.31-.05.18-.53-.14-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M655.46,455.06c1.73.39,3.53.33,5.26.71a82.17,82.17,0,0,1,8.22,2.55c5.49,1.84,11,3.73,16.8,4.36a28.63,28.63,0,0,0,15-1.93c4.13-1.88,7.17-5.14,10-8.59,3.23-4,6.42-8.16,11.3-10.16.3-.12.17-.61-.13-.48-8.2,3.36-11.42,12.4-18.51,17.18-9.77,6.59-22.27,3.14-32.49-.26-3.12-1-6.23-2.13-9.4-3-1.92-.53-3.94-.44-5.87-.87-.31-.07-.44.41-.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M730.7,427.45c-6.66,6.88-13.71,13.43-20.77,19.9a50.75,50.75,0,0,1-5.35,4.37c-1.82,1.26-3.81,2.16-5.72,3.27a32.09,32.09,0,0,1-6,3c-1.28.43-2.56.87-3.86,1.25a35.85,35.85,0,0,1-12.21,1.05c-.32,0-.32.48,0,.5,6.93.41,14.19-.66,20.29-4.17,3.84-2.21,7.69-4.17,11.09-7.06,3.56-3,6.86-6.36,10.27-9.54,4.27-4,8.51-8,12.56-12.19.23-.23-.13-.58-.35-.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M732.27,426.25c-17.38,5.71-27.7,22.8-44.6,29.43a47.74,47.74,0,0,1-18,3.26c-.32,0-.32.49,0,.5A49,49,0,0,0,698,450.71c7.41-5,13.72-11.46,20.94-16.74a49,49,0,0,1,13.52-7.24c.3-.1.17-.58-.14-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M730.75,426.12c-9.16,2.05-17.63,6.19-25.56,11.12-4,2.5-7.94,5.2-11.8,8s-7.9,5.94-12.14,8.44c-5,3-10.91,5-16.77,3.61-.31-.07-.45.41-.13.48,9.92,2.31,18.56-4.34,26.1-9.8,7.89-5.71,15.9-11.37,24.66-15.67a71,71,0,0,1,15.77-5.65c.31-.07.18-.56-.13-.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M726.05,426.93a67.84,67.84,0,0,0-28.24,9.32c-8.6,5.18-15.34,13-24.21,17.75a15.39,15.39,0,0,1-7.23,1.94,23,23,0,0,1-7.15-.89.25.25,0,0,0-.13.48,21.1,21.1,0,0,0,11.55.34,24.18,24.18,0,0,0,5.92-3,67.21,67.21,0,0,0,5.84-4.23c4.08-3.24,7.94-6.76,12.23-9.72a66.91,66.91,0,0,1,31.42-11.46c.32,0,.32-.53,0-.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M658.24,453.29a36.19,36.19,0,0,0,25-9.12c3.69-3.22,7-6.86,11-9.65a43.12,43.12,0,0,1,12.53-5.87,48.25,48.25,0,0,1,15.81-1.81c.32,0,.32-.48,0-.5a47.2,47.2,0,0,0-25.82,6c-8.24,4.74-13.7,13-22.37,17.11a35.21,35.21,0,0,1-16.19,3.34c-.32,0-.32.49,0,.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M720.63,426.69c-8.76-2-18.3-4.06-26.94-.44C685.6,429.63,681.78,438,676,444a33.53,33.53,0,0,1-12.38,8.39c-.3.11-.17.59.13.48,7.63-2.77,13.23-8.66,18-15,2.68-3.54,5.4-7.23,9.22-9.62a25.13,25.13,0,0,1,12.84-3.45c5.67-.12,11.23,1.15,16.72,2.4.31.07.45-.41.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M693,410.94a43.45,43.45,0,0,0-15.46,11.49c-4.22,4.95-6.57,11.78-12.14,15.48-5.26,3.5-12,3.93-18.1,2.82-7.13-1.3-13.64-4.75-20.2-7.68-6.91-3.09-15-6.33-22.49-3.53l.24.41c9.24-9.14,14.8-22.37,26.76-28.57a35.16,35.16,0,0,1,20-3.34,46.81,46.81,0,0,1,19.9,7.34,58.19,58.19,0,0,0,10.67,5.77c3.6,1.34,7.49,2.08,11,.15.28-.15,0-.58-.25-.43-3.85,2.1-8.11.85-11.91-.7a68.39,68.39,0,0,1-10-5.68,46.53,46.53,0,0,0-18.62-6.86A36.39,36.39,0,0,0,633.26,400c-6,2.6-10.73,7.15-14.8,12.18-4.68,5.78-8.68,12.12-14,17.38-.17.17,0,.51.24.42,7.42-2.75,15.31.45,22.11,3.48,6.48,2.89,12.91,6.3,19.95,7.67,5.5,1.07,11.51.91,16.64-1.55a18.54,18.54,0,0,0,6.8-5.66c2-2.66,3.53-5.64,5.46-8.35a42.83,42.83,0,0,1,17.56-14.22c.29-.13,0-.56-.26-.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M688.17,412.43a46.59,46.59,0,0,0-13.45,8c-4.13,3.73-6.15,9.35-10.77,12.59-4.77,3.34-11.1,3.66-16.66,2.59-6.11-1.18-11.77-3.9-17.61-5.95a45.5,45.5,0,0,0-9.81-2.4,25.66,25.66,0,0,0-5.4-.09c-.77.08-1.54.19-2.29.34l-.88.2-.87.24c-.42.27-.71.19-.86-.23.24.22.59-.13.35-.35-.74-.68-1.71,1.31.08,1.18a13.43,13.43,0,0,0,1.87-.46,19.56,19.56,0,0,1,3-.45,26.16,26.16,0,0,1,5.38.17c7.12.92,13.6,4.18,20.33,6.48,5.74,2,11.94,3.2,18,1.67a16.8,16.8,0,0,0,7.21-3.77c2.26-2,3.86-4.68,5.59-7.15,4.11-5.83,10.47-9.45,16.94-12.16.29-.12.16-.6-.14-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M606.78,428.48c1.19-1.16,2.65-2,3.88-3.15a59,59,0,0,0,5.09-5.73c3.47-4.14,6.88-8.39,11-12a30.86,30.86,0,0,1,13.58-7.29c5.11-1.08,10,.17,14.78,1.85s9.76,3.5,15,2.65c.32-.05.18-.53-.13-.48-5.13.83-10-1-14.73-2.65-4.5-1.57-9.05-2.81-13.85-2.07-10.55,1.64-18,10.12-24.51,17.86-2,2.35-3.91,4.76-6,7-1.32,1.37-3,2.32-4.38,3.64-.23.22.13.58.36.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M685.09,411.72c-4.83-1.24-9.71-2.29-14.6-3.29s-9.62-2.07-14.46-2.94a44.58,44.58,0,0,0-6.91-.79c-2.38-.07-4.76.23-7.14.27a36.86,36.86,0,0,0-9.6,1.64,36.14,36.14,0,0,0-10.73,5.46c-.27.18,0,.62.25.43,5.6-3.93,12.26-6.81,19.2-7,2.2-.07,4.4-.25,6.6-.3a39.32,39.32,0,0,1,7.26.61c4.67.78,9.31,1.88,14,2.82,5.37,1.08,10.73,2.22,16,3.58a.25.25,0,0,0,.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M686.72,412c-9.56,2.27-19.29.55-28.82-.94-9.14-1.43-18.35-2.45-27.45-.21a48,48,0,0,0-14.18,6.07c-.27.17,0,.6.25.43A48.11,48.11,0,0,1,643.75,410c9.37.28,18.51,2.66,27.84,3.41a48.86,48.86,0,0,0,15.26-.93.25.25,0,0,0-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M685.56,412.82c-9.56,3.3-19.71,4.09-29.76,3.9-5-.1-10-.43-15-.8-5.21-.38-10.49-1-15.71-.31a21.26,21.26,0,0,0-12.47,5.58c-.23.23.13.58.36.36,7.32-7.26,18.48-5.81,27.82-5.13,9.73.72,19.54,1.3,29.27.27a71,71,0,0,0,15.66-3.38c.3-.11.17-.59-.14-.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M681,414.65a67.23,67.23,0,0,1-29.81,6.8,123.59,123.59,0,0,1-16.16-1.65c-5-.68-10.29-1.38-15.29-.5-3.94.69-7.91,3.58-10.5,6.53-.22.24.14.59.35.35,2.51-2.85,6.34-5.64,10.14-6.37,5.39-1,11.26-.08,16.61.68A108.9,108.9,0,0,0,651.44,422a66.7,66.7,0,0,0,14.23-1.48,68.51,68.51,0,0,0,15.61-5.38c.29-.15,0-.58-.25-.44Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M610,428.62a35.32,35.32,0,0,1,13.15-5.3,39.71,39.71,0,0,1,15,.48c4.6.92,9.13,2,13.84,2a44.74,44.74,0,0,0,13.15-1.94,48.53,48.53,0,0,0,13.5-6.51c.26-.18,0-.61-.25-.43a46.41,46.41,0,0,1-26.4,8.38c-5,0-9.76-1.26-14.62-2.19a39.12,39.12,0,0,0-13.56-.44,35.69,35.69,0,0,0-14.11,5.49c-.27.17,0,.61.25.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M676.43,417.78c-6.22,6.1-13,12.62-21.88,14.26-9.07,1.69-17.14-3.92-25.68-5.9a33.32,33.32,0,0,0-14.26-.43c-.32.07-.18.55.13.48a33.26,33.26,0,0,1,14.45.53c4.36,1.06,8.43,2.88,12.64,4.36a25,25,0,0,0,12.22,1.55,28.69,28.69,0,0,0,11.5-4.76,81.32,81.32,0,0,0,11.24-9.73c.23-.23-.12-.58-.36-.36Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M691.53,411.8a42.92,42.92,0,0,1,21.85,2.72c3.23,1.31,6.21,3.15,9.46,4.42a18.77,18.77,0,0,0,8.86,1.28c13.6-1.46,21.07-14.4,28-24.55,4.17-6.08,9.21-12.88,16.89-14.4a.25.25,0,0,0,0-.48c-13.67-3.18-26.31-12.47-40.91-9.94-12.53,2.16-22.84,11.55-28.49,22.62-1.91,3.75-3.55,7.63-6.06,11s-5.36,6.49-9.66,6.7c-.32,0-.32.52,0,.5,6.41-.32,10.59-7,13.24-12.09,1.65-3.17,3-6.46,4.94-9.51a44.88,44.88,0,0,1,6.11-7.77,38.79,38.79,0,0,1,16.65-10.22c6.87-2,13.84-1.24,20.58.88,7.92,2.49,15.35,6.39,23.47,8.28v-.48c-6,1.19-10.52,5.66-14.11,10.35-4.26,5.56-7.67,11.73-12.15,17.13-4.3,5.19-9.89,9.72-16.63,11.14a18.16,18.16,0,0,1-10.21-.8c-3.23-1.21-6.2-3-9.38-4.38a43.46,43.46,0,0,0-22.6-2.91c-.31,0-.18.53.14.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M696.46,410.55c6.21-1,13.16-1.45,19.15.8,3.24,1.21,6.27,3,9.68,3.74a16.94,16.94,0,0,0,8.68-.5c12.85-4.1,17.85-18,27.39-26.28a27,27,0,0,1,9.46-5.28c.53-.18,1.54-.59,1.1-1.33a.6.6,0,0,0-1.11.08.25.25,0,0,0,.48.13c-.25.89-2.82,1.35-3.64,1.71a24.88,24.88,0,0,0-3.77,2.11,36.63,36.63,0,0,0-5.9,5.15c-4.11,4.39-7.34,9.5-11.29,14s-8.84,8.52-14.84,9.73a16.81,16.81,0,0,1-9.38-.91c-2.69-1-5.22-2.44-8-3.27-5.81-1.74-12.24-1.3-18.16-.37-.32.05-.18.53.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M774.18,380.58c-1.73-.39-3.53-.33-5.26-.71a82.35,82.35,0,0,1-8.23-2.55c-5.48-1.83-11-3.72-16.79-4.36a28.63,28.63,0,0,0-15,1.93c-4.13,1.88-7.17,5.14-10,8.59-3.24,4-6.42,8.16-11.31,10.16-.29.12-.16.61.14.48,8.2-3.36,11.42-12.4,18.51-17.18,9.77-6.59,22.26-3.14,32.49.26,3.12,1,6.23,2.13,9.4,3,1.92.53,3.93.44,5.86.87.32.07.45-.41.14-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M698.94,408.19c6.65-6.88,13.71-13.43,20.77-19.9a48.78,48.78,0,0,1,5.35-4.37c1.82-1.26,3.8-2.16,5.71-3.27a32.72,32.72,0,0,1,6-3c1.29-.43,2.56-.87,3.86-1.25a35.89,35.89,0,0,1,12.21-1.05c.32,0,.32-.48,0-.5-6.93-.41-14.19.66-20.28,4.17-3.84,2.22-7.69,4.18-11.09,7.06-3.56,3-6.86,6.37-10.28,9.55-4.27,4-8.5,8-12.56,12.18a.25.25,0,0,0,.36.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M697.36,409.39c17.39-5.71,27.7-22.79,44.61-29.43a47.52,47.52,0,0,1,17.95-3.25.25.25,0,0,0,0-.5,49,49,0,0,0-28.24,8.72c-7.4,5-13.71,11.47-20.93,16.74a49,49,0,0,1-13.52,7.24c-.3.1-.17.58.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M698.89,409.52c9.15-2,17.63-6.19,25.56-11.12,4-2.5,7.94-5.2,11.8-8s7.9-5.94,12.14-8.44c5-3,10.9-5,16.76-3.61.32.07.45-.41.14-.48-9.93-2.3-18.56,4.35-26.1,9.8-7.89,5.71-15.9,11.37-24.67,15.67A70.76,70.76,0,0,1,698.76,409c-.32.07-.18.56.13.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M703.58,408.71a67.75,67.75,0,0,0,28.24-9.32c8.61-5.18,15.34-13,24.22-17.74a15.25,15.25,0,0,1,7.23-1.95,23.05,23.05,0,0,1,7.15.89.25.25,0,0,0,.13-.48,21.1,21.1,0,0,0-11.55-.34,24.42,24.42,0,0,0-5.93,3,67.21,67.21,0,0,0-5.84,4.23c-4.07,3.24-7.93,6.76-12.23,9.72a66.91,66.91,0,0,1-31.42,11.46c-.31,0-.32.53,0,.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M771.4,382.35a36.16,36.16,0,0,0-25,9.12c-3.69,3.22-7,6.86-11,9.65A43.12,43.12,0,0,1,722.83,407,48,48,0,0,1,707,408.8c-.33,0-.32.48,0,.5a47.2,47.2,0,0,0,25.82-6c8.24-4.74,13.7-13,22.37-17.11a35.46,35.46,0,0,1,16.19-3.34c.32,0,.32-.49,0-.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M709,409c8.76,2,18.3,4.05,26.94.44,8.1-3.39,11.92-11.72,17.75-17.72a33.45,33.45,0,0,1,12.38-8.39c.3-.11.17-.59-.13-.48-7.63,2.77-13.23,8.67-18,15-2.68,3.54-5.39,7.23-9.22,9.62a25.11,25.11,0,0,1-12.84,3.46c-5.66.11-11.23-1.16-16.71-2.41-.32-.07-.45.41-.14.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M520.24,459.85a43.43,43.43,0,0,0-15.46,11.48c-4.22,5-6.57,11.79-12.14,15.49-5.25,3.5-12,3.93-18.1,2.82-7.13-1.3-13.64-4.76-20.2-7.68-6.91-3.09-14.95-6.33-22.49-3.54l.24.42c9.24-9.14,14.8-22.37,26.76-28.57a35,35,0,0,1,20-3.34,46.81,46.81,0,0,1,19.9,7.34A58.8,58.8,0,0,0,509.39,460c3.6,1.34,7.49,2.08,11,.15.28-.15,0-.58-.25-.43-3.85,2.1-8.11.85-11.91-.7a68.39,68.39,0,0,1-10-5.68,46.53,46.53,0,0,0-18.62-6.86,36.48,36.48,0,0,0-19.08,2.4c-6,2.6-10.73,7.16-14.8,12.18-4.68,5.79-8.68,12.13-14,17.39-.17.17,0,.51.24.42,7.42-2.75,15.31.45,22.11,3.48,6.48,2.89,12.92,6.3,20,7.67,5.51,1.06,11.51.91,16.64-1.55a18.64,18.64,0,0,0,6.8-5.66c2-2.66,3.53-5.64,5.46-8.35a42.82,42.82,0,0,1,17.56-14.22c.29-.13,0-.56-.26-.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M515.44,461.34a46.59,46.59,0,0,0-13.45,8c-4.13,3.73-6.15,9.35-10.77,12.59-4.77,3.34-11.1,3.66-16.66,2.59-6.11-1.19-11.77-3.9-17.61-5.95a45.5,45.5,0,0,0-9.81-2.4,25.66,25.66,0,0,0-5.4-.09,22.18,22.18,0,0,0-2.29.34c-.29.06-.59.12-.88.2l-.87.24c-.42.27-.7.19-.86-.23.24.22.59-.13.35-.35-.74-.69-1.71,1.31.08,1.18a13.46,13.46,0,0,0,1.87-.47,22.29,22.29,0,0,1,3-.45,26.86,26.86,0,0,1,5.38.18c7.12.92,13.6,4.18,20.33,6.47,5.74,2,11.94,3.21,17.95,1.68a17,17,0,0,0,7.22-3.77c2.25-2.05,3.85-4.68,5.58-7.15,4.11-5.83,10.47-9.45,16.94-12.16.29-.12.16-.6-.14-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M434.05,477.39c1.2-1.16,2.65-2,3.88-3.15a59,59,0,0,0,5.09-5.73c3.47-4.14,6.88-8.39,11-11.95a30.86,30.86,0,0,1,13.58-7.29c5.11-1.08,10,.17,14.78,1.85s9.76,3.5,15,2.65c.32,0,.18-.54-.13-.48-5.13.83-10-1-14.73-2.65-4.5-1.57-9.05-2.81-13.85-2.07-10.55,1.64-18,10.11-24.51,17.86-2,2.35-3.91,4.76-6,7-1.32,1.37-3,2.31-4.37,3.64-.24.22.12.57.35.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M512.36,460.63c-4.83-1.24-9.71-2.29-14.6-3.29s-9.62-2.07-14.46-2.95a45.82,45.82,0,0,0-6.91-.78c-2.38-.07-4.76.23-7.14.27a36.86,36.86,0,0,0-9.6,1.64A36.26,36.26,0,0,0,448.92,461c-.26.18,0,.61.25.43,5.6-3.93,12.26-6.81,19.2-7,2.2-.07,4.4-.25,6.6-.3a39.32,39.32,0,0,1,7.26.61c4.67.78,9.31,1.88,14,2.82,5.37,1.08,10.73,2.22,16,3.58a.25.25,0,0,0,.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M514,460.93c-9.56,2.27-19.29.55-28.82-.94-9.14-1.43-18.35-2.45-27.45-.21a48,48,0,0,0-14.18,6.06c-.27.18,0,.61.25.44A48,48,0,0,1,471,458.93c9.37.28,18.51,2.66,27.84,3.41a48.86,48.86,0,0,0,15.26-.93.25.25,0,0,0-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M512.84,461.73c-9.57,3.3-19.72,4.09-29.77,3.89-5-.09-10-.42-15-.79-5.21-.38-10.49-1-15.71-.32a21.33,21.33,0,0,0-12.47,5.59.25.25,0,0,0,.36.35c7.32-7.25,18.48-5.8,27.82-5.12,9.73.72,19.54,1.3,29.27.27A71.61,71.61,0,0,0,513,462.22c.3-.11.17-.59-.13-.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M508.3,463.56a67.23,67.23,0,0,1-29.81,6.8,125,125,0,0,1-16.15-1.65c-5-.68-10.3-1.38-15.3-.5-3.93.69-7.91,3.58-10.5,6.53-.22.24.14.59.35.35,2.51-2.85,6.34-5.65,10.14-6.37,5.39-1,11.26-.08,16.61.68a110.61,110.61,0,0,0,15.07,1.46,67.35,67.35,0,0,0,14.23-1.48A68.05,68.05,0,0,0,508.55,464c.29-.14,0-.57-.25-.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M437.25,477.53a35.21,35.21,0,0,1,13.14-5.3,39.71,39.71,0,0,1,15.05.48c4.6.92,9.13,2,13.84,2a44.69,44.69,0,0,0,13.15-1.94,48.53,48.53,0,0,0,13.5-6.51c.27-.18,0-.61-.25-.43a46.56,46.56,0,0,1-26.4,8.38c-5,0-9.76-1.27-14.62-2.19a39.32,39.32,0,0,0-13.55-.45A35.68,35.68,0,0,0,437,477.09c-.26.18,0,.61.26.44Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M503.71,466.69c-6.23,6.1-13,12.61-21.88,14.26-9.08,1.68-17.15-3.93-25.69-5.91a33.47,33.47,0,0,0-14.26-.42c-.32.07-.18.55.13.48a33.26,33.26,0,0,1,14.45.53c4.36,1.06,8.43,2.88,12.64,4.36a25,25,0,0,0,12.22,1.55,28.69,28.69,0,0,0,11.5-4.76,81.32,81.32,0,0,0,11.24-9.73.25.25,0,0,0-.35-.36Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M518.8,460.71a42.92,42.92,0,0,1,21.85,2.71c3.23,1.32,6.22,3.16,9.46,4.43a18.64,18.64,0,0,0,8.86,1.27c13.6-1.45,21.07-14.39,28-24.54,4.16-6.08,9.2-12.88,16.88-14.4a.25.25,0,0,0,0-.48c-13.67-3.19-26.31-12.47-40.91-9.94-12.53,2.16-22.84,11.55-28.48,22.62-1.92,3.75-3.56,7.63-6.07,11-2.39,3.25-5.36,6.49-9.66,6.7-.32,0-.32.51,0,.5,6.41-.32,10.59-7,13.24-12.09,1.65-3.17,3-6.47,4.94-9.51a45.37,45.37,0,0,1,6.11-7.77A38.66,38.66,0,0,1,559.7,421c6.86-2,13.83-1.24,20.57.88,7.92,2.49,15.35,6.39,23.47,8.28v-.48c-6,1.18-10.52,5.66-14.11,10.35-4.26,5.56-7.67,11.73-12.15,17.13-4.29,5.19-9.89,9.72-16.63,11.14a18.12,18.12,0,0,1-10.2-.8c-3.24-1.21-6.21-3-9.39-4.38a43.36,43.36,0,0,0-22.6-2.91c-.31,0-.18.52.14.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M523.73,459.46c6.21-1,13.16-1.45,19.15.8,3.24,1.21,6.27,3,9.68,3.74a16.84,16.84,0,0,0,8.68-.51c12.85-4.09,17.85-18,27.39-26.27a27,27,0,0,1,9.46-5.28c.53-.18,1.54-.59,1.1-1.33a.6.6,0,0,0-1.11.08.25.25,0,0,0,.48.13c-.25.89-2.82,1.35-3.64,1.71a24,24,0,0,0-3.77,2.11,36.63,36.63,0,0,0-5.9,5.15c-4.11,4.38-7.34,9.5-11.29,14s-8.84,8.52-14.84,9.73a16.81,16.81,0,0,1-9.38-.91c-2.69-1-5.22-2.44-8-3.27-5.81-1.74-12.24-1.3-18.16-.37-.32,0-.18.53.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M601.45,429.49c-1.73-.39-3.53-.33-5.26-.71a82.17,82.17,0,0,1-8.22-2.55c-5.49-1.84-11-3.73-16.8-4.36a28.63,28.63,0,0,0-15,1.93c-4.13,1.88-7.17,5.14-10,8.59-3.24,4-6.42,8.16-11.3,10.16-.3.12-.17.6.13.48,8.2-3.36,11.42-12.4,18.51-17.19,9.77-6.58,22.27-3.13,32.49.27,3.12,1,6.23,2.13,9.4,3,1.92.53,3.93.44,5.86.87.32.07.45-.41.14-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M526.21,457.1c6.65-6.88,13.71-13.43,20.77-19.9a49.75,49.75,0,0,1,5.35-4.37c1.82-1.26,3.8-2.17,5.71-3.27a32.72,32.72,0,0,1,6-3c1.29-.43,2.57-.87,3.86-1.25a35.89,35.89,0,0,1,12.21-1c.32,0,.32-.48,0-.5-6.92-.41-14.19.66-20.28,4.17-3.84,2.21-7.69,4.17-11.09,7.06-3.56,3-6.86,6.36-10.28,9.54-4.26,4-8.5,8-12.56,12.19a.25.25,0,0,0,.36.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M524.63,458.3c17.39-5.71,27.7-22.8,44.61-29.43a47.73,47.73,0,0,1,18-3.26.25.25,0,0,0,0-.5A49,49,0,0,0,559,433.84c-7.4,5-13.71,11.46-20.93,16.74a49.27,49.27,0,0,1-13.52,7.24c-.3.1-.17.58.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M526.16,458.42c9.15-2,17.63-6.18,25.56-11.11,4-2.5,7.94-5.21,11.8-8s7.9-5.94,12.14-8.44c5-3,10.9-5,16.77-3.61a.25.25,0,0,0,.13-.49c-9.93-2.3-18.56,4.35-26.1,9.81-7.89,5.7-15.9,11.37-24.67,15.67A70.76,70.76,0,0,1,526,457.94c-.32.07-.18.55.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M530.85,457.62a67.89,67.89,0,0,0,28.24-9.32c8.61-5.18,15.34-13,24.22-17.75a15.36,15.36,0,0,1,7.23-1.94,22.76,22.76,0,0,1,7.15.89.25.25,0,0,0,.13-.48,21.1,21.1,0,0,0-11.55-.34,24.07,24.07,0,0,0-5.93,3,67.21,67.21,0,0,0-5.84,4.23c-4.07,3.24-7.93,6.76-12.23,9.72a66.8,66.8,0,0,1-31.42,11.46c-.31,0-.32.53,0,.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M598.67,431.26a36.16,36.16,0,0,0-25,9.12c-3.69,3.22-7,6.86-11,9.65a43.12,43.12,0,0,1-12.53,5.87,48.25,48.25,0,0,1-15.81,1.81c-.33,0-.32.48,0,.5a47.2,47.2,0,0,0,25.82-6c8.24-4.74,13.7-13,22.37-17.11a35.21,35.21,0,0,1,16.19-3.34c.32,0,.32-.49,0-.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M536.27,457.86c8.77,2,18.3,4.06,26.94.44,8.1-3.38,11.92-11.71,17.75-17.71a33.45,33.45,0,0,1,12.38-8.39c.3-.11.17-.59-.13-.48-7.63,2.77-13.23,8.66-18,15-2.68,3.54-5.39,7.23-9.21,9.62a25.17,25.17,0,0,1-12.85,3.45c-5.66.12-11.23-1.15-16.71-2.4-.32-.07-.45.41-.14.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M705.71,473.14a43.28,43.28,0,0,0-15.46,11.49c-4.23,5-6.58,11.78-12.14,15.49-5.26,3.5-12,3.92-18.11,2.82-7.13-1.31-13.64-4.76-20.2-7.69-6.91-3.08-14.94-6.33-22.49-3.53l.24.42c9.24-9.15,14.8-22.38,26.76-28.57a35.11,35.11,0,0,1,20-3.35,46.87,46.87,0,0,1,19.91,7.35,58.33,58.33,0,0,0,10.66,5.76c3.61,1.34,7.49,2.08,11,.16a.25.25,0,0,0-.25-.44c-3.85,2.1-8.11.85-11.91-.7a68.39,68.39,0,0,1-10-5.68,46.53,46.53,0,0,0-18.62-6.86A36.39,36.39,0,0,0,646,462.22c-6,2.6-10.73,7.15-14.8,12.18-4.68,5.78-8.68,12.13-14,17.38-.17.17,0,.51.24.42,7.42-2.75,15.32.45,22.11,3.48,6.48,2.89,12.92,6.31,20,7.67,5.51,1.07,11.51.91,16.64-1.55a18.54,18.54,0,0,0,6.8-5.66c2-2.65,3.53-5.64,5.47-8.35A42.88,42.88,0,0,1,706,473.57c.29-.13,0-.56-.25-.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M700.9,474.63a47,47,0,0,0-13.45,8c-4.13,3.74-6.15,9.35-10.77,12.59s-11.1,3.67-16.65,2.59c-6.12-1.18-11.78-3.9-17.62-5.94a45.38,45.38,0,0,0-9.81-2.41,25.66,25.66,0,0,0-5.4-.08,22.15,22.15,0,0,0-2.29.33l-.88.2-.87.24c-.42.27-.7.2-.86-.22.24.21.59-.14.35-.36-.74-.68-1.71,1.31.08,1.18a13.92,13.92,0,0,0,1.88-.46,19.38,19.38,0,0,1,3-.45,26.07,26.07,0,0,1,5.37.18c7.12.91,13.6,4.17,20.33,6.47,5.75,2,11.94,3.2,17.95,1.67a16.68,16.68,0,0,0,7.22-3.77c2.25-2,3.85-4.68,5.59-7.15,4.1-5.83,10.47-9.45,16.93-12.15.29-.13.16-.61-.14-.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M619.51,490.68c1.2-1.16,2.65-2,3.89-3.15a57.61,57.61,0,0,0,5.08-5.73c3.47-4.14,6.88-8.39,11-11.95A30.91,30.91,0,0,1,653,462.56c5.12-1.08,10,.17,14.78,1.86s9.76,3.49,15,2.64c.32-.05.18-.53-.13-.48-5.13.83-10-1-14.72-2.65-4.51-1.57-9.06-2.81-13.86-2.07-10.55,1.64-18,10.12-24.51,17.86-2,2.35-3.91,4.76-6,7-1.32,1.36-3,2.31-4.37,3.63-.23.22.12.58.35.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M697.82,473.92c-4.83-1.24-9.71-2.29-14.59-3.29s-9.63-2.07-14.47-2.94a44.46,44.46,0,0,0-6.9-.79c-2.39-.06-4.77.23-7.15.27a36.86,36.86,0,0,0-9.6,1.64,36.39,36.39,0,0,0-10.73,5.46c-.26.18,0,.62.25.43,5.61-3.93,12.26-6.8,19.2-7,2.2-.07,4.4-.25,6.6-.3a39.26,39.26,0,0,1,7.26.62c4.68.78,9.31,1.88,14,2.81,5.37,1.09,10.73,2.23,16,3.58a.25.25,0,0,0,.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M699.45,474.22c-9.55,2.27-19.29.55-28.82-.94-9.14-1.43-18.34-2.45-27.45-.21A48,48,0,0,0,629,479.14c-.27.17,0,.6.25.43a48.11,48.11,0,0,1,27.23-7.35c9.38.28,18.52,2.66,27.84,3.41a49,49,0,0,0,15.27-.93c.31-.07.18-.56-.14-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M698.3,475c-9.57,3.29-19.71,4.08-29.77,3.89-5-.1-10-.43-15-.79-5.21-.39-10.49-1-15.71-.32a21.2,21.2,0,0,0-12.46,5.58.25.25,0,0,0,.35.36c7.32-7.26,18.48-5.81,27.82-5.12,9.73.71,19.54,1.29,29.27.26a70.47,70.47,0,0,0,15.66-3.38c.3-.11.17-.59-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M693.76,476.86A67.36,67.36,0,0,1,664,483.65,125.17,125.17,0,0,1,647.8,482c-5-.68-10.3-1.39-15.3-.51-3.93.69-7.91,3.58-10.5,6.53-.21.24.14.59.35.35,2.52-2.85,6.34-5.64,10.14-6.37,5.39-1,11.26-.08,16.62.68a108.75,108.75,0,0,0,15.07,1.46,66.67,66.67,0,0,0,14.22-1.48A67.86,67.86,0,0,0,694,477.29c.29-.14,0-.58-.25-.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M622.71,490.82a35.42,35.42,0,0,1,13.15-5.3,39.72,39.72,0,0,1,15,.48c4.59.92,9.12,2,13.83,2a44.36,44.36,0,0,0,13.15-1.94,48.53,48.53,0,0,0,13.5-6.51c.27-.18,0-.61-.25-.43a46.41,46.41,0,0,1-26.4,8.38c-5,0-9.76-1.26-14.62-2.19a39.06,39.06,0,0,0-13.55-.44,35.74,35.74,0,0,0-14.12,5.49c-.26.17,0,.61.26.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M689.17,480c-6.23,6.1-13,12.61-21.88,14.26-9.08,1.68-17.14-3.93-25.69-5.91a33.47,33.47,0,0,0-14.26-.43c-.31.07-.18.55.13.49a33,33,0,0,1,14.45.53c4.36,1.05,8.43,2.88,12.65,4.35a25.09,25.09,0,0,0,12.21,1.56,28.85,28.85,0,0,0,11.5-4.77,80.65,80.65,0,0,0,11.24-9.73.25.25,0,0,0-.35-.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M704.26,474a42.92,42.92,0,0,1,21.85,2.72c3.23,1.31,6.22,3.15,9.46,4.42a18.79,18.79,0,0,0,8.87,1.28c13.59-1.46,21.06-14.4,28-24.55,4.16-6.08,9.2-12.88,16.89-14.4a.25.25,0,0,0,0-.48c-13.68-3.18-26.32-12.47-40.92-9.94-12.53,2.17-22.84,11.55-28.48,22.62-1.91,3.75-3.56,7.63-6.07,11-2.39,3.25-5.35,6.48-9.66,6.69-.32,0-.32.52,0,.5,6.41-.31,10.59-7,13.24-12.09,1.65-3.17,3-6.46,4.94-9.5a45.05,45.05,0,0,1,6.11-7.78,38.76,38.76,0,0,1,16.66-10.22c6.86-2,13.83-1.24,20.57.88,7.93,2.49,15.35,6.39,23.47,8.28V443c-6,1.19-10.52,5.66-14.11,10.35-4.26,5.56-7.67,11.73-12.15,17.13-4.29,5.19-9.89,9.72-16.62,11.14a18.06,18.06,0,0,1-10.21-.8c-3.24-1.21-6.2-3-9.39-4.38a43.43,43.43,0,0,0-22.59-2.91c-.32,0-.19.53.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M709.2,472.75c6.2-1,13.15-1.45,19.14.8,3.24,1.21,6.28,3,9.69,3.74a16.91,16.91,0,0,0,8.67-.5c12.85-4.1,17.86-18,27.39-26.27a27,27,0,0,1,9.46-5.29c.53-.18,1.54-.59,1.1-1.32a.6.6,0,0,0-1.11.07c-.08.31.4.45.48.14-.24.88-2.81,1.34-3.63,1.7a23.65,23.65,0,0,0-3.77,2.12,35.34,35.34,0,0,0-5.91,5.15c-4.11,4.38-7.34,9.49-11.29,14s-8.83,8.51-14.83,9.72a16.85,16.85,0,0,1-9.39-.91c-2.69-1-5.22-2.44-8-3.26-5.82-1.75-12.25-1.31-18.17-.38-.31.05-.18.53.14.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M786.91,442.78c-1.73-.39-3.53-.33-5.26-.71a82.17,82.17,0,0,1-8.22-2.55c-5.49-1.83-11-3.72-16.8-4.36a28.63,28.63,0,0,0-15,1.94c-4.13,1.87-7.17,5.13-10,8.58-3.23,4-6.42,8.16-11.3,10.16-.3.12-.17.61.13.49,8.2-3.36,11.42-12.41,18.51-17.19,9.77-6.59,22.27-3.13,32.49.27,3.12,1,6.23,2.12,9.4,3,1.92.53,3.93.44,5.87.87.31.07.44-.41.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M711.67,470.39c6.66-6.88,13.71-13.43,20.77-19.9a51,51,0,0,1,5.35-4.37c1.82-1.25,3.81-2.16,5.71-3.26a31.91,31.91,0,0,1,6-3c1.28-.43,2.56-.87,3.86-1.24a36.07,36.07,0,0,1,12.2-1.06c.33,0,.33-.48,0-.5-6.92-.41-14.18.66-20.28,4.17-3.84,2.22-7.69,4.18-11.09,7.06-3.56,3-6.86,6.37-10.27,9.55-4.27,4-8.51,8-12.56,12.18-.23.23.13.58.35.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M710.1,471.59c17.38-5.7,27.69-22.79,44.6-29.43a47.54,47.54,0,0,1,18-3.25.25.25,0,0,0,0-.5,49,49,0,0,0-28.25,8.72c-7.4,5-13.71,11.47-20.93,16.74A48.72,48.72,0,0,1,710,471.11c-.3.1-.17.59.14.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M711.62,471.72c9.16-2.05,17.63-6.19,25.56-11.12,4-2.5,7.94-5.2,11.8-8s7.9-5.94,12.14-8.44c5-3,10.91-5,16.77-3.61.31.07.44-.41.13-.48-9.92-2.3-18.56,4.35-26.1,9.8-7.89,5.71-15.9,11.37-24.67,15.67a71.29,71.29,0,0,1-15.76,5.66c-.32.07-.18.55.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M716.32,470.91a67.84,67.84,0,0,0,28.24-9.32c8.6-5.17,15.34-13,24.21-17.74a15.25,15.25,0,0,1,7.23-2,23,23,0,0,1,7.15.89c.31.1.44-.39.13-.48a21.19,21.19,0,0,0-11.55-.34,24.78,24.78,0,0,0-5.93,3,67.06,67.06,0,0,0-5.83,4.23c-4.08,3.24-7.94,6.76-12.24,9.72a66.56,66.56,0,0,1-14,7.35,67.65,67.65,0,0,1-17.44,4.11c-.32,0-.32.54,0,.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M784.13,444.56a36.14,36.14,0,0,0-25,9.11c-3.69,3.22-7,6.86-11,9.65a43.4,43.4,0,0,1-12.53,5.88,48.25,48.25,0,0,1-15.81,1.8c-.32,0-.32.48,0,.5a47.2,47.2,0,0,0,25.82-6c8.24-4.74,13.7-13,22.37-17.11a35.33,35.33,0,0,1,16.19-3.33c.32,0,.32-.49,0-.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M721.74,471.16c8.76,2,18.29,4,26.94.44,8.09-3.39,11.91-11.71,17.74-17.72a33.45,33.45,0,0,1,12.38-8.39c.3-.11.17-.59-.13-.48-7.63,2.77-13.23,8.67-18,15-2.69,3.54-5.4,7.23-9.22,9.62a25.17,25.17,0,0,1-12.85,3.46c-5.66.11-11.22-1.16-16.71-2.4-.31-.08-.45.41-.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M533,522.05a43.28,43.28,0,0,0-15.46,11.49c-4.23,5-6.58,11.78-12.14,15.48-5.26,3.5-12,3.93-18.11,2.82-7.13-1.3-13.64-4.75-20.2-7.68-6.91-3.09-14.94-6.33-22.49-3.53l.24.41c9.25-9.14,14.81-22.37,26.76-28.57a35.19,35.19,0,0,1,20-3.34,46.85,46.85,0,0,1,19.91,7.34,57.78,57.78,0,0,0,10.66,5.77c3.61,1.34,7.5,2.08,11,.15.29-.15,0-.58-.25-.43-3.85,2.1-8.11.85-11.91-.7a67.77,67.77,0,0,1-10-5.68,46.63,46.63,0,0,0-18.63-6.86,36.39,36.39,0,0,0-19.08,2.41c-6,2.6-10.73,7.15-14.8,12.18-4.68,5.78-8.68,12.12-14,17.38-.17.17,0,.51.24.42,7.42-2.75,15.32.45,22.11,3.48,6.48,2.89,12.92,6.3,19.95,7.67,5.51,1.07,11.51.91,16.64-1.55a18.54,18.54,0,0,0,6.8-5.66c2-2.66,3.53-5.64,5.47-8.35a42.88,42.88,0,0,1,17.55-14.22c.29-.13,0-.56-.25-.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M528.18,523.54a46.64,46.64,0,0,0-13.46,8c-4.13,3.73-6.14,9.35-10.76,12.59-4.78,3.34-11.11,3.66-16.66,2.59-6.12-1.18-11.78-3.9-17.62-6a45.36,45.36,0,0,0-9.81-2.4,25.66,25.66,0,0,0-5.4-.09c-.77.08-1.53.19-2.29.34l-.88.2q-.44.1-.87.24c-.42.27-.7.2-.86-.23.24.22.59-.13.36-.35-.74-.68-1.72,1.31.07,1.18a13.92,13.92,0,0,0,1.88-.46,19.38,19.38,0,0,1,3-.45,26.07,26.07,0,0,1,5.37.17c7.13.92,13.6,4.18,20.33,6.48,5.75,2,11.95,3.2,17.95,1.67a16.78,16.78,0,0,0,7.22-3.77c2.26-2,3.85-4.68,5.59-7.15,4.1-5.83,10.47-9.45,16.93-12.16.29-.12.16-.6-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M446.78,539.59c1.2-1.16,2.65-2,3.89-3.15a57.61,57.61,0,0,0,5.08-5.73c3.47-4.14,6.88-8.39,11-12a30.88,30.88,0,0,1,13.57-7.29c5.12-1.08,10,.17,14.78,1.85s9.76,3.5,15,2.65c.32-.05.18-.53-.13-.48-5.13.83-10-1-14.72-2.65-4.5-1.57-9.06-2.81-13.86-2.07-10.55,1.64-18,10.12-24.51,17.86-2,2.35-3.91,4.76-6,7-1.32,1.37-3,2.32-4.37,3.64-.23.22.12.58.35.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M525.09,522.83c-4.83-1.24-9.71-2.29-14.59-3.29s-9.63-2.07-14.47-2.94a44.46,44.46,0,0,0-6.9-.79c-2.39-.07-4.77.23-7.15.27a36.86,36.86,0,0,0-9.6,1.64,36.39,36.39,0,0,0-10.73,5.46c-.26.18,0,.62.25.43,5.61-3.93,12.26-6.81,19.2-7,2.2-.07,4.41-.25,6.61-.3a39.21,39.21,0,0,1,7.25.61c4.68.78,9.31,1.88,14,2.82,5.37,1.08,10.73,2.22,16,3.58a.25.25,0,0,0,.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M526.72,523.13c-9.55,2.27-19.29.55-28.82-.94-9.14-1.43-18.34-2.45-27.45-.21a48,48,0,0,0-14.18,6.07c-.27.17,0,.6.25.43a48.11,48.11,0,0,1,27.23-7.35c9.38.28,18.52,2.66,27.84,3.41a49,49,0,0,0,15.27-.93c.31-.07.18-.56-.14-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M525.57,523.93c-9.57,3.3-19.71,4.09-29.77,3.9-5-.1-10-.43-15-.8-5.22-.38-10.5-1-15.71-.31a21.23,21.23,0,0,0-12.47,5.58.25.25,0,0,0,.35.36c7.32-7.26,18.49-5.81,27.83-5.13,9.72.72,19.53,1.3,29.26.27a71,71,0,0,0,15.66-3.38c.3-.11.17-.59-.13-.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M521,525.76a67.2,67.2,0,0,1-29.81,6.8,123.44,123.44,0,0,1-16.15-1.65c-5-.68-10.3-1.38-15.3-.5-3.93.69-7.91,3.58-10.5,6.53-.21.24.14.59.35.35,2.52-2.85,6.35-5.64,10.14-6.37,5.39-1,11.26-.08,16.62.68a108.75,108.75,0,0,0,15.07,1.46,66.67,66.67,0,0,0,14.22-1.48,68.34,68.34,0,0,0,15.61-5.38c.29-.15,0-.58-.25-.44Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M450,539.73a35.26,35.26,0,0,1,13.15-5.3,39.72,39.72,0,0,1,15.05.48c4.59.92,9.12,2,13.83,2A44.69,44.69,0,0,0,505.16,535a48.4,48.4,0,0,0,13.5-6.51c.27-.18,0-.61-.25-.43a46.41,46.41,0,0,1-26.4,8.38c-5,0-9.76-1.26-14.62-2.19a39.06,39.06,0,0,0-13.55-.44,35.64,35.64,0,0,0-14.11,5.49c-.27.17,0,.61.25.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M516.44,528.89c-6.23,6.11-13,12.62-21.88,14.26-9.08,1.69-17.14-3.92-25.69-5.9a33.31,33.31,0,0,0-14.26-.43c-.31.07-.18.55.13.48a33.26,33.26,0,0,1,14.45.53c4.36,1.06,8.43,2.88,12.65,4.36a25.09,25.09,0,0,0,12.21,1.56,28.79,28.79,0,0,0,11.5-4.77,81.32,81.32,0,0,0,11.24-9.73.25.25,0,0,0-.35-.36Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M531.53,522.91a42.92,42.92,0,0,1,21.85,2.72c3.23,1.31,6.22,3.15,9.46,4.42a18.79,18.79,0,0,0,8.87,1.28c13.59-1.46,21.06-14.4,28-24.55,4.16-6.08,9.2-12.88,16.89-14.4a.25.25,0,0,0,0-.48c-13.68-3.18-26.32-12.47-40.92-9.94-12.53,2.16-22.84,11.55-28.48,22.62-1.91,3.75-3.56,7.63-6.07,11s-5.35,6.49-9.66,6.7c-.32,0-.32.52,0,.5,6.41-.32,10.59-7,13.24-12.09,1.65-3.17,3.06-6.46,4.94-9.51a45.86,45.86,0,0,1,6.11-7.77,38.82,38.82,0,0,1,16.66-10.22c6.86-2,13.83-1.24,20.57.88,7.93,2.49,15.36,6.39,23.47,8.28v-.48c-6,1.19-10.52,5.66-14.11,10.35-4.26,5.56-7.67,11.73-12.14,17.13-4.3,5.19-9.9,9.72-16.63,11.14a18.15,18.15,0,0,1-10.21-.8c-3.24-1.21-6.2-3-9.39-4.38a43.43,43.43,0,0,0-22.59-2.91c-.32,0-.19.53.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M536.47,521.66c6.2-1,13.15-1.45,19.14.8,3.24,1.21,6.28,3,9.69,3.74a16.91,16.91,0,0,0,8.67-.5c12.85-4.1,17.86-18,27.39-26.28a27,27,0,0,1,9.46-5.28c.53-.18,1.54-.59,1.1-1.33a.6.6,0,0,0-1.11.08.25.25,0,0,0,.49.13c-.25.89-2.82,1.35-3.64,1.71a24.4,24.4,0,0,0-3.77,2.11A35.79,35.79,0,0,0,598,502c-4.12,4.39-7.34,9.5-11.3,14s-8.83,8.52-14.83,9.73a16.85,16.85,0,0,1-9.39-.91c-2.69-1-5.22-2.44-8-3.27-5.82-1.74-12.25-1.3-18.17-.37-.31.05-.18.53.14.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M614.18,491.69c-1.73-.39-3.53-.33-5.26-.71a82.17,82.17,0,0,1-8.22-2.55c-5.49-1.83-11-3.72-16.8-4.36a28.63,28.63,0,0,0-15,1.93c-4.13,1.88-7.17,5.14-10,8.59-3.23,4-6.42,8.16-11.3,10.16-.3.12-.17.61.13.48,8.2-3.36,11.42-12.4,18.51-17.18,9.77-6.59,22.27-3.13,32.49.26,3.12,1,6.23,2.13,9.4,3,1.92.53,3.94.44,5.87.87.31.07.44-.41.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M538.94,519.3c6.66-6.88,13.71-13.43,20.77-19.9a49.75,49.75,0,0,1,5.35-4.37c1.82-1.26,3.81-2.16,5.72-3.27a32.09,32.09,0,0,1,6-3c1.28-.43,2.56-.87,3.86-1.25a35.85,35.85,0,0,1,12.21-1.05c.32,0,.32-.48,0-.5-6.93-.41-14.19.66-20.29,4.17-3.84,2.22-7.69,4.18-11.09,7.06-3.56,3-6.86,6.37-10.27,9.55-4.27,4-8.51,8-12.56,12.18-.23.23.13.58.35.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M537.37,520.5c17.38-5.71,27.69-22.79,44.6-29.43a47.54,47.54,0,0,1,18-3.25.25.25,0,0,0,0-.5A48.94,48.94,0,0,0,571.69,496c-7.41,5-13.72,11.47-20.94,16.74A48.72,48.72,0,0,1,537.23,520c-.3.1-.17.58.14.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M538.89,520.63c9.16-2,17.63-6.19,25.56-11.12,4-2.5,7.94-5.2,11.8-8s7.9-5.94,12.14-8.44c5-3,10.91-5,16.77-3.61.31.07.44-.41.13-.48-9.92-2.3-18.56,4.35-26.1,9.8-7.89,5.71-15.9,11.37-24.66,15.67a71,71,0,0,1-15.77,5.65c-.31.07-.18.56.13.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M543.59,519.82a67.84,67.84,0,0,0,28.24-9.32c8.6-5.18,15.34-13,24.21-17.74a15.25,15.25,0,0,1,7.23-1.95,23,23,0,0,1,7.15.89.25.25,0,0,0,.13-.48,21.1,21.1,0,0,0-11.55-.34,24.42,24.42,0,0,0-5.93,3,67.06,67.06,0,0,0-5.83,4.23c-4.08,3.24-7.94,6.76-12.24,9.72a66.78,66.78,0,0,1-14,7.35,67.65,67.65,0,0,1-17.44,4.11c-.32,0-.32.53,0,.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M611.4,493.46a36.19,36.19,0,0,0-25,9.12c-3.69,3.22-7,6.86-11,9.65a43.12,43.12,0,0,1-12.53,5.87A48,48,0,0,1,547,519.91c-.32,0-.32.48,0,.5a47.2,47.2,0,0,0,25.82-6c8.24-4.74,13.7-13,22.37-17.11A35.46,35.46,0,0,1,611.4,494c.32,0,.32-.49,0-.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M549,520.07c8.76,2,18.29,4,26.94.44,8.09-3.39,11.91-11.72,17.74-17.72a33.53,33.53,0,0,1,12.38-8.39c.3-.11.17-.59-.13-.48-7.63,2.77-13.23,8.67-18,15-2.69,3.54-5.4,7.23-9.22,9.62A25.14,25.14,0,0,1,565.86,522c-5.67.11-11.23-1.16-16.72-2.41-.31-.07-.45.41-.13.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M576.67,535.81a43.54,43.54,0,0,0,15.46-11.48c4.22-5,6.57-11.79,12.14-15.49,5.25-3.5,12-3.93,18.1-2.82,7.13,1.3,13.64,4.76,20.2,7.68,6.91,3.09,14.95,6.33,22.49,3.54l-.24-.42c-9.24,9.14-14.8,22.37-26.76,28.57a35.08,35.08,0,0,1-20,3.34,46.81,46.81,0,0,1-19.9-7.34,58.8,58.8,0,0,0-10.67-5.77c-3.6-1.34-7.49-2.08-11-.15-.28.15,0,.58.25.43,3.85-2.1,8.11-.85,11.91.7a68.39,68.39,0,0,1,10,5.68,46.53,46.53,0,0,0,18.62,6.86,36.48,36.48,0,0,0,19.08-2.4c6-2.6,10.73-7.16,14.8-12.19,4.68-5.78,8.68-12.12,14-17.38.17-.17,0-.51-.24-.42-7.42,2.75-15.31-.45-22.11-3.48-6.48-2.89-12.92-6.3-20-7.67-5.51-1.06-11.51-.91-16.64,1.55a18.54,18.54,0,0,0-6.8,5.66c-2,2.66-3.53,5.64-5.46,8.35a42.82,42.82,0,0,1-17.56,14.22c-.29.13,0,.56.26.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M581.47,534.32a46.59,46.59,0,0,0,13.45-8c4.13-3.73,6.15-9.35,10.77-12.59s11.1-3.66,16.66-2.59c6.11,1.19,11.77,3.9,17.61,5.95a45.5,45.5,0,0,0,9.81,2.4,25.66,25.66,0,0,0,5.4.09c.77-.08,1.53-.19,2.29-.34l.88-.2q.43-.1.87-.24c.42-.27.71-.19.86.23-.24-.22-.59.13-.35.35.74.68,1.71-1.31-.08-1.18a13.43,13.43,0,0,0-1.87.46,19.56,19.56,0,0,1-3,.45,26.14,26.14,0,0,1-5.38-.17c-7.12-.92-13.6-4.18-20.33-6.47-5.74-2-11.94-3.21-17.95-1.68a17,17,0,0,0-7.22,3.77c-2.25,2.05-3.85,4.68-5.58,7.15-4.11,5.83-10.47,9.45-16.94,12.16-.29.12-.16.6.14.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M662.86,518.27c-1.2,1.16-2.65,2-3.88,3.15a59,59,0,0,0-5.09,5.73c-3.47,4.14-6.88,8.39-11,12a30.86,30.86,0,0,1-13.58,7.29c-5.11,1.08-10-.17-14.78-1.85s-9.76-3.5-15-2.65c-.32.05-.18.54.13.48,5.13-.83,10,1,14.73,2.65,4.5,1.57,9,2.81,13.85,2.07,10.55-1.64,18-10.12,24.51-17.86,2-2.35,3.91-4.76,6-7,1.32-1.37,3-2.31,4.37-3.64.24-.22-.12-.58-.35-.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M584.55,535c4.83,1.24,9.71,2.29,14.6,3.29s9.62,2.07,14.46,2.95a45.82,45.82,0,0,0,6.91.78c2.38.07,4.76-.23,7.14-.27a36.86,36.86,0,0,0,9.6-1.64A36.26,36.26,0,0,0,648,534.68c.26-.18,0-.62-.25-.43-5.6,3.93-12.26,6.81-19.2,7-2.2.07-4.4.25-6.6.3a39.32,39.32,0,0,1-7.26-.61c-4.67-.78-9.31-1.88-14-2.82-5.37-1.08-10.73-2.22-16-3.58a.25.25,0,0,0-.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M582.92,534.73c9.56-2.27,19.29-.55,28.82.94,9.14,1.43,18.35,2.45,27.45.21a48,48,0,0,0,14.18-6.06c.27-.18,0-.61-.25-.44a48,48,0,0,1-27.23,7.35c-9.37-.28-18.51-2.66-27.84-3.41a48.86,48.86,0,0,0-15.26.93.25.25,0,0,0,.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M584.07,533.93c9.57-3.3,19.72-4.09,29.77-3.89,5,.09,10,.42,15,.79,5.21.38,10.49,1,15.71.31a21.26,21.26,0,0,0,12.47-5.58c.23-.23-.13-.58-.36-.36-7.32,7.26-18.48,5.81-27.82,5.13-9.73-.72-19.54-1.3-29.27-.27a71.61,71.61,0,0,0-15.66,3.38c-.3.11-.17.59.13.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M588.61,532.1a67.23,67.23,0,0,1,29.81-6.8A123.27,123.27,0,0,1,634.57,527c5,.68,10.3,1.38,15.3.5,3.93-.69,7.91-3.58,10.5-6.53.22-.24-.14-.59-.35-.35-2.51,2.85-6.34,5.65-10.14,6.37-5.39,1-11.26.08-16.61-.68a110.61,110.61,0,0,0-15.07-1.46A66.7,66.7,0,0,0,604,526.28a68.05,68.05,0,0,0-15.61,5.39c-.29.14,0,.57.25.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M659.66,518.13a35.21,35.21,0,0,1-13.14,5.3,39.71,39.71,0,0,1-15-.48c-4.6-.92-9.13-2-13.84-2a44.69,44.69,0,0,0-13.15,1.94,48.53,48.53,0,0,0-13.5,6.51c-.27.18,0,.61.25.43a46.41,46.41,0,0,1,26.4-8.38c5,0,9.76,1.27,14.62,2.19a39.32,39.32,0,0,0,13.55.45,35.7,35.7,0,0,0,14.12-5.5c.26-.17,0-.61-.26-.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M593.2,529c6.23-6.1,13-12.61,21.88-14.26,9.08-1.69,17.15,3.93,25.69,5.9A33.32,33.32,0,0,0,655,521c.32-.07.18-.55-.13-.48a33.26,33.26,0,0,1-14.45-.53c-4.36-1.06-8.43-2.88-12.64-4.36a25,25,0,0,0-12.22-1.55,28.69,28.69,0,0,0-11.5,4.76,81.32,81.32,0,0,0-11.24,9.73.25.25,0,0,0,.35.36Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M578.11,535a42.92,42.92,0,0,1-21.85-2.72c-3.23-1.31-6.22-3.15-9.46-4.42a18.77,18.77,0,0,0-8.86-1.28c-13.6,1.46-21.07,14.4-28,24.55-4.16,6.08-9.2,12.88-16.88,14.4a.25.25,0,0,0,0,.48c13.67,3.19,26.31,12.47,40.91,9.94,12.53-2.16,22.84-11.55,28.48-22.62,1.92-3.75,3.56-7.63,6.07-11s5.36-6.49,9.66-6.7c.32,0,.32-.51,0-.5-6.41.32-10.59,7-13.24,12.09-1.65,3.17-3,6.46-4.94,9.51a45.37,45.37,0,0,1-6.11,7.77,38.69,38.69,0,0,1-16.65,10.22c-6.87,2-13.84,1.24-20.58-.88-7.92-2.49-15.35-6.39-23.47-8.28V566c6-1.19,10.52-5.66,14.11-10.35,4.26-5.56,7.67-11.73,12.15-17.13,4.29-5.19,9.89-9.72,16.63-11.14a18.16,18.16,0,0,1,10.21.8c3.23,1.21,6.2,3.05,9.38,4.38a43.36,43.36,0,0,0,22.6,2.91c.31,0,.18-.52-.14-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M573.18,536.2c-6.21,1-13.16,1.45-19.15-.8-3.24-1.21-6.27-3-9.68-3.74a16.84,16.84,0,0,0-8.68.51c-12.85,4.09-17.85,18-27.39,26.27a27,27,0,0,1-9.46,5.28c-.53.18-1.54.59-1.1,1.33a.6.6,0,0,0,1.11-.08.25.25,0,0,0-.48-.13c.25-.89,2.82-1.35,3.64-1.71a24.88,24.88,0,0,0,3.77-2.11,36.63,36.63,0,0,0,5.9-5.15c4.11-4.38,7.34-9.5,11.29-14s8.84-8.52,14.84-9.73a16.81,16.81,0,0,1,9.38.91c2.69,1,5.22,2.44,8,3.27,5.81,1.74,12.24,1.3,18.16.37.32,0,.18-.53-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M495.46,566.17c1.73.39,3.53.33,5.26.71a82.17,82.17,0,0,1,8.22,2.55c5.49,1.84,11,3.73,16.8,4.36a28.63,28.63,0,0,0,15-1.93c4.13-1.88,7.17-5.14,10-8.59,3.24-4,6.42-8.16,11.3-10.16.3-.12.17-.6-.13-.48-8.2,3.36-11.42,12.4-18.51,17.18-9.77,6.59-22.27,3.14-32.49-.26-3.12-1-6.23-2.13-9.4-3-1.92-.53-3.93-.44-5.86-.87-.32-.07-.45.41-.14.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M570.7,538.56c-6.65,6.88-13.71,13.43-20.77,19.9a49.75,49.75,0,0,1-5.35,4.37c-1.82,1.26-3.8,2.16-5.71,3.27a32.72,32.72,0,0,1-6,3c-1.29.43-2.57.87-3.86,1.25a35.89,35.89,0,0,1-12.21,1c-.32,0-.32.48,0,.5,6.92.41,14.19-.66,20.28-4.17,3.84-2.21,7.69-4.17,11.09-7.06,3.56-3,6.86-6.36,10.28-9.54,4.26-4,8.5-8,12.56-12.19a.25.25,0,0,0-.36-.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M572.28,537.36c-17.39,5.71-27.7,22.8-44.61,29.43a47.73,47.73,0,0,1-17.95,3.26.25.25,0,0,0,0,.5A49,49,0,0,0,538,561.82c7.4-5,13.71-11.46,20.93-16.74a49.27,49.27,0,0,1,13.52-7.24c.3-.1.17-.58-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M570.75,537.23c-9.15,2-17.63,6.19-25.56,11.12-4,2.5-7.94,5.2-11.8,8s-7.9,5.94-12.14,8.44c-5,3-10.9,5-16.77,3.61-.31-.07-.44.41-.13.48,9.93,2.31,18.56-4.34,26.1-9.8,7.89-5.71,15.9-11.37,24.67-15.67a70.76,70.76,0,0,1,15.76-5.65c.32-.07.18-.56-.13-.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M566.06,538a67.89,67.89,0,0,0-28.24,9.32c-8.61,5.18-15.34,13-24.22,17.75a15.36,15.36,0,0,1-7.23,1.94,23.05,23.05,0,0,1-7.15-.89.25.25,0,0,0-.13.48,21.1,21.1,0,0,0,11.55.34,24.07,24.07,0,0,0,5.93-3,67.21,67.21,0,0,0,5.84-4.23c4.07-3.24,7.93-6.76,12.23-9.72a66.8,66.8,0,0,1,31.42-11.46c.31,0,.32-.53,0-.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M498.24,564.4a36.16,36.16,0,0,0,25-9.12c3.69-3.22,7-6.86,11-9.65a43.12,43.12,0,0,1,12.53-5.87A48.25,48.25,0,0,1,562.62,538c.33,0,.32-.48,0-.5a47.2,47.2,0,0,0-25.82,6c-8.24,4.74-13.7,13-22.37,17.11a35.21,35.21,0,0,1-16.19,3.34c-.32,0-.32.49,0,.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M560.64,537.8c-8.77-2-18.3-4.06-26.94-.44-8.1,3.38-11.92,11.71-17.75,17.71a33.45,33.45,0,0,1-12.38,8.39c-.3.11-.17.59.13.48,7.63-2.77,13.23-8.66,18-15,2.68-3.54,5.39-7.23,9.21-9.62a25.17,25.17,0,0,1,12.85-3.45c5.66-.12,11.23,1.15,16.71,2.4.32.07.45-.41.14-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M749.4,486.91a43.45,43.45,0,0,0,15.45-11.49c4.23-5,6.58-11.79,12.15-15.49,5.25-3.5,12-3.93,18.1-2.82,7.13,1.3,13.64,4.76,20.2,7.68,6.91,3.09,15,6.33,22.49,3.54l-.24-.42c-9.24,9.14-14.8,22.37-26.76,28.57a35,35,0,0,1-20,3.34,46.76,46.76,0,0,1-19.9-7.34,58.14,58.14,0,0,0-10.67-5.76c-3.6-1.35-7.49-2.09-11-.16-.28.15,0,.58.25.43,3.85-2.1,8.11-.85,11.91.7a67.54,67.54,0,0,1,10,5.69A46.52,46.52,0,0,0,790,500.23a36.48,36.48,0,0,0,19.08-2.4c6-2.6,10.73-7.16,14.8-12.18,4.68-5.79,8.68-12.13,14-17.39.17-.16,0-.5-.24-.42-7.42,2.75-15.31-.45-22.11-3.48-6.48-2.89-12.92-6.3-19.95-7.66-5.51-1.07-11.51-.92-16.64,1.54a18.64,18.64,0,0,0-6.8,5.66c-2,2.66-3.53,5.64-5.46,8.35a42.82,42.82,0,0,1-17.56,14.22c-.29.13,0,.56.26.44Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M754.2,485.41a46.59,46.59,0,0,0,13.45-8c4.13-3.73,6.15-9.35,10.77-12.59,4.77-3.34,11.1-3.66,16.65-2.59,6.12,1.19,11.78,3.9,17.62,5.95a45.36,45.36,0,0,0,9.81,2.4,25.66,25.66,0,0,0,5.4.09,19.76,19.76,0,0,0,2.29-.34c.29-.06.59-.12.88-.2l.87-.24c.42-.27.7-.19.86.23-.24-.22-.59.13-.35.35.74.69,1.71-1.31-.08-1.18a13.46,13.46,0,0,0-1.87.47,22.29,22.29,0,0,1-3,.45,26.86,26.86,0,0,1-5.38-.18c-7.12-.92-13.6-4.17-20.33-6.47-5.75-2-11.94-3.21-18-1.68a17,17,0,0,0-7.22,3.77c-2.25,2.05-3.85,4.68-5.59,7.15-4.1,5.83-10.47,9.45-16.93,12.16-.29.12-.16.6.14.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M835.59,469.36c-1.2,1.16-2.65,2-3.89,3.16a56.4,56.4,0,0,0-5.08,5.72c-3.47,4.14-6.88,8.39-11,11.95a30.86,30.86,0,0,1-13.58,7.29c-5.11,1.08-10-.17-14.78-1.85s-9.76-3.5-15-2.65c-.32,0-.18.54.13.49,5.13-.84,10,1,14.73,2.64,4.5,1.57,9.05,2.82,13.85,2.07,10.55-1.64,18-10.11,24.51-17.86,2-2.35,3.91-4.76,6-7,1.32-1.37,3-2.31,4.37-3.64.23-.22-.12-.57-.35-.35Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M757.28,486.12c4.83,1.24,9.71,2.3,14.59,3.29s9.63,2.07,14.47,2.95a47.16,47.16,0,0,0,6.91.79c2.38.06,4.76-.23,7.14-.28a36.86,36.86,0,0,0,9.6-1.64,36.39,36.39,0,0,0,10.73-5.46c.26-.18,0-.61-.25-.43-5.61,3.93-12.26,6.81-19.2,7-2.2.07-4.4.25-6.6.3a39.25,39.25,0,0,1-7.26-.61c-4.68-.78-9.31-1.88-14-2.82-5.37-1.08-10.73-2.22-16-3.58a.25.25,0,0,0-.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M755.65,485.83c9.55-2.28,19.29-.56,28.82.93,9.14,1.43,18.34,2.45,27.45.21a48,48,0,0,0,14.18-6.06c.27-.17,0-.61-.25-.43a48.1,48.1,0,0,1-27.23,7.34c-9.37-.28-18.51-2.66-27.84-3.41a48.86,48.86,0,0,0-15.26.93.25.25,0,0,0,.13.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M756.8,485c9.57-3.3,19.71-4.09,29.77-3.89,5,.09,10,.42,15,.79,5.21.38,10.49,1,15.71.32a21.33,21.33,0,0,0,12.47-5.59.25.25,0,0,0-.36-.35c-7.32,7.25-18.48,5.81-27.82,5.12-9.73-.72-19.54-1.3-29.27-.27a71.05,71.05,0,0,0-15.66,3.39c-.3.1-.17.58.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M761.34,483.19a67.37,67.37,0,0,1,29.81-6.8A125.19,125.19,0,0,1,807.3,478c5,.68,10.3,1.38,15.3.5,3.93-.69,7.91-3.58,10.5-6.52.22-.25-.14-.6-.35-.36-2.51,2.86-6.34,5.65-10.14,6.37-5.39,1-11.26.08-16.61-.68a110.61,110.61,0,0,0-15.08-1.46,67.49,67.49,0,0,0-29.83,6.87c-.29.14,0,.57.25.43Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M832.39,469.22a35.11,35.11,0,0,1-13.14,5.3,39.71,39.71,0,0,1-15-.48c-4.6-.92-9.13-2-13.84-2A44.69,44.69,0,0,0,777.21,474a48.81,48.81,0,0,0-13.5,6.51c-.27.18,0,.61.25.43a46.56,46.56,0,0,1,26.4-8.38c5,0,9.76,1.27,14.62,2.19a39.32,39.32,0,0,0,13.55.45,35.68,35.68,0,0,0,14.12-5.49c.26-.18,0-.61-.26-.44Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M765.93,480.06c6.23-6.1,13-12.61,21.88-14.26,9.08-1.68,17.15,3.93,25.69,5.91a33.47,33.47,0,0,0,14.26.42c.32-.06.18-.55-.13-.48a33.26,33.26,0,0,1-14.45-.53c-4.36-1.06-8.43-2.88-12.65-4.36a25,25,0,0,0-12.21-1.55,28.69,28.69,0,0,0-11.5,4.76,82,82,0,0,0-11.24,9.73.25.25,0,0,0,.35.36Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M750.84,486A42.92,42.92,0,0,1,729,483.33c-3.23-1.31-6.22-3.16-9.46-4.43a18.64,18.64,0,0,0-8.86-1.27c-13.6,1.46-21.07,14.39-28,24.54-4.16,6.08-9.2,12.88-16.89,14.4a.25.25,0,0,0,0,.48c13.68,3.19,26.32,12.47,40.92,10,12.53-2.17,22.84-11.56,28.48-22.63,1.92-3.75,3.56-7.63,6.07-11,2.39-3.25,5.36-6.49,9.66-6.7.32,0,.32-.51,0-.5-6.41.32-10.59,7-13.24,12.09-1.65,3.17-3,6.47-4.94,9.51a45,45,0,0,1-6.11,7.77,38.66,38.66,0,0,1-16.66,10.22c-6.86,2-13.83,1.25-20.57-.87-7.93-2.5-15.35-6.4-23.47-8.29v.48c6-1.18,10.52-5.65,14.11-10.35,4.26-5.56,7.67-11.73,12.15-17.13,4.29-5.19,9.89-9.72,16.63-11.14a18.12,18.12,0,0,1,10.2.8c3.24,1.21,6.2,3,9.39,4.38a43.36,43.36,0,0,0,22.6,2.91c.31,0,.18-.52-.14-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M745.91,487.29c-6.21,1-13.16,1.45-19.15-.8-3.24-1.21-6.28-3-9.68-3.73a16.73,16.73,0,0,0-8.68.5c-12.85,4.09-17.86,18-27.39,26.27a27,27,0,0,1-9.46,5.28c-.53.18-1.54.59-1.1,1.33a.6.6,0,0,0,1.11-.08.25.25,0,0,0-.48-.13c.24-.89,2.82-1.35,3.64-1.71a24,24,0,0,0,3.76-2.11,36.28,36.28,0,0,0,5.91-5.15c4.11-4.38,7.34-9.49,11.29-14s8.84-8.51,14.83-9.73a17,17,0,0,1,9.39.91c2.69,1,5.22,2.44,8,3.27,5.82,1.74,12.25,1.3,18.17.38.32,0,.18-.54-.13-.49Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M668.19,517.26c1.73.39,3.53.33,5.26.71a82.17,82.17,0,0,1,8.22,2.55c5.49,1.84,11,3.73,16.8,4.36a28.63,28.63,0,0,0,15-1.93c4.13-1.88,7.17-5.13,10-8.59,3.24-4,6.42-8.16,11.3-10.16.3-.12.17-.6-.13-.48-8.2,3.36-11.42,12.4-18.51,17.19-9.77,6.58-22.27,3.13-32.49-.27-3.12-1-6.23-2.12-9.4-3-1.92-.52-3.93-.44-5.87-.87-.31-.07-.44.41-.13.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M743.43,489.65c-6.65,6.88-13.71,13.43-20.77,19.9a49.75,49.75,0,0,1-5.35,4.37c-1.82,1.26-3.81,2.17-5.71,3.27a32.49,32.49,0,0,1-6,3c-1.28.43-2.56.87-3.86,1.25a35.81,35.81,0,0,1-12.2,1.05c-.32,0-.32.48,0,.5,6.92.42,14.19-.65,20.28-4.17,3.84-2.21,7.69-4.17,11.09-7.06,3.56-3,6.86-6.36,10.27-9.54,4.27-4,8.51-8,12.56-12.18a.25.25,0,0,0-.35-.36Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M745,488.45c-17.39,5.71-27.7,22.8-44.61,29.43a47.73,47.73,0,0,1-17.95,3.26.25.25,0,0,0,0,.5,49,49,0,0,0,28.24-8.73c7.4-5,13.71-11.46,20.93-16.74a49.12,49.12,0,0,1,13.52-7.24c.3-.1.17-.58-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M743.48,488.33c-9.15,2-17.63,6.18-25.56,11.11-4,2.5-7.94,5.21-11.8,8s-7.9,5.94-12.14,8.45c-5,3-10.9,5-16.77,3.6a.25.25,0,0,0-.13.49c9.93,2.3,18.56-4.35,26.1-9.81,7.89-5.7,15.9-11.37,24.67-15.67a71.26,71.26,0,0,1,15.76-5.65c.32-.07.18-.55-.13-.48Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M738.79,489.13a67.89,67.89,0,0,0-28.24,9.32c-8.61,5.18-15.34,13-24.22,17.75a15.36,15.36,0,0,1-7.23,1.94,22.72,22.72,0,0,1-7.15-.89.25.25,0,0,0-.13.48,21.1,21.1,0,0,0,11.55.34,24.07,24.07,0,0,0,5.93-3,68.77,68.77,0,0,0,5.84-4.23c4.07-3.24,7.93-6.76,12.23-9.72a66.8,66.8,0,0,1,31.42-11.46c.31,0,.32-.53,0-.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M671,515.49a36.11,36.11,0,0,0,25-9.12c3.69-3.21,7-6.86,11-9.65a43.12,43.12,0,0,1,12.53-5.87,48,48,0,0,1,15.81-1.8c.32,0,.32-.49,0-.5a47.19,47.19,0,0,0-25.82,6c-8.24,4.74-13.7,13-22.37,17.11A35.21,35.21,0,0,1,671,515c-.32,0-.32.49,0,.5Z" transform="translate(-0.28)" />
        <path class="cls-3" d="M733.37,488.89c-8.77-2-18.3-4.06-27-.44-8.09,3.39-11.91,11.71-17.74,17.71a33.33,33.33,0,0,1-12.38,8.39c-.3.11-.17.59.13.48,7.63-2.77,13.23-8.66,18-15,2.68-3.54,5.39-7.23,9.21-9.62A25.17,25.17,0,0,1,716.52,487c5.66-.12,11.23,1.15,16.71,2.4.32.07.45-.41.14-.48Z" transform="translate(-0.28)" />
    </g>
</template>

<script>
export default {
    name: 'Dolphin'
}
</script>

<style>

</style>