<template>
    <g id="roots" class="nature-outline">
        <path class="cls-1" d="M5.62,207.24c17.73-42.46,45.43-81.18,82.09-109s82.52-44.18,128.5-42.52,91.62,22.15,121,57.6c10.29,12.43,18.5,26.45,26,40.72,15.61,29.55,28.56,60.56,37.46,92.77"/>
        <path class="cls-1" d="M208.19,56.1c-12.46,1.55-19.44,4.51-31.08,9.22-33.63,13.61-67.28,28.4-95.85,50.74A219.43,219.43,0,0,0,.38,254.24"/>
        <path class="cls-2" d="M208.05,55.82c48,8,87.84,37.82,122.56,73.94,37.87,39.4,58.08,93.39,64.12,147.7"/>
        <path class="cls-2" d="M207.38,56.9q-24,11.22-47.35,23.57c-23.88,12.59-47.7,26.35-66.1,46.11-11.68,12.56-20.87,27.22-29.08,42.28-12.28,22.55-22.76,47.37-21.07,73,1.48,22.62,12.33,43.56,24.82,62.47"/>
        <path class="cls-1" d="M206.8,56.84c9.25-.83,11.67,6.48,16.67,11.31a73.36,73.36,0,0,0,10.15,7.69c34.71,23.59,65.65,54,84.67,91.39,18.13,35.69,24.67,76,30.64,115.55"/>
        <path class="cls-1" d="M211.85,56.1C183.38,73.51,153.74,95.15,133.9,122c-14.79,20-24.51,43.47-30.14,67.7s-7.3,49.24-6.94,74.11"/>
        <path class="cls-1" d="M209.46,56.79c-8.23,3.1-16,7.46-23.64,11.75a233.14,233.14,0,0,0-72.74,63.74A158.28,158.28,0,0,0,98.6,155.09c-9.87,19.37-14.33,41.13-15.69,62.83s.26,43.45,2,65.12"/>
        <path class="cls-2" d="M208.34,56.38c14.11,16.33,27.47,36.42,41.59,52.74,8.58,9.92,17.17,19.85,25.28,30.16,6.9,8.76,13.46,17.8,20,26.82,5.33,7.35,10.7,14.75,14.68,22.91,10.55,21.64,10.57,46.71,10.38,70.79"/>
        <path class="cls-2" d="M211.14,56.07c-8.74,5.35-13.12,13.29-19.9,21-21.65,24.53-43.46,50.1-54.44,80.92-11.09,31.12-10.23,65-9.24,98.08"/>
        <path class="cls-1" d="M207.31,56.88c15.53,15.46,30.72,31.71,41,51,5,9.32,8.75,19.23,12.51,29.1,5.31,14,10.62,27.94,14.69,42.31,5.78,20.43,9,41.48,12.18,62.48,2,13.2,4,26.58,2.68,39.87"/>
        <path class="cls-1" d="M212.68,55.77c-8.14,7.36-11,25.27-13.71,35.89-2.86,11.07-5.67,22.18-7.25,33.5-1.52,10.92-1.87,22-2.23,33q-1.8,55.64-3.59,111.25c-.27,8.42-.36,17.49,4.43,24.42"/>
        <path class="cls-1" d="M208.91,57.78c41.64,54,65.22,124.58,62.94,192.76-.63,18.64-3.26,37.32-1.32,55.87"/>
        <path class="cls-2" d="M210.55,56.09c-2.91,4.88-3.6,10.72-4.06,16.38-5.64,68.4,11.55,137,8.1,205.5"/>
        <path class="cls-1" d="M140.84,147.44c-2.33,20.49.62,45-2.26,65.4"/>
        <path class="cls-1" d="M145.88,137.43c-4.85,10.9-9.22,15.94-14.37,24.69-1.85,3.15-4.21,6-6.06,9.14a76.66,76.66,0,0,0-5.69,12.47,125.68,125.68,0,0,0-7.13,31.73"/>
        <path class="cls-1" d="M117,192a191,191,0,0,1,3,45.22"/>
        <path class="cls-1" d="M115,155.39C103,170.9,96.15,189.58,89.43,208"/>
        <path class="cls-1" d="M46.63,213.52a118.82,118.82,0,0,0-11.54,51.19"/>
        <path class="cls-1" d="M37.17,242c.88,11.7,5.24,26,3,37.52"/>
        <path class="cls-1" d="M42.78,155c-4.72,6.82-7.25,12.27-8.71,19q-4.26,19.65-9.4,39.11"/>
        <path class="cls-1" d="M207.76,152.15c3.75,28.48,18.13,54.15,16.4,82.82"/>
        <path class="cls-1" d="M211.46,193.72c1.32,22.73-14.72,43.26-13.85,66"/>
        <path class="cls-1" d="M187.38,217.48c8,26,4,37.85,5.47,58"/>
        <path class="cls-1" d="M187.54,215.34c-1.61,19.41-14.7,38-13.85,57.41"/>
        <path class="cls-1" d="M188.71,179.62c-2.81,22.2-24,39.12-25.46,61.45"/>
        <path class="cls-1" d="M249.08,127.83c1.68,17-5.88,35.21-1.25,51.66"/>
        <path class="cls-1" d="M247.91,149.18c-.2,9.38,5.83,18.75,8.31,27.8a75,75,0,0,1,2,14.17l.64,8.56"/>
        <path class="cls-1" d="M290,259.63c.35,3.79-.85,5.31-1.77,7.35-3.55,7.84-7.05,16.24-5.85,24.76"/>
        <path class="cls-1" d="M290.66,273.85c4.15,4.59,5.87,19,4.88,25.12"/>
        <path class="cls-1" d="M307,183.68c3.21,6,.55,7.41-1.58,10-7.41,9.1-3.59,23.54-.89,35"/>
        <path class="cls-1" d="M303.43,196.7c-.41,10.68,7.5,26.86,7,39"/>
        <path class="cls-1" d="M320.11,253.4c-4.52,8.59-2.14,11.33-3.41,15.94s-2.24,9.44-1.39,14.15"/>
        <path class="cls-1" d="M320.36,248.55c.92,3.66,3.35,6.74,4.9,10.18,2,4.48,2.48,9.48,2.91,14.38"/>
        <path class="cls-1" d="M304.58,144.23A92.71,92.71,0,0,0,316.18,156c2.35,2,4.9,3.85,7.05,6.09a41.86,41.86,0,0,1,6.94,10.67c1.71,3.46,3.31,7.16,3.21,11"/>
        <path class="cls-1" d="M330.45,196.24c1.28,9.29-.12,19.52,2.13,28.92"/>
        <path class="cls-1" d="M326.94,125.82c4.52,4.71,5.56,11.85,6.47,18.32,3.12,22,3.35,45,12.69,65.19"/>
        <path class="cls-1" d="M393,264.89a77.49,77.49,0,0,0-2.45,44.46"/>
        <path class="cls-1" d="M367,161.44c1.94,5.9-1,14,2.18,19.31"/>
        <path class="cls-1" d="M354.47,138.47c5.69,7.82,14.78,12.66,22.27,18.78s14.58,13.85,15.87,23.43"/>
        <path class="cls-1" d="M389.55,172c2.91,4.94,8.49,7.78,10.66,12.36"/>
        <path class="cls-1" d="M206.73,57c17-1.14,30-18.41,35-33.38,1.83-5.55,3-11.43.19-16.57"/>
        <path class="cls-1" d="M241.76,7a3.48,3.48,0,0,1-3.63-.71A3.44,3.44,0,0,1,238,2.49,4.62,4.62,0,0,1,241.36.41a3,3,0,0,1,2.27.5,3.7,3.7,0,0,1,1,2.25,3.47,3.47,0,0,1-.18,2.34c-.56,1-1.61,1.18-3,1.69"/>
        <path d="M241.36,5a1.27,1.27,0,0,1-1.31-.26,1.24,1.24,0,0,1,0-1.38,1.67,1.67,0,0,1,1.2-.75,1.12,1.12,0,0,1,.82.18,1.32,1.32,0,0,1,.36.81,1.26,1.26,0,0,1-.06.84c-.2.36-.58.43-1.09.61"/>
        <path class="cls-1" d="M210.13,59.32a44.3,44.3,0,0,0,3.29-28.26"/>
        <path class="cls-1" d="M209.73,56.51a21.53,21.53,0,0,1-14.46-7A20.05,20.05,0,0,1,189.85,35"/>
        <path class="cls-1" d="M207.31,57.31c.12-5.71.12-11.51-1.3-17.05-.94-3.69-2.5-7.19-3.58-10.84s-1.74-6.93-.72-10.6"/>
        <path class="cls-1" d="M213.71,26.27a2.16,2.16,0,0,0-2.36,1.48,2.83,2.83,0,0,0,.81,2.78,1.75,1.75,0,0,0,.77.46,1.81,1.81,0,0,0,1.1-.1,2.78,2.78,0,0,0,1.86-2.61c-.06-1.14-1-1.86-2.31-2"/>
        <path class="cls-1" d="M202.79,14.14a2.48,2.48,0,0,0-2.71.61,2.53,2.53,0,0,0-.12,2.43,2.66,2.66,0,0,0,1.72,1.52,2.41,2.41,0,0,0,2.83-2.07A2.72,2.72,0,0,0,202,14"/>
        <path class="cls-1" d="M190.27,30.65a1.7,1.7,0,0,0-1.85,0,2.13,2.13,0,0,0-.83,1.55,2.47,2.47,0,0,0,1.91,2.68,1.92,1.92,0,0,0,2.12-2.21,3.45,3.45,0,0,0-1.7-2.17"/>
        <path d="M213.74,27.85a.87.87,0,0,0-.88.54.68.68,0,0,0,.46.87.78.78,0,0,0,.75-.38.81.81,0,0,0,.13-.67.48.48,0,0,0-.55-.33"/>
        <path d="M202.38,15.57a.67.67,0,0,0-.49,1.24.77.77,0,0,0,.8-.1.7.7,0,0,0,.19-.77.64.64,0,0,0-.68-.39"/>
        <path d="M189.14,32.52a.5.5,0,0,0,.28.55l.17,0a.37.37,0,0,0,.18,0,.49.49,0,1,0-.63-.58Z"/>
    </g>
</template>

<script>
export default {
    name: 'Roots'
}
</script>

<style>

</style>