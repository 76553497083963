import $ from 'jquery';

export default function scrollTo(target) {

    const $el = $(target);

    const scrollSpeed = 250;
    let scrollOffset = 120;

    try {

        var scrollTarget = $el.offset().top;

        $("html, body").stop(true).animate({
            scrollTop: (scrollTarget - scrollOffset) + "px"
        }, scrollSpeed);

    } catch (e) {
        //console.log("Couldn't calculate scroll distance");
    }

    
}