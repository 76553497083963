<template>

    <header class="header">
        <!-- <img v-on:click="returnHome" src="~@/images/logo.png"/> -->
        <svg v-on:click="returnHome" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 293.16 36.53" class="header__logo" alt="logo">
            <g style="fill: #fff;">
                <path d="M53.14,25.59a3.25,3.25,0,0,1-2.32-1,3.29,3.29,0,0,1,0-4.64l18-18.11a3.29,3.29,0,0,1,4.67,4.63l-18,18.1A3.25,3.25,0,0,1,53.14,25.59Z"/>
                <path d="M72.42,25.37a3.28,3.28,0,0,1-2.32-5.6L88,1.87a3.28,3.28,0,0,1,4.64,4.64L74.75,24.41A3.27,3.27,0,0,1,72.42,25.37Z"/>
                <path d="M91.65,25.37a3.28,3.28,0,0,1-2.33-5.6l17.9-17.9a3.28,3.28,0,1,1,4.65,4.64L94,24.41A3.27,3.27,0,0,1,91.65,25.37Z"/>
                <path d="M33.84,25.65A3.29,3.29,0,0,1,31.52,20L49.69,1.87A3.29,3.29,0,0,1,55.3,4.19a3.23,3.23,0,0,1-1,2.32L36.17,24.69A3.27,3.27,0,0,1,33.84,25.65Z"/>
                <path d="M3.56,36.53A3.56,3.56,0,0,1,1,30.45L29.82,1.67A3.57,3.57,0,0,1,35.9,4.19a3.52,3.52,0,0,1-1,2.52L6.08,35.49A3.57,3.57,0,0,1,3.56,36.53Z"/>
                <path class="header__logo__letter" d="M146,6.19a6.71,6.71,0,0,1,2.28,2.9,10.52,10.52,0,0,1,.78,4.15,10.37,10.37,0,0,1-.8,4.19,6.55,6.55,0,0,1-2.32,2.91,6.27,6.27,0,0,1-3.64,1.05,5.84,5.84,0,0,1-3-.74,5.92,5.92,0,0,1-2.14-2v9.21h-1.66V5.56H137v2.6a5.82,5.82,0,0,1,2.19-2.24,6.44,6.44,0,0,1,3.23-.79A6.05,6.05,0,0,1,146,6.19ZM146,18a7.83,7.83,0,0,0,1.34-4.75A7.87,7.87,0,0,0,146,8.52a5,5,0,0,0-7.66,0A7.8,7.8,0,0,0,137,13.24,8,8,0,0,0,138.32,18,4.88,4.88,0,0,0,146,18Z"/>
                <path class="header__logo__letter" d="M164.83,5.56V21h-1.51V18.66a5.33,5.33,0,0,1-2.18,2,6.89,6.89,0,0,1-3,.67,5.47,5.47,0,0,1-4.63-2.07A8.43,8.43,0,0,1,151.88,14V5.56h1.66v8a7.46,7.46,0,0,0,1.2,4.56,4.14,4.14,0,0,0,3.56,1.6,4.3,4.3,0,0,0,3.65-1.6,6.82,6.82,0,0,0,1.23-4.28V5.56Z"/>
                <path class="header__logo__letter" d="M173,5.72a5.46,5.46,0,0,1,1.84-.33,4.3,4.3,0,0,1,1.2.17V7.1a5.75,5.75,0,0,0-1.2-.14,3.8,3.8,0,0,0-2.65,1,4.08,4.08,0,0,0-1.39,2.23,13.86,13.86,0,0,0-.32,3.16V21h-1.66V5.56h1.51V8a4.29,4.29,0,0,1,1.09-1.31A4.59,4.59,0,0,1,173,5.72Z"/>
                <path class="header__logo__letter" d="M189.2,6.19a6.56,6.56,0,0,1,2.28,2.9,10.52,10.52,0,0,1,.78,4.15,10.54,10.54,0,0,1-.79,4.19,6.63,6.63,0,0,1-2.33,2.91,6.26,6.26,0,0,1-3.63,1.05,5.85,5.85,0,0,1-3-.74,5.92,5.92,0,0,1-2.14-2v9.21h-1.65V5.56h1.48v2.6a5.78,5.78,0,0,1,2.2-2.24,6.38,6.38,0,0,1,3.22-.79A6,6,0,0,1,189.2,6.19Zm0,11.8a7.83,7.83,0,0,0,1.34-4.75,7.8,7.8,0,0,0-1.31-4.72,5,5,0,0,0-7.65,0,7.74,7.74,0,0,0-1.33,4.75A7.89,7.89,0,0,0,181.56,18a4.87,4.87,0,0,0,7.62,0Z"/>
                <path class="header__logo__letter" d="M195.69,0h1.65V21h-1.65Z"/>
                <path class="header__logo__letter" d="M204,18.21a4.9,4.9,0,0,0,3.88,1.59A5.83,5.83,0,0,0,211,19a5.91,5.91,0,0,0,2.14-2.4l1.48.66A7.09,7.09,0,0,1,212,20.31a7.41,7.41,0,0,1-4,1.08,7.17,7.17,0,0,1-3.82-1,6.51,6.51,0,0,1-2.51-2.82,9.83,9.83,0,0,1-.89-4.3,10.11,10.11,0,0,1,.87-4.35,6.45,6.45,0,0,1,2.48-2.82,7.16,7.16,0,0,1,3.81-1,6.41,6.41,0,0,1,5.25,2.27A9.52,9.52,0,0,1,215,13.72H202.53A7,7,0,0,0,204,18.21Zm.13-10.07a6.54,6.54,0,0,0-1.61,4.1h10.66a6.73,6.73,0,0,0-1.59-4.12A4.81,4.81,0,0,0,208,6.7,5,5,0,0,0,204.17,8.14Z"/>
                <path class="header__logo__letter" d="M236.72,6.55a5.18,5.18,0,0,1,1.4,3.81l0,10.61h-1.62V10.61a4,4,0,0,0-1.05-2.91,3.53,3.53,0,0,0-2.66-1.08,3.86,3.86,0,0,0-2.68,1,4.13,4.13,0,0,0-1.14,3.21V21h-1.63l0-10.19a4.35,4.35,0,0,0-1-3,3.85,3.85,0,0,0-5.48,0,4.47,4.47,0,0,0-1,3.08V21h-1.65V5.56h1.48V7.19a4.89,4.89,0,0,1,1.83-1.51,5.61,5.61,0,0,1,2.48-.55,5.32,5.32,0,0,1,2.69.68,4.17,4.17,0,0,1,1.79,2,4.67,4.67,0,0,1,1.88-2,5.52,5.52,0,0,1,2.76-.7A5,5,0,0,1,236.72,6.55Z"/>
                <path class="header__logo__letter" d="M244.25,18.21a4.9,4.9,0,0,0,3.88,1.59,5.83,5.83,0,0,0,3.11-.83,5.91,5.91,0,0,0,2.14-2.4l1.48.66a7.09,7.09,0,0,1-2.68,3.08,7.41,7.41,0,0,1-4,1.08,7.17,7.17,0,0,1-3.82-1,6.45,6.45,0,0,1-2.51-2.82,9.83,9.83,0,0,1-.89-4.3,10.11,10.11,0,0,1,.87-4.35,6.45,6.45,0,0,1,2.48-2.82,7.16,7.16,0,0,1,3.81-1,6.41,6.41,0,0,1,5.25,2.27,9.52,9.52,0,0,1,1.79,6.32H242.74A7,7,0,0,0,244.25,18.21Zm.13-10.07a6.54,6.54,0,0,0-1.61,4.1h10.66a6.73,6.73,0,0,0-1.59-4.12,4.81,4.81,0,0,0-3.68-1.42A5,5,0,0,0,244.38,8.14Z"/>
                <path class="header__logo__letter" d="M271,.43V21h-1.48V18.34a6.13,6.13,0,0,1-2.17,2.26,6,6,0,0,1-3.11.79,6.21,6.21,0,0,1-3.63-1.05,6.57,6.57,0,0,1-2.33-2.91,10.54,10.54,0,0,1-.8-4.19,10.34,10.34,0,0,1,.79-4.15,6.56,6.56,0,0,1,2.28-2.9,6,6,0,0,1,3.55-1.06,6.36,6.36,0,0,1,3.07.72,5.93,5.93,0,0,1,2.18,2V.43ZM268.19,18a7.89,7.89,0,0,0,1.33-4.76,7.74,7.74,0,0,0-1.33-4.75,5,5,0,0,0-7.64,0,7.89,7.89,0,0,0-1.3,4.74A7.9,7.9,0,0,0,260.58,18a4.39,4.39,0,0,0,3.78,1.84A4.43,4.43,0,0,0,268.19,18Z"/>
                <path class="header__logo__letter" d="M275.28.11h1.65V2.2h-1.65Zm0,5.45h1.65V21h-1.65Z"/>
                <path class="header__logo__letter" d="M293.16,11.35V21h-1.48V18.26a6.06,6.06,0,0,1-2.44,2.32,7.65,7.65,0,0,1-3.58.81,6.32,6.32,0,0,1-2.91-.61,4.23,4.23,0,0,1-1.8-1.64,4.41,4.41,0,0,1-.6-2.28,3.86,3.86,0,0,1,1.12-2.93,6.35,6.35,0,0,1,2.93-1.52c1-.23,2.18-.42,3.5-.59l2.44-.3.58,0,.61-.09a5.22,5.22,0,0,0-1-3.52,4.35,4.35,0,0,0-3.38-1.13c-2.39,0-3.89,1.08-4.47,3.23L281,9.5a5.39,5.39,0,0,1,2.11-3.24,7,7,0,0,1,4.08-1.13,7.14,7.14,0,0,1,3.57.83,4.41,4.41,0,0,1,2,2.31A9,9,0,0,1,293.16,11.35Zm-1.66,1.52-.57.05c-1.35.15-2.49.29-3.43.42a21.29,21.29,0,0,0-2.53.47c-2,.53-2.93,1.55-2.93,3a2.92,2.92,0,0,0,.89,2.12,3.84,3.84,0,0,0,2.81.9,5.86,5.86,0,0,0,3.81-1.22,5.09,5.09,0,0,0,1.81-3.31A15.08,15.08,0,0,0,291.5,12.87Z"/>
            </g>
        </svg>
        <div v-on:click="returnHome"  class="burger">
            <div class="burger__bar"></div>
            <svg width="80" height="80" class="header__prog">
                <circle class="header__prog__line" cx="40" cy="40" r="25"/>
            </svg>
        </div>
    </header>

</template>

<script>

import router from '../router';
import $ from 'jquery';

export default {
    name: 'headerrow',
    props: {
        bg: String
    },
    data: function(){
        return {

        }
    },
    methods: {
        returnHome: function() {
            router.push('/')
        },
        onScroll() {
            let scrollHeight = document.body.scrollHeight - window.innerHeight,
                scrollPos = $("html").scrollTop(),
                scrollDecimal = scrollPos / scrollHeight,
                progSize = 25 * 6.26666666667,
                offsetValue = progSize - (progSize * scrollDecimal);

            $(".header__prog__line").css("stroke-dashoffset", offsetValue)

        }
    },
    mounted: function () {        
        // add parallaxing 
        document.addEventListener('scroll', this.onScroll);
        let comp = this;
        setTimeout(function(){
            comp.onScroll();
        }, 500);
    }
}
</script>

<style lang="scss" scoped>
    .header {
        position: fixed;
        top:0;
        left:0;
        width: 100%;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 40px;
        z-index: 15;
        &__logo {
            margin-top: 4px;
            cursor: pointer;
            height: 20px;
            &__letter {
                display: none;
                @media (min-width: 768px) {
                    display: block;
                }
            }
        }
        @media (max-width: 768px) {
            padding: 20px;
            height: 60px;
            & img {
                opacity: 0;
            }
        }
        &__prog {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotateZ(-90deg);
            pointer-events: none;
            &__line {
                stroke-dasharray: calc(25 * 6.26666666667);
                stroke-dashoffset: calc(25 * 6.26666666667);
                fill: none;
                stroke: white;
                transition: all .2s ease-out;
            }
        }
    } 
    .burger {
        position:relative;
        height: 30px;
        width: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position:relative;
        &__bar {
            width: 100%;
            height: 2px;
            background: white;
            transition: all .25s ease-out;
            &:before, &:after {
                content:'';
                position: absolute;
                width: inherit;
                height: inherit;
                background: inherit;
                transition: inherit;
            }
            &:before {
                bottom: 8px;
            }
            &:after {
                top: 8px;
            }
        }
    }       
</style>