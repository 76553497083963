<template>
    <g id="owl" class="animal-outline">
        <g class="cls-2">
            <g class="cls-3">
                <path class="cls-4" d="M163.44,7.64a5.13,5.13,0,0,0-3.1-.74.25.25,0,0,0,0,.5,4.68,4.68,0,0,1,2.85.67.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M160.34,6.65a11.77,11.77,0,0,0-8.81,3.91,9.21,9.21,0,0,0-1.73,8.76c1.12,3.34,4.55,5.45,7.82,6.33a8.7,8.7,0,0,0,8.7-2.56,9.61,9.61,0,0,0,1.37-10.86c-.14-.29-.57,0-.43.25a9.16,9.16,0,0,1-.65,9.49,8.33,8.33,0,0,1-8,3.39c-3.09-.55-6.71-2.54-8-5.5a8.69,8.69,0,0,1,.87-8.35,11.1,11.1,0,0,1,8.93-4.36.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M167.93,4.67c-1.09-.76-2.32-1.58-3.64-1.41h-.06a11.18,11.18,0,0,0-9.45,5,8.6,8.6,0,0,0,.9,10.29A11.1,11.1,0,0,0,158,20.22a11.93,11.93,0,0,0,5.68,1.87,8.91,8.91,0,0,0,8.53-7A10.47,10.47,0,0,0,167.93,4.67Zm.73,4.86a2.49,2.49,0,0,1-2.25,2.19,2.29,2.29,0,0,1-2.33-2c-.07-1.14.74-2.37,1.88-2.29H166c.65,0,1.42-.21,1.95.25A2.24,2.24,0,0,1,168.66,9.53Z"/>
                <path class="cls-4" d="M205.24,10.48a5.21,5.21,0,0,0-1.49,2.89.25.25,0,1,0,.5,0,4.63,4.63,0,0,1,1.34-2.54c.22-.24-.13-.59-.35-.35Z"/>
                <path class="cls-4" d="M203.52,13.24a11.73,11.73,0,0,0,1.67,9.52,9.19,9.19,0,0,0,8.26,3.77c3.47-.36,6.37-3.25,7.94-6.22a8.71,8.71,0,0,0-.58-9,9.63,9.63,0,0,0-10.1-3.8c-.31.06-.18.54.14.48a9.15,9.15,0,0,1,9,2.91,8.35,8.35,0,0,1,1.5,8.31,13,13,0,0,1-3,4.21,8.52,8.52,0,0,1-4.15,2.52,8.62,8.62,0,0,1-8.1-2.7A11,11,0,0,1,204,13.37c.07-.31-.41-.44-.48-.13Z"/>
                <path class="cls-4" d="M203.44,5.41c-1,.87-2.1,1.86-2.25,3.18l0,.06A11.2,11.2,0,0,0,203.68,19a8.58,8.58,0,0,0,10.2,1.62,11.67,11.67,0,0,0,2.24-1.85,12.14,12.14,0,0,0,3.2-5.07,8.94,8.94,0,0,0-4.71-10A10.5,10.5,0,0,0,203.44,5.41Zm4.89.46a2.5,2.5,0,0,1,1.58,2.72,2.29,2.29,0,0,1-2.54,1.76A2,2,0,0,1,205.6,8l0-.06c.1-.64.14-1.43.71-1.83A2.21,2.21,0,0,1,208.33,5.87Z"/>
                <path class="cls-4" d="M191,4.55a5.07,5.07,0,0,0-2.67-1.75.25.25,0,0,0-.13.48,4.69,4.69,0,0,1,2.45,1.62.25.25,0,0,0,.35-.35Z"/>
                <path class="cls-4" d="M188.42,2.56a11.67,11.67,0,0,0-10.09.95,9.18,9.18,0,0,0-4.14,7.76c.08,3.46,2.75,6.68,5.56,8.48a8.71,8.71,0,0,0,8.72.28,9.59,9.59,0,0,0,5-9.66c0-.31-.53-.31-.5,0A9.18,9.18,0,0,1,189.29,19a8.38,8.38,0,0,1-8.41.85,12.05,12.05,0,0,1-3.72-3,9.13,9.13,0,0,1-2.37-4.44A8.54,8.54,0,0,1,178,4.4,11,11,0,0,1,188.29,3c.3.11.43-.37.13-.48Z"/>
                <path class="cls-4" d="M196.25,3.32c-.76-1.09-1.64-2.29-2.93-2.57l-.06,0a11.21,11.21,0,0,0-10.58,1.4,8.58,8.58,0,0,0-2.68,10,10.87,10.87,0,0,0,1.6,2.43,12.06,12.06,0,0,0,4.71,3.71,8.93,8.93,0,0,0,10.41-3.63A10.5,10.5,0,0,0,196.25,3.32Zm-1,4.81a2.49,2.49,0,0,1-2.87,1.28,2.3,2.3,0,0,1-1.49-2.71,2,2,0,0,1,2.55-1.5l.06,0c.63.17,1.41.29,1.74.9A2.19,2.19,0,0,1,195.27,8.13Z"/>
                <path class="cls-4" d="M237.77,7.64a5.13,5.13,0,0,0-3.1-.74.25.25,0,0,0,0,.5,4.7,4.7,0,0,1,2.85.67.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M234.67,6.65a11.75,11.75,0,0,0-8.81,3.91,9.21,9.21,0,0,0-1.73,8.76c1.12,3.34,4.55,5.45,7.82,6.33a8.7,8.7,0,0,0,8.7-2.56A9.61,9.61,0,0,0,242,12.23c-.14-.29-.57,0-.43.25a9.16,9.16,0,0,1-.65,9.49,8.33,8.33,0,0,1-8.05,3.39c-3.08-.55-6.7-2.54-8-5.5a8.69,8.69,0,0,1,.87-8.35,11.09,11.09,0,0,1,8.93-4.36.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M242.26,4.67c-1.09-.76-2.32-1.58-3.64-1.41h-.07a11.18,11.18,0,0,0-9.44,5A8.58,8.58,0,0,0,230,18.5a11.29,11.29,0,0,0,2.34,1.72A12,12,0,0,0,238,22.09a8.91,8.91,0,0,0,8.53-7A10.47,10.47,0,0,0,242.26,4.67ZM243,9.53a2.49,2.49,0,0,1-2.26,2.19,2.29,2.29,0,0,1-2.32-2c-.07-1.14.74-2.37,1.88-2.29h.06c.65,0,1.42-.21,2,.25A2.24,2.24,0,0,1,243,9.53Z"/>
                <path class="cls-4" d="M279.57,10.48a5.15,5.15,0,0,0-1.49,2.89.25.25,0,1,0,.5,0,4.63,4.63,0,0,1,1.34-2.54c.22-.24-.13-.59-.35-.35Z"/>
                <path class="cls-4" d="M277.85,13.24a11.73,11.73,0,0,0,1.67,9.52,9.19,9.19,0,0,0,8.26,3.77c3.47-.36,6.37-3.25,7.94-6.22a8.71,8.71,0,0,0-.58-9A9.63,9.63,0,0,0,285,7.47c-.31.06-.18.54.14.48a9.15,9.15,0,0,1,9,2.91,8.35,8.35,0,0,1,1.5,8.31,13,13,0,0,1-3,4.21,8.52,8.52,0,0,1-4.15,2.52,8.62,8.62,0,0,1-8.1-2.7,11,11,0,0,1-2.17-9.83c.07-.31-.41-.44-.48-.13Z"/>
                <path class="cls-4" d="M277.77,5.41c-1,.87-2.1,1.86-2.25,3.18l0,.06A11.2,11.2,0,0,0,278,19a8.58,8.58,0,0,0,10.2,1.62,11.67,11.67,0,0,0,2.24-1.85,12.14,12.14,0,0,0,3.2-5.07,9,9,0,0,0-4.71-10A10.49,10.49,0,0,0,277.77,5.41Zm4.89.46a2.48,2.48,0,0,1,1.57,2.72,2.27,2.27,0,0,1-2.53,1.76A2,2,0,0,1,279.93,8V7.92c.11-.64.15-1.43.72-1.83A2.21,2.21,0,0,1,282.66,5.87Z"/>
                <path class="cls-4" d="M265.33,4.55a5.11,5.11,0,0,0-2.67-1.75.25.25,0,0,0-.13.48A4.69,4.69,0,0,1,265,4.9a.25.25,0,0,0,.35-.35Z"/>
                <path class="cls-4" d="M262.75,2.56a11.67,11.67,0,0,0-10.09.95,9.18,9.18,0,0,0-4.14,7.76c.08,3.46,2.75,6.68,5.56,8.48a8.71,8.71,0,0,0,8.72.28,9.59,9.59,0,0,0,5-9.66c0-.31-.53-.31-.5,0A9.2,9.2,0,0,1,263.62,19a8.38,8.38,0,0,1-8.41.85,12.05,12.05,0,0,1-3.72-3,9.23,9.23,0,0,1-2.38-4.44,8.59,8.59,0,0,1,3.16-7.92A11,11,0,0,1,262.62,3c.3.11.43-.37.13-.48Z"/>
                <path class="cls-4" d="M270.57,3.32c-.75-1.09-1.63-2.29-2.92-2.57l-.07,0A11.19,11.19,0,0,0,257,2.12a8.58,8.58,0,0,0-2.68,10,10.87,10.87,0,0,0,1.6,2.43,12.1,12.1,0,0,0,4.7,3.71A8.92,8.92,0,0,0,271,14.6,10.46,10.46,0,0,0,270.57,3.32Zm-1,4.81a2.49,2.49,0,0,1-2.87,1.28,2.3,2.3,0,0,1-1.49-2.71,2,2,0,0,1,2.55-1.5l.06,0c.63.17,1.41.29,1.74.9A2.22,2.22,0,0,1,269.6,8.13Z"/>
                <path class="cls-4" d="M15.32,7.64a5.16,5.16,0,0,0-3.11-.74.25.25,0,0,0,0,.5,4.71,4.71,0,0,1,2.86.67.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M12.22,6.65a11.75,11.75,0,0,0-8.81,3.91,9.18,9.18,0,0,0-1.73,8.76c1.12,3.34,4.55,5.45,7.81,6.33a8.71,8.71,0,0,0,8.71-2.56,9.62,9.62,0,0,0,1.37-10.86c-.14-.29-.57,0-.43.25A9.16,9.16,0,0,1,18.48,22a8.31,8.31,0,0,1-8,3.39c-3.08-.55-6.7-2.54-8-5.5a8.66,8.66,0,0,1,.87-8.35,11.09,11.09,0,0,1,8.93-4.36.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M19.8,4.67c-1.08-.76-2.31-1.58-3.63-1.41H16.1a11.21,11.21,0,0,0-9.45,5A8.58,8.58,0,0,0,7.56,18.5,11,11,0,0,0,9.9,20.22a11.93,11.93,0,0,0,5.69,1.87,8.92,8.92,0,0,0,8.53-7A10.48,10.48,0,0,0,19.8,4.67Zm.74,4.86a2.51,2.51,0,0,1-2.26,2.19,2.29,2.29,0,0,1-2.32-2c-.07-1.14.73-2.37,1.87-2.29h.07c.64,0,1.42-.21,1.94.25A2.22,2.22,0,0,1,20.54,9.53Z"/>
                <path class="cls-4" d="M57.11,10.48a5.14,5.14,0,0,0-1.48,2.89c0,.13.12.25.25.25a.27.27,0,0,0,.25-.25,4.56,4.56,0,0,1,1.33-2.54c.22-.24-.13-.59-.35-.35Z"/>
                <path class="cls-4" d="M55.4,13.24a11.73,11.73,0,0,0,1.66,9.52,9.22,9.22,0,0,0,8.27,3.77c3.47-.36,6.37-3.25,7.94-6.22a8.69,8.69,0,0,0-.59-9,9.61,9.61,0,0,0-10.09-3.8c-.31.06-.18.54.13.48a9.17,9.17,0,0,1,9,2.91,8.35,8.35,0,0,1,1.5,8.31,13,13,0,0,1-3,4.21,8.55,8.55,0,0,1-4.16,2.52A8.64,8.64,0,0,1,58,23.2a11,11,0,0,1-2.16-9.83c.07-.31-.41-.44-.48-.13Z"/>
                <path class="cls-4" d="M55.32,5.41c-1,.87-2.1,1.86-2.25,3.18l0,.06A11.17,11.17,0,0,0,55.56,19c2.59,2.66,7,3.53,10.19,1.62A11.43,11.43,0,0,0,68,18.79a12,12,0,0,0,3.19-5.07,8.92,8.92,0,0,0-4.71-10A10.48,10.48,0,0,0,55.32,5.41Zm4.88.46a2.49,2.49,0,0,1,1.58,2.72,2.28,2.28,0,0,1-2.54,1.76c-1.12-.21-2.12-1.29-1.76-2.37V7.92c.11-.64.14-1.43.71-1.83A2.22,2.22,0,0,1,60.2,5.87Z"/>
                <path class="cls-4" d="M42.88,4.55A5.11,5.11,0,0,0,40.21,2.8c-.31-.07-.45.4-.13.48A4.66,4.66,0,0,1,42.52,4.9a.25.25,0,0,0,.36-.35Z"/>
                <path class="cls-4" d="M40.3,2.56a11.67,11.67,0,0,0-10.09.95,9.18,9.18,0,0,0-4.14,7.76c.07,3.46,2.75,6.68,5.56,8.48a8.71,8.71,0,0,0,8.72.28,9.59,9.59,0,0,0,5-9.66c0-.31-.53-.31-.5,0A9.2,9.2,0,0,1,41.17,19a8.39,8.39,0,0,1-8.42.85,12.13,12.13,0,0,1-3.71-3,9.23,9.23,0,0,1-2.38-4.44A8.59,8.59,0,0,1,29.82,4.4,11,11,0,0,1,40.17,3c.3.11.43-.37.13-.48Z"/>
                <path class="cls-4" d="M48.12,3.32C47.36,2.23,46.49,1,45.19.75l-.06,0a11.19,11.19,0,0,0-10.57,1.4c-2.92,2.3-4.25,6.6-2.69,10a11.27,11.27,0,0,0,1.61,2.43,12,12,0,0,0,4.7,3.71A8.92,8.92,0,0,0,48.59,14.6,10.48,10.48,0,0,0,48.12,3.32Zm-1,4.81a2.5,2.5,0,0,1-2.88,1.28A2.28,2.28,0,0,1,42.79,6.7a2,2,0,0,1,2.55-1.5l.06,0c.62.17,1.4.29,1.74.9A2.22,2.22,0,0,1,47.15,8.13Z"/>
                <path class="cls-4" d="M89.65,7.64a5.16,5.16,0,0,0-3.11-.74.25.25,0,0,0,0,.5,4.73,4.73,0,0,1,2.86.67.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M86.55,6.65a11.75,11.75,0,0,0-8.81,3.91A9.18,9.18,0,0,0,76,19.32c1.12,3.34,4.55,5.45,7.81,6.33a8.69,8.69,0,0,0,8.7-2.56,9.59,9.59,0,0,0,1.37-10.86c-.13-.29-.56,0-.42.25A9.16,9.16,0,0,1,92.81,22a8.31,8.31,0,0,1-8,3.39c-3.08-.55-6.71-2.54-8-5.5a8.66,8.66,0,0,1,.87-8.35,11.09,11.09,0,0,1,8.93-4.36.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M94.13,4.67c-1.08-.76-2.32-1.58-3.63-1.41h-.07a11.19,11.19,0,0,0-9.45,5,8.58,8.58,0,0,0,.91,10.29,11,11,0,0,0,2.34,1.72,11.93,11.93,0,0,0,5.69,1.87,8.93,8.93,0,0,0,8.53-7A10.48,10.48,0,0,0,94.13,4.67Zm.74,4.86a2.51,2.51,0,0,1-2.26,2.19,2.28,2.28,0,0,1-2.32-2c-.07-1.14.73-2.37,1.87-2.29h.06c.65,0,1.43-.21,2,.25A2.22,2.22,0,0,1,94.87,9.53Z"/>
                <path class="cls-4" d="M131.44,10.48A5.14,5.14,0,0,0,130,13.37c0,.13.12.25.25.25a.25.25,0,0,0,.24-.25,4.63,4.63,0,0,1,1.34-2.54c.22-.24-.13-.59-.35-.35Z"/>
                <path class="cls-4" d="M129.72,13.24a11.77,11.77,0,0,0,1.67,9.52,9.22,9.22,0,0,0,8.27,3.77c3.47-.36,6.37-3.25,7.94-6.22a8.69,8.69,0,0,0-.59-9,9.61,9.61,0,0,0-10.09-3.8c-.31.06-.18.54.13.48a9.17,9.17,0,0,1,9,2.91,8.35,8.35,0,0,1,1.5,8.31,13,13,0,0,1-3,4.21,8.55,8.55,0,0,1-4.16,2.52,8.64,8.64,0,0,1-8.1-2.7,11.08,11.08,0,0,1-2.17-9.83.25.25,0,0,0-.48-.13Z"/>
                <path class="cls-4" d="M129.65,5.41c-1,.87-2.1,1.86-2.25,3.18l0,.06A11.2,11.2,0,0,0,129.88,19c2.6,2.66,7,3.53,10.2,1.62a11.43,11.43,0,0,0,2.25-1.85,12,12,0,0,0,3.19-5.07,8.92,8.92,0,0,0-4.71-10A10.48,10.48,0,0,0,129.65,5.41Zm4.88.46a2.49,2.49,0,0,1,1.58,2.72,2.28,2.28,0,0,1-2.54,1.76c-1.12-.21-2.12-1.29-1.76-2.37V7.92c.1-.64.14-1.43.71-1.83A2.21,2.21,0,0,1,134.53,5.87Z"/>
                <path class="cls-4" d="M117.2,4.55a5,5,0,0,0-2.66-1.75c-.31-.07-.45.4-.13.48a4.66,4.66,0,0,1,2.44,1.62.25.25,0,0,0,.35,0,.24.24,0,0,0,0-.35Z"/>
                <path class="cls-4" d="M114.63,2.56a11.67,11.67,0,0,0-10.09.95,9.18,9.18,0,0,0-4.14,7.76c.07,3.46,2.75,6.68,5.56,8.48a8.71,8.71,0,0,0,8.72.28,9.59,9.59,0,0,0,5-9.66c0-.31-.53-.31-.5,0A9.2,9.2,0,0,1,115.5,19a8.39,8.39,0,0,1-8.42.85,12.13,12.13,0,0,1-3.71-3A9.23,9.23,0,0,1,101,12.32a8.59,8.59,0,0,1,3.16-7.92A11,11,0,0,1,114.5,3c.3.11.43-.37.13-.48Z"/>
                <path class="cls-4" d="M122.45,3.32c-.76-1.09-1.63-2.29-2.93-2.57l-.06,0a11.19,11.19,0,0,0-10.57,1.4c-2.92,2.3-4.25,6.6-2.69,10a11.27,11.27,0,0,0,1.61,2.43,12,12,0,0,0,4.7,3.71,8.92,8.92,0,0,0,10.41-3.63A10.48,10.48,0,0,0,122.45,3.32Zm-1,4.81a2.5,2.5,0,0,1-2.88,1.28,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.55-1.5l.06,0c.62.17,1.4.29,1.74.9A2.22,2.22,0,0,1,121.48,8.13Z"/>
                <path class="cls-4" d="M169.33,32.24a5.18,5.18,0,0,0-3.11-.74.26.26,0,0,0-.25.25.25.25,0,0,0,.25.25,4.61,4.61,0,0,1,2.85.67c.28.16.54-.27.26-.43Z"/>
                <path class="cls-4" d="M166.22,31.25a11.79,11.79,0,0,0-8.81,3.9,9.25,9.25,0,0,0-1.73,8.77c1.12,3.34,4.56,5.45,7.82,6.33a8.69,8.69,0,0,0,8.7-2.56,9.62,9.62,0,0,0,1.37-10.87c-.14-.28-.56,0-.43.26a9.14,9.14,0,0,1-.65,9.49,8.33,8.33,0,0,1-8,3.39c-3.08-.55-6.71-2.54-8-5.5a8.66,8.66,0,0,1,.88-8.35,11.08,11.08,0,0,1,8.92-4.36c.32,0,.32-.51,0-.5Z"/>
                <path class="cls-4" d="M173.81,29.27c-1.09-.76-2.32-1.58-3.63-1.41h-.07a11.18,11.18,0,0,0-9.45,5c-1.95,3.16-1.72,7.66.9,10.28a11.4,11.4,0,0,0,2.35,1.73,12,12,0,0,0,5.69,1.87,8.92,8.92,0,0,0,8.52-7A10.47,10.47,0,0,0,173.81,29.27Zm.74,4.85a2.49,2.49,0,0,1-2.26,2.19,2.28,2.28,0,0,1-2.33-2c-.06-1.14.74-2.37,1.88-2.29h.06a2.52,2.52,0,0,1,1.95.25A2.23,2.23,0,0,1,174.55,34.12Z"/>
                <path class="cls-4" d="M211.12,35.07a5.24,5.24,0,0,0-1.49,2.9.26.26,0,0,0,.25.25.25.25,0,0,0,.25-.25,4.7,4.7,0,0,1,1.34-2.55c.22-.23-.13-.58-.35-.35Z"/>
                <path class="cls-4" d="M209.4,37.84a11.74,11.74,0,0,0,1.67,9.52,9.16,9.16,0,0,0,8.26,3.76c3.47-.35,6.37-3.24,7.94-6.21a8.69,8.69,0,0,0-.58-9,9.61,9.61,0,0,0-10.09-3.8c-.32.06-.18.54.13.48a9.15,9.15,0,0,1,9,2.91,8.32,8.32,0,0,1,1.5,8.3A12.93,12.93,0,0,1,224.31,48a8.5,8.5,0,0,1-4.16,2.51,8.61,8.61,0,0,1-8.1-2.69A11.09,11.09,0,0,1,209.88,38c.07-.31-.41-.44-.48-.13Z"/>
                <path class="cls-4" d="M209.33,30c-1,.87-2.11,1.87-2.25,3.19l0,.06a11.2,11.2,0,0,0,2.51,10.37,8.58,8.58,0,0,0,10.2,1.62A11.43,11.43,0,0,0,222,43.39a12,12,0,0,0,3.19-5.07,8.92,8.92,0,0,0-4.71-10A10.45,10.45,0,0,0,209.33,30Zm4.88.47a2.5,2.5,0,0,1,1.58,2.72A2.29,2.29,0,0,1,213.25,35c-1.12-.21-2.12-1.29-1.77-2.37l0-.06c.1-.65.14-1.43.71-1.83A2.21,2.21,0,0,1,214.21,30.47Z"/>
                <path class="cls-4" d="M196.88,29.15a5.14,5.14,0,0,0-2.67-1.76c-.31-.07-.44.41-.13.48a4.7,4.7,0,0,1,2.45,1.63.25.25,0,0,0,.35-.35Z"/>
                <path class="cls-4" d="M194.31,27.16a11.64,11.64,0,0,0-10.09.95,9.16,9.16,0,0,0-4.15,7.76c.08,3.46,2.75,6.68,5.57,8.47a8.69,8.69,0,0,0,8.72.29A9.59,9.59,0,0,0,199.31,35c0-.31-.52-.32-.49,0a9.21,9.21,0,0,1-3.65,8.59,8.38,8.38,0,0,1-8.41.85,12.17,12.17,0,0,1-3.72-3,9.29,9.29,0,0,1-2.37-4.44A8.54,8.54,0,0,1,183.83,29a11,11,0,0,1,10.34-1.36c.31.11.44-.37.14-.48Z"/>
                <path class="cls-4" d="M202.13,27.92c-.76-1.09-1.64-2.29-2.93-2.57l-.06,0a11.18,11.18,0,0,0-10.58,1.4c-2.91,2.3-4.24,6.6-2.68,10a11.27,11.27,0,0,0,1.61,2.43,12.05,12.05,0,0,0,4.7,3.71,8.93,8.93,0,0,0,10.41-3.64A10.46,10.46,0,0,0,202.13,27.92Zm-1,4.81A2.5,2.5,0,0,1,198.28,34a2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.54-1.51l.06,0c.63.17,1.41.29,1.75.9A2.25,2.25,0,0,1,201.15,32.73Z"/>
                <path class="cls-4" d="M243.65,32.24a5.13,5.13,0,0,0-3.1-.74.25.25,0,0,0,0,.5,4.61,4.61,0,0,1,2.85.67.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M240.55,31.25a11.79,11.79,0,0,0-8.81,3.9A9.23,9.23,0,0,0,230,43.92c1.12,3.34,4.56,5.45,7.82,6.33a8.7,8.7,0,0,0,8.7-2.56,9.62,9.62,0,0,0,1.37-10.87c-.14-.28-.57,0-.43.26a9.14,9.14,0,0,1-.65,9.49,8.33,8.33,0,0,1-8,3.39c-3.08-.55-6.71-2.54-8-5.5a8.66,8.66,0,0,1,.88-8.35,11.08,11.08,0,0,1,8.92-4.36c.32,0,.32-.51,0-.5Z"/>
                <path class="cls-4" d="M248.14,29.27c-1.09-.76-2.32-1.58-3.63-1.41h-.07a11.18,11.18,0,0,0-9.45,5c-2,3.16-1.72,7.66.9,10.28a11.4,11.4,0,0,0,2.35,1.73,12,12,0,0,0,5.69,1.87,8.92,8.92,0,0,0,8.52-7A10.47,10.47,0,0,0,248.14,29.27Zm.73,4.85a2.47,2.47,0,0,1-2.25,2.19,2.28,2.28,0,0,1-2.33-2c-.06-1.14.74-2.37,1.88-2.29h.06a2.52,2.52,0,0,1,2,.25A2.19,2.19,0,0,1,248.87,34.12Z"/>
                <path class="cls-4" d="M285.45,35.07A5.24,5.24,0,0,0,284,38a.25.25,0,0,0,.5,0,4.7,4.7,0,0,1,1.34-2.55c.22-.23-.13-.58-.35-.35Z"/>
                <path class="cls-4" d="M283.73,37.84a11.74,11.74,0,0,0,1.67,9.52,9.16,9.16,0,0,0,8.26,3.76c3.47-.35,6.37-3.24,7.94-6.21a8.71,8.71,0,0,0-.58-9,9.62,9.62,0,0,0-10.09-3.8c-.32.06-.18.54.13.48a9.15,9.15,0,0,1,9,2.91,8.34,8.34,0,0,1,1.5,8.3,12.81,12.81,0,0,1-3,4.22,8.43,8.43,0,0,1-4.15,2.51,8.61,8.61,0,0,1-8.1-2.69A11.09,11.09,0,0,1,284.21,38c.07-.31-.41-.44-.48-.13Z"/>
                <path class="cls-4" d="M283.65,30c-1,.87-2.1,1.87-2.25,3.19l0,.06a11.2,11.2,0,0,0,2.51,10.37,8.58,8.58,0,0,0,10.2,1.62,11.73,11.73,0,0,0,2.25-1.85,12.12,12.12,0,0,0,3.19-5.07,8.92,8.92,0,0,0-4.71-10A10.47,10.47,0,0,0,283.65,30Zm4.89.47a2.5,2.5,0,0,1,1.58,2.72A2.29,2.29,0,0,1,287.58,35a2,2,0,0,1-1.77-2.37l0-.06c.1-.65.14-1.43.71-1.83A2.21,2.21,0,0,1,288.54,30.47Z"/>
                <path class="cls-4" d="M271.21,29.15a5.14,5.14,0,0,0-2.67-1.76c-.31-.07-.44.41-.13.48a4.7,4.7,0,0,1,2.45,1.63.25.25,0,0,0,.35-.35Z"/>
                <path class="cls-4" d="M268.63,27.16a11.62,11.62,0,0,0-10.08.95,9.16,9.16,0,0,0-4.15,7.76c.08,3.46,2.75,6.68,5.56,8.47a8.71,8.71,0,0,0,8.73.29A9.6,9.6,0,0,0,273.64,35c0-.31-.52-.32-.49,0a9.21,9.21,0,0,1-3.65,8.59,8.38,8.38,0,0,1-8.41.85,12.17,12.17,0,0,1-3.72-3A9.21,9.21,0,0,1,255,36.92,8.54,8.54,0,0,1,258.16,29a11,11,0,0,1,10.34-1.36c.3.11.43-.37.13-.48Z"/>
                <path class="cls-4" d="M276.46,27.92c-.76-1.09-1.64-2.29-2.93-2.57l-.06,0a11.18,11.18,0,0,0-10.58,1.4c-2.91,2.3-4.24,6.6-2.68,10a10.91,10.91,0,0,0,1.61,2.43,12.05,12.05,0,0,0,4.7,3.71,8.93,8.93,0,0,0,10.41-3.64A10.46,10.46,0,0,0,276.46,27.92Zm-1,4.81A2.5,2.5,0,0,1,272.61,34a2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.54-1.51l.06,0c.63.17,1.41.29,1.75.9A2.25,2.25,0,0,1,275.48,32.73Z"/>
                <path class="cls-4" d="M21.2,32.24a5.15,5.15,0,0,0-3.1-.74.25.25,0,0,0,0,.5,4.63,4.63,0,0,1,2.85.67.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M18.1,31.25a11.78,11.78,0,0,0-8.81,3.9,9.23,9.23,0,0,0-1.73,8.77c1.12,3.34,4.55,5.45,7.82,6.33a8.7,8.7,0,0,0,8.7-2.56,9.62,9.62,0,0,0,1.37-10.87c-.14-.28-.57,0-.43.26a9.16,9.16,0,0,1-.65,9.49,8.33,8.33,0,0,1-8,3.39c-3.08-.55-6.7-2.54-8-5.5a8.68,8.68,0,0,1,.87-8.35,11.09,11.09,0,0,1,8.93-4.36c.32,0,.32-.51,0-.5Z"/>
                <path class="cls-4" d="M25.69,29.27c-1.09-.76-2.32-1.58-3.64-1.41H22a11.18,11.18,0,0,0-9.44,5,8.56,8.56,0,0,0,.9,10.28,11.34,11.34,0,0,0,2.34,1.73,12.11,12.11,0,0,0,5.69,1.87,8.92,8.92,0,0,0,8.53-7A10.47,10.47,0,0,0,25.69,29.27Zm.73,4.85a2.47,2.47,0,0,1-2.26,2.19,2.27,2.27,0,0,1-2.32-2c-.07-1.14.74-2.37,1.88-2.29h.06a2.52,2.52,0,0,1,1.95.25A2.22,2.22,0,0,1,26.42,34.12Z"/>
                <path class="cls-4" d="M63,35.07A5.12,5.12,0,0,0,61.51,38c0,.13.13.25.25.25A.26.26,0,0,0,62,38a4.63,4.63,0,0,1,1.34-2.55c.22-.23-.14-.58-.35-.35Z"/>
                <path class="cls-4" d="M61.28,37.84a11.77,11.77,0,0,0,1.66,9.52,9.19,9.19,0,0,0,8.27,3.76c3.47-.35,6.37-3.24,7.94-6.21a8.71,8.71,0,0,0-.58-9,9.63,9.63,0,0,0-10.1-3.8c-.31.06-.18.54.14.48a9.15,9.15,0,0,1,9,2.91,8.34,8.34,0,0,1,1.5,8.3,13,13,0,0,1-3,4.22A8.42,8.42,0,0,1,72,50.49a8.58,8.58,0,0,1-8.09-2.69A11,11,0,0,1,61.76,38c.07-.31-.41-.44-.48-.13Z"/>
                <path class="cls-4" d="M61.2,30c-1,.87-2.1,1.87-2.25,3.19l0,.06a11.2,11.2,0,0,0,2.51,10.37,8.58,8.58,0,0,0,10.2,1.62,11.67,11.67,0,0,0,2.24-1.85,12.14,12.14,0,0,0,3.2-5.07,9,9,0,0,0-4.71-10A10.47,10.47,0,0,0,61.2,30Zm4.89.47a2.48,2.48,0,0,1,1.57,2.72A2.28,2.28,0,0,1,65.12,35a2,2,0,0,1-1.76-2.37v-.06c.11-.65.14-1.43.72-1.83A2.21,2.21,0,0,1,66.09,30.47Z"/>
                <path class="cls-4" d="M48.76,29.15a5.18,5.18,0,0,0-2.67-1.76c-.31-.07-.44.41-.13.48a4.74,4.74,0,0,1,2.45,1.63.25.25,0,0,0,.35-.35Z"/>
                <path class="cls-4" d="M46.18,27.16a11.64,11.64,0,0,0-10.09.95A9.18,9.18,0,0,0,32,35.87c.08,3.46,2.75,6.68,5.56,8.47a8.69,8.69,0,0,0,8.72.29,9.59,9.59,0,0,0,5-9.66c0-.31-.53-.32-.5,0a9.21,9.21,0,0,1-3.64,8.59,8.39,8.39,0,0,1-8.42.85,12.13,12.13,0,0,1-3.71-3,9.23,9.23,0,0,1-2.38-4.44A8.59,8.59,0,0,1,35.7,29a11,11,0,0,1,10.35-1.36c.3.11.43-.37.13-.48Z"/>
                <path class="cls-4" d="M54,27.92c-.75-1.09-1.63-2.29-2.92-2.57l-.07,0a11.16,11.16,0,0,0-10.57,1.4,8.6,8.6,0,0,0-2.69,10,11.27,11.27,0,0,0,1.61,2.43,12.2,12.2,0,0,0,4.7,3.71,8.93,8.93,0,0,0,10.41-3.64A10.44,10.44,0,0,0,54,27.92Zm-1,4.81A2.5,2.5,0,0,1,50.16,34a2.29,2.29,0,0,1-1.49-2.71,2,2,0,0,1,2.55-1.51l.06,0c.63.17,1.41.29,1.74.9A2.24,2.24,0,0,1,53,32.73Z"/>
                <path class="cls-4" d="M95.53,32.24a5.16,5.16,0,0,0-3.11-.74.25.25,0,0,0,0,.5,4.64,4.64,0,0,1,2.86.67.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M92.43,31.25a11.78,11.78,0,0,0-8.81,3.9,9.2,9.2,0,0,0-1.73,8.77c1.12,3.34,4.55,5.45,7.82,6.33a8.7,8.7,0,0,0,8.7-2.56,9.62,9.62,0,0,0,1.37-10.87c-.14-.28-.57,0-.43.26a9.16,9.16,0,0,1-.65,9.49,8.33,8.33,0,0,1-8,3.39c-3.08-.55-6.7-2.54-8-5.5a8.66,8.66,0,0,1,.87-8.35,11.09,11.09,0,0,1,8.93-4.36c.32,0,.32-.51,0-.5Z"/>
                <path class="cls-4" d="M100,29.27c-1.09-.76-2.32-1.58-3.64-1.41h-.07a11.18,11.18,0,0,0-9.44,5,8.56,8.56,0,0,0,.9,10.28,11.34,11.34,0,0,0,2.34,1.73,12.08,12.08,0,0,0,5.69,1.87,8.92,8.92,0,0,0,8.53-7A10.48,10.48,0,0,0,100,29.27Zm.73,4.85a2.49,2.49,0,0,1-2.26,2.19,2.27,2.27,0,0,1-2.32-2c-.07-1.14.74-2.37,1.87-2.29h.07c.64-.06,1.42-.21,1.94.25A2.2,2.2,0,0,1,100.75,34.12Z"/>
                <path class="cls-4" d="M137.32,35.07a5.16,5.16,0,0,0-1.48,2.9c0,.13.13.25.25.25a.26.26,0,0,0,.25-.25,4.57,4.57,0,0,1,1.34-2.55c.21-.23-.14-.58-.36-.35Z"/>
                <path class="cls-4" d="M135.61,37.84a11.77,11.77,0,0,0,1.66,9.52,9.19,9.19,0,0,0,8.27,3.76c3.47-.35,6.37-3.24,7.94-6.21a8.69,8.69,0,0,0-.59-9,9.61,9.61,0,0,0-10.09-3.8c-.31.06-.18.54.13.48a9.17,9.17,0,0,1,9,2.91,8.34,8.34,0,0,1,1.5,8.3,13,13,0,0,1-3,4.22,8.42,8.42,0,0,1-4.16,2.51,8.58,8.58,0,0,1-8.09-2.69A11,11,0,0,1,136.09,38c.07-.31-.41-.44-.48-.13Z"/>
                <path class="cls-4" d="M135.53,30c-1,.87-2.1,1.87-2.25,3.19l0,.06a11.2,11.2,0,0,0,2.51,10.37A8.58,8.58,0,0,0,146,45.24a11.67,11.67,0,0,0,2.24-1.85,12.14,12.14,0,0,0,3.2-5.07,9,9,0,0,0-4.71-10A10.47,10.47,0,0,0,135.53,30Zm4.89.47A2.48,2.48,0,0,1,142,33.19,2.28,2.28,0,0,1,139.45,35a2,2,0,0,1-1.76-2.37v-.06c.11-.65.14-1.43.72-1.83A2.21,2.21,0,0,1,140.42,30.47Z"/>
                <path class="cls-4" d="M123.09,29.15a5.18,5.18,0,0,0-2.67-1.76c-.31-.07-.44.41-.13.48a4.74,4.74,0,0,1,2.45,1.63.25.25,0,0,0,.35,0,.26.26,0,0,0,0-.35Z"/>
                <path class="cls-4" d="M120.51,27.16a11.64,11.64,0,0,0-10.09.95,9.18,9.18,0,0,0-4.14,7.76c.08,3.46,2.75,6.68,5.56,8.47a8.69,8.69,0,0,0,8.72.29,9.59,9.59,0,0,0,5-9.66c0-.31-.53-.32-.5,0a9.21,9.21,0,0,1-3.64,8.59,8.39,8.39,0,0,1-8.42.85,12.13,12.13,0,0,1-3.71-3,9.23,9.23,0,0,1-2.38-4.44A8.59,8.59,0,0,1,110,29a11,11,0,0,1,10.35-1.36c.3.11.43-.37.13-.48Z"/>
                <path class="cls-4" d="M128.33,27.92c-.76-1.09-1.63-2.29-2.92-2.57l-.07,0a11.16,11.16,0,0,0-10.57,1.4c-2.92,2.3-4.25,6.6-2.69,10a11.27,11.27,0,0,0,1.61,2.43,12.12,12.12,0,0,0,4.7,3.71,8.93,8.93,0,0,0,10.41-3.64A10.44,10.44,0,0,0,128.33,27.92Zm-1,4.81A2.5,2.5,0,0,1,124.49,34,2.28,2.28,0,0,1,123,31.3a2,2,0,0,1,2.55-1.51l.06,0c.62.17,1.41.29,1.74.9A2.24,2.24,0,0,1,127.36,32.73Z"/>
                <path class="cls-4" d="M462.42,7.64a5.16,5.16,0,0,0-3.11-.74.25.25,0,0,0,0,.5,4.71,4.71,0,0,1,2.86.67.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M459.32,6.65a11.75,11.75,0,0,0-8.81,3.91,9.18,9.18,0,0,0-1.73,8.76c1.12,3.34,4.55,5.45,7.81,6.33a8.71,8.71,0,0,0,8.71-2.56,9.62,9.62,0,0,0,1.37-10.86c-.14-.29-.57,0-.43.25a9.16,9.16,0,0,1-.66,9.49,8.31,8.31,0,0,1-8,3.39c-3.08-.55-6.7-2.54-8-5.5a8.66,8.66,0,0,1,.87-8.35,11.09,11.09,0,0,1,8.93-4.36.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M466.9,4.67c-1.08-.76-2.31-1.58-3.63-1.41h-.07a11.21,11.21,0,0,0-9.45,5,8.6,8.6,0,0,0,.91,10.29A11,11,0,0,0,457,20.22a11.93,11.93,0,0,0,5.69,1.87,8.92,8.92,0,0,0,8.53-7A10.48,10.48,0,0,0,466.9,4.67Zm.74,4.86a2.51,2.51,0,0,1-2.26,2.19,2.29,2.29,0,0,1-2.32-2c-.07-1.14.73-2.37,1.87-2.29H465c.64,0,1.42-.21,1.94.25A2.22,2.22,0,0,1,467.64,9.53Z"/>
                <path class="cls-4" d="M504.21,10.48a5.14,5.14,0,0,0-1.48,2.89c0,.13.12.25.25.25a.26.26,0,0,0,.25-.25,4.56,4.56,0,0,1,1.33-2.54c.22-.24-.13-.59-.35-.35Z"/>
                <path class="cls-4" d="M502.5,13.24a11.73,11.73,0,0,0,1.66,9.52,9.22,9.22,0,0,0,8.27,3.77c3.47-.36,6.37-3.25,7.94-6.22a8.69,8.69,0,0,0-.59-9,9.61,9.61,0,0,0-10.09-3.8c-.31.06-.18.54.13.48a9.17,9.17,0,0,1,9,2.91,8.35,8.35,0,0,1,1.5,8.31,13,13,0,0,1-3,4.21,8,8,0,0,1-12.25-.18A11,11,0,0,1,503,13.37c.07-.31-.41-.44-.48-.13Z"/>
                <path class="cls-4" d="M502.42,5.41c-1,.87-2.1,1.86-2.25,3.18l0,.06A11.2,11.2,0,0,0,502.66,19c2.59,2.66,7,3.53,10.19,1.62a11.43,11.43,0,0,0,2.25-1.85,12,12,0,0,0,3.19-5.07,8.92,8.92,0,0,0-4.71-10A10.48,10.48,0,0,0,502.42,5.41Zm4.88.46a2.48,2.48,0,0,1,1.58,2.72,2.28,2.28,0,0,1-2.54,1.76c-1.12-.21-2.12-1.29-1.76-2.37V7.92c.11-.64.14-1.43.71-1.83A2.22,2.22,0,0,1,507.3,5.87Z"/>
                <path class="cls-4" d="M490,4.55a5.11,5.11,0,0,0-2.67-1.75.25.25,0,0,0-.13.48,4.66,4.66,0,0,1,2.44,1.62.25.25,0,0,0,.36-.35Z"/>
                <path class="cls-4" d="M487.4,2.56a11.67,11.67,0,0,0-10.09.95,9.18,9.18,0,0,0-4.14,7.76c.08,3.46,2.75,6.68,5.56,8.48a8.71,8.71,0,0,0,8.72.28,9.59,9.59,0,0,0,5-9.66c0-.31-.53-.31-.5,0A9.2,9.2,0,0,1,488.27,19a8.39,8.39,0,0,1-8.42.85,12.13,12.13,0,0,1-3.71-3,9.23,9.23,0,0,1-2.38-4.44,8.59,8.59,0,0,1,3.16-7.92A11,11,0,0,1,487.27,3c.3.11.43-.37.13-.48Z"/>
                <path class="cls-4" d="M495.22,3.32c-.76-1.09-1.63-2.29-2.93-2.57l-.06,0a11.19,11.19,0,0,0-10.57,1.4c-2.92,2.3-4.25,6.6-2.69,10a11.27,11.27,0,0,0,1.61,2.43,12,12,0,0,0,4.7,3.71,8.92,8.92,0,0,0,10.41-3.63A10.46,10.46,0,0,0,495.22,3.32Zm-1,4.81a2.5,2.5,0,0,1-2.88,1.28,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.55-1.5l.06,0c.62.17,1.4.29,1.74.9A2.22,2.22,0,0,1,494.25,8.13Z"/>
                <path class="cls-4" d="M536.75,7.64a5.16,5.16,0,0,0-3.11-.74.25.25,0,0,0,0,.5,4.73,4.73,0,0,1,2.86.67.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M533.65,6.65a11.75,11.75,0,0,0-8.81,3.91,9.18,9.18,0,0,0-1.73,8.76c1.12,3.34,4.55,5.45,7.81,6.33a8.68,8.68,0,0,0,8.7-2.56A9.59,9.59,0,0,0,541,12.23c-.13-.29-.56,0-.42.25a9.16,9.16,0,0,1-.66,9.49,8.31,8.31,0,0,1-8,3.39c-3.08-.55-6.71-2.54-8-5.5a8.66,8.66,0,0,1,.87-8.35,11.09,11.09,0,0,1,8.93-4.36.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M541.23,4.67c-1.08-.76-2.32-1.58-3.63-1.41h-.07a11.21,11.21,0,0,0-9.45,5A8.58,8.58,0,0,0,529,18.5a11,11,0,0,0,2.34,1.72A11.93,11.93,0,0,0,537,22.09a8.93,8.93,0,0,0,8.53-7A10.48,10.48,0,0,0,541.23,4.67ZM542,9.53a2.51,2.51,0,0,1-2.26,2.19,2.29,2.29,0,0,1-2.32-2c-.07-1.14.73-2.37,1.87-2.29h.07c.64,0,1.42-.21,1.94.25A2.22,2.22,0,0,1,542,9.53Z"/>
                <path class="cls-4" d="M578.54,10.48a5.14,5.14,0,0,0-1.48,2.89c0,.13.12.25.25.25a.27.27,0,0,0,.25-.25,4.56,4.56,0,0,1,1.33-2.54c.22-.24-.13-.59-.35-.35Z"/>
                <path class="cls-4" d="M576.83,13.24a11.73,11.73,0,0,0,1.66,9.52,9.22,9.22,0,0,0,8.27,3.77c3.47-.36,6.37-3.25,7.94-6.22a8.69,8.69,0,0,0-.59-9A9.61,9.61,0,0,0,584,7.47c-.31.06-.18.54.13.48a9.17,9.17,0,0,1,9,2.91,8.35,8.35,0,0,1,1.5,8.31,13,13,0,0,1-3,4.21,8.55,8.55,0,0,1-4.16,2.52,8.64,8.64,0,0,1-8.1-2.7,11.08,11.08,0,0,1-2.17-9.83c.08-.31-.4-.44-.47-.13Z"/>
                <path class="cls-4" d="M576.75,5.41c-1,.87-2.1,1.86-2.25,3.18l0,.06A11.15,11.15,0,0,0,577,19c2.59,2.66,7,3.53,10.19,1.62a11.43,11.43,0,0,0,2.25-1.85,12,12,0,0,0,3.19-5.07,8.92,8.92,0,0,0-4.71-10A10.48,10.48,0,0,0,576.75,5.41Zm4.88.46a2.49,2.49,0,0,1,1.58,2.72,2.28,2.28,0,0,1-2.54,1.76c-1.12-.21-2.12-1.29-1.76-2.37V7.92c.11-.64.14-1.43.71-1.83A2.21,2.21,0,0,1,581.63,5.87Z"/>
                <path class="cls-4" d="M564.3,4.55a5,5,0,0,0-2.66-1.75c-.31-.07-.45.4-.13.48A4.66,4.66,0,0,1,564,4.9a.25.25,0,0,0,.35,0,.24.24,0,0,0,0-.35Z"/>
                <path class="cls-4" d="M561.73,2.56a11.67,11.67,0,0,0-10.09.95,9.18,9.18,0,0,0-4.14,7.76c.07,3.46,2.75,6.68,5.56,8.48a8.71,8.71,0,0,0,8.72.28,9.59,9.59,0,0,0,5-9.66c0-.31-.53-.31-.5,0A9.2,9.2,0,0,1,562.6,19a8.39,8.39,0,0,1-8.42.85,12.13,12.13,0,0,1-3.71-3,9.23,9.23,0,0,1-2.38-4.44,8.59,8.59,0,0,1,3.16-7.92A11,11,0,0,1,561.6,3c.3.11.43-.37.13-.48Z"/>
                <path class="cls-4" d="M569.55,3.32c-.76-1.09-1.63-2.29-2.93-2.57l-.06,0A11.19,11.19,0,0,0,556,2.12c-2.92,2.3-4.25,6.6-2.69,10a11.27,11.27,0,0,0,1.61,2.43,12,12,0,0,0,4.7,3.71A8.92,8.92,0,0,0,570,14.6,10.48,10.48,0,0,0,569.55,3.32Zm-1,4.81a2.5,2.5,0,0,1-2.88,1.28,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.55-1.5l.06,0c.62.17,1.4.29,1.74.9A2.22,2.22,0,0,1,568.58,8.13Z"/>
                <path class="cls-4" d="M314.3,7.64a5.18,5.18,0,0,0-3.11-.74.25.25,0,0,0,0,.5,4.73,4.73,0,0,1,2.86.67.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M311.19,6.65a11.77,11.77,0,0,0-8.81,3.91,9.21,9.21,0,0,0-1.72,8.76c1.11,3.34,4.55,5.45,7.81,6.33a8.69,8.69,0,0,0,8.7-2.56,9.61,9.61,0,0,0,1.37-10.86c-.14-.29-.56,0-.43.25a9.14,9.14,0,0,1-.65,9.49,8.32,8.32,0,0,1-8,3.39c-3.08-.55-6.71-2.54-8-5.5a8.66,8.66,0,0,1,.88-8.35,11.08,11.08,0,0,1,8.92-4.36.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M318.78,4.67c-1.09-.76-2.32-1.58-3.63-1.41h-.07a11.19,11.19,0,0,0-9.45,5,8.6,8.6,0,0,0,.9,10.29,11.36,11.36,0,0,0,2.35,1.72,11.93,11.93,0,0,0,5.69,1.87,8.93,8.93,0,0,0,8.53-7A10.5,10.5,0,0,0,318.78,4.67Zm.74,4.86a2.51,2.51,0,0,1-2.26,2.19,2.3,2.3,0,0,1-2.33-2c-.06-1.14.74-2.37,1.88-2.29h.06c.65,0,1.42-.21,1.95.25A2.22,2.22,0,0,1,319.52,9.53Z"/>
                <path class="cls-4" d="M356.09,10.48a5.14,5.14,0,0,0-1.48,2.89.25.25,0,0,0,.24.25.25.25,0,0,0,.25-.25,4.63,4.63,0,0,1,1.34-2.54c.22-.24-.13-.59-.35-.35Z"/>
                <path class="cls-4" d="M354.37,13.24A11.73,11.73,0,0,0,356,22.76a9.2,9.2,0,0,0,8.26,3.77c3.48-.36,6.37-3.25,7.95-6.22a8.71,8.71,0,0,0-.59-9,9.61,9.61,0,0,0-10.09-3.8c-.32.06-.18.54.13.48a9.15,9.15,0,0,1,9,2.91,8.33,8.33,0,0,1,1.5,8.31,12.84,12.84,0,0,1-3,4.21A8,8,0,0,1,357,23.2a11.08,11.08,0,0,1-2.17-9.83.25.25,0,0,0-.48-.13Z"/>
                <path class="cls-4" d="M354.3,5.41c-1,.87-2.1,1.86-2.25,3.18l0,.06A11.2,11.2,0,0,0,354.53,19a8.58,8.58,0,0,0,10.2,1.62A11.43,11.43,0,0,0,367,18.79a12,12,0,0,0,3.19-5.07,8.92,8.92,0,0,0-4.71-10A10.48,10.48,0,0,0,354.3,5.41Zm4.88.46a2.5,2.5,0,0,1,1.58,2.72,2.29,2.29,0,0,1-2.54,1.76c-1.12-.21-2.12-1.29-1.77-2.37l0-.06c.1-.64.14-1.43.71-1.83A2.21,2.21,0,0,1,359.18,5.87Z"/>
                <path class="cls-4" d="M341.85,4.55a5.08,5.08,0,0,0-2.66-1.75c-.32-.07-.45.4-.14.48A4.69,4.69,0,0,1,341.5,4.9a.25.25,0,0,0,.35-.35Z"/>
                <path class="cls-4" d="M339.28,2.56a11.67,11.67,0,0,0-10.09.95A9.16,9.16,0,0,0,325,11.27c.08,3.46,2.76,6.68,5.57,8.48a8.71,8.71,0,0,0,8.72.28,9.57,9.57,0,0,0,4.95-9.66c0-.31-.52-.31-.49,0A9.18,9.18,0,0,1,340.14,19a8.38,8.38,0,0,1-8.41.85,12.29,12.29,0,0,1-3.72-3,9.29,9.29,0,0,1-2.37-4.44A8.54,8.54,0,0,1,328.8,4.4,11,11,0,0,1,339.14,3c.31.11.44-.37.14-.48Z"/>
                <path class="cls-4" d="M347.1,3.32c-.76-1.09-1.63-2.29-2.93-2.57l-.06,0a11.21,11.21,0,0,0-10.58,1.4c-2.91,2.3-4.24,6.6-2.68,10a11.27,11.27,0,0,0,1.61,2.43,12,12,0,0,0,4.7,3.71,8.92,8.92,0,0,0,10.41-3.63A10.48,10.48,0,0,0,347.1,3.32Zm-1,4.81a2.49,2.49,0,0,1-2.87,1.28,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.54-1.5l.06,0c.63.17,1.41.29,1.75.9A2.22,2.22,0,0,1,346.12,8.13Z"/>
                <path class="cls-4" d="M388.63,7.64a5.18,5.18,0,0,0-3.11-.74.26.26,0,0,0-.25.25.25.25,0,0,0,.25.25,4.68,4.68,0,0,1,2.85.67c.28.16.54-.27.26-.43Z"/>
                <path class="cls-4" d="M385.52,6.65a11.77,11.77,0,0,0-8.81,3.91A9.23,9.23,0,0,0,375,19.32c1.12,3.34,4.56,5.45,7.82,6.33a8.69,8.69,0,0,0,8.7-2.56,9.61,9.61,0,0,0,1.37-10.86c-.14-.29-.56,0-.43.25a9.14,9.14,0,0,1-.65,9.49,8.33,8.33,0,0,1-8,3.39c-3.08-.55-6.71-2.54-8-5.5a8.66,8.66,0,0,1,.88-8.35,11.08,11.08,0,0,1,8.92-4.36.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M393.11,4.67c-1.09-.76-2.32-1.58-3.63-1.41h-.07a11.18,11.18,0,0,0-9.45,5,8.6,8.6,0,0,0,.9,10.29,11.36,11.36,0,0,0,2.35,1.72,11.93,11.93,0,0,0,5.69,1.87,8.91,8.91,0,0,0,8.52-7A10.47,10.47,0,0,0,393.11,4.67Zm.74,4.86a2.51,2.51,0,0,1-2.26,2.19,2.3,2.3,0,0,1-2.33-2c-.06-1.14.74-2.37,1.88-2.29h.06c.65,0,1.42-.21,1.95.25A2.25,2.25,0,0,1,393.85,9.53Z"/>
                <path class="cls-4" d="M430.42,10.48a5.21,5.21,0,0,0-1.49,2.89.26.26,0,0,0,.25.25.25.25,0,0,0,.25-.25,4.63,4.63,0,0,1,1.34-2.54c.22-.24-.13-.59-.35-.35Z"/>
                <path class="cls-4" d="M428.7,13.24a11.73,11.73,0,0,0,1.67,9.52,9.19,9.19,0,0,0,8.26,3.77c3.48-.36,6.37-3.25,7.94-6.22a8.69,8.69,0,0,0-.58-9,9.61,9.61,0,0,0-10.09-3.8c-.32.06-.18.54.13.48a9.15,9.15,0,0,1,9,2.91,8.33,8.33,0,0,1,1.5,8.31,13,13,0,0,1-2.95,4.21,8,8,0,0,1-12.26-.18,11.08,11.08,0,0,1-2.17-9.83c.07-.31-.41-.44-.48-.13Z"/>
                <path class="cls-4" d="M428.63,5.41c-1,.87-2.11,1.86-2.25,3.18l0,.06A11.2,11.2,0,0,0,428.86,19a8.58,8.58,0,0,0,10.2,1.62,11.43,11.43,0,0,0,2.25-1.85,12,12,0,0,0,3.19-5.07,8.92,8.92,0,0,0-4.71-10A10.48,10.48,0,0,0,428.63,5.41Zm4.88.46a2.5,2.5,0,0,1,1.58,2.72,2.29,2.29,0,0,1-2.54,1.76c-1.12-.21-2.12-1.29-1.77-2.37l0-.06c.1-.64.14-1.43.71-1.83A2.21,2.21,0,0,1,433.51,5.87Z"/>
                <path class="cls-4" d="M416.18,4.55a5.08,5.08,0,0,0-2.66-1.75c-.32-.07-.45.4-.14.48a4.69,4.69,0,0,1,2.45,1.62.25.25,0,0,0,.35-.35Z"/>
                <path class="cls-4" d="M413.61,2.56a11.67,11.67,0,0,0-10.09.95,9.16,9.16,0,0,0-4.15,7.76c.08,3.46,2.75,6.68,5.57,8.48a8.71,8.71,0,0,0,8.72.28,9.59,9.59,0,0,0,4.95-9.66c0-.31-.52-.31-.49,0A9.2,9.2,0,0,1,414.47,19a8.38,8.38,0,0,1-8.41.85,12.29,12.29,0,0,1-3.72-3A9.29,9.29,0,0,1,400,12.32a8.54,8.54,0,0,1,3.16-7.92A11,11,0,0,1,413.47,3c.31.11.44-.37.14-.48Z"/>
                <path class="cls-4" d="M421.43,3.32C420.67,2.23,419.8,1,418.5.75l-.06,0a11.21,11.21,0,0,0-10.58,1.4c-2.91,2.3-4.24,6.6-2.68,10a11.27,11.27,0,0,0,1.61,2.43,12,12,0,0,0,4.7,3.71A8.92,8.92,0,0,0,421.9,14.6,10.48,10.48,0,0,0,421.43,3.32Zm-1,4.81a2.49,2.49,0,0,1-2.87,1.28A2.28,2.28,0,0,1,416.1,6.7a2,2,0,0,1,2.54-1.5l.06,0c.63.17,1.41.29,1.75.9A2.22,2.22,0,0,1,420.45,8.13Z"/>
                <path class="cls-4" d="M468.3,32.24a5.13,5.13,0,0,0-3.1-.74.25.25,0,0,0,0,.5,4.63,4.63,0,0,1,2.85.67.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M465.2,31.25a11.78,11.78,0,0,0-8.81,3.9,9.23,9.23,0,0,0-1.73,8.77c1.12,3.34,4.55,5.45,7.82,6.33a8.7,8.7,0,0,0,8.7-2.56,9.62,9.62,0,0,0,1.37-10.87c-.14-.28-.57,0-.43.26a9.16,9.16,0,0,1-.65,9.49A8.33,8.33,0,0,1,463.42,50c-3.08-.55-6.7-2.54-8-5.5a8.68,8.68,0,0,1,.87-8.35,11.09,11.09,0,0,1,8.93-4.36c.32,0,.32-.51,0-.5Z"/>
                <path class="cls-4" d="M472.79,29.27c-1.09-.76-2.32-1.58-3.64-1.41h-.07a11.18,11.18,0,0,0-9.44,5,8.56,8.56,0,0,0,.9,10.28,11.34,11.34,0,0,0,2.34,1.73,12.11,12.11,0,0,0,5.69,1.87,8.92,8.92,0,0,0,8.53-7A10.47,10.47,0,0,0,472.79,29.27Zm.73,4.85a2.47,2.47,0,0,1-2.26,2.19,2.27,2.27,0,0,1-2.32-2c-.07-1.14.74-2.37,1.88-2.29h.06a2.52,2.52,0,0,1,1.95.25A2.22,2.22,0,0,1,473.52,34.12Z"/>
                <path class="cls-4" d="M510.1,35.07a5.18,5.18,0,0,0-1.49,2.9.25.25,0,1,0,.5,0,4.7,4.7,0,0,1,1.34-2.55c.22-.23-.13-.58-.35-.35Z"/>
                <path class="cls-4" d="M508.38,37.84A11.77,11.77,0,0,0,510,47.36a9.19,9.19,0,0,0,8.27,3.76c3.47-.35,6.37-3.24,7.94-6.21a8.71,8.71,0,0,0-.58-9,9.63,9.63,0,0,0-10.1-3.8c-.31.06-.18.54.14.48a9.15,9.15,0,0,1,9,2.91,8.34,8.34,0,0,1,1.5,8.3,13,13,0,0,1-3,4.22,8.43,8.43,0,0,1-4.15,2.51A8.59,8.59,0,0,1,511,47.8,11.06,11.06,0,0,1,508.86,38c.07-.31-.41-.44-.48-.13Z"/>
                <path class="cls-4" d="M508.3,30c-1,.87-2.1,1.87-2.25,3.19l0,.06a11.2,11.2,0,0,0,2.51,10.37,8.58,8.58,0,0,0,10.2,1.62A11.67,11.67,0,0,0,521,43.39a12.14,12.14,0,0,0,3.2-5.07,9,9,0,0,0-4.71-10A10.47,10.47,0,0,0,508.3,30Zm4.89.47a2.48,2.48,0,0,1,1.57,2.72A2.28,2.28,0,0,1,512.22,35a2,2,0,0,1-1.76-2.37v-.06c.11-.65.15-1.43.72-1.83A2.21,2.21,0,0,1,513.19,30.47Z"/>
                <path class="cls-4" d="M495.86,29.15a5.18,5.18,0,0,0-2.67-1.76c-.31-.07-.44.41-.13.48a4.7,4.7,0,0,1,2.45,1.63.25.25,0,0,0,.35-.35Z"/>
                <path class="cls-4" d="M493.28,27.16a11.64,11.64,0,0,0-10.09.95,9.18,9.18,0,0,0-4.14,7.76c.08,3.46,2.75,6.68,5.56,8.47a8.69,8.69,0,0,0,8.72.29,9.59,9.59,0,0,0,5-9.66c0-.31-.53-.32-.5,0a9.21,9.21,0,0,1-3.64,8.59,8.38,8.38,0,0,1-8.41.85,12.05,12.05,0,0,1-3.72-3,9.23,9.23,0,0,1-2.38-4.44A8.59,8.59,0,0,1,482.8,29a11,11,0,0,1,10.35-1.36c.3.11.43-.37.13-.48Z"/>
                <path class="cls-4" d="M501.1,27.92c-.75-1.09-1.63-2.29-2.92-2.57l-.07,0a11.16,11.16,0,0,0-10.57,1.4,8.6,8.6,0,0,0-2.69,10,11.27,11.27,0,0,0,1.61,2.43,12.2,12.2,0,0,0,4.7,3.71,8.92,8.92,0,0,0,10.41-3.64A10.44,10.44,0,0,0,501.1,27.92Zm-1,4.81A2.5,2.5,0,0,1,497.26,34a2.29,2.29,0,0,1-1.49-2.71,2,2,0,0,1,2.55-1.51l.06,0c.63.17,1.41.29,1.74.9A2.24,2.24,0,0,1,500.13,32.73Z"/>
                <path class="cls-4" d="M542.63,32.24a5.16,5.16,0,0,0-3.11-.74.25.25,0,0,0,0,.5,4.64,4.64,0,0,1,2.86.67.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M539.53,31.25a11.78,11.78,0,0,0-8.81,3.9A9.2,9.2,0,0,0,529,43.92c1.12,3.34,4.55,5.45,7.82,6.33a8.7,8.7,0,0,0,8.7-2.56,9.62,9.62,0,0,0,1.37-10.87c-.14-.28-.57,0-.43.26a9.16,9.16,0,0,1-.65,9.49,8.33,8.33,0,0,1-8,3.39c-3.08-.55-6.7-2.54-8-5.5a8.68,8.68,0,0,1,.87-8.35,11.09,11.09,0,0,1,8.93-4.36c.32,0,.32-.51,0-.5Z"/>
                <path class="cls-4" d="M547.12,29.27c-1.09-.76-2.32-1.58-3.64-1.41h-.07a11.18,11.18,0,0,0-9.44,5,8.56,8.56,0,0,0,.9,10.28,11.34,11.34,0,0,0,2.34,1.73,12.11,12.11,0,0,0,5.69,1.87,8.92,8.92,0,0,0,8.53-7A10.47,10.47,0,0,0,547.12,29.27Zm.73,4.85a2.49,2.49,0,0,1-2.26,2.19,2.27,2.27,0,0,1-2.32-2c-.07-1.14.74-2.37,1.88-2.29h.06c.64-.06,1.42-.21,1.94.25A2.2,2.2,0,0,1,547.85,34.12Z"/>
                <path class="cls-4" d="M584.42,35.07a5.16,5.16,0,0,0-1.48,2.9c0,.13.13.25.25.25a.26.26,0,0,0,.25-.25,4.63,4.63,0,0,1,1.34-2.55c.22-.23-.14-.58-.36-.35Z"/>
                <path class="cls-4" d="M582.71,37.84a11.77,11.77,0,0,0,1.66,9.52,9.19,9.19,0,0,0,8.27,3.76c3.47-.35,6.37-3.24,7.94-6.21a8.71,8.71,0,0,0-.58-9,9.63,9.63,0,0,0-10.1-3.8c-.31.06-.18.54.13.48a9.16,9.16,0,0,1,9,2.91,8.34,8.34,0,0,1,1.5,8.3,13,13,0,0,1-3,4.22,8.42,8.42,0,0,1-4.16,2.51,8.58,8.58,0,0,1-8.09-2.69A11,11,0,0,1,583.19,38c.07-.31-.41-.44-.48-.13Z"/>
                <path class="cls-4" d="M582.63,30c-1,.87-2.1,1.87-2.25,3.19l0,.06a11.2,11.2,0,0,0,2.51,10.37,8.58,8.58,0,0,0,10.2,1.62,11.67,11.67,0,0,0,2.24-1.85,12.14,12.14,0,0,0,3.2-5.07,9,9,0,0,0-4.71-10A10.47,10.47,0,0,0,582.63,30Zm4.89.47a2.48,2.48,0,0,1,1.57,2.72A2.28,2.28,0,0,1,586.55,35a2,2,0,0,1-1.76-2.37v-.06c.11-.65.14-1.43.72-1.83A2.21,2.21,0,0,1,587.52,30.47Z"/>
                <path class="cls-4" d="M570.19,29.15a5.18,5.18,0,0,0-2.67-1.76c-.31-.07-.44.41-.13.48a4.74,4.74,0,0,1,2.45,1.63.25.25,0,0,0,.35,0,.26.26,0,0,0,0-.35Z"/>
                <path class="cls-4" d="M567.61,27.16a11.64,11.64,0,0,0-10.09.95,9.18,9.18,0,0,0-4.14,7.76c.08,3.46,2.75,6.68,5.56,8.47a8.69,8.69,0,0,0,8.72.29,9.59,9.59,0,0,0,5-9.66c0-.31-.53-.32-.5,0a9.21,9.21,0,0,1-3.64,8.59,8.39,8.39,0,0,1-8.42.85,12.13,12.13,0,0,1-3.71-3A9.23,9.23,0,0,1,554,36.92,8.59,8.59,0,0,1,557.13,29a11,11,0,0,1,10.35-1.36c.3.11.43-.37.13-.48Z"/>
                <path class="cls-4" d="M575.43,27.92c-.75-1.09-1.63-2.29-2.92-2.57l-.07,0a11.16,11.16,0,0,0-10.57,1.4c-2.92,2.3-4.25,6.6-2.69,10a11.27,11.27,0,0,0,1.61,2.43,12.2,12.2,0,0,0,4.7,3.71,8.93,8.93,0,0,0,10.41-3.64A10.44,10.44,0,0,0,575.43,27.92Zm-1,4.81A2.5,2.5,0,0,1,571.59,34a2.29,2.29,0,0,1-1.49-2.71,2,2,0,0,1,2.55-1.51l.06,0c.63.17,1.41.29,1.74.9A2.24,2.24,0,0,1,574.46,32.73Z"/>
                <path class="cls-4" d="M320.18,32.24a5.16,5.16,0,0,0-3.11-.74.25.25,0,0,0,0,.5,4.66,4.66,0,0,1,2.86.67.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M317.08,31.25a11.78,11.78,0,0,0-8.81,3.9,9.2,9.2,0,0,0-1.73,8.77c1.12,3.34,4.55,5.45,7.81,6.33a8.69,8.69,0,0,0,8.7-2.56,9.6,9.6,0,0,0,1.37-10.87c-.13-.28-.56,0-.42.26a9.16,9.16,0,0,1-.66,9.49,8.31,8.31,0,0,1-8,3.39c-3.08-.55-6.71-2.54-8-5.5a8.66,8.66,0,0,1,.87-8.35,11.09,11.09,0,0,1,8.93-4.36c.32,0,.32-.51,0-.5Z"/>
                <path class="cls-4" d="M324.66,29.27c-1.08-.76-2.32-1.58-3.63-1.41H321a11.19,11.19,0,0,0-9.45,5c-1.95,3.16-1.72,7.66.91,10.28a11.08,11.08,0,0,0,2.34,1.73,12,12,0,0,0,5.69,1.87,8.94,8.94,0,0,0,8.53-7A10.47,10.47,0,0,0,324.66,29.27Zm.74,4.85a2.49,2.49,0,0,1-2.26,2.19,2.26,2.26,0,0,1-2.32-2c-.07-1.14.73-2.37,1.87-2.29h.06c.65-.06,1.43-.21,1.95.25A2.2,2.2,0,0,1,325.4,34.12Z"/>
                <path class="cls-4" d="M362,35.07a5.16,5.16,0,0,0-1.48,2.9c0,.13.12.25.25.25A.25.25,0,0,0,361,38a4.7,4.7,0,0,1,1.34-2.55c.22-.23-.13-.58-.35-.35Z"/>
                <path class="cls-4" d="M360.25,37.84a11.78,11.78,0,0,0,1.67,9.52,9.19,9.19,0,0,0,8.27,3.76c3.47-.35,6.37-3.24,7.94-6.21a8.69,8.69,0,0,0-.59-9,9.61,9.61,0,0,0-10.09-3.8c-.31.06-.18.54.13.48a9.17,9.17,0,0,1,9,2.91,8.34,8.34,0,0,1,1.5,8.3,13,13,0,0,1-3,4.22A8.46,8.46,0,0,1,371,50.49a8.61,8.61,0,0,1-8.1-2.69A11.09,11.09,0,0,1,360.73,38a.25.25,0,0,0-.48-.13Z"/>
                <path class="cls-4" d="M360.18,30c-1,.87-2.1,1.87-2.25,3.19l0,.06a11.2,11.2,0,0,0,2.51,10.37c2.6,2.66,7,3.53,10.2,1.62a11.43,11.43,0,0,0,2.25-1.85,12,12,0,0,0,3.19-5.07,8.92,8.92,0,0,0-4.71-10A10.45,10.45,0,0,0,360.18,30Zm4.88.47a2.49,2.49,0,0,1,1.58,2.72A2.28,2.28,0,0,1,364.1,35c-1.12-.21-2.12-1.29-1.76-2.37v-.06c.1-.65.14-1.43.71-1.83A2.21,2.21,0,0,1,365.06,30.47Z"/>
                <path class="cls-4" d="M347.73,29.15a5.12,5.12,0,0,0-2.66-1.76c-.31-.07-.45.41-.13.48a4.68,4.68,0,0,1,2.44,1.63.25.25,0,0,0,.35,0,.24.24,0,0,0,0-.35Z"/>
                <path class="cls-4" d="M345.16,27.16a11.64,11.64,0,0,0-10.09.95,9.18,9.18,0,0,0-4.14,7.76c.07,3.46,2.75,6.68,5.56,8.47a8.69,8.69,0,0,0,8.72.29,9.59,9.59,0,0,0,5-9.66c0-.31-.53-.32-.5,0A9.21,9.21,0,0,1,346,43.56a8.39,8.39,0,0,1-8.42.85,12.13,12.13,0,0,1-3.71-3,9.23,9.23,0,0,1-2.38-4.44A8.59,8.59,0,0,1,334.68,29,11,11,0,0,1,345,27.64c.3.11.43-.37.13-.48Z"/>
                <path class="cls-4" d="M353,27.92c-.76-1.09-1.63-2.29-2.93-2.57l-.06,0a11.16,11.16,0,0,0-10.57,1.4c-2.92,2.3-4.25,6.6-2.69,10a11.27,11.27,0,0,0,1.61,2.43,12.12,12.12,0,0,0,4.7,3.71,8.93,8.93,0,0,0,10.41-3.64A10.46,10.46,0,0,0,353,27.92Zm-1,4.81A2.51,2.51,0,0,1,349.13,34a2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.55-1.51l.06,0c.62.17,1.4.29,1.74.9A2.24,2.24,0,0,1,352,32.73Z"/>
                <path class="cls-4" d="M394.51,32.24a5.16,5.16,0,0,0-3.11-.74.25.25,0,0,0,0,.5,4.66,4.66,0,0,1,2.86.67.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M391.41,31.25a11.78,11.78,0,0,0-8.81,3.9,9.2,9.2,0,0,0-1.73,8.77c1.11,3.34,4.55,5.45,7.81,6.33a8.69,8.69,0,0,0,8.7-2.56,9.6,9.6,0,0,0,1.37-10.87c-.13-.28-.56,0-.43.26a9.14,9.14,0,0,1-.65,9.49,8.32,8.32,0,0,1-8,3.39c-3.08-.55-6.71-2.54-8-5.5a8.66,8.66,0,0,1,.87-8.35,11.09,11.09,0,0,1,8.93-4.36c.32,0,.32-.51,0-.5Z"/>
                <path class="cls-4" d="M399,29.27c-1.08-.76-2.32-1.58-3.63-1.41h-.07a11.19,11.19,0,0,0-9.45,5c-1.95,3.16-1.72,7.66.9,10.28a11.4,11.4,0,0,0,2.35,1.73,12,12,0,0,0,5.69,1.87,8.94,8.94,0,0,0,8.53-7A10.47,10.47,0,0,0,399,29.27Zm.74,4.85a2.49,2.49,0,0,1-2.26,2.19,2.26,2.26,0,0,1-2.32-2c-.07-1.14.73-2.37,1.87-2.29h.06c.65-.06,1.43-.21,1.95.25A2.2,2.2,0,0,1,399.73,34.12Z"/>
                <path class="cls-4" d="M436.3,35.07a5.16,5.16,0,0,0-1.48,2.9c0,.13.12.25.25.25a.25.25,0,0,0,.24-.25,4.7,4.7,0,0,1,1.34-2.55c.22-.23-.13-.58-.35-.35Z"/>
                <path class="cls-4" d="M434.58,37.84a11.78,11.78,0,0,0,1.67,9.52,9.19,9.19,0,0,0,8.27,3.76c3.47-.35,6.36-3.24,7.94-6.21a8.71,8.71,0,0,0-.59-9,9.61,9.61,0,0,0-10.09-3.8c-.31.06-.18.54.13.48a9.17,9.17,0,0,1,9,2.91,8.34,8.34,0,0,1,1.5,8.3,13,13,0,0,1-3,4.22,8.46,8.46,0,0,1-4.16,2.51,8.61,8.61,0,0,1-8.1-2.69A11.09,11.09,0,0,1,435.06,38a.25.25,0,0,0-.48-.13Z"/>
                <path class="cls-4" d="M434.51,30c-1,.87-2.1,1.87-2.25,3.19l0,.06a11.2,11.2,0,0,0,2.51,10.37c2.59,2.66,7,3.53,10.2,1.62a11.43,11.43,0,0,0,2.25-1.85,12,12,0,0,0,3.19-5.07,8.92,8.92,0,0,0-4.71-10A10.45,10.45,0,0,0,434.51,30Zm4.88.47A2.49,2.49,0,0,1,441,33.19,2.28,2.28,0,0,1,438.43,35c-1.12-.21-2.12-1.29-1.76-2.37v-.06c.1-.65.14-1.43.71-1.83A2.21,2.21,0,0,1,439.39,30.47Z"/>
                <path class="cls-4" d="M422.06,29.15a5.12,5.12,0,0,0-2.66-1.76c-.31-.07-.45.41-.13.48a4.68,4.68,0,0,1,2.44,1.63.25.25,0,0,0,.35-.35Z"/>
                <path class="cls-4" d="M419.49,27.16a11.64,11.64,0,0,0-10.09.95,9.18,9.18,0,0,0-4.14,7.76c.07,3.46,2.75,6.68,5.56,8.47a8.69,8.69,0,0,0,8.72.29,9.59,9.59,0,0,0,5-9.66c0-.31-.53-.32-.5,0a9.19,9.19,0,0,1-3.65,8.59,8.38,8.38,0,0,1-8.41.85,12.13,12.13,0,0,1-3.71-3,9.23,9.23,0,0,1-2.38-4.44A8.59,8.59,0,0,1,409,29a11,11,0,0,1,10.35-1.36c.3.11.43-.37.13-.48Z"/>
                <path class="cls-4" d="M427.31,27.92c-.76-1.09-1.63-2.29-2.93-2.57l-.06,0a11.16,11.16,0,0,0-10.57,1.4c-2.92,2.3-4.25,6.6-2.69,10a11.27,11.27,0,0,0,1.61,2.43,12.12,12.12,0,0,0,4.7,3.71,8.93,8.93,0,0,0,10.41-3.64A10.46,10.46,0,0,0,427.31,27.92Zm-1,4.81A2.5,2.5,0,0,1,423.46,34,2.28,2.28,0,0,1,422,31.3a2,2,0,0,1,2.55-1.51l.06,0c.62.17,1.4.29,1.74.9A2.21,2.21,0,0,1,426.33,32.73Z"/>
                <path class="cls-4" d="M163.44,58.06a5.06,5.06,0,0,0-3.1-.74.25.25,0,0,0-.25.24.24.24,0,0,0,.25.25,4.69,4.69,0,0,1,2.85.68c.28.15.53-.28.25-.43Z"/>
                <path class="cls-4" d="M160.34,57.07a11.76,11.76,0,0,0-8.81,3.9,9.23,9.23,0,0,0-1.73,8.77c1.12,3.34,4.55,5.45,7.82,6.33a8.72,8.72,0,0,0,8.7-2.56,9.63,9.63,0,0,0,1.37-10.87c-.14-.29-.57,0-.43.25a9.18,9.18,0,0,1-.65,9.5,8.3,8.3,0,0,1-8,3.38c-3.09-.55-6.71-2.54-8-5.5a8.66,8.66,0,0,1,.87-8.34,11.11,11.11,0,0,1,8.93-4.37.25.25,0,0,0,0-.49Z"/>
                <path class="cls-4" d="M167.93,55.09c-1.09-.76-2.32-1.59-3.64-1.41h-.06a11.2,11.2,0,0,0-9.45,4.95,8.6,8.6,0,0,0,.9,10.29A11.14,11.14,0,0,0,158,70.64a12,12,0,0,0,5.68,1.87,8.92,8.92,0,0,0,8.53-7A10.47,10.47,0,0,0,167.93,55.09Zm.73,4.85a2.47,2.47,0,0,1-2.25,2.19,2.27,2.27,0,0,1-2.33-2c-.07-1.14.74-2.37,1.88-2.29H166a2.57,2.57,0,0,1,1.95.24A2.25,2.25,0,0,1,168.66,59.94Z"/>
                <path class="cls-4" d="M205.24,60.89a5.24,5.24,0,0,0-1.49,2.9.25.25,0,0,0,.5,0,4.7,4.7,0,0,1,1.34-2.55c.22-.23-.13-.58-.35-.35Z"/>
                <path class="cls-4" d="M203.52,63.65a11.76,11.76,0,0,0,1.67,9.53,9.16,9.16,0,0,0,8.26,3.76c3.47-.35,6.37-3.24,7.94-6.21a8.71,8.71,0,0,0-.58-9,9.63,9.63,0,0,0-10.1-3.8c-.31.06-.18.54.14.47a9.16,9.16,0,0,1,9,2.92,8.34,8.34,0,0,1,1.5,8.3,12.81,12.81,0,0,1-3,4.21,8.38,8.38,0,0,1-4.15,2.52,8.62,8.62,0,0,1-8.1-2.7A11,11,0,0,1,204,63.79c.07-.31-.41-.45-.48-.14Z"/>
                <path class="cls-4" d="M203.44,55.82c-1,.87-2.1,1.87-2.25,3.18l0,.07a11.21,11.21,0,0,0,2.51,10.37,8.59,8.59,0,0,0,10.2,1.62,11.36,11.36,0,0,0,2.24-1.86,12.11,12.11,0,0,0,3.2-5.06,8.93,8.93,0,0,0-4.71-10A10.47,10.47,0,0,0,203.44,55.82Zm4.89.47A2.5,2.5,0,0,1,209.91,59a2.29,2.29,0,0,1-2.54,1.76,2,2,0,0,1-1.77-2.38l0-.06c.1-.64.14-1.43.71-1.83A2.21,2.21,0,0,1,208.33,56.29Z"/>
                <path class="cls-4" d="M191,55a5.14,5.14,0,0,0-2.67-1.76c-.31-.07-.44.41-.13.48a4.7,4.7,0,0,1,2.45,1.63A.25.25,0,0,0,191,55Z"/>
                <path class="cls-4" d="M188.42,53a11.64,11.64,0,0,0-10.09.94,9.21,9.21,0,0,0-4.14,7.77c.08,3.46,2.75,6.68,5.56,8.47a8.66,8.66,0,0,0,8.72.28,9.58,9.58,0,0,0,5-9.65c0-.32-.53-.32-.5,0a9.16,9.16,0,0,1-3.64,8.58,8.4,8.4,0,0,1-8.41.86,12.19,12.19,0,0,1-3.72-3,9.21,9.21,0,0,1-2.37-4.44A8.56,8.56,0,0,1,178,54.81a11,11,0,0,1,10.34-1.35c.3.1.43-.38.13-.48Z"/>
                <path class="cls-4" d="M196.25,53.74c-.76-1.09-1.64-2.29-2.93-2.58l-.06,0a11.21,11.21,0,0,0-10.58,1.41,8.58,8.58,0,0,0-2.68,10,11.1,11.1,0,0,0,1.6,2.43,12.15,12.15,0,0,0,4.71,3.71A8.94,8.94,0,0,0,196.72,65,10.48,10.48,0,0,0,196.25,53.74Zm-1,4.8a2.49,2.49,0,0,1-2.87,1.29,2.3,2.3,0,0,1-1.49-2.71,2,2,0,0,1,2.55-1.51l.06,0c.63.17,1.41.29,1.74.9A2.19,2.19,0,0,1,195.27,58.54Z"/>
                <path class="cls-4" d="M237.77,58.06a5.06,5.06,0,0,0-3.1-.74.25.25,0,0,0-.25.24.24.24,0,0,0,.25.25,4.71,4.71,0,0,1,2.85.68c.28.15.53-.28.25-.43Z"/>
                <path class="cls-4" d="M234.67,57.07a11.74,11.74,0,0,0-8.81,3.9,9.23,9.23,0,0,0-1.73,8.77c1.12,3.34,4.55,5.45,7.82,6.33a8.72,8.72,0,0,0,8.7-2.56A9.63,9.63,0,0,0,242,62.64c-.14-.29-.57,0-.43.25a9.18,9.18,0,0,1-.65,9.5,8.31,8.31,0,0,1-8.05,3.38c-3.08-.55-6.7-2.54-8-5.5a8.66,8.66,0,0,1,.87-8.34,11.1,11.1,0,0,1,8.93-4.37.25.25,0,0,0,0-.49Z"/>
                <path class="cls-4" d="M242.26,55.09c-1.09-.76-2.32-1.59-3.64-1.41h-.07a11.2,11.2,0,0,0-9.44,4.95,8.58,8.58,0,0,0,.9,10.29,11.34,11.34,0,0,0,2.34,1.73A12.11,12.11,0,0,0,238,72.51a8.92,8.92,0,0,0,8.53-7A10.47,10.47,0,0,0,242.26,55.09Zm.73,4.85a2.47,2.47,0,0,1-2.26,2.19,2.27,2.27,0,0,1-2.32-2c-.07-1.14.74-2.37,1.88-2.29h.06a2.57,2.57,0,0,1,2,.24A2.25,2.25,0,0,1,243,59.94Z"/>
                <path class="cls-4" d="M279.57,60.89a5.18,5.18,0,0,0-1.49,2.9.25.25,0,0,0,.5,0,4.7,4.7,0,0,1,1.34-2.55c.22-.23-.13-.58-.35-.35Z"/>
                <path class="cls-4" d="M277.85,63.65a11.76,11.76,0,0,0,1.67,9.53,9.16,9.16,0,0,0,8.26,3.76c3.47-.35,6.37-3.24,7.94-6.21a8.71,8.71,0,0,0-.58-9,9.63,9.63,0,0,0-10.1-3.8c-.31.06-.18.54.14.47a9.16,9.16,0,0,1,9,2.92,8.34,8.34,0,0,1,1.5,8.3,12.81,12.81,0,0,1-3,4.21,8.38,8.38,0,0,1-4.15,2.52,8.62,8.62,0,0,1-8.1-2.7,11,11,0,0,1-2.17-9.82c.07-.31-.41-.45-.48-.14Z"/>
                <path class="cls-4" d="M277.77,55.82c-1,.87-2.1,1.87-2.25,3.18l0,.07A11.21,11.21,0,0,0,278,69.44a8.59,8.59,0,0,0,10.2,1.62,11.36,11.36,0,0,0,2.24-1.86,12.11,12.11,0,0,0,3.2-5.06,8.94,8.94,0,0,0-4.71-10A10.47,10.47,0,0,0,277.77,55.82Zm4.89.47A2.48,2.48,0,0,1,284.23,59a2.27,2.27,0,0,1-2.53,1.76,2,2,0,0,1-1.77-2.38v-.06c.11-.64.15-1.43.72-1.83A2.21,2.21,0,0,1,282.66,56.29Z"/>
                <path class="cls-4" d="M265.33,55a5.18,5.18,0,0,0-2.67-1.76c-.31-.07-.44.41-.13.48A4.7,4.7,0,0,1,265,55.32a.25.25,0,0,0,.35-.35Z"/>
                <path class="cls-4" d="M262.75,53a11.64,11.64,0,0,0-10.09.94,9.21,9.21,0,0,0-4.14,7.77c.08,3.46,2.75,6.68,5.56,8.47a8.66,8.66,0,0,0,8.72.28,9.58,9.58,0,0,0,5-9.65c0-.32-.53-.32-.5,0a9.18,9.18,0,0,1-3.64,8.58,8.4,8.4,0,0,1-8.41.86,12.19,12.19,0,0,1-3.72-3,9.32,9.32,0,0,1-2.38-4.44,8.61,8.61,0,0,1,3.16-7.93,11,11,0,0,1,10.35-1.35c.3.1.43-.38.13-.48Z"/>
                <path class="cls-4" d="M270.57,53.74c-.75-1.09-1.63-2.29-2.92-2.58l-.07,0A11.19,11.19,0,0,0,257,52.54a8.58,8.58,0,0,0-2.68,10,11.1,11.1,0,0,0,1.6,2.43,12.2,12.2,0,0,0,4.7,3.71A8.94,8.94,0,0,0,271,65,10.44,10.44,0,0,0,270.57,53.74Zm-1,4.8a2.49,2.49,0,0,1-2.87,1.29,2.3,2.3,0,0,1-1.49-2.71,2,2,0,0,1,2.55-1.51l.06,0c.63.17,1.41.29,1.74.9A2.22,2.22,0,0,1,269.6,58.54Z"/>
                <path class="cls-4" d="M15.32,58.06a5.09,5.09,0,0,0-3.11-.74.25.25,0,0,0-.25.24c0,.13.12.27.25.25a4.72,4.72,0,0,1,2.86.68c.28.15.53-.28.25-.43Z"/>
                <path class="cls-4" d="M12.22,57.07A11.74,11.74,0,0,0,3.41,61a9.2,9.2,0,0,0-1.73,8.77c1.12,3.34,4.55,5.45,7.81,6.33a8.73,8.73,0,0,0,8.71-2.56,9.64,9.64,0,0,0,1.37-10.87c-.14-.29-.57,0-.43.25a9.18,9.18,0,0,1-.66,9.5,8.28,8.28,0,0,1-8,3.38c-3.08-.55-6.7-2.54-8-5.5a8.64,8.64,0,0,1,.87-8.34,11.1,11.1,0,0,1,8.93-4.37.25.25,0,0,0,0-.49Z"/>
                <path class="cls-4" d="M19.8,55.09c-1.08-.76-2.31-1.59-3.63-1.41H16.1a11.23,11.23,0,0,0-9.45,4.95,8.58,8.58,0,0,0,.91,10.29A11.08,11.08,0,0,0,9.9,70.64a12,12,0,0,0,5.69,1.87,8.93,8.93,0,0,0,8.53-7A10.47,10.47,0,0,0,19.8,55.09Zm.74,4.85a2.49,2.49,0,0,1-2.26,2.19,2.27,2.27,0,0,1-2.32-2c-.07-1.14.73-2.37,1.87-2.29h.07c.64-.06,1.42-.21,1.94.24A2.23,2.23,0,0,1,20.54,59.94Z"/>
                <path class="cls-4" d="M57.11,60.89a5.16,5.16,0,0,0-1.48,2.9c0,.13.12.24.25.24a.26.26,0,0,0,.25-.24,4.62,4.62,0,0,1,1.33-2.55c.22-.23-.13-.58-.35-.35Z"/>
                <path class="cls-4" d="M55.4,63.65a11.76,11.76,0,0,0,1.66,9.53,9.19,9.19,0,0,0,8.27,3.76c3.47-.35,6.37-3.24,7.94-6.21a8.69,8.69,0,0,0-.59-9,9.61,9.61,0,0,0-10.09-3.8c-.31.06-.18.54.13.47a9.18,9.18,0,0,1,9,2.92,8.34,8.34,0,0,1,1.5,8.3,12.81,12.81,0,0,1-3,4.21,8.41,8.41,0,0,1-4.16,2.52,8.64,8.64,0,0,1-8.1-2.7,11,11,0,0,1-2.16-9.82c.07-.31-.41-.45-.48-.14Z"/>
                <path class="cls-4" d="M55.32,55.82c-1,.87-2.1,1.87-2.25,3.18l0,.07a11.18,11.18,0,0,0,2.51,10.37,8.58,8.58,0,0,0,10.19,1.62A11.14,11.14,0,0,0,68,69.2a12,12,0,0,0,3.19-5.06,8.91,8.91,0,0,0-4.71-10A10.46,10.46,0,0,0,55.32,55.82Zm4.88.47A2.49,2.49,0,0,1,61.78,59a2.28,2.28,0,0,1-2.54,1.76,2,2,0,0,1-1.76-2.38v-.06c.11-.64.14-1.43.71-1.83A2.21,2.21,0,0,1,60.2,56.29Z"/>
                <path class="cls-4" d="M42.88,55a5.18,5.18,0,0,0-2.67-1.76c-.31-.07-.45.41-.13.48a4.68,4.68,0,0,1,2.44,1.63.25.25,0,0,0,.36-.35Z"/>
                <path class="cls-4" d="M40.3,53a11.64,11.64,0,0,0-10.09.94,9.21,9.21,0,0,0-4.14,7.77c.07,3.46,2.75,6.68,5.56,8.47a8.66,8.66,0,0,0,8.72.28,9.58,9.58,0,0,0,5-9.65c0-.32-.53-.32-.5,0a9.18,9.18,0,0,1-3.64,8.58,8.42,8.42,0,0,1-8.42.86,12.27,12.27,0,0,1-3.71-3,9.32,9.32,0,0,1-2.38-4.44,8.61,8.61,0,0,1,3.16-7.93,11,11,0,0,1,10.35-1.35c.3.1.43-.38.13-.48Z"/>
                <path class="cls-4" d="M48.12,53.74c-.76-1.09-1.63-2.29-2.93-2.58l-.06,0a11.19,11.19,0,0,0-10.57,1.41,8.58,8.58,0,0,0-2.69,10,11.52,11.52,0,0,0,1.61,2.43,12.12,12.12,0,0,0,4.7,3.71A8.94,8.94,0,0,0,48.59,65,10.46,10.46,0,0,0,48.12,53.74Zm-1,4.8a2.51,2.51,0,0,1-2.88,1.29,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.55-1.51l.06,0c.62.17,1.4.29,1.74.9A2.22,2.22,0,0,1,47.15,58.54Z"/>
                <path class="cls-4" d="M89.65,58.06a5.09,5.09,0,0,0-3.11-.74.25.25,0,0,0-.25.24c0,.13.12.27.25.25a4.74,4.74,0,0,1,2.86.68c.28.15.53-.28.25-.43Z"/>
                <path class="cls-4" d="M86.55,57.07A11.74,11.74,0,0,0,77.74,61,9.2,9.2,0,0,0,76,69.74c1.12,3.34,4.55,5.45,7.81,6.33a8.71,8.71,0,0,0,8.7-2.56,9.61,9.61,0,0,0,1.37-10.87c-.13-.29-.56,0-.42.25a9.18,9.18,0,0,1-.66,9.5,8.28,8.28,0,0,1-8,3.38c-3.08-.55-6.71-2.54-8-5.5a8.64,8.64,0,0,1,.87-8.34,11.1,11.1,0,0,1,8.93-4.37.25.25,0,0,0,0-.49Z"/>
                <path class="cls-4" d="M94.13,55.09c-1.08-.76-2.32-1.59-3.63-1.41h-.07A11.22,11.22,0,0,0,81,58.62a8.58,8.58,0,0,0,.91,10.29,11.08,11.08,0,0,0,2.34,1.73,12,12,0,0,0,5.69,1.87,8.94,8.94,0,0,0,8.53-7A10.47,10.47,0,0,0,94.13,55.09Zm.74,4.85a2.49,2.49,0,0,1-2.26,2.19,2.26,2.26,0,0,1-2.32-2c-.07-1.14.73-2.37,1.87-2.29h.06c.65-.06,1.43-.21,2,.24A2.23,2.23,0,0,1,94.87,59.94Z"/>
                <path class="cls-4" d="M131.44,60.89a5.16,5.16,0,0,0-1.48,2.9c0,.13.12.24.25.24a.24.24,0,0,0,.24-.24,4.7,4.7,0,0,1,1.34-2.55c.22-.23-.13-.58-.35-.35Z"/>
                <path class="cls-4" d="M129.72,63.65a11.8,11.8,0,0,0,1.67,9.53,9.19,9.19,0,0,0,8.27,3.76c3.47-.35,6.37-3.24,7.94-6.21a8.69,8.69,0,0,0-.59-9,9.61,9.61,0,0,0-10.09-3.8c-.31.06-.18.54.13.47a9.18,9.18,0,0,1,9,2.92,8.34,8.34,0,0,1,1.5,8.3,12.81,12.81,0,0,1-3,4.21,8.41,8.41,0,0,1-4.16,2.52,8.64,8.64,0,0,1-8.1-2.7,11.06,11.06,0,0,1-2.17-9.82c.08-.31-.4-.45-.48-.14Z"/>
                <path class="cls-4" d="M129.65,55.82c-1,.87-2.1,1.87-2.25,3.18l0,.07a11.21,11.21,0,0,0,2.51,10.37c2.6,2.66,7,3.52,10.2,1.62a11.14,11.14,0,0,0,2.25-1.86,12,12,0,0,0,3.19-5.06,8.91,8.91,0,0,0-4.71-10A10.45,10.45,0,0,0,129.65,55.82Zm4.88.47A2.49,2.49,0,0,1,136.11,59a2.28,2.28,0,0,1-2.54,1.76,2,2,0,0,1-1.76-2.38v-.06c.1-.64.14-1.43.71-1.83A2.21,2.21,0,0,1,134.53,56.29Z"/>
                <path class="cls-4" d="M117.2,55a5.12,5.12,0,0,0-2.66-1.76c-.31-.07-.45.41-.13.48a4.68,4.68,0,0,1,2.44,1.63.25.25,0,0,0,.35,0,.24.24,0,0,0,0-.35Z"/>
                <path class="cls-4" d="M114.63,53a11.64,11.64,0,0,0-10.09.94,9.21,9.21,0,0,0-4.14,7.77c.07,3.46,2.75,6.68,5.56,8.47a8.66,8.66,0,0,0,8.72.28,9.58,9.58,0,0,0,5-9.65c0-.32-.53-.32-.5,0a9.18,9.18,0,0,1-3.64,8.58,8.42,8.42,0,0,1-8.42.86,12.27,12.27,0,0,1-3.71-3A9.32,9.32,0,0,1,101,62.74a8.61,8.61,0,0,1,3.16-7.93,11,11,0,0,1,10.35-1.35c.3.1.43-.38.13-.48Z"/>
                <path class="cls-4" d="M122.45,53.74c-.76-1.09-1.63-2.29-2.93-2.58l-.06,0a11.19,11.19,0,0,0-10.57,1.41,8.58,8.58,0,0,0-2.69,10,11.52,11.52,0,0,0,1.61,2.43,12.12,12.12,0,0,0,4.7,3.71A8.94,8.94,0,0,0,122.92,65,10.46,10.46,0,0,0,122.45,53.74Zm-1,4.8a2.51,2.51,0,0,1-2.88,1.29,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.55-1.51l.06,0c.62.17,1.4.29,1.74.9A2.22,2.22,0,0,1,121.48,58.54Z"/>
                <path class="cls-4" d="M169.33,82.66a5.18,5.18,0,0,0-3.11-.75.27.27,0,0,0-.25.25c0,.12.11.27.25.25a4.62,4.62,0,0,1,2.85.68c.28.15.54-.28.26-.43Z"/>
                <path class="cls-4" d="M166.22,81.66a11.77,11.77,0,0,0-8.81,3.91,9.24,9.24,0,0,0-1.73,8.76c1.12,3.34,4.56,5.46,7.82,6.33a8.69,8.69,0,0,0,8.7-2.56,9.61,9.61,0,0,0,1.37-10.86c-.14-.29-.56,0-.43.25a9.15,9.15,0,0,1-.65,9.5,8.32,8.32,0,0,1-8,3.38c-3.08-.55-6.71-2.54-8-5.5a8.64,8.64,0,0,1,.88-8.34,11.09,11.09,0,0,1,8.92-4.37.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M173.81,79.69c-1.09-.76-2.32-1.59-3.63-1.41h-.07a11.18,11.18,0,0,0-9.45,5,8.6,8.6,0,0,0,.9,10.29,11.4,11.4,0,0,0,2.35,1.73,12.15,12.15,0,0,0,5.69,1.87,8.93,8.93,0,0,0,8.52-7A10.47,10.47,0,0,0,173.81,79.69Zm.74,4.85a2.5,2.5,0,0,1-2.26,2.19,2.29,2.29,0,0,1-2.33-2c-.06-1.14.74-2.36,1.88-2.28h.06c.65-.05,1.42-.21,1.95.25A2.26,2.26,0,0,1,174.55,84.54Z"/>
                <path class="cls-4" d="M211.12,85.49a5.21,5.21,0,0,0-1.49,2.89.25.25,0,0,0,.25.25.25.25,0,0,0,.25-.25,4.67,4.67,0,0,1,1.34-2.54c.22-.23-.13-.59-.35-.35Z"/>
                <path class="cls-4" d="M209.4,88.25a11.76,11.76,0,0,0,1.67,9.53,9.2,9.2,0,0,0,8.26,3.76c3.47-.36,6.37-3.25,7.94-6.21a8.71,8.71,0,0,0-.58-9,9.63,9.63,0,0,0-10.09-3.8c-.32.07-.18.55.13.48a9.15,9.15,0,0,1,9,2.91,8.34,8.34,0,0,1,1.5,8.31,12.88,12.88,0,0,1-2.95,4.21,8.45,8.45,0,0,1-4.16,2.52,8.64,8.64,0,0,1-8.1-2.7,11.06,11.06,0,0,1-2.17-9.82c.07-.32-.41-.45-.48-.14Z"/>
                <path class="cls-4" d="M209.33,80.42c-1,.87-2.11,1.86-2.25,3.18l0,.07A11.19,11.19,0,0,0,209.56,94a8.6,8.6,0,0,0,10.2,1.63A11.14,11.14,0,0,0,222,93.8a12,12,0,0,0,3.19-5.06,8.91,8.91,0,0,0-4.71-10A10.45,10.45,0,0,0,209.33,80.42Zm4.88.46a2.52,2.52,0,0,1,1.58,2.73,2.29,2.29,0,0,1-2.54,1.76c-1.12-.22-2.12-1.29-1.77-2.38l0-.06c.1-.64.14-1.43.71-1.83A2.24,2.24,0,0,1,214.21,80.88Z"/>
                <path class="cls-4" d="M196.88,79.56a5.13,5.13,0,0,0-2.67-1.75c-.31-.07-.44.41-.13.48a4.64,4.64,0,0,1,2.45,1.63.25.25,0,0,0,.35,0,.26.26,0,0,0,0-.36Z"/>
                <path class="cls-4" d="M194.31,77.58a11.64,11.64,0,0,0-10.09.94,9.18,9.18,0,0,0-4.15,7.77c.08,3.46,2.75,6.67,5.57,8.47a8.69,8.69,0,0,0,8.72.28,9.57,9.57,0,0,0,4.95-9.65c0-.32-.52-.32-.49,0A9.19,9.19,0,0,1,195.17,94a8.38,8.38,0,0,1-8.41.85,12.14,12.14,0,0,1-3.72-3,9.38,9.38,0,0,1-2.37-4.44,8.55,8.55,0,0,1,3.16-7.93,11,11,0,0,1,10.34-1.35c.31.1.44-.38.14-.48Z"/>
                <path class="cls-4" d="M202.13,78.33c-.76-1.08-1.64-2.28-2.93-2.57l-.06,0a11.18,11.18,0,0,0-10.58,1.41,8.59,8.59,0,0,0-2.68,10,11.52,11.52,0,0,0,1.61,2.43,11.94,11.94,0,0,0,4.7,3.7,8.92,8.92,0,0,0,10.41-3.63A10.48,10.48,0,0,0,202.13,78.33Zm-1,4.81a2.49,2.49,0,0,1-2.87,1.28,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.54-1.5l.06,0c.63.17,1.41.29,1.75.9A2.22,2.22,0,0,1,201.15,83.14Z"/>
                <path class="cls-4" d="M243.65,82.66a5.14,5.14,0,0,0-3.1-.75.26.26,0,0,0-.25.25c0,.12.11.27.25.25a4.62,4.62,0,0,1,2.85.68c.28.15.53-.28.25-.43Z"/>
                <path class="cls-4" d="M240.55,81.66a11.77,11.77,0,0,0-8.81,3.91A9.21,9.21,0,0,0,230,94.33c1.12,3.34,4.56,5.46,7.82,6.33a8.7,8.7,0,0,0,8.7-2.56,9.61,9.61,0,0,0,1.37-10.86c-.14-.29-.57,0-.43.25a9.15,9.15,0,0,1-.65,9.5,8.32,8.32,0,0,1-8,3.38c-3.08-.55-6.71-2.54-8-5.5a8.64,8.64,0,0,1,.88-8.34,11.09,11.09,0,0,1,8.92-4.37.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M248.14,79.69c-1.09-.76-2.32-1.59-3.63-1.41h-.07a11.18,11.18,0,0,0-9.45,5,8.6,8.6,0,0,0,.9,10.29,11.4,11.4,0,0,0,2.35,1.73,12.15,12.15,0,0,0,5.69,1.87,8.93,8.93,0,0,0,8.52-7A10.47,10.47,0,0,0,248.14,79.69Zm.73,4.85a2.48,2.48,0,0,1-2.25,2.19,2.29,2.29,0,0,1-2.33-2c-.06-1.14.74-2.36,1.88-2.28h.06c.65-.05,1.42-.21,2,.25A2.22,2.22,0,0,1,248.87,84.54Z"/>
                <path class="cls-4" d="M285.45,85.49A5.21,5.21,0,0,0,284,88.38a.25.25,0,0,0,.5,0,4.67,4.67,0,0,1,1.34-2.54c.22-.23-.13-.59-.35-.35Z"/>
                <path class="cls-4" d="M283.73,88.25a11.76,11.76,0,0,0,1.67,9.53,9.2,9.2,0,0,0,8.26,3.76c3.47-.36,6.37-3.25,7.94-6.21a8.73,8.73,0,0,0-.58-9,9.64,9.64,0,0,0-10.09-3.8c-.32.07-.18.55.13.48a9.15,9.15,0,0,1,9,2.91,8.36,8.36,0,0,1,1.5,8.31,12.77,12.77,0,0,1-3,4.21,8.38,8.38,0,0,1-4.15,2.52,8.64,8.64,0,0,1-8.1-2.7,11.06,11.06,0,0,1-2.17-9.82c.07-.32-.41-.45-.48-.14Z"/>
                <path class="cls-4" d="M283.65,80.42c-1,.87-2.1,1.86-2.25,3.18l0,.07A11.19,11.19,0,0,0,283.89,94a8.6,8.6,0,0,0,10.2,1.63,11.42,11.42,0,0,0,2.25-1.86,12.08,12.08,0,0,0,3.19-5.06,8.91,8.91,0,0,0-4.71-10A10.47,10.47,0,0,0,283.65,80.42Zm4.89.46a2.52,2.52,0,0,1,1.58,2.73,2.29,2.29,0,0,1-2.54,1.76A2,2,0,0,1,285.81,83l0-.06c.1-.64.14-1.43.71-1.83A2.24,2.24,0,0,1,288.54,80.88Z"/>
                <path class="cls-4" d="M271.21,79.56a5.13,5.13,0,0,0-2.67-1.75c-.31-.07-.44.41-.13.48a4.64,4.64,0,0,1,2.45,1.63.25.25,0,0,0,.35,0,.26.26,0,0,0,0-.36Z"/>
                <path class="cls-4" d="M268.63,77.58a11.62,11.62,0,0,0-10.08.94,9.18,9.18,0,0,0-4.15,7.77c.08,3.46,2.75,6.67,5.56,8.47a8.71,8.71,0,0,0,8.73.28,9.59,9.59,0,0,0,4.95-9.65c0-.32-.52-.32-.49,0A9.19,9.19,0,0,1,269.5,94a8.38,8.38,0,0,1-8.41.85,12.14,12.14,0,0,1-3.72-3A9.3,9.3,0,0,1,255,87.34a8.55,8.55,0,0,1,3.16-7.93,11,11,0,0,1,10.34-1.35c.3.1.43-.38.13-.48Z"/>
                <path class="cls-4" d="M276.46,78.33c-.76-1.08-1.64-2.28-2.93-2.57l-.06,0a11.18,11.18,0,0,0-10.58,1.41,8.59,8.59,0,0,0-2.68,10,11.15,11.15,0,0,0,1.61,2.43,11.94,11.94,0,0,0,4.7,3.7,8.93,8.93,0,0,0,10.41-3.63A10.48,10.48,0,0,0,276.46,78.33Zm-1,4.81a2.49,2.49,0,0,1-2.87,1.28,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.54-1.5l.06,0c.63.17,1.41.29,1.75.9A2.22,2.22,0,0,1,275.48,83.14Z"/>
                <path class="cls-4" d="M21.2,82.66a5.15,5.15,0,0,0-3.1-.75.26.26,0,0,0-.25.25c0,.12.11.27.25.25a4.64,4.64,0,0,1,2.85.68c.28.15.53-.28.25-.43Z"/>
                <path class="cls-4" d="M18.1,81.66a11.75,11.75,0,0,0-8.81,3.91,9.21,9.21,0,0,0-1.73,8.76c1.12,3.34,4.55,5.46,7.82,6.33a8.7,8.7,0,0,0,8.7-2.56,9.61,9.61,0,0,0,1.37-10.86c-.14-.29-.57,0-.43.25a9.18,9.18,0,0,1-.65,9.5,8.33,8.33,0,0,1-8,3.38c-3.08-.55-6.7-2.54-8-5.5a8.66,8.66,0,0,1,.87-8.34,11.1,11.1,0,0,1,8.93-4.37.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M25.69,79.69c-1.09-.76-2.32-1.59-3.64-1.41H22a11.18,11.18,0,0,0-9.44,5,8.58,8.58,0,0,0,.9,10.29,11.34,11.34,0,0,0,2.34,1.73,12.23,12.23,0,0,0,5.69,1.87,8.93,8.93,0,0,0,8.53-7A10.47,10.47,0,0,0,25.69,79.69Zm.73,4.85a2.48,2.48,0,0,1-2.26,2.19,2.28,2.28,0,0,1-2.32-2,2,2,0,0,1,1.88-2.28h.06c.65-.05,1.42-.21,1.95.25A2.25,2.25,0,0,1,26.42,84.54Z"/>
                <path class="cls-4" d="M63,85.49a5.09,5.09,0,0,0-1.49,2.89c0,.14.13.25.25.25a.25.25,0,0,0,.25-.25,4.6,4.6,0,0,1,1.34-2.54c.22-.23-.14-.59-.35-.35Z"/>
                <path class="cls-4" d="M61.28,88.25a11.8,11.8,0,0,0,1.66,9.53,9.23,9.23,0,0,0,8.27,3.76c3.47-.36,6.37-3.25,7.94-6.21a8.73,8.73,0,0,0-.58-9,9.65,9.65,0,0,0-10.1-3.8c-.31.07-.18.55.14.48a9.15,9.15,0,0,1,9,2.91,8.36,8.36,0,0,1,1.5,8.31,12.91,12.91,0,0,1-3,4.21A8.36,8.36,0,0,1,72,100.91a8.61,8.61,0,0,1-8.09-2.7,11,11,0,0,1-2.17-9.82c.07-.32-.41-.45-.48-.14Z"/>
                <path class="cls-4" d="M61.2,80.42c-1,.87-2.1,1.86-2.25,3.18l0,.07A11.19,11.19,0,0,0,61.44,94a8.6,8.6,0,0,0,10.2,1.63,11.36,11.36,0,0,0,2.24-1.86,12.11,12.11,0,0,0,3.2-5.06,8.94,8.94,0,0,0-4.71-10A10.47,10.47,0,0,0,61.2,80.42Zm4.89.46a2.5,2.5,0,0,1,1.57,2.73,2.28,2.28,0,0,1-2.54,1.76A2,2,0,0,1,63.36,83v-.06c.11-.64.14-1.43.72-1.83A2.24,2.24,0,0,1,66.09,80.88Z"/>
                <path class="cls-4" d="M48.76,79.56a5.17,5.17,0,0,0-2.67-1.75c-.31-.07-.44.41-.13.48a4.68,4.68,0,0,1,2.45,1.63.25.25,0,0,0,.35,0,.26.26,0,0,0,0-.36Z"/>
                <path class="cls-4" d="M46.18,77.58a11.64,11.64,0,0,0-10.09.94A9.19,9.19,0,0,0,32,86.29C32,89.75,34.7,93,37.51,94.76a8.69,8.69,0,0,0,8.72.28,9.58,9.58,0,0,0,5-9.65c0-.32-.53-.32-.5,0A9.18,9.18,0,0,1,47.05,94a8.39,8.39,0,0,1-8.42.85,12.1,12.1,0,0,1-3.71-3,9.32,9.32,0,0,1-2.38-4.44,8.6,8.6,0,0,1,3.16-7.93,11,11,0,0,1,10.35-1.35c.3.1.43-.38.13-.48Z"/>
                <path class="cls-4" d="M54,78.33c-.75-1.08-1.63-2.28-2.92-2.57l-.07,0a11.17,11.17,0,0,0-10.57,1.41,8.59,8.59,0,0,0-2.69,10,11.52,11.52,0,0,0,1.61,2.43,12.08,12.08,0,0,0,4.7,3.7,8.92,8.92,0,0,0,10.41-3.63A10.46,10.46,0,0,0,54,78.33Zm-1,4.81a2.49,2.49,0,0,1-2.87,1.28,2.28,2.28,0,0,1-1.49-2.71,2,2,0,0,1,2.55-1.5l.06,0c.63.17,1.41.29,1.74.9A2.22,2.22,0,0,1,53,83.14Z"/>
                <path class="cls-4" d="M95.53,82.66a5.17,5.17,0,0,0-3.11-.75.26.26,0,0,0-.24.25c0,.12.11.27.24.25a4.65,4.65,0,0,1,2.86.68c.28.15.53-.28.25-.43Z"/>
                <path class="cls-4" d="M92.43,81.66a11.75,11.75,0,0,0-8.81,3.91,9.19,9.19,0,0,0-1.73,8.76c1.12,3.34,4.55,5.46,7.82,6.33a8.7,8.7,0,0,0,8.7-2.56,9.61,9.61,0,0,0,1.37-10.86c-.14-.29-.57,0-.43.25A9.18,9.18,0,0,1,98.7,97a8.33,8.33,0,0,1-8,3.38c-3.08-.55-6.7-2.54-8-5.5a8.64,8.64,0,0,1,.87-8.34,11.1,11.1,0,0,1,8.93-4.37.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M100,79.69c-1.09-.76-2.32-1.59-3.64-1.41h-.07a11.18,11.18,0,0,0-9.44,5,8.58,8.58,0,0,0,.9,10.29,11.34,11.34,0,0,0,2.34,1.73,12.19,12.19,0,0,0,5.69,1.87,8.93,8.93,0,0,0,8.53-7A10.48,10.48,0,0,0,100,79.69Zm.73,4.85a2.5,2.5,0,0,1-2.26,2.19,2.28,2.28,0,0,1-2.32-2c-.07-1.14.74-2.36,1.87-2.28h.07c.64-.05,1.42-.21,1.94.25A2.23,2.23,0,0,1,100.75,84.54Z"/>
                <path class="cls-4" d="M137.32,85.49a5.14,5.14,0,0,0-1.48,2.89c0,.14.13.25.25.25a.25.25,0,0,0,.25-.25,4.54,4.54,0,0,1,1.34-2.54c.21-.23-.14-.59-.36-.35Z"/>
                <path class="cls-4" d="M135.61,88.25a11.8,11.8,0,0,0,1.66,9.53,9.23,9.23,0,0,0,8.27,3.76c3.47-.36,6.37-3.25,7.94-6.21a8.71,8.71,0,0,0-.59-9,9.62,9.62,0,0,0-10.09-3.8c-.31.07-.18.55.13.48a9.17,9.17,0,0,1,9,2.91,8.36,8.36,0,0,1,1.5,8.31,12.91,12.91,0,0,1-3,4.21,8.36,8.36,0,0,1-4.16,2.52,8.61,8.61,0,0,1-8.09-2.7,11,11,0,0,1-2.17-9.82c.07-.32-.41-.45-.48-.14Z"/>
                <path class="cls-4" d="M135.53,80.42c-1,.87-2.1,1.86-2.25,3.18l0,.07A11.19,11.19,0,0,0,135.77,94,8.6,8.6,0,0,0,146,95.66a11.36,11.36,0,0,0,2.24-1.86,12.11,12.11,0,0,0,3.2-5.06,8.94,8.94,0,0,0-4.71-10A10.47,10.47,0,0,0,135.53,80.42Zm4.89.46A2.5,2.5,0,0,1,142,83.61a2.28,2.28,0,0,1-2.54,1.76A2,2,0,0,1,137.69,83v-.06c.11-.64.14-1.43.72-1.83A2.24,2.24,0,0,1,140.42,80.88Z"/>
                <path class="cls-4" d="M123.09,79.56a5.17,5.17,0,0,0-2.67-1.75c-.31-.07-.44.41-.13.48a4.68,4.68,0,0,1,2.45,1.63.25.25,0,0,0,.35,0,.27.27,0,0,0,0-.36Z"/>
                <path class="cls-4" d="M120.51,77.58a11.64,11.64,0,0,0-10.09.94,9.19,9.19,0,0,0-4.14,7.77c.08,3.46,2.75,6.67,5.56,8.47a8.69,8.69,0,0,0,8.72.28,9.58,9.58,0,0,0,5-9.65c0-.32-.53-.32-.5,0A9.18,9.18,0,0,1,121.38,94a8.39,8.39,0,0,1-8.42.85,12.1,12.1,0,0,1-3.71-3,9.32,9.32,0,0,1-2.38-4.44A8.6,8.6,0,0,1,110,79.41a11,11,0,0,1,10.35-1.35c.3.1.43-.38.13-.48Z"/>
                <path class="cls-4" d="M128.33,78.33c-.76-1.08-1.63-2.28-2.92-2.57l-.07,0a11.17,11.17,0,0,0-10.57,1.41,8.58,8.58,0,0,0-2.69,10,11.52,11.52,0,0,0,1.61,2.43,12,12,0,0,0,4.7,3.7,8.92,8.92,0,0,0,10.41-3.63A10.46,10.46,0,0,0,128.33,78.33Zm-1,4.81a2.49,2.49,0,0,1-2.87,1.28A2.27,2.27,0,0,1,123,81.71a2,2,0,0,1,2.55-1.5l.06,0c.62.17,1.41.29,1.74.9A2.22,2.22,0,0,1,127.36,83.14Z"/>
                <path class="cls-4" d="M462.42,58.06a5.09,5.09,0,0,0-3.11-.74.25.25,0,0,0-.25.24c0,.13.12.27.25.25a4.72,4.72,0,0,1,2.86.68c.28.15.53-.28.25-.43Z"/>
                <path class="cls-4" d="M459.32,57.07a11.74,11.74,0,0,0-8.81,3.9,9.2,9.2,0,0,0-1.73,8.77c1.12,3.34,4.55,5.45,7.81,6.33a8.73,8.73,0,0,0,8.71-2.56,9.64,9.64,0,0,0,1.37-10.87c-.14-.29-.57,0-.43.25a9.18,9.18,0,0,1-.66,9.5,8.28,8.28,0,0,1-8,3.38c-3.08-.55-6.7-2.54-8-5.5a8.64,8.64,0,0,1,.87-8.34,11.1,11.1,0,0,1,8.93-4.37.25.25,0,0,0,0-.49Z"/>
                <path class="cls-4" d="M466.9,55.09c-1.08-.76-2.31-1.59-3.63-1.41h-.07a11.23,11.23,0,0,0-9.45,4.95,8.6,8.6,0,0,0,.91,10.29A11.08,11.08,0,0,0,457,70.64a12,12,0,0,0,5.69,1.87,8.93,8.93,0,0,0,8.53-7A10.47,10.47,0,0,0,466.9,55.09Zm.74,4.85a2.49,2.49,0,0,1-2.26,2.19,2.27,2.27,0,0,1-2.32-2c-.07-1.14.73-2.37,1.87-2.29H465c.64-.06,1.42-.21,1.94.24A2.23,2.23,0,0,1,467.64,59.94Z"/>
                <path class="cls-4" d="M504.21,60.89a5.16,5.16,0,0,0-1.48,2.9c0,.13.12.24.25.24a.25.25,0,0,0,.25-.24,4.62,4.62,0,0,1,1.33-2.55c.22-.23-.13-.58-.35-.35Z"/>
                <path class="cls-4" d="M502.5,63.65a11.76,11.76,0,0,0,1.66,9.53,9.19,9.19,0,0,0,8.27,3.76c3.47-.35,6.37-3.24,7.94-6.21a8.69,8.69,0,0,0-.59-9,9.61,9.61,0,0,0-10.09-3.8c-.31.06-.18.54.13.47a9.18,9.18,0,0,1,9,2.92,8.34,8.34,0,0,1,1.5,8.3,12.81,12.81,0,0,1-3,4.21,8.41,8.41,0,0,1-4.16,2.52,8.61,8.61,0,0,1-8.09-2.7A11,11,0,0,1,503,63.79c.07-.31-.41-.45-.48-.14Z"/>
                <path class="cls-4" d="M502.42,55.82c-1,.87-2.1,1.87-2.25,3.18l0,.07a11.21,11.21,0,0,0,2.51,10.37,8.58,8.58,0,0,0,10.19,1.62,11.14,11.14,0,0,0,2.25-1.86,12,12,0,0,0,3.19-5.06,8.91,8.91,0,0,0-4.71-10A10.46,10.46,0,0,0,502.42,55.82Zm4.88.47A2.47,2.47,0,0,1,508.88,59a2.28,2.28,0,0,1-2.54,1.76,2,2,0,0,1-1.76-2.38v-.06c.11-.64.14-1.43.71-1.83A2.21,2.21,0,0,1,507.3,56.29Z"/>
                <path class="cls-4" d="M490,55a5.18,5.18,0,0,0-2.67-1.76c-.31-.07-.44.41-.13.48a4.68,4.68,0,0,1,2.44,1.63A.25.25,0,0,0,490,55Z"/>
                <path class="cls-4" d="M487.4,53a11.64,11.64,0,0,0-10.09.94,9.21,9.21,0,0,0-4.14,7.77c.08,3.46,2.75,6.68,5.56,8.47a8.66,8.66,0,0,0,8.72.28,9.58,9.58,0,0,0,5-9.65c0-.32-.53-.32-.5,0a9.18,9.18,0,0,1-3.64,8.58,8.42,8.42,0,0,1-8.42.86,12.27,12.27,0,0,1-3.71-3,9.32,9.32,0,0,1-2.38-4.44,8.61,8.61,0,0,1,3.16-7.93,11,11,0,0,1,10.35-1.35c.3.1.43-.38.13-.48Z"/>
                <path class="cls-4" d="M495.22,53.74c-.76-1.09-1.63-2.29-2.93-2.58l-.06,0a11.19,11.19,0,0,0-10.57,1.41,8.58,8.58,0,0,0-2.69,10,11.52,11.52,0,0,0,1.61,2.43,12.12,12.12,0,0,0,4.7,3.71A8.94,8.94,0,0,0,495.69,65,10.44,10.44,0,0,0,495.22,53.74Zm-1,4.8a2.51,2.51,0,0,1-2.88,1.29,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.55-1.51l.06,0c.62.17,1.4.29,1.74.9A2.22,2.22,0,0,1,494.25,58.54Z"/>
                <path class="cls-4" d="M536.75,58.06a5.09,5.09,0,0,0-3.11-.74.25.25,0,0,0-.25.24c0,.13.12.27.25.25a4.74,4.74,0,0,1,2.86.68c.28.15.53-.28.25-.43Z"/>
                <path class="cls-4" d="M533.65,57.07a11.74,11.74,0,0,0-8.81,3.9,9.2,9.2,0,0,0-1.73,8.77c1.12,3.34,4.55,5.45,7.81,6.33a8.7,8.7,0,0,0,8.7-2.56A9.61,9.61,0,0,0,541,62.64c-.13-.29-.56,0-.42.25a9.18,9.18,0,0,1-.66,9.5,8.28,8.28,0,0,1-8,3.38c-3.08-.55-6.71-2.54-8-5.5a8.64,8.64,0,0,1,.87-8.34,11.1,11.1,0,0,1,8.93-4.37.25.25,0,0,0,0-.49Z"/>
                <path class="cls-4" d="M541.23,55.09c-1.08-.76-2.32-1.59-3.63-1.41h-.07a11.23,11.23,0,0,0-9.45,4.95A8.58,8.58,0,0,0,529,68.91a11.08,11.08,0,0,0,2.34,1.73A12,12,0,0,0,537,72.51a8.94,8.94,0,0,0,8.53-7A10.47,10.47,0,0,0,541.23,55.09Zm.74,4.85a2.49,2.49,0,0,1-2.26,2.19,2.27,2.27,0,0,1-2.32-2c-.07-1.14.73-2.37,1.87-2.29h.07c.64-.06,1.42-.21,1.94.24A2.23,2.23,0,0,1,542,59.94Z"/>
                <path class="cls-4" d="M578.54,60.89a5.16,5.16,0,0,0-1.48,2.9c0,.13.12.24.25.24a.26.26,0,0,0,.25-.24,4.62,4.62,0,0,1,1.33-2.55c.22-.23-.13-.58-.35-.35Z"/>
                <path class="cls-4" d="M576.83,63.65a11.76,11.76,0,0,0,1.66,9.53,9.19,9.19,0,0,0,8.27,3.76c3.47-.35,6.37-3.24,7.94-6.21a8.69,8.69,0,0,0-.59-9A9.61,9.61,0,0,0,584,57.89c-.31.06-.18.54.13.47a9.18,9.18,0,0,1,9,2.92,8.34,8.34,0,0,1,1.5,8.3,12.81,12.81,0,0,1-3,4.21,8.41,8.41,0,0,1-4.16,2.52,8.64,8.64,0,0,1-8.1-2.7,11.06,11.06,0,0,1-2.17-9.82c.08-.31-.4-.45-.47-.14Z"/>
                <path class="cls-4" d="M576.75,55.82c-1,.87-2.1,1.87-2.25,3.18l0,.07A11.15,11.15,0,0,0,577,69.44a8.58,8.58,0,0,0,10.19,1.62,11.14,11.14,0,0,0,2.25-1.86,12,12,0,0,0,3.19-5.06,8.91,8.91,0,0,0-4.71-10A10.46,10.46,0,0,0,576.75,55.82Zm4.88.47A2.49,2.49,0,0,1,583.21,59a2.28,2.28,0,0,1-2.54,1.76,2,2,0,0,1-1.76-2.38v-.06c.11-.64.14-1.43.71-1.83A2.21,2.21,0,0,1,581.63,56.29Z"/>
                <path class="cls-4" d="M564.3,55a5.12,5.12,0,0,0-2.66-1.76c-.31-.07-.45.41-.13.48A4.68,4.68,0,0,1,564,55.32a.25.25,0,0,0,.35,0,.24.24,0,0,0,0-.35Z"/>
                <path class="cls-4" d="M561.73,53a11.64,11.64,0,0,0-10.09.94,9.21,9.21,0,0,0-4.14,7.77c.07,3.46,2.75,6.68,5.56,8.47a8.66,8.66,0,0,0,8.72.28,9.58,9.58,0,0,0,5-9.65c0-.32-.53-.32-.5,0a9.18,9.18,0,0,1-3.64,8.58,8.42,8.42,0,0,1-8.42.86,12.27,12.27,0,0,1-3.71-3,9.32,9.32,0,0,1-2.38-4.44,8.61,8.61,0,0,1,3.16-7.93,11,11,0,0,1,10.35-1.35c.3.1.43-.38.13-.48Z"/>
                <path class="cls-4" d="M569.55,53.74c-.76-1.09-1.63-2.29-2.93-2.58l-.06,0A11.19,11.19,0,0,0,556,52.54a8.58,8.58,0,0,0-2.69,10,11.52,11.52,0,0,0,1.61,2.43,12.12,12.12,0,0,0,4.7,3.71A8.94,8.94,0,0,0,570,65,10.46,10.46,0,0,0,569.55,53.74Zm-1,4.8a2.51,2.51,0,0,1-2.88,1.29,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.55-1.51l.06,0c.62.17,1.4.29,1.74.9A2.22,2.22,0,0,1,568.58,58.54Z"/>
                <path class="cls-4" d="M314.3,58.06a5.11,5.11,0,0,0-3.11-.74.25.25,0,0,0-.25.24c0,.13.12.27.25.25a4.74,4.74,0,0,1,2.86.68c.28.15.53-.28.25-.43Z"/>
                <path class="cls-4" d="M311.19,57.07a11.76,11.76,0,0,0-8.81,3.9,9.22,9.22,0,0,0-1.72,8.77c1.11,3.34,4.55,5.45,7.81,6.33a8.71,8.71,0,0,0,8.7-2.56,9.63,9.63,0,0,0,1.37-10.87c-.14-.29-.56,0-.43.25a9.15,9.15,0,0,1-.65,9.5,8.29,8.29,0,0,1-8,3.38c-3.08-.55-6.71-2.54-8-5.5a8.64,8.64,0,0,1,.88-8.34,11.09,11.09,0,0,1,8.92-4.37.25.25,0,0,0,0-.49Z"/>
                <path class="cls-4" d="M318.78,55.09c-1.09-.76-2.32-1.59-3.63-1.41h-.07a11.22,11.22,0,0,0-9.45,4.95,8.6,8.6,0,0,0,.9,10.29,11.4,11.4,0,0,0,2.35,1.73,12,12,0,0,0,5.69,1.87,8.94,8.94,0,0,0,8.53-7A10.49,10.49,0,0,0,318.78,55.09Zm.74,4.85a2.49,2.49,0,0,1-2.26,2.19,2.28,2.28,0,0,1-2.33-2c-.06-1.14.74-2.37,1.88-2.29h.06a2.57,2.57,0,0,1,1.95.24A2.23,2.23,0,0,1,319.52,59.94Z"/>
                <path class="cls-4" d="M356.09,60.89a5.16,5.16,0,0,0-1.48,2.9c0,.13.12.24.24.24a.25.25,0,0,0,.25-.24,4.7,4.7,0,0,1,1.34-2.55c.22-.23-.13-.58-.35-.35Z"/>
                <path class="cls-4" d="M354.37,63.65A11.76,11.76,0,0,0,356,73.18a9.17,9.17,0,0,0,8.26,3.76c3.48-.35,6.37-3.24,7.95-6.21a8.71,8.71,0,0,0-.59-9,9.61,9.61,0,0,0-10.09-3.8c-.32.06-.18.54.13.47a9.16,9.16,0,0,1,9,2.92,8.31,8.31,0,0,1,1.5,8.3,12.64,12.64,0,0,1-3,4.21,8.45,8.45,0,0,1-4.16,2.52,8.64,8.64,0,0,1-8.1-2.7,11.06,11.06,0,0,1-2.17-9.82c.07-.31-.4-.45-.48-.14Z"/>
                <path class="cls-4" d="M354.3,55.82c-1,.87-2.1,1.87-2.25,3.18l0,.07a11.21,11.21,0,0,0,2.51,10.37,8.59,8.59,0,0,0,10.2,1.62A11.14,11.14,0,0,0,367,69.2a12,12,0,0,0,3.19-5.06,8.91,8.91,0,0,0-4.71-10A10.45,10.45,0,0,0,354.3,55.82Zm4.88.47A2.5,2.5,0,0,1,360.76,59a2.29,2.29,0,0,1-2.54,1.76,2,2,0,0,1-1.77-2.38l0-.06c.1-.64.14-1.43.71-1.83A2.21,2.21,0,0,1,359.18,56.29Z"/>
                <path class="cls-4" d="M341.85,55a5.16,5.16,0,0,0-2.66-1.76c-.32-.07-.45.41-.14.48a4.7,4.7,0,0,1,2.45,1.63.25.25,0,0,0,.35-.35Z"/>
                <path class="cls-4" d="M339.28,53a11.64,11.64,0,0,0-10.09.94A9.19,9.19,0,0,0,325,61.69c.08,3.46,2.76,6.68,5.57,8.47a8.66,8.66,0,0,0,8.72.28,9.56,9.56,0,0,0,4.95-9.65c0-.32-.52-.32-.49,0a9.17,9.17,0,0,1-3.65,8.58,8.4,8.4,0,0,1-8.41.86,12.44,12.44,0,0,1-3.72-3,9.38,9.38,0,0,1-2.37-4.44,8.56,8.56,0,0,1,3.16-7.93,11,11,0,0,1,10.34-1.35c.31.1.44-.38.14-.48Z"/>
                <path class="cls-4" d="M347.1,53.74c-.76-1.09-1.63-2.29-2.93-2.58l-.06,0a11.21,11.21,0,0,0-10.58,1.41,8.59,8.59,0,0,0-2.68,10,11.52,11.52,0,0,0,1.61,2.43,12.12,12.12,0,0,0,4.7,3.71A8.94,8.94,0,0,0,347.57,65,10.46,10.46,0,0,0,347.1,53.74Zm-1,4.8a2.49,2.49,0,0,1-2.87,1.29,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.54-1.51l.06,0c.63.17,1.41.29,1.75.9A2.22,2.22,0,0,1,346.12,58.54Z"/>
                <path class="cls-4" d="M388.63,58.06a5.11,5.11,0,0,0-3.11-.74.25.25,0,0,0-.25.24.24.24,0,0,0,.25.25,4.69,4.69,0,0,1,2.85.68c.28.15.54-.28.26-.43Z"/>
                <path class="cls-4" d="M385.52,57.07a11.76,11.76,0,0,0-8.81,3.9A9.25,9.25,0,0,0,375,69.74c1.12,3.34,4.56,5.45,7.82,6.33a8.71,8.71,0,0,0,8.7-2.56,9.63,9.63,0,0,0,1.37-10.87c-.14-.29-.56,0-.43.25a9.15,9.15,0,0,1-.65,9.5,8.3,8.3,0,0,1-8,3.38c-3.08-.55-6.71-2.54-8-5.5a8.64,8.64,0,0,1,.88-8.34,11.09,11.09,0,0,1,8.92-4.37.25.25,0,0,0,0-.49Z"/>
                <path class="cls-4" d="M393.11,55.09c-1.09-.76-2.32-1.59-3.63-1.41h-.07A11.2,11.2,0,0,0,380,58.62a8.6,8.6,0,0,0,.9,10.29,11.4,11.4,0,0,0,2.35,1.73,12,12,0,0,0,5.69,1.87,8.92,8.92,0,0,0,8.52-7A10.47,10.47,0,0,0,393.11,55.09Zm.74,4.85a2.49,2.49,0,0,1-2.26,2.19,2.28,2.28,0,0,1-2.33-2c-.06-1.14.74-2.37,1.88-2.29h.06a2.57,2.57,0,0,1,1.95.24A2.26,2.26,0,0,1,393.85,59.94Z"/>
                <path class="cls-4" d="M430.42,60.89a5.24,5.24,0,0,0-1.49,2.9.25.25,0,0,0,.25.24.25.25,0,0,0,.25-.24,4.7,4.7,0,0,1,1.34-2.55c.22-.23-.13-.58-.35-.35Z"/>
                <path class="cls-4" d="M428.7,63.65a11.76,11.76,0,0,0,1.67,9.53,9.16,9.16,0,0,0,8.26,3.76c3.48-.35,6.37-3.24,7.94-6.21a8.69,8.69,0,0,0-.58-9,9.61,9.61,0,0,0-10.09-3.8c-.32.06-.18.54.13.47a9.16,9.16,0,0,1,9,2.92,8.31,8.31,0,0,1,1.5,8.3,12.78,12.78,0,0,1-2.95,4.21,8.45,8.45,0,0,1-4.16,2.52,8.64,8.64,0,0,1-8.1-2.7,11.06,11.06,0,0,1-2.17-9.82c.07-.31-.41-.45-.48-.14Z"/>
                <path class="cls-4" d="M428.63,55.82c-1,.87-2.11,1.87-2.25,3.18l0,.07a11.21,11.21,0,0,0,2.51,10.37,8.59,8.59,0,0,0,10.2,1.62,11.14,11.14,0,0,0,2.25-1.86,12,12,0,0,0,3.19-5.06,8.91,8.91,0,0,0-4.71-10A10.45,10.45,0,0,0,428.63,55.82Zm4.88.47A2.5,2.5,0,0,1,435.09,59a2.29,2.29,0,0,1-2.54,1.76,2,2,0,0,1-1.77-2.38l0-.06c.1-.64.14-1.43.71-1.83A2.21,2.21,0,0,1,433.51,56.29Z"/>
                <path class="cls-4" d="M416.18,55a5.16,5.16,0,0,0-2.66-1.76c-.32-.07-.45.41-.14.48a4.7,4.7,0,0,1,2.45,1.63.25.25,0,0,0,.35-.35Z"/>
                <path class="cls-4" d="M413.61,53a11.64,11.64,0,0,0-10.09.94,9.19,9.19,0,0,0-4.15,7.77c.08,3.46,2.75,6.68,5.57,8.47a8.66,8.66,0,0,0,8.72.28,9.57,9.57,0,0,0,4.95-9.65c0-.32-.52-.32-.49,0a9.19,9.19,0,0,1-3.65,8.58,8.4,8.4,0,0,1-8.41.86,12.44,12.44,0,0,1-3.72-3A9.38,9.38,0,0,1,400,62.74a8.56,8.56,0,0,1,3.16-7.93,11,11,0,0,1,10.34-1.35c.31.1.44-.38.14-.48Z"/>
                <path class="cls-4" d="M421.43,53.74c-.76-1.09-1.63-2.29-2.93-2.58l-.06,0a11.21,11.21,0,0,0-10.58,1.41,8.59,8.59,0,0,0-2.68,10,11.52,11.52,0,0,0,1.61,2.43,12.05,12.05,0,0,0,4.7,3.71A8.94,8.94,0,0,0,421.9,65,10.46,10.46,0,0,0,421.43,53.74Zm-1,4.8a2.49,2.49,0,0,1-2.87,1.29,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.54-1.51l.06,0c.63.17,1.41.29,1.75.9A2.22,2.22,0,0,1,420.45,58.54Z"/>
                <path class="cls-4" d="M468.3,82.66a5.14,5.14,0,0,0-3.1-.75.26.26,0,0,0-.25.25c0,.12.11.27.25.25a4.64,4.64,0,0,1,2.85.68c.28.15.53-.28.25-.43Z"/>
                <path class="cls-4" d="M465.2,81.66a11.75,11.75,0,0,0-8.81,3.91,9.21,9.21,0,0,0-1.73,8.76c1.12,3.34,4.55,5.46,7.82,6.33a8.7,8.7,0,0,0,8.7-2.56,9.61,9.61,0,0,0,1.37-10.86c-.14-.29-.57,0-.43.25a9.18,9.18,0,0,1-.65,9.5,8.33,8.33,0,0,1-8.05,3.38c-3.08-.55-6.7-2.54-8-5.5a8.66,8.66,0,0,1,.87-8.34,11.1,11.1,0,0,1,8.93-4.37.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M472.79,79.69c-1.09-.76-2.32-1.59-3.64-1.41h-.07a11.18,11.18,0,0,0-9.44,5,8.58,8.58,0,0,0,.9,10.29,11.34,11.34,0,0,0,2.34,1.73,12.23,12.23,0,0,0,5.69,1.87,8.93,8.93,0,0,0,8.53-7A10.47,10.47,0,0,0,472.79,79.69Zm.73,4.85a2.48,2.48,0,0,1-2.26,2.19,2.28,2.28,0,0,1-2.32-2,2,2,0,0,1,1.88-2.28h.06c.65-.05,1.42-.21,1.95.25A2.25,2.25,0,0,1,473.52,84.54Z"/>
                <path class="cls-4" d="M510.1,85.49a5.15,5.15,0,0,0-1.49,2.89.25.25,0,0,0,.5,0,4.67,4.67,0,0,1,1.34-2.54c.22-.23-.13-.59-.35-.35Z"/>
                <path class="cls-4" d="M508.38,88.25A11.8,11.8,0,0,0,510,97.78a9.23,9.23,0,0,0,8.27,3.76c3.47-.36,6.37-3.25,7.94-6.21a8.73,8.73,0,0,0-.58-9,9.65,9.65,0,0,0-10.1-3.8c-.31.07-.18.55.14.48a9.15,9.15,0,0,1,9,2.91,8.36,8.36,0,0,1,1.5,8.31,12.91,12.91,0,0,1-3,4.21,8.38,8.38,0,0,1-4.15,2.52,8.62,8.62,0,0,1-8.1-2.7,11,11,0,0,1-2.17-9.82c.07-.32-.41-.45-.48-.14Z"/>
                <path class="cls-4" d="M508.3,80.42c-1,.87-2.1,1.86-2.25,3.18l0,.07A11.19,11.19,0,0,0,508.54,94a8.6,8.6,0,0,0,10.2,1.63A11.36,11.36,0,0,0,521,93.8a12.11,12.11,0,0,0,3.2-5.06,8.94,8.94,0,0,0-4.71-10A10.47,10.47,0,0,0,508.3,80.42Zm4.89.46a2.5,2.5,0,0,1,1.57,2.73,2.28,2.28,0,0,1-2.54,1.76A2,2,0,0,1,510.46,83v-.06c.11-.64.15-1.43.72-1.83A2.24,2.24,0,0,1,513.19,80.88Z"/>
                <path class="cls-4" d="M495.86,79.56a5.17,5.17,0,0,0-2.67-1.75c-.31-.07-.44.41-.13.48a4.64,4.64,0,0,1,2.45,1.63.25.25,0,0,0,.35,0,.26.26,0,0,0,0-.36Z"/>
                <path class="cls-4" d="M493.28,77.58a11.64,11.64,0,0,0-10.09.94,9.19,9.19,0,0,0-4.14,7.77c.08,3.46,2.75,6.67,5.56,8.47a8.69,8.69,0,0,0,8.72.28,9.58,9.58,0,0,0,5-9.65c0-.32-.53-.32-.5,0A9.18,9.18,0,0,1,494.15,94a8.38,8.38,0,0,1-8.41.85,12,12,0,0,1-3.72-3,9.32,9.32,0,0,1-2.38-4.44,8.6,8.6,0,0,1,3.16-7.93,11,11,0,0,1,10.35-1.35c.3.1.43-.38.13-.48Z"/>
                <path class="cls-4" d="M501.1,78.33c-.75-1.08-1.63-2.28-2.92-2.57l-.07,0a11.17,11.17,0,0,0-10.57,1.41,8.59,8.59,0,0,0-2.69,10,11.52,11.52,0,0,0,1.61,2.43,12.08,12.08,0,0,0,4.7,3.7,8.92,8.92,0,0,0,10.41-3.63A10.46,10.46,0,0,0,501.1,78.33Zm-1,4.81a2.49,2.49,0,0,1-2.87,1.28,2.28,2.28,0,0,1-1.49-2.71,2,2,0,0,1,2.55-1.5l.06,0c.63.17,1.41.29,1.74.9A2.22,2.22,0,0,1,500.13,83.14Z"/>
                <path class="cls-4" d="M542.63,82.66a5.17,5.17,0,0,0-3.11-.75.26.26,0,0,0-.24.25c0,.12.11.27.24.25a4.65,4.65,0,0,1,2.86.68c.28.15.53-.28.25-.43Z"/>
                <path class="cls-4" d="M539.53,81.66a11.75,11.75,0,0,0-8.81,3.91A9.19,9.19,0,0,0,529,94.33c1.12,3.34,4.55,5.46,7.82,6.33a8.7,8.7,0,0,0,8.7-2.56,9.61,9.61,0,0,0,1.37-10.86c-.14-.29-.57,0-.43.25a9.18,9.18,0,0,1-.65,9.5,8.33,8.33,0,0,1-8,3.38c-3.08-.55-6.7-2.54-8-5.5a8.66,8.66,0,0,1,.87-8.34,11.1,11.1,0,0,1,8.93-4.37.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M547.12,79.69c-1.09-.76-2.32-1.59-3.64-1.41h-.07a11.18,11.18,0,0,0-9.44,5,8.58,8.58,0,0,0,.9,10.29,11.34,11.34,0,0,0,2.34,1.73,12.23,12.23,0,0,0,5.69,1.87,8.93,8.93,0,0,0,8.53-7A10.47,10.47,0,0,0,547.12,79.69Zm.73,4.85a2.5,2.5,0,0,1-2.26,2.19,2.28,2.28,0,0,1-2.32-2,2,2,0,0,1,1.88-2.28h.06c.64-.05,1.42-.21,1.94.25A2.23,2.23,0,0,1,547.85,84.54Z"/>
                <path class="cls-4" d="M584.42,85.49a5.14,5.14,0,0,0-1.48,2.89c0,.14.13.25.25.25a.25.25,0,0,0,.25-.25,4.6,4.6,0,0,1,1.34-2.54c.22-.23-.14-.59-.36-.35Z"/>
                <path class="cls-4" d="M582.71,88.25a11.8,11.8,0,0,0,1.66,9.53,9.23,9.23,0,0,0,8.27,3.76c3.47-.36,6.37-3.25,7.94-6.21a8.73,8.73,0,0,0-.58-9,9.65,9.65,0,0,0-10.1-3.8c-.31.07-.18.55.13.48a9.16,9.16,0,0,1,9,2.91,8.36,8.36,0,0,1,1.5,8.31,12.91,12.91,0,0,1-3,4.21,8.36,8.36,0,0,1-4.16,2.52,8.61,8.61,0,0,1-8.09-2.7,11,11,0,0,1-2.17-9.82c.07-.32-.41-.45-.48-.14Z"/>
                <path class="cls-4" d="M582.63,80.42c-1,.87-2.1,1.86-2.25,3.18l0,.07A11.19,11.19,0,0,0,582.87,94a8.6,8.6,0,0,0,10.2,1.63,11.36,11.36,0,0,0,2.24-1.86,12.11,12.11,0,0,0,3.2-5.06,8.94,8.94,0,0,0-4.71-10A10.47,10.47,0,0,0,582.63,80.42Zm4.89.46a2.5,2.5,0,0,1,1.57,2.73,2.28,2.28,0,0,1-2.54,1.76A2,2,0,0,1,584.79,83v-.06c.11-.64.14-1.43.72-1.83A2.24,2.24,0,0,1,587.52,80.88Z"/>
                <path class="cls-4" d="M570.19,79.56a5.17,5.17,0,0,0-2.67-1.75c-.31-.07-.44.41-.13.48a4.68,4.68,0,0,1,2.45,1.63.25.25,0,0,0,.35,0,.27.27,0,0,0,0-.36Z"/>
                <path class="cls-4" d="M567.61,77.58a11.64,11.64,0,0,0-10.09.94,9.19,9.19,0,0,0-4.14,7.77c.08,3.46,2.75,6.67,5.56,8.47a8.69,8.69,0,0,0,8.72.28,9.58,9.58,0,0,0,5-9.65c0-.32-.53-.32-.5,0A9.18,9.18,0,0,1,568.48,94a8.39,8.39,0,0,1-8.42.85,12.1,12.1,0,0,1-3.71-3A9.32,9.32,0,0,1,554,87.34a8.6,8.6,0,0,1,3.16-7.93,11,11,0,0,1,10.35-1.35c.3.1.43-.38.13-.48Z"/>
                <path class="cls-4" d="M575.43,78.33c-.75-1.08-1.63-2.28-2.92-2.57l-.07,0a11.17,11.17,0,0,0-10.57,1.41,8.58,8.58,0,0,0-2.69,10,11.52,11.52,0,0,0,1.61,2.43,12.08,12.08,0,0,0,4.7,3.7,8.92,8.92,0,0,0,10.41-3.63A10.46,10.46,0,0,0,575.43,78.33Zm-1,4.81a2.49,2.49,0,0,1-2.87,1.28,2.28,2.28,0,0,1-1.49-2.71,2,2,0,0,1,2.55-1.5l.06,0c.63.17,1.41.29,1.74.9A2.22,2.22,0,0,1,574.46,83.14Z"/>
                <path class="cls-4" d="M320.18,82.66a5.17,5.17,0,0,0-3.11-.75.27.27,0,0,0-.25.25c0,.12.12.27.25.25a4.67,4.67,0,0,1,2.86.68c.28.15.53-.28.25-.43Z"/>
                <path class="cls-4" d="M317.08,81.66a11.75,11.75,0,0,0-8.81,3.91,9.19,9.19,0,0,0-1.73,8.76c1.12,3.34,4.55,5.46,7.81,6.33a8.69,8.69,0,0,0,8.7-2.56,9.59,9.59,0,0,0,1.37-10.86c-.13-.29-.56,0-.42.25a9.18,9.18,0,0,1-.66,9.5,8.3,8.3,0,0,1-8,3.38c-3.08-.55-6.71-2.54-8-5.5a8.64,8.64,0,0,1,.87-8.34,11.1,11.1,0,0,1,8.93-4.37.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M324.66,79.69c-1.08-.76-2.32-1.59-3.63-1.41H321a11.19,11.19,0,0,0-9.45,5,8.58,8.58,0,0,0,.91,10.29,11.08,11.08,0,0,0,2.34,1.73,12.15,12.15,0,0,0,5.69,1.87,9,9,0,0,0,8.53-7A10.47,10.47,0,0,0,324.66,79.69Zm.74,4.85a2.5,2.5,0,0,1-2.26,2.19,2.27,2.27,0,0,1-2.32-2c-.07-1.14.73-2.36,1.87-2.28h.06c.65-.05,1.43-.21,1.95.25A2.23,2.23,0,0,1,325.4,84.54Z"/>
                <path class="cls-4" d="M362,85.49a5.14,5.14,0,0,0-1.48,2.89.24.24,0,0,0,.25.25.25.25,0,0,0,.24-.25,4.67,4.67,0,0,1,1.34-2.54c.22-.23-.13-.59-.35-.35Z"/>
                <path class="cls-4" d="M360.25,88.25a11.8,11.8,0,0,0,1.67,9.53,9.23,9.23,0,0,0,8.27,3.76c3.47-.36,6.37-3.25,7.94-6.21a8.71,8.71,0,0,0-.59-9,9.62,9.62,0,0,0-10.09-3.8c-.31.07-.18.55.13.48a9.17,9.17,0,0,1,9,2.91,8.36,8.36,0,0,1,1.5,8.31,12.91,12.91,0,0,1-3,4.21,8.41,8.41,0,0,1-4.16,2.52,8.64,8.64,0,0,1-8.1-2.7,11.06,11.06,0,0,1-2.17-9.82.25.25,0,0,0-.48-.14Z"/>
                <path class="cls-4" d="M360.18,80.42c-1,.87-2.1,1.86-2.25,3.18l0,.07A11.19,11.19,0,0,0,360.41,94c2.6,2.66,7,3.53,10.2,1.63a11.14,11.14,0,0,0,2.25-1.86,12,12,0,0,0,3.19-5.06,8.91,8.91,0,0,0-4.71-10A10.45,10.45,0,0,0,360.18,80.42Zm4.88.46a2.5,2.5,0,0,1,1.58,2.73,2.28,2.28,0,0,1-2.54,1.76C363,85.15,362,84.08,362.34,83v-.06c.1-.64.14-1.43.71-1.83A2.24,2.24,0,0,1,365.06,80.88Z"/>
                <path class="cls-4" d="M347.73,79.56a5.1,5.1,0,0,0-2.66-1.75c-.31-.07-.45.41-.13.48a4.62,4.62,0,0,1,2.44,1.63.25.25,0,0,0,.35,0,.25.25,0,0,0,0-.36Z"/>
                <path class="cls-4" d="M345.16,77.58a11.64,11.64,0,0,0-10.09.94,9.19,9.19,0,0,0-4.14,7.77c.07,3.46,2.75,6.67,5.56,8.47a8.69,8.69,0,0,0,8.72.28,9.58,9.58,0,0,0,5-9.65c0-.32-.53-.32-.5,0A9.18,9.18,0,0,1,346,94a8.39,8.39,0,0,1-8.42.85,12.1,12.1,0,0,1-3.71-3,9.32,9.32,0,0,1-2.38-4.44,8.6,8.6,0,0,1,3.16-7.93A11,11,0,0,1,345,78.06c.3.1.43-.38.13-.48Z"/>
                <path class="cls-4" d="M353,78.33c-.76-1.08-1.63-2.28-2.93-2.57l-.06,0a11.17,11.17,0,0,0-10.57,1.41,8.58,8.58,0,0,0-2.69,10,11.52,11.52,0,0,0,1.61,2.43,12,12,0,0,0,4.7,3.7,8.92,8.92,0,0,0,10.41-3.63A10.48,10.48,0,0,0,353,78.33Zm-1,4.81a2.5,2.5,0,0,1-2.88,1.28,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.55-1.5l.06,0c.62.17,1.4.29,1.74.9A2.22,2.22,0,0,1,352,83.14Z"/>
                <path class="cls-4" d="M394.51,82.66a5.17,5.17,0,0,0-3.11-.75.27.27,0,0,0-.25.25c0,.12.12.27.25.25a4.67,4.67,0,0,1,2.86.68c.28.15.53-.28.25-.43Z"/>
                <path class="cls-4" d="M391.41,81.66a11.75,11.75,0,0,0-8.81,3.91,9.19,9.19,0,0,0-1.73,8.76c1.11,3.34,4.55,5.46,7.81,6.33a8.69,8.69,0,0,0,8.7-2.56,9.59,9.59,0,0,0,1.37-10.86c-.13-.29-.56,0-.43.25a9.15,9.15,0,0,1-.65,9.5,8.31,8.31,0,0,1-8,3.38c-3.08-.55-6.71-2.54-8-5.5a8.64,8.64,0,0,1,.87-8.34,11.1,11.1,0,0,1,8.93-4.37.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M399,79.69c-1.08-.76-2.32-1.59-3.63-1.41h-.07a11.19,11.19,0,0,0-9.45,5,8.6,8.6,0,0,0,.9,10.29,11.4,11.4,0,0,0,2.35,1.73,12.15,12.15,0,0,0,5.69,1.87,9,9,0,0,0,8.53-7A10.47,10.47,0,0,0,399,79.69Zm.74,4.85a2.5,2.5,0,0,1-2.26,2.19,2.27,2.27,0,0,1-2.32-2c-.07-1.14.73-2.36,1.87-2.28h.06c.65-.05,1.43-.21,1.95.25A2.23,2.23,0,0,1,399.73,84.54Z"/>
                <path class="cls-4" d="M436.3,85.49a5.14,5.14,0,0,0-1.48,2.89.24.24,0,0,0,.25.25.25.25,0,0,0,.24-.25,4.67,4.67,0,0,1,1.34-2.54c.22-.23-.13-.59-.35-.35Z"/>
                <path class="cls-4" d="M434.58,88.25a11.8,11.8,0,0,0,1.67,9.53,9.23,9.23,0,0,0,8.27,3.76c3.47-.36,6.36-3.25,7.94-6.21a8.73,8.73,0,0,0-.59-9,9.62,9.62,0,0,0-10.09-3.8c-.31.07-.18.55.13.48a9.17,9.17,0,0,1,9,2.91,8.36,8.36,0,0,1,1.5,8.31,12.91,12.91,0,0,1-3,4.21,8.41,8.41,0,0,1-4.16,2.52,8.64,8.64,0,0,1-8.1-2.7,11.06,11.06,0,0,1-2.17-9.82.25.25,0,0,0-.48-.14Z"/>
                <path class="cls-4" d="M434.51,80.42c-1,.87-2.1,1.86-2.25,3.18l0,.07A11.19,11.19,0,0,0,434.74,94c2.59,2.66,7,3.53,10.2,1.63a11.14,11.14,0,0,0,2.25-1.86,12,12,0,0,0,3.19-5.06,8.91,8.91,0,0,0-4.71-10A10.45,10.45,0,0,0,434.51,80.42Zm4.88.46A2.5,2.5,0,0,1,441,83.61a2.28,2.28,0,0,1-2.54,1.76c-1.12-.22-2.12-1.29-1.76-2.38v-.06c.1-.64.14-1.43.71-1.83A2.24,2.24,0,0,1,439.39,80.88Z"/>
                <path class="cls-4" d="M422.06,79.56a5.1,5.1,0,0,0-2.66-1.75c-.31-.07-.45.41-.13.48a4.62,4.62,0,0,1,2.44,1.63.25.25,0,0,0,.35,0,.26.26,0,0,0,0-.36Z"/>
                <path class="cls-4" d="M419.49,77.58a11.64,11.64,0,0,0-10.09.94,9.19,9.19,0,0,0-4.14,7.77c.07,3.46,2.75,6.67,5.56,8.47a8.69,8.69,0,0,0,8.72.28,9.58,9.58,0,0,0,5-9.65c0-.32-.53-.32-.5,0A9.17,9.17,0,0,1,420.35,94a8.38,8.38,0,0,1-8.41.85,12.1,12.1,0,0,1-3.71-3,9.32,9.32,0,0,1-2.38-4.44A8.6,8.6,0,0,1,409,79.41a11,11,0,0,1,10.35-1.35c.3.1.43-.38.13-.48Z"/>
                <path class="cls-4" d="M427.31,78.33c-.76-1.08-1.63-2.28-2.93-2.57l-.06,0a11.16,11.16,0,0,0-10.57,1.41,8.58,8.58,0,0,0-2.69,10,11.52,11.52,0,0,0,1.61,2.43,12,12,0,0,0,4.7,3.7,8.92,8.92,0,0,0,10.41-3.63A10.48,10.48,0,0,0,427.31,78.33Zm-1,4.81a2.49,2.49,0,0,1-2.87,1.28A2.28,2.28,0,0,1,422,81.71a2,2,0,0,1,2.55-1.5l.06,0c.62.17,1.4.29,1.74.9A2.19,2.19,0,0,1,426.33,83.14Z"/>
                <path class="cls-4" d="M163.44,106.26a5.14,5.14,0,0,0-3.1-.75.26.26,0,0,0-.25.25c0,.12.11.27.25.25a4.62,4.62,0,0,1,2.85.68c.28.15.53-.28.25-.43Z"/>
                <path class="cls-4" d="M160.34,105.26a11.77,11.77,0,0,0-8.81,3.91,9.21,9.21,0,0,0-1.73,8.76c1.12,3.34,4.55,5.46,7.82,6.33a8.7,8.7,0,0,0,8.7-2.56,9.61,9.61,0,0,0,1.37-10.86c-.14-.29-.57,0-.43.25a9.18,9.18,0,0,1-.65,9.5,8.34,8.34,0,0,1-8,3.38c-3.09-.55-6.71-2.54-8-5.5a8.66,8.66,0,0,1,.87-8.34,11.11,11.11,0,0,1,8.93-4.37.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M167.93,103.29c-1.09-.76-2.32-1.59-3.64-1.41h-.06a11.18,11.18,0,0,0-9.45,4.95,8.6,8.6,0,0,0,.9,10.29,11.14,11.14,0,0,0,2.35,1.73,12.15,12.15,0,0,0,5.68,1.87,8.93,8.93,0,0,0,8.53-7A10.47,10.47,0,0,0,167.93,103.29Zm.73,4.85a2.49,2.49,0,0,1-2.25,2.19,2.29,2.29,0,0,1-2.33-2A2,2,0,0,1,166,106H166c.65,0,1.42-.21,1.95.25A2.25,2.25,0,0,1,168.66,108.14Z"/>
                <path class="cls-4" d="M205.24,109.09a5.21,5.21,0,0,0-1.49,2.89.25.25,0,1,0,.5,0,4.67,4.67,0,0,1,1.34-2.54c.22-.23-.13-.59-.35-.35Z"/>
                <path class="cls-4" d="M203.52,111.85a11.76,11.76,0,0,0,1.67,9.53,9.2,9.2,0,0,0,8.26,3.76c3.47-.36,6.37-3.25,7.94-6.22a8.71,8.71,0,0,0-.58-9,9.65,9.65,0,0,0-10.1-3.8c-.31.07-.18.55.14.48a9.15,9.15,0,0,1,9,2.91,8.36,8.36,0,0,1,1.5,8.31,12.91,12.91,0,0,1-3,4.21,8.38,8.38,0,0,1-4.15,2.52,8.62,8.62,0,0,1-8.1-2.7A11,11,0,0,1,204,112c.07-.31-.41-.44-.48-.13Z"/>
                <path class="cls-4" d="M203.44,104c-1,.87-2.1,1.86-2.25,3.18l0,.07a11.19,11.19,0,0,0,2.51,10.36,8.6,8.6,0,0,0,10.2,1.63,11.36,11.36,0,0,0,2.24-1.86,12.08,12.08,0,0,0,3.2-5.07,8.92,8.92,0,0,0-4.71-10A10.47,10.47,0,0,0,203.44,104Zm4.89.46a2.51,2.51,0,0,1,1.58,2.73,2.29,2.29,0,0,1-2.54,1.76,2,2,0,0,1-1.77-2.38l0-.06c.1-.64.14-1.43.71-1.83A2.24,2.24,0,0,1,208.33,104.48Z"/>
                <path class="cls-4" d="M191,103.16a5.13,5.13,0,0,0-2.67-1.75c-.31-.07-.44.41-.13.48a4.69,4.69,0,0,1,2.45,1.62.24.24,0,0,0,.35,0,.25.25,0,0,0,0-.35Z"/>
                <path class="cls-4" d="M188.42,101.18a11.64,11.64,0,0,0-10.09.94,9.19,9.19,0,0,0-4.14,7.77c.08,3.46,2.75,6.67,5.56,8.47a8.69,8.69,0,0,0,8.72.28,9.58,9.58,0,0,0,5-9.65c0-.32-.53-.32-.5,0a9.16,9.16,0,0,1-3.64,8.58,8.38,8.38,0,0,1-8.41.85,12,12,0,0,1-3.72-3,9.21,9.21,0,0,1-2.37-4.44A8.55,8.55,0,0,1,178,103a11,11,0,0,1,10.34-1.35c.3.1.43-.38.13-.48Z"/>
                <path class="cls-4" d="M196.25,101.93c-.76-1.09-1.64-2.28-2.93-2.57l-.06,0a11.21,11.21,0,0,0-10.58,1.4,8.59,8.59,0,0,0-2.68,10,10.81,10.81,0,0,0,1.6,2.42,12,12,0,0,0,4.71,3.71,8.93,8.93,0,0,0,10.41-3.63A10.5,10.5,0,0,0,196.25,101.93Zm-1,4.81A2.49,2.49,0,0,1,192.4,108a2.29,2.29,0,0,1-1.49-2.71,2,2,0,0,1,2.55-1.5l.06,0c.63.17,1.41.29,1.74.9A2.19,2.19,0,0,1,195.27,106.74Z"/>
                <path class="cls-4" d="M237.77,106.26a5.14,5.14,0,0,0-3.1-.75.26.26,0,0,0-.25.25c0,.12.11.27.25.25a4.64,4.64,0,0,1,2.85.68c.28.15.53-.28.25-.43Z"/>
                <path class="cls-4" d="M234.67,105.26a11.75,11.75,0,0,0-8.81,3.91,9.21,9.21,0,0,0-1.73,8.76c1.12,3.34,4.55,5.46,7.82,6.33a8.7,8.7,0,0,0,8.7-2.56A9.61,9.61,0,0,0,242,110.84c-.14-.29-.57,0-.43.25a9.18,9.18,0,0,1-.65,9.5,8.35,8.35,0,0,1-8.05,3.38c-3.08-.55-6.7-2.54-8-5.5a8.66,8.66,0,0,1,.87-8.34,11.1,11.1,0,0,1,8.93-4.37.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M242.26,103.29c-1.09-.76-2.32-1.59-3.64-1.41h-.07a11.18,11.18,0,0,0-9.44,4.95,8.58,8.58,0,0,0,.9,10.29,11.34,11.34,0,0,0,2.34,1.73,12.23,12.23,0,0,0,5.69,1.87,8.93,8.93,0,0,0,8.53-7A10.47,10.47,0,0,0,242.26,103.29Zm.73,4.85a2.49,2.49,0,0,1-2.26,2.19,2.29,2.29,0,0,1-2.32-2,2,2,0,0,1,1.88-2.28h.06c.65,0,1.42-.21,2,.25A2.25,2.25,0,0,1,243,108.14Z"/>
                <path class="cls-4" d="M279.57,109.09a5.15,5.15,0,0,0-1.49,2.89.25.25,0,1,0,.5,0,4.67,4.67,0,0,1,1.34-2.54c.22-.23-.13-.59-.35-.35Z"/>
                <path class="cls-4" d="M277.85,111.85a11.76,11.76,0,0,0,1.67,9.53,9.2,9.2,0,0,0,8.26,3.76c3.47-.36,6.37-3.25,7.94-6.22a8.71,8.71,0,0,0-.58-9,9.65,9.65,0,0,0-10.1-3.8c-.31.07-.18.55.14.48a9.15,9.15,0,0,1,9,2.91,8.36,8.36,0,0,1,1.5,8.31,12.91,12.91,0,0,1-3,4.21,8.38,8.38,0,0,1-4.15,2.52,8.62,8.62,0,0,1-8.1-2.7,11,11,0,0,1-2.17-9.83c.07-.31-.41-.44-.48-.13Z"/>
                <path class="cls-4" d="M277.77,104c-1,.87-2.1,1.86-2.25,3.18l0,.07A11.19,11.19,0,0,0,278,117.63a8.6,8.6,0,0,0,10.2,1.63,11.36,11.36,0,0,0,2.24-1.86,12.08,12.08,0,0,0,3.2-5.07,8.93,8.93,0,0,0-4.71-10A10.47,10.47,0,0,0,277.77,104Zm4.89.46a2.49,2.49,0,0,1,1.57,2.73A2.27,2.27,0,0,1,281.7,109a2,2,0,0,1-1.77-2.38v-.06c.11-.64.15-1.43.72-1.83A2.24,2.24,0,0,1,282.66,104.48Z"/>
                <path class="cls-4" d="M265.33,103.16a5.17,5.17,0,0,0-2.67-1.75c-.31-.07-.44.41-.13.48a4.69,4.69,0,0,1,2.45,1.62.24.24,0,0,0,.35,0,.25.25,0,0,0,0-.35Z"/>
                <path class="cls-4" d="M262.75,101.18a11.64,11.64,0,0,0-10.09.94,9.19,9.19,0,0,0-4.14,7.77c.08,3.46,2.75,6.67,5.56,8.47a8.69,8.69,0,0,0,8.72.28,9.58,9.58,0,0,0,5-9.65c0-.32-.53-.32-.5,0a9.18,9.18,0,0,1-3.64,8.58,8.38,8.38,0,0,1-8.41.85,12,12,0,0,1-3.72-3,9.32,9.32,0,0,1-2.38-4.44,8.6,8.6,0,0,1,3.16-7.93,11,11,0,0,1,10.35-1.35c.3.1.43-.38.13-.48Z"/>
                <path class="cls-4" d="M270.57,101.93c-.75-1.09-1.63-2.28-2.92-2.57l-.07,0a11.19,11.19,0,0,0-10.57,1.4,8.59,8.59,0,0,0-2.68,10,10.81,10.81,0,0,0,1.6,2.42,12,12,0,0,0,4.7,3.71A8.92,8.92,0,0,0,271,113.21,10.46,10.46,0,0,0,270.57,101.93Zm-1,4.81a2.49,2.49,0,0,1-2.87,1.28,2.29,2.29,0,0,1-1.49-2.71,2,2,0,0,1,2.55-1.5l.06,0c.63.17,1.41.29,1.74.9A2.22,2.22,0,0,1,269.6,106.74Z"/>
                <path class="cls-4" d="M15.32,106.26a5.17,5.17,0,0,0-3.11-.75.27.27,0,0,0-.25.25c0,.12.12.27.25.25a4.65,4.65,0,0,1,2.86.68c.28.15.53-.28.25-.43Z"/>
                <path class="cls-4" d="M12.22,105.26a11.75,11.75,0,0,0-8.81,3.91,9.19,9.19,0,0,0-1.73,8.76c1.12,3.34,4.55,5.46,7.81,6.33a8.71,8.71,0,0,0,8.71-2.56,9.62,9.62,0,0,0,1.37-10.86c-.14-.29-.57,0-.43.25a9.18,9.18,0,0,1-.66,9.5,8.32,8.32,0,0,1-8,3.38c-3.08-.55-6.7-2.54-8-5.5a8.64,8.64,0,0,1,.87-8.34,11.1,11.1,0,0,1,8.93-4.37.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M19.8,103.29c-1.08-.76-2.31-1.59-3.63-1.41H16.1a11.21,11.21,0,0,0-9.45,4.95,8.58,8.58,0,0,0,.91,10.29,11.08,11.08,0,0,0,2.34,1.73,12.15,12.15,0,0,0,5.69,1.87,8.94,8.94,0,0,0,8.53-7A10.47,10.47,0,0,0,19.8,103.29Zm.74,4.85a2.51,2.51,0,0,1-2.26,2.19,2.29,2.29,0,0,1-2.32-2c-.07-1.14.73-2.36,1.87-2.28h.07c.64,0,1.42-.21,1.94.25A2.23,2.23,0,0,1,20.54,108.14Z"/>
                <path class="cls-4" d="M57.11,109.09A5.14,5.14,0,0,0,55.63,112a.24.24,0,0,0,.25.25.26.26,0,0,0,.25-.25,4.59,4.59,0,0,1,1.33-2.54c.22-.23-.13-.59-.35-.35Z"/>
                <path class="cls-4" d="M55.4,111.85a11.76,11.76,0,0,0,1.66,9.53,9.23,9.23,0,0,0,8.27,3.76c3.47-.36,6.37-3.25,7.94-6.22a8.69,8.69,0,0,0-.59-9,9.62,9.62,0,0,0-10.09-3.8c-.31.07-.18.55.13.48a9.17,9.17,0,0,1,9,2.91,8.36,8.36,0,0,1,1.5,8.31,12.91,12.91,0,0,1-3,4.21,8.41,8.41,0,0,1-4.16,2.52,8.64,8.64,0,0,1-8.1-2.7A11,11,0,0,1,55.88,112c.07-.31-.41-.44-.48-.13Z"/>
                <path class="cls-4" d="M55.32,104c-1,.87-2.1,1.86-2.25,3.18l0,.07a11.16,11.16,0,0,0,2.51,10.36c2.59,2.66,7,3.53,10.19,1.63A11.14,11.14,0,0,0,68,117.4a12,12,0,0,0,3.19-5.07,8.91,8.91,0,0,0-4.71-10A10.46,10.46,0,0,0,55.32,104Zm4.88.46a2.5,2.5,0,0,1,1.58,2.73A2.28,2.28,0,0,1,59.24,109c-1.12-.22-2.12-1.29-1.76-2.38v-.06c.11-.64.14-1.43.71-1.83A2.25,2.25,0,0,1,60.2,104.48Z"/>
                <path class="cls-4" d="M42.88,103.16a5.17,5.17,0,0,0-2.67-1.75c-.31-.07-.45.41-.13.48a4.66,4.66,0,0,1,2.44,1.62.25.25,0,0,0,.36,0,.26.26,0,0,0,0-.35Z"/>
                <path class="cls-4" d="M40.3,101.18a11.64,11.64,0,0,0-10.09.94,9.19,9.19,0,0,0-4.14,7.77c.07,3.46,2.75,6.67,5.56,8.47a8.69,8.69,0,0,0,8.72.28,9.58,9.58,0,0,0,5-9.65c0-.32-.53-.32-.5,0a9.18,9.18,0,0,1-3.64,8.58,8.39,8.39,0,0,1-8.42.85,12.1,12.1,0,0,1-3.71-3,9.32,9.32,0,0,1-2.38-4.44A8.6,8.6,0,0,1,29.82,103a11,11,0,0,1,10.35-1.35c.3.1.43-.38.13-.48Z"/>
                <path class="cls-4" d="M48.12,101.93c-.76-1.09-1.63-2.28-2.93-2.57l-.06,0a11.19,11.19,0,0,0-10.57,1.4c-2.92,2.3-4.25,6.61-2.69,10a11.21,11.21,0,0,0,1.61,2.42,11.94,11.94,0,0,0,4.7,3.71,8.92,8.92,0,0,0,10.41-3.63A10.48,10.48,0,0,0,48.12,101.93Zm-1,4.81A2.5,2.5,0,0,1,44.27,108a2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.55-1.5l.06,0c.62.17,1.4.29,1.74.9A2.22,2.22,0,0,1,47.15,106.74Z"/>
                <path class="cls-4" d="M89.65,106.26a5.17,5.17,0,0,0-3.11-.75.27.27,0,0,0-.25.25c0,.12.12.27.25.25a4.67,4.67,0,0,1,2.86.68c.28.15.53-.28.25-.43Z"/>
                <path class="cls-4" d="M86.55,105.26a11.75,11.75,0,0,0-8.81,3.91A9.19,9.19,0,0,0,76,117.93c1.12,3.34,4.55,5.46,7.81,6.33a8.69,8.69,0,0,0,8.7-2.56,9.59,9.59,0,0,0,1.37-10.86c-.13-.29-.56,0-.42.25a9.18,9.18,0,0,1-.66,9.5,8.32,8.32,0,0,1-8,3.38c-3.08-.55-6.71-2.54-8-5.5a8.64,8.64,0,0,1,.87-8.34,11.1,11.1,0,0,1,8.93-4.37.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M94.13,103.29c-1.08-.76-2.32-1.59-3.63-1.41h-.07A11.19,11.19,0,0,0,81,106.82a8.58,8.58,0,0,0,.91,10.29,11.08,11.08,0,0,0,2.34,1.73,12.15,12.15,0,0,0,5.69,1.87,9,9,0,0,0,8.53-7A10.47,10.47,0,0,0,94.13,103.29Zm.74,4.85a2.51,2.51,0,0,1-2.26,2.19,2.28,2.28,0,0,1-2.32-2c-.07-1.14.73-2.36,1.87-2.28h.06c.65,0,1.43-.21,2,.25A2.23,2.23,0,0,1,94.87,108.14Z"/>
                <path class="cls-4" d="M131.44,109.09A5.14,5.14,0,0,0,130,112a.24.24,0,0,0,.25.25.25.25,0,0,0,.24-.25,4.67,4.67,0,0,1,1.34-2.54c.22-.23-.13-.59-.35-.35Z"/>
                <path class="cls-4" d="M129.72,111.85a11.8,11.8,0,0,0,1.67,9.53,9.23,9.23,0,0,0,8.27,3.76c3.47-.36,6.37-3.25,7.94-6.22a8.69,8.69,0,0,0-.59-9,9.62,9.62,0,0,0-10.09-3.8c-.31.07-.18.55.13.48a9.17,9.17,0,0,1,9,2.91,8.36,8.36,0,0,1,1.5,8.31,12.91,12.91,0,0,1-3,4.21,8.41,8.41,0,0,1-4.16,2.52,8.64,8.64,0,0,1-8.1-2.7A11.08,11.08,0,0,1,130.2,112a.25.25,0,0,0-.48-.13Z"/>
                <path class="cls-4" d="M129.65,104c-1,.87-2.1,1.86-2.25,3.18l0,.07a11.19,11.19,0,0,0,2.51,10.36c2.6,2.66,7,3.53,10.2,1.63a11.14,11.14,0,0,0,2.25-1.86,12,12,0,0,0,3.19-5.07,8.91,8.91,0,0,0-4.71-10A10.45,10.45,0,0,0,129.65,104Zm4.88.46a2.5,2.5,0,0,1,1.58,2.73,2.28,2.28,0,0,1-2.54,1.76c-1.12-.22-2.12-1.29-1.76-2.38v-.06c.1-.64.14-1.43.71-1.83A2.24,2.24,0,0,1,134.53,104.48Z"/>
                <path class="cls-4" d="M117.2,103.16a5.1,5.1,0,0,0-2.66-1.75c-.31-.07-.45.41-.13.48a4.66,4.66,0,0,1,2.44,1.62.25.25,0,1,0,.35-.35Z"/>
                <path class="cls-4" d="M114.63,101.18a11.64,11.64,0,0,0-10.09.94,9.19,9.19,0,0,0-4.14,7.77c.07,3.46,2.75,6.67,5.56,8.47a8.69,8.69,0,0,0,8.72.28,9.58,9.58,0,0,0,5-9.65c0-.32-.53-.32-.5,0a9.18,9.18,0,0,1-3.64,8.58,8.39,8.39,0,0,1-8.42.85,12.1,12.1,0,0,1-3.71-3,9.32,9.32,0,0,1-2.38-4.44,8.6,8.6,0,0,1,3.16-7.93,11,11,0,0,1,10.35-1.35c.3.1.43-.38.13-.48Z"/>
                <path class="cls-4" d="M122.45,101.93c-.76-1.09-1.63-2.28-2.93-2.57l-.06,0a11.19,11.19,0,0,0-10.57,1.4c-2.92,2.3-4.25,6.61-2.69,10a11.21,11.21,0,0,0,1.61,2.42,11.94,11.94,0,0,0,4.7,3.71,8.92,8.92,0,0,0,10.41-3.63A10.48,10.48,0,0,0,122.45,101.93Zm-1,4.81A2.5,2.5,0,0,1,118.6,108a2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.55-1.5l.06,0c.62.17,1.4.29,1.74.9A2.22,2.22,0,0,1,121.48,106.74Z"/>
                <path class="cls-4" d="M169.33,130.85a5.18,5.18,0,0,0-3.11-.74.26.26,0,0,0-.25.25c0,.12.11.27.25.25a4.68,4.68,0,0,1,2.85.67c.28.16.54-.27.26-.43Z"/>
                <path class="cls-4" d="M166.22,129.86a11.77,11.77,0,0,0-8.81,3.91,9.23,9.23,0,0,0-1.73,8.76c1.12,3.34,4.56,5.45,7.82,6.33a8.69,8.69,0,0,0,8.7-2.56,9.61,9.61,0,0,0,1.37-10.86c-.14-.29-.56,0-.43.25a9.14,9.14,0,0,1-.65,9.49,8.33,8.33,0,0,1-8,3.39c-3.08-.55-6.71-2.54-8-5.5a8.66,8.66,0,0,1,.88-8.35,11.08,11.08,0,0,1,8.92-4.36.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M173.81,127.89c-1.09-.77-2.32-1.59-3.63-1.41h-.07a11.18,11.18,0,0,0-9.45,4.95,8.6,8.6,0,0,0,.9,10.29,11.78,11.78,0,0,0,2.35,1.73,12,12,0,0,0,5.69,1.86,8.91,8.91,0,0,0,8.52-7A10.45,10.45,0,0,0,173.81,127.89Zm.74,4.85a2.51,2.51,0,0,1-2.26,2.19,2.3,2.3,0,0,1-2.33-2c-.06-1.14.74-2.37,1.88-2.29h.06c.65,0,1.42-.21,1.95.25A2.25,2.25,0,0,1,174.55,132.74Z"/>
                <path class="cls-4" d="M211.12,133.69a5.21,5.21,0,0,0-1.49,2.89.25.25,0,0,0,.25.25.25.25,0,0,0,.25-.25,4.63,4.63,0,0,1,1.34-2.54c.22-.24-.13-.59-.35-.35Z"/>
                <path class="cls-4" d="M209.4,136.45a11.76,11.76,0,0,0,1.67,9.53,9.2,9.2,0,0,0,8.26,3.76c3.47-.36,6.37-3.25,7.94-6.22a8.69,8.69,0,0,0-.58-9,9.61,9.61,0,0,0-10.09-3.8c-.32.06-.18.54.13.48a9.15,9.15,0,0,1,9,2.91,8.33,8.33,0,0,1,1.5,8.31,12.88,12.88,0,0,1-2.95,4.21,8,8,0,0,1-12.26-.18,11.08,11.08,0,0,1-2.17-9.83c.07-.31-.41-.44-.48-.13Z"/>
                <path class="cls-4" d="M209.33,128.62c-1,.87-2.11,1.86-2.25,3.18l0,.06a11.2,11.2,0,0,0,2.51,10.37,8.58,8.58,0,0,0,10.2,1.62A11.43,11.43,0,0,0,222,142a12,12,0,0,0,3.19-5.07,8.92,8.92,0,0,0-4.71-10A10.48,10.48,0,0,0,209.33,128.62Zm4.88.46a2.5,2.5,0,0,1,1.58,2.72,2.29,2.29,0,0,1-2.54,1.76,2,2,0,0,1-1.77-2.37l0-.06c.1-.64.14-1.43.71-1.83A2.21,2.21,0,0,1,214.21,129.08Z"/>
                <path class="cls-4" d="M196.88,127.76a5.07,5.07,0,0,0-2.67-1.75c-.31-.07-.44.41-.13.48a4.69,4.69,0,0,1,2.45,1.62.25.25,0,0,0,.35-.35Z"/>
                <path class="cls-4" d="M194.31,125.77a11.67,11.67,0,0,0-10.09,1,9.16,9.16,0,0,0-4.15,7.76c.08,3.46,2.75,6.68,5.57,8.48a8.71,8.71,0,0,0,8.72.28,9.58,9.58,0,0,0,4.95-9.65c0-.32-.52-.32-.49,0a9.2,9.2,0,0,1-3.65,8.58,8.38,8.38,0,0,1-8.41.85A12.17,12.17,0,0,1,183,140a9.29,9.29,0,0,1-2.37-4.44,8.54,8.54,0,0,1,3.16-7.92,11,11,0,0,1,10.34-1.36c.31.11.44-.37.14-.48Z"/>
                <path class="cls-4" d="M202.13,126.53c-.76-1.09-1.64-2.28-2.93-2.57l-.06,0a11.21,11.21,0,0,0-10.58,1.4c-2.91,2.3-4.24,6.6-2.68,10a11.27,11.27,0,0,0,1.61,2.43,11.87,11.87,0,0,0,4.7,3.71,8.92,8.92,0,0,0,10.41-3.63A10.48,10.48,0,0,0,202.13,126.53Zm-1,4.81a2.49,2.49,0,0,1-2.87,1.28,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.54-1.5l.06,0c.63.17,1.41.29,1.75.9A2.22,2.22,0,0,1,201.15,131.34Z"/>
                <path class="cls-4" d="M243.65,130.85a5.13,5.13,0,0,0-3.1-.74.25.25,0,0,0-.25.25c0,.12.11.27.25.25a4.68,4.68,0,0,1,2.85.67.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M240.55,129.86a11.77,11.77,0,0,0-8.81,3.91,9.21,9.21,0,0,0-1.73,8.76c1.12,3.34,4.56,5.45,7.82,6.33a8.7,8.7,0,0,0,8.7-2.56,9.61,9.61,0,0,0,1.37-10.86c-.14-.29-.57,0-.43.25a9.14,9.14,0,0,1-.65,9.49,8.33,8.33,0,0,1-8,3.39c-3.08-.55-6.71-2.54-8-5.5a8.66,8.66,0,0,1,.88-8.35,11.08,11.08,0,0,1,8.92-4.36.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M248.14,127.89c-1.09-.77-2.32-1.59-3.63-1.41h-.07a11.18,11.18,0,0,0-9.45,4.95,8.6,8.6,0,0,0,.9,10.29,11.78,11.78,0,0,0,2.35,1.73,12,12,0,0,0,5.69,1.86,8.91,8.91,0,0,0,8.52-7A10.45,10.45,0,0,0,248.14,127.89Zm.73,4.85a2.49,2.49,0,0,1-2.25,2.19,2.3,2.3,0,0,1-2.33-2c-.06-1.14.74-2.37,1.88-2.29h.06c.65,0,1.42-.21,2,.25A2.21,2.21,0,0,1,248.87,132.74Z"/>
                <path class="cls-4" d="M285.45,133.69a5.21,5.21,0,0,0-1.49,2.89.25.25,0,0,0,.5,0A4.63,4.63,0,0,1,285.8,134c.22-.24-.13-.59-.35-.35Z"/>
                <path class="cls-4" d="M283.73,136.45A11.76,11.76,0,0,0,285.4,146a9.2,9.2,0,0,0,8.26,3.76c3.47-.36,6.37-3.25,7.94-6.22a8.71,8.71,0,0,0-.58-9,9.62,9.62,0,0,0-10.09-3.8c-.32.06-.18.54.13.48a9.15,9.15,0,0,1,9,2.91,8.35,8.35,0,0,1,1.5,8.31,12.77,12.77,0,0,1-3,4.21,8.52,8.52,0,0,1-4.15,2.52,8.64,8.64,0,0,1-8.1-2.7,11.08,11.08,0,0,1-2.17-9.83c.07-.31-.41-.44-.48-.13Z"/>
                <path class="cls-4" d="M283.65,128.62c-1,.87-2.1,1.86-2.25,3.18l0,.06a11.2,11.2,0,0,0,2.51,10.37,8.58,8.58,0,0,0,10.2,1.62,11.73,11.73,0,0,0,2.25-1.85,12.12,12.12,0,0,0,3.19-5.07,8.92,8.92,0,0,0-4.71-10A10.5,10.5,0,0,0,283.65,128.62Zm4.89.46a2.5,2.5,0,0,1,1.58,2.72,2.29,2.29,0,0,1-2.54,1.76,2,2,0,0,1-1.77-2.37l0-.06c.1-.64.14-1.43.71-1.83A2.21,2.21,0,0,1,288.54,129.08Z"/>
                <path class="cls-4" d="M271.21,127.76a5.07,5.07,0,0,0-2.67-1.75c-.31-.07-.44.41-.13.48a4.69,4.69,0,0,1,2.45,1.62.25.25,0,0,0,.35-.35Z"/>
                <path class="cls-4" d="M268.63,125.77a11.65,11.65,0,0,0-10.08,1,9.16,9.16,0,0,0-4.15,7.76c.08,3.46,2.75,6.68,5.56,8.48a8.73,8.73,0,0,0,8.73.28,9.6,9.6,0,0,0,4.95-9.65c0-.32-.52-.32-.49,0a9.2,9.2,0,0,1-3.65,8.58,8.38,8.38,0,0,1-8.41.85,12.17,12.17,0,0,1-3.72-3.05,9.21,9.21,0,0,1-2.37-4.44,8.54,8.54,0,0,1,3.16-7.92,11,11,0,0,1,10.34-1.36c.3.11.43-.37.13-.48Z"/>
                <path class="cls-4" d="M276.46,126.53c-.76-1.09-1.64-2.28-2.93-2.57l-.06,0a11.21,11.21,0,0,0-10.58,1.4c-2.91,2.3-4.24,6.6-2.68,10a10.91,10.91,0,0,0,1.61,2.43,11.87,11.87,0,0,0,4.7,3.71,8.93,8.93,0,0,0,10.41-3.63A10.48,10.48,0,0,0,276.46,126.53Zm-1,4.81a2.49,2.49,0,0,1-2.87,1.28,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.54-1.5l.06,0c.63.17,1.41.29,1.75.9A2.22,2.22,0,0,1,275.48,131.34Z"/>
                <path class="cls-4" d="M21.2,130.85a5.15,5.15,0,0,0-3.1-.74.25.25,0,0,0-.25.25c0,.12.11.27.25.25a4.7,4.7,0,0,1,2.85.67.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M18.1,129.86a11.75,11.75,0,0,0-8.81,3.91,9.21,9.21,0,0,0-1.73,8.76c1.12,3.34,4.55,5.45,7.82,6.33a8.7,8.7,0,0,0,8.7-2.56,9.61,9.61,0,0,0,1.37-10.86c-.14-.29-.57,0-.43.25a9.16,9.16,0,0,1-.65,9.49,8.33,8.33,0,0,1-8,3.39c-3.08-.55-6.7-2.54-8-5.5a8.69,8.69,0,0,1,.87-8.35,11.09,11.09,0,0,1,8.93-4.36.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M25.69,127.89c-1.09-.77-2.32-1.59-3.64-1.41H22a11.18,11.18,0,0,0-9.44,4.95,8.58,8.58,0,0,0,.9,10.29,11.71,11.71,0,0,0,2.34,1.73,12.1,12.1,0,0,0,5.69,1.86,8.91,8.91,0,0,0,8.53-7A10.45,10.45,0,0,0,25.69,127.89Zm.73,4.85a2.49,2.49,0,0,1-2.26,2.19,2.29,2.29,0,0,1-2.32-2c-.07-1.14.74-2.37,1.88-2.29h.06c.65,0,1.42-.21,1.95.25A2.24,2.24,0,0,1,26.42,132.74Z"/>
                <path class="cls-4" d="M63,133.69a5.09,5.09,0,0,0-1.49,2.89c0,.14.13.25.25.25a.25.25,0,0,0,.25-.25A4.57,4.57,0,0,1,63.35,134c.22-.24-.14-.59-.35-.35Z"/>
                <path class="cls-4" d="M61.28,136.45A11.8,11.8,0,0,0,62.94,146a9.23,9.23,0,0,0,8.27,3.76c3.47-.36,6.37-3.25,7.94-6.22a8.71,8.71,0,0,0-.58-9,9.63,9.63,0,0,0-10.1-3.8c-.31.06-.18.54.14.48a9.15,9.15,0,0,1,9,2.91,8.35,8.35,0,0,1,1.5,8.31,12.91,12.91,0,0,1-3,4.21A8.51,8.51,0,0,1,72,149.11a8.61,8.61,0,0,1-8.09-2.7,11,11,0,0,1-2.17-9.83c.07-.31-.41-.44-.48-.13Z"/>
                <path class="cls-4" d="M61.2,128.62c-1,.87-2.1,1.86-2.25,3.18l0,.06a11.2,11.2,0,0,0,2.51,10.37,8.58,8.58,0,0,0,10.2,1.62A11.67,11.67,0,0,0,73.88,142a12.14,12.14,0,0,0,3.2-5.07,9,9,0,0,0-4.71-10A10.49,10.49,0,0,0,61.2,128.62Zm4.89.46a2.48,2.48,0,0,1,1.57,2.72,2.28,2.28,0,0,1-2.54,1.76,2,2,0,0,1-1.76-2.37v-.06c.11-.64.14-1.43.72-1.83A2.21,2.21,0,0,1,66.09,129.08Z"/>
                <path class="cls-4" d="M48.76,127.76A5.11,5.11,0,0,0,46.09,126c-.31-.07-.44.41-.13.48a4.72,4.72,0,0,1,2.45,1.62.25.25,0,0,0,.35-.35Z"/>
                <path class="cls-4" d="M46.18,125.77a11.67,11.67,0,0,0-10.09,1A9.18,9.18,0,0,0,32,134.48c.08,3.46,2.75,6.68,5.56,8.48a8.71,8.71,0,0,0,8.72.28,9.59,9.59,0,0,0,5-9.65c0-.32-.53-.32-.5,0a9.19,9.19,0,0,1-3.64,8.58,8.39,8.39,0,0,1-8.42.85A12.13,12.13,0,0,1,34.92,140a9.23,9.23,0,0,1-2.38-4.44,8.59,8.59,0,0,1,3.16-7.92,11,11,0,0,1,10.35-1.36c.3.11.43-.37.13-.48Z"/>
                <path class="cls-4" d="M54,126.53c-.75-1.09-1.63-2.28-2.92-2.57l-.07,0a11.19,11.19,0,0,0-10.57,1.4,8.6,8.6,0,0,0-2.69,10,11.27,11.27,0,0,0,1.61,2.43,12,12,0,0,0,4.7,3.71,8.92,8.92,0,0,0,10.41-3.63A10.46,10.46,0,0,0,54,126.53Zm-1,4.81a2.49,2.49,0,0,1-2.87,1.28,2.28,2.28,0,0,1-1.49-2.71,2,2,0,0,1,2.55-1.5l.06,0c.63.17,1.41.29,1.74.9A2.22,2.22,0,0,1,53,131.34Z"/>
                <path class="cls-4" d="M95.53,130.85a5.16,5.16,0,0,0-3.11-.74.25.25,0,0,0-.24.25c0,.12.11.27.24.25a4.71,4.71,0,0,1,2.86.67.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M92.43,129.86a11.75,11.75,0,0,0-8.81,3.91,9.18,9.18,0,0,0-1.73,8.76c1.12,3.34,4.55,5.45,7.82,6.33a8.7,8.7,0,0,0,8.7-2.56,9.61,9.61,0,0,0,1.37-10.86c-.14-.29-.57,0-.43.25a9.16,9.16,0,0,1-.65,9.49,8.33,8.33,0,0,1-8,3.39c-3.08-.55-6.7-2.54-8-5.5a8.66,8.66,0,0,1,.87-8.35,11.09,11.09,0,0,1,8.93-4.36.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M100,127.89c-1.09-.77-2.32-1.59-3.64-1.41h-.07a11.18,11.18,0,0,0-9.44,4.95,8.58,8.58,0,0,0,.9,10.29,11.71,11.71,0,0,0,2.34,1.73,12.06,12.06,0,0,0,5.69,1.86,8.91,8.91,0,0,0,8.53-7A10.47,10.47,0,0,0,100,127.89Zm.73,4.85a2.51,2.51,0,0,1-2.26,2.19,2.29,2.29,0,0,1-2.32-2c-.07-1.14.74-2.37,1.87-2.29h.07c.64,0,1.42-.21,1.94.25A2.22,2.22,0,0,1,100.75,132.74Z"/>
                <path class="cls-4" d="M137.32,133.69a5.14,5.14,0,0,0-1.48,2.89c0,.14.13.25.25.25a.25.25,0,0,0,.25-.25,4.51,4.51,0,0,1,1.34-2.54c.21-.24-.14-.59-.36-.35Z"/>
                <path class="cls-4" d="M135.61,136.45a11.8,11.8,0,0,0,1.66,9.53,9.23,9.23,0,0,0,8.27,3.76c3.47-.36,6.37-3.25,7.94-6.22a8.69,8.69,0,0,0-.59-9,9.61,9.61,0,0,0-10.09-3.8c-.31.06-.18.54.13.48a9.17,9.17,0,0,1,9,2.91,8.35,8.35,0,0,1,1.5,8.31,12.91,12.91,0,0,1-3,4.21,8.51,8.51,0,0,1-4.16,2.52,8.61,8.61,0,0,1-8.09-2.7,11,11,0,0,1-2.17-9.83c.07-.31-.41-.44-.48-.13Z"/>
                <path class="cls-4" d="M135.53,128.62c-1,.87-2.1,1.86-2.25,3.18l0,.06a11.2,11.2,0,0,0,2.51,10.37,8.58,8.58,0,0,0,10.2,1.62,11.67,11.67,0,0,0,2.24-1.85,12.14,12.14,0,0,0,3.2-5.07,9,9,0,0,0-4.71-10A10.49,10.49,0,0,0,135.53,128.62Zm4.89.46A2.48,2.48,0,0,1,142,131.8a2.28,2.28,0,0,1-2.54,1.76,2,2,0,0,1-1.76-2.37v-.06c.11-.64.14-1.43.72-1.83A2.21,2.21,0,0,1,140.42,129.08Z"/>
                <path class="cls-4" d="M123.09,127.76a5.11,5.11,0,0,0-2.67-1.75c-.31-.07-.44.41-.13.48a4.72,4.72,0,0,1,2.45,1.62.25.25,0,0,0,.35,0,.26.26,0,0,0,0-.35Z"/>
                <path class="cls-4" d="M120.51,125.77a11.67,11.67,0,0,0-10.09,1,9.18,9.18,0,0,0-4.14,7.76c.08,3.46,2.75,6.68,5.56,8.48a8.71,8.71,0,0,0,8.72.28,9.59,9.59,0,0,0,5-9.65c0-.32-.53-.32-.5,0a9.19,9.19,0,0,1-3.64,8.58A8.39,8.39,0,0,1,113,143a12.13,12.13,0,0,1-3.71-3.05,9.23,9.23,0,0,1-2.38-4.44,8.59,8.59,0,0,1,3.16-7.92,11,11,0,0,1,10.35-1.36c.3.11.43-.37.13-.48Z"/>
                <path class="cls-4" d="M128.33,126.53c-.76-1.09-1.63-2.28-2.92-2.57l-.07,0a11.19,11.19,0,0,0-10.57,1.4c-2.92,2.3-4.25,6.6-2.69,10a11.27,11.27,0,0,0,1.61,2.43,11.94,11.94,0,0,0,4.7,3.71,8.92,8.92,0,0,0,10.41-3.63A10.46,10.46,0,0,0,128.33,126.53Zm-1,4.81a2.49,2.49,0,0,1-2.87,1.28,2.27,2.27,0,0,1-1.49-2.71,2,2,0,0,1,2.55-1.5l.06,0c.62.17,1.41.29,1.74.9A2.22,2.22,0,0,1,127.36,131.34Z"/>
                <path class="cls-4" d="M462.42,106.26a5.17,5.17,0,0,0-3.11-.75.27.27,0,0,0-.25.25c0,.12.12.27.25.25a4.65,4.65,0,0,1,2.86.68c.28.15.53-.28.25-.43Z"/>
                <path class="cls-4" d="M459.32,105.26a11.75,11.75,0,0,0-8.81,3.91,9.19,9.19,0,0,0-1.73,8.76c1.12,3.34,4.55,5.46,7.81,6.33a8.71,8.71,0,0,0,8.71-2.56,9.62,9.62,0,0,0,1.37-10.86c-.14-.29-.57,0-.43.25a9.18,9.18,0,0,1-.66,9.5,8.32,8.32,0,0,1-8,3.38c-3.08-.55-6.7-2.54-8-5.5a8.64,8.64,0,0,1,.87-8.34,11.1,11.1,0,0,1,8.93-4.37.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M466.9,103.29c-1.08-.76-2.31-1.59-3.63-1.41h-.07a11.21,11.21,0,0,0-9.45,4.95,8.6,8.6,0,0,0,.91,10.29,11.08,11.08,0,0,0,2.34,1.73,12.15,12.15,0,0,0,5.69,1.87,8.94,8.94,0,0,0,8.53-7A10.47,10.47,0,0,0,466.9,103.29Zm.74,4.85a2.51,2.51,0,0,1-2.26,2.19,2.29,2.29,0,0,1-2.32-2c-.07-1.14.73-2.36,1.87-2.28H465c.64,0,1.42-.21,1.94.25A2.23,2.23,0,0,1,467.64,108.14Z"/>
                <path class="cls-4" d="M504.21,109.09a5.14,5.14,0,0,0-1.48,2.89.24.24,0,0,0,.25.25.25.25,0,0,0,.25-.25,4.59,4.59,0,0,1,1.33-2.54c.22-.23-.13-.59-.35-.35Z"/>
                <path class="cls-4" d="M502.5,111.85a11.76,11.76,0,0,0,1.66,9.53,9.23,9.23,0,0,0,8.27,3.76c3.47-.36,6.37-3.25,7.94-6.22a8.69,8.69,0,0,0-.59-9,9.62,9.62,0,0,0-10.09-3.8c-.31.07-.18.55.13.48a9.17,9.17,0,0,1,9,2.91,8.36,8.36,0,0,1,1.5,8.31,12.91,12.91,0,0,1-3,4.21,8.41,8.41,0,0,1-4.16,2.52,8.61,8.61,0,0,1-8.09-2.7A11,11,0,0,1,503,112c.07-.31-.41-.44-.48-.13Z"/>
                <path class="cls-4" d="M502.42,104c-1,.87-2.1,1.86-2.25,3.18l0,.07a11.19,11.19,0,0,0,2.51,10.36c2.59,2.66,7,3.53,10.19,1.63a11.14,11.14,0,0,0,2.25-1.86,12,12,0,0,0,3.19-5.07,8.91,8.91,0,0,0-4.71-10A10.46,10.46,0,0,0,502.42,104Zm4.88.46a2.49,2.49,0,0,1,1.58,2.73,2.28,2.28,0,0,1-2.54,1.76c-1.12-.22-2.12-1.29-1.76-2.38v-.06c.11-.64.14-1.43.71-1.83A2.25,2.25,0,0,1,507.3,104.48Z"/>
                <path class="cls-4" d="M490,103.16a5.17,5.17,0,0,0-2.67-1.75c-.31-.07-.44.41-.13.48a4.66,4.66,0,0,1,2.44,1.62.25.25,0,0,0,.36,0,.26.26,0,0,0,0-.35Z"/>
                <path class="cls-4" d="M487.4,101.18a11.64,11.64,0,0,0-10.09.94,9.19,9.19,0,0,0-4.14,7.77c.08,3.46,2.75,6.67,5.56,8.47a8.69,8.69,0,0,0,8.72.28,9.58,9.58,0,0,0,5-9.65c0-.32-.53-.32-.5,0a9.18,9.18,0,0,1-3.64,8.58,8.39,8.39,0,0,1-8.42.85,12.1,12.1,0,0,1-3.71-3,9.32,9.32,0,0,1-2.38-4.44,8.6,8.6,0,0,1,3.16-7.93,11,11,0,0,1,10.35-1.35c.3.1.43-.38.13-.48Z"/>
                <path class="cls-4" d="M495.22,101.93c-.76-1.09-1.63-2.28-2.93-2.57l-.06,0a11.19,11.19,0,0,0-10.57,1.4c-2.92,2.3-4.25,6.61-2.69,10a11.21,11.21,0,0,0,1.61,2.42,11.94,11.94,0,0,0,4.7,3.71,8.92,8.92,0,0,0,10.41-3.63A10.46,10.46,0,0,0,495.22,101.93Zm-1,4.81a2.5,2.5,0,0,1-2.88,1.28,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.55-1.5l.06,0c.62.17,1.4.29,1.74.9A2.22,2.22,0,0,1,494.25,106.74Z"/>
                <path class="cls-4" d="M536.75,106.26a5.17,5.17,0,0,0-3.11-.75.27.27,0,0,0-.25.25c0,.12.12.27.25.25a4.67,4.67,0,0,1,2.86.68c.28.15.53-.28.25-.43Z"/>
                <path class="cls-4" d="M533.65,105.26a11.75,11.75,0,0,0-8.81,3.91,9.19,9.19,0,0,0-1.73,8.76c1.12,3.34,4.55,5.46,7.81,6.33a8.68,8.68,0,0,0,8.7-2.56A9.59,9.59,0,0,0,541,110.84c-.13-.29-.56,0-.42.25a9.18,9.18,0,0,1-.66,9.5,8.32,8.32,0,0,1-8,3.38c-3.08-.55-6.71-2.54-8-5.5a8.64,8.64,0,0,1,.87-8.34,11.1,11.1,0,0,1,8.93-4.37.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M541.23,103.29c-1.08-.76-2.32-1.59-3.63-1.41h-.07a11.21,11.21,0,0,0-9.45,4.95,8.58,8.58,0,0,0,.91,10.29,11.08,11.08,0,0,0,2.34,1.73,12.15,12.15,0,0,0,5.69,1.87,9,9,0,0,0,8.53-7A10.47,10.47,0,0,0,541.23,103.29Zm.74,4.85a2.51,2.51,0,0,1-2.26,2.19,2.29,2.29,0,0,1-2.32-2c-.07-1.14.73-2.36,1.87-2.28h.07c.64,0,1.42-.21,1.94.25A2.23,2.23,0,0,1,542,108.14Z"/>
                <path class="cls-4" d="M578.54,109.09a5.14,5.14,0,0,0-1.48,2.89.24.24,0,0,0,.25.25.26.26,0,0,0,.25-.25,4.59,4.59,0,0,1,1.33-2.54c.22-.23-.13-.59-.35-.35Z"/>
                <path class="cls-4" d="M576.83,111.85a11.76,11.76,0,0,0,1.66,9.53,9.23,9.23,0,0,0,8.27,3.76c3.47-.36,6.37-3.25,7.94-6.22a8.69,8.69,0,0,0-.59-9,9.62,9.62,0,0,0-10.09-3.8c-.31.07-.18.55.13.48a9.17,9.17,0,0,1,9,2.91,8.36,8.36,0,0,1,1.5,8.31,12.91,12.91,0,0,1-3,4.21,8.41,8.41,0,0,1-4.16,2.52,8.64,8.64,0,0,1-8.1-2.7A11.08,11.08,0,0,1,577.3,112c.08-.31-.4-.44-.47-.13Z"/>
                <path class="cls-4" d="M576.75,104c-1,.87-2.1,1.86-2.25,3.18l0,.07A11.13,11.13,0,0,0,577,117.63c2.59,2.66,7,3.53,10.19,1.63a11.14,11.14,0,0,0,2.25-1.86,12,12,0,0,0,3.19-5.07,8.91,8.91,0,0,0-4.71-10A10.46,10.46,0,0,0,576.75,104Zm4.88.46a2.5,2.5,0,0,1,1.58,2.73,2.28,2.28,0,0,1-2.54,1.76c-1.12-.22-2.12-1.29-1.76-2.38v-.06c.11-.64.14-1.43.71-1.83A2.24,2.24,0,0,1,581.63,104.48Z"/>
                <path class="cls-4" d="M564.3,103.16a5.1,5.1,0,0,0-2.66-1.75c-.31-.07-.45.41-.13.48a4.66,4.66,0,0,1,2.44,1.62.25.25,0,1,0,.35-.35Z"/>
                <path class="cls-4" d="M561.73,101.18a11.64,11.64,0,0,0-10.09.94,9.19,9.19,0,0,0-4.14,7.77c.07,3.46,2.75,6.67,5.56,8.47a8.69,8.69,0,0,0,8.72.28,9.58,9.58,0,0,0,5-9.65c0-.32-.53-.32-.5,0a9.18,9.18,0,0,1-3.64,8.58,8.39,8.39,0,0,1-8.42.85,12.1,12.1,0,0,1-3.71-3,9.32,9.32,0,0,1-2.38-4.44,8.6,8.6,0,0,1,3.16-7.93,11,11,0,0,1,10.35-1.35c.3.1.43-.38.13-.48Z"/>
                <path class="cls-4" d="M569.55,101.93c-.76-1.09-1.63-2.28-2.93-2.57l-.06,0a11.19,11.19,0,0,0-10.57,1.4c-2.92,2.3-4.25,6.61-2.69,10a11.21,11.21,0,0,0,1.61,2.42,11.94,11.94,0,0,0,4.7,3.71A8.92,8.92,0,0,0,570,113.21,10.48,10.48,0,0,0,569.55,101.93Zm-1,4.81A2.5,2.5,0,0,1,565.7,108a2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.55-1.5l.06,0c.62.17,1.4.29,1.74.9A2.22,2.22,0,0,1,568.58,106.74Z"/>
                <path class="cls-4" d="M314.3,106.26a5.18,5.18,0,0,0-3.11-.75.27.27,0,0,0-.25.25c0,.12.12.27.25.25a4.67,4.67,0,0,1,2.86.68c.28.15.53-.28.25-.43Z"/>
                <path class="cls-4" d="M311.19,105.26a11.77,11.77,0,0,0-8.81,3.91,9.21,9.21,0,0,0-1.72,8.76c1.11,3.34,4.55,5.46,7.81,6.33a8.69,8.69,0,0,0,8.7-2.56,9.61,9.61,0,0,0,1.37-10.86c-.14-.29-.56,0-.43.25a9.15,9.15,0,0,1-.65,9.5,8.33,8.33,0,0,1-8,3.38c-3.08-.55-6.71-2.54-8-5.5a8.64,8.64,0,0,1,.88-8.34,11.09,11.09,0,0,1,8.92-4.37.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M318.78,103.29c-1.09-.76-2.32-1.59-3.63-1.41h-.07a11.19,11.19,0,0,0-9.45,4.95,8.6,8.6,0,0,0,.9,10.29,11.4,11.4,0,0,0,2.35,1.73,12.15,12.15,0,0,0,5.69,1.87,9,9,0,0,0,8.53-7A10.49,10.49,0,0,0,318.78,103.29Zm.74,4.85a2.51,2.51,0,0,1-2.26,2.19,2.3,2.3,0,0,1-2.33-2c-.06-1.14.74-2.36,1.88-2.28h.06c.65,0,1.42-.21,1.95.25A2.23,2.23,0,0,1,319.52,108.14Z"/>
                <path class="cls-4" d="M356.09,109.09a5.14,5.14,0,0,0-1.48,2.89.24.24,0,0,0,.24.25.25.25,0,0,0,.25-.25,4.67,4.67,0,0,1,1.34-2.54c.22-.23-.13-.59-.35-.35Z"/>
                <path class="cls-4" d="M354.37,111.85a11.76,11.76,0,0,0,1.67,9.53,9.21,9.21,0,0,0,8.26,3.76c3.48-.36,6.37-3.25,7.95-6.22a8.71,8.71,0,0,0-.59-9,9.62,9.62,0,0,0-10.09-3.8c-.32.07-.18.55.13.48a9.15,9.15,0,0,1,9,2.91,8.34,8.34,0,0,1,1.5,8.31,12.74,12.74,0,0,1-3,4.21,8.45,8.45,0,0,1-4.16,2.52,8.64,8.64,0,0,1-8.1-2.7,11.08,11.08,0,0,1-2.17-9.83.25.25,0,0,0-.48-.13Z"/>
                <path class="cls-4" d="M354.3,104c-1,.87-2.1,1.86-2.25,3.18l0,.07a11.19,11.19,0,0,0,2.51,10.36,8.6,8.6,0,0,0,10.2,1.63A11.14,11.14,0,0,0,367,117.4a12,12,0,0,0,3.19-5.07,8.91,8.91,0,0,0-4.71-10A10.45,10.45,0,0,0,354.3,104Zm4.88.46a2.51,2.51,0,0,1,1.58,2.73,2.29,2.29,0,0,1-2.54,1.76c-1.12-.22-2.12-1.29-1.77-2.38l0-.06c.1-.64.14-1.43.71-1.83A2.24,2.24,0,0,1,359.18,104.48Z"/>
                <path class="cls-4" d="M341.85,103.16a5.14,5.14,0,0,0-2.66-1.75c-.32-.07-.45.41-.14.48a4.69,4.69,0,0,1,2.45,1.62.24.24,0,0,0,.35,0,.25.25,0,0,0,0-.35Z"/>
                <path class="cls-4" d="M339.28,101.18a11.64,11.64,0,0,0-10.09.94,9.18,9.18,0,0,0-4.15,7.77c.08,3.46,2.76,6.67,5.57,8.47a8.69,8.69,0,0,0,8.72.28,9.56,9.56,0,0,0,4.95-9.65c0-.32-.52-.32-.49,0a9.17,9.17,0,0,1-3.65,8.58,8.38,8.38,0,0,1-8.41.85,12.25,12.25,0,0,1-3.72-3,9.38,9.38,0,0,1-2.37-4.44A8.55,8.55,0,0,1,328.8,103a11,11,0,0,1,10.34-1.35c.31.1.44-.38.14-.48Z"/>
                <path class="cls-4" d="M347.1,101.93c-.76-1.09-1.63-2.28-2.93-2.57l-.06,0a11.21,11.21,0,0,0-10.58,1.4c-2.91,2.3-4.24,6.61-2.68,10a11.21,11.21,0,0,0,1.61,2.42,11.94,11.94,0,0,0,4.7,3.71,8.92,8.92,0,0,0,10.41-3.63A10.48,10.48,0,0,0,347.1,101.93Zm-1,4.81a2.49,2.49,0,0,1-2.87,1.28,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.54-1.5l.06,0c.63.17,1.41.29,1.75.9A2.22,2.22,0,0,1,346.12,106.74Z"/>
                <path class="cls-4" d="M388.63,106.26a5.18,5.18,0,0,0-3.11-.75.27.27,0,0,0-.25.25c0,.12.11.27.25.25a4.62,4.62,0,0,1,2.85.68c.28.15.54-.28.26-.43Z"/>
                <path class="cls-4" d="M385.52,105.26a11.77,11.77,0,0,0-8.81,3.91,9.24,9.24,0,0,0-1.73,8.76c1.12,3.34,4.56,5.46,7.82,6.33a8.69,8.69,0,0,0,8.7-2.56,9.61,9.61,0,0,0,1.37-10.86c-.14-.29-.56,0-.43.25a9.15,9.15,0,0,1-.65,9.5,8.34,8.34,0,0,1-8,3.38c-3.08-.55-6.71-2.54-8-5.5a8.64,8.64,0,0,1,.88-8.34,11.09,11.09,0,0,1,8.92-4.37.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M393.11,103.29c-1.09-.76-2.32-1.59-3.63-1.41h-.07a11.18,11.18,0,0,0-9.45,4.95,8.6,8.6,0,0,0,.9,10.29,11.4,11.4,0,0,0,2.35,1.73,12.15,12.15,0,0,0,5.69,1.87,8.93,8.93,0,0,0,8.52-7A10.47,10.47,0,0,0,393.11,103.29Zm.74,4.85a2.51,2.51,0,0,1-2.26,2.19,2.3,2.3,0,0,1-2.33-2c-.06-1.14.74-2.36,1.88-2.28h.06c.65,0,1.42-.21,1.95.25A2.26,2.26,0,0,1,393.85,108.14Z"/>
                <path class="cls-4" d="M430.42,109.09a5.21,5.21,0,0,0-1.49,2.89.25.25,0,0,0,.25.25.25.25,0,0,0,.25-.25,4.67,4.67,0,0,1,1.34-2.54c.22-.23-.13-.59-.35-.35Z"/>
                <path class="cls-4" d="M428.7,111.85a11.76,11.76,0,0,0,1.67,9.53,9.2,9.2,0,0,0,8.26,3.76c3.48-.36,6.37-3.25,7.94-6.22a8.69,8.69,0,0,0-.58-9,9.62,9.62,0,0,0-10.09-3.8c-.32.07-.18.55.13.48a9.15,9.15,0,0,1,9,2.91,8.34,8.34,0,0,1,1.5,8.31,12.88,12.88,0,0,1-2.95,4.21,8.45,8.45,0,0,1-4.16,2.52,8.64,8.64,0,0,1-8.1-2.7,11.08,11.08,0,0,1-2.17-9.83c.07-.31-.41-.44-.48-.13Z"/>
                <path class="cls-4" d="M428.63,104c-1,.87-2.11,1.86-2.25,3.18l0,.07a11.19,11.19,0,0,0,2.51,10.36,8.6,8.6,0,0,0,10.2,1.63,11.14,11.14,0,0,0,2.25-1.86,12,12,0,0,0,3.19-5.07,8.91,8.91,0,0,0-4.71-10A10.45,10.45,0,0,0,428.63,104Zm4.88.46a2.51,2.51,0,0,1,1.58,2.73,2.29,2.29,0,0,1-2.54,1.76c-1.12-.22-2.12-1.29-1.77-2.38l0-.06c.1-.64.14-1.43.71-1.83A2.24,2.24,0,0,1,433.51,104.48Z"/>
                <path class="cls-4" d="M416.18,103.16a5.14,5.14,0,0,0-2.66-1.75c-.32-.07-.45.41-.14.48a4.69,4.69,0,0,1,2.45,1.62.24.24,0,0,0,.35,0,.25.25,0,0,0,0-.35Z"/>
                <path class="cls-4" d="M413.61,101.18a11.64,11.64,0,0,0-10.09.94,9.18,9.18,0,0,0-4.15,7.77c.08,3.46,2.75,6.67,5.57,8.47a8.69,8.69,0,0,0,8.72.28,9.57,9.57,0,0,0,4.95-9.65c0-.32-.52-.32-.49,0a9.19,9.19,0,0,1-3.65,8.58,8.38,8.38,0,0,1-8.41.85,12.25,12.25,0,0,1-3.72-3,9.38,9.38,0,0,1-2.37-4.44,8.55,8.55,0,0,1,3.16-7.93,11,11,0,0,1,10.34-1.35c.31.1.44-.38.14-.48Z"/>
                <path class="cls-4" d="M421.43,101.93c-.76-1.09-1.63-2.28-2.93-2.57l-.06,0a11.21,11.21,0,0,0-10.58,1.4c-2.91,2.3-4.24,6.61-2.68,10a11.21,11.21,0,0,0,1.61,2.42,11.87,11.87,0,0,0,4.7,3.71,8.92,8.92,0,0,0,10.41-3.63A10.48,10.48,0,0,0,421.43,101.93Zm-1,4.81a2.49,2.49,0,0,1-2.87,1.28,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.54-1.5l.06,0c.63.17,1.41.29,1.75.9A2.22,2.22,0,0,1,420.45,106.74Z"/>
                <path class="cls-4" d="M468.3,130.85a5.13,5.13,0,0,0-3.1-.74.25.25,0,0,0-.25.25c0,.12.11.27.25.25a4.7,4.7,0,0,1,2.85.67.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M465.2,129.86a11.75,11.75,0,0,0-8.81,3.91,9.21,9.21,0,0,0-1.73,8.76c1.12,3.34,4.55,5.45,7.82,6.33a8.7,8.7,0,0,0,8.7-2.56,9.61,9.61,0,0,0,1.37-10.86c-.14-.29-.57,0-.43.25a9.16,9.16,0,0,1-.65,9.49,8.33,8.33,0,0,1-8.05,3.39c-3.08-.55-6.7-2.54-8-5.5a8.69,8.69,0,0,1,.87-8.35,11.09,11.09,0,0,1,8.93-4.36.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M472.79,127.89c-1.09-.77-2.32-1.59-3.64-1.41h-.07a11.18,11.18,0,0,0-9.44,4.95,8.58,8.58,0,0,0,.9,10.29,11.71,11.71,0,0,0,2.34,1.73,12.1,12.1,0,0,0,5.69,1.86,8.91,8.91,0,0,0,8.53-7A10.45,10.45,0,0,0,472.79,127.89Zm.73,4.85a2.49,2.49,0,0,1-2.26,2.19,2.29,2.29,0,0,1-2.32-2c-.07-1.14.74-2.37,1.88-2.29h.06c.65,0,1.42-.21,1.95.25A2.24,2.24,0,0,1,473.52,132.74Z"/>
                <path class="cls-4" d="M510.1,133.69a5.15,5.15,0,0,0-1.49,2.89.25.25,0,1,0,.5,0,4.63,4.63,0,0,1,1.34-2.54c.22-.24-.13-.59-.35-.35Z"/>
                <path class="cls-4" d="M508.38,136.45A11.8,11.8,0,0,0,510,146a9.23,9.23,0,0,0,8.27,3.76c3.47-.36,6.37-3.25,7.94-6.22a8.71,8.71,0,0,0-.58-9,9.63,9.63,0,0,0-10.1-3.8c-.31.06-.18.54.14.48a9.15,9.15,0,0,1,9,2.91,8.35,8.35,0,0,1,1.5,8.31,12.91,12.91,0,0,1-3,4.21,8.52,8.52,0,0,1-4.15,2.52,8.62,8.62,0,0,1-8.1-2.7,11,11,0,0,1-2.17-9.83c.07-.31-.41-.44-.48-.13Z"/>
                <path class="cls-4" d="M508.3,128.62c-1,.87-2.1,1.86-2.25,3.18l0,.06a11.2,11.2,0,0,0,2.51,10.37,8.58,8.58,0,0,0,10.2,1.62A11.67,11.67,0,0,0,521,142a12.14,12.14,0,0,0,3.2-5.07,9,9,0,0,0-4.71-10A10.49,10.49,0,0,0,508.3,128.62Zm4.89.46a2.48,2.48,0,0,1,1.57,2.72,2.28,2.28,0,0,1-2.54,1.76,2,2,0,0,1-1.76-2.37v-.06c.11-.64.15-1.43.72-1.83A2.21,2.21,0,0,1,513.19,129.08Z"/>
                <path class="cls-4" d="M495.86,127.76a5.11,5.11,0,0,0-2.67-1.75c-.31-.07-.44.41-.13.48a4.69,4.69,0,0,1,2.45,1.62.25.25,0,0,0,.35-.35Z"/>
                <path class="cls-4" d="M493.28,125.77a11.67,11.67,0,0,0-10.09,1,9.18,9.18,0,0,0-4.14,7.76c.08,3.46,2.75,6.68,5.56,8.48a8.71,8.71,0,0,0,8.72.28,9.59,9.59,0,0,0,5-9.65c0-.32-.53-.32-.5,0a9.19,9.19,0,0,1-3.64,8.58,8.38,8.38,0,0,1-8.41.85A12.05,12.05,0,0,1,482,140a9.23,9.23,0,0,1-2.38-4.44,8.59,8.59,0,0,1,3.16-7.92,11,11,0,0,1,10.35-1.36c.3.11.43-.37.13-.48Z"/>
                <path class="cls-4" d="M501.1,126.53c-.75-1.09-1.63-2.28-2.92-2.57l-.07,0a11.19,11.19,0,0,0-10.57,1.4,8.6,8.6,0,0,0-2.69,10,11.27,11.27,0,0,0,1.61,2.43,12,12,0,0,0,4.7,3.71,8.92,8.92,0,0,0,10.41-3.63A10.46,10.46,0,0,0,501.1,126.53Zm-1,4.81a2.49,2.49,0,0,1-2.87,1.28,2.28,2.28,0,0,1-1.49-2.71,2,2,0,0,1,2.55-1.5l.06,0c.63.17,1.41.29,1.74.9A2.22,2.22,0,0,1,500.13,131.34Z"/>
                <path class="cls-4" d="M542.63,130.85a5.16,5.16,0,0,0-3.11-.74.25.25,0,0,0-.24.25c0,.12.11.27.24.25a4.71,4.71,0,0,1,2.86.67.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M539.53,129.86a11.75,11.75,0,0,0-8.81,3.91,9.18,9.18,0,0,0-1.73,8.76c1.12,3.34,4.55,5.45,7.82,6.33a8.7,8.7,0,0,0,8.7-2.56,9.61,9.61,0,0,0,1.37-10.86c-.14-.29-.57,0-.43.25a9.16,9.16,0,0,1-.65,9.49,8.33,8.33,0,0,1-8,3.39c-3.08-.55-6.7-2.54-8-5.5a8.69,8.69,0,0,1,.87-8.35,11.09,11.09,0,0,1,8.93-4.36.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M547.12,127.89c-1.09-.77-2.32-1.59-3.64-1.41h-.07a11.18,11.18,0,0,0-9.44,4.95,8.58,8.58,0,0,0,.9,10.29,11.71,11.71,0,0,0,2.34,1.73,12.1,12.1,0,0,0,5.69,1.86,8.91,8.91,0,0,0,8.53-7A10.45,10.45,0,0,0,547.12,127.89Zm.73,4.85a2.51,2.51,0,0,1-2.26,2.19,2.29,2.29,0,0,1-2.32-2c-.07-1.14.74-2.37,1.88-2.29h.06c.64,0,1.42-.21,1.94.25A2.22,2.22,0,0,1,547.85,132.74Z"/>
                <path class="cls-4" d="M584.42,133.69a5.14,5.14,0,0,0-1.48,2.89c0,.14.13.25.25.25a.25.25,0,0,0,.25-.25,4.57,4.57,0,0,1,1.34-2.54c.22-.24-.14-.59-.36-.35Z"/>
                <path class="cls-4" d="M582.71,136.45a11.8,11.8,0,0,0,1.66,9.53,9.23,9.23,0,0,0,8.27,3.76c3.47-.36,6.37-3.25,7.94-6.22a8.71,8.71,0,0,0-.58-9,9.63,9.63,0,0,0-10.1-3.8c-.31.06-.18.54.13.48a9.16,9.16,0,0,1,9,2.91,8.35,8.35,0,0,1,1.5,8.31,12.91,12.91,0,0,1-3,4.21,8.51,8.51,0,0,1-4.16,2.52,8.61,8.61,0,0,1-8.09-2.7,11,11,0,0,1-2.17-9.83c.07-.31-.41-.44-.48-.13Z"/>
                <path class="cls-4" d="M582.63,128.62c-1,.87-2.1,1.86-2.25,3.18l0,.06a11.2,11.2,0,0,0,2.51,10.37,8.58,8.58,0,0,0,10.2,1.62,11.67,11.67,0,0,0,2.24-1.85,12.14,12.14,0,0,0,3.2-5.07,9,9,0,0,0-4.71-10A10.49,10.49,0,0,0,582.63,128.62Zm4.89.46a2.48,2.48,0,0,1,1.57,2.72,2.28,2.28,0,0,1-2.54,1.76,2,2,0,0,1-1.76-2.37v-.06c.11-.64.14-1.43.72-1.83A2.21,2.21,0,0,1,587.52,129.08Z"/>
                <path class="cls-4" d="M570.19,127.76a5.11,5.11,0,0,0-2.67-1.75c-.31-.07-.44.41-.13.48a4.72,4.72,0,0,1,2.45,1.62.25.25,0,0,0,.35,0,.26.26,0,0,0,0-.35Z"/>
                <path class="cls-4" d="M567.61,125.77a11.67,11.67,0,0,0-10.09,1,9.18,9.18,0,0,0-4.14,7.76c.08,3.46,2.75,6.68,5.56,8.48a8.71,8.71,0,0,0,8.72.28,9.59,9.59,0,0,0,5-9.65c0-.32-.53-.32-.5,0a9.19,9.19,0,0,1-3.64,8.58,8.39,8.39,0,0,1-8.42.85,12.13,12.13,0,0,1-3.71-3.05,9.23,9.23,0,0,1-2.38-4.44,8.59,8.59,0,0,1,3.16-7.92,11,11,0,0,1,10.35-1.36c.3.11.43-.37.13-.48Z"/>
                <path class="cls-4" d="M575.43,126.53c-.75-1.09-1.63-2.28-2.92-2.57l-.07,0a11.19,11.19,0,0,0-10.57,1.4c-2.92,2.3-4.25,6.6-2.69,10a11.27,11.27,0,0,0,1.61,2.43,12,12,0,0,0,4.7,3.71,8.92,8.92,0,0,0,10.41-3.63A10.46,10.46,0,0,0,575.43,126.53Zm-1,4.81a2.49,2.49,0,0,1-2.87,1.28,2.28,2.28,0,0,1-1.49-2.71,2,2,0,0,1,2.55-1.5l.06,0c.63.17,1.41.29,1.74.9A2.22,2.22,0,0,1,574.46,131.34Z"/>
                <path class="cls-4" d="M320.18,130.85a5.16,5.16,0,0,0-3.11-.74.26.26,0,0,0-.25.25c0,.12.12.27.25.25a4.73,4.73,0,0,1,2.86.67.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M317.08,129.86a11.75,11.75,0,0,0-8.81,3.91,9.18,9.18,0,0,0-1.73,8.76c1.12,3.34,4.55,5.45,7.81,6.33a8.69,8.69,0,0,0,8.7-2.56,9.59,9.59,0,0,0,1.37-10.86c-.13-.29-.56,0-.42.25a9.16,9.16,0,0,1-.66,9.49,8.31,8.31,0,0,1-8,3.39c-3.08-.55-6.71-2.54-8-5.5a8.66,8.66,0,0,1,.87-8.35,11.09,11.09,0,0,1,8.93-4.36.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M324.66,127.89c-1.08-.77-2.32-1.59-3.63-1.41H321a11.19,11.19,0,0,0-9.45,4.95,8.58,8.58,0,0,0,.91,10.29,11.43,11.43,0,0,0,2.34,1.73,12,12,0,0,0,5.69,1.86,8.93,8.93,0,0,0,8.53-7A10.46,10.46,0,0,0,324.66,127.89Zm.74,4.85a2.51,2.51,0,0,1-2.26,2.19,2.28,2.28,0,0,1-2.32-2c-.07-1.14.73-2.37,1.87-2.29h.06c.65,0,1.43-.21,1.95.25A2.22,2.22,0,0,1,325.4,132.74Z"/>
                <path class="cls-4" d="M362,133.69a5.14,5.14,0,0,0-1.48,2.89.24.24,0,0,0,.25.25.25.25,0,0,0,.24-.25,4.63,4.63,0,0,1,1.34-2.54c.22-.24-.13-.59-.35-.35Z"/>
                <path class="cls-4" d="M360.25,136.45a11.8,11.8,0,0,0,1.67,9.53,9.23,9.23,0,0,0,8.27,3.76c3.47-.36,6.37-3.25,7.94-6.22a8.69,8.69,0,0,0-.59-9,9.61,9.61,0,0,0-10.09-3.8c-.31.06-.18.54.13.48a9.17,9.17,0,0,1,9,2.91,8.35,8.35,0,0,1,1.5,8.31,12.91,12.91,0,0,1-3,4.21,8,8,0,0,1-12.26-.18,11.08,11.08,0,0,1-2.17-9.83.25.25,0,0,0-.48-.13Z"/>
                <path class="cls-4" d="M360.18,128.62c-1,.87-2.1,1.86-2.25,3.18l0,.06a11.2,11.2,0,0,0,2.51,10.37c2.6,2.66,7,3.53,10.2,1.62a11.43,11.43,0,0,0,2.25-1.85,12,12,0,0,0,3.19-5.07,8.92,8.92,0,0,0-4.71-10A10.48,10.48,0,0,0,360.18,128.62Zm4.88.46a2.49,2.49,0,0,1,1.58,2.72,2.28,2.28,0,0,1-2.54,1.76c-1.12-.21-2.12-1.28-1.76-2.37v-.06c.1-.64.14-1.43.71-1.83A2.21,2.21,0,0,1,365.06,129.08Z"/>
                <path class="cls-4" d="M347.73,127.76a5,5,0,0,0-2.66-1.75c-.31-.07-.45.41-.13.48a4.66,4.66,0,0,1,2.44,1.62.25.25,0,0,0,.35,0,.24.24,0,0,0,0-.35Z"/>
                <path class="cls-4" d="M345.16,125.77a11.67,11.67,0,0,0-10.09,1,9.18,9.18,0,0,0-4.14,7.76c.07,3.46,2.75,6.68,5.56,8.48a8.71,8.71,0,0,0,8.72.28,9.59,9.59,0,0,0,5-9.65c0-.32-.53-.32-.5,0a9.19,9.19,0,0,1-3.64,8.58,8.39,8.39,0,0,1-8.42.85A12.13,12.13,0,0,1,333.9,140a9.23,9.23,0,0,1-2.38-4.44,8.59,8.59,0,0,1,3.16-7.92A11,11,0,0,1,345,126.25c.3.11.43-.37.13-.48Z"/>
                <path class="cls-4" d="M353,126.53c-.76-1.09-1.63-2.28-2.93-2.57l-.06,0a11.19,11.19,0,0,0-10.57,1.4c-2.92,2.3-4.25,6.6-2.69,10a11.27,11.27,0,0,0,1.61,2.43,11.94,11.94,0,0,0,4.7,3.71,8.92,8.92,0,0,0,10.41-3.63A10.48,10.48,0,0,0,353,126.53Zm-1,4.81a2.5,2.5,0,0,1-2.88,1.28,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.55-1.5l.06,0c.62.17,1.4.29,1.74.9A2.22,2.22,0,0,1,352,131.34Z"/>
                <path class="cls-4" d="M394.51,130.85a5.16,5.16,0,0,0-3.11-.74.26.26,0,0,0-.25.25c0,.12.12.27.25.25a4.73,4.73,0,0,1,2.86.67.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M391.41,129.86a11.75,11.75,0,0,0-8.81,3.91,9.18,9.18,0,0,0-1.73,8.76c1.11,3.34,4.55,5.45,7.81,6.33a8.69,8.69,0,0,0,8.7-2.56,9.59,9.59,0,0,0,1.37-10.86c-.13-.29-.56,0-.43.25a9.14,9.14,0,0,1-.65,9.49,8.32,8.32,0,0,1-8,3.39c-3.08-.55-6.71-2.54-8-5.5a8.66,8.66,0,0,1,.87-8.35,11.09,11.09,0,0,1,8.93-4.36.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M399,127.89c-1.08-.77-2.32-1.59-3.63-1.41h-.07a11.19,11.19,0,0,0-9.45,4.95,8.6,8.6,0,0,0,.9,10.29,11.78,11.78,0,0,0,2.35,1.73,12,12,0,0,0,5.69,1.86,8.93,8.93,0,0,0,8.53-7A10.46,10.46,0,0,0,399,127.89Zm.74,4.85a2.51,2.51,0,0,1-2.26,2.19,2.28,2.28,0,0,1-2.32-2c-.07-1.14.73-2.37,1.87-2.29h.06c.65,0,1.43-.21,1.95.25A2.22,2.22,0,0,1,399.73,132.74Z"/>
                <path class="cls-4" d="M436.3,133.69a5.14,5.14,0,0,0-1.48,2.89.24.24,0,0,0,.25.25.25.25,0,0,0,.24-.25,4.63,4.63,0,0,1,1.34-2.54c.22-.24-.13-.59-.35-.35Z"/>
                <path class="cls-4" d="M434.58,136.45a11.8,11.8,0,0,0,1.67,9.53,9.23,9.23,0,0,0,8.27,3.76c3.47-.36,6.36-3.25,7.94-6.22a8.71,8.71,0,0,0-.59-9,9.61,9.61,0,0,0-10.09-3.8c-.31.06-.18.54.13.48a9.17,9.17,0,0,1,9,2.91,8.35,8.35,0,0,1,1.5,8.31,12.91,12.91,0,0,1-3,4.21,8,8,0,0,1-12.26-.18,11.08,11.08,0,0,1-2.17-9.83.25.25,0,0,0-.48-.13Z"/>
                <path class="cls-4" d="M434.51,128.62c-1,.87-2.1,1.86-2.25,3.18l0,.06a11.2,11.2,0,0,0,2.51,10.37c2.59,2.66,7,3.53,10.2,1.62a11.43,11.43,0,0,0,2.25-1.85,12,12,0,0,0,3.19-5.07,8.92,8.92,0,0,0-4.71-10A10.48,10.48,0,0,0,434.51,128.62Zm4.88.46A2.49,2.49,0,0,1,441,131.8a2.28,2.28,0,0,1-2.54,1.76c-1.12-.21-2.12-1.28-1.76-2.37v-.06c.1-.64.14-1.43.71-1.83A2.21,2.21,0,0,1,439.39,129.08Z"/>
                <path class="cls-4" d="M422.06,127.76A5,5,0,0,0,419.4,126c-.31-.07-.45.41-.13.48a4.66,4.66,0,0,1,2.44,1.62.25.25,0,0,0,.35-.35Z"/>
                <path class="cls-4" d="M419.49,125.77a11.67,11.67,0,0,0-10.09,1,9.18,9.18,0,0,0-4.14,7.76c.07,3.46,2.75,6.68,5.56,8.48a8.71,8.71,0,0,0,8.72.28,9.59,9.59,0,0,0,5-9.65c0-.32-.53-.32-.5,0a9.17,9.17,0,0,1-3.65,8.58,8.38,8.38,0,0,1-8.41.85,12.13,12.13,0,0,1-3.71-3.05,9.23,9.23,0,0,1-2.38-4.44,8.59,8.59,0,0,1,3.16-7.92,11,11,0,0,1,10.35-1.36c.3.11.43-.37.13-.48Z"/>
                <path class="cls-4" d="M427.31,126.53c-.76-1.09-1.63-2.28-2.93-2.57l-.06,0a11.19,11.19,0,0,0-10.57,1.4c-2.92,2.3-4.25,6.6-2.69,10a11.27,11.27,0,0,0,1.61,2.43,11.94,11.94,0,0,0,4.7,3.71,8.92,8.92,0,0,0,10.41-3.63A10.48,10.48,0,0,0,427.31,126.53Zm-1,4.81a2.49,2.49,0,0,1-2.87,1.28,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.55-1.5l.06,0c.62.17,1.4.29,1.74.9A2.19,2.19,0,0,1,426.33,131.34Z"/>
                <path class="cls-4" d="M163.44,156.67a5.13,5.13,0,0,0-3.1-.74.25.25,0,0,0-.25.25.25.25,0,0,0,.25.25,4.61,4.61,0,0,1,2.85.67.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M160.34,155.68a11.79,11.79,0,0,0-8.81,3.9,9.23,9.23,0,0,0-1.73,8.77c1.12,3.34,4.55,5.45,7.82,6.33a8.7,8.7,0,0,0,8.7-2.56,9.62,9.62,0,0,0,1.37-10.87c-.14-.28-.57,0-.43.25a9.18,9.18,0,0,1-.65,9.5,8.33,8.33,0,0,1-8,3.39c-3.09-.55-6.71-2.54-8-5.5a8.68,8.68,0,0,1,.87-8.35,11.14,11.14,0,0,1,8.93-4.37.25.25,0,0,0,0-.49Z"/>
                <path class="cls-4" d="M167.93,153.7c-1.09-.76-2.32-1.58-3.64-1.41h-.06a11.21,11.21,0,0,0-9.45,5,8.58,8.58,0,0,0,.9,10.28,11.14,11.14,0,0,0,2.35,1.73,12,12,0,0,0,5.68,1.87,8.92,8.92,0,0,0,8.53-7A10.47,10.47,0,0,0,167.93,153.7Zm.73,4.85a2.47,2.47,0,0,1-2.25,2.19,2.27,2.27,0,0,1-2.33-2c-.07-1.14.74-2.37,1.88-2.29H166a2.52,2.52,0,0,1,1.95.25A2.22,2.22,0,0,1,168.66,158.55Z"/>
                <path class="cls-4" d="M205.24,159.5a5.24,5.24,0,0,0-1.49,2.9.25.25,0,0,0,.5,0,4.7,4.7,0,0,1,1.34-2.55c.22-.23-.13-.58-.35-.35Z"/>
                <path class="cls-4" d="M203.52,162.27a11.74,11.74,0,0,0,1.67,9.52,9.16,9.16,0,0,0,8.26,3.76c3.47-.35,6.37-3.24,7.94-6.21a8.71,8.71,0,0,0-.58-9,9.63,9.63,0,0,0-10.1-3.8c-.31.06-.18.54.14.48a9.15,9.15,0,0,1,9,2.91,8.34,8.34,0,0,1,1.5,8.3,13,13,0,0,1-3,4.22,8.43,8.43,0,0,1-4.15,2.51,8.59,8.59,0,0,1-8.1-2.69A11.06,11.06,0,0,1,204,162.4c.07-.31-.41-.44-.48-.13Z"/>
                <path class="cls-4" d="M203.44,154.43c-1,.87-2.1,1.87-2.25,3.19l0,.06a11.2,11.2,0,0,0,2.51,10.37,8.58,8.58,0,0,0,10.2,1.62,11.67,11.67,0,0,0,2.24-1.85,12.14,12.14,0,0,0,3.2-5.07,8.94,8.94,0,0,0-4.71-10A10.47,10.47,0,0,0,203.44,154.43Zm4.89.47a2.5,2.5,0,0,1,1.58,2.72,2.29,2.29,0,0,1-2.54,1.76A2,2,0,0,1,205.6,157l0-.07c.1-.64.14-1.42.71-1.82A2.21,2.21,0,0,1,208.33,154.9Z"/>
                <path class="cls-4" d="M191,153.58a5.14,5.14,0,0,0-2.67-1.76c-.31-.07-.44.41-.13.48a4.7,4.7,0,0,1,2.45,1.63.25.25,0,0,0,.35-.35Z"/>
                <path class="cls-4" d="M188.42,151.59a11.64,11.64,0,0,0-10.09.95,9.18,9.18,0,0,0-4.14,7.76c.08,3.46,2.75,6.68,5.56,8.47a8.69,8.69,0,0,0,8.72.29,9.59,9.59,0,0,0,5-9.66c0-.31-.53-.32-.5,0a9.18,9.18,0,0,1-3.64,8.59,8.38,8.38,0,0,1-8.41.85,12.05,12.05,0,0,1-3.72-3.05,9.13,9.13,0,0,1-2.37-4.44,8.54,8.54,0,0,1,3.16-7.92,11,11,0,0,1,10.34-1.36c.3.11.43-.37.13-.48Z"/>
                <path class="cls-4" d="M196.25,152.35c-.76-1.09-1.64-2.29-2.93-2.57l-.06,0a11.21,11.21,0,0,0-10.58,1.41,8.58,8.58,0,0,0-2.68,10,10.87,10.87,0,0,0,1.6,2.43,12.15,12.15,0,0,0,4.71,3.71,8.93,8.93,0,0,0,10.41-3.64A10.48,10.48,0,0,0,196.25,152.35Zm-1,4.81a2.5,2.5,0,0,1-2.87,1.28,2.3,2.3,0,0,1-1.49-2.71,2,2,0,0,1,2.55-1.51l.06,0c.63.17,1.41.29,1.74.9A2.21,2.21,0,0,1,195.27,157.16Z"/>
                <path class="cls-4" d="M237.77,156.67a5.13,5.13,0,0,0-3.1-.74.25.25,0,0,0-.25.25.25.25,0,0,0,.25.25,4.63,4.63,0,0,1,2.85.67.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M234.67,155.68a11.78,11.78,0,0,0-8.81,3.9,9.23,9.23,0,0,0-1.73,8.77c1.12,3.34,4.55,5.45,7.82,6.33a8.7,8.7,0,0,0,8.7-2.56A9.62,9.62,0,0,0,242,161.25c-.14-.28-.57,0-.43.25a9.18,9.18,0,0,1-.65,9.5,8.33,8.33,0,0,1-8.05,3.39c-3.08-.55-6.7-2.54-8-5.5a8.68,8.68,0,0,1,.87-8.35,11.13,11.13,0,0,1,8.93-4.37.25.25,0,0,0,0-.49Z"/>
                <path class="cls-4" d="M242.26,153.7c-1.09-.76-2.32-1.58-3.64-1.41h-.07a11.21,11.21,0,0,0-9.44,5,8.57,8.57,0,0,0,.9,10.28,11.34,11.34,0,0,0,2.34,1.73,12.11,12.11,0,0,0,5.69,1.87,8.92,8.92,0,0,0,8.53-7A10.47,10.47,0,0,0,242.26,153.7Zm.73,4.85a2.47,2.47,0,0,1-2.26,2.19,2.27,2.27,0,0,1-2.32-2c-.07-1.14.74-2.37,1.88-2.29h.06a2.52,2.52,0,0,1,2,.25A2.22,2.22,0,0,1,243,158.55Z"/>
                <path class="cls-4" d="M279.57,159.5a5.18,5.18,0,0,0-1.49,2.9.25.25,0,1,0,.5,0,4.7,4.7,0,0,1,1.34-2.55c.22-.23-.13-.58-.35-.35Z"/>
                <path class="cls-4" d="M277.85,162.27a11.74,11.74,0,0,0,1.67,9.52,9.16,9.16,0,0,0,8.26,3.76c3.47-.35,6.37-3.24,7.94-6.21a8.71,8.71,0,0,0-.58-9,9.63,9.63,0,0,0-10.1-3.8c-.31.06-.18.54.14.48a9.15,9.15,0,0,1,9,2.91,8.34,8.34,0,0,1,1.5,8.3,13,13,0,0,1-3,4.22,8.43,8.43,0,0,1-4.15,2.51,8.59,8.59,0,0,1-8.1-2.69,11.06,11.06,0,0,1-2.17-9.83c.07-.31-.41-.44-.48-.13Z"/>
                <path class="cls-4" d="M277.77,154.43c-1,.87-2.1,1.87-2.25,3.19l0,.06A11.2,11.2,0,0,0,278,168.05a8.58,8.58,0,0,0,10.2,1.62,11.67,11.67,0,0,0,2.24-1.85,12.14,12.14,0,0,0,3.2-5.07,9,9,0,0,0-4.71-10A10.47,10.47,0,0,0,277.77,154.43Zm4.89.47a2.48,2.48,0,0,1,1.57,2.72,2.27,2.27,0,0,1-2.53,1.76,2,2,0,0,1-1.77-2.37v-.07c.11-.64.15-1.42.72-1.82A2.21,2.21,0,0,1,282.66,154.9Z"/>
                <path class="cls-4" d="M265.33,153.58a5.18,5.18,0,0,0-2.67-1.76c-.31-.07-.44.41-.13.48a4.7,4.7,0,0,1,2.45,1.63.25.25,0,0,0,.35-.35Z"/>
                <path class="cls-4" d="M262.75,151.59a11.64,11.64,0,0,0-10.09.95,9.18,9.18,0,0,0-4.14,7.76c.08,3.46,2.75,6.68,5.56,8.47a8.69,8.69,0,0,0,8.72.29,9.59,9.59,0,0,0,5-9.66c0-.31-.53-.32-.5,0a9.21,9.21,0,0,1-3.64,8.59,8.38,8.38,0,0,1-8.41.85,12.05,12.05,0,0,1-3.72-3.05,9.23,9.23,0,0,1-2.38-4.44,8.59,8.59,0,0,1,3.16-7.92,11,11,0,0,1,10.35-1.36c.3.11.43-.37.13-.48Z"/>
                <path class="cls-4" d="M270.57,152.35c-.75-1.09-1.63-2.29-2.92-2.57l-.07,0A11.19,11.19,0,0,0,257,151.15a8.58,8.58,0,0,0-2.68,10,10.87,10.87,0,0,0,1.6,2.43,12.2,12.2,0,0,0,4.7,3.71A8.92,8.92,0,0,0,271,163.62,10.44,10.44,0,0,0,270.57,152.35Zm-1,4.81a2.5,2.5,0,0,1-2.87,1.28,2.3,2.3,0,0,1-1.49-2.71,2,2,0,0,1,2.55-1.51l.06,0c.63.17,1.41.29,1.74.9A2.24,2.24,0,0,1,269.6,157.16Z"/>
                <path class="cls-4" d="M15.32,156.67a5.16,5.16,0,0,0-3.11-.74.25.25,0,0,0-.25.25.26.26,0,0,0,.25.25,4.64,4.64,0,0,1,2.86.67.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M12.22,155.68a11.78,11.78,0,0,0-8.81,3.9,9.2,9.2,0,0,0-1.73,8.77c1.12,3.34,4.55,5.45,7.81,6.33a8.71,8.71,0,0,0,8.71-2.56,9.64,9.64,0,0,0,1.37-10.87c-.14-.28-.57,0-.43.25a9.18,9.18,0,0,1-.66,9.5,8.31,8.31,0,0,1-8,3.39c-3.08-.55-6.7-2.54-8-5.5a8.66,8.66,0,0,1,.87-8.35,11.13,11.13,0,0,1,8.93-4.37.25.25,0,0,0,0-.49Z"/>
                <path class="cls-4" d="M19.8,153.7c-1.08-.76-2.31-1.58-3.63-1.41H16.1a11.24,11.24,0,0,0-9.45,5,8.57,8.57,0,0,0,.91,10.28,11.08,11.08,0,0,0,2.34,1.73,12,12,0,0,0,5.69,1.87,8.93,8.93,0,0,0,8.53-7A10.47,10.47,0,0,0,19.8,153.7Zm.74,4.85a2.49,2.49,0,0,1-2.26,2.19,2.27,2.27,0,0,1-2.32-2c-.07-1.14.73-2.37,1.87-2.29h.07c.64-.06,1.42-.21,1.94.25A2.2,2.2,0,0,1,20.54,158.55Z"/>
                <path class="cls-4" d="M57.11,159.5a5.16,5.16,0,0,0-1.48,2.9c0,.13.12.25.25.25a.27.27,0,0,0,.25-.25,4.62,4.62,0,0,1,1.33-2.55c.22-.23-.13-.58-.35-.35Z"/>
                <path class="cls-4" d="M55.4,162.27a11.74,11.74,0,0,0,1.66,9.52,9.19,9.19,0,0,0,8.27,3.76c3.47-.35,6.37-3.24,7.94-6.21a8.69,8.69,0,0,0-.59-9,9.61,9.61,0,0,0-10.09-3.8c-.31.06-.18.54.13.48a9.17,9.17,0,0,1,9,2.91,8.34,8.34,0,0,1,1.5,8.3,13,13,0,0,1-3,4.22,8.46,8.46,0,0,1-4.16,2.51,8.61,8.61,0,0,1-8.1-2.69,11.06,11.06,0,0,1-2.16-9.83c.07-.31-.41-.44-.48-.13Z"/>
                <path class="cls-4" d="M55.32,154.43c-1,.87-2.1,1.87-2.25,3.19l0,.06a11.17,11.17,0,0,0,2.51,10.37c2.59,2.66,7,3.53,10.19,1.62A11.43,11.43,0,0,0,68,167.82a12,12,0,0,0,3.19-5.07,8.92,8.92,0,0,0-4.71-10A10.46,10.46,0,0,0,55.32,154.43Zm4.88.47a2.49,2.49,0,0,1,1.58,2.72,2.28,2.28,0,0,1-2.54,1.76c-1.12-.21-2.12-1.29-1.76-2.37v-.07c.11-.64.14-1.42.71-1.82A2.22,2.22,0,0,1,60.2,154.9Z"/>
                <path class="cls-4" d="M42.88,153.58a5.18,5.18,0,0,0-2.67-1.76c-.31-.07-.45.41-.13.48a4.68,4.68,0,0,1,2.44,1.63.25.25,0,0,0,.36-.35Z"/>
                <path class="cls-4" d="M40.3,151.59a11.64,11.64,0,0,0-10.09.95,9.18,9.18,0,0,0-4.14,7.76c.07,3.46,2.75,6.68,5.56,8.47a8.69,8.69,0,0,0,8.72.29,9.59,9.59,0,0,0,5-9.66c0-.31-.53-.32-.5,0A9.21,9.21,0,0,1,41.17,168a8.39,8.39,0,0,1-8.42.85A12.13,12.13,0,0,1,29,165.79a9.23,9.23,0,0,1-2.38-4.44,8.59,8.59,0,0,1,3.16-7.92,11,11,0,0,1,10.35-1.36c.3.11.43-.37.13-.48Z"/>
                <path class="cls-4" d="M48.12,152.35c-.76-1.09-1.63-2.29-2.93-2.57l-.06,0a11.19,11.19,0,0,0-10.57,1.41c-2.92,2.3-4.25,6.6-2.69,10a11.27,11.27,0,0,0,1.61,2.43,12.12,12.12,0,0,0,4.7,3.71,8.93,8.93,0,0,0,10.41-3.64A10.46,10.46,0,0,0,48.12,152.35Zm-1,4.81a2.51,2.51,0,0,1-2.88,1.28,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.55-1.51l.06,0c.62.17,1.4.29,1.74.9A2.24,2.24,0,0,1,47.15,157.16Z"/>
                <path class="cls-4" d="M89.65,156.67a5.16,5.16,0,0,0-3.11-.74.25.25,0,0,0-.25.25.26.26,0,0,0,.25.25,4.66,4.66,0,0,1,2.86.67.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M86.55,155.68a11.78,11.78,0,0,0-8.81,3.9A9.2,9.2,0,0,0,76,168.35c1.12,3.34,4.55,5.45,7.81,6.33a8.69,8.69,0,0,0,8.7-2.56,9.61,9.61,0,0,0,1.37-10.87c-.13-.28-.56,0-.42.25a9.18,9.18,0,0,1-.66,9.5,8.31,8.31,0,0,1-8,3.39c-3.08-.55-6.71-2.54-8-5.5a8.66,8.66,0,0,1,.87-8.35,11.13,11.13,0,0,1,8.93-4.37.25.25,0,0,0,0-.49Z"/>
                <path class="cls-4" d="M94.13,153.7c-1.08-.76-2.32-1.58-3.63-1.41h-.07a11.23,11.23,0,0,0-9.45,5,8.57,8.57,0,0,0,.91,10.28,11.08,11.08,0,0,0,2.34,1.73,12,12,0,0,0,5.69,1.87,8.94,8.94,0,0,0,8.53-7A10.47,10.47,0,0,0,94.13,153.7Zm.74,4.85a2.49,2.49,0,0,1-2.26,2.19,2.26,2.26,0,0,1-2.32-2c-.07-1.14.73-2.37,1.87-2.29h.06c.65-.06,1.43-.21,2,.25A2.2,2.2,0,0,1,94.87,158.55Z"/>
                <path class="cls-4" d="M131.44,159.5a5.16,5.16,0,0,0-1.48,2.9c0,.13.12.25.25.25a.25.25,0,0,0,.24-.25,4.7,4.7,0,0,1,1.34-2.55c.22-.23-.13-.58-.35-.35Z"/>
                <path class="cls-4" d="M129.72,162.27a11.78,11.78,0,0,0,1.67,9.52,9.19,9.19,0,0,0,8.27,3.76c3.47-.35,6.37-3.24,7.94-6.21a8.69,8.69,0,0,0-.59-9,9.61,9.61,0,0,0-10.09-3.8c-.31.06-.18.54.13.48a9.17,9.17,0,0,1,9,2.91,8.34,8.34,0,0,1,1.5,8.3,13,13,0,0,1-3,4.22,8.46,8.46,0,0,1-4.16,2.51,8.61,8.61,0,0,1-8.1-2.69,11.09,11.09,0,0,1-2.17-9.83.25.25,0,0,0-.48-.13Z"/>
                <path class="cls-4" d="M129.65,154.43c-1,.87-2.1,1.87-2.25,3.19l0,.06a11.2,11.2,0,0,0,2.51,10.37c2.6,2.66,7,3.53,10.2,1.62a11.43,11.43,0,0,0,2.25-1.85,12,12,0,0,0,3.19-5.07,8.92,8.92,0,0,0-4.71-10A10.45,10.45,0,0,0,129.65,154.43Zm4.88.47a2.49,2.49,0,0,1,1.58,2.72,2.28,2.28,0,0,1-2.54,1.76c-1.12-.21-2.12-1.29-1.76-2.37v-.07c.1-.64.14-1.42.71-1.82A2.21,2.21,0,0,1,134.53,154.9Z"/>
                <path class="cls-4" d="M117.2,153.58a5.12,5.12,0,0,0-2.66-1.76c-.31-.07-.45.41-.13.48a4.68,4.68,0,0,1,2.44,1.63.25.25,0,0,0,.35,0,.24.24,0,0,0,0-.35Z"/>
                <path class="cls-4" d="M114.63,151.59a11.64,11.64,0,0,0-10.09.95,9.18,9.18,0,0,0-4.14,7.76c.07,3.46,2.75,6.68,5.56,8.47a8.69,8.69,0,0,0,8.72.29,9.59,9.59,0,0,0,5-9.66c0-.31-.53-.32-.5,0A9.21,9.21,0,0,1,115.5,168a8.39,8.39,0,0,1-8.42.85,12.13,12.13,0,0,1-3.71-3.05,9.23,9.23,0,0,1-2.38-4.44,8.59,8.59,0,0,1,3.16-7.92,11,11,0,0,1,10.35-1.36c.3.11.43-.37.13-.48Z"/>
                <path class="cls-4" d="M122.45,152.35c-.76-1.09-1.63-2.29-2.93-2.57l-.06,0a11.19,11.19,0,0,0-10.57,1.41c-2.92,2.3-4.25,6.6-2.69,10a11.27,11.27,0,0,0,1.61,2.43,12.12,12.12,0,0,0,4.7,3.71,8.93,8.93,0,0,0,10.41-3.64A10.46,10.46,0,0,0,122.45,152.35Zm-1,4.81a2.51,2.51,0,0,1-2.88,1.28,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.55-1.51l.06,0c.62.17,1.4.29,1.74.9A2.24,2.24,0,0,1,121.48,157.16Z"/>
                <path class="cls-4" d="M169.33,181.27a5.11,5.11,0,0,0-3.11-.74.25.25,0,0,0-.25.24.24.24,0,0,0,.25.25,4.69,4.69,0,0,1,2.85.68c.28.15.54-.27.26-.43Z"/>
                <path class="cls-4" d="M166.22,180.28a11.76,11.76,0,0,0-8.81,3.9,9.25,9.25,0,0,0-1.73,8.77c1.12,3.34,4.56,5.45,7.82,6.33a8.69,8.69,0,0,0,8.7-2.56,9.63,9.63,0,0,0,1.37-10.87c-.14-.29-.56,0-.43.25a9.15,9.15,0,0,1-.65,9.5,8.3,8.3,0,0,1-8,3.38c-3.08-.55-6.71-2.54-8-5.49a8.66,8.66,0,0,1,.88-8.35,11.09,11.09,0,0,1,8.92-4.37.25.25,0,0,0,0-.49Z"/>
                <path class="cls-4" d="M173.81,178.3c-1.09-.76-2.32-1.58-3.63-1.41h-.07a11.2,11.2,0,0,0-9.45,5,8.6,8.6,0,0,0,.9,10.29,11.4,11.4,0,0,0,2.35,1.73,12,12,0,0,0,5.69,1.87,8.92,8.92,0,0,0,8.52-7A10.47,10.47,0,0,0,173.81,178.3Zm.74,4.85a2.49,2.49,0,0,1-2.26,2.19,2.28,2.28,0,0,1-2.33-2c-.06-1.14.74-2.37,1.88-2.29h.06a2.57,2.57,0,0,1,1.95.24A2.26,2.26,0,0,1,174.55,183.15Z"/>
                <path class="cls-4" d="M211.12,184.1a5.24,5.24,0,0,0-1.49,2.9.26.26,0,0,0,.25.25.25.25,0,0,0,.25-.25,4.7,4.7,0,0,1,1.34-2.55c.22-.23-.13-.58-.35-.35Z"/>
                <path class="cls-4" d="M209.4,186.87a11.75,11.75,0,0,0,1.67,9.52,9.16,9.16,0,0,0,8.26,3.76c3.47-.35,6.37-3.24,7.94-6.21a8.69,8.69,0,0,0-.58-9,9.61,9.61,0,0,0-10.09-3.8c-.32.06-.18.54.13.48a9.13,9.13,0,0,1,9,2.91,8.32,8.32,0,0,1,1.5,8.3,12.93,12.93,0,0,1-2.95,4.22,8.5,8.5,0,0,1-4.16,2.51,8.65,8.65,0,0,1-8.1-2.69,11.09,11.09,0,0,1-2.17-9.83c.07-.31-.41-.45-.48-.13Z"/>
                <path class="cls-4" d="M209.33,179c-1,.87-2.11,1.87-2.25,3.18l0,.07a11.21,11.21,0,0,0,2.51,10.37,8.59,8.59,0,0,0,10.2,1.62,11.14,11.14,0,0,0,2.25-1.86,12,12,0,0,0,3.19-5.06,8.92,8.92,0,0,0-4.71-10A10.45,10.45,0,0,0,209.33,179Zm4.88.47a2.5,2.5,0,0,1,1.58,2.72,2.29,2.29,0,0,1-2.54,1.76,2,2,0,0,1-1.77-2.38l0-.06c.1-.64.14-1.43.71-1.83A2.21,2.21,0,0,1,214.21,179.5Z"/>
                <path class="cls-4" d="M196.88,178.18a5.14,5.14,0,0,0-2.67-1.76c-.31-.07-.44.41-.13.48a4.7,4.7,0,0,1,2.45,1.63.25.25,0,0,0,.35-.35Z"/>
                <path class="cls-4" d="M194.31,176.19a11.67,11.67,0,0,0-10.09.94,9.19,9.19,0,0,0-4.15,7.77c.08,3.46,2.75,6.68,5.57,8.47a8.69,8.69,0,0,0,8.72.29,9.6,9.6,0,0,0,4.95-9.66c0-.32-.52-.32-.49,0a9.19,9.19,0,0,1-3.65,8.58,8.38,8.38,0,0,1-8.41.86,12.31,12.31,0,0,1-3.72-3.05,9.34,9.34,0,0,1-2.37-4.44,8.56,8.56,0,0,1,3.16-7.93,11,11,0,0,1,10.34-1.35c.31.1.44-.38.14-.48Z"/>
                <path class="cls-4" d="M202.13,177c-.76-1.09-1.64-2.29-2.93-2.58l-.06,0a11.21,11.21,0,0,0-10.58,1.41,8.59,8.59,0,0,0-2.68,10,11.52,11.52,0,0,0,1.61,2.43,12.05,12.05,0,0,0,4.7,3.71,8.93,8.93,0,0,0,10.41-3.64A10.46,10.46,0,0,0,202.13,177Zm-1,4.8a2.49,2.49,0,0,1-2.87,1.29,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.54-1.51l.06,0c.63.17,1.41.29,1.75.9A2.22,2.22,0,0,1,201.15,181.75Z"/>
                <path class="cls-4" d="M243.65,181.27a5.06,5.06,0,0,0-3.1-.74.25.25,0,0,0-.25.24.24.24,0,0,0,.25.25,4.69,4.69,0,0,1,2.85.68.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M240.55,180.28a11.76,11.76,0,0,0-8.81,3.9A9.23,9.23,0,0,0,230,193c1.12,3.34,4.56,5.45,7.82,6.33a8.7,8.7,0,0,0,8.7-2.56,9.63,9.63,0,0,0,1.37-10.87c-.14-.29-.57,0-.43.25a9.15,9.15,0,0,1-.65,9.5,8.3,8.3,0,0,1-8,3.38c-3.08-.55-6.71-2.54-8-5.49a8.66,8.66,0,0,1,.88-8.35,11.09,11.09,0,0,1,8.92-4.37.25.25,0,0,0,0-.49Z"/>
                <path class="cls-4" d="M248.14,178.3c-1.09-.76-2.32-1.58-3.63-1.41h-.07a11.2,11.2,0,0,0-9.45,5,8.6,8.6,0,0,0,.9,10.29,11.4,11.4,0,0,0,2.35,1.73,12,12,0,0,0,5.69,1.87,8.92,8.92,0,0,0,8.52-7A10.47,10.47,0,0,0,248.14,178.3Zm.73,4.85a2.47,2.47,0,0,1-2.25,2.19,2.28,2.28,0,0,1-2.33-2c-.06-1.14.74-2.37,1.88-2.29h.06a2.57,2.57,0,0,1,2,.24A2.22,2.22,0,0,1,248.87,183.15Z"/>
                <path class="cls-4" d="M285.45,184.1A5.24,5.24,0,0,0,284,187a.25.25,0,0,0,.5,0,4.7,4.7,0,0,1,1.34-2.55c.22-.23-.13-.58-.35-.35Z"/>
                <path class="cls-4" d="M283.73,186.87a11.75,11.75,0,0,0,1.67,9.52,9.16,9.16,0,0,0,8.26,3.76c3.47-.35,6.37-3.24,7.94-6.21a8.71,8.71,0,0,0-.58-9,9.62,9.62,0,0,0-10.09-3.8c-.32.06-.18.54.13.48a9.13,9.13,0,0,1,9,2.91,8.34,8.34,0,0,1,1.5,8.3,12.81,12.81,0,0,1-3,4.22,8.43,8.43,0,0,1-4.15,2.51,8.65,8.65,0,0,1-8.1-2.69,11.09,11.09,0,0,1-2.17-9.83c.07-.31-.41-.45-.48-.13Z"/>
                <path class="cls-4" d="M283.65,179c-1,.87-2.1,1.87-2.25,3.18l0,.07a11.21,11.21,0,0,0,2.51,10.37,8.59,8.59,0,0,0,10.2,1.62,11.42,11.42,0,0,0,2.25-1.86,12.08,12.08,0,0,0,3.19-5.06,8.92,8.92,0,0,0-4.71-10A10.47,10.47,0,0,0,283.65,179Zm4.89.47a2.5,2.5,0,0,1,1.58,2.72,2.29,2.29,0,0,1-2.54,1.76,2,2,0,0,1-1.77-2.38l0-.06c.1-.64.14-1.43.71-1.83A2.21,2.21,0,0,1,288.54,179.5Z"/>
                <path class="cls-4" d="M271.21,178.18a5.14,5.14,0,0,0-2.67-1.76c-.31-.07-.44.41-.13.48a4.7,4.7,0,0,1,2.45,1.63.25.25,0,0,0,.35-.35Z"/>
                <path class="cls-4" d="M268.63,176.19a11.65,11.65,0,0,0-10.08.94,9.19,9.19,0,0,0-4.15,7.77c.08,3.46,2.75,6.68,5.56,8.47a8.71,8.71,0,0,0,8.73.29,9.61,9.61,0,0,0,4.95-9.66c0-.32-.52-.32-.49,0a9.19,9.19,0,0,1-3.65,8.58,8.38,8.38,0,0,1-8.41.86,12.31,12.31,0,0,1-3.72-3.05A9.25,9.25,0,0,1,255,186a8.56,8.56,0,0,1,3.16-7.93,11,11,0,0,1,10.34-1.35c.3.1.43-.38.13-.48Z"/>
                <path class="cls-4" d="M276.46,177c-.76-1.09-1.64-2.29-2.93-2.58l-.06,0a11.21,11.21,0,0,0-10.58,1.41,8.59,8.59,0,0,0-2.68,10,11.15,11.15,0,0,0,1.61,2.43,12.05,12.05,0,0,0,4.7,3.71,8.93,8.93,0,0,0,10.41-3.64A10.46,10.46,0,0,0,276.46,177Zm-1,4.8a2.49,2.49,0,0,1-2.87,1.29,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.54-1.51l.06,0c.63.17,1.41.29,1.75.9A2.22,2.22,0,0,1,275.48,181.75Z"/>
                <path class="cls-4" d="M21.2,181.27a5.08,5.08,0,0,0-3.1-.74.25.25,0,0,0-.25.24.24.24,0,0,0,.25.25,4.71,4.71,0,0,1,2.85.68.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M18.1,180.28a11.74,11.74,0,0,0-8.81,3.9A9.23,9.23,0,0,0,7.56,193c1.12,3.34,4.55,5.45,7.82,6.33a8.7,8.7,0,0,0,8.7-2.56,9.63,9.63,0,0,0,1.37-10.87c-.14-.29-.57,0-.43.25a9.18,9.18,0,0,1-.65,9.5,8.31,8.31,0,0,1-8,3.38c-3.08-.55-6.7-2.54-8-5.49a8.68,8.68,0,0,1,.87-8.35,11.1,11.1,0,0,1,8.93-4.37.25.25,0,0,0,0-.49Z"/>
                <path class="cls-4" d="M25.69,178.3c-1.09-.76-2.32-1.58-3.64-1.41H22a11.2,11.2,0,0,0-9.44,5,8.58,8.58,0,0,0,.9,10.29,11.34,11.34,0,0,0,2.34,1.73,12.11,12.11,0,0,0,5.69,1.87,8.92,8.92,0,0,0,8.53-7A10.47,10.47,0,0,0,25.69,178.3Zm.73,4.85a2.47,2.47,0,0,1-2.26,2.19,2.27,2.27,0,0,1-2.32-2c-.07-1.14.74-2.37,1.88-2.29h.06a2.57,2.57,0,0,1,1.95.24A2.25,2.25,0,0,1,26.42,183.15Z"/>
                <path class="cls-4" d="M63,184.1a5.12,5.12,0,0,0-1.49,2.9c0,.13.13.25.25.25A.26.26,0,0,0,62,187a4.63,4.63,0,0,1,1.34-2.55c.22-.23-.14-.58-.35-.35Z"/>
                <path class="cls-4" d="M61.28,186.87a11.78,11.78,0,0,0,1.66,9.52,9.19,9.19,0,0,0,8.27,3.76c3.47-.35,6.37-3.24,7.94-6.21a8.71,8.71,0,0,0-.58-9,9.63,9.63,0,0,0-10.1-3.8c-.31.06-.18.54.14.48a9.13,9.13,0,0,1,9,2.91,8.34,8.34,0,0,1,1.5,8.3,13,13,0,0,1-3,4.22A8.42,8.42,0,0,1,72,199.52a8.63,8.63,0,0,1-8.09-2.69A11,11,0,0,1,61.76,187c.07-.31-.41-.45-.48-.13Z"/>
                <path class="cls-4" d="M61.2,179c-1,.87-2.1,1.87-2.25,3.18l0,.07a11.21,11.21,0,0,0,2.51,10.37,8.59,8.59,0,0,0,10.2,1.62,11.36,11.36,0,0,0,2.24-1.86,12.11,12.11,0,0,0,3.2-5.06,8.94,8.94,0,0,0-4.71-10A10.47,10.47,0,0,0,61.2,179Zm4.89.47a2.48,2.48,0,0,1,1.57,2.72A2.28,2.28,0,0,1,65.12,184a2,2,0,0,1-1.76-2.38v-.06c.11-.64.14-1.43.72-1.83A2.21,2.21,0,0,1,66.09,179.5Z"/>
                <path class="cls-4" d="M48.76,178.18a5.18,5.18,0,0,0-2.67-1.76c-.31-.07-.44.41-.13.48a4.74,4.74,0,0,1,2.45,1.63.25.25,0,1,0,.35-.35Z"/>
                <path class="cls-4" d="M46.18,176.19a11.67,11.67,0,0,0-10.09.94A9.21,9.21,0,0,0,32,184.9c.08,3.46,2.75,6.68,5.56,8.47a8.69,8.69,0,0,0,8.72.29,9.6,9.6,0,0,0,5-9.66c0-.32-.53-.32-.5,0a9.18,9.18,0,0,1-3.64,8.58,8.39,8.39,0,0,1-8.42.86,12.27,12.27,0,0,1-3.71-3.05A9.27,9.27,0,0,1,32.54,186,8.61,8.61,0,0,1,35.7,178a11,11,0,0,1,10.35-1.35c.3.1.43-.38.13-.48Z"/>
                <path class="cls-4" d="M54,177c-.75-1.09-1.63-2.29-2.92-2.58l-.07,0a11.19,11.19,0,0,0-10.57,1.41,8.59,8.59,0,0,0-2.69,10,11.52,11.52,0,0,0,1.61,2.43,12.2,12.2,0,0,0,4.7,3.71,8.93,8.93,0,0,0,10.41-3.64A10.44,10.44,0,0,0,54,177Zm-1,4.8A2.49,2.49,0,0,1,50.16,183a2.29,2.29,0,0,1-1.49-2.71,2,2,0,0,1,2.55-1.51l.06,0c.63.17,1.41.29,1.74.9A2.22,2.22,0,0,1,53,181.75Z"/>
                <path class="cls-4" d="M95.53,181.27a5.09,5.09,0,0,0-3.11-.74.24.24,0,0,0-.24.24c0,.13.11.27.24.25a4.72,4.72,0,0,1,2.86.68.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M92.43,180.28a11.74,11.74,0,0,0-8.81,3.9A9.2,9.2,0,0,0,81.89,193c1.12,3.34,4.55,5.45,7.82,6.33a8.7,8.7,0,0,0,8.7-2.56,9.63,9.63,0,0,0,1.37-10.87c-.14-.29-.57,0-.43.25a9.18,9.18,0,0,1-.65,9.5,8.31,8.31,0,0,1-8,3.38c-3.08-.55-6.7-2.54-8-5.49a8.65,8.65,0,0,1,.87-8.35,11.1,11.1,0,0,1,8.93-4.37.25.25,0,0,0,0-.49Z"/>
                <path class="cls-4" d="M100,178.3c-1.09-.76-2.32-1.58-3.64-1.41h-.07a11.2,11.2,0,0,0-9.44,5,8.58,8.58,0,0,0,.9,10.29,11.34,11.34,0,0,0,2.34,1.73,12.08,12.08,0,0,0,5.69,1.87,8.92,8.92,0,0,0,8.53-7A10.48,10.48,0,0,0,100,178.3Zm.73,4.85a2.49,2.49,0,0,1-2.26,2.19,2.27,2.27,0,0,1-2.32-2c-.07-1.14.74-2.37,1.87-2.29h.07c.64-.06,1.42-.21,1.94.24A2.23,2.23,0,0,1,100.75,183.15Z"/>
                <path class="cls-4" d="M137.32,184.1a5.16,5.16,0,0,0-1.48,2.9c0,.13.13.25.25.25a.26.26,0,0,0,.25-.25,4.57,4.57,0,0,1,1.34-2.55c.21-.23-.14-.58-.36-.35Z"/>
                <path class="cls-4" d="M135.61,186.87a11.78,11.78,0,0,0,1.66,9.52,9.19,9.19,0,0,0,8.27,3.76c3.47-.35,6.37-3.24,7.94-6.21a8.69,8.69,0,0,0-.59-9,9.61,9.61,0,0,0-10.09-3.8c-.31.06-.18.54.13.48a9.15,9.15,0,0,1,9,2.91,8.34,8.34,0,0,1,1.5,8.3,13,13,0,0,1-3,4.22,8.42,8.42,0,0,1-4.16,2.51,8.63,8.63,0,0,1-8.09-2.69,11,11,0,0,1-2.17-9.83c.07-.31-.41-.45-.48-.13Z"/>
                <path class="cls-4" d="M135.53,179c-1,.87-2.1,1.87-2.25,3.18l0,.07a11.21,11.21,0,0,0,2.51,10.37,8.59,8.59,0,0,0,10.2,1.62,11.36,11.36,0,0,0,2.24-1.86,12.11,12.11,0,0,0,3.2-5.06,8.94,8.94,0,0,0-4.71-10A10.47,10.47,0,0,0,135.53,179Zm4.89.47a2.48,2.48,0,0,1,1.57,2.72,2.28,2.28,0,0,1-2.54,1.76,2,2,0,0,1-1.76-2.38v-.06c.11-.64.14-1.43.72-1.83A2.21,2.21,0,0,1,140.42,179.5Z"/>
                <path class="cls-4" d="M123.09,178.18a5.18,5.18,0,0,0-2.67-1.76c-.31-.07-.44.41-.13.48a4.74,4.74,0,0,1,2.45,1.63.25.25,0,0,0,.35,0,.26.26,0,0,0,0-.35Z"/>
                <path class="cls-4" d="M120.51,176.19a11.67,11.67,0,0,0-10.09.94,9.21,9.21,0,0,0-4.14,7.77c.08,3.46,2.75,6.68,5.56,8.47a8.69,8.69,0,0,0,8.72.29,9.6,9.6,0,0,0,5-9.66c0-.32-.53-.32-.5,0a9.18,9.18,0,0,1-3.64,8.58,8.39,8.39,0,0,1-8.42.86,12.27,12.27,0,0,1-3.71-3.05,9.27,9.27,0,0,1-2.38-4.44A8.61,8.61,0,0,1,110,178a11,11,0,0,1,10.35-1.35c.3.1.43-.38.13-.48Z"/>
                <path class="cls-4" d="M128.33,177c-.76-1.09-1.63-2.29-2.92-2.58l-.07,0a11.19,11.19,0,0,0-10.57,1.41,8.58,8.58,0,0,0-2.69,10,11.52,11.52,0,0,0,1.61,2.43,12.12,12.12,0,0,0,4.7,3.71,8.93,8.93,0,0,0,10.41-3.64A10.44,10.44,0,0,0,128.33,177Zm-1,4.8a2.49,2.49,0,0,1-2.87,1.29,2.28,2.28,0,0,1-1.49-2.71,2,2,0,0,1,2.55-1.51l.06,0c.62.17,1.41.29,1.74.9A2.22,2.22,0,0,1,127.36,181.75Z"/>
                <path class="cls-4" d="M462.42,156.67a5.16,5.16,0,0,0-3.11-.74.25.25,0,0,0-.25.25.26.26,0,0,0,.25.25,4.64,4.64,0,0,1,2.86.67.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M459.32,155.68a11.78,11.78,0,0,0-8.81,3.9,9.2,9.2,0,0,0-1.73,8.77c1.12,3.34,4.55,5.45,7.81,6.33a8.71,8.71,0,0,0,8.71-2.56,9.64,9.64,0,0,0,1.37-10.87c-.14-.28-.57,0-.43.25a9.18,9.18,0,0,1-.66,9.5,8.31,8.31,0,0,1-8,3.39c-3.08-.55-6.7-2.54-8-5.5a8.66,8.66,0,0,1,.87-8.35,11.13,11.13,0,0,1,8.93-4.37.25.25,0,0,0,0-.49Z"/>
                <path class="cls-4" d="M466.9,153.7c-1.08-.76-2.31-1.58-3.63-1.41h-.07a11.24,11.24,0,0,0-9.45,5,8.58,8.58,0,0,0,.91,10.28,11.08,11.08,0,0,0,2.34,1.73,12,12,0,0,0,5.69,1.87,8.93,8.93,0,0,0,8.53-7A10.47,10.47,0,0,0,466.9,153.7Zm.74,4.85a2.49,2.49,0,0,1-2.26,2.19,2.27,2.27,0,0,1-2.32-2c-.07-1.14.73-2.37,1.87-2.29H465c.64-.06,1.42-.21,1.94.25A2.2,2.2,0,0,1,467.64,158.55Z"/>
                <path class="cls-4" d="M504.21,159.5a5.16,5.16,0,0,0-1.48,2.9c0,.13.12.25.25.25a.26.26,0,0,0,.25-.25,4.62,4.62,0,0,1,1.33-2.55c.22-.23-.13-.58-.35-.35Z"/>
                <path class="cls-4" d="M502.5,162.27a11.74,11.74,0,0,0,1.66,9.52,9.19,9.19,0,0,0,8.27,3.76c3.47-.35,6.37-3.24,7.94-6.21a8.69,8.69,0,0,0-.59-9,9.61,9.61,0,0,0-10.09-3.8c-.31.06-.18.54.13.48a9.17,9.17,0,0,1,9,2.91,8.34,8.34,0,0,1,1.5,8.3,13,13,0,0,1-3,4.22,8.46,8.46,0,0,1-4.16,2.51,8.58,8.58,0,0,1-8.09-2.69A11,11,0,0,1,503,162.4c.07-.31-.41-.44-.48-.13Z"/>
                <path class="cls-4" d="M502.42,154.43c-1,.87-2.1,1.87-2.25,3.19l0,.06a11.2,11.2,0,0,0,2.51,10.37c2.59,2.66,7,3.53,10.19,1.62a11.43,11.43,0,0,0,2.25-1.85,12,12,0,0,0,3.19-5.07,8.92,8.92,0,0,0-4.71-10A10.46,10.46,0,0,0,502.42,154.43Zm4.88.47a2.48,2.48,0,0,1,1.58,2.72,2.28,2.28,0,0,1-2.54,1.76c-1.12-.21-2.12-1.29-1.76-2.37v-.07c.11-.64.14-1.42.71-1.82A2.22,2.22,0,0,1,507.3,154.9Z"/>
                <path class="cls-4" d="M490,153.58a5.18,5.18,0,0,0-2.67-1.76c-.31-.07-.44.41-.13.48a4.68,4.68,0,0,1,2.44,1.63.25.25,0,0,0,.36-.35Z"/>
                <path class="cls-4" d="M487.4,151.59a11.64,11.64,0,0,0-10.09.95,9.18,9.18,0,0,0-4.14,7.76c.08,3.46,2.75,6.68,5.56,8.47a8.69,8.69,0,0,0,8.72.29,9.59,9.59,0,0,0,5-9.66c0-.31-.53-.32-.5,0a9.21,9.21,0,0,1-3.64,8.59,8.39,8.39,0,0,1-8.42.85,12.13,12.13,0,0,1-3.71-3.05,9.23,9.23,0,0,1-2.38-4.44,8.59,8.59,0,0,1,3.16-7.92,11,11,0,0,1,10.35-1.36c.3.11.43-.37.13-.48Z"/>
                <path class="cls-4" d="M495.22,152.35c-.76-1.09-1.63-2.29-2.93-2.57l-.06,0a11.19,11.19,0,0,0-10.57,1.41c-2.92,2.3-4.25,6.6-2.69,10a11.27,11.27,0,0,0,1.61,2.43,12.12,12.12,0,0,0,4.7,3.71,8.93,8.93,0,0,0,10.41-3.64A10.44,10.44,0,0,0,495.22,152.35Zm-1,4.81a2.51,2.51,0,0,1-2.88,1.28,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.55-1.51l.06,0c.62.17,1.4.29,1.74.9A2.24,2.24,0,0,1,494.25,157.16Z"/>
                <path class="cls-4" d="M536.75,156.67a5.16,5.16,0,0,0-3.11-.74.25.25,0,0,0-.25.25.26.26,0,0,0,.25.25,4.66,4.66,0,0,1,2.86.67.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M533.65,155.68a11.78,11.78,0,0,0-8.81,3.9,9.2,9.2,0,0,0-1.73,8.77c1.12,3.34,4.55,5.45,7.81,6.33a8.68,8.68,0,0,0,8.7-2.56A9.61,9.61,0,0,0,541,161.25c-.13-.28-.56,0-.42.25a9.18,9.18,0,0,1-.66,9.5,8.31,8.31,0,0,1-8,3.39c-3.08-.55-6.71-2.54-8-5.5a8.66,8.66,0,0,1,.87-8.35,11.13,11.13,0,0,1,8.93-4.37.25.25,0,0,0,0-.49Z"/>
                <path class="cls-4" d="M541.23,153.7c-1.08-.76-2.32-1.58-3.63-1.41h-.07a11.24,11.24,0,0,0-9.45,5,8.57,8.57,0,0,0,.91,10.28,11.08,11.08,0,0,0,2.34,1.73,12,12,0,0,0,5.69,1.87,8.94,8.94,0,0,0,8.53-7A10.47,10.47,0,0,0,541.23,153.7Zm.74,4.85a2.49,2.49,0,0,1-2.26,2.19,2.27,2.27,0,0,1-2.32-2c-.07-1.14.73-2.37,1.87-2.29h.07c.64-.06,1.42-.21,1.94.25A2.2,2.2,0,0,1,542,158.55Z"/>
                <path class="cls-4" d="M578.54,159.5a5.16,5.16,0,0,0-1.48,2.9c0,.13.12.25.25.25a.27.27,0,0,0,.25-.25,4.62,4.62,0,0,1,1.33-2.55c.22-.23-.13-.58-.35-.35Z"/>
                <path class="cls-4" d="M576.83,162.27a11.74,11.74,0,0,0,1.66,9.52,9.19,9.19,0,0,0,8.27,3.76c3.47-.35,6.37-3.24,7.94-6.21a8.69,8.69,0,0,0-.59-9A9.61,9.61,0,0,0,584,156.5c-.31.06-.18.54.13.48a9.17,9.17,0,0,1,9,2.91,8.34,8.34,0,0,1,1.5,8.3,13,13,0,0,1-3,4.22,8.46,8.46,0,0,1-4.16,2.51,8.61,8.61,0,0,1-8.1-2.69,11.09,11.09,0,0,1-2.17-9.83c.08-.31-.4-.44-.47-.13Z"/>
                <path class="cls-4" d="M576.75,154.43c-1,.87-2.1,1.87-2.25,3.19l0,.06A11.15,11.15,0,0,0,577,168.05c2.59,2.66,7,3.53,10.19,1.62a11.43,11.43,0,0,0,2.25-1.85,12,12,0,0,0,3.19-5.07,8.92,8.92,0,0,0-4.71-10A10.46,10.46,0,0,0,576.75,154.43Zm4.88.47a2.49,2.49,0,0,1,1.58,2.72,2.28,2.28,0,0,1-2.54,1.76c-1.12-.21-2.12-1.29-1.76-2.37v-.07c.11-.64.14-1.42.71-1.82A2.21,2.21,0,0,1,581.63,154.9Z"/>
                <path class="cls-4" d="M564.3,153.58a5.12,5.12,0,0,0-2.66-1.76c-.31-.07-.45.41-.13.48a4.68,4.68,0,0,1,2.44,1.63.25.25,0,0,0,.35,0,.24.24,0,0,0,0-.35Z"/>
                <path class="cls-4" d="M561.73,151.59a11.64,11.64,0,0,0-10.09.95,9.18,9.18,0,0,0-4.14,7.76c.07,3.46,2.75,6.68,5.56,8.47a8.69,8.69,0,0,0,8.72.29,9.59,9.59,0,0,0,5-9.66c0-.31-.53-.32-.5,0A9.21,9.21,0,0,1,562.6,168a8.39,8.39,0,0,1-8.42.85,12.13,12.13,0,0,1-3.71-3.05,9.23,9.23,0,0,1-2.38-4.44,8.59,8.59,0,0,1,3.16-7.92,11,11,0,0,1,10.35-1.36c.3.11.43-.37.13-.48Z"/>
                <path class="cls-4" d="M569.55,152.35c-.76-1.09-1.63-2.29-2.93-2.57l-.06,0A11.19,11.19,0,0,0,556,151.15c-2.92,2.3-4.25,6.6-2.69,10a11.27,11.27,0,0,0,1.61,2.43,12.12,12.12,0,0,0,4.7,3.71A8.93,8.93,0,0,0,570,163.62,10.46,10.46,0,0,0,569.55,152.35Zm-1,4.81a2.51,2.51,0,0,1-2.88,1.28,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.55-1.51l.06,0c.62.17,1.4.29,1.74.9A2.24,2.24,0,0,1,568.58,157.16Z"/>
                <path class="cls-4" d="M314.3,156.67a5.18,5.18,0,0,0-3.11-.74.25.25,0,0,0-.25.25.26.26,0,0,0,.25.25,4.66,4.66,0,0,1,2.86.67.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M311.19,155.68a11.79,11.79,0,0,0-8.81,3.9,9.22,9.22,0,0,0-1.72,8.77c1.11,3.34,4.55,5.45,7.81,6.33a8.69,8.69,0,0,0,8.7-2.56,9.62,9.62,0,0,0,1.37-10.87c-.14-.28-.56,0-.43.25a9.15,9.15,0,0,1-.65,9.5,8.32,8.32,0,0,1-8,3.39c-3.08-.55-6.71-2.54-8-5.5a8.66,8.66,0,0,1,.88-8.35,11.12,11.12,0,0,1,8.92-4.37.25.25,0,0,0,0-.49Z"/>
                <path class="cls-4" d="M318.78,153.7c-1.09-.76-2.32-1.58-3.63-1.41h-.07a11.23,11.23,0,0,0-9.45,5,8.58,8.58,0,0,0,.9,10.28,11.4,11.4,0,0,0,2.35,1.73,12,12,0,0,0,5.69,1.87,8.94,8.94,0,0,0,8.53-7A10.49,10.49,0,0,0,318.78,153.7Zm.74,4.85a2.49,2.49,0,0,1-2.26,2.19,2.28,2.28,0,0,1-2.33-2c-.06-1.14.74-2.37,1.88-2.29h.06a2.52,2.52,0,0,1,1.95.25A2.2,2.2,0,0,1,319.52,158.55Z"/>
                <path class="cls-4" d="M356.09,159.5a5.16,5.16,0,0,0-1.48,2.9.25.25,0,0,0,.24.25.25.25,0,0,0,.25-.25,4.7,4.7,0,0,1,1.34-2.55c.22-.23-.13-.58-.35-.35Z"/>
                <path class="cls-4" d="M354.37,162.27a11.74,11.74,0,0,0,1.67,9.52,9.17,9.17,0,0,0,8.26,3.76c3.48-.35,6.37-3.24,7.95-6.21a8.71,8.71,0,0,0-.59-9,9.61,9.61,0,0,0-10.09-3.8c-.32.06-.18.54.13.48a9.15,9.15,0,0,1,9,2.91,8.32,8.32,0,0,1,1.5,8.3,12.78,12.78,0,0,1-3,4.22,8.5,8.5,0,0,1-4.16,2.51,8.61,8.61,0,0,1-8.1-2.69,11.09,11.09,0,0,1-2.17-9.83.25.25,0,0,0-.48-.13Z"/>
                <path class="cls-4" d="M354.3,154.43c-1,.87-2.1,1.87-2.25,3.19l0,.06a11.2,11.2,0,0,0,2.51,10.37,8.58,8.58,0,0,0,10.2,1.62,11.43,11.43,0,0,0,2.25-1.85,12,12,0,0,0,3.19-5.07,8.92,8.92,0,0,0-4.71-10A10.45,10.45,0,0,0,354.3,154.43Zm4.88.47a2.5,2.5,0,0,1,1.58,2.72,2.29,2.29,0,0,1-2.54,1.76c-1.12-.21-2.12-1.29-1.77-2.37l0-.07c.1-.64.14-1.42.71-1.82A2.21,2.21,0,0,1,359.18,154.9Z"/>
                <path class="cls-4" d="M341.85,153.58a5.16,5.16,0,0,0-2.66-1.76c-.32-.07-.45.41-.14.48a4.7,4.7,0,0,1,2.45,1.63.25.25,0,0,0,.35-.35Z"/>
                <path class="cls-4" d="M339.28,151.59a11.64,11.64,0,0,0-10.09.95A9.16,9.16,0,0,0,325,160.3c.08,3.46,2.76,6.68,5.57,8.47a8.69,8.69,0,0,0,8.72.29,9.57,9.57,0,0,0,4.95-9.66c0-.31-.52-.32-.49,0a9.19,9.19,0,0,1-3.65,8.59,8.38,8.38,0,0,1-8.41.85,12.29,12.29,0,0,1-3.72-3.05,9.29,9.29,0,0,1-2.37-4.44,8.54,8.54,0,0,1,3.16-7.92,11,11,0,0,1,10.34-1.36c.31.11.44-.37.14-.48Z"/>
                <path class="cls-4" d="M347.1,152.35c-.76-1.09-1.63-2.29-2.93-2.57l-.06,0a11.21,11.21,0,0,0-10.58,1.41c-2.91,2.3-4.24,6.6-2.68,10a11.27,11.27,0,0,0,1.61,2.43,12.12,12.12,0,0,0,4.7,3.71,8.93,8.93,0,0,0,10.41-3.64A10.46,10.46,0,0,0,347.1,152.35Zm-1,4.81a2.5,2.5,0,0,1-2.87,1.28,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.54-1.51l.06,0c.63.17,1.41.29,1.75.9A2.25,2.25,0,0,1,346.12,157.16Z"/>
                <path class="cls-4" d="M388.63,156.67a5.18,5.18,0,0,0-3.11-.74.25.25,0,0,0,0,.5,4.61,4.61,0,0,1,2.85.67c.28.16.54-.27.26-.43Z"/>
                <path class="cls-4" d="M385.52,155.68a11.79,11.79,0,0,0-8.81,3.9,9.25,9.25,0,0,0-1.73,8.77c1.12,3.34,4.56,5.45,7.82,6.33a8.69,8.69,0,0,0,8.7-2.56,9.62,9.62,0,0,0,1.37-10.87c-.14-.28-.56,0-.43.25a9.15,9.15,0,0,1-.65,9.5,8.33,8.33,0,0,1-8,3.39c-3.08-.55-6.71-2.54-8-5.5a8.66,8.66,0,0,1,.88-8.35,11.12,11.12,0,0,1,8.92-4.37.25.25,0,0,0,0-.49Z"/>
                <path class="cls-4" d="M393.11,153.7c-1.09-.76-2.32-1.58-3.63-1.41h-.07a11.21,11.21,0,0,0-9.45,5,8.58,8.58,0,0,0,.9,10.28,11.4,11.4,0,0,0,2.35,1.73,12,12,0,0,0,5.69,1.87,8.92,8.92,0,0,0,8.52-7A10.47,10.47,0,0,0,393.11,153.7Zm.74,4.85a2.49,2.49,0,0,1-2.26,2.19,2.28,2.28,0,0,1-2.33-2c-.06-1.14.74-2.37,1.88-2.29h.06a2.52,2.52,0,0,1,1.95.25A2.23,2.23,0,0,1,393.85,158.55Z"/>
                <path class="cls-4" d="M430.42,159.5a5.24,5.24,0,0,0-1.49,2.9.26.26,0,0,0,.25.25.25.25,0,0,0,.25-.25,4.7,4.7,0,0,1,1.34-2.55c.22-.23-.13-.58-.35-.35Z"/>
                <path class="cls-4" d="M428.7,162.27a11.74,11.74,0,0,0,1.67,9.52,9.16,9.16,0,0,0,8.26,3.76c3.48-.35,6.37-3.24,7.94-6.21a8.69,8.69,0,0,0-.58-9,9.61,9.61,0,0,0-10.09-3.8c-.32.06-.18.54.13.48a9.15,9.15,0,0,1,9,2.91,8.32,8.32,0,0,1,1.5,8.3,12.93,12.93,0,0,1-2.95,4.22,8.5,8.5,0,0,1-4.16,2.51,8.61,8.61,0,0,1-8.1-2.69,11.09,11.09,0,0,1-2.17-9.83c.07-.31-.41-.44-.48-.13Z"/>
                <path class="cls-4" d="M428.63,154.43c-1,.87-2.11,1.87-2.25,3.19l0,.06a11.2,11.2,0,0,0,2.51,10.37,8.58,8.58,0,0,0,10.2,1.62,11.43,11.43,0,0,0,2.25-1.85,12,12,0,0,0,3.19-5.07,8.92,8.92,0,0,0-4.71-10A10.45,10.45,0,0,0,428.63,154.43Zm4.88.47a2.5,2.5,0,0,1,1.58,2.72,2.29,2.29,0,0,1-2.54,1.76c-1.12-.21-2.12-1.29-1.77-2.37l0-.07c.1-.64.14-1.42.71-1.82A2.21,2.21,0,0,1,433.51,154.9Z"/>
                <path class="cls-4" d="M416.18,153.58a5.16,5.16,0,0,0-2.66-1.76c-.32-.07-.45.41-.14.48a4.7,4.7,0,0,1,2.45,1.63.25.25,0,0,0,.35-.35Z"/>
                <path class="cls-4" d="M413.61,151.59a11.64,11.64,0,0,0-10.09.95,9.16,9.16,0,0,0-4.15,7.76c.08,3.46,2.75,6.68,5.57,8.47a8.69,8.69,0,0,0,8.72.29,9.59,9.59,0,0,0,4.95-9.66c0-.31-.52-.32-.49,0a9.21,9.21,0,0,1-3.65,8.59,8.38,8.38,0,0,1-8.41.85,12.29,12.29,0,0,1-3.72-3.05,9.29,9.29,0,0,1-2.37-4.44,8.54,8.54,0,0,1,3.16-7.92,11,11,0,0,1,10.34-1.36c.31.11.44-.37.14-.48Z"/>
                <path class="cls-4" d="M421.43,152.35c-.76-1.09-1.63-2.29-2.93-2.57l-.06,0a11.21,11.21,0,0,0-10.58,1.41c-2.91,2.3-4.24,6.6-2.68,10a11.27,11.27,0,0,0,1.61,2.43,12.05,12.05,0,0,0,4.7,3.71,8.93,8.93,0,0,0,10.41-3.64A10.46,10.46,0,0,0,421.43,152.35Zm-1,4.81a2.5,2.5,0,0,1-2.87,1.28,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.54-1.51l.06,0c.63.17,1.41.29,1.75.9A2.25,2.25,0,0,1,420.45,157.16Z"/>
                <path class="cls-4" d="M468.3,181.27a5.06,5.06,0,0,0-3.1-.74.25.25,0,0,0-.25.24.24.24,0,0,0,.25.25,4.71,4.71,0,0,1,2.85.68.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M465.2,180.28a11.74,11.74,0,0,0-8.81,3.9,9.23,9.23,0,0,0-1.73,8.77c1.12,3.34,4.55,5.45,7.82,6.33a8.7,8.7,0,0,0,8.7-2.56,9.63,9.63,0,0,0,1.37-10.87c-.14-.29-.57,0-.43.25a9.18,9.18,0,0,1-.65,9.5,8.31,8.31,0,0,1-8.05,3.38c-3.08-.55-6.7-2.54-8-5.49a8.68,8.68,0,0,1,.87-8.35,11.1,11.1,0,0,1,8.93-4.37.25.25,0,0,0,0-.49Z"/>
                <path class="cls-4" d="M472.79,178.3c-1.09-.76-2.32-1.58-3.64-1.41h-.07a11.2,11.2,0,0,0-9.44,5,8.58,8.58,0,0,0,.9,10.29,11.34,11.34,0,0,0,2.34,1.73,12.11,12.11,0,0,0,5.69,1.87,8.92,8.92,0,0,0,8.53-7A10.47,10.47,0,0,0,472.79,178.3Zm.73,4.85a2.47,2.47,0,0,1-2.26,2.19,2.27,2.27,0,0,1-2.32-2c-.07-1.14.74-2.37,1.88-2.29h.06a2.57,2.57,0,0,1,1.95.24A2.25,2.25,0,0,1,473.52,183.15Z"/>
                <path class="cls-4" d="M510.1,184.1a5.18,5.18,0,0,0-1.49,2.9.25.25,0,0,0,.5,0,4.7,4.7,0,0,1,1.34-2.55c.22-.23-.13-.58-.35-.35Z"/>
                <path class="cls-4" d="M508.38,186.87a11.78,11.78,0,0,0,1.66,9.52,9.19,9.19,0,0,0,8.27,3.76c3.47-.35,6.37-3.24,7.94-6.21a8.71,8.71,0,0,0-.58-9,9.63,9.63,0,0,0-10.1-3.8c-.31.06-.18.54.14.48a9.13,9.13,0,0,1,9,2.91,8.34,8.34,0,0,1,1.5,8.3,13,13,0,0,1-3,4.22,8.43,8.43,0,0,1-4.15,2.51,8.64,8.64,0,0,1-8.1-2.69,11.06,11.06,0,0,1-2.17-9.83c.07-.31-.41-.45-.48-.13Z"/>
                <path class="cls-4" d="M508.3,179c-1,.87-2.1,1.87-2.25,3.18l0,.07a11.21,11.21,0,0,0,2.51,10.37,8.59,8.59,0,0,0,10.2,1.62,11.36,11.36,0,0,0,2.24-1.86,12.11,12.11,0,0,0,3.2-5.06,8.94,8.94,0,0,0-4.71-10A10.47,10.47,0,0,0,508.3,179Zm4.89.47a2.48,2.48,0,0,1,1.57,2.72,2.28,2.28,0,0,1-2.54,1.76,2,2,0,0,1-1.76-2.38v-.06c.11-.64.15-1.43.72-1.83A2.21,2.21,0,0,1,513.19,179.5Z"/>
                <path class="cls-4" d="M495.86,178.18a5.18,5.18,0,0,0-2.67-1.76c-.31-.07-.44.41-.13.48a4.7,4.7,0,0,1,2.45,1.63.25.25,0,1,0,.35-.35Z"/>
                <path class="cls-4" d="M493.28,176.19a11.67,11.67,0,0,0-10.09.94,9.21,9.21,0,0,0-4.14,7.77c.08,3.46,2.75,6.68,5.56,8.47a8.69,8.69,0,0,0,8.72.29,9.6,9.6,0,0,0,5-9.66c0-.32-.53-.32-.5,0a9.18,9.18,0,0,1-3.64,8.58,8.38,8.38,0,0,1-8.41.86,12.19,12.19,0,0,1-3.72-3.05,9.27,9.27,0,0,1-2.38-4.44A8.61,8.61,0,0,1,482.8,178a11,11,0,0,1,10.35-1.35c.3.1.43-.38.13-.48Z"/>
                <path class="cls-4" d="M501.1,177c-.75-1.09-1.63-2.29-2.92-2.58l-.07,0a11.19,11.19,0,0,0-10.57,1.41,8.59,8.59,0,0,0-2.69,10,11.52,11.52,0,0,0,1.61,2.43,12.2,12.2,0,0,0,4.7,3.71,8.92,8.92,0,0,0,10.41-3.64A10.44,10.44,0,0,0,501.1,177Zm-1,4.8a2.49,2.49,0,0,1-2.87,1.29,2.29,2.29,0,0,1-1.49-2.71,2,2,0,0,1,2.55-1.51l.06,0c.63.17,1.41.29,1.74.9A2.22,2.22,0,0,1,500.13,181.75Z"/>
                <path class="cls-4" d="M542.63,181.27a5.09,5.09,0,0,0-3.11-.74.24.24,0,0,0-.24.24c0,.13.11.27.24.25a4.72,4.72,0,0,1,2.86.68.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M539.53,180.28a11.74,11.74,0,0,0-8.81,3.9A9.2,9.2,0,0,0,529,193c1.12,3.34,4.55,5.45,7.82,6.33a8.7,8.7,0,0,0,8.7-2.56,9.63,9.63,0,0,0,1.37-10.87c-.14-.29-.57,0-.43.25a9.18,9.18,0,0,1-.65,9.5,8.31,8.31,0,0,1-8,3.38c-3.08-.55-6.7-2.54-8-5.49a8.68,8.68,0,0,1,.87-8.35,11.1,11.1,0,0,1,8.93-4.37.25.25,0,0,0,0-.49Z"/>
                <path class="cls-4" d="M547.12,178.3c-1.09-.76-2.32-1.58-3.64-1.41h-.07a11.2,11.2,0,0,0-9.44,5,8.58,8.58,0,0,0,.9,10.29,11.34,11.34,0,0,0,2.34,1.73,12.11,12.11,0,0,0,5.69,1.87,8.92,8.92,0,0,0,8.53-7A10.47,10.47,0,0,0,547.12,178.3Zm.73,4.85a2.49,2.49,0,0,1-2.26,2.19,2.27,2.27,0,0,1-2.32-2c-.07-1.14.74-2.37,1.88-2.29h.06c.64-.06,1.42-.21,1.94.24A2.23,2.23,0,0,1,547.85,183.15Z"/>
                <path class="cls-4" d="M584.42,184.1a5.16,5.16,0,0,0-1.48,2.9c0,.13.13.25.25.25a.26.26,0,0,0,.25-.25,4.63,4.63,0,0,1,1.34-2.55c.22-.23-.14-.58-.36-.35Z"/>
                <path class="cls-4" d="M582.71,186.87a11.78,11.78,0,0,0,1.66,9.52,9.19,9.19,0,0,0,8.27,3.76c3.47-.35,6.37-3.24,7.94-6.21a8.71,8.71,0,0,0-.58-9,9.63,9.63,0,0,0-10.1-3.8c-.31.06-.18.54.13.48a9.14,9.14,0,0,1,9,2.91,8.34,8.34,0,0,1,1.5,8.3,13,13,0,0,1-3,4.22,8.42,8.42,0,0,1-4.16,2.51,8.63,8.63,0,0,1-8.09-2.69,11,11,0,0,1-2.17-9.83c.07-.31-.41-.45-.48-.13Z"/>
                <path class="cls-4" d="M582.63,179c-1,.87-2.1,1.87-2.25,3.18l0,.07a11.21,11.21,0,0,0,2.51,10.37,8.59,8.59,0,0,0,10.2,1.62,11.36,11.36,0,0,0,2.24-1.86,12.11,12.11,0,0,0,3.2-5.06,8.94,8.94,0,0,0-4.71-10A10.47,10.47,0,0,0,582.63,179Zm4.89.47a2.48,2.48,0,0,1,1.57,2.72,2.28,2.28,0,0,1-2.54,1.76,2,2,0,0,1-1.76-2.38v-.06c.11-.64.14-1.43.72-1.83A2.21,2.21,0,0,1,587.52,179.5Z"/>
                <path class="cls-4" d="M570.19,178.18a5.18,5.18,0,0,0-2.67-1.76c-.31-.07-.44.41-.13.48a4.74,4.74,0,0,1,2.45,1.63.25.25,0,0,0,.35,0,.26.26,0,0,0,0-.35Z"/>
                <path class="cls-4" d="M567.61,176.19a11.67,11.67,0,0,0-10.09.94,9.21,9.21,0,0,0-4.14,7.77c.08,3.46,2.75,6.68,5.56,8.47a8.69,8.69,0,0,0,8.72.29,9.6,9.6,0,0,0,5-9.66c0-.32-.53-.32-.5,0a9.18,9.18,0,0,1-3.64,8.58,8.39,8.39,0,0,1-8.42.86,12.27,12.27,0,0,1-3.71-3.05A9.27,9.27,0,0,1,554,186a8.61,8.61,0,0,1,3.16-7.93,11,11,0,0,1,10.35-1.35c.3.1.43-.38.13-.48Z"/>
                <path class="cls-4" d="M575.43,177c-.75-1.09-1.63-2.29-2.92-2.58l-.07,0a11.19,11.19,0,0,0-10.57,1.41,8.58,8.58,0,0,0-2.69,10,11.52,11.52,0,0,0,1.61,2.43,12.2,12.2,0,0,0,4.7,3.71,8.93,8.93,0,0,0,10.41-3.64A10.44,10.44,0,0,0,575.43,177Zm-1,4.8a2.49,2.49,0,0,1-2.87,1.29,2.29,2.29,0,0,1-1.49-2.71,2,2,0,0,1,2.55-1.51l.06,0c.63.17,1.41.29,1.74.9A2.22,2.22,0,0,1,574.46,181.75Z"/>
                <path class="cls-4" d="M320.18,181.27a5.09,5.09,0,0,0-3.11-.74.25.25,0,0,0-.25.24c0,.13.12.27.25.25a4.74,4.74,0,0,1,2.86.68.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M317.08,180.28a11.74,11.74,0,0,0-8.81,3.9,9.2,9.2,0,0,0-1.73,8.77c1.12,3.34,4.55,5.45,7.81,6.33a8.69,8.69,0,0,0,8.7-2.56,9.61,9.61,0,0,0,1.37-10.87c-.13-.29-.56,0-.42.25a9.18,9.18,0,0,1-.66,9.5,8.28,8.28,0,0,1-8,3.38c-3.08-.55-6.71-2.54-8-5.49a8.65,8.65,0,0,1,.87-8.35,11.1,11.1,0,0,1,8.93-4.37.25.25,0,0,0,0-.49Z"/>
                <path class="cls-4" d="M324.66,178.3c-1.08-.76-2.32-1.58-3.63-1.41H321a11.22,11.22,0,0,0-9.45,5,8.58,8.58,0,0,0,.91,10.29,11.08,11.08,0,0,0,2.34,1.73,12,12,0,0,0,5.69,1.87,8.94,8.94,0,0,0,8.53-7A10.47,10.47,0,0,0,324.66,178.3Zm.74,4.85a2.49,2.49,0,0,1-2.26,2.19,2.26,2.26,0,0,1-2.32-2c-.07-1.14.73-2.37,1.87-2.29h.06c.65-.06,1.43-.21,1.95.24A2.23,2.23,0,0,1,325.4,183.15Z"/>
                <path class="cls-4" d="M362,184.1a5.16,5.16,0,0,0-1.48,2.9c0,.13.12.25.25.25A.25.25,0,0,0,361,187a4.7,4.7,0,0,1,1.34-2.55c.22-.23-.13-.58-.35-.35Z"/>
                <path class="cls-4" d="M360.25,186.87a11.79,11.79,0,0,0,1.67,9.52,9.19,9.19,0,0,0,8.27,3.76c3.47-.35,6.37-3.24,7.94-6.21a8.69,8.69,0,0,0-.59-9,9.61,9.61,0,0,0-10.09-3.8c-.31.06-.18.54.13.48a9.15,9.15,0,0,1,9,2.91,8.34,8.34,0,0,1,1.5,8.3,13,13,0,0,1-3,4.22,8.46,8.46,0,0,1-4.16,2.51,8.65,8.65,0,0,1-8.1-2.69,11.09,11.09,0,0,1-2.17-9.83.25.25,0,0,0-.48-.13Z"/>
                <path class="cls-4" d="M360.18,179c-1,.87-2.1,1.87-2.25,3.18l0,.07a11.21,11.21,0,0,0,2.51,10.37c2.6,2.66,7,3.52,10.2,1.62a11.14,11.14,0,0,0,2.25-1.86,12,12,0,0,0,3.19-5.06,8.92,8.92,0,0,0-4.71-10A10.45,10.45,0,0,0,360.18,179Zm4.88.47a2.49,2.49,0,0,1,1.58,2.72A2.28,2.28,0,0,1,364.1,184a2,2,0,0,1-1.76-2.38v-.06c.1-.64.14-1.43.71-1.83A2.21,2.21,0,0,1,365.06,179.5Z"/>
                <path class="cls-4" d="M347.73,178.18a5.12,5.12,0,0,0-2.66-1.76c-.31-.07-.45.41-.13.48a4.68,4.68,0,0,1,2.44,1.63.25.25,0,0,0,.35,0,.24.24,0,0,0,0-.35Z"/>
                <path class="cls-4" d="M345.16,176.19a11.67,11.67,0,0,0-10.09.94,9.21,9.21,0,0,0-4.14,7.77c.07,3.46,2.75,6.68,5.56,8.47a8.69,8.69,0,0,0,8.72.29,9.6,9.6,0,0,0,5-9.66c0-.32-.53-.32-.5,0a9.18,9.18,0,0,1-3.64,8.58,8.4,8.4,0,0,1-8.42.86,12.27,12.27,0,0,1-3.71-3.05,9.27,9.27,0,0,1-2.38-4.44,8.61,8.61,0,0,1,3.16-7.93A11,11,0,0,1,345,176.67c.3.1.43-.38.13-.48Z"/>
                <path class="cls-4" d="M353,177c-.76-1.09-1.63-2.29-2.93-2.58l-.06,0a11.19,11.19,0,0,0-10.57,1.41,8.58,8.58,0,0,0-2.69,10,11.52,11.52,0,0,0,1.61,2.43,12.12,12.12,0,0,0,4.7,3.71,8.93,8.93,0,0,0,10.41-3.64A10.46,10.46,0,0,0,353,177Zm-1,4.8a2.51,2.51,0,0,1-2.88,1.29,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.55-1.51l.06,0c.62.17,1.4.29,1.74.9A2.22,2.22,0,0,1,352,181.75Z"/>
                <path class="cls-4" d="M394.51,181.27a5.09,5.09,0,0,0-3.11-.74.25.25,0,0,0-.25.24c0,.13.12.27.25.25a4.74,4.74,0,0,1,2.86.68.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M391.41,180.28a11.74,11.74,0,0,0-8.81,3.9,9.2,9.2,0,0,0-1.73,8.77c1.11,3.34,4.55,5.45,7.81,6.33a8.69,8.69,0,0,0,8.7-2.56,9.61,9.61,0,0,0,1.37-10.87c-.13-.29-.56,0-.43.25a9.15,9.15,0,0,1-.65,9.5,8.29,8.29,0,0,1-8,3.38c-3.08-.55-6.71-2.54-8-5.49a8.65,8.65,0,0,1,.87-8.35,11.1,11.1,0,0,1,8.93-4.37.25.25,0,0,0,0-.49Z"/>
                <path class="cls-4" d="M399,178.3c-1.08-.76-2.32-1.58-3.63-1.41h-.07a11.22,11.22,0,0,0-9.45,5,8.6,8.6,0,0,0,.9,10.29,11.4,11.4,0,0,0,2.35,1.73,12,12,0,0,0,5.69,1.87,8.94,8.94,0,0,0,8.53-7A10.47,10.47,0,0,0,399,178.3Zm.74,4.85a2.49,2.49,0,0,1-2.26,2.19,2.26,2.26,0,0,1-2.32-2c-.07-1.14.73-2.37,1.87-2.29h.06c.65-.06,1.43-.21,1.95.24A2.23,2.23,0,0,1,399.73,183.15Z"/>
                <path class="cls-4" d="M436.3,184.1a5.16,5.16,0,0,0-1.48,2.9c0,.13.12.25.25.25a.25.25,0,0,0,.24-.25,4.7,4.7,0,0,1,1.34-2.55c.22-.23-.13-.58-.35-.35Z"/>
                <path class="cls-4" d="M434.58,186.87a11.79,11.79,0,0,0,1.67,9.52,9.19,9.19,0,0,0,8.27,3.76c3.47-.35,6.36-3.24,7.94-6.21a8.71,8.71,0,0,0-.59-9,9.61,9.61,0,0,0-10.09-3.8c-.31.06-.18.54.13.48a9.15,9.15,0,0,1,9,2.91,8.34,8.34,0,0,1,1.5,8.3,13,13,0,0,1-3,4.22,8.46,8.46,0,0,1-4.16,2.51,8.65,8.65,0,0,1-8.1-2.69,11.09,11.09,0,0,1-2.17-9.83.25.25,0,0,0-.48-.13Z"/>
                <path class="cls-4" d="M434.51,179c-1,.87-2.1,1.87-2.25,3.18l0,.07a11.21,11.21,0,0,0,2.51,10.37,8.6,8.6,0,0,0,10.2,1.62,11.14,11.14,0,0,0,2.25-1.86,12,12,0,0,0,3.19-5.06,8.92,8.92,0,0,0-4.71-10A10.45,10.45,0,0,0,434.51,179Zm4.88.47a2.49,2.49,0,0,1,1.58,2.72,2.28,2.28,0,0,1-2.54,1.76,2,2,0,0,1-1.76-2.38v-.06c.1-.64.14-1.43.71-1.83A2.21,2.21,0,0,1,439.39,179.5Z"/>
                <path class="cls-4" d="M422.06,178.18a5.12,5.12,0,0,0-2.66-1.76c-.31-.07-.45.41-.13.48a4.68,4.68,0,0,1,2.44,1.63.25.25,0,1,0,.35-.35Z"/>
                <path class="cls-4" d="M419.49,176.19a11.67,11.67,0,0,0-10.09.94,9.21,9.21,0,0,0-4.14,7.77c.07,3.46,2.75,6.68,5.56,8.47a8.69,8.69,0,0,0,8.72.29,9.6,9.6,0,0,0,5-9.66c0-.32-.53-.32-.5,0a9.17,9.17,0,0,1-3.65,8.58,8.38,8.38,0,0,1-8.41.86,12.27,12.27,0,0,1-3.71-3.05,9.27,9.27,0,0,1-2.38-4.44A8.61,8.61,0,0,1,409,178a11,11,0,0,1,10.35-1.35c.3.1.43-.38.13-.48Z"/>
                <path class="cls-4" d="M427.31,177c-.76-1.09-1.63-2.29-2.93-2.58l-.06,0a11.19,11.19,0,0,0-10.57,1.41,8.58,8.58,0,0,0-2.69,10,11.52,11.52,0,0,0,1.61,2.43,12.12,12.12,0,0,0,4.7,3.71,8.93,8.93,0,0,0,10.41-3.64A10.46,10.46,0,0,0,427.31,177Zm-1,4.8a2.49,2.49,0,0,1-2.87,1.29,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.55-1.51l.06,0c.62.17,1.4.29,1.74.9A2.19,2.19,0,0,1,426.33,181.75Z"/>
            </g>
            <g class="cls-3">
                <path class="cls-4" d="M162.21,206.11a5.13,5.13,0,0,0-3.1-.74.25.25,0,0,0,0,.5,4.61,4.61,0,0,1,2.85.67.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M159.11,205.12a11.79,11.79,0,0,0-8.81,3.9,9.23,9.23,0,0,0-1.73,8.77c1.12,3.34,4.55,5.45,7.82,6.33a8.7,8.7,0,0,0,8.7-2.56,9.62,9.62,0,0,0,1.37-10.87c-.14-.28-.57,0-.43.26a9.14,9.14,0,0,1-.65,9.49,8.33,8.33,0,0,1-8,3.39c-3.08-.55-6.71-2.54-8-5.5a8.69,8.69,0,0,1,.87-8.35,11.12,11.12,0,0,1,8.93-4.36c.32,0,.32-.51,0-.5Z"/>
                <path class="cls-4" d="M166.7,203.14c-1.09-.76-2.32-1.58-3.63-1.41H163a11.18,11.18,0,0,0-9.45,5c-2,3.16-1.72,7.66.9,10.28a11.4,11.4,0,0,0,2.35,1.73,11.93,11.93,0,0,0,5.69,1.87,8.92,8.92,0,0,0,8.52-7A10.47,10.47,0,0,0,166.7,203.14Zm.73,4.85a2.49,2.49,0,0,1-2.25,2.2,2.3,2.3,0,0,1-2.33-2c-.07-1.14.74-2.37,1.88-2.29h.06a2.52,2.52,0,0,1,2,.25A2.19,2.19,0,0,1,167.43,208Z"/>
                <path class="cls-4" d="M204,208.94a5.24,5.24,0,0,0-1.49,2.9.25.25,0,0,0,.5,0,4.63,4.63,0,0,1,1.34-2.54c.22-.24-.13-.59-.35-.36Z"/>
                <path class="cls-4" d="M202.29,211.71a11.73,11.73,0,0,0,1.67,9.52,9.16,9.16,0,0,0,8.26,3.76c3.47-.35,6.37-3.24,7.94-6.21a8.71,8.71,0,0,0-.58-9,9.62,9.62,0,0,0-10.09-3.8c-.32.06-.19.54.13.48a9.15,9.15,0,0,1,9,2.91,8.34,8.34,0,0,1,1.5,8.3,12.81,12.81,0,0,1-3,4.22,8.43,8.43,0,0,1-4.15,2.51,8.61,8.61,0,0,1-8.1-2.69,11.06,11.06,0,0,1-2.17-9.83c.07-.31-.41-.44-.48-.13Z"/>
                <path class="cls-4" d="M202.21,203.88c-1,.86-2.1,1.86-2.25,3.18l0,.06a11.2,11.2,0,0,0,2.51,10.37,8.58,8.58,0,0,0,10.2,1.62,11.67,11.67,0,0,0,2.24-1.85,12.14,12.14,0,0,0,3.2-5.07,8.92,8.92,0,0,0-4.71-10A10.48,10.48,0,0,0,202.21,203.88Zm4.89.46a2.5,2.5,0,0,1,1.58,2.72,2.29,2.29,0,0,1-2.54,1.76,2,2,0,0,1-1.77-2.37l0-.06c.1-.65.14-1.43.71-1.83A2.21,2.21,0,0,1,207.1,204.34Z"/>
                <path class="cls-4" d="M189.77,203a5.14,5.14,0,0,0-2.67-1.76c-.31-.07-.44.41-.13.48a4.7,4.7,0,0,1,2.45,1.63.25.25,0,0,0,.35-.35Z"/>
                <path class="cls-4" d="M187.19,201a11.62,11.62,0,0,0-10.08.95,9.16,9.16,0,0,0-4.15,7.76c.08,3.46,2.75,6.68,5.56,8.47a8.69,8.69,0,0,0,8.72.29,9.59,9.59,0,0,0,5-9.66c0-.31-.52-.32-.49,0a9.21,9.21,0,0,1-3.65,8.59,8.38,8.38,0,0,1-8.41.85,12.17,12.17,0,0,1-3.72-3.05,9.13,9.13,0,0,1-2.37-4.44,8.54,8.54,0,0,1,3.16-7.92,11,11,0,0,1,10.34-1.36c.3.11.43-.37.13-.48Z"/>
                <path class="cls-4" d="M195,201.79c-.76-1.09-1.64-2.29-2.93-2.57l-.06,0a11.18,11.18,0,0,0-10.58,1.4,8.58,8.58,0,0,0-2.68,10,10.91,10.91,0,0,0,1.61,2.43,12.05,12.05,0,0,0,4.7,3.71,8.92,8.92,0,0,0,10.41-3.64A10.46,10.46,0,0,0,195,201.79Zm-1,4.81a2.5,2.5,0,0,1-2.87,1.28,2.3,2.3,0,0,1-1.49-2.71,2,2,0,0,1,2.55-1.51l.06,0c.63.18,1.41.29,1.75.91A2.22,2.22,0,0,1,194,206.6Z"/>
                <path class="cls-4" d="M236.54,206.11a5.13,5.13,0,0,0-3.1-.74.25.25,0,0,0,0,.5,4.61,4.61,0,0,1,2.85.67.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M233.44,205.12a11.79,11.79,0,0,0-8.81,3.9,9.23,9.23,0,0,0-1.73,8.77c1.12,3.34,4.55,5.45,7.82,6.33a8.7,8.7,0,0,0,8.7-2.56,9.62,9.62,0,0,0,1.37-10.87c-.14-.28-.57,0-.43.26a9.16,9.16,0,0,1-.65,9.49,8.33,8.33,0,0,1-8,3.39c-3.08-.55-6.71-2.54-8-5.5a8.69,8.69,0,0,1,.87-8.35,11.1,11.1,0,0,1,8.93-4.36c.32,0,.32-.51,0-.5Z"/>
                <path class="cls-4" d="M241,203.14c-1.09-.76-2.32-1.58-3.64-1.41h-.06a11.18,11.18,0,0,0-9.45,5c-1.95,3.16-1.72,7.66.9,10.28a11.14,11.14,0,0,0,2.35,1.73,11.93,11.93,0,0,0,5.68,1.87,8.92,8.92,0,0,0,8.53-7A10.47,10.47,0,0,0,241,203.14Zm.73,4.85a2.49,2.49,0,0,1-2.25,2.2,2.29,2.29,0,0,1-2.33-2c-.07-1.14.74-2.37,1.88-2.29h.06a2.52,2.52,0,0,1,1.95.25A2.22,2.22,0,0,1,241.76,208Z"/>
                <path class="cls-4" d="M278.34,208.94a5.24,5.24,0,0,0-1.49,2.9.25.25,0,1,0,.5,0,4.63,4.63,0,0,1,1.34-2.54c.22-.24-.13-.59-.35-.36Z"/>
                <path class="cls-4" d="M276.62,211.71a11.73,11.73,0,0,0,1.67,9.52,9.16,9.16,0,0,0,8.26,3.76c3.47-.35,6.37-3.24,7.94-6.21a8.71,8.71,0,0,0-.58-9,9.63,9.63,0,0,0-10.1-3.8c-.31.06-.18.54.14.48a9.15,9.15,0,0,1,9,2.91,8.34,8.34,0,0,1,1.5,8.3,13,13,0,0,1-3,4.22,8.43,8.43,0,0,1-4.15,2.51,8.6,8.6,0,0,1-8.1-2.69,11.06,11.06,0,0,1-2.17-9.83c.07-.31-.41-.44-.48-.13Z"/>
                <path class="cls-4" d="M276.54,203.88c-1,.86-2.1,1.86-2.25,3.18l0,.06a11.2,11.2,0,0,0,2.51,10.37,8.58,8.58,0,0,0,10.2,1.62,11.67,11.67,0,0,0,2.24-1.85,12.14,12.14,0,0,0,3.2-5.07,8.92,8.92,0,0,0-4.71-10A10.48,10.48,0,0,0,276.54,203.88Zm4.89.46a2.5,2.5,0,0,1,1.58,2.72,2.29,2.29,0,0,1-2.54,1.76,2,2,0,0,1-1.77-2.37l0-.06c.1-.65.14-1.43.71-1.83A2.21,2.21,0,0,1,281.43,204.34Z"/>
                <path class="cls-4" d="M264.1,203a5.14,5.14,0,0,0-2.67-1.76c-.31-.07-.44.41-.13.48a4.7,4.7,0,0,1,2.45,1.63.25.25,0,0,0,.35-.35Z"/>
                <path class="cls-4" d="M261.52,201a11.64,11.64,0,0,0-10.09.95,9.18,9.18,0,0,0-4.14,7.76c.08,3.46,2.75,6.68,5.56,8.47a8.69,8.69,0,0,0,8.72.29,9.59,9.59,0,0,0,5-9.66c0-.31-.53-.32-.5,0a9.18,9.18,0,0,1-3.64,8.59,8.38,8.38,0,0,1-8.41.85,12.05,12.05,0,0,1-3.72-3.05,9.13,9.13,0,0,1-2.37-4.44,8.54,8.54,0,0,1,3.16-7.92,11,11,0,0,1,10.34-1.36c.3.11.43-.37.13-.48Z"/>
                <path class="cls-4" d="M269.35,201.79c-.76-1.09-1.64-2.29-2.93-2.57l-.06,0a11.18,11.18,0,0,0-10.58,1.4,8.58,8.58,0,0,0-2.68,10,11.23,11.23,0,0,0,1.6,2.43,12.15,12.15,0,0,0,4.71,3.71,8.92,8.92,0,0,0,10.41-3.64A10.48,10.48,0,0,0,269.35,201.79Zm-1,4.81a2.5,2.5,0,0,1-2.87,1.28,2.3,2.3,0,0,1-1.49-2.71,2,2,0,0,1,2.55-1.51l.06,0c.63.18,1.41.29,1.74.91A2.19,2.19,0,0,1,268.37,206.6Z"/>
                <path class="cls-4" d="M14.09,206.11a5.16,5.16,0,0,0-3.11-.74.25.25,0,0,0,0,.5,4.64,4.64,0,0,1,2.86.67.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M11,205.12A11.78,11.78,0,0,0,2.18,209a9.2,9.2,0,0,0-1.73,8.77c1.12,3.34,4.55,5.45,7.82,6.33a8.7,8.7,0,0,0,8.7-2.56,9.62,9.62,0,0,0,1.37-10.87c-.14-.28-.57,0-.43.26a9.18,9.18,0,0,1-.65,9.49,8.33,8.33,0,0,1-8,3.39c-3.08-.55-6.7-2.54-8-5.5A8.66,8.66,0,0,1,2.06,210,11.09,11.09,0,0,1,11,205.62c.32,0,.32-.51,0-.5Z"/>
                <path class="cls-4" d="M18.57,203.14c-1.08-.76-2.31-1.58-3.63-1.41h-.07a11.21,11.21,0,0,0-9.45,5A8.58,8.58,0,0,0,6.33,217a11.34,11.34,0,0,0,2.34,1.73,12,12,0,0,0,5.69,1.87,8.93,8.93,0,0,0,8.53-7A10.47,10.47,0,0,0,18.57,203.14Zm.74,4.85a2.51,2.51,0,0,1-2.26,2.2,2.29,2.29,0,0,1-2.32-2c-.07-1.14.73-2.37,1.87-2.29h.07c.64-.06,1.42-.21,1.94.25A2.2,2.2,0,0,1,19.31,208Z"/>
                <path class="cls-4" d="M55.88,208.94a5.16,5.16,0,0,0-1.48,2.9c0,.13.13.25.25.25a.26.26,0,0,0,.25-.25,4.51,4.51,0,0,1,1.34-2.54c.21-.24-.14-.59-.36-.36Z"/>
                <path class="cls-4" d="M54.17,211.71a11.77,11.77,0,0,0,1.66,9.52A9.19,9.19,0,0,0,64.1,225c3.47-.35,6.37-3.24,7.94-6.21a8.69,8.69,0,0,0-.59-9,9.61,9.61,0,0,0-10.09-3.8c-.31.06-.18.54.13.48a9.17,9.17,0,0,1,9,2.91,8.34,8.34,0,0,1,1.5,8.3,13,13,0,0,1-3,4.22,8.46,8.46,0,0,1-4.16,2.51,8.58,8.58,0,0,1-8.09-2.69,11,11,0,0,1-2.17-9.83c.07-.31-.41-.44-.48-.13Z"/>
                <path class="cls-4" d="M54.09,203.88c-1,.86-2.1,1.86-2.25,3.18l0,.06a11.2,11.2,0,0,0,2.51,10.37,8.58,8.58,0,0,0,10.2,1.62,11.67,11.67,0,0,0,2.24-1.85,12.14,12.14,0,0,0,3.2-5.07,8.94,8.94,0,0,0-4.72-10A10.46,10.46,0,0,0,54.09,203.88Zm4.89.46a2.48,2.48,0,0,1,1.57,2.72A2.28,2.28,0,0,1,58,208.82a2,2,0,0,1-1.76-2.37v-.06c.11-.65.14-1.43.71-1.83A2.23,2.23,0,0,1,59,204.34Z"/>
                <path class="cls-4" d="M41.65,203A5.18,5.18,0,0,0,39,201.26c-.31-.07-.44.41-.13.48a4.68,4.68,0,0,1,2.44,1.63.25.25,0,0,0,.36-.35Z"/>
                <path class="cls-4" d="M39.07,201A11.64,11.64,0,0,0,29,202a9.18,9.18,0,0,0-4.14,7.76c.08,3.46,2.75,6.68,5.56,8.47a8.69,8.69,0,0,0,8.72.29,9.59,9.59,0,0,0,5-9.66c0-.31-.53-.32-.5,0a9.21,9.21,0,0,1-3.64,8.59,8.39,8.39,0,0,1-8.42.85,12.13,12.13,0,0,1-3.71-3.05,9.23,9.23,0,0,1-2.38-4.44,8.59,8.59,0,0,1,3.16-7.92,11,11,0,0,1,10.35-1.36c.3.11.43-.37.13-.48Z"/>
                <path class="cls-4" d="M46.89,201.79c-.76-1.09-1.63-2.29-2.92-2.57l-.07,0a11.16,11.16,0,0,0-10.57,1.4c-2.92,2.3-4.25,6.6-2.69,10A11.27,11.27,0,0,0,32.25,213,12.12,12.12,0,0,0,37,216.7a8.91,8.91,0,0,0,10.41-3.64A10.44,10.44,0,0,0,46.89,201.79Zm-1,4.81A2.51,2.51,0,0,1,43,207.88a2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.55-1.51l.06,0c.62.18,1.4.29,1.74.91A2.22,2.22,0,0,1,45.92,206.6Z"/>
                <path class="cls-4" d="M88.42,206.11a5.16,5.16,0,0,0-3.11-.74.25.25,0,0,0,0,.5,4.64,4.64,0,0,1,2.86.67.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M85.32,205.12a11.78,11.78,0,0,0-8.81,3.9,9.2,9.2,0,0,0-1.73,8.77c1.12,3.34,4.55,5.45,7.81,6.33a8.71,8.71,0,0,0,8.71-2.56,9.64,9.64,0,0,0,1.37-10.87c-.14-.28-.57,0-.43.26a9.16,9.16,0,0,1-.66,9.49,8.31,8.31,0,0,1-8,3.39c-3.08-.55-6.7-2.54-8-5.5a8.66,8.66,0,0,1,.87-8.35,11.09,11.09,0,0,1,8.93-4.36c.32,0,.32-.51,0-.5Z"/>
                <path class="cls-4" d="M92.9,203.14c-1.08-.76-2.31-1.58-3.63-1.41H89.2a11.21,11.21,0,0,0-9.45,5c-2,3.16-1.72,7.66.91,10.28A11.08,11.08,0,0,0,83,218.69a11.93,11.93,0,0,0,5.69,1.87,8.93,8.93,0,0,0,8.53-7A10.47,10.47,0,0,0,92.9,203.14Zm.74,4.85a2.51,2.51,0,0,1-2.26,2.2,2.29,2.29,0,0,1-2.32-2c-.07-1.14.73-2.37,1.87-2.29H91c.64-.06,1.42-.21,1.94.25A2.2,2.2,0,0,1,93.64,208Z"/>
                <path class="cls-4" d="M130.21,208.94a5.16,5.16,0,0,0-1.48,2.9c0,.13.12.25.25.25a.27.27,0,0,0,.25-.25,4.56,4.56,0,0,1,1.33-2.54c.22-.24-.13-.59-.35-.36Z"/>
                <path class="cls-4" d="M128.5,211.71a11.73,11.73,0,0,0,1.66,9.52,9.19,9.19,0,0,0,8.27,3.76c3.47-.35,6.37-3.24,7.94-6.21a8.69,8.69,0,0,0-.59-9,9.61,9.61,0,0,0-10.09-3.8c-.31.06-.18.54.13.48a9.17,9.17,0,0,1,9,2.91,8.34,8.34,0,0,1,1.5,8.3,13,13,0,0,1-3,4.22,8.46,8.46,0,0,1-4.16,2.51,8.58,8.58,0,0,1-8.09-2.69,11,11,0,0,1-2.17-9.83c.07-.31-.41-.44-.48-.13Z"/>
                <path class="cls-4" d="M128.42,203.88c-1,.86-2.1,1.86-2.25,3.18l0,.06a11.17,11.17,0,0,0,2.51,10.37c2.59,2.66,7,3.53,10.19,1.62a11.43,11.43,0,0,0,2.25-1.85,12,12,0,0,0,3.19-5.07,8.92,8.92,0,0,0-4.71-10A10.46,10.46,0,0,0,128.42,203.88Zm4.88.46a2.48,2.48,0,0,1,1.58,2.72,2.28,2.28,0,0,1-2.54,1.76c-1.12-.21-2.12-1.29-1.76-2.37v-.06c.11-.65.14-1.43.71-1.83A2.22,2.22,0,0,1,133.3,204.34Z"/>
                <path class="cls-4" d="M116,203a5.18,5.18,0,0,0-2.67-1.76c-.31-.07-.45.41-.13.48a4.68,4.68,0,0,1,2.44,1.63A.25.25,0,0,0,116,203Z"/>
                <path class="cls-4" d="M113.4,201a11.64,11.64,0,0,0-10.09.95,9.18,9.18,0,0,0-4.14,7.76c.08,3.46,2.75,6.68,5.56,8.47a8.69,8.69,0,0,0,8.72.29,9.59,9.59,0,0,0,5-9.66c0-.31-.53-.32-.5,0a9.21,9.21,0,0,1-3.64,8.59,8.39,8.39,0,0,1-8.42.85,12.13,12.13,0,0,1-3.71-3.05,9.23,9.23,0,0,1-2.38-4.44,8.59,8.59,0,0,1,3.16-7.92,11,11,0,0,1,10.35-1.36c.3.11.43-.37.13-.48Z"/>
                <path class="cls-4" d="M121.22,201.79c-.76-1.09-1.63-2.29-2.93-2.57l-.06,0a11.16,11.16,0,0,0-10.57,1.4c-2.92,2.3-4.25,6.6-2.69,10a11.27,11.27,0,0,0,1.61,2.43,12.12,12.12,0,0,0,4.7,3.71,8.91,8.91,0,0,0,10.41-3.64A10.46,10.46,0,0,0,121.22,201.79Zm-1,4.81a2.51,2.51,0,0,1-2.88,1.28,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.55-1.51l.06,0c.62.18,1.4.29,1.74.91A2.22,2.22,0,0,1,120.25,206.6Z"/>
                <path class="cls-4" d="M168.1,230.71A5.11,5.11,0,0,0,165,230a.25.25,0,0,0-.25.25.25.25,0,0,0,.25.24,4.74,4.74,0,0,1,2.86.68.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M165,229.72a11.79,11.79,0,0,0-8.81,3.9,9.22,9.22,0,0,0-1.72,8.77c1.11,3.34,4.55,5.45,7.81,6.33a8.69,8.69,0,0,0,8.7-2.56,9.63,9.63,0,0,0,1.37-10.87c-.14-.29-.56,0-.43.25a9.15,9.15,0,0,1-.65,9.5,8.29,8.29,0,0,1-8,3.38c-3.08-.55-6.71-2.53-8-5.49a8.66,8.66,0,0,1,.88-8.35,11.09,11.09,0,0,1,8.92-4.37.25.25,0,0,0,0-.49Z"/>
                <path class="cls-4" d="M172.58,227.74c-1.09-.76-2.32-1.58-3.63-1.41h-.07a11.2,11.2,0,0,0-9.45,5,8.58,8.58,0,0,0,.9,10.28,11.4,11.4,0,0,0,2.35,1.73,12,12,0,0,0,5.69,1.87,8.94,8.94,0,0,0,8.53-7A10.49,10.49,0,0,0,172.58,227.74Zm.74,4.85a2.49,2.49,0,0,1-2.26,2.19,2.28,2.28,0,0,1-2.33-2c-.06-1.14.74-2.37,1.88-2.29h.06a2.57,2.57,0,0,1,2,.24A2.23,2.23,0,0,1,173.32,232.59Z"/>
                <path class="cls-4" d="M209.89,233.54a5.16,5.16,0,0,0-1.48,2.9.25.25,0,0,0,.24.25.25.25,0,0,0,.25-.25,4.7,4.7,0,0,1,1.34-2.55c.22-.23-.13-.58-.35-.35Z"/>
                <path class="cls-4" d="M208.17,236.31a11.75,11.75,0,0,0,1.67,9.52,9.17,9.17,0,0,0,8.26,3.76c3.48-.35,6.37-3.24,8-6.21a8.71,8.71,0,0,0-.59-9,9.61,9.61,0,0,0-10.09-3.8c-.32.06-.18.54.13.48a9.13,9.13,0,0,1,9,2.91,8.32,8.32,0,0,1,1.5,8.3,12.78,12.78,0,0,1-2.95,4.22,8.5,8.5,0,0,1-4.16,2.51,8.63,8.63,0,0,1-8.1-2.69,11.09,11.09,0,0,1-2.17-9.83c.07-.31-.4-.45-.48-.13Z"/>
                <path class="cls-4" d="M208.1,228.47c-1,.87-2.1,1.87-2.25,3.19l0,.06a11.21,11.21,0,0,0,2.51,10.37,8.59,8.59,0,0,0,10.2,1.62,11.14,11.14,0,0,0,2.25-1.86,12,12,0,0,0,3.19-5.06,8.92,8.92,0,0,0-4.71-10A10.45,10.45,0,0,0,208.1,228.47Zm4.88.47a2.5,2.5,0,0,1,1.58,2.72,2.29,2.29,0,0,1-2.54,1.76,2,2,0,0,1-1.77-2.38l0-.06c.1-.64.14-1.43.71-1.83A2.24,2.24,0,0,1,213,228.94Z"/>
                <path class="cls-4" d="M195.65,227.62a5.16,5.16,0,0,0-2.66-1.76c-.32-.07-.45.41-.14.48A4.7,4.7,0,0,1,195.3,228a.25.25,0,1,0,.35-.35Z"/>
                <path class="cls-4" d="M193.08,225.63a11.64,11.64,0,0,0-10.09,1,9.16,9.16,0,0,0-4.15,7.76c.08,3.46,2.76,6.68,5.57,8.47a8.69,8.69,0,0,0,8.72.29,9.58,9.58,0,0,0,5-9.66c0-.31-.52-.32-.49,0a9.19,9.19,0,0,1-3.65,8.59,8.4,8.4,0,0,1-8.41.85,12.29,12.29,0,0,1-3.72-3,9.29,9.29,0,0,1-2.37-4.44,8.53,8.53,0,0,1,3.16-7.92,10.94,10.94,0,0,1,10.34-1.36c.31.1.44-.38.14-.48Z"/>
                <path class="cls-4" d="M200.9,226.39c-.76-1.09-1.63-2.29-2.93-2.58l-.06,0a11.21,11.21,0,0,0-10.58,1.41c-2.91,2.3-4.24,6.6-2.68,10a11.52,11.52,0,0,0,1.61,2.43,12.12,12.12,0,0,0,4.7,3.71,8.93,8.93,0,0,0,10.41-3.64A10.46,10.46,0,0,0,200.9,226.39Zm-1,4.81a2.5,2.5,0,0,1-2.87,1.28,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.54-1.51l.06,0c.63.17,1.41.29,1.75.9A2.25,2.25,0,0,1,199.92,231.2Z"/>
                <path class="cls-4" d="M242.43,230.71a5.11,5.11,0,0,0-3.11-.74.25.25,0,0,0-.25.25.24.24,0,0,0,.25.24,4.69,4.69,0,0,1,2.85.68c.28.16.54-.27.26-.43Z"/>
                <path class="cls-4" d="M239.32,229.72a11.79,11.79,0,0,0-8.81,3.9,9.25,9.25,0,0,0-1.73,8.77c1.12,3.34,4.56,5.45,7.82,6.33a8.69,8.69,0,0,0,8.7-2.56,9.63,9.63,0,0,0,1.37-10.87c-.14-.29-.56,0-.43.25a9.15,9.15,0,0,1-.65,9.5,8.3,8.3,0,0,1-8,3.38c-3.08-.55-6.71-2.53-8-5.49a8.66,8.66,0,0,1,.88-8.35,11.09,11.09,0,0,1,8.92-4.37.25.25,0,0,0,0-.49Z"/>
                <path class="cls-4" d="M246.91,227.74c-1.09-.76-2.32-1.58-3.63-1.41h-.07a11.19,11.19,0,0,0-9.45,5,8.58,8.58,0,0,0,.9,10.28,11.4,11.4,0,0,0,2.35,1.73,12,12,0,0,0,5.69,1.87,8.92,8.92,0,0,0,8.52-7A10.47,10.47,0,0,0,246.91,227.74Zm.74,4.85a2.49,2.49,0,0,1-2.26,2.19,2.28,2.28,0,0,1-2.33-2c-.06-1.14.74-2.37,1.88-2.29H245a2.57,2.57,0,0,1,1.95.24A2.26,2.26,0,0,1,247.65,232.59Z"/>
                <path class="cls-4" d="M284.22,233.54a5.24,5.24,0,0,0-1.49,2.9.26.26,0,0,0,.25.25.25.25,0,0,0,.25-.25,4.7,4.7,0,0,1,1.34-2.55c.22-.23-.13-.58-.35-.35Z"/>
                <path class="cls-4" d="M282.5,236.31a11.75,11.75,0,0,0,1.67,9.52,9.16,9.16,0,0,0,8.26,3.76c3.48-.35,6.37-3.24,7.94-6.21a8.69,8.69,0,0,0-.58-9,9.61,9.61,0,0,0-10.09-3.8c-.32.06-.18.54.13.48a9.13,9.13,0,0,1,9,2.91,8.32,8.32,0,0,1,1.5,8.3,12.93,12.93,0,0,1-2.95,4.22,8.5,8.5,0,0,1-4.16,2.51,8.63,8.63,0,0,1-8.1-2.69,11.09,11.09,0,0,1-2.17-9.83c.07-.31-.41-.45-.48-.13Z"/>
                <path class="cls-4" d="M282.43,228.47c-1,.87-2.11,1.87-2.25,3.19l0,.06a11.21,11.21,0,0,0,2.51,10.37,8.59,8.59,0,0,0,10.2,1.62,11.14,11.14,0,0,0,2.25-1.86,12,12,0,0,0,3.19-5.06,8.92,8.92,0,0,0-4.71-10A10.45,10.45,0,0,0,282.43,228.47Zm4.88.47a2.5,2.5,0,0,1,1.58,2.72,2.29,2.29,0,0,1-2.54,1.76,2,2,0,0,1-1.77-2.38l0-.06c.1-.64.14-1.43.71-1.83A2.24,2.24,0,0,1,287.31,228.94Z"/>
                <path class="cls-4" d="M270,227.62a5.16,5.16,0,0,0-2.66-1.76c-.32-.07-.45.41-.14.48a4.7,4.7,0,0,1,2.45,1.63.25.25,0,1,0,.35-.35Z"/>
                <path class="cls-4" d="M267.41,225.63a11.64,11.64,0,0,0-10.09,1,9.16,9.16,0,0,0-4.15,7.76c.08,3.46,2.75,6.68,5.57,8.47a8.69,8.69,0,0,0,8.72.29,9.6,9.6,0,0,0,5-9.66c0-.31-.52-.32-.49,0a9.21,9.21,0,0,1-3.65,8.59,8.4,8.4,0,0,1-8.41.85,12.29,12.29,0,0,1-3.72-3,9.29,9.29,0,0,1-2.37-4.44,8.53,8.53,0,0,1,3.16-7.92,10.94,10.94,0,0,1,10.34-1.36c.31.1.44-.38.14-.48Z"/>
                <path class="cls-4" d="M275.23,226.39c-.76-1.09-1.63-2.29-2.93-2.58l-.06,0a11.21,11.21,0,0,0-10.58,1.41c-2.91,2.3-4.24,6.6-2.68,10a11.52,11.52,0,0,0,1.61,2.43,12.05,12.05,0,0,0,4.7,3.71,8.93,8.93,0,0,0,10.41-3.64A10.46,10.46,0,0,0,275.23,226.39Zm-1,4.81a2.5,2.5,0,0,1-2.87,1.28,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.54-1.51l.06,0c.63.17,1.41.29,1.75.9A2.25,2.25,0,0,1,274.25,231.2Z"/>
                <path class="cls-4" d="M20,230.71a5.06,5.06,0,0,0-3.1-.74.25.25,0,0,0-.25.25.24.24,0,0,0,.25.24,4.69,4.69,0,0,1,2.85.68.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M16.87,229.72a11.79,11.79,0,0,0-8.81,3.9,9.23,9.23,0,0,0-1.73,8.77c1.12,3.34,4.55,5.45,7.82,6.33a8.7,8.7,0,0,0,8.7-2.56,9.63,9.63,0,0,0,1.37-10.87c-.14-.29-.57,0-.43.25a9.18,9.18,0,0,1-.65,9.5,8.3,8.3,0,0,1-8,3.38c-3.09-.55-6.71-2.53-8-5.49a8.68,8.68,0,0,1,.87-8.35,11.11,11.11,0,0,1,8.93-4.37.25.25,0,0,0,0-.49Z"/>
                <path class="cls-4" d="M24.46,227.74c-1.09-.76-2.32-1.58-3.64-1.41h-.06a11.19,11.19,0,0,0-9.45,5,8.58,8.58,0,0,0,.9,10.28,11.34,11.34,0,0,0,2.34,1.73,12.11,12.11,0,0,0,5.69,1.87,8.92,8.92,0,0,0,8.53-7A10.47,10.47,0,0,0,24.46,227.74Zm.73,4.85a2.47,2.47,0,0,1-2.25,2.19,2.27,2.27,0,0,1-2.33-2c-.07-1.14.74-2.37,1.88-2.29h.06a2.57,2.57,0,0,1,1.95.24A2.25,2.25,0,0,1,25.19,232.59Z"/>
                <path class="cls-4" d="M61.77,233.54a5.24,5.24,0,0,0-1.49,2.9.25.25,0,0,0,.5,0,4.7,4.7,0,0,1,1.34-2.55c.22-.23-.13-.58-.35-.35Z"/>
                <path class="cls-4" d="M60.05,236.31a11.75,11.75,0,0,0,1.67,9.52A9.16,9.16,0,0,0,70,249.59c3.47-.35,6.37-3.24,7.94-6.21a8.71,8.71,0,0,0-.58-9,9.63,9.63,0,0,0-10.1-3.8c-.31.06-.18.54.14.48a9.13,9.13,0,0,1,9,2.91,8.34,8.34,0,0,1,1.5,8.3,13,13,0,0,1-3,4.22A8.43,8.43,0,0,1,70.8,249a8.61,8.61,0,0,1-8.1-2.69,11.06,11.06,0,0,1-2.17-9.83c.07-.31-.41-.45-.48-.13Z"/>
                <path class="cls-4" d="M60,228.47c-1,.87-2.1,1.87-2.25,3.19l0,.06a11.21,11.21,0,0,0,2.51,10.37,8.59,8.59,0,0,0,10.2,1.62,11.36,11.36,0,0,0,2.24-1.86,12.11,12.11,0,0,0,3.2-5.06,8.94,8.94,0,0,0-4.71-10A10.47,10.47,0,0,0,60,228.47Zm4.89.47a2.48,2.48,0,0,1,1.57,2.72,2.27,2.27,0,0,1-2.53,1.76A2,2,0,0,1,62.13,231l0-.06c.1-.64.14-1.43.71-1.83A2.24,2.24,0,0,1,64.86,228.94Z"/>
                <path class="cls-4" d="M47.53,227.62a5.14,5.14,0,0,0-2.67-1.76c-.31-.07-.44.41-.13.48A4.7,4.7,0,0,1,47.18,228a.25.25,0,1,0,.35-.35Z"/>
                <path class="cls-4" d="M45,225.63a11.64,11.64,0,0,0-10.09,1,9.18,9.18,0,0,0-4.14,7.76c.08,3.46,2.75,6.68,5.56,8.47a8.69,8.69,0,0,0,8.72.29,9.6,9.6,0,0,0,5-9.66c0-.31-.53-.32-.5,0A9.21,9.21,0,0,1,45.82,242a8.4,8.4,0,0,1-8.41.85,12.05,12.05,0,0,1-3.72-3,9.13,9.13,0,0,1-2.37-4.44,8.53,8.53,0,0,1,3.16-7.92,10.94,10.94,0,0,1,10.34-1.36c.3.1.43-.38.13-.48Z"/>
                <path class="cls-4" d="M52.78,226.39c-.76-1.09-1.64-2.29-2.93-2.58l-.06,0a11.21,11.21,0,0,0-10.58,1.41,8.58,8.58,0,0,0-2.68,10,11.1,11.1,0,0,0,1.6,2.43,12.15,12.15,0,0,0,4.71,3.71,8.93,8.93,0,0,0,10.41-3.64A10.48,10.48,0,0,0,52.78,226.39Zm-1,4.81a2.5,2.5,0,0,1-2.87,1.28,2.3,2.3,0,0,1-1.49-2.71A2,2,0,0,1,50,228.26l.06,0c.63.17,1.41.29,1.74.9A2.21,2.21,0,0,1,51.8,231.2Z"/>
                <path class="cls-4" d="M94.3,230.71a5.06,5.06,0,0,0-3.1-.74.25.25,0,0,0-.25.25.24.24,0,0,0,.25.24,4.71,4.71,0,0,1,2.85.68.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M91.2,229.72a11.78,11.78,0,0,0-8.81,3.9,9.23,9.23,0,0,0-1.73,8.77c1.12,3.34,4.55,5.45,7.82,6.33a8.7,8.7,0,0,0,8.7-2.56,9.63,9.63,0,0,0,1.37-10.87c-.14-.29-.57,0-.43.25a9.18,9.18,0,0,1-.65,9.5,8.31,8.31,0,0,1-8,3.38c-3.08-.55-6.7-2.53-8-5.49a8.68,8.68,0,0,1,.87-8.35,11.1,11.1,0,0,1,8.93-4.37.25.25,0,0,0,0-.49Z"/>
                <path class="cls-4" d="M98.79,227.74c-1.09-.76-2.32-1.58-3.64-1.41h-.07a11.18,11.18,0,0,0-9.44,5,8.57,8.57,0,0,0,.9,10.28,11.34,11.34,0,0,0,2.34,1.73,12.11,12.11,0,0,0,5.69,1.87,8.92,8.92,0,0,0,8.53-7A10.47,10.47,0,0,0,98.79,227.74Zm.73,4.85a2.47,2.47,0,0,1-2.26,2.19,2.27,2.27,0,0,1-2.32-2c-.07-1.14.74-2.37,1.88-2.29h.06a2.57,2.57,0,0,1,2,.24A2.25,2.25,0,0,1,99.52,232.59Z"/>
                <path class="cls-4" d="M136.1,233.54a5.18,5.18,0,0,0-1.49,2.9c0,.13.13.25.25.25a.26.26,0,0,0,.25-.25,4.7,4.7,0,0,1,1.34-2.55c.22-.23-.13-.58-.35-.35Z"/>
                <path class="cls-4" d="M134.38,236.31a11.78,11.78,0,0,0,1.66,9.52,9.19,9.19,0,0,0,8.27,3.76c3.47-.35,6.37-3.24,7.94-6.21a8.71,8.71,0,0,0-.58-9,9.63,9.63,0,0,0-10.1-3.8c-.31.06-.18.54.14.48a9.13,9.13,0,0,1,9,2.91,8.34,8.34,0,0,1,1.5,8.3,13,13,0,0,1-3,4.22,8.43,8.43,0,0,1-4.15,2.51,8.61,8.61,0,0,1-8.1-2.69,11,11,0,0,1-2.17-9.83c.07-.31-.41-.45-.48-.13Z"/>
                <path class="cls-4" d="M134.3,228.47c-1,.87-2.1,1.87-2.25,3.19l0,.06a11.21,11.21,0,0,0,2.51,10.37,8.59,8.59,0,0,0,10.2,1.62,11.36,11.36,0,0,0,2.24-1.86,12.11,12.11,0,0,0,3.2-5.06,9,9,0,0,0-4.71-10A10.47,10.47,0,0,0,134.3,228.47Zm4.89.47a2.48,2.48,0,0,1,1.57,2.72,2.28,2.28,0,0,1-2.54,1.76,2,2,0,0,1-1.76-2.38V231c.11-.64.14-1.43.72-1.83A2.24,2.24,0,0,1,139.19,228.94Z"/>
                <path class="cls-4" d="M121.86,227.62a5.18,5.18,0,0,0-2.67-1.76c-.31-.07-.44.41-.13.48a4.74,4.74,0,0,1,2.45,1.63.25.25,0,1,0,.35-.35Z"/>
                <path class="cls-4" d="M119.28,225.63a11.64,11.64,0,0,0-10.09,1,9.18,9.18,0,0,0-4.14,7.76c.08,3.46,2.75,6.68,5.56,8.47a8.69,8.69,0,0,0,8.72.29,9.6,9.6,0,0,0,5-9.66c0-.31-.53-.32-.5,0a9.21,9.21,0,0,1-3.64,8.59,8.4,8.4,0,0,1-8.41.85,12.05,12.05,0,0,1-3.72-3,9.23,9.23,0,0,1-2.38-4.44,8.58,8.58,0,0,1,3.16-7.92,11,11,0,0,1,10.35-1.36c.3.1.43-.38.13-.48Z"/>
                <path class="cls-4" d="M127.1,226.39c-.75-1.09-1.63-2.29-2.92-2.58l-.07,0a11.19,11.19,0,0,0-10.57,1.41,8.6,8.6,0,0,0-2.69,10,11.52,11.52,0,0,0,1.61,2.43,12.2,12.2,0,0,0,4.7,3.71,8.93,8.93,0,0,0,10.41-3.64A10.44,10.44,0,0,0,127.1,226.39Zm-1,4.81a2.5,2.5,0,0,1-2.87,1.28,2.29,2.29,0,0,1-1.49-2.71,2,2,0,0,1,2.55-1.51l.06,0c.63.17,1.41.29,1.74.9A2.24,2.24,0,0,1,126.13,231.2Z"/>
                <path class="cls-4" d="M461.19,206.11a5.16,5.16,0,0,0-3.11-.74.25.25,0,0,0,0,.5,4.64,4.64,0,0,1,2.86.67.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M458.09,205.12a11.78,11.78,0,0,0-8.81,3.9,9.2,9.2,0,0,0-1.73,8.77c1.12,3.34,4.55,5.45,7.82,6.33a8.7,8.7,0,0,0,8.7-2.56,9.62,9.62,0,0,0,1.37-10.87c-.14-.28-.57,0-.43.26a9.16,9.16,0,0,1-.65,9.49,8.33,8.33,0,0,1-8.05,3.39c-3.08-.55-6.7-2.54-8-5.5a8.69,8.69,0,0,1,.87-8.35,11.09,11.09,0,0,1,8.93-4.36c.32,0,.32-.51,0-.5Z"/>
                <path class="cls-4" d="M465.68,203.14c-1.09-.76-2.32-1.58-3.64-1.41H462a11.18,11.18,0,0,0-9.44,5,8.56,8.56,0,0,0,.9,10.28,11.34,11.34,0,0,0,2.34,1.73,12,12,0,0,0,5.69,1.87,8.92,8.92,0,0,0,8.53-7A10.48,10.48,0,0,0,465.68,203.14Zm.73,4.85a2.51,2.51,0,0,1-2.26,2.2,2.29,2.29,0,0,1-2.32-2c-.07-1.14.74-2.37,1.87-2.29h.07c.64-.06,1.42-.21,1.94.25A2.2,2.2,0,0,1,466.41,208Z"/>
                <path class="cls-4" d="M503,208.94a5.16,5.16,0,0,0-1.48,2.9c0,.13.13.25.25.25a.26.26,0,0,0,.25-.25,4.51,4.51,0,0,1,1.34-2.54c.21-.24-.14-.59-.36-.36Z"/>
                <path class="cls-4" d="M501.27,211.71a11.77,11.77,0,0,0,1.66,9.52A9.19,9.19,0,0,0,511.2,225c3.47-.35,6.37-3.24,7.94-6.21a8.71,8.71,0,0,0-.58-9,9.63,9.63,0,0,0-10.1-3.8c-.31.06-.18.54.13.48a9.16,9.16,0,0,1,9,2.91,8.34,8.34,0,0,1,1.5,8.3,13,13,0,0,1-3,4.22,8.42,8.42,0,0,1-4.16,2.51,8.58,8.58,0,0,1-8.09-2.69,11,11,0,0,1-2.17-9.83c.07-.31-.41-.44-.48-.13Z"/>
                <path class="cls-4" d="M501.19,203.88c-1,.86-2.1,1.86-2.25,3.18l0,.06a11.2,11.2,0,0,0,2.51,10.37,8.58,8.58,0,0,0,10.2,1.62,11.67,11.67,0,0,0,2.24-1.85,12.14,12.14,0,0,0,3.2-5.07,9,9,0,0,0-4.71-10A10.47,10.47,0,0,0,501.19,203.88Zm4.89.46a2.48,2.48,0,0,1,1.57,2.72,2.28,2.28,0,0,1-2.54,1.76,2,2,0,0,1-1.76-2.37v-.06c.11-.65.14-1.43.72-1.83A2.21,2.21,0,0,1,506.08,204.34Z"/>
                <path class="cls-4" d="M488.75,203a5.18,5.18,0,0,0-2.67-1.76c-.31-.07-.44.41-.13.48a4.74,4.74,0,0,1,2.45,1.63.25.25,0,0,0,.35,0,.26.26,0,0,0,0-.35Z"/>
                <path class="cls-4" d="M486.17,201a11.64,11.64,0,0,0-10.09.95,9.18,9.18,0,0,0-4.14,7.76c.08,3.46,2.75,6.68,5.56,8.47a8.69,8.69,0,0,0,8.72.29,9.59,9.59,0,0,0,5-9.66c0-.31-.53-.32-.5,0a9.21,9.21,0,0,1-3.64,8.59,8.39,8.39,0,0,1-8.42.85,12.13,12.13,0,0,1-3.71-3.05,9.23,9.23,0,0,1-2.38-4.44,8.59,8.59,0,0,1,3.16-7.92A11,11,0,0,1,486,201.51c.3.11.43-.37.13-.48Z"/>
                <path class="cls-4" d="M494,201.79c-.76-1.09-1.63-2.29-2.92-2.57l-.07,0a11.16,11.16,0,0,0-10.57,1.4c-2.92,2.3-4.25,6.6-2.69,10a11.27,11.27,0,0,0,1.61,2.43,12.2,12.2,0,0,0,4.7,3.71,8.91,8.91,0,0,0,10.41-3.64A10.44,10.44,0,0,0,494,201.79Zm-1,4.81a2.5,2.5,0,0,1-2.87,1.28,2.28,2.28,0,0,1-1.49-2.71,2,2,0,0,1,2.55-1.51l.06,0c.63.18,1.41.29,1.74.91A2.22,2.22,0,0,1,493,206.6Z"/>
                <path class="cls-4" d="M535.52,206.11a5.16,5.16,0,0,0-3.11-.74.25.25,0,0,0,0,.5,4.64,4.64,0,0,1,2.86.67.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M532.42,205.12a11.78,11.78,0,0,0-8.81,3.9,9.2,9.2,0,0,0-1.73,8.77c1.12,3.34,4.55,5.45,7.82,6.33a8.7,8.7,0,0,0,8.7-2.56,9.62,9.62,0,0,0,1.37-10.87c-.14-.28-.57,0-.43.26a9.18,9.18,0,0,1-.65,9.49,8.33,8.33,0,0,1-8.05,3.39c-3.08-.55-6.7-2.54-8-5.5a8.66,8.66,0,0,1,.87-8.35,11.09,11.09,0,0,1,8.93-4.36c.32,0,.32-.51,0-.5Z"/>
                <path class="cls-4" d="M540,203.14c-1.08-.76-2.31-1.58-3.63-1.41h-.07a11.21,11.21,0,0,0-9.45,5,8.58,8.58,0,0,0,.91,10.28,11.08,11.08,0,0,0,2.34,1.73,12,12,0,0,0,5.69,1.87,8.93,8.93,0,0,0,8.53-7A10.47,10.47,0,0,0,540,203.14Zm.74,4.85a2.51,2.51,0,0,1-2.26,2.2,2.29,2.29,0,0,1-2.32-2c-.07-1.14.73-2.37,1.87-2.29h.07c.64-.06,1.42-.21,1.94.25A2.2,2.2,0,0,1,540.74,208Z"/>
                <path class="cls-4" d="M577.31,208.94a5.16,5.16,0,0,0-1.48,2.9c0,.13.12.25.25.25a.26.26,0,0,0,.25-.25,4.51,4.51,0,0,1,1.34-2.54c.21-.24-.14-.59-.36-.36Z"/>
                <path class="cls-4" d="M575.6,211.71a11.77,11.77,0,0,0,1.66,9.52,9.19,9.19,0,0,0,8.27,3.76c3.47-.35,6.37-3.24,7.94-6.21a8.69,8.69,0,0,0-.59-9,9.61,9.61,0,0,0-10.09-3.8c-.31.06-.18.54.13.48a9.17,9.17,0,0,1,9,2.91,8.34,8.34,0,0,1,1.5,8.3,13,13,0,0,1-3,4.22,8.46,8.46,0,0,1-4.16,2.51,8.58,8.58,0,0,1-8.09-2.69,11,11,0,0,1-2.17-9.83c.07-.31-.41-.44-.48-.13Z"/>
                <path class="cls-4" d="M575.52,203.88c-1,.86-2.1,1.86-2.25,3.18l0,.06a11.2,11.2,0,0,0,2.51,10.37,8.58,8.58,0,0,0,10.2,1.62,11.67,11.67,0,0,0,2.24-1.85,12.14,12.14,0,0,0,3.2-5.07,8.94,8.94,0,0,0-4.72-10A10.46,10.46,0,0,0,575.52,203.88Zm4.88.46a2.48,2.48,0,0,1,1.58,2.72,2.28,2.28,0,0,1-2.54,1.76c-1.12-.21-2.12-1.29-1.76-2.37v-.06c.11-.65.14-1.43.71-1.83A2.22,2.22,0,0,1,580.4,204.34Z"/>
                <path class="cls-4" d="M563.08,203a5.18,5.18,0,0,0-2.67-1.76c-.31-.07-.44.41-.13.48a4.68,4.68,0,0,1,2.44,1.63.25.25,0,1,0,.36-.35Z"/>
                <path class="cls-4" d="M560.5,201a11.64,11.64,0,0,0-10.09.95,9.18,9.18,0,0,0-4.14,7.76c.08,3.46,2.75,6.68,5.56,8.47a8.69,8.69,0,0,0,8.72.29,9.59,9.59,0,0,0,5-9.66c0-.31-.53-.32-.5,0a9.21,9.21,0,0,1-3.64,8.59,8.39,8.39,0,0,1-8.42.85,12.13,12.13,0,0,1-3.71-3.05,9.23,9.23,0,0,1-2.38-4.44,8.59,8.59,0,0,1,3.16-7.92,11,11,0,0,1,10.35-1.36c.3.11.43-.37.13-.48Z"/>
                <path class="cls-4" d="M568.32,201.79c-.76-1.09-1.63-2.29-2.92-2.57l-.07,0a11.16,11.16,0,0,0-10.57,1.4c-2.92,2.3-4.25,6.6-2.69,10a11.27,11.27,0,0,0,1.61,2.43,12.12,12.12,0,0,0,4.7,3.71,8.91,8.91,0,0,0,10.41-3.64A10.44,10.44,0,0,0,568.32,201.79Zm-1,4.81a2.51,2.51,0,0,1-2.88,1.28,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.55-1.51l.06,0c.62.18,1.4.29,1.74.91A2.22,2.22,0,0,1,567.35,206.6Z"/>
                <path class="cls-4" d="M313.07,206.11a5.16,5.16,0,0,0-3.11-.74.25.25,0,1,0,0,.5,4.66,4.66,0,0,1,2.86.67.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M310,205.12a11.78,11.78,0,0,0-8.81,3.9,9.2,9.2,0,0,0-1.73,8.77c1.11,3.34,4.55,5.45,7.81,6.33a8.69,8.69,0,0,0,8.7-2.56,9.6,9.6,0,0,0,1.37-10.87c-.13-.28-.56,0-.43.26a9.14,9.14,0,0,1-.65,9.49,8.32,8.32,0,0,1-8,3.39c-3.08-.55-6.71-2.54-8-5.5A8.66,8.66,0,0,1,301,210a11.09,11.09,0,0,1,8.93-4.36c.31,0,.32-.51,0-.5Z"/>
                <path class="cls-4" d="M317.55,203.14c-1.08-.76-2.32-1.58-3.63-1.41h-.07a11.19,11.19,0,0,0-9.45,5c-1.95,3.16-1.72,7.66.9,10.28a11.4,11.4,0,0,0,2.35,1.73,11.93,11.93,0,0,0,5.69,1.87,8.94,8.94,0,0,0,8.53-7A10.47,10.47,0,0,0,317.55,203.14Zm.74,4.85a2.51,2.51,0,0,1-2.26,2.2,2.28,2.28,0,0,1-2.32-2c-.07-1.14.73-2.37,1.87-2.29h.06a2.52,2.52,0,0,1,1.95.25A2.2,2.2,0,0,1,318.29,208Z"/>
                <path class="cls-4" d="M354.86,208.94a5.16,5.16,0,0,0-1.48,2.9.25.25,0,0,0,.24.25.25.25,0,0,0,.25-.25,4.63,4.63,0,0,1,1.34-2.54c.22-.24-.13-.59-.35-.36Z"/>
                <path class="cls-4" d="M353.14,211.71a11.77,11.77,0,0,0,1.67,9.52,9.18,9.18,0,0,0,8.26,3.76c3.48-.35,6.37-3.24,7.95-6.21a8.71,8.71,0,0,0-.59-9,9.61,9.61,0,0,0-10.09-3.8c-.31.06-.18.54.13.48a9.17,9.17,0,0,1,9,2.91,8.34,8.34,0,0,1,1.5,8.3,13,13,0,0,1-3,4.22,8.46,8.46,0,0,1-4.16,2.51,8.61,8.61,0,0,1-8.1-2.69,11.09,11.09,0,0,1-2.17-9.83.25.25,0,0,0-.48-.13Z"/>
                <path class="cls-4" d="M353.07,203.88c-1,.86-2.1,1.86-2.25,3.18l0,.06a11.2,11.2,0,0,0,2.51,10.37c2.59,2.66,7,3.53,10.2,1.62a11.43,11.43,0,0,0,2.25-1.85,12,12,0,0,0,3.19-5.07,8.92,8.92,0,0,0-4.71-10A10.46,10.46,0,0,0,353.07,203.88Zm4.88.46a2.49,2.49,0,0,1,1.58,2.72,2.28,2.28,0,0,1-2.54,1.76c-1.12-.21-2.12-1.29-1.76-2.37v-.06c.1-.65.14-1.43.71-1.83A2.21,2.21,0,0,1,358,204.34Z"/>
                <path class="cls-4" d="M340.62,203a5.12,5.12,0,0,0-2.66-1.76c-.31-.07-.45.41-.14.48a4.66,4.66,0,0,1,2.45,1.63.25.25,0,0,0,.35-.35Z"/>
                <path class="cls-4" d="M338.05,201A11.64,11.64,0,0,0,328,202a9.21,9.21,0,0,0-4.15,7.76c.08,3.46,2.76,6.68,5.57,8.47a8.69,8.69,0,0,0,8.72.29,9.59,9.59,0,0,0,5-9.66c0-.31-.53-.32-.5,0a9.19,9.19,0,0,1-3.65,8.59,8.38,8.38,0,0,1-8.41.85,12.13,12.13,0,0,1-3.71-3.05,9.23,9.23,0,0,1-2.38-4.44,8.57,8.57,0,0,1,3.16-7.92,11,11,0,0,1,10.35-1.36c.3.11.43-.37.13-.48Z"/>
                <path class="cls-4" d="M345.87,201.79c-.76-1.09-1.63-2.29-2.93-2.57l-.06,0a11.16,11.16,0,0,0-10.57,1.4c-2.92,2.3-4.25,6.6-2.69,10a11.27,11.27,0,0,0,1.61,2.43,12.12,12.12,0,0,0,4.7,3.71,8.91,8.91,0,0,0,10.41-3.64A10.46,10.46,0,0,0,345.87,201.79Zm-1,4.81a2.5,2.5,0,0,1-2.87,1.28,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.55-1.51l.05,0c.63.18,1.41.29,1.75.91A2.19,2.19,0,0,1,344.89,206.6Z"/>
                <path class="cls-4" d="M387.4,206.11a5.18,5.18,0,0,0-3.11-.74.25.25,0,0,0,0,.5,4.66,4.66,0,0,1,2.86.67.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M384.29,205.12a11.81,11.81,0,0,0-8.81,3.9,9.22,9.22,0,0,0-1.72,8.77c1.11,3.34,4.55,5.45,7.81,6.33a8.69,8.69,0,0,0,8.7-2.56,9.62,9.62,0,0,0,1.37-10.87c-.13-.28-.56,0-.43.26a9.14,9.14,0,0,1-.65,9.49,8.32,8.32,0,0,1-8,3.39c-3.08-.55-6.71-2.54-8-5.5a8.66,8.66,0,0,1,.88-8.35,11.08,11.08,0,0,1,8.92-4.36c.32,0,.32-.51,0-.5Z"/>
                <path class="cls-4" d="M391.88,203.14c-1.09-.76-2.32-1.58-3.63-1.41h-.07a11.19,11.19,0,0,0-9.45,5c-2,3.16-1.72,7.66.9,10.28a11.4,11.4,0,0,0,2.35,1.73,11.93,11.93,0,0,0,5.69,1.87,8.94,8.94,0,0,0,8.53-7A10.49,10.49,0,0,0,391.88,203.14Zm.74,4.85a2.51,2.51,0,0,1-2.26,2.2,2.3,2.3,0,0,1-2.33-2c-.06-1.14.74-2.37,1.88-2.29H390a2.52,2.52,0,0,1,1.95.25A2.2,2.2,0,0,1,392.62,208Z"/>
                <path class="cls-4" d="M429.19,208.94a5.16,5.16,0,0,0-1.48,2.9.25.25,0,0,0,.24.25.25.25,0,0,0,.25-.25,4.63,4.63,0,0,1,1.34-2.54c.22-.24-.13-.59-.35-.36Z"/>
                <path class="cls-4" d="M427.47,211.71a11.73,11.73,0,0,0,1.67,9.52A9.17,9.17,0,0,0,437.4,225c3.48-.35,6.37-3.24,8-6.21a8.71,8.71,0,0,0-.59-9,9.61,9.61,0,0,0-10.09-3.8c-.32.06-.18.54.13.48a9.15,9.15,0,0,1,9,2.91,8.32,8.32,0,0,1,1.5,8.3,12.78,12.78,0,0,1-2.95,4.22,8.5,8.5,0,0,1-4.16,2.51,8.61,8.61,0,0,1-8.1-2.69,11.09,11.09,0,0,1-2.17-9.83.25.25,0,0,0-.48-.13Z"/>
                <path class="cls-4" d="M427.4,203.88c-1,.86-2.1,1.86-2.25,3.18l0,.06a11.2,11.2,0,0,0,2.51,10.37,8.58,8.58,0,0,0,10.2,1.62,11.43,11.43,0,0,0,2.25-1.85,12,12,0,0,0,3.19-5.07,8.92,8.92,0,0,0-4.71-10A10.46,10.46,0,0,0,427.4,203.88Zm4.88.46a2.49,2.49,0,0,1,1.58,2.72,2.28,2.28,0,0,1-2.54,1.76c-1.12-.21-2.12-1.29-1.77-2.37l0-.06c.1-.65.14-1.43.71-1.83A2.21,2.21,0,0,1,432.28,204.34Z"/>
                <path class="cls-4" d="M415,203a5.16,5.16,0,0,0-2.66-1.76c-.32-.07-.45.41-.14.48a4.7,4.7,0,0,1,2.45,1.63A.25.25,0,0,0,415,203Z"/>
                <path class="cls-4" d="M412.38,201a11.64,11.64,0,0,0-10.09.95,9.16,9.16,0,0,0-4.15,7.76c.08,3.46,2.76,6.68,5.57,8.47a8.69,8.69,0,0,0,8.72.29,9.57,9.57,0,0,0,4.95-9.66c0-.31-.52-.32-.49,0a9.19,9.19,0,0,1-3.65,8.59,8.38,8.38,0,0,1-8.41.85,12.13,12.13,0,0,1-3.71-3.05,9.23,9.23,0,0,1-2.38-4.44,8.54,8.54,0,0,1,3.16-7.92,11,11,0,0,1,10.34-1.36c.31.11.44-.37.14-.48Z"/>
                <path class="cls-4" d="M420.2,201.79c-.76-1.09-1.63-2.29-2.93-2.57l-.06,0a11.16,11.16,0,0,0-10.57,1.4c-2.92,2.3-4.25,6.6-2.69,10a11.27,11.27,0,0,0,1.61,2.43,12.12,12.12,0,0,0,4.7,3.71,8.91,8.91,0,0,0,10.41-3.64A10.46,10.46,0,0,0,420.2,201.79Zm-1,4.81a2.5,2.5,0,0,1-2.87,1.28,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.54-1.51l.06,0c.63.18,1.41.29,1.75.91A2.22,2.22,0,0,1,419.22,206.6Z"/>
                <path class="cls-4" d="M467.07,230.71A5.06,5.06,0,0,0,464,230a.25.25,0,0,0-.25.25.24.24,0,0,0,.25.24,4.69,4.69,0,0,1,2.85.68.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M464,229.72a11.79,11.79,0,0,0-8.81,3.9,9.23,9.23,0,0,0-1.73,8.77c1.12,3.34,4.55,5.45,7.82,6.33a8.7,8.7,0,0,0,8.7-2.56,9.63,9.63,0,0,0,1.37-10.87c-.14-.29-.57,0-.43.25a9.18,9.18,0,0,1-.65,9.5,8.3,8.3,0,0,1-8,3.38c-3.09-.55-6.71-2.53-8-5.49a8.68,8.68,0,0,1,.87-8.35,11.11,11.11,0,0,1,8.93-4.37.25.25,0,0,0,0-.49Z"/>
                <path class="cls-4" d="M471.56,227.74c-1.09-.76-2.32-1.58-3.64-1.41h-.06a11.19,11.19,0,0,0-9.45,5,8.58,8.58,0,0,0,.9,10.28,11.14,11.14,0,0,0,2.35,1.73,12,12,0,0,0,5.68,1.87,8.92,8.92,0,0,0,8.53-7A10.47,10.47,0,0,0,471.56,227.74Zm.73,4.85a2.47,2.47,0,0,1-2.25,2.19,2.27,2.27,0,0,1-2.33-2c-.07-1.14.74-2.37,1.88-2.29h.06a2.57,2.57,0,0,1,2,.24A2.25,2.25,0,0,1,472.29,232.59Z"/>
                <path class="cls-4" d="M508.87,233.54a5.24,5.24,0,0,0-1.49,2.9.25.25,0,0,0,.5,0,4.7,4.7,0,0,1,1.34-2.55c.22-.23-.13-.58-.35-.35Z"/>
                <path class="cls-4" d="M507.15,236.31a11.75,11.75,0,0,0,1.67,9.52,9.16,9.16,0,0,0,8.26,3.76c3.47-.35,6.37-3.24,7.94-6.21a8.71,8.71,0,0,0-.58-9,9.63,9.63,0,0,0-10.1-3.8c-.31.06-.18.54.14.48a9.13,9.13,0,0,1,9,2.91,8.34,8.34,0,0,1,1.5,8.3,13,13,0,0,1-3,4.22A8.43,8.43,0,0,1,517.9,249a8.61,8.61,0,0,1-8.1-2.69,11.06,11.06,0,0,1-2.17-9.83c.07-.31-.41-.45-.48-.13Z"/>
                <path class="cls-4" d="M507.07,228.47c-1,.87-2.1,1.87-2.25,3.19l0,.06a11.21,11.21,0,0,0,2.51,10.37,8.59,8.59,0,0,0,10.2,1.62,11.36,11.36,0,0,0,2.24-1.86,12.11,12.11,0,0,0,3.2-5.06,8.94,8.94,0,0,0-4.71-10A10.47,10.47,0,0,0,507.07,228.47Zm4.89.47a2.5,2.5,0,0,1,1.58,2.72,2.29,2.29,0,0,1-2.54,1.76,2,2,0,0,1-1.77-2.38l0-.06c.1-.64.14-1.43.71-1.83A2.24,2.24,0,0,1,512,228.94Z"/>
                <path class="cls-4" d="M494.63,227.62a5.14,5.14,0,0,0-2.67-1.76c-.31-.07-.44.41-.13.48a4.7,4.7,0,0,1,2.45,1.63.25.25,0,1,0,.35-.35Z"/>
                <path class="cls-4" d="M492.05,225.63a11.64,11.64,0,0,0-10.09,1,9.18,9.18,0,0,0-4.14,7.76c.08,3.46,2.75,6.68,5.56,8.47a8.69,8.69,0,0,0,8.72.29,9.6,9.6,0,0,0,5-9.66c0-.31-.53-.32-.5,0a9.18,9.18,0,0,1-3.64,8.59,8.4,8.4,0,0,1-8.41.85,12.05,12.05,0,0,1-3.72-3,9.13,9.13,0,0,1-2.37-4.44,8.53,8.53,0,0,1,3.16-7.92,10.94,10.94,0,0,1,10.34-1.36c.3.1.43-.38.13-.48Z"/>
                <path class="cls-4" d="M499.88,226.39c-.76-1.09-1.64-2.29-2.93-2.58l-.06,0a11.21,11.21,0,0,0-10.58,1.41,8.58,8.58,0,0,0-2.68,10,11.1,11.1,0,0,0,1.6,2.43,12.15,12.15,0,0,0,4.71,3.71,8.93,8.93,0,0,0,10.41-3.64A10.48,10.48,0,0,0,499.88,226.39Zm-1,4.81a2.5,2.5,0,0,1-2.87,1.28,2.3,2.3,0,0,1-1.49-2.71,2,2,0,0,1,2.55-1.51l.06,0c.63.17,1.41.29,1.74.9A2.21,2.21,0,0,1,498.9,231.2Z"/>
                <path class="cls-4" d="M541.4,230.71a5.06,5.06,0,0,0-3.1-.74.25.25,0,0,0-.25.25.24.24,0,0,0,.25.24,4.71,4.71,0,0,1,2.85.68.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M538.3,229.72a11.78,11.78,0,0,0-8.81,3.9,9.23,9.23,0,0,0-1.73,8.77c1.12,3.34,4.55,5.45,7.82,6.33a8.7,8.7,0,0,0,8.7-2.56,9.63,9.63,0,0,0,1.37-10.87c-.14-.29-.57,0-.43.25a9.18,9.18,0,0,1-.65,9.5,8.31,8.31,0,0,1-8.05,3.38c-3.08-.55-6.7-2.53-8-5.49a8.68,8.68,0,0,1,.87-8.35,11.1,11.1,0,0,1,8.93-4.37.25.25,0,0,0,0-.49Z"/>
                <path class="cls-4" d="M545.89,227.74c-1.09-.76-2.32-1.58-3.64-1.41h-.07a11.18,11.18,0,0,0-9.44,5,8.58,8.58,0,0,0,.9,10.28,11.34,11.34,0,0,0,2.34,1.73,12.11,12.11,0,0,0,5.69,1.87,8.92,8.92,0,0,0,8.53-7A10.47,10.47,0,0,0,545.89,227.74Zm.73,4.85a2.47,2.47,0,0,1-2.26,2.19,2.27,2.27,0,0,1-2.32-2c-.07-1.14.74-2.37,1.88-2.29H544a2.57,2.57,0,0,1,1.95.24A2.25,2.25,0,0,1,546.62,232.59Z"/>
                <path class="cls-4" d="M583.2,233.54a5.18,5.18,0,0,0-1.49,2.9.25.25,0,1,0,.5,0,4.7,4.7,0,0,1,1.34-2.55c.22-.23-.13-.58-.35-.35Z"/>
                <path class="cls-4" d="M581.48,236.31a11.75,11.75,0,0,0,1.67,9.52,9.16,9.16,0,0,0,8.26,3.76c3.47-.35,6.37-3.24,7.94-6.21a8.71,8.71,0,0,0-.58-9,9.63,9.63,0,0,0-10.1-3.8c-.31.06-.18.54.14.48a9.13,9.13,0,0,1,9,2.91,8.34,8.34,0,0,1,1.5,8.3,13,13,0,0,1-3,4.22,8.43,8.43,0,0,1-4.15,2.51,8.61,8.61,0,0,1-8.1-2.69,11.06,11.06,0,0,1-2.17-9.83c.07-.31-.41-.45-.48-.13Z"/>
                <path class="cls-4" d="M581.4,228.47c-1,.87-2.1,1.87-2.25,3.19l0,.06a11.21,11.21,0,0,0,2.51,10.37,8.59,8.59,0,0,0,10.2,1.62,11.36,11.36,0,0,0,2.24-1.86,12.11,12.11,0,0,0,3.2-5.06,9,9,0,0,0-4.71-10A10.47,10.47,0,0,0,581.4,228.47Zm4.89.47a2.48,2.48,0,0,1,1.57,2.72,2.27,2.27,0,0,1-2.53,1.76,2,2,0,0,1-1.77-2.38V231c.11-.64.15-1.43.72-1.83A2.24,2.24,0,0,1,586.29,228.94Z"/>
                <path class="cls-4" d="M569,227.62a5.18,5.18,0,0,0-2.67-1.76c-.31-.07-.44.41-.13.48a4.7,4.7,0,0,1,2.45,1.63.25.25,0,1,0,.35-.35Z"/>
                <path class="cls-4" d="M566.38,225.63a11.64,11.64,0,0,0-10.09,1,9.18,9.18,0,0,0-4.14,7.76c.08,3.46,2.75,6.68,5.56,8.47a8.69,8.69,0,0,0,8.72.29,9.6,9.6,0,0,0,5-9.66c0-.31-.53-.32-.5,0a9.21,9.21,0,0,1-3.64,8.59,8.4,8.4,0,0,1-8.41.85,12.05,12.05,0,0,1-3.72-3,9.23,9.23,0,0,1-2.38-4.44,8.58,8.58,0,0,1,3.16-7.92,11,11,0,0,1,10.35-1.36c.3.1.43-.38.13-.48Z"/>
                <path class="cls-4" d="M574.2,226.39c-.75-1.09-1.63-2.29-2.92-2.58l-.07,0a11.19,11.19,0,0,0-10.57,1.41,8.58,8.58,0,0,0-2.68,10,11.1,11.1,0,0,0,1.6,2.43,12.2,12.2,0,0,0,4.7,3.71,8.92,8.92,0,0,0,10.41-3.64A10.44,10.44,0,0,0,574.2,226.39Zm-1,4.81a2.5,2.5,0,0,1-2.87,1.28,2.3,2.3,0,0,1-1.49-2.71,2,2,0,0,1,2.55-1.51l.06,0c.63.17,1.41.29,1.74.9A2.24,2.24,0,0,1,573.23,231.2Z"/>
                <path class="cls-4" d="M319,230.71a5.09,5.09,0,0,0-3.11-.74.25.25,0,0,0-.25.25.25.25,0,0,0,.25.24,4.72,4.72,0,0,1,2.86.68.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M315.85,229.72a11.78,11.78,0,0,0-8.81,3.9,9.2,9.2,0,0,0-1.73,8.77c1.12,3.34,4.55,5.45,7.81,6.33a8.71,8.71,0,0,0,8.71-2.56,9.64,9.64,0,0,0,1.37-10.87c-.14-.29-.57,0-.43.25a9.18,9.18,0,0,1-.66,9.5,8.28,8.28,0,0,1-8,3.38c-3.08-.55-6.7-2.53-8-5.49a8.65,8.65,0,0,1,.87-8.35,11.1,11.1,0,0,1,8.93-4.37.25.25,0,0,0,0-.49Z"/>
                <path class="cls-4" d="M323.43,227.74c-1.08-.76-2.31-1.58-3.63-1.41h-.07a11.21,11.21,0,0,0-9.45,5,8.57,8.57,0,0,0,.91,10.28,11.08,11.08,0,0,0,2.34,1.73,12,12,0,0,0,5.69,1.87,8.93,8.93,0,0,0,8.53-7A10.47,10.47,0,0,0,323.43,227.74Zm.74,4.85a2.49,2.49,0,0,1-2.26,2.19,2.27,2.27,0,0,1-2.32-2c-.07-1.14.73-2.37,1.87-2.29h.07c.64-.06,1.42-.21,1.94.24A2.23,2.23,0,0,1,324.17,232.59Z"/>
                <path class="cls-4" d="M360.74,233.54a5.16,5.16,0,0,0-1.48,2.9c0,.13.12.25.25.25a.27.27,0,0,0,.25-.25,4.62,4.62,0,0,1,1.33-2.55c.22-.23-.13-.58-.35-.35Z"/>
                <path class="cls-4" d="M359,236.31a11.74,11.74,0,0,0,1.66,9.52,9.19,9.19,0,0,0,8.27,3.76c3.47-.35,6.37-3.24,7.94-6.21a8.69,8.69,0,0,0-.59-9,9.61,9.61,0,0,0-10.09-3.8c-.31.06-.18.54.13.48a9.15,9.15,0,0,1,9,2.91,8.34,8.34,0,0,1,1.5,8.3,13,13,0,0,1-3,4.22,8.46,8.46,0,0,1-4.16,2.51,8.63,8.63,0,0,1-8.1-2.69,11.06,11.06,0,0,1-2.16-9.83c.07-.31-.41-.45-.48-.13Z"/>
                <path class="cls-4" d="M359,228.47c-1,.87-2.1,1.87-2.25,3.19l0,.06a11.18,11.18,0,0,0,2.51,10.37,8.58,8.58,0,0,0,10.19,1.62,11.14,11.14,0,0,0,2.25-1.86,12,12,0,0,0,3.19-5.06,8.92,8.92,0,0,0-4.71-10A10.46,10.46,0,0,0,359,228.47Zm4.88.47a2.48,2.48,0,0,1,1.58,2.72,2.28,2.28,0,0,1-2.54,1.76,2,2,0,0,1-1.76-2.38V231c.11-.64.14-1.43.71-1.83A2.25,2.25,0,0,1,363.83,228.94Z"/>
                <path class="cls-4" d="M346.51,227.62a5.18,5.18,0,0,0-2.67-1.76c-.31-.07-.45.41-.13.48a4.68,4.68,0,0,1,2.44,1.63.25.25,0,1,0,.36-.35Z"/>
                <path class="cls-4" d="M343.93,225.63a11.64,11.64,0,0,0-10.09,1,9.18,9.18,0,0,0-4.14,7.76c.07,3.46,2.75,6.68,5.56,8.47a8.69,8.69,0,0,0,8.72.29,9.6,9.6,0,0,0,5-9.66c0-.31-.53-.32-.5,0A9.21,9.21,0,0,1,344.8,242a8.42,8.42,0,0,1-8.42.85,12.13,12.13,0,0,1-3.71-3,9.23,9.23,0,0,1-2.38-4.44,8.58,8.58,0,0,1,3.16-7.92,11,11,0,0,1,10.35-1.36c.3.1.43-.38.13-.48Z"/>
                <path class="cls-4" d="M351.75,226.39c-.76-1.09-1.63-2.29-2.93-2.58l-.06,0a11.19,11.19,0,0,0-10.57,1.41c-2.92,2.3-4.25,6.6-2.69,10a11.52,11.52,0,0,0,1.61,2.43,12.12,12.12,0,0,0,4.7,3.71,8.93,8.93,0,0,0,10.41-3.64A10.46,10.46,0,0,0,351.75,226.39Zm-1,4.81a2.51,2.51,0,0,1-2.88,1.28,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.55-1.51l.06,0c.62.17,1.4.29,1.74.9A2.24,2.24,0,0,1,350.78,231.2Z"/>
                <path class="cls-4" d="M393.28,230.71a5.09,5.09,0,0,0-3.11-.74.25.25,0,0,0-.25.25.25.25,0,0,0,.25.24,4.74,4.74,0,0,1,2.86.68.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M390.18,229.72a11.78,11.78,0,0,0-8.81,3.9,9.2,9.2,0,0,0-1.73,8.77c1.12,3.34,4.55,5.45,7.81,6.33a8.69,8.69,0,0,0,8.7-2.56,9.61,9.61,0,0,0,1.37-10.87c-.13-.29-.56,0-.42.25a9.18,9.18,0,0,1-.66,9.5,8.28,8.28,0,0,1-8,3.38c-3.08-.55-6.71-2.53-8-5.49a8.65,8.65,0,0,1,.87-8.35,11.1,11.1,0,0,1,8.93-4.37.25.25,0,0,0,0-.49Z"/>
                <path class="cls-4" d="M397.76,227.74c-1.08-.76-2.32-1.58-3.63-1.41h-.07a11.2,11.2,0,0,0-9.45,5,8.57,8.57,0,0,0,.91,10.28,11.08,11.08,0,0,0,2.34,1.73,12,12,0,0,0,5.69,1.87,8.94,8.94,0,0,0,8.53-7A10.47,10.47,0,0,0,397.76,227.74Zm.74,4.85a2.49,2.49,0,0,1-2.26,2.19,2.26,2.26,0,0,1-2.32-2c-.07-1.14.73-2.37,1.87-2.29h.07c.64-.06,1.42-.21,1.94.24A2.23,2.23,0,0,1,398.5,232.59Z"/>
                <path class="cls-4" d="M435.07,233.54a5.16,5.16,0,0,0-1.48,2.9c0,.13.12.25.25.25a.25.25,0,0,0,.24-.25,4.7,4.7,0,0,1,1.34-2.55c.22-.23-.13-.58-.35-.35Z"/>
                <path class="cls-4" d="M433.35,236.31a11.79,11.79,0,0,0,1.67,9.52,9.19,9.19,0,0,0,8.27,3.76c3.47-.35,6.37-3.24,7.94-6.21a8.69,8.69,0,0,0-.59-9,9.61,9.61,0,0,0-10.09-3.8c-.31.06-.18.54.13.48a9.15,9.15,0,0,1,9,2.91,8.34,8.34,0,0,1,1.5,8.3,13,13,0,0,1-3,4.22A8.46,8.46,0,0,1,444.1,249a8.63,8.63,0,0,1-8.1-2.69,11.09,11.09,0,0,1-2.17-9.83.25.25,0,0,0-.48-.13Z"/>
                <path class="cls-4" d="M433.28,228.47c-1,.87-2.1,1.87-2.25,3.19l0,.06a11.18,11.18,0,0,0,2.52,10.37,8.58,8.58,0,0,0,10.19,1.62,11.14,11.14,0,0,0,2.25-1.86,12,12,0,0,0,3.19-5.06,8.92,8.92,0,0,0-4.71-10A10.45,10.45,0,0,0,433.28,228.47Zm4.88.47a2.49,2.49,0,0,1,1.58,2.72,2.28,2.28,0,0,1-2.54,1.76,2,2,0,0,1-1.76-2.38V231c.1-.64.14-1.43.71-1.83A2.24,2.24,0,0,1,438.16,228.94Z"/>
                <path class="cls-4" d="M420.83,227.62a5.12,5.12,0,0,0-2.66-1.76c-.31-.07-.45.41-.13.48a4.68,4.68,0,0,1,2.44,1.63.25.25,0,0,0,.35,0,.24.24,0,0,0,0-.35Z"/>
                <path class="cls-4" d="M418.26,225.63a11.64,11.64,0,0,0-10.09,1,9.18,9.18,0,0,0-4.14,7.76c.07,3.46,2.75,6.68,5.56,8.47a8.69,8.69,0,0,0,8.72.29,9.6,9.6,0,0,0,5-9.66c0-.31-.53-.32-.5,0a9.21,9.21,0,0,1-3.64,8.59,8.42,8.42,0,0,1-8.42.85,12.13,12.13,0,0,1-3.71-3,9.23,9.23,0,0,1-2.38-4.44,8.58,8.58,0,0,1,3.16-7.92,11,11,0,0,1,10.35-1.36c.3.1.43-.38.13-.48Z"/>
                <path class="cls-4" d="M426.08,226.39c-.76-1.09-1.63-2.29-2.93-2.58l-.06,0a11.19,11.19,0,0,0-10.57,1.41c-2.92,2.3-4.25,6.6-2.69,10a11.52,11.52,0,0,0,1.61,2.43,12.12,12.12,0,0,0,4.7,3.71,8.93,8.93,0,0,0,10.41-3.64A10.46,10.46,0,0,0,426.08,226.39Zm-1,4.81a2.51,2.51,0,0,1-2.88,1.28,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.55-1.51l.06,0c.62.17,1.4.29,1.74.9A2.24,2.24,0,0,1,425.11,231.2Z"/>
                <path class="cls-4" d="M162.21,256.53a5.14,5.14,0,0,0-3.1-.75.26.26,0,0,0-.25.25c0,.12.11.27.25.25A4.62,4.62,0,0,1,162,257c.28.15.53-.28.25-.43Z"/>
                <path class="cls-4" d="M159.11,255.53a11.77,11.77,0,0,0-8.81,3.91,9.21,9.21,0,0,0-1.73,8.76c1.12,3.34,4.55,5.46,7.82,6.34a8.72,8.72,0,0,0,8.7-2.57,9.61,9.61,0,0,0,1.37-10.86c-.14-.29-.57,0-.43.25a9.15,9.15,0,0,1-.65,9.5,8.32,8.32,0,0,1-8,3.38c-3.08-.55-6.71-2.54-8-5.5a8.66,8.66,0,0,1,.87-8.34,11.13,11.13,0,0,1,8.93-4.37.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M166.7,253.56c-1.09-.76-2.32-1.59-3.63-1.41H163a11.18,11.18,0,0,0-9.45,4.95,8.6,8.6,0,0,0,.9,10.29,11.4,11.4,0,0,0,2.35,1.73,12,12,0,0,0,5.69,1.87,8.93,8.93,0,0,0,8.52-7A10.47,10.47,0,0,0,166.7,253.56Zm.73,4.85a2.47,2.47,0,0,1-2.25,2.19,2.29,2.29,0,0,1-2.33-2,2,2,0,0,1,1.88-2.28h.06c.65,0,1.42-.21,2,.25A2.22,2.22,0,0,1,167.43,258.41Z"/>
                <path class="cls-4" d="M204,259.36a5.25,5.25,0,0,0-1.49,2.89.25.25,0,0,0,.5,0,4.67,4.67,0,0,1,1.34-2.54c.22-.23-.13-.58-.35-.35Z"/>
                <path class="cls-4" d="M202.29,262.12a11.76,11.76,0,0,0,1.67,9.53,9.2,9.2,0,0,0,8.26,3.76c3.47-.36,6.37-3.25,7.94-6.21a8.73,8.73,0,0,0-.58-9.05,9.64,9.64,0,0,0-10.09-3.8c-.32.07-.19.55.13.48a9.15,9.15,0,0,1,9,2.91,8.36,8.36,0,0,1,1.5,8.31,12.66,12.66,0,0,1-3,4.21,8.38,8.38,0,0,1-4.15,2.52,8.64,8.64,0,0,1-8.1-2.7,11,11,0,0,1-2.17-9.82c.07-.32-.41-.45-.48-.14Z"/>
                <path class="cls-4" d="M202.21,254.29c-1,.87-2.1,1.86-2.25,3.18l0,.07a11.19,11.19,0,0,0,2.51,10.36,8.6,8.6,0,0,0,10.2,1.63,11.36,11.36,0,0,0,2.24-1.86,12.11,12.11,0,0,0,3.2-5.06,8.91,8.91,0,0,0-4.71-10A10.47,10.47,0,0,0,202.21,254.29Zm4.89.46a2.52,2.52,0,0,1,1.58,2.73,2.29,2.29,0,0,1-2.54,1.76,2,2,0,0,1-1.77-2.38l0-.06c.1-.64.14-1.43.71-1.83A2.24,2.24,0,0,1,207.1,254.75Z"/>
                <path class="cls-4" d="M189.77,253.43a5.13,5.13,0,0,0-2.67-1.75c-.31-.07-.44.41-.13.48a4.7,4.7,0,0,1,2.45,1.63.25.25,0,0,0,.35,0,.26.26,0,0,0,0-.36Z"/>
                <path class="cls-4" d="M187.19,251.45a11.61,11.61,0,0,0-10.08.94,9.18,9.18,0,0,0-4.15,7.77c.08,3.46,2.75,6.67,5.56,8.47a8.69,8.69,0,0,0,8.72.28,9.58,9.58,0,0,0,5-9.65c0-.32-.52-.32-.49,0a9.19,9.19,0,0,1-3.65,8.58,8.38,8.38,0,0,1-8.41.85,12.14,12.14,0,0,1-3.72-3,9.21,9.21,0,0,1-2.37-4.44,8.55,8.55,0,0,1,3.16-7.93,11,11,0,0,1,10.34-1.35c.3.1.43-.38.13-.48Z"/>
                <path class="cls-4" d="M195,252.2c-.76-1.08-1.64-2.28-2.93-2.57l-.06,0A11.21,11.21,0,0,0,181.45,251a8.58,8.58,0,0,0-2.68,10,11.15,11.15,0,0,0,1.61,2.43,12.05,12.05,0,0,0,4.7,3.71,8.94,8.94,0,0,0,10.41-3.64A10.48,10.48,0,0,0,195,252.2ZM194,257a2.49,2.49,0,0,1-2.87,1.28,2.29,2.29,0,0,1-1.49-2.71,2,2,0,0,1,2.55-1.5l.06,0c.63.17,1.41.29,1.75.9A2.22,2.22,0,0,1,194,257Z"/>
                <path class="cls-4" d="M236.54,256.53a5.14,5.14,0,0,0-3.1-.75.26.26,0,0,0-.25.25c0,.12.11.27.25.25a4.62,4.62,0,0,1,2.85.68c.28.15.53-.28.25-.43Z"/>
                <path class="cls-4" d="M233.44,255.53a11.77,11.77,0,0,0-8.81,3.91,9.21,9.21,0,0,0-1.73,8.76c1.12,3.34,4.55,5.46,7.82,6.34a8.72,8.72,0,0,0,8.7-2.57,9.61,9.61,0,0,0,1.37-10.86c-.14-.29-.57,0-.43.25a9.18,9.18,0,0,1-.65,9.5,8.32,8.32,0,0,1-8,3.38c-3.08-.55-6.71-2.54-8-5.5a8.66,8.66,0,0,1,.87-8.34,11.11,11.11,0,0,1,8.93-4.37.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M241,253.56c-1.09-.76-2.32-1.59-3.64-1.41h-.06a11.18,11.18,0,0,0-9.45,4.95,8.6,8.6,0,0,0,.9,10.29,11.14,11.14,0,0,0,2.35,1.73,12,12,0,0,0,5.68,1.87,8.93,8.93,0,0,0,8.53-7A10.47,10.47,0,0,0,241,253.56Zm.73,4.85a2.47,2.47,0,0,1-2.25,2.19,2.28,2.28,0,0,1-2.33-2,2,2,0,0,1,1.88-2.28h.06c.65,0,1.42-.21,1.95.25A2.25,2.25,0,0,1,241.76,258.41Z"/>
                <path class="cls-4" d="M278.34,259.36a5.25,5.25,0,0,0-1.49,2.89.25.25,0,0,0,.5,0,4.67,4.67,0,0,1,1.34-2.54c.22-.23-.13-.58-.35-.35Z"/>
                <path class="cls-4" d="M276.62,262.12a11.76,11.76,0,0,0,1.67,9.53,9.2,9.2,0,0,0,8.26,3.76c3.47-.36,6.37-3.25,7.94-6.21a8.73,8.73,0,0,0-.58-9.05,9.65,9.65,0,0,0-10.1-3.8c-.31.07-.18.55.14.48a9.15,9.15,0,0,1,9,2.91,8.36,8.36,0,0,1,1.5,8.31,12.81,12.81,0,0,1-3,4.21,8.38,8.38,0,0,1-4.15,2.52,8.63,8.63,0,0,1-8.1-2.7,11,11,0,0,1-2.17-9.82c.07-.32-.41-.45-.48-.14Z"/>
                <path class="cls-4" d="M276.54,254.29c-1,.87-2.1,1.86-2.25,3.18l0,.07a11.19,11.19,0,0,0,2.51,10.36,8.6,8.6,0,0,0,10.2,1.63,11.36,11.36,0,0,0,2.24-1.86,12.11,12.11,0,0,0,3.2-5.06,8.91,8.91,0,0,0-4.71-10A10.47,10.47,0,0,0,276.54,254.29Zm4.89.46a2.52,2.52,0,0,1,1.58,2.73,2.29,2.29,0,0,1-2.54,1.76,2,2,0,0,1-1.77-2.38l0-.06c.1-.64.14-1.43.71-1.83A2.24,2.24,0,0,1,281.43,254.75Z"/>
                <path class="cls-4" d="M264.1,253.43a5.13,5.13,0,0,0-2.67-1.75c-.31-.07-.44.41-.13.48a4.7,4.7,0,0,1,2.45,1.63.25.25,0,0,0,.35,0,.26.26,0,0,0,0-.36Z"/>
                <path class="cls-4" d="M261.52,251.45a11.64,11.64,0,0,0-10.09.94,9.19,9.19,0,0,0-4.14,7.77c.08,3.46,2.75,6.67,5.56,8.47a8.69,8.69,0,0,0,8.72.28,9.58,9.58,0,0,0,5-9.65c0-.32-.53-.32-.5,0a9.16,9.16,0,0,1-3.64,8.58,8.38,8.38,0,0,1-8.41.85,12,12,0,0,1-3.72-3,9.21,9.21,0,0,1-2.37-4.44,8.55,8.55,0,0,1,3.16-7.93,11,11,0,0,1,10.34-1.35c.3.1.43-.38.13-.48Z"/>
                <path class="cls-4" d="M269.35,252.2c-.76-1.08-1.64-2.28-2.93-2.57l-.06,0A11.21,11.21,0,0,0,255.78,251a8.58,8.58,0,0,0-2.68,10,11.48,11.48,0,0,0,1.6,2.43,12.15,12.15,0,0,0,4.71,3.71,8.94,8.94,0,0,0,10.41-3.64A10.5,10.5,0,0,0,269.35,252.2Zm-1,4.81a2.49,2.49,0,0,1-2.87,1.28,2.29,2.29,0,0,1-1.49-2.71,2,2,0,0,1,2.55-1.5l.06,0c.63.17,1.41.29,1.74.9A2.19,2.19,0,0,1,268.37,257Z"/>
                <path class="cls-4" d="M14.09,256.53a5.17,5.17,0,0,0-3.11-.75.26.26,0,0,0-.24.25c0,.12.11.27.24.25a4.65,4.65,0,0,1,2.86.68c.28.15.53-.28.25-.43Z"/>
                <path class="cls-4" d="M11,255.53a11.75,11.75,0,0,0-8.81,3.91A9.19,9.19,0,0,0,.45,268.2c1.12,3.34,4.55,5.46,7.82,6.34A8.72,8.72,0,0,0,17,272a9.61,9.61,0,0,0,1.37-10.86c-.14-.29-.57,0-.43.25a9.2,9.2,0,0,1-.65,9.5,8.33,8.33,0,0,1-8,3.38c-3.08-.55-6.7-2.54-8-5.5a8.64,8.64,0,0,1,.87-8.34A11.1,11.1,0,0,1,11,256a.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M18.57,253.56c-1.08-.76-2.31-1.59-3.63-1.41h-.07a11.21,11.21,0,0,0-9.45,4.95,8.6,8.6,0,0,0,.91,10.29,11.34,11.34,0,0,0,2.34,1.73A12.08,12.08,0,0,0,14.36,271a8.94,8.94,0,0,0,8.53-7A10.47,10.47,0,0,0,18.57,253.56Zm.74,4.85a2.49,2.49,0,0,1-2.26,2.19,2.28,2.28,0,0,1-2.32-2c-.07-1.14.73-2.36,1.87-2.28h.07c.64,0,1.42-.21,1.94.25A2.23,2.23,0,0,1,19.31,258.41Z"/>
                <path class="cls-4" d="M55.88,259.36a5.17,5.17,0,0,0-1.48,2.89c0,.14.13.25.25.25a.25.25,0,0,0,.25-.25,4.54,4.54,0,0,1,1.34-2.54c.21-.23-.14-.58-.36-.35Z"/>
                <path class="cls-4" d="M54.17,262.12a11.8,11.8,0,0,0,1.66,9.53,9.23,9.23,0,0,0,8.27,3.76c3.47-.36,6.37-3.25,7.94-6.21a8.71,8.71,0,0,0-.59-9.05,9.62,9.62,0,0,0-10.09-3.8c-.31.07-.18.55.13.48a9.17,9.17,0,0,1,9,2.91,8.36,8.36,0,0,1,1.5,8.31,12.81,12.81,0,0,1-3,4.21,8.41,8.41,0,0,1-4.16,2.52,8.61,8.61,0,0,1-8.09-2.7,11,11,0,0,1-2.17-9.82c.07-.32-.41-.45-.48-.14Z"/>
                <path class="cls-4" d="M54.09,254.29c-1,.87-2.1,1.86-2.25,3.18l0,.07a11.19,11.19,0,0,0,2.51,10.36,8.6,8.6,0,0,0,10.2,1.63,11.36,11.36,0,0,0,2.24-1.86,12.11,12.11,0,0,0,3.2-5.06,8.93,8.93,0,0,0-4.72-10A10.46,10.46,0,0,0,54.09,254.29Zm4.89.46a2.5,2.5,0,0,1,1.57,2.73A2.28,2.28,0,0,1,58,259.24a2,2,0,0,1-1.76-2.38v-.06c.11-.64.14-1.43.71-1.83A2.27,2.27,0,0,1,59,254.75Z"/>
                <path class="cls-4" d="M41.65,253.43A5.17,5.17,0,0,0,39,251.68c-.31-.07-.44.41-.13.48a4.68,4.68,0,0,1,2.44,1.63.26.26,0,0,0,.36,0,.27.27,0,0,0,0-.36Z"/>
                <path class="cls-4" d="M39.07,251.45a11.64,11.64,0,0,0-10.09.94,9.19,9.19,0,0,0-4.14,7.77c.08,3.46,2.75,6.67,5.56,8.47a8.69,8.69,0,0,0,8.72.28,9.58,9.58,0,0,0,5-9.65c0-.32-.53-.32-.5,0a9.18,9.18,0,0,1-3.64,8.58,8.39,8.39,0,0,1-8.42.85,12.1,12.1,0,0,1-3.71-3,9.32,9.32,0,0,1-2.38-4.44,8.6,8.6,0,0,1,3.16-7.93,11,11,0,0,1,10.35-1.35c.3.1.43-.38.13-.48Z"/>
                <path class="cls-4" d="M46.89,252.2c-.76-1.08-1.63-2.28-2.92-2.57l-.07,0A11.19,11.19,0,0,0,33.33,251a8.58,8.58,0,0,0-2.69,10,11.52,11.52,0,0,0,1.61,2.43,12.12,12.12,0,0,0,4.7,3.71,8.94,8.94,0,0,0,10.41-3.64A10.46,10.46,0,0,0,46.89,252.2Zm-1,4.81A2.49,2.49,0,0,1,43,258.29a2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.55-1.5l.06,0c.62.17,1.4.29,1.74.9A2.22,2.22,0,0,1,45.92,257Z"/>
                <path class="cls-4" d="M88.42,256.53a5.17,5.17,0,0,0-3.11-.75.27.27,0,0,0-.25.25c0,.12.12.27.25.25a4.65,4.65,0,0,1,2.86.68c.28.15.53-.28.25-.43Z"/>
                <path class="cls-4" d="M85.32,255.53a11.75,11.75,0,0,0-8.81,3.91,9.19,9.19,0,0,0-1.73,8.76c1.12,3.34,4.55,5.46,7.81,6.34A8.73,8.73,0,0,0,91.3,272a9.62,9.62,0,0,0,1.37-10.86c-.14-.29-.57,0-.43.25a9.18,9.18,0,0,1-.66,9.5,8.3,8.3,0,0,1-8,3.38c-3.08-.55-6.7-2.54-8-5.5a8.64,8.64,0,0,1,.87-8.34A11.1,11.1,0,0,1,85.32,256a.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M92.9,253.56c-1.08-.76-2.31-1.59-3.63-1.41H89.2a11.21,11.21,0,0,0-9.45,4.95,8.58,8.58,0,0,0,.91,10.29A11.08,11.08,0,0,0,83,269.11,12,12,0,0,0,88.69,271a8.94,8.94,0,0,0,8.53-7A10.47,10.47,0,0,0,92.9,253.56Zm.74,4.85a2.49,2.49,0,0,1-2.26,2.19,2.28,2.28,0,0,1-2.32-2c-.07-1.14.73-2.36,1.87-2.28H91c.64,0,1.42-.21,1.94.25A2.23,2.23,0,0,1,93.64,258.41Z"/>
                <path class="cls-4" d="M130.21,259.36a5.17,5.17,0,0,0-1.48,2.89.24.24,0,0,0,.25.25.26.26,0,0,0,.25-.25,4.59,4.59,0,0,1,1.33-2.54c.22-.23-.13-.58-.35-.35Z"/>
                <path class="cls-4" d="M128.5,262.12a11.76,11.76,0,0,0,1.66,9.53,9.23,9.23,0,0,0,8.27,3.76c3.47-.36,6.37-3.25,7.94-6.21a8.71,8.71,0,0,0-.59-9.05,9.62,9.62,0,0,0-10.09-3.8c-.31.07-.18.55.13.48a9.17,9.17,0,0,1,9,2.91,8.36,8.36,0,0,1,1.5,8.31,12.81,12.81,0,0,1-3,4.21,8.41,8.41,0,0,1-4.16,2.52,8.61,8.61,0,0,1-8.09-2.7,11,11,0,0,1-2.17-9.82c.07-.32-.41-.45-.48-.14Z"/>
                <path class="cls-4" d="M128.42,254.29c-1,.87-2.1,1.86-2.25,3.18l0,.07a11.16,11.16,0,0,0,2.51,10.36c2.59,2.66,7,3.53,10.19,1.63a11.14,11.14,0,0,0,2.25-1.86,12,12,0,0,0,3.19-5.06,8.91,8.91,0,0,0-4.71-10A10.46,10.46,0,0,0,128.42,254.29Zm4.88.46a2.49,2.49,0,0,1,1.58,2.73,2.28,2.28,0,0,1-2.54,1.76,2,2,0,0,1-1.76-2.38v-.06c.11-.64.14-1.43.71-1.83A2.25,2.25,0,0,1,133.3,254.75Z"/>
                <path class="cls-4" d="M116,253.43a5.17,5.17,0,0,0-2.67-1.75c-.31-.07-.45.41-.13.48a4.68,4.68,0,0,1,2.44,1.63.26.26,0,0,0,.36,0,.27.27,0,0,0,0-.36Z"/>
                <path class="cls-4" d="M113.4,251.45a11.64,11.64,0,0,0-10.09.94,9.19,9.19,0,0,0-4.14,7.77c.08,3.46,2.75,6.67,5.56,8.47a8.69,8.69,0,0,0,8.72.28,9.58,9.58,0,0,0,5-9.65c0-.32-.53-.32-.5,0a9.18,9.18,0,0,1-3.64,8.58,8.39,8.39,0,0,1-8.42.85,12.1,12.1,0,0,1-3.71-3,9.32,9.32,0,0,1-2.38-4.44,8.6,8.6,0,0,1,3.16-7.93,11,11,0,0,1,10.35-1.35c.3.1.43-.38.13-.48Z"/>
                <path class="cls-4" d="M121.22,252.2c-.76-1.08-1.63-2.28-2.93-2.57l-.06,0A11.19,11.19,0,0,0,107.66,251,8.58,8.58,0,0,0,105,261a11.52,11.52,0,0,0,1.61,2.43,12.12,12.12,0,0,0,4.7,3.71,8.94,8.94,0,0,0,10.41-3.64A10.48,10.48,0,0,0,121.22,252.2Zm-1,4.81a2.5,2.5,0,0,1-2.88,1.28,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.55-1.5l.06,0c.62.17,1.4.29,1.74.9A2.22,2.22,0,0,1,120.25,257Z"/>
                <path class="cls-4" d="M168.1,281.13a5.18,5.18,0,0,0-3.11-.75.26.26,0,0,0-.25.25c0,.12.12.27.25.25a4.73,4.73,0,0,1,2.86.67c.28.16.53-.27.25-.42Z"/>
                <path class="cls-4" d="M165,280.13a11.77,11.77,0,0,0-8.81,3.91,9.21,9.21,0,0,0-1.72,8.76c1.11,3.34,4.55,5.46,7.81,6.33a8.69,8.69,0,0,0,8.7-2.56,9.61,9.61,0,0,0,1.37-10.86c-.14-.29-.56,0-.43.25a9.14,9.14,0,0,1-.65,9.49,8.32,8.32,0,0,1-8,3.39c-3.08-.55-6.71-2.54-8-5.5a8.66,8.66,0,0,1,.88-8.35,11.08,11.08,0,0,1,8.92-4.36.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M172.58,278.16c-1.09-.76-2.32-1.59-3.63-1.41h-.07a11.19,11.19,0,0,0-9.45,4.95,8.6,8.6,0,0,0,.9,10.29,11.4,11.4,0,0,0,2.35,1.73,12,12,0,0,0,5.69,1.86,8.93,8.93,0,0,0,8.53-7A10.48,10.48,0,0,0,172.58,278.16Zm.74,4.85a2.51,2.51,0,0,1-2.26,2.19,2.3,2.3,0,0,1-2.33-2c-.06-1.14.74-2.37,1.88-2.29h.06c.65-.05,1.42-.21,2,.25A2.22,2.22,0,0,1,173.32,283Z"/>
                <path class="cls-4" d="M209.89,284a5.14,5.14,0,0,0-1.48,2.89.24.24,0,0,0,.24.25.25.25,0,0,0,.25-.25,4.63,4.63,0,0,1,1.34-2.54c.22-.23-.13-.59-.35-.35Z"/>
                <path class="cls-4" d="M208.17,286.72a11.76,11.76,0,0,0,1.67,9.53A9.21,9.21,0,0,0,218.1,300c3.48-.36,6.37-3.25,8-6.22a8.71,8.71,0,0,0-.59-9,9.61,9.61,0,0,0-10.09-3.8c-.32.07-.18.54.13.48a9.15,9.15,0,0,1,9,2.91,8.34,8.34,0,0,1,1.5,8.31,12.74,12.74,0,0,1-2.95,4.21,8.52,8.52,0,0,1-4.16,2.52,8.64,8.64,0,0,1-8.1-2.7,11.08,11.08,0,0,1-2.17-9.83.25.25,0,0,0-.48-.13Z"/>
                <path class="cls-4" d="M208.1,278.89c-1,.87-2.1,1.86-2.25,3.18l0,.07a11.19,11.19,0,0,0,2.51,10.36,8.58,8.58,0,0,0,10.2,1.62,11.09,11.09,0,0,0,2.25-1.85A12,12,0,0,0,224,287.2a8.92,8.92,0,0,0-4.71-10A10.48,10.48,0,0,0,208.1,278.89Zm4.88.46a2.5,2.5,0,0,1,1.58,2.72,2.28,2.28,0,0,1-2.54,1.76,2,2,0,0,1-1.77-2.37l0-.06c.1-.64.14-1.43.71-1.83A2.21,2.21,0,0,1,213,279.35Z"/>
                <path class="cls-4" d="M195.65,278a5.14,5.14,0,0,0-2.66-1.75c-.32-.07-.45.41-.14.48a4.69,4.69,0,0,1,2.45,1.62.24.24,0,0,0,.35,0,.25.25,0,0,0,0-.35Z"/>
                <path class="cls-4" d="M193.08,276A11.67,11.67,0,0,0,183,277a9.16,9.16,0,0,0-4.15,7.76c.08,3.47,2.76,6.68,5.57,8.48a8.71,8.71,0,0,0,8.72.28,9.57,9.57,0,0,0,5-9.65c0-.32-.52-.32-.49,0a9.17,9.17,0,0,1-3.65,8.58,8.38,8.38,0,0,1-8.41.85,12.14,12.14,0,0,1-3.72-3.05,9.29,9.29,0,0,1-2.37-4.44,8.54,8.54,0,0,1,3.16-7.92,11,11,0,0,1,10.34-1.36c.31.11.44-.37.14-.48Z"/>
                <path class="cls-4" d="M200.9,276.8c-.76-1.09-1.63-2.28-2.93-2.57l-.06,0a11.21,11.21,0,0,0-10.58,1.4c-2.91,2.3-4.24,6.61-2.68,10a11.45,11.45,0,0,0,1.61,2.42,11.94,11.94,0,0,0,4.7,3.71,8.92,8.92,0,0,0,10.41-3.63A10.48,10.48,0,0,0,200.9,276.8Zm-1,4.81a2.49,2.49,0,0,1-2.87,1.28,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.54-1.5l.06,0c.63.17,1.41.29,1.75.9A2.22,2.22,0,0,1,199.92,281.61Z"/>
                <path class="cls-4" d="M242.43,281.13a5.18,5.18,0,0,0-3.11-.75.26.26,0,0,0-.25.25c0,.12.11.27.25.25a4.68,4.68,0,0,1,2.85.67c.28.16.54-.27.26-.42Z"/>
                <path class="cls-4" d="M239.32,280.13a11.77,11.77,0,0,0-8.81,3.91,9.24,9.24,0,0,0-1.73,8.76c1.12,3.34,4.56,5.46,7.82,6.33a8.69,8.69,0,0,0,8.7-2.56,9.61,9.61,0,0,0,1.37-10.86c-.14-.29-.56,0-.43.25a9.14,9.14,0,0,1-.65,9.49,8.33,8.33,0,0,1-8,3.39c-3.08-.55-6.71-2.54-8-5.5a8.66,8.66,0,0,1,.88-8.35,11.08,11.08,0,0,1,8.92-4.36.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M246.91,278.16c-1.09-.76-2.32-1.59-3.63-1.41h-.07a11.18,11.18,0,0,0-9.45,4.95,8.6,8.6,0,0,0,.9,10.29,11.4,11.4,0,0,0,2.35,1.73,12,12,0,0,0,5.69,1.86,8.91,8.91,0,0,0,8.52-7A10.46,10.46,0,0,0,246.91,278.16Zm.74,4.85a2.51,2.51,0,0,1-2.26,2.19,2.3,2.3,0,0,1-2.33-2c-.06-1.14.74-2.37,1.88-2.29H245c.65-.05,1.42-.21,1.95.25A2.25,2.25,0,0,1,247.65,283Z"/>
                <path class="cls-4" d="M284.22,284a5.21,5.21,0,0,0-1.49,2.89.25.25,0,0,0,.25.25.25.25,0,0,0,.25-.25,4.63,4.63,0,0,1,1.34-2.54c.22-.23-.13-.59-.35-.35Z"/>
                <path class="cls-4" d="M282.5,286.72a11.76,11.76,0,0,0,1.67,9.53,9.2,9.2,0,0,0,8.26,3.76c3.48-.36,6.37-3.25,7.94-6.22a8.69,8.69,0,0,0-.58-9A9.61,9.61,0,0,0,289.7,281c-.32.07-.18.54.13.48a9.15,9.15,0,0,1,9,2.91,8.34,8.34,0,0,1,1.5,8.31,12.88,12.88,0,0,1-2.95,4.21,8.52,8.52,0,0,1-4.16,2.52,8.64,8.64,0,0,1-8.1-2.7,11.08,11.08,0,0,1-2.17-9.83c.07-.31-.41-.44-.48-.13Z"/>
                <path class="cls-4" d="M282.43,278.89c-1,.87-2.11,1.86-2.25,3.18l0,.07a11.19,11.19,0,0,0,2.51,10.36,8.58,8.58,0,0,0,10.2,1.62,11.09,11.09,0,0,0,2.25-1.85,12,12,0,0,0,3.19-5.07,8.92,8.92,0,0,0-4.71-10A10.48,10.48,0,0,0,282.43,278.89Zm4.88.46a2.5,2.5,0,0,1,1.58,2.72,2.28,2.28,0,0,1-2.54,1.76,2,2,0,0,1-1.77-2.37l0-.06c.1-.64.14-1.43.71-1.83A2.21,2.21,0,0,1,287.31,279.35Z"/>
                <path class="cls-4" d="M270,278a5.14,5.14,0,0,0-2.66-1.75c-.32-.07-.45.41-.14.48a4.69,4.69,0,0,1,2.45,1.62.24.24,0,0,0,.35,0,.25.25,0,0,0,0-.35Z"/>
                <path class="cls-4" d="M267.41,276a11.67,11.67,0,0,0-10.09.95,9.16,9.16,0,0,0-4.15,7.76c.08,3.47,2.75,6.68,5.57,8.48a8.71,8.71,0,0,0,8.72.28,9.58,9.58,0,0,0,5-9.65c0-.32-.52-.32-.49,0a9.19,9.19,0,0,1-3.65,8.58,8.38,8.38,0,0,1-8.41.85,12.14,12.14,0,0,1-3.72-3.05,9.29,9.29,0,0,1-2.37-4.44,8.54,8.54,0,0,1,3.16-7.92,11,11,0,0,1,10.34-1.36c.31.11.44-.37.14-.48Z"/>
                <path class="cls-4" d="M275.23,276.8c-.76-1.09-1.63-2.28-2.93-2.57l-.06,0a11.21,11.21,0,0,0-10.58,1.4c-2.91,2.3-4.24,6.61-2.68,10a11.45,11.45,0,0,0,1.61,2.42,11.87,11.87,0,0,0,4.7,3.71,8.92,8.92,0,0,0,10.41-3.63A10.48,10.48,0,0,0,275.23,276.8Zm-1,4.81a2.49,2.49,0,0,1-2.87,1.28,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.54-1.5l.06,0c.63.17,1.41.29,1.75.9A2.22,2.22,0,0,1,274.25,281.61Z"/>
                <path class="cls-4" d="M20,281.13a5.14,5.14,0,0,0-3.1-.75.25.25,0,0,0-.25.25c0,.12.11.27.25.25a4.68,4.68,0,0,1,2.85.67c.28.16.53-.27.25-.42Z"/>
                <path class="cls-4" d="M16.87,280.13A11.77,11.77,0,0,0,8.06,284a9.21,9.21,0,0,0-1.73,8.76c1.12,3.34,4.55,5.46,7.82,6.33a8.7,8.7,0,0,0,8.7-2.56,9.61,9.61,0,0,0,1.37-10.86c-.14-.29-.57,0-.43.25a9.16,9.16,0,0,1-.65,9.49,8.33,8.33,0,0,1-8,3.39c-3.09-.55-6.71-2.54-8-5.5A8.69,8.69,0,0,1,7.94,285a11.1,11.1,0,0,1,8.93-4.36.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M24.46,278.16c-1.09-.76-2.32-1.59-3.64-1.41h-.06a11.18,11.18,0,0,0-9.45,4.95,8.6,8.6,0,0,0,.9,10.29,11.34,11.34,0,0,0,2.34,1.73,12.1,12.1,0,0,0,5.69,1.86,8.91,8.91,0,0,0,8.53-7A10.46,10.46,0,0,0,24.46,278.16Zm.73,4.85a2.49,2.49,0,0,1-2.25,2.19,2.29,2.29,0,0,1-2.33-2c-.07-1.14.74-2.37,1.88-2.29h.06c.65-.05,1.42-.21,1.95.25A2.24,2.24,0,0,1,25.19,283Z"/>
                <path class="cls-4" d="M61.77,284a5.21,5.21,0,0,0-1.49,2.89.25.25,0,0,0,.5,0,4.63,4.63,0,0,1,1.34-2.54c.22-.23-.13-.59-.35-.35Z"/>
                <path class="cls-4" d="M60.05,286.72a11.76,11.76,0,0,0,1.67,9.53A9.2,9.2,0,0,0,70,300c3.47-.36,6.37-3.25,7.94-6.22a8.71,8.71,0,0,0-.58-9,9.63,9.63,0,0,0-10.1-3.8c-.31.07-.18.54.14.48a9.15,9.15,0,0,1,9,2.91,8.36,8.36,0,0,1,1.5,8.31,12.91,12.91,0,0,1-3,4.21,8.45,8.45,0,0,1-4.15,2.52,8.62,8.62,0,0,1-8.1-2.7,11,11,0,0,1-2.17-9.83c.07-.31-.41-.44-.48-.13Z"/>
                <path class="cls-4" d="M60,278.89c-1,.87-2.1,1.86-2.25,3.18l0,.07a11.19,11.19,0,0,0,2.51,10.36,8.58,8.58,0,0,0,10.2,1.62,11.31,11.31,0,0,0,2.24-1.85,12.08,12.08,0,0,0,3.2-5.07,8.94,8.94,0,0,0-4.71-10A10.49,10.49,0,0,0,60,278.89Zm4.89.46a2.48,2.48,0,0,1,1.57,2.72,2.26,2.26,0,0,1-2.53,1.76,2,2,0,0,1-1.77-2.37l0-.06c.1-.64.14-1.43.71-1.83A2.21,2.21,0,0,1,64.86,279.35Z"/>
                <path class="cls-4" d="M47.53,278a5.13,5.13,0,0,0-2.67-1.75c-.31-.07-.44.41-.13.48a4.69,4.69,0,0,1,2.45,1.62.24.24,0,0,0,.35,0,.25.25,0,0,0,0-.35Z"/>
                <path class="cls-4" d="M45,276a11.67,11.67,0,0,0-10.09.95,9.18,9.18,0,0,0-4.14,7.76c.08,3.47,2.75,6.68,5.56,8.48a8.71,8.71,0,0,0,8.72.28,9.59,9.59,0,0,0,5-9.65c0-.32-.53-.32-.5,0a9.18,9.18,0,0,1-3.64,8.58,8.38,8.38,0,0,1-8.41.85,11.91,11.91,0,0,1-3.72-3.05,9.13,9.13,0,0,1-2.37-4.44,8.54,8.54,0,0,1,3.16-7.92,11,11,0,0,1,10.34-1.36c.3.11.43-.37.13-.48Z"/>
                <path class="cls-4" d="M52.78,276.8c-.76-1.09-1.64-2.28-2.93-2.57l-.06,0a11.21,11.21,0,0,0-10.58,1.4,8.59,8.59,0,0,0-2.68,10,11,11,0,0,0,1.6,2.42,12,12,0,0,0,4.71,3.71,8.93,8.93,0,0,0,10.41-3.63A10.5,10.5,0,0,0,52.78,276.8Zm-1,4.81a2.49,2.49,0,0,1-2.87,1.28,2.29,2.29,0,0,1-1.49-2.71,2,2,0,0,1,2.55-1.5l.06,0c.63.17,1.41.29,1.74.9A2.19,2.19,0,0,1,51.8,281.61Z"/>
                <path class="cls-4" d="M94.3,281.13a5.14,5.14,0,0,0-3.1-.75.25.25,0,0,0-.25.25c0,.12.11.27.25.25a4.7,4.7,0,0,1,2.85.67c.28.16.53-.27.25-.42Z"/>
                <path class="cls-4" d="M91.2,280.13A11.75,11.75,0,0,0,82.39,284a9.21,9.21,0,0,0-1.73,8.76c1.12,3.34,4.55,5.46,7.82,6.33a8.7,8.7,0,0,0,8.7-2.56,9.61,9.61,0,0,0,1.37-10.86c-.14-.29-.57,0-.43.25a9.16,9.16,0,0,1-.65,9.49,8.33,8.33,0,0,1-8,3.39c-3.08-.55-6.7-2.54-8-5.5a8.69,8.69,0,0,1,.87-8.35,11.09,11.09,0,0,1,8.93-4.36.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M98.79,278.16c-1.09-.76-2.32-1.59-3.64-1.41h-.07a11.18,11.18,0,0,0-9.44,4.95,8.58,8.58,0,0,0,.9,10.29,11.34,11.34,0,0,0,2.34,1.73,12.1,12.1,0,0,0,5.69,1.86,8.91,8.91,0,0,0,8.53-7A10.46,10.46,0,0,0,98.79,278.16Zm.73,4.85a2.49,2.49,0,0,1-2.26,2.19,2.29,2.29,0,0,1-2.32-2c-.07-1.14.74-2.37,1.88-2.29h.06c.65-.05,1.42-.21,2,.25A2.24,2.24,0,0,1,99.52,283Z"/>
                <path class="cls-4" d="M136.1,284a5.15,5.15,0,0,0-1.49,2.89c0,.14.13.25.25.25a.25.25,0,0,0,.25-.25,4.63,4.63,0,0,1,1.34-2.54c.22-.23-.13-.59-.35-.35Z"/>
                <path class="cls-4" d="M134.38,286.72a11.8,11.8,0,0,0,1.66,9.53,9.23,9.23,0,0,0,8.27,3.76c3.47-.36,6.37-3.25,7.94-6.22a8.71,8.71,0,0,0-.58-9,9.63,9.63,0,0,0-10.1-3.8c-.31.07-.18.54.14.48a9.15,9.15,0,0,1,9,2.91,8.36,8.36,0,0,1,1.5,8.31,12.91,12.91,0,0,1-3,4.21,8.45,8.45,0,0,1-4.15,2.52,8.62,8.62,0,0,1-8.1-2.7,11,11,0,0,1-2.17-9.83c.07-.31-.41-.44-.48-.13Z"/>
                <path class="cls-4" d="M134.3,278.89c-1,.87-2.1,1.86-2.25,3.18l0,.07a11.19,11.19,0,0,0,2.51,10.36,8.58,8.58,0,0,0,10.2,1.62,11.31,11.31,0,0,0,2.24-1.85,12.08,12.08,0,0,0,3.2-5.07,9,9,0,0,0-4.71-10A10.49,10.49,0,0,0,134.3,278.89Zm4.89.46a2.48,2.48,0,0,1,1.57,2.72,2.27,2.27,0,0,1-2.54,1.76,2,2,0,0,1-1.76-2.37v-.06c.11-.64.14-1.43.72-1.83A2.21,2.21,0,0,1,139.19,279.35Z"/>
                <path class="cls-4" d="M121.86,278a5.17,5.17,0,0,0-2.67-1.75c-.31-.07-.44.41-.13.48a4.72,4.72,0,0,1,2.45,1.62.24.24,0,0,0,.35,0,.25.25,0,0,0,0-.35Z"/>
                <path class="cls-4" d="M119.28,276a11.67,11.67,0,0,0-10.09.95,9.18,9.18,0,0,0-4.14,7.76c.08,3.47,2.75,6.68,5.56,8.48a8.71,8.71,0,0,0,8.72.28,9.59,9.59,0,0,0,5-9.65c0-.32-.53-.32-.5,0a9.18,9.18,0,0,1-3.64,8.58,8.38,8.38,0,0,1-8.41.85,11.91,11.91,0,0,1-3.72-3.05,9.23,9.23,0,0,1-2.38-4.44,8.59,8.59,0,0,1,3.16-7.92,11,11,0,0,1,10.35-1.36c.3.11.43-.37.13-.48Z"/>
                <path class="cls-4" d="M127.1,276.8c-.75-1.09-1.63-2.28-2.92-2.57l-.07,0a11.19,11.19,0,0,0-10.57,1.4,8.61,8.61,0,0,0-2.69,10,11.45,11.45,0,0,0,1.61,2.42,12,12,0,0,0,4.7,3.71,8.92,8.92,0,0,0,10.41-3.63A10.46,10.46,0,0,0,127.1,276.8Zm-1,4.81a2.49,2.49,0,0,1-2.87,1.28,2.28,2.28,0,0,1-1.49-2.71,2,2,0,0,1,2.55-1.5l.06,0c.63.17,1.41.29,1.74.9A2.22,2.22,0,0,1,126.13,281.61Z"/>
                <path class="cls-4" d="M461.19,256.53a5.17,5.17,0,0,0-3.11-.75.26.26,0,0,0-.24.25c0,.12.11.27.24.25a4.65,4.65,0,0,1,2.86.68c.28.15.53-.28.25-.43Z"/>
                <path class="cls-4" d="M458.09,255.53a11.75,11.75,0,0,0-8.81,3.91,9.19,9.19,0,0,0-1.73,8.76c1.12,3.34,4.55,5.46,7.82,6.34a8.72,8.72,0,0,0,8.7-2.57,9.61,9.61,0,0,0,1.37-10.86c-.14-.29-.57,0-.43.25a9.18,9.18,0,0,1-.65,9.5,8.33,8.33,0,0,1-8.05,3.38c-3.08-.55-6.7-2.54-8-5.5a8.66,8.66,0,0,1,.87-8.34,11.1,11.1,0,0,1,8.93-4.37.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M465.68,253.56c-1.09-.76-2.32-1.59-3.64-1.41H462a11.18,11.18,0,0,0-9.44,4.95,8.58,8.58,0,0,0,.9,10.29,11.34,11.34,0,0,0,2.34,1.73,12.08,12.08,0,0,0,5.69,1.87,8.93,8.93,0,0,0,8.53-7A10.48,10.48,0,0,0,465.68,253.56Zm.73,4.85a2.49,2.49,0,0,1-2.26,2.19,2.28,2.28,0,0,1-2.32-2c-.07-1.14.74-2.36,1.87-2.28h.07c.64,0,1.42-.21,1.94.25A2.23,2.23,0,0,1,466.41,258.41Z"/>
                <path class="cls-4" d="M503,259.36a5.17,5.17,0,0,0-1.48,2.89c0,.14.13.25.25.25a.25.25,0,0,0,.25-.25,4.54,4.54,0,0,1,1.34-2.54c.21-.23-.14-.58-.36-.35Z"/>
                <path class="cls-4" d="M501.27,262.12a11.8,11.8,0,0,0,1.66,9.53,9.23,9.23,0,0,0,8.27,3.76c3.47-.36,6.37-3.25,7.94-6.21a8.73,8.73,0,0,0-.58-9.05,9.65,9.65,0,0,0-10.1-3.8c-.31.07-.18.55.13.48a9.16,9.16,0,0,1,9,2.91,8.36,8.36,0,0,1,1.5,8.31,12.81,12.81,0,0,1-3,4.21,8.36,8.36,0,0,1-4.16,2.52,8.61,8.61,0,0,1-8.09-2.7,11,11,0,0,1-2.17-9.82c.07-.32-.41-.45-.48-.14Z"/>
                <path class="cls-4" d="M501.19,254.29c-1,.87-2.1,1.86-2.25,3.18l0,.07a11.19,11.19,0,0,0,2.51,10.36,8.6,8.6,0,0,0,10.2,1.63,11.36,11.36,0,0,0,2.24-1.86,12.11,12.11,0,0,0,3.2-5.06,8.94,8.94,0,0,0-4.71-10A10.47,10.47,0,0,0,501.19,254.29Zm4.89.46a2.5,2.5,0,0,1,1.57,2.73,2.28,2.28,0,0,1-2.54,1.76,2,2,0,0,1-1.76-2.38v-.06c.11-.64.14-1.43.72-1.83A2.24,2.24,0,0,1,506.08,254.75Z"/>
                <path class="cls-4" d="M488.75,253.43a5.17,5.17,0,0,0-2.67-1.75c-.31-.07-.44.41-.13.48a4.74,4.74,0,0,1,2.45,1.63.25.25,0,0,0,.35,0,.27.27,0,0,0,0-.36Z"/>
                <path class="cls-4" d="M486.17,251.45a11.64,11.64,0,0,0-10.09.94,9.19,9.19,0,0,0-4.14,7.77c.08,3.46,2.75,6.67,5.56,8.47a8.69,8.69,0,0,0,8.72.28,9.58,9.58,0,0,0,5-9.65c0-.32-.53-.32-.5,0a9.18,9.18,0,0,1-3.64,8.58,8.39,8.39,0,0,1-8.42.85,12.1,12.1,0,0,1-3.71-3,9.32,9.32,0,0,1-2.38-4.44,8.6,8.6,0,0,1,3.16-7.93A11,11,0,0,1,486,251.93c.3.1.43-.38.13-.48Z"/>
                <path class="cls-4" d="M494,252.2c-.76-1.08-1.63-2.28-2.92-2.57l-.07,0A11.19,11.19,0,0,0,480.43,251a8.58,8.58,0,0,0-2.69,10,11.52,11.52,0,0,0,1.61,2.43,12.2,12.2,0,0,0,4.7,3.71,8.94,8.94,0,0,0,10.41-3.64A10.46,10.46,0,0,0,494,252.2ZM493,257a2.49,2.49,0,0,1-2.87,1.28,2.27,2.27,0,0,1-1.49-2.71,2,2,0,0,1,2.55-1.5l.06,0c.63.17,1.41.29,1.74.9A2.22,2.22,0,0,1,493,257Z"/>
                <path class="cls-4" d="M535.52,256.53a5.17,5.17,0,0,0-3.11-.75.26.26,0,0,0-.24.25c0,.12.11.27.24.25a4.65,4.65,0,0,1,2.86.68c.28.15.53-.28.25-.43Z"/>
                <path class="cls-4" d="M532.42,255.53a11.75,11.75,0,0,0-8.81,3.91,9.19,9.19,0,0,0-1.73,8.76c1.12,3.34,4.55,5.46,7.82,6.34a8.72,8.72,0,0,0,8.7-2.57,9.61,9.61,0,0,0,1.37-10.86c-.14-.29-.57,0-.43.25a9.2,9.2,0,0,1-.65,9.5,8.33,8.33,0,0,1-8.05,3.38c-3.08-.55-6.7-2.54-8-5.5a8.64,8.64,0,0,1,.87-8.34,11.1,11.1,0,0,1,8.93-4.37.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M540,253.56c-1.08-.76-2.31-1.59-3.63-1.41h-.07a11.21,11.21,0,0,0-9.45,4.95,8.6,8.6,0,0,0,.91,10.29,11.08,11.08,0,0,0,2.34,1.73,12.08,12.08,0,0,0,5.69,1.87,8.94,8.94,0,0,0,8.53-7A10.47,10.47,0,0,0,540,253.56Zm.74,4.85a2.49,2.49,0,0,1-2.26,2.19,2.28,2.28,0,0,1-2.32-2c-.07-1.14.73-2.36,1.87-2.28h.07c.64,0,1.42-.21,1.94.25A2.23,2.23,0,0,1,540.74,258.41Z"/>
                <path class="cls-4" d="M577.31,259.36a5.17,5.17,0,0,0-1.48,2.89.24.24,0,0,0,.25.25.25.25,0,0,0,.25-.25,4.54,4.54,0,0,1,1.34-2.54c.21-.23-.14-.58-.36-.35Z"/>
                <path class="cls-4" d="M575.6,262.12a11.8,11.8,0,0,0,1.66,9.53,9.23,9.23,0,0,0,8.27,3.76c3.47-.36,6.37-3.25,7.94-6.21a8.71,8.71,0,0,0-.59-9.05,9.62,9.62,0,0,0-10.09-3.8c-.31.07-.18.55.13.48a9.17,9.17,0,0,1,9,2.91,8.36,8.36,0,0,1,1.5,8.31,12.81,12.81,0,0,1-3,4.21,8.41,8.41,0,0,1-4.16,2.52,8.61,8.61,0,0,1-8.09-2.7,11,11,0,0,1-2.17-9.82c.07-.32-.41-.45-.48-.14Z"/>
                <path class="cls-4" d="M575.52,254.29c-1,.87-2.1,1.86-2.25,3.18l0,.07a11.19,11.19,0,0,0,2.51,10.36,8.6,8.6,0,0,0,10.2,1.63,11.36,11.36,0,0,0,2.24-1.86,12.11,12.11,0,0,0,3.2-5.06,8.93,8.93,0,0,0-4.72-10A10.46,10.46,0,0,0,575.52,254.29Zm4.88.46a2.49,2.49,0,0,1,1.58,2.73,2.28,2.28,0,0,1-2.54,1.76,2,2,0,0,1-1.76-2.38v-.06c.11-.64.14-1.43.71-1.83A2.25,2.25,0,0,1,580.4,254.75Z"/>
                <path class="cls-4" d="M563.08,253.43a5.17,5.17,0,0,0-2.67-1.75c-.31-.07-.44.41-.13.48a4.68,4.68,0,0,1,2.44,1.63.26.26,0,0,0,.36,0,.27.27,0,0,0,0-.36Z"/>
                <path class="cls-4" d="M560.5,251.45a11.64,11.64,0,0,0-10.09.94,9.19,9.19,0,0,0-4.14,7.77c.08,3.46,2.75,6.67,5.56,8.47a8.69,8.69,0,0,0,8.72.28,9.58,9.58,0,0,0,5-9.65c0-.32-.53-.32-.5,0a9.18,9.18,0,0,1-3.64,8.58,8.39,8.39,0,0,1-8.42.85,12.1,12.1,0,0,1-3.71-3,9.32,9.32,0,0,1-2.38-4.44,8.6,8.6,0,0,1,3.16-7.93,11,11,0,0,1,10.35-1.35c.3.1.43-.38.13-.48Z"/>
                <path class="cls-4" d="M568.32,252.2c-.76-1.08-1.63-2.28-2.92-2.57l-.07,0A11.19,11.19,0,0,0,554.76,251a8.58,8.58,0,0,0-2.69,10,11.52,11.52,0,0,0,1.61,2.43,12.12,12.12,0,0,0,4.7,3.71,8.94,8.94,0,0,0,10.41-3.64A10.46,10.46,0,0,0,568.32,252.2Zm-1,4.81a2.5,2.5,0,0,1-2.88,1.28,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.55-1.5l.06,0c.62.17,1.4.29,1.74.9A2.22,2.22,0,0,1,567.35,257Z"/>
                <path class="cls-4" d="M313.07,256.53a5.17,5.17,0,0,0-3.11-.75.27.27,0,0,0-.25.25c0,.12.12.27.25.25a4.67,4.67,0,0,1,2.86.68c.28.15.53-.28.25-.43Z"/>
                <path class="cls-4" d="M310,255.53a11.75,11.75,0,0,0-8.81,3.91,9.19,9.19,0,0,0-1.73,8.76c1.11,3.34,4.55,5.46,7.81,6.34a8.72,8.72,0,0,0,8.7-2.57,9.59,9.59,0,0,0,1.37-10.86c-.13-.29-.56,0-.43.25a9.15,9.15,0,0,1-.65,9.5,8.31,8.31,0,0,1-8,3.38c-3.08-.55-6.71-2.54-8-5.5a8.64,8.64,0,0,1,.87-8.34A11.1,11.1,0,0,1,310,256a.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M317.55,253.56c-1.08-.76-2.32-1.59-3.63-1.41h-.07a11.19,11.19,0,0,0-9.45,4.95,8.6,8.6,0,0,0,.9,10.29,11.4,11.4,0,0,0,2.35,1.73,12,12,0,0,0,5.69,1.87,9,9,0,0,0,8.53-7A10.47,10.47,0,0,0,317.55,253.56Zm.74,4.85A2.49,2.49,0,0,1,316,260.6a2.27,2.27,0,0,1-2.32-2c-.07-1.14.73-2.36,1.87-2.28h.06c.65,0,1.42-.21,1.95.25A2.23,2.23,0,0,1,318.29,258.41Z"/>
                <path class="cls-4" d="M354.86,259.36a5.17,5.17,0,0,0-1.48,2.89.24.24,0,0,0,.24.25.25.25,0,0,0,.25-.25,4.67,4.67,0,0,1,1.34-2.54c.22-.23-.13-.58-.35-.35Z"/>
                <path class="cls-4" d="M353.14,262.12a11.8,11.8,0,0,0,1.67,9.53,9.22,9.22,0,0,0,8.26,3.76c3.48-.36,6.37-3.25,7.95-6.21a8.73,8.73,0,0,0-.59-9.05,9.62,9.62,0,0,0-10.09-3.8c-.31.07-.18.55.13.48a9.17,9.17,0,0,1,9,2.91,8.36,8.36,0,0,1,1.5,8.31,12.81,12.81,0,0,1-3,4.21,8.41,8.41,0,0,1-4.16,2.52,8.64,8.64,0,0,1-8.1-2.7,11.06,11.06,0,0,1-2.17-9.82.25.25,0,0,0-.48-.14Z"/>
                <path class="cls-4" d="M353.07,254.29c-1,.87-2.1,1.86-2.25,3.18l0,.07a11.19,11.19,0,0,0,2.51,10.36c2.59,2.66,7,3.53,10.2,1.63a11.14,11.14,0,0,0,2.25-1.86,12,12,0,0,0,3.19-5.06,8.91,8.91,0,0,0-4.71-10A10.45,10.45,0,0,0,353.07,254.29Zm4.88.46a2.5,2.5,0,0,1,1.58,2.73,2.28,2.28,0,0,1-2.54,1.76,2,2,0,0,1-1.76-2.38v-.06c.1-.64.14-1.43.71-1.83A2.24,2.24,0,0,1,358,254.75Z"/>
                <path class="cls-4" d="M340.62,253.43a5.1,5.1,0,0,0-2.66-1.75c-.31-.07-.45.41-.14.48a4.66,4.66,0,0,1,2.45,1.63.25.25,0,0,0,.35,0,.26.26,0,0,0,0-.36Z"/>
                <path class="cls-4" d="M338.05,251.45a11.64,11.64,0,0,0-10.09.94,9.23,9.23,0,0,0-4.15,7.77c.08,3.46,2.76,6.67,5.57,8.47a8.69,8.69,0,0,0,8.72.28,9.58,9.58,0,0,0,5-9.65c0-.32-.53-.32-.5,0a9.17,9.17,0,0,1-3.65,8.58,8.38,8.38,0,0,1-8.41.85,12.1,12.1,0,0,1-3.71-3,9.32,9.32,0,0,1-2.38-4.44,8.57,8.57,0,0,1,3.16-7.93,11,11,0,0,1,10.35-1.35c.3.1.43-.38.13-.48Z"/>
                <path class="cls-4" d="M345.87,252.2c-.76-1.08-1.63-2.28-2.93-2.57l-.06,0A11.19,11.19,0,0,0,332.31,251a8.58,8.58,0,0,0-2.69,10,11.52,11.52,0,0,0,1.61,2.43,12.12,12.12,0,0,0,4.7,3.71,8.94,8.94,0,0,0,10.41-3.64A10.48,10.48,0,0,0,345.87,252.2Zm-1,4.81a2.49,2.49,0,0,1-2.87,1.28,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.55-1.5l.05,0c.63.17,1.41.29,1.75.9A2.19,2.19,0,0,1,344.89,257Z"/>
                <path class="cls-4" d="M387.4,256.53a5.18,5.18,0,0,0-3.11-.75.27.27,0,0,0-.25.25c0,.12.12.27.25.25a4.67,4.67,0,0,1,2.86.68c.28.15.53-.28.25-.43Z"/>
                <path class="cls-4" d="M384.29,255.53a11.78,11.78,0,0,0-8.81,3.91,9.21,9.21,0,0,0-1.72,8.76c1.11,3.34,4.55,5.46,7.81,6.34a8.72,8.72,0,0,0,8.7-2.57,9.61,9.61,0,0,0,1.37-10.86c-.13-.29-.56,0-.43.25a9.15,9.15,0,0,1-.65,9.5,8.31,8.31,0,0,1-8,3.38c-3.08-.55-6.71-2.54-8-5.5a8.64,8.64,0,0,1,.88-8.34,11.09,11.09,0,0,1,8.92-4.37.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M391.88,253.56c-1.09-.76-2.32-1.59-3.63-1.41h-.07a11.19,11.19,0,0,0-9.45,4.95,8.6,8.6,0,0,0,.9,10.29,11.4,11.4,0,0,0,2.35,1.73,12,12,0,0,0,5.69,1.87,9,9,0,0,0,8.53-7A10.49,10.49,0,0,0,391.88,253.56Zm.74,4.85a2.49,2.49,0,0,1-2.26,2.19,2.29,2.29,0,0,1-2.33-2c-.06-1.14.74-2.36,1.88-2.28H390c.65,0,1.42-.21,1.95.25A2.23,2.23,0,0,1,392.62,258.41Z"/>
                <path class="cls-4" d="M429.19,259.36a5.17,5.17,0,0,0-1.48,2.89.24.24,0,0,0,.24.25.25.25,0,0,0,.25-.25,4.67,4.67,0,0,1,1.34-2.54c.22-.23-.13-.58-.35-.35Z"/>
                <path class="cls-4" d="M427.47,262.12a11.76,11.76,0,0,0,1.67,9.53,9.21,9.21,0,0,0,8.26,3.76c3.48-.36,6.37-3.25,8-6.21a8.73,8.73,0,0,0-.59-9.05,9.62,9.62,0,0,0-10.09-3.8c-.32.07-.18.55.13.48a9.15,9.15,0,0,1,9,2.91,8.34,8.34,0,0,1,1.5,8.31,12.64,12.64,0,0,1-2.95,4.21,8.45,8.45,0,0,1-4.16,2.52,8.64,8.64,0,0,1-8.1-2.7,11.06,11.06,0,0,1-2.17-9.82.25.25,0,0,0-.48-.14Z"/>
                <path class="cls-4" d="M427.4,254.29c-1,.87-2.1,1.86-2.25,3.18l0,.07a11.19,11.19,0,0,0,2.51,10.36,8.6,8.6,0,0,0,10.2,1.63,11.14,11.14,0,0,0,2.25-1.86,12,12,0,0,0,3.19-5.06,8.91,8.91,0,0,0-4.71-10A10.45,10.45,0,0,0,427.4,254.29Zm4.88.46a2.5,2.5,0,0,1,1.58,2.73,2.28,2.28,0,0,1-2.54,1.76,2,2,0,0,1-1.77-2.38l0-.06c.1-.64.14-1.43.71-1.83A2.24,2.24,0,0,1,432.28,254.75Z"/>
                <path class="cls-4" d="M415,253.43a5.14,5.14,0,0,0-2.66-1.75c-.32-.07-.45.41-.14.48a4.7,4.7,0,0,1,2.45,1.63.25.25,0,0,0,.35,0,.26.26,0,0,0,0-.36Z"/>
                <path class="cls-4" d="M412.38,251.45a11.64,11.64,0,0,0-10.09.94,9.18,9.18,0,0,0-4.15,7.77c.08,3.46,2.76,6.67,5.57,8.47a8.69,8.69,0,0,0,8.72.28,9.56,9.56,0,0,0,4.95-9.65c0-.32-.52-.32-.49,0a9.17,9.17,0,0,1-3.65,8.58,8.38,8.38,0,0,1-8.41.85,12.1,12.1,0,0,1-3.71-3,9.32,9.32,0,0,1-2.38-4.44,8.55,8.55,0,0,1,3.16-7.93,11,11,0,0,1,10.34-1.35c.31.1.44-.38.14-.48Z"/>
                <path class="cls-4" d="M420.2,252.2c-.76-1.08-1.63-2.28-2.93-2.57l-.06,0A11.19,11.19,0,0,0,406.64,251,8.58,8.58,0,0,0,404,261a11.52,11.52,0,0,0,1.61,2.43,12.12,12.12,0,0,0,4.7,3.71,8.94,8.94,0,0,0,10.41-3.64A10.48,10.48,0,0,0,420.2,252.2Zm-1,4.81a2.49,2.49,0,0,1-2.87,1.28,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.54-1.5l.06,0c.63.17,1.41.29,1.75.9A2.22,2.22,0,0,1,419.22,257Z"/>
                <path class="cls-4" d="M467.07,281.13a5.14,5.14,0,0,0-3.1-.75.25.25,0,0,0-.25.25c0,.12.11.27.25.25a4.68,4.68,0,0,1,2.85.67c.28.16.53-.27.25-.42Z"/>
                <path class="cls-4" d="M464,280.13a11.77,11.77,0,0,0-8.81,3.91,9.21,9.21,0,0,0-1.73,8.76c1.12,3.34,4.55,5.46,7.82,6.33a8.7,8.7,0,0,0,8.7-2.56,9.61,9.61,0,0,0,1.37-10.86c-.14-.29-.57,0-.43.25a9.16,9.16,0,0,1-.65,9.49,8.33,8.33,0,0,1-8,3.39c-3.09-.55-6.71-2.54-8-5.5A8.69,8.69,0,0,1,455,285a11.1,11.1,0,0,1,8.93-4.36.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M471.56,278.16c-1.09-.76-2.32-1.59-3.64-1.41h-.06a11.18,11.18,0,0,0-9.45,4.95,8.6,8.6,0,0,0,.9,10.29,11.14,11.14,0,0,0,2.35,1.73,12,12,0,0,0,5.68,1.86,8.91,8.91,0,0,0,8.53-7A10.46,10.46,0,0,0,471.56,278.16Zm.73,4.85A2.49,2.49,0,0,1,470,285.2a2.29,2.29,0,0,1-2.33-2c-.07-1.14.74-2.37,1.88-2.29h.06c.65-.05,1.42-.21,2,.25A2.24,2.24,0,0,1,472.29,283Z"/>
                <path class="cls-4" d="M508.87,284a5.21,5.21,0,0,0-1.49,2.89.25.25,0,0,0,.5,0,4.63,4.63,0,0,1,1.34-2.54c.22-.23-.13-.59-.35-.35Z"/>
                <path class="cls-4" d="M507.15,286.72a11.76,11.76,0,0,0,1.67,9.53,9.2,9.2,0,0,0,8.26,3.76c3.47-.36,6.37-3.25,7.94-6.22a8.71,8.71,0,0,0-.58-9,9.63,9.63,0,0,0-10.1-3.8c-.31.07-.18.54.14.48a9.15,9.15,0,0,1,9,2.91,8.36,8.36,0,0,1,1.5,8.31,12.91,12.91,0,0,1-3,4.21,8.45,8.45,0,0,1-4.15,2.52,8.62,8.62,0,0,1-8.1-2.7,11,11,0,0,1-2.17-9.83c.07-.31-.41-.44-.48-.13Z"/>
                <path class="cls-4" d="M507.07,278.89c-1,.87-2.1,1.86-2.25,3.18l0,.07a11.19,11.19,0,0,0,2.51,10.36,8.58,8.58,0,0,0,10.2,1.62,11.31,11.31,0,0,0,2.24-1.85,12.08,12.08,0,0,0,3.2-5.07,8.94,8.94,0,0,0-4.71-10A10.5,10.5,0,0,0,507.07,278.89Zm4.89.46a2.5,2.5,0,0,1,1.58,2.72,2.28,2.28,0,0,1-2.54,1.76,2,2,0,0,1-1.77-2.37l0-.06c.1-.64.14-1.43.71-1.83A2.21,2.21,0,0,1,512,279.35Z"/>
                <path class="cls-4" d="M494.63,278a5.13,5.13,0,0,0-2.67-1.75c-.31-.07-.44.41-.13.48a4.69,4.69,0,0,1,2.45,1.62.24.24,0,0,0,.35,0,.25.25,0,0,0,0-.35Z"/>
                <path class="cls-4" d="M492.05,276A11.67,11.67,0,0,0,482,277a9.18,9.18,0,0,0-4.14,7.76c.08,3.47,2.75,6.68,5.56,8.48a8.71,8.71,0,0,0,8.72.28,9.59,9.59,0,0,0,5-9.65c0-.32-.53-.32-.5,0a9.16,9.16,0,0,1-3.64,8.58,8.38,8.38,0,0,1-8.41.85,11.91,11.91,0,0,1-3.72-3.05,9.13,9.13,0,0,1-2.37-4.44,8.54,8.54,0,0,1,3.16-7.92,11,11,0,0,1,10.34-1.36c.3.11.43-.37.13-.48Z"/>
                <path class="cls-4" d="M499.88,276.8c-.76-1.09-1.64-2.28-2.93-2.57l-.06,0a11.21,11.21,0,0,0-10.58,1.4,8.59,8.59,0,0,0-2.68,10,11,11,0,0,0,1.6,2.42,12,12,0,0,0,4.71,3.71,8.93,8.93,0,0,0,10.41-3.63A10.5,10.5,0,0,0,499.88,276.8Zm-1,4.81a2.49,2.49,0,0,1-2.87,1.28,2.29,2.29,0,0,1-1.49-2.71,2,2,0,0,1,2.55-1.5l.06,0c.63.17,1.41.29,1.74.9A2.19,2.19,0,0,1,498.9,281.61Z"/>
                <path class="cls-4" d="M541.4,281.13a5.14,5.14,0,0,0-3.1-.75.25.25,0,0,0-.25.25c0,.12.11.27.25.25a4.7,4.7,0,0,1,2.85.67c.28.16.53-.27.25-.42Z"/>
                <path class="cls-4" d="M538.3,280.13a11.75,11.75,0,0,0-8.81,3.91,9.21,9.21,0,0,0-1.73,8.76c1.12,3.34,4.55,5.46,7.82,6.33a8.7,8.7,0,0,0,8.7-2.56,9.61,9.61,0,0,0,1.37-10.86c-.14-.29-.57,0-.43.25a9.16,9.16,0,0,1-.65,9.49,8.33,8.33,0,0,1-8.05,3.39c-3.08-.55-6.7-2.54-8-5.5a8.69,8.69,0,0,1,.87-8.35,11.09,11.09,0,0,1,8.93-4.36.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M545.89,278.16c-1.09-.76-2.32-1.59-3.64-1.41h-.07a11.18,11.18,0,0,0-9.44,4.95,8.6,8.6,0,0,0,.9,10.29,11.34,11.34,0,0,0,2.34,1.73,12.1,12.1,0,0,0,5.69,1.86,8.91,8.91,0,0,0,8.53-7A10.46,10.46,0,0,0,545.89,278.16Zm.73,4.85a2.49,2.49,0,0,1-2.26,2.19,2.29,2.29,0,0,1-2.32-2c-.07-1.14.74-2.37,1.88-2.29H544c.65-.05,1.42-.21,1.95.25A2.24,2.24,0,0,1,546.62,283Z"/>
                <path class="cls-4" d="M583.2,284a5.15,5.15,0,0,0-1.49,2.89.25.25,0,0,0,.5,0,4.63,4.63,0,0,1,1.34-2.54c.22-.23-.13-.59-.35-.35Z"/>
                <path class="cls-4" d="M581.48,286.72a11.76,11.76,0,0,0,1.67,9.53,9.2,9.2,0,0,0,8.26,3.76c3.47-.36,6.37-3.25,7.94-6.22a8.71,8.71,0,0,0-.58-9,9.63,9.63,0,0,0-10.1-3.8c-.31.07-.18.54.14.48a9.15,9.15,0,0,1,9,2.91,8.36,8.36,0,0,1,1.5,8.31,12.91,12.91,0,0,1-3,4.21,8.45,8.45,0,0,1-4.15,2.52,8.62,8.62,0,0,1-8.1-2.7,11,11,0,0,1-2.17-9.83c.07-.31-.41-.44-.48-.13Z"/>
                <path class="cls-4" d="M581.4,278.89c-1,.87-2.1,1.86-2.25,3.18l0,.07a11.19,11.19,0,0,0,2.51,10.36,8.58,8.58,0,0,0,10.2,1.62,11.31,11.31,0,0,0,2.24-1.85,12.08,12.08,0,0,0,3.2-5.07,9,9,0,0,0-4.71-10A10.49,10.49,0,0,0,581.4,278.89Zm4.89.46a2.48,2.48,0,0,1,1.57,2.72,2.26,2.26,0,0,1-2.53,1.76,2,2,0,0,1-1.77-2.37v-.06c.11-.64.15-1.43.72-1.83A2.21,2.21,0,0,1,586.29,279.35Z"/>
                <path class="cls-4" d="M569,278a5.17,5.17,0,0,0-2.67-1.75c-.31-.07-.44.41-.13.48a4.69,4.69,0,0,1,2.45,1.62.24.24,0,0,0,.35,0,.25.25,0,0,0,0-.35Z"/>
                <path class="cls-4" d="M566.38,276a11.67,11.67,0,0,0-10.09.95,9.18,9.18,0,0,0-4.14,7.76c.08,3.47,2.75,6.68,5.56,8.48a8.71,8.71,0,0,0,8.72.28,9.59,9.59,0,0,0,5-9.65c0-.32-.53-.32-.5,0a9.18,9.18,0,0,1-3.64,8.58,8.38,8.38,0,0,1-8.41.85,11.91,11.91,0,0,1-3.72-3.05,9.23,9.23,0,0,1-2.38-4.44,8.59,8.59,0,0,1,3.16-7.92,11,11,0,0,1,10.35-1.36c.3.11.43-.37.13-.48Z"/>
                <path class="cls-4" d="M574.2,276.8c-.75-1.09-1.63-2.28-2.92-2.57l-.07,0a11.19,11.19,0,0,0-10.57,1.4,8.59,8.59,0,0,0-2.68,10,11,11,0,0,0,1.6,2.42,12,12,0,0,0,4.7,3.71,8.92,8.92,0,0,0,10.41-3.63A10.46,10.46,0,0,0,574.2,276.8Zm-1,4.81a2.49,2.49,0,0,1-2.87,1.28,2.29,2.29,0,0,1-1.49-2.71,2,2,0,0,1,2.55-1.5l.06,0c.63.17,1.41.29,1.74.9A2.22,2.22,0,0,1,573.23,281.61Z"/>
                <path class="cls-4" d="M319,281.13a5.17,5.17,0,0,0-3.11-.75.26.26,0,0,0-.25.25c0,.12.12.27.25.25a4.71,4.71,0,0,1,2.86.67c.28.16.53-.27.25-.42Z"/>
                <path class="cls-4" d="M315.85,280.13A11.75,11.75,0,0,0,307,284a9.19,9.19,0,0,0-1.73,8.76c1.12,3.34,4.55,5.46,7.81,6.33a8.71,8.71,0,0,0,8.71-2.56,9.62,9.62,0,0,0,1.37-10.86c-.14-.29-.57,0-.43.25a9.16,9.16,0,0,1-.66,9.49,8.31,8.31,0,0,1-8,3.39c-3.08-.55-6.7-2.54-8-5.5a8.66,8.66,0,0,1,.87-8.35,11.09,11.09,0,0,1,8.93-4.36.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M323.43,278.16c-1.08-.76-2.31-1.59-3.63-1.41h-.07a11.21,11.21,0,0,0-9.45,4.95,8.58,8.58,0,0,0,.91,10.29,11.08,11.08,0,0,0,2.34,1.73,12,12,0,0,0,5.69,1.86,8.92,8.92,0,0,0,8.53-7A10.46,10.46,0,0,0,323.43,278.16Zm.74,4.85a2.51,2.51,0,0,1-2.26,2.19,2.29,2.29,0,0,1-2.32-2c-.07-1.14.73-2.37,1.87-2.29h.07c.64-.05,1.42-.21,1.94.25A2.22,2.22,0,0,1,324.17,283Z"/>
                <path class="cls-4" d="M360.74,284a5.14,5.14,0,0,0-1.48,2.89.24.24,0,0,0,.25.25.26.26,0,0,0,.25-.25,4.56,4.56,0,0,1,1.33-2.54c.22-.23-.13-.59-.35-.35Z"/>
                <path class="cls-4" d="M359,286.72a11.76,11.76,0,0,0,1.66,9.53A9.23,9.23,0,0,0,369,300c3.47-.36,6.37-3.25,7.94-6.22a8.69,8.69,0,0,0-.59-9,9.61,9.61,0,0,0-10.09-3.8c-.31.07-.18.54.13.48a9.17,9.17,0,0,1,9,2.91,8.36,8.36,0,0,1,1.5,8.31,12.91,12.91,0,0,1-3,4.21,8.48,8.48,0,0,1-4.16,2.52,8.64,8.64,0,0,1-8.1-2.7,11,11,0,0,1-2.16-9.83c.07-.31-.41-.44-.48-.13Z"/>
                <path class="cls-4" d="M359,278.89c-1,.87-2.1,1.86-2.25,3.18l0,.07a11.16,11.16,0,0,0,2.51,10.36c2.59,2.66,7,3.53,10.19,1.62a11.09,11.09,0,0,0,2.25-1.85,12,12,0,0,0,3.19-5.07,8.92,8.92,0,0,0-4.71-10A10.48,10.48,0,0,0,359,278.89Zm4.88.46a2.48,2.48,0,0,1,1.58,2.72,2.27,2.27,0,0,1-2.54,1.76c-1.12-.21-2.12-1.28-1.76-2.37v-.06c.11-.64.14-1.43.71-1.83A2.22,2.22,0,0,1,363.83,279.35Z"/>
                <path class="cls-4" d="M346.51,278a5.17,5.17,0,0,0-2.67-1.75c-.31-.07-.45.41-.13.48a4.66,4.66,0,0,1,2.44,1.62.25.25,0,0,0,.36,0,.26.26,0,0,0,0-.35Z"/>
                <path class="cls-4" d="M343.93,276a11.67,11.67,0,0,0-10.09.95,9.18,9.18,0,0,0-4.14,7.76c.07,3.47,2.75,6.68,5.56,8.48a8.71,8.71,0,0,0,8.72.28,9.59,9.59,0,0,0,5-9.65c0-.32-.53-.32-.5,0a9.18,9.18,0,0,1-3.64,8.58,8.39,8.39,0,0,1-8.42.85,12,12,0,0,1-3.71-3.05,9.23,9.23,0,0,1-2.38-4.44,8.59,8.59,0,0,1,3.16-7.92,11,11,0,0,1,10.35-1.36c.3.11.43-.37.13-.48Z"/>
                <path class="cls-4" d="M351.75,276.8c-.76-1.09-1.63-2.28-2.93-2.57l-.06,0a11.19,11.19,0,0,0-10.57,1.4c-2.92,2.3-4.25,6.61-2.69,10a11.45,11.45,0,0,0,1.61,2.42,11.94,11.94,0,0,0,4.7,3.71,8.92,8.92,0,0,0,10.41-3.63A10.48,10.48,0,0,0,351.75,276.8Zm-1,4.81a2.5,2.5,0,0,1-2.88,1.28,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.55-1.5l.06,0c.62.17,1.4.29,1.74.9A2.22,2.22,0,0,1,350.78,281.61Z"/>
                <path class="cls-4" d="M393.28,281.13a5.17,5.17,0,0,0-3.11-.75.26.26,0,0,0-.25.25c0,.12.12.27.25.25a4.73,4.73,0,0,1,2.86.67c.28.16.53-.27.25-.42Z"/>
                <path class="cls-4" d="M390.18,280.13a11.75,11.75,0,0,0-8.81,3.91,9.19,9.19,0,0,0-1.73,8.76c1.12,3.34,4.55,5.46,7.81,6.33a8.69,8.69,0,0,0,8.7-2.56,9.59,9.59,0,0,0,1.37-10.86c-.13-.29-.56,0-.42.25a9.16,9.16,0,0,1-.66,9.49,8.31,8.31,0,0,1-8,3.39c-3.08-.55-6.71-2.54-8-5.5a8.66,8.66,0,0,1,.87-8.35,11.09,11.09,0,0,1,8.93-4.36.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M397.76,278.16c-1.08-.76-2.32-1.59-3.63-1.41h-.07a11.19,11.19,0,0,0-9.45,4.95,8.58,8.58,0,0,0,.91,10.29,11.08,11.08,0,0,0,2.34,1.73,12,12,0,0,0,5.69,1.86,8.93,8.93,0,0,0,8.53-7A10.46,10.46,0,0,0,397.76,278.16Zm.74,4.85a2.51,2.51,0,0,1-2.26,2.19,2.28,2.28,0,0,1-2.32-2c-.07-1.14.73-2.37,1.87-2.29h.07c.64-.05,1.42-.21,1.94.25A2.22,2.22,0,0,1,398.5,283Z"/>
                <path class="cls-4" d="M435.07,284a5.14,5.14,0,0,0-1.48,2.89.24.24,0,0,0,.25.25.25.25,0,0,0,.24-.25,4.63,4.63,0,0,1,1.34-2.54c.22-.23-.13-.59-.35-.35Z"/>
                <path class="cls-4" d="M433.35,286.72a11.8,11.8,0,0,0,1.67,9.53,9.23,9.23,0,0,0,8.27,3.76c3.47-.36,6.37-3.25,7.94-6.22a8.69,8.69,0,0,0-.59-9,9.61,9.61,0,0,0-10.09-3.8c-.31.07-.18.54.13.48a9.17,9.17,0,0,1,9,2.91,8.36,8.36,0,0,1,1.5,8.31,12.91,12.91,0,0,1-3,4.21,8.48,8.48,0,0,1-4.16,2.52,8.64,8.64,0,0,1-8.1-2.7,11.08,11.08,0,0,1-2.17-9.83.25.25,0,0,0-.48-.13Z"/>
                <path class="cls-4" d="M433.28,278.89c-1,.87-2.1,1.86-2.25,3.18l0,.07a11.16,11.16,0,0,0,2.52,10.36c2.59,2.66,7,3.53,10.19,1.62a11.09,11.09,0,0,0,2.25-1.85,12,12,0,0,0,3.19-5.07,8.92,8.92,0,0,0-4.71-10A10.48,10.48,0,0,0,433.28,278.89Zm4.88.46a2.49,2.49,0,0,1,1.58,2.72,2.27,2.27,0,0,1-2.54,1.76c-1.12-.21-2.12-1.28-1.76-2.37v-.06c.1-.64.14-1.43.71-1.83A2.21,2.21,0,0,1,438.16,279.35Z"/>
                <path class="cls-4" d="M420.83,278a5.1,5.1,0,0,0-2.66-1.75c-.31-.07-.45.41-.13.48a4.66,4.66,0,0,1,2.44,1.62.25.25,0,0,0,.35-.35Z"/>
                <path class="cls-4" d="M418.26,276a11.67,11.67,0,0,0-10.09.95,9.18,9.18,0,0,0-4.14,7.76c.07,3.47,2.75,6.68,5.56,8.48a8.71,8.71,0,0,0,8.72.28,9.59,9.59,0,0,0,5-9.65c0-.32-.53-.32-.5,0a9.18,9.18,0,0,1-3.64,8.58,8.39,8.39,0,0,1-8.42.85,12,12,0,0,1-3.71-3.05,9.23,9.23,0,0,1-2.38-4.44,8.59,8.59,0,0,1,3.16-7.92,11,11,0,0,1,10.35-1.36c.3.11.43-.37.13-.48Z"/>
                <path class="cls-4" d="M426.08,276.8c-.76-1.09-1.63-2.28-2.93-2.57l-.06,0a11.19,11.19,0,0,0-10.57,1.4c-2.92,2.3-4.25,6.61-2.69,10a11.45,11.45,0,0,0,1.61,2.42,11.94,11.94,0,0,0,4.7,3.71,8.92,8.92,0,0,0,10.41-3.63A10.48,10.48,0,0,0,426.08,276.8Zm-1,4.81a2.5,2.5,0,0,1-2.88,1.28,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.55-1.5l.06,0c.62.17,1.4.29,1.74.9A2.22,2.22,0,0,1,425.11,281.61Z"/>
                <path class="cls-4" d="M162.21,304.72a5.2,5.2,0,0,0-3.1-.74.25.25,0,0,0-.25.25c0,.12.11.27.25.25a4.68,4.68,0,0,1,2.85.67.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M159.11,303.73a11.77,11.77,0,0,0-8.81,3.91,9.21,9.21,0,0,0-1.73,8.76c1.12,3.34,4.55,5.46,7.82,6.33a8.7,8.7,0,0,0,8.7-2.56,9.61,9.61,0,0,0,1.37-10.86c-.14-.29-.57,0-.43.25a9.14,9.14,0,0,1-.65,9.49,8.33,8.33,0,0,1-8,3.39c-3.08-.55-6.71-2.54-8-5.5a8.69,8.69,0,0,1,.87-8.35,11.12,11.12,0,0,1,8.93-4.36.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M166.7,301.76c-1.09-.77-2.32-1.59-3.63-1.41H163a11.18,11.18,0,0,0-9.45,5,8.6,8.6,0,0,0,.9,10.29,11.78,11.78,0,0,0,2.35,1.73,12,12,0,0,0,5.69,1.86,8.91,8.91,0,0,0,8.52-7A10.45,10.45,0,0,0,166.7,301.76Zm.73,4.85a2.49,2.49,0,0,1-2.25,2.19,2.3,2.3,0,0,1-2.33-2c-.07-1.14.74-2.37,1.88-2.29h.06c.65-.05,1.42-.21,2,.25A2.21,2.21,0,0,1,167.43,306.61Z"/>
                <path class="cls-4" d="M204,307.56a5.21,5.21,0,0,0-1.49,2.89.25.25,0,0,0,.5,0,4.63,4.63,0,0,1,1.34-2.54c.22-.24-.13-.59-.35-.35Z"/>
                <path class="cls-4" d="M202.29,310.32a11.76,11.76,0,0,0,1.67,9.53,9.2,9.2,0,0,0,8.26,3.76c3.47-.36,6.37-3.25,7.94-6.22a8.71,8.71,0,0,0-.58-9,9.62,9.62,0,0,0-10.09-3.8c-.32.06-.19.54.13.48a9.15,9.15,0,0,1,9,2.91,8.36,8.36,0,0,1,1.5,8.31,12.77,12.77,0,0,1-3,4.21A8.45,8.45,0,0,1,213,323a8.64,8.64,0,0,1-8.1-2.7,11,11,0,0,1-2.17-9.83c.07-.31-.41-.44-.48-.13Z"/>
                <path class="cls-4" d="M202.21,302.49c-1,.87-2.1,1.86-2.25,3.18l0,.06a11.2,11.2,0,0,0,2.51,10.37,8.58,8.58,0,0,0,10.2,1.62,11.31,11.31,0,0,0,2.24-1.85,12.08,12.08,0,0,0,3.2-5.07,8.92,8.92,0,0,0-4.71-10A10.5,10.5,0,0,0,202.21,302.49Zm4.89.46a2.5,2.5,0,0,1,1.58,2.72,2.28,2.28,0,0,1-2.54,1.76,2,2,0,0,1-1.77-2.37l0-.06c.1-.64.14-1.43.71-1.83A2.21,2.21,0,0,1,207.1,303Z"/>
                <path class="cls-4" d="M189.77,301.63a5.07,5.07,0,0,0-2.67-1.75c-.31-.07-.44.41-.13.48a4.69,4.69,0,0,1,2.45,1.62.24.24,0,0,0,.35,0,.25.25,0,0,0,0-.35Z"/>
                <path class="cls-4" d="M187.19,299.64a11.65,11.65,0,0,0-10.08.95,9.16,9.16,0,0,0-4.15,7.76c.08,3.46,2.75,6.68,5.56,8.48a8.71,8.71,0,0,0,8.72.28,9.59,9.59,0,0,0,5-9.65c0-.32-.52-.32-.49,0a9.19,9.19,0,0,1-3.65,8.58,8.38,8.38,0,0,1-8.41.85,12,12,0,0,1-3.72-3.05,9.13,9.13,0,0,1-2.37-4.44,8.54,8.54,0,0,1,3.16-7.92,11,11,0,0,1,10.34-1.36c.3.11.43-.37.13-.48Z"/>
                <path class="cls-4" d="M195,300.4c-.76-1.09-1.64-2.28-2.93-2.57l-.06,0a11.21,11.21,0,0,0-10.58,1.4,8.58,8.58,0,0,0-2.68,10,10.91,10.91,0,0,0,1.61,2.43,11.87,11.87,0,0,0,4.7,3.71,8.93,8.93,0,0,0,10.41-3.63A10.48,10.48,0,0,0,195,300.4Zm-1,4.81a2.49,2.49,0,0,1-2.87,1.28,2.29,2.29,0,0,1-1.49-2.71,2,2,0,0,1,2.55-1.5l.06,0c.63.17,1.41.29,1.75.9A2.22,2.22,0,0,1,194,305.21Z"/>
                <path class="cls-4" d="M236.54,304.72a5.2,5.2,0,0,0-3.1-.74.25.25,0,0,0-.25.25c0,.12.11.27.25.25a4.68,4.68,0,0,1,2.85.67.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M233.44,303.73a11.77,11.77,0,0,0-8.81,3.91,9.21,9.21,0,0,0-1.73,8.76c1.12,3.34,4.55,5.46,7.82,6.33a8.7,8.7,0,0,0,8.7-2.56,9.61,9.61,0,0,0,1.37-10.86c-.14-.29-.57,0-.43.25a9.16,9.16,0,0,1-.65,9.49,8.33,8.33,0,0,1-8,3.39c-3.08-.55-6.71-2.54-8-5.5a8.69,8.69,0,0,1,.87-8.35,11.1,11.1,0,0,1,8.93-4.36.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M241,301.76c-1.09-.77-2.32-1.59-3.64-1.41h-.06a11.18,11.18,0,0,0-9.45,5,8.6,8.6,0,0,0,.9,10.29,11.5,11.5,0,0,0,2.35,1.73,12,12,0,0,0,5.68,1.86,8.91,8.91,0,0,0,8.53-7A10.45,10.45,0,0,0,241,301.76Zm.73,4.85a2.49,2.49,0,0,1-2.25,2.19,2.29,2.29,0,0,1-2.33-2c-.07-1.14.74-2.37,1.88-2.29h.06c.65-.05,1.42-.21,1.95.25A2.24,2.24,0,0,1,241.76,306.61Z"/>
                <path class="cls-4" d="M278.34,307.56a5.21,5.21,0,0,0-1.49,2.89.25.25,0,0,0,.5,0,4.63,4.63,0,0,1,1.34-2.54c.22-.24-.13-.59-.35-.35Z"/>
                <path class="cls-4" d="M276.62,310.32a11.76,11.76,0,0,0,1.67,9.53,9.2,9.2,0,0,0,8.26,3.76c3.47-.36,6.37-3.25,7.94-6.22a8.71,8.71,0,0,0-.58-9,9.63,9.63,0,0,0-10.1-3.8c-.31.06-.18.54.14.48a9.15,9.15,0,0,1,9,2.91,8.36,8.36,0,0,1,1.5,8.31,12.91,12.91,0,0,1-3,4.21,8.45,8.45,0,0,1-4.15,2.52,8.63,8.63,0,0,1-8.1-2.7,11,11,0,0,1-2.17-9.83c.07-.31-.41-.44-.48-.13Z"/>
                <path class="cls-4" d="M276.54,302.49c-1,.87-2.1,1.86-2.25,3.18l0,.06a11.2,11.2,0,0,0,2.51,10.37,8.58,8.58,0,0,0,10.2,1.62,11.31,11.31,0,0,0,2.24-1.85,12.08,12.08,0,0,0,3.2-5.07,8.92,8.92,0,0,0-4.71-10A10.5,10.5,0,0,0,276.54,302.49Zm4.89.46a2.5,2.5,0,0,1,1.58,2.72,2.28,2.28,0,0,1-2.54,1.76,2,2,0,0,1-1.77-2.37l0-.06c.1-.64.14-1.43.71-1.83A2.21,2.21,0,0,1,281.43,303Z"/>
                <path class="cls-4" d="M264.1,301.63a5.07,5.07,0,0,0-2.67-1.75c-.31-.07-.44.41-.13.48a4.69,4.69,0,0,1,2.45,1.62.24.24,0,0,0,.35,0,.25.25,0,0,0,0-.35Z"/>
                <path class="cls-4" d="M261.52,299.64a11.67,11.67,0,0,0-10.09.95,9.18,9.18,0,0,0-4.14,7.76c.08,3.46,2.75,6.68,5.56,8.48a8.71,8.71,0,0,0,8.72.28,9.59,9.59,0,0,0,5-9.65c0-.32-.53-.32-.5,0a9.16,9.16,0,0,1-3.64,8.58,8.38,8.38,0,0,1-8.41.85,11.91,11.91,0,0,1-3.72-3.05,9.13,9.13,0,0,1-2.37-4.44,8.54,8.54,0,0,1,3.16-7.92,11,11,0,0,1,10.34-1.36c.3.11.43-.37.13-.48Z"/>
                <path class="cls-4" d="M269.35,300.4c-.76-1.09-1.64-2.28-2.93-2.57l-.06,0a11.21,11.21,0,0,0-10.58,1.4,8.58,8.58,0,0,0-2.68,10,11.23,11.23,0,0,0,1.6,2.43,12,12,0,0,0,4.71,3.71,8.93,8.93,0,0,0,10.41-3.63A10.5,10.5,0,0,0,269.35,300.4Zm-1,4.81a2.49,2.49,0,0,1-2.87,1.28,2.29,2.29,0,0,1-1.49-2.71,2,2,0,0,1,2.55-1.5l.06,0c.63.17,1.41.29,1.74.9A2.19,2.19,0,0,1,268.37,305.21Z"/>
                <path class="cls-4" d="M14.09,304.72A5.23,5.23,0,0,0,11,304a.25.25,0,0,0-.24.25c0,.12.11.27.24.25a4.71,4.71,0,0,1,2.86.67.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M11,303.73a11.75,11.75,0,0,0-8.81,3.91A9.18,9.18,0,0,0,.45,316.4c1.12,3.34,4.55,5.46,7.82,6.33a8.7,8.7,0,0,0,8.7-2.56,9.61,9.61,0,0,0,1.37-10.86c-.14-.29-.57,0-.43.25a9.18,9.18,0,0,1-.65,9.49,8.33,8.33,0,0,1-8,3.39c-3.08-.55-6.7-2.54-8-5.5a8.66,8.66,0,0,1,.87-8.35A11.09,11.09,0,0,1,11,304.23a.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M18.57,301.76c-1.08-.77-2.31-1.59-3.63-1.41h-.07a11.21,11.21,0,0,0-9.45,5,8.6,8.6,0,0,0,.91,10.29,11.71,11.71,0,0,0,2.34,1.73,12.06,12.06,0,0,0,5.69,1.86,8.92,8.92,0,0,0,8.53-7A10.46,10.46,0,0,0,18.57,301.76Zm.74,4.85a2.51,2.51,0,0,1-2.26,2.19,2.29,2.29,0,0,1-2.32-2c-.07-1.14.73-2.37,1.87-2.29h.07c.64-.05,1.42-.21,1.94.25A2.22,2.22,0,0,1,19.31,306.61Z"/>
                <path class="cls-4" d="M55.88,307.56a5.14,5.14,0,0,0-1.48,2.89c0,.14.13.25.25.25a.25.25,0,0,0,.25-.25,4.51,4.51,0,0,1,1.34-2.54c.21-.24-.14-.59-.36-.35Z"/>
                <path class="cls-4" d="M54.17,310.32a11.8,11.8,0,0,0,1.66,9.53,9.23,9.23,0,0,0,8.27,3.76c3.47-.36,6.37-3.25,7.94-6.22a8.69,8.69,0,0,0-.59-9,9.61,9.61,0,0,0-10.09-3.8c-.31.06-.18.54.13.48a9.17,9.17,0,0,1,9,2.91,8.36,8.36,0,0,1,1.5,8.31,12.91,12.91,0,0,1-3,4.21A8.48,8.48,0,0,1,64.91,323a8.61,8.61,0,0,1-8.09-2.7,11,11,0,0,1-2.17-9.83c.07-.31-.41-.44-.48-.13Z"/>
                <path class="cls-4" d="M54.09,302.49c-1,.87-2.1,1.86-2.25,3.18l0,.06a11.2,11.2,0,0,0,2.51,10.37,8.58,8.58,0,0,0,10.2,1.62,11.31,11.31,0,0,0,2.24-1.85A12.08,12.08,0,0,0,70,310.8a8.94,8.94,0,0,0-4.72-10A10.48,10.48,0,0,0,54.09,302.49ZM59,303a2.48,2.48,0,0,1,1.57,2.72A2.27,2.27,0,0,1,58,307.43a2,2,0,0,1-1.76-2.37V305c.11-.64.14-1.43.71-1.83A2.23,2.23,0,0,1,59,303Z"/>
                <path class="cls-4" d="M41.65,301.63A5.11,5.11,0,0,0,39,299.88c-.31-.07-.44.41-.13.48A4.66,4.66,0,0,1,41.29,302a.25.25,0,0,0,.36,0,.26.26,0,0,0,0-.35Z"/>
                <path class="cls-4" d="M39.07,299.64a11.67,11.67,0,0,0-10.09.95,9.18,9.18,0,0,0-4.14,7.76c.08,3.46,2.75,6.68,5.56,8.48a8.71,8.71,0,0,0,8.72.28,9.59,9.59,0,0,0,5-9.65c0-.32-.53-.32-.5,0A9.18,9.18,0,0,1,39.94,316a8.39,8.39,0,0,1-8.42.85,12,12,0,0,1-3.71-3.05,9.23,9.23,0,0,1-2.38-4.44,8.59,8.59,0,0,1,3.16-7.92,11,11,0,0,1,10.35-1.36c.3.11.43-.37.13-.48Z"/>
                <path class="cls-4" d="M46.89,300.4c-.76-1.09-1.63-2.28-2.92-2.57l-.07,0a11.19,11.19,0,0,0-10.57,1.4c-2.92,2.3-4.25,6.6-2.69,10a11.27,11.27,0,0,0,1.61,2.43,11.94,11.94,0,0,0,4.7,3.71,8.92,8.92,0,0,0,10.41-3.63A10.46,10.46,0,0,0,46.89,300.4Zm-1,4.81A2.49,2.49,0,0,1,43,306.49a2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.55-1.5l.06,0c.62.17,1.4.29,1.74.9A2.22,2.22,0,0,1,45.92,305.21Z"/>
                <path class="cls-4" d="M88.42,304.72a5.23,5.23,0,0,0-3.11-.74.26.26,0,0,0-.25.25c0,.12.12.27.25.25a4.71,4.71,0,0,1,2.86.67.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M85.32,303.73a11.75,11.75,0,0,0-8.81,3.91,9.18,9.18,0,0,0-1.73,8.76c1.12,3.34,4.55,5.46,7.81,6.33a8.71,8.71,0,0,0,8.71-2.56,9.62,9.62,0,0,0,1.37-10.86c-.14-.29-.57,0-.43.25a9.16,9.16,0,0,1-.66,9.49,8.31,8.31,0,0,1-8,3.39c-3.08-.55-6.7-2.54-8-5.5a8.66,8.66,0,0,1,.87-8.35,11.09,11.09,0,0,1,8.93-4.36.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M92.9,301.76c-1.08-.77-2.31-1.59-3.63-1.41H89.2a11.21,11.21,0,0,0-9.45,5,8.58,8.58,0,0,0,.91,10.29A11.43,11.43,0,0,0,83,317.31a12,12,0,0,0,5.69,1.86,8.92,8.92,0,0,0,8.53-7A10.46,10.46,0,0,0,92.9,301.76Zm.74,4.85a2.51,2.51,0,0,1-2.26,2.19,2.29,2.29,0,0,1-2.32-2c-.07-1.14.73-2.37,1.87-2.29H91c.64-.05,1.42-.21,1.94.25A2.22,2.22,0,0,1,93.64,306.61Z"/>
                <path class="cls-4" d="M130.21,307.56a5.14,5.14,0,0,0-1.48,2.89.24.24,0,0,0,.25.25.26.26,0,0,0,.25-.25,4.56,4.56,0,0,1,1.33-2.54c.22-.24-.13-.59-.35-.35Z"/>
                <path class="cls-4" d="M128.5,310.32a11.76,11.76,0,0,0,1.66,9.53,9.23,9.23,0,0,0,8.27,3.76c3.47-.36,6.37-3.25,7.94-6.22a8.69,8.69,0,0,0-.59-9,9.61,9.61,0,0,0-10.09-3.8c-.31.06-.18.54.13.48a9.17,9.17,0,0,1,9,2.91,8.36,8.36,0,0,1,1.5,8.31,12.91,12.91,0,0,1-3,4.21,8.48,8.48,0,0,1-4.16,2.52,8.61,8.61,0,0,1-8.09-2.7,11,11,0,0,1-2.17-9.83c.07-.31-.41-.44-.48-.13Z"/>
                <path class="cls-4" d="M128.42,302.49c-1,.87-2.1,1.86-2.25,3.18l0,.06a11.17,11.17,0,0,0,2.51,10.37c2.59,2.66,7,3.53,10.19,1.62a11.09,11.09,0,0,0,2.25-1.85,12,12,0,0,0,3.19-5.07,8.92,8.92,0,0,0-4.71-10A10.48,10.48,0,0,0,128.42,302.49Zm4.88.46a2.48,2.48,0,0,1,1.58,2.72,2.27,2.27,0,0,1-2.54,1.76c-1.12-.21-2.12-1.28-1.76-2.37V305c.11-.64.14-1.43.71-1.83A2.22,2.22,0,0,1,133.3,303Z"/>
                <path class="cls-4" d="M116,301.63a5.11,5.11,0,0,0-2.67-1.75c-.31-.07-.45.41-.13.48a4.66,4.66,0,0,1,2.44,1.62.25.25,0,0,0,.36,0,.26.26,0,0,0,0-.35Z"/>
                <path class="cls-4" d="M113.4,299.64a11.67,11.67,0,0,0-10.09.95,9.18,9.18,0,0,0-4.14,7.76c.08,3.46,2.75,6.68,5.56,8.48a8.71,8.71,0,0,0,8.72.28,9.59,9.59,0,0,0,5-9.65c0-.32-.53-.32-.5,0a9.18,9.18,0,0,1-3.64,8.58,8.39,8.39,0,0,1-8.42.85,12,12,0,0,1-3.71-3.05,9.23,9.23,0,0,1-2.38-4.44,8.59,8.59,0,0,1,3.16-7.92,11,11,0,0,1,10.35-1.36c.3.11.43-.37.13-.48Z"/>
                <path class="cls-4" d="M121.22,300.4c-.76-1.09-1.63-2.28-2.93-2.57l-.06,0a11.19,11.19,0,0,0-10.57,1.4c-2.92,2.3-4.25,6.6-2.69,10a11.27,11.27,0,0,0,1.61,2.43,11.94,11.94,0,0,0,4.7,3.71,8.92,8.92,0,0,0,10.41-3.63A10.48,10.48,0,0,0,121.22,300.4Zm-1,4.81a2.5,2.5,0,0,1-2.88,1.28,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.55-1.5l.06,0c.62.17,1.4.29,1.74.9A2.22,2.22,0,0,1,120.25,305.21Z"/>
                <path class="cls-4" d="M168.1,329.32a5.18,5.18,0,0,0-3.11-.74.25.25,0,0,0,0,.5,4.73,4.73,0,0,1,2.86.67.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M165,328.33a11.79,11.79,0,0,0-8.81,3.9,9.23,9.23,0,0,0-1.72,8.77c1.11,3.34,4.55,5.45,7.81,6.33a8.69,8.69,0,0,0,8.7-2.56,9.61,9.61,0,0,0,1.37-10.86c-.14-.29-.56,0-.43.25a9.14,9.14,0,0,1-.65,9.49,8.32,8.32,0,0,1-8,3.39c-3.08-.55-6.71-2.54-8-5.5a8.66,8.66,0,0,1,.88-8.35,11.08,11.08,0,0,1,8.92-4.36c.32,0,.32-.51,0-.5Z"/>
                <path class="cls-4" d="M172.58,326.35c-1.09-.76-2.32-1.58-3.63-1.41h-.07a11.19,11.19,0,0,0-9.45,4.95,8.6,8.6,0,0,0,.9,10.29,11.36,11.36,0,0,0,2.35,1.72,11.93,11.93,0,0,0,5.69,1.87,8.94,8.94,0,0,0,8.53-7A10.5,10.5,0,0,0,172.58,326.35Zm.74,4.86a2.51,2.51,0,0,1-2.26,2.19,2.3,2.3,0,0,1-2.33-2c-.06-1.14.74-2.37,1.88-2.29h.06c.65-.05,1.42-.21,2,.25A2.22,2.22,0,0,1,173.32,331.21Z"/>
                <path class="cls-4" d="M209.89,332.15a5.16,5.16,0,0,0-1.48,2.9.25.25,0,0,0,.24.25.25.25,0,0,0,.25-.25,4.63,4.63,0,0,1,1.34-2.54c.22-.24-.13-.59-.35-.36Z"/>
                <path class="cls-4" d="M208.17,334.92a11.73,11.73,0,0,0,1.67,9.52,9.17,9.17,0,0,0,8.26,3.76c3.48-.35,6.37-3.24,8-6.21a8.71,8.71,0,0,0-.59-9,9.61,9.61,0,0,0-10.09-3.8c-.32.06-.18.54.13.48a9.15,9.15,0,0,1,9,2.91,8.33,8.33,0,0,1,1.5,8.31,12.84,12.84,0,0,1-2.95,4.21,8,8,0,0,1-12.26-.18,11.08,11.08,0,0,1-2.17-9.83.25.25,0,0,0-.48-.13Z"/>
                <path class="cls-4" d="M208.1,327.09c-1,.86-2.1,1.86-2.25,3.18l0,.06a11.2,11.2,0,0,0,2.51,10.37,8.58,8.58,0,0,0,10.2,1.62,11.43,11.43,0,0,0,2.25-1.85A12,12,0,0,0,224,335.4a8.92,8.92,0,0,0-4.71-10A10.48,10.48,0,0,0,208.1,327.09Zm4.88.46a2.5,2.5,0,0,1,1.58,2.72A2.29,2.29,0,0,1,212,332c-1.12-.21-2.12-1.29-1.77-2.37l0-.06c.1-.64.14-1.43.71-1.83A2.21,2.21,0,0,1,213,327.55Z"/>
                <path class="cls-4" d="M195.65,326.23a5.08,5.08,0,0,0-2.66-1.75.25.25,0,0,0-.14.48,4.69,4.69,0,0,1,2.45,1.62.25.25,0,0,0,.35-.35Z"/>
                <path class="cls-4" d="M193.08,324.24a11.67,11.67,0,0,0-10.09.95,9.16,9.16,0,0,0-4.15,7.76c.08,3.46,2.76,6.68,5.57,8.48a8.71,8.71,0,0,0,8.72.28,9.57,9.57,0,0,0,5-9.66c0-.31-.52-.32-.49,0a9.19,9.19,0,0,1-3.65,8.59,8.38,8.38,0,0,1-8.41.85,12.29,12.29,0,0,1-3.72-3.05,9.29,9.29,0,0,1-2.37-4.44,8.54,8.54,0,0,1,3.16-7.92,11,11,0,0,1,10.34-1.36c.31.11.44-.37.14-.48Z"/>
                <path class="cls-4" d="M200.9,325c-.76-1.09-1.63-2.29-2.93-2.57l-.06,0a11.21,11.21,0,0,0-10.58,1.4c-2.91,2.3-4.24,6.6-2.68,10a11.27,11.27,0,0,0,1.61,2.43,12,12,0,0,0,4.7,3.71,8.92,8.92,0,0,0,10.41-3.63A10.48,10.48,0,0,0,200.9,325Zm-1,4.81a2.49,2.49,0,0,1-2.87,1.28,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.54-1.51l.06,0c.63.17,1.41.29,1.75.9A2.22,2.22,0,0,1,199.92,329.81Z"/>
                <path class="cls-4" d="M242.43,329.32a5.18,5.18,0,0,0-3.11-.74.26.26,0,0,0-.25.25.25.25,0,0,0,.25.25,4.68,4.68,0,0,1,2.85.67c.28.16.54-.27.26-.43Z"/>
                <path class="cls-4" d="M239.32,328.33a11.79,11.79,0,0,0-8.81,3.9,9.26,9.26,0,0,0-1.73,8.77c1.12,3.34,4.56,5.45,7.82,6.33a8.69,8.69,0,0,0,8.7-2.56,9.61,9.61,0,0,0,1.37-10.86c-.14-.29-.56,0-.43.25a9.14,9.14,0,0,1-.65,9.49,8.33,8.33,0,0,1-8,3.39c-3.08-.55-6.71-2.54-8-5.5a8.66,8.66,0,0,1,.88-8.35,11.08,11.08,0,0,1,8.92-4.36c.32,0,.32-.51,0-.5Z"/>
                <path class="cls-4" d="M246.91,326.35c-1.09-.76-2.32-1.58-3.63-1.41h-.07a11.18,11.18,0,0,0-9.45,4.95,8.6,8.6,0,0,0,.9,10.29A11.36,11.36,0,0,0,237,341.9a11.93,11.93,0,0,0,5.69,1.87,8.92,8.92,0,0,0,8.52-7A10.47,10.47,0,0,0,246.91,326.35Zm.74,4.86a2.51,2.51,0,0,1-2.26,2.19,2.3,2.3,0,0,1-2.33-2c-.06-1.14.74-2.37,1.88-2.29H245c.65-.05,1.42-.21,1.95.25A2.25,2.25,0,0,1,247.65,331.21Z"/>
                <path class="cls-4" d="M284.22,332.15a5.24,5.24,0,0,0-1.49,2.9.26.26,0,0,0,.25.25.25.25,0,0,0,.25-.25,4.63,4.63,0,0,1,1.34-2.54c.22-.24-.13-.59-.35-.36Z"/>
                <path class="cls-4" d="M282.5,334.92a11.73,11.73,0,0,0,1.67,9.52,9.16,9.16,0,0,0,8.26,3.76c3.48-.35,6.37-3.24,7.94-6.21a8.69,8.69,0,0,0-.58-9,9.61,9.61,0,0,0-10.09-3.8c-.32.06-.18.54.13.48a9.15,9.15,0,0,1,9,2.91,8.33,8.33,0,0,1,1.5,8.31,13,13,0,0,1-2.95,4.21,8,8,0,0,1-12.26-.18,11.08,11.08,0,0,1-2.17-9.83c.07-.31-.41-.44-.48-.13Z"/>
                <path class="cls-4" d="M282.43,327.09c-1,.86-2.11,1.86-2.25,3.18l0,.06a11.2,11.2,0,0,0,2.51,10.37,8.58,8.58,0,0,0,10.2,1.62,11.43,11.43,0,0,0,2.25-1.85,12,12,0,0,0,3.19-5.07,8.92,8.92,0,0,0-4.71-10A10.48,10.48,0,0,0,282.43,327.09Zm4.88.46a2.5,2.5,0,0,1,1.58,2.72,2.29,2.29,0,0,1-2.54,1.76c-1.12-.21-2.12-1.29-1.77-2.37l0-.06c.1-.64.14-1.43.71-1.83A2.21,2.21,0,0,1,287.31,327.55Z"/>
                <path class="cls-4" d="M270,326.23a5.08,5.08,0,0,0-2.66-1.75.25.25,0,0,0-.14.48,4.69,4.69,0,0,1,2.45,1.62.25.25,0,0,0,.35-.35Z"/>
                <path class="cls-4" d="M267.41,324.24a11.67,11.67,0,0,0-10.09.95,9.16,9.16,0,0,0-4.15,7.76c.08,3.46,2.75,6.68,5.57,8.48a8.71,8.71,0,0,0,8.72.28,9.59,9.59,0,0,0,5-9.66c0-.31-.52-.32-.49,0a9.21,9.21,0,0,1-3.65,8.59,8.38,8.38,0,0,1-8.41.85,12.29,12.29,0,0,1-3.72-3.05,9.29,9.29,0,0,1-2.37-4.44,8.54,8.54,0,0,1,3.16-7.92,11,11,0,0,1,10.34-1.36c.31.11.44-.37.14-.48Z"/>
                <path class="cls-4" d="M275.23,325c-.76-1.09-1.63-2.29-2.93-2.57l-.06,0a11.21,11.21,0,0,0-10.58,1.4c-2.91,2.3-4.24,6.6-2.68,10a11.27,11.27,0,0,0,1.61,2.43,12,12,0,0,0,4.7,3.71,8.92,8.92,0,0,0,10.41-3.63A10.48,10.48,0,0,0,275.23,325Zm-1,4.81a2.49,2.49,0,0,1-2.87,1.28,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.54-1.51l.06,0c.63.17,1.41.29,1.75.9A2.22,2.22,0,0,1,274.25,329.81Z"/>
                <path class="cls-4" d="M20,329.32a5.13,5.13,0,0,0-3.1-.74.25.25,0,0,0,0,.5,4.68,4.68,0,0,1,2.85.67.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M16.87,328.33a11.79,11.79,0,0,0-8.81,3.9A9.23,9.23,0,0,0,6.33,341c1.12,3.34,4.55,5.45,7.82,6.33a8.7,8.7,0,0,0,8.7-2.56,9.61,9.61,0,0,0,1.37-10.86c-.14-.29-.57,0-.43.25a9.16,9.16,0,0,1-.65,9.49,8.33,8.33,0,0,1-8,3.39c-3.09-.55-6.71-2.54-8-5.5a8.69,8.69,0,0,1,.87-8.35,11.1,11.1,0,0,1,8.93-4.36c.32,0,.32-.51,0-.5Z"/>
                <path class="cls-4" d="M24.46,326.35c-1.09-.76-2.32-1.58-3.64-1.41h-.06a11.18,11.18,0,0,0-9.45,4.95,8.6,8.6,0,0,0,.9,10.29,11.29,11.29,0,0,0,2.34,1.72,12,12,0,0,0,5.69,1.87,8.92,8.92,0,0,0,8.53-7A10.47,10.47,0,0,0,24.46,326.35Zm.73,4.86a2.49,2.49,0,0,1-2.25,2.19,2.29,2.29,0,0,1-2.33-2c-.07-1.14.74-2.37,1.88-2.29h.06c.65-.05,1.42-.21,1.95.25A2.24,2.24,0,0,1,25.19,331.21Z"/>
                <path class="cls-4" d="M61.77,332.15a5.24,5.24,0,0,0-1.49,2.9.25.25,0,0,0,.5,0,4.63,4.63,0,0,1,1.34-2.54c.22-.24-.13-.59-.35-.36Z"/>
                <path class="cls-4" d="M60.05,334.92a11.73,11.73,0,0,0,1.67,9.52A9.16,9.16,0,0,0,70,348.2c3.47-.35,6.37-3.24,7.94-6.21a8.71,8.71,0,0,0-.58-9,9.63,9.63,0,0,0-10.1-3.8c-.31.06-.18.54.14.48a9.15,9.15,0,0,1,9,2.91,8.35,8.35,0,0,1,1.5,8.31,13,13,0,0,1-3,4.21,8.52,8.52,0,0,1-4.15,2.52,8.62,8.62,0,0,1-8.1-2.7,11,11,0,0,1-2.17-9.83c.07-.31-.41-.44-.48-.13Z"/>
                <path class="cls-4" d="M60,327.09c-1,.86-2.1,1.86-2.25,3.18l0,.06a11.2,11.2,0,0,0,2.51,10.37,8.58,8.58,0,0,0,10.2,1.62,11.67,11.67,0,0,0,2.24-1.85,12.14,12.14,0,0,0,3.2-5.07,8.94,8.94,0,0,0-4.71-10A10.49,10.49,0,0,0,60,327.09Zm4.89.46a2.48,2.48,0,0,1,1.57,2.72A2.27,2.27,0,0,1,63.9,332a2,2,0,0,1-1.77-2.37l0-.06c.1-.64.14-1.43.71-1.83A2.21,2.21,0,0,1,64.86,327.55Z"/>
                <path class="cls-4" d="M47.53,326.23a5.07,5.07,0,0,0-2.67-1.75.25.25,0,0,0-.13.48,4.69,4.69,0,0,1,2.45,1.62.25.25,0,0,0,.35-.35Z"/>
                <path class="cls-4" d="M45,324.24a11.67,11.67,0,0,0-10.09.95A9.18,9.18,0,0,0,30.72,333c.08,3.46,2.75,6.68,5.56,8.48a8.71,8.71,0,0,0,8.72.28,9.59,9.59,0,0,0,5-9.66c0-.31-.53-.32-.5,0a9.21,9.21,0,0,1-3.64,8.59,8.38,8.38,0,0,1-8.41.85,12.05,12.05,0,0,1-3.72-3.05A9.13,9.13,0,0,1,31.32,334a8.54,8.54,0,0,1,3.16-7.92,11,11,0,0,1,10.34-1.36c.3.11.43-.37.13-.48Z"/>
                <path class="cls-4" d="M52.78,325c-.76-1.09-1.64-2.29-2.93-2.57l-.06,0a11.21,11.21,0,0,0-10.58,1.4,8.58,8.58,0,0,0-2.68,10,10.87,10.87,0,0,0,1.6,2.43,12.06,12.06,0,0,0,4.71,3.71,8.93,8.93,0,0,0,10.41-3.63A10.5,10.5,0,0,0,52.78,325Zm-1,4.81a2.49,2.49,0,0,1-2.87,1.28,2.3,2.3,0,0,1-1.49-2.71A2,2,0,0,1,50,326.87l.06,0c.63.17,1.41.29,1.74.9A2.19,2.19,0,0,1,51.8,329.81Z"/>
                <path class="cls-4" d="M94.3,329.32a5.13,5.13,0,0,0-3.1-.74.25.25,0,1,0,0,.5,4.7,4.7,0,0,1,2.85.67.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M91.2,328.33a11.78,11.78,0,0,0-8.81,3.9A9.23,9.23,0,0,0,80.66,341c1.12,3.34,4.55,5.45,7.82,6.33a8.7,8.7,0,0,0,8.7-2.56,9.61,9.61,0,0,0,1.37-10.86c-.14-.29-.57,0-.43.25a9.16,9.16,0,0,1-.65,9.49,8.33,8.33,0,0,1-8,3.39c-3.08-.55-6.7-2.54-8-5.5a8.69,8.69,0,0,1,.87-8.35,11.09,11.09,0,0,1,8.93-4.36c.32,0,.32-.51,0-.5Z"/>
                <path class="cls-4" d="M98.79,326.35c-1.09-.76-2.32-1.58-3.64-1.41h-.07a11.18,11.18,0,0,0-9.44,4.95,8.58,8.58,0,0,0,.9,10.29,11.29,11.29,0,0,0,2.34,1.72,12,12,0,0,0,5.69,1.87,8.92,8.92,0,0,0,8.53-7A10.47,10.47,0,0,0,98.79,326.35Zm.73,4.86a2.49,2.49,0,0,1-2.26,2.19,2.29,2.29,0,0,1-2.32-2c-.07-1.14.74-2.37,1.88-2.29h.06c.65-.05,1.42-.21,2,.25A2.24,2.24,0,0,1,99.52,331.21Z"/>
                <path class="cls-4" d="M136.1,332.15a5.18,5.18,0,0,0-1.49,2.9c0,.13.13.25.25.25a.26.26,0,0,0,.25-.25,4.63,4.63,0,0,1,1.34-2.54c.22-.24-.13-.59-.35-.36Z"/>
                <path class="cls-4" d="M134.38,334.92a11.77,11.77,0,0,0,1.66,9.52,9.19,9.19,0,0,0,8.27,3.76c3.47-.35,6.37-3.24,7.94-6.21a8.71,8.71,0,0,0-.58-9,9.63,9.63,0,0,0-10.1-3.8c-.31.06-.18.54.14.48a9.15,9.15,0,0,1,9,2.91,8.35,8.35,0,0,1,1.5,8.31,13,13,0,0,1-3,4.21,8.52,8.52,0,0,1-4.15,2.52,8.62,8.62,0,0,1-8.1-2.7,11,11,0,0,1-2.17-9.83c.07-.31-.41-.44-.48-.13Z"/>
                <path class="cls-4" d="M134.3,327.09c-1,.86-2.1,1.86-2.25,3.18l0,.06a11.2,11.2,0,0,0,2.51,10.37,8.58,8.58,0,0,0,10.2,1.62,11.67,11.67,0,0,0,2.24-1.85,12.14,12.14,0,0,0,3.2-5.07,9,9,0,0,0-4.71-10A10.49,10.49,0,0,0,134.3,327.09Zm4.89.46a2.48,2.48,0,0,1,1.57,2.72,2.28,2.28,0,0,1-2.54,1.76,2,2,0,0,1-1.76-2.37v-.06c.11-.64.14-1.43.72-1.83A2.21,2.21,0,0,1,139.19,327.55Z"/>
                <path class="cls-4" d="M121.86,326.23a5.11,5.11,0,0,0-2.67-1.75.25.25,0,0,0-.13.48,4.72,4.72,0,0,1,2.45,1.62.25.25,0,0,0,.35-.35Z"/>
                <path class="cls-4" d="M119.28,324.24a11.67,11.67,0,0,0-10.09.95,9.18,9.18,0,0,0-4.14,7.76c.08,3.46,2.75,6.68,5.56,8.48a8.71,8.71,0,0,0,8.72.28,9.59,9.59,0,0,0,5-9.66c0-.31-.53-.32-.5,0a9.21,9.21,0,0,1-3.64,8.59,8.38,8.38,0,0,1-8.41.85,12.05,12.05,0,0,1-3.72-3.05,9.23,9.23,0,0,1-2.38-4.44,8.59,8.59,0,0,1,3.16-7.92,11,11,0,0,1,10.35-1.36c.3.11.43-.37.13-.48Z"/>
                <path class="cls-4" d="M127.1,325c-.75-1.09-1.63-2.29-2.92-2.57l-.07,0a11.19,11.19,0,0,0-10.57,1.4,8.6,8.6,0,0,0-2.69,10,11.27,11.27,0,0,0,1.61,2.43,12.1,12.1,0,0,0,4.7,3.71,8.92,8.92,0,0,0,10.41-3.63A10.46,10.46,0,0,0,127.1,325Zm-1,4.81a2.49,2.49,0,0,1-2.87,1.28,2.29,2.29,0,0,1-1.49-2.71,2,2,0,0,1,2.55-1.51l.06,0c.63.17,1.41.29,1.74.9A2.22,2.22,0,0,1,126.13,329.81Z"/>
                <path class="cls-4" d="M461.19,304.72a5.23,5.23,0,0,0-3.11-.74.25.25,0,0,0-.24.25c0,.12.11.27.24.25a4.71,4.71,0,0,1,2.86.67.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M458.09,303.73a11.75,11.75,0,0,0-8.81,3.91,9.18,9.18,0,0,0-1.73,8.76c1.12,3.34,4.55,5.46,7.82,6.33a8.7,8.7,0,0,0,8.7-2.56,9.61,9.61,0,0,0,1.37-10.86c-.14-.29-.57,0-.43.25a9.16,9.16,0,0,1-.65,9.49,8.33,8.33,0,0,1-8.05,3.39c-3.08-.55-6.7-2.54-8-5.5a8.69,8.69,0,0,1,.87-8.35,11.09,11.09,0,0,1,8.93-4.36.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M465.68,301.76c-1.09-.77-2.32-1.59-3.64-1.41H462a11.18,11.18,0,0,0-9.44,5,8.58,8.58,0,0,0,.9,10.29,11.71,11.71,0,0,0,2.34,1.73,12.06,12.06,0,0,0,5.69,1.86,8.91,8.91,0,0,0,8.53-7A10.47,10.47,0,0,0,465.68,301.76Zm.73,4.85a2.51,2.51,0,0,1-2.26,2.19,2.29,2.29,0,0,1-2.32-2c-.07-1.14.74-2.37,1.87-2.29h.07c.64-.05,1.42-.21,1.94.25A2.22,2.22,0,0,1,466.41,306.61Z"/>
                <path class="cls-4" d="M503,307.56a5.14,5.14,0,0,0-1.48,2.89c0,.14.13.25.25.25a.25.25,0,0,0,.25-.25,4.51,4.51,0,0,1,1.34-2.54c.21-.24-.14-.59-.36-.35Z"/>
                <path class="cls-4" d="M501.27,310.32a11.8,11.8,0,0,0,1.66,9.53,9.23,9.23,0,0,0,8.27,3.76c3.47-.36,6.37-3.25,7.94-6.22a8.71,8.71,0,0,0-.58-9,9.63,9.63,0,0,0-10.1-3.8c-.31.06-.18.54.13.48a9.16,9.16,0,0,1,9,2.91,8.36,8.36,0,0,1,1.5,8.31,12.91,12.91,0,0,1-3,4.21A8.43,8.43,0,0,1,512,323a8.61,8.61,0,0,1-8.09-2.7,11,11,0,0,1-2.17-9.83c.07-.31-.41-.44-.48-.13Z"/>
                <path class="cls-4" d="M501.19,302.49c-1,.87-2.1,1.86-2.25,3.18l0,.06a11.2,11.2,0,0,0,2.51,10.37,8.58,8.58,0,0,0,10.2,1.62,11.31,11.31,0,0,0,2.24-1.85,12.08,12.08,0,0,0,3.2-5.07,9,9,0,0,0-4.71-10A10.49,10.49,0,0,0,501.19,302.49Zm4.89.46a2.48,2.48,0,0,1,1.57,2.72,2.27,2.27,0,0,1-2.54,1.76,2,2,0,0,1-1.76-2.37V305c.11-.64.14-1.43.72-1.83A2.21,2.21,0,0,1,506.08,303Z"/>
                <path class="cls-4" d="M488.75,301.63a5.11,5.11,0,0,0-2.67-1.75c-.31-.07-.44.41-.13.48A4.72,4.72,0,0,1,488.4,302a.24.24,0,0,0,.35,0,.26.26,0,0,0,0-.35Z"/>
                <path class="cls-4" d="M486.17,299.64a11.67,11.67,0,0,0-10.09.95,9.18,9.18,0,0,0-4.14,7.76c.08,3.46,2.75,6.68,5.56,8.48a8.71,8.71,0,0,0,8.72.28,9.59,9.59,0,0,0,5-9.65c0-.32-.53-.32-.5,0A9.18,9.18,0,0,1,487,316a8.39,8.39,0,0,1-8.42.85,12,12,0,0,1-3.71-3.05,9.23,9.23,0,0,1-2.38-4.44,8.59,8.59,0,0,1,3.16-7.92A11,11,0,0,1,486,300.12c.3.11.43-.37.13-.48Z"/>
                <path class="cls-4" d="M494,300.4c-.76-1.09-1.63-2.28-2.92-2.57l-.07,0a11.19,11.19,0,0,0-10.57,1.4c-2.92,2.3-4.25,6.6-2.69,10a11.27,11.27,0,0,0,1.61,2.43,12,12,0,0,0,4.7,3.71,8.92,8.92,0,0,0,10.41-3.63A10.46,10.46,0,0,0,494,300.4Zm-1,4.81a2.49,2.49,0,0,1-2.87,1.28,2.27,2.27,0,0,1-1.49-2.71,2,2,0,0,1,2.55-1.5l.06,0c.63.17,1.41.29,1.74.9A2.22,2.22,0,0,1,493,305.21Z"/>
                <path class="cls-4" d="M535.52,304.72a5.23,5.23,0,0,0-3.11-.74.25.25,0,0,0-.24.25c0,.12.11.27.24.25a4.71,4.71,0,0,1,2.86.67.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M532.42,303.73a11.75,11.75,0,0,0-8.81,3.91,9.18,9.18,0,0,0-1.73,8.76c1.12,3.34,4.55,5.46,7.82,6.33a8.7,8.7,0,0,0,8.7-2.56,9.61,9.61,0,0,0,1.37-10.86c-.14-.29-.57,0-.43.25a9.18,9.18,0,0,1-.65,9.49,8.33,8.33,0,0,1-8.05,3.39c-3.08-.55-6.7-2.54-8-5.5a8.66,8.66,0,0,1,.87-8.35,11.09,11.09,0,0,1,8.93-4.36.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M540,301.76c-1.08-.77-2.31-1.59-3.63-1.41h-.07a11.21,11.21,0,0,0-9.45,5,8.6,8.6,0,0,0,.91,10.29,11.43,11.43,0,0,0,2.34,1.73,12.06,12.06,0,0,0,5.69,1.86,8.92,8.92,0,0,0,8.53-7A10.46,10.46,0,0,0,540,301.76Zm.74,4.85a2.51,2.51,0,0,1-2.26,2.19,2.29,2.29,0,0,1-2.32-2c-.07-1.14.73-2.37,1.87-2.29h.07c.64-.05,1.42-.21,1.94.25A2.22,2.22,0,0,1,540.74,306.61Z"/>
                <path class="cls-4" d="M577.31,307.56a5.14,5.14,0,0,0-1.48,2.89.24.24,0,0,0,.25.25.25.25,0,0,0,.25-.25,4.51,4.51,0,0,1,1.34-2.54c.21-.24-.14-.59-.36-.35Z"/>
                <path class="cls-4" d="M575.6,310.32a11.8,11.8,0,0,0,1.66,9.53,9.23,9.23,0,0,0,8.27,3.76c3.47-.36,6.37-3.25,7.94-6.22a8.69,8.69,0,0,0-.59-9,9.61,9.61,0,0,0-10.09-3.8c-.31.06-.18.54.13.48a9.17,9.17,0,0,1,9,2.91,8.36,8.36,0,0,1,1.5,8.31,12.91,12.91,0,0,1-3,4.21,8.48,8.48,0,0,1-4.16,2.52,8.61,8.61,0,0,1-8.09-2.7,11,11,0,0,1-2.17-9.83c.07-.31-.41-.44-.48-.13Z"/>
                <path class="cls-4" d="M575.52,302.49c-1,.87-2.1,1.86-2.25,3.18l0,.06a11.2,11.2,0,0,0,2.51,10.37,8.58,8.58,0,0,0,10.2,1.62,11.31,11.31,0,0,0,2.24-1.85,12.08,12.08,0,0,0,3.2-5.07,8.94,8.94,0,0,0-4.72-10A10.48,10.48,0,0,0,575.52,302.49Zm4.88.46a2.48,2.48,0,0,1,1.58,2.72,2.27,2.27,0,0,1-2.54,1.76c-1.12-.21-2.12-1.28-1.76-2.37V305c.11-.64.14-1.43.71-1.83A2.22,2.22,0,0,1,580.4,303Z"/>
                <path class="cls-4" d="M563.08,301.63a5.11,5.11,0,0,0-2.67-1.75c-.31-.07-.44.41-.13.48a4.66,4.66,0,0,1,2.44,1.62.25.25,0,0,0,.36,0,.26.26,0,0,0,0-.35Z"/>
                <path class="cls-4" d="M560.5,299.64a11.67,11.67,0,0,0-10.09.95,9.18,9.18,0,0,0-4.14,7.76c.08,3.46,2.75,6.68,5.56,8.48a8.71,8.71,0,0,0,8.72.28,9.59,9.59,0,0,0,5-9.65c0-.32-.53-.32-.5,0a9.18,9.18,0,0,1-3.64,8.58,8.39,8.39,0,0,1-8.42.85,12,12,0,0,1-3.71-3.05,9.23,9.23,0,0,1-2.38-4.44,8.59,8.59,0,0,1,3.16-7.92,11,11,0,0,1,10.35-1.36c.3.11.43-.37.13-.48Z"/>
                <path class="cls-4" d="M568.32,300.4c-.76-1.09-1.63-2.28-2.92-2.57l-.07,0a11.19,11.19,0,0,0-10.57,1.4c-2.92,2.3-4.25,6.6-2.69,10a11.27,11.27,0,0,0,1.61,2.43,11.94,11.94,0,0,0,4.7,3.71,8.92,8.92,0,0,0,10.41-3.63A10.46,10.46,0,0,0,568.32,300.4Zm-1,4.81a2.5,2.5,0,0,1-2.88,1.28,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.55-1.5l.06,0c.62.17,1.4.29,1.74.9A2.22,2.22,0,0,1,567.35,305.21Z"/>
                <path class="cls-4" d="M313.07,304.72A5.23,5.23,0,0,0,310,304a.26.26,0,0,0-.25.25c0,.12.12.27.25.25a4.73,4.73,0,0,1,2.86.67.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M310,303.73a11.75,11.75,0,0,0-8.81,3.91,9.18,9.18,0,0,0-1.73,8.76c1.11,3.34,4.55,5.46,7.81,6.33a8.69,8.69,0,0,0,8.7-2.56,9.59,9.59,0,0,0,1.37-10.86c-.13-.29-.56,0-.43.25a9.14,9.14,0,0,1-.65,9.49,8.32,8.32,0,0,1-8,3.39c-3.08-.55-6.71-2.54-8-5.5a8.66,8.66,0,0,1,.87-8.35,11.09,11.09,0,0,1,8.93-4.36.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M317.55,301.76c-1.08-.77-2.32-1.59-3.63-1.41h-.07a11.19,11.19,0,0,0-9.45,5,8.6,8.6,0,0,0,.9,10.29,11.78,11.78,0,0,0,2.35,1.73,12,12,0,0,0,5.69,1.86,8.93,8.93,0,0,0,8.53-7A10.46,10.46,0,0,0,317.55,301.76Zm.74,4.85A2.51,2.51,0,0,1,316,308.8a2.28,2.28,0,0,1-2.32-2c-.07-1.14.73-2.37,1.87-2.29h.06c.65-.05,1.42-.21,1.95.25A2.22,2.22,0,0,1,318.29,306.61Z"/>
                <path class="cls-4" d="M354.86,307.56a5.14,5.14,0,0,0-1.48,2.89.24.24,0,0,0,.24.25.25.25,0,0,0,.25-.25,4.63,4.63,0,0,1,1.34-2.54c.22-.24-.13-.59-.35-.35Z"/>
                <path class="cls-4" d="M353.14,310.32a11.8,11.8,0,0,0,1.67,9.53,9.22,9.22,0,0,0,8.26,3.76c3.48-.36,6.37-3.25,7.95-6.22a8.71,8.71,0,0,0-.59-9,9.61,9.61,0,0,0-10.09-3.8c-.31.06-.18.54.13.48a9.17,9.17,0,0,1,9,2.91,8.36,8.36,0,0,1,1.5,8.31,12.91,12.91,0,0,1-3,4.21,8.48,8.48,0,0,1-4.16,2.52,8.64,8.64,0,0,1-8.1-2.7,11.08,11.08,0,0,1-2.17-9.83.25.25,0,0,0-.48-.13Z"/>
                <path class="cls-4" d="M353.07,302.49c-1,.87-2.1,1.86-2.25,3.18l0,.06a11.2,11.2,0,0,0,2.51,10.37c2.59,2.66,7,3.53,10.2,1.62a11.09,11.09,0,0,0,2.25-1.85,12,12,0,0,0,3.19-5.07,8.92,8.92,0,0,0-4.71-10A10.48,10.48,0,0,0,353.07,302.49ZM358,303a2.49,2.49,0,0,1,1.58,2.72,2.27,2.27,0,0,1-2.54,1.76c-1.12-.21-2.12-1.28-1.76-2.37V305c.1-.64.14-1.43.71-1.83A2.21,2.21,0,0,1,358,303Z"/>
                <path class="cls-4" d="M340.62,301.63a5,5,0,0,0-2.66-1.75c-.31-.07-.45.41-.14.48a4.65,4.65,0,0,1,2.45,1.62.24.24,0,0,0,.35,0,.25.25,0,0,0,0-.35Z"/>
                <path class="cls-4" d="M338.05,299.64a11.67,11.67,0,0,0-10.09.95,9.21,9.21,0,0,0-4.15,7.76c.08,3.46,2.76,6.68,5.57,8.48a8.71,8.71,0,0,0,8.72.28,9.59,9.59,0,0,0,5-9.65c0-.32-.53-.32-.5,0a9.17,9.17,0,0,1-3.65,8.58,8.38,8.38,0,0,1-8.41.85,12,12,0,0,1-3.71-3.05,9.23,9.23,0,0,1-2.38-4.44,8.57,8.57,0,0,1,3.16-7.92,11,11,0,0,1,10.35-1.36c.3.11.43-.37.13-.48Z"/>
                <path class="cls-4" d="M345.87,300.4c-.76-1.09-1.63-2.28-2.93-2.57l-.06,0a11.19,11.19,0,0,0-10.57,1.4c-2.92,2.3-4.25,6.6-2.69,10a11.27,11.27,0,0,0,1.61,2.43,11.94,11.94,0,0,0,4.7,3.71,8.92,8.92,0,0,0,10.41-3.63A10.48,10.48,0,0,0,345.87,300.4Zm-1,4.81a2.49,2.49,0,0,1-2.87,1.28,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.55-1.5l.05,0c.63.17,1.41.29,1.75.9A2.19,2.19,0,0,1,344.89,305.21Z"/>
                <path class="cls-4" d="M387.4,304.72a5.25,5.25,0,0,0-3.11-.74.26.26,0,0,0-.25.25c0,.12.12.27.25.25a4.73,4.73,0,0,1,2.86.67.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M384.29,303.73a11.78,11.78,0,0,0-8.81,3.91,9.21,9.21,0,0,0-1.72,8.76c1.11,3.34,4.55,5.46,7.81,6.33a8.69,8.69,0,0,0,8.7-2.56,9.61,9.61,0,0,0,1.37-10.86c-.13-.29-.56,0-.43.25a9.14,9.14,0,0,1-.65,9.49,8.32,8.32,0,0,1-8,3.39c-3.08-.55-6.71-2.54-8-5.5a8.66,8.66,0,0,1,.88-8.35,11.08,11.08,0,0,1,8.92-4.36.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M391.88,301.76c-1.09-.77-2.32-1.59-3.63-1.41h-.07a11.19,11.19,0,0,0-9.45,5,8.6,8.6,0,0,0,.9,10.29,11.78,11.78,0,0,0,2.35,1.73,12,12,0,0,0,5.69,1.86,8.93,8.93,0,0,0,8.53-7A10.47,10.47,0,0,0,391.88,301.76Zm.74,4.85a2.51,2.51,0,0,1-2.26,2.19,2.3,2.3,0,0,1-2.33-2c-.06-1.14.74-2.37,1.88-2.29H390c.65-.05,1.42-.21,1.95.25A2.22,2.22,0,0,1,392.62,306.61Z"/>
                <path class="cls-4" d="M429.19,307.56a5.14,5.14,0,0,0-1.48,2.89.24.24,0,0,0,.24.25.25.25,0,0,0,.25-.25,4.63,4.63,0,0,1,1.34-2.54c.22-.24-.13-.59-.35-.35Z"/>
                <path class="cls-4" d="M427.47,310.32a11.76,11.76,0,0,0,1.67,9.53,9.21,9.21,0,0,0,8.26,3.76c3.48-.36,6.37-3.25,8-6.22a8.71,8.71,0,0,0-.59-9,9.61,9.61,0,0,0-10.09-3.8c-.32.06-.18.54.13.48a9.15,9.15,0,0,1,9,2.91,8.34,8.34,0,0,1,1.5,8.31,12.74,12.74,0,0,1-2.95,4.21,8.52,8.52,0,0,1-4.16,2.52,8.64,8.64,0,0,1-8.1-2.7,11.08,11.08,0,0,1-2.17-9.83.25.25,0,0,0-.48-.13Z"/>
                <path class="cls-4" d="M427.4,302.49c-1,.87-2.1,1.86-2.25,3.18l0,.06a11.2,11.2,0,0,0,2.51,10.37,8.58,8.58,0,0,0,10.2,1.62,11.09,11.09,0,0,0,2.25-1.85,12,12,0,0,0,3.19-5.07,8.92,8.92,0,0,0-4.71-10A10.48,10.48,0,0,0,427.4,302.49Zm4.88.46a2.49,2.49,0,0,1,1.58,2.72,2.27,2.27,0,0,1-2.54,1.76,2,2,0,0,1-1.77-2.37l0-.06c.1-.64.14-1.43.71-1.83A2.21,2.21,0,0,1,432.28,303Z"/>
                <path class="cls-4" d="M415,301.63a5.08,5.08,0,0,0-2.66-1.75c-.32-.07-.45.41-.14.48A4.69,4.69,0,0,1,414.6,302a.24.24,0,0,0,.35,0,.25.25,0,0,0,0-.35Z"/>
                <path class="cls-4" d="M412.38,299.64a11.67,11.67,0,0,0-10.09.95,9.16,9.16,0,0,0-4.15,7.76c.08,3.46,2.76,6.68,5.57,8.48a8.71,8.71,0,0,0,8.72.28,9.57,9.57,0,0,0,4.95-9.65c0-.32-.52-.32-.49,0a9.17,9.17,0,0,1-3.65,8.58,8.38,8.38,0,0,1-8.41.85,12,12,0,0,1-3.71-3.05,9.23,9.23,0,0,1-2.38-4.44,8.54,8.54,0,0,1,3.16-7.92,11,11,0,0,1,10.34-1.36c.31.11.44-.37.14-.48Z"/>
                <path class="cls-4" d="M420.2,300.4c-.76-1.09-1.63-2.28-2.93-2.57l-.06,0a11.19,11.19,0,0,0-10.57,1.4c-2.92,2.3-4.25,6.6-2.69,10a11.27,11.27,0,0,0,1.61,2.43,11.94,11.94,0,0,0,4.7,3.71,8.92,8.92,0,0,0,10.41-3.63A10.48,10.48,0,0,0,420.2,300.4Zm-1,4.81a2.49,2.49,0,0,1-2.87,1.28,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.54-1.5l.06,0c.63.17,1.41.29,1.75.9A2.22,2.22,0,0,1,419.22,305.21Z"/>
                <path class="cls-4" d="M467.07,329.32a5.13,5.13,0,0,0-3.1-.74.25.25,0,0,0,0,.5,4.68,4.68,0,0,1,2.85.67.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M464,328.33a11.79,11.79,0,0,0-8.81,3.9,9.23,9.23,0,0,0-1.73,8.77c1.12,3.34,4.55,5.45,7.82,6.33a8.7,8.7,0,0,0,8.7-2.56,9.61,9.61,0,0,0,1.37-10.86c-.14-.29-.57,0-.43.25a9.16,9.16,0,0,1-.65,9.49,8.33,8.33,0,0,1-8,3.39c-3.09-.55-6.71-2.54-8-5.5a8.69,8.69,0,0,1,.87-8.35,11.1,11.1,0,0,1,8.93-4.36c.32,0,.32-.51,0-.5Z"/>
                <path class="cls-4" d="M471.56,326.35c-1.09-.76-2.32-1.58-3.64-1.41h-.06a11.18,11.18,0,0,0-9.45,4.95,8.6,8.6,0,0,0,.9,10.29,11.1,11.1,0,0,0,2.35,1.72,11.93,11.93,0,0,0,5.68,1.87,8.92,8.92,0,0,0,8.53-7A10.47,10.47,0,0,0,471.56,326.35Zm.73,4.86A2.49,2.49,0,0,1,470,333.4a2.29,2.29,0,0,1-2.33-2c-.07-1.14.74-2.37,1.88-2.29h.06c.65-.05,1.42-.21,2,.25A2.24,2.24,0,0,1,472.29,331.21Z"/>
                <path class="cls-4" d="M508.87,332.15a5.24,5.24,0,0,0-1.49,2.9.25.25,0,1,0,.5,0,4.63,4.63,0,0,1,1.34-2.54c.22-.24-.13-.59-.35-.36Z"/>
                <path class="cls-4" d="M507.15,334.92a11.73,11.73,0,0,0,1.67,9.52,9.16,9.16,0,0,0,8.26,3.76c3.47-.35,6.37-3.24,7.94-6.21a8.71,8.71,0,0,0-.58-9,9.63,9.63,0,0,0-10.1-3.8c-.31.06-.18.54.14.48a9.15,9.15,0,0,1,9,2.91,8.35,8.35,0,0,1,1.5,8.31,13,13,0,0,1-3,4.21,8.52,8.52,0,0,1-4.15,2.52,8.62,8.62,0,0,1-8.1-2.7,11,11,0,0,1-2.17-9.83c.07-.31-.41-.44-.48-.13Z"/>
                <path class="cls-4" d="M507.07,327.09c-1,.86-2.1,1.86-2.25,3.18l0,.06a11.2,11.2,0,0,0,2.51,10.37,8.58,8.58,0,0,0,10.2,1.62,11.67,11.67,0,0,0,2.24-1.85,12.14,12.14,0,0,0,3.2-5.07,8.94,8.94,0,0,0-4.71-10A10.5,10.5,0,0,0,507.07,327.09Zm4.89.46a2.5,2.5,0,0,1,1.58,2.72A2.29,2.29,0,0,1,511,332a2,2,0,0,1-1.77-2.37l0-.06c.1-.64.14-1.43.71-1.83A2.21,2.21,0,0,1,512,327.55Z"/>
                <path class="cls-4" d="M494.63,326.23a5.07,5.07,0,0,0-2.67-1.75.25.25,0,0,0-.13.48,4.69,4.69,0,0,1,2.45,1.62.25.25,0,0,0,.35-.35Z"/>
                <path class="cls-4" d="M492.05,324.24a11.67,11.67,0,0,0-10.09.95,9.18,9.18,0,0,0-4.14,7.76c.08,3.46,2.75,6.68,5.56,8.48a8.71,8.71,0,0,0,8.72.28,9.59,9.59,0,0,0,5-9.66c0-.31-.53-.32-.5,0a9.18,9.18,0,0,1-3.64,8.59,8.38,8.38,0,0,1-8.41.85,12.05,12.05,0,0,1-3.72-3.05,9.13,9.13,0,0,1-2.37-4.44,8.54,8.54,0,0,1,3.16-7.92,11,11,0,0,1,10.34-1.36c.3.11.43-.37.13-.48Z"/>
                <path class="cls-4" d="M499.88,325c-.76-1.09-1.64-2.29-2.93-2.57l-.06,0a11.21,11.21,0,0,0-10.58,1.4,8.58,8.58,0,0,0-2.68,10,10.87,10.87,0,0,0,1.6,2.43,12.06,12.06,0,0,0,4.71,3.71,8.93,8.93,0,0,0,10.41-3.63A10.5,10.5,0,0,0,499.88,325Zm-1,4.81a2.49,2.49,0,0,1-2.87,1.28,2.3,2.3,0,0,1-1.49-2.71,2,2,0,0,1,2.55-1.51l.06,0c.63.17,1.41.29,1.74.9A2.19,2.19,0,0,1,498.9,329.81Z"/>
                <path class="cls-4" d="M541.4,329.32a5.13,5.13,0,0,0-3.1-.74.25.25,0,0,0,0,.5,4.7,4.7,0,0,1,2.85.67.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M538.3,328.33a11.78,11.78,0,0,0-8.81,3.9,9.23,9.23,0,0,0-1.73,8.77c1.12,3.34,4.55,5.45,7.82,6.33a8.7,8.7,0,0,0,8.7-2.56,9.61,9.61,0,0,0,1.37-10.86c-.14-.29-.57,0-.43.25a9.16,9.16,0,0,1-.65,9.49,8.33,8.33,0,0,1-8.05,3.39c-3.08-.55-6.7-2.54-8-5.5a8.69,8.69,0,0,1,.87-8.35,11.09,11.09,0,0,1,8.93-4.36c.32,0,.32-.51,0-.5Z"/>
                <path class="cls-4" d="M545.89,326.35c-1.09-.76-2.32-1.58-3.64-1.41h-.07a11.18,11.18,0,0,0-9.44,4.95,8.6,8.6,0,0,0,.9,10.29A11.29,11.29,0,0,0,536,341.9a12,12,0,0,0,5.69,1.87,8.92,8.92,0,0,0,8.53-7A10.47,10.47,0,0,0,545.89,326.35Zm.73,4.86a2.49,2.49,0,0,1-2.26,2.19,2.29,2.29,0,0,1-2.32-2c-.07-1.14.74-2.37,1.88-2.29H544c.65-.05,1.42-.21,1.95.25A2.24,2.24,0,0,1,546.62,331.21Z"/>
                <path class="cls-4" d="M583.2,332.15a5.18,5.18,0,0,0-1.49,2.9.25.25,0,0,0,.5,0,4.63,4.63,0,0,1,1.34-2.54c.22-.24-.13-.59-.35-.36Z"/>
                <path class="cls-4" d="M581.48,334.92a11.73,11.73,0,0,0,1.67,9.52,9.16,9.16,0,0,0,8.26,3.76c3.47-.35,6.37-3.24,7.94-6.21a8.71,8.71,0,0,0-.58-9,9.63,9.63,0,0,0-10.1-3.8c-.31.06-.18.54.14.48a9.15,9.15,0,0,1,9,2.91,8.35,8.35,0,0,1,1.5,8.31,13,13,0,0,1-3,4.21,8.52,8.52,0,0,1-4.15,2.52,8.62,8.62,0,0,1-8.1-2.7,11,11,0,0,1-2.17-9.83c.07-.31-.41-.44-.48-.13Z"/>
                <path class="cls-4" d="M581.4,327.09c-1,.86-2.1,1.86-2.25,3.18l0,.06a11.2,11.2,0,0,0,2.51,10.37,8.58,8.58,0,0,0,10.2,1.62,11.67,11.67,0,0,0,2.24-1.85,12.14,12.14,0,0,0,3.2-5.07,9,9,0,0,0-4.71-10A10.49,10.49,0,0,0,581.4,327.09Zm4.89.46a2.48,2.48,0,0,1,1.57,2.72,2.27,2.27,0,0,1-2.53,1.76,2,2,0,0,1-1.77-2.37v-.06c.11-.64.15-1.43.72-1.83A2.21,2.21,0,0,1,586.29,327.55Z"/>
                <path class="cls-4" d="M569,326.23a5.11,5.11,0,0,0-2.67-1.75.25.25,0,0,0-.13.48,4.69,4.69,0,0,1,2.45,1.62.25.25,0,1,0,.35-.35Z"/>
                <path class="cls-4" d="M566.38,324.24a11.67,11.67,0,0,0-10.09.95,9.18,9.18,0,0,0-4.14,7.76c.08,3.46,2.75,6.68,5.56,8.48a8.71,8.71,0,0,0,8.72.28,9.59,9.59,0,0,0,5-9.66c0-.31-.53-.32-.5,0a9.21,9.21,0,0,1-3.64,8.59,8.38,8.38,0,0,1-8.41.85,12.05,12.05,0,0,1-3.72-3.05,9.23,9.23,0,0,1-2.38-4.44,8.59,8.59,0,0,1,3.16-7.92,11,11,0,0,1,10.35-1.36c.3.11.43-.37.13-.48Z"/>
                <path class="cls-4" d="M574.2,325c-.75-1.09-1.63-2.29-2.92-2.57l-.07,0a11.19,11.19,0,0,0-10.57,1.4,8.58,8.58,0,0,0-2.68,10,10.87,10.87,0,0,0,1.6,2.43,12.1,12.1,0,0,0,4.7,3.71,8.92,8.92,0,0,0,10.41-3.63A10.46,10.46,0,0,0,574.2,325Zm-1,4.81a2.49,2.49,0,0,1-2.87,1.28,2.3,2.3,0,0,1-1.49-2.71,2,2,0,0,1,2.55-1.51l.06,0c.63.17,1.41.29,1.74.9A2.22,2.22,0,0,1,573.23,329.81Z"/>
                <path class="cls-4" d="M319,329.32a5.16,5.16,0,0,0-3.11-.74.25.25,0,0,0,0,.5,4.71,4.71,0,0,1,2.86.67.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M315.85,328.33a11.78,11.78,0,0,0-8.81,3.9,9.21,9.21,0,0,0-1.73,8.77c1.12,3.34,4.55,5.45,7.81,6.33a8.71,8.71,0,0,0,8.71-2.56,9.62,9.62,0,0,0,1.37-10.86c-.14-.29-.57,0-.43.25a9.16,9.16,0,0,1-.66,9.49,8.31,8.31,0,0,1-8,3.39c-3.08-.55-6.7-2.54-8-5.5a8.66,8.66,0,0,1,.87-8.35,11.09,11.09,0,0,1,8.93-4.36c.32,0,.32-.51,0-.5Z"/>
                <path class="cls-4" d="M323.43,326.35c-1.08-.76-2.31-1.58-3.63-1.41h-.07a11.21,11.21,0,0,0-9.45,4.95,8.58,8.58,0,0,0,.91,10.29,11,11,0,0,0,2.34,1.72,11.93,11.93,0,0,0,5.69,1.87,8.93,8.93,0,0,0,8.53-7A10.48,10.48,0,0,0,323.43,326.35Zm.74,4.86a2.51,2.51,0,0,1-2.26,2.19,2.29,2.29,0,0,1-2.32-2c-.07-1.14.73-2.37,1.87-2.29h.07c.64-.05,1.42-.21,1.94.25A2.22,2.22,0,0,1,324.17,331.21Z"/>
                <path class="cls-4" d="M360.74,332.15a5.16,5.16,0,0,0-1.48,2.9c0,.13.12.25.25.25a.27.27,0,0,0,.25-.25,4.56,4.56,0,0,1,1.33-2.54c.22-.24-.13-.59-.35-.36Z"/>
                <path class="cls-4" d="M359,334.92a11.73,11.73,0,0,0,1.66,9.52A9.19,9.19,0,0,0,369,348.2c3.47-.35,6.37-3.24,7.94-6.21a8.69,8.69,0,0,0-.59-9,9.61,9.61,0,0,0-10.09-3.8c-.31.06-.18.54.13.48a9.17,9.17,0,0,1,9,2.91,8.35,8.35,0,0,1,1.5,8.31,13,13,0,0,1-3,4.21,8,8,0,0,1-12.26-.18,11,11,0,0,1-2.16-9.83c.07-.31-.41-.44-.48-.13Z"/>
                <path class="cls-4" d="M359,327.09c-1,.86-2.1,1.86-2.25,3.18l0,.06a11.17,11.17,0,0,0,2.51,10.37c2.59,2.66,7,3.53,10.19,1.62a11.43,11.43,0,0,0,2.25-1.85,12,12,0,0,0,3.19-5.07,8.92,8.92,0,0,0-4.71-10A10.48,10.48,0,0,0,359,327.09Zm4.88.46a2.48,2.48,0,0,1,1.58,2.72,2.28,2.28,0,0,1-2.54,1.76c-1.12-.21-2.12-1.29-1.76-2.37v-.06c.11-.64.14-1.43.71-1.83A2.22,2.22,0,0,1,363.83,327.55Z"/>
                <path class="cls-4" d="M346.51,326.23a5.11,5.11,0,0,0-2.67-1.75.25.25,0,0,0-.13.48,4.66,4.66,0,0,1,2.44,1.62.25.25,0,0,0,.36-.35Z"/>
                <path class="cls-4" d="M343.93,324.24a11.67,11.67,0,0,0-10.09.95A9.18,9.18,0,0,0,329.7,333c.07,3.46,2.75,6.68,5.56,8.48a8.71,8.71,0,0,0,8.72.28,9.59,9.59,0,0,0,5-9.66c0-.31-.53-.32-.5,0a9.21,9.21,0,0,1-3.64,8.59,8.39,8.39,0,0,1-8.42.85,12.13,12.13,0,0,1-3.71-3.05,9.23,9.23,0,0,1-2.38-4.44,8.59,8.59,0,0,1,3.16-7.92,11,11,0,0,1,10.35-1.36c.3.11.43-.37.13-.48Z"/>
                <path class="cls-4" d="M351.75,325c-.76-1.09-1.63-2.29-2.93-2.57l-.06,0a11.19,11.19,0,0,0-10.57,1.4c-2.92,2.3-4.25,6.6-2.69,10a11.27,11.27,0,0,0,1.61,2.43,12,12,0,0,0,4.7,3.71,8.92,8.92,0,0,0,10.41-3.63A10.48,10.48,0,0,0,351.75,325Zm-1,4.81a2.5,2.5,0,0,1-2.88,1.28,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.55-1.51l.06,0c.62.17,1.4.29,1.74.9A2.22,2.22,0,0,1,350.78,329.81Z"/>
                <path class="cls-4" d="M393.28,329.32a5.16,5.16,0,0,0-3.11-.74.25.25,0,0,0,0,.5,4.73,4.73,0,0,1,2.86.67.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M390.18,328.33a11.78,11.78,0,0,0-8.81,3.9,9.21,9.21,0,0,0-1.73,8.77c1.12,3.34,4.55,5.45,7.81,6.33a8.69,8.69,0,0,0,8.7-2.56,9.59,9.59,0,0,0,1.37-10.86c-.13-.29-.56,0-.42.25a9.16,9.16,0,0,1-.66,9.49,8.31,8.31,0,0,1-8,3.39c-3.08-.55-6.71-2.54-8-5.5a8.66,8.66,0,0,1,.87-8.35,11.09,11.09,0,0,1,8.93-4.36c.32,0,.32-.51,0-.5Z"/>
                <path class="cls-4" d="M397.76,326.35c-1.08-.76-2.32-1.58-3.63-1.41h-.07a11.19,11.19,0,0,0-9.45,4.95,8.58,8.58,0,0,0,.91,10.29,11,11,0,0,0,2.34,1.72,11.93,11.93,0,0,0,5.69,1.87,8.94,8.94,0,0,0,8.53-7A10.48,10.48,0,0,0,397.76,326.35Zm.74,4.86a2.51,2.51,0,0,1-2.26,2.19,2.28,2.28,0,0,1-2.32-2c-.07-1.14.73-2.37,1.87-2.29h.07c.64-.05,1.42-.21,1.94.25A2.22,2.22,0,0,1,398.5,331.21Z"/>
                <path class="cls-4" d="M435.07,332.15a5.16,5.16,0,0,0-1.48,2.9c0,.13.12.25.25.25a.25.25,0,0,0,.24-.25,4.63,4.63,0,0,1,1.34-2.54c.22-.24-.13-.59-.35-.36Z"/>
                <path class="cls-4" d="M433.35,334.92a11.77,11.77,0,0,0,1.67,9.52,9.19,9.19,0,0,0,8.27,3.76c3.47-.35,6.37-3.24,7.94-6.21a8.69,8.69,0,0,0-.59-9,9.61,9.61,0,0,0-10.09-3.8c-.31.06-.18.54.13.48a9.17,9.17,0,0,1,9,2.91,8.35,8.35,0,0,1,1.5,8.31,13,13,0,0,1-3,4.21,8,8,0,0,1-12.26-.18,11.08,11.08,0,0,1-2.17-9.83.25.25,0,0,0-.48-.13Z"/>
                <path class="cls-4" d="M433.28,327.09c-1,.86-2.1,1.86-2.25,3.18l0,.06a11.18,11.18,0,0,0,2.52,10.37c2.59,2.66,7,3.53,10.19,1.62a11.43,11.43,0,0,0,2.25-1.85,12,12,0,0,0,3.19-5.07,8.92,8.92,0,0,0-4.71-10A10.48,10.48,0,0,0,433.28,327.09Zm4.88.46a2.49,2.49,0,0,1,1.58,2.72A2.28,2.28,0,0,1,437.2,332c-1.12-.21-2.12-1.29-1.76-2.37v-.06c.1-.64.14-1.43.71-1.83A2.21,2.21,0,0,1,438.16,327.55Z"/>
                <path class="cls-4" d="M420.83,326.23a5,5,0,0,0-2.66-1.75.25.25,0,0,0-.13.48,4.66,4.66,0,0,1,2.44,1.62.25.25,0,0,0,.35,0,.24.24,0,0,0,0-.35Z"/>
                <path class="cls-4" d="M418.26,324.24a11.67,11.67,0,0,0-10.09.95A9.18,9.18,0,0,0,404,333c.07,3.46,2.75,6.68,5.56,8.48a8.71,8.71,0,0,0,8.72.28,9.59,9.59,0,0,0,5-9.66c0-.31-.53-.32-.5,0a9.21,9.21,0,0,1-3.64,8.59,8.39,8.39,0,0,1-8.42.85,12.13,12.13,0,0,1-3.71-3.05,9.23,9.23,0,0,1-2.38-4.44,8.59,8.59,0,0,1,3.16-7.92,11,11,0,0,1,10.35-1.36c.3.11.43-.37.13-.48Z"/>
                <path class="cls-4" d="M426.08,325c-.76-1.09-1.63-2.29-2.93-2.57l-.06,0a11.19,11.19,0,0,0-10.57,1.4c-2.92,2.3-4.25,6.6-2.69,10a11.27,11.27,0,0,0,1.61,2.43,12,12,0,0,0,4.7,3.71,8.92,8.92,0,0,0,10.41-3.63A10.48,10.48,0,0,0,426.08,325Zm-1,4.81a2.5,2.5,0,0,1-2.88,1.28,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.55-1.51l.06,0c.62.17,1.4.29,1.74.9A2.22,2.22,0,0,1,425.11,329.81Z"/>
                <path class="cls-4" d="M162.21,355.14a5.06,5.06,0,0,0-3.1-.74.25.25,0,0,0-.25.25.24.24,0,0,0,.25.24,4.69,4.69,0,0,1,2.85.68.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M159.11,354.15a11.79,11.79,0,0,0-8.81,3.9,9.23,9.23,0,0,0-1.73,8.77c1.12,3.34,4.55,5.45,7.82,6.33a8.7,8.7,0,0,0,8.7-2.56,9.63,9.63,0,0,0,1.37-10.87c-.14-.29-.57,0-.43.25a9.15,9.15,0,0,1-.65,9.5,8.3,8.3,0,0,1-8,3.38c-3.08-.55-6.71-2.54-8-5.49a8.68,8.68,0,0,1,.87-8.35,11.13,11.13,0,0,1,8.93-4.37.25.25,0,0,0,0-.49Z"/>
                <path class="cls-4" d="M166.7,352.17c-1.09-.76-2.32-1.58-3.63-1.41H163a11.19,11.19,0,0,0-9.45,5,8.58,8.58,0,0,0,.9,10.28,11.4,11.4,0,0,0,2.35,1.73,12,12,0,0,0,5.69,1.87,8.92,8.92,0,0,0,8.52-7A10.47,10.47,0,0,0,166.7,352.17Zm.73,4.85a2.47,2.47,0,0,1-2.25,2.19,2.28,2.28,0,0,1-2.33-2c-.07-1.14.74-2.37,1.88-2.29h.06a2.57,2.57,0,0,1,2,.24A2.22,2.22,0,0,1,167.43,357Z"/>
                <path class="cls-4" d="M204,358a5.24,5.24,0,0,0-1.49,2.9.25.25,0,0,0,.5,0,4.7,4.7,0,0,1,1.34-2.55c.22-.23-.13-.58-.35-.35Z"/>
                <path class="cls-4" d="M202.29,360.74a11.75,11.75,0,0,0,1.67,9.52,9.16,9.16,0,0,0,8.26,3.76c3.47-.35,6.37-3.24,7.94-6.21a8.71,8.71,0,0,0-.58-9,9.62,9.62,0,0,0-10.09-3.8c-.32.06-.19.54.13.48a9.13,9.13,0,0,1,9,2.91,8.34,8.34,0,0,1,1.5,8.3,12.81,12.81,0,0,1-3,4.22,8.43,8.43,0,0,1-4.15,2.51,8.65,8.65,0,0,1-8.1-2.69,11.06,11.06,0,0,1-2.17-9.83c.07-.31-.41-.45-.48-.13Z"/>
                <path class="cls-4" d="M202.21,352.9c-1,.87-2.1,1.87-2.25,3.18l0,.07a11.21,11.21,0,0,0,2.51,10.37,8.59,8.59,0,0,0,10.2,1.62,11.36,11.36,0,0,0,2.24-1.86,12.11,12.11,0,0,0,3.2-5.06,8.92,8.92,0,0,0-4.71-10A10.47,10.47,0,0,0,202.21,352.9Zm4.89.47a2.5,2.5,0,0,1,1.58,2.72,2.29,2.29,0,0,1-2.54,1.76,2,2,0,0,1-1.77-2.38l0-.06c.1-.64.14-1.43.71-1.83A2.24,2.24,0,0,1,207.1,353.37Z"/>
                <path class="cls-4" d="M189.77,352.05a5.14,5.14,0,0,0-2.67-1.76c-.31-.07-.44.41-.13.48a4.7,4.7,0,0,1,2.45,1.63.25.25,0,1,0,.35-.35Z"/>
                <path class="cls-4" d="M187.19,350.06a11.62,11.62,0,0,0-10.08.95,9.16,9.16,0,0,0-4.15,7.76c.08,3.46,2.75,6.68,5.56,8.47a8.69,8.69,0,0,0,8.72.29,9.6,9.6,0,0,0,5-9.66c0-.32-.52-.32-.49,0a9.21,9.21,0,0,1-3.65,8.59,8.4,8.4,0,0,1-8.41.85,12.17,12.17,0,0,1-3.72-3.05,9.17,9.17,0,0,1-2.37-4.44,8.56,8.56,0,0,1,3.16-7.93,11,11,0,0,1,10.34-1.35c.3.1.43-.38.13-.48Z"/>
                <path class="cls-4" d="M195,350.82c-.76-1.09-1.64-2.29-2.93-2.58l-.06,0a11.21,11.21,0,0,0-10.58,1.41,8.58,8.58,0,0,0-2.68,10,11.15,11.15,0,0,0,1.61,2.43,12.05,12.05,0,0,0,4.7,3.71,8.93,8.93,0,0,0,10.41-3.64A10.46,10.46,0,0,0,195,350.82Zm-1,4.8a2.49,2.49,0,0,1-2.87,1.29,2.3,2.3,0,0,1-1.49-2.71,2,2,0,0,1,2.55-1.51l.06,0c.63.17,1.41.29,1.75.9A2.22,2.22,0,0,1,194,355.62Z"/>
                <path class="cls-4" d="M236.54,355.14a5.06,5.06,0,0,0-3.1-.74.25.25,0,0,0-.25.25.24.24,0,0,0,.25.24,4.69,4.69,0,0,1,2.85.68.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M233.44,354.15a11.79,11.79,0,0,0-8.81,3.9,9.23,9.23,0,0,0-1.73,8.77c1.12,3.34,4.55,5.45,7.82,6.33a8.7,8.7,0,0,0,8.7-2.56,9.63,9.63,0,0,0,1.37-10.87c-.14-.29-.57,0-.43.25a9.18,9.18,0,0,1-.65,9.5,8.3,8.3,0,0,1-8,3.38c-3.08-.55-6.71-2.54-8-5.49a8.68,8.68,0,0,1,.87-8.35,11.11,11.11,0,0,1,8.93-4.37.25.25,0,0,0,0-.49Z"/>
                <path class="cls-4" d="M241,352.17c-1.09-.76-2.32-1.58-3.64-1.41h-.06a11.19,11.19,0,0,0-9.45,5,8.58,8.58,0,0,0,.9,10.28,11.14,11.14,0,0,0,2.35,1.73,12,12,0,0,0,5.68,1.87,8.92,8.92,0,0,0,8.53-7A10.47,10.47,0,0,0,241,352.17Zm.73,4.85a2.47,2.47,0,0,1-2.25,2.19,2.27,2.27,0,0,1-2.33-2c-.07-1.14.74-2.37,1.88-2.29h.06a2.57,2.57,0,0,1,1.95.24A2.25,2.25,0,0,1,241.76,357Z"/>
                <path class="cls-4" d="M278.34,358a5.24,5.24,0,0,0-1.49,2.9.25.25,0,0,0,.5,0,4.7,4.7,0,0,1,1.34-2.55c.22-.23-.13-.58-.35-.35Z"/>
                <path class="cls-4" d="M276.62,360.74a11.75,11.75,0,0,0,1.67,9.52,9.16,9.16,0,0,0,8.26,3.76c3.47-.35,6.37-3.24,7.94-6.21a8.71,8.71,0,0,0-.58-9,9.63,9.63,0,0,0-10.1-3.8c-.31.06-.18.54.14.48a9.13,9.13,0,0,1,9,2.91,8.34,8.34,0,0,1,1.5,8.3,13,13,0,0,1-3,4.22,8.43,8.43,0,0,1-4.15,2.51,8.65,8.65,0,0,1-8.1-2.69,11.06,11.06,0,0,1-2.17-9.83c.07-.31-.41-.45-.48-.13Z"/>
                <path class="cls-4" d="M276.54,352.9c-1,.87-2.1,1.87-2.25,3.18l0,.07a11.21,11.21,0,0,0,2.51,10.37,8.59,8.59,0,0,0,10.2,1.62,11.36,11.36,0,0,0,2.24-1.86,12.11,12.11,0,0,0,3.2-5.06,8.92,8.92,0,0,0-4.71-10A10.47,10.47,0,0,0,276.54,352.9Zm4.89.47a2.5,2.5,0,0,1,1.58,2.72,2.29,2.29,0,0,1-2.54,1.76,2,2,0,0,1-1.77-2.38l0-.06c.1-.64.14-1.43.71-1.83A2.24,2.24,0,0,1,281.43,353.37Z"/>
                <path class="cls-4" d="M264.1,352.05a5.14,5.14,0,0,0-2.67-1.76c-.31-.07-.44.41-.13.48a4.7,4.7,0,0,1,2.45,1.63.25.25,0,1,0,.35-.35Z"/>
                <path class="cls-4" d="M261.52,350.06a11.64,11.64,0,0,0-10.09.95,9.18,9.18,0,0,0-4.14,7.76c.08,3.46,2.75,6.68,5.56,8.47a8.69,8.69,0,0,0,8.72.29,9.6,9.6,0,0,0,5-9.66c0-.32-.53-.32-.5,0a9.18,9.18,0,0,1-3.64,8.59,8.4,8.4,0,0,1-8.41.85,12.05,12.05,0,0,1-3.72-3.05,9.17,9.17,0,0,1-2.37-4.44,8.56,8.56,0,0,1,3.16-7.93,11,11,0,0,1,10.34-1.35c.3.1.43-.38.13-.48Z"/>
                <path class="cls-4" d="M269.35,350.82c-.76-1.09-1.64-2.29-2.93-2.58l-.06,0a11.21,11.21,0,0,0-10.58,1.41,8.58,8.58,0,0,0-2.68,10,11.48,11.48,0,0,0,1.6,2.43,12.15,12.15,0,0,0,4.71,3.71,8.93,8.93,0,0,0,10.41-3.64A10.48,10.48,0,0,0,269.35,350.82Zm-1,4.8a2.49,2.49,0,0,1-2.87,1.29A2.3,2.3,0,0,1,264,354.2a2,2,0,0,1,2.55-1.51l.06,0c.63.17,1.41.29,1.74.9A2.19,2.19,0,0,1,268.37,355.62Z"/>
                <path class="cls-4" d="M14.09,355.14A5.09,5.09,0,0,0,11,354.4a.25.25,0,0,0-.24.25c0,.12.11.26.24.24a4.72,4.72,0,0,1,2.86.68.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M11,354.15a11.78,11.78,0,0,0-8.81,3.9,9.2,9.2,0,0,0-1.73,8.77c1.12,3.34,4.55,5.45,7.82,6.33a8.7,8.7,0,0,0,8.7-2.56,9.63,9.63,0,0,0,1.37-10.87c-.14-.29-.57,0-.43.25a9.2,9.2,0,0,1-.65,9.5,8.31,8.31,0,0,1-8,3.38c-3.08-.55-6.7-2.54-8-5.49A8.65,8.65,0,0,1,2.06,359,11.1,11.1,0,0,1,11,354.64a.25.25,0,0,0,0-.49Z"/>
                <path class="cls-4" d="M18.57,352.17c-1.08-.76-2.31-1.58-3.63-1.41h-.07a11.21,11.21,0,0,0-9.45,5A8.58,8.58,0,0,0,6.33,366a11.34,11.34,0,0,0,2.34,1.73,12.08,12.08,0,0,0,5.69,1.87,8.93,8.93,0,0,0,8.53-7A10.47,10.47,0,0,0,18.57,352.17Zm.74,4.85a2.49,2.49,0,0,1-2.26,2.19,2.27,2.27,0,0,1-2.32-2c-.07-1.14.73-2.37,1.87-2.29h.07c.64-.06,1.42-.21,1.94.24A2.23,2.23,0,0,1,19.31,357Z"/>
                <path class="cls-4" d="M55.88,358a5.16,5.16,0,0,0-1.48,2.9c0,.13.13.25.25.25a.26.26,0,0,0,.25-.25,4.57,4.57,0,0,1,1.34-2.55c.21-.23-.14-.58-.36-.35Z"/>
                <path class="cls-4" d="M54.17,360.74a11.78,11.78,0,0,0,1.66,9.52A9.19,9.19,0,0,0,64.1,374c3.47-.35,6.37-3.24,7.94-6.21a8.69,8.69,0,0,0-.59-9A9.61,9.61,0,0,0,61.36,355c-.31.06-.18.54.13.48a9.15,9.15,0,0,1,9,2.91,8.34,8.34,0,0,1,1.5,8.3,13,13,0,0,1-3,4.22,8.46,8.46,0,0,1-4.16,2.51,8.63,8.63,0,0,1-8.09-2.69,11,11,0,0,1-2.17-9.83c.07-.31-.41-.45-.48-.13Z"/>
                <path class="cls-4" d="M54.09,352.9c-1,.87-2.1,1.87-2.25,3.18l0,.07a11.21,11.21,0,0,0,2.51,10.37,8.59,8.59,0,0,0,10.2,1.62,11.36,11.36,0,0,0,2.24-1.86,12.11,12.11,0,0,0,3.2-5.06,8.94,8.94,0,0,0-4.72-10A10.46,10.46,0,0,0,54.09,352.9Zm4.89.47a2.48,2.48,0,0,1,1.57,2.72A2.28,2.28,0,0,1,58,357.85a2,2,0,0,1-1.76-2.38v-.06c.11-.64.14-1.43.71-1.83A2.26,2.26,0,0,1,59,353.37Z"/>
                <path class="cls-4" d="M41.65,352.05A5.18,5.18,0,0,0,39,350.29c-.31-.07-.44.41-.13.48a4.68,4.68,0,0,1,2.44,1.63.25.25,0,1,0,.36-.35Z"/>
                <path class="cls-4" d="M39.07,350.06A11.64,11.64,0,0,0,29,351a9.18,9.18,0,0,0-4.14,7.76c.08,3.46,2.75,6.68,5.56,8.47a8.69,8.69,0,0,0,8.72.29,9.6,9.6,0,0,0,5-9.66c0-.32-.53-.32-.5,0a9.21,9.21,0,0,1-3.64,8.59,8.42,8.42,0,0,1-8.42.85,12.13,12.13,0,0,1-3.71-3.05,9.27,9.27,0,0,1-2.38-4.44,8.61,8.61,0,0,1,3.16-7.93,11,11,0,0,1,10.35-1.35c.3.1.43-.38.13-.48Z"/>
                <path class="cls-4" d="M46.89,350.82c-.76-1.09-1.63-2.29-2.92-2.58l-.07,0a11.19,11.19,0,0,0-10.57,1.41,8.58,8.58,0,0,0-2.69,10A11.52,11.52,0,0,0,32.25,362a12.12,12.12,0,0,0,4.7,3.71,8.93,8.93,0,0,0,10.41-3.64A10.44,10.44,0,0,0,46.89,350.82Zm-1,4.8A2.5,2.5,0,0,1,43,356.91a2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.55-1.51l.06,0c.62.17,1.4.29,1.74.9A2.22,2.22,0,0,1,45.92,355.62Z"/>
                <path class="cls-4" d="M88.42,355.14a5.09,5.09,0,0,0-3.11-.74.25.25,0,0,0-.25.25.25.25,0,0,0,.25.24,4.72,4.72,0,0,1,2.86.68.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M85.32,354.15a11.78,11.78,0,0,0-8.81,3.9,9.2,9.2,0,0,0-1.73,8.77c1.12,3.34,4.55,5.45,7.81,6.33a8.71,8.71,0,0,0,8.71-2.56,9.64,9.64,0,0,0,1.37-10.87c-.14-.29-.57,0-.43.25a9.18,9.18,0,0,1-.66,9.5,8.28,8.28,0,0,1-8,3.38c-3.08-.55-6.7-2.54-8-5.49a8.65,8.65,0,0,1,.87-8.35,11.1,11.1,0,0,1,8.93-4.37.25.25,0,0,0,0-.49Z"/>
                <path class="cls-4" d="M92.9,352.17c-1.08-.76-2.31-1.58-3.63-1.41H89.2a11.21,11.21,0,0,0-9.45,5A8.57,8.57,0,0,0,80.66,366,11.08,11.08,0,0,0,83,367.72a12,12,0,0,0,5.69,1.87,8.93,8.93,0,0,0,8.53-7A10.47,10.47,0,0,0,92.9,352.17Zm.74,4.85a2.49,2.49,0,0,1-2.26,2.19,2.27,2.27,0,0,1-2.32-2c-.07-1.14.73-2.37,1.87-2.29H91c.64-.06,1.42-.21,1.94.24A2.23,2.23,0,0,1,93.64,357Z"/>
                <path class="cls-4" d="M130.21,358a5.16,5.16,0,0,0-1.48,2.9c0,.13.12.25.25.25a.27.27,0,0,0,.25-.25,4.62,4.62,0,0,1,1.33-2.55c.22-.23-.13-.58-.35-.35Z"/>
                <path class="cls-4" d="M128.5,360.74a11.74,11.74,0,0,0,1.66,9.52,9.19,9.19,0,0,0,8.27,3.76c3.47-.35,6.37-3.24,7.94-6.21a8.69,8.69,0,0,0-.59-9,9.61,9.61,0,0,0-10.09-3.8c-.31.06-.18.54.13.48a9.15,9.15,0,0,1,9,2.91,8.34,8.34,0,0,1,1.5,8.3,13,13,0,0,1-3,4.22,8.46,8.46,0,0,1-4.16,2.51,8.63,8.63,0,0,1-8.09-2.69,11,11,0,0,1-2.17-9.83c.07-.31-.41-.45-.48-.13Z"/>
                <path class="cls-4" d="M128.42,352.9c-1,.87-2.1,1.87-2.25,3.18l0,.07a11.18,11.18,0,0,0,2.51,10.37,8.58,8.58,0,0,0,10.19,1.62,11.14,11.14,0,0,0,2.25-1.86,12,12,0,0,0,3.19-5.06,8.92,8.92,0,0,0-4.71-10A10.46,10.46,0,0,0,128.42,352.9Zm4.88.47a2.48,2.48,0,0,1,1.58,2.72,2.28,2.28,0,0,1-2.54,1.76,2,2,0,0,1-1.76-2.38v-.06c.11-.64.14-1.43.71-1.83A2.25,2.25,0,0,1,133.3,353.37Z"/>
                <path class="cls-4" d="M116,352.05a5.18,5.18,0,0,0-2.67-1.76c-.31-.07-.45.41-.13.48a4.68,4.68,0,0,1,2.44,1.63.25.25,0,1,0,.36-.35Z"/>
                <path class="cls-4" d="M113.4,350.06a11.64,11.64,0,0,0-10.09.95,9.18,9.18,0,0,0-4.14,7.76c.08,3.46,2.75,6.68,5.56,8.47a8.69,8.69,0,0,0,8.72.29,9.6,9.6,0,0,0,5-9.66c0-.32-.53-.32-.5,0a9.21,9.21,0,0,1-3.64,8.59,8.42,8.42,0,0,1-8.42.85,12.13,12.13,0,0,1-3.71-3.05,9.27,9.27,0,0,1-2.38-4.44,8.61,8.61,0,0,1,3.16-7.93,11,11,0,0,1,10.35-1.35c.3.1.43-.38.13-.48Z"/>
                <path class="cls-4" d="M121.22,350.82c-.76-1.09-1.63-2.29-2.93-2.58l-.06,0a11.19,11.19,0,0,0-10.57,1.41,8.58,8.58,0,0,0-2.69,10,11.52,11.52,0,0,0,1.61,2.43,12.12,12.12,0,0,0,4.7,3.71,8.93,8.93,0,0,0,10.41-3.64A10.46,10.46,0,0,0,121.22,350.82Zm-1,4.8a2.51,2.51,0,0,1-2.88,1.29,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.55-1.51l.06,0c.62.17,1.4.29,1.74.9A2.22,2.22,0,0,1,120.25,355.62Z"/>
                <path class="cls-4" d="M168.1,379.74A5.18,5.18,0,0,0,165,379a.27.27,0,0,0-.25.25c0,.12.12.27.25.25a4.74,4.74,0,0,1,2.86.68c.28.15.53-.28.25-.43Z"/>
                <path class="cls-4" d="M165,378.74a11.77,11.77,0,0,0-8.81,3.91,9.21,9.21,0,0,0-1.72,8.76c1.11,3.35,4.55,5.46,7.81,6.34a8.71,8.71,0,0,0,8.7-2.56,9.63,9.63,0,0,0,1.37-10.87c-.14-.29-.56,0-.43.25a9.15,9.15,0,0,1-.65,9.5,8.29,8.29,0,0,1-8,3.38c-3.08-.55-6.71-2.54-8-5.5a8.64,8.64,0,0,1,.88-8.34,11.09,11.09,0,0,1,8.92-4.37.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M172.58,376.77c-1.09-.76-2.32-1.59-3.63-1.41h-.07a11.19,11.19,0,0,0-9.45,4.95,8.6,8.6,0,0,0,.9,10.29,11.4,11.4,0,0,0,2.35,1.73,12,12,0,0,0,5.69,1.87,8.94,8.94,0,0,0,8.53-7A10.49,10.49,0,0,0,172.58,376.77Zm.74,4.85a2.49,2.49,0,0,1-2.26,2.19,2.28,2.28,0,0,1-2.33-2c-.06-1.14.74-2.37,1.88-2.29h.06a2.57,2.57,0,0,1,2,.24A2.23,2.23,0,0,1,173.32,381.62Z"/>
                <path class="cls-4" d="M209.89,382.57a5.16,5.16,0,0,0-1.48,2.9c0,.13.12.24.24.24a.25.25,0,0,0,.25-.24,4.66,4.66,0,0,1,1.34-2.55c.22-.23-.13-.58-.35-.35Z"/>
                <path class="cls-4" d="M208.17,385.33a11.76,11.76,0,0,0,1.67,9.53,9.17,9.17,0,0,0,8.26,3.76c3.48-.36,6.37-3.24,8-6.21a8.71,8.71,0,0,0-.59-9,9.61,9.61,0,0,0-10.09-3.81c-.32.07-.18.55.13.48a9.17,9.17,0,0,1,9,2.91,8.34,8.34,0,0,1,1.5,8.31,12.64,12.64,0,0,1-2.95,4.21,8.45,8.45,0,0,1-4.16,2.52,8.64,8.64,0,0,1-8.1-2.7,11.06,11.06,0,0,1-2.17-9.82c.07-.31-.4-.45-.48-.14Z"/>
                <path class="cls-4" d="M208.1,377.5c-1,.87-2.1,1.87-2.25,3.18l0,.07a11.21,11.21,0,0,0,2.51,10.37,8.59,8.59,0,0,0,10.2,1.62,11.14,11.14,0,0,0,2.25-1.86,12,12,0,0,0,3.19-5.06,8.91,8.91,0,0,0-4.71-10A10.45,10.45,0,0,0,208.1,377.5ZM213,378a2.52,2.52,0,0,1,1.58,2.73,2.29,2.29,0,0,1-2.54,1.76,2,2,0,0,1-1.77-2.38l0-.06c.1-.64.14-1.43.71-1.83A2.24,2.24,0,0,1,213,378Z"/>
                <path class="cls-4" d="M195.65,376.65a5.1,5.1,0,0,0-2.66-1.76c-.32-.07-.45.41-.14.48A4.7,4.7,0,0,1,195.3,377a.25.25,0,1,0,.35-.35Z"/>
                <path class="cls-4" d="M193.08,374.66a11.64,11.64,0,0,0-10.09.94,9.19,9.19,0,0,0-4.15,7.77c.08,3.46,2.76,6.67,5.57,8.47a8.69,8.69,0,0,0,8.72.28,9.56,9.56,0,0,0,5-9.65c0-.32-.52-.32-.49,0a9.17,9.17,0,0,1-3.65,8.58,8.38,8.38,0,0,1-8.41.85,12.25,12.25,0,0,1-3.72-3,9.38,9.38,0,0,1-2.37-4.44,8.56,8.56,0,0,1,3.16-7.93,11,11,0,0,1,10.34-1.35c.31.1.44-.38.14-.48Z"/>
                <path class="cls-4" d="M200.9,375.41c-.76-1.08-1.63-2.28-2.93-2.57l-.06,0a11.21,11.21,0,0,0-10.58,1.41,8.59,8.59,0,0,0-2.68,10,11.52,11.52,0,0,0,1.61,2.43,12.12,12.12,0,0,0,4.7,3.71,8.94,8.94,0,0,0,10.41-3.64A10.48,10.48,0,0,0,200.9,375.41Zm-1,4.81a2.49,2.49,0,0,1-2.87,1.29,2.29,2.29,0,0,1-1.48-2.72,2,2,0,0,1,2.54-1.5l.06,0c.63.17,1.41.29,1.75.9A2.22,2.22,0,0,1,199.92,380.22Z"/>
                <path class="cls-4" d="M242.43,379.74a5.18,5.18,0,0,0-3.11-.75.27.27,0,0,0-.25.25c0,.12.11.27.25.25a4.69,4.69,0,0,1,2.85.68c.28.15.54-.28.26-.43Z"/>
                <path class="cls-4" d="M239.32,378.74a11.77,11.77,0,0,0-8.81,3.91,9.24,9.24,0,0,0-1.73,8.76c1.12,3.35,4.56,5.46,7.82,6.34a8.71,8.71,0,0,0,8.7-2.56,9.63,9.63,0,0,0,1.37-10.87c-.14-.29-.56,0-.43.25a9.15,9.15,0,0,1-.65,9.5,8.3,8.3,0,0,1-8,3.38c-3.08-.55-6.71-2.54-8-5.5a8.64,8.64,0,0,1,.88-8.34,11.09,11.09,0,0,1,8.92-4.37.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M246.91,376.77c-1.09-.76-2.32-1.59-3.63-1.41h-.07a11.18,11.18,0,0,0-9.45,4.95,8.6,8.6,0,0,0,.9,10.29,11.4,11.4,0,0,0,2.35,1.73,12,12,0,0,0,5.69,1.87,8.92,8.92,0,0,0,8.52-7A10.47,10.47,0,0,0,246.91,376.77Zm.74,4.85a2.49,2.49,0,0,1-2.26,2.19,2.28,2.28,0,0,1-2.33-2c-.06-1.14.74-2.37,1.88-2.29H245a2.57,2.57,0,0,1,1.95.24A2.26,2.26,0,0,1,247.65,381.62Z"/>
                <path class="cls-4" d="M284.22,382.57a5.24,5.24,0,0,0-1.49,2.9.25.25,0,0,0,.25.24.25.25,0,0,0,.25-.24,4.66,4.66,0,0,1,1.34-2.55c.22-.23-.13-.58-.35-.35Z"/>
                <path class="cls-4" d="M282.5,385.33a11.76,11.76,0,0,0,1.67,9.53,9.16,9.16,0,0,0,8.26,3.76c3.48-.36,6.37-3.24,7.94-6.21a8.69,8.69,0,0,0-.58-9,9.61,9.61,0,0,0-10.09-3.81c-.32.07-.18.55.13.48a9.17,9.17,0,0,1,9,2.91,8.34,8.34,0,0,1,1.5,8.31,12.78,12.78,0,0,1-2.95,4.21,8.45,8.45,0,0,1-4.16,2.52,8.64,8.64,0,0,1-8.1-2.7,11.06,11.06,0,0,1-2.17-9.82c.07-.31-.41-.45-.48-.14Z"/>
                <path class="cls-4" d="M282.43,377.5c-1,.87-2.11,1.87-2.25,3.18l0,.07a11.21,11.21,0,0,0,2.51,10.37,8.59,8.59,0,0,0,10.2,1.62,11.14,11.14,0,0,0,2.25-1.86,12,12,0,0,0,3.19-5.06,8.91,8.91,0,0,0-4.71-10A10.45,10.45,0,0,0,282.43,377.5Zm4.88.46a2.52,2.52,0,0,1,1.58,2.73,2.29,2.29,0,0,1-2.54,1.76,2,2,0,0,1-1.77-2.38l0-.06c.1-.64.14-1.43.71-1.83A2.24,2.24,0,0,1,287.31,378Z"/>
                <path class="cls-4" d="M270,376.65a5.1,5.1,0,0,0-2.66-1.76c-.32-.07-.45.41-.14.48a4.7,4.7,0,0,1,2.45,1.63.25.25,0,0,0,.35-.35Z"/>
                <path class="cls-4" d="M267.41,374.66a11.64,11.64,0,0,0-10.09.94,9.19,9.19,0,0,0-4.15,7.77c.08,3.46,2.75,6.67,5.57,8.47a8.69,8.69,0,0,0,8.72.28,9.57,9.57,0,0,0,5-9.65c0-.32-.52-.32-.49,0a9.19,9.19,0,0,1-3.65,8.58,8.38,8.38,0,0,1-8.41.85,12.25,12.25,0,0,1-3.72-3,9.38,9.38,0,0,1-2.37-4.44,8.56,8.56,0,0,1,3.16-7.93,11,11,0,0,1,10.34-1.35c.31.1.44-.38.14-.48Z"/>
                <path class="cls-4" d="M275.23,375.41c-.76-1.08-1.63-2.28-2.93-2.57l-.06,0a11.21,11.21,0,0,0-10.58,1.41,8.59,8.59,0,0,0-2.68,10,11.52,11.52,0,0,0,1.61,2.43,12.05,12.05,0,0,0,4.7,3.71,8.94,8.94,0,0,0,10.41-3.64A10.48,10.48,0,0,0,275.23,375.41Zm-1,4.81a2.49,2.49,0,0,1-2.87,1.29,2.29,2.29,0,0,1-1.48-2.72,2,2,0,0,1,2.54-1.5l.06,0c.63.17,1.41.29,1.75.9A2.22,2.22,0,0,1,274.25,380.22Z"/>
                <path class="cls-4" d="M20,379.74a5.14,5.14,0,0,0-3.1-.75.26.26,0,0,0-.25.25c0,.12.11.27.25.25a4.69,4.69,0,0,1,2.85.68c.28.15.53-.28.25-.43Z"/>
                <path class="cls-4" d="M16.87,378.74a11.77,11.77,0,0,0-8.81,3.91,9.21,9.21,0,0,0-1.73,8.76c1.12,3.35,4.55,5.46,7.82,6.34a8.72,8.72,0,0,0,8.7-2.56,9.63,9.63,0,0,0,1.37-10.87c-.14-.29-.57,0-.43.25a9.18,9.18,0,0,1-.65,9.5,8.3,8.3,0,0,1-8,3.38c-3.09-.55-6.71-2.54-8-5.5a8.66,8.66,0,0,1,.87-8.34,11.11,11.11,0,0,1,8.93-4.37.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M24.46,376.77c-1.09-.76-2.32-1.59-3.64-1.41h-.06a11.18,11.18,0,0,0-9.45,4.95,8.6,8.6,0,0,0,.9,10.29,11.34,11.34,0,0,0,2.34,1.73,12.11,12.11,0,0,0,5.69,1.87,8.92,8.92,0,0,0,8.53-7A10.47,10.47,0,0,0,24.46,376.77Zm.73,4.85a2.47,2.47,0,0,1-2.25,2.19,2.27,2.27,0,0,1-2.33-2c-.07-1.14.74-2.37,1.88-2.29h.06a2.57,2.57,0,0,1,1.95.24A2.25,2.25,0,0,1,25.19,381.62Z"/>
                <path class="cls-4" d="M61.77,382.57a5.24,5.24,0,0,0-1.49,2.9.25.25,0,0,0,.5,0,4.66,4.66,0,0,1,1.34-2.55c.22-.23-.13-.58-.35-.35Z"/>
                <path class="cls-4" d="M60.05,385.33a11.76,11.76,0,0,0,1.67,9.53A9.16,9.16,0,0,0,70,398.62c3.47-.36,6.37-3.24,7.94-6.21a8.71,8.71,0,0,0-.58-9,9.63,9.63,0,0,0-10.1-3.81c-.31.07-.18.55.14.48a9.17,9.17,0,0,1,9,2.91,8.36,8.36,0,0,1,1.5,8.31,12.81,12.81,0,0,1-3,4.21A8.38,8.38,0,0,1,70.8,398a8.62,8.62,0,0,1-8.1-2.7,11,11,0,0,1-2.17-9.82c.07-.31-.41-.45-.48-.14Z"/>
                <path class="cls-4" d="M60,377.5c-1,.87-2.1,1.87-2.25,3.18l0,.07a11.21,11.21,0,0,0,2.51,10.37,8.59,8.59,0,0,0,10.2,1.62,11.36,11.36,0,0,0,2.24-1.86,12.11,12.11,0,0,0,3.2-5.06,8.93,8.93,0,0,0-4.71-10A10.47,10.47,0,0,0,60,377.5Zm4.89.46a2.5,2.5,0,0,1,1.57,2.73,2.27,2.27,0,0,1-2.53,1.76,2,2,0,0,1-1.77-2.38l0-.06c.1-.64.14-1.43.71-1.83A2.24,2.24,0,0,1,64.86,378Z"/>
                <path class="cls-4" d="M47.53,376.65a5.08,5.08,0,0,0-2.67-1.76c-.31-.07-.44.41-.13.48A4.7,4.7,0,0,1,47.18,377a.25.25,0,1,0,.35-.35Z"/>
                <path class="cls-4" d="M45,374.66a11.64,11.64,0,0,0-10.09.94,9.21,9.21,0,0,0-4.14,7.77c.08,3.46,2.75,6.67,5.56,8.47a8.69,8.69,0,0,0,8.72.28,9.58,9.58,0,0,0,5-9.65c0-.32-.53-.32-.5,0a9.18,9.18,0,0,1-3.64,8.58,8.38,8.38,0,0,1-8.41.85,12,12,0,0,1-3.72-3,9.21,9.21,0,0,1-2.37-4.44,8.56,8.56,0,0,1,3.16-7.93,11,11,0,0,1,10.34-1.35c.3.1.43-.38.13-.48Z"/>
                <path class="cls-4" d="M52.78,375.41c-.76-1.08-1.64-2.28-2.93-2.57l-.06,0a11.21,11.21,0,0,0-10.58,1.41,8.58,8.58,0,0,0-2.68,10,11.1,11.1,0,0,0,1.6,2.43,12.15,12.15,0,0,0,4.71,3.71,8.94,8.94,0,0,0,10.41-3.64A10.5,10.5,0,0,0,52.78,375.41Zm-1,4.81a2.49,2.49,0,0,1-2.87,1.29,2.3,2.3,0,0,1-1.49-2.72,2,2,0,0,1,2.55-1.5l.06,0c.63.17,1.41.29,1.74.9A2.19,2.19,0,0,1,51.8,380.22Z"/>
                <path class="cls-4" d="M94.3,379.74a5.14,5.14,0,0,0-3.1-.75.26.26,0,0,0-.25.25c0,.12.11.27.25.25a4.71,4.71,0,0,1,2.85.68c.28.15.53-.28.25-.43Z"/>
                <path class="cls-4" d="M91.2,378.74a11.75,11.75,0,0,0-8.81,3.91,9.21,9.21,0,0,0-1.73,8.76c1.12,3.35,4.55,5.46,7.82,6.34a8.72,8.72,0,0,0,8.7-2.56,9.63,9.63,0,0,0,1.37-10.87c-.14-.29-.57,0-.43.25a9.18,9.18,0,0,1-.65,9.5,8.31,8.31,0,0,1-8,3.38c-3.08-.55-6.7-2.54-8-5.5a8.66,8.66,0,0,1,.87-8.34,11.1,11.1,0,0,1,8.93-4.37.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M98.79,376.77c-1.09-.76-2.32-1.59-3.64-1.41h-.07a11.18,11.18,0,0,0-9.44,4.95,8.58,8.58,0,0,0,.9,10.29,11.34,11.34,0,0,0,2.34,1.73,12.11,12.11,0,0,0,5.69,1.87,8.92,8.92,0,0,0,8.53-7A10.47,10.47,0,0,0,98.79,376.77Zm.73,4.85a2.47,2.47,0,0,1-2.26,2.19,2.27,2.27,0,0,1-2.32-2c-.07-1.14.74-2.37,1.88-2.29h.06a2.57,2.57,0,0,1,2,.24A2.25,2.25,0,0,1,99.52,381.62Z"/>
                <path class="cls-4" d="M136.1,382.57a5.18,5.18,0,0,0-1.49,2.9c0,.13.13.24.25.24a.25.25,0,0,0,.25-.24,4.66,4.66,0,0,1,1.34-2.55c.22-.23-.13-.58-.35-.35Z"/>
                <path class="cls-4" d="M134.38,385.33a11.8,11.8,0,0,0,1.66,9.53,9.19,9.19,0,0,0,8.27,3.76c3.47-.36,6.37-3.24,7.94-6.21a8.71,8.71,0,0,0-.58-9,9.63,9.63,0,0,0-10.1-3.81c-.31.07-.18.55.14.48a9.17,9.17,0,0,1,9,2.91,8.36,8.36,0,0,1,1.5,8.31,12.81,12.81,0,0,1-3,4.21,8.38,8.38,0,0,1-4.15,2.52,8.62,8.62,0,0,1-8.1-2.7,11,11,0,0,1-2.17-9.82c.07-.31-.41-.45-.48-.14Z"/>
                <path class="cls-4" d="M134.3,377.5c-1,.87-2.1,1.87-2.25,3.18l0,.07a11.21,11.21,0,0,0,2.51,10.37,8.59,8.59,0,0,0,10.2,1.62,11.36,11.36,0,0,0,2.24-1.86,12.11,12.11,0,0,0,3.2-5.06,8.94,8.94,0,0,0-4.71-10A10.47,10.47,0,0,0,134.3,377.5Zm4.89.46a2.5,2.5,0,0,1,1.57,2.73,2.28,2.28,0,0,1-2.54,1.76,2,2,0,0,1-1.76-2.38V380c.11-.64.14-1.43.72-1.83A2.24,2.24,0,0,1,139.19,378Z"/>
                <path class="cls-4" d="M121.86,376.65a5.12,5.12,0,0,0-2.67-1.76c-.31-.07-.44.41-.13.48a4.74,4.74,0,0,1,2.45,1.63.25.25,0,0,0,.35-.35Z"/>
                <path class="cls-4" d="M119.28,374.66a11.64,11.64,0,0,0-10.09.94,9.21,9.21,0,0,0-4.14,7.77c.08,3.46,2.75,6.67,5.56,8.47a8.69,8.69,0,0,0,8.72.28,9.58,9.58,0,0,0,5-9.65c0-.32-.53-.32-.5,0a9.18,9.18,0,0,1-3.64,8.58,8.38,8.38,0,0,1-8.41.85,12,12,0,0,1-3.72-3,9.32,9.32,0,0,1-2.38-4.44,8.61,8.61,0,0,1,3.16-7.93,11,11,0,0,1,10.35-1.35c.3.1.43-.38.13-.48Z"/>
                <path class="cls-4" d="M127.1,375.41c-.75-1.08-1.63-2.28-2.92-2.57l-.07,0a11.19,11.19,0,0,0-10.57,1.41,8.59,8.59,0,0,0-2.69,10,11.52,11.52,0,0,0,1.61,2.43,12.2,12.2,0,0,0,4.7,3.71,8.94,8.94,0,0,0,10.41-3.64A10.46,10.46,0,0,0,127.1,375.41Zm-1,4.81a2.49,2.49,0,0,1-2.87,1.29,2.29,2.29,0,0,1-1.49-2.72,2,2,0,0,1,2.55-1.5l.06,0c.63.17,1.41.29,1.74.9A2.22,2.22,0,0,1,126.13,380.22Z"/>
                <path class="cls-4" d="M461.19,355.14a5.09,5.09,0,0,0-3.11-.74.25.25,0,0,0-.24.25c0,.12.11.26.24.24a4.72,4.72,0,0,1,2.86.68.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M458.09,354.15a11.78,11.78,0,0,0-8.81,3.9,9.2,9.2,0,0,0-1.73,8.77c1.12,3.34,4.55,5.45,7.82,6.33a8.7,8.7,0,0,0,8.7-2.56,9.63,9.63,0,0,0,1.37-10.87c-.14-.29-.57,0-.43.25a9.18,9.18,0,0,1-.65,9.5,8.31,8.31,0,0,1-8.05,3.38c-3.08-.55-6.7-2.54-8-5.49a8.68,8.68,0,0,1,.87-8.35,11.1,11.1,0,0,1,8.93-4.37.25.25,0,0,0,0-.49Z"/>
                <path class="cls-4" d="M465.68,352.17c-1.09-.76-2.32-1.58-3.64-1.41H462a11.18,11.18,0,0,0-9.44,5,8.57,8.57,0,0,0,.9,10.28,11.34,11.34,0,0,0,2.34,1.73,12.08,12.08,0,0,0,5.69,1.87,8.92,8.92,0,0,0,8.53-7A10.48,10.48,0,0,0,465.68,352.17Zm.73,4.85a2.49,2.49,0,0,1-2.26,2.19,2.27,2.27,0,0,1-2.32-2c-.07-1.14.74-2.37,1.87-2.29h.07c.64-.06,1.42-.21,1.94.24A2.23,2.23,0,0,1,466.41,357Z"/>
                <path class="cls-4" d="M503,358a5.16,5.16,0,0,0-1.48,2.9c0,.13.13.25.25.25a.26.26,0,0,0,.25-.25,4.57,4.57,0,0,1,1.34-2.55c.21-.23-.14-.58-.36-.35Z"/>
                <path class="cls-4" d="M501.27,360.74a11.78,11.78,0,0,0,1.66,9.52A9.19,9.19,0,0,0,511.2,374c3.47-.35,6.37-3.24,7.94-6.21a8.71,8.71,0,0,0-.58-9,9.63,9.63,0,0,0-10.1-3.8c-.31.06-.18.54.13.48a9.14,9.14,0,0,1,9,2.91,8.34,8.34,0,0,1,1.5,8.3,13,13,0,0,1-3,4.22,8.42,8.42,0,0,1-4.16,2.51,8.63,8.63,0,0,1-8.09-2.69,11,11,0,0,1-2.17-9.83c.07-.31-.41-.45-.48-.13Z"/>
                <path class="cls-4" d="M501.19,352.9c-1,.87-2.1,1.87-2.25,3.18l0,.07a11.21,11.21,0,0,0,2.51,10.37,8.59,8.59,0,0,0,10.2,1.62,11.36,11.36,0,0,0,2.24-1.86,12.11,12.11,0,0,0,3.2-5.06,8.94,8.94,0,0,0-4.71-10A10.47,10.47,0,0,0,501.19,352.9Zm4.89.47a2.48,2.48,0,0,1,1.57,2.72,2.28,2.28,0,0,1-2.54,1.76,2,2,0,0,1-1.76-2.38v-.06c.11-.64.14-1.43.72-1.83A2.24,2.24,0,0,1,506.08,353.37Z"/>
                <path class="cls-4" d="M488.75,352.05a5.18,5.18,0,0,0-2.67-1.76c-.31-.07-.44.41-.13.48a4.74,4.74,0,0,1,2.45,1.63.25.25,0,0,0,.35,0,.26.26,0,0,0,0-.35Z"/>
                <path class="cls-4" d="M486.17,350.06a11.64,11.64,0,0,0-10.09.95,9.18,9.18,0,0,0-4.14,7.76c.08,3.46,2.75,6.68,5.56,8.47a8.69,8.69,0,0,0,8.72.29,9.6,9.6,0,0,0,5-9.66c0-.32-.53-.32-.5,0a9.21,9.21,0,0,1-3.64,8.59,8.42,8.42,0,0,1-8.42.85,12.13,12.13,0,0,1-3.71-3.05,9.27,9.27,0,0,1-2.38-4.44,8.61,8.61,0,0,1,3.16-7.93A11,11,0,0,1,486,350.54c.3.1.43-.38.13-.48Z"/>
                <path class="cls-4" d="M494,350.82c-.76-1.09-1.63-2.29-2.92-2.58l-.07,0a11.19,11.19,0,0,0-10.57,1.41,8.58,8.58,0,0,0-2.69,10,11.52,11.52,0,0,0,1.61,2.43,12.2,12.2,0,0,0,4.7,3.71,8.93,8.93,0,0,0,10.41-3.64A10.44,10.44,0,0,0,494,350.82Zm-1,4.8a2.49,2.49,0,0,1-2.87,1.29,2.28,2.28,0,0,1-1.49-2.71,2,2,0,0,1,2.55-1.51l.06,0c.63.17,1.41.29,1.74.9A2.22,2.22,0,0,1,493,355.62Z"/>
                <path class="cls-4" d="M535.52,355.14a5.09,5.09,0,0,0-3.11-.74.25.25,0,0,0-.24.25c0,.12.11.26.24.24a4.72,4.72,0,0,1,2.86.68.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M532.42,354.15a11.78,11.78,0,0,0-8.81,3.9,9.2,9.2,0,0,0-1.73,8.77c1.12,3.34,4.55,5.45,7.82,6.33a8.7,8.7,0,0,0,8.7-2.56,9.63,9.63,0,0,0,1.37-10.87c-.14-.29-.57,0-.43.25a9.2,9.2,0,0,1-.65,9.5,8.31,8.31,0,0,1-8.05,3.38c-3.08-.55-6.7-2.54-8-5.49a8.65,8.65,0,0,1,.87-8.35,11.1,11.1,0,0,1,8.93-4.37.25.25,0,0,0,0-.49Z"/>
                <path class="cls-4" d="M540,352.17c-1.08-.76-2.31-1.58-3.63-1.41h-.07a11.21,11.21,0,0,0-9.45,5,8.58,8.58,0,0,0,.91,10.28,11.08,11.08,0,0,0,2.34,1.73,12.08,12.08,0,0,0,5.69,1.87,8.93,8.93,0,0,0,8.53-7A10.47,10.47,0,0,0,540,352.17Zm.74,4.85a2.49,2.49,0,0,1-2.26,2.19,2.27,2.27,0,0,1-2.32-2c-.07-1.14.73-2.37,1.87-2.29h.07c.64-.06,1.42-.21,1.94.24A2.23,2.23,0,0,1,540.74,357Z"/>
                <path class="cls-4" d="M577.31,358a5.16,5.16,0,0,0-1.48,2.9c0,.13.12.25.25.25a.26.26,0,0,0,.25-.25,4.57,4.57,0,0,1,1.34-2.55c.21-.23-.14-.58-.36-.35Z"/>
                <path class="cls-4" d="M575.6,360.74a11.78,11.78,0,0,0,1.66,9.52,9.19,9.19,0,0,0,8.27,3.76c3.47-.35,6.37-3.24,7.94-6.21a8.69,8.69,0,0,0-.59-9,9.61,9.61,0,0,0-10.09-3.8c-.31.06-.18.54.13.48a9.15,9.15,0,0,1,9,2.91,8.34,8.34,0,0,1,1.5,8.3,13,13,0,0,1-3,4.22,8.46,8.46,0,0,1-4.16,2.51,8.63,8.63,0,0,1-8.09-2.69,11,11,0,0,1-2.17-9.83c.07-.31-.41-.45-.48-.13Z"/>
                <path class="cls-4" d="M575.52,352.9c-1,.87-2.1,1.87-2.25,3.18l0,.07a11.21,11.21,0,0,0,2.51,10.37,8.59,8.59,0,0,0,10.2,1.62,11.36,11.36,0,0,0,2.24-1.86,12.11,12.11,0,0,0,3.2-5.06,8.94,8.94,0,0,0-4.72-10A10.46,10.46,0,0,0,575.52,352.9Zm4.88.47a2.48,2.48,0,0,1,1.58,2.72,2.28,2.28,0,0,1-2.54,1.76,2,2,0,0,1-1.76-2.38v-.06c.11-.64.14-1.43.71-1.83A2.25,2.25,0,0,1,580.4,353.37Z"/>
                <path class="cls-4" d="M563.08,352.05a5.18,5.18,0,0,0-2.67-1.76c-.31-.07-.44.41-.13.48a4.68,4.68,0,0,1,2.44,1.63.25.25,0,1,0,.36-.35Z"/>
                <path class="cls-4" d="M560.5,350.06a11.64,11.64,0,0,0-10.09.95,9.18,9.18,0,0,0-4.14,7.76c.08,3.46,2.75,6.68,5.56,8.47a8.69,8.69,0,0,0,8.72.29,9.6,9.6,0,0,0,5-9.66c0-.32-.53-.32-.5,0a9.21,9.21,0,0,1-3.64,8.59,8.42,8.42,0,0,1-8.42.85,12.13,12.13,0,0,1-3.71-3.05,9.27,9.27,0,0,1-2.38-4.44,8.61,8.61,0,0,1,3.16-7.93,11,11,0,0,1,10.35-1.35c.3.1.43-.38.13-.48Z"/>
                <path class="cls-4" d="M568.32,350.82c-.76-1.09-1.63-2.29-2.92-2.58l-.07,0a11.19,11.19,0,0,0-10.57,1.41,8.58,8.58,0,0,0-2.69,10,11.52,11.52,0,0,0,1.61,2.43,12.12,12.12,0,0,0,4.7,3.71,8.93,8.93,0,0,0,10.41-3.64A10.44,10.44,0,0,0,568.32,350.82Zm-1,4.8a2.51,2.51,0,0,1-2.88,1.29A2.28,2.28,0,0,1,563,354.2a2,2,0,0,1,2.55-1.51l.06,0c.62.17,1.4.29,1.74.9A2.22,2.22,0,0,1,567.35,355.62Z"/>
                <path class="cls-4" d="M313.07,355.14a5.09,5.09,0,0,0-3.11-.74.25.25,0,0,0-.25.25.25.25,0,0,0,.25.24,4.74,4.74,0,0,1,2.86.68.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M310,354.15a11.78,11.78,0,0,0-8.81,3.9,9.2,9.2,0,0,0-1.73,8.77c1.11,3.34,4.55,5.45,7.81,6.33a8.69,8.69,0,0,0,8.7-2.56,9.61,9.61,0,0,0,1.37-10.87c-.13-.29-.56,0-.43.25a9.15,9.15,0,0,1-.65,9.5,8.29,8.29,0,0,1-8,3.38c-3.08-.55-6.71-2.54-8-5.49A8.65,8.65,0,0,1,301,359a11.1,11.1,0,0,1,8.93-4.37c.31,0,.32-.5,0-.49Z"/>
                <path class="cls-4" d="M317.55,352.17c-1.08-.76-2.32-1.58-3.63-1.41h-.07a11.2,11.2,0,0,0-9.45,5,8.58,8.58,0,0,0,.9,10.28,11.4,11.4,0,0,0,2.35,1.73,12,12,0,0,0,5.69,1.87,8.94,8.94,0,0,0,8.53-7A10.47,10.47,0,0,0,317.55,352.17Zm.74,4.85a2.49,2.49,0,0,1-2.26,2.19,2.26,2.26,0,0,1-2.32-2c-.07-1.14.73-2.37,1.87-2.29h.06a2.57,2.57,0,0,1,1.95.24A2.23,2.23,0,0,1,318.29,357Z"/>
                <path class="cls-4" d="M354.86,358a5.16,5.16,0,0,0-1.48,2.9.25.25,0,0,0,.24.25.25.25,0,0,0,.25-.25,4.7,4.7,0,0,1,1.34-2.55c.22-.23-.13-.58-.35-.35Z"/>
                <path class="cls-4" d="M353.14,360.74a11.79,11.79,0,0,0,1.67,9.52,9.18,9.18,0,0,0,8.26,3.76c3.48-.35,6.37-3.24,7.95-6.21a8.71,8.71,0,0,0-.59-9,9.61,9.61,0,0,0-10.09-3.8c-.31.06-.18.54.13.48a9.15,9.15,0,0,1,9,2.91,8.34,8.34,0,0,1,1.5,8.3,13,13,0,0,1-3,4.22,8.46,8.46,0,0,1-4.16,2.51,8.65,8.65,0,0,1-8.1-2.69,11.09,11.09,0,0,1-2.17-9.83.25.25,0,0,0-.48-.13Z"/>
                <path class="cls-4" d="M353.07,352.9c-1,.87-2.1,1.87-2.25,3.18l0,.07a11.21,11.21,0,0,0,2.51,10.37,8.6,8.6,0,0,0,10.2,1.62,11.14,11.14,0,0,0,2.25-1.86,12,12,0,0,0,3.19-5.06,8.92,8.92,0,0,0-4.71-10A10.45,10.45,0,0,0,353.07,352.9Zm4.88.47a2.49,2.49,0,0,1,1.58,2.72,2.28,2.28,0,0,1-2.54,1.76,2,2,0,0,1-1.76-2.38v-.06c.1-.64.14-1.43.71-1.83A2.24,2.24,0,0,1,358,353.37Z"/>
                <path class="cls-4" d="M340.62,352.05a5.12,5.12,0,0,0-2.66-1.76c-.31-.07-.45.41-.14.48a4.66,4.66,0,0,1,2.45,1.63.25.25,0,1,0,.35-.35Z"/>
                <path class="cls-4" d="M338.05,350.06A11.64,11.64,0,0,0,328,351a9.21,9.21,0,0,0-4.15,7.76c.08,3.46,2.76,6.68,5.57,8.47a8.69,8.69,0,0,0,8.72.29,9.6,9.6,0,0,0,5-9.66c0-.32-.53-.32-.5,0a9.19,9.19,0,0,1-3.65,8.59,8.4,8.4,0,0,1-8.41.85,12.13,12.13,0,0,1-3.71-3.05,9.27,9.27,0,0,1-2.38-4.44,8.58,8.58,0,0,1,3.16-7.93,11,11,0,0,1,10.35-1.35c.3.1.43-.38.13-.48Z"/>
                <path class="cls-4" d="M345.87,350.82c-.76-1.09-1.63-2.29-2.93-2.58l-.06,0a11.19,11.19,0,0,0-10.57,1.41,8.58,8.58,0,0,0-2.69,10,11.52,11.52,0,0,0,1.61,2.43,12.12,12.12,0,0,0,4.7,3.71,8.93,8.93,0,0,0,10.41-3.64A10.46,10.46,0,0,0,345.87,350.82Zm-1,4.8a2.49,2.49,0,0,1-2.87,1.29,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.55-1.51l.05,0c.63.17,1.41.29,1.75.9A2.19,2.19,0,0,1,344.89,355.62Z"/>
                <path class="cls-4" d="M387.4,355.14a5.11,5.11,0,0,0-3.11-.74.25.25,0,0,0-.25.25.25.25,0,0,0,.25.24,4.74,4.74,0,0,1,2.86.68.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M384.29,354.15a11.81,11.81,0,0,0-8.81,3.9,9.22,9.22,0,0,0-1.72,8.77c1.11,3.34,4.55,5.45,7.81,6.33a8.69,8.69,0,0,0,8.7-2.56,9.63,9.63,0,0,0,1.37-10.87c-.13-.29-.56,0-.43.25a9.15,9.15,0,0,1-.65,9.5,8.29,8.29,0,0,1-8,3.38c-3.08-.55-6.71-2.54-8-5.49a8.66,8.66,0,0,1,.88-8.35,11.09,11.09,0,0,1,8.92-4.37.25.25,0,0,0,0-.49Z"/>
                <path class="cls-4" d="M391.88,352.17c-1.09-.76-2.32-1.58-3.63-1.41h-.07a11.2,11.2,0,0,0-9.45,5,8.58,8.58,0,0,0,.9,10.28,11.4,11.4,0,0,0,2.35,1.73,12,12,0,0,0,5.69,1.87,8.94,8.94,0,0,0,8.53-7A10.49,10.49,0,0,0,391.88,352.17Zm.74,4.85a2.49,2.49,0,0,1-2.26,2.19,2.28,2.28,0,0,1-2.33-2c-.06-1.14.74-2.37,1.88-2.29H390a2.57,2.57,0,0,1,1.95.24A2.23,2.23,0,0,1,392.62,357Z"/>
                <path class="cls-4" d="M429.19,358a5.16,5.16,0,0,0-1.48,2.9.25.25,0,0,0,.24.25.25.25,0,0,0,.25-.25,4.7,4.7,0,0,1,1.34-2.55c.22-.23-.13-.58-.35-.35Z"/>
                <path class="cls-4" d="M427.47,360.74a11.75,11.75,0,0,0,1.67,9.52A9.17,9.17,0,0,0,437.4,374c3.48-.35,6.37-3.24,8-6.21a8.71,8.71,0,0,0-.59-9,9.61,9.61,0,0,0-10.09-3.8c-.32.06-.18.54.13.48a9.13,9.13,0,0,1,9,2.91,8.32,8.32,0,0,1,1.5,8.3,12.78,12.78,0,0,1-2.95,4.22,8.5,8.5,0,0,1-4.16,2.51,8.65,8.65,0,0,1-8.1-2.69,11.09,11.09,0,0,1-2.17-9.83.25.25,0,0,0-.48-.13Z"/>
                <path class="cls-4" d="M427.4,352.9c-1,.87-2.1,1.87-2.25,3.18l0,.07a11.21,11.21,0,0,0,2.51,10.37,8.59,8.59,0,0,0,10.2,1.62,11.14,11.14,0,0,0,2.25-1.86,12,12,0,0,0,3.19-5.06,8.92,8.92,0,0,0-4.71-10A10.45,10.45,0,0,0,427.4,352.9Zm4.88.47a2.49,2.49,0,0,1,1.58,2.72,2.28,2.28,0,0,1-2.54,1.76,2,2,0,0,1-1.77-2.38l0-.06c.1-.64.14-1.43.71-1.83A2.24,2.24,0,0,1,432.28,353.37Z"/>
                <path class="cls-4" d="M415,352.05a5.16,5.16,0,0,0-2.66-1.76c-.32-.07-.45.41-.14.48a4.7,4.7,0,0,1,2.45,1.63.25.25,0,0,0,.35-.35Z"/>
                <path class="cls-4" d="M412.38,350.06a11.64,11.64,0,0,0-10.09.95,9.16,9.16,0,0,0-4.15,7.76c.08,3.46,2.76,6.68,5.57,8.47a8.69,8.69,0,0,0,8.72.29,9.58,9.58,0,0,0,4.95-9.66c0-.32-.52-.32-.49,0a9.19,9.19,0,0,1-3.65,8.59,8.4,8.4,0,0,1-8.41.85,12.13,12.13,0,0,1-3.71-3.05,9.27,9.27,0,0,1-2.38-4.44,8.56,8.56,0,0,1,3.16-7.93,11,11,0,0,1,10.34-1.35c.31.1.44-.38.14-.48Z"/>
                <path class="cls-4" d="M420.2,350.82c-.76-1.09-1.63-2.29-2.93-2.58l-.06,0a11.19,11.19,0,0,0-10.57,1.41,8.58,8.58,0,0,0-2.69,10,11.52,11.52,0,0,0,1.61,2.43,12.12,12.12,0,0,0,4.7,3.71,8.93,8.93,0,0,0,10.41-3.64A10.46,10.46,0,0,0,420.2,350.82Zm-1,4.8a2.49,2.49,0,0,1-2.87,1.29,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.54-1.51l.06,0c.63.17,1.41.29,1.75.9A2.22,2.22,0,0,1,419.22,355.62Z"/>
                <path class="cls-4" d="M467.07,379.74A5.14,5.14,0,0,0,464,379a.26.26,0,0,0-.25.25c0,.12.11.27.25.25a4.69,4.69,0,0,1,2.85.68c.28.15.53-.28.25-.43Z"/>
                <path class="cls-4" d="M464,378.74a11.77,11.77,0,0,0-8.81,3.91,9.21,9.21,0,0,0-1.73,8.76c1.12,3.35,4.55,5.46,7.82,6.34a8.72,8.72,0,0,0,8.7-2.56,9.63,9.63,0,0,0,1.37-10.87c-.14-.29-.57,0-.43.25a9.18,9.18,0,0,1-.65,9.5,8.3,8.3,0,0,1-8,3.38c-3.09-.55-6.71-2.54-8-5.5a8.66,8.66,0,0,1,.87-8.34,11.11,11.11,0,0,1,8.93-4.37.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M471.56,376.77c-1.09-.76-2.32-1.59-3.64-1.41h-.06a11.18,11.18,0,0,0-9.45,4.95,8.6,8.6,0,0,0,.9,10.29,11.14,11.14,0,0,0,2.35,1.73,12,12,0,0,0,5.68,1.87,8.92,8.92,0,0,0,8.53-7A10.47,10.47,0,0,0,471.56,376.77Zm.73,4.85a2.47,2.47,0,0,1-2.25,2.19,2.27,2.27,0,0,1-2.33-2c-.07-1.14.74-2.37,1.88-2.29h.06a2.57,2.57,0,0,1,2,.24A2.25,2.25,0,0,1,472.29,381.62Z"/>
                <path class="cls-4" d="M508.87,382.57a5.24,5.24,0,0,0-1.49,2.9.25.25,0,0,0,.5,0,4.66,4.66,0,0,1,1.34-2.55c.22-.23-.13-.58-.35-.35Z"/>
                <path class="cls-4" d="M507.15,385.33a11.76,11.76,0,0,0,1.67,9.53,9.16,9.16,0,0,0,8.26,3.76c3.47-.36,6.37-3.24,7.94-6.21a8.71,8.71,0,0,0-.58-9,9.63,9.63,0,0,0-10.1-3.81c-.31.07-.18.55.14.48a9.17,9.17,0,0,1,9,2.91,8.36,8.36,0,0,1,1.5,8.31,12.81,12.81,0,0,1-3,4.21A8.38,8.38,0,0,1,517.9,398a8.62,8.62,0,0,1-8.1-2.7,11,11,0,0,1-2.17-9.82c.07-.31-.41-.45-.48-.14Z"/>
                <path class="cls-4" d="M507.07,377.5c-1,.87-2.1,1.87-2.25,3.18l0,.07a11.21,11.21,0,0,0,2.51,10.37,8.59,8.59,0,0,0,10.2,1.62,11.36,11.36,0,0,0,2.24-1.86,12.11,12.11,0,0,0,3.2-5.06,8.93,8.93,0,0,0-4.71-10A10.47,10.47,0,0,0,507.07,377.5ZM512,378a2.52,2.52,0,0,1,1.58,2.73,2.29,2.29,0,0,1-2.54,1.76,2,2,0,0,1-1.77-2.38l0-.06c.1-.64.14-1.43.71-1.83A2.24,2.24,0,0,1,512,378Z"/>
                <path class="cls-4" d="M494.63,376.65a5.08,5.08,0,0,0-2.67-1.76c-.31-.07-.44.41-.13.48a4.7,4.7,0,0,1,2.45,1.63.25.25,0,0,0,.35-.35Z"/>
                <path class="cls-4" d="M492.05,374.66a11.64,11.64,0,0,0-10.09.94,9.21,9.21,0,0,0-4.14,7.77c.08,3.46,2.75,6.67,5.56,8.47a8.69,8.69,0,0,0,8.72.28,9.58,9.58,0,0,0,5-9.65c0-.32-.53-.32-.5,0a9.16,9.16,0,0,1-3.64,8.58,8.38,8.38,0,0,1-8.41.85,12,12,0,0,1-3.72-3,9.21,9.21,0,0,1-2.37-4.44,8.56,8.56,0,0,1,3.16-7.93,11,11,0,0,1,10.34-1.35c.3.1.43-.38.13-.48Z"/>
                <path class="cls-4" d="M499.88,375.41c-.76-1.08-1.64-2.28-2.93-2.57l-.06,0a11.21,11.21,0,0,0-10.58,1.41,8.58,8.58,0,0,0-2.68,10,11.1,11.1,0,0,0,1.6,2.43,12.15,12.15,0,0,0,4.71,3.71,8.94,8.94,0,0,0,10.41-3.64A10.5,10.5,0,0,0,499.88,375.41Zm-1,4.81a2.49,2.49,0,0,1-2.87,1.29,2.3,2.3,0,0,1-1.49-2.72,2,2,0,0,1,2.55-1.5l.06,0c.63.17,1.41.29,1.74.9A2.19,2.19,0,0,1,498.9,380.22Z"/>
                <path class="cls-4" d="M541.4,379.74a5.14,5.14,0,0,0-3.1-.75.26.26,0,0,0-.25.25c0,.12.11.27.25.25a4.71,4.71,0,0,1,2.85.68c.28.15.53-.28.25-.43Z"/>
                <path class="cls-4" d="M538.3,378.74a11.75,11.75,0,0,0-8.81,3.91,9.21,9.21,0,0,0-1.73,8.76c1.12,3.35,4.55,5.46,7.82,6.34a8.72,8.72,0,0,0,8.7-2.56,9.63,9.63,0,0,0,1.37-10.87c-.14-.29-.57,0-.43.25a9.18,9.18,0,0,1-.65,9.5,8.31,8.31,0,0,1-8.05,3.38c-3.08-.55-6.7-2.54-8-5.5a8.66,8.66,0,0,1,.87-8.34,11.1,11.1,0,0,1,8.93-4.37.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M545.89,376.77c-1.09-.76-2.32-1.59-3.64-1.41h-.07a11.18,11.18,0,0,0-9.44,4.95,8.6,8.6,0,0,0,.9,10.29,11.34,11.34,0,0,0,2.34,1.73,12.11,12.11,0,0,0,5.69,1.87,8.92,8.92,0,0,0,8.53-7A10.47,10.47,0,0,0,545.89,376.77Zm.73,4.85a2.47,2.47,0,0,1-2.26,2.19,2.27,2.27,0,0,1-2.32-2c-.07-1.14.74-2.37,1.88-2.29H544a2.57,2.57,0,0,1,1.95.24A2.25,2.25,0,0,1,546.62,381.62Z"/>
                <path class="cls-4" d="M583.2,382.57a5.18,5.18,0,0,0-1.49,2.9.25.25,0,0,0,.5,0,4.66,4.66,0,0,1,1.34-2.55c.22-.23-.13-.58-.35-.35Z"/>
                <path class="cls-4" d="M581.48,385.33a11.76,11.76,0,0,0,1.67,9.53,9.16,9.16,0,0,0,8.26,3.76c3.47-.36,6.37-3.24,7.94-6.21a8.71,8.71,0,0,0-.58-9,9.63,9.63,0,0,0-10.1-3.81c-.31.07-.18.55.14.48a9.17,9.17,0,0,1,9,2.91,8.36,8.36,0,0,1,1.5,8.31,12.81,12.81,0,0,1-3,4.21,8.38,8.38,0,0,1-4.15,2.52,8.62,8.62,0,0,1-8.1-2.7,11,11,0,0,1-2.17-9.82c.07-.31-.41-.45-.48-.14Z"/>
                <path class="cls-4" d="M581.4,377.5c-1,.87-2.1,1.87-2.25,3.18l0,.07a11.21,11.21,0,0,0,2.51,10.37,8.59,8.59,0,0,0,10.2,1.62,11.36,11.36,0,0,0,2.24-1.86,12.11,12.11,0,0,0,3.2-5.06,8.94,8.94,0,0,0-4.71-10A10.47,10.47,0,0,0,581.4,377.5Zm4.89.46a2.5,2.5,0,0,1,1.57,2.73,2.27,2.27,0,0,1-2.53,1.76,2,2,0,0,1-1.77-2.38V380c.11-.64.15-1.43.72-1.83A2.24,2.24,0,0,1,586.29,378Z"/>
                <path class="cls-4" d="M569,376.65a5.12,5.12,0,0,0-2.67-1.76c-.31-.07-.44.41-.13.48a4.7,4.7,0,0,1,2.45,1.63.25.25,0,1,0,.35-.35Z"/>
                <path class="cls-4" d="M566.38,374.66a11.64,11.64,0,0,0-10.09.94,9.21,9.21,0,0,0-4.14,7.77c.08,3.46,2.75,6.67,5.56,8.47a8.69,8.69,0,0,0,8.72.28,9.58,9.58,0,0,0,5-9.65c0-.32-.53-.32-.5,0a9.18,9.18,0,0,1-3.64,8.58,8.38,8.38,0,0,1-8.41.85,12,12,0,0,1-3.72-3,9.32,9.32,0,0,1-2.38-4.44,8.61,8.61,0,0,1,3.16-7.93,11,11,0,0,1,10.35-1.35c.3.1.43-.38.13-.48Z"/>
                <path class="cls-4" d="M574.2,375.41c-.75-1.08-1.63-2.28-2.92-2.57l-.07,0a11.19,11.19,0,0,0-10.57,1.41,8.58,8.58,0,0,0-2.68,10,11.1,11.1,0,0,0,1.6,2.43,12.2,12.2,0,0,0,4.7,3.71,8.94,8.94,0,0,0,10.41-3.64A10.46,10.46,0,0,0,574.2,375.41Zm-1,4.81a2.49,2.49,0,0,1-2.87,1.29,2.3,2.3,0,0,1-1.49-2.72,2,2,0,0,1,2.55-1.5l.06,0c.63.17,1.41.29,1.74.9A2.22,2.22,0,0,1,573.23,380.22Z"/>
                <path class="cls-4" d="M319,379.74a5.17,5.17,0,0,0-3.11-.75.27.27,0,0,0-.25.25c0,.12.12.27.25.25a4.72,4.72,0,0,1,2.86.68c.28.15.53-.28.25-.43Z"/>
                <path class="cls-4" d="M315.85,378.74a11.75,11.75,0,0,0-8.81,3.91,9.19,9.19,0,0,0-1.73,8.76c1.12,3.35,4.55,5.46,7.81,6.34a8.73,8.73,0,0,0,8.71-2.56,9.64,9.64,0,0,0,1.37-10.87c-.14-.29-.57,0-.43.25a9.18,9.18,0,0,1-.66,9.5,8.28,8.28,0,0,1-8,3.38c-3.08-.55-6.7-2.54-8-5.5a8.64,8.64,0,0,1,.87-8.34,11.1,11.1,0,0,1,8.93-4.37.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M323.43,376.77c-1.08-.76-2.31-1.59-3.63-1.41h-.07a11.21,11.21,0,0,0-9.45,4.95,8.58,8.58,0,0,0,.91,10.29,11.08,11.08,0,0,0,2.34,1.73,12,12,0,0,0,5.69,1.87,8.93,8.93,0,0,0,8.53-7A10.47,10.47,0,0,0,323.43,376.77Zm.74,4.85a2.49,2.49,0,0,1-2.26,2.19,2.27,2.27,0,0,1-2.32-2c-.07-1.14.73-2.37,1.87-2.29h.07c.64-.06,1.42-.21,1.94.24A2.23,2.23,0,0,1,324.17,381.62Z"/>
                <path class="cls-4" d="M360.74,382.57a5.16,5.16,0,0,0-1.48,2.9c0,.13.12.24.25.24a.26.26,0,0,0,.25-.24,4.58,4.58,0,0,1,1.33-2.55c.22-.23-.13-.58-.35-.35Z"/>
                <path class="cls-4" d="M359,385.33a11.76,11.76,0,0,0,1.66,9.53,9.19,9.19,0,0,0,8.27,3.76c3.47-.36,6.37-3.24,7.94-6.21a8.69,8.69,0,0,0-.59-9,9.61,9.61,0,0,0-10.09-3.81c-.31.07-.18.55.13.48a9.19,9.19,0,0,1,9,2.91,8.36,8.36,0,0,1,1.5,8.31,12.81,12.81,0,0,1-3,4.21,8.41,8.41,0,0,1-4.16,2.52,8.64,8.64,0,0,1-8.1-2.7,11,11,0,0,1-2.16-9.82c.07-.31-.41-.45-.48-.14Z"/>
                <path class="cls-4" d="M359,377.5c-1,.87-2.1,1.87-2.25,3.18l0,.07a11.18,11.18,0,0,0,2.51,10.37,8.58,8.58,0,0,0,10.19,1.62,11.14,11.14,0,0,0,2.25-1.86,12,12,0,0,0,3.19-5.06,8.91,8.91,0,0,0-4.71-10A10.46,10.46,0,0,0,359,377.5Zm4.88.46a2.49,2.49,0,0,1,1.58,2.73,2.28,2.28,0,0,1-2.54,1.76,2,2,0,0,1-1.76-2.38V380c.11-.64.14-1.43.71-1.83A2.25,2.25,0,0,1,363.83,378Z"/>
                <path class="cls-4" d="M346.51,376.65a5.12,5.12,0,0,0-2.67-1.76c-.31-.07-.45.41-.13.48a4.68,4.68,0,0,1,2.44,1.63.25.25,0,1,0,.36-.35Z"/>
                <path class="cls-4" d="M343.93,374.66a11.64,11.64,0,0,0-10.09.94,9.21,9.21,0,0,0-4.14,7.77c.07,3.46,2.75,6.67,5.56,8.47a8.69,8.69,0,0,0,8.72.28,9.58,9.58,0,0,0,5-9.65c0-.32-.53-.32-.5,0a9.18,9.18,0,0,1-3.64,8.58,8.39,8.39,0,0,1-8.42.85,12.1,12.1,0,0,1-3.71-3,9.32,9.32,0,0,1-2.38-4.44,8.61,8.61,0,0,1,3.16-7.93,11,11,0,0,1,10.35-1.35c.3.1.43-.38.13-.48Z"/>
                <path class="cls-4" d="M351.75,375.41c-.76-1.08-1.63-2.28-2.93-2.57l-.06,0a11.19,11.19,0,0,0-10.57,1.41,8.58,8.58,0,0,0-2.69,10,11.52,11.52,0,0,0,1.61,2.43,12.12,12.12,0,0,0,4.7,3.71,8.94,8.94,0,0,0,10.41-3.64A10.48,10.48,0,0,0,351.75,375.41Zm-1,4.81a2.51,2.51,0,0,1-2.88,1.29,2.29,2.29,0,0,1-1.48-2.72,2,2,0,0,1,2.55-1.5l.06,0c.62.17,1.4.29,1.74.9A2.22,2.22,0,0,1,350.78,380.22Z"/>
                <path class="cls-4" d="M393.28,379.74a5.17,5.17,0,0,0-3.11-.75.27.27,0,0,0-.25.25c0,.12.12.27.25.25a4.74,4.74,0,0,1,2.86.68c.28.15.53-.28.25-.43Z"/>
                <path class="cls-4" d="M390.18,378.74a11.75,11.75,0,0,0-8.81,3.91,9.19,9.19,0,0,0-1.73,8.76c1.12,3.35,4.55,5.46,7.81,6.34a8.71,8.71,0,0,0,8.7-2.56,9.61,9.61,0,0,0,1.37-10.87c-.13-.29-.56,0-.42.25a9.18,9.18,0,0,1-.66,9.5,8.28,8.28,0,0,1-8,3.38c-3.08-.55-6.71-2.54-8-5.5a8.64,8.64,0,0,1,.87-8.34,11.1,11.1,0,0,1,8.93-4.37.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M397.76,376.77c-1.08-.76-2.32-1.59-3.63-1.41h-.07a11.19,11.19,0,0,0-9.45,4.95,8.58,8.58,0,0,0,.91,10.29,11.08,11.08,0,0,0,2.34,1.73,12,12,0,0,0,5.69,1.87,8.94,8.94,0,0,0,8.53-7A10.47,10.47,0,0,0,397.76,376.77Zm.74,4.85a2.49,2.49,0,0,1-2.26,2.19,2.26,2.26,0,0,1-2.32-2c-.07-1.14.73-2.37,1.87-2.29h.07c.64-.06,1.42-.21,1.94.24A2.23,2.23,0,0,1,398.5,381.62Z"/>
                <path class="cls-4" d="M435.07,382.57a5.16,5.16,0,0,0-1.48,2.9c0,.13.12.24.25.24a.24.24,0,0,0,.24-.24,4.66,4.66,0,0,1,1.34-2.55c.22-.23-.13-.58-.35-.35Z"/>
                <path class="cls-4" d="M433.35,385.33a11.8,11.8,0,0,0,1.67,9.53,9.19,9.19,0,0,0,8.27,3.76c3.47-.36,6.37-3.24,7.94-6.21a8.69,8.69,0,0,0-.59-9,9.61,9.61,0,0,0-10.09-3.81c-.31.07-.18.55.13.48a9.19,9.19,0,0,1,9,2.91,8.36,8.36,0,0,1,1.5,8.31,12.81,12.81,0,0,1-3,4.21A8.41,8.41,0,0,1,444.1,398a8.64,8.64,0,0,1-8.1-2.7,11.06,11.06,0,0,1-2.17-9.82c.08-.31-.4-.45-.48-.14Z"/>
                <path class="cls-4" d="M433.28,377.5c-1,.87-2.1,1.87-2.25,3.18l0,.07a11.18,11.18,0,0,0,2.52,10.37,8.58,8.58,0,0,0,10.19,1.62,11.14,11.14,0,0,0,2.25-1.86,12,12,0,0,0,3.19-5.06,8.91,8.91,0,0,0-4.71-10A10.45,10.45,0,0,0,433.28,377.5Zm4.88.46a2.5,2.5,0,0,1,1.58,2.73,2.28,2.28,0,0,1-2.54,1.76,2,2,0,0,1-1.76-2.38V380c.1-.64.14-1.43.71-1.83A2.24,2.24,0,0,1,438.16,378Z"/>
                <path class="cls-4" d="M420.83,376.65a5.06,5.06,0,0,0-2.66-1.76c-.31-.07-.45.41-.13.48a4.68,4.68,0,0,1,2.44,1.63.25.25,0,0,0,.35,0,.24.24,0,0,0,0-.35Z"/>
                <path class="cls-4" d="M418.26,374.66a11.64,11.64,0,0,0-10.09.94,9.21,9.21,0,0,0-4.14,7.77c.07,3.46,2.75,6.67,5.56,8.47a8.69,8.69,0,0,0,8.72.28,9.58,9.58,0,0,0,5-9.65c0-.32-.53-.32-.5,0a9.18,9.18,0,0,1-3.64,8.58,8.39,8.39,0,0,1-8.42.85,12.1,12.1,0,0,1-3.71-3,9.32,9.32,0,0,1-2.38-4.44,8.61,8.61,0,0,1,3.16-7.93,11,11,0,0,1,10.35-1.35c.3.1.43-.38.13-.48Z"/>
                <path class="cls-4" d="M426.08,375.41c-.76-1.08-1.63-2.28-2.93-2.57l-.06,0a11.19,11.19,0,0,0-10.57,1.41,8.58,8.58,0,0,0-2.69,10,11.52,11.52,0,0,0,1.61,2.43,12.12,12.12,0,0,0,4.7,3.71,8.94,8.94,0,0,0,10.41-3.64A10.48,10.48,0,0,0,426.08,375.41Zm-1,4.81a2.51,2.51,0,0,1-2.88,1.29,2.29,2.29,0,0,1-1.48-2.72,2,2,0,0,1,2.55-1.5l.06,0c.62.17,1.4.29,1.74.9A2.22,2.22,0,0,1,425.11,380.22Z"/>
            </g>
            <g class="cls-3">
                <path class="cls-4" d="M162.21,407.49a5.13,5.13,0,0,0-3.1-.74.25.25,0,0,0,0,.5,4.68,4.68,0,0,1,2.85.67.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M159.11,406.5a11.77,11.77,0,0,0-8.81,3.91,9.21,9.21,0,0,0-1.73,8.76c1.12,3.34,4.55,5.45,7.82,6.33a8.7,8.7,0,0,0,8.7-2.56,9.61,9.61,0,0,0,1.37-10.86c-.14-.29-.57,0-.43.25a9.14,9.14,0,0,1-.65,9.49,8.33,8.33,0,0,1-8,3.39c-3.08-.55-6.71-2.54-8-5.5a8.69,8.69,0,0,1,.87-8.35,11.12,11.12,0,0,1,8.93-4.36.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M166.7,404.52c-1.09-.76-2.32-1.58-3.63-1.41H163a11.18,11.18,0,0,0-9.45,4.95,8.6,8.6,0,0,0,.9,10.29,11.36,11.36,0,0,0,2.35,1.72,11.93,11.93,0,0,0,5.69,1.87,8.91,8.91,0,0,0,8.52-7A10.47,10.47,0,0,0,166.7,404.52Zm.73,4.86a2.49,2.49,0,0,1-2.25,2.19,2.3,2.3,0,0,1-2.33-2c-.07-1.14.74-2.37,1.88-2.29h.06c.65-.05,1.42-.21,2,.25A2.21,2.21,0,0,1,167.43,409.38Z"/>
                <path class="cls-4" d="M204,410.33a5.21,5.21,0,0,0-1.49,2.89.26.26,0,0,0,.25.25.25.25,0,0,0,.25-.25,4.63,4.63,0,0,1,1.34-2.54c.22-.24-.13-.59-.35-.35Z"/>
                <path class="cls-4" d="M202.29,413.09a11.73,11.73,0,0,0,1.67,9.52,9.19,9.19,0,0,0,8.26,3.77c3.47-.36,6.37-3.25,7.94-6.22a8.71,8.71,0,0,0-.58-9,9.62,9.62,0,0,0-10.09-3.8c-.32.06-.19.54.13.48a9.15,9.15,0,0,1,9,2.91,8.35,8.35,0,0,1,1.5,8.31,12.87,12.87,0,0,1-3,4.21,8.52,8.52,0,0,1-4.15,2.52,8.64,8.64,0,0,1-8.1-2.7,11,11,0,0,1-2.17-9.83c.07-.31-.41-.44-.48-.13Z"/>
                <path class="cls-4" d="M202.21,405.26c-1,.87-2.1,1.86-2.25,3.18l0,.06a11.2,11.2,0,0,0,2.51,10.37,8.58,8.58,0,0,0,10.2,1.62,11.67,11.67,0,0,0,2.24-1.85,12.14,12.14,0,0,0,3.2-5.07,8.92,8.92,0,0,0-4.71-10A10.5,10.5,0,0,0,202.21,405.26Zm4.89.46a2.5,2.5,0,0,1,1.58,2.72,2.29,2.29,0,0,1-2.54,1.76,2,2,0,0,1-1.77-2.37l0-.06c.1-.64.14-1.43.71-1.83A2.21,2.21,0,0,1,207.1,405.72Z"/>
                <path class="cls-4" d="M189.77,404.4a5.07,5.07,0,0,0-2.67-1.75.25.25,0,0,0-.13.48,4.69,4.69,0,0,1,2.45,1.62.25.25,0,0,0,.35-.35Z"/>
                <path class="cls-4" d="M187.19,402.41a11.65,11.65,0,0,0-10.08.95,9.16,9.16,0,0,0-4.15,7.76c.08,3.46,2.75,6.68,5.56,8.48a8.71,8.71,0,0,0,8.72.28,9.59,9.59,0,0,0,5-9.66c0-.31-.52-.31-.49,0a9.2,9.2,0,0,1-3.65,8.59,8.38,8.38,0,0,1-8.41.85,12.17,12.17,0,0,1-3.72-3.05,9.13,9.13,0,0,1-2.37-4.44,8.54,8.54,0,0,1,3.16-7.92,11,11,0,0,1,10.34-1.36c.3.11.43-.37.13-.48Z"/>
                <path class="cls-4" d="M195,403.17c-.76-1.09-1.64-2.29-2.93-2.57l-.06,0a11.21,11.21,0,0,0-10.58,1.4,8.58,8.58,0,0,0-2.68,10,10.91,10.91,0,0,0,1.61,2.43,12,12,0,0,0,4.7,3.71,8.93,8.93,0,0,0,10.41-3.63A10.48,10.48,0,0,0,195,403.17ZM194,408a2.49,2.49,0,0,1-2.87,1.28,2.3,2.3,0,0,1-1.49-2.71,2,2,0,0,1,2.55-1.5l.06,0c.63.17,1.41.29,1.75.9A2.22,2.22,0,0,1,194,408Z"/>
                <path class="cls-4" d="M236.54,407.49a5.13,5.13,0,0,0-3.1-.74.25.25,0,0,0,0,.5,4.68,4.68,0,0,1,2.85.67.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M233.44,406.5a11.77,11.77,0,0,0-8.81,3.91,9.21,9.21,0,0,0-1.73,8.76c1.12,3.34,4.55,5.45,7.82,6.33a8.7,8.7,0,0,0,8.7-2.56,9.61,9.61,0,0,0,1.37-10.86c-.14-.29-.57,0-.43.25a9.16,9.16,0,0,1-.65,9.49,8.33,8.33,0,0,1-8,3.39c-3.08-.55-6.71-2.54-8-5.5a8.69,8.69,0,0,1,.87-8.35,11.1,11.1,0,0,1,8.93-4.36.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M241,404.52c-1.09-.76-2.32-1.58-3.64-1.41h-.06a11.18,11.18,0,0,0-9.45,4.95,8.6,8.6,0,0,0,.9,10.29,11.1,11.1,0,0,0,2.35,1.72,11.93,11.93,0,0,0,5.68,1.87,8.91,8.91,0,0,0,8.53-7A10.47,10.47,0,0,0,241,404.52Zm.73,4.86a2.49,2.49,0,0,1-2.25,2.19,2.29,2.29,0,0,1-2.33-2c-.07-1.14.74-2.37,1.88-2.29h.06c.65-.05,1.42-.21,1.95.25A2.24,2.24,0,0,1,241.76,409.38Z"/>
                <path class="cls-4" d="M278.34,410.33a5.21,5.21,0,0,0-1.49,2.89.26.26,0,0,0,.25.25.25.25,0,0,0,.25-.25,4.63,4.63,0,0,1,1.34-2.54c.22-.24-.13-.59-.35-.35Z"/>
                <path class="cls-4" d="M276.62,413.09a11.73,11.73,0,0,0,1.67,9.52,9.19,9.19,0,0,0,8.26,3.77c3.47-.36,6.37-3.25,7.94-6.22a8.71,8.71,0,0,0-.58-9,9.63,9.63,0,0,0-10.1-3.8c-.31.06-.18.54.14.48a9.15,9.15,0,0,1,9,2.91,8.35,8.35,0,0,1,1.5,8.31,13,13,0,0,1-3,4.21,8.52,8.52,0,0,1-4.15,2.52,8.63,8.63,0,0,1-8.1-2.7,11,11,0,0,1-2.17-9.83c.07-.31-.41-.44-.48-.13Z"/>
                <path class="cls-4" d="M276.54,405.26c-1,.87-2.1,1.86-2.25,3.18l0,.06a11.2,11.2,0,0,0,2.51,10.37,8.58,8.58,0,0,0,10.2,1.62,11.67,11.67,0,0,0,2.24-1.85,12.14,12.14,0,0,0,3.2-5.07,8.92,8.92,0,0,0-4.71-10A10.5,10.5,0,0,0,276.54,405.26Zm4.89.46a2.5,2.5,0,0,1,1.58,2.72,2.29,2.29,0,0,1-2.54,1.76,2,2,0,0,1-1.77-2.37l0-.06c.1-.64.14-1.43.71-1.83A2.21,2.21,0,0,1,281.43,405.72Z"/>
                <path class="cls-4" d="M264.1,404.4a5.07,5.07,0,0,0-2.67-1.75.25.25,0,0,0-.13.48,4.69,4.69,0,0,1,2.45,1.62.25.25,0,0,0,.35-.35Z"/>
                <path class="cls-4" d="M261.52,402.41a11.67,11.67,0,0,0-10.09.95,9.18,9.18,0,0,0-4.14,7.76c.08,3.46,2.75,6.68,5.56,8.48a8.71,8.71,0,0,0,8.72.28,9.59,9.59,0,0,0,5-9.66c0-.31-.53-.31-.5,0a9.18,9.18,0,0,1-3.64,8.59,8.38,8.38,0,0,1-8.41.85,12.05,12.05,0,0,1-3.72-3.05,9.13,9.13,0,0,1-2.37-4.44,8.54,8.54,0,0,1,3.16-7.92,11,11,0,0,1,10.34-1.36c.3.11.43-.37.13-.48Z"/>
                <path class="cls-4" d="M269.35,403.17c-.76-1.09-1.64-2.29-2.93-2.57l-.06,0a11.21,11.21,0,0,0-10.58,1.4,8.58,8.58,0,0,0-2.68,10,11.23,11.23,0,0,0,1.6,2.43,12.06,12.06,0,0,0,4.71,3.71,8.93,8.93,0,0,0,10.41-3.63A10.5,10.5,0,0,0,269.35,403.17Zm-1,4.81a2.49,2.49,0,0,1-2.87,1.28,2.3,2.3,0,0,1-1.49-2.71,2,2,0,0,1,2.55-1.5l.06,0c.63.17,1.41.29,1.74.9A2.19,2.19,0,0,1,268.37,408Z"/>
                <path class="cls-4" d="M14.09,407.49a5.16,5.16,0,0,0-3.11-.74.25.25,0,0,0,0,.5,4.71,4.71,0,0,1,2.86.67.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M11,406.5a11.75,11.75,0,0,0-8.81,3.91,9.18,9.18,0,0,0-1.73,8.76c1.12,3.34,4.55,5.45,7.82,6.33a8.7,8.7,0,0,0,8.7-2.56,9.61,9.61,0,0,0,1.37-10.86c-.14-.29-.57,0-.43.25a9.18,9.18,0,0,1-.65,9.49,8.33,8.33,0,0,1-8,3.39c-3.08-.55-6.7-2.54-8-5.5a8.66,8.66,0,0,1,.87-8.35A11.09,11.09,0,0,1,11,407a.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M18.57,404.52c-1.08-.76-2.31-1.58-3.63-1.41h-.07a11.21,11.21,0,0,0-9.45,4.95,8.6,8.6,0,0,0,.91,10.29,11.29,11.29,0,0,0,2.34,1.72,12,12,0,0,0,5.69,1.87,8.92,8.92,0,0,0,8.53-7A10.48,10.48,0,0,0,18.57,404.52Zm.74,4.86a2.51,2.51,0,0,1-2.26,2.19,2.29,2.29,0,0,1-2.32-2c-.07-1.14.73-2.37,1.87-2.29h.07c.64-.05,1.42-.21,1.94.25A2.22,2.22,0,0,1,19.31,409.38Z"/>
                <path class="cls-4" d="M55.88,410.33a5.14,5.14,0,0,0-1.48,2.89c0,.13.13.25.25.25a.25.25,0,0,0,.25-.25,4.51,4.51,0,0,1,1.34-2.54c.21-.24-.14-.59-.36-.35Z"/>
                <path class="cls-4" d="M54.17,413.09a11.77,11.77,0,0,0,1.66,9.52,9.22,9.22,0,0,0,8.27,3.77c3.47-.36,6.37-3.25,7.94-6.22a8.69,8.69,0,0,0-.59-9,9.61,9.61,0,0,0-10.09-3.8c-.31.06-.18.54.13.48a9.17,9.17,0,0,1,9,2.91A8.35,8.35,0,0,1,72,419a13,13,0,0,1-3,4.21,8,8,0,0,1-12.25-.18,11,11,0,0,1-2.17-9.83c.07-.31-.41-.44-.48-.13Z"/>
                <path class="cls-4" d="M54.09,405.26c-1,.87-2.1,1.86-2.25,3.18l0,.06a11.2,11.2,0,0,0,2.51,10.37,8.58,8.58,0,0,0,10.2,1.62,11.67,11.67,0,0,0,2.24-1.85,12.14,12.14,0,0,0,3.2-5.07,8.94,8.94,0,0,0-4.72-10A10.48,10.48,0,0,0,54.09,405.26Zm4.89.46a2.48,2.48,0,0,1,1.57,2.72A2.28,2.28,0,0,1,58,410.2a2,2,0,0,1-1.76-2.37v-.06c.11-.64.14-1.43.71-1.83A2.23,2.23,0,0,1,59,405.72Z"/>
                <path class="cls-4" d="M41.65,404.4A5.11,5.11,0,0,0,39,402.65a.25.25,0,0,0-.13.48,4.66,4.66,0,0,1,2.44,1.62.25.25,0,0,0,.36-.35Z"/>
                <path class="cls-4" d="M39.07,402.41a11.67,11.67,0,0,0-10.09.95,9.18,9.18,0,0,0-4.14,7.76c.08,3.46,2.75,6.68,5.56,8.48a8.71,8.71,0,0,0,8.72.28,9.59,9.59,0,0,0,5-9.66c0-.31-.53-.31-.5,0a9.2,9.2,0,0,1-3.64,8.59,8.39,8.39,0,0,1-8.42.85,12.13,12.13,0,0,1-3.71-3.05,9.23,9.23,0,0,1-2.38-4.44,8.59,8.59,0,0,1,3.16-7.92,11,11,0,0,1,10.35-1.36c.3.11.43-.37.13-.48Z"/>
                <path class="cls-4" d="M46.89,403.17c-.76-1.09-1.63-2.29-2.92-2.57l-.07,0A11.19,11.19,0,0,0,33.33,402c-2.92,2.3-4.25,6.6-2.69,10a11.27,11.27,0,0,0,1.61,2.43,12,12,0,0,0,4.7,3.71,8.92,8.92,0,0,0,10.41-3.63A10.46,10.46,0,0,0,46.89,403.17Zm-1,4.81A2.49,2.49,0,0,1,43,409.26a2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.55-1.5l.06,0c.62.17,1.4.29,1.74.9A2.22,2.22,0,0,1,45.92,408Z"/>
                <path class="cls-4" d="M88.42,407.49a5.16,5.16,0,0,0-3.11-.74.25.25,0,0,0,0,.5,4.71,4.71,0,0,1,2.86.67.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M85.32,406.5a11.75,11.75,0,0,0-8.81,3.91,9.18,9.18,0,0,0-1.73,8.76c1.12,3.34,4.55,5.45,7.81,6.33a8.71,8.71,0,0,0,8.71-2.56,9.62,9.62,0,0,0,1.37-10.86c-.14-.29-.57,0-.43.25a9.16,9.16,0,0,1-.66,9.49,8.31,8.31,0,0,1-8,3.39c-3.08-.55-6.7-2.54-8-5.5a8.66,8.66,0,0,1,.87-8.35A11.09,11.09,0,0,1,85.32,407a.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M92.9,404.52c-1.08-.76-2.31-1.58-3.63-1.41H89.2a11.21,11.21,0,0,0-9.45,4.95,8.58,8.58,0,0,0,.91,10.29A11,11,0,0,0,83,420.07a11.93,11.93,0,0,0,5.69,1.87,8.92,8.92,0,0,0,8.53-7A10.48,10.48,0,0,0,92.9,404.52Zm.74,4.86a2.51,2.51,0,0,1-2.26,2.19,2.29,2.29,0,0,1-2.32-2c-.07-1.14.73-2.37,1.87-2.29H91c.64-.05,1.42-.21,1.94.25A2.22,2.22,0,0,1,93.64,409.38Z"/>
                <path class="cls-4" d="M130.21,410.33a5.14,5.14,0,0,0-1.48,2.89c0,.13.12.25.25.25a.26.26,0,0,0,.25-.25,4.56,4.56,0,0,1,1.33-2.54c.22-.24-.13-.59-.35-.35Z"/>
                <path class="cls-4" d="M128.5,413.09a11.73,11.73,0,0,0,1.66,9.52,9.22,9.22,0,0,0,8.27,3.77c3.47-.36,6.37-3.25,7.94-6.22a8.69,8.69,0,0,0-.59-9,9.61,9.61,0,0,0-10.09-3.8c-.31.06-.18.54.13.48a9.17,9.17,0,0,1,9,2.91,8.35,8.35,0,0,1,1.5,8.31,13,13,0,0,1-3,4.21,8,8,0,0,1-12.25-.18,11,11,0,0,1-2.17-9.83c.07-.31-.41-.44-.48-.13Z"/>
                <path class="cls-4" d="M128.42,405.26c-1,.87-2.1,1.86-2.25,3.18l0,.06a11.17,11.17,0,0,0,2.51,10.37c2.59,2.66,7,3.53,10.19,1.62a11.43,11.43,0,0,0,2.25-1.85,12,12,0,0,0,3.19-5.07,8.92,8.92,0,0,0-4.71-10A10.48,10.48,0,0,0,128.42,405.26Zm4.88.46a2.48,2.48,0,0,1,1.58,2.72,2.28,2.28,0,0,1-2.54,1.76c-1.12-.21-2.12-1.29-1.76-2.37v-.06c.11-.64.14-1.43.71-1.83A2.22,2.22,0,0,1,133.3,405.72Z"/>
                <path class="cls-4" d="M116,404.4a5.11,5.11,0,0,0-2.67-1.75c-.31-.07-.45.4-.13.48a4.66,4.66,0,0,1,2.44,1.62.25.25,0,1,0,.36-.35Z"/>
                <path class="cls-4" d="M113.4,402.41a11.67,11.67,0,0,0-10.09.95,9.18,9.18,0,0,0-4.14,7.76c.08,3.46,2.75,6.68,5.56,8.48a8.71,8.71,0,0,0,8.72.28,9.59,9.59,0,0,0,5-9.66c0-.31-.53-.31-.5,0a9.2,9.2,0,0,1-3.64,8.59,8.39,8.39,0,0,1-8.42.85,12.13,12.13,0,0,1-3.71-3.05,9.23,9.23,0,0,1-2.38-4.44,8.59,8.59,0,0,1,3.16-7.92,11,11,0,0,1,10.35-1.36c.3.11.43-.37.13-.48Z"/>
                <path class="cls-4" d="M121.22,403.17c-.76-1.09-1.63-2.29-2.93-2.57l-.06,0a11.19,11.19,0,0,0-10.57,1.4c-2.92,2.3-4.25,6.6-2.69,10a11.27,11.27,0,0,0,1.61,2.43,12,12,0,0,0,4.7,3.71,8.92,8.92,0,0,0,10.41-3.63A10.48,10.48,0,0,0,121.22,403.17Zm-1,4.81a2.5,2.5,0,0,1-2.88,1.28,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.55-1.5l.06,0c.62.17,1.4.29,1.74.9A2.22,2.22,0,0,1,120.25,408Z"/>
                <path class="cls-4" d="M168.1,432.09a5.18,5.18,0,0,0-3.11-.74.25.25,0,0,0,0,.5,4.66,4.66,0,0,1,2.86.67.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M165,431.1a11.79,11.79,0,0,0-8.81,3.9,9.22,9.22,0,0,0-1.72,8.77c1.11,3.34,4.55,5.45,7.81,6.33a8.69,8.69,0,0,0,8.7-2.56,9.62,9.62,0,0,0,1.37-10.87c-.14-.28-.56,0-.43.26a9.14,9.14,0,0,1-.65,9.49,8.32,8.32,0,0,1-8,3.39c-3.08-.55-6.71-2.54-8-5.5a8.66,8.66,0,0,1,.88-8.35A11.08,11.08,0,0,1,165,431.6c.32,0,.32-.51,0-.5Z"/>
                <path class="cls-4" d="M172.58,429.12c-1.09-.76-2.32-1.58-3.63-1.41h-.07a11.19,11.19,0,0,0-9.45,5c-2,3.16-1.72,7.66.9,10.28a11.4,11.4,0,0,0,2.35,1.73,12,12,0,0,0,5.69,1.87,8.94,8.94,0,0,0,8.53-7A10.49,10.49,0,0,0,172.58,429.12Zm.74,4.85a2.51,2.51,0,0,1-2.26,2.2,2.3,2.3,0,0,1-2.33-2c-.06-1.14.74-2.37,1.88-2.29h.06a2.52,2.52,0,0,1,2,.25A2.2,2.2,0,0,1,173.32,434Z"/>
                <path class="cls-4" d="M209.89,434.92a5.16,5.16,0,0,0-1.48,2.9.25.25,0,0,0,.24.25.25.25,0,0,0,.25-.25,4.7,4.7,0,0,1,1.34-2.55c.22-.23-.13-.58-.35-.35Z"/>
                <path class="cls-4" d="M208.17,437.69a11.74,11.74,0,0,0,1.67,9.52A9.17,9.17,0,0,0,218.1,451c3.48-.35,6.37-3.24,8-6.21a8.71,8.71,0,0,0-.59-9,9.61,9.61,0,0,0-10.09-3.8c-.32.06-.18.54.13.48a9.15,9.15,0,0,1,9,2.91,8.32,8.32,0,0,1,1.5,8.3,12.78,12.78,0,0,1-2.95,4.22,8.5,8.5,0,0,1-4.16,2.51,8.61,8.61,0,0,1-8.1-2.69,11.09,11.09,0,0,1-2.17-9.83.25.25,0,0,0-.48-.13Z"/>
                <path class="cls-4" d="M208.1,429.85c-1,.87-2.1,1.87-2.25,3.19l0,.06a11.2,11.2,0,0,0,2.51,10.37,8.58,8.58,0,0,0,10.2,1.62,11.43,11.43,0,0,0,2.25-1.85,12,12,0,0,0,3.19-5.07,8.92,8.92,0,0,0-4.71-10A10.45,10.45,0,0,0,208.1,429.85Zm4.88.47a2.5,2.5,0,0,1,1.58,2.72A2.29,2.29,0,0,1,212,434.8c-1.12-.21-2.12-1.29-1.77-2.37l0-.06c.1-.65.14-1.43.71-1.83A2.21,2.21,0,0,1,213,430.32Z"/>
                <path class="cls-4" d="M195.65,429a5.16,5.16,0,0,0-2.66-1.76c-.32-.07-.45.41-.14.48a4.7,4.7,0,0,1,2.45,1.63.25.25,0,1,0,.35-.35Z"/>
                <path class="cls-4" d="M193.08,427A11.64,11.64,0,0,0,183,428a9.16,9.16,0,0,0-4.15,7.76c.08,3.46,2.76,6.68,5.57,8.47a8.69,8.69,0,0,0,8.72.29,9.57,9.57,0,0,0,5-9.66c0-.31-.52-.32-.49,0a9.19,9.19,0,0,1-3.65,8.59,8.38,8.38,0,0,1-8.41.85,12.29,12.29,0,0,1-3.72-3.05,9.29,9.29,0,0,1-2.37-4.44,8.54,8.54,0,0,1,3.16-7.92,11,11,0,0,1,10.34-1.36c.31.11.44-.37.14-.48Z"/>
                <path class="cls-4" d="M200.9,427.77c-.76-1.09-1.63-2.29-2.93-2.57l-.06,0a11.18,11.18,0,0,0-10.58,1.4c-2.91,2.3-4.24,6.6-2.68,10a11.27,11.27,0,0,0,1.61,2.43,12.12,12.12,0,0,0,4.7,3.71A8.91,8.91,0,0,0,201.37,439,10.46,10.46,0,0,0,200.9,427.77Zm-1,4.81a2.5,2.5,0,0,1-2.87,1.28,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.54-1.51l.06,0c.63.17,1.41.29,1.75.9A2.25,2.25,0,0,1,199.92,432.58Z"/>
                <path class="cls-4" d="M242.43,432.09a5.18,5.18,0,0,0-3.11-.74.26.26,0,0,0-.25.25.25.25,0,0,0,.25.25,4.61,4.61,0,0,1,2.85.67c.28.16.54-.27.26-.43Z"/>
                <path class="cls-4" d="M239.32,431.1a11.79,11.79,0,0,0-8.81,3.9,9.25,9.25,0,0,0-1.73,8.77c1.12,3.34,4.56,5.45,7.82,6.33a8.69,8.69,0,0,0,8.7-2.56,9.62,9.62,0,0,0,1.37-10.87c-.14-.28-.56,0-.43.26a9.14,9.14,0,0,1-.65,9.49,8.33,8.33,0,0,1-8,3.39c-3.08-.55-6.71-2.54-8-5.5a8.66,8.66,0,0,1,.88-8.35,11.08,11.08,0,0,1,8.92-4.36c.32,0,.32-.51,0-.5Z"/>
                <path class="cls-4" d="M246.91,429.12c-1.09-.76-2.32-1.58-3.63-1.41h-.07a11.18,11.18,0,0,0-9.45,5c-1.95,3.16-1.72,7.66.9,10.28a11.4,11.4,0,0,0,2.35,1.73,12,12,0,0,0,5.69,1.87,8.92,8.92,0,0,0,8.52-7A10.47,10.47,0,0,0,246.91,429.12Zm.74,4.85a2.51,2.51,0,0,1-2.26,2.2,2.3,2.3,0,0,1-2.33-2c-.06-1.14.74-2.37,1.88-2.29H245a2.52,2.52,0,0,1,1.95.25A2.23,2.23,0,0,1,247.65,434Z"/>
                <path class="cls-4" d="M284.22,434.92a5.24,5.24,0,0,0-1.49,2.9.26.26,0,0,0,.25.25.25.25,0,0,0,.25-.25,4.7,4.7,0,0,1,1.34-2.55c.22-.23-.13-.58-.35-.35Z"/>
                <path class="cls-4" d="M282.5,437.69a11.74,11.74,0,0,0,1.67,9.52,9.16,9.16,0,0,0,8.26,3.76c3.48-.35,6.37-3.24,7.94-6.21a8.69,8.69,0,0,0-.58-9,9.61,9.61,0,0,0-10.09-3.8c-.32.06-.18.54.13.48a9.15,9.15,0,0,1,9,2.91,8.32,8.32,0,0,1,1.5,8.3,12.93,12.93,0,0,1-2.95,4.22,8.5,8.5,0,0,1-4.16,2.51,8.61,8.61,0,0,1-8.1-2.69,11.09,11.09,0,0,1-2.17-9.83c.07-.31-.41-.44-.48-.13Z"/>
                <path class="cls-4" d="M282.43,429.85c-1,.87-2.11,1.87-2.25,3.19l0,.06a11.2,11.2,0,0,0,2.51,10.37,8.58,8.58,0,0,0,10.2,1.62,11.43,11.43,0,0,0,2.25-1.85,12,12,0,0,0,3.19-5.07,8.92,8.92,0,0,0-4.71-10A10.45,10.45,0,0,0,282.43,429.85Zm4.88.47a2.5,2.5,0,0,1,1.58,2.72,2.29,2.29,0,0,1-2.54,1.76c-1.12-.21-2.12-1.29-1.77-2.37l0-.06c.1-.65.14-1.43.71-1.83A2.21,2.21,0,0,1,287.31,430.32Z"/>
                <path class="cls-4" d="M270,429a5.16,5.16,0,0,0-2.66-1.76c-.32-.07-.45.41-.14.48a4.7,4.7,0,0,1,2.45,1.63A.25.25,0,0,0,270,429Z"/>
                <path class="cls-4" d="M267.41,427a11.64,11.64,0,0,0-10.09.95,9.16,9.16,0,0,0-4.15,7.76c.08,3.46,2.75,6.68,5.57,8.47a8.69,8.69,0,0,0,8.72.29,9.59,9.59,0,0,0,5-9.66c0-.31-.52-.32-.49,0a9.21,9.21,0,0,1-3.65,8.59,8.38,8.38,0,0,1-8.41.85,12.29,12.29,0,0,1-3.72-3.05,9.29,9.29,0,0,1-2.37-4.44,8.54,8.54,0,0,1,3.16-7.92,11,11,0,0,1,10.34-1.36c.31.11.44-.37.14-.48Z"/>
                <path class="cls-4" d="M275.23,427.77c-.76-1.09-1.63-2.29-2.93-2.57l-.06,0a11.18,11.18,0,0,0-10.58,1.4c-2.91,2.3-4.24,6.6-2.68,10a11.27,11.27,0,0,0,1.61,2.43,12.05,12.05,0,0,0,4.7,3.71A8.91,8.91,0,0,0,275.7,439,10.46,10.46,0,0,0,275.23,427.77Zm-1,4.81a2.5,2.5,0,0,1-2.87,1.28,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.54-1.51l.06,0c.63.17,1.41.29,1.75.9A2.25,2.25,0,0,1,274.25,432.58Z"/>
                <path class="cls-4" d="M20,432.09a5.13,5.13,0,0,0-3.1-.74.25.25,0,0,0,0,.5,4.61,4.61,0,0,1,2.85.67.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M16.87,431.1A11.79,11.79,0,0,0,8.06,435a9.23,9.23,0,0,0-1.73,8.77c1.12,3.34,4.55,5.45,7.82,6.33a8.7,8.7,0,0,0,8.7-2.56,9.62,9.62,0,0,0,1.37-10.87c-.14-.28-.57,0-.43.26a9.16,9.16,0,0,1-.65,9.49,8.33,8.33,0,0,1-8,3.39c-3.09-.55-6.71-2.54-8-5.5A8.69,8.69,0,0,1,7.94,436a11.1,11.1,0,0,1,8.93-4.36c.32,0,.32-.51,0-.5Z"/>
                <path class="cls-4" d="M24.46,429.12c-1.09-.76-2.32-1.58-3.64-1.41h-.06a11.18,11.18,0,0,0-9.45,5c-2,3.16-1.72,7.66.9,10.28a11.34,11.34,0,0,0,2.34,1.73,12.11,12.11,0,0,0,5.69,1.87,8.92,8.92,0,0,0,8.53-7A10.47,10.47,0,0,0,24.46,429.12Zm.73,4.85a2.49,2.49,0,0,1-2.25,2.2,2.29,2.29,0,0,1-2.33-2c-.07-1.14.74-2.37,1.88-2.29h.06a2.52,2.52,0,0,1,1.95.25A2.22,2.22,0,0,1,25.19,434Z"/>
                <path class="cls-4" d="M61.77,434.92a5.24,5.24,0,0,0-1.49,2.9.25.25,0,0,0,.5,0,4.7,4.7,0,0,1,1.34-2.55c.22-.23-.13-.58-.35-.35Z"/>
                <path class="cls-4" d="M60.05,437.69a11.74,11.74,0,0,0,1.67,9.52A9.16,9.16,0,0,0,70,451c3.47-.35,6.37-3.24,7.94-6.21a8.71,8.71,0,0,0-.58-9,9.63,9.63,0,0,0-10.1-3.8c-.31.06-.18.54.14.48a9.15,9.15,0,0,1,9,2.91,8.34,8.34,0,0,1,1.5,8.3,13,13,0,0,1-3,4.22,8.43,8.43,0,0,1-4.15,2.51,8.59,8.59,0,0,1-8.1-2.69,11.06,11.06,0,0,1-2.17-9.83c.07-.31-.41-.44-.48-.13Z"/>
                <path class="cls-4" d="M60,429.85c-1,.87-2.1,1.87-2.25,3.19l0,.06a11.2,11.2,0,0,0,2.51,10.37,8.58,8.58,0,0,0,10.2,1.62,11.67,11.67,0,0,0,2.24-1.85,12.14,12.14,0,0,0,3.2-5.07,8.94,8.94,0,0,0-4.71-10A10.47,10.47,0,0,0,60,429.85Zm4.89.47A2.48,2.48,0,0,1,66.43,433a2.27,2.27,0,0,1-2.53,1.76,2,2,0,0,1-1.77-2.37l0-.06c.1-.65.14-1.43.71-1.83A2.21,2.21,0,0,1,64.86,430.32Z"/>
                <path class="cls-4" d="M47.53,429a5.14,5.14,0,0,0-2.67-1.76c-.31-.07-.44.41-.13.48a4.7,4.7,0,0,1,2.45,1.63.25.25,0,1,0,.35-.35Z"/>
                <path class="cls-4" d="M45,427a11.64,11.64,0,0,0-10.09.95,9.18,9.18,0,0,0-4.14,7.76c.08,3.46,2.75,6.68,5.56,8.47a8.69,8.69,0,0,0,8.72.29,9.59,9.59,0,0,0,5-9.66c0-.31-.53-.32-.5,0a9.21,9.21,0,0,1-3.64,8.59,8.38,8.38,0,0,1-8.41.85,12.05,12.05,0,0,1-3.72-3.05,9.13,9.13,0,0,1-2.37-4.44,8.54,8.54,0,0,1,3.16-7.92,11,11,0,0,1,10.34-1.36c.3.11.43-.37.13-.48Z"/>
                <path class="cls-4" d="M52.78,427.77c-.76-1.09-1.64-2.29-2.93-2.57l-.06,0a11.18,11.18,0,0,0-10.58,1.4,8.58,8.58,0,0,0-2.68,10,10.87,10.87,0,0,0,1.6,2.43,12.15,12.15,0,0,0,4.71,3.71A8.92,8.92,0,0,0,53.25,439,10.48,10.48,0,0,0,52.78,427.77Zm-1,4.81a2.5,2.5,0,0,1-2.87,1.28,2.3,2.3,0,0,1-1.49-2.71A2,2,0,0,1,50,429.64l.06,0c.63.17,1.41.29,1.74.9A2.21,2.21,0,0,1,51.8,432.58Z"/>
                <path class="cls-4" d="M94.3,432.09a5.13,5.13,0,0,0-3.1-.74.25.25,0,0,0,0,.5,4.63,4.63,0,0,1,2.85.67.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M91.2,431.1a11.78,11.78,0,0,0-8.81,3.9,9.23,9.23,0,0,0-1.73,8.77c1.12,3.34,4.55,5.45,7.82,6.33a8.7,8.7,0,0,0,8.7-2.56,9.62,9.62,0,0,0,1.37-10.87c-.14-.28-.57,0-.43.26a9.16,9.16,0,0,1-.65,9.49,8.33,8.33,0,0,1-8,3.39c-3.08-.55-6.7-2.54-8-5.5a8.69,8.69,0,0,1,.87-8.35,11.09,11.09,0,0,1,8.93-4.36c.32,0,.32-.51,0-.5Z"/>
                <path class="cls-4" d="M98.79,429.12c-1.09-.76-2.32-1.58-3.64-1.41h-.07a11.18,11.18,0,0,0-9.44,5,8.56,8.56,0,0,0,.9,10.28,11.34,11.34,0,0,0,2.34,1.73,12.11,12.11,0,0,0,5.69,1.87,8.92,8.92,0,0,0,8.53-7A10.47,10.47,0,0,0,98.79,429.12Zm.73,4.85a2.49,2.49,0,0,1-2.26,2.2,2.29,2.29,0,0,1-2.32-2c-.07-1.14.74-2.37,1.88-2.29h.06a2.52,2.52,0,0,1,2,.25A2.22,2.22,0,0,1,99.52,434Z"/>
                <path class="cls-4" d="M136.1,434.92a5.18,5.18,0,0,0-1.49,2.9c0,.13.13.25.25.25a.26.26,0,0,0,.25-.25,4.7,4.7,0,0,1,1.34-2.55c.22-.23-.13-.58-.35-.35Z"/>
                <path class="cls-4" d="M134.38,437.69a11.77,11.77,0,0,0,1.66,9.52,9.19,9.19,0,0,0,8.27,3.76c3.47-.35,6.37-3.24,7.94-6.21a8.71,8.71,0,0,0-.58-9,9.63,9.63,0,0,0-10.1-3.8c-.31.06-.18.54.14.48a9.15,9.15,0,0,1,9,2.91,8.34,8.34,0,0,1,1.5,8.3,13,13,0,0,1-3,4.22,8.43,8.43,0,0,1-4.15,2.51,8.59,8.59,0,0,1-8.1-2.69,11,11,0,0,1-2.17-9.83c.07-.31-.41-.44-.48-.13Z"/>
                <path class="cls-4" d="M134.3,429.85c-1,.87-2.1,1.87-2.25,3.19l0,.06a11.2,11.2,0,0,0,2.51,10.37,8.58,8.58,0,0,0,10.2,1.62,11.67,11.67,0,0,0,2.24-1.85,12.14,12.14,0,0,0,3.2-5.07,9,9,0,0,0-4.71-10A10.47,10.47,0,0,0,134.3,429.85Zm4.89.47a2.48,2.48,0,0,1,1.57,2.72,2.28,2.28,0,0,1-2.54,1.76,2,2,0,0,1-1.76-2.37v-.06c.11-.65.14-1.43.72-1.83A2.21,2.21,0,0,1,139.19,430.32Z"/>
                <path class="cls-4" d="M121.86,429a5.18,5.18,0,0,0-2.67-1.76c-.31-.07-.44.41-.13.48a4.74,4.74,0,0,1,2.45,1.63.25.25,0,0,0,.35-.35Z"/>
                <path class="cls-4" d="M119.28,427a11.64,11.64,0,0,0-10.09.95,9.18,9.18,0,0,0-4.14,7.76c.08,3.46,2.75,6.68,5.56,8.47a8.69,8.69,0,0,0,8.72.29,9.59,9.59,0,0,0,5-9.66c0-.31-.53-.32-.5,0a9.21,9.21,0,0,1-3.64,8.59,8.38,8.38,0,0,1-8.41.85,12.05,12.05,0,0,1-3.72-3.05,9.23,9.23,0,0,1-2.38-4.44,8.59,8.59,0,0,1,3.16-7.92,11,11,0,0,1,10.35-1.36c.3.11.43-.37.13-.48Z"/>
                <path class="cls-4" d="M127.1,427.77c-.75-1.09-1.63-2.29-2.92-2.57l-.07,0a11.16,11.16,0,0,0-10.57,1.4,8.6,8.6,0,0,0-2.69,10,11.27,11.27,0,0,0,1.61,2.43,12.2,12.2,0,0,0,4.7,3.71A8.91,8.91,0,0,0,127.57,439,10.44,10.44,0,0,0,127.1,427.77Zm-1,4.81a2.5,2.5,0,0,1-2.87,1.28,2.29,2.29,0,0,1-1.49-2.71,2,2,0,0,1,2.55-1.51l.06,0c.63.17,1.41.29,1.74.9A2.25,2.25,0,0,1,126.13,432.58Z"/>
                <path class="cls-4" d="M461.19,407.49a5.16,5.16,0,0,0-3.11-.74.25.25,0,0,0,0,.5,4.71,4.71,0,0,1,2.86.67.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M458.09,406.5a11.75,11.75,0,0,0-8.81,3.91,9.18,9.18,0,0,0-1.73,8.76c1.12,3.34,4.55,5.45,7.82,6.33a8.7,8.7,0,0,0,8.7-2.56,9.61,9.61,0,0,0,1.37-10.86c-.14-.29-.57,0-.43.25a9.16,9.16,0,0,1-.65,9.49,8.33,8.33,0,0,1-8.05,3.39c-3.08-.55-6.7-2.54-8-5.5a8.69,8.69,0,0,1,.87-8.35,11.09,11.09,0,0,1,8.93-4.36.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M465.68,404.52c-1.09-.76-2.32-1.58-3.64-1.41H462a11.18,11.18,0,0,0-9.44,4.95,8.58,8.58,0,0,0,.9,10.29,11.29,11.29,0,0,0,2.34,1.72,12,12,0,0,0,5.69,1.87,8.91,8.91,0,0,0,8.53-7A10.49,10.49,0,0,0,465.68,404.52Zm.73,4.86a2.51,2.51,0,0,1-2.26,2.19,2.29,2.29,0,0,1-2.32-2c-.07-1.14.74-2.37,1.87-2.29h.07c.64-.05,1.42-.21,1.94.25A2.22,2.22,0,0,1,466.41,409.38Z"/>
                <path class="cls-4" d="M503,410.33a5.14,5.14,0,0,0-1.48,2.89c0,.13.13.25.25.25a.25.25,0,0,0,.25-.25,4.51,4.51,0,0,1,1.34-2.54c.21-.24-.14-.59-.36-.35Z"/>
                <path class="cls-4" d="M501.27,413.09a11.77,11.77,0,0,0,1.66,9.52,9.22,9.22,0,0,0,8.27,3.77c3.47-.36,6.37-3.25,7.94-6.22a8.71,8.71,0,0,0-.58-9,9.63,9.63,0,0,0-10.1-3.8c-.31.06-.18.54.13.48a9.16,9.16,0,0,1,9,2.91,8.35,8.35,0,0,1,1.5,8.31,13,13,0,0,1-3,4.21,8.51,8.51,0,0,1-4.16,2.52,8.61,8.61,0,0,1-8.09-2.7,11,11,0,0,1-2.17-9.83c.07-.31-.41-.44-.48-.13Z"/>
                <path class="cls-4" d="M501.19,405.26c-1,.87-2.1,1.86-2.25,3.18l0,.06a11.2,11.2,0,0,0,2.51,10.37,8.58,8.58,0,0,0,10.2,1.62,11.67,11.67,0,0,0,2.24-1.85,12.14,12.14,0,0,0,3.2-5.07,9,9,0,0,0-4.71-10A10.49,10.49,0,0,0,501.19,405.26Zm4.89.46a2.48,2.48,0,0,1,1.57,2.72,2.28,2.28,0,0,1-2.54,1.76,2,2,0,0,1-1.76-2.37v-.06c.11-.64.14-1.43.72-1.83A2.21,2.21,0,0,1,506.08,405.72Z"/>
                <path class="cls-4" d="M488.75,404.4a5.11,5.11,0,0,0-2.67-1.75.25.25,0,0,0-.13.48,4.72,4.72,0,0,1,2.45,1.62.25.25,0,0,0,.35,0,.26.26,0,0,0,0-.35Z"/>
                <path class="cls-4" d="M486.17,402.41a11.67,11.67,0,0,0-10.09.95,9.18,9.18,0,0,0-4.14,7.76c.08,3.46,2.75,6.68,5.56,8.48a8.71,8.71,0,0,0,8.72.28,9.59,9.59,0,0,0,5-9.66c0-.31-.53-.31-.5,0a9.2,9.2,0,0,1-3.64,8.59,8.39,8.39,0,0,1-8.42.85,12.13,12.13,0,0,1-3.71-3.05,9.23,9.23,0,0,1-2.38-4.44,8.59,8.59,0,0,1,3.16-7.92A11,11,0,0,1,486,402.89c.3.11.43-.37.13-.48Z"/>
                <path class="cls-4" d="M494,403.17c-.76-1.09-1.63-2.29-2.92-2.57l-.07,0a11.19,11.19,0,0,0-10.57,1.4c-2.92,2.3-4.25,6.6-2.69,10a11.27,11.27,0,0,0,1.61,2.43,12.1,12.1,0,0,0,4.7,3.71,8.92,8.92,0,0,0,10.41-3.63A10.46,10.46,0,0,0,494,403.17ZM493,408a2.49,2.49,0,0,1-2.87,1.28,2.28,2.28,0,0,1-1.49-2.71,2,2,0,0,1,2.55-1.5l.06,0c.63.17,1.41.29,1.74.9A2.22,2.22,0,0,1,493,408Z"/>
                <path class="cls-4" d="M535.52,407.49a5.16,5.16,0,0,0-3.11-.74.25.25,0,0,0,0,.5,4.71,4.71,0,0,1,2.86.67.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M532.42,406.5a11.75,11.75,0,0,0-8.81,3.91,9.18,9.18,0,0,0-1.73,8.76c1.12,3.34,4.55,5.45,7.82,6.33a8.7,8.7,0,0,0,8.7-2.56,9.61,9.61,0,0,0,1.37-10.86c-.14-.29-.57,0-.43.25a9.18,9.18,0,0,1-.65,9.49,8.33,8.33,0,0,1-8.05,3.39c-3.08-.55-6.7-2.54-8-5.5a8.66,8.66,0,0,1,.87-8.35,11.09,11.09,0,0,1,8.93-4.36.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M540,404.52c-1.08-.76-2.31-1.58-3.63-1.41h-.07a11.21,11.21,0,0,0-9.45,4.95,8.6,8.6,0,0,0,.91,10.29,11,11,0,0,0,2.34,1.72,12,12,0,0,0,5.69,1.87,8.92,8.92,0,0,0,8.53-7A10.48,10.48,0,0,0,540,404.52Zm.74,4.86a2.51,2.51,0,0,1-2.26,2.19,2.29,2.29,0,0,1-2.32-2c-.07-1.14.73-2.37,1.87-2.29h.07c.64-.05,1.42-.21,1.94.25A2.22,2.22,0,0,1,540.74,409.38Z"/>
                <path class="cls-4" d="M577.31,410.33a5.14,5.14,0,0,0-1.48,2.89c0,.13.12.25.25.25a.25.25,0,0,0,.25-.25,4.51,4.51,0,0,1,1.34-2.54c.21-.24-.14-.59-.36-.35Z"/>
                <path class="cls-4" d="M575.6,413.09a11.77,11.77,0,0,0,1.66,9.52,9.22,9.22,0,0,0,8.27,3.77c3.47-.36,6.37-3.25,7.94-6.22a8.69,8.69,0,0,0-.59-9,9.61,9.61,0,0,0-10.09-3.8c-.31.06-.18.54.13.48a9.17,9.17,0,0,1,9,2.91,8.35,8.35,0,0,1,1.5,8.31,13,13,0,0,1-3,4.21,8,8,0,0,1-12.25-.18,11,11,0,0,1-2.17-9.83c.07-.31-.41-.44-.48-.13Z"/>
                <path class="cls-4" d="M575.52,405.26c-1,.87-2.1,1.86-2.25,3.18l0,.06a11.2,11.2,0,0,0,2.51,10.37,8.58,8.58,0,0,0,10.2,1.62,11.67,11.67,0,0,0,2.24-1.85,12.14,12.14,0,0,0,3.2-5.07,8.94,8.94,0,0,0-4.72-10A10.48,10.48,0,0,0,575.52,405.26Zm4.88.46a2.48,2.48,0,0,1,1.58,2.72,2.28,2.28,0,0,1-2.54,1.76c-1.12-.21-2.12-1.29-1.76-2.37v-.06c.11-.64.14-1.43.71-1.83A2.22,2.22,0,0,1,580.4,405.72Z"/>
                <path class="cls-4" d="M563.08,404.4a5.11,5.11,0,0,0-2.67-1.75.25.25,0,0,0-.13.48,4.66,4.66,0,0,1,2.44,1.62.25.25,0,1,0,.36-.35Z"/>
                <path class="cls-4" d="M560.5,402.41a11.67,11.67,0,0,0-10.09.95,9.18,9.18,0,0,0-4.14,7.76c.08,3.46,2.75,6.68,5.56,8.48a8.71,8.71,0,0,0,8.72.28,9.59,9.59,0,0,0,5-9.66c0-.31-.53-.31-.5,0a9.2,9.2,0,0,1-3.64,8.59,8.39,8.39,0,0,1-8.42.85,12.13,12.13,0,0,1-3.71-3.05,9.23,9.23,0,0,1-2.38-4.44,8.59,8.59,0,0,1,3.16-7.92,11,11,0,0,1,10.35-1.36c.3.11.43-.37.13-.48Z"/>
                <path class="cls-4" d="M568.32,403.17c-.76-1.09-1.63-2.29-2.92-2.57l-.07,0a11.19,11.19,0,0,0-10.57,1.4c-2.92,2.3-4.25,6.6-2.69,10a11.27,11.27,0,0,0,1.61,2.43,12,12,0,0,0,4.7,3.71,8.92,8.92,0,0,0,10.41-3.63A10.46,10.46,0,0,0,568.32,403.17Zm-1,4.81a2.5,2.5,0,0,1-2.88,1.28,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.55-1.5l.06,0c.62.17,1.4.29,1.74.9A2.22,2.22,0,0,1,567.35,408Z"/>
                <path class="cls-4" d="M313.07,407.49a5.16,5.16,0,0,0-3.11-.74.25.25,0,0,0,0,.5,4.73,4.73,0,0,1,2.86.67.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M310,406.5a11.75,11.75,0,0,0-8.81,3.91,9.18,9.18,0,0,0-1.73,8.76c1.11,3.34,4.55,5.45,7.81,6.33a8.69,8.69,0,0,0,8.7-2.56,9.59,9.59,0,0,0,1.37-10.86c-.13-.29-.56,0-.43.25a9.14,9.14,0,0,1-.65,9.49,8.32,8.32,0,0,1-8,3.39c-3.08-.55-6.71-2.54-8-5.5a8.66,8.66,0,0,1,.87-8.35A11.09,11.09,0,0,1,310,407a.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M317.55,404.52c-1.08-.76-2.32-1.58-3.63-1.41h-.07a11.19,11.19,0,0,0-9.45,4.95,8.6,8.6,0,0,0,.9,10.29,11.36,11.36,0,0,0,2.35,1.72,11.93,11.93,0,0,0,5.69,1.87,8.93,8.93,0,0,0,8.53-7A10.48,10.48,0,0,0,317.55,404.52Zm.74,4.86a2.51,2.51,0,0,1-2.26,2.19,2.28,2.28,0,0,1-2.32-2c-.07-1.14.73-2.37,1.87-2.29h.06c.65-.05,1.42-.21,1.95.25A2.22,2.22,0,0,1,318.29,409.38Z"/>
                <path class="cls-4" d="M354.86,410.33a5.14,5.14,0,0,0-1.48,2.89.25.25,0,1,0,.49,0,4.63,4.63,0,0,1,1.34-2.54c.22-.24-.13-.59-.35-.35Z"/>
                <path class="cls-4" d="M353.14,413.09a11.77,11.77,0,0,0,1.67,9.52,9.21,9.21,0,0,0,8.26,3.77c3.48-.36,6.37-3.25,7.95-6.22a8.71,8.71,0,0,0-.59-9,9.61,9.61,0,0,0-10.09-3.8c-.31.06-.18.54.13.48a9.17,9.17,0,0,1,9,2.91A8.35,8.35,0,0,1,371,419a13,13,0,0,1-3,4.21,8,8,0,0,1-12.26-.18,11.08,11.08,0,0,1-2.17-9.83.25.25,0,0,0-.48-.13Z"/>
                <path class="cls-4" d="M353.07,405.26c-1,.87-2.1,1.86-2.25,3.18l0,.06a11.2,11.2,0,0,0,2.51,10.37c2.59,2.66,7,3.53,10.2,1.62a11.43,11.43,0,0,0,2.25-1.85,12,12,0,0,0,3.19-5.07,8.92,8.92,0,0,0-4.71-10A10.48,10.48,0,0,0,353.07,405.26Zm4.88.46a2.49,2.49,0,0,1,1.58,2.72A2.28,2.28,0,0,1,357,410.2c-1.12-.21-2.12-1.29-1.76-2.37v-.06c.1-.64.14-1.43.71-1.83A2.21,2.21,0,0,1,358,405.72Z"/>
                <path class="cls-4" d="M340.62,404.4a5,5,0,0,0-2.66-1.75c-.31-.07-.45.4-.14.48a4.65,4.65,0,0,1,2.45,1.62.25.25,0,0,0,.35-.35Z"/>
                <path class="cls-4" d="M338.05,402.41a11.67,11.67,0,0,0-10.09.95,9.21,9.21,0,0,0-4.15,7.76c.08,3.46,2.76,6.68,5.57,8.48a8.71,8.71,0,0,0,8.72.28,9.59,9.59,0,0,0,5-9.66c0-.31-.53-.31-.5,0a9.18,9.18,0,0,1-3.65,8.59,8.38,8.38,0,0,1-8.41.85,12.13,12.13,0,0,1-3.71-3.05,9.23,9.23,0,0,1-2.38-4.44,8.57,8.57,0,0,1,3.16-7.92,11,11,0,0,1,10.35-1.36c.3.11.43-.37.13-.48Z"/>
                <path class="cls-4" d="M345.87,403.17c-.76-1.09-1.63-2.29-2.93-2.57l-.06,0a11.19,11.19,0,0,0-10.57,1.4c-2.92,2.3-4.25,6.6-2.69,10a11.27,11.27,0,0,0,1.61,2.43,12,12,0,0,0,4.7,3.71,8.92,8.92,0,0,0,10.41-3.63A10.48,10.48,0,0,0,345.87,403.17Zm-1,4.81a2.49,2.49,0,0,1-2.87,1.28,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.55-1.5l.05,0c.63.17,1.41.29,1.75.9A2.19,2.19,0,0,1,344.89,408Z"/>
                <path class="cls-4" d="M387.4,407.49a5.18,5.18,0,0,0-3.11-.74.25.25,0,1,0,0,.5,4.73,4.73,0,0,1,2.86.67.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M384.29,406.5a11.78,11.78,0,0,0-8.81,3.91,9.21,9.21,0,0,0-1.72,8.76c1.11,3.34,4.55,5.45,7.81,6.33a8.69,8.69,0,0,0,8.7-2.56,9.61,9.61,0,0,0,1.37-10.86c-.13-.29-.56,0-.43.25a9.14,9.14,0,0,1-.65,9.49,8.32,8.32,0,0,1-8,3.39c-3.08-.55-6.71-2.54-8-5.5a8.66,8.66,0,0,1,.88-8.35,11.08,11.08,0,0,1,8.92-4.36.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M391.88,404.52c-1.09-.76-2.32-1.58-3.63-1.41h-.07a11.19,11.19,0,0,0-9.45,4.95,8.6,8.6,0,0,0,.9,10.29,11.36,11.36,0,0,0,2.35,1.72,11.93,11.93,0,0,0,5.69,1.87,8.93,8.93,0,0,0,8.53-7A10.5,10.5,0,0,0,391.88,404.52Zm.74,4.86a2.51,2.51,0,0,1-2.26,2.19,2.3,2.3,0,0,1-2.33-2c-.06-1.14.74-2.37,1.88-2.29H390c.65-.05,1.42-.21,1.95.25A2.22,2.22,0,0,1,392.62,409.38Z"/>
                <path class="cls-4" d="M429.19,410.33a5.14,5.14,0,0,0-1.48,2.89.25.25,0,1,0,.49,0,4.63,4.63,0,0,1,1.34-2.54c.22-.24-.13-.59-.35-.35Z"/>
                <path class="cls-4" d="M427.47,413.09a11.73,11.73,0,0,0,1.67,9.52,9.2,9.2,0,0,0,8.26,3.77c3.48-.36,6.37-3.25,8-6.22a8.71,8.71,0,0,0-.59-9,9.61,9.61,0,0,0-10.09-3.8c-.32.06-.18.54.13.48a9.15,9.15,0,0,1,9,2.91,8.33,8.33,0,0,1,1.5,8.31,12.84,12.84,0,0,1-2.95,4.21,8,8,0,0,1-12.26-.18,11.08,11.08,0,0,1-2.17-9.83.25.25,0,0,0-.48-.13Z"/>
                <path class="cls-4" d="M427.4,405.26c-1,.87-2.1,1.86-2.25,3.18l0,.06a11.2,11.2,0,0,0,2.51,10.37,8.58,8.58,0,0,0,10.2,1.62,11.43,11.43,0,0,0,2.25-1.85,12,12,0,0,0,3.19-5.07,8.92,8.92,0,0,0-4.71-10A10.48,10.48,0,0,0,427.4,405.26Zm4.88.46a2.49,2.49,0,0,1,1.58,2.72,2.28,2.28,0,0,1-2.54,1.76c-1.12-.21-2.12-1.29-1.77-2.37l0-.06c.1-.64.14-1.43.71-1.83A2.21,2.21,0,0,1,432.28,405.72Z"/>
                <path class="cls-4" d="M415,404.4a5.08,5.08,0,0,0-2.66-1.75c-.32-.07-.45.4-.14.48a4.69,4.69,0,0,1,2.45,1.62.25.25,0,0,0,.35-.35Z"/>
                <path class="cls-4" d="M412.38,402.41a11.67,11.67,0,0,0-10.09.95,9.16,9.16,0,0,0-4.15,7.76c.08,3.46,2.76,6.68,5.57,8.48a8.71,8.71,0,0,0,8.72.28,9.57,9.57,0,0,0,4.95-9.66c0-.31-.52-.31-.49,0a9.18,9.18,0,0,1-3.65,8.59,8.38,8.38,0,0,1-8.41.85,12.13,12.13,0,0,1-3.71-3.05,9.23,9.23,0,0,1-2.38-4.44,8.54,8.54,0,0,1,3.16-7.92,11,11,0,0,1,10.34-1.36c.31.11.44-.37.14-.48Z"/>
                <path class="cls-4" d="M420.2,403.17c-.76-1.09-1.63-2.29-2.93-2.57l-.06,0a11.19,11.19,0,0,0-10.57,1.4c-2.92,2.3-4.25,6.6-2.69,10a11.27,11.27,0,0,0,1.61,2.43,12,12,0,0,0,4.7,3.71,8.92,8.92,0,0,0,10.41-3.63A10.48,10.48,0,0,0,420.2,403.17Zm-1,4.81a2.49,2.49,0,0,1-2.87,1.28,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.54-1.5l.06,0c.63.17,1.41.29,1.75.9A2.22,2.22,0,0,1,419.22,408Z"/>
                <path class="cls-4" d="M467.07,432.09a5.13,5.13,0,0,0-3.1-.74.25.25,0,0,0,0,.5,4.61,4.61,0,0,1,2.85.67.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M464,431.1a11.79,11.79,0,0,0-8.81,3.9,9.23,9.23,0,0,0-1.73,8.77c1.12,3.34,4.55,5.45,7.82,6.33a8.7,8.7,0,0,0,8.7-2.56,9.62,9.62,0,0,0,1.37-10.87c-.14-.28-.57,0-.43.26a9.16,9.16,0,0,1-.65,9.49,8.33,8.33,0,0,1-8,3.39c-3.09-.55-6.71-2.54-8-5.5A8.69,8.69,0,0,1,455,436,11.1,11.1,0,0,1,464,431.6c.32,0,.32-.51,0-.5Z"/>
                <path class="cls-4" d="M471.56,429.12c-1.09-.76-2.32-1.58-3.64-1.41h-.06a11.18,11.18,0,0,0-9.45,5c-2,3.16-1.72,7.66.9,10.28a11.14,11.14,0,0,0,2.35,1.73,12,12,0,0,0,5.68,1.87,8.92,8.92,0,0,0,8.53-7A10.47,10.47,0,0,0,471.56,429.12Zm.73,4.85a2.49,2.49,0,0,1-2.25,2.2,2.29,2.29,0,0,1-2.33-2c-.07-1.14.74-2.37,1.88-2.29h.06a2.52,2.52,0,0,1,2,.25A2.22,2.22,0,0,1,472.29,434Z"/>
                <path class="cls-4" d="M508.87,434.92a5.24,5.24,0,0,0-1.49,2.9.25.25,0,1,0,.5,0,4.7,4.7,0,0,1,1.34-2.55c.22-.23-.13-.58-.35-.35Z"/>
                <path class="cls-4" d="M507.15,437.69a11.74,11.74,0,0,0,1.67,9.52,9.16,9.16,0,0,0,8.26,3.76c3.47-.35,6.37-3.24,7.94-6.21a8.71,8.71,0,0,0-.58-9,9.63,9.63,0,0,0-10.1-3.8c-.31.06-.18.54.14.48a9.15,9.15,0,0,1,9,2.91,8.34,8.34,0,0,1,1.5,8.3,13,13,0,0,1-3,4.22,8.43,8.43,0,0,1-4.15,2.51,8.59,8.59,0,0,1-8.1-2.69,11.06,11.06,0,0,1-2.17-9.83c.07-.31-.41-.44-.48-.13Z"/>
                <path class="cls-4" d="M507.07,429.85c-1,.87-2.1,1.87-2.25,3.19l0,.06a11.2,11.2,0,0,0,2.51,10.37,8.58,8.58,0,0,0,10.2,1.62,11.67,11.67,0,0,0,2.24-1.85,12.14,12.14,0,0,0,3.2-5.07,8.94,8.94,0,0,0-4.71-10A10.47,10.47,0,0,0,507.07,429.85Zm4.89.47a2.5,2.5,0,0,1,1.58,2.72A2.29,2.29,0,0,1,511,434.8a2,2,0,0,1-1.77-2.37l0-.06c.1-.65.14-1.43.71-1.83A2.21,2.21,0,0,1,512,430.32Z"/>
                <path class="cls-4" d="M494.63,429a5.14,5.14,0,0,0-2.67-1.76c-.31-.07-.44.41-.13.48a4.7,4.7,0,0,1,2.45,1.63.25.25,0,0,0,.35-.35Z"/>
                <path class="cls-4" d="M492.05,427A11.64,11.64,0,0,0,482,428a9.18,9.18,0,0,0-4.14,7.76c.08,3.46,2.75,6.68,5.56,8.47a8.69,8.69,0,0,0,8.72.29,9.59,9.59,0,0,0,5-9.66c0-.31-.53-.32-.5,0a9.18,9.18,0,0,1-3.64,8.59,8.38,8.38,0,0,1-8.41.85,12.05,12.05,0,0,1-3.72-3.05,9.13,9.13,0,0,1-2.37-4.44,8.54,8.54,0,0,1,3.16-7.92,11,11,0,0,1,10.34-1.36c.3.11.43-.37.13-.48Z"/>
                <path class="cls-4" d="M499.88,427.77c-.76-1.09-1.64-2.29-2.93-2.57l-.06,0a11.18,11.18,0,0,0-10.58,1.4,8.58,8.58,0,0,0-2.68,10,10.87,10.87,0,0,0,1.6,2.43,12.15,12.15,0,0,0,4.71,3.71A8.92,8.92,0,0,0,500.35,439,10.48,10.48,0,0,0,499.88,427.77Zm-1,4.81a2.5,2.5,0,0,1-2.87,1.28,2.3,2.3,0,0,1-1.49-2.71,2,2,0,0,1,2.55-1.51l.06,0c.63.17,1.41.29,1.74.9A2.21,2.21,0,0,1,498.9,432.58Z"/>
                <path class="cls-4" d="M541.4,432.09a5.13,5.13,0,0,0-3.1-.74.25.25,0,0,0,0,.5,4.63,4.63,0,0,1,2.85.67.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M538.3,431.1a11.78,11.78,0,0,0-8.81,3.9,9.23,9.23,0,0,0-1.73,8.77c1.12,3.34,4.55,5.45,7.82,6.33a8.7,8.7,0,0,0,8.7-2.56,9.62,9.62,0,0,0,1.37-10.87c-.14-.28-.57,0-.43.26a9.16,9.16,0,0,1-.65,9.49,8.33,8.33,0,0,1-8.05,3.39c-3.08-.55-6.7-2.54-8-5.5a8.69,8.69,0,0,1,.87-8.35,11.09,11.09,0,0,1,8.93-4.36c.32,0,.32-.51,0-.5Z"/>
                <path class="cls-4" d="M545.89,429.12c-1.09-.76-2.32-1.58-3.64-1.41h-.07a11.18,11.18,0,0,0-9.44,5c-2,3.16-1.72,7.66.9,10.28a11.34,11.34,0,0,0,2.34,1.73,12.11,12.11,0,0,0,5.69,1.87,8.92,8.92,0,0,0,8.53-7A10.47,10.47,0,0,0,545.89,429.12Zm.73,4.85a2.49,2.49,0,0,1-2.26,2.2,2.29,2.29,0,0,1-2.32-2c-.07-1.14.74-2.37,1.88-2.29H544a2.52,2.52,0,0,1,1.95.25A2.22,2.22,0,0,1,546.62,434Z"/>
                <path class="cls-4" d="M583.2,434.92a5.18,5.18,0,0,0-1.49,2.9.25.25,0,0,0,.5,0,4.7,4.7,0,0,1,1.34-2.55c.22-.23-.13-.58-.35-.35Z"/>
                <path class="cls-4" d="M581.48,437.69a11.74,11.74,0,0,0,1.67,9.52,9.16,9.16,0,0,0,8.26,3.76c3.47-.35,6.37-3.24,7.94-6.21a8.71,8.71,0,0,0-.58-9,9.63,9.63,0,0,0-10.1-3.8c-.31.06-.18.54.14.48a9.15,9.15,0,0,1,9,2.91,8.34,8.34,0,0,1,1.5,8.3,13,13,0,0,1-3,4.22,8.43,8.43,0,0,1-4.15,2.51,8.59,8.59,0,0,1-8.1-2.69,11.06,11.06,0,0,1-2.17-9.83c.07-.31-.41-.44-.48-.13Z"/>
                <path class="cls-4" d="M581.4,429.85c-1,.87-2.1,1.87-2.25,3.19l0,.06a11.2,11.2,0,0,0,2.51,10.37,8.58,8.58,0,0,0,10.2,1.62,11.67,11.67,0,0,0,2.24-1.85,12.14,12.14,0,0,0,3.2-5.07,9,9,0,0,0-4.71-10A10.47,10.47,0,0,0,581.4,429.85Zm4.89.47a2.48,2.48,0,0,1,1.57,2.72,2.27,2.27,0,0,1-2.53,1.76,2,2,0,0,1-1.77-2.37v-.06c.11-.65.15-1.43.72-1.83A2.21,2.21,0,0,1,586.29,430.32Z"/>
                <path class="cls-4" d="M569,429a5.18,5.18,0,0,0-2.67-1.76c-.31-.07-.44.41-.13.48a4.7,4.7,0,0,1,2.45,1.63A.25.25,0,1,0,569,429Z"/>
                <path class="cls-4" d="M566.38,427a11.64,11.64,0,0,0-10.09.95,9.18,9.18,0,0,0-4.14,7.76c.08,3.46,2.75,6.68,5.56,8.47a8.69,8.69,0,0,0,8.72.29,9.59,9.59,0,0,0,5-9.66c0-.31-.53-.32-.5,0a9.21,9.21,0,0,1-3.64,8.59,8.38,8.38,0,0,1-8.41.85,12.05,12.05,0,0,1-3.72-3.05,9.23,9.23,0,0,1-2.38-4.44,8.59,8.59,0,0,1,3.16-7.92,11,11,0,0,1,10.35-1.36c.3.11.43-.37.13-.48Z"/>
                <path class="cls-4" d="M574.2,427.77c-.75-1.09-1.63-2.29-2.92-2.57l-.07,0a11.16,11.16,0,0,0-10.57,1.4,8.58,8.58,0,0,0-2.68,10,10.87,10.87,0,0,0,1.6,2.43,12.2,12.2,0,0,0,4.7,3.71A8.91,8.91,0,0,0,574.67,439,10.44,10.44,0,0,0,574.2,427.77Zm-1,4.81a2.5,2.5,0,0,1-2.87,1.28,2.3,2.3,0,0,1-1.49-2.71,2,2,0,0,1,2.55-1.51l.06,0c.63.17,1.41.29,1.74.9A2.25,2.25,0,0,1,573.23,432.58Z"/>
                <path class="cls-4" d="M319,432.09a5.16,5.16,0,0,0-3.11-.74.25.25,0,0,0,0,.5,4.64,4.64,0,0,1,2.86.67.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M315.85,431.1A11.78,11.78,0,0,0,307,435a9.2,9.2,0,0,0-1.73,8.77c1.12,3.34,4.55,5.45,7.81,6.33a8.71,8.71,0,0,0,8.71-2.56,9.64,9.64,0,0,0,1.37-10.87c-.14-.28-.57,0-.43.26a9.16,9.16,0,0,1-.66,9.49,8.31,8.31,0,0,1-8,3.39c-3.08-.55-6.7-2.54-8-5.5a8.66,8.66,0,0,1,.87-8.35,11.09,11.09,0,0,1,8.93-4.36c.32,0,.32-.51,0-.5Z"/>
                <path class="cls-4" d="M323.43,429.12c-1.08-.76-2.31-1.58-3.63-1.41h-.07a11.21,11.21,0,0,0-9.45,5c-1.95,3.16-1.72,7.66.91,10.28a11.08,11.08,0,0,0,2.34,1.73,12,12,0,0,0,5.69,1.87,8.93,8.93,0,0,0,8.53-7A10.47,10.47,0,0,0,323.43,429.12Zm.74,4.85a2.51,2.51,0,0,1-2.26,2.2,2.29,2.29,0,0,1-2.32-2c-.07-1.14.73-2.37,1.87-2.29h.07c.64-.06,1.42-.21,1.94.25A2.2,2.2,0,0,1,324.17,434Z"/>
                <path class="cls-4" d="M360.74,434.92a5.16,5.16,0,0,0-1.48,2.9c0,.13.12.25.25.25a.27.27,0,0,0,.25-.25,4.62,4.62,0,0,1,1.33-2.55c.22-.23-.13-.58-.35-.35Z"/>
                <path class="cls-4" d="M359,437.69a11.74,11.74,0,0,0,1.66,9.52A9.19,9.19,0,0,0,369,451c3.47-.35,6.37-3.24,7.94-6.21a8.69,8.69,0,0,0-.59-9,9.61,9.61,0,0,0-10.09-3.8c-.31.06-.18.54.13.48a9.17,9.17,0,0,1,9,2.91,8.34,8.34,0,0,1,1.5,8.3,13,13,0,0,1-3,4.22,8.46,8.46,0,0,1-4.16,2.51,8.61,8.61,0,0,1-8.1-2.69,11.06,11.06,0,0,1-2.16-9.83c.07-.31-.41-.44-.48-.13Z"/>
                <path class="cls-4" d="M359,429.85c-1,.87-2.1,1.87-2.25,3.19l0,.06a11.17,11.17,0,0,0,2.51,10.37c2.59,2.66,7,3.53,10.19,1.62a11.43,11.43,0,0,0,2.25-1.85,12,12,0,0,0,3.19-5.07,8.92,8.92,0,0,0-4.71-10A10.46,10.46,0,0,0,359,429.85Zm4.88.47a2.48,2.48,0,0,1,1.58,2.72,2.28,2.28,0,0,1-2.54,1.76c-1.12-.21-2.12-1.29-1.76-2.37v-.06c.11-.65.14-1.43.71-1.83A2.22,2.22,0,0,1,363.83,430.32Z"/>
                <path class="cls-4" d="M346.51,429a5.18,5.18,0,0,0-2.67-1.76c-.31-.07-.45.41-.13.48a4.68,4.68,0,0,1,2.44,1.63.25.25,0,1,0,.36-.35Z"/>
                <path class="cls-4" d="M343.93,427a11.64,11.64,0,0,0-10.09.95,9.18,9.18,0,0,0-4.14,7.76c.07,3.46,2.75,6.68,5.56,8.47a8.69,8.69,0,0,0,8.72.29,9.59,9.59,0,0,0,5-9.66c0-.31-.53-.32-.5,0a9.21,9.21,0,0,1-3.64,8.59,8.39,8.39,0,0,1-8.42.85,12.13,12.13,0,0,1-3.71-3.05,9.23,9.23,0,0,1-2.38-4.44,8.59,8.59,0,0,1,3.16-7.92,11,11,0,0,1,10.35-1.36c.3.11.43-.37.13-.48Z"/>
                <path class="cls-4" d="M351.75,427.77c-.76-1.09-1.63-2.29-2.93-2.57l-.06,0a11.16,11.16,0,0,0-10.57,1.4c-2.92,2.3-4.25,6.6-2.69,10a11.27,11.27,0,0,0,1.61,2.43,12.12,12.12,0,0,0,4.7,3.71A8.91,8.91,0,0,0,352.22,439,10.46,10.46,0,0,0,351.75,427.77Zm-1,4.81a2.51,2.51,0,0,1-2.88,1.28,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.55-1.51l.06,0c.62.17,1.4.29,1.74.9A2.25,2.25,0,0,1,350.78,432.58Z"/>
                <path class="cls-4" d="M393.28,432.09a5.16,5.16,0,0,0-3.11-.74.25.25,0,1,0,0,.5,4.66,4.66,0,0,1,2.86.67.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M390.18,431.1a11.78,11.78,0,0,0-8.81,3.9,9.2,9.2,0,0,0-1.73,8.77c1.12,3.34,4.55,5.45,7.81,6.33a8.69,8.69,0,0,0,8.7-2.56,9.6,9.6,0,0,0,1.37-10.87c-.13-.28-.56,0-.42.26a9.16,9.16,0,0,1-.66,9.49,8.31,8.31,0,0,1-8,3.39c-3.08-.55-6.71-2.54-8-5.5a8.66,8.66,0,0,1,.87-8.35,11.09,11.09,0,0,1,8.93-4.36c.32,0,.32-.51,0-.5Z"/>
                <path class="cls-4" d="M397.76,429.12c-1.08-.76-2.32-1.58-3.63-1.41h-.07a11.19,11.19,0,0,0-9.45,5c-1.95,3.16-1.72,7.66.91,10.28a11.08,11.08,0,0,0,2.34,1.73,12,12,0,0,0,5.69,1.87,8.94,8.94,0,0,0,8.53-7A10.47,10.47,0,0,0,397.76,429.12Zm.74,4.85a2.51,2.51,0,0,1-2.26,2.2,2.28,2.28,0,0,1-2.32-2c-.07-1.14.73-2.37,1.87-2.29h.07c.64-.06,1.42-.21,1.94.25A2.2,2.2,0,0,1,398.5,434Z"/>
                <path class="cls-4" d="M435.07,434.92a5.16,5.16,0,0,0-1.48,2.9c0,.13.12.25.25.25a.25.25,0,0,0,.24-.25,4.7,4.7,0,0,1,1.34-2.55c.22-.23-.13-.58-.35-.35Z"/>
                <path class="cls-4" d="M433.35,437.69a11.78,11.78,0,0,0,1.67,9.52,9.19,9.19,0,0,0,8.27,3.76c3.47-.35,6.37-3.24,7.94-6.21a8.69,8.69,0,0,0-.59-9,9.61,9.61,0,0,0-10.09-3.8c-.31.06-.18.54.13.48a9.17,9.17,0,0,1,9,2.91,8.34,8.34,0,0,1,1.5,8.3,13,13,0,0,1-3,4.22,8.46,8.46,0,0,1-4.16,2.51,8.61,8.61,0,0,1-8.1-2.69,11.09,11.09,0,0,1-2.17-9.83.25.25,0,0,0-.48-.13Z"/>
                <path class="cls-4" d="M433.28,429.85c-1,.87-2.1,1.87-2.25,3.19l0,.06a11.18,11.18,0,0,0,2.52,10.37c2.59,2.66,7,3.53,10.19,1.62a11.43,11.43,0,0,0,2.25-1.85,12,12,0,0,0,3.19-5.07,8.92,8.92,0,0,0-4.71-10A10.45,10.45,0,0,0,433.28,429.85Zm4.88.47a2.49,2.49,0,0,1,1.58,2.72,2.28,2.28,0,0,1-2.54,1.76c-1.12-.21-2.12-1.29-1.76-2.37v-.06c.1-.65.14-1.43.71-1.83A2.21,2.21,0,0,1,438.16,430.32Z"/>
                <path class="cls-4" d="M420.83,429a5.12,5.12,0,0,0-2.66-1.76c-.31-.07-.45.41-.13.48a4.68,4.68,0,0,1,2.44,1.63.25.25,0,0,0,.35,0,.24.24,0,0,0,0-.35Z"/>
                <path class="cls-4" d="M418.26,427a11.64,11.64,0,0,0-10.09.95,9.18,9.18,0,0,0-4.14,7.76c.07,3.46,2.75,6.68,5.56,8.47a8.69,8.69,0,0,0,8.72.29,9.59,9.59,0,0,0,5-9.66c0-.31-.53-.32-.5,0a9.21,9.21,0,0,1-3.64,8.59,8.39,8.39,0,0,1-8.42.85,12.13,12.13,0,0,1-3.71-3.05,9.23,9.23,0,0,1-2.38-4.44,8.59,8.59,0,0,1,3.16-7.92,11,11,0,0,1,10.35-1.36c.3.11.43-.37.13-.48Z"/>
                <path class="cls-4" d="M426.08,427.77c-.76-1.09-1.63-2.29-2.93-2.57l-.06,0a11.16,11.16,0,0,0-10.57,1.4c-2.92,2.3-4.25,6.6-2.69,10a11.27,11.27,0,0,0,1.61,2.43,12.12,12.12,0,0,0,4.7,3.71A8.91,8.91,0,0,0,426.55,439,10.46,10.46,0,0,0,426.08,427.77Zm-1,4.81a2.51,2.51,0,0,1-2.88,1.28,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.55-1.51l.06,0c.62.17,1.4.29,1.74.9A2.25,2.25,0,0,1,425.11,432.58Z"/>
                <path class="cls-4" d="M162.21,457.91a5.06,5.06,0,0,0-3.1-.74.25.25,0,0,0-.25.24.24.24,0,0,0,.25.25,4.69,4.69,0,0,1,2.85.68c.28.15.53-.28.25-.43Z"/>
                <path class="cls-4" d="M159.11,456.92a11.76,11.76,0,0,0-8.81,3.9,9.23,9.23,0,0,0-1.73,8.77c1.12,3.34,4.55,5.45,7.82,6.33a8.72,8.72,0,0,0,8.7-2.56,9.63,9.63,0,0,0,1.37-10.87c-.14-.29-.57,0-.43.25a9.15,9.15,0,0,1-.65,9.5,8.3,8.3,0,0,1-8,3.38c-3.08-.55-6.71-2.54-8-5.5a8.66,8.66,0,0,1,.87-8.34,11.13,11.13,0,0,1,8.93-4.37.25.25,0,0,0,0-.49Z"/>
                <path class="cls-4" d="M166.7,454.94c-1.09-.76-2.32-1.59-3.63-1.41H163a11.2,11.2,0,0,0-9.45,5,8.6,8.6,0,0,0,.9,10.29,11.4,11.4,0,0,0,2.35,1.73,12,12,0,0,0,5.69,1.87,8.92,8.92,0,0,0,8.52-7A10.47,10.47,0,0,0,166.7,454.94Zm.73,4.85a2.47,2.47,0,0,1-2.25,2.19,2.28,2.28,0,0,1-2.33-2c-.07-1.14.74-2.37,1.88-2.29h.06a2.57,2.57,0,0,1,2,.24A2.22,2.22,0,0,1,167.43,459.79Z"/>
                <path class="cls-4" d="M204,460.74a5.24,5.24,0,0,0-1.49,2.9.25.25,0,0,0,.5,0,4.7,4.7,0,0,1,1.34-2.55c.22-.23-.13-.58-.35-.35Z"/>
                <path class="cls-4" d="M202.29,463.51A11.75,11.75,0,0,0,204,473a9.16,9.16,0,0,0,8.26,3.76c3.47-.35,6.37-3.24,7.94-6.21a8.71,8.71,0,0,0-.58-9,9.62,9.62,0,0,0-10.09-3.8c-.32.06-.19.54.13.47a9.16,9.16,0,0,1,9,2.92,8.34,8.34,0,0,1,1.5,8.3,12.66,12.66,0,0,1-3,4.21,8.38,8.38,0,0,1-4.15,2.52,8.64,8.64,0,0,1-8.1-2.7,11,11,0,0,1-2.17-9.82c.07-.31-.41-.45-.48-.13Z"/>
                <path class="cls-4" d="M202.21,455.67c-1,.87-2.1,1.87-2.25,3.18l0,.07a11.21,11.21,0,0,0,2.51,10.37,8.59,8.59,0,0,0,10.2,1.62,11.36,11.36,0,0,0,2.24-1.86,12.11,12.11,0,0,0,3.2-5.06,8.91,8.91,0,0,0-4.71-10A10.47,10.47,0,0,0,202.21,455.67Zm4.89.47a2.5,2.5,0,0,1,1.58,2.72,2.29,2.29,0,0,1-2.54,1.76,2,2,0,0,1-1.77-2.38l0-.06c.1-.64.14-1.43.71-1.83A2.21,2.21,0,0,1,207.1,456.14Z"/>
                <path class="cls-4" d="M189.77,454.82a5.14,5.14,0,0,0-2.67-1.76c-.31-.07-.44.41-.13.48a4.7,4.7,0,0,1,2.45,1.63.25.25,0,0,0,.35-.35Z"/>
                <path class="cls-4" d="M187.19,452.83a11.61,11.61,0,0,0-10.08.94,9.19,9.19,0,0,0-4.15,7.77c.08,3.46,2.75,6.68,5.56,8.47a8.69,8.69,0,0,0,8.72.29,9.6,9.6,0,0,0,5-9.66c0-.32-.52-.32-.49,0a9.19,9.19,0,0,1-3.65,8.58,8.4,8.4,0,0,1-8.41.86,12.31,12.31,0,0,1-3.72-3.05,9.21,9.21,0,0,1-2.37-4.44,8.56,8.56,0,0,1,3.16-7.93,11,11,0,0,1,10.34-1.35c.3.1.43-.38.13-.48Z"/>
                <path class="cls-4" d="M195,453.59c-.76-1.09-1.64-2.29-2.93-2.58l-.06,0a11.21,11.21,0,0,0-10.58,1.41,8.58,8.58,0,0,0-2.68,10,11.15,11.15,0,0,0,1.61,2.43,12.05,12.05,0,0,0,4.7,3.71,8.94,8.94,0,0,0,10.41-3.64A10.46,10.46,0,0,0,195,453.59Zm-1,4.8a2.49,2.49,0,0,1-2.87,1.29,2.3,2.3,0,0,1-1.49-2.71,2,2,0,0,1,2.55-1.51l.06,0c.63.17,1.41.29,1.75.9A2.22,2.22,0,0,1,194,458.39Z"/>
                <path class="cls-4" d="M236.54,457.91a5.06,5.06,0,0,0-3.1-.74.25.25,0,0,0-.25.24.24.24,0,0,0,.25.25,4.69,4.69,0,0,1,2.85.68c.28.15.53-.28.25-.43Z"/>
                <path class="cls-4" d="M233.44,456.92a11.76,11.76,0,0,0-8.81,3.9,9.23,9.23,0,0,0-1.73,8.77c1.12,3.34,4.55,5.45,7.82,6.33a8.72,8.72,0,0,0,8.7-2.56,9.63,9.63,0,0,0,1.37-10.87c-.14-.29-.57,0-.43.25a9.18,9.18,0,0,1-.65,9.5,8.3,8.3,0,0,1-8,3.38c-3.08-.55-6.71-2.54-8-5.5a8.66,8.66,0,0,1,.87-8.34,11.11,11.11,0,0,1,8.93-4.37.25.25,0,0,0,0-.49Z"/>
                <path class="cls-4" d="M241,454.94c-1.09-.76-2.32-1.59-3.64-1.41h-.06a11.2,11.2,0,0,0-9.45,5,8.6,8.6,0,0,0,.9,10.29,11.14,11.14,0,0,0,2.35,1.73,12,12,0,0,0,5.68,1.87,8.92,8.92,0,0,0,8.53-7A10.47,10.47,0,0,0,241,454.94Zm.73,4.85a2.47,2.47,0,0,1-2.25,2.19,2.27,2.27,0,0,1-2.33-2c-.07-1.14.74-2.37,1.88-2.29h.06a2.57,2.57,0,0,1,1.95.24A2.25,2.25,0,0,1,241.76,459.79Z"/>
                <path class="cls-4" d="M278.34,460.74a5.24,5.24,0,0,0-1.49,2.9.25.25,0,0,0,.5,0,4.7,4.7,0,0,1,1.34-2.55c.22-.23-.13-.58-.35-.35Z"/>
                <path class="cls-4" d="M276.62,463.51a11.75,11.75,0,0,0,1.67,9.52,9.16,9.16,0,0,0,8.26,3.76c3.47-.35,6.37-3.24,7.94-6.21a8.71,8.71,0,0,0-.58-9,9.63,9.63,0,0,0-10.1-3.8c-.31.06-.18.54.14.47a9.16,9.16,0,0,1,9,2.92,8.34,8.34,0,0,1,1.5,8.3,12.81,12.81,0,0,1-3,4.21,8.38,8.38,0,0,1-4.15,2.52,8.63,8.63,0,0,1-8.1-2.7,11,11,0,0,1-2.17-9.82c.07-.31-.41-.45-.48-.13Z"/>
                <path class="cls-4" d="M276.54,455.67c-1,.87-2.1,1.87-2.25,3.18l0,.07a11.21,11.21,0,0,0,2.51,10.37,8.59,8.59,0,0,0,10.2,1.62,11.36,11.36,0,0,0,2.24-1.86,12.11,12.11,0,0,0,3.2-5.06,8.91,8.91,0,0,0-4.71-10A10.47,10.47,0,0,0,276.54,455.67Zm4.89.47a2.5,2.5,0,0,1,1.58,2.72,2.29,2.29,0,0,1-2.54,1.76,2,2,0,0,1-1.77-2.38l0-.06c.1-.64.14-1.43.71-1.83A2.21,2.21,0,0,1,281.43,456.14Z"/>
                <path class="cls-4" d="M264.1,454.82a5.14,5.14,0,0,0-2.67-1.76c-.31-.07-.44.41-.13.48a4.7,4.7,0,0,1,2.45,1.63.25.25,0,0,0,.35-.35Z"/>
                <path class="cls-4" d="M261.52,452.83a11.64,11.64,0,0,0-10.09.94,9.21,9.21,0,0,0-4.14,7.77c.08,3.46,2.75,6.68,5.56,8.47a8.69,8.69,0,0,0,8.72.29,9.6,9.6,0,0,0,5-9.66c0-.32-.53-.32-.5,0a9.16,9.16,0,0,1-3.64,8.58,8.4,8.4,0,0,1-8.41.86,12.19,12.19,0,0,1-3.72-3.05,9.21,9.21,0,0,1-2.37-4.44,8.56,8.56,0,0,1,3.16-7.93,11,11,0,0,1,10.34-1.35c.3.1.43-.38.13-.48Z"/>
                <path class="cls-4" d="M269.35,453.59c-.76-1.09-1.64-2.29-2.93-2.58l-.06,0a11.21,11.21,0,0,0-10.58,1.41,8.58,8.58,0,0,0-2.68,10,11.48,11.48,0,0,0,1.6,2.43,12.15,12.15,0,0,0,4.71,3.71,8.94,8.94,0,0,0,10.41-3.64A10.48,10.48,0,0,0,269.35,453.59Zm-1,4.8a2.49,2.49,0,0,1-2.87,1.29A2.3,2.3,0,0,1,264,457a2,2,0,0,1,2.55-1.51l.06,0c.63.17,1.41.29,1.74.9A2.19,2.19,0,0,1,268.37,458.39Z"/>
                <path class="cls-4" d="M14.09,457.91a5.09,5.09,0,0,0-3.11-.74.24.24,0,0,0-.24.24c0,.13.11.27.24.25a4.72,4.72,0,0,1,2.86.68c.28.15.53-.28.25-.43Z"/>
                <path class="cls-4" d="M11,456.92a11.74,11.74,0,0,0-8.81,3.9,9.2,9.2,0,0,0-1.73,8.77C1.57,472.93,5,475,8.27,475.92a8.72,8.72,0,0,0,8.7-2.56,9.63,9.63,0,0,0,1.37-10.87c-.14-.29-.57,0-.43.25a9.2,9.2,0,0,1-.65,9.5,8.31,8.31,0,0,1-8,3.38c-3.08-.55-6.7-2.54-8-5.5a8.64,8.64,0,0,1,.87-8.34A11.1,11.1,0,0,1,11,457.41a.25.25,0,0,0,0-.49Z"/>
                <path class="cls-4" d="M18.57,454.94c-1.08-.76-2.31-1.59-3.63-1.41h-.07a11.23,11.23,0,0,0-9.45,5,8.6,8.6,0,0,0,.91,10.29,11.34,11.34,0,0,0,2.34,1.73,12.08,12.08,0,0,0,5.69,1.87,8.93,8.93,0,0,0,8.53-7A10.47,10.47,0,0,0,18.57,454.94Zm.74,4.85A2.49,2.49,0,0,1,17.05,462a2.27,2.27,0,0,1-2.32-2c-.07-1.14.73-2.37,1.87-2.29h.07c.64-.06,1.42-.21,1.94.24A2.23,2.23,0,0,1,19.31,459.79Z"/>
                <path class="cls-4" d="M55.88,460.74a5.16,5.16,0,0,0-1.48,2.9c0,.13.13.24.25.24a.25.25,0,0,0,.25-.24,4.57,4.57,0,0,1,1.34-2.55c.21-.23-.14-.58-.36-.35Z"/>
                <path class="cls-4" d="M54.17,463.51A11.78,11.78,0,0,0,55.83,473a9.19,9.19,0,0,0,8.27,3.76c3.47-.35,6.37-3.24,7.94-6.21a8.69,8.69,0,0,0-.59-9,9.61,9.61,0,0,0-10.09-3.8c-.31.06-.18.54.13.47a9.18,9.18,0,0,1,9,2.92,8.34,8.34,0,0,1,1.5,8.3,12.81,12.81,0,0,1-3,4.21,8.41,8.41,0,0,1-4.16,2.52,8.61,8.61,0,0,1-8.09-2.7,11,11,0,0,1-2.17-9.82c.07-.31-.41-.45-.48-.13Z"/>
                <path class="cls-4" d="M54.09,455.67c-1,.87-2.1,1.87-2.25,3.18l0,.07a11.21,11.21,0,0,0,2.51,10.37,8.59,8.59,0,0,0,10.2,1.62,11.36,11.36,0,0,0,2.24-1.86A12.11,12.11,0,0,0,70,464a8.93,8.93,0,0,0-4.72-10A10.46,10.46,0,0,0,54.09,455.67Zm4.89.47a2.48,2.48,0,0,1,1.57,2.72A2.28,2.28,0,0,1,58,460.62a2,2,0,0,1-1.76-2.38v-.06c.11-.64.14-1.43.71-1.83A2.23,2.23,0,0,1,59,456.14Z"/>
                <path class="cls-4" d="M41.65,454.82A5.18,5.18,0,0,0,39,453.06c-.31-.07-.44.41-.13.48a4.68,4.68,0,0,1,2.44,1.63.25.25,0,0,0,.36-.35Z"/>
                <path class="cls-4" d="M39.07,452.83a11.64,11.64,0,0,0-10.09.94,9.21,9.21,0,0,0-4.14,7.77c.08,3.46,2.75,6.68,5.56,8.47a8.69,8.69,0,0,0,8.72.29,9.6,9.6,0,0,0,5-9.66c0-.32-.53-.32-.5,0a9.18,9.18,0,0,1-3.64,8.58,8.42,8.42,0,0,1-8.42.86A12.27,12.27,0,0,1,27.81,467a9.32,9.32,0,0,1-2.38-4.44,8.61,8.61,0,0,1,3.16-7.93,11,11,0,0,1,10.35-1.35c.3.1.43-.38.13-.48Z"/>
                <path class="cls-4" d="M46.89,453.59c-.76-1.09-1.63-2.29-2.92-2.58l-.07,0a11.19,11.19,0,0,0-10.57,1.41,8.58,8.58,0,0,0-2.69,10,11.52,11.52,0,0,0,1.61,2.43A12.12,12.12,0,0,0,37,468.5a8.94,8.94,0,0,0,10.41-3.64A10.44,10.44,0,0,0,46.89,453.59Zm-1,4.8A2.5,2.5,0,0,1,43,459.68,2.28,2.28,0,0,1,41.56,457a2,2,0,0,1,2.55-1.51l.06,0c.62.17,1.4.29,1.74.9A2.22,2.22,0,0,1,45.92,458.39Z"/>
                <path class="cls-4" d="M88.42,457.91a5.09,5.09,0,0,0-3.11-.74.25.25,0,0,0-.25.24c0,.13.12.27.25.25a4.72,4.72,0,0,1,2.86.68c.28.15.53-.28.25-.43Z"/>
                <path class="cls-4" d="M85.32,456.92a11.74,11.74,0,0,0-8.81,3.9,9.2,9.2,0,0,0-1.73,8.77c1.12,3.34,4.55,5.45,7.81,6.33a8.73,8.73,0,0,0,8.71-2.56,9.64,9.64,0,0,0,1.37-10.87c-.14-.29-.57,0-.43.25a9.18,9.18,0,0,1-.66,9.5,8.28,8.28,0,0,1-8,3.38c-3.08-.55-6.7-2.54-8-5.5a8.64,8.64,0,0,1,.87-8.34,11.1,11.1,0,0,1,8.93-4.37.25.25,0,0,0,0-.49Z"/>
                <path class="cls-4" d="M92.9,454.94c-1.08-.76-2.31-1.59-3.63-1.41H89.2a11.23,11.23,0,0,0-9.45,5,8.58,8.58,0,0,0,.91,10.29A11.08,11.08,0,0,0,83,470.49a12,12,0,0,0,5.69,1.87,8.93,8.93,0,0,0,8.53-7A10.47,10.47,0,0,0,92.9,454.94Zm.74,4.85A2.49,2.49,0,0,1,91.38,462a2.27,2.27,0,0,1-2.32-2c-.07-1.14.73-2.37,1.87-2.29H91c.64-.06,1.42-.21,1.94.24A2.23,2.23,0,0,1,93.64,459.79Z"/>
                <path class="cls-4" d="M130.21,460.74a5.16,5.16,0,0,0-1.48,2.9c0,.13.12.24.25.24a.26.26,0,0,0,.25-.24,4.62,4.62,0,0,1,1.33-2.55c.22-.23-.13-.58-.35-.35Z"/>
                <path class="cls-4" d="M128.5,463.51a11.74,11.74,0,0,0,1.66,9.52,9.19,9.19,0,0,0,8.27,3.76c3.47-.35,6.37-3.24,7.94-6.21a8.69,8.69,0,0,0-.59-9,9.61,9.61,0,0,0-10.09-3.8c-.31.06-.18.54.13.47a9.18,9.18,0,0,1,9,2.92,8.34,8.34,0,0,1,1.5,8.3,12.81,12.81,0,0,1-3,4.21,8.41,8.41,0,0,1-4.16,2.52,8.61,8.61,0,0,1-8.09-2.7,11,11,0,0,1-2.17-9.82c.07-.31-.41-.45-.48-.13Z"/>
                <path class="cls-4" d="M128.42,455.67c-1,.87-2.1,1.87-2.25,3.18l0,.07a11.18,11.18,0,0,0,2.51,10.37,8.58,8.58,0,0,0,10.19,1.62,11.14,11.14,0,0,0,2.25-1.86,12,12,0,0,0,3.19-5.06,8.91,8.91,0,0,0-4.71-10A10.46,10.46,0,0,0,128.42,455.67Zm4.88.47a2.47,2.47,0,0,1,1.58,2.72,2.28,2.28,0,0,1-2.54,1.76,2,2,0,0,1-1.76-2.38v-.06c.11-.64.14-1.43.71-1.83A2.21,2.21,0,0,1,133.3,456.14Z"/>
                <path class="cls-4" d="M116,454.82a5.18,5.18,0,0,0-2.67-1.76c-.31-.07-.45.41-.13.48a4.68,4.68,0,0,1,2.44,1.63.25.25,0,1,0,.36-.35Z"/>
                <path class="cls-4" d="M113.4,452.83a11.64,11.64,0,0,0-10.09.94,9.21,9.21,0,0,0-4.14,7.77c.08,3.46,2.75,6.68,5.56,8.47a8.69,8.69,0,0,0,8.72.29,9.6,9.6,0,0,0,5-9.66c0-.32-.53-.32-.5,0a9.18,9.18,0,0,1-3.64,8.58,8.42,8.42,0,0,1-8.42.86,12.27,12.27,0,0,1-3.71-3.05,9.32,9.32,0,0,1-2.38-4.44,8.61,8.61,0,0,1,3.16-7.93,11,11,0,0,1,10.35-1.35c.3.1.43-.38.13-.48Z"/>
                <path class="cls-4" d="M121.22,453.59c-.76-1.09-1.63-2.29-2.93-2.58l-.06,0a11.19,11.19,0,0,0-10.57,1.41,8.58,8.58,0,0,0-2.69,10,11.52,11.52,0,0,0,1.61,2.43,12.12,12.12,0,0,0,4.7,3.71,8.94,8.94,0,0,0,10.41-3.64A10.46,10.46,0,0,0,121.22,453.59Zm-1,4.8a2.51,2.51,0,0,1-2.88,1.29,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.55-1.51l.06,0c.62.17,1.4.29,1.74.9A2.22,2.22,0,0,1,120.25,458.39Z"/>
                <path class="cls-4" d="M168.1,482.51a5.18,5.18,0,0,0-3.11-.75.27.27,0,0,0-.25.25c0,.12.12.27.25.25a4.67,4.67,0,0,1,2.86.68c.28.15.53-.28.25-.43Z"/>
                <path class="cls-4" d="M165,481.51a11.77,11.77,0,0,0-8.81,3.91,9.21,9.21,0,0,0-1.72,8.76c1.11,3.34,4.55,5.46,7.81,6.34A8.72,8.72,0,0,0,171,498a9.61,9.61,0,0,0,1.37-10.86c-.14-.29-.56,0-.43.25a9.15,9.15,0,0,1-.65,9.5,8.31,8.31,0,0,1-8,3.38c-3.08-.55-6.71-2.54-8-5.5a8.64,8.64,0,0,1,.88-8.34A11.09,11.09,0,0,1,165,482a.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M172.58,479.54c-1.09-.76-2.32-1.59-3.63-1.41h-.07a11.19,11.19,0,0,0-9.45,4.95,8.6,8.6,0,0,0,.9,10.29,11.4,11.4,0,0,0,2.35,1.73,12.15,12.15,0,0,0,5.69,1.87,9,9,0,0,0,8.53-7A10.49,10.49,0,0,0,172.58,479.54Zm.74,4.85a2.5,2.5,0,0,1-2.26,2.19,2.29,2.29,0,0,1-2.33-2c-.06-1.14.74-2.36,1.88-2.28h.06c.65-.05,1.42-.21,2,.25A2.23,2.23,0,0,1,173.32,484.39Z"/>
                <path class="cls-4" d="M209.89,485.34a5.14,5.14,0,0,0-1.48,2.89.24.24,0,0,0,.24.25.25.25,0,0,0,.25-.25,4.67,4.67,0,0,1,1.34-2.54c.22-.23-.13-.59-.35-.35Z"/>
                <path class="cls-4" d="M208.17,488.1a11.76,11.76,0,0,0,1.67,9.53,9.21,9.21,0,0,0,8.26,3.76c3.48-.36,6.37-3.25,8-6.21a8.73,8.73,0,0,0-.59-9.05,9.62,9.62,0,0,0-10.09-3.8c-.32.07-.18.55.13.48a9.15,9.15,0,0,1,9,2.91A8.34,8.34,0,0,1,226,494a12.64,12.64,0,0,1-2.95,4.21,8.45,8.45,0,0,1-4.16,2.52,8.64,8.64,0,0,1-8.1-2.7,11.06,11.06,0,0,1-2.17-9.82c.07-.32-.4-.45-.48-.14Z"/>
                <path class="cls-4" d="M208.1,480.27c-1,.87-2.1,1.86-2.25,3.18l0,.07a11.19,11.19,0,0,0,2.51,10.36,8.6,8.6,0,0,0,10.2,1.63,11.14,11.14,0,0,0,2.25-1.86,12,12,0,0,0,3.19-5.06,8.91,8.91,0,0,0-4.71-10A10.45,10.45,0,0,0,208.1,480.27Zm4.88.46a2.52,2.52,0,0,1,1.58,2.73,2.29,2.29,0,0,1-2.54,1.76,2,2,0,0,1-1.77-2.38l0-.06c.1-.64.14-1.43.71-1.83A2.24,2.24,0,0,1,213,480.73Z"/>
                <path class="cls-4" d="M195.65,479.41a5.14,5.14,0,0,0-2.66-1.75c-.32-.07-.45.41-.14.48a4.64,4.64,0,0,1,2.45,1.63.25.25,0,0,0,.35,0,.26.26,0,0,0,0-.36Z"/>
                <path class="cls-4" d="M193.08,477.43a11.64,11.64,0,0,0-10.09.94,9.18,9.18,0,0,0-4.15,7.77c.08,3.46,2.76,6.67,5.57,8.47a8.69,8.69,0,0,0,8.72.28,9.56,9.56,0,0,0,5-9.65c0-.32-.52-.32-.49,0a9.17,9.17,0,0,1-3.65,8.58,8.38,8.38,0,0,1-8.41.85,12.25,12.25,0,0,1-3.72-3,9.38,9.38,0,0,1-2.37-4.44,8.55,8.55,0,0,1,3.16-7.93,11,11,0,0,1,10.34-1.35c.31.1.44-.38.14-.48Z"/>
                <path class="cls-4" d="M200.9,478.18c-.76-1.08-1.63-2.28-2.93-2.57l-.06,0A11.18,11.18,0,0,0,187.33,477a8.59,8.59,0,0,0-2.68,10,11.52,11.52,0,0,0,1.61,2.43,12,12,0,0,0,4.7,3.7,8.92,8.92,0,0,0,10.41-3.63A10.48,10.48,0,0,0,200.9,478.18Zm-1,4.81a2.49,2.49,0,0,1-2.87,1.28,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.54-1.5l.06,0c.63.17,1.41.29,1.75.9A2.22,2.22,0,0,1,199.92,483Z"/>
                <path class="cls-4" d="M242.43,482.51a5.18,5.18,0,0,0-3.11-.75.27.27,0,0,0-.25.25c0,.12.11.27.25.25a4.62,4.62,0,0,1,2.85.68c.28.15.54-.28.26-.43Z"/>
                <path class="cls-4" d="M239.32,481.51a11.77,11.77,0,0,0-8.81,3.91,9.24,9.24,0,0,0-1.73,8.76c1.12,3.34,4.56,5.46,7.82,6.34a8.72,8.72,0,0,0,8.7-2.57,9.61,9.61,0,0,0,1.37-10.86c-.14-.29-.56,0-.43.25a9.15,9.15,0,0,1-.65,9.5,8.32,8.32,0,0,1-8,3.38c-3.08-.55-6.71-2.54-8-5.5a8.64,8.64,0,0,1,.88-8.34,11.09,11.09,0,0,1,8.92-4.37.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M246.91,479.54c-1.09-.76-2.32-1.59-3.63-1.41h-.07a11.18,11.18,0,0,0-9.45,4.95,8.6,8.6,0,0,0,.9,10.29,11.4,11.4,0,0,0,2.35,1.73A12.15,12.15,0,0,0,242.7,497a8.93,8.93,0,0,0,8.52-7A10.47,10.47,0,0,0,246.91,479.54Zm.74,4.85a2.5,2.5,0,0,1-2.26,2.19,2.29,2.29,0,0,1-2.33-2c-.06-1.14.74-2.36,1.88-2.28H245c.65-.05,1.42-.21,1.95.25A2.26,2.26,0,0,1,247.65,484.39Z"/>
                <path class="cls-4" d="M284.22,485.34a5.21,5.21,0,0,0-1.49,2.89.25.25,0,0,0,.25.25.25.25,0,0,0,.25-.25,4.67,4.67,0,0,1,1.34-2.54c.22-.23-.13-.59-.35-.35Z"/>
                <path class="cls-4" d="M282.5,488.1a11.76,11.76,0,0,0,1.67,9.53,9.2,9.2,0,0,0,8.26,3.76c3.48-.36,6.37-3.25,7.94-6.21a8.71,8.71,0,0,0-.58-9.05,9.62,9.62,0,0,0-10.09-3.8c-.32.07-.18.55.13.48a9.15,9.15,0,0,1,9,2.91,8.34,8.34,0,0,1,1.5,8.31,12.78,12.78,0,0,1-2.95,4.21,8.45,8.45,0,0,1-4.16,2.52,8.64,8.64,0,0,1-8.1-2.7,11.06,11.06,0,0,1-2.17-9.82c.07-.32-.41-.45-.48-.14Z"/>
                <path class="cls-4" d="M282.43,480.27c-1,.87-2.11,1.86-2.25,3.18l0,.07a11.19,11.19,0,0,0,2.51,10.36,8.6,8.6,0,0,0,10.2,1.63,11.14,11.14,0,0,0,2.25-1.86,12,12,0,0,0,3.19-5.06,8.91,8.91,0,0,0-4.71-10A10.45,10.45,0,0,0,282.43,480.27Zm4.88.46a2.52,2.52,0,0,1,1.58,2.73,2.29,2.29,0,0,1-2.54,1.76,2,2,0,0,1-1.77-2.38l0-.06c.1-.64.14-1.43.71-1.83A2.24,2.24,0,0,1,287.31,480.73Z"/>
                <path class="cls-4" d="M270,479.41a5.14,5.14,0,0,0-2.66-1.75c-.32-.07-.45.41-.14.48a4.64,4.64,0,0,1,2.45,1.63.25.25,0,0,0,.35,0,.26.26,0,0,0,0-.36Z"/>
                <path class="cls-4" d="M267.41,477.43a11.64,11.64,0,0,0-10.09.94,9.18,9.18,0,0,0-4.15,7.77c.08,3.46,2.75,6.67,5.57,8.47a8.69,8.69,0,0,0,8.72.28,9.57,9.57,0,0,0,5-9.65c0-.32-.52-.32-.49,0a9.19,9.19,0,0,1-3.65,8.58,8.38,8.38,0,0,1-8.41.85,12.25,12.25,0,0,1-3.72-3,9.38,9.38,0,0,1-2.37-4.44,8.55,8.55,0,0,1,3.16-7.93,11,11,0,0,1,10.34-1.35c.31.1.44-.38.14-.48Z"/>
                <path class="cls-4" d="M275.23,478.18c-.76-1.08-1.63-2.28-2.93-2.57l-.06,0A11.18,11.18,0,0,0,261.66,477,8.59,8.59,0,0,0,259,487a11.52,11.52,0,0,0,1.61,2.43,11.94,11.94,0,0,0,4.7,3.7,8.92,8.92,0,0,0,10.41-3.63A10.48,10.48,0,0,0,275.23,478.18Zm-1,4.81a2.49,2.49,0,0,1-2.87,1.28,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.54-1.5l.06,0c.63.17,1.41.29,1.75.9A2.22,2.22,0,0,1,274.25,483Z"/>
                <path class="cls-4" d="M20,482.51a5.14,5.14,0,0,0-3.1-.75.26.26,0,0,0-.25.25c0,.12.11.27.25.25a4.62,4.62,0,0,1,2.85.68c.28.15.53-.28.25-.43Z"/>
                <path class="cls-4" d="M16.87,481.51a11.77,11.77,0,0,0-8.81,3.91,9.21,9.21,0,0,0-1.73,8.76c1.12,3.34,4.55,5.46,7.82,6.34a8.72,8.72,0,0,0,8.7-2.57,9.61,9.61,0,0,0,1.37-10.86c-.14-.29-.57,0-.43.25a9.18,9.18,0,0,1-.65,9.5,8.32,8.32,0,0,1-8,3.38c-3.09-.55-6.71-2.54-8-5.5a8.66,8.66,0,0,1,.87-8.34A11.11,11.11,0,0,1,16.87,482a.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M24.46,479.54c-1.09-.76-2.32-1.59-3.64-1.41h-.06a11.18,11.18,0,0,0-9.45,4.95,8.6,8.6,0,0,0,.9,10.29,11.34,11.34,0,0,0,2.34,1.73A12.23,12.23,0,0,0,20.24,497a8.93,8.93,0,0,0,8.53-7A10.47,10.47,0,0,0,24.46,479.54Zm.73,4.85a2.48,2.48,0,0,1-2.25,2.19,2.28,2.28,0,0,1-2.33-2,2,2,0,0,1,1.88-2.28h.06c.65-.05,1.42-.21,1.95.25A2.25,2.25,0,0,1,25.19,484.39Z"/>
                <path class="cls-4" d="M61.77,485.34a5.21,5.21,0,0,0-1.49,2.89.25.25,0,0,0,.5,0,4.67,4.67,0,0,1,1.34-2.54c.22-.23-.13-.59-.35-.35Z"/>
                <path class="cls-4" d="M60.05,488.1a11.76,11.76,0,0,0,1.67,9.53A9.2,9.2,0,0,0,70,501.39c3.47-.36,6.37-3.25,7.94-6.21a8.73,8.73,0,0,0-.58-9.05,9.65,9.65,0,0,0-10.1-3.8c-.31.07-.18.55.14.48a9.15,9.15,0,0,1,9,2.91,8.36,8.36,0,0,1,1.5,8.31,12.81,12.81,0,0,1-3,4.21,8.38,8.38,0,0,1-4.15,2.52,8.62,8.62,0,0,1-8.1-2.7,11,11,0,0,1-2.17-9.82c.07-.32-.41-.45-.48-.14Z"/>
                <path class="cls-4" d="M60,480.27c-1,.87-2.1,1.86-2.25,3.18l0,.07a11.19,11.19,0,0,0,2.51,10.36,8.6,8.6,0,0,0,10.2,1.63,11.36,11.36,0,0,0,2.24-1.86,12.11,12.11,0,0,0,3.2-5.06,8.93,8.93,0,0,0-4.71-10A10.47,10.47,0,0,0,60,480.27Zm4.89.46a2.5,2.5,0,0,1,1.57,2.73,2.27,2.27,0,0,1-2.53,1.76,2,2,0,0,1-1.77-2.38l0-.06c.1-.64.14-1.43.71-1.83A2.24,2.24,0,0,1,64.86,480.73Z"/>
                <path class="cls-4" d="M47.53,479.41a5.13,5.13,0,0,0-2.67-1.75c-.31-.07-.44.41-.13.48a4.64,4.64,0,0,1,2.45,1.63.25.25,0,0,0,.35,0,.26.26,0,0,0,0-.36Z"/>
                <path class="cls-4" d="M45,477.43a11.64,11.64,0,0,0-10.09.94,9.19,9.19,0,0,0-4.14,7.77c.08,3.46,2.75,6.67,5.56,8.47a8.69,8.69,0,0,0,8.72.28,9.58,9.58,0,0,0,5-9.65c0-.32-.53-.32-.5,0a9.18,9.18,0,0,1-3.64,8.58,8.38,8.38,0,0,1-8.41.85,12,12,0,0,1-3.72-3,9.21,9.21,0,0,1-2.37-4.44,8.55,8.55,0,0,1,3.16-7.93,11,11,0,0,1,10.34-1.35c.3.1.43-.38.13-.48Z"/>
                <path class="cls-4" d="M52.78,478.18c-.76-1.08-1.64-2.28-2.93-2.57l-.06,0A11.18,11.18,0,0,0,39.21,477a8.58,8.58,0,0,0-2.68,10,11.1,11.1,0,0,0,1.6,2.43,12,12,0,0,0,4.71,3.7,8.93,8.93,0,0,0,10.41-3.63A10.5,10.5,0,0,0,52.78,478.18Zm-1,4.81a2.49,2.49,0,0,1-2.87,1.28,2.29,2.29,0,0,1-1.49-2.71,2,2,0,0,1,2.55-1.5l.06,0c.63.17,1.41.29,1.74.9A2.19,2.19,0,0,1,51.8,483Z"/>
                <path class="cls-4" d="M94.3,482.51a5.14,5.14,0,0,0-3.1-.75A.26.26,0,0,0,91,482c0,.12.11.27.25.25a4.64,4.64,0,0,1,2.85.68c.28.15.53-.28.25-.43Z"/>
                <path class="cls-4" d="M91.2,481.51a11.75,11.75,0,0,0-8.81,3.91,9.21,9.21,0,0,0-1.73,8.76c1.12,3.34,4.55,5.46,7.82,6.34a8.72,8.72,0,0,0,8.7-2.57,9.61,9.61,0,0,0,1.37-10.86c-.14-.29-.57,0-.43.25a9.18,9.18,0,0,1-.65,9.5,8.33,8.33,0,0,1-8,3.38c-3.08-.55-6.7-2.54-8-5.5a8.66,8.66,0,0,1,.87-8.34A11.1,11.1,0,0,1,91.2,482a.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M98.79,479.54c-1.09-.76-2.32-1.59-3.64-1.41h-.07a11.18,11.18,0,0,0-9.44,4.95,8.58,8.58,0,0,0,.9,10.29,11.34,11.34,0,0,0,2.34,1.73A12.23,12.23,0,0,0,94.57,497a8.93,8.93,0,0,0,8.53-7A10.47,10.47,0,0,0,98.79,479.54Zm.73,4.85a2.48,2.48,0,0,1-2.26,2.19,2.28,2.28,0,0,1-2.32-2,2,2,0,0,1,1.88-2.28h.06c.65-.05,1.42-.21,2,.25A2.25,2.25,0,0,1,99.52,484.39Z"/>
                <path class="cls-4" d="M136.1,485.34a5.15,5.15,0,0,0-1.49,2.89c0,.14.13.25.25.25a.25.25,0,0,0,.25-.25,4.67,4.67,0,0,1,1.34-2.54c.22-.23-.13-.59-.35-.35Z"/>
                <path class="cls-4" d="M134.38,488.1a11.8,11.8,0,0,0,1.66,9.53,9.23,9.23,0,0,0,8.27,3.76c3.47-.36,6.37-3.25,7.94-6.21a8.73,8.73,0,0,0-.58-9.05,9.65,9.65,0,0,0-10.1-3.8c-.31.07-.18.55.14.48a9.15,9.15,0,0,1,9,2.91,8.36,8.36,0,0,1,1.5,8.31,12.81,12.81,0,0,1-3,4.21,8.38,8.38,0,0,1-4.15,2.52,8.62,8.62,0,0,1-8.1-2.7,11,11,0,0,1-2.17-9.82c.07-.32-.41-.45-.48-.14Z"/>
                <path class="cls-4" d="M134.3,480.27c-1,.87-2.1,1.86-2.25,3.18l0,.07a11.19,11.19,0,0,0,2.51,10.36,8.6,8.6,0,0,0,10.2,1.63,11.36,11.36,0,0,0,2.24-1.86,12.11,12.11,0,0,0,3.2-5.06,8.94,8.94,0,0,0-4.71-10A10.47,10.47,0,0,0,134.3,480.27Zm4.89.46a2.5,2.5,0,0,1,1.57,2.73,2.28,2.28,0,0,1-2.54,1.76,2,2,0,0,1-1.76-2.38v-.06c.11-.64.14-1.43.72-1.83A2.24,2.24,0,0,1,139.19,480.73Z"/>
                <path class="cls-4" d="M121.86,479.41a5.17,5.17,0,0,0-2.67-1.75c-.31-.07-.44.41-.13.48a4.68,4.68,0,0,1,2.45,1.63.25.25,0,0,0,.35,0,.26.26,0,0,0,0-.36Z"/>
                <path class="cls-4" d="M119.28,477.43a11.64,11.64,0,0,0-10.09.94,9.19,9.19,0,0,0-4.14,7.77c.08,3.46,2.75,6.67,5.56,8.47a8.69,8.69,0,0,0,8.72.28,9.58,9.58,0,0,0,5-9.65c0-.32-.53-.32-.5,0a9.18,9.18,0,0,1-3.64,8.58,8.38,8.38,0,0,1-8.41.85,12,12,0,0,1-3.72-3,9.32,9.32,0,0,1-2.38-4.44,8.6,8.6,0,0,1,3.16-7.93,11,11,0,0,1,10.35-1.35c.3.1.43-.38.13-.48Z"/>
                <path class="cls-4" d="M127.1,478.18c-.75-1.08-1.63-2.28-2.92-2.57l-.07,0A11.17,11.17,0,0,0,113.54,477a8.59,8.59,0,0,0-2.69,10,11.52,11.52,0,0,0,1.61,2.43,12.08,12.08,0,0,0,4.7,3.7,8.92,8.92,0,0,0,10.41-3.63A10.46,10.46,0,0,0,127.1,478.18Zm-1,4.81a2.49,2.49,0,0,1-2.87,1.28,2.28,2.28,0,0,1-1.49-2.71,2,2,0,0,1,2.55-1.5l.06,0c.63.17,1.41.29,1.74.9A2.22,2.22,0,0,1,126.13,483Z"/>
                <path class="cls-4" d="M461.19,457.91a5.09,5.09,0,0,0-3.11-.74.24.24,0,0,0-.24.24c0,.13.11.27.24.25a4.72,4.72,0,0,1,2.86.68c.28.15.53-.28.25-.43Z"/>
                <path class="cls-4" d="M458.09,456.92a11.74,11.74,0,0,0-8.81,3.9,9.2,9.2,0,0,0-1.73,8.77c1.12,3.34,4.55,5.45,7.82,6.33a8.72,8.72,0,0,0,8.7-2.56,9.63,9.63,0,0,0,1.37-10.87c-.14-.29-.57,0-.43.25a9.18,9.18,0,0,1-.65,9.5,8.31,8.31,0,0,1-8.05,3.38c-3.08-.55-6.7-2.54-8-5.5a8.66,8.66,0,0,1,.87-8.34,11.1,11.1,0,0,1,8.93-4.37.25.25,0,0,0,0-.49Z"/>
                <path class="cls-4" d="M465.68,454.94c-1.09-.76-2.32-1.59-3.64-1.41H462a11.2,11.2,0,0,0-9.44,5,8.58,8.58,0,0,0,.9,10.29,11.34,11.34,0,0,0,2.34,1.73,12.08,12.08,0,0,0,5.69,1.87,8.92,8.92,0,0,0,8.53-7A10.48,10.48,0,0,0,465.68,454.94Zm.73,4.85a2.49,2.49,0,0,1-2.26,2.19,2.27,2.27,0,0,1-2.32-2c-.07-1.14.74-2.37,1.87-2.29h.07c.64-.06,1.42-.21,1.94.24A2.23,2.23,0,0,1,466.41,459.79Z"/>
                <path class="cls-4" d="M503,460.74a5.16,5.16,0,0,0-1.48,2.9c0,.13.13.24.25.24a.25.25,0,0,0,.25-.24,4.57,4.57,0,0,1,1.34-2.55c.21-.23-.14-.58-.36-.35Z"/>
                <path class="cls-4" d="M501.27,463.51a11.78,11.78,0,0,0,1.66,9.52,9.19,9.19,0,0,0,8.27,3.76c3.47-.35,6.37-3.24,7.94-6.21a8.71,8.71,0,0,0-.58-9,9.63,9.63,0,0,0-10.1-3.8c-.31.06-.18.54.13.47a9.17,9.17,0,0,1,9,2.92,8.34,8.34,0,0,1,1.5,8.3,12.81,12.81,0,0,1-3,4.21,8.36,8.36,0,0,1-4.16,2.52,8.61,8.61,0,0,1-8.09-2.7,11,11,0,0,1-2.17-9.82c.07-.31-.41-.45-.48-.13Z"/>
                <path class="cls-4" d="M501.19,455.67c-1,.87-2.1,1.87-2.25,3.18l0,.07a11.21,11.21,0,0,0,2.51,10.37,8.59,8.59,0,0,0,10.2,1.62,11.36,11.36,0,0,0,2.24-1.86,12.11,12.11,0,0,0,3.2-5.06,8.94,8.94,0,0,0-4.71-10A10.47,10.47,0,0,0,501.19,455.67Zm4.89.47a2.48,2.48,0,0,1,1.57,2.72,2.28,2.28,0,0,1-2.54,1.76,2,2,0,0,1-1.76-2.38v-.06c.11-.64.14-1.43.72-1.83A2.21,2.21,0,0,1,506.08,456.14Z"/>
                <path class="cls-4" d="M488.75,454.82a5.18,5.18,0,0,0-2.67-1.76c-.31-.07-.44.41-.13.48a4.74,4.74,0,0,1,2.45,1.63.25.25,0,0,0,.35,0,.26.26,0,0,0,0-.35Z"/>
                <path class="cls-4" d="M486.17,452.83a11.64,11.64,0,0,0-10.09.94,9.21,9.21,0,0,0-4.14,7.77c.08,3.46,2.75,6.68,5.56,8.47a8.69,8.69,0,0,0,8.72.29,9.6,9.6,0,0,0,5-9.66c0-.32-.53-.32-.5,0a9.18,9.18,0,0,1-3.64,8.58,8.42,8.42,0,0,1-8.42.86,12.27,12.27,0,0,1-3.71-3.05,9.32,9.32,0,0,1-2.38-4.44,8.61,8.61,0,0,1,3.16-7.93A11,11,0,0,1,486,453.31c.3.1.43-.38.13-.48Z"/>
                <path class="cls-4" d="M494,453.59c-.76-1.09-1.63-2.29-2.92-2.58l-.07,0a11.19,11.19,0,0,0-10.57,1.41,8.58,8.58,0,0,0-2.69,10,11.52,11.52,0,0,0,1.61,2.43,12.2,12.2,0,0,0,4.7,3.71,8.94,8.94,0,0,0,10.41-3.64A10.44,10.44,0,0,0,494,453.59Zm-1,4.8a2.49,2.49,0,0,1-2.87,1.29,2.28,2.28,0,0,1-1.49-2.71,2,2,0,0,1,2.55-1.51l.06,0c.63.17,1.41.29,1.74.9A2.22,2.22,0,0,1,493,458.39Z"/>
                <path class="cls-4" d="M535.52,457.91a5.09,5.09,0,0,0-3.11-.74.24.24,0,0,0-.24.24c0,.13.11.27.24.25a4.72,4.72,0,0,1,2.86.68c.28.15.53-.28.25-.43Z"/>
                <path class="cls-4" d="M532.42,456.92a11.74,11.74,0,0,0-8.81,3.9,9.2,9.2,0,0,0-1.73,8.77c1.12,3.34,4.55,5.45,7.82,6.33a8.72,8.72,0,0,0,8.7-2.56,9.63,9.63,0,0,0,1.37-10.87c-.14-.29-.57,0-.43.25a9.2,9.2,0,0,1-.65,9.5,8.31,8.31,0,0,1-8.05,3.38c-3.08-.55-6.7-2.54-8-5.5a8.64,8.64,0,0,1,.87-8.34,11.1,11.1,0,0,1,8.93-4.37.25.25,0,0,0,0-.49Z"/>
                <path class="cls-4" d="M540,454.94c-1.08-.76-2.31-1.59-3.63-1.41h-.07a11.23,11.23,0,0,0-9.45,5,8.6,8.6,0,0,0,.91,10.29,11.08,11.08,0,0,0,2.34,1.73,12.08,12.08,0,0,0,5.69,1.87,8.93,8.93,0,0,0,8.53-7A10.47,10.47,0,0,0,540,454.94Zm.74,4.85a2.49,2.49,0,0,1-2.26,2.19,2.27,2.27,0,0,1-2.32-2c-.07-1.14.73-2.37,1.87-2.29h.07c.64-.06,1.42-.21,1.94.24A2.23,2.23,0,0,1,540.74,459.79Z"/>
                <path class="cls-4" d="M577.31,460.74a5.16,5.16,0,0,0-1.48,2.9c0,.13.12.24.25.24a.25.25,0,0,0,.25-.24,4.57,4.57,0,0,1,1.34-2.55c.21-.23-.14-.58-.36-.35Z"/>
                <path class="cls-4" d="M575.6,463.51a11.78,11.78,0,0,0,1.66,9.52,9.19,9.19,0,0,0,8.27,3.76c3.47-.35,6.37-3.24,7.94-6.21a8.69,8.69,0,0,0-.59-9,9.61,9.61,0,0,0-10.09-3.8c-.31.06-.18.54.13.47a9.18,9.18,0,0,1,9,2.92,8.34,8.34,0,0,1,1.5,8.3,12.81,12.81,0,0,1-3,4.21,8.41,8.41,0,0,1-4.16,2.52,8.61,8.61,0,0,1-8.09-2.7,11,11,0,0,1-2.17-9.82c.07-.31-.41-.45-.48-.13Z"/>
                <path class="cls-4" d="M575.52,455.67c-1,.87-2.1,1.87-2.25,3.18l0,.07a11.21,11.21,0,0,0,2.51,10.37,8.59,8.59,0,0,0,10.2,1.62,11.36,11.36,0,0,0,2.24-1.86,12.11,12.11,0,0,0,3.2-5.06,8.93,8.93,0,0,0-4.72-10A10.46,10.46,0,0,0,575.52,455.67Zm4.88.47a2.47,2.47,0,0,1,1.58,2.72,2.28,2.28,0,0,1-2.54,1.76,2,2,0,0,1-1.76-2.38v-.06c.11-.64.14-1.43.71-1.83A2.21,2.21,0,0,1,580.4,456.14Z"/>
                <path class="cls-4" d="M563.08,454.82a5.18,5.18,0,0,0-2.67-1.76c-.31-.07-.44.41-.13.48a4.68,4.68,0,0,1,2.44,1.63.25.25,0,1,0,.36-.35Z"/>
                <path class="cls-4" d="M560.5,452.83a11.64,11.64,0,0,0-10.09.94,9.21,9.21,0,0,0-4.14,7.77c.08,3.46,2.75,6.68,5.56,8.47a8.69,8.69,0,0,0,8.72.29,9.6,9.6,0,0,0,5-9.66c0-.32-.53-.32-.5,0a9.18,9.18,0,0,1-3.64,8.58,8.42,8.42,0,0,1-8.42.86,12.27,12.27,0,0,1-3.71-3.05,9.32,9.32,0,0,1-2.38-4.44,8.61,8.61,0,0,1,3.16-7.93,11,11,0,0,1,10.35-1.35c.3.1.43-.38.13-.48Z"/>
                <path class="cls-4" d="M568.32,453.59c-.76-1.09-1.63-2.29-2.92-2.58l-.07,0a11.19,11.19,0,0,0-10.57,1.41,8.58,8.58,0,0,0-2.69,10,11.52,11.52,0,0,0,1.61,2.43,12.12,12.12,0,0,0,4.7,3.71,8.94,8.94,0,0,0,10.41-3.64A10.44,10.44,0,0,0,568.32,453.59Zm-1,4.8a2.51,2.51,0,0,1-2.88,1.29A2.28,2.28,0,0,1,563,457a2,2,0,0,1,2.55-1.51l.06,0c.62.17,1.4.29,1.74.9A2.22,2.22,0,0,1,567.35,458.39Z"/>
                <path class="cls-4" d="M313.07,457.91a5.09,5.09,0,0,0-3.11-.74.25.25,0,0,0-.25.24c0,.13.12.27.25.25a4.74,4.74,0,0,1,2.86.68c.28.15.53-.28.25-.43Z"/>
                <path class="cls-4" d="M310,456.92a11.74,11.74,0,0,0-8.81,3.9,9.2,9.2,0,0,0-1.73,8.77c1.11,3.34,4.55,5.45,7.81,6.33a8.71,8.71,0,0,0,8.7-2.56,9.61,9.61,0,0,0,1.37-10.87c-.13-.29-.56,0-.43.25a9.15,9.15,0,0,1-.65,9.5,8.29,8.29,0,0,1-8,3.38c-3.08-.55-6.71-2.54-8-5.5a8.64,8.64,0,0,1,.87-8.34,11.1,11.1,0,0,1,8.93-4.37c.31,0,.32-.5,0-.49Z"/>
                <path class="cls-4" d="M317.55,454.94c-1.08-.76-2.32-1.59-3.63-1.41h-.07a11.22,11.22,0,0,0-9.45,5,8.6,8.6,0,0,0,.9,10.29,11.4,11.4,0,0,0,2.35,1.73,12,12,0,0,0,5.69,1.87,8.94,8.94,0,0,0,8.53-7A10.47,10.47,0,0,0,317.55,454.94Zm.74,4.85A2.49,2.49,0,0,1,316,462a2.26,2.26,0,0,1-2.32-2c-.07-1.14.73-2.37,1.87-2.29h.06a2.57,2.57,0,0,1,1.95.24A2.23,2.23,0,0,1,318.29,459.79Z"/>
                <path class="cls-4" d="M354.86,460.74a5.16,5.16,0,0,0-1.48,2.9c0,.13.12.24.24.24a.25.25,0,0,0,.25-.24,4.7,4.7,0,0,1,1.34-2.55c.22-.23-.13-.58-.35-.35Z"/>
                <path class="cls-4" d="M353.14,463.51a11.79,11.79,0,0,0,1.67,9.52,9.18,9.18,0,0,0,8.26,3.76c3.48-.35,6.37-3.24,7.95-6.21a8.71,8.71,0,0,0-.59-9,9.61,9.61,0,0,0-10.09-3.8c-.31.06-.18.54.13.47a9.18,9.18,0,0,1,9,2.92,8.34,8.34,0,0,1,1.5,8.3,12.81,12.81,0,0,1-3,4.21,8.41,8.41,0,0,1-4.16,2.52,8.64,8.64,0,0,1-8.1-2.7,11.06,11.06,0,0,1-2.17-9.82.25.25,0,0,0-.48-.13Z"/>
                <path class="cls-4" d="M353.07,455.67c-1,.87-2.1,1.87-2.25,3.18l0,.07a11.21,11.21,0,0,0,2.51,10.37,8.6,8.6,0,0,0,10.2,1.62,11.14,11.14,0,0,0,2.25-1.86,12,12,0,0,0,3.19-5.06,8.91,8.91,0,0,0-4.71-10A10.45,10.45,0,0,0,353.07,455.67Zm4.88.47a2.49,2.49,0,0,1,1.58,2.72,2.28,2.28,0,0,1-2.54,1.76,2,2,0,0,1-1.76-2.38v-.06c.1-.64.14-1.43.71-1.83A2.21,2.21,0,0,1,358,456.14Z"/>
                <path class="cls-4" d="M340.62,454.82a5.12,5.12,0,0,0-2.66-1.76c-.31-.07-.45.41-.14.48a4.66,4.66,0,0,1,2.45,1.63.25.25,0,0,0,.35-.35Z"/>
                <path class="cls-4" d="M338.05,452.83a11.64,11.64,0,0,0-10.09.94,9.24,9.24,0,0,0-4.15,7.77c.08,3.46,2.76,6.68,5.57,8.47a8.69,8.69,0,0,0,8.72.29,9.6,9.6,0,0,0,5-9.66c0-.32-.53-.32-.5,0a9.17,9.17,0,0,1-3.65,8.58,8.4,8.4,0,0,1-8.41.86,12.27,12.27,0,0,1-3.71-3.05,9.32,9.32,0,0,1-2.38-4.44,8.58,8.58,0,0,1,3.16-7.93,11,11,0,0,1,10.35-1.35c.3.1.43-.38.13-.48Z"/>
                <path class="cls-4" d="M345.87,453.59c-.76-1.09-1.63-2.29-2.93-2.58l-.06,0a11.19,11.19,0,0,0-10.57,1.41,8.58,8.58,0,0,0-2.69,10,11.52,11.52,0,0,0,1.61,2.43,12.12,12.12,0,0,0,4.7,3.71,8.94,8.94,0,0,0,10.41-3.64A10.46,10.46,0,0,0,345.87,453.59Zm-1,4.8a2.49,2.49,0,0,1-2.87,1.29,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.55-1.51l.05,0c.63.17,1.41.29,1.75.9A2.19,2.19,0,0,1,344.89,458.39Z"/>
                <path class="cls-4" d="M387.4,457.91a5.11,5.11,0,0,0-3.11-.74.25.25,0,0,0-.25.24c0,.13.12.27.25.25a4.74,4.74,0,0,1,2.86.68c.28.15.53-.28.25-.43Z"/>
                <path class="cls-4" d="M384.29,456.92a11.77,11.77,0,0,0-8.81,3.9,9.22,9.22,0,0,0-1.72,8.77c1.11,3.34,4.55,5.45,7.81,6.33a8.71,8.71,0,0,0,8.7-2.56,9.63,9.63,0,0,0,1.37-10.87c-.13-.29-.56,0-.43.25a9.15,9.15,0,0,1-.65,9.5,8.29,8.29,0,0,1-8,3.38c-3.08-.55-6.71-2.54-8-5.5a8.64,8.64,0,0,1,.88-8.34,11.09,11.09,0,0,1,8.92-4.37.25.25,0,0,0,0-.49Z"/>
                <path class="cls-4" d="M391.88,454.94c-1.09-.76-2.32-1.59-3.63-1.41h-.07a11.22,11.22,0,0,0-9.45,5,8.6,8.6,0,0,0,.9,10.29,11.4,11.4,0,0,0,2.35,1.73,12,12,0,0,0,5.69,1.87,8.94,8.94,0,0,0,8.53-7A10.49,10.49,0,0,0,391.88,454.94Zm.74,4.85a2.49,2.49,0,0,1-2.26,2.19,2.28,2.28,0,0,1-2.33-2c-.06-1.14.74-2.37,1.88-2.29H390a2.57,2.57,0,0,1,1.95.24A2.23,2.23,0,0,1,392.62,459.79Z"/>
                <path class="cls-4" d="M429.19,460.74a5.16,5.16,0,0,0-1.48,2.9c0,.13.12.24.24.24a.25.25,0,0,0,.25-.24,4.7,4.7,0,0,1,1.34-2.55c.22-.23-.13-.58-.35-.35Z"/>
                <path class="cls-4" d="M427.47,463.51a11.75,11.75,0,0,0,1.67,9.52,9.17,9.17,0,0,0,8.26,3.76c3.48-.35,6.37-3.24,8-6.21a8.71,8.71,0,0,0-.59-9,9.61,9.61,0,0,0-10.09-3.8c-.32.06-.18.54.13.47a9.16,9.16,0,0,1,9,2.92,8.31,8.31,0,0,1,1.5,8.3,12.64,12.64,0,0,1-2.95,4.21,8.45,8.45,0,0,1-4.16,2.52,8.64,8.64,0,0,1-8.1-2.7,11.06,11.06,0,0,1-2.17-9.82.25.25,0,0,0-.48-.13Z"/>
                <path class="cls-4" d="M427.4,455.67c-1,.87-2.1,1.87-2.25,3.18l0,.07a11.21,11.21,0,0,0,2.51,10.37,8.59,8.59,0,0,0,10.2,1.62,11.14,11.14,0,0,0,2.25-1.86,12,12,0,0,0,3.19-5.06,8.91,8.91,0,0,0-4.71-10A10.45,10.45,0,0,0,427.4,455.67Zm4.88.47a2.49,2.49,0,0,1,1.58,2.72,2.28,2.28,0,0,1-2.54,1.76,2,2,0,0,1-1.77-2.38l0-.06c.1-.64.14-1.43.71-1.83A2.21,2.21,0,0,1,432.28,456.14Z"/>
                <path class="cls-4" d="M415,454.82a5.16,5.16,0,0,0-2.66-1.76c-.32-.07-.45.41-.14.48a4.7,4.7,0,0,1,2.45,1.63.25.25,0,0,0,.35-.35Z"/>
                <path class="cls-4" d="M412.38,452.83a11.64,11.64,0,0,0-10.09.94,9.19,9.19,0,0,0-4.15,7.77c.08,3.46,2.76,6.68,5.57,8.47a8.69,8.69,0,0,0,8.72.29,9.58,9.58,0,0,0,4.95-9.66c0-.32-.52-.32-.49,0a9.17,9.17,0,0,1-3.65,8.58,8.4,8.4,0,0,1-8.41.86,12.27,12.27,0,0,1-3.71-3.05,9.32,9.32,0,0,1-2.38-4.44,8.56,8.56,0,0,1,3.16-7.93,11,11,0,0,1,10.34-1.35c.31.1.44-.38.14-.48Z"/>
                <path class="cls-4" d="M420.2,453.59c-.76-1.09-1.63-2.29-2.93-2.58l-.06,0a11.19,11.19,0,0,0-10.57,1.41,8.58,8.58,0,0,0-2.69,10,11.52,11.52,0,0,0,1.61,2.43,12.12,12.12,0,0,0,4.7,3.71,8.94,8.94,0,0,0,10.41-3.64A10.46,10.46,0,0,0,420.2,453.59Zm-1,4.8a2.49,2.49,0,0,1-2.87,1.29,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.54-1.51l.06,0c.63.17,1.41.29,1.75.9A2.22,2.22,0,0,1,419.22,458.39Z"/>
                <path class="cls-4" d="M467.07,482.51a5.14,5.14,0,0,0-3.1-.75.26.26,0,0,0-.25.25c0,.12.11.27.25.25a4.62,4.62,0,0,1,2.85.68c.28.15.53-.28.25-.43Z"/>
                <path class="cls-4" d="M464,481.51a11.77,11.77,0,0,0-8.81,3.91,9.21,9.21,0,0,0-1.73,8.76c1.12,3.34,4.55,5.46,7.82,6.34A8.72,8.72,0,0,0,470,498a9.61,9.61,0,0,0,1.37-10.86c-.14-.29-.57,0-.43.25a9.18,9.18,0,0,1-.65,9.5,8.32,8.32,0,0,1-8,3.38c-3.09-.55-6.71-2.54-8-5.5a8.66,8.66,0,0,1,.87-8.34A11.11,11.11,0,0,1,464,482a.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M471.56,479.54c-1.09-.76-2.32-1.59-3.64-1.41h-.06a11.18,11.18,0,0,0-9.45,4.95,8.6,8.6,0,0,0,.9,10.29,11.14,11.14,0,0,0,2.35,1.73,12.15,12.15,0,0,0,5.68,1.87,8.93,8.93,0,0,0,8.53-7A10.47,10.47,0,0,0,471.56,479.54Zm.73,4.85a2.48,2.48,0,0,1-2.25,2.19,2.28,2.28,0,0,1-2.33-2,2,2,0,0,1,1.88-2.28h.06c.65-.05,1.42-.21,2,.25A2.25,2.25,0,0,1,472.29,484.39Z"/>
                <path class="cls-4" d="M508.87,485.34a5.21,5.21,0,0,0-1.49,2.89.25.25,0,0,0,.5,0,4.67,4.67,0,0,1,1.34-2.54c.22-.23-.13-.59-.35-.35Z"/>
                <path class="cls-4" d="M507.15,488.1a11.76,11.76,0,0,0,1.67,9.53,9.2,9.2,0,0,0,8.26,3.76c3.47-.36,6.37-3.25,7.94-6.21a8.73,8.73,0,0,0-.58-9.05,9.65,9.65,0,0,0-10.1-3.8c-.31.07-.18.55.14.48a9.15,9.15,0,0,1,9,2.91A8.36,8.36,0,0,1,525,494a12.81,12.81,0,0,1-3,4.21,8.38,8.38,0,0,1-4.15,2.52,8.62,8.62,0,0,1-8.1-2.7,11,11,0,0,1-2.17-9.82c.07-.32-.41-.45-.48-.14Z"/>
                <path class="cls-4" d="M507.07,480.27c-1,.87-2.1,1.86-2.25,3.18l0,.07a11.19,11.19,0,0,0,2.51,10.36,8.6,8.6,0,0,0,10.2,1.63,11.36,11.36,0,0,0,2.24-1.86,12.11,12.11,0,0,0,3.2-5.06,8.93,8.93,0,0,0-4.71-10A10.47,10.47,0,0,0,507.07,480.27Zm4.89.46a2.52,2.52,0,0,1,1.58,2.73,2.29,2.29,0,0,1-2.54,1.76,2,2,0,0,1-1.77-2.38l0-.06c.1-.64.14-1.43.71-1.83A2.24,2.24,0,0,1,512,480.73Z"/>
                <path class="cls-4" d="M494.63,479.41a5.13,5.13,0,0,0-2.67-1.75c-.31-.07-.44.41-.13.48a4.64,4.64,0,0,1,2.45,1.63.25.25,0,0,0,.35,0,.26.26,0,0,0,0-.36Z"/>
                <path class="cls-4" d="M492.05,477.43a11.64,11.64,0,0,0-10.09.94,9.19,9.19,0,0,0-4.14,7.77c.08,3.46,2.75,6.67,5.56,8.47a8.69,8.69,0,0,0,8.72.28,9.58,9.58,0,0,0,5-9.65c0-.32-.53-.32-.5,0a9.16,9.16,0,0,1-3.64,8.58,8.38,8.38,0,0,1-8.41.85,12,12,0,0,1-3.72-3,9.21,9.21,0,0,1-2.37-4.44,8.55,8.55,0,0,1,3.16-7.93,11,11,0,0,1,10.34-1.35c.3.1.43-.38.13-.48Z"/>
                <path class="cls-4" d="M499.88,478.18c-.76-1.08-1.64-2.28-2.93-2.57l-.06,0A11.18,11.18,0,0,0,486.31,477a8.58,8.58,0,0,0-2.68,10,11.1,11.1,0,0,0,1.6,2.43,12,12,0,0,0,4.71,3.7,8.93,8.93,0,0,0,10.41-3.63A10.5,10.5,0,0,0,499.88,478.18Zm-1,4.81a2.49,2.49,0,0,1-2.87,1.28,2.29,2.29,0,0,1-1.49-2.71,2,2,0,0,1,2.55-1.5l.06,0c.63.17,1.41.29,1.74.9A2.19,2.19,0,0,1,498.9,483Z"/>
                <path class="cls-4" d="M541.4,482.51a5.14,5.14,0,0,0-3.1-.75.26.26,0,0,0-.25.25c0,.12.11.27.25.25a4.64,4.64,0,0,1,2.85.68c.28.15.53-.28.25-.43Z"/>
                <path class="cls-4" d="M538.3,481.51a11.75,11.75,0,0,0-8.81,3.91,9.21,9.21,0,0,0-1.73,8.76c1.12,3.34,4.55,5.46,7.82,6.34a8.72,8.72,0,0,0,8.7-2.57,9.61,9.61,0,0,0,1.37-10.86c-.14-.29-.57,0-.43.25a9.18,9.18,0,0,1-.65,9.5,8.33,8.33,0,0,1-8.05,3.38c-3.08-.55-6.7-2.54-8-5.5a8.66,8.66,0,0,1,.87-8.34A11.1,11.1,0,0,1,538.3,482a.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M545.89,479.54c-1.09-.76-2.32-1.59-3.64-1.41h-.07a11.18,11.18,0,0,0-9.44,4.95,8.6,8.6,0,0,0,.9,10.29,11.34,11.34,0,0,0,2.34,1.73,12.23,12.23,0,0,0,5.69,1.87,8.93,8.93,0,0,0,8.53-7A10.47,10.47,0,0,0,545.89,479.54Zm.73,4.85a2.48,2.48,0,0,1-2.26,2.19,2.28,2.28,0,0,1-2.32-2,2,2,0,0,1,1.88-2.28H544c.65-.05,1.42-.21,1.95.25A2.25,2.25,0,0,1,546.62,484.39Z"/>
                <path class="cls-4" d="M583.2,485.34a5.15,5.15,0,0,0-1.49,2.89.25.25,0,0,0,.5,0,4.67,4.67,0,0,1,1.34-2.54c.22-.23-.13-.59-.35-.35Z"/>
                <path class="cls-4" d="M581.48,488.1a11.76,11.76,0,0,0,1.67,9.53,9.2,9.2,0,0,0,8.26,3.76c3.47-.36,6.37-3.25,7.94-6.21a8.73,8.73,0,0,0-.58-9.05,9.65,9.65,0,0,0-10.1-3.8c-.31.07-.18.55.14.48a9.15,9.15,0,0,1,9,2.91,8.36,8.36,0,0,1,1.5,8.31,12.81,12.81,0,0,1-3,4.21,8.38,8.38,0,0,1-4.15,2.52,8.62,8.62,0,0,1-8.1-2.7,11,11,0,0,1-2.17-9.82c.07-.32-.41-.45-.48-.14Z"/>
                <path class="cls-4" d="M581.4,480.27c-1,.87-2.1,1.86-2.25,3.18l0,.07a11.19,11.19,0,0,0,2.51,10.36,8.6,8.6,0,0,0,10.2,1.63,11.36,11.36,0,0,0,2.24-1.86,12.11,12.11,0,0,0,3.2-5.06,8.94,8.94,0,0,0-4.71-10A10.47,10.47,0,0,0,581.4,480.27Zm4.89.46a2.5,2.5,0,0,1,1.57,2.73,2.27,2.27,0,0,1-2.53,1.76,2,2,0,0,1-1.77-2.38v-.06c.11-.64.15-1.43.72-1.83A2.24,2.24,0,0,1,586.29,480.73Z"/>
                <path class="cls-4" d="M569,479.41a5.17,5.17,0,0,0-2.67-1.75c-.31-.07-.44.41-.13.48a4.64,4.64,0,0,1,2.45,1.63.25.25,0,0,0,.35,0,.26.26,0,0,0,0-.36Z"/>
                <path class="cls-4" d="M566.38,477.43a11.64,11.64,0,0,0-10.09.94,9.19,9.19,0,0,0-4.14,7.77c.08,3.46,2.75,6.67,5.56,8.47a8.69,8.69,0,0,0,8.72.28,9.58,9.58,0,0,0,5-9.65c0-.32-.53-.32-.5,0a9.18,9.18,0,0,1-3.64,8.58,8.38,8.38,0,0,1-8.41.85,12,12,0,0,1-3.72-3,9.32,9.32,0,0,1-2.38-4.44,8.6,8.6,0,0,1,3.16-7.93,11,11,0,0,1,10.35-1.35c.3.1.43-.38.13-.48Z"/>
                <path class="cls-4" d="M574.2,478.18c-.75-1.08-1.63-2.28-2.92-2.57l-.07,0A11.17,11.17,0,0,0,560.64,477,8.58,8.58,0,0,0,558,487a11.1,11.1,0,0,0,1.6,2.43,12.08,12.08,0,0,0,4.7,3.7,8.92,8.92,0,0,0,10.41-3.63A10.46,10.46,0,0,0,574.2,478.18Zm-1,4.81a2.49,2.49,0,0,1-2.87,1.28,2.29,2.29,0,0,1-1.49-2.71,2,2,0,0,1,2.55-1.5l.06,0c.63.17,1.41.29,1.74.9A2.22,2.22,0,0,1,573.23,483Z"/>
                <path class="cls-4" d="M319,482.51a5.17,5.17,0,0,0-3.11-.75.27.27,0,0,0-.25.25c0,.12.12.27.25.25a4.65,4.65,0,0,1,2.86.68c.28.15.53-.28.25-.43Z"/>
                <path class="cls-4" d="M315.85,481.51a11.75,11.75,0,0,0-8.81,3.91,9.19,9.19,0,0,0-1.73,8.76c1.12,3.34,4.55,5.46,7.81,6.34a8.73,8.73,0,0,0,8.71-2.57,9.62,9.62,0,0,0,1.37-10.86c-.14-.29-.57,0-.43.25a9.18,9.18,0,0,1-.66,9.5,8.3,8.3,0,0,1-8,3.38c-3.08-.55-6.7-2.54-8-5.5a8.64,8.64,0,0,1,.87-8.34,11.1,11.1,0,0,1,8.93-4.37.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M323.43,479.54c-1.08-.76-2.31-1.59-3.63-1.41h-.07a11.21,11.21,0,0,0-9.45,4.95,8.58,8.58,0,0,0,.91,10.29,11.08,11.08,0,0,0,2.34,1.73,12.15,12.15,0,0,0,5.69,1.87,8.94,8.94,0,0,0,8.53-7A10.47,10.47,0,0,0,323.43,479.54Zm.74,4.85a2.5,2.5,0,0,1-2.26,2.19,2.28,2.28,0,0,1-2.32-2c-.07-1.14.73-2.36,1.87-2.28h.07c.64-.05,1.42-.21,1.94.25A2.23,2.23,0,0,1,324.17,484.39Z"/>
                <path class="cls-4" d="M360.74,485.34a5.14,5.14,0,0,0-1.48,2.89.24.24,0,0,0,.25.25.26.26,0,0,0,.25-.25,4.59,4.59,0,0,1,1.33-2.54c.22-.23-.13-.59-.35-.35Z"/>
                <path class="cls-4" d="M359,488.1a11.76,11.76,0,0,0,1.66,9.53,9.23,9.23,0,0,0,8.27,3.76c3.47-.36,6.37-3.25,7.94-6.21a8.71,8.71,0,0,0-.59-9.05,9.62,9.62,0,0,0-10.09-3.8c-.31.07-.18.55.13.48a9.17,9.17,0,0,1,9,2.91,8.36,8.36,0,0,1,1.5,8.31,12.81,12.81,0,0,1-3,4.21,8.41,8.41,0,0,1-4.16,2.52,8.64,8.64,0,0,1-8.1-2.7,11,11,0,0,1-2.16-9.82c.07-.32-.41-.45-.48-.14Z"/>
                <path class="cls-4" d="M359,480.27c-1,.87-2.1,1.86-2.25,3.18l0,.07a11.16,11.16,0,0,0,2.51,10.36c2.59,2.66,7,3.53,10.19,1.63a11.14,11.14,0,0,0,2.25-1.86,12,12,0,0,0,3.19-5.06,8.91,8.91,0,0,0-4.71-10A10.46,10.46,0,0,0,359,480.27Zm4.88.46a2.49,2.49,0,0,1,1.58,2.73,2.28,2.28,0,0,1-2.54,1.76,2,2,0,0,1-1.76-2.38v-.06c.11-.64.14-1.43.71-1.83A2.25,2.25,0,0,1,363.83,480.73Z"/>
                <path class="cls-4" d="M346.51,479.41a5.17,5.17,0,0,0-2.67-1.75c-.31-.07-.45.41-.13.48a4.62,4.62,0,0,1,2.44,1.63.26.26,0,0,0,.36,0,.27.27,0,0,0,0-.36Z"/>
                <path class="cls-4" d="M343.93,477.43a11.64,11.64,0,0,0-10.09.94,9.19,9.19,0,0,0-4.14,7.77c.07,3.46,2.75,6.67,5.56,8.47a8.69,8.69,0,0,0,8.72.28,9.58,9.58,0,0,0,5-9.65c0-.32-.53-.32-.5,0a9.18,9.18,0,0,1-3.64,8.58,8.39,8.39,0,0,1-8.42.85,12.1,12.1,0,0,1-3.71-3,9.32,9.32,0,0,1-2.38-4.44,8.6,8.6,0,0,1,3.16-7.93,11,11,0,0,1,10.35-1.35c.3.1.43-.38.13-.48Z"/>
                <path class="cls-4" d="M351.75,478.18c-.76-1.08-1.63-2.28-2.93-2.57l-.06,0A11.17,11.17,0,0,0,338.19,477a8.58,8.58,0,0,0-2.69,10,11.52,11.52,0,0,0,1.61,2.43,12,12,0,0,0,4.7,3.7,8.92,8.92,0,0,0,10.41-3.63A10.48,10.48,0,0,0,351.75,478.18Zm-1,4.81a2.5,2.5,0,0,1-2.88,1.28,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.55-1.5l.06,0c.62.17,1.4.29,1.74.9A2.22,2.22,0,0,1,350.78,483Z"/>
                <path class="cls-4" d="M393.28,482.51a5.17,5.17,0,0,0-3.11-.75.27.27,0,0,0-.25.25c0,.12.12.27.25.25a4.67,4.67,0,0,1,2.86.68c.28.15.53-.28.25-.43Z"/>
                <path class="cls-4" d="M390.18,481.51a11.75,11.75,0,0,0-8.81,3.91,9.19,9.19,0,0,0-1.73,8.76c1.12,3.34,4.55,5.46,7.81,6.34a8.72,8.72,0,0,0,8.7-2.57,9.59,9.59,0,0,0,1.37-10.86c-.13-.29-.56,0-.42.25a9.18,9.18,0,0,1-.66,9.5,8.3,8.3,0,0,1-8,3.38c-3.08-.55-6.71-2.54-8-5.5a8.64,8.64,0,0,1,.87-8.34,11.1,11.1,0,0,1,8.93-4.37.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M397.76,479.54c-1.08-.76-2.32-1.59-3.63-1.41h-.07a11.19,11.19,0,0,0-9.45,4.95,8.58,8.58,0,0,0,.91,10.29,11.08,11.08,0,0,0,2.34,1.73,12.15,12.15,0,0,0,5.69,1.87,9,9,0,0,0,8.53-7A10.47,10.47,0,0,0,397.76,479.54Zm.74,4.85a2.5,2.5,0,0,1-2.26,2.19,2.27,2.27,0,0,1-2.32-2c-.07-1.14.73-2.36,1.87-2.28h.07c.64-.05,1.42-.21,1.94.25A2.23,2.23,0,0,1,398.5,484.39Z"/>
                <path class="cls-4" d="M435.07,485.34a5.14,5.14,0,0,0-1.48,2.89.24.24,0,0,0,.25.25.25.25,0,0,0,.24-.25,4.67,4.67,0,0,1,1.34-2.54c.22-.23-.13-.59-.35-.35Z"/>
                <path class="cls-4" d="M433.35,488.1a11.8,11.8,0,0,0,1.67,9.53,9.23,9.23,0,0,0,8.27,3.76c3.47-.36,6.37-3.25,7.94-6.21a8.71,8.71,0,0,0-.59-9.05,9.62,9.62,0,0,0-10.09-3.8c-.31.07-.18.55.13.48a9.17,9.17,0,0,1,9,2.91,8.36,8.36,0,0,1,1.5,8.31,12.81,12.81,0,0,1-3,4.21,8.41,8.41,0,0,1-4.16,2.52,8.64,8.64,0,0,1-8.1-2.7,11.06,11.06,0,0,1-2.17-9.82.25.25,0,0,0-.48-.14Z"/>
                <path class="cls-4" d="M433.28,480.27c-1,.87-2.1,1.86-2.25,3.18l0,.07a11.16,11.16,0,0,0,2.52,10.36c2.59,2.66,7,3.53,10.19,1.63a11.14,11.14,0,0,0,2.25-1.86,12,12,0,0,0,3.19-5.06,8.91,8.91,0,0,0-4.71-10A10.45,10.45,0,0,0,433.28,480.27Zm4.88.46a2.5,2.5,0,0,1,1.58,2.73,2.28,2.28,0,0,1-2.54,1.76,2,2,0,0,1-1.76-2.38v-.06c.1-.64.14-1.43.71-1.83A2.24,2.24,0,0,1,438.16,480.73Z"/>
                <path class="cls-4" d="M420.83,479.41a5.1,5.1,0,0,0-2.66-1.75c-.31-.07-.45.41-.13.48a4.62,4.62,0,0,1,2.44,1.63.25.25,0,0,0,.35,0,.25.25,0,0,0,0-.36Z"/>
                <path class="cls-4" d="M418.26,477.43a11.64,11.64,0,0,0-10.09.94,9.19,9.19,0,0,0-4.14,7.77c.07,3.46,2.75,6.67,5.56,8.47a8.69,8.69,0,0,0,8.72.28,9.58,9.58,0,0,0,5-9.65c0-.32-.53-.32-.5,0a9.18,9.18,0,0,1-3.64,8.58,8.39,8.39,0,0,1-8.42.85,12.1,12.1,0,0,1-3.71-3,9.32,9.32,0,0,1-2.38-4.44,8.6,8.6,0,0,1,3.16-7.93,11,11,0,0,1,10.35-1.35c.3.1.43-.38.13-.48Z"/>
                <path class="cls-4" d="M426.08,478.18c-.76-1.08-1.63-2.28-2.93-2.57l-.06,0A11.17,11.17,0,0,0,412.52,477a8.58,8.58,0,0,0-2.69,10,11.52,11.52,0,0,0,1.61,2.43,12,12,0,0,0,4.7,3.7,8.92,8.92,0,0,0,10.41-3.63A10.48,10.48,0,0,0,426.08,478.18Zm-1,4.81a2.5,2.5,0,0,1-2.88,1.28,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.55-1.5l.06,0c.62.17,1.4.29,1.74.9A2.22,2.22,0,0,1,425.11,483Z"/>
                <path class="cls-4" d="M162.21,506.11a5.14,5.14,0,0,0-3.1-.75.26.26,0,0,0-.25.25c0,.12.11.27.25.25a4.62,4.62,0,0,1,2.85.68c.28.15.53-.28.25-.43Z"/>
                <path class="cls-4" d="M159.11,505.11A11.77,11.77,0,0,0,150.3,509a9.21,9.21,0,0,0-1.73,8.76c1.12,3.34,4.55,5.46,7.82,6.33a8.7,8.7,0,0,0,8.7-2.56,9.61,9.61,0,0,0,1.37-10.86c-.14-.29-.57,0-.43.25a9.15,9.15,0,0,1-.65,9.5,8.32,8.32,0,0,1-8,3.38c-3.08-.55-6.71-2.54-8-5.5a8.66,8.66,0,0,1,.87-8.34,11.13,11.13,0,0,1,8.93-4.37.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M166.7,503.14c-1.09-.76-2.32-1.59-3.63-1.41H163a11.18,11.18,0,0,0-9.45,4.95,8.6,8.6,0,0,0,.9,10.29,11.4,11.4,0,0,0,2.35,1.73,12.15,12.15,0,0,0,5.69,1.87,8.93,8.93,0,0,0,8.52-7A10.47,10.47,0,0,0,166.7,503.14Zm.73,4.85a2.49,2.49,0,0,1-2.25,2.19,2.3,2.3,0,0,1-2.33-2,2,2,0,0,1,1.88-2.28h.06c.65-.05,1.42-.21,2,.25A2.22,2.22,0,0,1,167.43,508Z"/>
                <path class="cls-4" d="M204,508.94a5.21,5.21,0,0,0-1.49,2.89.25.25,0,1,0,.5,0,4.67,4.67,0,0,1,1.34-2.54c.22-.23-.13-.59-.35-.35Z"/>
                <path class="cls-4" d="M202.29,511.7a11.76,11.76,0,0,0,1.67,9.53,9.2,9.2,0,0,0,8.26,3.76c3.47-.36,6.37-3.25,7.94-6.22a8.71,8.71,0,0,0-.58-9,9.64,9.64,0,0,0-10.09-3.8c-.32.07-.19.55.13.48a9.15,9.15,0,0,1,9,2.91,8.36,8.36,0,0,1,1.5,8.31,12.77,12.77,0,0,1-3,4.21,8.38,8.38,0,0,1-4.15,2.52,8.64,8.64,0,0,1-8.1-2.7,11,11,0,0,1-2.17-9.83c.07-.31-.41-.44-.48-.13Z"/>
                <path class="cls-4" d="M202.21,503.87c-1,.87-2.1,1.86-2.25,3.18l0,.07a11.19,11.19,0,0,0,2.51,10.36,8.6,8.6,0,0,0,10.2,1.63,11.36,11.36,0,0,0,2.24-1.86,12.11,12.11,0,0,0,3.2-5.06,8.91,8.91,0,0,0-4.71-10A10.47,10.47,0,0,0,202.21,503.87Zm4.89.46a2.52,2.52,0,0,1,1.58,2.73,2.29,2.29,0,0,1-2.54,1.76,2,2,0,0,1-1.77-2.38l0-.06c.1-.64.14-1.43.71-1.83A2.24,2.24,0,0,1,207.1,504.33Z"/>
                <path class="cls-4" d="M189.77,503a5.13,5.13,0,0,0-2.67-1.75c-.31-.07-.44.41-.13.48a4.69,4.69,0,0,1,2.45,1.62.24.24,0,0,0,.35,0,.25.25,0,0,0,0-.35Z"/>
                <path class="cls-4" d="M187.19,501a11.61,11.61,0,0,0-10.08.94,9.18,9.18,0,0,0-4.15,7.77c.08,3.46,2.75,6.67,5.56,8.47a8.69,8.69,0,0,0,8.72.28,9.58,9.58,0,0,0,5-9.65c0-.32-.52-.32-.49,0a9.19,9.19,0,0,1-3.65,8.58,8.38,8.38,0,0,1-8.41.85,12.14,12.14,0,0,1-3.72-3,9.21,9.21,0,0,1-2.37-4.44,8.55,8.55,0,0,1,3.16-7.93,11,11,0,0,1,10.34-1.35c.3.1.43-.38.13-.48Z"/>
                <path class="cls-4" d="M195,501.78c-.76-1.09-1.64-2.28-2.93-2.57l-.06,0a11.18,11.18,0,0,0-10.58,1.41,8.58,8.58,0,0,0-2.68,10,11.15,11.15,0,0,0,1.61,2.43,11.94,11.94,0,0,0,4.7,3.7,8.93,8.93,0,0,0,10.41-3.63A10.48,10.48,0,0,0,195,501.78Zm-1,4.81a2.49,2.49,0,0,1-2.87,1.28,2.29,2.29,0,0,1-1.49-2.71,2,2,0,0,1,2.55-1.5l.06,0c.63.17,1.41.29,1.75.9A2.22,2.22,0,0,1,194,506.59Z"/>
                <path class="cls-4" d="M236.54,506.11a5.14,5.14,0,0,0-3.1-.75.26.26,0,0,0-.25.25c0,.12.11.27.25.25a4.62,4.62,0,0,1,2.85.68c.28.15.53-.28.25-.43Z"/>
                <path class="cls-4" d="M233.44,505.11a11.77,11.77,0,0,0-8.81,3.91,9.21,9.21,0,0,0-1.73,8.76c1.12,3.34,4.55,5.46,7.82,6.33a8.7,8.7,0,0,0,8.7-2.56,9.61,9.61,0,0,0,1.37-10.86c-.14-.29-.57,0-.43.25a9.18,9.18,0,0,1-.65,9.5,8.32,8.32,0,0,1-8,3.38c-3.08-.55-6.71-2.54-8-5.5a8.66,8.66,0,0,1,.87-8.34,11.11,11.11,0,0,1,8.93-4.37.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M241,503.14c-1.09-.76-2.32-1.59-3.64-1.41h-.06a11.18,11.18,0,0,0-9.45,4.95,8.6,8.6,0,0,0,.9,10.29,11.14,11.14,0,0,0,2.35,1.73,12.15,12.15,0,0,0,5.68,1.87,8.93,8.93,0,0,0,8.53-7A10.47,10.47,0,0,0,241,503.14Zm.73,4.85a2.49,2.49,0,0,1-2.25,2.19,2.29,2.29,0,0,1-2.33-2,2,2,0,0,1,1.88-2.28h.06c.65-.05,1.42-.21,1.95.25A2.25,2.25,0,0,1,241.76,508Z"/>
                <path class="cls-4" d="M278.34,508.94a5.21,5.21,0,0,0-1.49,2.89.25.25,0,1,0,.5,0,4.67,4.67,0,0,1,1.34-2.54c.22-.23-.13-.59-.35-.35Z"/>
                <path class="cls-4" d="M276.62,511.7a11.76,11.76,0,0,0,1.67,9.53,9.2,9.2,0,0,0,8.26,3.76c3.47-.36,6.37-3.25,7.94-6.22a8.71,8.71,0,0,0-.58-9,9.65,9.65,0,0,0-10.1-3.8c-.31.07-.18.55.14.48a9.15,9.15,0,0,1,9,2.91,8.36,8.36,0,0,1,1.5,8.31,12.91,12.91,0,0,1-3,4.21,8.38,8.38,0,0,1-4.15,2.52,8.63,8.63,0,0,1-8.1-2.7,11,11,0,0,1-2.17-9.83c.07-.31-.41-.44-.48-.13Z"/>
                <path class="cls-4" d="M276.54,503.87c-1,.87-2.1,1.86-2.25,3.18l0,.07a11.19,11.19,0,0,0,2.51,10.36,8.6,8.6,0,0,0,10.2,1.63,11.36,11.36,0,0,0,2.24-1.86,12.11,12.11,0,0,0,3.2-5.06,8.91,8.91,0,0,0-4.71-10A10.47,10.47,0,0,0,276.54,503.87Zm4.89.46a2.52,2.52,0,0,1,1.58,2.73,2.29,2.29,0,0,1-2.54,1.76,2,2,0,0,1-1.77-2.38l0-.06c.1-.64.14-1.43.71-1.83A2.24,2.24,0,0,1,281.43,504.33Z"/>
                <path class="cls-4" d="M264.1,503a5.13,5.13,0,0,0-2.67-1.75c-.31-.07-.44.41-.13.48a4.69,4.69,0,0,1,2.45,1.62.24.24,0,0,0,.35,0,.25.25,0,0,0,0-.35Z"/>
                <path class="cls-4" d="M261.52,501a11.64,11.64,0,0,0-10.09.94,9.19,9.19,0,0,0-4.14,7.77c.08,3.46,2.75,6.67,5.56,8.47a8.69,8.69,0,0,0,8.72.28,9.58,9.58,0,0,0,5-9.65c0-.32-.53-.32-.5,0a9.16,9.16,0,0,1-3.64,8.58,8.38,8.38,0,0,1-8.41.85,12,12,0,0,1-3.72-3,9.21,9.21,0,0,1-2.37-4.44,8.55,8.55,0,0,1,3.16-7.93,11,11,0,0,1,10.34-1.35c.3.1.43-.38.13-.48Z"/>
                <path class="cls-4" d="M269.35,501.78c-.76-1.09-1.64-2.28-2.93-2.57l-.06,0a11.18,11.18,0,0,0-10.58,1.41,8.58,8.58,0,0,0-2.68,10,11.48,11.48,0,0,0,1.6,2.43,12,12,0,0,0,4.71,3.7,8.93,8.93,0,0,0,10.41-3.63A10.5,10.5,0,0,0,269.35,501.78Zm-1,4.81a2.49,2.49,0,0,1-2.87,1.28,2.29,2.29,0,0,1-1.49-2.71,2,2,0,0,1,2.55-1.5l.06,0c.63.17,1.41.29,1.74.9A2.19,2.19,0,0,1,268.37,506.59Z"/>
                <path class="cls-4" d="M14.09,506.11a5.17,5.17,0,0,0-3.11-.75.26.26,0,0,0-.24.25c0,.12.11.27.24.25a4.65,4.65,0,0,1,2.86.68c.28.15.53-.28.25-.43Z"/>
                <path class="cls-4" d="M11,505.11A11.75,11.75,0,0,0,2.18,509a9.19,9.19,0,0,0-1.73,8.76c1.12,3.34,4.55,5.46,7.82,6.33a8.7,8.7,0,0,0,8.7-2.56,9.61,9.61,0,0,0,1.37-10.86c-.14-.29-.57,0-.43.25a9.2,9.2,0,0,1-.65,9.5,8.33,8.33,0,0,1-8,3.38c-3.08-.55-6.7-2.54-8-5.5A8.64,8.64,0,0,1,2.06,510,11.1,11.1,0,0,1,11,505.61a.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M18.57,503.14c-1.08-.76-2.31-1.59-3.63-1.41h-.07a11.21,11.21,0,0,0-9.45,4.95A8.6,8.6,0,0,0,6.33,517a11.34,11.34,0,0,0,2.34,1.73,12.19,12.19,0,0,0,5.69,1.87,8.94,8.94,0,0,0,8.53-7A10.47,10.47,0,0,0,18.57,503.14Zm.74,4.85a2.51,2.51,0,0,1-2.26,2.19,2.29,2.29,0,0,1-2.32-2c-.07-1.14.73-2.36,1.87-2.28h.07c.64-.05,1.42-.21,1.94.25A2.23,2.23,0,0,1,19.31,508Z"/>
                <path class="cls-4" d="M55.88,508.94a5.14,5.14,0,0,0-1.48,2.89c0,.14.13.25.25.25a.25.25,0,0,0,.25-.25,4.54,4.54,0,0,1,1.34-2.54c.21-.23-.14-.59-.36-.35Z"/>
                <path class="cls-4" d="M54.17,511.7a11.8,11.8,0,0,0,1.66,9.53A9.23,9.23,0,0,0,64.1,525c3.47-.36,6.37-3.25,7.94-6.22a8.69,8.69,0,0,0-.59-9,9.62,9.62,0,0,0-10.09-3.8c-.31.07-.18.55.13.48a9.17,9.17,0,0,1,9,2.91,8.36,8.36,0,0,1,1.5,8.31,12.91,12.91,0,0,1-3,4.21,8.41,8.41,0,0,1-4.16,2.52,8.61,8.61,0,0,1-8.09-2.7,11,11,0,0,1-2.17-9.83c.07-.31-.41-.44-.48-.13Z"/>
                <path class="cls-4" d="M54.09,503.87c-1,.87-2.1,1.86-2.25,3.18l0,.07a11.19,11.19,0,0,0,2.51,10.36,8.6,8.6,0,0,0,10.2,1.63,11.36,11.36,0,0,0,2.24-1.86,12.11,12.11,0,0,0,3.2-5.06,8.93,8.93,0,0,0-4.72-10A10.46,10.46,0,0,0,54.09,503.87Zm4.89.46a2.5,2.5,0,0,1,1.57,2.73A2.28,2.28,0,0,1,58,508.82a2,2,0,0,1-1.76-2.38v-.06c.11-.64.14-1.43.71-1.83A2.27,2.27,0,0,1,59,504.33Z"/>
                <path class="cls-4" d="M41.65,503A5.17,5.17,0,0,0,39,501.26c-.31-.07-.44.41-.13.48a4.66,4.66,0,0,1,2.44,1.62.25.25,0,0,0,.36,0,.26.26,0,0,0,0-.35Z"/>
                <path class="cls-4" d="M39.07,501A11.64,11.64,0,0,0,29,502a9.19,9.19,0,0,0-4.14,7.77c.08,3.46,2.75,6.67,5.56,8.47a8.69,8.69,0,0,0,8.72.28,9.58,9.58,0,0,0,5-9.65c0-.32-.53-.32-.5,0a9.18,9.18,0,0,1-3.64,8.58,8.39,8.39,0,0,1-8.42.85,12.1,12.1,0,0,1-3.71-3,9.32,9.32,0,0,1-2.38-4.44,8.6,8.6,0,0,1,3.16-7.93,11,11,0,0,1,10.35-1.35c.3.1.43-.38.13-.48Z"/>
                <path class="cls-4" d="M46.89,501.78c-.76-1.09-1.63-2.28-2.92-2.57l-.07,0a11.17,11.17,0,0,0-10.57,1.41,8.58,8.58,0,0,0-2.69,10A11.52,11.52,0,0,0,32.25,513a12,12,0,0,0,4.7,3.7,8.92,8.92,0,0,0,10.41-3.63A10.46,10.46,0,0,0,46.89,501.78Zm-1,4.81A2.49,2.49,0,0,1,43,507.87a2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.55-1.5l.06,0c.62.17,1.4.29,1.74.9A2.22,2.22,0,0,1,45.92,506.59Z"/>
                <path class="cls-4" d="M88.42,506.11a5.17,5.17,0,0,0-3.11-.75.27.27,0,0,0-.25.25c0,.12.12.27.25.25a4.65,4.65,0,0,1,2.86.68c.28.15.53-.28.25-.43Z"/>
                <path class="cls-4" d="M85.32,505.11A11.75,11.75,0,0,0,76.51,509a9.19,9.19,0,0,0-1.73,8.76c1.12,3.34,4.55,5.46,7.81,6.33a8.71,8.71,0,0,0,8.71-2.56,9.62,9.62,0,0,0,1.37-10.86c-.14-.29-.57,0-.43.25a9.18,9.18,0,0,1-.66,9.5,8.3,8.3,0,0,1-8,3.38c-3.08-.55-6.7-2.54-8-5.5a8.64,8.64,0,0,1,.87-8.34,11.1,11.1,0,0,1,8.93-4.37.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M92.9,503.14c-1.08-.76-2.31-1.59-3.63-1.41H89.2a11.21,11.21,0,0,0-9.45,4.95A8.58,8.58,0,0,0,80.66,517,11.08,11.08,0,0,0,83,518.69a12.15,12.15,0,0,0,5.69,1.87,8.94,8.94,0,0,0,8.53-7A10.47,10.47,0,0,0,92.9,503.14Zm.74,4.85a2.51,2.51,0,0,1-2.26,2.19,2.29,2.29,0,0,1-2.32-2c-.07-1.14.73-2.36,1.87-2.28H91c.64-.05,1.42-.21,1.94.25A2.23,2.23,0,0,1,93.64,508Z"/>
                <path class="cls-4" d="M130.21,508.94a5.14,5.14,0,0,0-1.48,2.89.24.24,0,0,0,.25.25.26.26,0,0,0,.25-.25,4.59,4.59,0,0,1,1.33-2.54c.22-.23-.13-.59-.35-.35Z"/>
                <path class="cls-4" d="M128.5,511.7a11.76,11.76,0,0,0,1.66,9.53,9.23,9.23,0,0,0,8.27,3.76c3.47-.36,6.37-3.25,7.94-6.22a8.69,8.69,0,0,0-.59-9,9.62,9.62,0,0,0-10.09-3.8c-.31.07-.18.55.13.48a9.17,9.17,0,0,1,9,2.91,8.36,8.36,0,0,1,1.5,8.31,12.91,12.91,0,0,1-3,4.21,8.41,8.41,0,0,1-4.16,2.52,8.61,8.61,0,0,1-8.09-2.7,11,11,0,0,1-2.17-9.83c.07-.31-.41-.44-.48-.13Z"/>
                <path class="cls-4" d="M128.42,503.87c-1,.87-2.1,1.86-2.25,3.18l0,.07a11.16,11.16,0,0,0,2.51,10.36c2.59,2.66,7,3.53,10.19,1.63a11.14,11.14,0,0,0,2.25-1.86,12,12,0,0,0,3.19-5.06,8.91,8.91,0,0,0-4.71-10A10.46,10.46,0,0,0,128.42,503.87Zm4.88.46a2.49,2.49,0,0,1,1.58,2.73,2.28,2.28,0,0,1-2.54,1.76c-1.12-.22-2.12-1.29-1.76-2.38v-.06c.11-.64.14-1.43.71-1.83A2.25,2.25,0,0,1,133.3,504.33Z"/>
                <path class="cls-4" d="M116,503a5.17,5.17,0,0,0-2.67-1.75c-.31-.07-.45.41-.13.48a4.66,4.66,0,0,1,2.44,1.62.25.25,0,0,0,.36,0,.26.26,0,0,0,0-.35Z"/>
                <path class="cls-4" d="M113.4,501a11.64,11.64,0,0,0-10.09.94,9.19,9.19,0,0,0-4.14,7.77c.08,3.46,2.75,6.67,5.56,8.47a8.69,8.69,0,0,0,8.72.28,9.58,9.58,0,0,0,5-9.65c0-.32-.53-.32-.5,0a9.18,9.18,0,0,1-3.64,8.58,8.39,8.39,0,0,1-8.42.85,12.1,12.1,0,0,1-3.71-3,9.32,9.32,0,0,1-2.38-4.44,8.6,8.6,0,0,1,3.16-7.93,11,11,0,0,1,10.35-1.35c.3.1.43-.38.13-.48Z"/>
                <path class="cls-4" d="M121.22,501.78c-.76-1.09-1.63-2.28-2.93-2.57l-.06,0a11.17,11.17,0,0,0-10.57,1.41,8.58,8.58,0,0,0-2.69,10,11.52,11.52,0,0,0,1.61,2.43,12,12,0,0,0,4.7,3.7,8.92,8.92,0,0,0,10.41-3.63A10.48,10.48,0,0,0,121.22,501.78Zm-1,4.81a2.5,2.5,0,0,1-2.88,1.28,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.55-1.5l.06,0c.62.17,1.4.29,1.74.9A2.22,2.22,0,0,1,120.25,506.59Z"/>
                <path class="cls-4" d="M168.1,530.7A5.18,5.18,0,0,0,165,530a.26.26,0,0,0-.25.25c0,.12.12.27.25.25a4.73,4.73,0,0,1,2.86.67.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M165,529.71a11.77,11.77,0,0,0-8.81,3.91,9.21,9.21,0,0,0-1.72,8.76c1.11,3.34,4.55,5.46,7.81,6.33a8.69,8.69,0,0,0,8.7-2.56,9.61,9.61,0,0,0,1.37-10.86c-.14-.29-.56,0-.43.25a9.14,9.14,0,0,1-.65,9.49,8.32,8.32,0,0,1-8,3.39c-3.08-.55-6.71-2.54-8-5.5a8.66,8.66,0,0,1,.88-8.35,11.08,11.08,0,0,1,8.92-4.36.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M172.58,527.74c-1.09-.77-2.32-1.59-3.63-1.41h-.07a11.19,11.19,0,0,0-9.45,4.95,8.6,8.6,0,0,0,.9,10.29,11.78,11.78,0,0,0,2.35,1.73,12,12,0,0,0,5.69,1.86,8.93,8.93,0,0,0,8.53-7A10.47,10.47,0,0,0,172.58,527.74Zm.74,4.85a2.51,2.51,0,0,1-2.26,2.19,2.3,2.3,0,0,1-2.33-2c-.06-1.14.74-2.37,1.88-2.29h.06c.65-.05,1.42-.21,2,.25A2.22,2.22,0,0,1,173.32,532.59Z"/>
                <path class="cls-4" d="M209.89,533.54a5.14,5.14,0,0,0-1.48,2.89.24.24,0,0,0,.24.25.25.25,0,0,0,.25-.25,4.63,4.63,0,0,1,1.34-2.54c.22-.24-.13-.59-.35-.35Z"/>
                <path class="cls-4" d="M208.17,536.3a11.76,11.76,0,0,0,1.67,9.53,9.21,9.21,0,0,0,8.26,3.76c3.48-.36,6.37-3.25,8-6.22a8.71,8.71,0,0,0-.59-9,9.61,9.61,0,0,0-10.09-3.8c-.32.06-.18.54.13.48a9.15,9.15,0,0,1,9,2.91,8.33,8.33,0,0,1,1.5,8.31,12.74,12.74,0,0,1-2.95,4.21,8,8,0,0,1-12.26-.18,11.08,11.08,0,0,1-2.17-9.83.25.25,0,0,0-.48-.13Z"/>
                <path class="cls-4" d="M208.1,528.47c-1,.87-2.1,1.86-2.25,3.18l0,.06a11.2,11.2,0,0,0,2.51,10.37,8.58,8.58,0,0,0,10.2,1.62,11.09,11.09,0,0,0,2.25-1.85,12,12,0,0,0,3.19-5.07,8.92,8.92,0,0,0-4.71-10A10.48,10.48,0,0,0,208.1,528.47Zm4.88.46a2.5,2.5,0,0,1,1.58,2.72,2.29,2.29,0,0,1-2.54,1.76,2,2,0,0,1-1.77-2.37l0-.06c.1-.64.14-1.43.71-1.83A2.21,2.21,0,0,1,213,528.93Z"/>
                <path class="cls-4" d="M195.65,527.61a5.08,5.08,0,0,0-2.66-1.75c-.32-.07-.45.41-.14.48A4.69,4.69,0,0,1,195.3,528a.25.25,0,1,0,.35-.35Z"/>
                <path class="cls-4" d="M193.08,525.62a11.67,11.67,0,0,0-10.09,1,9.16,9.16,0,0,0-4.15,7.76c.08,3.46,2.76,6.68,5.57,8.48a8.71,8.71,0,0,0,8.72.28,9.57,9.57,0,0,0,5-9.65c0-.32-.52-.32-.49,0a9.17,9.17,0,0,1-3.65,8.58,8.38,8.38,0,0,1-8.41.85,12.29,12.29,0,0,1-3.72-3,9.29,9.29,0,0,1-2.37-4.44,8.54,8.54,0,0,1,3.16-7.92,11,11,0,0,1,10.34-1.36c.31.11.44-.37.14-.48Z"/>
                <path class="cls-4" d="M200.9,526.38c-.76-1.09-1.63-2.28-2.93-2.57l-.06,0a11.21,11.21,0,0,0-10.58,1.4c-2.91,2.3-4.24,6.6-2.68,10a11.27,11.27,0,0,0,1.61,2.43,11.94,11.94,0,0,0,4.7,3.71,8.92,8.92,0,0,0,10.41-3.63A10.48,10.48,0,0,0,200.9,526.38Zm-1,4.81a2.49,2.49,0,0,1-2.87,1.28,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.54-1.5l.06,0c.63.17,1.41.29,1.75.9A2.22,2.22,0,0,1,199.92,531.19Z"/>
                <path class="cls-4" d="M242.43,530.7a5.18,5.18,0,0,0-3.11-.74.26.26,0,0,0-.25.25c0,.12.11.27.25.25a4.68,4.68,0,0,1,2.85.67c.28.16.54-.27.26-.43Z"/>
                <path class="cls-4" d="M239.32,529.71a11.77,11.77,0,0,0-8.81,3.91,9.23,9.23,0,0,0-1.73,8.76c1.12,3.34,4.56,5.46,7.82,6.33a8.69,8.69,0,0,0,8.7-2.56,9.61,9.61,0,0,0,1.37-10.86c-.14-.29-.56,0-.43.25a9.14,9.14,0,0,1-.65,9.49,8.33,8.33,0,0,1-8,3.39c-3.08-.55-6.71-2.54-8-5.5a8.66,8.66,0,0,1,.88-8.35,11.08,11.08,0,0,1,8.92-4.36.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M246.91,527.74c-1.09-.77-2.32-1.59-3.63-1.41h-.07a11.18,11.18,0,0,0-9.45,4.95,8.6,8.6,0,0,0,.9,10.29,11.78,11.78,0,0,0,2.35,1.73,12,12,0,0,0,5.69,1.86,8.91,8.91,0,0,0,8.52-7A10.45,10.45,0,0,0,246.91,527.74Zm.74,4.85a2.51,2.51,0,0,1-2.26,2.19,2.3,2.3,0,0,1-2.33-2c-.06-1.14.74-2.37,1.88-2.29H245c.65-.05,1.42-.21,1.95.25A2.25,2.25,0,0,1,247.65,532.59Z"/>
                <path class="cls-4" d="M284.22,533.54a5.21,5.21,0,0,0-1.49,2.89.25.25,0,0,0,.25.25.25.25,0,0,0,.25-.25,4.63,4.63,0,0,1,1.34-2.54c.22-.24-.13-.59-.35-.35Z"/>
                <path class="cls-4" d="M282.5,536.3a11.76,11.76,0,0,0,1.67,9.53,9.2,9.2,0,0,0,8.26,3.76c3.48-.36,6.37-3.25,7.94-6.22a8.69,8.69,0,0,0-.58-9,9.61,9.61,0,0,0-10.09-3.8c-.32.06-.18.54.13.48a9.15,9.15,0,0,1,9,2.91,8.33,8.33,0,0,1,1.5,8.31,12.88,12.88,0,0,1-2.95,4.21,8,8,0,0,1-12.26-.18,11.08,11.08,0,0,1-2.17-9.83c.07-.31-.41-.44-.48-.13Z"/>
                <path class="cls-4" d="M282.43,528.47c-1,.87-2.11,1.86-2.25,3.18l0,.06a11.2,11.2,0,0,0,2.51,10.37,8.58,8.58,0,0,0,10.2,1.62,11.09,11.09,0,0,0,2.25-1.85,12,12,0,0,0,3.19-5.07,8.92,8.92,0,0,0-4.71-10A10.48,10.48,0,0,0,282.43,528.47Zm4.88.46a2.5,2.5,0,0,1,1.58,2.72,2.29,2.29,0,0,1-2.54,1.76,2,2,0,0,1-1.77-2.37l0-.06c.1-.64.14-1.43.71-1.83A2.21,2.21,0,0,1,287.31,528.93Z"/>
                <path class="cls-4" d="M270,527.61a5.08,5.08,0,0,0-2.66-1.75c-.32-.07-.45.41-.14.48a4.69,4.69,0,0,1,2.45,1.62.25.25,0,1,0,.35-.35Z"/>
                <path class="cls-4" d="M267.41,525.62a11.67,11.67,0,0,0-10.09,1,9.16,9.16,0,0,0-4.15,7.76c.08,3.46,2.75,6.68,5.57,8.48a8.71,8.71,0,0,0,8.72.28,9.58,9.58,0,0,0,5-9.65c0-.32-.52-.32-.49,0a9.19,9.19,0,0,1-3.65,8.58,8.38,8.38,0,0,1-8.41.85,12.29,12.29,0,0,1-3.72-3,9.29,9.29,0,0,1-2.37-4.44,8.54,8.54,0,0,1,3.16-7.92,11,11,0,0,1,10.34-1.36c.31.11.44-.37.14-.48Z"/>
                <path class="cls-4" d="M275.23,526.38c-.76-1.09-1.63-2.28-2.93-2.57l-.06,0a11.21,11.21,0,0,0-10.58,1.4c-2.91,2.3-4.24,6.6-2.68,10a11.27,11.27,0,0,0,1.61,2.43,11.87,11.87,0,0,0,4.7,3.71,8.92,8.92,0,0,0,10.41-3.63A10.48,10.48,0,0,0,275.23,526.38Zm-1,4.81a2.49,2.49,0,0,1-2.87,1.28,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.54-1.5l.06,0c.63.17,1.41.29,1.75.9A2.22,2.22,0,0,1,274.25,531.19Z"/>
                <path class="cls-4" d="M20,530.7a5.13,5.13,0,0,0-3.1-.74.25.25,0,0,0-.25.25c0,.12.11.27.25.25a4.68,4.68,0,0,1,2.85.67.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M16.87,529.71a11.77,11.77,0,0,0-8.81,3.91,9.21,9.21,0,0,0-1.73,8.76c1.12,3.34,4.55,5.46,7.82,6.33a8.7,8.7,0,0,0,8.7-2.56,9.61,9.61,0,0,0,1.37-10.86c-.14-.29-.57,0-.43.25a9.16,9.16,0,0,1-.65,9.49,8.33,8.33,0,0,1-8,3.39c-3.09-.55-6.71-2.54-8-5.5a8.69,8.69,0,0,1,.87-8.35,11.1,11.1,0,0,1,8.93-4.36.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M24.46,527.74c-1.09-.77-2.32-1.59-3.64-1.41h-.06a11.18,11.18,0,0,0-9.45,4.95,8.6,8.6,0,0,0,.9,10.29,11.71,11.71,0,0,0,2.34,1.73,12.1,12.1,0,0,0,5.69,1.86,8.91,8.91,0,0,0,8.53-7A10.45,10.45,0,0,0,24.46,527.74Zm.73,4.85a2.49,2.49,0,0,1-2.25,2.19,2.29,2.29,0,0,1-2.33-2c-.07-1.14.74-2.37,1.88-2.29h.06c.65-.05,1.42-.21,1.95.25A2.24,2.24,0,0,1,25.19,532.59Z"/>
                <path class="cls-4" d="M61.77,533.54a5.21,5.21,0,0,0-1.49,2.89.25.25,0,0,0,.5,0,4.63,4.63,0,0,1,1.34-2.54c.22-.24-.13-.59-.35-.35Z"/>
                <path class="cls-4" d="M60.05,536.3a11.76,11.76,0,0,0,1.67,9.53A9.2,9.2,0,0,0,70,549.59c3.47-.36,6.37-3.25,7.94-6.22a8.71,8.71,0,0,0-.58-9,9.63,9.63,0,0,0-10.1-3.8c-.31.06-.18.54.14.48a9.15,9.15,0,0,1,9,2.91,8.35,8.35,0,0,1,1.5,8.31,12.91,12.91,0,0,1-3,4.21A8.52,8.52,0,0,1,70.8,549a8.62,8.62,0,0,1-8.1-2.7,11,11,0,0,1-2.17-9.83c.07-.31-.41-.44-.48-.13Z"/>
                <path class="cls-4" d="M60,528.47c-1,.87-2.1,1.86-2.25,3.18l0,.06a11.2,11.2,0,0,0,2.51,10.37,8.58,8.58,0,0,0,10.2,1.62,11.31,11.31,0,0,0,2.24-1.85,12.14,12.14,0,0,0,3.2-5.07,8.94,8.94,0,0,0-4.71-10A10.49,10.49,0,0,0,60,528.47Zm4.89.46a2.48,2.48,0,0,1,1.57,2.72,2.27,2.27,0,0,1-2.53,1.76A2,2,0,0,1,62.13,531l0-.06c.1-.64.14-1.43.71-1.83A2.21,2.21,0,0,1,64.86,528.93Z"/>
                <path class="cls-4" d="M47.53,527.61a5.07,5.07,0,0,0-2.67-1.75c-.31-.07-.44.41-.13.48A4.69,4.69,0,0,1,47.18,528a.25.25,0,1,0,.35-.35Z"/>
                <path class="cls-4" d="M45,525.62a11.67,11.67,0,0,0-10.09,1,9.18,9.18,0,0,0-4.14,7.76c.08,3.46,2.75,6.68,5.56,8.48a8.71,8.71,0,0,0,8.72.28,9.59,9.59,0,0,0,5-9.65c0-.32-.53-.32-.5,0A9.18,9.18,0,0,1,45.82,542a8.38,8.38,0,0,1-8.41.85,12.05,12.05,0,0,1-3.72-3,9.13,9.13,0,0,1-2.37-4.44,8.54,8.54,0,0,1,3.16-7.92,11,11,0,0,1,10.34-1.36c.3.11.43-.37.13-.48Z"/>
                <path class="cls-4" d="M52.78,526.38c-.76-1.09-1.64-2.28-2.93-2.57l-.06,0a11.21,11.21,0,0,0-10.58,1.4,8.58,8.58,0,0,0-2.68,10,10.87,10.87,0,0,0,1.6,2.43,12,12,0,0,0,4.71,3.71,8.93,8.93,0,0,0,10.41-3.63A10.5,10.5,0,0,0,52.78,526.38Zm-1,4.81a2.49,2.49,0,0,1-2.87,1.28,2.29,2.29,0,0,1-1.49-2.71,2,2,0,0,1,2.55-1.5l.06,0c.63.17,1.41.29,1.74.9A2.19,2.19,0,0,1,51.8,531.19Z"/>
                <path class="cls-4" d="M94.3,530.7a5.13,5.13,0,0,0-3.1-.74.25.25,0,0,0-.25.25c0,.12.11.27.25.25a4.7,4.7,0,0,1,2.85.67.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M91.2,529.71a11.75,11.75,0,0,0-8.81,3.91,9.21,9.21,0,0,0-1.73,8.76c1.12,3.34,4.55,5.46,7.82,6.33a8.7,8.7,0,0,0,8.7-2.56,9.61,9.61,0,0,0,1.37-10.86c-.14-.29-.57,0-.43.25a9.16,9.16,0,0,1-.65,9.49,8.33,8.33,0,0,1-8,3.39c-3.08-.55-6.7-2.54-8-5.5a8.69,8.69,0,0,1,.87-8.35,11.09,11.09,0,0,1,8.93-4.36.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M98.79,527.74c-1.09-.77-2.32-1.59-3.64-1.41h-.07a11.18,11.18,0,0,0-9.44,4.95,8.58,8.58,0,0,0,.9,10.29,11.71,11.71,0,0,0,2.34,1.73,12.1,12.1,0,0,0,5.69,1.86,8.91,8.91,0,0,0,8.53-7A10.45,10.45,0,0,0,98.79,527.74Zm.73,4.85a2.49,2.49,0,0,1-2.26,2.19,2.29,2.29,0,0,1-2.32-2c-.07-1.14.74-2.37,1.88-2.29h.06c.65-.05,1.42-.21,2,.25A2.24,2.24,0,0,1,99.52,532.59Z"/>
                <path class="cls-4" d="M136.1,533.54a5.15,5.15,0,0,0-1.49,2.89c0,.14.13.25.25.25a.25.25,0,0,0,.25-.25,4.63,4.63,0,0,1,1.34-2.54c.22-.24-.13-.59-.35-.35Z"/>
                <path class="cls-4" d="M134.38,536.3a11.8,11.8,0,0,0,1.66,9.53,9.23,9.23,0,0,0,8.27,3.76c3.47-.36,6.37-3.25,7.94-6.22a8.71,8.71,0,0,0-.58-9,9.63,9.63,0,0,0-10.1-3.8c-.31.06-.18.54.14.48a9.15,9.15,0,0,1,9,2.91,8.35,8.35,0,0,1,1.5,8.31,12.91,12.91,0,0,1-3,4.21,8.52,8.52,0,0,1-4.15,2.52,8.62,8.62,0,0,1-8.1-2.7,11,11,0,0,1-2.17-9.83c.07-.31-.41-.44-.48-.13Z"/>
                <path class="cls-4" d="M134.3,528.47c-1,.87-2.1,1.86-2.25,3.18l0,.06a11.2,11.2,0,0,0,2.51,10.37,8.58,8.58,0,0,0,10.2,1.62,11.31,11.31,0,0,0,2.24-1.85,12.14,12.14,0,0,0,3.2-5.07,9,9,0,0,0-4.71-10A10.49,10.49,0,0,0,134.3,528.47Zm4.89.46a2.48,2.48,0,0,1,1.57,2.72,2.28,2.28,0,0,1-2.54,1.76,2,2,0,0,1-1.76-2.37V531c.11-.64.14-1.43.72-1.83A2.21,2.21,0,0,1,139.19,528.93Z"/>
                <path class="cls-4" d="M121.86,527.61a5.11,5.11,0,0,0-2.67-1.75c-.31-.07-.44.41-.13.48a4.72,4.72,0,0,1,2.45,1.62.25.25,0,1,0,.35-.35Z"/>
                <path class="cls-4" d="M119.28,525.62a11.67,11.67,0,0,0-10.09,1,9.18,9.18,0,0,0-4.14,7.76c.08,3.46,2.75,6.68,5.56,8.48a8.71,8.71,0,0,0,8.72.28,9.59,9.59,0,0,0,5-9.65c0-.32-.53-.32-.5,0a9.18,9.18,0,0,1-3.64,8.58,8.38,8.38,0,0,1-8.41.85,12.05,12.05,0,0,1-3.72-3,9.23,9.23,0,0,1-2.38-4.44,8.59,8.59,0,0,1,3.16-7.92,11,11,0,0,1,10.35-1.36c.3.11.43-.37.13-.48Z"/>
                <path class="cls-4" d="M127.1,526.38c-.75-1.09-1.63-2.28-2.92-2.57l-.07,0a11.19,11.19,0,0,0-10.57,1.4,8.6,8.6,0,0,0-2.69,10,11.27,11.27,0,0,0,1.61,2.43,12,12,0,0,0,4.7,3.71,8.92,8.92,0,0,0,10.41-3.63A10.46,10.46,0,0,0,127.1,526.38Zm-1,4.81a2.49,2.49,0,0,1-2.87,1.28,2.28,2.28,0,0,1-1.49-2.71,2,2,0,0,1,2.55-1.5l.06,0c.63.17,1.41.29,1.74.9A2.22,2.22,0,0,1,126.13,531.19Z"/>
                <path class="cls-4" d="M461.19,506.11a5.17,5.17,0,0,0-3.11-.75.26.26,0,0,0-.24.25c0,.12.11.27.24.25a4.65,4.65,0,0,1,2.86.68c.28.15.53-.28.25-.43Z"/>
                <path class="cls-4" d="M458.09,505.11a11.75,11.75,0,0,0-8.81,3.91,9.19,9.19,0,0,0-1.73,8.76c1.12,3.34,4.55,5.46,7.82,6.33a8.7,8.7,0,0,0,8.7-2.56,9.61,9.61,0,0,0,1.37-10.86c-.14-.29-.57,0-.43.25a9.18,9.18,0,0,1-.65,9.5,8.33,8.33,0,0,1-8.05,3.38c-3.08-.55-6.7-2.54-8-5.5a8.66,8.66,0,0,1,.87-8.34,11.1,11.1,0,0,1,8.93-4.37.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M465.68,503.14c-1.09-.76-2.32-1.59-3.64-1.41H462a11.18,11.18,0,0,0-9.44,4.95,8.58,8.58,0,0,0,.9,10.29,11.34,11.34,0,0,0,2.34,1.73,12.19,12.19,0,0,0,5.69,1.87,8.93,8.93,0,0,0,8.53-7A10.48,10.48,0,0,0,465.68,503.14Zm.73,4.85a2.51,2.51,0,0,1-2.26,2.19,2.29,2.29,0,0,1-2.32-2c-.07-1.14.74-2.36,1.87-2.28h.07c.64-.05,1.42-.21,1.94.25A2.23,2.23,0,0,1,466.41,508Z"/>
                <path class="cls-4" d="M503,508.94a5.14,5.14,0,0,0-1.48,2.89c0,.14.13.25.25.25a.25.25,0,0,0,.25-.25,4.54,4.54,0,0,1,1.34-2.54c.21-.23-.14-.59-.36-.35Z"/>
                <path class="cls-4" d="M501.27,511.7a11.8,11.8,0,0,0,1.66,9.53A9.23,9.23,0,0,0,511.2,525c3.47-.36,6.37-3.25,7.94-6.22a8.71,8.71,0,0,0-.58-9,9.65,9.65,0,0,0-10.1-3.8c-.31.07-.18.55.13.48a9.16,9.16,0,0,1,9,2.91,8.36,8.36,0,0,1,1.5,8.31,12.91,12.91,0,0,1-3,4.21,8.36,8.36,0,0,1-4.16,2.52,8.61,8.61,0,0,1-8.09-2.7,11,11,0,0,1-2.17-9.83c.07-.31-.41-.44-.48-.13Z"/>
                <path class="cls-4" d="M501.19,503.87c-1,.87-2.1,1.86-2.25,3.18l0,.07a11.19,11.19,0,0,0,2.51,10.36,8.6,8.6,0,0,0,10.2,1.63,11.36,11.36,0,0,0,2.24-1.86,12.11,12.11,0,0,0,3.2-5.06,8.94,8.94,0,0,0-4.71-10A10.47,10.47,0,0,0,501.19,503.87Zm4.89.46a2.5,2.5,0,0,1,1.57,2.73,2.28,2.28,0,0,1-2.54,1.76,2,2,0,0,1-1.76-2.38v-.06c.11-.64.14-1.43.72-1.83A2.24,2.24,0,0,1,506.08,504.33Z"/>
                <path class="cls-4" d="M488.75,503a5.17,5.17,0,0,0-2.67-1.75c-.31-.07-.44.41-.13.48a4.72,4.72,0,0,1,2.45,1.62.24.24,0,0,0,.35,0,.26.26,0,0,0,0-.35Z"/>
                <path class="cls-4" d="M486.17,501a11.64,11.64,0,0,0-10.09.94,9.19,9.19,0,0,0-4.14,7.77c.08,3.46,2.75,6.67,5.56,8.47a8.69,8.69,0,0,0,8.72.28,9.58,9.58,0,0,0,5-9.65c0-.32-.53-.32-.5,0a9.18,9.18,0,0,1-3.64,8.58,8.39,8.39,0,0,1-8.42.85,12.1,12.1,0,0,1-3.71-3,9.32,9.32,0,0,1-2.38-4.44,8.6,8.6,0,0,1,3.16-7.93A11,11,0,0,1,486,501.51c.3.1.43-.38.13-.48Z"/>
                <path class="cls-4" d="M494,501.78c-.76-1.09-1.63-2.28-2.92-2.57l-.07,0a11.17,11.17,0,0,0-10.57,1.41,8.58,8.58,0,0,0-2.69,10,11.52,11.52,0,0,0,1.61,2.43,12.08,12.08,0,0,0,4.7,3.7,8.92,8.92,0,0,0,10.41-3.63A10.46,10.46,0,0,0,494,501.78Zm-1,4.81a2.49,2.49,0,0,1-2.87,1.28,2.27,2.27,0,0,1-1.49-2.71,2,2,0,0,1,2.55-1.5l.06,0c.63.17,1.41.29,1.74.9A2.22,2.22,0,0,1,493,506.59Z"/>
                <path class="cls-4" d="M535.52,506.11a5.17,5.17,0,0,0-3.11-.75.26.26,0,0,0-.24.25c0,.12.11.27.24.25a4.65,4.65,0,0,1,2.86.68c.28.15.53-.28.25-.43Z"/>
                <path class="cls-4" d="M532.42,505.11a11.75,11.75,0,0,0-8.81,3.91,9.19,9.19,0,0,0-1.73,8.76c1.12,3.34,4.55,5.46,7.82,6.33a8.7,8.7,0,0,0,8.7-2.56,9.61,9.61,0,0,0,1.37-10.86c-.14-.29-.57,0-.43.25a9.2,9.2,0,0,1-.65,9.5,8.33,8.33,0,0,1-8.05,3.38c-3.08-.55-6.7-2.54-8-5.5a8.64,8.64,0,0,1,.87-8.34,11.1,11.1,0,0,1,8.93-4.37.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M540,503.14c-1.08-.76-2.31-1.59-3.63-1.41h-.07a11.21,11.21,0,0,0-9.45,4.95,8.6,8.6,0,0,0,.91,10.29,11.08,11.08,0,0,0,2.34,1.73,12.19,12.19,0,0,0,5.69,1.87,8.94,8.94,0,0,0,8.53-7A10.47,10.47,0,0,0,540,503.14Zm.74,4.85a2.51,2.51,0,0,1-2.26,2.19,2.29,2.29,0,0,1-2.32-2c-.07-1.14.73-2.36,1.87-2.28h.07c.64-.05,1.42-.21,1.94.25A2.23,2.23,0,0,1,540.74,508Z"/>
                <path class="cls-4" d="M577.31,508.94a5.14,5.14,0,0,0-1.48,2.89.24.24,0,0,0,.25.25.25.25,0,0,0,.25-.25,4.54,4.54,0,0,1,1.34-2.54c.21-.23-.14-.59-.36-.35Z"/>
                <path class="cls-4" d="M575.6,511.7a11.8,11.8,0,0,0,1.66,9.53,9.23,9.23,0,0,0,8.27,3.76c3.47-.36,6.37-3.25,7.94-6.22a8.69,8.69,0,0,0-.59-9,9.62,9.62,0,0,0-10.09-3.8c-.31.07-.18.55.13.48a9.17,9.17,0,0,1,9,2.91,8.36,8.36,0,0,1,1.5,8.31,12.91,12.91,0,0,1-3,4.21,8.41,8.41,0,0,1-4.16,2.52,8.61,8.61,0,0,1-8.09-2.7,11,11,0,0,1-2.17-9.83c.07-.31-.41-.44-.48-.13Z"/>
                <path class="cls-4" d="M575.52,503.87c-1,.87-2.1,1.86-2.25,3.18l0,.07a11.19,11.19,0,0,0,2.51,10.36,8.6,8.6,0,0,0,10.2,1.63,11.36,11.36,0,0,0,2.24-1.86,12.11,12.11,0,0,0,3.2-5.06,8.93,8.93,0,0,0-4.72-10A10.46,10.46,0,0,0,575.52,503.87Zm4.88.46a2.49,2.49,0,0,1,1.58,2.73,2.28,2.28,0,0,1-2.54,1.76c-1.12-.22-2.12-1.29-1.76-2.38v-.06c.11-.64.14-1.43.71-1.83A2.25,2.25,0,0,1,580.4,504.33Z"/>
                <path class="cls-4" d="M563.08,503a5.17,5.17,0,0,0-2.67-1.75c-.31-.07-.44.41-.13.48a4.66,4.66,0,0,1,2.44,1.62.25.25,0,0,0,.36,0,.26.26,0,0,0,0-.35Z"/>
                <path class="cls-4" d="M560.5,501a11.64,11.64,0,0,0-10.09.94,9.19,9.19,0,0,0-4.14,7.77c.08,3.46,2.75,6.67,5.56,8.47a8.69,8.69,0,0,0,8.72.28,9.58,9.58,0,0,0,5-9.65c0-.32-.53-.32-.5,0a9.18,9.18,0,0,1-3.64,8.58,8.39,8.39,0,0,1-8.42.85,12.1,12.1,0,0,1-3.71-3,9.32,9.32,0,0,1-2.38-4.44,8.6,8.6,0,0,1,3.16-7.93,11,11,0,0,1,10.35-1.35c.3.1.43-.38.13-.48Z"/>
                <path class="cls-4" d="M568.32,501.78c-.76-1.09-1.63-2.28-2.92-2.57l-.07,0a11.17,11.17,0,0,0-10.57,1.41,8.58,8.58,0,0,0-2.69,10,11.52,11.52,0,0,0,1.61,2.43,12,12,0,0,0,4.7,3.7,8.92,8.92,0,0,0,10.41-3.63A10.46,10.46,0,0,0,568.32,501.78Zm-1,4.81a2.5,2.5,0,0,1-2.88,1.28,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.55-1.5l.06,0c.62.17,1.4.29,1.74.9A2.22,2.22,0,0,1,567.35,506.59Z"/>
                <path class="cls-4" d="M313.07,506.11a5.17,5.17,0,0,0-3.11-.75.27.27,0,0,0-.25.25c0,.12.12.27.25.25a4.67,4.67,0,0,1,2.86.68c.28.15.53-.28.25-.43Z"/>
                <path class="cls-4" d="M310,505.11a11.75,11.75,0,0,0-8.81,3.91,9.19,9.19,0,0,0-1.73,8.76c1.11,3.34,4.55,5.46,7.81,6.33a8.69,8.69,0,0,0,8.7-2.56,9.59,9.59,0,0,0,1.37-10.86c-.13-.29-.56,0-.43.25a9.15,9.15,0,0,1-.65,9.5,8.31,8.31,0,0,1-8,3.38c-3.08-.55-6.71-2.54-8-5.5A8.64,8.64,0,0,1,301,510a11.1,11.1,0,0,1,8.93-4.37.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M317.55,503.14c-1.08-.76-2.32-1.59-3.63-1.41h-.07a11.19,11.19,0,0,0-9.45,4.95,8.6,8.6,0,0,0,.9,10.29,11.4,11.4,0,0,0,2.35,1.73,12.15,12.15,0,0,0,5.69,1.87,9,9,0,0,0,8.53-7A10.47,10.47,0,0,0,317.55,503.14Zm.74,4.85a2.51,2.51,0,0,1-2.26,2.19,2.28,2.28,0,0,1-2.32-2c-.07-1.14.73-2.36,1.87-2.28h.06c.65-.05,1.42-.21,1.95.25A2.23,2.23,0,0,1,318.29,508Z"/>
                <path class="cls-4" d="M354.86,508.94a5.14,5.14,0,0,0-1.48,2.89.24.24,0,0,0,.24.25.25.25,0,0,0,.25-.25,4.67,4.67,0,0,1,1.34-2.54c.22-.23-.13-.59-.35-.35Z"/>
                <path class="cls-4" d="M353.14,511.7a11.8,11.8,0,0,0,1.67,9.53,9.22,9.22,0,0,0,8.26,3.76c3.48-.36,6.37-3.25,7.95-6.22a8.71,8.71,0,0,0-.59-9,9.62,9.62,0,0,0-10.09-3.8c-.31.07-.18.55.13.48a9.17,9.17,0,0,1,9,2.91,8.36,8.36,0,0,1,1.5,8.31,12.91,12.91,0,0,1-3,4.21,8.41,8.41,0,0,1-4.16,2.52,8.64,8.64,0,0,1-8.1-2.7,11.08,11.08,0,0,1-2.17-9.83.25.25,0,0,0-.48-.13Z"/>
                <path class="cls-4" d="M353.07,503.87c-1,.87-2.1,1.86-2.25,3.18l0,.07a11.19,11.19,0,0,0,2.51,10.36c2.59,2.66,7,3.53,10.2,1.63a11.14,11.14,0,0,0,2.25-1.86,12,12,0,0,0,3.19-5.06,8.91,8.91,0,0,0-4.71-10A10.45,10.45,0,0,0,353.07,503.87Zm4.88.46a2.5,2.5,0,0,1,1.58,2.73,2.28,2.28,0,0,1-2.54,1.76c-1.12-.22-2.12-1.29-1.76-2.38v-.06c.1-.64.14-1.43.71-1.83A2.24,2.24,0,0,1,358,504.33Z"/>
                <path class="cls-4" d="M340.62,503a5.1,5.1,0,0,0-2.66-1.75c-.31-.07-.45.41-.14.48a4.65,4.65,0,0,1,2.45,1.62.24.24,0,0,0,.35,0,.25.25,0,0,0,0-.35Z"/>
                <path class="cls-4" d="M338.05,501A11.64,11.64,0,0,0,328,502a9.23,9.23,0,0,0-4.15,7.77c.08,3.46,2.76,6.67,5.57,8.47a8.69,8.69,0,0,0,8.72.28,9.58,9.58,0,0,0,5-9.65c0-.32-.53-.32-.5,0a9.17,9.17,0,0,1-3.65,8.58,8.38,8.38,0,0,1-8.41.85,12.1,12.1,0,0,1-3.71-3,9.32,9.32,0,0,1-2.38-4.44,8.57,8.57,0,0,1,3.16-7.93,11,11,0,0,1,10.35-1.35c.3.1.43-.38.13-.48Z"/>
                <path class="cls-4" d="M345.87,501.78c-.76-1.09-1.63-2.28-2.93-2.57l-.06,0a11.16,11.16,0,0,0-10.57,1.41,8.58,8.58,0,0,0-2.69,10,11.52,11.52,0,0,0,1.61,2.43,12,12,0,0,0,4.7,3.7,8.92,8.92,0,0,0,10.41-3.63A10.48,10.48,0,0,0,345.87,501.78Zm-1,4.81a2.49,2.49,0,0,1-2.87,1.28,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.55-1.5l.05,0c.63.17,1.41.29,1.75.9A2.19,2.19,0,0,1,344.89,506.59Z"/>
                <path class="cls-4" d="M387.4,506.11a5.18,5.18,0,0,0-3.11-.75.27.27,0,0,0-.25.25c0,.12.12.27.25.25a4.67,4.67,0,0,1,2.86.68c.28.15.53-.28.25-.43Z"/>
                <path class="cls-4" d="M384.29,505.11a11.78,11.78,0,0,0-8.81,3.91,9.21,9.21,0,0,0-1.72,8.76c1.11,3.34,4.55,5.46,7.81,6.33a8.69,8.69,0,0,0,8.7-2.56,9.61,9.61,0,0,0,1.37-10.86c-.13-.29-.56,0-.43.25a9.15,9.15,0,0,1-.65,9.5,8.31,8.31,0,0,1-8,3.38c-3.08-.55-6.71-2.54-8-5.5a8.64,8.64,0,0,1,.88-8.34,11.09,11.09,0,0,1,8.92-4.37.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M391.88,503.14c-1.09-.76-2.32-1.59-3.63-1.41h-.07a11.19,11.19,0,0,0-9.45,4.95,8.6,8.6,0,0,0,.9,10.29,11.4,11.4,0,0,0,2.35,1.73,12.15,12.15,0,0,0,5.69,1.87,9,9,0,0,0,8.53-7A10.49,10.49,0,0,0,391.88,503.14Zm.74,4.85a2.51,2.51,0,0,1-2.26,2.19,2.3,2.3,0,0,1-2.33-2c-.06-1.14.74-2.36,1.88-2.28H390c.65-.05,1.42-.21,1.95.25A2.23,2.23,0,0,1,392.62,508Z"/>
                <path class="cls-4" d="M429.19,508.94a5.14,5.14,0,0,0-1.48,2.89.24.24,0,0,0,.24.25.25.25,0,0,0,.25-.25,4.67,4.67,0,0,1,1.34-2.54c.22-.23-.13-.59-.35-.35Z"/>
                <path class="cls-4" d="M427.47,511.7a11.76,11.76,0,0,0,1.67,9.53A9.21,9.21,0,0,0,437.4,525c3.48-.36,6.37-3.25,8-6.22a8.71,8.71,0,0,0-.59-9,9.62,9.62,0,0,0-10.09-3.8c-.32.07-.18.55.13.48a9.15,9.15,0,0,1,9,2.91,8.34,8.34,0,0,1,1.5,8.31,12.74,12.74,0,0,1-2.95,4.21,8.45,8.45,0,0,1-4.16,2.52,8.64,8.64,0,0,1-8.1-2.7,11.08,11.08,0,0,1-2.17-9.83.25.25,0,0,0-.48-.13Z"/>
                <path class="cls-4" d="M427.4,503.87c-1,.87-2.1,1.86-2.25,3.18l0,.07a11.19,11.19,0,0,0,2.51,10.36,8.6,8.6,0,0,0,10.2,1.63,11.14,11.14,0,0,0,2.25-1.86,12,12,0,0,0,3.19-5.06,8.91,8.91,0,0,0-4.71-10A10.45,10.45,0,0,0,427.4,503.87Zm4.88.46a2.5,2.5,0,0,1,1.58,2.73,2.28,2.28,0,0,1-2.54,1.76c-1.12-.22-2.12-1.29-1.77-2.38l0-.06c.1-.64.14-1.43.71-1.83A2.24,2.24,0,0,1,432.28,504.33Z"/>
                <path class="cls-4" d="M415,503a5.14,5.14,0,0,0-2.66-1.75c-.32-.07-.45.41-.14.48a4.69,4.69,0,0,1,2.45,1.62.24.24,0,0,0,.35,0,.25.25,0,0,0,0-.35Z"/>
                <path class="cls-4" d="M412.38,501a11.64,11.64,0,0,0-10.09.94,9.18,9.18,0,0,0-4.15,7.77c.08,3.46,2.76,6.67,5.57,8.47a8.69,8.69,0,0,0,8.72.28,9.56,9.56,0,0,0,4.95-9.65c0-.32-.52-.32-.49,0a9.17,9.17,0,0,1-3.65,8.58,8.38,8.38,0,0,1-8.41.85,12.1,12.1,0,0,1-3.71-3,9.32,9.32,0,0,1-2.38-4.44,8.55,8.55,0,0,1,3.16-7.93,11,11,0,0,1,10.34-1.35c.31.1.44-.38.14-.48Z"/>
                <path class="cls-4" d="M420.2,501.78c-.76-1.09-1.63-2.28-2.93-2.57l-.06,0a11.16,11.16,0,0,0-10.57,1.41,8.58,8.58,0,0,0-2.69,10,11.52,11.52,0,0,0,1.61,2.43,12,12,0,0,0,4.7,3.7,8.92,8.92,0,0,0,10.41-3.63A10.48,10.48,0,0,0,420.2,501.78Zm-1,4.81a2.49,2.49,0,0,1-2.87,1.28,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.54-1.5l.06,0c.63.17,1.41.29,1.75.9A2.22,2.22,0,0,1,419.22,506.59Z"/>
                <path class="cls-4" d="M467.07,530.7A5.13,5.13,0,0,0,464,530a.25.25,0,0,0-.25.25c0,.12.11.27.25.25a4.68,4.68,0,0,1,2.85.67.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M464,529.71a11.77,11.77,0,0,0-8.81,3.91,9.21,9.21,0,0,0-1.73,8.76c1.12,3.34,4.55,5.46,7.82,6.33a8.7,8.7,0,0,0,8.7-2.56,9.61,9.61,0,0,0,1.37-10.86c-.14-.29-.57,0-.43.25a9.16,9.16,0,0,1-.65,9.49,8.33,8.33,0,0,1-8,3.39c-3.09-.55-6.71-2.54-8-5.5a8.69,8.69,0,0,1,.87-8.35,11.1,11.1,0,0,1,8.93-4.36.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M471.56,527.74c-1.09-.77-2.32-1.59-3.64-1.41h-.06a11.18,11.18,0,0,0-9.45,4.95,8.6,8.6,0,0,0,.9,10.29,11.5,11.5,0,0,0,2.35,1.73,12,12,0,0,0,5.68,1.86,8.91,8.91,0,0,0,8.53-7A10.45,10.45,0,0,0,471.56,527.74Zm.73,4.85a2.49,2.49,0,0,1-2.25,2.19,2.29,2.29,0,0,1-2.33-2c-.07-1.14.74-2.37,1.88-2.29h.06c.65-.05,1.42-.21,2,.25A2.24,2.24,0,0,1,472.29,532.59Z"/>
                <path class="cls-4" d="M508.87,533.54a5.21,5.21,0,0,0-1.49,2.89.25.25,0,0,0,.5,0,4.63,4.63,0,0,1,1.34-2.54c.22-.24-.13-.59-.35-.35Z"/>
                <path class="cls-4" d="M507.15,536.3a11.76,11.76,0,0,0,1.67,9.53,9.2,9.2,0,0,0,8.26,3.76c3.47-.36,6.37-3.25,7.94-6.22a8.71,8.71,0,0,0-.58-9,9.63,9.63,0,0,0-10.1-3.8c-.31.06-.18.54.14.48a9.15,9.15,0,0,1,9,2.91,8.35,8.35,0,0,1,1.5,8.31,12.91,12.91,0,0,1-3,4.21A8.52,8.52,0,0,1,517.9,549a8.62,8.62,0,0,1-8.1-2.7,11,11,0,0,1-2.17-9.83c.07-.31-.41-.44-.48-.13Z"/>
                <path class="cls-4" d="M507.07,528.47c-1,.87-2.1,1.86-2.25,3.18l0,.06a11.2,11.2,0,0,0,2.51,10.37,8.58,8.58,0,0,0,10.2,1.62,11.31,11.31,0,0,0,2.24-1.85,12.14,12.14,0,0,0,3.2-5.07,8.94,8.94,0,0,0-4.71-10A10.5,10.5,0,0,0,507.07,528.47Zm4.89.46a2.5,2.5,0,0,1,1.58,2.72,2.29,2.29,0,0,1-2.54,1.76,2,2,0,0,1-1.77-2.37l0-.06c.1-.64.14-1.43.71-1.83A2.21,2.21,0,0,1,512,528.93Z"/>
                <path class="cls-4" d="M494.63,527.61a5.07,5.07,0,0,0-2.67-1.75c-.31-.07-.44.41-.13.48a4.69,4.69,0,0,1,2.45,1.62.25.25,0,1,0,.35-.35Z"/>
                <path class="cls-4" d="M492.05,525.62a11.67,11.67,0,0,0-10.09,1,9.18,9.18,0,0,0-4.14,7.76c.08,3.46,2.75,6.68,5.56,8.48a8.71,8.71,0,0,0,8.72.28,9.59,9.59,0,0,0,5-9.65c0-.32-.53-.32-.5,0a9.16,9.16,0,0,1-3.64,8.58,8.38,8.38,0,0,1-8.41.85,12.05,12.05,0,0,1-3.72-3,9.13,9.13,0,0,1-2.37-4.44,8.54,8.54,0,0,1,3.16-7.92,11,11,0,0,1,10.34-1.36c.3.11.43-.37.13-.48Z"/>
                <path class="cls-4" d="M499.88,526.38c-.76-1.09-1.64-2.28-2.93-2.57l-.06,0a11.21,11.21,0,0,0-10.58,1.4,8.58,8.58,0,0,0-2.68,10,10.87,10.87,0,0,0,1.6,2.43,12,12,0,0,0,4.71,3.71,8.93,8.93,0,0,0,10.41-3.63A10.5,10.5,0,0,0,499.88,526.38Zm-1,4.81a2.49,2.49,0,0,1-2.87,1.28,2.29,2.29,0,0,1-1.49-2.71,2,2,0,0,1,2.55-1.5l.06,0c.63.17,1.41.29,1.74.9A2.19,2.19,0,0,1,498.9,531.19Z"/>
                <path class="cls-4" d="M541.4,530.7a5.13,5.13,0,0,0-3.1-.74.25.25,0,0,0-.25.25c0,.12.11.27.25.25a4.7,4.7,0,0,1,2.85.67.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M538.3,529.71a11.75,11.75,0,0,0-8.81,3.91,9.21,9.21,0,0,0-1.73,8.76c1.12,3.34,4.55,5.46,7.82,6.33a8.7,8.7,0,0,0,8.7-2.56,9.61,9.61,0,0,0,1.37-10.86c-.14-.29-.57,0-.43.25a9.16,9.16,0,0,1-.65,9.49,8.33,8.33,0,0,1-8.05,3.39c-3.08-.55-6.7-2.54-8-5.5a8.69,8.69,0,0,1,.87-8.35,11.09,11.09,0,0,1,8.93-4.36.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M545.89,527.74c-1.09-.77-2.32-1.59-3.64-1.41h-.07a11.18,11.18,0,0,0-9.44,4.95,8.6,8.6,0,0,0,.9,10.29,11.71,11.71,0,0,0,2.34,1.73,12.1,12.1,0,0,0,5.69,1.86,8.91,8.91,0,0,0,8.53-7A10.45,10.45,0,0,0,545.89,527.74Zm.73,4.85a2.49,2.49,0,0,1-2.26,2.19,2.29,2.29,0,0,1-2.32-2c-.07-1.14.74-2.37,1.88-2.29H544c.65-.05,1.42-.21,1.95.25A2.24,2.24,0,0,1,546.62,532.59Z"/>
                <path class="cls-4" d="M583.2,533.54a5.15,5.15,0,0,0-1.49,2.89.25.25,0,0,0,.5,0,4.63,4.63,0,0,1,1.34-2.54c.22-.24-.13-.59-.35-.35Z"/>
                <path class="cls-4" d="M581.48,536.3a11.76,11.76,0,0,0,1.67,9.53,9.2,9.2,0,0,0,8.26,3.76c3.47-.36,6.37-3.25,7.94-6.22a8.71,8.71,0,0,0-.58-9,9.63,9.63,0,0,0-10.1-3.8c-.31.06-.18.54.14.48a9.15,9.15,0,0,1,9,2.91,8.35,8.35,0,0,1,1.5,8.31,12.91,12.91,0,0,1-3,4.21,8.52,8.52,0,0,1-4.15,2.52,8.62,8.62,0,0,1-8.1-2.7,11,11,0,0,1-2.17-9.83c.07-.31-.41-.44-.48-.13Z"/>
                <path class="cls-4" d="M581.4,528.47c-1,.87-2.1,1.86-2.25,3.18l0,.06a11.2,11.2,0,0,0,2.51,10.37,8.58,8.58,0,0,0,10.2,1.62,11.31,11.31,0,0,0,2.24-1.85,12.14,12.14,0,0,0,3.2-5.07,9,9,0,0,0-4.71-10A10.49,10.49,0,0,0,581.4,528.47Zm4.89.46a2.48,2.48,0,0,1,1.57,2.72,2.27,2.27,0,0,1-2.53,1.76,2,2,0,0,1-1.77-2.37V531c.11-.64.15-1.43.72-1.83A2.21,2.21,0,0,1,586.29,528.93Z"/>
                <path class="cls-4" d="M569,527.61a5.11,5.11,0,0,0-2.67-1.75c-.31-.07-.44.41-.13.48a4.69,4.69,0,0,1,2.45,1.62.25.25,0,1,0,.35-.35Z"/>
                <path class="cls-4" d="M566.38,525.62a11.67,11.67,0,0,0-10.09,1,9.18,9.18,0,0,0-4.14,7.76c.08,3.46,2.75,6.68,5.56,8.48a8.71,8.71,0,0,0,8.72.28,9.59,9.59,0,0,0,5-9.65c0-.32-.53-.32-.5,0a9.18,9.18,0,0,1-3.64,8.58,8.38,8.38,0,0,1-8.41.85,12.05,12.05,0,0,1-3.72-3,9.23,9.23,0,0,1-2.38-4.44,8.59,8.59,0,0,1,3.16-7.92,11,11,0,0,1,10.35-1.36c.3.11.43-.37.13-.48Z"/>
                <path class="cls-4" d="M574.2,526.38c-.75-1.09-1.63-2.28-2.92-2.57l-.07,0a11.19,11.19,0,0,0-10.57,1.4,8.58,8.58,0,0,0-2.68,10,10.87,10.87,0,0,0,1.6,2.43,12,12,0,0,0,4.7,3.71,8.92,8.92,0,0,0,10.41-3.63A10.46,10.46,0,0,0,574.2,526.38Zm-1,4.81a2.49,2.49,0,0,1-2.87,1.28,2.29,2.29,0,0,1-1.49-2.71,2,2,0,0,1,2.55-1.5l.06,0c.63.17,1.41.29,1.74.9A2.22,2.22,0,0,1,573.23,531.19Z"/>
                <path class="cls-4" d="M319,530.7a5.16,5.16,0,0,0-3.11-.74.26.26,0,0,0-.25.25c0,.12.12.27.25.25a4.71,4.71,0,0,1,2.86.67.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M315.85,529.71a11.75,11.75,0,0,0-8.81,3.91,9.18,9.18,0,0,0-1.73,8.76c1.12,3.34,4.55,5.46,7.81,6.33a8.71,8.71,0,0,0,8.71-2.56,9.62,9.62,0,0,0,1.37-10.86c-.14-.29-.57,0-.43.25a9.16,9.16,0,0,1-.66,9.49,8.31,8.31,0,0,1-8,3.39c-3.08-.55-6.7-2.54-8-5.5a8.66,8.66,0,0,1,.87-8.35,11.09,11.09,0,0,1,8.93-4.36.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M323.43,527.74c-1.08-.77-2.31-1.59-3.63-1.41h-.07a11.21,11.21,0,0,0-9.45,4.95,8.58,8.58,0,0,0,.91,10.29,11.43,11.43,0,0,0,2.34,1.73,12,12,0,0,0,5.69,1.86,8.92,8.92,0,0,0,8.53-7A10.46,10.46,0,0,0,323.43,527.74Zm.74,4.85a2.51,2.51,0,0,1-2.26,2.19,2.29,2.29,0,0,1-2.32-2c-.07-1.14.73-2.37,1.87-2.29h.07c.64-.05,1.42-.21,1.94.25A2.22,2.22,0,0,1,324.17,532.59Z"/>
                <path class="cls-4" d="M360.74,533.54a5.14,5.14,0,0,0-1.48,2.89.24.24,0,0,0,.25.25.26.26,0,0,0,.25-.25,4.56,4.56,0,0,1,1.33-2.54c.22-.24-.13-.59-.35-.35Z"/>
                <path class="cls-4" d="M359,536.3a11.76,11.76,0,0,0,1.66,9.53,9.23,9.23,0,0,0,8.27,3.76c3.47-.36,6.37-3.25,7.94-6.22a8.69,8.69,0,0,0-.59-9,9.61,9.61,0,0,0-10.09-3.8c-.31.06-.18.54.13.48a9.17,9.17,0,0,1,9,2.91,8.35,8.35,0,0,1,1.5,8.31,12.91,12.91,0,0,1-3,4.21,8,8,0,0,1-12.26-.18,11,11,0,0,1-2.16-9.83c.07-.31-.41-.44-.48-.13Z"/>
                <path class="cls-4" d="M359,528.47c-1,.87-2.1,1.86-2.25,3.18l0,.06a11.17,11.17,0,0,0,2.51,10.37c2.59,2.66,7,3.53,10.19,1.62a11.09,11.09,0,0,0,2.25-1.85,12,12,0,0,0,3.19-5.07,8.92,8.92,0,0,0-4.71-10A10.48,10.48,0,0,0,359,528.47Zm4.88.46a2.48,2.48,0,0,1,1.58,2.72,2.28,2.28,0,0,1-2.54,1.76c-1.12-.21-2.12-1.28-1.76-2.37V531c.11-.64.14-1.43.71-1.83A2.22,2.22,0,0,1,363.83,528.93Z"/>
                <path class="cls-4" d="M346.51,527.61a5.11,5.11,0,0,0-2.67-1.75c-.31-.07-.45.41-.13.48a4.66,4.66,0,0,1,2.44,1.62.25.25,0,1,0,.36-.35Z"/>
                <path class="cls-4" d="M343.93,525.62a11.67,11.67,0,0,0-10.09,1,9.18,9.18,0,0,0-4.14,7.76c.07,3.46,2.75,6.68,5.56,8.48a8.71,8.71,0,0,0,8.72.28,9.59,9.59,0,0,0,5-9.65c0-.32-.53-.32-.5,0A9.18,9.18,0,0,1,344.8,542a8.39,8.39,0,0,1-8.42.85,12.13,12.13,0,0,1-3.71-3,9.23,9.23,0,0,1-2.38-4.44,8.59,8.59,0,0,1,3.16-7.92,11,11,0,0,1,10.35-1.36c.3.11.43-.37.13-.48Z"/>
                <path class="cls-4" d="M351.75,526.38c-.76-1.09-1.63-2.28-2.93-2.57l-.06,0a11.19,11.19,0,0,0-10.57,1.4c-2.92,2.3-4.25,6.6-2.69,10a11.27,11.27,0,0,0,1.61,2.43,11.94,11.94,0,0,0,4.7,3.71,8.92,8.92,0,0,0,10.41-3.63A10.48,10.48,0,0,0,351.75,526.38Zm-1,4.81a2.5,2.5,0,0,1-2.88,1.28,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.55-1.5l.06,0c.62.17,1.4.29,1.74.9A2.22,2.22,0,0,1,350.78,531.19Z"/>
                <path class="cls-4" d="M393.28,530.7a5.16,5.16,0,0,0-3.11-.74.26.26,0,0,0-.25.25c0,.12.12.27.25.25a4.73,4.73,0,0,1,2.86.67.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M390.18,529.71a11.75,11.75,0,0,0-8.81,3.91,9.18,9.18,0,0,0-1.73,8.76c1.12,3.34,4.55,5.46,7.81,6.33a8.69,8.69,0,0,0,8.7-2.56,9.59,9.59,0,0,0,1.37-10.86c-.13-.29-.56,0-.42.25a9.16,9.16,0,0,1-.66,9.49,8.31,8.31,0,0,1-8,3.39c-3.08-.55-6.71-2.54-8-5.5a8.66,8.66,0,0,1,.87-8.35,11.09,11.09,0,0,1,8.93-4.36.25.25,0,0,0,0-.5Z"/>
                <path class="cls-4" d="M397.76,527.74c-1.08-.77-2.32-1.59-3.63-1.41h-.07a11.19,11.19,0,0,0-9.45,4.95,8.58,8.58,0,0,0,.91,10.29,11.43,11.43,0,0,0,2.34,1.73,12,12,0,0,0,5.69,1.86,8.93,8.93,0,0,0,8.53-7A10.46,10.46,0,0,0,397.76,527.74Zm.74,4.85a2.51,2.51,0,0,1-2.26,2.19,2.28,2.28,0,0,1-2.32-2c-.07-1.14.73-2.37,1.87-2.29h.07c.64-.05,1.42-.21,1.94.25A2.22,2.22,0,0,1,398.5,532.59Z"/>
                <path class="cls-4" d="M435.07,533.54a5.14,5.14,0,0,0-1.48,2.89.24.24,0,0,0,.25.25.25.25,0,0,0,.24-.25,4.63,4.63,0,0,1,1.34-2.54c.22-.24-.13-.59-.35-.35Z"/>
                <path class="cls-4" d="M433.35,536.3a11.8,11.8,0,0,0,1.67,9.53,9.23,9.23,0,0,0,8.27,3.76c3.47-.36,6.37-3.25,7.94-6.22a8.69,8.69,0,0,0-.59-9,9.61,9.61,0,0,0-10.09-3.8c-.31.06-.18.54.13.48a9.17,9.17,0,0,1,9,2.91,8.35,8.35,0,0,1,1.5,8.31,12.91,12.91,0,0,1-3,4.21,8,8,0,0,1-12.26-.18,11.08,11.08,0,0,1-2.17-9.83.25.25,0,0,0-.48-.13Z"/>
                <path class="cls-4" d="M433.28,528.47c-1,.87-2.1,1.86-2.25,3.18l0,.06a11.18,11.18,0,0,0,2.52,10.37c2.59,2.66,7,3.53,10.19,1.62a11.09,11.09,0,0,0,2.25-1.85,12,12,0,0,0,3.19-5.07,8.92,8.92,0,0,0-4.71-10A10.48,10.48,0,0,0,433.28,528.47Zm4.88.46a2.49,2.49,0,0,1,1.58,2.72,2.28,2.28,0,0,1-2.54,1.76c-1.12-.21-2.12-1.28-1.76-2.37V531c.1-.64.14-1.43.71-1.83A2.21,2.21,0,0,1,438.16,528.93Z"/>
                <path class="cls-4" d="M420.83,527.61a5,5,0,0,0-2.66-1.75c-.31-.07-.45.41-.13.48a4.66,4.66,0,0,1,2.44,1.62.25.25,0,0,0,.35,0,.24.24,0,0,0,0-.35Z"/>
                <path class="cls-4" d="M418.26,525.62a11.67,11.67,0,0,0-10.09,1,9.18,9.18,0,0,0-4.14,7.76c.07,3.46,2.75,6.68,5.56,8.48a8.71,8.71,0,0,0,8.72.28,9.59,9.59,0,0,0,5-9.65c0-.32-.53-.32-.5,0a9.18,9.18,0,0,1-3.64,8.58,8.39,8.39,0,0,1-8.42.85,12.13,12.13,0,0,1-3.71-3,9.23,9.23,0,0,1-2.38-4.44,8.59,8.59,0,0,1,3.16-7.92,11,11,0,0,1,10.35-1.36c.3.11.43-.37.13-.48Z"/>
                <path class="cls-4" d="M426.08,526.38c-.76-1.09-1.63-2.28-2.93-2.57l-.06,0a11.19,11.19,0,0,0-10.57,1.4c-2.92,2.3-4.25,6.6-2.69,10a11.27,11.27,0,0,0,1.61,2.43,11.94,11.94,0,0,0,4.7,3.71,8.92,8.92,0,0,0,10.41-3.63A10.48,10.48,0,0,0,426.08,526.38Zm-1,4.81a2.5,2.5,0,0,1-2.88,1.28,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.55-1.5l.06,0c.62.17,1.4.29,1.74.9A2.22,2.22,0,0,1,425.11,531.19Z"/>
                <path class="cls-4" d="M162.21,556.52a5.13,5.13,0,0,0-3.1-.74.25.25,0,0,0,0,.5A4.61,4.61,0,0,1,162,557a.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M159.11,555.53a11.79,11.79,0,0,0-8.81,3.9,9.23,9.23,0,0,0-1.73,8.77c1.12,3.34,4.55,5.45,7.82,6.33a8.7,8.7,0,0,0,8.7-2.56,9.62,9.62,0,0,0,1.37-10.87c-.14-.28-.57,0-.43.26a9.14,9.14,0,0,1-.65,9.49,8.33,8.33,0,0,1-8,3.39c-3.08-.55-6.71-2.54-8-5.5a8.68,8.68,0,0,1,.87-8.35,11.12,11.12,0,0,1,8.93-4.36c.32,0,.32-.51,0-.5Z"/>
                <path class="cls-4" d="M166.7,553.55c-1.09-.76-2.32-1.58-3.63-1.41H163a11.18,11.18,0,0,0-9.45,5,8.58,8.58,0,0,0,.9,10.28,11.4,11.4,0,0,0,2.35,1.73,12,12,0,0,0,5.69,1.87,8.92,8.92,0,0,0,8.52-7A10.47,10.47,0,0,0,166.7,553.55Zm.73,4.85a2.47,2.47,0,0,1-2.25,2.19,2.28,2.28,0,0,1-2.33-2c-.07-1.14.74-2.37,1.88-2.29h.06a2.52,2.52,0,0,1,2,.25A2.19,2.19,0,0,1,167.43,558.4Z"/>
                <path class="cls-4" d="M204,559.35a5.24,5.24,0,0,0-1.49,2.9.25.25,0,0,0,.5,0,4.7,4.7,0,0,1,1.34-2.55c.22-.23-.13-.58-.35-.35Z"/>
                <path class="cls-4" d="M202.29,562.12a11.74,11.74,0,0,0,1.67,9.52,9.16,9.16,0,0,0,8.26,3.76c3.47-.35,6.37-3.24,7.94-6.21a8.71,8.71,0,0,0-.58-9,9.62,9.62,0,0,0-10.09-3.8c-.32.06-.19.54.13.48a9.15,9.15,0,0,1,9,2.91,8.34,8.34,0,0,1,1.5,8.3,12.81,12.81,0,0,1-3,4.22,8.43,8.43,0,0,1-4.15,2.51,8.61,8.61,0,0,1-8.1-2.69,11.06,11.06,0,0,1-2.17-9.83c.07-.31-.41-.44-.48-.13Z"/>
                <path class="cls-4" d="M202.21,554.28c-1,.87-2.1,1.87-2.25,3.19l0,.06a11.2,11.2,0,0,0,2.51,10.37,8.58,8.58,0,0,0,10.2,1.62,11.67,11.67,0,0,0,2.24-1.85,12.14,12.14,0,0,0,3.2-5.07,8.92,8.92,0,0,0-4.71-10A10.47,10.47,0,0,0,202.21,554.28Zm4.89.47a2.5,2.5,0,0,1,1.58,2.72,2.29,2.29,0,0,1-2.54,1.76,2,2,0,0,1-1.77-2.37l0-.07c.1-.64.14-1.42.71-1.82A2.21,2.21,0,0,1,207.1,554.75Z"/>
                <path class="cls-4" d="M189.77,553.43a5.14,5.14,0,0,0-2.67-1.76c-.31-.07-.44.41-.13.48a4.7,4.7,0,0,1,2.45,1.63.25.25,0,1,0,.35-.35Z"/>
                <path class="cls-4" d="M187.19,551.44a11.62,11.62,0,0,0-10.08.95,9.16,9.16,0,0,0-4.15,7.76c.08,3.46,2.75,6.68,5.56,8.47a8.69,8.69,0,0,0,8.72.29,9.59,9.59,0,0,0,5-9.66c0-.31-.52-.32-.49,0a9.21,9.21,0,0,1-3.65,8.59,8.38,8.38,0,0,1-8.41.85,12.17,12.17,0,0,1-3.72-3.05,9.13,9.13,0,0,1-2.37-4.44,8.54,8.54,0,0,1,3.16-7.92,11,11,0,0,1,10.34-1.36c.3.11.43-.37.13-.48Z"/>
                <path class="cls-4" d="M195,552.2c-.76-1.09-1.64-2.29-2.93-2.57l-.06,0A11.21,11.21,0,0,0,181.45,551a8.58,8.58,0,0,0-2.68,10,10.91,10.91,0,0,0,1.61,2.43,12.05,12.05,0,0,0,4.7,3.71,8.93,8.93,0,0,0,10.41-3.64A10.46,10.46,0,0,0,195,552.2ZM194,557a2.5,2.5,0,0,1-2.87,1.28,2.3,2.3,0,0,1-1.49-2.71,2,2,0,0,1,2.55-1.51l.06,0c.63.17,1.41.29,1.75.9A2.25,2.25,0,0,1,194,557Z"/>
                <path class="cls-4" d="M236.54,556.52a5.13,5.13,0,0,0-3.1-.74.25.25,0,0,0,0,.5,4.61,4.61,0,0,1,2.85.67.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M233.44,555.53a11.79,11.79,0,0,0-8.81,3.9,9.23,9.23,0,0,0-1.73,8.77c1.12,3.34,4.55,5.45,7.82,6.33a8.7,8.7,0,0,0,8.7-2.56,9.62,9.62,0,0,0,1.37-10.87c-.14-.28-.57,0-.43.26a9.16,9.16,0,0,1-.65,9.49,8.33,8.33,0,0,1-8,3.39c-3.08-.55-6.71-2.54-8-5.5a8.68,8.68,0,0,1,.87-8.35,11.1,11.1,0,0,1,8.93-4.36c.32,0,.32-.51,0-.5Z"/>
                <path class="cls-4" d="M241,553.55c-1.09-.76-2.32-1.58-3.64-1.41h-.06a11.18,11.18,0,0,0-9.45,5,8.58,8.58,0,0,0,.9,10.28,11.14,11.14,0,0,0,2.35,1.73,12,12,0,0,0,5.68,1.87,8.92,8.92,0,0,0,8.53-7A10.47,10.47,0,0,0,241,553.55Zm.73,4.85a2.47,2.47,0,0,1-2.25,2.19,2.27,2.27,0,0,1-2.33-2c-.07-1.14.74-2.37,1.88-2.29h.06a2.52,2.52,0,0,1,1.95.25A2.22,2.22,0,0,1,241.76,558.4Z"/>
                <path class="cls-4" d="M278.34,559.35a5.24,5.24,0,0,0-1.49,2.9.25.25,0,0,0,.5,0,4.7,4.7,0,0,1,1.34-2.55c.22-.23-.13-.58-.35-.35Z"/>
                <path class="cls-4" d="M276.62,562.12a11.74,11.74,0,0,0,1.67,9.52,9.16,9.16,0,0,0,8.26,3.76c3.47-.35,6.37-3.24,7.94-6.21a8.71,8.71,0,0,0-.58-9,9.63,9.63,0,0,0-10.1-3.8c-.31.06-.18.54.14.48a9.15,9.15,0,0,1,9,2.91,8.34,8.34,0,0,1,1.5,8.3,13,13,0,0,1-3,4.22,8.43,8.43,0,0,1-4.15,2.51,8.6,8.6,0,0,1-8.1-2.69,11.06,11.06,0,0,1-2.17-9.83c.07-.31-.41-.44-.48-.13Z"/>
                <path class="cls-4" d="M276.54,554.28c-1,.87-2.1,1.87-2.25,3.19l0,.06a11.2,11.2,0,0,0,2.51,10.37,8.58,8.58,0,0,0,10.2,1.62,11.67,11.67,0,0,0,2.24-1.85,12.14,12.14,0,0,0,3.2-5.07,8.92,8.92,0,0,0-4.71-10A10.47,10.47,0,0,0,276.54,554.28Zm4.89.47a2.5,2.5,0,0,1,1.58,2.72,2.29,2.29,0,0,1-2.54,1.76,2,2,0,0,1-1.77-2.37l0-.07c.1-.64.14-1.42.71-1.82A2.21,2.21,0,0,1,281.43,554.75Z"/>
                <path class="cls-4" d="M264.1,553.43a5.14,5.14,0,0,0-2.67-1.76c-.31-.07-.44.41-.13.48a4.7,4.7,0,0,1,2.45,1.63.25.25,0,1,0,.35-.35Z"/>
                <path class="cls-4" d="M261.52,551.44a11.64,11.64,0,0,0-10.09.95,9.18,9.18,0,0,0-4.14,7.76c.08,3.46,2.75,6.68,5.56,8.47a8.69,8.69,0,0,0,8.72.29,9.59,9.59,0,0,0,5-9.66c0-.31-.53-.32-.5,0a9.18,9.18,0,0,1-3.64,8.59,8.38,8.38,0,0,1-8.41.85,12.05,12.05,0,0,1-3.72-3.05,9.13,9.13,0,0,1-2.37-4.44,8.54,8.54,0,0,1,3.16-7.92,11,11,0,0,1,10.34-1.36c.3.11.43-.37.13-.48Z"/>
                <path class="cls-4" d="M269.35,552.2c-.76-1.09-1.64-2.29-2.93-2.57l-.06,0A11.21,11.21,0,0,0,255.78,551a8.58,8.58,0,0,0-2.68,10,11.23,11.23,0,0,0,1.6,2.43,12.15,12.15,0,0,0,4.71,3.71,8.93,8.93,0,0,0,10.41-3.64A10.48,10.48,0,0,0,269.35,552.2Zm-1,4.81a2.5,2.5,0,0,1-2.87,1.28,2.3,2.3,0,0,1-1.49-2.71,2,2,0,0,1,2.55-1.51l.06,0c.63.17,1.41.29,1.74.9A2.21,2.21,0,0,1,268.37,557Z"/>
                <path class="cls-4" d="M14.09,556.52a5.16,5.16,0,0,0-3.11-.74.25.25,0,0,0,0,.5,4.64,4.64,0,0,1,2.86.67.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M11,555.53a11.78,11.78,0,0,0-8.81,3.9A9.2,9.2,0,0,0,.45,568.2c1.12,3.34,4.55,5.45,7.82,6.33A8.7,8.7,0,0,0,17,572a9.62,9.62,0,0,0,1.37-10.87c-.14-.28-.57,0-.43.26a9.18,9.18,0,0,1-.65,9.49,8.33,8.33,0,0,1-8,3.39c-3.08-.55-6.7-2.54-8-5.5a8.66,8.66,0,0,1,.87-8.35A11.09,11.09,0,0,1,11,556c.32,0,.32-.51,0-.5Z"/>
                <path class="cls-4" d="M18.57,553.55c-1.08-.76-2.31-1.58-3.63-1.41h-.07a11.21,11.21,0,0,0-9.45,5,8.58,8.58,0,0,0,.91,10.28,11.34,11.34,0,0,0,2.34,1.73A12.08,12.08,0,0,0,14.36,571a8.93,8.93,0,0,0,8.53-7A10.47,10.47,0,0,0,18.57,553.55Zm.74,4.85a2.49,2.49,0,0,1-2.26,2.19,2.27,2.27,0,0,1-2.32-2c-.07-1.14.73-2.37,1.87-2.29h.07c.64-.06,1.42-.21,1.94.25A2.2,2.2,0,0,1,19.31,558.4Z"/>
                <path class="cls-4" d="M55.88,559.35a5.16,5.16,0,0,0-1.48,2.9c0,.13.13.25.25.25a.26.26,0,0,0,.25-.25,4.57,4.57,0,0,1,1.34-2.55c.21-.23-.14-.58-.36-.35Z"/>
                <path class="cls-4" d="M54.17,562.12a11.77,11.77,0,0,0,1.66,9.52,9.19,9.19,0,0,0,8.27,3.76c3.47-.35,6.37-3.24,7.94-6.21a8.69,8.69,0,0,0-.59-9,9.61,9.61,0,0,0-10.09-3.8c-.31.06-.18.54.13.48a9.17,9.17,0,0,1,9,2.91A8.34,8.34,0,0,1,72,568a13,13,0,0,1-3,4.22,8.46,8.46,0,0,1-4.16,2.51,8.58,8.58,0,0,1-8.09-2.69,11,11,0,0,1-2.17-9.83c.07-.31-.41-.44-.48-.13Z"/>
                <path class="cls-4" d="M54.09,554.28c-1,.87-2.1,1.87-2.25,3.19l0,.06a11.2,11.2,0,0,0,2.51,10.37,8.58,8.58,0,0,0,10.2,1.62,11.67,11.67,0,0,0,2.24-1.85A12.14,12.14,0,0,0,70,562.6a8.94,8.94,0,0,0-4.72-10A10.46,10.46,0,0,0,54.09,554.28Zm4.89.47a2.48,2.48,0,0,1,1.57,2.72A2.28,2.28,0,0,1,58,559.23a2,2,0,0,1-1.76-2.37v-.07c.11-.64.14-1.42.71-1.82A2.23,2.23,0,0,1,59,554.75Z"/>
                <path class="cls-4" d="M41.65,553.43A5.18,5.18,0,0,0,39,551.67c-.31-.07-.44.41-.13.48a4.68,4.68,0,0,1,2.44,1.63.25.25,0,1,0,.36-.35Z"/>
                <path class="cls-4" d="M39.07,551.44a11.64,11.64,0,0,0-10.09.95,9.18,9.18,0,0,0-4.14,7.76c.08,3.46,2.75,6.68,5.56,8.47a8.69,8.69,0,0,0,8.72.29,9.59,9.59,0,0,0,5-9.66c0-.31-.53-.32-.5,0a9.21,9.21,0,0,1-3.64,8.59,8.39,8.39,0,0,1-8.42.85,12.13,12.13,0,0,1-3.71-3.05,9.23,9.23,0,0,1-2.38-4.44,8.59,8.59,0,0,1,3.16-7.92,11,11,0,0,1,10.35-1.36c.3.11.43-.37.13-.48Z"/>
                <path class="cls-4" d="M46.89,552.2c-.76-1.09-1.63-2.29-2.92-2.57l-.07,0A11.19,11.19,0,0,0,33.33,551c-2.92,2.3-4.25,6.6-2.69,10a11.27,11.27,0,0,0,1.61,2.43,12.12,12.12,0,0,0,4.7,3.71,8.93,8.93,0,0,0,10.41-3.64A10.44,10.44,0,0,0,46.89,552.2Zm-1,4.81A2.51,2.51,0,0,1,43,558.29a2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.55-1.51l.06,0c.62.17,1.4.29,1.74.9A2.24,2.24,0,0,1,45.92,557Z"/>
                <path class="cls-4" d="M88.42,556.52a5.16,5.16,0,0,0-3.11-.74.25.25,0,1,0,0,.5,4.64,4.64,0,0,1,2.86.67.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M85.32,555.53a11.78,11.78,0,0,0-8.81,3.9,9.2,9.2,0,0,0-1.73,8.77c1.12,3.34,4.55,5.45,7.81,6.33A8.71,8.71,0,0,0,91.3,572a9.64,9.64,0,0,0,1.37-10.87c-.14-.28-.57,0-.43.26a9.16,9.16,0,0,1-.66,9.49,8.31,8.31,0,0,1-8,3.39c-3.08-.55-6.7-2.54-8-5.5a8.66,8.66,0,0,1,.87-8.35A11.09,11.09,0,0,1,85.32,556c.32,0,.32-.51,0-.5Z"/>
                <path class="cls-4" d="M92.9,553.55c-1.08-.76-2.31-1.58-3.63-1.41H89.2a11.21,11.21,0,0,0-9.45,5,8.57,8.57,0,0,0,.91,10.28A11.08,11.08,0,0,0,83,569.1,12,12,0,0,0,88.69,571a8.93,8.93,0,0,0,8.53-7A10.47,10.47,0,0,0,92.9,553.55Zm.74,4.85a2.49,2.49,0,0,1-2.26,2.19,2.27,2.27,0,0,1-2.32-2c-.07-1.14.73-2.37,1.87-2.29H91c.64-.06,1.42-.21,1.94.25A2.2,2.2,0,0,1,93.64,558.4Z"/>
                <path class="cls-4" d="M130.21,559.35a5.16,5.16,0,0,0-1.48,2.9c0,.13.12.25.25.25a.27.27,0,0,0,.25-.25,4.62,4.62,0,0,1,1.33-2.55c.22-.23-.13-.58-.35-.35Z"/>
                <path class="cls-4" d="M128.5,562.12a11.74,11.74,0,0,0,1.66,9.52,9.19,9.19,0,0,0,8.27,3.76c3.47-.35,6.37-3.24,7.94-6.21a8.69,8.69,0,0,0-.59-9,9.61,9.61,0,0,0-10.09-3.8c-.31.06-.18.54.13.48a9.17,9.17,0,0,1,9,2.91,8.34,8.34,0,0,1,1.5,8.3,13,13,0,0,1-3,4.22,8.46,8.46,0,0,1-4.16,2.51,8.58,8.58,0,0,1-8.09-2.69,11,11,0,0,1-2.17-9.83c.07-.31-.41-.44-.48-.13Z"/>
                <path class="cls-4" d="M128.42,554.28c-1,.87-2.1,1.87-2.25,3.19l0,.06a11.17,11.17,0,0,0,2.51,10.37c2.59,2.66,7,3.53,10.19,1.62a11.43,11.43,0,0,0,2.25-1.85,12,12,0,0,0,3.19-5.07,8.92,8.92,0,0,0-4.71-10A10.46,10.46,0,0,0,128.42,554.28Zm4.88.47a2.48,2.48,0,0,1,1.58,2.72,2.28,2.28,0,0,1-2.54,1.76c-1.12-.21-2.12-1.29-1.76-2.37v-.07c.11-.64.14-1.42.71-1.82A2.22,2.22,0,0,1,133.3,554.75Z"/>
                <path class="cls-4" d="M116,553.43a5.18,5.18,0,0,0-2.67-1.76c-.31-.07-.45.41-.13.48a4.68,4.68,0,0,1,2.44,1.63.25.25,0,1,0,.36-.35Z"/>
                <path class="cls-4" d="M113.4,551.44a11.64,11.64,0,0,0-10.09.95,9.18,9.18,0,0,0-4.14,7.76c.08,3.46,2.75,6.68,5.56,8.47a8.69,8.69,0,0,0,8.72.29,9.59,9.59,0,0,0,5-9.66c0-.31-.53-.32-.5,0a9.21,9.21,0,0,1-3.64,8.59,8.39,8.39,0,0,1-8.42.85,12.13,12.13,0,0,1-3.71-3.05,9.23,9.23,0,0,1-2.38-4.44,8.59,8.59,0,0,1,3.16-7.92,11,11,0,0,1,10.35-1.36c.3.11.43-.37.13-.48Z"/>
                <path class="cls-4" d="M121.22,552.2c-.76-1.09-1.63-2.29-2.93-2.57l-.06,0A11.19,11.19,0,0,0,107.66,551c-2.92,2.3-4.25,6.6-2.69,10a11.27,11.27,0,0,0,1.61,2.43,12.12,12.12,0,0,0,4.7,3.71,8.93,8.93,0,0,0,10.41-3.64A10.46,10.46,0,0,0,121.22,552.2Zm-1,4.81a2.51,2.51,0,0,1-2.88,1.28,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.55-1.51l.06,0c.62.17,1.4.29,1.74.9A2.24,2.24,0,0,1,120.25,557Z"/>
                <path class="cls-4" d="M168.1,581.12a5.11,5.11,0,0,0-3.11-.74.25.25,0,0,0-.25.24c0,.13.12.27.25.25a4.74,4.74,0,0,1,2.86.68.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M165,580.13a11.79,11.79,0,0,0-8.81,3.9,9.22,9.22,0,0,0-1.72,8.77c1.11,3.34,4.55,5.45,7.81,6.33a8.69,8.69,0,0,0,8.7-2.56,9.63,9.63,0,0,0,1.37-10.87c-.14-.29-.56,0-.43.25a9.15,9.15,0,0,1-.65,9.5,8.29,8.29,0,0,1-8,3.38c-3.08-.55-6.71-2.54-8-5.49a8.66,8.66,0,0,1,.88-8.35,11.09,11.09,0,0,1,8.92-4.37.25.25,0,0,0,0-.49Z"/>
                <path class="cls-4" d="M172.58,578.15c-1.09-.76-2.32-1.58-3.63-1.41h-.07a11.2,11.2,0,0,0-9.45,5,8.58,8.58,0,0,0,.9,10.28,11.4,11.4,0,0,0,2.35,1.73,12,12,0,0,0,5.69,1.87,8.94,8.94,0,0,0,8.53-7A10.49,10.49,0,0,0,172.58,578.15Zm.74,4.85a2.49,2.49,0,0,1-2.26,2.19,2.28,2.28,0,0,1-2.33-2c-.06-1.14.74-2.37,1.88-2.29h.06a2.57,2.57,0,0,1,2,.24A2.23,2.23,0,0,1,173.32,583Z"/>
                <path class="cls-4" d="M209.89,584a5.16,5.16,0,0,0-1.48,2.9.25.25,0,0,0,.24.25.25.25,0,0,0,.25-.25,4.7,4.7,0,0,1,1.34-2.55c.22-.23-.13-.58-.35-.35Z"/>
                <path class="cls-4" d="M208.17,586.72a11.75,11.75,0,0,0,1.67,9.52A9.17,9.17,0,0,0,218.1,600c3.48-.35,6.37-3.24,8-6.21a8.71,8.71,0,0,0-.59-9,9.61,9.61,0,0,0-10.09-3.8c-.32.06-.18.54.13.48a9.13,9.13,0,0,1,9,2.91,8.32,8.32,0,0,1,1.5,8.3,12.78,12.78,0,0,1-2.95,4.22,8.5,8.5,0,0,1-4.16,2.51,8.65,8.65,0,0,1-8.1-2.69,11.09,11.09,0,0,1-2.17-9.83c.07-.31-.4-.45-.48-.13Z"/>
                <path class="cls-4" d="M208.1,578.88c-1,.87-2.1,1.87-2.25,3.18l0,.07a11.21,11.21,0,0,0,2.51,10.37,8.59,8.59,0,0,0,10.2,1.62,11.14,11.14,0,0,0,2.25-1.86A12,12,0,0,0,224,587.2a8.92,8.92,0,0,0-4.71-10A10.45,10.45,0,0,0,208.1,578.88Zm4.88.47a2.5,2.5,0,0,1,1.58,2.72,2.29,2.29,0,0,1-2.54,1.76,2,2,0,0,1-1.77-2.38l0-.06c.1-.64.14-1.43.71-1.83A2.21,2.21,0,0,1,213,579.35Z"/>
                <path class="cls-4" d="M195.65,578a5.16,5.16,0,0,0-2.66-1.76c-.32-.07-.45.41-.14.48a4.7,4.7,0,0,1,2.45,1.63.25.25,0,0,0,.35-.35Z"/>
                <path class="cls-4" d="M193.08,576A11.64,11.64,0,0,0,183,577a9.16,9.16,0,0,0-4.15,7.76c.08,3.46,2.76,6.68,5.57,8.47a8.69,8.69,0,0,0,8.72.29,9.58,9.58,0,0,0,5-9.66c0-.32-.52-.32-.49,0a9.17,9.17,0,0,1-3.65,8.58,8.38,8.38,0,0,1-8.41.86,12.44,12.44,0,0,1-3.72-3,9.34,9.34,0,0,1-2.37-4.44,8.56,8.56,0,0,1,3.16-7.93,11,11,0,0,1,10.34-1.35c.31.1.44-.38.14-.48Z"/>
                <path class="cls-4" d="M200.9,576.8c-.76-1.09-1.63-2.29-2.93-2.58l-.06,0a11.21,11.21,0,0,0-10.58,1.41,8.59,8.59,0,0,0-2.68,10,11.52,11.52,0,0,0,1.61,2.43,12.12,12.12,0,0,0,4.7,3.71,8.93,8.93,0,0,0,10.41-3.64A10.46,10.46,0,0,0,200.9,576.8Zm-1,4.8a2.49,2.49,0,0,1-2.87,1.29,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.54-1.51l.06,0c.63.17,1.41.29,1.75.9A2.22,2.22,0,0,1,199.92,581.6Z"/>
                <path class="cls-4" d="M242.43,581.12a5.11,5.11,0,0,0-3.11-.74.25.25,0,0,0-.25.24.24.24,0,0,0,.25.25,4.69,4.69,0,0,1,2.85.68c.28.15.54-.27.26-.43Z"/>
                <path class="cls-4" d="M239.32,580.13a11.79,11.79,0,0,0-8.81,3.9,9.25,9.25,0,0,0-1.73,8.77c1.12,3.34,4.56,5.45,7.82,6.33a8.69,8.69,0,0,0,8.7-2.56,9.63,9.63,0,0,0,1.37-10.87c-.14-.29-.56,0-.43.25a9.15,9.15,0,0,1-.65,9.5,8.3,8.3,0,0,1-8,3.38c-3.08-.55-6.71-2.54-8-5.49a8.66,8.66,0,0,1,.88-8.35,11.09,11.09,0,0,1,8.92-4.37.25.25,0,0,0,0-.49Z"/>
                <path class="cls-4" d="M246.91,578.15c-1.09-.76-2.32-1.58-3.63-1.41h-.07a11.19,11.19,0,0,0-9.45,5,8.58,8.58,0,0,0,.9,10.28A11.4,11.4,0,0,0,237,593.7a12,12,0,0,0,5.69,1.87,8.92,8.92,0,0,0,8.52-7A10.47,10.47,0,0,0,246.91,578.15Zm.74,4.85a2.49,2.49,0,0,1-2.26,2.19,2.28,2.28,0,0,1-2.33-2c-.06-1.14.74-2.37,1.88-2.29H245a2.57,2.57,0,0,1,1.95.24A2.26,2.26,0,0,1,247.65,583Z"/>
                <path class="cls-4" d="M284.22,584a5.24,5.24,0,0,0-1.49,2.9.26.26,0,0,0,.25.25.25.25,0,0,0,.25-.25,4.7,4.7,0,0,1,1.34-2.55c.22-.23-.13-.58-.35-.35Z"/>
                <path class="cls-4" d="M282.5,586.72a11.75,11.75,0,0,0,1.67,9.52,9.16,9.16,0,0,0,8.26,3.76c3.48-.35,6.37-3.24,7.94-6.21a8.69,8.69,0,0,0-.58-9A9.61,9.61,0,0,0,289.7,581c-.32.06-.18.54.13.48a9.13,9.13,0,0,1,9,2.91,8.32,8.32,0,0,1,1.5,8.3,12.93,12.93,0,0,1-2.95,4.22,8.5,8.5,0,0,1-4.16,2.51,8.65,8.65,0,0,1-8.1-2.69,11.09,11.09,0,0,1-2.17-9.83c.07-.31-.41-.45-.48-.13Z"/>
                <path class="cls-4" d="M282.43,578.88c-1,.87-2.11,1.87-2.25,3.18l0,.07a11.21,11.21,0,0,0,2.51,10.37,8.59,8.59,0,0,0,10.2,1.62,11.14,11.14,0,0,0,2.25-1.86,12,12,0,0,0,3.19-5.06,8.92,8.92,0,0,0-4.71-10A10.45,10.45,0,0,0,282.43,578.88Zm4.88.47a2.5,2.5,0,0,1,1.58,2.72,2.29,2.29,0,0,1-2.54,1.76,2,2,0,0,1-1.77-2.38l0-.06c.1-.64.14-1.43.71-1.83A2.21,2.21,0,0,1,287.31,579.35Z"/>
                <path class="cls-4" d="M270,578a5.16,5.16,0,0,0-2.66-1.76c-.32-.07-.45.41-.14.48a4.7,4.7,0,0,1,2.45,1.63A.25.25,0,0,0,270,578Z"/>
                <path class="cls-4" d="M267.41,576a11.64,11.64,0,0,0-10.09,1,9.16,9.16,0,0,0-4.15,7.76c.08,3.46,2.75,6.68,5.57,8.47a8.69,8.69,0,0,0,8.72.29,9.6,9.6,0,0,0,5-9.66c0-.32-.52-.32-.49,0a9.19,9.19,0,0,1-3.65,8.58,8.38,8.38,0,0,1-8.41.86,12.44,12.44,0,0,1-3.72-3,9.34,9.34,0,0,1-2.37-4.44,8.56,8.56,0,0,1,3.16-7.93,11,11,0,0,1,10.34-1.35c.31.1.44-.38.14-.48Z"/>
                <path class="cls-4" d="M275.23,576.8c-.76-1.09-1.63-2.29-2.93-2.58l-.06,0a11.21,11.21,0,0,0-10.58,1.41,8.59,8.59,0,0,0-2.68,10,11.52,11.52,0,0,0,1.61,2.43,12.05,12.05,0,0,0,4.7,3.71,8.93,8.93,0,0,0,10.41-3.64A10.46,10.46,0,0,0,275.23,576.8Zm-1,4.8a2.49,2.49,0,0,1-2.87,1.29,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.54-1.51l.06,0c.63.17,1.41.29,1.75.9A2.22,2.22,0,0,1,274.25,581.6Z"/>
                <path class="cls-4" d="M20,581.12a5.06,5.06,0,0,0-3.1-.74.25.25,0,0,0-.25.24.24.24,0,0,0,.25.25,4.69,4.69,0,0,1,2.85.68.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M16.87,580.13A11.79,11.79,0,0,0,8.06,584a9.23,9.23,0,0,0-1.73,8.77c1.12,3.34,4.55,5.45,7.82,6.33a8.7,8.7,0,0,0,8.7-2.56,9.63,9.63,0,0,0,1.37-10.87c-.14-.29-.57,0-.43.25a9.18,9.18,0,0,1-.65,9.5,8.3,8.3,0,0,1-8,3.38c-3.09-.55-6.71-2.54-8-5.49A8.68,8.68,0,0,1,7.94,585a11.11,11.11,0,0,1,8.93-4.37.25.25,0,0,0,0-.49Z"/>
                <path class="cls-4" d="M24.46,578.15c-1.09-.76-2.32-1.58-3.64-1.41h-.06a11.19,11.19,0,0,0-9.45,5,8.58,8.58,0,0,0,.9,10.28,11.34,11.34,0,0,0,2.34,1.73,12.11,12.11,0,0,0,5.69,1.87,8.92,8.92,0,0,0,8.53-7A10.47,10.47,0,0,0,24.46,578.15Zm.73,4.85a2.47,2.47,0,0,1-2.25,2.19,2.27,2.27,0,0,1-2.33-2c-.07-1.14.74-2.37,1.88-2.29h.06a2.57,2.57,0,0,1,1.95.24A2.25,2.25,0,0,1,25.19,583Z"/>
                <path class="cls-4" d="M61.77,584a5.24,5.24,0,0,0-1.49,2.9.25.25,0,0,0,.5,0,4.7,4.7,0,0,1,1.34-2.55c.22-.23-.13-.58-.35-.35Z"/>
                <path class="cls-4" d="M60.05,586.72a11.75,11.75,0,0,0,1.67,9.52A9.16,9.16,0,0,0,70,600c3.47-.35,6.37-3.24,7.94-6.21a8.71,8.71,0,0,0-.58-9,9.63,9.63,0,0,0-10.1-3.8c-.31.06-.18.54.14.48a9.13,9.13,0,0,1,9,2.91,8.34,8.34,0,0,1,1.5,8.3,13,13,0,0,1-3,4.22,8.43,8.43,0,0,1-4.15,2.51,8.64,8.64,0,0,1-8.1-2.69,11.06,11.06,0,0,1-2.17-9.83c.07-.31-.41-.45-.48-.13Z"/>
                <path class="cls-4" d="M60,578.88c-1,.87-2.1,1.87-2.25,3.18l0,.07a11.21,11.21,0,0,0,2.51,10.37,8.59,8.59,0,0,0,10.2,1.62,11.36,11.36,0,0,0,2.24-1.86,12.11,12.11,0,0,0,3.2-5.06,8.93,8.93,0,0,0-4.71-10A10.47,10.47,0,0,0,60,578.88Zm4.89.47a2.48,2.48,0,0,1,1.57,2.72,2.27,2.27,0,0,1-2.53,1.76,2,2,0,0,1-1.77-2.38l0-.06c.1-.64.14-1.43.71-1.83A2.21,2.21,0,0,1,64.86,579.35Z"/>
                <path class="cls-4" d="M47.53,578a5.14,5.14,0,0,0-2.67-1.76c-.31-.07-.44.41-.13.48a4.7,4.7,0,0,1,2.45,1.63.25.25,0,0,0,.35-.35Z"/>
                <path class="cls-4" d="M45,576a11.64,11.64,0,0,0-10.09,1,9.18,9.18,0,0,0-4.14,7.76c.08,3.46,2.75,6.68,5.56,8.47a8.69,8.69,0,0,0,8.72.29,9.6,9.6,0,0,0,5-9.66c0-.32-.53-.32-.5,0a9.18,9.18,0,0,1-3.64,8.58,8.38,8.38,0,0,1-8.41.86,12.19,12.19,0,0,1-3.72-3,9.17,9.17,0,0,1-2.37-4.44,8.56,8.56,0,0,1,3.16-7.93,11,11,0,0,1,10.34-1.35c.3.1.43-.38.13-.48Z"/>
                <path class="cls-4" d="M52.78,576.8c-.76-1.09-1.64-2.29-2.93-2.58l-.06,0a11.21,11.21,0,0,0-10.58,1.41,8.58,8.58,0,0,0-2.68,10,11.1,11.1,0,0,0,1.6,2.43,12.15,12.15,0,0,0,4.71,3.71,8.93,8.93,0,0,0,10.41-3.64A10.48,10.48,0,0,0,52.78,576.8Zm-1,4.8a2.49,2.49,0,0,1-2.87,1.29,2.3,2.3,0,0,1-1.49-2.71A2,2,0,0,1,50,578.67l.06,0c.63.17,1.41.29,1.74.9A2.19,2.19,0,0,1,51.8,581.6Z"/>
                <path class="cls-4" d="M94.3,581.12a5.06,5.06,0,0,0-3.1-.74.25.25,0,0,0-.25.24.24.24,0,0,0,.25.25,4.71,4.71,0,0,1,2.85.68.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M91.2,580.13a11.78,11.78,0,0,0-8.81,3.9,9.23,9.23,0,0,0-1.73,8.77c1.12,3.34,4.55,5.45,7.82,6.33a8.7,8.7,0,0,0,8.7-2.56,9.63,9.63,0,0,0,1.37-10.87c-.14-.29-.57,0-.43.25a9.18,9.18,0,0,1-.65,9.5,8.31,8.31,0,0,1-8,3.38c-3.08-.55-6.7-2.54-8-5.49a8.68,8.68,0,0,1,.87-8.35,11.1,11.1,0,0,1,8.93-4.37.25.25,0,0,0,0-.49Z"/>
                <path class="cls-4" d="M98.79,578.15c-1.09-.76-2.32-1.58-3.64-1.41h-.07a11.18,11.18,0,0,0-9.44,5,8.57,8.57,0,0,0,.9,10.28,11.34,11.34,0,0,0,2.34,1.73,12.11,12.11,0,0,0,5.69,1.87,8.92,8.92,0,0,0,8.53-7A10.47,10.47,0,0,0,98.79,578.15Zm.73,4.85a2.47,2.47,0,0,1-2.26,2.19,2.27,2.27,0,0,1-2.32-2c-.07-1.14.74-2.37,1.88-2.29h.06a2.57,2.57,0,0,1,2,.24A2.25,2.25,0,0,1,99.52,583Z"/>
                <path class="cls-4" d="M136.1,584a5.18,5.18,0,0,0-1.49,2.9c0,.13.13.25.25.25a.26.26,0,0,0,.25-.25,4.7,4.7,0,0,1,1.34-2.55c.22-.23-.13-.58-.35-.35Z"/>
                <path class="cls-4" d="M134.38,586.72a11.78,11.78,0,0,0,1.66,9.52,9.19,9.19,0,0,0,8.27,3.76c3.47-.35,6.37-3.24,7.94-6.21a8.71,8.71,0,0,0-.58-9,9.63,9.63,0,0,0-10.1-3.8c-.31.06-.18.54.14.48a9.13,9.13,0,0,1,9,2.91,8.34,8.34,0,0,1,1.5,8.3,13,13,0,0,1-3,4.22,8.43,8.43,0,0,1-4.15,2.51,8.64,8.64,0,0,1-8.1-2.69,11,11,0,0,1-2.17-9.83c.07-.31-.41-.45-.48-.13Z"/>
                <path class="cls-4" d="M134.3,578.88c-1,.87-2.1,1.87-2.25,3.18l0,.07a11.21,11.21,0,0,0,2.51,10.37,8.59,8.59,0,0,0,10.2,1.62,11.36,11.36,0,0,0,2.24-1.86,12.11,12.11,0,0,0,3.2-5.06,8.94,8.94,0,0,0-4.71-10A10.47,10.47,0,0,0,134.3,578.88Zm4.89.47a2.48,2.48,0,0,1,1.57,2.72,2.28,2.28,0,0,1-2.54,1.76,2,2,0,0,1-1.76-2.38v-.06c.11-.64.14-1.43.72-1.83A2.21,2.21,0,0,1,139.19,579.35Z"/>
                <path class="cls-4" d="M121.86,578a5.18,5.18,0,0,0-2.67-1.76c-.31-.07-.44.41-.13.48a4.74,4.74,0,0,1,2.45,1.63.25.25,0,0,0,.35-.35Z"/>
                <path class="cls-4" d="M119.28,576a11.64,11.64,0,0,0-10.09,1,9.18,9.18,0,0,0-4.14,7.76c.08,3.46,2.75,6.68,5.56,8.47a8.69,8.69,0,0,0,8.72.29,9.6,9.6,0,0,0,5-9.66c0-.32-.53-.32-.5,0a9.18,9.18,0,0,1-3.64,8.58,8.38,8.38,0,0,1-8.41.86,12.19,12.19,0,0,1-3.72-3,9.27,9.27,0,0,1-2.38-4.44,8.61,8.61,0,0,1,3.16-7.93,11,11,0,0,1,10.35-1.35c.3.1.43-.38.13-.48Z"/>
                <path class="cls-4" d="M127.1,576.8c-.75-1.09-1.63-2.29-2.92-2.58l-.07,0a11.19,11.19,0,0,0-10.57,1.41,8.59,8.59,0,0,0-2.69,10,11.52,11.52,0,0,0,1.61,2.43,12.2,12.2,0,0,0,4.7,3.71,8.93,8.93,0,0,0,10.41-3.64A10.44,10.44,0,0,0,127.1,576.8Zm-1,4.8a2.49,2.49,0,0,1-2.87,1.29,2.29,2.29,0,0,1-1.49-2.71,2,2,0,0,1,2.55-1.51l.06,0c.63.17,1.41.29,1.74.9A2.22,2.22,0,0,1,126.13,581.6Z"/>
                <path class="cls-4" d="M461.19,556.52a5.16,5.16,0,0,0-3.11-.74.25.25,0,0,0,0,.5,4.64,4.64,0,0,1,2.86.67.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M458.09,555.53a11.78,11.78,0,0,0-8.81,3.9,9.2,9.2,0,0,0-1.73,8.77c1.12,3.34,4.55,5.45,7.82,6.33a8.7,8.7,0,0,0,8.7-2.56,9.62,9.62,0,0,0,1.37-10.87c-.14-.28-.57,0-.43.26a9.16,9.16,0,0,1-.65,9.49,8.33,8.33,0,0,1-8.05,3.39c-3.08-.55-6.7-2.54-8-5.5a8.68,8.68,0,0,1,.87-8.35,11.09,11.09,0,0,1,8.93-4.36c.32,0,.32-.51,0-.5Z"/>
                <path class="cls-4" d="M465.68,553.55c-1.09-.76-2.32-1.58-3.64-1.41H462a11.18,11.18,0,0,0-9.44,5,8.57,8.57,0,0,0,.9,10.28,11.34,11.34,0,0,0,2.34,1.73,12.08,12.08,0,0,0,5.69,1.87,8.92,8.92,0,0,0,8.53-7A10.48,10.48,0,0,0,465.68,553.55Zm.73,4.85a2.49,2.49,0,0,1-2.26,2.19,2.27,2.27,0,0,1-2.32-2c-.07-1.14.74-2.37,1.87-2.29h.07c.64-.06,1.42-.21,1.94.25A2.2,2.2,0,0,1,466.41,558.4Z"/>
                <path class="cls-4" d="M503,559.35a5.16,5.16,0,0,0-1.48,2.9c0,.13.13.25.25.25a.26.26,0,0,0,.25-.25,4.57,4.57,0,0,1,1.34-2.55c.21-.23-.14-.58-.36-.35Z"/>
                <path class="cls-4" d="M501.27,562.12a11.77,11.77,0,0,0,1.66,9.52,9.19,9.19,0,0,0,8.27,3.76c3.47-.35,6.37-3.24,7.94-6.21a8.71,8.71,0,0,0-.58-9,9.63,9.63,0,0,0-10.1-3.8c-.31.06-.18.54.13.48a9.16,9.16,0,0,1,9,2.91,8.34,8.34,0,0,1,1.5,8.3,13,13,0,0,1-3,4.22,8.42,8.42,0,0,1-4.16,2.51,8.58,8.58,0,0,1-8.09-2.69,11,11,0,0,1-2.17-9.83c.07-.31-.41-.44-.48-.13Z"/>
                <path class="cls-4" d="M501.19,554.28c-1,.87-2.1,1.87-2.25,3.19l0,.06a11.2,11.2,0,0,0,2.51,10.37,8.58,8.58,0,0,0,10.2,1.62,11.67,11.67,0,0,0,2.24-1.85,12.14,12.14,0,0,0,3.2-5.07,9,9,0,0,0-4.71-10A10.47,10.47,0,0,0,501.19,554.28Zm4.89.47a2.48,2.48,0,0,1,1.57,2.72,2.28,2.28,0,0,1-2.54,1.76,2,2,0,0,1-1.76-2.37v-.07c.11-.64.14-1.42.72-1.82A2.21,2.21,0,0,1,506.08,554.75Z"/>
                <path class="cls-4" d="M488.75,553.43a5.18,5.18,0,0,0-2.67-1.76c-.31-.07-.44.41-.13.48a4.74,4.74,0,0,1,2.45,1.63.25.25,0,0,0,.35,0,.26.26,0,0,0,0-.35Z"/>
                <path class="cls-4" d="M486.17,551.44a11.64,11.64,0,0,0-10.09.95,9.18,9.18,0,0,0-4.14,7.76c.08,3.46,2.75,6.68,5.56,8.47a8.69,8.69,0,0,0,8.72.29,9.59,9.59,0,0,0,5-9.66c0-.31-.53-.32-.5,0a9.21,9.21,0,0,1-3.64,8.59,8.39,8.39,0,0,1-8.42.85,12.13,12.13,0,0,1-3.71-3.05,9.23,9.23,0,0,1-2.38-4.44,8.59,8.59,0,0,1,3.16-7.92A11,11,0,0,1,486,551.92c.3.11.43-.37.13-.48Z"/>
                <path class="cls-4" d="M494,552.2c-.76-1.09-1.63-2.29-2.92-2.57l-.07,0A11.19,11.19,0,0,0,480.43,551c-2.92,2.3-4.25,6.6-2.69,10a11.27,11.27,0,0,0,1.61,2.43,12.2,12.2,0,0,0,4.7,3.71,8.93,8.93,0,0,0,10.41-3.64A10.44,10.44,0,0,0,494,552.2ZM493,557a2.5,2.5,0,0,1-2.87,1.28,2.28,2.28,0,0,1-1.49-2.71,2,2,0,0,1,2.55-1.51l.06,0c.63.17,1.41.29,1.74.9A2.24,2.24,0,0,1,493,557Z"/>
                <path class="cls-4" d="M535.52,556.52a5.16,5.16,0,0,0-3.11-.74.25.25,0,0,0,0,.5,4.64,4.64,0,0,1,2.86.67.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M532.42,555.53a11.78,11.78,0,0,0-8.81,3.9,9.2,9.2,0,0,0-1.73,8.77c1.12,3.34,4.55,5.45,7.82,6.33a8.7,8.7,0,0,0,8.7-2.56,9.62,9.62,0,0,0,1.37-10.87c-.14-.28-.57,0-.43.26a9.18,9.18,0,0,1-.65,9.49,8.33,8.33,0,0,1-8.05,3.39c-3.08-.55-6.7-2.54-8-5.5a8.66,8.66,0,0,1,.87-8.35,11.09,11.09,0,0,1,8.93-4.36c.32,0,.32-.51,0-.5Z"/>
                <path class="cls-4" d="M540,553.55c-1.08-.76-2.31-1.58-3.63-1.41h-.07a11.21,11.21,0,0,0-9.45,5,8.58,8.58,0,0,0,.91,10.28,11.08,11.08,0,0,0,2.34,1.73,12.08,12.08,0,0,0,5.69,1.87,8.93,8.93,0,0,0,8.53-7A10.47,10.47,0,0,0,540,553.55Zm.74,4.85a2.49,2.49,0,0,1-2.26,2.19,2.27,2.27,0,0,1-2.32-2c-.07-1.14.73-2.37,1.87-2.29h.07c.64-.06,1.42-.21,1.94.25A2.2,2.2,0,0,1,540.74,558.4Z"/>
                <path class="cls-4" d="M577.31,559.35a5.16,5.16,0,0,0-1.48,2.9c0,.13.12.25.25.25a.26.26,0,0,0,.25-.25,4.57,4.57,0,0,1,1.34-2.55c.21-.23-.14-.58-.36-.35Z"/>
                <path class="cls-4" d="M575.6,562.12a11.77,11.77,0,0,0,1.66,9.52,9.19,9.19,0,0,0,8.27,3.76c3.47-.35,6.37-3.24,7.94-6.21a8.69,8.69,0,0,0-.59-9,9.61,9.61,0,0,0-10.09-3.8c-.31.06-.18.54.13.48a9.17,9.17,0,0,1,9,2.91,8.34,8.34,0,0,1,1.5,8.3,13,13,0,0,1-3,4.22,8.46,8.46,0,0,1-4.16,2.51,8.58,8.58,0,0,1-8.09-2.69,11,11,0,0,1-2.17-9.83c.07-.31-.41-.44-.48-.13Z"/>
                <path class="cls-4" d="M575.52,554.28c-1,.87-2.1,1.87-2.25,3.19l0,.06a11.2,11.2,0,0,0,2.51,10.37,8.58,8.58,0,0,0,10.2,1.62,11.67,11.67,0,0,0,2.24-1.85,12.14,12.14,0,0,0,3.2-5.07,8.94,8.94,0,0,0-4.72-10A10.46,10.46,0,0,0,575.52,554.28Zm4.88.47a2.48,2.48,0,0,1,1.58,2.72,2.28,2.28,0,0,1-2.54,1.76c-1.12-.21-2.12-1.29-1.76-2.37v-.07c.11-.64.14-1.42.71-1.82A2.22,2.22,0,0,1,580.4,554.75Z"/>
                <path class="cls-4" d="M563.08,553.43a5.18,5.18,0,0,0-2.67-1.76c-.31-.07-.44.41-.13.48a4.68,4.68,0,0,1,2.44,1.63.25.25,0,1,0,.36-.35Z"/>
                <path class="cls-4" d="M560.5,551.44a11.64,11.64,0,0,0-10.09.95,9.18,9.18,0,0,0-4.14,7.76c.08,3.46,2.75,6.68,5.56,8.47a8.69,8.69,0,0,0,8.72.29,9.59,9.59,0,0,0,5-9.66c0-.31-.53-.32-.5,0a9.21,9.21,0,0,1-3.64,8.59,8.39,8.39,0,0,1-8.42.85,12.13,12.13,0,0,1-3.71-3.05,9.23,9.23,0,0,1-2.38-4.44,8.59,8.59,0,0,1,3.16-7.92,11,11,0,0,1,10.35-1.36c.3.11.43-.37.13-.48Z"/>
                <path class="cls-4" d="M568.32,552.2c-.76-1.09-1.63-2.29-2.92-2.57l-.07,0A11.19,11.19,0,0,0,554.76,551c-2.92,2.3-4.25,6.6-2.69,10a11.27,11.27,0,0,0,1.61,2.43,12.12,12.12,0,0,0,4.7,3.71,8.93,8.93,0,0,0,10.41-3.64A10.44,10.44,0,0,0,568.32,552.2Zm-1,4.81a2.51,2.51,0,0,1-2.88,1.28,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.55-1.51l.06,0c.62.17,1.4.29,1.74.9A2.24,2.24,0,0,1,567.35,557Z"/>
                <path class="cls-4" d="M313.07,556.52a5.16,5.16,0,0,0-3.11-.74.25.25,0,0,0,0,.5,4.66,4.66,0,0,1,2.86.67.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M310,555.53a11.78,11.78,0,0,0-8.81,3.9,9.2,9.2,0,0,0-1.73,8.77c1.11,3.34,4.55,5.45,7.81,6.33a8.69,8.69,0,0,0,8.7-2.56,9.61,9.61,0,0,0,1.37-10.87c-.13-.28-.56,0-.43.26a9.14,9.14,0,0,1-.65,9.49,8.32,8.32,0,0,1-8,3.39c-3.08-.55-6.71-2.54-8-5.5a8.66,8.66,0,0,1,.87-8.35A11.09,11.09,0,0,1,310,556c.31,0,.32-.51,0-.5Z"/>
                <path class="cls-4" d="M317.55,553.55c-1.08-.76-2.32-1.58-3.63-1.41h-.07a11.19,11.19,0,0,0-9.45,5,8.58,8.58,0,0,0,.9,10.28,11.4,11.4,0,0,0,2.35,1.73,12,12,0,0,0,5.69,1.87,8.94,8.94,0,0,0,8.53-7A10.47,10.47,0,0,0,317.55,553.55Zm.74,4.85a2.49,2.49,0,0,1-2.26,2.19,2.26,2.26,0,0,1-2.32-2c-.07-1.14.73-2.37,1.87-2.29h.06a2.52,2.52,0,0,1,1.95.25A2.2,2.2,0,0,1,318.29,558.4Z"/>
                <path class="cls-4" d="M354.86,559.35a5.16,5.16,0,0,0-1.48,2.9.25.25,0,0,0,.24.25.25.25,0,0,0,.25-.25,4.7,4.7,0,0,1,1.34-2.55c.22-.23-.13-.58-.35-.35Z"/>
                <path class="cls-4" d="M353.14,562.12a11.78,11.78,0,0,0,1.67,9.52,9.18,9.18,0,0,0,8.26,3.76c3.48-.35,6.37-3.24,7.95-6.21a8.71,8.71,0,0,0-.59-9,9.61,9.61,0,0,0-10.09-3.8c-.31.06-.18.54.13.48a9.17,9.17,0,0,1,9,2.91A8.34,8.34,0,0,1,371,568a13,13,0,0,1-3,4.22,8.46,8.46,0,0,1-4.16,2.51,8.61,8.61,0,0,1-8.1-2.69,11.09,11.09,0,0,1-2.17-9.83.25.25,0,0,0-.48-.13Z"/>
                <path class="cls-4" d="M353.07,554.28c-1,.87-2.1,1.87-2.25,3.19l0,.06a11.2,11.2,0,0,0,2.51,10.37c2.59,2.66,7,3.53,10.2,1.62a11.43,11.43,0,0,0,2.25-1.85,12,12,0,0,0,3.19-5.07,8.92,8.92,0,0,0-4.71-10A10.45,10.45,0,0,0,353.07,554.28Zm4.88.47a2.49,2.49,0,0,1,1.58,2.72,2.28,2.28,0,0,1-2.54,1.76c-1.12-.21-2.12-1.29-1.76-2.37v-.07c.1-.64.14-1.42.71-1.82A2.21,2.21,0,0,1,358,554.75Z"/>
                <path class="cls-4" d="M340.62,553.43a5.12,5.12,0,0,0-2.66-1.76c-.31-.07-.45.41-.14.48a4.66,4.66,0,0,1,2.45,1.63.25.25,0,1,0,.35-.35Z"/>
                <path class="cls-4" d="M338.05,551.44a11.64,11.64,0,0,0-10.09.95,9.21,9.21,0,0,0-4.15,7.76c.08,3.46,2.76,6.68,5.57,8.47a8.69,8.69,0,0,0,8.72.29,9.59,9.59,0,0,0,5-9.66c0-.31-.53-.32-.5,0a9.19,9.19,0,0,1-3.65,8.59,8.38,8.38,0,0,1-8.41.85,12.13,12.13,0,0,1-3.71-3.05,9.23,9.23,0,0,1-2.38-4.44,8.57,8.57,0,0,1,3.16-7.92,11,11,0,0,1,10.35-1.36c.3.11.43-.37.13-.48Z"/>
                <path class="cls-4" d="M345.87,552.2c-.76-1.09-1.63-2.29-2.93-2.57l-.06,0A11.19,11.19,0,0,0,332.31,551c-2.92,2.3-4.25,6.6-2.69,10a11.27,11.27,0,0,0,1.61,2.43,12.12,12.12,0,0,0,4.7,3.71,8.93,8.93,0,0,0,10.41-3.64A10.46,10.46,0,0,0,345.87,552.2Zm-1,4.81a2.5,2.5,0,0,1-2.87,1.28,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.55-1.51l.05,0c.63.17,1.41.29,1.75.9A2.21,2.21,0,0,1,344.89,557Z"/>
                <path class="cls-4" d="M387.4,556.52a5.18,5.18,0,0,0-3.11-.74.25.25,0,1,0,0,.5,4.66,4.66,0,0,1,2.86.67.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M384.29,555.53a11.81,11.81,0,0,0-8.81,3.9,9.22,9.22,0,0,0-1.72,8.77c1.11,3.34,4.55,5.45,7.81,6.33a8.69,8.69,0,0,0,8.7-2.56,9.62,9.62,0,0,0,1.37-10.87c-.13-.28-.56,0-.43.26a9.14,9.14,0,0,1-.65,9.49,8.32,8.32,0,0,1-8,3.39c-3.08-.55-6.71-2.54-8-5.5a8.66,8.66,0,0,1,.88-8.35,11.08,11.08,0,0,1,8.92-4.36c.32,0,.32-.51,0-.5Z"/>
                <path class="cls-4" d="M391.88,553.55c-1.09-.76-2.32-1.58-3.63-1.41h-.07a11.19,11.19,0,0,0-9.45,5,8.58,8.58,0,0,0,.9,10.28A11.4,11.4,0,0,0,382,569.1a12,12,0,0,0,5.69,1.87,8.94,8.94,0,0,0,8.53-7A10.49,10.49,0,0,0,391.88,553.55Zm.74,4.85a2.49,2.49,0,0,1-2.26,2.19,2.28,2.28,0,0,1-2.33-2c-.06-1.14.74-2.37,1.88-2.29H390a2.52,2.52,0,0,1,1.95.25A2.2,2.2,0,0,1,392.62,558.4Z"/>
                <path class="cls-4" d="M429.19,559.35a5.16,5.16,0,0,0-1.48,2.9.25.25,0,0,0,.24.25.25.25,0,0,0,.25-.25,4.7,4.7,0,0,1,1.34-2.55c.22-.23-.13-.58-.35-.35Z"/>
                <path class="cls-4" d="M427.47,562.12a11.74,11.74,0,0,0,1.67,9.52,9.17,9.17,0,0,0,8.26,3.76c3.48-.35,6.37-3.24,8-6.21a8.71,8.71,0,0,0-.59-9,9.61,9.61,0,0,0-10.09-3.8c-.32.06-.18.54.13.48a9.15,9.15,0,0,1,9,2.91,8.32,8.32,0,0,1,1.5,8.3,12.78,12.78,0,0,1-2.95,4.22,8.5,8.5,0,0,1-4.16,2.51,8.61,8.61,0,0,1-8.1-2.69,11.09,11.09,0,0,1-2.17-9.83.25.25,0,0,0-.48-.13Z"/>
                <path class="cls-4" d="M427.4,554.28c-1,.87-2.1,1.87-2.25,3.19l0,.06a11.2,11.2,0,0,0,2.51,10.37,8.58,8.58,0,0,0,10.2,1.62,11.43,11.43,0,0,0,2.25-1.85,12,12,0,0,0,3.19-5.07,8.92,8.92,0,0,0-4.71-10A10.45,10.45,0,0,0,427.4,554.28Zm4.88.47a2.49,2.49,0,0,1,1.58,2.72,2.28,2.28,0,0,1-2.54,1.76c-1.12-.21-2.12-1.29-1.77-2.37l0-.07c.1-.64.14-1.42.71-1.82A2.21,2.21,0,0,1,432.28,554.75Z"/>
                <path class="cls-4" d="M415,553.43a5.16,5.16,0,0,0-2.66-1.76c-.32-.07-.45.41-.14.48a4.7,4.7,0,0,1,2.45,1.63.25.25,0,1,0,.35-.35Z"/>
                <path class="cls-4" d="M412.38,551.44a11.64,11.64,0,0,0-10.09.95,9.16,9.16,0,0,0-4.15,7.76c.08,3.46,2.76,6.68,5.57,8.47a8.69,8.69,0,0,0,8.72.29,9.57,9.57,0,0,0,4.95-9.66c0-.31-.52-.32-.49,0a9.19,9.19,0,0,1-3.65,8.59,8.38,8.38,0,0,1-8.41.85,12.13,12.13,0,0,1-3.71-3.05,9.23,9.23,0,0,1-2.38-4.44,8.54,8.54,0,0,1,3.16-7.92,11,11,0,0,1,10.34-1.36c.31.11.44-.37.14-.48Z"/>
                <path class="cls-4" d="M420.2,552.2c-.76-1.09-1.63-2.29-2.93-2.57l-.06,0A11.19,11.19,0,0,0,406.64,551c-2.92,2.3-4.25,6.6-2.69,10a11.27,11.27,0,0,0,1.61,2.43,12.12,12.12,0,0,0,4.7,3.71,8.93,8.93,0,0,0,10.41-3.64A10.46,10.46,0,0,0,420.2,552.2Zm-1,4.81a2.5,2.5,0,0,1-2.87,1.28,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.54-1.51l.06,0c.63.17,1.41.29,1.75.9A2.25,2.25,0,0,1,419.22,557Z"/>
                <path class="cls-4" d="M467.07,581.12a5.06,5.06,0,0,0-3.1-.74.25.25,0,0,0-.25.24.24.24,0,0,0,.25.25,4.69,4.69,0,0,1,2.85.68.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M464,580.13a11.79,11.79,0,0,0-8.81,3.9,9.23,9.23,0,0,0-1.73,8.77c1.12,3.34,4.55,5.45,7.82,6.33a8.7,8.7,0,0,0,8.7-2.56,9.63,9.63,0,0,0,1.37-10.87c-.14-.29-.57,0-.43.25a9.18,9.18,0,0,1-.65,9.5,8.3,8.3,0,0,1-8,3.38c-3.09-.55-6.71-2.54-8-5.49A8.68,8.68,0,0,1,455,585a11.11,11.11,0,0,1,8.93-4.37.25.25,0,0,0,0-.49Z"/>
                <path class="cls-4" d="M471.56,578.15c-1.09-.76-2.32-1.58-3.64-1.41h-.06a11.19,11.19,0,0,0-9.45,5,8.58,8.58,0,0,0,.9,10.28,11.14,11.14,0,0,0,2.35,1.73,12,12,0,0,0,5.68,1.87,8.92,8.92,0,0,0,8.53-7A10.47,10.47,0,0,0,471.56,578.15Zm.73,4.85a2.47,2.47,0,0,1-2.25,2.19,2.27,2.27,0,0,1-2.33-2c-.07-1.14.74-2.37,1.88-2.29h.06a2.57,2.57,0,0,1,2,.24A2.25,2.25,0,0,1,472.29,583Z"/>
                <path class="cls-4" d="M508.87,584a5.24,5.24,0,0,0-1.49,2.9.25.25,0,0,0,.5,0,4.7,4.7,0,0,1,1.34-2.55c.22-.23-.13-.58-.35-.35Z"/>
                <path class="cls-4" d="M507.15,586.72a11.75,11.75,0,0,0,1.67,9.52,9.16,9.16,0,0,0,8.26,3.76c3.47-.35,6.37-3.24,7.94-6.21a8.71,8.71,0,0,0-.58-9,9.63,9.63,0,0,0-10.1-3.8c-.31.06-.18.54.14.48a9.13,9.13,0,0,1,9,2.91,8.34,8.34,0,0,1,1.5,8.3,13,13,0,0,1-3,4.22,8.43,8.43,0,0,1-4.15,2.51,8.64,8.64,0,0,1-8.1-2.69,11.06,11.06,0,0,1-2.17-9.83c.07-.31-.41-.45-.48-.13Z"/>
                <path class="cls-4" d="M507.07,578.88c-1,.87-2.1,1.87-2.25,3.18l0,.07a11.21,11.21,0,0,0,2.51,10.37,8.59,8.59,0,0,0,10.2,1.62,11.36,11.36,0,0,0,2.24-1.86,12.11,12.11,0,0,0,3.2-5.06,8.93,8.93,0,0,0-4.71-10A10.47,10.47,0,0,0,507.07,578.88Zm4.89.47a2.5,2.5,0,0,1,1.58,2.72,2.29,2.29,0,0,1-2.54,1.76,2,2,0,0,1-1.77-2.38l0-.06c.1-.64.14-1.43.71-1.83A2.21,2.21,0,0,1,512,579.35Z"/>
                <path class="cls-4" d="M494.63,578a5.14,5.14,0,0,0-2.67-1.76c-.31-.07-.44.41-.13.48a4.7,4.7,0,0,1,2.45,1.63.25.25,0,0,0,.35-.35Z"/>
                <path class="cls-4" d="M492.05,576A11.64,11.64,0,0,0,482,577a9.18,9.18,0,0,0-4.14,7.76c.08,3.46,2.75,6.68,5.56,8.47a8.69,8.69,0,0,0,8.72.29,9.6,9.6,0,0,0,5-9.66c0-.32-.53-.32-.5,0a9.16,9.16,0,0,1-3.64,8.58,8.38,8.38,0,0,1-8.41.86,12.19,12.19,0,0,1-3.72-3,9.17,9.17,0,0,1-2.37-4.44,8.56,8.56,0,0,1,3.16-7.93,11,11,0,0,1,10.34-1.35c.3.1.43-.38.13-.48Z"/>
                <path class="cls-4" d="M499.88,576.8c-.76-1.09-1.64-2.29-2.93-2.58l-.06,0a11.21,11.21,0,0,0-10.58,1.41,8.58,8.58,0,0,0-2.68,10,11.1,11.1,0,0,0,1.6,2.43,12.15,12.15,0,0,0,4.71,3.71,8.93,8.93,0,0,0,10.41-3.64A10.48,10.48,0,0,0,499.88,576.8Zm-1,4.8a2.49,2.49,0,0,1-2.87,1.29,2.3,2.3,0,0,1-1.49-2.71,2,2,0,0,1,2.55-1.51l.06,0c.63.17,1.41.29,1.74.9A2.19,2.19,0,0,1,498.9,581.6Z"/>
                <path class="cls-4" d="M541.4,581.12a5.06,5.06,0,0,0-3.1-.74.25.25,0,0,0-.25.24.24.24,0,0,0,.25.25,4.71,4.71,0,0,1,2.85.68.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M538.3,580.13a11.78,11.78,0,0,0-8.81,3.9,9.23,9.23,0,0,0-1.73,8.77c1.12,3.34,4.55,5.45,7.82,6.33a8.7,8.7,0,0,0,8.7-2.56,9.63,9.63,0,0,0,1.37-10.87c-.14-.29-.57,0-.43.25a9.18,9.18,0,0,1-.65,9.5,8.31,8.31,0,0,1-8.05,3.38c-3.08-.55-6.7-2.54-8-5.49a8.68,8.68,0,0,1,.87-8.35,11.1,11.1,0,0,1,8.93-4.37.25.25,0,0,0,0-.49Z"/>
                <path class="cls-4" d="M545.89,578.15c-1.09-.76-2.32-1.58-3.64-1.41h-.07a11.18,11.18,0,0,0-9.44,5,8.58,8.58,0,0,0,.9,10.28A11.34,11.34,0,0,0,536,593.7a12.11,12.11,0,0,0,5.69,1.87,8.92,8.92,0,0,0,8.53-7A10.47,10.47,0,0,0,545.89,578.15Zm.73,4.85a2.47,2.47,0,0,1-2.26,2.19,2.27,2.27,0,0,1-2.32-2c-.07-1.14.74-2.37,1.88-2.29H544a2.57,2.57,0,0,1,1.95.24A2.25,2.25,0,0,1,546.62,583Z"/>
                <path class="cls-4" d="M583.2,584a5.18,5.18,0,0,0-1.49,2.9.25.25,0,0,0,.5,0,4.7,4.7,0,0,1,1.34-2.55c.22-.23-.13-.58-.35-.35Z"/>
                <path class="cls-4" d="M581.48,586.72a11.75,11.75,0,0,0,1.67,9.52,9.16,9.16,0,0,0,8.26,3.76c3.47-.35,6.37-3.24,7.94-6.21a8.71,8.71,0,0,0-.58-9,9.63,9.63,0,0,0-10.1-3.8c-.31.06-.18.54.14.48a9.13,9.13,0,0,1,9,2.91,8.34,8.34,0,0,1,1.5,8.3,13,13,0,0,1-3,4.22,8.43,8.43,0,0,1-4.15,2.51,8.64,8.64,0,0,1-8.1-2.69,11.06,11.06,0,0,1-2.17-9.83c.07-.31-.41-.45-.48-.13Z"/>
                <path class="cls-4" d="M581.4,578.88c-1,.87-2.1,1.87-2.25,3.18l0,.07a11.21,11.21,0,0,0,2.51,10.37,8.59,8.59,0,0,0,10.2,1.62,11.36,11.36,0,0,0,2.24-1.86,12.11,12.11,0,0,0,3.2-5.06,8.94,8.94,0,0,0-4.71-10A10.47,10.47,0,0,0,581.4,578.88Zm4.89.47a2.48,2.48,0,0,1,1.57,2.72,2.27,2.27,0,0,1-2.53,1.76,2,2,0,0,1-1.77-2.38v-.06c.11-.64.15-1.43.72-1.83A2.21,2.21,0,0,1,586.29,579.35Z"/>
                <path class="cls-4" d="M569,578a5.18,5.18,0,0,0-2.67-1.76c-.31-.07-.44.41-.13.48a4.7,4.7,0,0,1,2.45,1.63A.25.25,0,0,0,569,578Z"/>
                <path class="cls-4" d="M566.38,576a11.64,11.64,0,0,0-10.09,1,9.18,9.18,0,0,0-4.14,7.76c.08,3.46,2.75,6.68,5.56,8.47a8.69,8.69,0,0,0,8.72.29,9.6,9.6,0,0,0,5-9.66c0-.32-.53-.32-.5,0a9.18,9.18,0,0,1-3.64,8.58,8.38,8.38,0,0,1-8.41.86,12.19,12.19,0,0,1-3.72-3,9.27,9.27,0,0,1-2.38-4.44,8.61,8.61,0,0,1,3.16-7.93,11,11,0,0,1,10.35-1.35c.3.1.43-.38.13-.48Z"/>
                <path class="cls-4" d="M574.2,576.8c-.75-1.09-1.63-2.29-2.92-2.58l-.07,0a11.19,11.19,0,0,0-10.57,1.41,8.58,8.58,0,0,0-2.68,10,11.1,11.1,0,0,0,1.6,2.43,12.2,12.2,0,0,0,4.7,3.71,8.92,8.92,0,0,0,10.41-3.64A10.44,10.44,0,0,0,574.2,576.8Zm-1,4.8a2.49,2.49,0,0,1-2.87,1.29,2.3,2.3,0,0,1-1.49-2.71,2,2,0,0,1,2.55-1.51l.06,0c.63.17,1.41.29,1.74.9A2.22,2.22,0,0,1,573.23,581.6Z"/>
                <path class="cls-4" d="M319,581.12a5.09,5.09,0,0,0-3.11-.74.25.25,0,0,0-.25.24c0,.13.12.27.25.25a4.72,4.72,0,0,1,2.86.68.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M315.85,580.13A11.78,11.78,0,0,0,307,584a9.2,9.2,0,0,0-1.73,8.77c1.12,3.34,4.55,5.45,7.81,6.33a8.71,8.71,0,0,0,8.71-2.56,9.64,9.64,0,0,0,1.37-10.87c-.14-.29-.57,0-.43.25a9.18,9.18,0,0,1-.66,9.5,8.28,8.28,0,0,1-8,3.38c-3.08-.55-6.7-2.54-8-5.49a8.65,8.65,0,0,1,.87-8.35,11.1,11.1,0,0,1,8.93-4.37.25.25,0,0,0,0-.49Z"/>
                <path class="cls-4" d="M323.43,578.15c-1.08-.76-2.31-1.58-3.63-1.41h-.07a11.21,11.21,0,0,0-9.45,5,8.57,8.57,0,0,0,.91,10.28,11.08,11.08,0,0,0,2.34,1.73,12,12,0,0,0,5.69,1.87,8.93,8.93,0,0,0,8.53-7A10.47,10.47,0,0,0,323.43,578.15Zm.74,4.85a2.49,2.49,0,0,1-2.26,2.19,2.27,2.27,0,0,1-2.32-2c-.07-1.14.73-2.37,1.87-2.29h.07c.64-.06,1.42-.21,1.94.24A2.23,2.23,0,0,1,324.17,583Z"/>
                <path class="cls-4" d="M360.74,584a5.16,5.16,0,0,0-1.48,2.9c0,.13.12.25.25.25a.27.27,0,0,0,.25-.25,4.62,4.62,0,0,1,1.33-2.55c.22-.23-.13-.58-.35-.35Z"/>
                <path class="cls-4" d="M359,586.72a11.74,11.74,0,0,0,1.66,9.52A9.19,9.19,0,0,0,369,600c3.47-.35,6.37-3.24,7.94-6.21a8.69,8.69,0,0,0-.59-9,9.61,9.61,0,0,0-10.09-3.8c-.31.06-.18.54.13.48a9.15,9.15,0,0,1,9,2.91,8.34,8.34,0,0,1,1.5,8.3,13,13,0,0,1-3,4.22,8.46,8.46,0,0,1-4.16,2.51,8.65,8.65,0,0,1-8.1-2.69,11.06,11.06,0,0,1-2.16-9.83c.07-.31-.41-.45-.48-.13Z"/>
                <path class="cls-4" d="M359,578.88c-1,.87-2.1,1.87-2.25,3.18l0,.07a11.18,11.18,0,0,0,2.51,10.37,8.58,8.58,0,0,0,10.19,1.62,11.14,11.14,0,0,0,2.25-1.86,12,12,0,0,0,3.19-5.06,8.92,8.92,0,0,0-4.71-10A10.46,10.46,0,0,0,359,578.88Zm4.88.47a2.48,2.48,0,0,1,1.58,2.72,2.28,2.28,0,0,1-2.54,1.76,2,2,0,0,1-1.76-2.38v-.06c.11-.64.14-1.43.71-1.83A2.21,2.21,0,0,1,363.83,579.35Z"/>
                <path class="cls-4" d="M346.51,578a5.18,5.18,0,0,0-2.67-1.76c-.31-.07-.45.41-.13.48a4.68,4.68,0,0,1,2.44,1.63.25.25,0,0,0,.36-.35Z"/>
                <path class="cls-4" d="M343.93,576a11.64,11.64,0,0,0-10.09,1,9.18,9.18,0,0,0-4.14,7.76c.07,3.46,2.75,6.68,5.56,8.47a8.69,8.69,0,0,0,8.72.29,9.6,9.6,0,0,0,5-9.66c0-.32-.53-.32-.5,0a9.18,9.18,0,0,1-3.64,8.58,8.39,8.39,0,0,1-8.42.86,12.27,12.27,0,0,1-3.71-3,9.27,9.27,0,0,1-2.38-4.44,8.61,8.61,0,0,1,3.16-7.93,11,11,0,0,1,10.35-1.35c.3.1.43-.38.13-.48Z"/>
                <path class="cls-4" d="M351.75,576.8c-.76-1.09-1.63-2.29-2.93-2.58l-.06,0a11.19,11.19,0,0,0-10.57,1.41,8.58,8.58,0,0,0-2.69,10,11.52,11.52,0,0,0,1.61,2.43,12.12,12.12,0,0,0,4.7,3.71,8.93,8.93,0,0,0,10.41-3.64A10.46,10.46,0,0,0,351.75,576.8Zm-1,4.8a2.51,2.51,0,0,1-2.88,1.29,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.55-1.51l.06,0c.62.17,1.4.29,1.74.9A2.22,2.22,0,0,1,350.78,581.6Z"/>
                <path class="cls-4" d="M393.28,581.12a5.09,5.09,0,0,0-3.11-.74.25.25,0,0,0-.25.24c0,.13.12.27.25.25a4.74,4.74,0,0,1,2.86.68.25.25,0,0,0,.25-.43Z"/>
                <path class="cls-4" d="M390.18,580.13a11.78,11.78,0,0,0-8.81,3.9,9.2,9.2,0,0,0-1.73,8.77c1.12,3.34,4.55,5.45,7.81,6.33a8.69,8.69,0,0,0,8.7-2.56,9.61,9.61,0,0,0,1.37-10.87c-.13-.29-.56,0-.42.25a9.18,9.18,0,0,1-.66,9.5,8.28,8.28,0,0,1-8,3.38c-3.08-.55-6.71-2.54-8-5.49a8.65,8.65,0,0,1,.87-8.35,11.1,11.1,0,0,1,8.93-4.37.25.25,0,0,0,0-.49Z"/>
                <path class="cls-4" d="M397.76,578.15c-1.08-.76-2.32-1.58-3.63-1.41h-.07a11.2,11.2,0,0,0-9.45,5,8.57,8.57,0,0,0,.91,10.28,11.08,11.08,0,0,0,2.34,1.73,12,12,0,0,0,5.69,1.87,8.94,8.94,0,0,0,8.53-7A10.47,10.47,0,0,0,397.76,578.15Zm.74,4.85a2.49,2.49,0,0,1-2.26,2.19,2.26,2.26,0,0,1-2.32-2c-.07-1.14.73-2.37,1.87-2.29h.07c.64-.06,1.42-.21,1.94.24A2.23,2.23,0,0,1,398.5,583Z"/>
                <path class="cls-4" d="M435.07,584a5.16,5.16,0,0,0-1.48,2.9c0,.13.12.25.25.25a.25.25,0,0,0,.24-.25,4.7,4.7,0,0,1,1.34-2.55c.22-.23-.13-.58-.35-.35Z"/>
                <path class="cls-4" d="M433.35,586.72a11.79,11.79,0,0,0,1.67,9.52,9.19,9.19,0,0,0,8.27,3.76c3.47-.35,6.37-3.24,7.94-6.21a8.69,8.69,0,0,0-.59-9,9.61,9.61,0,0,0-10.09-3.8c-.31.06-.18.54.13.48a9.15,9.15,0,0,1,9,2.91,8.34,8.34,0,0,1,1.5,8.3,13,13,0,0,1-3,4.22,8.46,8.46,0,0,1-4.16,2.51,8.65,8.65,0,0,1-8.1-2.69,11.09,11.09,0,0,1-2.17-9.83.25.25,0,0,0-.48-.13Z"/>
                <path class="cls-4" d="M433.28,578.88c-1,.87-2.1,1.87-2.25,3.18l0,.07a11.18,11.18,0,0,0,2.52,10.37,8.58,8.58,0,0,0,10.19,1.62,11.14,11.14,0,0,0,2.25-1.86,12,12,0,0,0,3.19-5.06,8.92,8.92,0,0,0-4.71-10A10.45,10.45,0,0,0,433.28,578.88Zm4.88.47a2.49,2.49,0,0,1,1.58,2.72,2.28,2.28,0,0,1-2.54,1.76,2,2,0,0,1-1.76-2.38v-.06c.1-.64.14-1.43.71-1.83A2.21,2.21,0,0,1,438.16,579.35Z"/>
                <path class="cls-4" d="M420.83,578a5.12,5.12,0,0,0-2.66-1.76c-.31-.07-.45.41-.13.48a4.68,4.68,0,0,1,2.44,1.63.25.25,0,0,0,.35,0,.24.24,0,0,0,0-.35Z"/>
                <path class="cls-4" d="M418.26,576a11.64,11.64,0,0,0-10.09,1,9.18,9.18,0,0,0-4.14,7.76c.07,3.46,2.75,6.68,5.56,8.47a8.69,8.69,0,0,0,8.72.29,9.6,9.6,0,0,0,5-9.66c0-.32-.53-.32-.5,0a9.18,9.18,0,0,1-3.64,8.58,8.4,8.4,0,0,1-8.42.86,12.27,12.27,0,0,1-3.71-3,9.27,9.27,0,0,1-2.38-4.44,8.61,8.61,0,0,1,3.16-7.93,11,11,0,0,1,10.35-1.35c.3.1.43-.38.13-.48Z"/>
                <path class="cls-4" d="M426.08,576.8c-.76-1.09-1.63-2.29-2.93-2.58l-.06,0a11.19,11.19,0,0,0-10.57,1.41,8.58,8.58,0,0,0-2.69,10,11.52,11.52,0,0,0,1.61,2.43,12.12,12.12,0,0,0,4.7,3.71,8.93,8.93,0,0,0,10.41-3.64A10.46,10.46,0,0,0,426.08,576.8Zm-1,4.8a2.51,2.51,0,0,1-2.88,1.29,2.28,2.28,0,0,1-1.48-2.71,2,2,0,0,1,2.55-1.51l.06,0c.62.17,1.4.29,1.74.9A2.22,2.22,0,0,1,425.11,581.6Z"/>
            </g>
        </g>
        <g class="cls-5">
            <path class="cls-6" d="M63.05,74.56,67.68,85"/>
            <path class="cls-6" d="M63.39,56.51q6.12,16.11,13.13,31.87"/>
            <path class="cls-6" d="M65,46.17q9,21,19,41.67"/>
            <path class="cls-6" d="M72.13,47.77c5.85,8.4,9.48,18.12,13,27.72l4.44,12"/>
            <path class="cls-6" d="M80.07,47,94,79.08C95.28,82,96.59,85,98.8,87.3"/>
            <path class="cls-6" d="M85.42,47.73a218.4,218.4,0,0,1,15.15,29.36"/>
            <path class="cls-6" d="M92.75,48.73a98.56,98.56,0,0,1,9,16"/>
            <path class="cls-6" d="M131.1,47.64l6.75,7.92"/>
            <path class="cls-6" d="M123.42,49.33a56.43,56.43,0,0,0,12.16,13.4"/>
            <path class="cls-6" d="M115.45,49.58q10.78,12.22,21.87,24.15"/>
            <path class="cls-6" d="M107.81,47.85a275,275,0,0,0,28.95,32.34"/>
            <path class="cls-6" d="M101.52,47.17c9.82,14.16,22.07,26.44,34.25,38.62"/>
            <path class="cls-6" d="M101.52,56.11a265.12,265.12,0,0,0,27.67,31.64"/>
            <path class="cls-6" d="M102,65.45a212.71,212.71,0,0,0,19.44,23.24"/>
            <path class="cls-6" d="M101.21,72.63,112,87.31"/>
            <path class="cls-6" d="M102.22,86.81l-.49-1"/>
            <path class="cls-6" d="M169.81,47.61,173.75,51"/>
            <path class="cls-6" d="M157.26,47.76l17.47,9.59"/>
            <path class="cls-6" d="M146.33,48.54a240.32,240.32,0,0,0,26.94,14.67"/>
            <path class="cls-6" d="M136.47,48.39C149.69,55.24,160.6,66.82,175,70.56"/>
            <path class="cls-6" d="M137,56.85c3.62,1.4,6.6,4,9.63,6.47a124.59,124.59,0,0,0,28.07,17.05"/>
            <path class="cls-6" d="M137.64,64.06a168.94,168.94,0,0,0,21.24,14.78c5,2.92,10.08,5.58,15.21,8.18"/>
            <path class="cls-6" d="M137.83,70.56c.5-.49,1.3,0,1.8.51A88.94,88.94,0,0,0,170,90.7"/>
            <path class="cls-6" d="M137.59,76.91a72,72,0,0,0,14.24,11.61"/>
            <path class="cls-6" d="M137,82.4l3.91,3.92"/>
            <path class="cls-6" d="M207,49.76l5,1.12"/>
            <path class="cls-6" d="M188.89,50.05c8.05,1,16.06,2.3,24,4"/>
            <path class="cls-6" d="M176.22,50.42q13.34,4.08,26.9,7.37c2.91.71,5.92,1.39,8.86.87"/>
            <path class="cls-6" d="M175.26,54.25a193.74,193.74,0,0,0,36.6,8.44"/>
            <path class="cls-6" d="M175.19,58.85c7.73.91,15.16,3.45,22.73,5.25A120.17,120.17,0,0,0,211,66.47"/>
            <path class="cls-6" d="M175.63,63.08c12.27,1.31,23.53,9,35.86,8.43"/>
            <path class="cls-6" d="M176.13,67.5c3.37-.36,6.62,1.14,9.76,2.42a91.47,91.47,0,0,0,25.58,6.39"/>
            <path class="cls-6" d="M176.19,72q15.93,4.38,32.06,8a6.68,6.68,0,0,0,3.78.11"/>
            <path class="cls-6" d="M176.61,75.32c2.29-.43,4.54.74,6.64,1.77a67,67,0,0,0,26.23,6.72"/>
            <path class="cls-6" d="M176.18,79.25,181.42,81c9.6,3.28,19.36,6.59,29.49,7.11"/>
            <path class="cls-6" d="M176.11,83c5.11,2.14,10,4.84,15.15,6.79S202.08,93,207.52,92"/>
            <path class="cls-6" d="M211.91,74.56,216.54,85"/>
            <path class="cls-6" d="M212.25,56.51q6.12,16.11,13.13,31.87"/>
            <path class="cls-6" d="M213.85,46.17q9,21,19,41.67"/>
            <path class="cls-6" d="M221,47.77c5.86,8.4,9.49,18.12,13.05,27.72l4.44,12"/>
            <path class="cls-6" d="M228.93,47l13.94,32.09c1.27,2.92,2.58,5.92,4.79,8.22"/>
            <path class="cls-6" d="M234.28,47.73a217.19,217.19,0,0,1,15.14,29.36"/>
            <path class="cls-6" d="M241.61,48.73a98.51,98.51,0,0,1,9,16"/>
            <path class="cls-6" d="M280,47.64l6.75,7.92"/>
            <path class="cls-6" d="M272.27,49.33a56.47,56.47,0,0,0,12.17,13.4"/>
            <path class="cls-6" d="M264.31,49.58Q275.1,61.8,286.18,73.73"/>
            <path class="cls-6" d="M256.67,47.85a275,275,0,0,0,28.95,32.34"/>
            <path class="cls-6" d="M250.38,47.17c9.82,14.16,22.07,26.44,34.24,38.62"/>
            <path class="cls-6" d="M250.38,56.11A265.93,265.93,0,0,0,278,87.75"/>
            <path class="cls-6" d="M250.81,65.45a212.71,212.71,0,0,0,19.44,23.24"/>
            <path class="cls-6" d="M250.07,72.63l10.78,14.68"/>
            <path class="cls-6" d="M251.08,86.81l-.49-1"/>
            <path class="cls-6" d="M318.67,47.61,322.61,51"/>
            <path class="cls-6" d="M306.12,47.76l17.47,9.59"/>
            <path class="cls-6" d="M295.19,48.54a240.19,240.19,0,0,0,26.93,14.67"/>
            <path class="cls-6" d="M285.33,48.39c13.22,6.85,24.13,18.43,38.54,22.17"/>
            <path class="cls-6" d="M285.87,56.85c3.63,1.4,6.61,4,9.64,6.47a124.15,124.15,0,0,0,28.07,17.05"/>
            <path class="cls-6" d="M286.5,64.06a168.94,168.94,0,0,0,21.24,14.78c5,2.92,10.07,5.58,15.21,8.18"/>
            <path class="cls-6" d="M286.69,70.56c.49-.49,1.3,0,1.79.51A89.08,89.08,0,0,0,318.89,90.7"/>
            <path class="cls-6" d="M286.45,76.91a72,72,0,0,0,14.24,11.61"/>
            <path class="cls-6" d="M285.85,82.4l3.91,3.92"/>
            <path class="cls-6" d="M355.83,49.76l5,1.12"/>
            <path class="cls-6" d="M337.75,50.05c8.05,1,16.06,2.3,24,4"/>
            <path class="cls-6" d="M325.07,50.42Q338.42,54.5,352,57.79c2.9.71,5.92,1.39,8.86.87"/>
            <path class="cls-6" d="M324.12,54.25a193.82,193.82,0,0,0,36.59,8.44"/>
            <path class="cls-6" d="M324.05,58.85c7.73.91,15.16,3.45,22.73,5.25a120.43,120.43,0,0,0,13,2.37"/>
            <path class="cls-6" d="M324.49,63.08c12.27,1.31,23.53,9,35.86,8.43"/>
            <path class="cls-6" d="M325,67.5c3.37-.36,6.62,1.14,9.76,2.42a91.4,91.4,0,0,0,25.57,6.39"/>
            <path class="cls-6" d="M325.05,72q15.93,4.38,32.06,8a6.68,6.68,0,0,0,3.78.11"/>
            <path class="cls-6" d="M325.47,75.32c2.29-.43,4.54.74,6.63,1.77a67.07,67.07,0,0,0,26.24,6.72"/>
            <path class="cls-6" d="M325,79.25,330.27,81c9.61,3.28,19.37,6.59,29.5,7.11"/>
            <path class="cls-6" d="M325,83c5.11,2.14,10,4.84,15.15,6.79S350.94,93,356.38,92"/>
            <path class="cls-6" d="M360.77,74.56,365.4,85"/>
            <path class="cls-6" d="M361.1,56.51q6.13,16.11,13.14,31.87"/>
            <path class="cls-6" d="M362.71,46.17q9,21,19,41.67"/>
            <path class="cls-6" d="M369.84,47.77c5.86,8.4,9.48,18.12,13.05,27.72l4.44,12"/>
            <path class="cls-6" d="M377.79,47l13.93,32.09C393,82,394.31,85,396.51,87.3"/>
            <path class="cls-6" d="M383.14,47.73a217.19,217.19,0,0,1,15.14,29.36"/>
            <path class="cls-6" d="M390.47,48.73a98.51,98.51,0,0,1,9,16"/>
            <path class="cls-6" d="M428.82,47.64l6.75,7.92"/>
            <path class="cls-6" d="M421.13,49.33a56.47,56.47,0,0,0,12.17,13.4"/>
            <path class="cls-6" d="M413.17,49.58Q423.94,61.8,435,73.73"/>
            <path class="cls-6" d="M405.53,47.85a273.23,273.23,0,0,0,29,32.34"/>
            <path class="cls-6" d="M399.24,47.17c9.81,14.16,22.06,26.44,34.24,38.62"/>
            <path class="cls-6" d="M399.24,56.11A264.14,264.14,0,0,0,426.9,87.75"/>
            <path class="cls-6" d="M399.67,65.45a212.71,212.71,0,0,0,19.44,23.24"/>
            <path class="cls-6" d="M398.93,72.63l10.78,14.68"/>
            <path class="cls-6" d="M399.94,86.81l-.49-1"/>
            <path class="cls-6" d="M467.53,47.61,471.46,51"/>
            <path class="cls-6" d="M455,47.76l17.47,9.59"/>
            <path class="cls-6" d="M444.05,48.54A239.31,239.31,0,0,0,471,63.21"/>
            <path class="cls-6" d="M434.18,48.39c13.23,6.85,24.14,18.43,38.55,22.17"/>
            <path class="cls-6" d="M434.73,56.85c3.62,1.4,6.61,4,9.63,6.47a124.44,124.44,0,0,0,28.08,17.05"/>
            <path class="cls-6" d="M435.36,64.06A168.94,168.94,0,0,0,456.6,78.84c5,2.92,10.07,5.58,15.21,8.18"/>
            <path class="cls-6" d="M435.54,70.56c.5-.49,1.31,0,1.8.51a89.14,89.14,0,0,0,30.4,19.63"/>
            <path class="cls-6" d="M435.3,76.91a72.33,72.33,0,0,0,14.25,11.61"/>
            <path class="cls-6" d="M434.7,82.4l3.92,3.92"/>
            <path class="cls-6" d="M504.69,49.76l5,1.12"/>
            <path class="cls-6" d="M486.61,50.05q12.08,1.43,24,4"/>
            <path class="cls-6" d="M473.93,50.42q13.33,4.08,26.91,7.37c2.9.71,5.91,1.39,8.86.87"/>
            <path class="cls-6" d="M473,54.25a193.64,193.64,0,0,0,36.59,8.44"/>
            <path class="cls-6" d="M472.91,58.85c7.73.91,15.16,3.45,22.73,5.25a120.43,120.43,0,0,0,13,2.37"/>
            <path class="cls-6" d="M473.35,63.08c12.27,1.31,23.53,9,35.86,8.43"/>
            <path class="cls-6" d="M473.85,67.5c3.37-.36,6.62,1.14,9.75,2.42a91.56,91.56,0,0,0,25.58,6.39"/>
            <path class="cls-6" d="M473.91,72q15.93,4.38,32.06,8a6.68,6.68,0,0,0,3.78.11"/>
            <path class="cls-6" d="M474.33,75.32c2.29-.43,4.54.74,6.63,1.77a67.07,67.07,0,0,0,26.24,6.72"/>
            <path class="cls-6" d="M473.9,79.25,479.13,81c9.61,3.28,19.36,6.59,29.5,7.11"/>
            <path class="cls-6" d="M473.83,83c5.11,2.14,10,4.84,15.15,6.79S499.8,93,505.24,92"/>
            <path class="cls-6" d="M509.63,74.56,514.26,85"/>
            <path class="cls-6" d="M510,56.51q6.13,16.11,13.14,31.87"/>
            <path class="cls-6" d="M511.57,46.17q9,21,19,41.67"/>
            <path class="cls-6" d="M518.7,47.77c5.86,8.4,9.48,18.12,13,27.72l4.44,12"/>
            <path class="cls-6" d="M526.65,47l13.93,32.09c1.27,2.92,2.59,5.92,4.79,8.22"/>
            <path class="cls-6" d="M532,47.73a216.11,216.11,0,0,1,15.15,29.36"/>
            <path class="cls-6" d="M539.33,48.73a98.51,98.51,0,0,1,9,16"/>
            <path class="cls-6" d="M577.68,47.64l6.75,7.92"/>
            <path class="cls-6" d="M570,49.33a56.47,56.47,0,0,0,12.17,13.4"/>
            <path class="cls-6" d="M562,49.58Q572.8,61.8,583.9,73.73"/>
            <path class="cls-6" d="M554.39,47.85a273.23,273.23,0,0,0,29,32.34"/>
            <path class="cls-6" d="M548.1,47.17c9.81,14.16,22.06,26.44,34.24,38.62"/>
            <path class="cls-6" d="M548.1,56.11a264.14,264.14,0,0,0,27.66,31.64"/>
            <path class="cls-6" d="M548.53,65.45A210.47,210.47,0,0,0,568,88.69"/>
            <path class="cls-6" d="M547.78,72.63l10.79,14.68"/>
            <path class="cls-6" d="M548.79,86.81l-.49-1"/>
            <path class="cls-6" d="M616.39,47.61,620.32,51"/>
            <path class="cls-6" d="M603.83,47.76l17.47,9.59"/>
            <path class="cls-6" d="M592.91,48.54a239.31,239.31,0,0,0,26.93,14.67"/>
            <path class="cls-6" d="M583,48.39c13.22,6.85,24.14,18.43,38.55,22.17"/>
            <path class="cls-6" d="M583.59,56.85c3.62,1.4,6.61,4,9.63,6.47A124.44,124.44,0,0,0,621.3,80.37"/>
            <path class="cls-6" d="M584.22,64.06a168.22,168.22,0,0,0,21.24,14.78c5,2.92,10.07,5.58,15.21,8.18"/>
            <path class="cls-6" d="M584.4,70.56c.5-.49,1.31,0,1.8.51A89.14,89.14,0,0,0,616.6,90.7"/>
            <path class="cls-6" d="M584.16,76.91a72.33,72.33,0,0,0,14.25,11.61"/>
            <path class="cls-6" d="M583.56,82.4l3.92,3.92"/>
            <path class="cls-6" d="M653.54,49.76l5,1.12"/>
            <path class="cls-6" d="M635.47,50.05q12.07,1.43,24,4"/>
            <path class="cls-6" d="M622.79,50.42q13.34,4.08,26.9,7.37c2.91.71,5.92,1.39,8.87.87"/>
            <path class="cls-6" d="M621.83,54.25a193.92,193.92,0,0,0,36.6,8.44"/>
            <path class="cls-6" d="M621.76,58.85c7.73.91,15.17,3.45,22.74,5.25a120,120,0,0,0,13,2.37"/>
            <path class="cls-6" d="M622.2,63.08c12.27,1.31,23.54,9,35.87,8.43"/>
            <path class="cls-6" d="M622.7,67.5c3.37-.36,6.63,1.14,9.76,2.42A91.56,91.56,0,0,0,658,76.31"/>
            <path class="cls-6" d="M622.77,72q15.93,4.38,32.06,8a6.68,6.68,0,0,0,3.78.11"/>
            <path class="cls-6" d="M623.19,75.32c2.29-.43,4.54.74,6.63,1.77a67.07,67.07,0,0,0,26.24,6.72"/>
            <path class="cls-6" d="M622.76,79.25,628,81c9.6,3.28,19.36,6.59,29.5,7.11"/>
            <path class="cls-6" d="M622.69,83c5.1,2.14,10,4.84,15.15,6.79S648.65,93,654.1,92"/>
            <path class="cls-6" d="M659.44,105.19l-4.63-10.4"/>
            <path class="cls-6" d="M659.1,123.25Q653,107.14,646,91.37"/>
            <path class="cls-6" d="M657.5,133.58q-9-21-19-41.66"/>
            <path class="cls-6" d="M650.37,132c-5.86-8.4-9.49-18.12-13-27.72l-4.44-12"/>
            <path class="cls-6" d="M642.42,132.77l-13.94-32.1c-1.27-2.92-2.58-5.91-4.79-8.21"/>
            <path class="cls-6" d="M637.07,132a219.12,219.12,0,0,1-15.15-29.37"/>
            <path class="cls-6" d="M629.74,131a99.4,99.4,0,0,1-9-16"/>
            <path class="cls-6" d="M591.39,132.12l-6.75-7.93"/>
            <path class="cls-6" d="M599.08,130.42A56.66,56.66,0,0,0,586.91,117"/>
            <path class="cls-6" d="M607,130.17Q596.26,118,585.17,106"/>
            <path class="cls-6" d="M614.68,131.9a274.91,274.91,0,0,0-28.95-32.33"/>
            <path class="cls-6" d="M621,132.58c-9.82-14.16-22.07-26.43-34.24-38.62"/>
            <path class="cls-6" d="M621,123.64A266.69,266.69,0,0,0,593.3,92"/>
            <path class="cls-6" d="M620.54,114.3A212.71,212.71,0,0,0,601.1,91.06"/>
            <path class="cls-6" d="M621.28,107.12,610.5,92.44"/>
            <path class="cls-6" d="M620.27,93l.49,1"/>
            <path class="cls-6" d="M552.68,132.14l-3.94-3.4"/>
            <path class="cls-6" d="M565.23,132l-17.47-9.59"/>
            <path class="cls-6" d="M576.16,131.21a238.51,238.51,0,0,0-26.93-14.66"/>
            <path class="cls-6" d="M586,131.36c-13.22-6.84-24.13-18.42-38.54-22.16"/>
            <path class="cls-6" d="M585.48,122.91c-3.63-1.41-6.61-4-9.64-6.47a123.43,123.43,0,0,0-28.07-17"/>
            <path class="cls-6" d="M584.85,115.7a170.07,170.07,0,0,0-21.24-14.79c-5-2.92-10.07-5.58-15.21-8.18"/>
            <path class="cls-6" d="M584.66,109.2c-.49.48-1.3,0-1.79-.52a89.05,89.05,0,0,0-30.41-19.62"/>
            <path class="cls-6" d="M584.9,102.85a71.63,71.63,0,0,0-14.24-11.61"/>
            <path class="cls-6" d="M585.5,97.35l-3.91-3.91"/>
            <path class="cls-6" d="M515.52,130l-5-1.12"/>
            <path class="cls-6" d="M533.6,129.7a242.64,242.64,0,0,1-24-4"/>
            <path class="cls-6" d="M546.28,129.33q-13.35-4.08-26.91-7.37c-2.9-.7-5.92-1.39-8.86-.87"/>
            <path class="cls-6" d="M547.23,125.51a193.82,193.82,0,0,0-36.59-8.44"/>
            <path class="cls-6" d="M547.3,120.9c-7.73-.91-15.16-3.44-22.73-5.25a122.88,122.88,0,0,0-13-2.36"/>
            <path class="cls-6" d="M546.86,116.67c-12.27-1.31-23.53-9-35.86-8.42"/>
            <path class="cls-6" d="M546.36,112.25c-3.37.36-6.62-1.13-9.76-2.42A92.1,92.1,0,0,0,511,103.44"/>
            <path class="cls-6" d="M546.3,107.74q-15.93-4.39-32.06-8a6.77,6.77,0,0,0-3.78-.11"/>
            <path class="cls-6" d="M545.88,104.43c-2.29.43-4.54-.74-6.64-1.76A66.81,66.81,0,0,0,513,96"/>
            <path class="cls-6" d="M546.31,100.51l-5.23-1.79c-9.61-3.28-19.37-6.59-29.5-7.1"/>
            <path class="cls-6" d="M546.38,96.73c-5.11-2.13-10-4.83-15.15-6.79S520.41,86.78,515,87.79"/>
            <path class="cls-6" d="M510.58,105.19,506,94.79"/>
            <path class="cls-6" d="M510.25,123.25q-6.13-16.11-13.14-31.88"/>
            <path class="cls-6" d="M508.64,133.58q-9-21-19-41.66"/>
            <path class="cls-6" d="M501.51,132c-5.86-8.4-9.48-18.12-13.05-27.72l-4.44-12"/>
            <path class="cls-6" d="M493.56,132.77l-13.93-32.1c-1.27-2.92-2.59-5.91-4.79-8.21"/>
            <path class="cls-6" d="M488.21,132a217.9,217.9,0,0,1-15.14-29.37"/>
            <path class="cls-6" d="M480.88,131a98.6,98.6,0,0,1-9-16"/>
            <path class="cls-6" d="M442.53,132.12l-6.75-7.93"/>
            <path class="cls-6" d="M450.22,130.42A56.66,56.66,0,0,0,438.05,117"/>
            <path class="cls-6" d="M458.18,130.17Q447.41,118,436.31,106"/>
            <path class="cls-6" d="M465.82,131.9a273.14,273.14,0,0,0-28.95-32.33"/>
            <path class="cls-6" d="M472.11,132.58c-9.81-14.16-22.06-26.43-34.24-38.62"/>
            <path class="cls-6" d="M472.11,123.64A265.71,265.71,0,0,0,444.45,92"/>
            <path class="cls-6" d="M471.68,114.3a212.71,212.71,0,0,0-19.44-23.24"/>
            <path class="cls-6" d="M472.42,107.12,461.64,92.44"/>
            <path class="cls-6" d="M471.41,93l.49,1"/>
            <path class="cls-6" d="M403.82,132.14l-3.93-3.4"/>
            <path class="cls-6" d="M416.37,132,398.9,122.4"/>
            <path class="cls-6" d="M427.3,131.21a237.65,237.65,0,0,0-26.93-14.66"/>
            <path class="cls-6" d="M437.17,131.36c-13.23-6.84-24.14-18.42-38.55-22.16"/>
            <path class="cls-6" d="M436.62,122.91c-3.62-1.41-6.61-4-9.63-6.47a123.72,123.72,0,0,0-28.08-17"/>
            <path class="cls-6" d="M436,115.7a170.07,170.07,0,0,0-21.24-14.79c-5-2.92-10.07-5.58-15.21-8.18"/>
            <path class="cls-6" d="M435.81,109.2c-.5.48-1.31,0-1.8-.52a89.11,89.11,0,0,0-30.4-19.62"/>
            <path class="cls-6" d="M436.05,102.85A72,72,0,0,0,421.8,91.24"/>
            <path class="cls-6" d="M436.65,97.35l-3.92-3.91"/>
            <path class="cls-6" d="M366.66,130l-5-1.12"/>
            <path class="cls-6" d="M384.74,129.7q-12.08-1.41-24-4"/>
            <path class="cls-6" d="M397.42,129.33q-13.34-4.08-26.91-7.37c-2.9-.7-5.91-1.39-8.86-.87"/>
            <path class="cls-6" d="M398.37,125.51a193.64,193.64,0,0,0-36.59-8.44"/>
            <path class="cls-6" d="M398.44,120.9c-7.73-.91-15.16-3.44-22.73-5.25a122.88,122.88,0,0,0-13-2.36"/>
            <path class="cls-6" d="M398,116.67c-12.27-1.31-23.53-9-35.86-8.42"/>
            <path class="cls-6" d="M397.5,112.25c-3.37.36-6.62-1.13-9.75-2.42a92.25,92.25,0,0,0-25.58-6.39"/>
            <path class="cls-6" d="M397.44,107.74q-15.93-4.39-32.06-8a6.77,6.77,0,0,0-3.78-.11"/>
            <path class="cls-6" d="M397,104.43c-2.29.43-4.54-.74-6.63-1.76A66.9,66.9,0,0,0,364.15,96"/>
            <path class="cls-6" d="M397.45,100.51l-5.23-1.79c-9.61-3.28-19.36-6.59-29.5-7.1"/>
            <path class="cls-6" d="M397.52,96.73c-5.11-2.13-10-4.83-15.15-6.79s-10.82-3.16-16.26-2.15"/>
            <path class="cls-6" d="M361.72,105.19l-4.63-10.4"/>
            <path class="cls-6" d="M361.39,123.25q-6.13-16.11-13.14-31.88"/>
            <path class="cls-6" d="M359.78,133.58q-9-21-19-41.66"/>
            <path class="cls-6" d="M352.65,132c-5.86-8.4-9.48-18.12-13-27.72l-4.44-12"/>
            <path class="cls-6" d="M344.7,132.77l-13.93-32.1c-1.27-2.92-2.59-5.91-4.79-8.21"/>
            <path class="cls-6" d="M339.36,132a216.81,216.81,0,0,1-15.15-29.37"/>
            <path class="cls-6" d="M332,131a98.6,98.6,0,0,1-9-16"/>
            <path class="cls-6" d="M293.67,132.12l-6.75-7.93"/>
            <path class="cls-6" d="M301.36,130.42A56.66,56.66,0,0,0,289.19,117"/>
            <path class="cls-6" d="M309.32,130.17Q298.55,118,287.45,106"/>
            <path class="cls-6" d="M317,131.9A273.14,273.14,0,0,0,288,99.57"/>
            <path class="cls-6" d="M323.25,132.58C313.44,118.42,301.19,106.15,289,94"/>
            <path class="cls-6" d="M323.25,123.64A265.71,265.71,0,0,0,295.59,92"/>
            <path class="cls-6" d="M322.82,114.3a210.47,210.47,0,0,0-19.44-23.24"/>
            <path class="cls-6" d="M323.57,107.12,312.78,92.44"/>
            <path class="cls-6" d="M322.56,93l.49,1"/>
            <path class="cls-6" d="M255,132.14l-3.93-3.4"/>
            <path class="cls-6" d="M267.52,132l-17.47-9.59"/>
            <path class="cls-6" d="M278.44,131.21a237.65,237.65,0,0,0-26.93-14.66"/>
            <path class="cls-6" d="M288.31,131.36c-13.22-6.84-24.14-18.42-38.55-22.16"/>
            <path class="cls-6" d="M287.76,122.91c-3.62-1.41-6.61-4-9.63-6.47a123.72,123.72,0,0,0-28.08-17"/>
            <path class="cls-6" d="M287.13,115.7a169.33,169.33,0,0,0-21.24-14.79c-5-2.92-10.07-5.58-15.21-8.18"/>
            <path class="cls-6" d="M287,109.2c-.5.48-1.31,0-1.8-.52a89.11,89.11,0,0,0-30.4-19.62"/>
            <path class="cls-6" d="M287.19,102.85a72,72,0,0,0-14.25-11.61"/>
            <path class="cls-6" d="M287.79,97.35l-3.92-3.91"/>
            <path class="cls-6" d="M217.81,130l-5-1.12"/>
            <path class="cls-6" d="M235.88,129.7q-12.07-1.41-24-4"/>
            <path class="cls-6" d="M248.56,129.33q-13.34-4.08-26.9-7.37c-2.91-.7-5.92-1.39-8.87-.87"/>
            <path class="cls-6" d="M249.52,125.51a193.92,193.92,0,0,0-36.6-8.44"/>
            <path class="cls-6" d="M249.59,120.9c-7.73-.91-15.17-3.44-22.74-5.25a122.42,122.42,0,0,0-13-2.36"/>
            <path class="cls-6" d="M249.15,116.67c-12.27-1.31-23.54-9-35.87-8.42"/>
            <path class="cls-6" d="M248.65,112.25c-3.37.36-6.63-1.13-9.76-2.42a92.25,92.25,0,0,0-25.58-6.39"/>
            <path class="cls-6" d="M248.58,107.74q-15.93-4.39-32.06-8a6.77,6.77,0,0,0-3.78-.11"/>
            <path class="cls-6" d="M248.16,104.43c-2.29.43-4.54-.74-6.63-1.76A66.9,66.9,0,0,0,215.29,96"/>
            <path class="cls-6" d="M248.59,100.51l-5.23-1.79c-9.6-3.28-19.36-6.59-29.5-7.1"/>
            <path class="cls-6" d="M248.66,96.73c-5.1-2.13-10-4.83-15.15-6.79s-10.81-3.16-16.26-2.15"/>
            <path class="cls-6" d="M212.86,105.19l-4.62-10.4"/>
            <path class="cls-6" d="M212.53,123.25q-6.12-16.11-13.13-31.88"/>
            <path class="cls-6" d="M210.92,133.58q-9-21-19-41.66"/>
            <path class="cls-6" d="M203.79,132c-5.86-8.4-9.48-18.12-13-27.72l-4.44-12"/>
            <path class="cls-6" d="M195.84,132.77l-13.93-32.1c-1.27-2.92-2.58-5.91-4.79-8.21"/>
            <path class="cls-6" d="M190.5,132a218,218,0,0,1-15.15-29.37"/>
            <path class="cls-6" d="M183.17,131a97.91,97.91,0,0,1-9-16"/>
            <path class="cls-6" d="M144.81,132.12l-6.75-7.93"/>
            <path class="cls-6" d="M152.5,130.42A56.45,56.45,0,0,0,140.33,117"/>
            <path class="cls-6" d="M160.46,130.17Q149.69,118,138.59,106"/>
            <path class="cls-6" d="M168.11,131.9a274.11,274.11,0,0,0-29-32.33"/>
            <path class="cls-6" d="M174.39,132.58c-9.81-14.16-22.06-26.43-34.24-38.62"/>
            <path class="cls-6" d="M174.39,123.64A265.71,265.71,0,0,0,146.73,92"/>
            <path class="cls-6" d="M174,114.3a211.5,211.5,0,0,0-19.43-23.24"/>
            <path class="cls-6" d="M174.71,107.12,163.92,92.44"/>
            <path class="cls-6" d="M173.7,93l.49,1"/>
            <path class="cls-6" d="M106.1,132.14l-3.93-3.4"/>
            <path class="cls-6" d="M118.66,132l-17.47-9.59"/>
            <path class="cls-6" d="M129.58,131.21a237.65,237.65,0,0,0-26.93-14.66"/>
            <path class="cls-6" d="M139.45,131.36c-13.22-6.84-24.14-18.42-38.55-22.16"/>
            <path class="cls-6" d="M138.9,122.91c-3.62-1.41-6.61-4-9.63-6.47a123.72,123.72,0,0,0-28.08-17"/>
            <path class="cls-6" d="M138.27,115.7A168.61,168.61,0,0,0,117,100.91c-5-2.92-10.07-5.58-15.21-8.18"/>
            <path class="cls-6" d="M138.09,109.2c-.5.48-1.3,0-1.8-.52a89.11,89.11,0,0,0-30.4-19.62"/>
            <path class="cls-6" d="M138.33,102.85a72.2,72.2,0,0,0-14.24-11.61"/>
            <path class="cls-6" d="M138.93,97.35,135,93.44"/>
            <path class="cls-6" d="M69,130l-5-1.12"/>
            <path class="cls-6" d="M87,129.7q-12.08-1.41-24-4"/>
            <path class="cls-6" d="M99.7,129.33Q86.37,125.25,72.8,122c-2.91-.7-5.92-1.39-8.87-.87"/>
            <path class="cls-6" d="M100.66,125.51a193.92,193.92,0,0,0-36.6-8.44"/>
            <path class="cls-6" d="M100.73,120.9C93,120,85.57,117.46,78,115.65a122.42,122.42,0,0,0-13-2.36"/>
            <path class="cls-6" d="M100.29,116.67c-12.27-1.31-23.54-9-35.86-8.42"/>
            <path class="cls-6" d="M99.79,112.25c-3.37.36-6.62-1.13-9.76-2.42a92.16,92.16,0,0,0-25.58-6.39"/>
            <path class="cls-6" d="M99.73,107.74q-15.95-4.39-32.06-8a6.81,6.81,0,0,0-3.79-.11"/>
            <path class="cls-6" d="M99.31,104.43c-2.3.43-4.55-.74-6.64-1.76A66.81,66.81,0,0,0,66.44,96"/>
            <path class="cls-6" d="M99.73,100.51,94.5,98.72c-9.6-3.28-19.36-6.59-29.5-7.1"/>
            <path class="cls-6" d="M99.8,96.73c-5.1-2.13-10-4.83-15.15-6.79s-10.81-3.16-16.26-2.15"/>
            <path class="cls-6" d="M63.05,158.76l4.63,10.41"/>
            <path class="cls-6" d="M63.39,140.71q6.12,16.11,13.13,31.87"/>
            <path class="cls-6" d="M65,130.37q9,21,19,41.66"/>
            <path class="cls-6" d="M72.13,132c5.85,8.4,9.48,18.12,13,27.72l4.44,11.95"/>
            <path class="cls-6" d="M80.07,131.19,94,163.28c1.27,2.92,2.58,5.92,4.79,8.22"/>
            <path class="cls-6" d="M85.42,131.93a218.4,218.4,0,0,1,15.15,29.36"/>
            <path class="cls-6" d="M92.75,132.93a98.14,98.14,0,0,1,9,16"/>
            <path class="cls-6" d="M131.1,131.84l6.75,7.92"/>
            <path class="cls-6" d="M123.42,133.53a56.43,56.43,0,0,0,12.16,13.4"/>
            <path class="cls-6" d="M115.45,133.78q10.78,12.22,21.87,24.15"/>
            <path class="cls-6" d="M107.81,132.05a275,275,0,0,0,28.95,32.34"/>
            <path class="cls-6" d="M101.52,131.37c9.82,14.16,22.07,26.44,34.25,38.62"/>
            <path class="cls-6" d="M101.52,140.31A265.12,265.12,0,0,0,129.19,172"/>
            <path class="cls-6" d="M102,149.65a212.71,212.71,0,0,0,19.44,23.24"/>
            <path class="cls-6" d="M101.21,156.83,112,171.51"/>
            <path class="cls-6" d="M102.22,171l-.49-1"/>
            <path class="cls-6" d="M169.81,131.81l3.94,3.41"/>
            <path class="cls-6" d="M157.26,132l17.47,9.59"/>
            <path class="cls-6" d="M146.33,132.74a240.32,240.32,0,0,0,26.94,14.67"/>
            <path class="cls-6" d="M136.47,132.59C149.69,139.44,160.6,151,175,154.76"/>
            <path class="cls-6" d="M137,141.05c3.62,1.4,6.6,4,9.63,6.47a124.59,124.59,0,0,0,28.07,17"/>
            <path class="cls-6" d="M137.64,148.26A168.94,168.94,0,0,0,158.88,163c5,2.92,10.08,5.58,15.21,8.18"/>
            <path class="cls-6" d="M137.83,154.76c.5-.49,1.3,0,1.8.51A88.94,88.94,0,0,0,170,174.9"/>
            <path class="cls-6" d="M137.59,161.11a72,72,0,0,0,14.24,11.61"/>
            <path class="cls-6" d="M137,166.6l3.91,3.92"/>
            <path class="cls-6" d="M207,134l5,1.12"/>
            <path class="cls-6" d="M188.89,134.25c8.05.95,16.06,2.3,24,4"/>
            <path class="cls-6" d="M176.22,134.62q13.34,4.08,26.9,7.37c2.91.71,5.92,1.39,8.86.87"/>
            <path class="cls-6" d="M175.26,138.45a193.74,193.74,0,0,0,36.6,8.44"/>
            <path class="cls-6" d="M175.19,143.05c7.73.91,15.16,3.45,22.73,5.25A120.17,120.17,0,0,0,211,150.67"/>
            <path class="cls-6" d="M175.63,147.28c12.27,1.31,23.53,8.95,35.86,8.43"/>
            <path class="cls-6" d="M176.13,151.7c3.37-.36,6.62,1.14,9.76,2.42a91.47,91.47,0,0,0,25.58,6.39"/>
            <path class="cls-6" d="M176.19,156.22q15.93,4.38,32.06,8a6.68,6.68,0,0,0,3.78.11"/>
            <path class="cls-6" d="M176.61,159.52c2.29-.43,4.54.74,6.64,1.77A67,67,0,0,0,209.48,168"/>
            <path class="cls-6" d="M176.18,163.45l5.24,1.78c9.6,3.28,19.36,6.59,29.49,7.11"/>
            <path class="cls-6" d="M176.11,167.22c5.11,2.14,10,4.84,15.15,6.79s10.82,3.16,16.26,2.15"/>
            <path class="cls-6" d="M211.91,158.76l4.63,10.41"/>
            <path class="cls-6" d="M212.25,140.71q6.12,16.11,13.13,31.87"/>
            <path class="cls-6" d="M213.85,130.37q9,21,19,41.66"/>
            <path class="cls-6" d="M221,132c5.86,8.4,9.49,18.12,13.05,27.72l4.44,11.95"/>
            <path class="cls-6" d="M228.93,131.19l13.94,32.09c1.27,2.92,2.58,5.92,4.79,8.22"/>
            <path class="cls-6" d="M234.28,131.93a217.19,217.19,0,0,1,15.14,29.36"/>
            <path class="cls-6" d="M241.61,132.93a98.09,98.09,0,0,1,9,16"/>
            <path class="cls-6" d="M280,131.84l6.75,7.92"/>
            <path class="cls-6" d="M272.27,133.53a56.47,56.47,0,0,0,12.17,13.4"/>
            <path class="cls-6" d="M264.31,133.78Q275.1,146,286.18,157.93"/>
            <path class="cls-6" d="M256.67,132.05a275,275,0,0,0,28.95,32.34"/>
            <path class="cls-6" d="M250.38,131.37c9.82,14.16,22.07,26.44,34.24,38.62"/>
            <path class="cls-6" d="M250.38,140.31A265.93,265.93,0,0,0,278,172"/>
            <path class="cls-6" d="M250.81,149.65a212.71,212.71,0,0,0,19.44,23.24"/>
            <path class="cls-6" d="M250.07,156.83l10.78,14.68"/>
            <path class="cls-6" d="M251.08,171l-.49-1"/>
            <path class="cls-6" d="M318.67,131.81l3.94,3.41"/>
            <path class="cls-6" d="M306.12,132l17.47,9.59"/>
            <path class="cls-6" d="M295.19,132.74a240.19,240.19,0,0,0,26.93,14.67"/>
            <path class="cls-6" d="M285.33,132.59c13.22,6.85,24.13,18.43,38.54,22.17"/>
            <path class="cls-6" d="M285.87,141.05c3.63,1.4,6.61,4,9.64,6.47a124.15,124.15,0,0,0,28.07,17"/>
            <path class="cls-6" d="M286.5,148.26A168.94,168.94,0,0,0,307.74,163c5,2.92,10.07,5.58,15.21,8.18"/>
            <path class="cls-6" d="M286.69,154.76c.49-.49,1.3,0,1.79.51a89.08,89.08,0,0,0,30.41,19.63"/>
            <path class="cls-6" d="M286.45,161.11a72,72,0,0,0,14.24,11.61"/>
            <path class="cls-6" d="M285.85,166.6l3.91,3.92"/>
            <path class="cls-6" d="M355.83,134l5,1.12"/>
            <path class="cls-6" d="M337.75,134.25c8.05.95,16.06,2.3,24,4"/>
            <path class="cls-6" d="M325.07,134.62Q338.42,138.7,352,142c2.9.71,5.92,1.39,8.86.87"/>
            <path class="cls-6" d="M324.12,138.45a193.82,193.82,0,0,0,36.59,8.44"/>
            <path class="cls-6" d="M324.05,143.05c7.73.91,15.16,3.45,22.73,5.25a120.43,120.43,0,0,0,13,2.37"/>
            <path class="cls-6" d="M324.49,147.28c12.27,1.31,23.53,8.95,35.86,8.43"/>
            <path class="cls-6" d="M325,151.7c3.37-.36,6.62,1.14,9.76,2.42a91.4,91.4,0,0,0,25.57,6.39"/>
            <path class="cls-6" d="M325.05,156.22q15.93,4.38,32.06,8a6.68,6.68,0,0,0,3.78.11"/>
            <path class="cls-6" d="M325.47,159.52c2.29-.43,4.54.74,6.63,1.77A67.07,67.07,0,0,0,358.34,168"/>
            <path class="cls-6" d="M325,163.45l5.23,1.78c9.61,3.28,19.37,6.59,29.5,7.11"/>
            <path class="cls-6" d="M325,167.22c5.11,2.14,10,4.84,15.15,6.79s10.82,3.16,16.26,2.15"/>
            <path class="cls-6" d="M360.77,158.76l4.63,10.41"/>
            <path class="cls-6" d="M361.1,140.71q6.13,16.11,13.14,31.87"/>
            <path class="cls-6" d="M362.71,130.37q9,21,19,41.66"/>
            <path class="cls-6" d="M369.84,132c5.86,8.4,9.48,18.12,13.05,27.72l4.44,11.95"/>
            <path class="cls-6" d="M377.79,131.19l13.93,32.09c1.27,2.92,2.59,5.92,4.79,8.22"/>
            <path class="cls-6" d="M383.14,131.93a217.19,217.19,0,0,1,15.14,29.36"/>
            <path class="cls-6" d="M390.47,132.93a98.09,98.09,0,0,1,9,16"/>
            <path class="cls-6" d="M428.82,131.84l6.75,7.92"/>
            <path class="cls-6" d="M421.13,133.53a56.47,56.47,0,0,0,12.17,13.4"/>
            <path class="cls-6" d="M413.17,133.78Q423.94,146,435,157.93"/>
            <path class="cls-6" d="M405.53,132.05a273.23,273.23,0,0,0,29,32.34"/>
            <path class="cls-6" d="M399.24,131.37c9.81,14.16,22.06,26.44,34.24,38.62"/>
            <path class="cls-6" d="M399.24,140.31A264.14,264.14,0,0,0,426.9,172"/>
            <path class="cls-6" d="M399.67,149.65a212.71,212.71,0,0,0,19.44,23.24"/>
            <path class="cls-6" d="M398.93,156.83l10.78,14.68"/>
            <path class="cls-6" d="M399.94,171l-.49-1"/>
            <path class="cls-6" d="M467.53,131.81l3.93,3.41"/>
            <path class="cls-6" d="M455,132l17.47,9.59"/>
            <path class="cls-6" d="M444.05,132.74A239.31,239.31,0,0,0,471,147.41"/>
            <path class="cls-6" d="M434.18,132.59c13.23,6.85,24.14,18.43,38.55,22.17"/>
            <path class="cls-6" d="M434.73,141.05c3.62,1.4,6.61,4,9.63,6.47a124.44,124.44,0,0,0,28.08,17"/>
            <path class="cls-6" d="M435.36,148.26A168.94,168.94,0,0,0,456.6,163c5,2.92,10.07,5.58,15.21,8.18"/>
            <path class="cls-6" d="M435.54,154.76c.5-.49,1.31,0,1.8.51a89.14,89.14,0,0,0,30.4,19.63"/>
            <path class="cls-6" d="M435.3,161.11a72.33,72.33,0,0,0,14.25,11.61"/>
            <path class="cls-6" d="M434.7,166.6l3.92,3.92"/>
            <path class="cls-6" d="M504.69,134l5,1.12"/>
            <path class="cls-6" d="M486.61,134.25q12.08,1.42,24,4"/>
            <path class="cls-6" d="M473.93,134.62q13.33,4.08,26.91,7.37c2.9.71,5.91,1.39,8.86.87"/>
            <path class="cls-6" d="M473,138.45a193.64,193.64,0,0,0,36.59,8.44"/>
            <path class="cls-6" d="M472.91,143.05c7.73.91,15.16,3.45,22.73,5.25a120.43,120.43,0,0,0,13,2.37"/>
            <path class="cls-6" d="M473.35,147.28c12.27,1.31,23.53,8.95,35.86,8.43"/>
            <path class="cls-6" d="M473.85,151.7c3.37-.36,6.62,1.14,9.75,2.42a91.56,91.56,0,0,0,25.58,6.39"/>
            <path class="cls-6" d="M473.91,156.22q15.93,4.38,32.06,8a6.68,6.68,0,0,0,3.78.11"/>
            <path class="cls-6" d="M474.33,159.52c2.29-.43,4.54.74,6.63,1.77A67.07,67.07,0,0,0,507.2,168"/>
            <path class="cls-6" d="M473.9,163.45l5.23,1.78c9.61,3.28,19.36,6.59,29.5,7.11"/>
            <path class="cls-6" d="M473.83,167.22c5.11,2.14,10,4.84,15.15,6.79s10.82,3.16,16.26,2.15"/>
            <path class="cls-6" d="M509.63,158.76l4.63,10.41"/>
            <path class="cls-6" d="M510,140.71q6.13,16.11,13.14,31.87"/>
            <path class="cls-6" d="M511.57,130.37q9,21,19,41.66"/>
            <path class="cls-6" d="M518.7,132c5.86,8.4,9.48,18.12,13,27.72l4.44,11.95"/>
            <path class="cls-6" d="M526.65,131.19l13.93,32.09c1.27,2.92,2.59,5.92,4.79,8.22"/>
            <path class="cls-6" d="M532,131.93a216.11,216.11,0,0,1,15.15,29.36"/>
            <path class="cls-6" d="M539.33,132.93a98.09,98.09,0,0,1,9,16"/>
            <path class="cls-6" d="M577.68,131.84l6.75,7.92"/>
            <path class="cls-6" d="M570,133.53a56.47,56.47,0,0,0,12.17,13.4"/>
            <path class="cls-6" d="M562,133.78Q572.8,146,583.9,157.93"/>
            <path class="cls-6" d="M554.39,132.05a273.23,273.23,0,0,0,29,32.34"/>
            <path class="cls-6" d="M548.1,131.37c9.81,14.16,22.06,26.44,34.24,38.62"/>
            <path class="cls-6" d="M548.1,140.31A264.14,264.14,0,0,0,575.76,172"/>
            <path class="cls-6" d="M548.53,149.65A210.47,210.47,0,0,0,568,172.89"/>
            <path class="cls-6" d="M547.78,156.83l10.79,14.68"/>
            <path class="cls-6" d="M548.79,171l-.49-1"/>
            <path class="cls-6" d="M616.39,131.81l3.93,3.41"/>
            <path class="cls-6" d="M603.83,132l17.47,9.59"/>
            <path class="cls-6" d="M592.91,132.74a239.31,239.31,0,0,0,26.93,14.67"/>
            <path class="cls-6" d="M583,132.59c13.22,6.85,24.14,18.43,38.55,22.17"/>
            <path class="cls-6" d="M583.59,141.05c3.62,1.4,6.61,4,9.63,6.47a124.44,124.44,0,0,0,28.08,17"/>
            <path class="cls-6" d="M584.22,148.26A168.22,168.22,0,0,0,605.46,163c5,2.92,10.07,5.58,15.21,8.18"/>
            <path class="cls-6" d="M584.4,154.76c.5-.49,1.31,0,1.8.51a89.14,89.14,0,0,0,30.4,19.63"/>
            <path class="cls-6" d="M584.16,161.11a72.33,72.33,0,0,0,14.25,11.61"/>
            <path class="cls-6" d="M583.56,166.6l3.92,3.92"/>
            <path class="cls-6" d="M653.54,134l5,1.12"/>
            <path class="cls-6" d="M635.47,134.25q12.07,1.42,24,4"/>
            <path class="cls-6" d="M622.79,134.62q13.34,4.08,26.9,7.37c2.91.71,5.92,1.39,8.87.87"/>
            <path class="cls-6" d="M621.83,138.45a193.92,193.92,0,0,0,36.6,8.44"/>
            <path class="cls-6" d="M621.76,143.05c7.73.91,15.17,3.45,22.74,5.25a120,120,0,0,0,13,2.37"/>
            <path class="cls-6" d="M622.2,147.28c12.27,1.31,23.54,8.95,35.87,8.43"/>
            <path class="cls-6" d="M622.7,151.7c3.37-.36,6.63,1.14,9.76,2.42A91.56,91.56,0,0,0,658,160.51"/>
            <path class="cls-6" d="M622.77,156.22q15.93,4.38,32.06,8a6.68,6.68,0,0,0,3.78.11"/>
            <path class="cls-6" d="M623.19,159.52c2.29-.43,4.54.74,6.63,1.77A67.07,67.07,0,0,0,656.06,168"/>
            <path class="cls-6" d="M622.76,163.45l5.23,1.78c9.6,3.28,19.36,6.59,29.5,7.11"/>
            <path class="cls-6" d="M622.69,167.22c5.1,2.14,10,4.84,15.15,6.79s10.81,3.16,16.26,2.15"/>
            <path class="cls-6" d="M659.44,189.39,654.81,179"/>
            <path class="cls-6" d="M659.1,207.45Q653,191.34,646,175.57"/>
            <path class="cls-6" d="M657.5,217.78q-9-21-19-41.66"/>
            <path class="cls-6" d="M650.37,216.19c-5.86-8.4-9.49-18.12-13-27.72l-4.44-12"/>
            <path class="cls-6" d="M642.42,217l-13.94-32.1c-1.27-2.92-2.58-5.91-4.79-8.21"/>
            <path class="cls-6" d="M637.07,216.23a219.12,219.12,0,0,1-15.15-29.37"/>
            <path class="cls-6" d="M629.74,215.23a99.4,99.4,0,0,1-9-16"/>
            <path class="cls-6" d="M591.39,216.32l-6.75-7.93"/>
            <path class="cls-6" d="M599.08,214.62a56.66,56.66,0,0,0-12.17-13.4"/>
            <path class="cls-6" d="M607,214.37q-10.78-12.21-21.87-24.14"/>
            <path class="cls-6" d="M614.68,216.1a274.91,274.91,0,0,0-28.95-32.33"/>
            <path class="cls-6" d="M621,216.78c-9.82-14.16-22.07-26.43-34.24-38.62"/>
            <path class="cls-6" d="M621,207.84A266.69,266.69,0,0,0,593.3,176.2"/>
            <path class="cls-6" d="M620.54,198.5a212.71,212.71,0,0,0-19.44-23.24"/>
            <path class="cls-6" d="M621.28,191.32,610.5,176.64"/>
            <path class="cls-6" d="M620.27,177.15l.49,1"/>
            <path class="cls-6" d="M552.68,216.34l-3.94-3.4"/>
            <path class="cls-6" d="M565.23,216.19l-17.47-9.59"/>
            <path class="cls-6" d="M576.16,215.41a238.51,238.51,0,0,0-26.93-14.66"/>
            <path class="cls-6" d="M586,215.56c-13.22-6.84-24.13-18.42-38.54-22.16"/>
            <path class="cls-6" d="M585.48,207.11c-3.63-1.41-6.61-4-9.64-6.47a123.43,123.43,0,0,0-28.07-17"/>
            <path class="cls-6" d="M584.85,199.9a170.07,170.07,0,0,0-21.24-14.79c-5-2.92-10.07-5.58-15.21-8.18"/>
            <path class="cls-6" d="M584.66,193.4c-.49.48-1.3,0-1.79-.52a89.05,89.05,0,0,0-30.41-19.62"/>
            <path class="cls-6" d="M584.9,187.05a71.63,71.63,0,0,0-14.24-11.61"/>
            <path class="cls-6" d="M585.5,181.55l-3.91-3.91"/>
            <path class="cls-6" d="M515.52,214.19l-5-1.12"/>
            <path class="cls-6" d="M533.6,213.9a242.64,242.64,0,0,1-24-4"/>
            <path class="cls-6" d="M546.28,213.53q-13.35-4.08-26.91-7.37c-2.9-.7-5.92-1.39-8.86-.87"/>
            <path class="cls-6" d="M547.23,209.71a193.82,193.82,0,0,0-36.59-8.44"/>
            <path class="cls-6" d="M547.3,205.1c-7.73-.91-15.16-3.44-22.73-5.25a122.88,122.88,0,0,0-13-2.36"/>
            <path class="cls-6" d="M546.86,200.87c-12.27-1.31-23.53-9-35.86-8.42"/>
            <path class="cls-6" d="M546.36,196.45c-3.37.36-6.62-1.13-9.76-2.42A92.1,92.1,0,0,0,511,187.64"/>
            <path class="cls-6" d="M546.3,191.94q-15.93-4.4-32.06-8a6.77,6.77,0,0,0-3.78-.11"/>
            <path class="cls-6" d="M545.88,188.63c-2.29.43-4.54-.74-6.64-1.76A66.81,66.81,0,0,0,513,180.15"/>
            <path class="cls-6" d="M546.31,184.71l-5.23-1.79c-9.61-3.28-19.37-6.59-29.5-7.1"/>
            <path class="cls-6" d="M546.38,180.93c-5.11-2.14-10-4.83-15.15-6.79S520.41,171,515,172"/>
            <path class="cls-6" d="M510.58,189.39,506,179"/>
            <path class="cls-6" d="M510.25,207.45q-6.13-16.11-13.14-31.88"/>
            <path class="cls-6" d="M508.64,217.78q-9-21-19-41.66"/>
            <path class="cls-6" d="M501.51,216.19c-5.86-8.4-9.48-18.12-13.05-27.72l-4.44-12"/>
            <path class="cls-6" d="M493.56,217l-13.93-32.1c-1.27-2.92-2.59-5.91-4.79-8.21"/>
            <path class="cls-6" d="M488.21,216.23a217.9,217.9,0,0,1-15.14-29.37"/>
            <path class="cls-6" d="M480.88,215.23a98.6,98.6,0,0,1-9-16"/>
            <path class="cls-6" d="M442.53,216.32l-6.75-7.93"/>
            <path class="cls-6" d="M450.22,214.62a56.66,56.66,0,0,0-12.17-13.4"/>
            <path class="cls-6" d="M458.18,214.37q-10.77-12.21-21.87-24.14"/>
            <path class="cls-6" d="M465.82,216.1a273.14,273.14,0,0,0-28.95-32.33"/>
            <path class="cls-6" d="M472.11,216.78c-9.81-14.16-22.06-26.43-34.24-38.62"/>
            <path class="cls-6" d="M472.11,207.84a265.71,265.71,0,0,0-27.66-31.64"/>
            <path class="cls-6" d="M471.68,198.5a212.71,212.71,0,0,0-19.44-23.24"/>
            <path class="cls-6" d="M472.42,191.32l-10.78-14.68"/>
            <path class="cls-6" d="M471.41,177.15l.49,1"/>
            <path class="cls-6" d="M403.82,216.34l-3.93-3.4"/>
            <path class="cls-6" d="M416.37,216.19,398.9,206.6"/>
            <path class="cls-6" d="M427.3,215.41a237.65,237.65,0,0,0-26.93-14.66"/>
            <path class="cls-6" d="M437.17,215.56c-13.23-6.84-24.14-18.42-38.55-22.16"/>
            <path class="cls-6" d="M436.62,207.11c-3.62-1.41-6.61-4-9.63-6.47a123.72,123.72,0,0,0-28.08-17"/>
            <path class="cls-6" d="M436,199.9a170.07,170.07,0,0,0-21.24-14.79c-5-2.92-10.07-5.58-15.21-8.18"/>
            <path class="cls-6" d="M435.81,193.4c-.5.48-1.31,0-1.8-.52a89.11,89.11,0,0,0-30.4-19.62"/>
            <path class="cls-6" d="M436.05,187.05a72,72,0,0,0-14.25-11.61"/>
            <path class="cls-6" d="M436.65,181.55l-3.92-3.91"/>
            <path class="cls-6" d="M366.66,214.19l-5-1.12"/>
            <path class="cls-6" d="M384.74,213.9q-12.08-1.41-24-4"/>
            <path class="cls-6" d="M397.42,213.53q-13.34-4.08-26.91-7.37c-2.9-.7-5.91-1.39-8.86-.87"/>
            <path class="cls-6" d="M398.37,209.71a193.64,193.64,0,0,0-36.59-8.44"/>
            <path class="cls-6" d="M398.44,205.1c-7.73-.91-15.16-3.44-22.73-5.25a122.88,122.88,0,0,0-13-2.36"/>
            <path class="cls-6" d="M398,200.87c-12.27-1.31-23.53-9-35.86-8.42"/>
            <path class="cls-6" d="M397.5,196.45c-3.37.36-6.62-1.13-9.75-2.42a92.25,92.25,0,0,0-25.58-6.39"/>
            <path class="cls-6" d="M397.44,191.94q-15.93-4.4-32.06-8a6.77,6.77,0,0,0-3.78-.11"/>
            <path class="cls-6" d="M397,188.63c-2.29.43-4.54-.74-6.63-1.76a66.9,66.9,0,0,0-26.24-6.72"/>
            <path class="cls-6" d="M397.45,184.71l-5.23-1.79c-9.61-3.28-19.36-6.59-29.5-7.1"/>
            <path class="cls-6" d="M397.52,180.93c-5.11-2.14-10-4.83-15.15-6.79S371.55,171,366.11,172"/>
            <path class="cls-6" d="M361.72,189.39,357.09,179"/>
            <path class="cls-6" d="M361.39,207.45q-6.13-16.11-13.14-31.88"/>
            <path class="cls-6" d="M359.78,217.78q-9-21-19-41.66"/>
            <path class="cls-6" d="M352.65,216.19c-5.86-8.4-9.48-18.12-13-27.72l-4.44-12"/>
            <path class="cls-6" d="M344.7,217l-13.93-32.1c-1.27-2.92-2.59-5.91-4.79-8.21"/>
            <path class="cls-6" d="M339.36,216.23a216.81,216.81,0,0,1-15.15-29.37"/>
            <path class="cls-6" d="M332,215.23a98.6,98.6,0,0,1-9-16"/>
            <path class="cls-6" d="M293.67,216.32l-6.75-7.93"/>
            <path class="cls-6" d="M301.36,214.62a56.66,56.66,0,0,0-12.17-13.4"/>
            <path class="cls-6" d="M309.32,214.37q-10.77-12.21-21.87-24.14"/>
            <path class="cls-6" d="M317,216.1A273.14,273.14,0,0,0,288,183.77"/>
            <path class="cls-6" d="M323.25,216.78c-9.81-14.16-22.06-26.43-34.24-38.62"/>
            <path class="cls-6" d="M323.25,207.84a265.71,265.71,0,0,0-27.66-31.64"/>
            <path class="cls-6" d="M322.82,198.5a210.47,210.47,0,0,0-19.44-23.24"/>
            <path class="cls-6" d="M323.57,191.32l-10.79-14.68"/>
            <path class="cls-6" d="M322.56,177.15l.49,1"/>
            <path class="cls-6" d="M255,216.34l-3.93-3.4"/>
            <path class="cls-6" d="M267.52,216.19l-17.47-9.59"/>
            <path class="cls-6" d="M278.44,215.41a237.65,237.65,0,0,0-26.93-14.66"/>
            <path class="cls-6" d="M288.31,215.56c-13.22-6.84-24.14-18.42-38.55-22.16"/>
            <path class="cls-6" d="M287.76,207.11c-3.62-1.41-6.61-4-9.63-6.47a123.72,123.72,0,0,0-28.08-17"/>
            <path class="cls-6" d="M287.13,199.9a169.33,169.33,0,0,0-21.24-14.79c-5-2.92-10.07-5.58-15.21-8.18"/>
            <path class="cls-6" d="M287,193.4c-.5.48-1.31,0-1.8-.52a89.11,89.11,0,0,0-30.4-19.62"/>
            <path class="cls-6" d="M287.19,187.05a72,72,0,0,0-14.25-11.61"/>
            <path class="cls-6" d="M287.79,181.55l-3.92-3.91"/>
            <path class="cls-6" d="M217.81,214.19l-5-1.12"/>
            <path class="cls-6" d="M235.88,213.9q-12.07-1.41-24-4"/>
            <path class="cls-6" d="M248.56,213.53q-13.34-4.08-26.9-7.37c-2.91-.7-5.92-1.39-8.87-.87"/>
            <path class="cls-6" d="M249.52,209.71a193.92,193.92,0,0,0-36.6-8.44"/>
            <path class="cls-6" d="M249.59,205.1c-7.73-.91-15.17-3.44-22.74-5.25a122.42,122.42,0,0,0-13-2.36"/>
            <path class="cls-6" d="M249.15,200.87c-12.27-1.31-23.54-9-35.87-8.42"/>
            <path class="cls-6" d="M248.65,196.45c-3.37.36-6.63-1.13-9.76-2.42a92.25,92.25,0,0,0-25.58-6.39"/>
            <path class="cls-6" d="M248.58,191.94q-15.93-4.4-32.06-8a6.77,6.77,0,0,0-3.78-.11"/>
            <path class="cls-6" d="M248.16,188.63c-2.29.43-4.54-.74-6.63-1.76a66.9,66.9,0,0,0-26.24-6.72"/>
            <path class="cls-6" d="M248.59,184.71l-5.23-1.79c-9.6-3.28-19.36-6.59-29.5-7.1"/>
            <path class="cls-6" d="M248.66,180.93c-5.1-2.14-10-4.83-15.15-6.79S222.7,171,217.25,172"/>
            <path class="cls-6" d="M212.86,189.39,208.24,179"/>
            <path class="cls-6" d="M212.53,207.45q-6.12-16.11-13.13-31.88"/>
            <path class="cls-6" d="M210.92,217.78q-9-21-19-41.66"/>
            <path class="cls-6" d="M203.79,216.19c-5.86-8.4-9.48-18.12-13-27.72l-4.44-12"/>
            <path class="cls-6" d="M195.84,217l-13.93-32.1c-1.27-2.92-2.58-5.91-4.79-8.21"/>
            <path class="cls-6" d="M190.5,216.23a218,218,0,0,1-15.15-29.37"/>
            <path class="cls-6" d="M183.17,215.23a97.91,97.91,0,0,1-9-16"/>
            <path class="cls-6" d="M144.81,216.32l-6.75-7.93"/>
            <path class="cls-6" d="M152.5,214.62a56.45,56.45,0,0,0-12.17-13.4"/>
            <path class="cls-6" d="M160.46,214.37q-10.77-12.21-21.87-24.14"/>
            <path class="cls-6" d="M168.11,216.1a274.11,274.11,0,0,0-29-32.33"/>
            <path class="cls-6" d="M174.39,216.78c-9.81-14.16-22.06-26.43-34.24-38.62"/>
            <path class="cls-6" d="M174.39,207.84a265.71,265.71,0,0,0-27.66-31.64"/>
            <path class="cls-6" d="M174,198.5a211.5,211.5,0,0,0-19.43-23.24"/>
            <path class="cls-6" d="M174.71,191.32l-10.79-14.68"/>
            <path class="cls-6" d="M173.7,177.15l.49,1"/>
            <path class="cls-6" d="M106.1,216.34l-3.93-3.4"/>
            <path class="cls-6" d="M118.66,216.19l-17.47-9.59"/>
            <path class="cls-6" d="M129.58,215.41a237.65,237.65,0,0,0-26.93-14.66"/>
            <path class="cls-6" d="M139.45,215.56c-13.22-6.84-24.14-18.42-38.55-22.16"/>
            <path class="cls-6" d="M138.9,207.11c-3.62-1.41-6.61-4-9.63-6.47a123.72,123.72,0,0,0-28.08-17"/>
            <path class="cls-6" d="M138.27,199.9A168.61,168.61,0,0,0,117,185.11c-5-2.92-10.07-5.58-15.21-8.18"/>
            <path class="cls-6" d="M138.09,193.4c-.5.48-1.3,0-1.8-.52a89.11,89.11,0,0,0-30.4-19.62"/>
            <path class="cls-6" d="M138.33,187.05a72.2,72.2,0,0,0-14.24-11.61"/>
            <path class="cls-6" d="M138.93,181.55,135,177.64"/>
            <path class="cls-6" d="M69,214.19l-5-1.12"/>
            <path class="cls-6" d="M87,213.9q-12.08-1.41-24-4"/>
            <path class="cls-6" d="M99.7,213.53q-13.33-4.08-26.9-7.37c-2.91-.7-5.92-1.39-8.87-.87"/>
            <path class="cls-6" d="M100.66,209.71a193.92,193.92,0,0,0-36.6-8.44"/>
            <path class="cls-6" d="M100.73,205.1c-7.73-.91-15.16-3.44-22.74-5.25a122.42,122.42,0,0,0-13-2.36"/>
            <path class="cls-6" d="M100.29,200.87c-12.27-1.31-23.54-9-35.86-8.42"/>
            <path class="cls-6" d="M99.79,196.45c-3.37.36-6.62-1.13-9.76-2.42a92.16,92.16,0,0,0-25.58-6.39"/>
            <path class="cls-6" d="M99.73,191.94q-15.95-4.4-32.06-8a6.81,6.81,0,0,0-3.79-.11"/>
            <path class="cls-6" d="M99.31,188.63c-2.3.43-4.55-.74-6.64-1.76a66.81,66.81,0,0,0-26.23-6.72"/>
            <path class="cls-6" d="M99.73,184.71l-5.23-1.79c-9.6-3.28-19.36-6.59-29.5-7.1"/>
            <path class="cls-6" d="M99.8,180.93c-5.1-2.14-10-4.83-15.15-6.79S73.84,171,68.39,172"/>
            <path class="cls-6" d="M63.05,242.51l4.63,10.4"/>
            <path class="cls-6" d="M63.39,224.45q6.12,16.13,13.13,31.88"/>
            <path class="cls-6" d="M65,214.12q9,21,19,41.66"/>
            <path class="cls-6" d="M72.13,215.71c5.85,8.41,9.48,18.13,13,27.73l4.44,11.95"/>
            <path class="cls-6" d="M80.07,214.93,94,247c1.27,2.92,2.58,5.91,4.79,8.22"/>
            <path class="cls-6" d="M85.42,215.68A218.4,218.4,0,0,1,100.57,245"/>
            <path class="cls-6" d="M92.75,216.68a98.14,98.14,0,0,1,9,16"/>
            <path class="cls-6" d="M131.1,215.59l6.75,7.92"/>
            <path class="cls-6" d="M123.42,217.28a56.43,56.43,0,0,0,12.16,13.4"/>
            <path class="cls-6" d="M115.45,217.53q10.78,12.21,21.87,24.15"/>
            <path class="cls-6" d="M107.81,215.8a274.91,274.91,0,0,0,28.95,32.33"/>
            <path class="cls-6" d="M101.52,215.12c9.82,14.16,22.07,26.43,34.25,38.62"/>
            <path class="cls-6" d="M101.52,224.06a265.12,265.12,0,0,0,27.67,31.64"/>
            <path class="cls-6" d="M102,233.4a212.71,212.71,0,0,0,19.44,23.24"/>
            <path class="cls-6" d="M101.21,240.58,112,255.26"/>
            <path class="cls-6" d="M102.22,254.76l-.49-1"/>
            <path class="cls-6" d="M169.81,215.56l3.94,3.41"/>
            <path class="cls-6" d="M157.26,215.71l17.47,9.59"/>
            <path class="cls-6" d="M146.33,216.49a240.32,240.32,0,0,0,26.94,14.67"/>
            <path class="cls-6" d="M136.47,216.34c13.22,6.84,24.13,18.43,38.54,22.17"/>
            <path class="cls-6" d="M137,224.8c3.62,1.4,6.6,4,9.63,6.47a124.59,124.59,0,0,0,28.07,17"/>
            <path class="cls-6" d="M137.64,232a168.94,168.94,0,0,0,21.24,14.78c5,2.92,10.08,5.58,15.21,8.18"/>
            <path class="cls-6" d="M137.83,238.51c.5-.49,1.3,0,1.8.51A88.77,88.77,0,0,0,170,258.64"/>
            <path class="cls-6" d="M137.59,244.86a72,72,0,0,0,14.24,11.61"/>
            <path class="cls-6" d="M137,250.35l3.91,3.92"/>
            <path class="cls-6" d="M207,217.71l5,1.12"/>
            <path class="cls-6" d="M188.89,218a242.67,242.67,0,0,1,24,4"/>
            <path class="cls-6" d="M176.22,218.37q13.34,4.08,26.9,7.37c2.91.71,5.92,1.39,8.86.87"/>
            <path class="cls-6" d="M175.26,222.2a193.74,193.74,0,0,0,36.6,8.44"/>
            <path class="cls-6" d="M175.19,226.8c7.73.91,15.16,3.45,22.73,5.25A120.17,120.17,0,0,0,211,234.42"/>
            <path class="cls-6" d="M175.63,231c12.27,1.31,23.53,8.95,35.86,8.43"/>
            <path class="cls-6" d="M176.13,235.45c3.37-.36,6.62,1.13,9.76,2.42a91.47,91.47,0,0,0,25.58,6.39"/>
            <path class="cls-6" d="M176.19,240q15.93,4.38,32.06,8a6.68,6.68,0,0,0,3.78.11"/>
            <path class="cls-6" d="M176.61,243.27c2.29-.43,4.54.74,6.64,1.77a67,67,0,0,0,26.23,6.72"/>
            <path class="cls-6" d="M176.18,247.2l5.24,1.78c9.6,3.28,19.36,6.59,29.49,7.1"/>
            <path class="cls-6" d="M176.11,251c5.11,2.14,10,4.83,15.15,6.79s10.82,3.16,16.26,2.15"/>
            <path class="cls-6" d="M211.91,242.51l4.63,10.4"/>
            <path class="cls-6" d="M212.25,224.45q6.12,16.13,13.13,31.88"/>
            <path class="cls-6" d="M213.85,214.12q9,21,19,41.66"/>
            <path class="cls-6" d="M221,215.71c5.86,8.41,9.49,18.13,13.05,27.73l4.44,11.95"/>
            <path class="cls-6" d="M228.93,214.93,242.87,247c1.27,2.92,2.58,5.91,4.79,8.22"/>
            <path class="cls-6" d="M234.28,215.68A217.19,217.19,0,0,1,249.42,245"/>
            <path class="cls-6" d="M241.61,216.68a98.09,98.09,0,0,1,9,16"/>
            <path class="cls-6" d="M280,215.59l6.75,7.92"/>
            <path class="cls-6" d="M272.27,217.28a56.47,56.47,0,0,0,12.17,13.4"/>
            <path class="cls-6" d="M264.31,217.53q10.79,12.21,21.87,24.15"/>
            <path class="cls-6" d="M256.67,215.8a274.91,274.91,0,0,0,28.95,32.33"/>
            <path class="cls-6" d="M250.38,215.12c9.82,14.16,22.07,26.43,34.24,38.62"/>
            <path class="cls-6" d="M250.38,224.06A265.93,265.93,0,0,0,278,255.7"/>
            <path class="cls-6" d="M250.81,233.4a212.71,212.71,0,0,0,19.44,23.24"/>
            <path class="cls-6" d="M250.07,240.58l10.78,14.68"/>
            <path class="cls-6" d="M251.08,254.76l-.49-1"/>
            <path class="cls-6" d="M318.67,215.56l3.94,3.41"/>
            <path class="cls-6" d="M306.12,215.71l17.47,9.59"/>
            <path class="cls-6" d="M295.19,216.49a240.19,240.19,0,0,0,26.93,14.67"/>
            <path class="cls-6" d="M285.33,216.34c13.22,6.84,24.13,18.43,38.54,22.17"/>
            <path class="cls-6" d="M285.87,224.8c3.63,1.4,6.61,4,9.64,6.47a124.15,124.15,0,0,0,28.07,17"/>
            <path class="cls-6" d="M286.5,232a168.94,168.94,0,0,0,21.24,14.78c5,2.92,10.07,5.58,15.21,8.18"/>
            <path class="cls-6" d="M286.69,238.51c.49-.49,1.3,0,1.79.51a88.91,88.91,0,0,0,30.41,19.62"/>
            <path class="cls-6" d="M286.45,244.86a72,72,0,0,0,14.24,11.61"/>
            <path class="cls-6" d="M285.85,250.35l3.91,3.92"/>
            <path class="cls-6" d="M355.83,217.71l5,1.12"/>
            <path class="cls-6" d="M337.75,218a242.67,242.67,0,0,1,24,4"/>
            <path class="cls-6" d="M325.07,218.37q13.35,4.08,26.91,7.37c2.9.71,5.92,1.39,8.86.87"/>
            <path class="cls-6" d="M324.12,222.2a193.82,193.82,0,0,0,36.59,8.44"/>
            <path class="cls-6" d="M324.05,226.8c7.73.91,15.16,3.45,22.73,5.25a120.43,120.43,0,0,0,13,2.37"/>
            <path class="cls-6" d="M324.49,231c12.27,1.31,23.53,8.95,35.86,8.43"/>
            <path class="cls-6" d="M325,235.45c3.37-.36,6.62,1.13,9.76,2.42a91.4,91.4,0,0,0,25.57,6.39"/>
            <path class="cls-6" d="M325.05,240q15.93,4.38,32.06,8a6.68,6.68,0,0,0,3.78.11"/>
            <path class="cls-6" d="M325.47,243.27c2.29-.43,4.54.74,6.63,1.77a67.07,67.07,0,0,0,26.24,6.72"/>
            <path class="cls-6" d="M325,247.2l5.23,1.78c9.61,3.28,19.37,6.59,29.5,7.1"/>
            <path class="cls-6" d="M325,251c5.11,2.14,10,4.83,15.15,6.79s10.82,3.16,16.26,2.15"/>
            <path class="cls-6" d="M360.77,242.51l4.63,10.4"/>
            <path class="cls-6" d="M361.1,224.45q6.13,16.13,13.14,31.88"/>
            <path class="cls-6" d="M362.71,214.12q9,21,19,41.66"/>
            <path class="cls-6" d="M369.84,215.71c5.86,8.41,9.48,18.13,13.05,27.73l4.44,11.95"/>
            <path class="cls-6" d="M377.79,214.93,391.72,247c1.27,2.92,2.59,5.91,4.79,8.22"/>
            <path class="cls-6" d="M383.14,215.68A217.19,217.19,0,0,1,398.28,245"/>
            <path class="cls-6" d="M390.47,216.68a98.09,98.09,0,0,1,9,16"/>
            <path class="cls-6" d="M428.82,215.59l6.75,7.92"/>
            <path class="cls-6" d="M421.13,217.28a56.47,56.47,0,0,0,12.17,13.4"/>
            <path class="cls-6" d="M413.17,217.53q10.77,12.21,21.87,24.15"/>
            <path class="cls-6" d="M405.53,215.8a273.14,273.14,0,0,0,29,32.33"/>
            <path class="cls-6" d="M399.24,215.12c9.81,14.16,22.06,26.43,34.24,38.62"/>
            <path class="cls-6" d="M399.24,224.06A264.14,264.14,0,0,0,426.9,255.7"/>
            <path class="cls-6" d="M399.67,233.4a212.71,212.71,0,0,0,19.44,23.24"/>
            <path class="cls-6" d="M398.93,240.58l10.78,14.68"/>
            <path class="cls-6" d="M399.94,254.76l-.49-1"/>
            <path class="cls-6" d="M467.53,215.56l3.93,3.41"/>
            <path class="cls-6" d="M455,215.71l17.47,9.59"/>
            <path class="cls-6" d="M444.05,216.49A239.31,239.31,0,0,0,471,231.16"/>
            <path class="cls-6" d="M434.18,216.34c13.23,6.84,24.14,18.43,38.55,22.17"/>
            <path class="cls-6" d="M434.73,224.8c3.62,1.4,6.61,4,9.63,6.47a124.44,124.44,0,0,0,28.08,17"/>
            <path class="cls-6" d="M435.36,232a168.94,168.94,0,0,0,21.24,14.78c5,2.92,10.07,5.58,15.21,8.18"/>
            <path class="cls-6" d="M435.54,238.51c.5-.49,1.31,0,1.8.51a89,89,0,0,0,30.4,19.62"/>
            <path class="cls-6" d="M435.3,244.86a72.33,72.33,0,0,0,14.25,11.61"/>
            <path class="cls-6" d="M434.7,250.35l3.92,3.92"/>
            <path class="cls-6" d="M504.69,217.71l5,1.12"/>
            <path class="cls-6" d="M486.61,218q12.08,1.42,24,4"/>
            <path class="cls-6" d="M473.93,218.37q13.33,4.08,26.91,7.37c2.9.71,5.91,1.39,8.86.87"/>
            <path class="cls-6" d="M473,222.2a193.64,193.64,0,0,0,36.59,8.44"/>
            <path class="cls-6" d="M472.91,226.8c7.73.91,15.16,3.45,22.73,5.25a120.43,120.43,0,0,0,13,2.37"/>
            <path class="cls-6" d="M473.35,231c12.27,1.31,23.53,8.95,35.86,8.43"/>
            <path class="cls-6" d="M473.85,235.45c3.37-.36,6.62,1.13,9.75,2.42a91.56,91.56,0,0,0,25.58,6.39"/>
            <path class="cls-6" d="M473.91,240q15.93,4.38,32.06,8a6.68,6.68,0,0,0,3.78.11"/>
            <path class="cls-6" d="M474.33,243.27c2.29-.43,4.54.74,6.63,1.77a67.07,67.07,0,0,0,26.24,6.72"/>
            <path class="cls-6" d="M473.9,247.2l5.23,1.78c9.61,3.28,19.36,6.59,29.5,7.1"/>
            <path class="cls-6" d="M473.83,251c5.11,2.14,10,4.83,15.15,6.79s10.82,3.16,16.26,2.15"/>
            <path class="cls-6" d="M509.63,242.51l4.63,10.4"/>
            <path class="cls-6" d="M510,224.45q6.13,16.13,13.14,31.88"/>
            <path class="cls-6" d="M511.57,214.12q9,21,19,41.66"/>
            <path class="cls-6" d="M518.7,215.71c5.86,8.41,9.48,18.13,13,27.73l4.44,11.95"/>
            <path class="cls-6" d="M526.65,214.93,540.58,247c1.27,2.92,2.59,5.91,4.79,8.22"/>
            <path class="cls-6" d="M532,215.68A216.11,216.11,0,0,1,547.14,245"/>
            <path class="cls-6" d="M539.33,216.68a98.09,98.09,0,0,1,9,16"/>
            <path class="cls-6" d="M577.68,215.59l6.75,7.92"/>
            <path class="cls-6" d="M570,217.28a56.47,56.47,0,0,0,12.17,13.4"/>
            <path class="cls-6" d="M562,217.53q10.77,12.21,21.87,24.15"/>
            <path class="cls-6" d="M554.39,215.8a273.14,273.14,0,0,0,29,32.33"/>
            <path class="cls-6" d="M548.1,215.12c9.81,14.16,22.06,26.43,34.24,38.62"/>
            <path class="cls-6" d="M548.1,224.06a264.14,264.14,0,0,0,27.66,31.64"/>
            <path class="cls-6" d="M548.53,233.4A210.47,210.47,0,0,0,568,256.64"/>
            <path class="cls-6" d="M547.78,240.58l10.79,14.68"/>
            <path class="cls-6" d="M548.79,254.76l-.49-1"/>
            <path class="cls-6" d="M616.39,215.56l3.93,3.41"/>
            <path class="cls-6" d="M603.83,215.71l17.47,9.59"/>
            <path class="cls-6" d="M592.91,216.49a239.31,239.31,0,0,0,26.93,14.67"/>
            <path class="cls-6" d="M583,216.34c13.22,6.84,24.14,18.43,38.55,22.17"/>
            <path class="cls-6" d="M583.59,224.8c3.62,1.4,6.61,4,9.63,6.47a124.44,124.44,0,0,0,28.08,17"/>
            <path class="cls-6" d="M584.22,232a168.22,168.22,0,0,0,21.24,14.78c5,2.92,10.07,5.58,15.21,8.18"/>
            <path class="cls-6" d="M584.4,238.51c.5-.49,1.31,0,1.8.51a89,89,0,0,0,30.4,19.62"/>
            <path class="cls-6" d="M584.16,244.86a72.33,72.33,0,0,0,14.25,11.61"/>
            <path class="cls-6" d="M583.56,250.35l3.92,3.92"/>
            <path class="cls-6" d="M653.54,217.71l5,1.12"/>
            <path class="cls-6" d="M635.47,218q12.07,1.42,24,4"/>
            <path class="cls-6" d="M622.79,218.37q13.34,4.08,26.9,7.37c2.91.71,5.92,1.39,8.87.87"/>
            <path class="cls-6" d="M621.83,222.2a193.92,193.92,0,0,0,36.6,8.44"/>
            <path class="cls-6" d="M621.76,226.8c7.73.91,15.17,3.45,22.74,5.25a120,120,0,0,0,13,2.37"/>
            <path class="cls-6" d="M622.2,231c12.27,1.31,23.54,8.95,35.87,8.43"/>
            <path class="cls-6" d="M622.7,235.45c3.37-.36,6.63,1.13,9.76,2.42A91.56,91.56,0,0,0,658,244.26"/>
            <path class="cls-6" d="M622.77,240q15.93,4.38,32.06,8a6.68,6.68,0,0,0,3.78.11"/>
            <path class="cls-6" d="M623.19,243.27c2.29-.43,4.54.74,6.63,1.77a67.07,67.07,0,0,0,26.24,6.72"/>
            <path class="cls-6" d="M622.76,247.2,628,249c9.6,3.28,19.36,6.59,29.5,7.1"/>
            <path class="cls-6" d="M622.69,251c5.1,2.14,10,4.83,15.15,6.79s10.81,3.16,16.26,2.15"/>
            <path class="cls-6" d="M659.44,273.14l-4.63-10.4"/>
            <path class="cls-6" d="M659.1,291.2Q653,275.08,646,259.32"/>
            <path class="cls-6" d="M657.5,301.53q-9-21-19-41.66"/>
            <path class="cls-6" d="M650.37,299.94c-5.86-8.4-9.49-18.12-13-27.72l-4.44-12"/>
            <path class="cls-6" d="M642.42,300.72l-13.94-32.1c-1.27-2.92-2.58-5.91-4.79-8.21"/>
            <path class="cls-6" d="M637.07,300a218.4,218.4,0,0,1-15.15-29.36"/>
            <path class="cls-6" d="M629.74,299a99.4,99.4,0,0,1-9-16"/>
            <path class="cls-6" d="M591.39,300.06l-6.75-7.92"/>
            <path class="cls-6" d="M599.08,298.37A56.66,56.66,0,0,0,586.91,285"/>
            <path class="cls-6" d="M607,298.12Q596.26,285.91,585.17,274"/>
            <path class="cls-6" d="M614.68,299.85a274.91,274.91,0,0,0-28.95-32.33"/>
            <path class="cls-6" d="M621,300.53c-9.82-14.16-22.07-26.43-34.24-38.62"/>
            <path class="cls-6" d="M621,291.59A265.12,265.12,0,0,0,593.3,260"/>
            <path class="cls-6" d="M620.54,282.25A212.71,212.71,0,0,0,601.1,259"/>
            <path class="cls-6" d="M621.28,275.07,610.5,260.39"/>
            <path class="cls-6" d="M620.27,260.89l.49,1"/>
            <path class="cls-6" d="M552.68,300.09l-3.94-3.4"/>
            <path class="cls-6" d="M565.23,299.94l-17.47-9.59"/>
            <path class="cls-6" d="M576.16,299.16a238.51,238.51,0,0,0-26.93-14.66"/>
            <path class="cls-6" d="M586,299.31c-13.22-6.84-24.13-18.42-38.54-22.16"/>
            <path class="cls-6" d="M585.48,290.86c-3.63-1.41-6.61-4-9.64-6.48a123.75,123.75,0,0,0-28.07-17"/>
            <path class="cls-6" d="M584.85,283.65a169,169,0,0,0-21.24-14.79c-5-2.92-10.07-5.58-15.21-8.18"/>
            <path class="cls-6" d="M584.66,277.15c-.49.48-1.3,0-1.79-.52A89.05,89.05,0,0,0,552.46,257"/>
            <path class="cls-6" d="M584.9,270.8a71.63,71.63,0,0,0-14.24-11.61"/>
            <path class="cls-6" d="M585.5,265.3l-3.91-3.91"/>
            <path class="cls-6" d="M515.52,297.94l-5-1.12"/>
            <path class="cls-6" d="M533.6,297.65a242.64,242.64,0,0,1-24-4"/>
            <path class="cls-6" d="M546.28,297.28q-13.35-4.08-26.91-7.37c-2.9-.7-5.92-1.39-8.86-.87"/>
            <path class="cls-6" d="M547.23,293.46A194.62,194.62,0,0,0,510.64,285"/>
            <path class="cls-6" d="M547.3,288.85c-7.73-.91-15.16-3.44-22.73-5.25a122.88,122.88,0,0,0-13-2.36"/>
            <path class="cls-6" d="M546.86,284.62c-12.27-1.31-23.53-8.95-35.86-8.43"/>
            <path class="cls-6" d="M546.36,280.2c-3.37.36-6.62-1.13-9.76-2.42A92.1,92.1,0,0,0,511,271.39"/>
            <path class="cls-6" d="M546.3,275.69q-15.93-4.4-32.06-8a6.77,6.77,0,0,0-3.78-.11"/>
            <path class="cls-6" d="M545.88,272.38c-2.29.43-4.54-.74-6.64-1.76A66.81,66.81,0,0,0,513,263.9"/>
            <path class="cls-6" d="M546.31,268.46l-5.23-1.79c-9.61-3.28-19.37-6.59-29.5-7.1"/>
            <path class="cls-6" d="M546.38,264.68c-5.11-2.14-10-4.83-15.15-6.79s-10.82-3.16-16.26-2.15"/>
            <path class="cls-6" d="M510.58,273.14,506,262.74"/>
            <path class="cls-6" d="M510.25,291.2q-6.13-16.12-13.14-31.88"/>
            <path class="cls-6" d="M508.64,301.53q-9-21-19-41.66"/>
            <path class="cls-6" d="M501.51,299.94c-5.86-8.4-9.48-18.12-13.05-27.72l-4.44-12"/>
            <path class="cls-6" d="M493.56,300.72l-13.93-32.1c-1.27-2.92-2.59-5.91-4.79-8.21"/>
            <path class="cls-6" d="M488.21,300a217.19,217.19,0,0,1-15.14-29.36"/>
            <path class="cls-6" d="M480.88,299a98.6,98.6,0,0,1-9-16"/>
            <path class="cls-6" d="M442.53,300.06l-6.75-7.92"/>
            <path class="cls-6" d="M450.22,298.37A56.66,56.66,0,0,0,438.05,285"/>
            <path class="cls-6" d="M458.18,298.12Q447.41,285.91,436.31,274"/>
            <path class="cls-6" d="M465.82,299.85a273.14,273.14,0,0,0-28.95-32.33"/>
            <path class="cls-6" d="M472.11,300.53c-9.81-14.16-22.06-26.43-34.24-38.62"/>
            <path class="cls-6" d="M472.11,291.59A264.14,264.14,0,0,0,444.45,260"/>
            <path class="cls-6" d="M471.68,282.25A212.71,212.71,0,0,0,452.24,259"/>
            <path class="cls-6" d="M472.42,275.07l-10.78-14.68"/>
            <path class="cls-6" d="M471.41,260.89l.49,1"/>
            <path class="cls-6" d="M403.82,300.09l-3.93-3.4"/>
            <path class="cls-6" d="M416.37,299.94l-17.47-9.59"/>
            <path class="cls-6" d="M427.3,299.16a237.65,237.65,0,0,0-26.93-14.66"/>
            <path class="cls-6" d="M437.17,299.31c-13.23-6.84-24.14-18.42-38.55-22.16"/>
            <path class="cls-6" d="M436.62,290.86c-3.62-1.41-6.61-4-9.63-6.48a124,124,0,0,0-28.08-17"/>
            <path class="cls-6" d="M436,283.65a169,169,0,0,0-21.24-14.79c-5-2.92-10.07-5.58-15.21-8.18"/>
            <path class="cls-6" d="M435.81,277.15c-.5.48-1.31,0-1.8-.52A89.11,89.11,0,0,0,403.61,257"/>
            <path class="cls-6" d="M436.05,270.8a72,72,0,0,0-14.25-11.61"/>
            <path class="cls-6" d="M436.65,265.3l-3.92-3.91"/>
            <path class="cls-6" d="M366.66,297.94l-5-1.12"/>
            <path class="cls-6" d="M384.74,297.65q-12.08-1.41-24-4"/>
            <path class="cls-6" d="M397.42,297.28q-13.34-4.08-26.91-7.37c-2.9-.7-5.91-1.39-8.86-.87"/>
            <path class="cls-6" d="M398.37,293.46A194.44,194.44,0,0,0,361.78,285"/>
            <path class="cls-6" d="M398.44,288.85c-7.73-.91-15.16-3.44-22.73-5.25a122.88,122.88,0,0,0-13-2.36"/>
            <path class="cls-6" d="M398,284.62c-12.27-1.31-23.53-8.95-35.86-8.43"/>
            <path class="cls-6" d="M397.5,280.2c-3.37.36-6.62-1.13-9.75-2.42a92.25,92.25,0,0,0-25.58-6.39"/>
            <path class="cls-6" d="M397.44,275.69q-15.93-4.4-32.06-8a6.77,6.77,0,0,0-3.78-.11"/>
            <path class="cls-6" d="M397,272.38c-2.29.43-4.54-.74-6.63-1.76a66.9,66.9,0,0,0-26.24-6.72"/>
            <path class="cls-6" d="M397.45,268.46l-5.23-1.79c-9.61-3.28-19.36-6.59-29.5-7.1"/>
            <path class="cls-6" d="M397.52,264.68c-5.11-2.14-10-4.83-15.15-6.79s-10.82-3.16-16.26-2.15"/>
            <path class="cls-6" d="M361.72,273.14l-4.63-10.4"/>
            <path class="cls-6" d="M361.39,291.2q-6.13-16.12-13.14-31.88"/>
            <path class="cls-6" d="M359.78,301.53q-9-21-19-41.66"/>
            <path class="cls-6" d="M352.65,299.94c-5.86-8.4-9.48-18.12-13-27.72l-4.44-12"/>
            <path class="cls-6" d="M344.7,300.72l-13.93-32.1c-1.27-2.92-2.59-5.91-4.79-8.21"/>
            <path class="cls-6" d="M339.36,300a216.11,216.11,0,0,1-15.15-29.36"/>
            <path class="cls-6" d="M332,299a98.6,98.6,0,0,1-9-16"/>
            <path class="cls-6" d="M293.67,300.06l-6.75-7.92"/>
            <path class="cls-6" d="M301.36,298.37A56.66,56.66,0,0,0,289.19,285"/>
            <path class="cls-6" d="M309.32,298.12Q298.55,285.91,287.45,274"/>
            <path class="cls-6" d="M317,299.85A273.14,273.14,0,0,0,288,267.52"/>
            <path class="cls-6" d="M323.25,300.53c-9.81-14.16-22.06-26.43-34.24-38.62"/>
            <path class="cls-6" d="M323.25,291.59A264.14,264.14,0,0,0,295.59,260"/>
            <path class="cls-6" d="M322.82,282.25A210.47,210.47,0,0,0,303.38,259"/>
            <path class="cls-6" d="M323.57,275.07l-10.79-14.68"/>
            <path class="cls-6" d="M322.56,260.89l.49,1"/>
            <path class="cls-6" d="M255,300.09l-3.93-3.4"/>
            <path class="cls-6" d="M267.52,299.94l-17.47-9.59"/>
            <path class="cls-6" d="M278.44,299.16a237.65,237.65,0,0,0-26.93-14.66"/>
            <path class="cls-6" d="M288.31,299.31c-13.22-6.84-24.14-18.42-38.55-22.16"/>
            <path class="cls-6" d="M287.76,290.86c-3.62-1.41-6.61-4-9.63-6.48a124,124,0,0,0-28.08-17"/>
            <path class="cls-6" d="M287.13,283.65a168.29,168.29,0,0,0-21.24-14.79c-5-2.92-10.07-5.58-15.21-8.18"/>
            <path class="cls-6" d="M287,277.15c-.5.48-1.31,0-1.8-.52A89.11,89.11,0,0,0,254.75,257"/>
            <path class="cls-6" d="M287.19,270.8a72,72,0,0,0-14.25-11.61"/>
            <path class="cls-6" d="M287.79,265.3l-3.92-3.91"/>
            <path class="cls-6" d="M217.81,297.94l-5-1.12"/>
            <path class="cls-6" d="M235.88,297.65q-12.07-1.41-24-4"/>
            <path class="cls-6" d="M248.56,297.28q-13.34-4.08-26.9-7.37c-2.91-.7-5.92-1.39-8.87-.87"/>
            <path class="cls-6" d="M249.52,293.46a194.72,194.72,0,0,0-36.6-8.45"/>
            <path class="cls-6" d="M249.59,288.85c-7.73-.91-15.17-3.44-22.74-5.25a122.42,122.42,0,0,0-13-2.36"/>
            <path class="cls-6" d="M249.15,284.62c-12.27-1.31-23.54-8.95-35.87-8.43"/>
            <path class="cls-6" d="M248.65,280.2c-3.37.36-6.63-1.13-9.76-2.42a92.25,92.25,0,0,0-25.58-6.39"/>
            <path class="cls-6" d="M248.58,275.69q-15.93-4.4-32.06-8a6.77,6.77,0,0,0-3.78-.11"/>
            <path class="cls-6" d="M248.16,272.38c-2.29.43-4.54-.74-6.63-1.76a66.9,66.9,0,0,0-26.24-6.72"/>
            <path class="cls-6" d="M248.59,268.46l-5.23-1.79c-9.6-3.28-19.36-6.59-29.5-7.1"/>
            <path class="cls-6" d="M248.66,264.68c-5.1-2.14-10-4.83-15.15-6.79s-10.81-3.16-16.26-2.15"/>
            <path class="cls-6" d="M212.86,273.14l-4.62-10.4"/>
            <path class="cls-6" d="M212.53,291.2q-6.12-16.12-13.13-31.88"/>
            <path class="cls-6" d="M210.92,301.53q-9-21-19-41.66"/>
            <path class="cls-6" d="M203.79,299.94c-5.86-8.4-9.48-18.12-13-27.72l-4.44-12"/>
            <path class="cls-6" d="M195.84,300.72l-13.93-32.1c-1.27-2.92-2.58-5.91-4.79-8.21"/>
            <path class="cls-6" d="M190.5,300a217.25,217.25,0,0,1-15.15-29.36"/>
            <path class="cls-6" d="M183.17,299a97.91,97.91,0,0,1-9-16"/>
            <path class="cls-6" d="M144.81,300.06l-6.75-7.92"/>
            <path class="cls-6" d="M152.5,298.37A56.45,56.45,0,0,0,140.33,285"/>
            <path class="cls-6" d="M160.46,298.12Q149.69,285.91,138.59,274"/>
            <path class="cls-6" d="M168.11,299.85a274.11,274.11,0,0,0-29-32.33"/>
            <path class="cls-6" d="M174.39,300.53c-9.81-14.16-22.06-26.43-34.24-38.62"/>
            <path class="cls-6" d="M174.39,291.59A264.14,264.14,0,0,0,146.73,260"/>
            <path class="cls-6" d="M174,282.25A211.5,211.5,0,0,0,154.53,259"/>
            <path class="cls-6" d="M174.71,275.07l-10.79-14.68"/>
            <path class="cls-6" d="M173.7,260.89l.49,1"/>
            <path class="cls-6" d="M106.1,300.09l-3.93-3.4"/>
            <path class="cls-6" d="M118.66,299.94l-17.47-9.59"/>
            <path class="cls-6" d="M129.58,299.16a237.65,237.65,0,0,0-26.93-14.66"/>
            <path class="cls-6" d="M139.45,299.31c-13.22-6.84-24.14-18.42-38.55-22.16"/>
            <path class="cls-6" d="M138.9,290.86c-3.62-1.41-6.61-4-9.63-6.48a124,124,0,0,0-28.08-17"/>
            <path class="cls-6" d="M138.27,283.65A167.57,167.57,0,0,0,117,268.86c-5-2.92-10.07-5.58-15.21-8.18"/>
            <path class="cls-6" d="M138.09,277.15c-.5.48-1.3,0-1.8-.52A89.11,89.11,0,0,0,105.89,257"/>
            <path class="cls-6" d="M138.33,270.8a72.2,72.2,0,0,0-14.24-11.61"/>
            <path class="cls-6" d="M138.93,265.3,135,261.39"/>
            <path class="cls-6" d="M69,297.94l-5-1.12"/>
            <path class="cls-6" d="M87,297.65q-12.08-1.41-24-4"/>
            <path class="cls-6" d="M99.7,297.28q-13.33-4.08-26.9-7.37c-2.91-.7-5.92-1.39-8.87-.87"/>
            <path class="cls-6" d="M100.66,293.46A194.72,194.72,0,0,0,64.06,285"/>
            <path class="cls-6" d="M100.73,288.85C93,287.94,85.57,285.41,78,283.6a122.42,122.42,0,0,0-13-2.36"/>
            <path class="cls-6" d="M100.29,284.62c-12.27-1.31-23.54-8.95-35.86-8.43"/>
            <path class="cls-6" d="M99.79,280.2c-3.37.36-6.62-1.13-9.76-2.42a92.16,92.16,0,0,0-25.58-6.39"/>
            <path class="cls-6" d="M99.73,275.69q-15.95-4.4-32.06-8a6.81,6.81,0,0,0-3.79-.11"/>
            <path class="cls-6" d="M99.31,272.38c-2.3.43-4.55-.74-6.64-1.76a66.81,66.81,0,0,0-26.23-6.72"/>
            <path class="cls-6" d="M99.73,268.46l-5.23-1.79c-9.6-3.28-19.36-6.59-29.5-7.1"/>
            <path class="cls-6" d="M99.8,264.68c-5.1-2.14-10-4.83-15.15-6.79s-10.81-3.16-16.26-2.15"/>
            <path class="cls-6" d="M63.05,326.71l4.63,10.4"/>
            <path class="cls-6" d="M63.39,308.65q6.12,16.13,13.13,31.88"/>
            <path class="cls-6" d="M65,298.32q9,21,19,41.66"/>
            <path class="cls-6" d="M72.13,299.91c5.85,8.41,9.48,18.13,13,27.73l4.44,11.95"/>
            <path class="cls-6" d="M80.07,299.13,94,331.23c1.27,2.92,2.58,5.91,4.79,8.22"/>
            <path class="cls-6" d="M85.42,299.88a218.4,218.4,0,0,1,15.15,29.36"/>
            <path class="cls-6" d="M92.75,300.88a98.14,98.14,0,0,1,9,16"/>
            <path class="cls-6" d="M131.1,299.79l6.75,7.92"/>
            <path class="cls-6" d="M123.42,301.48a56.43,56.43,0,0,0,12.16,13.4"/>
            <path class="cls-6" d="M115.45,301.73q10.78,12.21,21.87,24.15"/>
            <path class="cls-6" d="M107.81,300a274.91,274.91,0,0,0,28.95,32.33"/>
            <path class="cls-6" d="M101.52,299.32c9.82,14.16,22.07,26.43,34.25,38.62"/>
            <path class="cls-6" d="M101.52,308.26a265.12,265.12,0,0,0,27.67,31.64"/>
            <path class="cls-6" d="M102,317.6a212.71,212.71,0,0,0,19.44,23.24"/>
            <path class="cls-6" d="M101.21,324.78,112,339.46"/>
            <path class="cls-6" d="M102.22,339l-.49-1"/>
            <path class="cls-6" d="M169.81,299.76l3.94,3.41"/>
            <path class="cls-6" d="M157.26,299.91l17.47,9.59"/>
            <path class="cls-6" d="M146.33,300.69a240.32,240.32,0,0,0,26.94,14.67"/>
            <path class="cls-6" d="M136.47,300.54C149.69,307.38,160.6,319,175,322.71"/>
            <path class="cls-6" d="M137,309c3.62,1.4,6.6,4,9.63,6.47a124.59,124.59,0,0,0,28.07,17"/>
            <path class="cls-6" d="M137.64,316.21A168.94,168.94,0,0,0,158.88,331c5,2.92,10.08,5.58,15.21,8.18"/>
            <path class="cls-6" d="M137.83,322.71c.5-.49,1.3,0,1.8.51A88.77,88.77,0,0,0,170,342.84"/>
            <path class="cls-6" d="M137.59,329.06a72,72,0,0,0,14.24,11.61"/>
            <path class="cls-6" d="M137,334.55l3.91,3.92"/>
            <path class="cls-6" d="M207,301.91l5,1.12"/>
            <path class="cls-6" d="M188.89,302.2a242.67,242.67,0,0,1,24,4"/>
            <path class="cls-6" d="M176.22,302.57q13.34,4.08,26.9,7.37c2.91.71,5.92,1.39,8.86.87"/>
            <path class="cls-6" d="M175.26,306.4a193.74,193.74,0,0,0,36.6,8.44"/>
            <path class="cls-6" d="M175.19,311c7.73.91,15.16,3.45,22.73,5.25A120.17,120.17,0,0,0,211,318.62"/>
            <path class="cls-6" d="M175.63,315.23c12.27,1.31,23.53,8.95,35.86,8.43"/>
            <path class="cls-6" d="M176.13,319.65c3.37-.36,6.62,1.13,9.76,2.42a91.47,91.47,0,0,0,25.58,6.39"/>
            <path class="cls-6" d="M176.19,324.17q15.93,4.38,32.06,8a6.68,6.68,0,0,0,3.78.11"/>
            <path class="cls-6" d="M176.61,327.47c2.29-.43,4.54.74,6.64,1.77A67,67,0,0,0,209.48,336"/>
            <path class="cls-6" d="M176.18,331.39l5.24,1.79c9.6,3.28,19.36,6.59,29.49,7.1"/>
            <path class="cls-6" d="M176.11,335.17c5.11,2.14,10,4.83,15.15,6.79s10.82,3.16,16.26,2.15"/>
            <path class="cls-6" d="M211.91,326.71l4.63,10.4"/>
            <path class="cls-6" d="M212.25,308.65q6.12,16.13,13.13,31.88"/>
            <path class="cls-6" d="M213.85,298.32q9,21,19,41.66"/>
            <path class="cls-6" d="M221,299.91c5.86,8.41,9.49,18.13,13.05,27.73l4.44,11.95"/>
            <path class="cls-6" d="M228.93,299.13l13.94,32.1c1.27,2.92,2.58,5.91,4.79,8.22"/>
            <path class="cls-6" d="M234.28,299.88a217.19,217.19,0,0,1,15.14,29.36"/>
            <path class="cls-6" d="M241.61,300.88a98.09,98.09,0,0,1,9,16"/>
            <path class="cls-6" d="M280,299.79l6.75,7.92"/>
            <path class="cls-6" d="M272.27,301.48a56.47,56.47,0,0,0,12.17,13.4"/>
            <path class="cls-6" d="M264.31,301.73q10.79,12.21,21.87,24.15"/>
            <path class="cls-6" d="M256.67,300a274.91,274.91,0,0,0,28.95,32.33"/>
            <path class="cls-6" d="M250.38,299.32c9.82,14.16,22.07,26.43,34.24,38.62"/>
            <path class="cls-6" d="M250.38,308.26A265.93,265.93,0,0,0,278,339.9"/>
            <path class="cls-6" d="M250.81,317.6a212.71,212.71,0,0,0,19.44,23.24"/>
            <path class="cls-6" d="M250.07,324.78l10.78,14.68"/>
            <path class="cls-6" d="M251.08,339l-.49-1"/>
            <path class="cls-6" d="M318.67,299.76l3.94,3.41"/>
            <path class="cls-6" d="M306.12,299.91l17.47,9.59"/>
            <path class="cls-6" d="M295.19,300.69a240.19,240.19,0,0,0,26.93,14.67"/>
            <path class="cls-6" d="M285.33,300.54c13.22,6.84,24.13,18.43,38.54,22.17"/>
            <path class="cls-6" d="M285.87,309c3.63,1.4,6.61,4,9.64,6.47a124.15,124.15,0,0,0,28.07,17"/>
            <path class="cls-6" d="M286.5,316.21A168.94,168.94,0,0,0,307.74,331c5,2.92,10.07,5.58,15.21,8.18"/>
            <path class="cls-6" d="M286.69,322.71c.49-.49,1.3,0,1.79.51a88.91,88.91,0,0,0,30.41,19.62"/>
            <path class="cls-6" d="M286.45,329.06a72,72,0,0,0,14.24,11.61"/>
            <path class="cls-6" d="M285.85,334.55l3.91,3.92"/>
            <path class="cls-6" d="M355.83,301.91l5,1.12"/>
            <path class="cls-6" d="M337.75,302.2a242.67,242.67,0,0,1,24,4"/>
            <path class="cls-6" d="M325.07,302.57q13.35,4.08,26.91,7.37c2.9.71,5.92,1.39,8.86.87"/>
            <path class="cls-6" d="M324.12,306.4a193.82,193.82,0,0,0,36.59,8.44"/>
            <path class="cls-6" d="M324.05,311c7.73.91,15.16,3.45,22.73,5.25a120.43,120.43,0,0,0,13,2.37"/>
            <path class="cls-6" d="M324.49,315.23c12.27,1.31,23.53,8.95,35.86,8.43"/>
            <path class="cls-6" d="M325,319.65c3.37-.36,6.62,1.13,9.76,2.42a91.4,91.4,0,0,0,25.57,6.39"/>
            <path class="cls-6" d="M325.05,324.17q15.93,4.38,32.06,8a6.68,6.68,0,0,0,3.78.11"/>
            <path class="cls-6" d="M325.47,327.47c2.29-.43,4.54.74,6.63,1.77A67.07,67.07,0,0,0,358.34,336"/>
            <path class="cls-6" d="M325,331.39l5.23,1.79c9.61,3.28,19.37,6.59,29.5,7.1"/>
            <path class="cls-6" d="M325,335.17c5.11,2.14,10,4.83,15.15,6.79s10.82,3.16,16.26,2.15"/>
            <path class="cls-6" d="M360.77,326.71l4.63,10.4"/>
            <path class="cls-6" d="M361.1,308.65q6.13,16.13,13.14,31.88"/>
            <path class="cls-6" d="M362.71,298.32q9,21,19,41.66"/>
            <path class="cls-6" d="M369.84,299.91c5.86,8.41,9.48,18.13,13.05,27.73l4.44,11.95"/>
            <path class="cls-6" d="M377.79,299.13l13.93,32.1c1.27,2.92,2.59,5.91,4.79,8.22"/>
            <path class="cls-6" d="M383.14,299.88a217.19,217.19,0,0,1,15.14,29.36"/>
            <path class="cls-6" d="M390.47,300.88a98.09,98.09,0,0,1,9,16"/>
            <path class="cls-6" d="M428.82,299.79l6.75,7.92"/>
            <path class="cls-6" d="M421.13,301.48a56.47,56.47,0,0,0,12.17,13.4"/>
            <path class="cls-6" d="M413.17,301.73q10.77,12.21,21.87,24.15"/>
            <path class="cls-6" d="M405.53,300a273.14,273.14,0,0,0,29,32.33"/>
            <path class="cls-6" d="M399.24,299.32c9.81,14.16,22.06,26.43,34.24,38.62"/>
            <path class="cls-6" d="M399.24,308.26A264.14,264.14,0,0,0,426.9,339.9"/>
            <path class="cls-6" d="M399.67,317.6a212.71,212.71,0,0,0,19.44,23.24"/>
            <path class="cls-6" d="M398.93,324.78l10.78,14.68"/>
            <path class="cls-6" d="M399.94,339l-.49-1"/>
            <path class="cls-6" d="M467.53,299.76l3.93,3.41"/>
            <path class="cls-6" d="M455,299.91l17.47,9.59"/>
            <path class="cls-6" d="M444.05,300.69A239.31,239.31,0,0,0,471,315.36"/>
            <path class="cls-6" d="M434.18,300.54c13.23,6.84,24.14,18.43,38.55,22.17"/>
            <path class="cls-6" d="M434.73,309c3.62,1.4,6.61,4,9.63,6.47a124.44,124.44,0,0,0,28.08,17"/>
            <path class="cls-6" d="M435.36,316.21A168.94,168.94,0,0,0,456.6,331c5,2.92,10.07,5.58,15.21,8.18"/>
            <path class="cls-6" d="M435.54,322.71c.5-.49,1.31,0,1.8.51a89,89,0,0,0,30.4,19.62"/>
            <path class="cls-6" d="M435.3,329.06a72.33,72.33,0,0,0,14.25,11.61"/>
            <path class="cls-6" d="M434.7,334.55l3.92,3.92"/>
            <path class="cls-6" d="M504.69,301.91l5,1.12"/>
            <path class="cls-6" d="M486.61,302.2q12.08,1.43,24,4"/>
            <path class="cls-6" d="M473.93,302.57q13.33,4.08,26.91,7.37c2.9.71,5.91,1.39,8.86.87"/>
            <path class="cls-6" d="M473,306.4a193.64,193.64,0,0,0,36.59,8.44"/>
            <path class="cls-6" d="M472.91,311c7.73.91,15.16,3.45,22.73,5.25a120.43,120.43,0,0,0,13,2.37"/>
            <path class="cls-6" d="M473.35,315.23c12.27,1.31,23.53,8.95,35.86,8.43"/>
            <path class="cls-6" d="M473.85,319.65c3.37-.36,6.62,1.13,9.75,2.42a91.56,91.56,0,0,0,25.58,6.39"/>
            <path class="cls-6" d="M473.91,324.17q15.93,4.38,32.06,8a6.68,6.68,0,0,0,3.78.11"/>
            <path class="cls-6" d="M474.33,327.47c2.29-.43,4.54.74,6.63,1.77A67.07,67.07,0,0,0,507.2,336"/>
            <path class="cls-6" d="M473.9,331.39l5.23,1.79c9.61,3.28,19.36,6.59,29.5,7.1"/>
            <path class="cls-6" d="M473.83,335.17c5.11,2.14,10,4.83,15.15,6.79s10.82,3.16,16.26,2.15"/>
            <path class="cls-6" d="M509.63,326.71l4.63,10.4"/>
            <path class="cls-6" d="M510,308.65q6.13,16.13,13.14,31.88"/>
            <path class="cls-6" d="M511.57,298.32q9,21,19,41.66"/>
            <path class="cls-6" d="M518.7,299.91c5.86,8.41,9.48,18.13,13,27.73l4.44,11.95"/>
            <path class="cls-6" d="M526.65,299.13l13.93,32.1c1.27,2.92,2.59,5.91,4.79,8.22"/>
            <path class="cls-6" d="M532,299.88a216.11,216.11,0,0,1,15.15,29.36"/>
            <path class="cls-6" d="M539.33,300.88a98.09,98.09,0,0,1,9,16"/>
            <path class="cls-6" d="M577.68,299.79l6.75,7.92"/>
            <path class="cls-6" d="M570,301.48a56.47,56.47,0,0,0,12.17,13.4"/>
            <path class="cls-6" d="M562,301.73q10.77,12.21,21.87,24.15"/>
            <path class="cls-6" d="M554.39,300a273.14,273.14,0,0,0,29,32.33"/>
            <path class="cls-6" d="M548.1,299.32c9.81,14.16,22.06,26.43,34.24,38.62"/>
            <path class="cls-6" d="M548.1,308.26a264.14,264.14,0,0,0,27.66,31.64"/>
            <path class="cls-6" d="M548.53,317.6A210.47,210.47,0,0,0,568,340.84"/>
            <path class="cls-6" d="M547.78,324.78l10.79,14.68"/>
            <path class="cls-6" d="M548.79,339l-.49-1"/>
            <path class="cls-6" d="M616.39,299.76l3.93,3.41"/>
            <path class="cls-6" d="M603.83,299.91l17.47,9.59"/>
            <path class="cls-6" d="M592.91,300.69a239.31,239.31,0,0,0,26.93,14.67"/>
            <path class="cls-6" d="M583,300.54c13.22,6.84,24.14,18.43,38.55,22.17"/>
            <path class="cls-6" d="M583.59,309c3.62,1.4,6.61,4,9.63,6.47a124.44,124.44,0,0,0,28.08,17"/>
            <path class="cls-6" d="M584.22,316.21A168.22,168.22,0,0,0,605.46,331c5,2.92,10.07,5.58,15.21,8.18"/>
            <path class="cls-6" d="M584.4,322.71c.5-.49,1.31,0,1.8.51a89,89,0,0,0,30.4,19.62"/>
            <path class="cls-6" d="M584.16,329.06a72.33,72.33,0,0,0,14.25,11.61"/>
            <path class="cls-6" d="M583.56,334.55l3.92,3.92"/>
            <path class="cls-6" d="M653.54,301.91l5,1.12"/>
            <path class="cls-6" d="M635.47,302.2q12.07,1.43,24,4"/>
            <path class="cls-6" d="M622.79,302.57q13.34,4.08,26.9,7.37c2.91.71,5.92,1.39,8.87.87"/>
            <path class="cls-6" d="M621.83,306.4a193.92,193.92,0,0,0,36.6,8.44"/>
            <path class="cls-6" d="M621.76,311c7.73.91,15.17,3.45,22.74,5.25a120,120,0,0,0,13,2.37"/>
            <path class="cls-6" d="M622.2,315.23c12.27,1.31,23.54,8.95,35.87,8.43"/>
            <path class="cls-6" d="M622.7,319.65c3.37-.36,6.63,1.13,9.76,2.42A91.56,91.56,0,0,0,658,328.46"/>
            <path class="cls-6" d="M622.77,324.17q15.93,4.38,32.06,8a6.68,6.68,0,0,0,3.78.11"/>
            <path class="cls-6" d="M623.19,327.47c2.29-.43,4.54.74,6.63,1.77A67.07,67.07,0,0,0,656.06,336"/>
            <path class="cls-6" d="M622.76,331.39l5.23,1.79c9.6,3.28,19.36,6.59,29.5,7.1"/>
            <path class="cls-6" d="M622.69,335.17c5.1,2.14,10,4.83,15.15,6.79s10.81,3.16,16.26,2.15"/>
            <path class="cls-6" d="M659.44,357.34l-4.63-10.4"/>
            <path class="cls-6" d="M659.1,375.4Q653,359.28,646,343.52"/>
            <path class="cls-6" d="M657.5,385.73q-9-21-19-41.66"/>
            <path class="cls-6" d="M650.37,384.14c-5.86-8.4-9.49-18.12-13-27.72l-4.44-12"/>
            <path class="cls-6" d="M642.42,384.92l-13.94-32.1c-1.27-2.92-2.58-5.91-4.79-8.21"/>
            <path class="cls-6" d="M637.07,384.17a218.4,218.4,0,0,1-15.15-29.36"/>
            <path class="cls-6" d="M629.74,383.18a99.4,99.4,0,0,1-9-16"/>
            <path class="cls-6" d="M591.39,384.26l-6.75-7.92"/>
            <path class="cls-6" d="M599.08,382.57a56.66,56.66,0,0,0-12.17-13.4"/>
            <path class="cls-6" d="M607,382.32q-10.78-12.21-21.87-24.15"/>
            <path class="cls-6" d="M614.68,384.05a274.91,274.91,0,0,0-28.95-32.33"/>
            <path class="cls-6" d="M621,384.73c-9.82-14.16-22.07-26.43-34.24-38.62"/>
            <path class="cls-6" d="M621,375.79a265.12,265.12,0,0,0-27.67-31.64"/>
            <path class="cls-6" d="M620.54,366.45a212.71,212.71,0,0,0-19.44-23.24"/>
            <path class="cls-6" d="M621.28,359.27,610.5,344.59"/>
            <path class="cls-6" d="M620.27,345.09l.49,1"/>
            <path class="cls-6" d="M552.68,384.29l-3.94-3.41"/>
            <path class="cls-6" d="M565.23,384.14l-17.47-9.59"/>
            <path class="cls-6" d="M576.16,383.36a238.51,238.51,0,0,0-26.93-14.66"/>
            <path class="cls-6" d="M586,383.51c-13.22-6.84-24.13-18.42-38.54-22.16"/>
            <path class="cls-6" d="M585.48,375.06c-3.63-1.41-6.61-4-9.64-6.48a123.75,123.75,0,0,0-28.07-17"/>
            <path class="cls-6" d="M584.85,367.85a169,169,0,0,0-21.24-14.79c-5-2.92-10.07-5.58-15.21-8.18"/>
            <path class="cls-6" d="M584.66,361.35c-.49.48-1.3,0-1.79-.52a89.05,89.05,0,0,0-30.41-19.62"/>
            <path class="cls-6" d="M584.9,355a71.63,71.63,0,0,0-14.24-11.61"/>
            <path class="cls-6" d="M585.5,349.5l-3.91-3.91"/>
            <path class="cls-6" d="M515.52,382.14l-5-1.12"/>
            <path class="cls-6" d="M533.6,381.85a242.64,242.64,0,0,1-24-4"/>
            <path class="cls-6" d="M546.28,381.48q-13.35-4.08-26.91-7.37c-2.9-.71-5.92-1.39-8.86-.87"/>
            <path class="cls-6" d="M547.23,377.66a194.62,194.62,0,0,0-36.59-8.45"/>
            <path class="cls-6" d="M547.3,373.05c-7.73-.91-15.16-3.44-22.73-5.25a122.88,122.88,0,0,0-13-2.36"/>
            <path class="cls-6" d="M546.86,368.82c-12.27-1.31-23.53-8.95-35.86-8.43"/>
            <path class="cls-6" d="M546.36,364.4c-3.37.36-6.62-1.13-9.76-2.42A92.1,92.1,0,0,0,511,355.59"/>
            <path class="cls-6" d="M546.3,359.89q-15.93-4.39-32.06-8a6.77,6.77,0,0,0-3.78-.11"/>
            <path class="cls-6" d="M545.88,356.58c-2.29.43-4.54-.74-6.64-1.76A66.81,66.81,0,0,0,513,348.1"/>
            <path class="cls-6" d="M546.31,352.66l-5.23-1.79c-9.61-3.28-19.37-6.59-29.5-7.1"/>
            <path class="cls-6" d="M546.38,348.88c-5.11-2.14-10-4.83-15.15-6.79s-10.82-3.16-16.26-2.15"/>
            <path class="cls-6" d="M510.58,357.34,506,346.94"/>
            <path class="cls-6" d="M510.25,375.4q-6.13-16.12-13.14-31.88"/>
            <path class="cls-6" d="M508.64,385.73q-9-21-19-41.66"/>
            <path class="cls-6" d="M501.51,384.14c-5.86-8.4-9.48-18.12-13.05-27.72l-4.44-12"/>
            <path class="cls-6" d="M493.56,384.92l-13.93-32.1c-1.27-2.92-2.59-5.91-4.79-8.21"/>
            <path class="cls-6" d="M488.21,384.17a217.19,217.19,0,0,1-15.14-29.36"/>
            <path class="cls-6" d="M480.88,383.18a98.6,98.6,0,0,1-9-16"/>
            <path class="cls-6" d="M442.53,384.26l-6.75-7.92"/>
            <path class="cls-6" d="M450.22,382.57a56.66,56.66,0,0,0-12.17-13.4"/>
            <path class="cls-6" d="M458.18,382.32q-10.77-12.21-21.87-24.15"/>
            <path class="cls-6" d="M465.82,384.05a273.14,273.14,0,0,0-28.95-32.33"/>
            <path class="cls-6" d="M472.11,384.73c-9.81-14.16-22.06-26.43-34.24-38.62"/>
            <path class="cls-6" d="M472.11,375.79a264.14,264.14,0,0,0-27.66-31.64"/>
            <path class="cls-6" d="M471.68,366.45a212.71,212.71,0,0,0-19.44-23.24"/>
            <path class="cls-6" d="M472.42,359.27l-10.78-14.68"/>
            <path class="cls-6" d="M471.41,345.09l.49,1"/>
            <path class="cls-6" d="M403.82,384.29l-3.93-3.41"/>
            <path class="cls-6" d="M416.37,384.14l-17.47-9.59"/>
            <path class="cls-6" d="M427.3,383.36a237.65,237.65,0,0,0-26.93-14.66"/>
            <path class="cls-6" d="M437.17,383.51c-13.23-6.84-24.14-18.42-38.55-22.16"/>
            <path class="cls-6" d="M436.62,375.06c-3.62-1.41-6.61-4-9.63-6.48a124,124,0,0,0-28.08-17"/>
            <path class="cls-6" d="M436,367.85a169,169,0,0,0-21.24-14.79c-5-2.92-10.07-5.58-15.21-8.18"/>
            <path class="cls-6" d="M435.81,361.35c-.5.48-1.31,0-1.8-.52a89.11,89.11,0,0,0-30.4-19.62"/>
            <path class="cls-6" d="M436.05,355a72,72,0,0,0-14.25-11.61"/>
            <path class="cls-6" d="M436.65,349.5l-3.92-3.91"/>
            <path class="cls-6" d="M366.66,382.14l-5-1.12"/>
            <path class="cls-6" d="M384.74,381.85q-12.08-1.41-24-4"/>
            <path class="cls-6" d="M397.42,381.48q-13.34-4.08-26.91-7.37c-2.9-.71-5.91-1.39-8.86-.87"/>
            <path class="cls-6" d="M398.37,377.66a194.44,194.44,0,0,0-36.59-8.45"/>
            <path class="cls-6" d="M398.44,373.05c-7.73-.91-15.16-3.44-22.73-5.25a122.88,122.88,0,0,0-13-2.36"/>
            <path class="cls-6" d="M398,368.82c-12.27-1.31-23.53-8.95-35.86-8.43"/>
            <path class="cls-6" d="M397.5,364.4c-3.37.36-6.62-1.13-9.75-2.42a92.25,92.25,0,0,0-25.58-6.39"/>
            <path class="cls-6" d="M397.44,359.89q-15.93-4.39-32.06-8a6.77,6.77,0,0,0-3.78-.11"/>
            <path class="cls-6" d="M397,356.58c-2.29.43-4.54-.74-6.63-1.76a66.9,66.9,0,0,0-26.24-6.72"/>
            <path class="cls-6" d="M397.45,352.66l-5.23-1.79c-9.61-3.28-19.36-6.59-29.5-7.1"/>
            <path class="cls-6" d="M397.52,348.88c-5.11-2.14-10-4.83-15.15-6.79s-10.82-3.16-16.26-2.15"/>
            <path class="cls-6" d="M361.72,357.34l-4.63-10.4"/>
            <path class="cls-6" d="M361.39,375.4q-6.13-16.12-13.14-31.88"/>
            <path class="cls-6" d="M359.78,385.73q-9-21-19-41.66"/>
            <path class="cls-6" d="M352.65,384.14c-5.86-8.4-9.48-18.12-13-27.72l-4.44-12"/>
            <path class="cls-6" d="M344.7,384.92l-13.93-32.1c-1.27-2.92-2.59-5.91-4.79-8.21"/>
            <path class="cls-6" d="M339.36,384.17a216.11,216.11,0,0,1-15.15-29.36"/>
            <path class="cls-6" d="M332,383.18a98.6,98.6,0,0,1-9-16"/>
            <path class="cls-6" d="M293.67,384.26l-6.75-7.92"/>
            <path class="cls-6" d="M301.36,382.57a56.66,56.66,0,0,0-12.17-13.4"/>
            <path class="cls-6" d="M309.32,382.32q-10.77-12.21-21.87-24.15"/>
            <path class="cls-6" d="M317,384.05A273.14,273.14,0,0,0,288,351.72"/>
            <path class="cls-6" d="M323.25,384.73c-9.81-14.16-22.06-26.43-34.24-38.62"/>
            <path class="cls-6" d="M323.25,375.79a264.14,264.14,0,0,0-27.66-31.64"/>
            <path class="cls-6" d="M322.82,366.45a210.47,210.47,0,0,0-19.44-23.24"/>
            <path class="cls-6" d="M323.57,359.27l-10.79-14.68"/>
            <path class="cls-6" d="M322.56,345.09l.49,1"/>
            <path class="cls-6" d="M255,384.29,251,380.88"/>
            <path class="cls-6" d="M267.52,384.14l-17.47-9.59"/>
            <path class="cls-6" d="M278.44,383.36a237.65,237.65,0,0,0-26.93-14.66"/>
            <path class="cls-6" d="M288.31,383.51c-13.22-6.84-24.14-18.42-38.55-22.16"/>
            <path class="cls-6" d="M287.76,375.06c-3.62-1.41-6.61-4-9.63-6.48a124,124,0,0,0-28.08-17"/>
            <path class="cls-6" d="M287.13,367.85a168.29,168.29,0,0,0-21.24-14.79c-5-2.92-10.07-5.58-15.21-8.18"/>
            <path class="cls-6" d="M287,361.35c-.5.48-1.31,0-1.8-.52a89.11,89.11,0,0,0-30.4-19.62"/>
            <path class="cls-6" d="M287.19,355a72,72,0,0,0-14.25-11.61"/>
            <path class="cls-6" d="M287.79,349.5l-3.92-3.91"/>
            <path class="cls-6" d="M217.81,382.14l-5-1.12"/>
            <path class="cls-6" d="M235.88,381.85q-12.07-1.41-24-4"/>
            <path class="cls-6" d="M248.56,381.48q-13.34-4.08-26.9-7.37c-2.91-.71-5.92-1.39-8.87-.87"/>
            <path class="cls-6" d="M249.52,377.66a194.72,194.72,0,0,0-36.6-8.45"/>
            <path class="cls-6" d="M249.59,373.05c-7.73-.91-15.17-3.44-22.74-5.25a122.42,122.42,0,0,0-13-2.36"/>
            <path class="cls-6" d="M249.15,368.82c-12.27-1.31-23.54-8.95-35.87-8.43"/>
            <path class="cls-6" d="M248.65,364.4c-3.37.36-6.63-1.13-9.76-2.42a92.25,92.25,0,0,0-25.58-6.39"/>
            <path class="cls-6" d="M248.58,359.89q-15.93-4.39-32.06-8a6.77,6.77,0,0,0-3.78-.11"/>
            <path class="cls-6" d="M248.16,356.58c-2.29.43-4.54-.74-6.63-1.76a66.9,66.9,0,0,0-26.24-6.72"/>
            <path class="cls-6" d="M248.59,352.66l-5.23-1.79c-9.6-3.28-19.36-6.59-29.5-7.1"/>
            <path class="cls-6" d="M248.66,348.88c-5.1-2.14-10-4.83-15.15-6.79s-10.81-3.16-16.26-2.15"/>
            <path class="cls-6" d="M212.86,357.34l-4.62-10.4"/>
            <path class="cls-6" d="M212.53,375.4q-6.12-16.12-13.13-31.88"/>
            <path class="cls-6" d="M210.92,385.73q-9-21-19-41.66"/>
            <path class="cls-6" d="M203.79,384.14c-5.86-8.4-9.48-18.12-13-27.72l-4.44-12"/>
            <path class="cls-6" d="M195.84,384.92l-13.93-32.1c-1.27-2.92-2.58-5.91-4.79-8.21"/>
            <path class="cls-6" d="M190.5,384.17a217.25,217.25,0,0,1-15.15-29.36"/>
            <path class="cls-6" d="M183.17,383.18a97.91,97.91,0,0,1-9-16"/>
            <path class="cls-6" d="M144.81,384.26l-6.75-7.92"/>
            <path class="cls-6" d="M152.5,382.57a56.45,56.45,0,0,0-12.17-13.4"/>
            <path class="cls-6" d="M160.46,382.32q-10.77-12.21-21.87-24.15"/>
            <path class="cls-6" d="M168.11,384.05a274.11,274.11,0,0,0-29-32.33"/>
            <path class="cls-6" d="M174.39,384.73c-9.81-14.16-22.06-26.43-34.24-38.62"/>
            <path class="cls-6" d="M174.39,375.79a264.14,264.14,0,0,0-27.66-31.64"/>
            <path class="cls-6" d="M174,366.45a211.5,211.5,0,0,0-19.43-23.24"/>
            <path class="cls-6" d="M174.71,359.27l-10.79-14.68"/>
            <path class="cls-6" d="M173.7,345.09l.49,1"/>
            <path class="cls-6" d="M106.1,384.29l-3.93-3.41"/>
            <path class="cls-6" d="M118.66,384.14l-17.47-9.59"/>
            <path class="cls-6" d="M129.58,383.36a237.65,237.65,0,0,0-26.93-14.66"/>
            <path class="cls-6" d="M139.45,383.51c-13.22-6.84-24.14-18.42-38.55-22.16"/>
            <path class="cls-6" d="M138.9,375.06c-3.62-1.41-6.61-4-9.63-6.48a124,124,0,0,0-28.08-17"/>
            <path class="cls-6" d="M138.27,367.85A167.57,167.57,0,0,0,117,353.06c-5-2.92-10.07-5.58-15.21-8.18"/>
            <path class="cls-6" d="M138.09,361.35c-.5.48-1.3,0-1.8-.52a89.11,89.11,0,0,0-30.4-19.62"/>
            <path class="cls-6" d="M138.33,355a72.2,72.2,0,0,0-14.24-11.61"/>
            <path class="cls-6" d="M138.93,349.5,135,345.59"/>
            <path class="cls-6" d="M69,382.14,64,381"/>
            <path class="cls-6" d="M87,381.85q-12.08-1.41-24-4"/>
            <path class="cls-6" d="M99.7,381.48q-13.33-4.08-26.9-7.37c-2.91-.71-5.92-1.39-8.87-.87"/>
            <path class="cls-6" d="M100.66,377.66a194.72,194.72,0,0,0-36.6-8.45"/>
            <path class="cls-6" d="M100.73,373.05C93,372.14,85.57,369.61,78,367.8a122.42,122.42,0,0,0-13-2.36"/>
            <path class="cls-6" d="M100.29,368.82c-12.27-1.31-23.54-8.95-35.86-8.43"/>
            <path class="cls-6" d="M99.79,364.4c-3.37.36-6.62-1.13-9.76-2.42a92.16,92.16,0,0,0-25.58-6.39"/>
            <path class="cls-6" d="M99.73,359.89q-15.95-4.39-32.06-8a6.81,6.81,0,0,0-3.79-.11"/>
            <path class="cls-6" d="M99.31,356.58c-2.3.43-4.55-.74-6.64-1.76a66.81,66.81,0,0,0-26.23-6.72"/>
            <path class="cls-6" d="M99.73,352.66l-5.23-1.79c-9.6-3.28-19.36-6.59-29.5-7.1"/>
            <path class="cls-6" d="M99.8,348.88c-5.1-2.14-10-4.83-15.15-6.79s-10.81-3.16-16.26-2.15"/>
            <path class="cls-6" d="M63.05,410.93l4.63,10.41"/>
            <path class="cls-6" d="M63.39,392.88Q69.51,409,76.52,424.75"/>
            <path class="cls-6" d="M65,382.54q9,21.06,19,41.67"/>
            <path class="cls-6" d="M72.13,384.14c5.85,8.4,9.48,18.12,13,27.72l4.44,11.95"/>
            <path class="cls-6" d="M80.07,383.36,94,415.45c1.27,2.93,2.58,5.92,4.79,8.22"/>
            <path class="cls-6" d="M85.42,384.1a218.4,218.4,0,0,1,15.15,29.36"/>
            <path class="cls-6" d="M92.75,385.1a98.56,98.56,0,0,1,9,16"/>
            <path class="cls-6" d="M131.1,384l6.75,7.92"/>
            <path class="cls-6" d="M123.42,385.7a56.43,56.43,0,0,0,12.16,13.4"/>
            <path class="cls-6" d="M115.45,386q10.78,12.23,21.87,24.15"/>
            <path class="cls-6" d="M107.81,384.22a275,275,0,0,0,28.95,32.34"/>
            <path class="cls-6" d="M101.52,383.54c9.82,14.16,22.07,26.44,34.25,38.62"/>
            <path class="cls-6" d="M101.52,392.48a265.12,265.12,0,0,0,27.67,31.64"/>
            <path class="cls-6" d="M102,401.83a214.62,214.62,0,0,0,19.44,23.24"/>
            <path class="cls-6" d="M101.21,409,112,423.68"/>
            <path class="cls-6" d="M102.22,423.18l-.49-1"/>
            <path class="cls-6" d="M169.81,384l3.94,3.41"/>
            <path class="cls-6" d="M157.26,384.14l17.47,9.59"/>
            <path class="cls-6" d="M146.33,384.92a241.87,241.87,0,0,0,26.94,14.66"/>
            <path class="cls-6" d="M136.47,384.76c13.22,6.85,24.13,18.43,38.54,22.17"/>
            <path class="cls-6" d="M137,393.22c3.62,1.41,6.6,4,9.63,6.47a124.59,124.59,0,0,0,28.07,17.05"/>
            <path class="cls-6" d="M137.64,400.43a168.94,168.94,0,0,0,21.24,14.78c5,2.92,10.08,5.58,15.21,8.18"/>
            <path class="cls-6" d="M137.83,406.93c.5-.49,1.3,0,1.8.51A88.94,88.94,0,0,0,170,427.07"/>
            <path class="cls-6" d="M137.59,413.28a71.63,71.63,0,0,0,14.24,11.61"/>
            <path class="cls-6" d="M137,418.77l3.91,3.92"/>
            <path class="cls-6" d="M207,386.14l5,1.11"/>
            <path class="cls-6" d="M188.89,386.42c8.05.95,16.06,2.3,24,4"/>
            <path class="cls-6" d="M176.22,386.79q13.34,4.08,26.9,7.37c2.91.71,5.92,1.39,8.86.88"/>
            <path class="cls-6" d="M175.26,390.62a193.74,193.74,0,0,0,36.6,8.44"/>
            <path class="cls-6" d="M175.19,395.22c7.73.91,15.16,3.45,22.73,5.25A120.17,120.17,0,0,0,211,402.84"/>
            <path class="cls-6" d="M175.63,399.45c12.27,1.32,23.53,8.95,35.86,8.43"/>
            <path class="cls-6" d="M176.13,403.87c3.37-.35,6.62,1.14,9.76,2.42a91.47,91.47,0,0,0,25.58,6.39"/>
            <path class="cls-6" d="M176.19,408.39q15.93,4.38,32.06,8a6.68,6.68,0,0,0,3.78.11"/>
            <path class="cls-6" d="M176.61,411.69c2.29-.43,4.54.74,6.64,1.77a66.81,66.81,0,0,0,26.23,6.72"/>
            <path class="cls-6" d="M176.18,415.62l5.24,1.78c9.6,3.28,19.36,6.6,29.49,7.11"/>
            <path class="cls-6" d="M176.11,419.39c5.11,2.14,10,4.84,15.15,6.79s10.82,3.16,16.26,2.15"/>
            <path class="cls-6" d="M211.91,410.93l4.63,10.41"/>
            <path class="cls-6" d="M212.25,392.88q6.12,16.11,13.13,31.87"/>
            <path class="cls-6" d="M213.85,382.54q9,21.06,19,41.67"/>
            <path class="cls-6" d="M221,384.14c5.86,8.4,9.49,18.12,13.05,27.72l4.44,11.95"/>
            <path class="cls-6" d="M228.93,383.36l13.94,32.09c1.27,2.93,2.58,5.92,4.79,8.22"/>
            <path class="cls-6" d="M234.28,384.1a217.19,217.19,0,0,1,15.14,29.36"/>
            <path class="cls-6" d="M241.61,385.1a98.51,98.51,0,0,1,9,16"/>
            <path class="cls-6" d="M280,384l6.75,7.92"/>
            <path class="cls-6" d="M272.27,385.7a56.47,56.47,0,0,0,12.17,13.4"/>
            <path class="cls-6" d="M264.31,386q10.79,12.23,21.87,24.15"/>
            <path class="cls-6" d="M256.67,384.22a275,275,0,0,0,28.95,32.34"/>
            <path class="cls-6" d="M250.38,383.54c9.82,14.16,22.07,26.44,34.24,38.62"/>
            <path class="cls-6" d="M250.38,392.48A265.93,265.93,0,0,0,278,424.12"/>
            <path class="cls-6" d="M250.81,401.83a214.62,214.62,0,0,0,19.44,23.24"/>
            <path class="cls-6" d="M250.07,409l10.78,14.68"/>
            <path class="cls-6" d="M251.08,423.18l-.49-1"/>
            <path class="cls-6" d="M318.67,384l3.94,3.41"/>
            <path class="cls-6" d="M306.12,384.14l17.47,9.59"/>
            <path class="cls-6" d="M295.19,384.92a241.73,241.73,0,0,0,26.93,14.66"/>
            <path class="cls-6" d="M285.33,384.76c13.22,6.85,24.13,18.43,38.54,22.17"/>
            <path class="cls-6" d="M285.87,393.22c3.63,1.41,6.61,4,9.64,6.47a124.15,124.15,0,0,0,28.07,17.05"/>
            <path class="cls-6" d="M286.5,400.43a168.94,168.94,0,0,0,21.24,14.78c5,2.92,10.07,5.58,15.21,8.18"/>
            <path class="cls-6" d="M286.69,406.93c.49-.49,1.3,0,1.79.51a89.08,89.08,0,0,0,30.41,19.63"/>
            <path class="cls-6" d="M286.45,413.28a71.63,71.63,0,0,0,14.24,11.61"/>
            <path class="cls-6" d="M285.85,418.77l3.91,3.92"/>
            <path class="cls-6" d="M355.83,386.14l5,1.11"/>
            <path class="cls-6" d="M337.75,386.42c8.05.95,16.06,2.3,24,4"/>
            <path class="cls-6" d="M325.07,386.79q13.35,4.08,26.91,7.37c2.9.71,5.92,1.39,8.86.88"/>
            <path class="cls-6" d="M324.12,390.62a193.82,193.82,0,0,0,36.59,8.44"/>
            <path class="cls-6" d="M324.05,395.22c7.73.91,15.16,3.45,22.73,5.25a120.43,120.43,0,0,0,13,2.37"/>
            <path class="cls-6" d="M324.49,399.45c12.27,1.32,23.53,8.95,35.86,8.43"/>
            <path class="cls-6" d="M325,403.87c3.37-.35,6.62,1.14,9.76,2.42a91.4,91.4,0,0,0,25.57,6.39"/>
            <path class="cls-6" d="M325.05,408.39q15.93,4.38,32.06,8a6.68,6.68,0,0,0,3.78.11"/>
            <path class="cls-6" d="M325.47,411.69c2.29-.43,4.54.74,6.63,1.77a66.9,66.9,0,0,0,26.24,6.72"/>
            <path class="cls-6" d="M325,415.62l5.23,1.78c9.61,3.28,19.37,6.6,29.5,7.11"/>
            <path class="cls-6" d="M325,419.39c5.11,2.14,10,4.84,15.15,6.79s10.82,3.16,16.26,2.15"/>
            <path class="cls-6" d="M360.77,410.93l4.63,10.41"/>
            <path class="cls-6" d="M361.1,392.88q6.13,16.11,13.14,31.87"/>
            <path class="cls-6" d="M362.71,382.54q9,21.06,19,41.67"/>
            <path class="cls-6" d="M369.84,384.14c5.86,8.4,9.48,18.12,13.05,27.72l4.44,11.95"/>
            <path class="cls-6" d="M377.79,383.36l13.93,32.09c1.27,2.93,2.59,5.92,4.79,8.22"/>
            <path class="cls-6" d="M383.14,384.1a217.19,217.19,0,0,1,15.14,29.36"/>
            <path class="cls-6" d="M390.47,385.1a98.51,98.51,0,0,1,9,16"/>
            <path class="cls-6" d="M428.82,384l6.75,7.92"/>
            <path class="cls-6" d="M421.13,385.7a56.47,56.47,0,0,0,12.17,13.4"/>
            <path class="cls-6" d="M413.17,386Q423.94,398.18,435,410.1"/>
            <path class="cls-6" d="M405.53,384.22a273.23,273.23,0,0,0,29,32.34"/>
            <path class="cls-6" d="M399.24,383.54c9.81,14.16,22.06,26.44,34.24,38.62"/>
            <path class="cls-6" d="M399.24,392.48a264.14,264.14,0,0,0,27.66,31.64"/>
            <path class="cls-6" d="M399.67,401.83a214.62,214.62,0,0,0,19.44,23.24"/>
            <path class="cls-6" d="M398.93,409l10.78,14.68"/>
            <path class="cls-6" d="M399.94,423.18l-.49-1"/>
            <path class="cls-6" d="M467.53,384l3.93,3.41"/>
            <path class="cls-6" d="M455,384.14l17.47,9.59"/>
            <path class="cls-6" d="M444.05,384.92A240.85,240.85,0,0,0,471,399.58"/>
            <path class="cls-6" d="M434.18,384.76c13.23,6.85,24.14,18.43,38.55,22.17"/>
            <path class="cls-6" d="M434.73,393.22c3.62,1.41,6.61,4,9.63,6.47a124.44,124.44,0,0,0,28.08,17.05"/>
            <path class="cls-6" d="M435.36,400.43a168.94,168.94,0,0,0,21.24,14.78c5,2.92,10.07,5.58,15.21,8.18"/>
            <path class="cls-6" d="M435.54,406.93c.5-.49,1.31,0,1.8.51a89.14,89.14,0,0,0,30.4,19.63"/>
            <path class="cls-6" d="M435.3,413.28a72,72,0,0,0,14.25,11.61"/>
            <path class="cls-6" d="M434.7,418.77l3.92,3.92"/>
            <path class="cls-6" d="M504.69,386.14l5,1.11"/>
            <path class="cls-6" d="M486.61,386.42q12.08,1.43,24,4"/>
            <path class="cls-6" d="M473.93,386.79q13.33,4.08,26.91,7.37c2.9.71,5.91,1.39,8.86.88"/>
            <path class="cls-6" d="M473,390.62a193.64,193.64,0,0,0,36.59,8.44"/>
            <path class="cls-6" d="M472.91,395.22c7.73.91,15.16,3.45,22.73,5.25a120.43,120.43,0,0,0,13,2.37"/>
            <path class="cls-6" d="M473.35,399.45c12.27,1.32,23.53,8.95,35.86,8.43"/>
            <path class="cls-6" d="M473.85,403.87c3.37-.35,6.62,1.14,9.75,2.42a91.56,91.56,0,0,0,25.58,6.39"/>
            <path class="cls-6" d="M473.91,408.39q15.93,4.38,32.06,8a6.68,6.68,0,0,0,3.78.11"/>
            <path class="cls-6" d="M474.33,411.69c2.29-.43,4.54.74,6.63,1.77a66.9,66.9,0,0,0,26.24,6.72"/>
            <path class="cls-6" d="M473.9,415.62l5.23,1.78c9.61,3.28,19.36,6.6,29.5,7.11"/>
            <path class="cls-6" d="M473.83,419.39c5.11,2.14,10,4.84,15.15,6.79s10.82,3.16,16.26,2.15"/>
            <path class="cls-6" d="M509.63,410.93l4.63,10.41"/>
            <path class="cls-6" d="M510,392.88q6.13,16.11,13.14,31.87"/>
            <path class="cls-6" d="M511.57,382.54q9,21.06,19,41.67"/>
            <path class="cls-6" d="M518.7,384.14c5.86,8.4,9.48,18.12,13,27.72l4.44,11.95"/>
            <path class="cls-6" d="M526.65,383.36l13.93,32.09c1.27,2.93,2.59,5.92,4.79,8.22"/>
            <path class="cls-6" d="M532,384.1a216.11,216.11,0,0,1,15.15,29.36"/>
            <path class="cls-6" d="M539.33,385.1a98.51,98.51,0,0,1,9,16"/>
            <path class="cls-6" d="M577.68,384l6.75,7.92"/>
            <path class="cls-6" d="M570,385.7a56.47,56.47,0,0,0,12.17,13.4"/>
            <path class="cls-6" d="M562,386q10.77,12.23,21.87,24.15"/>
            <path class="cls-6" d="M554.39,384.22a273.23,273.23,0,0,0,29,32.34"/>
            <path class="cls-6" d="M548.1,383.54c9.81,14.16,22.06,26.44,34.24,38.62"/>
            <path class="cls-6" d="M548.1,392.48a264.14,264.14,0,0,0,27.66,31.64"/>
            <path class="cls-6" d="M548.53,401.83A212.34,212.34,0,0,0,568,425.07"/>
            <path class="cls-6" d="M547.78,409l10.79,14.68"/>
            <path class="cls-6" d="M548.79,423.18l-.49-1"/>
            <path class="cls-6" d="M616.39,384l3.93,3.41"/>
            <path class="cls-6" d="M603.83,384.14l17.47,9.59"/>
            <path class="cls-6" d="M592.91,384.92a240.85,240.85,0,0,0,26.93,14.66"/>
            <path class="cls-6" d="M583,384.76c13.22,6.85,24.14,18.43,38.55,22.17"/>
            <path class="cls-6" d="M583.59,393.22c3.62,1.41,6.61,4,9.63,6.47a124.44,124.44,0,0,0,28.08,17.05"/>
            <path class="cls-6" d="M584.22,400.43a168.22,168.22,0,0,0,21.24,14.78c5,2.92,10.07,5.58,15.21,8.18"/>
            <path class="cls-6" d="M584.4,406.93c.5-.49,1.31,0,1.8.51a89.14,89.14,0,0,0,30.4,19.63"/>
            <path class="cls-6" d="M584.16,413.28a72,72,0,0,0,14.25,11.61"/>
            <path class="cls-6" d="M583.56,418.77l3.92,3.92"/>
            <path class="cls-6" d="M653.54,386.14l5,1.11"/>
            <path class="cls-6" d="M635.47,386.42q12.07,1.43,24,4"/>
            <path class="cls-6" d="M622.79,386.79q13.34,4.08,26.9,7.37c2.91.71,5.92,1.39,8.87.88"/>
            <path class="cls-6" d="M621.83,390.62a193.92,193.92,0,0,0,36.6,8.44"/>
            <path class="cls-6" d="M621.76,395.22c7.73.91,15.17,3.45,22.74,5.25a120,120,0,0,0,13,2.37"/>
            <path class="cls-6" d="M622.2,399.45c12.27,1.32,23.54,8.95,35.87,8.43"/>
            <path class="cls-6" d="M622.7,403.87c3.37-.35,6.63,1.14,9.76,2.42A91.56,91.56,0,0,0,658,412.68"/>
            <path class="cls-6" d="M622.77,408.39q15.93,4.38,32.06,8a6.68,6.68,0,0,0,3.78.11"/>
            <path class="cls-6" d="M623.19,411.69c2.29-.43,4.54.74,6.63,1.77a66.9,66.9,0,0,0,26.24,6.72"/>
            <path class="cls-6" d="M622.76,415.62,628,417.4c9.6,3.28,19.36,6.6,29.5,7.11"/>
            <path class="cls-6" d="M622.69,419.39c5.1,2.14,10,4.84,15.15,6.79s10.81,3.16,16.26,2.15"/>
            <path class="cls-6" d="M659.44,441.56l-4.63-10.4"/>
            <path class="cls-6" d="M659.1,459.62Q653,443.51,646,427.74"/>
            <path class="cls-6" d="M657.5,470q-9-21-19-41.66"/>
            <path class="cls-6" d="M650.37,468.36c-5.86-8.4-9.49-18.12-13-27.72l-4.44-12"/>
            <path class="cls-6" d="M642.42,469.14l-13.94-32.09c-1.27-2.93-2.58-5.92-4.79-8.22"/>
            <path class="cls-6" d="M637.07,468.4A218.52,218.52,0,0,1,621.92,439"/>
            <path class="cls-6" d="M629.74,467.4a99.4,99.4,0,0,1-9-16"/>
            <path class="cls-6" d="M591.39,468.49l-6.75-7.93"/>
            <path class="cls-6" d="M599.08,466.8a56.28,56.28,0,0,0-12.17-13.4"/>
            <path class="cls-6" d="M607,466.54q-10.78-12.21-21.87-24.14"/>
            <path class="cls-6" d="M614.68,468.27a274.91,274.91,0,0,0-28.95-32.33"/>
            <path class="cls-6" d="M621,469c-9.82-14.17-22.07-26.44-34.24-38.63"/>
            <path class="cls-6" d="M621,460a265.81,265.81,0,0,0-27.67-31.63"/>
            <path class="cls-6" d="M620.54,450.67a213.66,213.66,0,0,0-19.44-23.24"/>
            <path class="cls-6" d="M621.28,443.5,610.5,428.82"/>
            <path class="cls-6" d="M620.27,429.32l.49,1"/>
            <path class="cls-6" d="M552.68,468.51l-3.94-3.4"/>
            <path class="cls-6" d="M565.23,468.36l-17.47-9.59"/>
            <path class="cls-6" d="M576.16,467.58a241.73,241.73,0,0,0-26.93-14.66"/>
            <path class="cls-6" d="M586,467.74c-13.22-6.85-24.13-18.43-38.54-22.17"/>
            <path class="cls-6" d="M585.48,459.28c-3.63-1.41-6.61-4-9.64-6.47a123.43,123.43,0,0,0-28.07-17.05"/>
            <path class="cls-6" d="M584.85,452.07a170.07,170.07,0,0,0-21.24-14.79c-5-2.91-10.07-5.58-15.21-8.18"/>
            <path class="cls-6" d="M584.66,445.57c-.49.48-1.3,0-1.79-.51a88.93,88.93,0,0,0-30.41-19.63"/>
            <path class="cls-6" d="M584.9,439.22a71.63,71.63,0,0,0-14.24-11.61"/>
            <path class="cls-6" d="M585.5,433.73l-3.91-3.92"/>
            <path class="cls-6" d="M515.52,466.36l-5-1.11"/>
            <path class="cls-6" d="M533.6,466.07a242.64,242.64,0,0,1-24-4"/>
            <path class="cls-6" d="M546.28,465.7q-13.35-4.08-26.91-7.37c-2.9-.7-5.92-1.38-8.86-.87"/>
            <path class="cls-6" d="M547.23,461.88a193.82,193.82,0,0,0-36.59-8.44"/>
            <path class="cls-6" d="M547.3,457.28c-7.73-.91-15.16-3.45-22.73-5.26a122.88,122.88,0,0,0-13-2.36"/>
            <path class="cls-6" d="M546.86,453c-12.27-1.31-23.53-9-35.86-8.42"/>
            <path class="cls-6" d="M546.36,448.62c-3.37.36-6.62-1.13-9.76-2.42A92.1,92.1,0,0,0,511,439.81"/>
            <path class="cls-6" d="M546.3,444.11q-15.93-4.39-32.06-8a6.68,6.68,0,0,0-3.78-.1"/>
            <path class="cls-6" d="M545.88,440.8c-2.29.43-4.54-.74-6.64-1.76A66.81,66.81,0,0,0,513,432.32"/>
            <path class="cls-6" d="M546.31,436.88l-5.23-1.79c-9.61-3.28-19.37-6.59-29.5-7.1"/>
            <path class="cls-6" d="M546.38,433.11c-5.11-2.14-10-4.84-15.15-6.8s-10.82-3.16-16.26-2.14"/>
            <path class="cls-6" d="M510.58,441.56,506,431.16"/>
            <path class="cls-6" d="M510.25,459.62q-6.13-16.11-13.14-31.88"/>
            <path class="cls-6" d="M508.64,470q-9-21-19-41.66"/>
            <path class="cls-6" d="M501.51,468.36c-5.86-8.4-9.48-18.12-13.05-27.72l-4.44-12"/>
            <path class="cls-6" d="M493.56,469.14l-13.93-32.09c-1.27-2.93-2.59-5.92-4.79-8.22"/>
            <path class="cls-6" d="M488.21,468.4A217.31,217.31,0,0,1,473.07,439"/>
            <path class="cls-6" d="M480.88,467.4a98.6,98.6,0,0,1-9-16"/>
            <path class="cls-6" d="M442.53,468.49l-6.75-7.93"/>
            <path class="cls-6" d="M450.22,466.8a56.28,56.28,0,0,0-12.17-13.4"/>
            <path class="cls-6" d="M458.18,466.54q-10.77-12.21-21.87-24.14"/>
            <path class="cls-6" d="M465.82,468.27a273.14,273.14,0,0,0-28.95-32.33"/>
            <path class="cls-6" d="M472.11,469c-9.81-14.17-22.06-26.44-34.24-38.63"/>
            <path class="cls-6" d="M472.11,460a264.83,264.83,0,0,0-27.66-31.63"/>
            <path class="cls-6" d="M471.68,450.67a213.66,213.66,0,0,0-19.44-23.24"/>
            <path class="cls-6" d="M472.42,443.5l-10.78-14.68"/>
            <path class="cls-6" d="M471.41,429.32l.49,1"/>
            <path class="cls-6" d="M403.82,468.51l-3.93-3.4"/>
            <path class="cls-6" d="M416.37,468.36l-17.47-9.59"/>
            <path class="cls-6" d="M427.3,467.58a240.85,240.85,0,0,0-26.93-14.66"/>
            <path class="cls-6" d="M437.17,467.74c-13.23-6.85-24.14-18.43-38.55-22.17"/>
            <path class="cls-6" d="M436.62,459.28c-3.62-1.41-6.61-4-9.63-6.47a123.72,123.72,0,0,0-28.08-17.05"/>
            <path class="cls-6" d="M436,452.07a170.07,170.07,0,0,0-21.24-14.79c-5-2.91-10.07-5.58-15.21-8.18"/>
            <path class="cls-6" d="M435.81,445.57c-.5.48-1.31,0-1.8-.51a89,89,0,0,0-30.4-19.63"/>
            <path class="cls-6" d="M436.05,439.22a72,72,0,0,0-14.25-11.61"/>
            <path class="cls-6" d="M436.65,433.73l-3.92-3.92"/>
            <path class="cls-6" d="M366.66,466.36l-5-1.11"/>
            <path class="cls-6" d="M384.74,466.07q-12.08-1.41-24-4"/>
            <path class="cls-6" d="M397.42,465.7q-13.34-4.08-26.91-7.37c-2.9-.7-5.91-1.38-8.86-.87"/>
            <path class="cls-6" d="M398.37,461.88a193.64,193.64,0,0,0-36.59-8.44"/>
            <path class="cls-6" d="M398.44,457.28c-7.73-.91-15.16-3.45-22.73-5.26a122.88,122.88,0,0,0-13-2.36"/>
            <path class="cls-6" d="M398,453c-12.27-1.31-23.53-9-35.86-8.42"/>
            <path class="cls-6" d="M397.5,448.62c-3.37.36-6.62-1.13-9.75-2.42a92.25,92.25,0,0,0-25.58-6.39"/>
            <path class="cls-6" d="M397.44,444.11q-15.93-4.39-32.06-8a6.68,6.68,0,0,0-3.78-.1"/>
            <path class="cls-6" d="M397,440.8c-2.29.43-4.54-.74-6.63-1.76a66.9,66.9,0,0,0-26.24-6.72"/>
            <path class="cls-6" d="M397.45,436.88l-5.23-1.79c-9.61-3.28-19.36-6.59-29.5-7.1"/>
            <path class="cls-6" d="M397.52,433.11c-5.11-2.14-10-4.84-15.15-6.8s-10.82-3.16-16.26-2.14"/>
            <path class="cls-6" d="M361.72,441.56l-4.63-10.4"/>
            <path class="cls-6" d="M361.39,459.62q-6.13-16.11-13.14-31.88"/>
            <path class="cls-6" d="M359.78,470q-9-21-19-41.66"/>
            <path class="cls-6" d="M352.65,468.36c-5.86-8.4-9.48-18.12-13-27.72l-4.44-12"/>
            <path class="cls-6" d="M344.7,469.14l-13.93-32.09c-1.27-2.93-2.59-5.92-4.79-8.22"/>
            <path class="cls-6" d="M339.36,468.4A216.22,216.22,0,0,1,324.21,439"/>
            <path class="cls-6" d="M332,467.4a98.6,98.6,0,0,1-9-16"/>
            <path class="cls-6" d="M293.67,468.49l-6.75-7.93"/>
            <path class="cls-6" d="M301.36,466.8a56.28,56.28,0,0,0-12.17-13.4"/>
            <path class="cls-6" d="M309.32,466.54q-10.77-12.21-21.87-24.14"/>
            <path class="cls-6" d="M317,468.27A273.14,273.14,0,0,0,288,435.94"/>
            <path class="cls-6" d="M323.25,469c-9.81-14.17-22.06-26.44-34.24-38.63"/>
            <path class="cls-6" d="M323.25,460a264.83,264.83,0,0,0-27.66-31.63"/>
            <path class="cls-6" d="M322.82,450.67a211.4,211.4,0,0,0-19.44-23.24"/>
            <path class="cls-6" d="M323.57,443.5l-10.79-14.68"/>
            <path class="cls-6" d="M322.56,429.32l.49,1"/>
            <path class="cls-6" d="M255,468.51l-3.93-3.4"/>
            <path class="cls-6" d="M267.52,468.36l-17.47-9.59"/>
            <path class="cls-6" d="M278.44,467.58a240.85,240.85,0,0,0-26.93-14.66"/>
            <path class="cls-6" d="M288.31,467.74c-13.22-6.85-24.14-18.43-38.55-22.17"/>
            <path class="cls-6" d="M287.76,459.28c-3.62-1.41-6.61-4-9.63-6.47a123.72,123.72,0,0,0-28.08-17.05"/>
            <path class="cls-6" d="M287.13,452.07a169.33,169.33,0,0,0-21.24-14.79c-5-2.91-10.07-5.58-15.21-8.18"/>
            <path class="cls-6" d="M287,445.57c-.5.48-1.31,0-1.8-.51a89,89,0,0,0-30.4-19.63"/>
            <path class="cls-6" d="M287.19,439.22a72,72,0,0,0-14.25-11.61"/>
            <path class="cls-6" d="M287.79,433.73l-3.92-3.92"/>
            <path class="cls-6" d="M217.81,466.36l-5-1.11"/>
            <path class="cls-6" d="M235.88,466.07q-12.07-1.41-24-4"/>
            <path class="cls-6" d="M248.56,465.7q-13.34-4.08-26.9-7.37c-2.91-.7-5.92-1.38-8.87-.87"/>
            <path class="cls-6" d="M249.52,461.88a193.92,193.92,0,0,0-36.6-8.44"/>
            <path class="cls-6" d="M249.59,457.28c-7.73-.91-15.17-3.45-22.74-5.26a122.42,122.42,0,0,0-13-2.36"/>
            <path class="cls-6" d="M249.15,453c-12.27-1.31-23.54-9-35.87-8.42"/>
            <path class="cls-6" d="M248.65,448.62c-3.37.36-6.63-1.13-9.76-2.42a92.25,92.25,0,0,0-25.58-6.39"/>
            <path class="cls-6" d="M248.58,444.11q-15.93-4.39-32.06-8a6.68,6.68,0,0,0-3.78-.1"/>
            <path class="cls-6" d="M248.16,440.8c-2.29.43-4.54-.74-6.63-1.76a66.9,66.9,0,0,0-26.24-6.72"/>
            <path class="cls-6" d="M248.59,436.88l-5.23-1.79c-9.6-3.28-19.36-6.59-29.5-7.1"/>
            <path class="cls-6" d="M248.66,433.11c-5.1-2.14-10-4.84-15.15-6.8s-10.81-3.16-16.26-2.14"/>
            <path class="cls-6" d="M212.86,441.56l-4.62-10.4"/>
            <path class="cls-6" d="M212.53,459.62q-6.12-16.11-13.13-31.88"/>
            <path class="cls-6" d="M210.92,470q-9-21-19-41.66"/>
            <path class="cls-6" d="M203.79,468.36c-5.86-8.4-9.48-18.12-13-27.72l-4.44-12"/>
            <path class="cls-6" d="M195.84,469.14l-13.93-32.09c-1.27-2.93-2.58-5.92-4.79-8.22"/>
            <path class="cls-6" d="M190.5,468.4A217.37,217.37,0,0,1,175.35,439"/>
            <path class="cls-6" d="M183.17,467.4a97.91,97.91,0,0,1-9-16"/>
            <path class="cls-6" d="M144.81,468.49l-6.75-7.93"/>
            <path class="cls-6" d="M152.5,466.8a56.07,56.07,0,0,0-12.17-13.4"/>
            <path class="cls-6" d="M160.46,466.54q-10.77-12.21-21.87-24.14"/>
            <path class="cls-6" d="M168.11,468.27a274.11,274.11,0,0,0-29-32.33"/>
            <path class="cls-6" d="M174.39,469c-9.81-14.17-22.06-26.44-34.24-38.63"/>
            <path class="cls-6" d="M174.39,460a264.83,264.83,0,0,0-27.66-31.63"/>
            <path class="cls-6" d="M174,450.67a212.43,212.43,0,0,0-19.43-23.24"/>
            <path class="cls-6" d="M174.71,443.5l-10.79-14.68"/>
            <path class="cls-6" d="M173.7,429.32l.49,1"/>
            <path class="cls-6" d="M106.1,468.51l-3.93-3.4"/>
            <path class="cls-6" d="M118.66,468.36l-17.47-9.59"/>
            <path class="cls-6" d="M129.58,467.58a240.85,240.85,0,0,0-26.93-14.66"/>
            <path class="cls-6" d="M139.45,467.74c-13.22-6.85-24.14-18.43-38.55-22.17"/>
            <path class="cls-6" d="M138.9,459.28c-3.62-1.41-6.61-4-9.63-6.47a123.72,123.72,0,0,0-28.08-17.05"/>
            <path class="cls-6" d="M138.27,452.07A168.61,168.61,0,0,0,117,437.28c-5-2.91-10.07-5.58-15.21-8.18"/>
            <path class="cls-6" d="M138.09,445.57c-.5.48-1.3,0-1.8-.51a89,89,0,0,0-30.4-19.63"/>
            <path class="cls-6" d="M138.33,439.22a72.2,72.2,0,0,0-14.24-11.61"/>
            <path class="cls-6" d="M138.93,433.73,135,429.81"/>
            <path class="cls-6" d="M69,466.36l-5-1.11"/>
            <path class="cls-6" d="M87,466.07q-12.08-1.41-24-4"/>
            <path class="cls-6" d="M99.7,465.7q-13.33-4.08-26.9-7.37c-2.91-.7-5.92-1.38-8.87-.87"/>
            <path class="cls-6" d="M100.66,461.88a193.92,193.92,0,0,0-36.6-8.44"/>
            <path class="cls-6" d="M100.73,457.28C93,456.37,85.57,453.83,78,452a122.42,122.42,0,0,0-13-2.36"/>
            <path class="cls-6" d="M100.29,453c-12.27-1.31-23.54-9-35.86-8.42"/>
            <path class="cls-6" d="M99.79,448.62c-3.37.36-6.62-1.13-9.76-2.42a92.16,92.16,0,0,0-25.58-6.39"/>
            <path class="cls-6" d="M99.73,444.11q-15.95-4.39-32.06-8a6.71,6.71,0,0,0-3.79-.1"/>
            <path class="cls-6" d="M99.31,440.8c-2.3.43-4.55-.74-6.64-1.76a66.81,66.81,0,0,0-26.23-6.72"/>
            <path class="cls-6" d="M99.73,436.88l-5.23-1.79c-9.6-3.28-19.36-6.59-29.5-7.1"/>
            <path class="cls-6" d="M99.8,433.11c-5.1-2.14-10-4.84-15.15-6.8s-10.81-3.16-16.26-2.14"/>
            <path class="cls-6" d="M63.05,497.12l4.63,10.4"/>
            <path class="cls-6" d="M63.39,479.06q6.12,16.11,13.13,31.87"/>
            <path class="cls-6" d="M65,468.72q9,21.06,19,41.67"/>
            <path class="cls-6" d="M72.13,470.32c5.85,8.4,9.48,18.12,13,27.72L89.61,510"/>
            <path class="cls-6" d="M80.07,469.54,94,501.63c1.27,2.93,2.58,5.92,4.79,8.22"/>
            <path class="cls-6" d="M85.42,470.28a219,219,0,0,1,15.15,29.36"/>
            <path class="cls-6" d="M92.75,471.28a98.56,98.56,0,0,1,9,16"/>
            <path class="cls-6" d="M131.1,470.19l6.75,7.93"/>
            <path class="cls-6" d="M123.42,471.88a56.43,56.43,0,0,0,12.16,13.4"/>
            <path class="cls-6" d="M115.45,472.13q10.78,12.22,21.87,24.15"/>
            <path class="cls-6" d="M107.81,470.4a275,275,0,0,0,28.95,32.34"/>
            <path class="cls-6" d="M101.52,469.72c9.82,14.16,22.07,26.44,34.25,38.63"/>
            <path class="cls-6" d="M101.52,478.66a265.12,265.12,0,0,0,27.67,31.64"/>
            <path class="cls-6" d="M102,488a213.66,213.66,0,0,0,19.44,23.24"/>
            <path class="cls-6" d="M101.21,495.18,112,509.86"/>
            <path class="cls-6" d="M102.22,509.36l-.49-1"/>
            <path class="cls-6" d="M169.81,470.16l3.94,3.41"/>
            <path class="cls-6" d="M157.26,470.32l17.47,9.59"/>
            <path class="cls-6" d="M146.33,471.1a241.87,241.87,0,0,0,26.94,14.66"/>
            <path class="cls-6" d="M136.47,470.94c13.22,6.85,24.13,18.43,38.54,22.17"/>
            <path class="cls-6" d="M137,479.4c3.62,1.41,6.6,4,9.63,6.47a124.59,124.59,0,0,0,28.07,17.05"/>
            <path class="cls-6" d="M137.64,486.61a168.94,168.94,0,0,0,21.24,14.78c5,2.92,10.08,5.58,15.21,8.18"/>
            <path class="cls-6" d="M137.83,493.11c.5-.48,1.3,0,1.8.51A88.94,88.94,0,0,0,170,513.25"/>
            <path class="cls-6" d="M137.59,499.46a71.63,71.63,0,0,0,14.24,11.61"/>
            <path class="cls-6" d="M137,505l3.91,3.92"/>
            <path class="cls-6" d="M207,472.32l5,1.11"/>
            <path class="cls-6" d="M188.89,472.6c8.05.95,16.06,2.3,24,4"/>
            <path class="cls-6" d="M176.22,473q13.34,4.09,26.9,7.38c2.91.7,5.92,1.38,8.86.87"/>
            <path class="cls-6" d="M175.26,476.8a193.74,193.74,0,0,0,36.6,8.44"/>
            <path class="cls-6" d="M175.19,481.4c7.73.91,15.16,3.45,22.73,5.25A120.17,120.17,0,0,0,211,489"/>
            <path class="cls-6" d="M175.63,485.64c12.27,1.31,23.53,8.95,35.86,8.42"/>
            <path class="cls-6" d="M176.13,490.05c3.37-.35,6.62,1.14,9.76,2.42a91.47,91.47,0,0,0,25.58,6.39"/>
            <path class="cls-6" d="M176.19,494.57q15.93,4.38,32.06,8a6.59,6.59,0,0,0,3.78.11"/>
            <path class="cls-6" d="M176.61,497.87c2.29-.43,4.54.74,6.64,1.77a66.81,66.81,0,0,0,26.23,6.72"/>
            <path class="cls-6" d="M176.18,501.8l5.24,1.79c9.6,3.28,19.36,6.59,29.49,7.1"/>
            <path class="cls-6" d="M176.11,505.57c5.11,2.14,10,4.84,15.15,6.79s10.82,3.16,16.26,2.15"/>
            <path class="cls-6" d="M211.91,497.12l4.63,10.4"/>
            <path class="cls-6" d="M212.25,479.06q6.12,16.11,13.13,31.87"/>
            <path class="cls-6" d="M213.85,468.72q9,21.06,19,41.67"/>
            <path class="cls-6" d="M221,470.32c5.86,8.4,9.49,18.12,13.05,27.72L238.47,510"/>
            <path class="cls-6" d="M228.93,469.54l13.94,32.09c1.27,2.93,2.58,5.92,4.79,8.22"/>
            <path class="cls-6" d="M234.28,470.28a217.78,217.78,0,0,1,15.14,29.36"/>
            <path class="cls-6" d="M241.61,471.28a98.51,98.51,0,0,1,9,16"/>
            <path class="cls-6" d="M280,470.19l6.75,7.93"/>
            <path class="cls-6" d="M272.27,471.88a56.47,56.47,0,0,0,12.17,13.4"/>
            <path class="cls-6" d="M264.31,472.13q10.79,12.22,21.87,24.15"/>
            <path class="cls-6" d="M256.67,470.4a275,275,0,0,0,28.95,32.34"/>
            <path class="cls-6" d="M250.38,469.72c9.82,14.16,22.07,26.44,34.24,38.63"/>
            <path class="cls-6" d="M250.38,478.66A265.93,265.93,0,0,0,278,510.3"/>
            <path class="cls-6" d="M250.81,488a213.66,213.66,0,0,0,19.44,23.24"/>
            <path class="cls-6" d="M250.07,495.18l10.78,14.68"/>
            <path class="cls-6" d="M251.08,509.36l-.49-1"/>
            <path class="cls-6" d="M318.67,470.16l3.94,3.41"/>
            <path class="cls-6" d="M306.12,470.32l17.47,9.59"/>
            <path class="cls-6" d="M295.19,471.1a241.73,241.73,0,0,0,26.93,14.66"/>
            <path class="cls-6" d="M285.33,470.94c13.22,6.85,24.13,18.43,38.54,22.17"/>
            <path class="cls-6" d="M285.87,479.4c3.63,1.41,6.61,4,9.64,6.47a124.15,124.15,0,0,0,28.07,17.05"/>
            <path class="cls-6" d="M286.5,486.61a168.94,168.94,0,0,0,21.24,14.78c5,2.92,10.07,5.58,15.21,8.18"/>
            <path class="cls-6" d="M286.69,493.11c.49-.48,1.3,0,1.79.51a89.08,89.08,0,0,0,30.41,19.63"/>
            <path class="cls-6" d="M286.45,499.46a71.63,71.63,0,0,0,14.24,11.61"/>
            <path class="cls-6" d="M285.85,505l3.91,3.92"/>
            <path class="cls-6" d="M355.83,472.32l5,1.11"/>
            <path class="cls-6" d="M337.75,472.6c8.05.95,16.06,2.3,24,4"/>
            <path class="cls-6" d="M325.07,473q13.35,4.09,26.91,7.38c2.9.7,5.92,1.38,8.86.87"/>
            <path class="cls-6" d="M324.12,476.8a193.82,193.82,0,0,0,36.59,8.44"/>
            <path class="cls-6" d="M324.05,481.4c7.73.91,15.16,3.45,22.73,5.25a120.43,120.43,0,0,0,13,2.37"/>
            <path class="cls-6" d="M324.49,485.64c12.27,1.31,23.53,8.95,35.86,8.42"/>
            <path class="cls-6" d="M325,490.05c3.37-.35,6.62,1.14,9.76,2.42a91.4,91.4,0,0,0,25.57,6.39"/>
            <path class="cls-6" d="M325.05,494.57q15.93,4.38,32.06,8a6.59,6.59,0,0,0,3.78.11"/>
            <path class="cls-6" d="M325.47,497.87c2.29-.43,4.54.74,6.63,1.77a66.9,66.9,0,0,0,26.24,6.72"/>
            <path class="cls-6" d="M325,501.8l5.23,1.79c9.61,3.28,19.37,6.59,29.5,7.1"/>
            <path class="cls-6" d="M325,505.57c5.11,2.14,10,4.84,15.15,6.79s10.82,3.16,16.26,2.15"/>
            <path class="cls-6" d="M360.77,497.12l4.63,10.4"/>
            <path class="cls-6" d="M361.1,479.06q6.13,16.11,13.14,31.87"/>
            <path class="cls-6" d="M362.71,468.72q9,21.06,19,41.67"/>
            <path class="cls-6" d="M369.84,470.32c5.86,8.4,9.48,18.12,13.05,27.72L387.33,510"/>
            <path class="cls-6" d="M377.79,469.54l13.93,32.09c1.27,2.93,2.59,5.92,4.79,8.22"/>
            <path class="cls-6" d="M383.14,470.28a217.78,217.78,0,0,1,15.14,29.36"/>
            <path class="cls-6" d="M390.47,471.28a98.51,98.51,0,0,1,9,16"/>
            <path class="cls-6" d="M428.82,470.19l6.75,7.93"/>
            <path class="cls-6" d="M421.13,471.88a56.47,56.47,0,0,0,12.17,13.4"/>
            <path class="cls-6" d="M413.17,472.13q10.77,12.22,21.87,24.15"/>
            <path class="cls-6" d="M405.53,470.4a273.23,273.23,0,0,0,29,32.34"/>
            <path class="cls-6" d="M399.24,469.72c9.81,14.16,22.06,26.44,34.24,38.63"/>
            <path class="cls-6" d="M399.24,478.66A264.14,264.14,0,0,0,426.9,510.3"/>
            <path class="cls-6" d="M399.67,488a213.66,213.66,0,0,0,19.44,23.24"/>
            <path class="cls-6" d="M398.93,495.18l10.78,14.68"/>
            <path class="cls-6" d="M399.94,509.36l-.49-1"/>
            <path class="cls-6" d="M467.53,470.16l3.93,3.41"/>
            <path class="cls-6" d="M455,470.32l17.47,9.59"/>
            <path class="cls-6" d="M444.05,471.1A240.85,240.85,0,0,0,471,485.76"/>
            <path class="cls-6" d="M434.18,470.94c13.23,6.85,24.14,18.43,38.55,22.17"/>
            <path class="cls-6" d="M434.73,479.4c3.62,1.41,6.61,4,9.63,6.47a124.44,124.44,0,0,0,28.08,17.05"/>
            <path class="cls-6" d="M435.36,486.61a168.94,168.94,0,0,0,21.24,14.78c5,2.92,10.07,5.58,15.21,8.18"/>
            <path class="cls-6" d="M435.54,493.11c.5-.48,1.31,0,1.8.51a89.14,89.14,0,0,0,30.4,19.63"/>
            <path class="cls-6" d="M435.3,499.46a72,72,0,0,0,14.25,11.61"/>
            <path class="cls-6" d="M434.7,505l3.92,3.92"/>
            <path class="cls-6" d="M504.69,472.32l5,1.11"/>
            <path class="cls-6" d="M486.61,472.6q12.08,1.42,24,4"/>
            <path class="cls-6" d="M473.93,473q13.33,4.09,26.91,7.38c2.9.7,5.91,1.38,8.86.87"/>
            <path class="cls-6" d="M473,476.8a193.64,193.64,0,0,0,36.59,8.44"/>
            <path class="cls-6" d="M472.91,481.4c7.73.91,15.16,3.45,22.73,5.25a120.43,120.43,0,0,0,13,2.37"/>
            <path class="cls-6" d="M473.35,485.64c12.27,1.31,23.53,8.95,35.86,8.42"/>
            <path class="cls-6" d="M473.85,490.05c3.37-.35,6.62,1.14,9.75,2.42a91.56,91.56,0,0,0,25.58,6.39"/>
            <path class="cls-6" d="M473.91,494.57q15.93,4.38,32.06,8a6.59,6.59,0,0,0,3.78.11"/>
            <path class="cls-6" d="M474.33,497.87c2.29-.43,4.54.74,6.63,1.77a66.9,66.9,0,0,0,26.24,6.72"/>
            <path class="cls-6" d="M473.9,501.8l5.23,1.79c9.61,3.28,19.36,6.59,29.5,7.1"/>
            <path class="cls-6" d="M473.83,505.57c5.11,2.14,10,4.84,15.15,6.79s10.82,3.16,16.26,2.15"/>
            <path class="cls-6" d="M509.63,497.12l4.63,10.4"/>
            <path class="cls-6" d="M510,479.06q6.13,16.11,13.14,31.87"/>
            <path class="cls-6" d="M511.57,468.72q9,21.06,19,41.67"/>
            <path class="cls-6" d="M518.7,470.32c5.86,8.4,9.48,18.12,13,27.72L536.19,510"/>
            <path class="cls-6" d="M526.65,469.54l13.93,32.09c1.27,2.93,2.59,5.92,4.79,8.22"/>
            <path class="cls-6" d="M532,470.28a216.7,216.7,0,0,1,15.15,29.36"/>
            <path class="cls-6" d="M539.33,471.28a98.51,98.51,0,0,1,9,16"/>
            <path class="cls-6" d="M577.68,470.19l6.75,7.93"/>
            <path class="cls-6" d="M570,471.88a56.47,56.47,0,0,0,12.17,13.4"/>
            <path class="cls-6" d="M562,472.13q10.77,12.22,21.87,24.15"/>
            <path class="cls-6" d="M554.39,470.4a273.23,273.23,0,0,0,29,32.34"/>
            <path class="cls-6" d="M548.1,469.72c9.81,14.16,22.06,26.44,34.24,38.63"/>
            <path class="cls-6" d="M548.1,478.66a264.14,264.14,0,0,0,27.66,31.64"/>
            <path class="cls-6" d="M548.53,488A211.4,211.4,0,0,0,568,511.25"/>
            <path class="cls-6" d="M547.78,495.18l10.79,14.68"/>
            <path class="cls-6" d="M548.79,509.36l-.49-1"/>
            <path class="cls-6" d="M616.39,470.16l3.93,3.41"/>
            <path class="cls-6" d="M603.83,470.32l17.47,9.59"/>
            <path class="cls-6" d="M592.91,471.1a240.85,240.85,0,0,0,26.93,14.66"/>
            <path class="cls-6" d="M583,470.94c13.22,6.85,24.14,18.43,38.55,22.17"/>
            <path class="cls-6" d="M583.59,479.4c3.62,1.41,6.61,4,9.63,6.47a124.44,124.44,0,0,0,28.08,17.05"/>
            <path class="cls-6" d="M584.22,486.61a168.22,168.22,0,0,0,21.24,14.78c5,2.92,10.07,5.58,15.21,8.18"/>
            <path class="cls-6" d="M584.4,493.11c.5-.48,1.31,0,1.8.51a89.14,89.14,0,0,0,30.4,19.63"/>
            <path class="cls-6" d="M584.16,499.46a72,72,0,0,0,14.25,11.61"/>
            <path class="cls-6" d="M583.56,505l3.92,3.92"/>
            <path class="cls-6" d="M653.54,472.32l5,1.11"/>
            <path class="cls-6" d="M635.47,472.6q12.07,1.42,24,4"/>
            <path class="cls-6" d="M622.79,473q13.34,4.09,26.9,7.38c2.91.7,5.92,1.38,8.87.87"/>
            <path class="cls-6" d="M621.83,476.8a193.92,193.92,0,0,0,36.6,8.44"/>
            <path class="cls-6" d="M621.76,481.4c7.73.91,15.17,3.45,22.74,5.25a120,120,0,0,0,13,2.37"/>
            <path class="cls-6" d="M622.2,485.64c12.27,1.31,23.54,8.95,35.87,8.42"/>
            <path class="cls-6" d="M622.7,490.05c3.37-.35,6.63,1.14,9.76,2.42A91.56,91.56,0,0,0,658,498.86"/>
            <path class="cls-6" d="M622.77,494.57q15.93,4.38,32.06,8a6.59,6.59,0,0,0,3.78.11"/>
            <path class="cls-6" d="M623.19,497.87c2.29-.43,4.54.74,6.63,1.77a66.9,66.9,0,0,0,26.24,6.72"/>
            <path class="cls-6" d="M622.76,501.8l5.23,1.79c9.6,3.28,19.36,6.59,29.5,7.1"/>
            <path class="cls-6" d="M622.69,505.57c5.1,2.14,10,4.84,15.15,6.79s10.81,3.16,16.26,2.15"/>
            <path class="cls-6" d="M659.44,527.74l-4.63-10.4"/>
            <path class="cls-6" d="M659.1,545.8Q653,529.69,646,513.92"/>
            <path class="cls-6" d="M657.5,556.14q-9-21.06-19-41.67"/>
            <path class="cls-6" d="M650.37,554.54c-5.86-8.4-9.49-18.12-13-27.72l-4.44-12"/>
            <path class="cls-6" d="M642.42,555.32l-13.94-32.09c-1.27-2.93-2.58-5.92-4.79-8.22"/>
            <path class="cls-6" d="M637.07,554.58a218.52,218.52,0,0,1-15.15-29.37"/>
            <path class="cls-6" d="M629.74,553.58a99.31,99.31,0,0,1-9-16"/>
            <path class="cls-6" d="M591.39,554.67l-6.75-7.93"/>
            <path class="cls-6" d="M599.08,553a56.28,56.28,0,0,0-12.17-13.4"/>
            <path class="cls-6" d="M607,552.72q-10.78-12.21-21.87-24.14"/>
            <path class="cls-6" d="M614.68,554.46a274.21,274.21,0,0,0-28.95-32.34"/>
            <path class="cls-6" d="M621,555.14c-9.82-14.17-22.07-26.44-34.24-38.63"/>
            <path class="cls-6" d="M621,546.19a265.81,265.81,0,0,0-27.67-31.63"/>
            <path class="cls-6" d="M620.54,536.85a213.66,213.66,0,0,0-19.44-23.24"/>
            <path class="cls-6" d="M621.28,529.68,610.5,515"/>
            <path class="cls-6" d="M620.27,515.5l.49,1"/>
            <path class="cls-6" d="M552.68,554.69l-3.94-3.4"/>
            <path class="cls-6" d="M565.23,554.54,547.76,545"/>
            <path class="cls-6" d="M576.16,553.76a241.73,241.73,0,0,0-26.93-14.66"/>
            <path class="cls-6" d="M586,553.92c-13.22-6.85-24.13-18.43-38.54-22.17"/>
            <path class="cls-6" d="M585.48,545.46c-3.63-1.41-6.61-4-9.64-6.47a124.15,124.15,0,0,0-28.07-17"/>
            <path class="cls-6" d="M584.85,538.25a170.07,170.07,0,0,0-21.24-14.79c-5-2.91-10.07-5.57-15.21-8.17"/>
            <path class="cls-6" d="M584.66,531.75c-.49.48-1.3,0-1.79-.51a89.08,89.08,0,0,0-30.41-19.63"/>
            <path class="cls-6" d="M584.9,525.4a71.63,71.63,0,0,0-14.24-11.61"/>
            <path class="cls-6" d="M585.5,519.91,581.59,516"/>
            <path class="cls-6" d="M515.52,552.54l-5-1.11"/>
            <path class="cls-6" d="M533.6,552.26c-8.05-1-16.06-2.3-24-4"/>
            <path class="cls-6" d="M546.28,551.88q-13.35-4.08-26.91-7.37c-2.9-.7-5.92-1.38-8.86-.87"/>
            <path class="cls-6" d="M547.23,548.06a193.82,193.82,0,0,0-36.59-8.44"/>
            <path class="cls-6" d="M547.3,543.46c-7.73-.91-15.16-3.45-22.73-5.26a122.88,122.88,0,0,0-13-2.36"/>
            <path class="cls-6" d="M546.86,539.22c-12.27-1.31-23.53-9-35.86-8.42"/>
            <path class="cls-6" d="M546.36,534.8c-3.37.36-6.62-1.13-9.76-2.41A91.77,91.77,0,0,0,511,526"/>
            <path class="cls-6" d="M546.3,530.29q-15.93-4.38-32.06-8a6.59,6.59,0,0,0-3.78-.11"/>
            <path class="cls-6" d="M545.88,527c-2.29.43-4.54-.74-6.64-1.76A66.81,66.81,0,0,0,513,518.5"/>
            <path class="cls-6" d="M546.31,523.06l-5.23-1.79c-9.61-3.28-19.37-6.59-29.5-7.1"/>
            <path class="cls-6" d="M546.38,519.29c-5.11-2.14-10-4.84-15.15-6.8s-10.82-3.16-16.26-2.14"/>
            <path class="cls-6" d="M510.58,527.74,506,517.34"/>
            <path class="cls-6" d="M510.25,545.8q-6.13-16.11-13.14-31.88"/>
            <path class="cls-6" d="M508.64,556.14q-9-21.06-19-41.67"/>
            <path class="cls-6" d="M501.51,554.54c-5.86-8.4-9.48-18.12-13.05-27.72l-4.44-12"/>
            <path class="cls-6" d="M493.56,555.32l-13.93-32.09c-1.27-2.93-2.59-5.92-4.79-8.22"/>
            <path class="cls-6" d="M488.21,554.58a217.31,217.31,0,0,1-15.14-29.37"/>
            <path class="cls-6" d="M480.88,553.58a98.51,98.51,0,0,1-9-16"/>
            <path class="cls-6" d="M442.53,554.67l-6.75-7.93"/>
            <path class="cls-6" d="M450.22,553a56.28,56.28,0,0,0-12.17-13.4"/>
            <path class="cls-6" d="M458.18,552.72q-10.77-12.21-21.87-24.14"/>
            <path class="cls-6" d="M465.82,554.46a272.45,272.45,0,0,0-28.95-32.34"/>
            <path class="cls-6" d="M472.11,555.14c-9.81-14.17-22.06-26.44-34.24-38.63"/>
            <path class="cls-6" d="M472.11,546.19a264.83,264.83,0,0,0-27.66-31.63"/>
            <path class="cls-6" d="M471.68,536.85a213.66,213.66,0,0,0-19.44-23.24"/>
            <path class="cls-6" d="M472.42,529.68,461.64,515"/>
            <path class="cls-6" d="M471.41,515.5l.49,1"/>
            <path class="cls-6" d="M403.82,554.69l-3.93-3.4"/>
            <path class="cls-6" d="M416.37,554.54,398.9,545"/>
            <path class="cls-6" d="M427.3,553.76a240.85,240.85,0,0,0-26.93-14.66"/>
            <path class="cls-6" d="M437.17,553.92c-13.23-6.85-24.14-18.43-38.55-22.17"/>
            <path class="cls-6" d="M436.62,545.46c-3.62-1.41-6.61-4-9.63-6.47a124.44,124.44,0,0,0-28.08-17"/>
            <path class="cls-6" d="M436,538.25a170.07,170.07,0,0,0-21.24-14.79c-5-2.91-10.07-5.57-15.21-8.17"/>
            <path class="cls-6" d="M435.81,531.75c-.5.48-1.31,0-1.8-.51a89.14,89.14,0,0,0-30.4-19.63"/>
            <path class="cls-6" d="M436.05,525.4a72,72,0,0,0-14.25-11.61"/>
            <path class="cls-6" d="M436.65,519.91,432.73,516"/>
            <path class="cls-6" d="M366.66,552.54l-5-1.11"/>
            <path class="cls-6" d="M384.74,552.26q-12.08-1.43-24-4"/>
            <path class="cls-6" d="M397.42,551.88q-13.34-4.08-26.91-7.37c-2.9-.7-5.91-1.38-8.86-.87"/>
            <path class="cls-6" d="M398.37,548.06a193.64,193.64,0,0,0-36.59-8.44"/>
            <path class="cls-6" d="M398.44,543.46c-7.73-.91-15.16-3.45-22.73-5.26a122.88,122.88,0,0,0-13-2.36"/>
            <path class="cls-6" d="M398,539.22c-12.27-1.31-23.53-9-35.86-8.42"/>
            <path class="cls-6" d="M397.5,534.8c-3.37.36-6.62-1.13-9.75-2.41a91.92,91.92,0,0,0-25.58-6.4"/>
            <path class="cls-6" d="M397.44,530.29q-15.93-4.38-32.06-8a6.59,6.59,0,0,0-3.78-.11"/>
            <path class="cls-6" d="M397,527c-2.29.43-4.54-.74-6.63-1.76a66.9,66.9,0,0,0-26.24-6.72"/>
            <path class="cls-6" d="M397.45,523.06l-5.23-1.79c-9.61-3.28-19.36-6.59-29.5-7.1"/>
            <path class="cls-6" d="M397.52,519.29c-5.11-2.14-10-4.84-15.15-6.8s-10.82-3.16-16.26-2.14"/>
            <path class="cls-6" d="M361.72,527.74l-4.63-10.4"/>
            <path class="cls-6" d="M361.39,545.8q-6.13-16.11-13.14-31.88"/>
            <path class="cls-6" d="M359.78,556.14q-9-21.06-19-41.67"/>
            <path class="cls-6" d="M352.65,554.54c-5.86-8.4-9.48-18.12-13-27.72l-4.44-12"/>
            <path class="cls-6" d="M344.7,555.32l-13.93-32.09c-1.27-2.93-2.59-5.92-4.79-8.22"/>
            <path class="cls-6" d="M339.36,554.58a216.22,216.22,0,0,1-15.15-29.37"/>
            <path class="cls-6" d="M332,553.58a98.51,98.51,0,0,1-9-16"/>
            <path class="cls-6" d="M293.67,554.67l-6.75-7.93"/>
            <path class="cls-6" d="M301.36,553a56.28,56.28,0,0,0-12.17-13.4"/>
            <path class="cls-6" d="M309.32,552.72q-10.77-12.21-21.87-24.14"/>
            <path class="cls-6" d="M317,554.46A272.45,272.45,0,0,0,288,522.12"/>
            <path class="cls-6" d="M323.25,555.14C313.44,541,301.19,528.7,289,516.51"/>
            <path class="cls-6" d="M323.25,546.19a264.83,264.83,0,0,0-27.66-31.63"/>
            <path class="cls-6" d="M322.82,536.85a211.4,211.4,0,0,0-19.44-23.24"/>
            <path class="cls-6" d="M323.57,529.68,312.78,515"/>
            <path class="cls-6" d="M322.56,515.5l.49,1"/>
            <path class="cls-6" d="M255,554.69l-3.93-3.4"/>
            <path class="cls-6" d="M267.52,554.54,250.05,545"/>
            <path class="cls-6" d="M278.44,553.76a240.85,240.85,0,0,0-26.93-14.66"/>
            <path class="cls-6" d="M288.31,553.92c-13.22-6.85-24.14-18.43-38.55-22.17"/>
            <path class="cls-6" d="M287.76,545.46c-3.62-1.41-6.61-4-9.63-6.47a124.44,124.44,0,0,0-28.08-17"/>
            <path class="cls-6" d="M287.13,538.25a169.33,169.33,0,0,0-21.24-14.79c-5-2.91-10.07-5.57-15.21-8.17"/>
            <path class="cls-6" d="M287,531.75c-.5.48-1.31,0-1.8-.51a89.14,89.14,0,0,0-30.4-19.63"/>
            <path class="cls-6" d="M287.19,525.4a72,72,0,0,0-14.25-11.61"/>
            <path class="cls-6" d="M287.79,519.91,283.87,516"/>
            <path class="cls-6" d="M217.81,552.54l-5-1.11"/>
            <path class="cls-6" d="M235.88,552.26q-12.07-1.43-24-4"/>
            <path class="cls-6" d="M248.56,551.88q-13.34-4.08-26.9-7.37c-2.91-.7-5.92-1.38-8.87-.87"/>
            <path class="cls-6" d="M249.52,548.06a193.92,193.92,0,0,0-36.6-8.44"/>
            <path class="cls-6" d="M249.59,543.46c-7.73-.91-15.17-3.45-22.74-5.26a122.42,122.42,0,0,0-13-2.36"/>
            <path class="cls-6" d="M249.15,539.22c-12.27-1.31-23.54-9-35.87-8.42"/>
            <path class="cls-6" d="M248.65,534.8c-3.37.36-6.63-1.13-9.76-2.41a91.92,91.92,0,0,0-25.58-6.4"/>
            <path class="cls-6" d="M248.58,530.29q-15.93-4.38-32.06-8a6.59,6.59,0,0,0-3.78-.11"/>
            <path class="cls-6" d="M248.16,527c-2.29.43-4.54-.74-6.63-1.76a66.9,66.9,0,0,0-26.24-6.72"/>
            <path class="cls-6" d="M248.59,523.06l-5.23-1.79c-9.6-3.28-19.36-6.59-29.5-7.1"/>
            <path class="cls-6" d="M248.66,519.29c-5.1-2.14-10-4.84-15.15-6.8s-10.81-3.16-16.26-2.14"/>
            <path class="cls-6" d="M212.86,527.74l-4.62-10.4"/>
            <path class="cls-6" d="M212.53,545.8q-6.12-16.11-13.13-31.88"/>
            <path class="cls-6" d="M210.92,556.14q-9-21.06-19-41.67"/>
            <path class="cls-6" d="M203.79,554.54c-5.86-8.4-9.48-18.12-13-27.72l-4.44-12"/>
            <path class="cls-6" d="M195.84,555.32l-13.93-32.09c-1.27-2.93-2.58-5.92-4.79-8.22"/>
            <path class="cls-6" d="M190.5,554.58a217.37,217.37,0,0,1-15.15-29.37"/>
            <path class="cls-6" d="M183.17,553.58a97.82,97.82,0,0,1-9-16"/>
            <path class="cls-6" d="M144.81,554.67l-6.75-7.93"/>
            <path class="cls-6" d="M152.5,553a56.07,56.07,0,0,0-12.17-13.4"/>
            <path class="cls-6" d="M160.46,552.72q-10.77-12.21-21.87-24.14"/>
            <path class="cls-6" d="M168.11,554.46a273.41,273.41,0,0,0-29-32.34"/>
            <path class="cls-6" d="M174.39,555.14c-9.81-14.17-22.06-26.44-34.24-38.63"/>
            <path class="cls-6" d="M174.39,546.19a264.83,264.83,0,0,0-27.66-31.63"/>
            <path class="cls-6" d="M174,536.85a212.43,212.43,0,0,0-19.43-23.24"/>
            <path class="cls-6" d="M174.71,529.68,163.92,515"/>
            <path class="cls-6" d="M173.7,515.5l.49,1"/>
            <path class="cls-6" d="M106.1,554.69l-3.93-3.4"/>
            <path class="cls-6" d="M118.66,554.54,101.19,545"/>
            <path class="cls-6" d="M129.58,553.76a240.85,240.85,0,0,0-26.93-14.66"/>
            <path class="cls-6" d="M139.45,553.92c-13.22-6.85-24.14-18.43-38.55-22.17"/>
            <path class="cls-6" d="M138.9,545.46c-3.62-1.41-6.61-4-9.63-6.47a124.44,124.44,0,0,0-28.08-17"/>
            <path class="cls-6" d="M138.27,538.25A168.61,168.61,0,0,0,117,523.46c-5-2.91-10.07-5.57-15.21-8.17"/>
            <path class="cls-6" d="M138.09,531.75c-.5.48-1.3,0-1.8-.51a89.14,89.14,0,0,0-30.4-19.63"/>
            <path class="cls-6" d="M138.33,525.4a72.2,72.2,0,0,0-14.24-11.61"/>
            <path class="cls-6" d="M138.93,519.91,135,516"/>
            <path class="cls-6" d="M69,552.54l-5-1.11"/>
            <path class="cls-6" d="M87,552.26q-12.08-1.43-24-4"/>
            <path class="cls-6" d="M99.7,551.88q-13.33-4.08-26.9-7.37c-2.91-.7-5.92-1.38-8.87-.87"/>
            <path class="cls-6" d="M100.66,548.06a193.92,193.92,0,0,0-36.6-8.44"/>
            <path class="cls-6" d="M100.73,543.46C93,542.55,85.57,540,78,538.2a122.42,122.42,0,0,0-13-2.36"/>
            <path class="cls-6" d="M100.29,539.22c-12.27-1.31-23.54-9-35.86-8.42"/>
            <path class="cls-6" d="M99.79,534.8c-3.37.36-6.62-1.13-9.76-2.41A91.83,91.83,0,0,0,64.45,526"/>
            <path class="cls-6" d="M99.73,530.29q-15.95-4.38-32.06-8a6.63,6.63,0,0,0-3.79-.11"/>
            <path class="cls-6" d="M99.31,527c-2.3.43-4.55-.74-6.64-1.76a66.81,66.81,0,0,0-26.23-6.72"/>
            <path class="cls-6" d="M99.73,523.06l-5.23-1.79c-9.6-3.28-19.36-6.59-29.5-7.1"/>
            <path class="cls-6" d="M99.8,519.29c-5.1-2.14-10-4.84-15.15-6.8s-10.81-3.16-16.26-2.14"/>
            <path class="cls-6" d="M63.05,581.48l4.63,10.4"/>
            <path class="cls-6" d="M63.39,563.42q6.12,16.11,13.13,31.87"/>
            <path class="cls-6" d="M65,553.08q9,21.06,19,41.67"/>
            <path class="cls-6" d="M72.13,554.68c5.85,8.4,9.48,18.12,13,27.72l4.44,12"/>
            <path class="cls-6" d="M80.07,553.9,94,586c1.27,2.93,2.58,5.92,4.79,8.22"/>
            <path class="cls-6" d="M85.42,554.64A219,219,0,0,1,100.57,584"/>
            <path class="cls-6" d="M92.75,555.64a98.56,98.56,0,0,1,9,16"/>
            <path class="cls-6" d="M131.1,554.55l6.75,7.92"/>
            <path class="cls-6" d="M123.42,556.24a56.43,56.43,0,0,0,12.16,13.4"/>
            <path class="cls-6" d="M115.45,556.49q10.78,12.22,21.87,24.15"/>
            <path class="cls-6" d="M107.81,554.76a275,275,0,0,0,28.95,32.34"/>
            <path class="cls-6" d="M101.52,554.08c9.82,14.16,22.07,26.44,34.25,38.63"/>
            <path class="cls-6" d="M101.52,563a265.12,265.12,0,0,0,27.67,31.64"/>
            <path class="cls-6" d="M102,572.37a214.62,214.62,0,0,0,19.44,23.24"/>
            <path class="cls-6" d="M101.21,579.54,112,594.22"/>
            <path class="cls-6" d="M102.22,593.72l-.49-1"/>
            <path class="cls-6" d="M169.81,554.52l3.94,3.41"/>
            <path class="cls-6" d="M157.26,554.68l17.47,9.59"/>
            <path class="cls-6" d="M146.33,555.46a241.87,241.87,0,0,0,26.94,14.66"/>
            <path class="cls-6" d="M136.47,555.3c13.22,6.85,24.13,18.43,38.54,22.17"/>
            <path class="cls-6" d="M137,563.76c3.62,1.41,6.6,4,9.63,6.47a124.59,124.59,0,0,0,28.07,17"/>
            <path class="cls-6" d="M137.64,571a168.94,168.94,0,0,0,21.24,14.78c5,2.92,10.08,5.58,15.21,8.18"/>
            <path class="cls-6" d="M137.83,577.47c.5-.48,1.3,0,1.8.51A88.94,88.94,0,0,0,170,597.61"/>
            <path class="cls-6" d="M137.59,583.82a71.63,71.63,0,0,0,14.24,11.61"/>
            <path class="cls-6" d="M137,589.31l3.91,3.92"/>
            <path class="cls-6" d="M207,556.68l5,1.11"/>
            <path class="cls-6" d="M188.89,557c8.05.95,16.06,2.3,24,4"/>
            <path class="cls-6" d="M176.22,557.33q13.34,4.08,26.9,7.38c2.91.7,5.92,1.38,8.86.87"/>
            <path class="cls-6" d="M175.26,561.16a193.74,193.74,0,0,0,36.6,8.44"/>
            <path class="cls-6" d="M175.19,565.76c7.73.91,15.16,3.45,22.73,5.25A120.17,120.17,0,0,0,211,573.38"/>
            <path class="cls-6" d="M175.63,570c12.27,1.31,23.53,9,35.86,8.42"/>
            <path class="cls-6" d="M176.13,574.41c3.37-.35,6.62,1.14,9.76,2.42a91.47,91.47,0,0,0,25.58,6.39"/>
            <path class="cls-6" d="M176.19,578.93q15.93,4.38,32.06,8a6.68,6.68,0,0,0,3.78.11"/>
            <path class="cls-6" d="M176.61,582.23c2.29-.43,4.54.74,6.64,1.77a66.81,66.81,0,0,0,26.23,6.72"/>
            <path class="cls-6" d="M176.18,586.16l5.24,1.78c9.6,3.29,19.36,6.6,29.49,7.11"/>
            <path class="cls-6" d="M176.11,589.93c5.11,2.14,10,4.84,15.15,6.79s10.82,3.16,16.26,2.15"/>
            <path class="cls-6" d="M211.91,581.48l4.63,10.4"/>
            <path class="cls-6" d="M212.25,563.42q6.12,16.11,13.13,31.87"/>
            <path class="cls-6" d="M213.85,553.08q9,21.06,19,41.67"/>
            <path class="cls-6" d="M221,554.68c5.86,8.4,9.49,18.12,13.05,27.72l4.44,12"/>
            <path class="cls-6" d="M228.93,553.9,242.87,586c1.27,2.93,2.58,5.92,4.79,8.22"/>
            <path class="cls-6" d="M234.28,554.64A217.78,217.78,0,0,1,249.42,584"/>
            <path class="cls-6" d="M241.61,555.64a98.51,98.51,0,0,1,9,16"/>
            <path class="cls-6" d="M280,554.55l6.75,7.92"/>
            <path class="cls-6" d="M272.27,556.24a56.47,56.47,0,0,0,12.17,13.4"/>
            <path class="cls-6" d="M264.31,556.49q10.79,12.22,21.87,24.15"/>
            <path class="cls-6" d="M256.67,554.76a275,275,0,0,0,28.95,32.34"/>
            <path class="cls-6" d="M250.38,554.08c9.82,14.16,22.07,26.44,34.24,38.63"/>
            <path class="cls-6" d="M250.38,563A265.93,265.93,0,0,0,278,594.66"/>
            <path class="cls-6" d="M250.81,572.37a214.62,214.62,0,0,0,19.44,23.24"/>
            <path class="cls-6" d="M250.07,579.54l10.78,14.68"/>
            <path class="cls-6" d="M251.08,593.72l-.49-1"/>
            <path class="cls-6" d="M318.67,554.52l3.94,3.41"/>
            <path class="cls-6" d="M306.12,554.68l17.47,9.59"/>
            <path class="cls-6" d="M295.19,555.46a241.73,241.73,0,0,0,26.93,14.66"/>
            <path class="cls-6" d="M285.33,555.3c13.22,6.85,24.13,18.43,38.54,22.17"/>
            <path class="cls-6" d="M285.87,563.76c3.63,1.41,6.61,4,9.64,6.47a124.15,124.15,0,0,0,28.07,17"/>
            <path class="cls-6" d="M286.5,571a168.94,168.94,0,0,0,21.24,14.78c5,2.92,10.07,5.58,15.21,8.18"/>
            <path class="cls-6" d="M286.69,577.47c.49-.48,1.3,0,1.79.51a89.08,89.08,0,0,0,30.41,19.63"/>
            <path class="cls-6" d="M286.45,583.82a71.63,71.63,0,0,0,14.24,11.61"/>
            <path class="cls-6" d="M285.85,589.31l3.91,3.92"/>
            <path class="cls-6" d="M355.83,556.68l5,1.11"/>
            <path class="cls-6" d="M337.75,557c8.05.95,16.06,2.3,24,4"/>
            <path class="cls-6" d="M325.07,557.33q13.35,4.08,26.91,7.38c2.9.7,5.92,1.38,8.86.87"/>
            <path class="cls-6" d="M324.12,561.16a193.82,193.82,0,0,0,36.59,8.44"/>
            <path class="cls-6" d="M324.05,565.76c7.73.91,15.16,3.45,22.73,5.25a120.43,120.43,0,0,0,13,2.37"/>
            <path class="cls-6" d="M324.49,570c12.27,1.31,23.53,9,35.86,8.42"/>
            <path class="cls-6" d="M325,574.41c3.37-.35,6.62,1.14,9.76,2.42a91.4,91.4,0,0,0,25.57,6.39"/>
            <path class="cls-6" d="M325.05,578.93q15.93,4.38,32.06,8a6.68,6.68,0,0,0,3.78.11"/>
            <path class="cls-6" d="M325.47,582.23c2.29-.43,4.54.74,6.63,1.77a66.9,66.9,0,0,0,26.24,6.72"/>
            <path class="cls-6" d="M325,586.16l5.23,1.78c9.61,3.29,19.37,6.6,29.5,7.11"/>
            <path class="cls-6" d="M325,589.93c5.11,2.14,10,4.84,15.15,6.79s10.82,3.16,16.26,2.15"/>
            <path class="cls-6" d="M360.77,581.48l4.63,10.4"/>
            <path class="cls-6" d="M361.1,563.42q6.13,16.11,13.14,31.87"/>
            <path class="cls-6" d="M362.71,553.08q9,21.06,19,41.67"/>
            <path class="cls-6" d="M369.84,554.68c5.86,8.4,9.48,18.12,13.05,27.72l4.44,12"/>
            <path class="cls-6" d="M377.79,553.9,391.72,586c1.27,2.93,2.59,5.92,4.79,8.22"/>
            <path class="cls-6" d="M383.14,554.64A217.78,217.78,0,0,1,398.28,584"/>
            <path class="cls-6" d="M390.47,555.64a98.51,98.51,0,0,1,9,16"/>
            <path class="cls-6" d="M428.82,554.55l6.75,7.92"/>
            <path class="cls-6" d="M421.13,556.24a56.47,56.47,0,0,0,12.17,13.4"/>
            <path class="cls-6" d="M413.17,556.49q10.77,12.22,21.87,24.15"/>
            <path class="cls-6" d="M405.53,554.76a273.23,273.23,0,0,0,29,32.34"/>
            <path class="cls-6" d="M399.24,554.08c9.81,14.16,22.06,26.44,34.24,38.63"/>
            <path class="cls-6" d="M399.24,563a264.14,264.14,0,0,0,27.66,31.64"/>
            <path class="cls-6" d="M399.67,572.37a214.62,214.62,0,0,0,19.44,23.24"/>
            <path class="cls-6" d="M398.93,579.54l10.78,14.68"/>
            <path class="cls-6" d="M399.94,593.72l-.49-1"/>
            <path class="cls-6" d="M467.53,554.52l3.93,3.41"/>
            <path class="cls-6" d="M455,554.68l17.47,9.59"/>
            <path class="cls-6" d="M444.05,555.46A240.85,240.85,0,0,0,471,570.12"/>
            <path class="cls-6" d="M434.18,555.3c13.23,6.85,24.14,18.43,38.55,22.17"/>
            <path class="cls-6" d="M434.73,563.76c3.62,1.41,6.61,4,9.63,6.47a124.44,124.44,0,0,0,28.08,17"/>
            <path class="cls-6" d="M435.36,571a168.94,168.94,0,0,0,21.24,14.78c5,2.92,10.07,5.58,15.21,8.18"/>
            <path class="cls-6" d="M435.54,577.47c.5-.48,1.31,0,1.8.51a89.14,89.14,0,0,0,30.4,19.63"/>
            <path class="cls-6" d="M435.3,583.82a72,72,0,0,0,14.25,11.61"/>
            <path class="cls-6" d="M434.7,589.31l3.92,3.92"/>
            <path class="cls-6" d="M504.69,556.68l5,1.11"/>
            <path class="cls-6" d="M486.61,557q12.08,1.42,24,4"/>
            <path class="cls-6" d="M473.93,557.33q13.33,4.08,26.91,7.38c2.9.7,5.91,1.38,8.86.87"/>
            <path class="cls-6" d="M473,561.16a193.64,193.64,0,0,0,36.59,8.44"/>
            <path class="cls-6" d="M472.91,565.76c7.73.91,15.16,3.45,22.73,5.25a120.43,120.43,0,0,0,13,2.37"/>
            <path class="cls-6" d="M473.35,570c12.27,1.31,23.53,9,35.86,8.42"/>
            <path class="cls-6" d="M473.85,574.41c3.37-.35,6.62,1.14,9.75,2.42a91.56,91.56,0,0,0,25.58,6.39"/>
            <path class="cls-6" d="M473.91,578.93q15.93,4.38,32.06,8a6.68,6.68,0,0,0,3.78.11"/>
            <path class="cls-6" d="M474.33,582.23c2.29-.43,4.54.74,6.63,1.77a66.9,66.9,0,0,0,26.24,6.72"/>
            <path class="cls-6" d="M473.9,586.16l5.23,1.78c9.61,3.29,19.36,6.6,29.5,7.11"/>
            <path class="cls-6" d="M473.83,589.93c5.11,2.14,10,4.84,15.15,6.79s10.82,3.16,16.26,2.15"/>
            <path class="cls-6" d="M509.63,581.48l4.63,10.4"/>
            <path class="cls-6" d="M510,563.42q6.13,16.11,13.14,31.87"/>
            <path class="cls-6" d="M511.57,553.08q9,21.06,19,41.67"/>
            <path class="cls-6" d="M518.7,554.68c5.86,8.4,9.48,18.12,13,27.72l4.44,12"/>
            <path class="cls-6" d="M526.65,553.9,540.58,586c1.27,2.93,2.59,5.92,4.79,8.22"/>
            <path class="cls-6" d="M532,554.64A216.7,216.7,0,0,1,547.14,584"/>
            <path class="cls-6" d="M539.33,555.64a98.51,98.51,0,0,1,9,16"/>
            <path class="cls-6" d="M577.68,554.55l6.75,7.92"/>
            <path class="cls-6" d="M570,556.24a56.47,56.47,0,0,0,12.17,13.4"/>
            <path class="cls-6" d="M562,556.49q10.77,12.22,21.87,24.15"/>
            <path class="cls-6" d="M554.39,554.76a273.23,273.23,0,0,0,29,32.34"/>
            <path class="cls-6" d="M548.1,554.08c9.81,14.16,22.06,26.44,34.24,38.63"/>
            <path class="cls-6" d="M548.1,563a264.14,264.14,0,0,0,27.66,31.64"/>
            <path class="cls-6" d="M548.53,572.37A212.34,212.34,0,0,0,568,595.61"/>
            <path class="cls-6" d="M547.78,579.54l10.79,14.68"/>
            <path class="cls-6" d="M548.79,593.72l-.49-1"/>
            <path class="cls-6" d="M616.39,554.52l3.93,3.41"/>
            <path class="cls-6" d="M603.83,554.68l17.47,9.59"/>
            <path class="cls-6" d="M592.91,555.46a240.85,240.85,0,0,0,26.93,14.66"/>
            <path class="cls-6" d="M583,555.3c13.22,6.85,24.14,18.43,38.55,22.17"/>
            <path class="cls-6" d="M583.59,563.76c3.62,1.41,6.61,4,9.63,6.47a124.44,124.44,0,0,0,28.08,17"/>
            <path class="cls-6" d="M584.22,571a168.22,168.22,0,0,0,21.24,14.78c5,2.92,10.07,5.58,15.21,8.18"/>
            <path class="cls-6" d="M584.4,577.47c.5-.48,1.31,0,1.8.51a89.14,89.14,0,0,0,30.4,19.63"/>
            <path class="cls-6" d="M584.16,583.82a72,72,0,0,0,14.25,11.61"/>
            <path class="cls-6" d="M583.56,589.31l3.92,3.92"/>
            <path class="cls-6" d="M653.54,556.68l5,1.11"/>
            <path class="cls-6" d="M635.47,557q12.07,1.42,24,4"/>
            <path class="cls-6" d="M622.79,557.33q13.34,4.08,26.9,7.38c2.91.7,5.92,1.38,8.87.87"/>
            <path class="cls-6" d="M621.83,561.16a193.92,193.92,0,0,0,36.6,8.44"/>
            <path class="cls-6" d="M621.76,565.76c7.73.91,15.17,3.45,22.74,5.25a120,120,0,0,0,13,2.37"/>
            <path class="cls-6" d="M622.2,570c12.27,1.31,23.54,9,35.87,8.42"/>
            <path class="cls-6" d="M622.7,574.41c3.37-.35,6.63,1.14,9.76,2.42A91.56,91.56,0,0,0,658,583.22"/>
            <path class="cls-6" d="M622.77,578.93q15.93,4.38,32.06,8a6.68,6.68,0,0,0,3.78.11"/>
            <path class="cls-6" d="M623.19,582.23c2.29-.43,4.54.74,6.63,1.77a66.9,66.9,0,0,0,26.24,6.72"/>
            <path class="cls-6" d="M622.76,586.16l5.23,1.78c9.6,3.29,19.36,6.6,29.5,7.11"/>
            <path class="cls-6" d="M622.69,589.93c5.1,2.14,10,4.84,15.15,6.79s10.81,3.16,16.26,2.15"/>
            <path class="cls-6" d="M659.44,612.1l-4.63-10.4"/>
            <path class="cls-6" d="M659.1,630.16Q653,614.05,646,598.28"/>
            <path class="cls-6" d="M657.5,640.5q-9-21.06-19-41.67"/>
            <path class="cls-6" d="M650.37,638.9c-5.86-8.4-9.49-18.12-13-27.72l-4.44-12"/>
            <path class="cls-6" d="M642.42,639.68l-13.94-32.09c-1.27-2.93-2.58-5.92-4.79-8.22"/>
            <path class="cls-6" d="M637.07,638.94a218.52,218.52,0,0,1-15.15-29.37"/>
            <path class="cls-6" d="M629.74,637.94a99.31,99.31,0,0,1-9-16"/>
            <path class="cls-6" d="M591.39,639l-6.75-7.93"/>
            <path class="cls-6" d="M599.08,637.34a56.28,56.28,0,0,0-12.17-13.4"/>
            <path class="cls-6" d="M607,637.08q-10.78-12.21-21.87-24.14"/>
            <path class="cls-6" d="M614.68,638.82a274.21,274.21,0,0,0-28.95-32.34"/>
            <path class="cls-6" d="M621,639.5c-9.82-14.17-22.07-26.44-34.24-38.63"/>
            <path class="cls-6" d="M621,630.55a265.81,265.81,0,0,0-27.67-31.63"/>
            <path class="cls-6" d="M620.54,621.21A213.66,213.66,0,0,0,601.1,598"/>
            <path class="cls-6" d="M621.28,614,610.5,599.36"/>
            <path class="cls-6" d="M620.27,599.86l.49,1"/>
            <path class="cls-6" d="M552.68,639.05l-3.94-3.4"/>
            <path class="cls-6" d="M565.23,638.9l-17.47-9.59"/>
            <path class="cls-6" d="M576.16,638.12a241.73,241.73,0,0,0-26.93-14.66"/>
            <path class="cls-6" d="M586,638.28c-13.22-6.85-24.13-18.43-38.54-22.17"/>
            <path class="cls-6" d="M585.48,629.82c-3.63-1.41-6.61-4-9.64-6.47a124.15,124.15,0,0,0-28.07-17.05"/>
            <path class="cls-6" d="M584.85,622.61a170.07,170.07,0,0,0-21.24-14.79c-5-2.91-10.07-5.58-15.21-8.17"/>
            <path class="cls-6" d="M584.66,616.11c-.49.48-1.3,0-1.79-.51A88.93,88.93,0,0,0,552.46,596"/>
            <path class="cls-6" d="M584.9,609.76a71.63,71.63,0,0,0-14.24-11.61"/>
            <path class="cls-6" d="M585.5,604.27l-3.91-3.92"/>
            <path class="cls-6" d="M515.52,636.9l-5-1.11"/>
            <path class="cls-6" d="M533.6,636.62c-8.05-1-16.06-2.3-24-4"/>
            <path class="cls-6" d="M546.28,636.24q-13.35-4.08-26.91-7.37c-2.9-.7-5.92-1.38-8.86-.87"/>
            <path class="cls-6" d="M547.23,632.42A193.82,193.82,0,0,0,510.64,624"/>
            <path class="cls-6" d="M547.3,627.82c-7.73-.91-15.16-3.45-22.73-5.26a122.88,122.88,0,0,0-13-2.36"/>
            <path class="cls-6" d="M546.86,623.58c-12.27-1.31-23.53-9-35.86-8.42"/>
            <path class="cls-6" d="M546.36,619.16c-3.37.36-6.62-1.13-9.76-2.41a91.77,91.77,0,0,0-25.57-6.4"/>
            <path class="cls-6" d="M546.3,614.65q-15.93-4.39-32.06-8a6.59,6.59,0,0,0-3.78-.11"/>
            <path class="cls-6" d="M545.88,611.34c-2.29.43-4.54-.74-6.64-1.76A66.81,66.81,0,0,0,513,602.86"/>
            <path class="cls-6" d="M546.31,607.42l-5.23-1.79c-9.61-3.28-19.37-6.59-29.5-7.1"/>
            <path class="cls-6" d="M546.38,603.65c-5.11-2.14-10-4.84-15.15-6.8s-10.82-3.16-16.26-2.14"/>
            <path class="cls-6" d="M510.58,612.1,506,601.7"/>
            <path class="cls-6" d="M510.25,630.16q-6.13-16.11-13.14-31.88"/>
            <path class="cls-6" d="M508.64,640.5q-9-21.06-19-41.67"/>
            <path class="cls-6" d="M501.51,638.9c-5.86-8.4-9.48-18.12-13.05-27.72l-4.44-12"/>
            <path class="cls-6" d="M493.56,639.68l-13.93-32.09c-1.27-2.93-2.59-5.92-4.79-8.22"/>
            <path class="cls-6" d="M488.21,638.94a217.31,217.31,0,0,1-15.14-29.37"/>
            <path class="cls-6" d="M480.88,637.94a98.51,98.51,0,0,1-9-16"/>
            <path class="cls-6" d="M442.53,639l-6.75-7.93"/>
            <path class="cls-6" d="M450.22,637.34a56.28,56.28,0,0,0-12.17-13.4"/>
            <path class="cls-6" d="M458.18,637.08q-10.77-12.21-21.87-24.14"/>
            <path class="cls-6" d="M465.82,638.82a272.45,272.45,0,0,0-28.95-32.34"/>
            <path class="cls-6" d="M472.11,639.5c-9.81-14.17-22.06-26.44-34.24-38.63"/>
            <path class="cls-6" d="M472.11,630.55a264.83,264.83,0,0,0-27.66-31.63"/>
            <path class="cls-6" d="M471.68,621.21A213.66,213.66,0,0,0,452.24,598"/>
            <path class="cls-6" d="M472.42,614l-10.78-14.68"/>
            <path class="cls-6" d="M471.41,599.86l.49,1"/>
            <path class="cls-6" d="M403.82,639.05l-3.93-3.4"/>
            <path class="cls-6" d="M416.37,638.9l-17.47-9.59"/>
            <path class="cls-6" d="M427.3,638.12a240.85,240.85,0,0,0-26.93-14.66"/>
            <path class="cls-6" d="M437.17,638.28c-13.23-6.85-24.14-18.43-38.55-22.17"/>
            <path class="cls-6" d="M436.62,629.82c-3.62-1.41-6.61-4-9.63-6.47a124.44,124.44,0,0,0-28.08-17.05"/>
            <path class="cls-6" d="M436,622.61a170.07,170.07,0,0,0-21.24-14.79c-5-2.91-10.07-5.58-15.21-8.17"/>
            <path class="cls-6" d="M435.81,616.11c-.5.48-1.31,0-1.8-.51A89,89,0,0,0,403.61,596"/>
            <path class="cls-6" d="M436.05,609.76a72,72,0,0,0-14.25-11.61"/>
            <path class="cls-6" d="M436.65,604.27l-3.92-3.92"/>
            <path class="cls-6" d="M366.66,636.9l-5-1.11"/>
            <path class="cls-6" d="M384.74,636.62q-12.08-1.43-24-4"/>
            <path class="cls-6" d="M397.42,636.24q-13.34-4.08-26.91-7.37c-2.9-.7-5.91-1.38-8.86-.87"/>
            <path class="cls-6" d="M398.37,632.42A193.64,193.64,0,0,0,361.78,624"/>
            <path class="cls-6" d="M398.44,627.82c-7.73-.91-15.16-3.45-22.73-5.26a122.88,122.88,0,0,0-13-2.36"/>
            <path class="cls-6" d="M398,623.58c-12.27-1.31-23.53-9-35.86-8.42"/>
            <path class="cls-6" d="M397.5,619.16c-3.37.36-6.62-1.13-9.75-2.41a91.92,91.92,0,0,0-25.58-6.4"/>
            <path class="cls-6" d="M397.44,614.65q-15.93-4.39-32.06-8a6.59,6.59,0,0,0-3.78-.11"/>
            <path class="cls-6" d="M397,611.34c-2.29.43-4.54-.74-6.63-1.76a66.9,66.9,0,0,0-26.24-6.72"/>
            <path class="cls-6" d="M397.45,607.42l-5.23-1.79c-9.61-3.28-19.36-6.59-29.5-7.1"/>
            <path class="cls-6" d="M397.52,603.65c-5.11-2.14-10-4.84-15.15-6.8s-10.82-3.16-16.26-2.14"/>
            <path class="cls-6" d="M361.72,612.1l-4.63-10.4"/>
            <path class="cls-6" d="M361.39,630.16q-6.13-16.11-13.14-31.88"/>
            <path class="cls-6" d="M359.78,640.5q-9-21.06-19-41.67"/>
            <path class="cls-6" d="M352.65,638.9c-5.86-8.4-9.48-18.12-13-27.72l-4.44-12"/>
            <path class="cls-6" d="M344.7,639.68l-13.93-32.09c-1.27-2.93-2.59-5.92-4.79-8.22"/>
            <path class="cls-6" d="M339.36,638.94a216.22,216.22,0,0,1-15.15-29.37"/>
            <path class="cls-6" d="M332,637.94a98.51,98.51,0,0,1-9-16"/>
            <path class="cls-6" d="M293.67,639l-6.75-7.93"/>
            <path class="cls-6" d="M301.36,637.34a56.28,56.28,0,0,0-12.17-13.4"/>
            <path class="cls-6" d="M309.32,637.08q-10.77-12.21-21.87-24.14"/>
            <path class="cls-6" d="M317,638.82A272.45,272.45,0,0,0,288,606.48"/>
            <path class="cls-6" d="M323.25,639.5c-9.81-14.17-22.06-26.44-34.24-38.63"/>
            <path class="cls-6" d="M323.25,630.55a264.83,264.83,0,0,0-27.66-31.63"/>
            <path class="cls-6" d="M322.82,621.21A211.4,211.4,0,0,0,303.38,598"/>
            <path class="cls-6" d="M323.57,614l-10.79-14.68"/>
            <path class="cls-6" d="M322.56,599.86l.49,1"/>
            <path class="cls-6" d="M255,639.05l-3.93-3.4"/>
            <path class="cls-6" d="M267.52,638.9l-17.47-9.59"/>
            <path class="cls-6" d="M278.44,638.12a240.85,240.85,0,0,0-26.93-14.66"/>
            <path class="cls-6" d="M288.31,638.28c-13.22-6.85-24.14-18.43-38.55-22.17"/>
            <path class="cls-6" d="M287.76,629.82c-3.62-1.41-6.61-4-9.63-6.47a124.44,124.44,0,0,0-28.08-17.05"/>
            <path class="cls-6" d="M287.13,622.61a169.33,169.33,0,0,0-21.24-14.79c-5-2.91-10.07-5.58-15.21-8.17"/>
            <path class="cls-6" d="M287,616.11c-.5.48-1.31,0-1.8-.51A89,89,0,0,0,254.75,596"/>
            <path class="cls-6" d="M287.19,609.76a72,72,0,0,0-14.25-11.61"/>
            <path class="cls-6" d="M287.79,604.27l-3.92-3.92"/>
            <path class="cls-6" d="M217.81,636.9l-5-1.11"/>
            <path class="cls-6" d="M235.88,636.62q-12.07-1.43-24-4"/>
            <path class="cls-6" d="M248.56,636.24q-13.34-4.08-26.9-7.37c-2.91-.7-5.92-1.38-8.87-.87"/>
            <path class="cls-6" d="M249.52,632.42a193.92,193.92,0,0,0-36.6-8.44"/>
            <path class="cls-6" d="M249.59,627.82c-7.73-.91-15.17-3.45-22.74-5.26a122.42,122.42,0,0,0-13-2.36"/>
            <path class="cls-6" d="M249.15,623.58c-12.27-1.31-23.54-9-35.87-8.42"/>
            <path class="cls-6" d="M248.65,619.16c-3.37.36-6.63-1.13-9.76-2.41a91.92,91.92,0,0,0-25.58-6.4"/>
            <path class="cls-6" d="M248.58,614.65q-15.93-4.39-32.06-8a6.59,6.59,0,0,0-3.78-.11"/>
            <path class="cls-6" d="M248.16,611.34c-2.29.43-4.54-.74-6.63-1.76a66.9,66.9,0,0,0-26.24-6.72"/>
            <path class="cls-6" d="M248.59,607.42l-5.23-1.79c-9.6-3.28-19.36-6.59-29.5-7.1"/>
            <path class="cls-6" d="M248.66,603.65c-5.1-2.14-10-4.84-15.15-6.8s-10.81-3.16-16.26-2.14"/>
            <path class="cls-6" d="M212.86,612.1l-4.62-10.4"/>
            <path class="cls-6" d="M212.53,630.16q-6.12-16.11-13.13-31.88"/>
            <path class="cls-6" d="M210.92,640.5q-9-21.06-19-41.67"/>
            <path class="cls-6" d="M203.79,638.9c-5.86-8.4-9.48-18.12-13-27.72l-4.44-12"/>
            <path class="cls-6" d="M195.84,639.68l-13.93-32.09c-1.27-2.93-2.58-5.92-4.79-8.22"/>
            <path class="cls-6" d="M190.5,638.94a217.37,217.37,0,0,1-15.15-29.37"/>
            <path class="cls-6" d="M183.17,637.94a97.82,97.82,0,0,1-9-16"/>
            <path class="cls-6" d="M144.81,639l-6.75-7.93"/>
            <path class="cls-6" d="M152.5,637.34a56.07,56.07,0,0,0-12.17-13.4"/>
            <path class="cls-6" d="M160.46,637.08q-10.77-12.21-21.87-24.14"/>
            <path class="cls-6" d="M168.11,638.82a273.41,273.41,0,0,0-29-32.34"/>
            <path class="cls-6" d="M174.39,639.5c-9.81-14.17-22.06-26.44-34.24-38.63"/>
            <path class="cls-6" d="M174.39,630.55a264.83,264.83,0,0,0-27.66-31.63"/>
            <path class="cls-6" d="M174,621.21A212.43,212.43,0,0,0,154.53,598"/>
            <path class="cls-6" d="M174.71,614l-10.79-14.68"/>
            <path class="cls-6" d="M173.7,599.86l.49,1"/>
            <path class="cls-6" d="M106.1,639.05l-3.93-3.4"/>
            <path class="cls-6" d="M118.66,638.9l-17.47-9.59"/>
            <path class="cls-6" d="M129.58,638.12a240.85,240.85,0,0,0-26.93-14.66"/>
            <path class="cls-6" d="M139.45,638.28c-13.22-6.85-24.14-18.43-38.55-22.17"/>
            <path class="cls-6" d="M138.9,629.82c-3.62-1.41-6.61-4-9.63-6.47a124.44,124.44,0,0,0-28.08-17.05"/>
            <path class="cls-6" d="M138.27,622.61A168.61,168.61,0,0,0,117,607.82c-5-2.91-10.07-5.58-15.21-8.17"/>
            <path class="cls-6" d="M138.09,616.11c-.5.48-1.3,0-1.8-.51A89,89,0,0,0,105.89,596"/>
            <path class="cls-6" d="M138.33,609.76a72.2,72.2,0,0,0-14.24-11.61"/>
            <path class="cls-6" d="M138.93,604.27,135,600.35"/>
            <path class="cls-6" d="M69,636.9l-5-1.11"/>
            <path class="cls-6" d="M87,636.62q-12.08-1.43-24-4"/>
            <path class="cls-6" d="M99.7,636.24q-13.33-4.08-26.9-7.37c-2.91-.7-5.92-1.38-8.87-.87"/>
            <path class="cls-6" d="M100.66,632.42A193.92,193.92,0,0,0,64.06,624"/>
            <path class="cls-6" d="M100.73,627.82c-7.73-.91-15.16-3.45-22.74-5.26a122.42,122.42,0,0,0-13-2.36"/>
            <path class="cls-6" d="M100.29,623.58c-12.27-1.31-23.54-9-35.86-8.42"/>
            <path class="cls-6" d="M99.79,619.16c-3.37.36-6.62-1.13-9.76-2.41a91.83,91.83,0,0,0-25.58-6.4"/>
            <path class="cls-6" d="M99.73,614.65q-15.95-4.39-32.06-8a6.63,6.63,0,0,0-3.79-.11"/>
            <path class="cls-6" d="M99.31,611.34c-2.3.43-4.55-.74-6.64-1.76a66.81,66.81,0,0,0-26.23-6.72"/>
            <path class="cls-6" d="M99.73,607.42l-5.23-1.79c-9.6-3.28-19.36-6.59-29.5-7.1"/>
            <path class="cls-6" d="M99.8,603.65c-5.1-2.14-10-4.84-15.15-6.8s-10.81-3.16-16.26-2.14"/>
        </g>
    </g>
</template>

<script>
export default {
    name: 'Owl'
}
</script>

<style>

</style>