<template>
    <div class="question" :data-set="set" :data-number="number" :data-q="question">
        <h3 class="question__title">{{questions[question].q}}</h3>
        <!-- <p class="question__inst">How strongly do you agree with the following statement?</p> -->
        <p class="question__inst" :style="{'color': color}">Drag the slider to a point on the scale</p>
        <!-- <p class="question__q">{{questions[question].q}}</p> -->

        <div class="slider__row">
                        
            <div class="slider__plate">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 85.34 34.47">
                    <g :style="{'fill': color}">
                        <path class="cls-1" d="M.06,34.47C-.51,34.47,3.47,0,3.47,0L85.34,2.32,83.07,34.47Z"/>
                    </g>
                </svg>
                <p class="slider__plate__text">Disagree</p>
            </div>

            <div class="question__slider">
                <svg class="question__slider__handle ui-slider-handle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34.99 36.31">
                    <g :style="{'fill': color}">
                        <path class="cls-1" d="M34.89,18.32c0-.33.08-.66.09-1A12.52,12.52,0,0,0,32.4,9.47l-.24-.3A14.48,14.48,0,0,0,31.07,7.3a15.12,15.12,0,0,0-3.19-3.38A14.22,14.22,0,0,0,25,1.83l-.34-.17A10.5,10.5,0,0,0,19.47,0,13.65,13.65,0,0,0,13,1.4,12.81,12.81,0,0,0,5.16,6.12a15.81,15.81,0,0,0-1.5,2.23A17,17,0,0,0,.14,16.62a15.17,15.17,0,0,0,1.65,9.19,15.57,15.57,0,0,0,3.35,5.72,11.16,11.16,0,0,0,6.56,4.18q.5.1,1,.18a13.71,13.71,0,0,0,5.31.28A11.8,11.8,0,0,0,19.55,36,15,15,0,0,0,24.39,34a12.72,12.72,0,0,0,7.45-6.43c.09-.16.16-.33.24-.5A15.91,15.91,0,0,0,34.89,18.32ZM19.27,30.43s0,0,0,0h-.14ZM17.56,5.91a2.79,2.79,0,0,0-.39-.1c.19,0,.39.07.58.11Z"/>
                    </g>
                </svg>
            </div>

            <div class="slider__plate">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 61.42 33.01">
                    <g :style="{'fill': color}">
                        <path  d="M.39,30.84C0,33,0,2.45,0,2.45L61.42,0,60,33Z"/>
                    </g>
                </svg>
                <p class="slider__plate__text">Agree</p>
            </div>

        </div>
    </div>
</template>

<script>
import * as $ from 'jquery';
import 'jquery-ui/ui/widgets/slider';
import {questions} from '../tables';
import key from '../fixtures/key';
import scrollTo from '../helpers/scroll.js';

// touch punch
!function(a){function f(a,b){if(!(a.originalEvent.touches.length>1)){a.preventDefault();var c=a.originalEvent.changedTouches[0],d=document.createEvent("MouseEvents");d.initMouseEvent(b,!0,!0,window,1,c.screenX,c.screenY,c.clientX,c.clientY,!1,!1,!1,!1,0,null),a.target.dispatchEvent(d)}}if(a.support.touch="ontouchend"in document,a.support.touch){var e,b=a.ui.mouse.prototype,c=b._mouseInit,d=b._mouseDestroy;b._touchStart=function(a){var b=this;!e&&b._mouseCapture(a.originalEvent.changedTouches[0])&&(e=!0,b._touchMoved=!1,f(a,"mouseover"),f(a,"mousemove"),f(a,"mousedown"))},b._touchMove=function(a){e&&(this._touchMoved=!0,f(a,"mousemove"))},b._touchEnd=function(a){e&&(f(a,"mouseup"),f(a,"mouseout"),this._touchMoved||f(a,"click"),e=!1)},b._mouseInit=function(){var b=this;b.element.bind({touchstart:a.proxy(b,"_touchStart"),touchmove:a.proxy(b,"_touchMove"),touchend:a.proxy(b,"_touchEnd")}),c.call(b)},b._mouseDestroy=function(){var b=this;b.element.unbind({touchstart:a.proxy(b,"_touchStart"),touchmove:a.proxy(b,"_touchMove"),touchend:a.proxy(b,"_touchEnd")}),d.call(b)}}}($);

export default {    
    name: 'question',
    props: {
        msg: String,
        color: String,
        set: Number,
        number: Number
    },
    data: function () {

        return {
            title: 'Lorem Ipsum',
            questions: questions,
            question: this.number === 1 ? this.set - 1: (((this.number - 1) * 7) + this.set) - 1,
            answer: 0,
            submitted: false
        };
    },
    methods: {

        submit: function() {
            if (this.submitted === false) {
                var cAns = this.questions[this.question].answers;

                // set other answers to false
                for (var i = 0; i < cAns.length; i++) {
                    cAns[i].selected = false;
                }
                this.questions[this.question].answers[this.answer].selected = true;
                this.submitted = true;
                localStorage.setItem(key, JSON.stringify(this.questions));

            }
        },
        restoreAnswers: function () {
            const q = JSON.parse(localStorage.getItem(key))

            const qa = q[this.question].answers;

            for (let i = 0; i < qa.length; i++) {
                const aa = qa[i];

                if (aa.selected === true) {
                    const question = $(".question[data-q='"+this.question+"']");

                    question.addClass("answered");
                    question.attr("data-value", i * 100);
                    question.find(".question__slider").slider('value', i * 100);
                }
                
            }
            
        }

    },
    computed: {

    },
    mounted: function () {
        
        var data = this;

        const question = $(".question[data-set='"+this.set+"'][data-number='"+this.number+"']");
        question.find(".question__slider").slider({
            range: "min",
            value: 450,
            min: 0,
            max: 900,
            change:function(event, ui) {    
                var val = Math.round(ui.value / 100)         
                data.answer = val;
                question.addClass("answered");
                question.attr("data-value", val);
                question.next(".question").slideDown();
                question.closest(".accordian").addClass("passed")
                data.$parent.answered();
                // question.closest(".accordian").next(".accordian").slideDown();
                setTimeout(function() {
                    scrollTo(question.next(".question"))
                },250);
            }
        });
        data.answer = 5;
        question.find(".ui-slider-range, .ui-slider-handle").css({"background-color": this.color})

        try {
            this.restoreAnswers();
        } catch (e) {
            // failed to restore answers, likely nothing in local storage
        }

    }
}
</script>

<style lang="scss">
    .slider {
        position: relative;

        &__row {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &__plate {
            width: 220px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            & svg {
                max-height: 50px;
            }
            &__text {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                color: white;
                text-transform: uppercase;
                font-family: "Bitter";
                @media (max-width: 576px) {
                    font-size: 12px;
                }
            }
        }
    }

    .question {
        font-family: Helvetica;
        font-size: 16px;
        margin: 40px 0;
        max-width: 576px;
        &:not(:first-of-type) {
            display: none;
        }
        &__title {            
            font-family: 'futura-pt';
            font-weight: 700;
            color: white;
        }
        &__inst {
            margin-bottom: 40px;
            font-size: 14px;
            font-family: "Bitter";
        }
        &__slider {
            background: rgba(0,0,0,0.2);    
            height: 3px;
            position: relative;
            width: 100%;
            margin: 0 20px;
            &:before {
                content: "";
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                height: 20px;
                width: 1px;
                background: #959595;
            }
        }
        &__key {                    
            margin-top: 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 12px;
        }
        .ui-slider-range,.ui-corner-all {
            border-radius: 50px!important;
            border: none!important;  
        }  
        .ui-slider-handle {
            background: none!important;
            outline: none!important;            
            height: 30px;
            width: 30px;
            top: 50%;
            margin-left: -15px;
            margin-top: -15px;
            box-shadow: none!important;
            cursor: pointer;
        }

        &__submit {
            float: right;
            border: 1px solid #515151;
            padding: 0px 15px;
            cursor: pointer;
        }
    }
</style>