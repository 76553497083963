<template>
    <g id="leaf" class="nature-outline">
        <g class="cls-2">
            <path class="cls-3" d="M172,338.65A655,655,0,0,0,48.87,323"/>
            <path class="cls-3" d="M341.26,176.22A2174.21,2174.21,0,0,1,170.9,338.81"/>
            <path class="cls-3" d="M372,289.18c-70.95,23.63-125.39,33.95-196.68,44"/>
            <path class="cls-3" d="M169.43,338.63c-.77,5.64-.35,11.36.11,17,3.15,38.83,5.71,59.41,13,97.67"/>
            <path class="cls-3" d="M182.83,327.69c7.62-13.05,6.14-29.33,10-43.93,2.25-8.45,6.3-16.29,10.31-24.05L255,159.51"/>
            <path class="cls-3" d="M192.51,319.44q45.7-80.13,98.69-155.79"/>
            <path class="cls-3" d="M198.06,315.52c42.37-49.2,81.16-96,122.94-145.76"/>
            <path class="cls-3" d="M183.28,325.93c64.2-21.12,120.7-44.29,181.65-73.48"/>
            <path class="cls-3" d="M196.62,316.26c48.18-29.1,96.81-58.53,137.39-97.53,6.24-6,12.26-12.2,18.6-18.08"/>
            <path class="cls-3" d="M173.92,335c64.55,16.15,90.82,24.61,156.05,37.74"/>
            <path class="cls-3" d="M173.84,336.53a811.71,811.71,0,0,0,94.6,51.63"/>
            <path class="cls-3" d="M170.88,338.21c9.14,24.06,25.76,44.45,38.05,67.07,4.46,8.2,8.35,16.7,12.62,25,8.21,16,2,8.48,12.85,22.76"/>
            <path class="cls-3" d="M176.57,333.49a551.64,551.64,0,0,1-76.12-101.12"/>
            <path class="cls-3" d="M172.63,336.86,66.82,437.66"/>
            <path class="cls-3" d="M171.8,337.46A312,312,0,0,0,49.32,318.58"/>
            <path class="cls-3" d="M172.37,337c-37.22-18.55-79.49-24.88-121.08-25.17"/>
            <path class="cls-3" d="M171.81,337.07A347.54,347.54,0,0,0,52.42,303.66"/>
            <path class="cls-3" d="M170.55,338.73C130.31,326,88,317.44,46,321.81"/>
            <path class="cls-3" d="M171.22,338.84c-11.67-7.53-25.36-11.17-38.84-14.51-26.72-6.63-54.3-12.57-81.6-9"/>
            <path class="cls-3" d="M174.08,336.36l-40.66-21.44-14.17-7.47C100,297.32,80.82,287.18,61,278.36"/>
            <path class="cls-3" d="M173.58,336.11Q118.85,303.17,62.51,273"/>
            <path class="cls-3" d="M174.31,334.73c-37.82-19.11-70.62-46.89-107.79-67.21"/>
            <path class="cls-3" d="M174.86,335.19c-13.09-6.63-24.87-15.54-36.57-24.4L71.61,260.33"/>
            <path class="cls-3" d="M175.72,334.63c-8.23-4.76-15.42-11.09-22.55-17.38Q116,284.45,79.05,251.45"/>
            <path class="cls-3" d="M174.36,335.73c-3.74-4.06-8.31-7.26-12.45-10.92-3.61-3.2-6.9-6.74-10.17-10.27-22-23.7-44.06-47.52-62.19-74.28"/>
            <path class="cls-3" d="M173.91,335.27c-3-2.89-6.74-4.91-10.2-7.27a120.76,120.76,0,0,1-14.38-12.18c-22.82-21.46-45.7-43-66.1-66.76"/>
            <path class="cls-3" d="M173.38,336.39Q124.54,297.2,77.44,255.9"/>
            <path class="cls-3" d="M177.07,331.36l-9.78-22.18c-3-6.92-6.1-13.85-8.5-21-9.93-29.69-8.22-62.08-16-92.4"/>
            <path class="cls-3" d="M176.81,333.45c-2.46-10-10.48-17.52-14.94-26.76a80.19,80.19,0,0,1-4.09-11.24c-4-12.9-8-25.89-9.69-39.29-.71-5.58-1-11.2-1.56-16.8A214.93,214.93,0,0,0,138.17,197"/>
            <path class="cls-3" d="M174.83,334.08c-2-1.77-2.67-4.55-3.93-6.9-1.88-3.53-5.08-6.13-7.76-9.1-3.38-3.73-5.95-8.1-8.66-12.35C138,279.92,116,257.63,102.83,230"/>
            <path class="cls-3" d="M174.38,334.17a119.88,119.88,0,0,0-9.59-16.23q-14.1-21.13-28.85-41.82c-11.29-15.83-23-31.67-30.14-49.74"/>
            <path class="cls-3" d="M173.72,335.13c.18-7.32-3.75-14-7.54-20.29-3.33-5.49-6.67-11-10.4-16.22-3.46-4.85-7.25-9.45-10.82-14.2-10.17-13.54-18.59-28.31-26.78-43.13-3.55-6.42-7.14-13.1-7.68-20.41"/>
            <path class="cls-3" d="M174.87,335.41c-.46-13.83-8.2-26.21-15.63-37.89q-24.36-38.26-47.79-77.11"/>
            <path class="cls-3" d="M173.65,335.61c1.23-4.71.17-9.76-1.72-14.24s-4.58-8.59-6.8-12.92c-4.15-8.07-6.66-16.9-10.8-25C148,271.17,138,261,132.32,248.35c-1.59-3.5-2.84-7.16-4.56-10.6-2-4.07-4.71-7.81-6.59-12s-2.91-8.94-1.44-13.25"/>
            <path class="cls-3" d="M174,336.24c-1.39-18.27-10-35.11-18.42-51.38l-9.09-17.53c-9.86-19-19.91-38.67-22.08-60"/>
            <path class="cls-3" d="M174.93,335.16c1.43-8.84-.58-17.93-4-26.21s-8.16-15.91-12.52-23.73c-13.05-23.4-22.76-48.91-26.21-75.48-.37-2.85-.66-5.79.13-8.55"/>
            <path class="cls-3" d="M178.82,331.23a280,280,0,0,0-13.2-59.09c-1.89-5.52-4-11-5.11-16.73-1.58-7.82-1.44-15.87-1.85-23.84-.69-13.25-2.92-26.35-5.15-39.43"/>
            <path class="cls-3" d="M179.58,331c7.5-18.41,7.82-38.88,12.42-58.22,9.91-41.65,38.82-75.7,59.22-113.33"/>
            <path class="cls-3" d="M178.86,331.13l10.25-57.58a252.63,252.63,0,0,1,5.74-26.67c9.57-32.79,31.88-60.3,45.24-91.75"/>
            <path class="cls-3" d="M176.52,332.57a92.06,92.06,0,0,0,1.65-24.84c-.82-11.82-3.93-23.34-5.93-35-4-23.15-3.63-46.8-3.26-70.29.08-5.43.21-11,2.49-16"/>
            <path class="cls-3" d="M176.39,332.56c6.67-23,7.66-47.27,10-71.14s6.29-48.21,18.1-69.09c5.42-9.59,12.5-18.47,15.42-29.09"/>
            <path class="cls-3" d="M174.46,334.54a153.23,153.23,0,0,0,3.42-55.26c-1.27-10.44-3.61-20.73-4.66-31.2-2.19-21.66,1.16-43.55,6.48-64.66"/>
            <path class="cls-3" d="M173.85,337.23c7-17.76,7.32-37.3,10.3-56.15,6.86-43.43,27.69-83.22,48.22-122.1"/>
            <path class="cls-3" d="M179.61,331.84c5.1-5.12,7.38-12.31,9.61-19.18a423.92,423.92,0,0,1,18.23-47.35c3.93-8.48,8.23-16.78,12.53-25.09l41.16-79.47"/>
            <path class="cls-3" d="M177.58,332.55c4.18-4.27,6.88-9.73,9.51-15.09L264,160.85"/>
            <path class="cls-3" d="M176.49,332c14.67-13.57,25-31.05,35.15-48.26s20.14-34.22,30.22-51.33c3.71-6.31,7.43-12.63,11-19,10-17.68,19.3-36,32.78-51.23"/>
            <path class="cls-3" d="M175.69,336a453.63,453.63,0,0,1,24-39.1c4.71-6.88,9.62-13.62,14.25-20.55,20.4-30.5,35.29-64.25,51.57-97.13,3.08-6.22,6.26-12.47,10.68-17.82"/>
            <path class="cls-3" d="M174.54,334.09l34-54.65c5.48-8.83,11-17.67,15.63-27,2.86-5.69,5.39-11.54,8.05-17.32,11.63-25.24,25.75-49.24,39.84-73.2"/>
            <path class="cls-3" d="M176.43,333.7c5.32-7.43,12.88-12.91,19-19.66,4.9-5.35,8.88-11.47,12.86-17.54Q251.21,231,296.83,167.38"/>
            <path class="cls-3" d="M182.18,329.54c48.29-53.06,83.79-107.81,132.4-159.69"/>
            <path class="cls-3" d="M185.14,328.29q34.5-48.69,69.64-96.93c16.38-22.49,31.67-40.56,50.31-61.22"/>
            <path class="cls-3" d="M183.7,327q78-74.81,153.45-152.2"/>
            <path class="cls-3" d="M181.24,327.86,292.18,216.23c13.87-13.95,27.77-27.94,39.94-43.4"/>
            <path class="cls-3" d="M176.46,332.61c52.79-51.28,105-103.64,149-162.57"/>
            <path class="cls-3" d="M173.6,333.18c5.56-10.68,4.67-23.6,1.65-35.25s-8-22.77-10.39-34.57a81,81,0,0,1-.56-28.75c.58-3.58,1.4-7.13,1.67-10.75.41-5.25-.34-10.5-.77-15.75a128.4,128.4,0,0,1,.1-21.57"/>
            <path class="cls-3" d="M178.36,333c4.76-10.46,5.95-22.21,5.91-33.7s-1.26-23-1-34.47c.35-18.22,4.31-36.16,8.25-54a118.9,118.9,0,0,1,4-14.87c4.11-11.31,11.61-21.18,15.37-32.6"/>
            <path class="cls-3" d="M184.29,326.08c22.67-20.91,51.51-33.79,76.25-52.21,5.67-4.22,11.12-8.72,16.56-13.23,18.42-15.25,36.85-30.5,54.7-46.41,6-5.32,11.9-10.75,16.66-17.17"/>
            <path class="cls-3" d="M184.84,324.16,311.38,221.94c11.64-9.4,23.31-18.84,33.62-29.69"/>
            <path class="cls-3" d="M191.52,318.73c51.26-45.64,108.94-85.12,151.2-139.2"/>
            <path class="cls-3" d="M183.41,326.66c45.86-44.87,101.7-78.57,147.38-123.63,4.92-4.86,9.76-9.88,13.51-15.69"/>
            <path class="cls-3" d="M177.4,327.26C236.53,286.5,296,245.46,347.19,195.09"/>
            <path class="cls-3" d="M176.64,333q47.28-27.7,96.78-51.35c31.32-14.95,59.85-25.92,85.12-49.7"/>
            <path class="cls-3" d="M180.21,326.09c62.62-26.43,124.51-46.45,178.7-87.48"/>
            <path class="cls-3" d="M180.87,330.64c57.63-35.26,126.57-49.2,183.07-86.26"/>
            <path class="cls-3" d="M178.09,331.19c63.7-22,127.49-44.26,188-74"/>
            <path class="cls-3" d="M173.51,335.18,217.14,323c11.55-3.24,23.13-6.48,35-8.31,6.42-1,12.91-1.57,19.34-2.49,6.85-1,13.63-2.35,20.38-3.85C321.13,301.79,343.2,294.24,371,283"/>
            <path class="cls-3" d="M168.45,339.36C180,333,193.58,331.58,206.71,330a935.73,935.73,0,0,0,163.34-34.24"/>
            <path class="cls-3" d="M169.3,337.12C186,332,203.6,331,221,329.94l74.6-4.36c6.87-.4,13.76-.8,20.56-1.88A97.63,97.63,0,0,0,333,319.49c7.74-2.7,24.94-6.34,31.7-11"/>
            <path class="cls-3" d="M173.66,335.29c31.24-6.07,63.19-7.27,94.88-10.25s63.67-7.87,92.76-20.79"/>
            <path class="cls-3" d="M169.36,339.3c6.57-4,14.56-4.66,22.23-5.11,55.64-3.24,112.46.3,166.11-14.8"/>
            <path class="cls-3" d="M172.33,336.28a658.06,658.06,0,0,0,117,35.13c10.53,2.12,21.23,4,32,3.47"/>
            <path class="cls-3" d="M168.73,337.82a282.74,282.74,0,0,1,60.19,9.1c14.28,3.76,28.28,8.65,42.78,11.5,20.08,3.94,40.73,3.9,61.18,3"/>
            <path class="cls-3" d="M169.06,337.71A34.1,34.1,0,0,1,171,353.34c-.3,2.73-.93,5.41-1.17,8.13a54.93,54.93,0,0,0,.69,12.07,542.63,542.63,0,0,0,15.57,74.53"/>
            <path class="cls-3" d="M170.65,338.16c5.18,42,29,79.39,55.37,112.51"/>
            <path class="cls-3" d="M170.49,339.62a206.51,206.51,0,0,0,51.95,118.63"/>
            <path class="cls-3" d="M171.36,342.22l7,20.8c1.48,4.37,3,8.74,4.7,13a192,192,0,0,0,12.18,23.59q17.83,30.51,38.6,59.18"/>
            <path class="cls-3" d="M170.54,339a375.47,375.47,0,0,0,98,50.95"/>
            <path class="cls-3" d="M170.65,339.34c21.42,14.84,44.11,28.13,68.59,37.06s50.91,13.37,76.78,10.3"/>
            <path class="cls-3" d="M172.13,338.91c18,9.39,36.16,18.75,55.63,24.44,8.25,2.41,16.67,4.14,25.08,5.87l45.73,9.41"/>
            <path class="cls-3" d="M172.69,338c1.12-1.06,2.92-.2,4.19.65,14.05,9.47,29.41,16.77,44.71,24,10.82,5.14,21.69,10.3,33.22,13.53,17.38,4.86,35.66,5.18,53.7,5.46"/>
            <path class="cls-3" d="M171.86,340.38A183.79,183.79,0,0,0,302.73,394c3.1,0,6.42-.22,8.9-2.09"/>
            <path class="cls-3" d="M170.25,337.94c23-5.05,46.88.66,69.47,7.3s45.48,14.35,69,13.29"/>
            <path class="cls-3" d="M173.8,341.08c2.23,7.84,7,14.67,11.55,21.41q26.18,38.5,48.72,79.32"/>
            <path class="cls-3" d="M173.74,342.87c15.39,19,38.1,30.23,60.08,40.91,6.62,3.22,13.28,6.46,20.31,8.65,15.51,4.86,32.11,4.47,48.37,4"/>
            <path class="cls-3" d="M171.58,337.93a134.64,134.64,0,0,0,130.53,64.79"/>
        </g>
        <g class="cls-2">
            <path class="cls-3" d="M94.87,146.19A432.37,432.37,0,0,0,52,215.73"/>
            <path class="cls-3" d="M43.5.54A1432.69,1432.69,0,0,1,94.68,146.86"/>
            <path class="cls-3" d="M94.17,147.69a51.94,51.94,0,0,0,10.24,4.57C128.55,160.92,141.58,165,166.5,171"/>
            <path class="cls-3" d="M92.35,140.38l-25.84-2.46c-10.59-1-21.25-2-31.48-4.92-11.45-3.24-22.36-8.82-34.21-9.94"/>
            <path class="cls-3" d="M91.26,136.68c-5.76-8.11-15.92-11.65-23.62-18-4.45-3.64-8-8.2-11.62-12.72L10,47.71"/>
            <path class="cls-3" d="M88.94,128.64Q53.32,79.47,22.36,27.14"/>
            <path class="cls-3" d="M88.1,124.25c-18-38.77-35.53-74.76-54-113.32"/>
            <path class="cls-3" d="M90.32,135.94A969.67,969.67,0,0,0,95.63,7.07"/>
            <path class="cls-3" d="M88.84,129.92q-.12-28.89-1-57.78c-.74-23.34-2.06-47.28-11.5-68.63"/>
            <path class="cls-3" d="M88.15,125.31C83.8,88.52,79.37,51.37,67,16.44,65.11,11.07,63,5.77,61.23.38"/>
            <path class="cls-3" d="M92.93,142.86C108.88,111.62,130.5,76,146.46,44.75"/>
            <path class="cls-3" d="M93.23,144c27.22-34.31,39.43-47.76,65-83.3"/>
            <path class="cls-3" d="M94.11,144.48a533.4,533.4,0,0,0,56.66-42.68"/>
            <path class="cls-3" d="M94.31,146.71c16.91,1.06,33.65-3.36,50.55-4.59,6.13-.44,12.28-.46,18.42-.77,11.81-.58,5.62,1.13,17.14-1.52"/>
            <path class="cls-3" d="M93.3,145.49,31.75,199"/>
            <path class="cls-3" d="M93,142a363.09,363.09,0,0,1-81.31,18.15"/>
            <path class="cls-3" d="M94,145.29l31.68,90.83"/>
            <path class="cls-3" d="M93.88,145.84a228.61,228.61,0,0,0-52.47,62.5"/>
            <path class="cls-3" d="M93.8,145.84,66.7,170.17c-9.63,8.64-19.26,17.29-29.37,25.35-2,1.57-4,3.18-5.12,5.42"/>
            <path class="cls-3" d="M94.89,146.2q-29.73,27.09-59.41,54.22c-.71.65-1.48,1.47-1.31,2.41"/>
            <path class="cls-3" d="M93.89,147.33,69.77,170.09c-11.64,11-23.37,22.07-32.58,35.16"/>
            <path class="cls-3" d="M94.53,147.05c-18.56,20.67-35.21,43.73-44,70.08"/>
            <path class="cls-3" d="M94.07,144.29l-23.9,18.55-8.33,6.46c-11.29,8.76-22.6,17.54-33.28,27"/>
            <path class="cls-3" d="M93.78,144.52q-34.61,23.86-68,49.45"/>
            <path class="cls-3" d="M94.24,143.85q-33.15,9.1-66.9,15.83c-5,1-10.11,2-15.22,1.69"/>
            <path class="cls-3" d="M93.38,143.8a303.34,303.34,0,0,1-81,21"/>
            <path class="cls-3" d="M93.77,144c-3.45,1.14-6.61,3-9.93,4.49a94.49,94.49,0,0,1-8.92,3.31c-20.19,6.74-40.46,13.51-61.43,17.1"/>
            <path class="cls-3" d="M93.37,144.1a49.72,49.72,0,0,0-7.12,4.13A80.52,80.52,0,0,1,75,153.55C56,161.4,36.85,169.26,17.05,175"/>
            <path class="cls-3" d="M91.89,141.14l-16-.17c-5-.05-10-.1-14.91-.61-20.5-2.12-39.45-11.94-59.75-15.52"/>
            <path class="cls-3" d="M93.07,141.86c-6.64-1.23-13.35,1.52-20.1,1.69a51.59,51.59,0,0,1-7.85-.61c-8.82-1.12-17.69-2.25-26.19-4.86-3.54-1.09-7-2.43-10.5-3.64A141.58,141.58,0,0,0,.78,128"/>
            <path class="cls-3" d="M92.91,143.22c-1.6.71-3.45.37-5.21.48-2.62.17-5,1.38-7.56,2.18a58.4,58.4,0,0,1-9.75,1.83c-20,2.85-39.31,10-59.46,10.39"/>
            <path class="cls-3" d="M92.84,143.52a77.74,77.74,0,0,0-12.34,1.33q-16.5,2.72-32.91,5.93c-12.55,2.47-25.23,5.18-38,4.55"/>
            <path class="cls-3" d="M93.58,143.45c-4.91-3.25-10.9-4.52-16.79-4.73s-11.76.56-17.63,1.05c-17.57,1.47-35.51.36-52.38-4.79a13.94,13.94,0,0,1-5.08-2.41"/>
            <path class="cls-3" d="M92.28,140.05a184.27,184.27,0,0,0-39-8.14,89,89,0,0,1-11.41-1.49c-5.12-1.17-9.91-3.44-14.8-5.36a224.51,224.51,0,0,0-25-7.63"/>
            <path class="cls-3" d="M92.36,139.54c-9-9.5-21.18-15.26-31.53-23.27C38.56,99,26,72.42,9,50"/>
            <path class="cls-3" d="M92.24,140,60.5,118.21a169.14,169.14,0,0,1-14.43-10.69C29,92.87,18.58,72,3.36,55.46"/>
            <path class="cls-3" d="M92.69,141.38C79.37,131,63,125.46,47.64,118.29c-15.11-7.06-29.35-15.85-43.46-24.76"/>
            <path class="cls-3" d="M92.46,141.8A60.55,60.55,0,0,0,78,134.05c-7.31-2.71-15.06-4-22.61-6-15-3.91-29-10.55-43-17.15-3.24-1.53-6.57-3.13-8.91-5.84"/>
            <path class="cls-3" d="M92.42,141.87c-12-10.26-26.25-17.44-39.93-25.33S25.3,99.66,16,86.91C11.71,81.06,8.31,74.4,2.74,69.76"/>
            <path class="cls-3" d="M93.09,143.57a100.9,100.9,0,0,0-32.2-17.07c-6.61-2.08-13.42-3.47-20-5.68-13.58-4.58-25.79-12.54-37-21.46"/>
            <path class="cls-3" d="M94.53,144.66c-8.76-9-20.37-14.52-30.86-21.43C39.5,107.31,21.3,84,3.57,61.14"/>
            <path class="cls-3" d="M92.12,141.66C88,129.18,80.3,118.23,72.74,107.48L50.18,75.41c-2.78-4-5.55-7.89-8.4-11.79C33.9,52.82,25.42,42.26,20,30.05"/>
            <path class="cls-3" d="M93.12,142.15c-3-5.2-4.24-11.23-6.61-16.75a88.5,88.5,0,0,0-7-12.48q-27.6-43.5-53.35-88.14"/>
            <path class="cls-3" d="M92.19,137.58C73.5,94.21,50.32,58.05,32.43,14.81"/>
            <path class="cls-3" d="M92.24,135.47Q72.43,101.56,53.05,67.38A474.33,474.33,0,0,1,30,20.57"/>
            <path class="cls-3" d="M91.06,136Q67.41,68.88,41.52,2.61"/>
            <path class="cls-3" d="M90.92,137.68,54.15,40.84C49.55,28.73,44.94,16.6,39,5.1"/>
            <path class="cls-3" d="M92.47,141.84C76.07,96.26,58.86,50.75,35.51,8.3"/>
            <path class="cls-3" d="M92,143.71c-4.89-6.23-12.88-9.21-20.69-10.57S55.52,131.76,47.8,130a53.22,53.22,0,0,1-17.39-7.48c-2-1.32-3.89-2.77-6-3.92a65.44,65.44,0,0,0-9.65-3.82,84,84,0,0,1-12.9-5.92"/>
            <path class="cls-3" d="M93.23,140.82c-5-5.7-11.69-9.61-18.6-12.7s-14.11-5.49-20.94-8.75C42.86,114.21,33.18,107,23.58,99.76a76.69,76.69,0,0,1-7.84-6.42C10.08,87.8,6.2,80.63.38,75.26"/>
            <path class="cls-3" d="M88.25,129C74.81,85.91,66.82,40.6,45.88.57"/>
            <path class="cls-3" d="M90.26,137.09c-3.89-13.69-4.45-28.07-5-42.29C84.13,64.43,81.84,32.67,73,3.59"/>
            <path class="cls-3" d="M89.11,135.56A781.53,781.53,0,0,0,78.94,44.15,286.5,286.5,0,0,0,68,2.57"/>
            <path class="cls-3" d="M92.73,141.83Q89,106,88.25,69.85C87.8,47,89,26.93,81.58,5.32"/>
            <path class="cls-3" d="M89.58,137.82c1.17-44.72,6-87.26-3.88-130.9"/>
            <path class="cls-3" d="M92.49,138.66c-5.48-44.13,4.9-89.25-2-133.19"/>
            <path class="cls-3" d="M92.06,140.48C96.21,96.32,100.18,52,98.79,7.69"/>
            <path class="cls-3" d="M91.61,144.4l4.52-27.13A227.67,227.67,0,0,0,99.3,93c.22-3.71.27-7.43.5-11.14,1.56-24.77,11.67-49.17,8.06-73.73"/>
            <path class="cls-3" d="M92,143.46l6.79-64.34L100.5,63l1.83-17.33c1.27-12,2.53-24,2-36"/>
            <path class="cls-3" d="M94.34,148.48c-.68-8.67,2.17-17.18,4.81-25.47A616.77,616.77,0,0,0,123,15.77"/>
            <path class="cls-3" d="M94.15,148.65c-.58-9,3.42-17.48,7.37-25.53,12.67-25.85,28.9-59.49,42.41-84.91"/>
            <path class="cls-3" d="M93.21,147.72a84.61,84.61,0,0,1,4-16.45c1.56-4.45,3.5-8.76,5.44-13.07q12.09-26.88,24.2-53.75c1.73-3.83,3.45-7.66,5-11.57,3.78-9.69,5.86-15.78,7.05-26.11"/>
            <path class="cls-3" d="M93.23,147.36c1.47-11.39,5.63-22.24,9.75-33l17.66-45.9c1.63-4.23,3.26-8.47,4.46-12.84a64.06,64.06,0,0,0,2.05-11.24c.49-5.38,3-16.68,2-22"/>
            <path class="cls-3" d="M93.32,144.25c4.85-20.38,12.81-39.86,19.64-59.67s12.58-40.31,12.74-61.26"/>
            <path class="cls-3" d="M94.55,147.92c-.6-5,1.16-10,3-14.72C110.71,99,128.27,65.86,133.8,29.6"/>
            <path class="cls-3" d="M93.55,145.32A433.1,433.1,0,0,0,146.41,84.7c4.14-5.74,8.17-11.65,10.77-18.23"/>
            <path class="cls-3" d="M94.68,147.12a247,247,0,0,0,57.17-44.9"/>
            <path class="cls-3" d="M94.93,147.15c14.72-8.8,28.85-18.79,40.85-31.05s21.85-26.88,27.05-43.23"/>
            <path class="cls-3" d="M95.07,146.15c10.52-8.23,21.07-16.58,29.77-26.71,3.69-4.29,7-8.87,10.34-13.44l18.06-24.86"/>
            <path class="cls-3" d="M94.68,145.57c-.33-1,.67-1.81,1.53-2.34,9.49-5.85,18-13.07,26.56-20.27,6-5.09,12.07-10.2,17.14-16.24,7.64-9.1,12.8-20,17.87-30.71"/>
            <path class="cls-3" d="M95.88,146.71a121,121,0,0,0,67.7-63.89c.82-1.87,1.61-3.91,1.16-5.9"/>
            <path class="cls-3" d="M96.83,145.74c5.3.79,10.69-.19,16-1.1q30.2-5.23,60.79-7.66"/>
            <path class="cls-3" d="M97.88,146.26c15.56-4.07,28.48-14.63,40.86-24.9a97.62,97.62,0,0,0,10.7-9.82c7.13-8,11.41-18,15.57-27.9"/>
            <path class="cls-3" d="M94.33,146.22a88.6,88.6,0,0,0,74.32-60.66"/>
        </g>
    </g>
</template>

<script>
export default {
    name: 'Leaf'
}
</script>

<style>

</style>