<template>

    <div class="home">        

        <section :style="{'background-color': section1}">
            <div class="container">
                <div class="row">
                    <h1 class="accent">The science behind the difference</h1>
                </div>
                <div class="row">
                    <div class="col-12 col-md-4">
                        <article class="review">
                            <p class="review__text accent">"Hilarious and informative"</p>
                            <img src="~@/images/home/stars.png" class="review__rating" alt="rating"/>
                        </article>
                    </div>
                    <div class="col-12 col-md-4"></div>
                    <div class="col-12 col-md-4">                    
                        <article class="review">
                            <p class="review__text accent">"Timeless classic"</p>
                            <img src="~@/images/home/stars.png" class="review__rating" alt="rating"/>
                        </article>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-4">
                        <article class="review">
                            <p class="review__text accent">"Groundbreaking"</p>
                            <img src="~@/images/home/stars.png" class="review__rating" alt="rating"/>
                        </article>
                    </div>
                    <div class="col-12 col-md-4"></div>
                    <div class="col-12 col-md-4">                    
                        <article class="review">
                            <p class="review__text accent">"Brilliant... a must see"</p>
                            <img src="~@/images/home/stars.png" class="review__rating" alt="rating"/>
                        </article>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-12 col-md-6 text-center">
                        <img class="actor actor--lift" src="~@/images/home/actor1.png" alt="">
                    </div>
                </div>
            </div>
        </section>  
        <section :style="{'background-image': 'linear-gradient(' + section1 + ','+ section2 + ')'}">
            <div class="container">
                <div class="row">
                    <div class="col-12 text-center">

                        <div class="home-nav" v-on:click="film">
                            <p class="act-text">Act 1</p>
                            <img src="~@/images/home/play.png" class="play-button" alt="play"/>
                            <div class="pointer pointer--play">
                                <img src="~@/images/home/pointer.png" alt="" class="pointer__img">
                            </div>
                        </div>

                    </div>
                </div>
                <div class="row content-row">
                    <div class="col-12 col-md-6 text-center">
                        <div class="spinner mouth"></div>
                    </div>
                    <div class="col-12 col-md-6">  
                        <div class="content-row__body">
                            <h2 class="accent" data-anim="TextReveal">Film Synopsis:</h2>                                      
                            <p class="content-row__text" data-anim="TextReveal">
                                John has heard glowing reviews of an agency renowned for its captivating learning content. Violet is on hand to guide him through a whimsical televised landscape in which the true nature of their know-how is uncovered. Together, they are thrust into the limelight, discovering as they go that there’s a whole lot more under the aesthetically pleasing surface: there’s a science behind the difference.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>  
        <section :style="{'background-color': section2}">   
            <div class="container">            
                <div class="row justify-content-center">
                    <div class="col-12 col-md-6 text-center">
                        <div style="position: relative;">
                            <img class="actor actor--lab" src="~@/images/home/actor2.png" alt="">
                            <img src="~@/images/home/lab-arrow.png" alt="" class="lab-arrow">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 text-center">

                        <div class="home-nav" v-on:click="research">
                            <p class="act-text">Act 2</p>
                            <img src="~@/images/home/research.png" class="play-button" alt="play"/>
                            <div class="pointer pointer--research">
                                <img src="~@/images/home/pointer.png" alt="" class="pointer__img">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section :style="{'background-image': 'linear-gradient(' + section2 + ','+ section3 + ')'}">
            <div class="container">
                <div class="row content-row">
                    <div class="col-12 col-md-6 text-center order-md-2">
                        <div class="spinner heart"></div>
                    </div>
                    <div class="col-12 col-md-6 order-md-1">  
                        <div class="content-row__body">
                            <h2 class="accent" data-anim="TextReveal">It's all love:</h2>                                      
                            <p class="content-row__text" data-anim="TextReveal">
                                Despite the complex science, there really is only one 
                                ingredient in the formula that truly matters: <b>love</b>. We 
                                put love into everything we do, and it shines through 
                                in the work we produce. The science certainly provides 
                                a sturdy foundation, but it is the passion and care that 
                                truly elevates our creations. It enables us to properly 
                                <b>engage</b> our audiences, provide them with an unforgettable 
                                <b>experience</b> and leave them feeling recognised and <b>empower</b>ed.
                            </p>
                        </div>
                    </div>
                </div>          
                <div class="row justify-content-center">
                    <div class="col-12 col-md-6 text-center">
                        <helmet />    
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <div id="anim-element"></div>
                    </div>
                </div>

            </div>
        </section>  
        
        <section style="padding-bottom: 120px;" :style="{'background-color': section3}">

            <div class="container">
                <div class="row">
                    <div class="col-12 text-center">
                        <p class="act-text">Act 3</p>
                        <div class="eMenu">
                            <img src="~@/images/home/engage.png" alt="" class="eMenu__item" v-on:click="engage">
                            <img src="~@/images/home/e-split.png" alt="" class="eMenu__split">
                            <img src="~@/images/home/experience.png" alt="" class="eMenu__item" v-on:click="experience">
                            <img src="~@/images/home/e-split.png" alt="" class="eMenu__split">
                            <img src="~@/images/home/empower.png" alt="" class="eMenu__item" v-on:click="empower">
                            <div class="pointer pointer--emenu">
                                <img src="~@/images/home/pointer.png" alt="" class="pointer__img">
                            </div>
                        </div>
                    </div>
                </div>     
                <div class="row justify-content-center">
                    <div class="col-12 text-center">
                        <img class="actor actor--screen" src="~@/images/home/actor3.png" alt="">                    
                    </div>
                </div>
            </div>

        </section>

        <ContactFooter />

    </div>

</template>

<script>

import router from '../router';
import $ from 'jquery';
import helmet from '../components/helmet.vue';
import ContactFooter from '../components/contactFooter.vue';
// import lottie from 'lottie-web';
// import * as animData from '../animations/test.json';

export default {
    name: 'home',
    props: {
    },
    components: {
        helmet,
        ContactFooter
    },
    data: function () {

        return {
            animation: null,
            section1: "rgb(84,172,159)",
            section2: "rgb(255,148,158)",
            section3: "rgb(224,205,173)"
        };
    },
    methods: {
        film: function() {
            const loc = window.location.origin;
            let url = 'https://sciencebehind.co.uk/film/?redir=' + loc;
            console.log(url);
            window.location.href = url;
        },
        engage: function() {
            document.querySelector("html").scrollTop = 0
            router.push('/engage-intro');
        },
        experience: function() {
            document.querySelector("html").scrollTop = 0
            router.push('/experience-intro');
        },
        empower: function() {
            document.querySelector("html").scrollTop = 0
            router.push('/empower-intro');
        },
        research: function() {
            document.querySelector("html").scrollTop = 0
            router.push('/research')
        },
        onScroll: function() {
            this.fadeInScroll(.9);
            this.animator();
        },
        fadeInScroll: function(anchor) {
            // let p = this;

            // This controls the rows fading and floating up as they scroll into view


            let scrollPos = $("html").scrollTop();
            let wH = window.innerHeight;
            let trigger = (wH * anchor) + scrollPos;
            $(".home .row").each(function(){
                let pos = $(this).offset().top;
                if (pos < trigger) {
                    // Fade In
                    $(this).css({
                        "opacity": 1,
                        "transform": "translate(0px, 0px)"
                    });
                } else {
                    // Fade out
                    $(this).css({
                        "opacity": 0,
                        "transform": "translate(0px, 120px)"
                    });
                }
            });

            // This controls the text revealing diagonally as it's scrolled into view

            $("[data-anim='TextReveal']").each(function(){                
                let scrollPos = $("html").scrollTop();
                let wH = window.innerHeight;
                let bottom = scrollPos + (wH/2);
                let top = (wH + scrollPos) - bottom;
                let pos = ($(this).offset().top) - bottom;

                let prog = (pos/top) * 100;
                if (pos < top) { 

                    if (prog < 0) prog = 0;
                    if (prog > 100) prog = 100;

                    let p1 = 100 - (prog*2);
                    let p2 = 100 - prog;

                    $(this).css({
                        "--gradient-progress": p1+"%",
                        "--gradient-progress-2": p2+"%"
                    })
                }
            });

            // $("#anim-element").each(function(){
            //     let pos = $(this).offset().top;
            //     if (pos < trigger) {
            //         p.animation.play();
            //     } else {
            //         p.animation.goToAndStop(0);
            //     }
            // });

        },
        animator: function(){

            // Methods for controlling the png sequencer

            $(".spinner").each(function(){
                
                let actor = $(this),
                    frameCount = 60,
                    actorWidth = actor.css("width"),scrollPos = $("html").scrollTop(),
                    animationFriction = 10,
                    scrollVal = Math.round(scrollPos / animationFriction),
                    frameNumber = scrollVal >= frameCount ? scrollVal % frameCount : scrollVal,
                    frameInSprite = frameNumber * parseInt(actorWidth);
    
                actor.css("background-position", -Math.abs(frameInSprite) + "px 0px")
            })

        }
        
    },
    computed: {     
    },
    mounted: function() {

        // const animElement = document.getElementById("anim-element");

        // this.animation = lottie.loadAnimation({
        //     container: animElement, // the dom element that will contain the animation
        //     renderer: 'svg',
        //     loop: false,
        //     autoplay: false,
        //     animationData: animData.default // the path to the animation json
        // });

        // add parallaxing 
        document.addEventListener('scroll', this.onScroll);
        let comp = this;
        setTimeout(function(){
            comp.fadeInScroll(1.1);
            comp.animator();
        }, 500)

    }
}
</script>

<style lang="scss" scoped>

    $yellow: rgb(246,236,0);

    @keyframes pointer {
        0% {
            transform: translateY(0px);
        }
        100% {
            transform: translateY(-20px);
        }
    }

    .pointer {
        position: absolute;
        &--play {
            right:-60px;
            top:50%;
            transform: rotateZ(-60deg);
            @media (max-width: 768px) {
                right: 10px;
            }

        }
        &--research {
            right:-60px;
            top:0%;
            transform: rotateZ(-120deg);
            @media (max-width: 768px) {
                right: 10px;
            }
        }
        &--emenu {
            left: 80px;
            top: -80px;
            transform: rotateZ(135deg);
            @media (max-width: 768px) {
                left: 20px;
                top: -100px;
            }
        }
        &__img {
            animation: pointer infinite 1s alternate-reverse ease-in-out;
        }
    }

    .home-nav {
        max-width: 400px;
        margin: auto;
        position: relative;
    }

    .act-text {
        font-family: 'futura-pt';
        color: #1d1b19;
        font-weight: 700;
        font-size: 21px;
        margin: 20px 0px 0px;
    }

    [data-anim='TextReveal'] {   
        z-index: 1;
        position: relative;     
        
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;

        --gradient-progress: 0%;
        --gradient-progress-2: 0%;
        --gradient-angle: 135deg;
        --gradient-color: 255;
        --gradient-color-2: 240;
        --gradient-opacity: 1;
        --gradient-opacity-2: 0;
        background-image: linear-gradient(
            var(--gradient-angle), 
            rgba(
                var(--gradient-color), 
                var(--gradient-color), 
                var(--gradient-color), 
                var(--gradient-opacity)) 
            var(--gradient-progress), 
            rgba(
                var(--gradient-color-2), 
                var(--gradient-color-2), 
                var(--gradient-color-2), 
                var(--gradient-opacity-2)) 
            var(--gradient-progress-2));

        &.accent {                
            background-image: linear-gradient(
                var(--gradient-angle), 
                rgba(
                    $yellow, 
                    var(--gradient-opacity)) 
                var(--gradient-progress), 
                rgba(
                    $yellow, 
                    var(--gradient-opacity-2)) 
                var(--gradient-progress-2));
        }
    }

    .row {
        opacity: 0; 
        transform: translate(0px, 120px);
        // transition: all .5s ease-out;
        transition: all 1s cubic-bezier(0, 0, 0, 1);

    }

    @media (min-width: 992px) {
        .container {
            max-width: 960px;
        }
    }
    h1, h2 {
        text-transform: uppercase;
        font-family: futura-pt;
        font-weight: 700;
        letter-spacing: 2px;
        margin-bottom: 40px;
    }
    h1 {
        text-align: center;
    }
    h2 {
        margin-bottom: 20px;
    }
    p {        
        font-family: Helvetica;
            font-size: 20px;
    }
    .accent {
        color: $yellow;
    }
    section{
        // padding: 80px 0;
        &:first-of-type {
            padding-top: 200px;
            @media (max-width: 768px) {
                padding-top: 120px;
            }
        }
    }
    .review {
        margin-bottom: 40px;
        text-align: center;
        &__text {
            margin-bottom: 0;
        }
    }
    .heart, .mouth {
        position: relative; 
        height: 400px;
        width: 400px;
        background-size: cover;
        background-position: 0px 0px;

        @media (max-width: 992px) {
            height: 300px;
            width: 300px;
            display: block;
            margin: auto;
        }
    }
    .heart {
        background-image: url(~@/images/home/heart-sprite.png);
    }
    .mouth {
        background-image: url(~@/images/home/mouth-sprite.png);
    }
    .actor {
        max-width: 90%;
        &--lift {
            @media (min-width: 768px) {
                margin-top: -100px;
            }
        }
        &--screen {
            margin-bottom: 120px;
        }
    }
    .play-button {
        margin-top: 20px;
        max-width: 100%;
        transition: all .02s ease-out;
        cursor: pointer;
        @media (max-width: 768px) {
            max-width: 60vw;
        }
        &:hover {
            transform: scale(0.97);
        }
    }
    .content-row {
        padding: 120px 0;
        align-items: center;
        @media (max-width: 768px) {
            padding: 80px 0;
        }
        &__image {
            max-width: calc(100% - 40px);
            margin: 40px 0px;
        }
        &__body {
            @media (min-width: 768px) {
                // margin-top: 40%;
            }
        }
        &__text {
            color: white;
        }
    }
    .lab-arrow {
        position: absolute;
        right: 100%;
        bottom: -80px;
        @media (max-width: 768px) {
            display:none;
        }
        @media (min-width: 992px) {
            right: calc(100% - 60px)
        }
    }
    .eMenu {
        margin-top: 40px;
        margin-bottom: 80px;
        position: relative;
        @media (max-width: 768px) {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        &__item {
            cursor: pointer;
            margin: 0px 5px;
            transition: all .02s ease-out;
            cursor: pointer;
            &:hover {
                transform: scale(0.97);
            }
            @media (max-width: 768px) {
                margin-bottom: 40px
            }
        
        }
        &__split {
            margin: 0px 5px;
            @media (max-width: 768px) {
                display: none;
            }
        }
    }
</style>