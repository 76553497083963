<template>
    <g id="petal" class="nature-outline">
        <path class="cls-1" d="M95.7,10.7c-16.38,7.49-31,20.51-36.65,37.6-7.28,22,1.2,45.92,11.54,66.63,11.7,23.45,31.44,38.57,47.88,59,4.22,5.25,3.42,17.68,9.83,19.78,12.26,4,19.84-12.72,28.27-22.48,23-26.58,41.24-52.58,54.26-85.22,3.56-8.91,7.15-18,8.12-27.51C225.57-6.7,136.16-7.81,95.7,10.7Z"/>
			<path class="cls-1" d="M131.64,194.05c1.11-9.15,1-18.4,1.13-27.62.43-27.54,3.29-55,4.23-82.52s-.1-55.41-7.3-82"/>
			<path class="cls-1" d="M131.52,194.25C137.89,137.84,143.47,81.66,148,25.08c.67-8.32,1.34-16.78,4.37-24.55"/>
			<path class="cls-1" d="M131.89,194.18c8.74-45.48,15.76-90.89,21-136.89,2.17-18.88,4.23-38.9,13.36-55.56"/>
			<path class="cls-1" d="M133,194.15c3.78-7.83,5.43-15.66,7-24.21q8.72-48.3,15.77-96.88c3.47-23.81,7-48.3,16.72-70.34"/>
			<path class="cls-1" d="M133.17,194.12a139.8,139.8,0,0,0,9.75-21.91,948.71,948.71,0,0,1,40.55-95.86c8.25-16.86,17.49-34.91,28.95-49.77"/>
			<path class="cls-1" d="M133.47,194.08c9.67-24.93,25.78-46.27,37.69-70.21,6.31-12.68,11.48-25.94,18.57-38.2s17-24.2,29.44-30.91"/>
			<path class="cls-1" d="M135.47,193.55l16.22-24.7C160.83,154.94,170,141,178.1,126.48,190,105.11,200.4,82,217.88,64.89"/>
			<path class="cls-1" d="M134.44,193.84c32-26.71,47.37-69.46,73.74-101.7"/>
			<path class="cls-1" d="M132.59,194.33c18.63-29.52,36-59.26,52.34-90.1C194.08,86.94,202,69.46,218.8,59.38"/>
			<path class="cls-1" d="M132.1,193.41l42.9-86c12.49-25.05,24.84-50.06,41.39-72.62"/>
			<path class="cls-1" d="M134.12,194c7.7-28.18,15.1-54.78,22.8-83,2.93-10.7,5.85-21.4,8.14-32.26,5.25-24.94,7.32-51,21.56-72.12"/>
			<path class="cls-1" d="M133,194.16c13-39.74,26.88-78.07,41.69-117.18,7.89-20.85,16.21-41.9,29.75-59.6"/>
			<path class="cls-1" d="M129.77,193.55c.91-38.94,5.81-78,4.48-116.33C133.37,52,133.21,25,120.76,3"/>
			<path class="cls-1" d="M129.2,193.85c-.13-1.21-.09-1.54-.21-2.75-6.26-62-.59-126-19.8-185.28"/>
			<path class="cls-1" d="M127.48,193.2c-2.28-18.14-4.91-34.83-8.73-52.71-2.52-11.8-5.37-23.54-8.22-35.27l-9-37.1c-1.51-6.23-3-12.5-5.64-18.37a108.43,108.43,0,0,0-8.71-15c-3-4.58-5.78-8.66-8.77-13.24"/>
			<path class="cls-1" d="M126.92,192.94c-2.65-12-5.55-22.88-11.52-33.61-6.73-12.11-16.74-22-26.61-31.75-7.48-7.38-15.16-14.75-22.65-22.13"/>
			<path class="cls-1" d="M126.41,192.58c-.91-10.88-1.69-19.86-5.15-30.22-2.58-7.7-6.49-14.87-10.47-22-14.4-25.61-30.44-51-54.16-68.36"/>
			<path class="cls-1" d="M128.23,193.59c.06.12.16-.16.06-.07a26.28,26.28,0,0,1-2.07-9.85c-1.21-18-4.55-36.13-12.71-52.22-2.43-4.8-5.28-9.38-7.49-14.28-2.58-5.72-4.26-11.79-6.53-17.64-3.07-7.91-7.2-15.37-11.31-22.8C80.88,63.55,73.52,50.2,63,39.41"/>
			<path class="cls-1" d="M128.76,193.77A260.43,260.43,0,0,0,123,143.45a164.55,164.55,0,0,1-3-16.86c-.73-7.35-.17-14.77-.45-22.15C119,89.56,115,75.05,111,60.71c-4.72-16.85-9.15-33.65-18.68-48.32"/>
			<path class="cls-1" d="M130.53,194.13c3.52-18.31-2.65-36.12-1.38-54.72.36-5.26,1.29-10.45,1.86-15.69,4.3-39.42-9.28-80-15.66-119.17"/>
			<path class="cls-1" d="M22.61,269.13c8.1,8.29,19.18,14.32,30.77,14,14.91-.38,27.58-10.73,37.88-21.51,11.67-12.2,16.54-27.46,25.33-41.87,2.27-3.71,10-5.93,9.85-10.27-.24-8.31-12-9.26-19.78-12.24-21.12-8.14-40.86-13.55-63.48-14.34-6.18-.21-12.45-.42-18.45,1.07C-16.23,194.18,2.57,248.63,22.61,269.13Z"/>
			<path class="cls-1" d="M125.93,207.41a169.66,169.66,0,0,0-17,5.33c-16.79,5.74-34,10-50.95,15.4S24.42,240.27,9.87,250.43"/>
			<path class="cls-1" d="M126.07,207.44c-35.57,8.42-70.85,17.27-106.14,26.85C14.75,235.7,9.47,237.13,4.1,237"/>
			<path class="cls-1" d="M126,207.23c-29.47,4.61-58.53,10.24-87.56,17.07-11.92,2.8-24.5,5.91-36.59,4"/>
			<path class="cls-1" d="M125.69,206.56c-5.57-.59-10.67.12-16.19,1.05q-31.18,5.23-62.16,11.53c-15.2,3.09-30.82,6.26-46.29,5.19"/>
			<path class="cls-1" d="M125.64,206.46a90.32,90.32,0,0,0-15.41-1.13,611,611,0,0,1-66.94-3.71c-12-1.33-25-3-36.48-6.71"/>
			<path class="cls-1" d="M125.55,206.29c-17.22-.43-33.66-5.55-50.78-7.56-9.06-1.06-18.22-1.3-27.2-2.94s-18.36-5-25.14-11.11"/>
			<path class="cls-1" d="M124.79,205.2l-18.51-4.46c-10.42-2.51-20.86-5-31.43-6.78-15.55-2.58-31.81-3.84-46-10.7"/>
			<path class="cls-1" d="M125.19,205.75C102,192.2,72.76,192.17,47.48,183.2"/>
			<path class="cls-1" d="M125.89,206.77c-22-4.87-43.76-8.91-66-12.1-12.47-1.79-24.8-2.81-34.56-10.77"/>
			<path class="cls-1" d="M125.44,207.27,63.94,200c-17.91-2.12-35.76-4.16-53-9.28"/>
			<path class="cls-1" d="M125.38,205.91c-18.76,1.47-36.5,2.77-55.26,4.24-7.12.56-14.25,1.12-21.33,2.1-16.26,2.25-32.5,6.66-48.41,2.63"/>
			<path class="cls-1" d="M125.71,206.58c-26.93.77-53.19.71-80.12.25-14.36-.25-28.93-.71-42.61-5.06"/>
			<path class="cls-1" d="M126,208.65c-23.81,7.93-48.58,13.47-71.5,22.62-15.13,6-31.44,12-42.06,24.34"/>
			<path class="cls-1" d="M126.34,208.93c-.7.34-.91.39-1.62.73C88.51,227,48.45,237.46,16.71,262"/>
			<path class="cls-1" d="M126.32,210.11c-10.5,5.34-20,10.57-30,16.77-6.61,4.1-13.1,8.39-19.6,12.67L56.15,253.09a69.93,69.93,0,0,0-9.91,7.42A69.56,69.56,0,0,0,39,269.07c-2.13,2.82-4,5.39-6.12,8.2"/>
			<path class="cls-1" d="M126.29,210.51c-6.69,4.22-12.66,8.35-17.87,14.3-5.88,6.72-9.71,14.95-13.46,23.05-2.84,6.14-5.64,12.41-8.48,18.55"/>
			<path class="cls-1" d="M126.18,210.9c-6.4,2.92-11.68,5.35-17.2,9.7a83.46,83.46,0,0,0-11,11.13C85.56,246,73.64,261.3,68.31,279.45"/>
			<path class="cls-1" d="M126.39,209.57c.07-.06-.13,0,0,0a16.72,16.72,0,0,1-5.52,3.4C110.17,217.61,99.9,223.58,91.93,232c-2.38,2.51-4.54,5.24-7,7.64-2.9,2.81-6.22,5.16-9.27,7.8a122.09,122.09,0,0,0-11.36,11.83c-6.4,7.29-12.89,14.66-17.14,23.38"/>
			<path class="cls-1" d="M126.39,209.21a168.16,168.16,0,0,0-29.26,14.44,102,102,0,0,1-9.56,5.51c-4.3,2-8.92,3.32-13.33,5.1-8.89,3.59-16.83,9.16-24.65,14.71-9.18,6.53-18.4,12.88-25.22,21.85"/>
			<path class="cls-1" d="M126.22,208.06c-11.87,1.86-21.32,9.47-32.88,12.76-3.26.93-6.61,1.49-9.91,2.28-24.84,6-46.49,23.06-68.83,35.45"/>
			<path class="cls-1" d="M433.05,311.32c3.39-30.14-2.73-62.53-22.51-85.53-25.42-29.56-67.34-38.08-106.21-41.12-44-3.44-83,12-126.7,18-11.25,1.55-28.5-10.39-37.09-3-16.42,14.24,1.16,39.67,7.94,60.32,18.46,56.22,40.16,105.16,76,152.25,9.79,12.86,19.75,25.9,32.69,35.58C345.55,513.94,424.68,385.8,433.05,311.32Z"/>
			<path class="cls-1" d="M137.14,204.22c12.25,9.54,25.72,17.37,38.9,25.58,39.4,24.5,76.55,52.44,115.48,77.67s80.1,47.92,124.74,60.57"/>
			<path class="cls-1" d="M137,203.87c75.92,58.11,152.21,114.89,230,170.49,11.43,8.18,23.06,16.49,31.66,27.6"/>
			<path class="cls-1" d="M136.74,204.47c58.09,52,117.57,101.57,179.43,149.07C341.55,373,368.67,393.36,384.8,421"/>
			<path class="cls-1" d="M135.82,206.1c8,12.24,17.89,21.41,28.91,31.06q62.19,54.45,126.2,106.78C322.32,369.59,354.59,396,378,429.07"/>
			<path class="cls-1" d="M135.71,206.36a235.27,235.27,0,0,0,23.18,33.08A1595.57,1595.57,0,0,1,262.15,381.13c17.18,26.53,35.23,55.52,46.75,85"/>
			<path class="cls-1" d="M135.51,206.83c27.61,35.59,44.44,77.36,68.69,115.32,12.84,20.1,27.51,39.06,39.05,59.94s20.23,45.48,19.1,69.3"/>
			<path class="cls-1" d="M134.54,210.18,156.14,255c12.16,25.26,24.34,50.54,38.28,74.87,20.5,35.75,44.85,70.75,54.43,110.84"/>
			<path class="cls-1" d="M135,208.44c10.86,69.31,59.22,128.64,82.9,194.68"/>
			<path class="cls-1" d="M135.92,205.34c26.46,52.51,54.34,103.36,84.71,153.71,17,28.21,35.36,54.86,35.37,87.81"/>
			<path class="cls-1" d="M137.67,205.43,224.7,342c25.34,39.76,50.74,79.28,69,122.76"/>
			<path class="cls-1" d="M135,207.81c34,35.56,66,69.31,100,104.87,12.91,13.5,25.84,27,39.53,39.72C306,381.61,341.81,407.18,360,446.08"/>
			<path class="cls-1" d="M135.82,206c46.11,53.25,89.43,106.53,133.06,161.83,23.25,29.47,46.44,59.74,60.26,94.64"/>
			<path class="cls-1" d="M139.48,201.94C194.92,237,247.12,278,303.57,309.3c37.24,20.64,76.32,43.79,118.88,44.89"/>
			<path class="cls-1" d="M139.54,200.86c1.86.86,2.3,1.21,4.16,2.08C238.61,247.65,326.17,311.39,428.39,335"/>
			<path class="cls-1" d="M142,199c28.16,12.43,54.55,23.1,83.67,33.1,19.23,6.6,38.65,12.66,58.06,18.72l61.38,19.16c10.32,3.22,20.69,6.46,31.41,7.79,9.7,1.2,19.52.83,29.28.46,9.22-.35,17.51-.83,26.73-1.18"/>
			<path class="cls-1" d="M142.83,198.36c19.61,6.57,37.85,11.82,58.53,12.52,23.33.78,46.31-5.08,68.92-10.89,17.14-4.41,34.45-9.11,51.6-13.51"/>
			<path class="cls-1" d="M143.8,197.94c16.5,8.11,30.14,14.78,48.1,18.76,13.35,3,27.1,3.53,40.77,3.92,49.47,1.42,100.09.31,145.68-18.92"/>
			<path class="cls-1" d="M140.75,199.69c-.22,0,.11.37.05.15,6.43.86,10.24,2.59,16,5.55,27,13.85,56.13,24.76,86.44,26.93,9,.65,18.12.52,27.11,1.57,10.49,1.24,20.72,4.07,31.13,5.87,14.1,2.43,28.44,2.94,42.74,3.44,25.36.9,51,1.84,75.71-4"/>
			<path class="cls-1" d="M140,200.3c26,15,49.32,25.41,77.64,35.35,9.08,3.19,18.28,6.12,27,10.24,11.25,5.33,21.47,12.57,32.38,18.56,22,12.08,46.38,18.94,70.56,25.57,28.42,7.79,56.52,16,86,14.93"/>
			<path class="cls-1" d="M138,202.54c23.4,21,54.45,27.5,80.22,45.47,7.28,5.07,14,10.92,21,16.29,53.2,40.39,123.6,56,185.66,80.73"/>
    </g>
</template>

<script>
export default {
    name: 'Petal'
}
</script>

<style>

</style>