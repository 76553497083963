<template>
    <div :style="position" class="audio-rack__dial" :class="trackClass">
        <audio :src="source"></audio>
        <img class="audio-rack__dial__img" alt="dial" src="~@/images/empower/dial.png" data-rZ="0"/>
        <div class="audio-rack__dial__drag">
            <img src="~@/images/empower/dial-empty.png" alt="" class="audio-rack__dial__drag__img">
        </div>
    </div>
</template>

<script>
export default {
    name: 'rack-dial',
    props: {
        stem: {
            type: Object,
            required: true
        },
        track: {
            type: Number,
            required: true
        }
    },
    computed: {
        position(){
            return `left: ${this.stem.x}; top: ${this.stem.y}`
        },
        source() {
            return `/audio/${this.stem.src}.mp3`
        },
        trackClass() {
            return `track-${this.track}`
        }        
    }
}
</script>

<style lang="scss" scoped>

</style>