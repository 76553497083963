<template>
    <section class="accordian" :class="'bg-' + set" :style="{'background-color': backgroundColor}">
        <div class="accordian__head" v-on:click="toggleAccordion">
            <div class="container">
                <div class="row align-items-center justify-content-between">
                    <div class="col-9">
                        <h2 class="accordian__no">0{{set}}</h2>
                    </div>
                    <div class="col-3">
                        <div class="accordian__state" v-bind:class="{'is-closed': isClosed}"></div>
                    </div>            
                </div>
            </div>
        </div>

        <div class="accordian__drop" v-bind:class="{'is-closed': isClosed}">
            
            <div class="container">
                <div class="row justify-content-center">
                <Question :color="color" :set="set" v-for="index in 5" :key="index" :number="index" />

                </div>
            </div>

            <!-- <div class="carousel" :data-set="set">
                <div class="carousel__slide accordian__drop-inner" v-for="index in 5" :key="index">

                </div>
                <div class="carousel__controls">
                    <div class="carousel__button" v-on:click="crsl(0)"></div>
                    <div class="carousel__button" v-on:click="crsl(1)"></div>
                </div>
            </div> -->

        </div>

    </section>
</template>

<script>
import Question from './question.vue';
import scrollTo from '../helpers/scroll.js';

export default {
    name: 'accordian',
    props: {
        msg: String,
        set: Number,
        color: String,
        backgroundColor: String
    },
    components: {
        Question
    },
    data: function() {
        return {
            isClosed: true
        }
    },
    methods: {
        toggleAccordion: function(evt) {
            this.isClosed = !this.isClosed;
            if (!this.isClosed) {
                // scroll to drop
                setTimeout(function() {
                    scrollTo(evt.target);
                }, 250)
            }
        },
        answered() {
            this.$parent.checkAnswered();
        },
        crsl: function(dir) {
            const n = document.querySelector(".carousel[data-set='"+this.set+"']").firstElementChild,
                w = document.defaultView.getComputedStyle(n).width,
                currentMargin = document.defaultView.getComputedStyle(n).marginLeft,
                btn = document.querySelectorAll(".carousel__button");

                for (var i = 0; i < btn.length; i++) {
                    btn[i].style.pointerEvents = "none";
                }

            let timer = 500;


            if (dir === 0) {
                if (currentMargin === '0px') {
                    timer = 0;
                } else {
                    n.style.marginLeft = (parseInt(currentMargin) + parseInt(w)) + "px";
                }
            } else {
                if (currentMargin === -Math.abs(parseInt(w) * 4) + "px") {
                    timer = 0;
                } else {
                    n.style.marginLeft = (parseInt(currentMargin) - parseInt(w)) + "px";
                }
            }

            setTimeout(function(){
                for (var i = 0; i < btn.length; i++) {
                    btn[i].style.pointerEvents = "auto";
                }
            }, timer);

        }
    },
    computed:  {
        
        accordionClasses: function() {
            return this.isClosed === true ? 'is-closed' : '';
            
        }
    },
    mounted: function() {
    }
}
</script>

<style lang="scss">
    @media (min-width: 992px) {
        .container {
            max-width: 960px;
        }
    }

    .accordian {
        box-shadow: inset 0 0 20px rgb(0, 0, 0, .20);
    background-size: 100% auto;

        &.bg-1 {
            background-image: url('~@/images/quiz/1.svg');
        }
        &.bg-2 {
            background-image: url('~@/images/quiz/2.svg');
        }
        &.bg-3 {
            background-image: url('~@/images/quiz/3.svg');
        }
        &.bg-4 {
            background-image: url('~@/images/quiz/4.svg');
        }
        &.bg-5 {
            background-image: url('~@/images/quiz/5.svg');
        }
        &.bg-6 {
            background-image: url('~@/images/quiz/6.svg');
        }
        &.bg-7 {
            background-image: url('~@/images/quiz/7.svg');
        }
        &.bg-8 {
            background-image: url('~@/images/quiz/8.svg');
        }
        &:last-of-type {
            display: none;
        }
        &__head {
            height: 100px;
            display: flex;
            align-items: center;
            cursor: pointer;
        }
        &__drop {
            max-height: 200vh;
            overflow: hidden;
            transition: .25s ease all;
            &.is-closed {
                max-height:0;
            }
        }
        &__drop-inner {
            padding: 40px 0;
        }
        &__no {
            color:white;
            font-family: futura-pt;
            font-weight: 700;
            margin:0;
        }
        &__state {
            height: 16px;
            width: 16px;
            float:right;
            margin-right:20px;
            position: relative;
            &:before, &:after {
                content:'';
                position: absolute;
                background: white;
                top: 50%;
                left:50%;
                transform:translate(-50%, -50%);
                width:16px;
                height:4px;
                transition: .2s ease-out;
            }
            &.is-closed {
                &:after {
                    transform:translate(-50%, -50%) rotateZ(270deg);
                }
            }
        }
    }

    .carousel {
        position: relative;
        white-space: nowrap;
        &__slide {
            // display:inline-block;
            display:block;
            width:100%;
            transition: all .5s ease-out;
        }
        &__controls {
            // display: flex;
            display: none;
            align-items: center;
            justify-content: center;
        }
        &__button {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0px 20px 40px;
            font-size: 48px;
            border: 1px solid #515151;
            color: #515151;
            border-radius: 200px;
            height: 40px;
            width: 40px;
            cursor: pointer;

            background-size: 10px;
            background-repeat: no-repeat;
            background-position: center center;
            background-image: url('~@/images/accordian/chev-left.png');
            &:nth-child(2) {
                background-image: url('~@/images/accordian/chev-right.png');
            }
        }
    }
        
</style> 