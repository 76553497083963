<template>

    <div class="share">

        <div class="share__button">
            <div 
                data-href="https://sciencebehind.co.uk/" 
                data-layout="button" 
                data-size="large">

                <a 
                    target="_blank" 
                    href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fsciencebehind.co.uk%2F&amp;src=sdkpreparse" 
                    class="fb-xfbml-parse-ignore">
                    <svg class="share__button__img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.04 17.04">                        
                        <g :style="{'fill': color}">
                            <path d="M16.1,0H.93A.94.94,0,0,0,0,.94V16.1A.94.94,0,0,0,.94,17H9.1V10.45H6.89V7.87H9.1V6a3.1,3.1,0,0,1,3.31-3.4,18.75,18.75,0,0,1,2,.1V5H13c-1.07,0-1.28.51-1.28,1.25V7.87h2.55L14,10.45H11.76V17H16.1A.94.94,0,0,0,17,16.1V.93A1,1,0,0,0,16.1,0Z"/>
                        </g>
                    </svg>
                </a>
            </div>                
        </div>

        <div class="share__button">
            <a 
                target="_blank" 
                href="https://twitter.com/share?ref_src=twsrc%5Etfw" 
                data-size="large" 
                data-text="The Art of the Difference, by PurpleMedia." 
                data-url="https://sciencebehind.co.uk/" 
                data-via="Purple_learn" 
                data-hashtags="sciencebehind" 
                data-related="" 
                data-show-count="false">
                <svg class="share__button__img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.1 16.34">
                    <g :style="{'fill': color}">
                        <path d="M6.32,16.34A11.66,11.66,0,0,0,18.06,4.6c0-.18,0-.35,0-.53A8.35,8.35,0,0,0,20.1,1.93a8.15,8.15,0,0,1-2.37.65A4.13,4.13,0,0,0,19.55.3a8.23,8.23,0,0,1-2.62,1,4.12,4.12,0,0,0-7,3.76A11.7,11.7,0,0,1,1.4.76,4,4,0,0,0,.84,2.83,4.12,4.12,0,0,0,2.68,6.26,4.17,4.17,0,0,1,.81,5.75v0a4.12,4.12,0,0,0,3.31,4,4,4,0,0,1-1.87.07,4.15,4.15,0,0,0,3.86,2.87A8.33,8.33,0,0,1,1,14.54c-.33,0-.66,0-1,0a11.76,11.76,0,0,0,6.32,1.85"/>
                    </g>
                </svg>
            </a>
        </div>

        <div class="share__button">
            <a 
                target="_blank" 
                href="https://www.linkedin.com/sharing/share-offsite/?url=https://sciencebehind.co.uk/">
                <svg class="share__button__img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.33 17.33">
                    <g :style="{'fill': color}">
                        <path d="M16.05,0H1.28A1.26,1.26,0,0,0,0,1.25V16.08a1.26,1.26,0,0,0,1.28,1.25H16.05a1.27,1.27,0,0,0,1.28-1.25V1.25A1.27,1.27,0,0,0,16.05,0ZM5.14,14.77H2.57V6.5H5.14ZM3.85,5.37A1.49,1.49,0,1,1,5.34,3.88,1.49,1.49,0,0,1,3.85,5.37Zm10.92,9.4H12.2v-4c0-1,0-2.19-1.34-2.19s-1.54,1-1.54,2.13v4.09H6.75V6.5H9.22V7.63h0a2.7,2.7,0,0,1,2.43-1.34c2.61,0,3.09,1.71,3.09,3.94Z"/>
                    </g>
                </svg>
            </a>
        </div>

        <div class="share__spacer"></div>

        <div class="share__button" v-on:click="share">
            <svg class="share__button__img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.33 17.33">
                <g style="fill: none;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px;" :style="{'stroke': color}">
                    <line x1="13.38" y1="16.27" x2="0.75" y2="16.27"/>
                    <line x1="6.87" y1="0.75" x2="6.87" y2="12.6"/>
                    <polyline points="11.79 8.72 7.07 13.44 2.34 8.72"/>
                </g>
            </svg>
        </div>
        <div class="share__button" v-on:click="retry">
            <svg class="share__button__img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.33 17.33">
                <g style="fill: none;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px;" :style="{'stroke': color}">
                    <polyline  points="6.3 7.98 2.68 4.36 6.3 0.75"/>
                    <path d="M.75,11A6.59,6.59,0,1,0,7.33,4.38H3.59"/>
                </g>
            </svg>
        </div>
        
    </div>

    <div v-if="loading" class="loading">
        <p>Generating your image</p>
        <img src="~@/images/loading.svg" alt="">
    </div>

</template>

<script>

import domtoimage from 'dom-to-image';
import $ from 'jquery';

export default {
    name: 'Socials',
    data: function() {
        return {
            loading: false,
            color: '#acc2cd'
        }
    },
    methods: {
        
        share(){
            const that = this;
            that.loading = true;
            // $(".artwork__logo").show();

            let className = "grad-" + $(".artwork").attr("data-color");
            
            $("#cap").addClass(className);


            setTimeout(function(){
                
                var node = document.getElementById('cap');
    
                domtoimage.toJpeg(node)
                    .then(function (dataUrl) {
                        var link = document.createElement('a');
                        link.download = 'myartwork.jpg';
                        link.href = encodeURI(dataUrl);
                        link.click();
                        that.loading = false;
                        $(".artwork__logo").hide();
                        $("#cap").removeClass(className);
                    });
            }, 250)


        },
        retry() {
            this.$parent.goToQuestions();
        }
    }
}
</script>

<style lang="scss" scoped>

    .loading {
        
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: white;
        background: rgba(0,0,0,0.8);

        & img {
            height: 150px;
            width: 150px;
        }
    }

    .share {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 60px;

        &__spacer {
            display: block;
            width: 30px;
        }

        &__button {
            display: block;
            margin: 0 10px;
            cursor: pointer;
            &__img {
                height: 40px;
                width: 20px;
            }
        }
    }

</style>