<template>
    <div class="audio-rack audio-mngr" :data-index="startIndex">
        <img class="audio-rack__img" :src="require(`@/images/empower/panel-${rack.color}.jpg`)" alt="">
        <img src="~@/images/empower/switch.png" alt="" class="audio-rack__switch">
        <rack-dial v-for="(item, index) in rack.stems" :stem="item" :track="index" :key="index"/>
    </div>
</template>

<script>
import RackDial from './dial.vue';
export default {
    name: 'rack',
    props: {
        start: {
            type: String,
            required: true
        },
        rack: {
            type: Object,
            required: true
        }
    },
    components: {
        RackDial
    },
    computed: {
        startIndex() {
            return this.start
        }
    }
}
</script>

<style lang="scss">
    .audio-rack {
        position: relative;
        width: 100%;
        margin: 1px 0px 2px;
        &__img {
            width: 100%;
        }
        &__switch {
            position: absolute;
            left: 5%;
            top: 10%;
            left: 5.3%;
            top: 37%;
            width: 6%;
            transform: rotateZ(180deg);
            cursor: pointer;
            &.active {                
                transform: rotateZ(0deg);
            }
        }
        &__dial {
            cursor: pointer;
            position: absolute;
            width: 8.3%;
            &__img {
                width: 100%;
                pointer-events: none;
                cursor: inherit;
            }
            &__drag {
                position: absolute;
                top:0;
                left:0;
                width: 100%;
                height: 100%;
                cursor: inherit;
                &_img {                
                    cursor: inherit;
                }
            }
        }
    }
</style>