<template>
    <div class="empower">
        
        <div class="container">

            <div class="row">
                <div class="col-12">
                    <div class="valves">
                        <img src="~@/images/empower/valves.png" alt="" class="valves__img">
                        <img src="~@/images/empower/valves-on.png" alt="" class="valves__img--on">
                    </div>

                    <div class="rack rack--colgate">
                        <div class="rack__switch rack__switch--colgate"></div>
                        <img src="~@/images/empower/colgate.jpg" alt="" class="rack__img">
                    </div>

                    <img class="rack" src="~@/images/empower/empowered.jpg" alt="">
                    
                    <Rack :rack="machine.beanbag" start="0"/>


                    <div class="rack rack--imperial">
                        <div class="rack__switch rack__switch--imperial"></div>
                        <img src="~@/images/empower/imperial.jpg" alt="" class="rack__img">
                    </div>
                    
                    <Rack :rack="machine.worldofdreams" start="4"/>

                    <div class="rack rack--jd">
                        <div class="rack__switch rack__switch--jd"></div>
                        <img src="~@/images/empower/jd.jpg" alt="" class="rack__img">
                    </div>
                    
                    <Rack :rack="machine.dharma" start="8"/>

                    <div class="rack rack--vodafone">
                        <div class="rack__switch rack__switch--vodafone"></div>
                        <img src="~@/images/empower/vodafone.jpg" alt="" class="rack__img">
                    </div>

                </div>
            </div>


        </div>
        <div class="tabletop">
            <img src="~@/images/empower/footer.png" alt="" class="tabletop">
            <div class="tabletop__links">
                <div class="tabletop__link" v-on:click="engage"></div>
                <div class="tabletop__link" v-on:click="experience"></div>
                <div class="tabletop__link" v-on:click="empower"></div>
            </div>
        </div>
    </div>
</template>

<script>
import router from '../router';
import * as $ from 'jquery';
import 'jquery-ui/ui/widgets/slider';
import Rack from '../components/empower/rack.vue';
import empowerMachine from '../fixtures/empower';
export default {
    name: 'Empower',
    components: {
        Rack
    },
    data() {
        return {     
            audioContext: [
                {
                    node: null,
                    audioCtx: null
                },
                {
                    node: null,
                    audioCtx: null
                },
                {
                    node: null,
                    audioCtx: null
                },
                {
                    node: null,
                    audioCtx: null
                },
                {
                    node: null,
                    audioCtx: null
                },
                {
                    node: null,
                    audioCtx: null
                },
                {
                    node: null,
                    audioCtx: null
                },
                {
                    node: null,
                    audioCtx: null
                },
                {
                    node: null,
                    audioCtx: null
                },
                {
                    node: null,
                    audioCtx: null
                },
                {
                    node: null,
                    audioCtx: null
                }
            ],
            machine: empowerMachine
        }
    },
    methods: {
        engage: function() {
            document.querySelector("html").scrollTop = 0
            router.push('/engage-intro');
        },
        experience: function() {
            document.querySelector("html").scrollTop = 0
            router.push('/experience-intro');
        },
        empower: function() {
            document.querySelector("html").scrollTop = 0
            router.push('/empower-intro');
        },
        returnToMenu: function() {
            router.push('/menu');
        },
        killAllAudio() {
            for (let i = 0; i < this.audioContext.length; i++) {
                let o = this.audioContext[i].audioCtx;
                if (o !== null ) {   
                    console.log(o.state)        
                    if (o.state !== 'suspended') {
                        console.log("suspending...")
                        o.suspend();                    
                    }
                }            
            }
        },
        createAudioContext(node) {

            const comp = this;

            let start = Number($(node).attr("data-index"));

            $(node).find(".audio-rack__switch").on('click',function() {


                if ($(this).hasClass("active")) {
                    comp.killAllAudio();
                } else {

                    if ($(".audio-rack__switch.active").length) {
                        $(".audio-rack__switch.active").removeClass("active");
                        comp.killAllAudio();
                    }

                    let audioTrackArray = $(node).find("audio");


                    audioTrackArray.each(function(i) {
                        let audioNode = $(this).get(0),
                            tI = i + start,
                            exists = false;

                        if (comp.audioContext[tI].audioCtx !== null ) {
                            const audioElement = comp.audioContext[tI].audioElement;
                            comp.audioContext[tI].audioCtx.resume();                    
                            audioElement.play();
                            console.log("resuming...")

                            exists = true;
                        }

                        if (!exists) {
                            console.log("creating...")
                            const AudioContext = window.AudioContext || window.webkitAudioContext;
                            comp.audioContext[tI].audioCtx = new AudioContext();
                            comp.audioContext[tI].audioElement = audioNode;

                            const audioElement = comp.audioContext[tI].audioElement;
                            const audioCtx = comp.audioContext[tI].audioCtx;

                            // instance of track
                            const track = audioCtx.createMediaElementSource(audioElement);
                            
                            // init gain
                            const gainNode = audioCtx.createGain();

                            track
                                .connect(gainNode)
                                .connect(audioCtx.destination);

                            // play sound
                            audioElement.play();

                            let trackControls = $(node).find(`.track-${i}`)

                            comp.gainControl(trackControls, gainNode);
                        }

                    });
                }

                $(this).toggleClass("active")

            });

        },
        gainControl(node, gainNode){
            let dial = $(node).find(".audio-rack__dial__drag").get(0);

            const _dragStart = function () {
                if ($(dial).hasClass("enabled")) {
                    let x = window.event.pageX;
                    dial.setAttribute('startX', x);
                }
            };
            const _dragMove = function () {
                let x = window.event.pageX,
                    startX = dial.getAttribute('startX'),
                    dif = x - startX,
                    startRZ = dial.getAttribute('rZ');

                let newRZ = startRZ + (dif*1.75);

                const maxAngle = 70

                if (newRZ > maxAngle) newRZ = maxAngle;
                if (newRZ < -Math.abs(maxAngle)) newRZ = -Math.abs(maxAngle);

                let volume = ((newRZ + maxAngle) / (maxAngle * 2)) * 2;

                gainNode.gain.value = volume;

                $(node).find("img").css('transform', `rotateZ(${newRZ}deg)`)
                
            };
            
            dial.addEventListener("mousedown", function(){
                $(dial).addClass("enabled");
            })
            dial.addEventListener("mouseup", function(){
                $(dial).removeClass("enabled");
            })

            dial.ondragstart = function() {
                _dragStart();
            }
            dial.ondragover = function() {
                if ($(dial).hasClass("enabled")) {
                    _dragMove();
                }
            }
            dial.ondragend = function() {
                $(dial).removeClass("enabled");
            }
            dial.addEventListener('touchstart', function(){
                $(dial).addClass("enabled");
                _dragStart();
            });
            dial.addEventListener('touchend', function(){
                $(dial).removeClass("enabled");
            });
            dial.addEventListener('touchmove', function() {                
                if ($(dial).hasClass("enabled")) {
                    _dragMove();
                }
            });

            // dial.addEventListener('mouseup',function() {
            //     let rZ = dial.getAttribute('rZ');
            // })


            // slider.addEventListener('input', function() {
            //     gainNode.gain.value = this.value;
            // });
        },
    },
    unmounted() {
        this.killAllAudio();
    },
    mounted: function(){

        const comp = this;

        setTimeout(function(){
            document.querySelector(".valves").classList.add("active");
        },500);
        

        $(".audio-mngr").each(function() {
            let node = $(this).get(0);
            comp.createAudioContext(node);
        });

        $(".rack__switch").on('click', function(){
            $(this).closest("div.rack").toggleClass("rack--off");
        });


    }
}
</script>

<style lang="scss" scoped>

    .rack-mount {
        position: relative;
        padding: 80px;
        background: brown;
        color: white;
    }
    .tracks {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .empower {
        width: 100%;
        overflow-x: hidden;
        background-image: url(~@/images/empower/bg.jpg);
        background-position: top center;
    }
    .container {
        padding-top: 20vh;
        overflow-y: visible;
        position: relative;
        z-index: 5;
    }
    .concrete {
        position: fixed;
        top:0;
        left:0;
        height: 100vh;
        width: 100vw;
        background-image: url(~@/images/empower/bg.jpg);
        background-size: 270vw auto;
        background-position: top center;
        @media (min-width: 576px) {
            background-size: 1200px auto;
        }
        @media (min-width: 768px) {
            background-size: 1600px auto;
        }
        @media (min-width: 992px) {
            background-size: 2200px auto;
        }
        @media (min-width: 1920px) {
            background-size: cover;
        }
    }
    .valves {
        position: relative;
        &:before {
            content:'';
            position: absolute;
            opacity: 0;
            top: 50%;
            left: 50%;
            width: 150%;
            height: 310%;
            transform: translate(-50%, -57%);
            transition: opacity 2s ease-out;
            background-image: url(~@/images/empower/valves-back.png);
            background-size: contain;
            background-repeat: no-repeat;
            mix-blend-mode: color-dodge;
        }
        &__img {
            position: relative;
            width:100%;
            &--on {
                position: absolute;
                opacity: 0;
                top: 50%;
                left: 50%;
                width: 120%;
                transform: translate(-50%, -60%);
                transition: opacity 2s ease-out;
                mix-blend-mode: color-dodge;
            }
        }
        &.active {
            &:before {                
                opacity: 1;
            }
        }
        &.active & {
            &__img{
                &--on {
                        opacity: 1;
                }
            }
        }
    }
    .rack {
        position: relative;
        width: 100%;
        &__img {
            width: 100%;
        }
        &--off {
            &:before {
                content:'';
                display: none;
                position: absolute;
                top:0;
                left: 0;
                height: 100%;
                width: 100%;
                background-size: contain;
                background-position: center center;
                background-repeat: no-repeat;
            }
        }
        &--colgate {
            &:before {
                background-image: url(~@/images/empower/colgate-off.png);
                display: block;
            }
        }
        &--imperial {
            &:before {
                background-image: url(~@/images/empower/imperial-off.png);
                display: block;
            }
        }
        &--jd {
            &:before {
                background-image: url(~@/images/empower/jd-off.png);
                display: block;
            }
        }
        &--vodafone {
            &:before {
                background-image: url(~@/images/empower/vodafone-off.png);
                display: block;
            }
        }
        &__switch {
            position: absolute;
            right: 13.5%;
            width: 6%;
            cursor: pointer;
            &--colgate {
                top: 10%;
                height: 13%;
            }
            &--imperial {
                top: 10%;
                height: 10%;
            }
            &--jd {
                top: 10%;
                height: 13%;
            }
            &--vodafone {
                top: 14%;
                height: 19%;
            }
            
        }
    }
    .tabletop {
        position:relative;
        display: block;
        margin: auto;
        width: 1200px;
        max-width:100%;
        margin-top: -5px;
        @media (max-width: 992px) {
            margin-top: -1px;
            margin-bottom: 60px;
        }
        &__img {
            width: 100%;
        }
        &__links {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        &__link {
            height: 100%;
            width: 33.3%;
            cursor: pointer;
        }
    }
    .menu-btn {
        margin-top: 40px;
        cursor: pointer;
        &:hover {
            transform: scale(0.97);
        }
    }
</style>