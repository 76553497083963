<template>
    <div class="experience" :style="{'background-color': color}">
        <video controls playsinline autoplay id="fullVideo">
            <source :src="video" type="video/mp4">
        </video>
    </div>
</template>

<script>
import router from '../router';
export default {
    name: 'FullVideo',
    props: {
        color: {
            type: String,
            required: true,
            default: '#7f9453'
        },
        video: {
            type: String,
            required: true
        },
        redirect: {
            type: String,
            required: true
        }
    },
    mounted: function() {
        const video = document.getElementById("fullVideo"),
            path    = this.redirect;

        video.onended = function() {
            router.push(path);
        }
    }
}
</script>

<style lang="scss" scoped>
    .experience {
        width: 100%;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        & video {
            width: 100%;
            height: 100%;
            max-width: calc(100% - 120px);
            max-height: calc(100% - 120px);
            &::-webkit-media-controls-panel {
                background-image: none;
            }
        }
    }
</style>

